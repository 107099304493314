/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, { Component } from 'react';

import HeaderText from 'components/HeaderText';

import FormJSX from 'components/Form/FormJSX';
import FieldOption from 'components/Form/FieldOption';
import Button from 'components/Button';

import BlankSlate from 'components/BlankSlate';
import ErnieImage from 'images/ernie/email-sent.png';

import t from 'tcomb-validation';
import { Phone } from 'schemas/common/phone';
import Email from 'schemas/common/email/Email';
import { Currency } from 'schemas/common/currency';
import { PercentNotZero } from 'schemas/common/percent';

const createSchema = (value) => {

  let schema = {
    name: t.String,
    phone: Phone,
    email: Email,
    issue: t.enums({
      'IncorrectAmount': 'Incorrect amount',
      'AlreadyPaid': 'Invoice already paid',
      'WrongCompany': 'Wrong vendor / location',
      'CardIssue': 'Declined card',
      'FeeRequired': 'Processing fee not included',
      'PaymentAmountLimit': 'Amount exceeds limit',
      'UpdatePaymentInstructions': 'Update payment instructions',
      'RequiresMultipleTransactions': 'Requires multiple transactions',
      'VendorCannotBeReached': 'Vendor cannot be reached',
      'Other': 'Other'
    })
  };


  if (value.issue === 'IncorrectAmount') {
    schema.amount = t.maybe(Currency);
    schema.invoiceNumber = t.maybe(t.String);
  }

  if (value.issue === 'AlreadyPaid') {
    schema.referenceNumber = t.maybe(t.String);
    schema.amount = t.maybe(Currency);
    schema.date = t.maybe(t.Date);
  }

  if (value.issue === 'WrongCompany') {
    schema.contactName = t.maybe(t.String);
    schema.contactPhone = t.maybe(Phone);
    schema.contactEmail = t.maybe(Email);
  }

  if (value.issue === 'FeeRequired') {
    schema.feePercentage = t.maybe(PercentNotZero);
    schema.feeAmount = t.maybe(Currency);
  }

  if (value.issue === 'PaymentAmountLimit') {
    schema.paymentAmountLimit = t.maybe(Currency);
  }

  if (!!value.issue) {
    schema.comments = value.issue === 'Other' ? t.String : t.maybe(t.String);
  }

  return t.struct(schema);
};

export default class VccHelp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formState: {},
      submitted: false,
      error: false
    };
  }

  onSubmit() {
    let value = this.formRef.getValue();
    if (value) {
      this.setState({ error: false });
      this.props.onSubmit(value, (success) => {
        if (success) {
          this.setState({ submitted: true });
        } else {
          this.setState({ error: true });
        }
      });
    }
  }

  renderIssueInstruction() {
    switch (this.state.formState.issue) {
      case 'IncorrectAmount': return 'Please provide as much information as you can about the incorrect amount.';
      case 'AlreadyPaid': return 'Please provide as much information as you can about the previous payment.';
      case 'WrongCompany': return `If possible, please provide any alternate contact 
        or information to help reroute this payment.`;
      case 'CardIssue': return `Please explain your issue.`;
      case 'FeeRequired': return `Please provide details about your processing fees.`;
      case 'PaymentAmountLimit': return `What is the maximum amount that can be received via card?`;
      case 'Other': return `Please explain.`;
      default: return null;
    }
  }

  renderForm() {
    return (
      <div style={{ textAlign: 'left' }}>
        <FormJSX ref={ref => this.formRef = ref}
          modelType={createSchema(this.state.formState)}
          value={this.state.formState}
          onChange={v => this.setState({ formState: v })}>

          <FieldOption name="name" cols={3} label="Name" />
          <FieldOption name="phone" cols={3} label="Phone" />
          <FieldOption name="email" cols={6} label="Email Address" />

          <div style={{ borderTop: '1px solid #ddd' }}></div>

          <FieldOption name="issue" label="Issue" />

          <div>
            <strong>{this.renderIssueInstruction()}</strong>
          </div>

          <FieldOption name="amount" label="Amount" cols={3} />
          <FieldOption name="date" label="Date" cols={3} inputProps={{
            shouldSlashPastDates: false
          }} />
          <FieldOption name="referenceNumber" label="Check / Ref #" cols={6} />

          <FieldOption name="invoiceNumber" label="Invoice #" cols={6} />

          <FieldOption name="contactName" label="Contact (if known)" cols={3} />
          <FieldOption name="contactPhone" label="Phone" cols={3} />
          <FieldOption name="contactEmail" label="Email Address" cols={6} />

          <FieldOption name="feePercentage" label="Fee Percentage" cols={3} />
          <FieldOption name="feeAmount" label="Fee Amount" cols={3} />

          <FieldOption name="paymentAmountLimit" label="Maximum Amount" cols={3} />

          <FieldOption name="comments" label="Comments" type="textarea" />

          <div style={{ borderTop: '1px solid #ddd' }}></div>

        </FormJSX>
        <Button onClick={() => this.onSubmit()} expand={true}>
          Submit
        </Button>
      </div>
    );
  }

  renderSubmitted() {
    return (
      <div>
        <HeaderText style={{ paddingBottom: 25 }}>
          Thank You
        </HeaderText>
        We're processing your request. We'll get back with you as soon as possible.
        <BlankSlate image={ErnieImage}
          imageAlt="Email Sent!" />
      </div>
    );
  }

  renderError() {
    if (!this.state.error) return null;
    return (
      <div className="vp-error" style={{ padding: '15px' }}>
        We're having some trouble processing your request.
        <br />
        Please try again later or contact&nbsp;<a href="mailto:paymentsupport@viewpost.com">Payment Support</a>
        for assistance.
      </div>
    );
  }

  render() {
    if (this.state.submitted) {
      return this.renderSubmitted();
    }
    return (
      <div>
        <HeaderText style={{ paddingBottom: 25 }}>
          Contact our Support Team
        </HeaderText>
        <div style={{ paddingBottom: 15, lineHeight: '1.5' }}>
          Please complete the form below and explain your payment issue.
          A support specialist will be in touch with you shortly to assist you with processing your payment.
        </div>
        {this.renderForm()}
        {this.renderError()}
      </div>
    );
  }
}
