/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, { useState, useEffect } from 'react';

// import moment from 'moment';

import DatePicker from '../DatePicker';
import TimePicker from '../TimePicker';


function getDate(value) {
  if (typeof value === 'string') {
    // simple safeguard against common mistake of passing date string
    return null;
  }
  return value;
}


/**
 * Input that returns time portion of a Date
 */
function DateTimePicker({
  value,
  onChange,
  dateProps,
  timeProps
}) {

  const [date, setDate] = useState(getDate(value));
  const [time, setTime] = useState(getDate(value));

  useEffect(() => {
    if (!date || !time) {
      onChange(null);
      return;
    }

    // splice together date based on date and time
    onChange(new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      time.getHours(),
      time.getMinutes(),
      time.getSeconds()
    ));
  }, [date, time]);

  return (
    <div className="component-date-time-picker">
      <div style={{ display: 'flex', gap: '5px'}}>
        <div>
          <DatePicker {...dateProps} value={date} onChange={setDate} />
        </div>
        <div>
          <TimePicker {...timeProps} value={time} onChange={setTime} />
        </div>
      </div>
    </div>
  );
}

export default DateTimePicker;