/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import ApiConfiguration from './apiConfiguration';

let apiInstance = null;

export const getApi = () => {
  if (!apiInstance) throw new Error('API not yet initialized! Make sure the app is calling initializeApi before rendering.'); // eslint-disable-line max-len

  return apiInstance;
};

/*
 * See ApiConfiguration to see how to configure the API.
 */
export const initializeApi = async (config) => {
  apiInstance = new ApiConfiguration(config);
};
