/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { shallowEqual }  from 'react-redux';

// This is an incredibly low-rent memoize function that, after careful deliberation, was the best
// thing to slap together to simply memoize functions that have the following properties:
//   * it only has one argument
//   * we don't mean recalculating if the argument changes, because we assume that is rare
//   * that shallow equality is necessary because of the argument type (read: an object)
// There are a lot of memoize libraries out there that are overkill to pull in right now, but
// moreover, they end up handling complex arguments in crazy ways (read: JSON.stringify). Since
// there are many cases were we keep reference equality, we can heavily rely on that for this
// memoization to work how you expect it.
export const memoizeOne = (func) => {
  let lastKey = null;
  let lastResult = null;

  return (arg) => {
    if (shallowEqual(arg, lastKey)) return lastResult;
    lastResult = func(arg);
    lastKey = arg;
    return lastResult;
  };
};
