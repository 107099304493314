/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

const ConnectionStatus = {
  Unconnected: 'Unconnected',
  Connecting: 'Connecting', // Avoid using this, prefer what type of connecting is happening below
  Connected: 'Connected',
  Connectable: 'Connectable', // Unconnected but on the network
  InviteConnecting: 'InviteConnecting', // Connecting via Invitation
  NetworkConnecting: 'NetworkConnecting' // Connecting via Connection Request
};

export default ConnectionStatus;