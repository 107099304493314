/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { Component } from 'react';
import { connect } from 'react-redux';
import { debounce } from 'lodash';
import { MOBILE_BREAK, TABLET_BREAK } from 'config/constants';
import { MapStateToProps, MapDispatchToProps } from './index.connect';

class DeviceInfoService extends Component {
  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  // Add our resize sensor.
  handleResize = debounce((isRecheck) => {
    const { isMobile, isTablet, onUpdateIsMobile, onUpdateIsTablet } = this.props;

    const newIsMobile = window.innerWidth < MOBILE_BREAK;
    const newIsTablet = window.innerWidth < TABLET_BREAK;

    if (isMobile !== newIsMobile) {
      onUpdateIsMobile(newIsMobile);
    }
    if (isTablet !== newIsTablet) {
      onUpdateIsTablet(newIsTablet);
    }

    if (!isRecheck) {
      // Fire off another check in a second because things will have settled by then
      setTimeout(() => this.handleResize(true), 1000);
    }
  }, 500)

  render() {
    // Does not provide context, should not be passed children
    return null;
  }
}

export default connect(MapStateToProps, MapDispatchToProps)(DeviceInfoService);
