/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import PageClick from 'react-page-click';
import RenderMobile from 'decorators/RenderMobile';
import ActionSheet from 'components/ActionSheet';
import MenuToggle from 'components/MenuToggle';
import classNames from 'classnames';
import { CSSTransition } from 'react-transition-group';
import './index.scss';

function flipPosition(style, negate) {
  if (style.left) {
    return {
      ...style,
      left: undefined,
      right: (negate ? -1 : 1) * (style.left || 0)
    };
  } if (style.right) {
    return {
      ...style,
      right: undefined,
      left: (negate ? -1 : 1) * (style.right || 0)
    };
  }

  return style;
}

class DropdownPanel extends Component {
  static propTypes = {
    style: PropTypes.object,
    openDirection: PropTypes.oneOf(['bottom-left', 'bottom-right', 'bottom-center',
      'top-left', 'top-right', 'top-center']),
    toggle: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.node
    ]),
    onToggle: PropTypes.func,
    showMenu: PropTypes.bool,
    hideCaret: PropTypes.bool,
    disabled: PropTypes.bool,
    menuStyle: PropTypes.object,
    menuClassName: PropTypes.string,
    actionSheetClassName: PropTypes.string,
    willAnimate: PropTypes.bool,
    floatLeft: PropTypes.bool,
    useMobileActionSheet: PropTypes.bool,
    onClose: PropTypes.func
  };

  static defaultProps = {
    openDirection: 'bottom-left',
    showMenu: false,
    menuStyle: {},
    disabled: false,
    willAnimate: false,
    useMobileActionSheet: true,
    isInline: true
  };

  constructor(props) {
    super(props);

    this.state = {
      showMenu: !props.disabled && props.showMenu
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.disabled !== this.props.disabled || nextProps.showMenu !== this.props.showMenu) {
      this.setState({
        showMenu: !nextProps.disabled && nextProps.showMenu
      });
    }
  }

  changeShow(event, willShow, fromPage) {
    if (event) event.stopPropagation();

    if (!willShow && this.props.onClose) {
      this.props.onClose();
    }

    if (fromPage && !willShow) {
      this.setState({
        showMenu: !this.props.disabled && willShow,
        preventToggle: true
      }, () => {
        // A necessary evil to account for the fact that page clicks
        // and toggle clicks are two separate events
        // (read: no stopPropagation) and the two fight each other
        // when the toggle is pressed to hide. This renables the toggle
        // to allow show after the events have processed
        setTimeout(() => {
          this.setState({
            preventToggle: false
          });
        }, 250);
        if (this.props.onToggle) {
          this.props.onToggle(this.state.showMenu);
        }
      });
    } else if (!fromPage && this.state.preventToggle) {
      this.setState({
        preventToggle: false
      });
    } else {
      this.setState({
        showMenu: !this.props.disabled && willShow,
        preventToggle: false
      }, () => {
        if (this.props.onToggle) {
          this.props.onToggle(this.state.showMenu);
        }
      });
    }
  }

  close = () => {
    this.changeShow(null, false);
  }

  renderMenu(isMobile) {
    if (this.props.alwaysShow || this.state.showMenu) {
      if (isMobile && this.props.useMobileActionSheet) {
        return (
          <ActionSheet
            isOpen={true}
            onOuterClick={this.close}
            className={this.props.actionSheetClassName}>
            {this.props.children}
          </ActionSheet>
        );
      }

      let style = {position: 'absolute',
        borderRadius: 2,
        marginTop: this.props.hideCaret ? 0 : -12, ...this.props.menuStyle};

      let caretStyle = {
        top: -20,
        right: 10
      };

      if (this.props.openDirection.includes('right')) {
        style = flipPosition(style, true);
        caretStyle = flipPosition(caretStyle);
      } else if (this.props.openDirection.includes('center')) {
        style.left = '50%';
        style.transform = 'translateX(-50%)';
        caretStyle.right = '50%';
        caretStyle.transform = 'translateX(50%)';
      }

      if (this.props.openDirection.includes('top')) {
        style.transform = `${style.transform ? style.transform : ''} translateY(-100%)`;
        style.marginTop = this.props.hideCaret ? 0 : -47;
        caretStyle.transform = `${caretStyle.transform ? caretStyle.transform : ''} rotate(180deg)`;
        caretStyle.bottom = caretStyle.top;
        caretStyle.top = 'auto';
      }

      let menuContents = (
        <div className={classNames('dropdownPanel', this.props.menuClassName)} style={style}>
          { this.props.hideCaret ? null : (
            <span className="dropdownPanelCaret"
              style={caretStyle} />
          ) }
          {this.props.children}
        </div>
      );

      return (
        <PageClick notify={e => this.changeShow(e, false, true)}>
          {this.props.willAnimate
            ? (
              <CSSTransition
                timeout={{ exit: 400, enter: 400 }}
                classNames="animate-shiftDownUp"
                leave={false}
                appear={true}
                component="span"
                className="animatedDropdownPanel"
              >
                {menuContents}
              </CSSTransition>
            ) : menuContents}
        </PageClick>
      );
    }

    return null;
  }

  renderContent(isMobile) {
    let style = {display: 'inline-block',
      position: 'relative', ...this.props.style || {}};

    let toggle;
    if (this.props.toggle) {
      if (React.isValidElement(this.props.toggle)) {
        toggle = this.props.toggle;
      } else if (typeof this.props.toggle === 'function') {
        toggle = (
          <this.props.toggle {...this.props.toggleProps}
            style={this.props.toggleStyle}
            active={this.state.showMenu}
            disabled={this.props.disabled} />
        );
      }
    } else if (this.props.label) {
      toggle = <MenuToggle style={this.props.toggleStyle} label={this.props.label} active={this.state.showMenu} />;
    }

    let float;
    if (this.props.floatLeft) {
      float = 'left';
    } else if (this.props.floatRight) {
      float = 'right';
    }

    if (this.props.onlyMenu) {
      return this.renderMenu(isMobile);
    }

    return (
      <div className={`viewstrap ${this.props.className || ''}`}
        style={{
          display: this.props.isInline ? 'inline-block' : 'block',
          position: 'relative',
          float: float
        }}
        onClick={event => event.stopPropagation()}>
        <div className="dropdown-toggle"
          onClick={event => this.changeShow(event, !this.state.showMenu, false)}
          style={{...style, width: '100%'}}>
          {toggle}
        </div>
        {this.renderMenu(isMobile)}
      </div>
    );
  }

  renderMobile() {
    return this.renderContent(true);
  }

  render() {
    return this.renderContent(false);
  }
}

export default RenderMobile(DropdownPanel);
