/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

/*
  Combines multiple mappers into one
*/
function compositeMapper(...mappers) {
  return (inputParams) => {
    let result = inputParams;
    mappers.forEach((mapper) => {
      result = mapper(result);
    });
    return result;
  };
}

export default compositeMapper;