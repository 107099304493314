/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import t from 'tcomb-validation';
import { isNil } from 'lodash';
import Messages from './phone.messages';

// use same regex used by platform
const PhoneRegex = new RegExp('^[0-9]{10}$');

const isValid = val => PhoneRegex.test(val);

export const stripFormatting = (value) => {
  if (isNil(value)) return null;

  return value.replace(/\D/g, '');
};

export const Phone = t.subtype(t.String, isValid, 'Phone');

Phone.getValidationErrorMessage = (value, path, context) => {
  if (!isValid(value) && context && context.intl) {
    return context.intl.formatMessage(Messages.Invalid);
  }

  return '';
};
