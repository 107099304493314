/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import moment from 'moment';

// enum used to identify releases
export const Releases = {
  R102: 'R102',
  R119: 'R119',
  R285: 'R285'
};

const ReleaseDates = {
  [Releases.R102]: moment('2016-08-30').toDate(),
  [Releases.R119]: moment('2017-05-02').toDate(),
  [Releases.R285]: moment('2020-12-03').toDate()
};

export function getReleaseDate(release) {

  if (ReleaseDates[release]) {
    return ReleaseDates[release];
  }

  console.error(`getReleaseDate was called for an invalid release.
    Actual: ${release}
    Expected: ${Object.keys(Releases).toString()}
    `);
}

export const didUserRegisterBefore = (release, identity) => {
  if (!release || !identity) return false;
  let releaseDate = getReleaseDate(release);
  if (!releaseDate) return false;
  return !moment(releaseDate).isBefore(identity.createdDateTime);
};
