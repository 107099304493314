/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, { memo, useState, useMemo } from 'react';
import classNames from 'classnames';
import Icon, { IconTypes } from 'components/Icon';
import HorizontalNav from 'components/HorizontalNav';
import MenuItem from 'components/Menu/MenuItem';
import SubMenu from 'components/Menu/SubMenu';
import { BaseSlidePanel } from 'components/SlidePanel';
import Paths, { findPath, hasPermissionForPath } from 'config/paths';
import useUserAvailableActions from 'hooks/identity/useUserAvailableActions';
import usePathname from 'hooks/location/usePathname';
import { memoizeOne } from 'utils/memoize';
import getMobileConfig from '../config/mobile';
import { isPathConfig } from '../config/utils';
import useNavigationParams from '../hooks/useNavigationParams';
import GreyNavBar from '../GreyNavBar';
import LogoLink from '../LogoLink';
import NavLabel, { PrimaryNavLabel, SecondaryNavLabel } from '../NavLabel';
import TasksMenuDropdown from '../TasksMenuDropdown';
import NavigationMenu from './NavigationMenu';
import './index.scss';

const getLastMenuPathId = (navConfig, menuPath) => {
  const result = menuPath.reduce(
    ({ match, config }, menuPathIndex) => {
      if (!config || match) return { match };

      if (!config.children[menuPathIndex]) {
        return {
          match: config.navLabel.id
        };
      }

      return { config: config.children[menuPathIndex] };
    },
    { config: navConfig }
  );

  if (result.match) return result.match;
  if ((result.config || {}).navLabel) return result.config.navLabel.id;

  return null;
};

const NavSpacer = <>&nbsp;&raquo;&nbsp;</>;

const MobileNav = memo(({
  activePathId,
  activeMenuPath,
  navigation,
  settingsMenu
}) => {
  const BreadcrumbNav = ({ onOpenNav }) => {
    const isInSettingsMenu = activeMenuPath[0] === 'settings';

    const parentMenuId = activeMenuPath.length ? getLastMenuPathId(
      isInSettingsMenu ? { children: settingsMenu } : { children: navigation },
      activeMenuPath.slice(1)
    ) : null;

    const activePathLabel = activePathId
      ? <SecondaryNavLabel id={activePathId} />
      : null;

    const parentPathLabel = parentMenuId
      ? <PrimaryNavLabel id={parentMenuId} />
      : null;

    const rootPathLabel = isInSettingsMenu
      ? <NavLabel id={Paths.Settings.id} />
      : null;

    if (!activePathLabel && !parentPathLabel && !rootPathLabel) {
      return <div style={{ paddingBottom: 12 }} />;
    }

    return (
      <GreyNavBar onClick={() => onOpenNav()} style={{marginBottom: 20}}>
        <div className="mobile-breadcrumb-nav">
          {rootPathLabel && !parentPathLabel ? rootPathLabel : null}
          {parentPathLabel ? (
            <>
              {rootPathLabel ? (
                <>
                  &hellip;
                  {NavSpacer}
                  {parentPathLabel}
                </>
              ) : parentPathLabel}
            </>
          ) : null}
          {(rootPathLabel || parentPathLabel) && activePathLabel ? NavSpacer : null}
          {activePathLabel}
        </div>
      </GreyNavBar>
    );
  };

  const { isPersonalUser, isPortalUser } = useNavigationParams();
  const [ { isMenuOpen, menuPath }, setMenuState ] = useState({ isMenuOpen: false });

  const variant = isPersonalUser ? 'slim' : null;

  const setMenuOpen = value => setMenuState({ isMenuOpen: value });

  return (
    <div>
      <HorizontalNav key="mobile" className="primary-nav">
        <MenuItem
          className={classNames(
            'logo',
            { 'vp-variant': variant === 'slim' }
          )}
        >
          <LogoLink
            isMobile={true}
            variant={variant === 'slim' ? 'vp' : 'ignoreHighlight'}
          />
        </MenuItem>
        {isPersonalUser && activePathId ? (
          <MenuItem>
            <div className="primary-nav-mobile-divider"></div>
            <span className="active-link-header">
              <NavLabel id={activePathId} />
            </span>
          </MenuItem>
        ) : null}
        <SubMenu style={{ float: 'right' }}>
          {isPersonalUser || isPortalUser ? null : (
            <MenuItem style={{ paddingRight: 15 }}>
              <TasksMenuDropdown/>
            </MenuItem>
          )}
          <BaseSlidePanel
            className="mobileNavPanel"
            isOpen={isMenuOpen}
            onClose={() => setMenuOpen(false)}
            onTogglePanel={() => setMenuOpen(!isMenuOpen)}
            toggle={(
              <Icon
                type={IconTypes.Hamburger}
                size={30}
                className="navSettingsIcon"
              />
            )}
          >
            <NavigationMenu
              onClose={() => setMenuOpen(false)}
              menuPath={menuPath || []}
              navigation={navigation}
              setMenuPath={v => setMenuState({ isMenuOpen: true, menuPath: v })}
              settingsMenu={settingsMenu}
            />
          </BaseSlidePanel>
        </SubMenu>
      </HorizontalNav>
      {variant === 'slim' ? null : (
        <BreadcrumbNav
          onOpenNav={() => setMenuState({ isMenuOpen: true, menuPath: activeMenuPath})}
        />
      )}
    </div>
  );
});

const filterNavigation = memoizeOne(({
  availableActions,
  Navigation,
  SettingsMenu
}) => {
  const filterReduce = (val, config) => {
    if (!config) return val;

    if (isPathConfig(config)) {
      return hasPermissionForPath(config, availableActions)
        ? [ ...val, config ]
        : val;
    }

    const { fallbackPath, path, children } = config;

    if (isPathConfig(path)) {
      return hasPermissionForPath(path, availableActions)
        ? [ ...val, config ]
        : val;
    }

    const filteredChildren = (children || []).reduce(
      filterReduce,
      []
    );

    if (filteredChildren.length) {
      return [
        ...val,
        {
          ...config,
          children: filteredChildren
        }
      ];
    }

    if (isPathConfig(fallbackPath)
      && hasPermissionForPath(fallbackPath, availableActions)) {
      return [
        ...val,
        { path: fallbackPath }
      ];
    }

    return val;
  };

  return {
    Navigation: Navigation.reduce(
      filterReduce,
      []
    ),
    SettingsMenu: SettingsMenu.reduce(
      filterReduce,
      []
    )
  };
});

const getMenuPath = ({
  Navigation,
  pathname,
  SettingsMenu
}) => {
  const checkMatch = config => config.match(pathname, { alsoMatchChildren: true });

  const matchPathReduce = (val, config, index) => {
    if (val) return val;

    if (isPathConfig(config)) return null;

    const {
      matchOn,
      matchOnChildren,
      children
    } = config;

    let matched = false;

    if (matchOn) {
      matched = checkMatch(matchOn);
    } else if (matchOnChildren) {
      matched = children.some(checkMatch);
    }

    if (matched) {
      return children
        ? [ index, ...(children.reduce(matchPathReduce, null) || []) ]
        : [ index ];
    }

    return null;
  };

  const navigationMatch = Navigation.reduce(matchPathReduce, null);

  if ((navigationMatch || []).length) return ['primary', ...navigationMatch];

  const settingsMenuMatch = SettingsMenu.reduce(matchPathReduce, null);

  if ((settingsMenuMatch || []).length) return ['settings', ...settingsMenuMatch];

  return checkMatch(Paths.Settings) ? ['settings'] : [];
};

const MobileNavCalculator = () => {
  const {
    accountingPackage,
    isPersonalUser,
    isPortalUser,
    isVccEnabled
  } = useNavigationParams();

  const {
    Navigation: UnfilteredNavigation,
    SettingsMenu: UnfilteredSettingsMenu
  } = useMemo(
    () => getMobileConfig({
      accountingPackage,
      isPersonalUser,
      isPortalUser,
      isVccEnabled
    }),
    [ accountingPackage, isPersonalUser, isPortalUser, isVccEnabled ]
  );

  const availableActions = useUserAvailableActions();

  const {
    Navigation,
    SettingsMenu
  } = useMemo(
    () => filterNavigation({
      availableActions,
      Navigation: UnfilteredNavigation,
      SettingsMenu: UnfilteredSettingsMenu
    }),
    [ availableActions, UnfilteredNavigation, UnfilteredSettingsMenu ]
  );

  const pathname = usePathname();

  const activePath = findPath(pathname, { onlyBestMatch: true });

  const activeMenuPath = getMenuPath({
    Navigation,
    pathname,
    SettingsMenu
  });

  return (
    <MobileNav
      activePathId={(activePath || {}).id}
      activeMenuPath={activeMenuPath}
      navigation={Navigation}
      settingsMenu={SettingsMenu}
    />
  );
};

export default MobileNavCalculator;
