/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { uniqueId } from 'lodash';
import {
  API_ACTION_PREFIX,
  API_ACTION_SUFFIX_FETCH,
  API_ACTION_SUFFIX_SUCCESS,
  API_ACTION_SUFFIX_FAIL
} from './constants';
import createAction from './createAction';

const createDefaultConfigName = ({ method, url }) => `${uniqueId()}-${method}-${url}`;

/*
 * Creates a single endpoint action creator. Unless set, it will generate a unique name for the
 * endpoint (vs the more common createApiActions case where the name comes from the map of the name
 * to the config).
 *
 * Use this to create endpoints for a single export. If you are super interested in setting a name
 * (please don't be unless its to support legacy behavior), you can pass in a name field as part of
 * the config
 */
export const createApiAction = (config, overrideName) => {
  if (overrideName) {
    config.name = overrideName;
  }

  if (!config.name) {
    config.name = createDefaultConfigName(config);
  }

  const actionTypes = {
    fetch: `${API_ACTION_PREFIX}${config.name}${API_ACTION_SUFFIX_FETCH}`,
    success: `${API_ACTION_PREFIX}${config.name}${API_ACTION_SUFFIX_SUCCESS}`,
    fail: `${API_ACTION_PREFIX}${config.name}${API_ACTION_SUFFIX_FAIL}`
  };

  // Generate an action creator to issue the api call
  return createAction(config, actionTypes);
};

const createApiActions = config => Object.keys(config)
  .reduce((val, key) => ({
    ...val,
    // Force the API Config to have the name of the key
    [key]: createApiAction(config[key], key)
  }), {});

export default createApiActions;
