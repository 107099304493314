/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Icon, { IconTypes } from 'components/Icon';
import TextBox from 'components/Form/Inputs/TextBox';
import LoadingSpinner from 'components/LoadingSpinner';

import { stopPropagation } from 'config/utils/click';

import useFuncRef from 'hooks/react/useFuncRef';
import usePropRef from 'hooks/react/usePropRef';

import './index.scss';

const SearchBox = ({
  iconPlacement,
  isLoading,
  onBlur: onBlurProp,
  onCancel: onCancelProp,
  onChange: onChangeProp,
  onFocus: onFocusProp,
  searchClassName: searchClassNameProp,
  showCancel,
  style,
  value,
  variant,
  ...props
}) => {
  // Hooks
  const [ searchPhrase, setSearchPhrase ] = useState(value);
  const [ isFocused, setIsFocused ] = useState(false);

  const onBlurPropRef = usePropRef(onBlurProp);
  const onCancelPropRef = usePropRef(onCancelProp);
  const onChangePropRef = usePropRef(onChangeProp);
  const onFocusPropRef = usePropRef(onFocusProp);

  const { current: onChange } = useFuncRef(
    () => (newValue) => {
      setSearchPhrase(newValue);
      onChangePropRef.current?.(newValue);
    }
  );

  const { current: onCancel } = useFuncRef(
    () => stopPropagation(() => {
      setSearchPhrase(null);

      // Put the onus of doing any appropriate onChange in onCancel
      if (onCancelPropRef.current) {
        onCancelPropRef.current();
      } else {
        onChangePropRef.current?.(null);
      }
    })
  );

  const { current: onBlur } = useFuncRef(
    () => () => {
      onBlurPropRef.current?.();
      setIsFocused(false);
    }
  );

  const { current: onFocus } = useFuncRef(
    () => () => {
      onFocusPropRef.current?.();
      setIsFocused(true);
    }
  );

  useEffect(
    () => {
      if (!value && searchPhrase) {
        setSearchPhrase(value);
      }
    },
    [ value ]
  );

  // Render
  let iconProps = {};
  let icon = <Icon type={IconTypes.Search} className="color-secondary" />;
  if (isLoading) {
    icon = (
      <div
        style={{
          width: '32px',
          height: '34px',
          left: '10px',
          top: '2px'
        }}
      >
        <LoadingSpinner />
      </div>
    );
  }

  if (iconPlacement === 'left') {
    iconProps.prependIcon = icon;
  } else if (iconPlacement == null || iconPlacement === 'right') {
    iconProps.appendIcon = icon;
  }

  if (showCancel || (!!searchPhrase && showCancel !== false)) {
    iconProps.appendIcon = (
      <Icon
        type={IconTypes.Exit}
        onClick={onCancel}
        className="color-secondary"
      />
    );
  }

  let searchClassName = searchClassNameProp;

  if (variant === 'collapsible') {
    searchClassName = classNames(
      'collapsible-search-box-component',
      { 'is-collapsed': !isFocused && !value },
      searchClassNameProp
    );
  }

  return (
    <TextBox
      {...props}
      searchClassName={searchClassName}
      value={searchPhrase}
      onBlur={onBlur}
      onChange={onChange}
      onFocus={onFocus}
      {...iconProps}
    />
  );
};

SearchBox.propTypes = {
  iconPlacement: PropTypes.oneOf([ 'left', 'right', 'hidden' ])
};

export default SearchBox;