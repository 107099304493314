/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './Moon.scss';

const sizeToStyle = (height, additionalStyle) => ({
  height,
  width: height,
  ...(additionalStyle || {})
});

/**
 * Ported the Moon loading indicator from:
 * https://github.com/yuanyan/halogen
 */
const MoonLoadingIndicator = ({
  className,
  style,
  height,
  inline
}) => {
  const moonHeight = height / 7;
  let parentStyle = {};
  if (inline) {
    parentStyle = {
      display: 'inline-block'
    };
  }

  if (style) parentStyle = { ...parentStyle, ...style };

  return (
    <div className={classnames('moon-loading-indicator', className)} style={parentStyle}>
      <div className="moon-ball-animation" style={{ position: 'relative' }}>
        <div className="moon-ball moon-ball-animation moon-ball-style"
          style={sizeToStyle(moonHeight, { top: height / 2 - moonHeight / 2 })} />
        <div className="moon-ball-style moon-ball-background"
          style={sizeToStyle(height, { borderWidth: moonHeight })}/>
      </div>
    </div>
  );
};

MoonLoadingIndicator.propTypes = {
  /** The height, in pixels, of the loading indicator */
  height: PropTypes.number,
  /** If the loading indicator should have an inline style applied to it */
  inline: PropTypes.bool
};

MoonLoadingIndicator.defaultProps = {
  height: 70
};

export default MoonLoadingIndicator;

export const MessageMoonLoadingIndicator = ({
  children,
  style
}) => (
  <div className="loading-indicator" style={style}>
    <div className="moon-indicator">
      <MoonLoadingIndicator inline={true}/>
    </div>
    <div className="message">
      {children}
    </div>
  </div>
);
