/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { getApi } from 'api/core/init';
import trackPageViewed from 'api/telemetry/trackPageViewed';

import useQueryParams from 'hooks/location/useQueryParams';

const TrackEmailIdService = () => {
  // Hooks
  const dispatch = useDispatch();
  const { emailId, linkContext } = useQueryParams(['emailId', 'linkContext']);

  useEffect(
    () => {
      if (emailId) {
        getApi().setParam('emailId', emailId);
        dispatch(trackPageViewed({
          emailId,
          linkContext
        }));
      } else {
        getApi().setParam('emailId', null);
      }
    },
    [ emailId ]
  );

  // Render
  return <></>;
};

export default TrackEmailIdService;