/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, { useEffect, useMemo, useState } from 'react';
import { uniqueId } from 'lodash';
import { useDispatch } from 'react-redux';

import PathActions from 'actions/paths';

import getIdentity from 'api/session/getIdentity';
import updateSession from 'api/session/updateSession';

import Address from 'components/Address';
import BodyText from 'components/BodyText';
import Button from 'components/Button';
import CompanyLogo from 'components/Network/CompanyLogo';
import Truncate from 'components/Truncate';

import CommonMessages from 'config/messages/common';

import createExecuteApiResultHooks from 'hooks/apiResult/createExecuteApiResultHooks';
import useStatusIndicator from 'hooks/apiResult/useStatusIndicator';
import GetSwitchCompaniesHooks from 'hooks/identity/GetSwitchCompaniesHooks';
import useIdentity from 'hooks/identity/useIdentity';
import useChangeQueryParams from 'hooks/location/useChangeQueryParams';
import useQueryParams from 'hooks/location/useQueryParams';

import SadErnie from 'images/ernie/sad.png';

import BlueHeaderOnWhiteLayout from 'layouts/BlueHeaderOnWhite';

import Messages from './index.messages';

const UpdateSessionHooks = createExecuteApiResultHooks({
  endpoint: updateSession
});

const SwitchCompanyPrompt = ({
  destinationCompanyId,
  onComplete
}) => {
  // Hooks
  const dispatch = useDispatch();
  const changeQueryParams = useChangeQueryParams();
  const { companyId: currentCompanyId } = useIdentity();

  GetSwitchCompaniesHooks.useProvideParams({});

  const switchCompanies = GetSwitchCompaniesHooks.useCompanies();

  const executeUpdateSession = UpdateSessionHooks.useExecuteRequest();
  const isSwitchingCompany = UpdateSessionHooks.useIsLoading();

  const [
    onIgnore,
    onSwitch
  ] = useMemo(
    () => {
      const onClick = async (willSwitch) => {
        if (!willSwitch) {
          dispatch(PathActions.Dashboard());
          onComplete();
          return;
        }

        await executeUpdateSession(
          {
            nonce: uniqueId(),
            currentCompanyProfileId: destinationCompanyId
          },
          {
            assertSuccess: true
          }
        );

        // Wipe it from the URL now that we've established that as the context
        changeQueryParams({ destinationCompanyId: null });

        await dispatch(getIdentity());

        onComplete();
      };

      return [
        () => onClick(false),
        () => onClick(true)
      ];
    }
  );

  const currentCompany = switchCompanies?.find(({ id }) => id === currentCompanyId);
  const targetCompany = switchCompanies?.find(({ id }) => id === destinationCompanyId);

  const StatusIndicator = useStatusIndicator([ GetSwitchCompaniesHooks ]);

  // Render
  if (StatusIndicator) return <StatusIndicator />;

  if (!targetCompany) {
    return (
      <div className="container-pad-small">
        <div className="pad-double">
          <h1 className="text-center">
            <Messages.UnknownCompanyTitle.Message/>
          </h1>
          <div className="text-center pad-20-y">
            <img src={SadErnie} />
          </div>
          <BodyText>
            <Messages.UnknownCompanyInstructions.Message />
          </BodyText>
          <div className="text-center pad-20-top">
            <Button
              onClick={onIgnore}
              variant="primary"
              type="button"
            >
              <CommonMessages.Continue.Message />
            </Button>
          </div>
        </div>
      </div>
    );
  }

  const renderTargetCompany = () => {
    let defaultTag = null;

    const {
      address,
      companyName: targetCompanyName,
      dbaCompanyName: targetDbaCompanyName,
      id: targetCompanyId,
      isActiveUser,
      isDefault
    } = targetCompany;

    if (!isActiveUser) {
      return null;
    }

    // show default tag
    if (isDefault) {
      defaultTag = (
        <span className="vp-tag secondary">
          <Messages.DefaultCompany.Message />
        </span>
      );
    }

    return (
      <div className="vp-alert secondary">
        <div className="col-3">
          <CompanyLogo id={`1${targetCompanyId}`} width={120} height={72}/>
        </div>
        <div className="vp-content col-6 pad-12-left">
          <div>
            <strong>{targetCompanyName}</strong>
              &nbsp; {defaultTag}
          </div>
          {targetDbaCompanyName && targetCompanyName !== targetDbaCompanyName ? (
            <div>
              <Messages.DoingBusinessAs.Message
                companyName={<Truncate value={targetDbaCompanyName} maxLength={40}/>}
              />
            </div>
          ) : null}
          <Address
            {...address}
            linesWithoutAddressee={[
              address.line1,
              address.line2,
              address.line3,
              address.line4
            ].filter(x => x)}
          />
        </div>
      </div>
    );
  };

  const companyNameWithDba = currentCompany.dbaCompanyName
    && currentCompany.dbaCompanyName !== currentCompany.companyName
    ? (
      <Messages.DbaWithCompanyName.Message
        companyName={currentCompany.companyName}
        dbaCompanyName={currentCompany.dbaCompanyName}
      />
    ) : currentCompany.companyName;

  const targetCompanyNameWithDba = targetCompany.dbaCompanyName
    && targetCompany.dbaCompanyName !== targetCompany.companyName
    ? (
      <Messages.DbaWithCompanyName.Message
        companyName={targetCompany.companyName}
        dbaCompanyName={targetCompany.dbaCompanyName}
      />
    ) : targetCompany.companyName;

  return (
    <div className="container-pad-small">
      <div className="pad-double">
        <h1 className="text-center">
          <Messages.TitleSwitchCompanyPrompt.Message/>
        </h1>
        <BodyText>
          <Messages.SwitchCompanyPromptWarning.Message
            currentCompany={companyNameWithDba}
            targetCompany={targetCompanyNameWithDba}
          />
        </BodyText>
        {renderTargetCompany()}
        <div className="text-center">
          <div>
            <Button
              isDisabled={isSwitchingCompany}
              onClick={onIgnore}
              variant="secondary"
              type="button"
            >
              <Messages.NoSwitch.Message />
            </Button>

            <Button
              isDisabled={isSwitchingCompany}
              onClick={onSwitch}
              variant="primary"
              type="button"
            >
              <Messages.YesSwitch.Message />
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

const SwitchCompanyWorkflowStep = ({
  active,
  updateState
}) => {
  // Hooks
  let {
    destinationCompanyId: originalDestinationCompanyId
  } = useQueryParams([
    'destinationCompanyId'
  ]);

  if (originalDestinationCompanyId?.[0] && typeof originalDestinationCompanyId !== 'string') {
    // HACK to handle some jacked up email url cases providing
    // DestinationCompanyId[0]=x&DestinationCompanyId[1]=x&
    originalDestinationCompanyId = originalDestinationCompanyId[0];
  }

  // Capturing the destinationCompanyId so we don't lose it when the URL changes
  const [ destinationCompanyId ] = useState(originalDestinationCompanyId);

  const { companyId } = useIdentity();

  const willShow = !!destinationCompanyId && companyId !== destinationCompanyId;

  useEffect(
    () => {
      updateState(!willShow ? 'skipped' : 'loaded');
    },
    [ willShow ]
  );

  // Render
  if (!active) return <></>;

  return (
    <BlueHeaderOnWhiteLayout
      contentWidth={650}
      hideLoginLink={true}
    >
      <div className="viewstrap">
        <SwitchCompanyPrompt
          destinationCompanyId={destinationCompanyId}
          onComplete={() => updateState('completed')}
        />
      </div>
    </BlueHeaderOnWhiteLayout>
  );
};

export default SwitchCompanyWorkflowStep;
