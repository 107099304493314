/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React from 'react';
import SearchBox from 'components/Form/Inputs/SearchBox';
import classNames from 'classnames';
import createDropdown from '../createDropdown';
import { ListDropdownPanel } from '../createListDropdown';
import './index.scss';

/**
 * The innards for a dropdown that applies our dropdown search and list style
 */
export const SearchDropdownPanel = ({
  header,
  activeId,
  isMobile,
  isLoading,
  searchPlaceholder,
  onChange,
  onSearch,
  children,
  className,
  closeMenu,
  closeMenuOnClick
}) => {
  const searchBox = (
    <SearchBox
      searchClassName="dropdown-search-select"
      iconPlacement="left"
      autoFocus={true}
      placeholder={searchPlaceholder}
      onChange={onSearch} />
  );

  const searchResults = (
    <ListDropdownPanel activeId={activeId}
      closeMenu={closeMenu}
      closeMenuOnClick={closeMenuOnClick}>
      {children}
    </ListDropdownPanel>
  );

  if (isMobile) {
    return (
      <div className={classNames('dropdown-search-mobile', className)}>
        {searchResults}
        {searchBox}
      </div>
    );
  }

  return (
    <div className={classNames('dropdown-search-popover', className)}>
      {searchBox}
      {searchResults}
    </div>
  );
};

const createSearchDropdown = (
  ToggleComponent,
  dropdownParams = {}
) => createDropdown(
  ToggleComponent, {
    ...dropdownParams,
    childComponent: SearchDropdownPanel,
    childPropNames: [
      'activeId',
      'searchPlaceholder',
      'isLoading',
      'onChange',
      'onSearch',
      'closeMenu',
      'closeMenuOnClick'
    ]
  }
);

export default createSearchDropdown;
