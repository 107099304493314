/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { isEqual } from 'lodash';
import { BaseProblemErnie } from 'components/ProblemErnie';
import logError from 'services/ErrorService';

const createCatchAppError = (resetHasError) => {
  class CatchAppError extends Component {
    constructor(props) {
      super(props);
      this.state = { hasError: false };
    }

    static getDerivedStateFromError = error => ({ hasError: true })

    componentDidCatch(error, info) {
      const { context } = this.props;
      const { message } = error;
      logError(`An app error was caught - ${message}`, { error, ...info, context: context || 'Unknown' });
    }

    componentDidUpdate(prevProps) {
      const { hasError } = this.state;

      // Reset the isError state when directed
      if (resetHasError && hasError && resetHasError(this.props, prevProps)) {
        // This is terrible, but with the conditional, we should be in good shape
        this.setState({ hasError: false }); // eslint-disable-line react/no-did-update-set-state
      }
    }

    render() {
      const { children, ignoreReactIntl, isWholePage } = this.props;
      const { hasError } = this.state;

      if (hasError) {
        return (
          <BaseProblemErnie
            centerInPage={isWholePage}
            ignoreReactIntl={ignoreReactIntl}
          />
        );
      }

      return children;
    }
  }

  return CatchAppError;
};

// Will reset hasError when changing pages
export const PageCatchAppError = withRouter(createCatchAppError(
  ({ location }, { location: prevLocation }) => !isEqual(location, prevLocation)
));

export default createCatchAppError();
