/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */
/* eslint max-len:0 */

import { toMessages } from 'config/messages';

export default toMessages('FreeTrialNotificationsModal', {
  ContinueWithTrial: 'Continue With Trial',
  Subscribe: 'Subscribe',
  CreateInvoice: 'Create Invoice',
  SendPayment: 'Send a Payment'
});

export const IntroMessages = toMessages('FreeTrialNotificationsModal.Intro', {
  Header: '{days} Days Remaining in Your Free Trial',
  Subheader: 'Make the most of your free trial.',
  Instructions: 'Enjoy full access to Viewpost invoicing and payments features through {date}. Create invoices and receive or send electronic payments to or from your bank account. Get started now.'
});

export const NoAction1Messages = toMessages('FreeTrialNotificationsModal.NoAction1', {
  Header: 'Free Trial Expires {date}',
  Subheader: 'You have {days} days left in your free trial.',
  Instructions: 'There’s still time to try using Viewpost with more of your customers and vendors. Start now by adding a new company to your network, creating an invoice, or sending a payment.'
});

export const NoAction2Messages = toMessages('FreeTrialNotificationsModal.NoAction2', {
  Header: 'Free Trial Expires {date}',
  Subheader: 'You have {days} days left in your free trial.',
  Instructions: 'Your trial period ends soon. Never fear, you can subscribe to a Corporate Account to continue using all Viewpost features. If you do nothing, your account will revert to a Portal Account, which allows you to transact exclusively with companies that invite you to connect.'
});

export const ActionTaken1Messages = toMessages('FreeTrialNotificationsModal.ActionTaken1', {
  Header: 'Free Trial Expires {date}',
  Subheader: 'We hope you’re enjoying your free trial.',
  Instructions: 'It looks like you’ve had a chance to try out some Viewpost features. If you need any help, please contact our support team. If you like what you see, subscribe to a Corporate account to retain all Viewpost features after your trial period ends.'
});

export const ActionTaken2Messages = toMessages('FreeTrialNotificationsModal.ActionTaken2', {
  Header: 'Free Trial Expires {date}',
  Subheader: 'You have {days} days left in your free trial.',
  Instructions: 'You can retain all Viewpost features that you’ve been using by subscribing to a Corporate Account. If you do nothing, your account will revert to a standard Portal Account, which allows you to transact exclusively with companies that invite you to connect.'
});

export const ExpiredMessages = toMessages('FreeTrialNotificationsModal.Expired', {
  Header: 'Account Update',
  Subheader: 'We hope you enjoyed your free trial.',
  Instructions: 'Your 60-day Corporate Account trial has ended. Your service has been reverted to a standard {portalType} Portal, which enables you to transact exclusively with companies that invite you to connect. If you’d like to retain full functionality in your account, you can subscribe at any time.'
});
