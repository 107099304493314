/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ActionSheet from 'components/ActionSheet';
import classNames from 'classnames';
import './index.scss';

export const BaseSlidePanel = ({
  children,
  className,
  isOpen,
  largePanel,
  onClose,
  onTogglePanel,
  openFromDirection,
  toggle
}) => (
  <div
    className={classNames(
      'slide-panel-component',
      className
    )}
    style={{display: 'inline-block', position: 'relative'}}>
    <div onClick={onTogglePanel}>
      {toggle}
    </div>
    {isOpen ? <div className="silkscreen"></div> : null}
    <ActionSheet
      isOpen={isOpen}
      onOuterClick={onClose}
      className={classNames(
        'slide-panel-component-side-panel',
        openFromDirection || 'right',
        { 'large-slide-panel': largePanel }
      )}
    >
      {children}
    </ActionSheet>
  </div>
);

export default class SlidePanel extends Component {
  static propTypes = {
    /** This should be a React node that is rendered as the toggle button */
    toggle: PropTypes.node.isRequired,
    className: PropTypes.string,
    openFromDirection: PropTypes.oneOf(['top', 'bottom', 'left', 'right'])
  };

  constructor(props) {
    super(props);

    this.state = {
      showPanel: false
    };
  }

  open = () => {
    this.setState({
      showPanel: true
    });
  }

  close = () => {
    this.setState({
      showPanel: false
    });
  }

  togglePanel = () => {
    this.setState({
      showPanel: !this.state.showPanel
    });
  }

  render() {
    return (
      <BaseSlidePanel
        {...this.props}
        isOpen={this.state.showPanel}
        onClose={this.close}
        onTogglePanel={this.togglePanel}
      />
    );
  }
}
