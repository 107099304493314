/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { isSuccessAction } from 'api/core/utils';
import { newState } from './utils';

export default function AddressBookImport(state = {}, action) {
  if (isSuccessAction(action, 'getAddressBookProfiles')) {
    return newState(state, {
      profiles: action.payload.profiles
    });
  }

  if (isSuccessAction(action, 'loadImports')) {
    return newState(state, {
      addressBookImports: action.payload.addressBook
    });
  }

  return state;
}
