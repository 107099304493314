/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import t from 'tcomb-validation';
import { defineSchema } from 'schemas';

export const AppCapabilitySettings = defineSchema(
  {
    apiCapabilities: t.list(t.String)
  },
  // This is referenced by getApplicationSetting
  'AppCapabilitySettings'
);

export const AppPlaidSettings = defineSchema(
  {
    publicKey: t.String,
    environment: t.String
  },
  // This is referenced by getApplicationSetting
  'AppPlaidSettings'
);

export const AppStripeSettings = defineSchema(
  {
    publishableKey: t.String,
    clientId: t.String
  },
  // This is referenced by getApplicationSetting
  'AppStripeSettings'
);

export const AppReCaptchaSettings = defineSchema(
  {
    siteKey: t.String
  },
  // This is referenced by getApplicationSetting
  'AppReCaptchaSettings'
);

export const AppAttachmentSettings = defineSchema(
  {
    maxFileSizeInBytes: t.Number,
    allowedExtensions: t.list(t.String)
  },
  // This is referenced by getApplicationSetting
  'AppAttachmentSettings'
);

export const AppEnvironmentSettings = defineSchema(
  {
    isExternal: t.Bool
  },
  // This is referenced by getApplicationSetting
  'AppEnvironmentSettings'
);
