/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, { useContext } from 'react';
import classnames from 'classnames';
import AnalyticsContext from 'components/AnalyticsCategory/context';
import RouteLink from 'components/Link/RouteLink';
import InfoTooltip from 'components/InfoTooltip';

const ListDropdownOption = ({
  children,
  isActive,
  isDisabled,
  isMobile,
  onClick,
  onMouseEnter,
  onMouseLeave,
  link,
  linkProps,
  path,
  type,
  analyticsId,
  rightContent,
  help
}) => {
  const {
    category: analyticsCategory
  } = useContext(AnalyticsContext) || {};

  const getId = () => {
    if (!analyticsId) return null;

    if (analyticsCategory) {
      return `${analyticsCategory}.${analyticsId}`;
    }

    return analyticsId;
  };

  let content = children;

  if (link) {
    // TODO: Move off of route link
    content = (
      <RouteLink linkRef={link} {...(linkProps || {})}>
        {children}
      </RouteLink>
    );
  } else if (path) {
    // This is me pulling a sneaky, you can technically use this to
    // wrap an option with anything. But this keeps it open to both
    // PathLinks and LogicalPathLinks.
    if (React.isValidElement(path)) {
      content = React.cloneElement(path, {}, children);
    }
  }

  const itemType = type || 'option';
  const helpTooltip = help && isMobile ? (
    <InfoTooltip placement="right" style={{padding: 8}}
      iconStyle={{margin: 0, height: 18, width: 18}}>
      {help}
    </InfoTooltip>
  ) : null;

  return (
    <div
      id={getId()}
      className={classnames(
        {'dropdown-list-item-mobile': isMobile},
        {'dropdown-list-item-popover': !isMobile},
        {'active': isActive},
        `dropdown-list-item-${itemType}`,
        {'disabled': isDisabled}
      )}
      onClick={isDisabled ? null : onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}>
      {content}
      {helpTooltip || rightContent ? (
        <span className="dropdown-list-item-right">
          {helpTooltip || rightContent}
        </span>
      ) : null}
    </div>
  );
};

export default ListDropdownOption;
