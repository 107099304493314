/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */
/* eslint max-len:0 */

import { defineMessages } from 'react-intl';

export default defineMessages({
  Message: {
    id: 'FeatureBranchWarning.Message',
    defaultMessage: 'You are on feature branch \'{branch}\'.'
  },
  Button: {
    id: 'FeatureBranchWarning.Button',
    defaultMessage: 'Reset'
  }
});
