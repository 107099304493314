/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Button from 'components/Button';
import Icon, { IconTypes } from 'components/Icon';
import Badge from 'components/Badge';
import classNames from 'classnames';
import './index.scss';

export default class IconNotification extends Component {
  static propTypes = {
    iconType: PropTypes.string,
    count: PropTypes.number,
    onClick: PropTypes.func
  };

  static defaultProps = {
    iconType: IconTypes.PaperClip,
    count: 0
  };

  renderBadge() {
    return (
      <Badge type="primary">
        {this.props.count}
      </Badge>
    );
  }

  render() {
    let badge;
    if (this.props.count > 0) {
      badge = this.renderBadge();
    }

    const {className, ...props} = this.props;

    return (
      <Button
        className={classNames('small', 'utility', 'notification-overflow', className)}
        {...props}>
        <Icon type={this.props.iconType} />
        {this.props.children}
        {badge}
      </Button>
    );
  }
}
