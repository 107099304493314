/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, { useMemo, useRef, useState } from 'react';

import { submitElectronicPaymentInterest } from 'api/onboarding/paymentOptions';

import ProfessionalHeaderOnWhiteLayout from 'layouts/ProfessionalHeaderOnWhiteLayout';

import FormJSX from 'components/Form/FormJSX';
import FieldOption from 'components/Form/FieldOption';

import Button from 'components/Button';

import createExecuteApiResultHooks from 'hooks/apiResult/createExecuteApiResultHooks';
import useIsMobile from 'hooks/browser/useIsMobile';

import t from 'tcomb-validation';
import { Phone } from 'schemas/common/phone';
import { createMaxLengthStringType } from 'schemas/common/string';

import Messages from './index.messages';

const FormSchema = t.struct({
  firstName: createMaxLengthStringType(50),
  lastName: createMaxLengthStringType(50),
  title: createMaxLengthStringType(50),
  phoneNumber: Phone,
  phoneExtension: t.maybe(createMaxLengthStringType(10))
});

const SubmitElectronicPaymentInterestHooks = createExecuteApiResultHooks({
  endpoint: submitElectronicPaymentInterest
});

const ElectronicPaymentInterest = ({
  secureTokenContext
}) => {
  // Hooks
  const isMobile = useIsMobile();
  const formRef = useRef();
  const [ formState, setFormState ] = useState({});
  const [ submitted, setSubmitted ] = useState(false);

  const submitInterest = SubmitElectronicPaymentInterestHooks.useExecuteRequest();
  const isLoading = SubmitElectronicPaymentInterestHooks.useIsLoading();

  const secureToken = secureTokenContext.token;
  const onSubmit = useMemo(
    () => async (e) => {
      if (e) {
        e.preventDefault();
      }

      const value = formRef.current.getValue();
      if (value) {
        const [ success ] = await submitInterest({ ...value, secureToken });

        if (success) setSubmitted(true);
      }
    },
    [ formRef, secureToken, setSubmitted, submitInterest ]
  );

  const relationship = secureTokenContext.data.relationships.length
    && secureTokenContext.data.relationships[0];

  // Render
  const content = !submitted ? (
    <>
      <div style={{border: '1px solid #ddd', borderRadius: '2px', marginBottom: '30px'}}>
        <div style={{
          display: 'inline-block',
          verticalAlign: 'middle',
          marginRight: '30px',
          borderRight: ' 1px solid #ddd',
          padding: '8px'
        }}>
          <img src={relationship.customerLogoUrl} width="120" height="72" />
        </div>
        <div style={{ display: 'inline-block', verticalAlign: 'middle', color: '#666'}}>
          <strong style={{fontSize: '16px'}}>
            Submit this form and a payment specialist will contact you.
          </strong>
          <br/>
          Viewpost is an authorized payments partner of&nbsp;{relationship.customerName}.
        </div>
      </div>
      <form style={{marginBottom: '80px'}}>
        <FormJSX
          ref={formRef}
          modelType={FormSchema}
          value={formState}
          onChange={v => setFormState(v)}>

          <FieldOption name="firstName" label="First Name" cols={6} />
          <FieldOption name="lastName" label="Last Name" cols={6} />
          <FieldOption name="title" label="Title / Company Role" cols={6}
            placeholder="e.g. AR Department, CFO, Customer Support, etc" />
          <FieldOption name="phoneNumber" label="Phone Number" cols={4} />
          <FieldOption name="phoneExtension" label="Ext" cols={2} />
        </FormJSX>

        <Button
          type="submit"
          expand={true}
          onClick={onSubmit}
          isDisabled={isLoading}
          isProcessing={isLoading}
        >
          Submit
        </Button>
      </form>
    </>
  ) : (
    <div style={{ fontSize: 20, textAlign: 'center'}}>
      Thank you for contacting us.
      <br/>
      <br/>
      A payment specialist will call you at the phone number provided.
    </div>
  );

  return (
    <ProfessionalHeaderOnWhiteLayout
      headerMessage={Messages.Header}
      subheaderMessage={Messages.Subheader}
      contentWidth={isMobile ? 320 : 650}
    >
      {content}
    </ProfessionalHeaderOnWhiteLayout>
  );
};

export default ElectronicPaymentInterest;