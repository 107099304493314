/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getCompanySettings, isCorporateExperience } from 'actions/common/companySettings';

import getIdentity from 'api/session/getIdentity';

import BlueHeaderOnWhiteLayout from 'layouts/BlueHeaderOnWhite';
import { AlignBackground, CityThree } from 'layouts/BlueHeaderOnWhite/HeaderBackgrounds';
import { wrapImport } from 'decorators/ModuleLoader';
import KnightErnie from 'public/images/ernie/knight.png';
import launchCancelSubscriptionModal from 'views/Settings/Company/Profile/SubscriptionPanel/CancelModal/Async';
import launchSelectPortalModal from 'views/Settings/Company/Subscription/SelectPortalModal';
import Messages from './index.messages';

const SubscriptionPage = wrapImport(
  () => import(/* webpackChunkName: "views" */ 'views/Settings/Company/Subscription')
);

const Backgrounds = [
  (
    <AlignBackground key="bg-2" bottom={0} left={0}>
      <CityThree />
    </AlignBackground>
  ),
  (
    <AlignBackground key="bg-3" bottom={-7} left={210}>
      <img src={KnightErnie} style={{ height: 120 }} />
    </AlignBackground>
  )
];

const DelinquentAccountSpeedBump = ({
  active,
  updateState
}) => {
  // Hooks
  const dispatch = useDispatch();

  const isSubscriptionDelinquent = useSelector(
    state => !!getCompanySettings(state).delinquencyDate && isCorporateExperience(state)
  );

  useEffect(
    () => updateState(!isSubscriptionDelinquent ? 'skipped' : 'loaded'),
    [ isSubscriptionDelinquent ]
  );

  const launchCancel = () => dispatch(launchCancelSubscriptionModal({
    showConnections: false,
    onComplete: () => {
      updateState('completed');
      dispatch(launchSelectPortalModal());
      dispatch(getIdentity());
    }
  }));

  // Render
  if (!active) return <></>;

  return (
    <BlueHeaderOnWhiteLayout
      headerMessage={Messages.Header}
      subheaderMessage={Messages.Subheader}
      backgrounds={Backgrounds}
      contentWidth={650}
      hideLoginLink={true}
    >
      <div className="viewstrap">
        <SubscriptionPage
          ignoreTopPadding={true}
          onSelectPortal={launchCancel}
          corporateButtonMessage={<Messages.SelectCorporate.Message/>}
        />
      </div>
    </BlueHeaderOnWhiteLayout>
  );
};

export default DelinquentAccountSpeedBump;
