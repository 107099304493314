/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React from 'react';
import DarkImage from 'images/main/viewpost-app-logo-grey.svg';
import LightImage from 'images/main/viewpost-app-logo.svg';
import BlueImage from 'images/main/viewpost.svg';
import VPImage from 'images/main/vp-id.svg';

export const Logo = ({
  className,
  height = '26',
  width = '125',
  variant = 'light'
}) => {
  let image = LightImage;
  let imgWidth = width;
  let imgHeight = height;
  switch (variant) {
    case 'dark':
      image = DarkImage;
      break;
    case 'blue':
      image = BlueImage;
      break;
    case 'vp':
      image = VPImage;
      imgWidth = 50;
      imgHeight = 18;
      break;
    default:
      break;
  }
  return (
    <img className={className}
      src={image}
      height={imgHeight}
      width={imgWidth}
      style={{borderStyle: 'none'}}
      alt="Viewpost Logo" />
  );
};

export default Logo;
