/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import createApiActions from 'api/core';
import {
  queryParamNameMapper,
  defaultMapper,
  sortFieldsQueryMapper,
  compositeMapper
} from 'api/common/mappers';
import {
  uploadCompanyLogoRequestMapper,
  uploadCompanyLogoResponseMapper,
  getIndustryTypesResponseMapper
} from 'api/network/mappers/companyMappers';
import {
  discountStrategiesResponseMapper,
  getVendorListByDiscountStrategyResponseMapper,
  getAutoAcceptDiscountSettingsResponseMapper
} from 'api/settings/mappers/vpxMappers';

import NetworkProfileDetails from 'schemas/network/NetworkProfileDetails';

import createUpdateCompanySettingResponseMapper
  from './mappers/createUpdateCompanySettingResponseMapper';
import {
  getCompanyUsersResponseMapper,
  getCompanyGroupsResponseMapper,
  getEmailPreferencesResponseMapper,
  updateEmailPreferencesRequestMapper,
  addGroupRequestMapper,
  getUsersForGroupResponseMapper,
  getAvailableGroupsResponseMapper,
  getPermissionsForGroupsResponseMapper
} from './mappers/userMappers';
import {
  getTwoFactorAuthenticationResponseMapper
} from './mappers/twoFactorAuthenticationMappers';
import {
  getVendorPaysFeesSettingsResponseMapper,
  updateVendorPaysFeesSettingsRequestMapper
} from './mappers/feeMappers';

export default createApiActions({
  inviteCompanyUser: {
    method: 'post',
    url: '/ajax/user/inviteUser',
    requestBodyMapper: ({userId}) => {
      return {
        userId
      };
    }
  },
  getCompanyUsers: {
    method: 'get',
    url: '/ajax/user/getUsersForCompany',
    queryStringMapper: queryParamNameMapper({
      'includeInactiveUsers': 'includeInactiveUsers'
    }),
    responseMapper: getCompanyUsersResponseMapper
  },
  getCompanyGroups: {
    method: 'get',
    url: '/ajax/role/getRoles',
    responseMapper: getCompanyGroupsResponseMapper
  },
  getPermissionsForGroup: {
    method: 'get',
    url: '/ajax/role/getPermissionsForRole',
    queryStringMapper: queryParamNameMapper({
      'id': 'id'
    }),
    responseMapper: getCompanyGroupsResponseMapper
  },
  getPermissionsForMultipleGroups: {
    method: 'post',
    url: '/ajax/role/getPermissionsForRoles',
    requestBodyMapper: defaultMapper,
    responseMapper: getPermissionsForGroupsResponseMapper
  },
  getUsersForGroup: {
    method: 'get',
    url: '/ajax/user/getUsersForRole',
    queryStringMapper: queryParamNameMapper({
      'id': 'roleId'
    }),
    responseMapper: getUsersForGroupResponseMapper
  },
  getTwoFactorAuthentication: {
    method: 'get',
    url: '/ajax/profile/getTwoStepVerificationSettings',
    responseMapper: getTwoFactorAuthenticationResponseMapper
  },
  disableTwoFactorAuthentication: {
    method: 'post',
    url: '/ajax/profile/disableTwoStepVerification'
  },
  uploadCompanyLogo: {
    method: 'post',
    url: '/api/company-profiles/:companyId/logos/:logoType',
    queryStringMapper: queryParamNameMapper({
      isTemporary: 'dryRun'
    }),
    requestBodyMapper: uploadCompanyLogoRequestMapper,
    responseMapper: uploadCompanyLogoResponseMapper
  },
  deleteCompanyLogo: {
    method: 'delete',
    url: '/api/company-profiles/:companyId/logos/:logoType'
  },
  getVendorPaysFeesSettings: {
    method: 'get',
    url: '/ajax/fees/get-covering',
    queryStringMapper: queryParamNameMapper({
      addressBookIds: 'addressBookIds',
      companyName: 'name',
      includeNotCovered: 'includeNotCovered',
      page: 'page',
      pageSize: 'pageSize'
    }),
    responseMapper: getVendorPaysFeesSettingsResponseMapper
  },
  updateVendorPaysFeesSettings: {
    method: 'post',
    url: '/ajax/fees/post-covering',
    requestBodyMapper: updateVendorPaysFeesSettingsRequestMapper,
    markUpdated: {
      entityTypes: [
        NetworkProfileDetails.meta.name
      ]
    }
  },
  'cancelEmailChange': {
    method: 'delete',
    url: '/api/user-profiles/current/email'
  },
  'getEmailPreferences': {
    method: 'get',
    url: '/ajax/profile/emailPreferences',
    responseMapper: getEmailPreferencesResponseMapper
  },
  'updateEmailPreferences': {
    method: 'put',
    url: '/ajax/profile/emailPreferences',
    requestBodyMapper: updateEmailPreferencesRequestMapper
  },
  'isGroupNameUsed': {
    method: 'post',
    url: '/ajax/role/IsRoleNameUsedForCompany',
    requestBodyMapper: defaultMapper,
    responseMapper: defaultMapper
  },
  'addGroup': {
    method: 'post',
    url: '/ajax/role/addRole',
    requestBodyMapper: addGroupRequestMapper,
    responseMapper: defaultMapper
  },
  'updateGroup': {
    method: 'post',
    url: '/ajax/role/updateRole',
    requestBodyMapper: addGroupRequestMapper,
    responseMapper: defaultMapper
  },
  'deleteGroup': {
    method: 'post',
    url: '/ajax/role/deleteRole',
    requestBodyMapper: defaultMapper,
    responseMapper: defaultMapper
  },
  'getAvailableGroups': {
    method: 'get',
    url: '/ajax/role/getAvailableRoles',
    responseMapper: getAvailableGroupsResponseMapper
  },
  'addUser': {
    method: 'post',
    url: '/ajax/user/addUser',
    requestBodyMapper: defaultMapper,
    responseMapper: defaultMapper
  },
  changeAutoApplyPayments: {
    method: 'post',
    url: '/ajax/settings/updateAutoApplyPayments',
    requestBodyMapper: (params) => {
      return {
        isAutoApplyPaymentsEnabled: params.value
      };
    },
    responseMapper: createUpdateCompanySettingResponseMapper('autoApplyPayments')
  },
  changeSuggestCompany: {
    method: 'post',
    url: '/ajax/settings/updateSuggestCompanyAsConnection',
    requestBodyMapper: (params) => {
      return {
        isSuggestCompanyEnabled: params.value
      };
    },
    responseMapper: createUpdateCompanySettingResponseMapper('suggestCompany')
  },
  changeMultistepApprovals: {
    method: 'post',
    url: '/ajax/settings/updateMultistepApprovals',
    requestBodyMapper: (params) => {
      return {
        isMultistepApprovalEnabled: params.value
      };
    },
    responseMapper: createUpdateCompanySettingResponseMapper('multistepApprovals')
  },
  'getUserDetailsForEdit': {
    method: 'get',
    url: '/ajax/user/getUserForEdit',
    queryStringMapper: queryParamNameMapper({
      'id': 'userId'
    }),
    responseMapper: defaultMapper
  },
  'updateCompanyUser': {
    method: 'post',
    url: '/ajax/user/updateCompanyUser',
    requestBodyMapper: defaultMapper,
    responseMapper: defaultMapper
  },
  'mobileSignature': {
    method: 'post',
    url: '/api/next/account/signature/create',
    requestBodyMapper: defaultMapper
  },
  'getDiscountStrategies': {
    method: 'get',
    url: '/api/web/get_discount_strategies',
    responseMapper: discountStrategiesResponseMapper
  },
  'getDiscountRateFromReturn': {
    method: 'get',
    url: '/api/web/get_discount_rate_from_return',
    queryStringMapper: queryParamNameMapper({
      'returnPercent': 'returnPercent'
    }),
    responseMapper: defaultMapper
  },
  'deleteDiscountStrategy': {
    method: 'delete',
    url: '/api/web/delete_discount_strategy',
    requestBodyMapper: defaultMapper
  },
  'saveDiscountStrategy': {
    method: 'post',
    url: '/api/web/save_discount_strategy',
    requestBodyMapper: defaultMapper,
    responseMapper: defaultMapper
  },
  'assignVendorsToDiscountStrategy': {
    method: 'post',
    url: '/api/web/assign_vendors_to_discount_strategy',
    requestBodyMapper: defaultMapper
  },
  'getVendorDiscountStrategyList': {
    method: 'get',
    url: '/api/web/get_vendor_discount_strategy_list',
    queryStringMapper: compositeMapper(
      queryParamNameMapper({
        'customerAddressBookForVendorQueryString': 'customerAddressBookForVendorQueryString',
        'discountStrategyIds': 'discountStrategyIds',
        'page': 'page',
        'pageSize': 'pageSize',
        'sort': 'sort',
        'format': 'format'
      }),
      sortFieldsQueryMapper({
        vendorName: 'vendorName',
        discountStrategyName: 'discountStrategyName',
        lastOfferReturn: 'lastOfferReturn',
        invoiceCount: 'invoiceCount',
        sumDiscountAmount: 'sumDiscountAmount'
      })),
    responseMapper: getVendorListByDiscountStrategyResponseMapper
  },
  'getAutoAcceptDiscountSettings': {
    method: 'get',
    url: '/api/autopay/autoaccept',
    responseMapper: getAutoAcceptDiscountSettingsResponseMapper
  },
  'saveAutoAcceptDiscountSettings': {
    method: 'post',
    url: '/api/autopay/autoaccept',
    requestBodyMapper: defaultMapper
  },
  'getDiscountReturnFromRate': {
    method: 'get',
    url: '/api/web/get_discount_return_from_rate',
    queryStringMapper: queryParamNameMapper({
      'daysEarly': 'daysEarly',
      'discountPercent': 'discountPercent'
    }),
    responseMapper: defaultMapper
  },
  setOneClickPayEnabled: {
    method: 'put',
    url: '/api/user-profiles/:id/settings/OneClickPayEnabled',
    requestBodyMapper({ isEnabled: isOneClickEnabled }) {
      return {
        id: 'OneClickPayEnabled',
        value: isOneClickEnabled
      };
    }
  },
  setOneClickPaymentMethod: {
    method: 'put',
    url: '/api/user-profiles/:id/settings/OneClickPaymentMethodId',
    requestBodyMapper({paymentMethod}) {
      return {
        id: 'OneClickPaymentMethodId',
        value: paymentMethod
      };
    }
  },
  setOneClickPaymentTiming: {
    method: 'put',
    url: '/api/user-profiles/:id/settings/OneClickPaymentTiming',
    requestBodyMapper({paymentTiming}) {
      return {
        id: 'OneClickPaymentTiming',
        value: paymentTiming
      };
    }
  },
  getIndustryTypes: {
    method: 'get',
    url: '/api/web/industries',
    responseMapper: getIndustryTypesResponseMapper
  },
  getVpxEnabledForVendorSetting: {
    method: 'get',
    url: '/api/companySetting/vpxEnabledForVendor'
  },
  updateVpxEnabledForVendorSetting: {
    method: 'post',
    url: '/api/companySetting/vpxEnabledForVendor',
    requestBodyMapper: params => ({ isBiddingEnabled: params.enabled })
  }
});
