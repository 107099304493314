/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { uniqueId } from 'lodash';
import { createEntity } from 'schemas/state';
import { Payable } from 'schemas/invoicing/invoice';
import { dateMapper } from 'api/common/mappers/coreMappers';
import { invoiceStatusMapper, mapVpxDiscount } from 'api/invoice/mappers/utils';

export function getBillsInvitationDocumentsResponseMapper(apiResponse) {
  let entities = [];

  if (apiResponse && apiResponse.documents) {
    entities = apiResponse.documents.map(apiDocument => createEntity(
      uniqueId(),
      Payable.meta.name,
      {
        ...apiDocument,
        scheduledPayDate: dateMapper(apiDocument.scheduledPayDate),
        status: invoiceStatusMapper(apiDocument.displayStatus),
        dueDate: dateMapper(apiDocument.dueDate),
        availableActions: [],
        invoiceType: Payable.meta.name,
        totalAmount: apiDocument.invoiceAmount,
        vpxAvailableDiscount: apiDocument.earlyPay ? mapVpxDiscount(apiDocument.earlyPay.availableRate) : null
      }
    ));
  }

  return {
    entities
  };
}

export function shareOnboardingInvoiceRequestMapper(apiParams) {
  return {
    id: apiParams.invoiceId,
    emailCC: apiParams.emailCC,
    emailTo: apiParams.emailTo,
    accessToken: apiParams.secureToken,
    comments: apiParams.comments
  };
}
