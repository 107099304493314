/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { shallowEqual, useSelector } from 'react-redux';
import { getCompanySettings } from 'actions/common/companySettings';

const useCompanySettings = () => useSelector(
  state => getCompanySettings(state),
  shallowEqual
);

export default useCompanySettings;