/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { connect } from 'react-redux';
import getCompanyPaymentAccounts from 'actions/common/getCompanyPaymentAccounts';
import { verifyAccount } from 'actions/modal';
import launchAddPaymentMethodModal
  from 'containers/AddBankAccount/Modal';
import { loadBankAccount } from 'actions/settings';
import { getEntityList } from 'schemas/state';
import filterForValidAccounts from 'schemas/payments/bankAccount/filterForValidAccounts';
import launchMissingSignatureModal from './MissingSignatureWorkflowModal/Async';

const addMissingSignature = (bankAccountId, onComplete) => (dispatch, getState) => {
  // attempt to prepopulate with 1st signature found on any account
  const state = getState();
  const bankAccounts = getEntityList(state, state.session.bankAccounts);
  if (bankAccounts) {
    const accountWithSignature = bankAccounts.find(account => account.hasSignature);
    if (accountWithSignature) {
      // we have to fetch the bank account's details to get the actual signature
      dispatch(loadBankAccount(accountWithSignature.id, null, (success, action) => {
        let bankAccount = action.payload.entities[0].value;
        let signatureImage = bankAccount.signatureImage1;
        let authorizedSigner = bankAccount.authorizedSigner;
        dispatch(launchMissingSignatureModal({
          bankAccountId,
          onComplete,
          signatureImage,
          authorizedSigner
        }));
      }));
      return;
    }
  }

  // else don't prepoluate and just collect a new signature
  dispatch(launchMissingSignatureModal({
    bankAccountId,
    onComplete
  }));
};

const MapStateToProps = (state, { value }) => {
  const { isWhitelistedToUseUnverfiedAccount } = value || {};
  return {
    bankAccounts: filterForValidAccounts(
      getCompanyPaymentAccounts(state),
      {
        includeVerifiable: true,
        isRecipientWhitelisted: isWhitelistedToUseUnverfiedAccount
      }
    )
  };
};

const MapDispatchToProps = (dispatch, { value }) => {
  const { isWhitelistedToUseUnverfiedAccount } = value || {};

  return {
    onAddAccount: onAddAccount => dispatch(
      launchAddPaymentMethodModal({
        isWhitelistedFlow: isWhitelistedToUseUnverfiedAccount,
        whitelistedCompanyName: '', // TODO: Fill out
        onComplete: () => {
          // Ideally we'd get back the Bank Account ID so we can set it here, but we don't 🤷‍♂️
          if (onAddAccount) onAddAccount();
        }
      })
    ),
    onVerifyAccount: (bankAccountId, onComplete) => dispatch(
      verifyAccount(bankAccountId, onComplete)
    ),
    onAddSignature: (bankAccountId, onComplete) => dispatch(
      addMissingSignature(bankAccountId, onComplete)
    )
  };
};

export default connect(MapStateToProps, MapDispatchToProps);
