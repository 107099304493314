/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */
/* eslint max-len:0 */

import { defineMessages } from 'react-intl';

const common = defineMessages({
  Password: {
    id: 'PasswordStrengthField.Password',
    defaultMessage: 'Password {status}'
  },
  PasswordStrength: {
    id: 'PasswordStrengthField.PasswordStrength',
    defaultMessage: 'Password strength: {strength}'
  },
  PasswordRules: {
    id: 'PasswordStrengthField.PasswordRules',
    defaultMessage: 'Password must be 8-32 characters, contain at least one uppercase letter and one lowercase letter, and include one number or one symbol.'
  }
});

export default {
  ...common
};
