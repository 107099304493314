/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import PropTypes from 'prop-types';
/**
  Configuration component representing a field in a <FormJSX>
  @docscomponent
*/
function FieldOption(props) {
  if (!props.name) {
    throw new Error('Each Form FieldOption must have a name.');
  }

  let { name, children, label, placeholder, type, attrs, ...config } = props;

  return {
    name,
    type,
    config,
    label,
    placeholder,
    attrs,
    children
  };
}

FieldOption.propTypes = {
  /** The matching tcomb schema's property name for this field */
  name: PropTypes.string.isRequired,

  /** The label for the field. Generally text content above the input component.
   * This CANNOT be JSX content, must Use overrideLabel for that.
   */
  label: PropTypes.any,

  /** Placeholder text (if any) to use for the input component */
  placeholder: PropTypes.string,

  /** Footnote label or content that goes directly below the field */
  footerLabel: PropTypes.any,

  /**
   * Fields are laid out in a 12-step grid system (See Bootstrap Grid System).
   * Specify how many columns (1-12) this field should take up.
   * Default: 12
   */
  cols: PropTypes.number,

  /** Set to "textarea" to use textarea instead of single line input for t.String types */
  type: PropTypes.string,

  /** A second(ary) label (OR JSX content) right-aligned above the input (label is left-aligned) */
  rightLabel: PropTypes.any,

  /** Alternative to label prop. Use custom JSX content for the label  */
  overrideLabel: PropTypes.any,

  /** Applies a CSS className to this field */
  className: PropTypes.string,

  /** Custom style attributes to apply to this field */
  style: PropTypes.object,

  /**
   * Alongside the label, show an Info Icon that opens a tooltip when clicked. The tooltip content will be this string value.
   */
  toolTipMessage: PropTypes.string,

  /**
   * Pass in alternative Input component type to use for this field (e.g. from components/Form/Inputs)
   * The input component's value type should align with the schema for this field (e.g. t.Boolean schema must use a bool-balue Input component).
   * When this is not a set a default input for the type is selected by the Form architecture
  */
  inputComponent: PropTypes.any,

  /**
   * Pass custom props down to the underlying Input component for this field
   * (e.g. either the inputComponent prop passed or the default chosen by the architecture)
   */
  inputProps: PropTypes.object
};

export default FieldOption;
