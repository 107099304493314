/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */
/* eslint max-len:0 */

import { defineMessages } from 'react-intl';

const common = defineMessages({
  FollowInstructions: {
    id: 'AddSignature.FollowInstructions',
    defaultMessage: 'Follow the instructions sent via text message to {phoneNumber}'
  },
  SignatureWillAppear: {
    id: 'AddSignature.SignatureWillAppear',
    defaultMessage: 'Your signature will appear here.'
  },
  SignatureInstructions: {
    id: 'AddSignature.SignatureInstructions',
    defaultMessage: 'We will send you a text message with instructions to add a signature by phone.'
  },
  EnterSignatureError: {
    id: 'AddSignature.EnterSignatureError',
    defaultMessage: 'Please enter a signature below'
  },
  SwitchToDrawMessage: {
    id: 'AddSignature.SwitchToDrawMessage',
    defaultMessage: 'Don\'t have a smartphone? '
  },
  SwitchToDrawAction: {
    id: 'AddSignature.SwitchToDrawAction',
    defaultMessage: 'Sign with computer'
  },
  SwitchToMobileMessage: {
    id: 'AddSignature.SwitchToMobileMessage',
    defaultMessage: 'Have a smartphone? '
  },
  SwitchToMobileAction: {
    id: 'AddSignature.SwitchToMobileAction',
    defaultMessage: 'Sign by phone'
  },
  SignByMobileHeader: {
    id: 'AddSignature.SignByMobileHeader',
    defaultMessage: 'Sign using your smartphone.'
  },
  SignByMobileInstructions: {
    id: 'AddSignature.SignByMobileInstructions',
    defaultMessage: 'Enter your phone number to begin.'
  }
});

const formLabels = defineMessages({
  Signature: {
    id: 'AddSignature.formLabels.Signature',
    defaultMessage: 'Signature (Authorized Signer)'
  },
  EnterMobile: {
    id: 'AddSignature.formLabels.EnterMobile',
    defaultMessage: 'Sign using your smartphone. Enter number to begin:'
  }
});

const placeholders = defineMessages({
  PhoneNumber: {
    id: 'AddSignature.placeholders.PhoneNumber',
    defaultMessage: '(000) 000-0000'
  }
});

const buttons = defineMessages({
  Resend: {
    id: 'AddSignature.buttons.Resend',
    defaultMessage: 'Resend Link'
  },
  ChangePhone: {
    id: 'AddSignature.buttons.ChangePhone',
    defaultMessage: 'Change Phone'
  },
  Clear: {
    id: 'AddSignature.buttons.Clear',
    defaultMessage: 'Clear'
  },
  Send: {
    id: 'AddSignature.buttons.Send',
    defaultMessage: 'Send'
  },
  Edit: {
    id: 'AddSignature.buttons.Edit',
    defaultMessage: 'Edit'
  }
});

export default {
  ...common,
  formLabels,
  placeholders,
  buttons
};
