/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Column = ({
  className,
  size,
  border,
  flex,
  style: originalStyle,
  offset,
  children
}) => {
  const classes = classNames(
    className,
    size != null ? `${flex ? 'flex-' : ''}col-${size}` : null,
    { border }
  );

  const style = {
    ...originalStyle
  };

  if (offset > 0) {
    style.marginLeft = `${(offset / 12) * 100}%`;
  }

  return (
    <div className={classes} style={style}>
      {children || '\u00a0'}
    </div>
  );
};

Column.propTypes = {
  size: PropTypes.number.isRequired,
  offset: PropTypes.number,
  border: PropTypes.bool
};

Column.defaultProps = {
  border: false,
  offset: 0
};

// Forgoing doing a proper component because its not strictly necessary since we are only hardcoding
// a single component
export const FlexColumn = props => Column({
  ...props,
  flex: true
});

export default Column;
