/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */
/* eslint max-len:0 */

import { defineMessages } from 'react-intl';

const common = defineMessages({
  ProfileChanged: {
    id: 'SecurityQuesetionsForm.ProfileChanged',
    defaultMessage: 'Thanks! Your profile has been updated.'
  }
});

const buttons = defineMessages({
  Save: {
    id: 'SecurityQuesetionsForm.buttons.Save',
    defaultMessage: 'Save'
  },
  Cancel: {
    id: 'SecurityQuesetionsForm.buttons.Cancel',
    defaultMessage: 'Cancel'
  }
});

export default {
  ...common,
  buttons
};
