/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */
/* eslint max-len:0 */

import { defineMessages } from 'react-intl';
import { get } from 'lodash';
import Paths from 'config/paths';

const createMessages = (parentPath, parentMessage, childrenMessages, ignoreMissing) => {
  if (!ignoreMissing) {
    parentPath.children.forEach((key) => {
      if (!childrenMessages[key]) {
        console.warn(`'${parentPath[key].id}' is missing from NavLabels`);
      }
    });
  }

  Object.keys(childrenMessages).forEach((key) => {
    if (!get(parentPath, key)) {
      // Consider deleting the message if this happens
      console.warn(`Path child '${key}' under ${parentPath.id} doesn't exist but has a message.`);
    }
  });

  return {
    [parentPath.id]: {
      id: `Navigation.Labels.${parentPath.id}`,
      defaultMessage: parentMessage
    },
    ...Object.keys(childrenMessages).reduce((val, key) => {
      let childMessage = childrenMessages[key];
      let childPath = get(parentPath, key);
      if (!childPath) {
        console.warn(`'${key}' does not exist in ${parentPath.id}`);
        return val;
      }

      return {
        ...val,
        [childPath.id]: {
          id: `Navigation.Labels.${childPath.id}`,
          defaultMessage: childMessage
        }
      };
    }, {})
  };
};

const InvoicesLabels = createMessages(Paths.Invoices, 'Invoices', {
  List: 'List',
  Details: 'Invoice Details',
  Summary: 'Summary',
  Vpx: 'Get Paid Early',
  Recurring: 'Recurring',
  RecordPayment: 'Record Payment',
  QbOnline: 'Sync from QuickBooks',
  Create: 'Create Invoice',
  Edit: 'Edit Invoice Details'
});

const BillsLabels = createMessages(Paths.Bills, 'Bills', {
  List: 'List',
  Details: 'Bill Details',
  Statements: 'Statements',
  Vpx: 'Early Pay Offers',
  PaymentQueue: 'Payment Queue',
  StatementDetails: 'Statements',
  PaymentQueueReview: 'Payment Queue Review',
  RecordPayment: 'Record Payment',
  OutOfBandAttachments: 'Select Invoice',
  OutOfBandEdit: 'Edit Invoice Details',
  VpInboxCreate: 'Edit Invoice Details',
  Create: 'Create Bill',
  Edit: 'Edit Invoice Details',
  ConsumerOpenBills: 'Open Bills',
  ConsumerClosedBills: 'Closed Bills'
});

const PaymentsLabels = createMessages(Paths.Payments, 'Payments', {
  SendPayment: 'Send Payment',
  Sent: 'Sent',
  SentDetails: 'Details',
  Received: 'Received',
  ReceivedDetails: 'Details',
  QbOnline: 'Sync from QuickBooks',
  AutoPay: 'Auto Pay',
  Approvals: 'Approval Queue'
});

const NetworkLabels = createMessages(Paths.Network, 'Network', {
  List: 'Contacts',
  Requests: 'Connection Requests',
  Profile: 'Company Details'
});

const SyncLabels = createMessages(Paths.Sync, 'Sync', {
  Payments: 'Payments',
  History: 'History',
  Settings: 'Settings',
  Landing: 'Landing',
  DownloadXero: 'Download',
  DownloadQbOnline: 'Download',
  DownloadNetsuite: 'Download',
  DownloadQuickbooksOnline: 'Download',
  DownloadQuickBooksDesktop: 'Download',
  DownloadMicrosoftDynamicsGp: 'Download',
  DownloadSageLive: 'Download',
  DownloadRiskmaster: 'Download',
  DownloadOracle: 'Download',
  QuickbooksOnlineOnboarding: 'Onboarding',
  XeroOnboarding: 'Onboarding',
  OracleOnboarding: 'Onboarding'
});

const SettingsLabels = createMessages(Paths.Settings, 'Settings', {
  Profile: 'Profile',
  'Profile.Edit': 'Edit Profile',
  Preferences: 'Preferences',
  CloseAccount: 'Close Account',
  Logos: 'Logos',
  Groups: 'Groups',
  'Groups.Add': 'Add Group',
  'Groups.Edit': 'Edit Group',
  Users: 'Users',
  'Users.Add': 'Add User',
  'Users.Edit': 'Edit User',
  PaymentApproval: 'Payment Approval',
  Discounts: 'Discount Strategies',
  'Discounts.ManageVpxVendors': 'Manage Vendors',
  'Discounts.CreateStrategy': 'Create Strategy',
  'Discounts.EditStrategy': 'Edit Strategy',
  'Discounts.AddVendors': 'Add Vendors',
  UserAccount: 'User Account',
  PaymentMethods: 'Payment Methods',
  Fees: 'Fees',
  'Fees.Details': 'Fee Details',
  Reports: 'Reports',
  SwitchCompanies: 'Switch Companies',
  Subscription: 'Subscribe'
});

export const PrimaryNavLabels = defineMessages({
  [Paths.Settings.Profile.id]: {
    id: 'PrimaryNav.NavLabels.CompanySettings',
    defaultMessage: 'Company Settings'
  },
  [Paths.Settings.Discounts.id]: {
    id: 'PrimaryNav.NavLabels.Discounts',
    defaultMessage: 'Discount Settings'
  }
});

export const PortalNavLabels = defineMessages({
  ...createMessages(Paths.Invoices, 'Invoices', {
    List: 'Invoices'
  }, true),
  ...createMessages(Paths.Payments, 'Payments', {
    Received: 'Payments Received',
    Sent: 'Payments Sent'
  }, true),
  ...createMessages(Paths.Bills, 'Bills', {
    List: 'Bills'
  }, true)
});

export const PortalSecondaryNavLabels = defineMessages({
  ...createMessages(Paths.Payments, 'Payments', {
    Received: 'List',
    Sent: 'List'
  }, true)
});

const NavLabels = defineMessages({
  LogOut: {
    id: 'NewSecondaryNav.NavLabels.LogOut',
    defaultMessage: 'Log Out'
  },
  [Paths.Logon.id]: {
    id: 'NewSecondaryNav.NavLabels.Logon',
    defaultMessage: 'Log In'
  },
  [Paths.Dashboard.id]: {
    id: 'NewSecondaryNav.NavLabels.Dashboard',
    defaultMessage: 'Dashboard'
  },
  ...InvoicesLabels,
  ...BillsLabels,
  ...PaymentsLabels,
  ...NetworkLabels,
  ...SyncLabels,
  ...SettingsLabels,
  [Paths.Tasks.id]: {
    id: 'NewSecondaryNav.NavLabels.Tasks',
    defaultMessage: 'Tasks'
  },
  [Paths.FileImport.id]: {
    id: 'NewSecondaryNav.NavLabels.FileImport',
    defaultMessage: 'File Import'
  },
  [Paths.ConsumerAutoPay.id]: {
    id: 'NewSecondaryNav.NavLabels.ConsumerAutoPay',
    defaultMessage: 'Auto Pay'
  },
  [Paths.ConsumerFees.id]: {
    id: 'NewSecondaryNav.NavLabels.ConsumerFees',
    defaultMessage: 'Fees'
  },
  [Paths.ConsumerSettings.id]: {
    id: 'NewSecondaryNav.NavLabels.ConsumerSettings',
    defaultMessage: 'Settings'
  },
  [Paths.Help.id]: {
    id: 'NewSecondaryNav.NavLabels.Help',
    defaultMessage: 'Support'
  }
});

export default NavLabels;
