/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import qs from 'qs';
import { push } from 'connected-react-router';

const setQuery = (
  newQuery,
  {
    dispatch,
    history
  }
) => {
  const pathname = history.location?.pathname;

  // Remove any nil values from the query, use nil to clear out set query
  // params
  Object.keys(newQuery).forEach(
    (key) => {
      if (newQuery[key] == null) {
        delete newQuery[key];
      }
    }
  );

  dispatch(push({
    pathname,
    search: Object.keys(newQuery).length === 0
      ? null // Don't set any search string if there is nothing in the query
      : `?${qs.stringify(newQuery)}`
  }));
};

export default setQuery;