/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, { createRef } from 'react';
import { useDispatch } from 'react-redux';
import { hideTask } from 'api/tasks';
import Badge from 'components/Badge';
import Button from 'components/Button';
import generateDropdown from 'components/Dropdown/generateDropdown';
import Icon, { IconTypes } from 'components/Icon';
import Menu from 'components/Menu/Menu';
import MenuItem from 'components/Menu/MenuItem';
import VerticalMenuRenderer from 'components/Menu/VerticalMenuRenderer';
import PathLink from 'components/Link/PathLink';
import TaskActionLink from 'components/TaskActionLink';
import { connectNavigationData } from '../index.connect';
import Messages from './index.messages';
import './index.scss';

const IconWithBadgeCaret = ({
  badgeCount
}) => {
  return (
    <span className="dropdown-caret-component icon-badge-caret">
      {badgeCount ? <Badge type="attention" affix="top-right">{badgeCount}</Badge> : null}
      <Icon type={IconTypes.Bell} size={19} className="caret-icon" />
    </span>
  );
};

const IconWithBadgeToggle = ({
  isOpen,
  badgeCount,
  onClick,
  children
}) => (
  <span className="viewstrap">
    <span className="dropdown-icon-badge-toggle-component"
      onClick={onClick}>
      <IconWithBadgeCaret badgeCount={badgeCount} isOpen={isOpen} />
    </span>
    {children}
  </span>
);

const IconWithBadgeToggleDropdown = generateDropdown(
  IconWithBadgeToggle,
  {
    useToggleForPopover: true
  }
);

const TasksMenu = ({
  closeMenu,
  navData: {
    connectionRequestCount,
    receivedConnectionRequests,
    tasks,
    totalTaskCount,
    approvalQueueCount
  }
}) => {
  const dispatch = useDispatch();

  let itemCount = (connectionRequestCount || 0)
    + (totalTaskCount || 0)
    + (approvalQueueCount || 0);

  return (
    <div className="viewstrap">
      <Menu renderer={VerticalMenuRenderer} className="TaskMenuDropdown">
        {itemCount === 0
          ? (
            <MenuItem text={true} className="NoTasks menuItem">
              <strong>
                <Messages.NoTasksHeader.Message />
              </strong>
              <br />
              <Messages.NoTasksSubheader.Message />
            </MenuItem>
          )
          : null}
        {approvalQueueCount > 0
          ? (
            <MenuItem key="approvalQueue" text={true}>
              <li className="SubPanel">
                <strong>
                  <Messages.PaymentApprovalsItemHeader.Message />
                </strong>
                <div className="details">
                  There {approvalQueueCount === 1
                    ? 'is a payment'
                    : <span>are <strong>{approvalQueueCount}</strong> payments</span>} ready for your approval.
                </div>
                <PathLink.Payments.Approvals button={true} onClick={closeMenu}>
                  View Approval Queue
                </PathLink.Payments.Approvals>
              </li>
            </MenuItem>
          )
          : null}
        {receivedConnectionRequests.map(request => (
          <MenuItem key={request.connectionRequestId} text={true}>
            <li className="SubPanel">
              <strong>
                <Messages.ConnectionRequestItemHeader.Message />
              </strong>
              <div className="details">
                From {request.companyName} as your {request.relationship}
              </div>
              <PathLink.Network.Requests button={true} onClick={closeMenu}>
                View Details
              </PathLink.Network.Requests>
            </li>
          </MenuItem>
        ))}
        {tasks.map(task => (
          <MenuItem key={task.id} text={true}>
            <li key={task.id} className="SubPanel">
              <strong>
                <Messages.TaskItemHeader.Message />
              </strong>
              <div className="details">
                {task.message}
              </div>
              <div>
                <span style={{ marginRight: '1rem' }} onClick={closeMenu}>
                  <TaskActionLink
                    taskEntityType={task.controlType}
                    taskEntityId={task.domainId}
                  >
                    <Messages.TaskPrimaryAction.Message />
                  </TaskActionLink>
                </span>
                <Button variant="secondary" onClick={() => dispatch(hideTask({ id: task.id }))}>
                  <Messages.TaskDismissAction.Message />
                </Button>
              </div>
            </li>
          </MenuItem>
        ))}
        {totalTaskCount > 0
          ? null
          : <MenuItem divider={true} />}
        {totalTaskCount > 0
          ? (
            <MenuItem className="ViewAllTasks" onClick={closeMenu}>
              <PathLink.Tasks>
                <Messages.ViewAllTasks.Message count={totalTaskCount} />
              </PathLink.Tasks>
            </MenuItem>
          )
          : null}
      </Menu>
    </div>
  );
};

const TasksMenuDropdown = ({
  navData
}) => {
  const { connectionRequestCount, totalTaskCount, approvalQueueCount } = navData || {};
  const dropdownRef = createRef();

  let itemCount = (connectionRequestCount || 0)
    + (totalTaskCount || 0)
    + (approvalQueueCount || 0);

  return (
    <IconWithBadgeToggleDropdown
      ref={dropdownRef}
      badgeCount={itemCount}
    >
      <div style={{ paddingTop: 8 }}>
        <TasksMenu
          closeMenu={() => dropdownRef.current.close()}
          navData={navData}
        />
      </div>
    </IconWithBadgeToggleDropdown>
  );
};

export default connectNavigationData()(TasksMenuDropdown);
