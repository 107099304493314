/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */
/* eslint max-len:0 */

import { toMessages } from 'config/messages';

export default toMessages('SubscriptionPanel.CreditCardForm', {
  CardNameLabel: 'Cardholder Name',
  CardNumberLabel: 'Card Number',
  CardExpirationLabel: 'Expiration',
  CardCvcLabel: 'Security Code',
  CardZipLabel: 'Billing Zip',
  CardExpirationPlaceholder: 'MM/YY'
});
