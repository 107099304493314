/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React from 'react';
import PropTypes from 'prop-types';
import DefaultLogo from 'images/default-logo.png';
import './index.css';

export default class CompanyLogo extends React.Component {
  static propTypes = {
    id: PropTypes.string, // companyBookId, avoid this
    companyId: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
    specifySize: PropTypes.bool
  };

  static defaultProps = {
    width: 240,
    height: 144,
    specifySize: true
  };

  constructor(props) {
    super(props);
  }

  getImageUrl(width, height) {
    let imageUrl = null;
    if (this.props.id) {
      imageUrl = `/api/companies/${this.props.id}/profile/logo?`
        + `returnDefaultImage=true&width=${width}&height=${height}`;

      // hackity hack hack hack - testing if this is a connected company
      // if not then don't supply an imageUrl
      if (this.props.id.length !== 37 || this.props.id.charAt(0) !== '1') {
        imageUrl = null;
      }
    } else if (this.props.companyId) {
      imageUrl = `/api/companies/1${this.props.companyId}/profile/logo?`
        + `returnDefaultImage=true&width=${width}&height=${height}`;
    }

    return imageUrl || DefaultLogo;
  }

  render() {
    let width = this.props.width;
    let height = this.props.height;

    let aspectRatio = width / height;
    let defaultAspectRatio = CompanyLogo.defaultProps.width / CompanyLogo.defaultProps.height;

    // invalid aspect ratio
    // find which dimension doesn't match the default
    // modify the other dimension to match the aspect ratio
    // if both dimensions were supplied it will modify the height
    if (defaultAspectRatio !== aspectRatio) {
      if (this.props.width && width !== CompanyLogo.defaultProps.width) {
        height = Math.floor(width / defaultAspectRatio);
      } else if (this.props.height) {
        width = Math.floor(height * defaultAspectRatio);
      }
    }

    let imageStyle = {
      width: `${width}px`,
      height: `${height}px`
    };

    return (
      <img className={this.props.className} src={this.getImageUrl(width, height)}
        style={this.props.specifySize ? imageStyle : null} />
    );
  }
}
