/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { connect } from 'react-redux';

const ProvideIsMobile = connect(
  ({ deviceInfo: { isMobile } }, { forceIsMobile }) => ({
    isMobile: isMobile || forceIsMobile
  }),
  null,
  null,
  { forwardRef: true }
);

export default ProvideIsMobile;
