/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, { forwardRef, useEffect, useState } from 'react';
import loadjs from 'loadjs';
import logError from 'services/ErrorService';

import { isEnabled } from 'services/FeatureToggles';
import BaseLoadingIndicator from 'components/LoadingIndicator/Base';
import { MessageMoonLoadingIndicator } from 'components/LoadingIndicator/Moon';
import ProblemErnie from 'components/ProblemErnie';

import useFuncRef from 'hooks/react/useFuncRef';

const DefaultLoadingIndicator = ({
  centerInPage,
  style,
  message
}) => isEnabled('newLoadingIndicator') ? (
  <MessageMoonLoadingIndicator
    centerInPage={centerInPage}
    style={{ padding: '20px 0', ...(style || {}) }}
  >
    {message}
  </MessageMoonLoadingIndicator>
) : (
  <BaseLoadingIndicator
    centerInPage={centerInPage}
    style={{ padding: '20px 0', ...(style || {}) }}
  >
    {message}
  </BaseLoadingIndicator>
);

export const createModuleLoader = ({
  hideLoadingIndicator,
  importModule,
  importModuleKey,
  importScript,
  scriptToLoad,
  ignoreExtraProps,
  innerComponent: InnerComponent,
  isOutsideContext,
  isWholePageLoader,
  loadingIndicator,
  loadingMessage,
  onModuleLoaded: onModuleLoadedCallback
} = {}) => {
  const ModuleLoader = forwardRef(
    (
      props,
      ref
    ) => {
      // Hooks
      const [ [ loaded, component ], setLoaded ] = useState([]);
      const [ hasError, setHasError ] = useState();

      const { current: onLoad } = useFuncRef(
        () => {
          let hasUnmounted = false;

          const onModuleLoaded = (module) => {
            if (hasUnmounted) return;

            setLoaded([
              true,
              module && module[importModuleKey || 'default']
                ? module[importModuleKey || 'default']
                : module
            ]);

            onModuleLoadedCallback?.(module);
          };

          const onScriptLoaded = () => {
            if (hasUnmounted) return;

            setLoaded([ true ]);
          };

          const onError = ({ error, paths }) => {
            if (hasUnmounted) return;

            if (paths) {
              logError(`Failed to load script: ${paths}`);
            } else if (error) {
              logError('Error when loading module.', { error });
            }

            setHasError(true);
          };

          const handleImportPromise = (promise) => {
            promise
              .then(onModuleLoaded)
              .catch(error => onError({ error }));
          };

          const execute = async () => {
            if (importModule) {
              if (importModule.then) {
                console.warn(`Passed in a promise directly, so the module is already loaded.
                  Change the method signature to pass in a function that returns a import promise.`);
                handleImportPromise(importModule);
                return;
              }

              const importPromise = importModule();

              if (!importPromise?.then) {
                logError('Module import did not return promise. Are you sure the import result was returned?');
                return;
              }

              handleImportPromise(importPromise);

              return;
            }

            if (importScript) {
              try {
                await importScript;
                onScriptLoaded();
              } catch (e) {
                onError({ error: 'There was a problem loading a script.' });
              }

              return;
            }

            if (scriptToLoad) {
              loadjs(scriptToLoad, {
                success: onScriptLoaded,
                error: paths => onError({ paths })
              });

              return;
            }
          };

          return () => {
            execute();

            // This is necessary so that state updates don't happen after the
            // component is gone
            return () => {
              hasUnmounted = true;
            };
          };
        }
      );

      useEffect(
        onLoad,
        []
      );

      // Render
      if (hasError) {
        return (
          <ProblemErnie
            errorReason="Module failed to load."
            centerInPage={isWholePageLoader}
          />
        );
      }

      if (!loaded) {
        if (hideLoadingIndicator) return null;

        const LoadingIndicator = loadingIndicator || DefaultLoadingIndicator;

        return (
          <LoadingIndicator
            message={loadingMessage || 'Loading...'}
            centerInPage={isWholePageLoader}
          />
        );
      }

      const ModuleComponent = component || InnerComponent;

      return (
        <ModuleComponent
          ref={ref}
          {...props}
          {...(ignoreExtraProps ? null : { wasAsyncLoaded: true })}
        />
      );
    }
  );

  return ModuleLoader;
};

export const wrapImport = (importModule, params) => {
  if (importModule.then) {
    console.warn(`Passed in a promise directly, so the module is already loaded.
      Change the method signature to pass in a function that returns a import promise.`);
  }

  return createModuleLoader({
    importModule,
    ...(params || {})
  });
};

export default createModuleLoader;
