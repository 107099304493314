/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import t from 'tcomb-validation';
import AddressBookRelationships from 'config/constants/network/AddressBookRelationships';
import {
  MetadataTargetCompanyStatus,
  MetadataTargetCompanyPaymentStatus,
  ShowAddPaymentAccountStatus
} from 'config/constants/registration';
import { defineSchema } from 'schemas';
import { LooseAddress } from 'schemas/common/address';
import { createEntity } from 'schemas/state';

export const InvitationMetadata = defineSchema({
  isValid: t.Bool,
  isUserRegistration: t.Bool,
  invitationId: t.maybe(t.String),
  referralToken: t.maybe(t.String),
  id: t.String,
  email: t.maybe(t.String),
  companyName: t.maybe(t.String),
  firstName: t.maybe(t.String),
  lastName: t.maybe(t.String),
  phoneNumber: t.maybe(t.String),
  from: t.struct({
    companyName: t.maybe(t.String),
    companyId: t.maybe(t.String)
  }),
  address: LooseAddress,
  invitationType: t.maybe(t.String),
  displayAddPaymentAccount: t.enums(ShowAddPaymentAccountStatus),
  offerPersonalPortal: t.Bool,
  context: t.maybe(t.String),
  token: t.maybe(t.String),
  targetCompanyInfo: t.struct({
    status: t.enums(MetadataTargetCompanyStatus),
    paymentAccountStatus: t.enums(MetadataTargetCompanyPaymentStatus),
    connectionType: t.enums({ ...AddressBookRelationships, None: 'None' }),
    isBankUnitedInvitedCompany: t.maybe(t.Bool),
    isIndividual: t.maybe(t.Bool)
  }),
  subscription: t.struct({
    isRequired: t.Bool,
    amount: t.Number
  })
}, 'InvitationMetadata');

export const createInvitationMetadata = ({
  address,
  companyName,
  emailAddress,
  firstName,
  fromCompanyName,
  fromCompanyId,
  id,
  invitationId,
  referralToken,
  invitationType,
  isEmployeeRegistration,
  isValid,
  lastName,
  phoneNumber,
  displayAddPaymentAccount,
  offerPersonalPortal,
  subscription,
  targetCompanyInfo,
  context,
  token
}) => {
  targetCompanyInfo = targetCompanyInfo || {};
  let targetCompanyStatus = targetCompanyInfo.status || MetadataTargetCompanyStatus.Unregistered;
  if (targetCompanyStatus === 'Unknown') {
    // TOOD: Log and Error/Warning
    targetCompanyStatus = MetadataTargetCompanyStatus.Unregistered;
  }

  let targetPaymentAccountStatus = targetCompanyInfo.paymentAccountStatus
    || MetadataTargetCompanyPaymentStatus.NoAccounts;
  if (targetPaymentAccountStatus === 'Unknown') {
    // TOOD: Log and Error/Warning
    targetPaymentAccountStatus = MetadataTargetCompanyPaymentStatus.NoAccounts;
  }

  return createEntity(
    id,
    InvitationMetadata.meta.name,
    {
      isValid: isValid,
      isUserRegistration: isEmployeeRegistration || false,
      id: id,
      invitationId,
      referralToken,
      email: emailAddress,
      companyName,
      firstName,
      lastName,
      phoneNumber,
      from: {
        companyName: fromCompanyName,
        companyId: fromCompanyId
      },
      address: address ? {
        line1: address.address1,
        line2: address.address2,
        line3: address.address3,
        line4: address.address4,
        city: address.city,
        stateProvince: address.stateProvince,
        postalCode: address.postalCode,
        country: address.country
      } : {},
      invitationType,
      context,
      token,
      displayAddPaymentAccount: displayAddPaymentAccount || ShowAddPaymentAccountStatus.Shown,
      offerPersonalPortal: offerPersonalPortal || false,
      targetCompanyInfo: {
        status: targetCompanyStatus,
        paymentAccountStatus: targetPaymentAccountStatus,
        connectionType: AddressBookRelationships[targetCompanyInfo.connectionType]
          ? targetCompanyInfo.connectionType
          : 'None',
        isBankUnitedInvitedCompany: targetCompanyInfo.isBankUnitedInvitedCompany,
        isIndividual: targetCompanyInfo.isIndividual
      },
      subscription
    });
};
