/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, { Component } from 'react';
import FatalErrorLayout from 'layouts/FatalError';
import KnightErnieImage from 'images/ernie/knight.png';

import './notAuthorized.scss';

export default class NotAuthorized extends Component {
  static pageTitle = 'Not Authorized - Viewpost';

  constructor(props) {
    super(props);
  }

  render() {
    return (
      <FatalErrorLayout className="view-error403">
        <p className="error-code">
          <strong>403:</strong> Page Not Authorized
        </p>
        <h1>Hold your horses.</h1>
        <p className="error-message">
          At the moment, you’re not authorized to enter the section of the site requested.
          There’s no way you could have known that, so don’t sweat it.
          We apologize for the inconvenience.
          <br/>
          <a href="/">Take me back</a>
        </p>

        <div className="knight">
          <img src={KnightErnieImage} />
        </div>

      </FatalErrorLayout>
    );
  }
}
