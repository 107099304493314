/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, { Component } from 'react';
import BlueHeaderOnWhiteLayout from 'layouts/BlueHeaderOnWhite';
import ProvideIsMobile from 'decorators/ProvideIsMobile';
import { VerifyMicroDeposits } from 'schemas/payments/bankAccount';
import { SmallBannerNotification, NotificationStyleTypes } from 'components/Notification';
import Button from 'components/Button';
import HeaderText from 'components/HeaderText';
import BodyText from 'components/BodyText';
import FormattedMessage from 'components/FormattedMessage';
import FormJSX from 'components/Form/FormJSX';
import FieldOption from 'components/Form/FieldOption';
import CommonMessages from 'config/messages/common';
import connect from './index.connect';
import Messages from './index.messages';

class VerifyPaymentAccountOnboarding extends Component {
  constructor(props) {
    super(props);
    this.state = { formState: {} };
  }

  componentDidMount() {
    this.props.getPaymentAccountStatus(this.props.secureToken || this.props.match.params.token);
  }

  componentWillReceiveProps(nextProps) {
    if (!nextProps.isSubmitting && this.props.isSubmitting) {
      this.setState({ formState: {} });
    }
  }

  onSubmit() {
    let value = this.formRef.getValue();

    if (value) {
      this.props.verifyAccount({
        accountId: this.props.account.paymentAccountId,
        depositAmount1: `0.${value.depositAmount1 < 10 ? '0' : ''}${value.depositAmount1}`,
        depositAmount2: `0.${value.depositAmount2 < 10 ? '0' : ''}${value.depositAmount2}`,
        secureToken: this.props.secureToken || this.props.match.params.token
      });
    }
  }

  render() {
    let content = null;

    if (this.props.account) {
      if (this.props.account.isLocked
        || (this.props.verification && this.props.verification.verificationFailed)) {
        content = (
          <HeaderText>
            <Messages.FailureHeader.Message />
          </HeaderText>
        );
      } else if (this.props.account.isVerified
        || (this.props.verification && this.props.verification.isVerified)) {
        content = (
          <HeaderText>
            <Messages.SuccessHeader.Message />
          </HeaderText>
        );
      } else if (this.props.account.isActive && !this.props.account.isVerified) {
        const icon = <span className="pad-x-half">$0.</span>;

        let alert = null;

        if (this.props.verification && !this.props.isSubmitting) {
          if (!this.props.verification.isVerified) {
            alert = (
              <SmallBannerNotification type={NotificationStyleTypes.Warning}>
                <Messages.IncorrectMicroDeposit.Message />
              </SmallBannerNotification>
            );
          }
        }

        content = (
          <div>
            <HeaderText>
              <Messages.Header.Message
                accountNumber={this.props.account.bankAccountNumberDisplayDigits} />
            </HeaderText>
            {alert ? (
              <div style={{padding: '16px 0'}}>
                {alert}
              </div>
            ) : null}
            <BodyText className="pad-y-8">
              <FormattedMessage {...Messages.Instructions} />
            </BodyText>
            <FormJSX modelType={VerifyMicroDeposits}
              onChange={formState => this.setState({ formState })}
              value={this.state.formState}
              ref={ref => this.formRef = ref}>
              <FieldOption name="depositAmount1"
                cols={6}
                label={Messages.DepositOne}
                inlineLabel={true}
                placeholder={Messages.Placeholder}
                prependIcon={icon} />
              <FieldOption name="depositAmount2"
                cols={6}
                label={Messages.DepositTwo}
                inlineLabel={true}
                placeholder={Messages.Placeholder}
                prependIcon={icon} />
              <Button type="submit"
                expand={true}
                cols={12}
                isProcessing={this.props.isSubmitting}
                isDisabled={this.props.isSubmitting}
                onClick={() => this.onSubmit()}
              >
                <CommonMessages.Submit.Message />
              </Button>
            </FormJSX>
          </div>
        );
      }
    }

    return (
      <BlueHeaderOnWhiteLayout contentWidth={this.props.isMobile ? 320 : 650}>
        <div className="viewstrap">
          {content}
        </div>
      </BlueHeaderOnWhiteLayout>
    );
  }
}

export default connect(ProvideIsMobile(VerifyPaymentAccountOnboarding));
