/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, { Component } from 'react';

import HeaderText from 'components/HeaderText';

import FormJSX from 'components/Form/FormJSX';
import FieldOption from 'components/Form/FieldOption';
import Button from 'components/Button';

import t from 'tcomb-validation';
import { Phone } from 'schemas/common/phone';
import Email from 'schemas/common/email/Email';

export default class HelpForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formState: {}
    };
  }

  onSubmit() {
    let value = this.formRef.getValue();
    if (value) {
      this.setState({ error: false });
      this.props.onSubmit({
        ...value,
        issue: 'Other'
      }, (success) => {
        if (!success) {
          this.setState({ error: true });
        }
      });
    }
  }

  renderForm() {
    return (
      <div style={{ textAlign: 'left' }}>
        <FormJSX ref={ref => this.formRef = ref}
          modelType={t.struct({
            name: t.String,
            phone: Phone,
            email: Email,
            comments: t.String
          })}
          value={this.state.formState}
          onChange={v => this.setState({ formState: v })}>
          <FieldOption name="name" cols={4} label="Name" />
          <FieldOption name="phone" cols={4} label="Phone" />
          <FieldOption name="email" cols={4} label="Email Address" />
          <FieldOption name="comments" label="Comments" type="textarea" />
        </FormJSX>
        <Button onClick={() => this.onSubmit()} expand={true}>
          Submit
        </Button>
      </div>
    );
  }

  renderError() {
    if (!this.state.error) return null;
    return (
      <div className="vp-error" style={{ padding: '15px' }}>
        We're having some trouble processing your request.
        <br />
        Please try again later or contact your customer for assistance.
      </div>
    );
  }

  render() {
    return (
      <div style={{textAlign: 'left'}}>
        <HeaderText style={{ paddingBottom: 25 }}>
          Contact our Support Team
        </HeaderText>
        <div style={{ paddingBottom: 15, lineHeight: '1.5' }}>
          Please complete the form below and explain your payment issue.
          A support specialist will be in touch with you shortly to assist you with processing your payment.
        </div>
        {this.renderForm()}
        {this.renderError()}
      </div>
    );
  }
}
