/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React from 'react';

import Icon, { IconTypes } from 'components/Icon';
import Tooltip from 'components/Tooltip';

export default function Guidepoint({
  number,
  arrow,
  color,
  onClick,
  message,
  title,
  left,
  top,
  right
}) {
  let content = <strong>{number}</strong>;
  if (arrow === 'left') {
    content = <Icon type={IconTypes.LeftArrow3} style={{ color: 'white' }} size={20} />;
  }
  if (arrow === 'right') {
    content = <Icon type={IconTypes.RightArrow3} style={{ color: 'white' }} size={20} />;
  }
  let bgColor = null;
  switch (color) {
    case 'red':
      bgColor = '#ff553d';
      break;
    case 'yellow':
      bgColor = '#ffbb33';
      break;
    case 'green':
      bgColor = '#99cc33';
      break;
    default:
      // blue
      bgColor = '#1e88e5';
      break;
  }
  let button = (
    <div className="component-vcchelp-circle-button" style={{
      backgroundColor: bgColor,
      borderRadius: '50%',
      display: 'inline-block',
      minWidth: '35px',
      minHeight: '35px',
      textAlign: 'center',
      color: 'white',
      fontSize: '20px',
      lineHeight: '35px',
      cursor: 'pointer'
    }}>
      <div className="darkener" style={{
        width: '100%',
        height: '100%',
        position: 'absolute',
        borderRadius: '50%'
      }}>&nbsp;</div>
      {content}
    </div>
  );

  if (message) {
    let overlay = (
      <div>
        {title
          ? (
            <div style={{
              borderBottom: '1px solid #ddd',
              lineHeight: '18px',
              fontWeight: 'bold',
              padding: '4px 0px 8px 0px',
              marginBottom: '8px'
            }}>
              {title}
            </div>
          )
          : null}
        {message}
      </div>
    );
    return (
      <Tooltip overlay={overlay} trigger={['click']}
        childWrapperStyle={{
          position: (!!left || !!top || !!right) ? 'absolute' : null,
          left,
          top,
          right
        }}>
        {button}
      </Tooltip>
    );
  }

  return (
    <div style={{
      position: (!!left || !!top || !!right) ? 'absolute' : null,
      left,
      top,
      right
    }} onClick={onClick}>
      {button}
    </div>
  );
}