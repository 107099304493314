/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { defaultMapper } from 'api/common/mappers/default';
import { createApiAction } from 'api/core';

const verifyPlaidAccount = createApiAction({
  method: 'post',
  url: '/api/next/account/verifyPlaidAccount',
  requestBodyMapper: defaultMapper,
  saveResponseToResult: true
});

export default verifyPlaidAccount;