/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import createAvailableActions, { createLegacyAvailableActions } from 'config/constants/actionsRegistry';

export const BankAccountActions = createLegacyAvailableActions('BankAccountActions', {
  Reset: 'ResetBankAccount',
  Disable: 'DisableBankAccount',
  Enable: 'EnableBankAccount',
  Add: 'AddBankAccount',
  Register: 'RegisterBankAccount',
  Reregister: 'ReregisterBankAccount',
  SetDefault: 'SetDefaultBankAccount',
  Verify: 'VerifyBankAccount',
  Delete: 'DeleteBankAccount',
  EditACH: 'EditACHInformation',
  Edit: 'EditBankAccount'
});

export const AddBankAccountActions = createAvailableActions(
  'BankAccountActions.AddBankAccount', [
    'AddSendAndReceiveAccount',
    'AddReceiveOnlyAccount'
  ]
);
