/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { debounce } from 'lodash';

const cachedErrors = [];
const cachedExceptions = [];

// This is to get around that when we minify, we strip console.*
// However, in this case, we really want it to show, so I'm being clever.
// It's the same 'ole console.error, just obfuscated.
const obfuscatedConsoleError = (...args) => (console || {})['error'](...args); //eslint-disable-line dot-notation

const obfuscatedConsoleWarning = (...args) => (console || {})['warn'](...args); //eslint-disable-line dot-notation

const obfuscatedConsoleInfo = (...args) => (console || {})['info'](...args); //eslint-disable-line dot-notation

// Cache the errors until the app loads, although sometimes the error is that the app can't load, so
// logging the error to the console at least makes it visible somewhere. There's a good chance it
// probably will be logged to the console again once the error service gets initialized
let errorHandler = (...args) => {
  if (args[1] && args[1].error) {
    console.error(args[1].error);
  }
  obfuscatedConsoleError(...args);
  cachedErrors.push([...args]);
};

// See comment above for what's going on here
let exceptionHandler = (...args) => {
  obfuscatedConsoleError(...args);
  cachedExceptions.push([...args]);
};

// The implementation of this in web-app is in redux/stores/instrumentErrorHandling.js

export const setHandler = (handler) => {
  errorHandler = handler;
  cachedErrors.forEach(e => errorHandler(...e));
  cachedErrors.length = 0;
};

export const setExceptionHandler = (handler) => {
  exceptionHandler = handler;
  cachedExceptions.forEach(e => exceptionHandler(...e));
  cachedExceptions.length = 0;
};

export const logException = (err) => {
  if (!exceptionHandler) return;
  exceptionHandler(err);
};

export const logWarning = obfuscatedConsoleWarning;

export const logInfo = obfuscatedConsoleInfo;

const logError = (message, data, options) => {
  if (!errorHandler) return;
  errorHandler(message, data, options);
};

const LoopCountThreshold = 20;
const LoopTimeInterval = 250; // In milliseconds;

export function instrumentInfiniteLoopWatcher(newThis, debug) {
  (function instrument() {
    const originalComponentDidUpdate = this.componentDidUpdate;

    const logCduError = debounce((data) => {
      logError(
        'componentDidUpdate loop threshhold broken',
        data,
        { includeStackTrace: true }
      );
    }, 2500, { leading: true, trailing: false, maxWait: 2500 });

    this.componentDidUpdate = (...args) => {
      const currentTime = (new Date()).getTime();

      this.cduCount = (this.cduCount || 0) + 1;
      this.firstCduTime = this.firstCduTime || currentTime;

      const msTimeDiff = currentTime - this.firstCduTime;
      if (msTimeDiff < LoopTimeInterval) {
        if (this.cduCount >= LoopCountThreshold) {
          logCduError({
            msTimeDiff,
            threshold: LoopCountThreshold,
            timeInterval: LoopTimeInterval
          });
          this.cduCount = 0;
          this.firstCduTime = currentTime;
        }
      } else if (msTimeDiff >= LoopTimeInterval) {
        if (debug) {
          console.log(`msTimeDiff: ${msTimeDiff} cduCount: ${this.cduCount}`);
        }

        this.cduCount = 0;
        this.firstCduTime = currentTime;
      }

      if (originalComponentDidUpdate) {
        originalComponentDidUpdate.call(this, ...args);
      }
    };
  }).call(newThis);
}

export default logError;
