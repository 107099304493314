/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import t from 'tcomb-validation';
import { defineSchema } from 'schemas';
import { PercentNotZero, Percent, PercentNotZeroNoMax } from 'schemas/common/percent';
import { createMaxLengthStringType } from 'schemas/common/string';
import Email from 'schemas/common/email/Email';
import {
  applyMinimumFractionDigits,
  Currency,
  CurrencyGreaterThan,
  CurrencyPositive
} from 'schemas/common/currency';
import { PositiveWholeNumber } from 'schemas/common/number';
import { PaymentMethod } from 'schemas/payments/bankAccount';

function buildAutoPayForm(options, invoiceLimit = 0, dailyCompanyLimit = 0) {
  // Make sure the All Company Limit is greater than the others
  let allCompanyType = Currency;
  let max = Math.max(invoiceLimit, dailyCompanyLimit);
  if (max > 0) {
    allCompanyType = CurrencyGreaterThan(max, { equalTo: true });
  }
  return t.struct({
    invoiceLimit: t.maybe(Currency),
    dailyCompanyLimit: t.maybe(Currency),
    allCompanyLimit: t.maybe(allCompanyType),
    autoPayAccount: t.maybe(t.enums(options))
  });
}

// Request Early Payment Modal
const RequestEarlyPaymentDiscountPercent = t.struct({
  discount: CurrencyPositive,
  discountPercent: Percent
}, 'RequestEarlyPaymentDiscountPercent');

const RequestEarlyPaymentExpires = t.struct({
  hoursToExpiration: PositiveWholeNumber
}, 'RequestEarlyPaymentExpires');

const EarlyPayProvideEmail = t.struct({
  email: Email
}, 'EarlyPayProvideEmail');

const DiscountStrategy = defineSchema({
  id: t.String,
  name: t.maybe(t.String),
  isDefault: t.maybe(t.Boolean),
  blackoutWindowDays: t.maybe(t.String),
  minimumReturnMode: t.maybe(t.String),
  targetReturnPercent: t.maybe(t.Number),
  actualReturnPercent: t.maybe(t.Number),
  targetDiscountPercent: t.maybe(t.Number),
  actualDiscountPercent: t.maybe(t.Number),
  assignedVendorCount: t.maybe(t.Number),
  totalSavedDollars: t.maybe(t.Number),
  discountStrategyType: t.maybe(t.String)
}, 'DiscountStrategy');

const DiscountStrategyVendor = defineSchema({
  id: t.String,
  name: t.maybe(t.String),
  assignedDiscountStrategyId: t.maybe(t.String),
  assignedDiscountStrategyName: t.maybe(t.String),
  lastOfferReturnPercent: t.maybe(t.Number),
  lastOfferDate: t.maybe(t.Date),
  totalInvoicesReceivedCount: t.maybe(t.Number),
  totalSavingsDollars: t.maybe(t.Number),
  averageInvoiceAmount: t.maybe(t.Number)
}, 'DiscountStrategyVendor');

const AutoAcceptDiscountForm = defineSchema({
  paymentAccountId: PaymentMethod,
  minReturn: PercentNotZero,
  dailyLimit: applyMinimumFractionDigits(CurrencyPositive, 0)
}, 'AutoAcceptDiscountForm');

const BlackoutWindowOptions = t.enums({
  0: '0 days',
  1: '1 day',
  2: '2 days',
  3: '3 days',
  4: '4 days',
  5: '5 days'
});

const MinimumReturnOptions = t.enums({
  NoMinimum: 'No Minimum',
  TargetReturn: 'Target Return'
});

const createNewStrategyForm = isNoDiscount => (
  t.struct({
    isDefault: t.Boolean,
    name: createMaxLengthStringType(50),
    targetReturnPercent: isNoDiscount ? Percent : PercentNotZeroNoMax,
    blackoutWindowDays: t.maybe(BlackoutWindowOptions),
    minimumReturnMode: t.maybe(MinimumReturnOptions)
  })
);

const AutoAcceptDiscountSettings = defineSchema({
  isEnabled: t.Boolean,
  minReturn: t.maybe(t.Number),
  dailyLimit: t.maybe(t.Number),
  paymentAccountId: t.maybe(t.String)
}, 'AutoAcceptDiscountSettings');

export {
  buildAutoPayForm,
  RequestEarlyPaymentDiscountPercent,
  RequestEarlyPaymentExpires,
  EarlyPayProvideEmail,
  DiscountStrategy,
  DiscountStrategyVendor,
  AutoAcceptDiscountForm,
  createNewStrategyForm,
  AutoAcceptDiscountSettings
};
