/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */
/* eslint max-len:0 */

import { toMessages } from 'config/messages';

export default toMessages('Registration.ExpeditedRegistration.WelcomeModal', {
  Header: 'Welcome to Viewpost',
  InvoiceDataFootnote: '*Invoice tracking is dependent on a customer’s data and account activity.'
});

