/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { isFunction } from 'lodash';
import { createRequestBody, createUrl } from './utils';

/*
  SEE Documentation at: /docs/Api.md
 */
export default class ApiConfiguration {
  constructor(originalConfig) {
    const {
      headerProviders,
      isSuccessAnError,
      mapErrorToPayload,
      mapHeadersToEntities,
      transformUrl
    } = originalConfig || {};

    this.headerProviders = [ ...(headerProviders || []) ];
    this.headersToEntitiesMapper = mapHeadersToEntities;
    this.mapErrorToPayload = mapErrorToPayload;
    this.isSuccessAnError = isSuccessAnError;
    this.transformUrl = transformUrl;
  }

  isInternalApi({ url }) {
    return url.startsWith('/');
  }

  getHeaders = params => this.headerProviders.reduce(
    (val, provider) => ({
      ...val,
      ...(provider(params || {}, this) || {})
    }),
    {}
  );

  setParam = (key, value) => {
    this[key] = value;
  }

  createRequest = (apiConfig, apiParams) => {
    const {
      headers: configHeaders,
      name,
      method,
      queryStringMapper,
      requestBodyMapper,
      url: urlTemplate,
      urlParamMapper
    } = apiConfig;

    let headers = this.getHeaders({
      isInternalApi: this.isInternalApi(apiConfig)
    });

    if (configHeaders) {
      headers = {
        ...headers,
        ...(
          isFunction(configHeaders)
            ? configHeaders(apiParams)
            : configHeaders
        )
      };
    }

    let url = null;
    try {
      url = createUrl(
        urlTemplate,
        apiParams,
        urlParamMapper,
        queryStringMapper
      );
    } catch (e) {
      throw new Error(`Cannot create URL for ${name}: ${e.toString()}`);
    }

    return {
      method: method || 'get',
      url: url,
      headers,
      body: createRequestBody(apiParams, requestBodyMapper)
    };
  }
}
