/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */
/* eslint max-len:0 */

import { defineMessages } from 'react-intl';

const common = defineMessages({});

const buttons = defineMessages({
  Clear: {
    id: 'Signature.buttons.Clear',
    defaultMessage: 'CLEAR'
  }
});

export default {
  ...common,
  buttons
};
