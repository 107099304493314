/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { toEnumObject } from './utils';

export const CorrelationId = {
  FetchMobileSignature: 'FetchMobileSignature'
};

export const NewUserChecklistSteps = toEnumObject([
  'AcceptConnection',
  'RegisterBankAccount',
  'VerifyMicroDeposits',
  'CreateInvoice',
  'ActivateDiscounts',
  'MakePayment',
  'CreateBill',
  'ViewBill',
  'ViewInvoice',
  'SendInvite',
  'SetupViewpostInbox',
  'RequestEarlyPayment',
  'AutomaticPayment',
  'ExportData',
  'PayStatement'
]);
