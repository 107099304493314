/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { SHOW_ACTION } from 'actions/guidedTour';
import { newState } from './utils';

export default function guidedTour(state = {}, action) {

  if (action.type === SHOW_ACTION) {
    return newState(state, {
      steps: action.steps,
      tourOptions: action.tourOptions,
      timestamp: action.timestamp
    });
  }

  if (action.type === '@@router/LOCATION_CHANGE') {
    return {};
  }

  return state;
}
