/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React from 'react';
import classNames from 'classnames';
import Menu from 'components/Menu/Menu';
import RendererProvider from 'components/RendererProvider';
import useWindowWidth from 'hooks/browser/useWindowWidth';
import './index.scss';

/** @docsignore */
const renderMenuItem = ({
  forceVerticalAlign,
  children,
  className,
  style
}) => (
  <div
    className={classNames(
      'item',
      { forceVerticalAlign },
      className
    )}
    style={style}
  >
    {children}
  </div>
);

const HorizontalNavMenuRenderer = ({
  children
}) => (
  <RendererProvider
    renderers={{
      renderMenuItem: renderMenuItem
    }}
  >
    <div className="nav-body">
      {children}
    </div>
  </RendererProvider>
);

const HorizontalNav = ({
  addAppContainerPadding,
  className,
  children,
  innerClassName,
  innerStyle,
  ...props
}) => {
  const windowWidth = useWindowWidth();

  let style = innerStyle;
  if (addAppContainerPadding) {
    style = {
      ...style,
      // needed to do this calculation in JS instead of CSS because IE does not cap negative paddings to 0
      padding: `0 ${Math.max((windowWidth - 1020) / 2, 0)}px`
    };
  }

  return (
    <nav
      {...props}
      className={classNames('horizontal-nav', className)}
    >
      <div
        className={classNames('inner', innerClassName)}
        style={style}
      >
        <Menu
          renderer={HorizontalNavMenuRenderer}
          {...props}
        >
          {children}
        </Menu>
      </div>
    </nav>
  );

};

export default HorizontalNav;
