/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import t from 'tcomb-validation';

const SchemaRegistry = {};

const AdditionalTypeMetadata = {};

export const defineSchemas = (schemas) => {
  Object.keys(schemas).forEach((key) => {
    SchemaRegistry[key] = schemas[key];
  });

  return schemas;
};

export const addSchema = (
  schema,
  metadata
) => {
  SchemaRegistry[schema.meta.name] = schema;

  if (metadata) {
    AdditionalTypeMetadata[schema.meta.name] = metadata;
  }

  return schema;
};

export const defineSchema = (
  struct,
  name,
  metadata
) => {
  if (!name) throw new Error('No name passed to defineSchema');

  const schema = t.struct(struct, name);
  SchemaRegistry[name] = schema;

  if (metadata) {
    AdditionalTypeMetadata[name] = metadata;
  }

  return schema;
};

export const getRegistryStats = () => ({
  schemaCount: Object.keys(SchemaRegistry).length
});

export const getSchema = key => SchemaRegistry[key];

export function applySchema(schema, value) {

  if (!schema || !schema.is) {
    console.error('applySchema was not passed a valid tcomb schema:', schema);
    return null;
  }

  // ensure value conforms to schema
  let validationResult = t.validate(value, schema);
  if (validationResult.errors.length > 0) {
    console.error(`Schema validation error:
        Value: ${value}
        Does not conform to schema: ${schema.meta.name}
        `, validationResult);
    return null;
  }

  // enforce compliance with schema
  return schema(value);
}

/*
 * This is an experimental pattern to be able to associate more data with a schema, beyond
 * validation. Data that is could be intrinsic to how the UI or consumer should handle this type,
 * such as knowing how many decimal points are expected.
 * The idea here is that our schema types are more than just validation, it is a representation of
 * the behavior we want, hence why we take types and apply a standard form component to them, the
 * argument here is this is just an extension of that. Moreover, this trying to standardize how to
 * know the special properties of a type, rather than encoding it as a property of the form
 * component, but then needing to align that with the API and other things that would work with the
 * value of that type.
 *
 * Originally the idea was to just set this data on the type, either on the root type or within
 * .meta, but that was quickly thwarted when the underlying typescript nature of tcomb strips those
 * values out when put through tcomb-form. So here we are.
 *
 * name - needs to be unique to the metadata you want associated with it
 * type - the type for this to subtype to apply the metadata to
 * metadata - an object with the properties you want associated
 */
export const applyMetadata = (name, type, metadata) => {
  AdditionalTypeMetadata[name] = metadata;

  return t.subtype(type, () => true, name);
};

/*
 * This will always return an object.
 */
export const getMetadata = (type) => {
  const { displayName } = type || {};

  // TODO: Could recurse down the subtypes and collect multiple
  // sets of metadata or just to 'hop' past t.maybes, but that use case
  // isn't present yet.
  return AdditionalTypeMetadata[displayName] || {};
};

export const getMetadataOf = name => AdditionalTypeMetadata[name] || {};
