/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, { useEffect, useState } from 'react';
import HeaderText, { HeaderTextVariants } from 'components/HeaderText';
import logError from 'services/ErrorService';
import Messages from './index.messages';
import './index.scss';

const InAppNotFound = () => {
  return (
    <div className="inAppNotFound view-error404">
      <HeaderText variant={HeaderTextVariants.PageHeader}>
        <Messages.Header.Message />
      </HeaderText>
      <br/>
      <Messages.Instructions.Message />
      <div className="notFoundImage">
        <div className="space-background" />
        <div className="astronaut-ernie" />
      </div>
    </div>
  );
};


export const ErrorLoggingInAppNotFound = (props) => {
  const [ hasLogged, setHasLogged ] = useState(false);

  useEffect(() => {
    if (!hasLogged) {
      // Shouldn't need to log anything else, the current page URL should be gotten for free.
      logError('User saw Not Found Ernie.');
      setHasLogged(true);
    }
  });

  return <InAppNotFound {...props} />;
};


export default InAppNotFound;