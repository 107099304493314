/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, { useMemo } from 'react';
import PathLink from 'components/Link/PathLink';
import Logo from 'components/Logo';
import Paths from 'config/paths';
import useNavigationParams from '../hooks/useNavigationParams';

export const useRootPath = () => {
  const {
    isCustomer,
    isPersonalUser,
    isPortalUser,
    isVendor
  } = useNavigationParams();

  return useMemo(
    () => {
      if (isPersonalUser) {
        if (isVendor) return Paths.Payments.Received;
        if (isCustomer) return Paths.Bills.ConsumerOpenBills;
      } else if (isPortalUser) {
        if (isVendor) return Paths.Payments.Received;
        if (isCustomer) return Paths.Bills.List;
      }

      return Paths.Dashboard;
    },
    [ isCustomer, isPersonalUser, isPortalUser, isVendor ]
  );
};

const LogoLink = ({
  height = 21,
  width = 108,
  variant
}) => {
  const rootPath = useRootPath();

  return (
    <PathLink
      path={rootPath}
      className="logo"
      activeClassName={variant === 'vp' || variant === 'ignoreHighlight'
        ? null
        : 'active navCaret noSecNav'}
    >
      <Logo
        height={height || 21}
        width={width || 108}
        variant={variant}
      />
    </PathLink>
  );
};

export default LogoLink;