/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import CompanyRadioButton from 'components/CompanyRadioButtonForm/CompanyRadioButton';
import Button from 'components/Button';
import { DEFAULT_EMPTY_GUID } from 'config/constants';
import Messages from './index.messages.js';
import './index.scss';

const CompanyMatchCard = ({match, onSelect, isChecked}) => {
  const addressBook = {
    id: match.companyId,
    companyName: match.name,
    address: match.address || {
      linesWithoutAddressee: [
        match.address1 !== match.name ? match.address1 : null,
        match.address1
      ].filter(a => a),
      city: match.city,
      stateProvince: match.state,
      postalCode: match.postalCode,
      country: match.country
    },
    phone: match.phone,
    email: match.email
  };

  return (
    <CompanyRadioButton company={addressBook}
      showLogo={false}
      onClick={onSelect}
      isChecked={isChecked} />
  );
};

class CompanyMatches extends Component {
  static propTypes = {
    matches: PropTypes.array.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let matches = this.props.matches.map((match, index) => (
      <CompanyMatchCard key={index}
        match={match}
        onSelect={() => this.setState({selected: match.companyId})}
        isChecked={id => id === this.state.selected } />
    ));

    return (
      <div>
        <p>
          <FormattedMessage {...Messages.Instructions} />
        </p>
        {matches}
        <CompanyRadioButton company={{id: DEFAULT_EMPTY_GUID, address: {}, companyName: 'Create New Company'}}
          showLogo={false}
          onClick={() => this.setState({selected: DEFAULT_EMPTY_GUID})}
          isChecked={() => DEFAULT_EMPTY_GUID === this.state.selected } />
        <Button expand={true}
          onClick={() => this.props.onSelect(this.state.selected)}
          style={{marginBottom: 40}}
          isDisabled={!this.state.selected || this.props.isLoading}>
          <FormattedMessage {...Messages.SelectButton} />
        </Button>
      </div>
    );
  }
}

export default CompanyMatches;
