/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { getEntity, createEntity, createEntityReference } from 'schemas/state';
import { VendorPaysFeesSetting, VendorPaysFeesSettingCounts } from 'schemas/settings/fee';
import pagedResponseMapper from 'api/common/mappers/pagedResponseMapper';

export function getVendorPaysFeesSettingsResponseMapper(apiResponse, state, apiParams) {
  let entities = [];
  if (apiResponse.response && apiResponse.response.customers) {
    entities = apiResponse.response.customers.map((apiSetting) => {
      return createEntity(
        apiSetting.addressBookId,
        VendorPaysFeesSetting.meta.name,
        {
          id: apiSetting.addressBookId,
          companyName: apiSetting.companyName,
          isEnabled: !!apiSetting.isCovered
        }
      );
    });
  }

  // update counts
  let existingCounts = getEntity(state, createEntityReference(
    'current',
    VendorPaysFeesSettingCounts.meta.name
  )) || {};
  if (apiParams.includeNotCovered) {
    existingCounts.totalCount = apiResponse.response.totalCount;
  } else {
    existingCounts.totalEnabledCount = apiResponse.response.totalCount;
  }
  entities.push(createEntity(
    'current',
    VendorPaysFeesSettingCounts.meta.name,
    existingCounts
  ));

  return {
    entities,
    pagination: pagedResponseMapper({
      totalCount: apiResponse.response.totalCount
    }, apiParams).pagination
  };
}

export function updateVendorPaysFeesSettingsRequestMapper(apiParams) {
  if (apiParams.coverNewCustomers === true || apiParams.coverNewCustomers === false) {
    return {
      coverNew: !!apiParams.coverNewCustomers
    };
  }
  return {
    covered: apiParams.isEnabled,
    addressBookIds: apiParams.addressBookIds
  };
}
