/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React from 'react';
import { isObject } from 'lodash';

import ActionTip, { hasTipFor } from 'components/ActionTooltip/ActionTip';
import Tip from 'components/ActionTooltip/Tip';
import ListDropdownOption from 'components/Dropdown/ListDropdownOption';

/**
 * Creates an action dropdown action from an availableActionsState object
 *
 * Expects at minimum that a label is passed in for actionParams
 */
export const createActionFromState = (availableActionsState, availableAction, actionParams) => {
  if (!availableActionsState
    || !availableActionsState.availableActions
    || !availableActionsState.deniedReasons) {
    console.error('Invalid availableActionsState');
    return null;
  } if (!availableAction) {
    console.error('Invalid availableAction');
    return null;
  }

  const {
    // By default, actions that can't be done but have a reason will be shown, unless this is set
    hideWhenDisabled,
    // By default, actions that can't be done and don't have a reason will be hidden, unless this is set
    showWhenDenied,
    label,
    // Can pass in a message to display as help. Can be either be an object or element.
    // Pasing in an object allows the help title to be set.
    // Will display a denied reason before the help message.
    help: originalHelp,
    ...actionProps
  } = actionParams || {};

  if (!label) {
    // console.error('Missing action label');
  }

  const canDo = availableActionsState.availableActions.includes(availableAction);
  let deniedReason = null;

  if (!canDo) {
    deniedReason = (availableActionsState.deniedReasons[availableAction] || [])[0];

    if (!deniedReason && !showWhenDenied) return null;
    if (deniedReason && hideWhenDisabled) return null;
  }

  let help = null;

  if (!deniedReason) {
    if (React.isValidElement(originalHelp)) {
      help = originalHelp
        ? { message: originalHelp }
        : null;
    } else if (isObject(help)) {
      help = originalHelp;
    }
  } else if (originalHelp) {
    if (React.isValidElement(originalHelp)) {
      help = { message: (
        <div>
          <div style={{ paddingBottom: 8 }}>
            {deniedReason}
          </div>
          {originalHelp}
        </div>
      )};
    } else if (isObject(help)) {
      help = {
        ...originalHelp,
        message: (
          <div>
            <div style={{ paddingBottom: 8 }}>
              {deniedReason}
            </div>
            {originalHelp.message}
          </div>
        )
      };
    } else {
      console.error('Invalid help');
    }
  } else {
    help = { message: deniedReason };
  }

  return {
    ...actionProps,
    label,
    id: availableAction,
    isDisabled: !canDo,
    help
  };
};

/**
 * Creates a list of actions from configuration.
 *
 * Expects the keys of the config to be available actions and the value to be actionParams
 */
export const createActionsFromState = (availableActionsState, config) => Object.keys(config).reduce((val, key) => {
  const actionConfig = config[key];

  const action = createActionFromState(availableActionsState, key, actionConfig);

  if (action) {
    val.push(action);
  }

  return val;
}, []);

export const createListOptions = (
  actions,
  {
    useDropdownWithHelp
  } = {}
) => {
  return (actions || []).map((action, index) => {
    if (!action) {
      console.error('Invalid action');
      return;
    }

    const {
      help,
      isDisabled,
      label,
      name,
      onClick,
      path
    } = action;

    let optionHelp = null;
    if (useDropdownWithHelp) {
      optionHelp = hasTipFor(action)
        ? <ActionTip action={action} />
        : null;
    } else if (help && help.message) {
      optionHelp = (
        <Tip title={help.title} message={help.message} />
      );
    }

    return (
      <ListDropdownOption
        key={index}
        id={name}
        help={optionHelp}
        onClick={onClick}
        isDisabled={isDisabled}
        path={path}
      >
        {label}
      </ListDropdownOption>
    );
  });
};

export const createListOptionsFromState = (
  availableActionsState,
  config
) => createListOptions(
  createActionsFromState(availableActionsState, config)
);

export const createOptionFromState = (
  availableActionsState,
  availableAction,
  optionParams
) => {
  if (!availableActionsState
    || !availableActionsState.availableActions
    || !availableActionsState.deniedReasons) {
    console.error('Invalid availableActionsState');
    return null;
  } if (!availableAction) {
    console.error('Invalid availableAction');
    return null;
  }

  const canDo = availableActionsState.availableActions.includes(availableAction);

  return {
    isDisabled: !canDo,
    ...(optionParams || {})
  };
};
