/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React from 'react';
import PropTypes from 'prop-types';
import FormattedMessage from 'components/FormattedMessage';
import Tip from '../Tip';
import { ActionDescription } from './index.messages';

export const hasTipFor = action => !!ActionDescription[action.id] || action.help;

const newActions = [];

/**
 * The tip component that maps action to tip messaging
 */
const ActionTip = ({
  action
}) => {
  let message = null;
  let title = null;

  if (action.id) {
    title = action.label;

    if (action.help) {
      message = action.help;
    } else if (ActionDescription[action.id]) {
      message = <FormattedMessage {...ActionDescription[action.id]}/>;
    }
  }

  const isNew = action.isNew || newActions.includes(action.id);

  return (
    <Tip
      title={title}
      message={message}
      isNew={isNew}
    />
  );
};

ActionTip.propTypes = {
  action: PropTypes.shape({
    /** The ID of the action you want the tooltip for */
    id: PropTypes.string,
    /** label (name) of action */
    label: PropTypes.node,
    /** optional help message (if default not available) */
    help: PropTypes.node,
    /** optional new indicator */
    isNew: PropTypes.bool
  }).isRequired
};

export default ActionTip;
