/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { createApiAction } from 'api/core';

import EntityContext from 'config/constants/schemas/EntityContext';

const selectSubscriptionOption = createApiAction({
  method: 'post',
  url: '/api/subscription/option',
  requestBodyMapper: ({
    campaignId,
    creditCardToken,
    selection
  }) => ({
    subscriptionCampaignId: campaignId,
    selection,
    creditCardToken
  }),
  responseMapper: () => ({
    updated: {
      contexts: [ EntityContext.Identity ]
    }
  })
});

export default selectSubscriptionOption;