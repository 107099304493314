/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { createApiAction } from 'api/core';

import actionNumberToAction from './mappers/actionNumberToAction';

const twoFactorSubmitResetPasswordRequest = createApiAction({
  method: 'post',
  url: '/ajax/resetPassword/twoFactorAuth',
  requestBodyMapper: ({
    code,
    token
  }) => ({
    authenticationCode: code,
    token
  }),
  responseMapper: ({
    success,
    response,
    messages
  }) => {
    if (!success || !response) return {};

    const {
      action,
      isLocked,
      isValid,
      newPasswordToken
    } = response;

    if (isLocked) {
      return {
        result: { failure: 'Locked' }
      };
    }

    if (isValid != null && !isValid) {
      return {
        result: {
          action: 'TwoFactorRequired',
          newPasswordToken: newPasswordToken,
          twoFactor: {
            reason: 'InvalidCode'
          }
        }
      };
    }

    return {
      result: {
        action: actionNumberToAction(action, newPasswordToken),
        newPasswordToken
      }
    };
  }
});

export default twoFactorSubmitResetPasswordRequest;