/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, { cloneElement, Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './index.scss';

export const NotificationTypes = {
  Toast: 'Toast',
  SmallBanner: 'SmallBanner',
  FullBanner: 'FullBanner'
};

export const NotificationStyleTypes = {
  Default: 'default',
  Info: 'info',
  Success: 'success',
  Warning: 'warning',
  Error: 'error',
  Disabled: 'disabled',
  Terms: 'terms'
};

export const Props = {
  notificationType: PropTypes.oneOf([
    NotificationTypes.Toast,
    NotificationTypes.SmallBanner,
    NotificationTypes.FullBanner
  ]),
  type: PropTypes.oneOf([
    NotificationStyleTypes.Default,
    NotificationStyleTypes.Info,
    NotificationStyleTypes.Success,
    NotificationStyleTypes.Warning,
    NotificationStyleTypes.Error,
    NotificationStyleTypes.Disabled,
    NotificationStyleTypes.Terms
  ]),
  onClick: PropTypes.func,
  onClose: PropTypes.func,
  header: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  renderFooter: PropTypes.func,
  shakeOnEnter: PropTypes.bool
};

export default class Notification extends Component {
  static propTypes = Props;

  render() {
    const {
      children,
      className,
      onClick,
      onClose,
      type,
      footer: originalFooter,
      header,
      message,
      renderFooter,
      style,
      shakeOnEnter
    } = this.props;

    let footer = null;
    if (renderFooter) {
      footer = renderFooter(onClose);
    } else if (originalFooter) {
      footer = cloneElement(originalFooter, { onClose });
    }

    const classes = classnames(
      'component-notification',
      `style-${type}`,
      { 'shake-on-enter': shakeOnEnter },
      className
    );

    const messageClasses = classnames(
      'message',
      { 'has-no-close': !onClose }
    );

    return (
      <div>
        <div className={classes} style={style} onClick={onClick}>
          {onClose ? <button type="button" className="close-button" onClick={onClose}>×</button> : null}
          <div className="title">{header}</div>
          <div className={messageClasses}>{children || message}</div>
          {footer}
        </div>
      </div>
    );
  }
}

export const SmallBannerNotification = props => (
  <Notification notificationType={NotificationTypes.SmallBanner} {...props} />
);
