/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */
/* eslint max-len:0 */

import { toMessages } from 'config/messages';

export default toMessages('BeneficalOwnerCollectionPanel', {
  Instruction: `To help the government fight financial crime, Federal regulation requires certain financial institutions to obtain, verify,
                and record information about the beneficial owners of companies. Requiring
                the disclosure of key individuals who own or control your company (i.e., the beneficial owners) helps law enforcement
                investigate and prosecute these crimes.
                {br}{br}
                {b}Failure to provide this information may restrict your ability to make electronic payments with some trading partners.{b}
                `,
  ControllingOwnerInstruction: `Please provide the following information about
                an individual with significant responsibility for managing your company (e.g., a Chief Executive
                Officer, Chief Financial Officer, Chief Operating Officer, Managing Member, General Partner, President,
                Vice President, or Treasurer).`,
  BeneficialOwnerInstruction: `Please provide the following information
    {count, plural,
      one {about this owner.}
      other {about each of the {count} owners.}
    }`,
  BeneficialOwnerCount: `How many individuals own, directly or indirectly{tooltip}, 25 percent or more of the equity interests of
                your company? (e.g., each natural person that owns 25 percent or more of the shares of a corporation)`,
  IndirectOwnershipTooltip: `{b}Indirect Ownership{b}
    {br}
    An indirect owner has ownership through another company.
    {br} {br}
    Example: Your company is 50% owned by Company X. John Doe owns 80% of Company X. Thus, John Doe indirectly owns 40% (80% of 50%) of your company.
    Since John Doe owns more than 25% of your company, he must be included in the list of owners here.
  `,
  CertifyStatement: `I certify that the information provided above is complete and true, to the best of my knowledge.`
});
