/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import t from 'tcomb-validation';

import { toMessages } from 'config/messages';

const Messages = toMessages('Schemas.SelectedCompanyOrganizationNode', {
  OrgNodeNotSelectedError: 'Select a recipient to send to.'
});

function isValidSelectedCompanyOrgNode(value) {
  return !!(value && value.id && value.name);
}

const SelectedCompanyOrganizationNode = t.subtype(t.Object, (value) => {
  return isValidSelectedCompanyOrgNode(value);
}, 'SelectedCompanyOrgNode');

SelectedCompanyOrganizationNode.getValidationErrorMessage = (value, path, context) => {
  if (!isValidSelectedCompanyOrgNode(value) && context && context.intl) {
    return context.intl.formatMessage(Messages.OrgNodeNotSelectedError);
  }

  return '';
};

export default SelectedCompanyOrganizationNode;