/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import moment from 'moment';
import t from 'tcomb-validation';
import { PaymentData } from 'api/payments/quickPay.schemas';
import { defineSchema, addSchema } from 'schemas';
import { createMaxLengthStringType } from 'schemas/common/string';
import { LooseAddress } from 'schemas/common/address';
import {
  currencyBounded,
  CurrencyPositiveAllowZero,
  MAX_PAYMENT_AMOUNT
} from 'schemas/common/currency';
import { VpxDiscount, InvoiceLateFee } from 'schemas/invoicing/invoice';
import { isCurrentOrFutureDate } from 'schemas/common/date';
import CompanyOrganizationNode from 'schemas/network/CompanyOrganizationNode';
import { getPaymentMethodString } from 'utils/paymentUtils';
import { PaymentTracker } from 'schemas/payments/tracker';
import { PostalCode } from 'schemas/common/address';

import Messages from './payments.messages';
import {
  CardNumber,
  CardExpirationDate,
  CardVerificationCode
} from './creditCard';
import PaymentBase, {
  PaymentMethodTypes,
  PaymentRole
} from './PaymentBase';

export {
  PaymentMethodTypes,
  PaymentRole
};

const COMMENT_MAXLENGTH = 254;

export function createPaymentMethodTypeEnum(paymentMethodTypes) {
  let enumMap = {};
  paymentMethodTypes.forEach((method) => {
    enumMap[method] = getPaymentMethodString({ methodValue: method });
  });

  return t.enums(enumMap);
}

const ReceivedPayment = addSchema(PaymentBase.extend({
}, 'ReceivedPayment'));

const PaymentRemittance = defineSchema({
  id: t.maybe(t.String),
  reference: t.maybe(t.String),
  date: t.maybe(t.Date),
  gross: t.maybe(t.Number),
  discount: t.maybe(t.Number),
  adjustments: t.maybe(t.Number),
  payment: t.maybe(t.Number),
  description: t.maybe(t.String),
  invoiceAmount: t.maybe(t.Number),
  invoiceBalance: t.maybe(t.Number),
  companyNodeName: t.maybe(t.String),
  invoiceAttachmentId: t.maybe(t.String),
  invoiceAttachmentFileName: t.maybe(t.String),
  companyNodeHierarchy: t.maybe(t.list(CompanyOrganizationNode)),
  companyReferenceId: t.maybe(t.String),
  isUsingCustomRemittance: t.maybe(t.Boolean)
}, 'PaymentRemittance');

const PaymentAttachment = defineSchema({
  id: t.maybe(t.String),
  user: t.maybe(t.String),
  fileUrl: t.maybe(t.String),
  fileName: t.maybe(t.String),
  uploadedDate: t.maybe(t.String),
  canDelete: t.Boolean,
  status: t.maybe(t.String),
  settlementId: t.maybe(t.String)
}, 'PaymentAttachment');

const PaymentStatus = defineSchema({
  label: t.maybe(t.String),
  value: t.maybe(t.String),
  count: t.maybe(t.Number),
  balance: t.maybe(t.Number)
}, 'PaymentStatus');


const VoidPayment = t.struct({
  comment: createMaxLengthStringType(COMMENT_MAXLENGTH)
}, 'VoidPayment');

const RemittableItem = defineSchema({
  id: t.maybe(t.String),
  reference: t.maybe(t.String),
  date: t.maybe(t.Date),
  originalBalance: t.maybe(t.Number),
  lateFee: t.maybe(t.Number),
  lateFeePaidAmount: t.maybe(t.Number),
  appliedDiscount: t.maybe(t.Number),
  appliedPayment: t.maybe(t.Number)
}, 'RemittableItem');

const isPaymentDate = (testDate) => {
  if (isCurrentOrFutureDate(testDate)) {
    const nextYear = moment().startOf('d').add(1, 'y');

    let date;
    if (typeof testDate === 'string') {
      date = moment(testDate, 'M/D/YYYY');
    } else {
      date = moment(testDate);
    }

    if (date.isValid()) {
      return date.isSameOrBefore(nextYear);
    }
  }

  return false;
};

const PaymentDate = t.subtype(t.Date, isPaymentDate);
PaymentDate.getValidationErrorMessage = (value, path, context) => {
  if (!isPaymentDate(value) && context && context.intl) {
    return context.intl.formatMessage(Messages.InvalidPaymentDate);
  }

  return '';
};

const ReceivedPaymentDateForm = t.struct({
  date: t.Date
}, 'ReceivedPaymentDateForm');

const PaymentQueueDateForm = t.struct({
  paymentDate: PaymentDate
}, 'PaymentQueueDateForm');

const CreditCardPaymentForm = t.struct({
  cardholderName: t.String,
  cardNumber: CardNumber,
  expiration: CardExpirationDate,
  cvc: CardVerificationCode,
  billingPostalCode: PostalCode
}, 'CreditCardPaymentForm');

const QueueSubmitError = defineSchema({
  invalidPayables: t.maybe(t.list(t.Object)),
  settlementValidationMessages: t.maybe(t.list(t.String)),
  genericValidationMessages: t.maybe(t.list(t.String))
}, 'QueueSubmitError');

const PaymentQueueConfirmAddressForm = t.struct({
  optOut: t.Boolean
}, 'PaymentQueueConfirmAddressForm');


// TODO: Can we consolidate this with an invoice schema?
const QueuePayable = t.struct({
  id: t.maybe(t.String),
  masterInvoiceId: t.maybe(t.String),
  reference: t.maybe(t.String), // FIXME: this is really invoiceNumber
  dueDate: t.maybe(t.Date),
  balance: t.maybe(t.Number),
  amount: t.maybe(t.Number), // custom specified amount to pay
  vpxActiveDiscount: t.maybe(VpxDiscount),
  lateFee: t.maybe(InvoiceLateFee)
}, 'QueuePayable');

const PaymentQueuePayment = defineSchema({
  settlementId: t.maybe(t.String),

  creditCard: t.maybe(t.Boolean),
  paymentMethodId: t.maybe(t.String),
  payables: t.maybe(t.list(QueuePayable)),

  scheduledPayDate: t.maybe(t.Date),
  memo: t.maybe(t.String),

  // consolidated model with quickpay / digital checkbook
  paymentData: LooseAddress.extend(PaymentData)
}, 'PaymentQueuePayment');

const SettlementFees = defineSchema({
  credit: t.maybe(t.Number),
  digital: t.maybe(t.Number),
  digitalCount: t.maybe(t.Number),
  paper: t.maybe(t.Number),
  paperCount: t.maybe(t.Number),
  total: t.maybe(t.Number),
  vpx: t.maybe(t.Number),
  vpxCount: t.maybe(t.Number),
  additionalFeesApply: t.maybe(t.Boolean)
}, 'SettlementFees');

const createPaymentQueueAmountForm = ({ balance, lateFee }) => {
  let amountDue = balance;
  if (lateFee) {
    amountDue += lateFee.balance;
  }
  let lowerBound = 0;
  let upperBound = Math.min(amountDue, MAX_PAYMENT_AMOUNT);
  if (balance < 0) {
    lowerBound = amountDue;
    upperBound = 0;
  }
  return t.struct({
    amount: currencyBounded(lowerBound, upperBound)
  }, 'PaymentQueueAmountForm');
};

const PaymentQueueSubmitResults = defineSchema({
  success: t.Boolean,
  hasSentPayments: t.Boolean,
  hasScheduledPayments: t.Boolean,
  hasPendingPayments: t.Boolean
}, 'PaymentQueueSubmitResults');

const PaymentApplicationForm = t.struct({
  appliedDiscount: t.maybe(CurrencyPositiveAllowZero),
  appliedPayment: t.maybe(CurrencyPositiveAllowZero)
}, 'PaymentApplicationForm');

export {
  isPaymentDate,
  SettlementFees,
  ReceivedPayment,
  PaymentTracker,
  PaymentStatus,
  PaymentRemittance,
  PaymentAttachment,
  VoidPayment,
  RemittableItem,
  PaymentQueueDateForm,
  QueuePayable,
  PaymentQueuePayment,
  ReceivedPaymentDateForm,
  PaymentQueueSubmitResults,
  CreditCardPaymentForm,
  QueueSubmitError,
  PaymentQueueConfirmAddressForm,
  PaymentApplicationForm,
  createPaymentQueueAmountForm
};
