/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { CreditCardType } from 'schemas/payments/creditCard';

// Docs: https://stripe.com/docs/stripe.js
let stripe = null;
let publishableKey = null;

function requireStripe() {
  if (!stripe && global.window.Stripe) {
    stripe = global.window.Stripe;
  }

  if (!stripe) {
    throw new Error(`Stripe was attempted to be called but is not present.
      You may have tried to use stripe before it fully loaded.`);
  }

  stripe.setPublishableKey(publishableKey);
}

/*
  init functions
*/

export function isStripeLoaded() {
  if (!stripe && global.window.Stripe) {
    stripe = global.window.Stripe;
  }

  return !!stripe;
}

export const StripeJsScript = 'https://js.stripe.com/v2/';

export function setPublishableKey(key) {
  publishableKey = key;
}

/*
  Validation functions
*/

export function isValidCardNumber(cardNumber) {
  requireStripe();
  return stripe.card.validateCardNumber(cardNumber);
}

export function isValidExpirationDate(expirationDate) {
  requireStripe();
  return stripe.card.validateExpiry(expirationDate);
}

export function isValidCVC(cvc) {
  requireStripe();
  return stripe.card.validateCVC(cvc);
}

export function getCardType(cardNumber) {
  requireStripe();
  if (!cardNumber) {
    // stripe api does not like an undefined card value
    return 'Unknown';
  }

  let cardType = stripe.card.cardType(cardNumber);
  // normalize card type to match CreditCardAccount model
  switch (cardType) {
    case 'Visa': return CreditCardType.meta.map.Visa;
    case 'MasterCard': return CreditCardType.meta.map.MasterCard;
    case 'American Express': return CreditCardType.meta.map.Amex;
    case 'Discover': return CreditCardType.meta.map.Discover;
    case 'Diners Club': return CreditCardType.meta.map.DinersClub;
    case 'JCB': return CreditCardType.meta.map.JCB;
    default: return CreditCardType.meta.map.Unknown;
  }
}

/*
  Redux actions
*/

const CREATE_CARD_TOKEN_FETCH_TYPE = 'STRIPE@createCardToken';
const CREATE_CARD_TOKEN_SUCCESS_TYPE = 'STRIPE@createCardToken_success';
const CREATE_CARD_TOKEN_FAIL_TYPE = 'STRIPE@createCardToken_fail';

export const createCardTokenAsync = ({
  cardNumber,
  expirationDate,
  cvc,
  cardholderName,
  billingPostalCode
}) => dispatch => new Promise(
  (resolve, reject) => {
    requireStripe();

    dispatch({
      type: CREATE_CARD_TOKEN_FETCH_TYPE,
      payload: {
        number: cardNumber,
        exp: expirationDate,
        cvc: cvc,
        name: cardholderName,
        address_zip: billingPostalCode
      }
    });

    stripe.card.createToken(
      {
        number: cardNumber,
        exp: expirationDate,
        cvc: cvc,
        name: cardholderName,
        address_zip: billingPostalCode
      },
      // See https://stripe.com/docs/stripe-js/v2#collecting-card-details for
      // an example of what this response looks like.
      //
      // response.id => credit card token
      // response.error.message => error message
      (status, response) => {
        if (response.error) {
          const action = {
            type: CREATE_CARD_TOKEN_FAIL_TYPE,
            payload: response
          };

          dispatch(action);
          resolve([ false, action ]);
          return;
        }

        const action = {
          type: CREATE_CARD_TOKEN_SUCCESS_TYPE,
          payload: response
        };

        dispatch(action);
        resolve([ true, action ]);
      }
    );
  }
);

export const createCardToken = (cardInfo, onCompleteCallback) => async (dispatch) => {
  const result = await dispatch(createCardTokenAsync(cardInfo));
  onCompleteCallback?.(...result);
};

createCardToken.actionTypes = {
  fetch: CREATE_CARD_TOKEN_FETCH_TYPE,
  success: CREATE_CARD_TOKEN_SUCCESS_TYPE,
  fail: CREATE_CARD_TOKEN_FAIL_TYPE
};
