/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { createApiAction } from 'api/core';

import logonResponseMapper from '../mappers/logonResponseMapper';

const completeResetPassword = createApiAction({
  method: 'post',
  url: '/ajax/resetPassword/complete',
  requestBodyMapper: ({
    token,
    newPasswordToken,
    password
  }) => ({
    token,
    newPasswordToken,
    password,
    confirmPassword: password
  }),
  responseMapper: logonResponseMapper
});

export default completeResetPassword;