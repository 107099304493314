/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { defineSchema } from 'schemas';
import Email from 'schemas/common/email/Email';
import { Password, createMatchingPassword } from 'schemas/common/password';
import { PaymentMethod } from 'schemas/payments/bankAccount';
import { OneClickPayTimings } from 'config/constants';
import t from 'tcomb-validation';

const UsersForCompanyGroup = t.struct({
  id: t.String,
  name: t.String,
  permissions: t.maybe(t.String)
}, 'UsersForCompanyGroup');

const UsersForCompanyUser = t.struct({
  groups: t.list(UsersForCompanyGroup)
}, 'UsersForCompanyUser');

const UsersForCompany = defineSchema({
  hasInactiveUsers: t.Boolean,
  users: t.list(UsersForCompanyUser)
}, 'UsersForCompany');

const CompanyUser = defineSchema({
  id: t.String,
  userId: t.String,
  companyUserId: t.String,
  firstName: t.String,
  lastName: t.String,
  isRegistered: t.Boolean,
  isActive: t.Boolean,
  isInvited: t.Boolean,
  isOwner: t.Boolean,
  groups: t.list(t.String),
  lastLogonDate: t.maybe(t.String)
}, 'CompanyUser');

const createNewCompanyUserForm = (value) => {
  let fields = {
    firstName: t.String,
    lastName: t.String,
    emailAddress: Email,
    isActive: t.maybe(t.Boolean)
  };

  if (value.isActive) {
    fields.sendInvitation = t.maybe(t.Boolean);
  }
  return t.struct(fields, 'NewCompanyUserForm');
};

const UserName = t.struct({
  firstName: t.String,
  lastName: t.String
}, 'UserName');

const UserEmail = t.struct({
  email: Email
}, 'Email');

const createUserPassword = password => t.struct({
  oldPassword: t.String,
  password: Password,
  confirmPassword: createMatchingPassword(password)
});

const createSecurityQuestionsEnum = (questions) => {
  let enumObject = {};
  questions.forEach(question => enumObject[question] = question);

  return t.enums(enumObject);
};

const createUserSecurityQuestions = (questions, selectedQuestions) => {
  let questions1Enum = createSecurityQuestionsEnum(
    questions.filter(question => question === selectedQuestions[0] || !selectedQuestions.includes(question))
  );
  let questions2Enum = createSecurityQuestionsEnum(
    questions.filter(question => question === selectedQuestions[1] || !selectedQuestions.includes(question))
  );
  let questions3Enum = createSecurityQuestionsEnum(
    questions.filter(question => question === selectedQuestions[2] || !selectedQuestions.includes(question))
  );

  return t.struct({
    question1: questions1Enum,
    question2: questions2Enum,
    question3: questions3Enum,
    answer1: t.String,
    answer2: t.String,
    answer3: t.String
  }, 'UserSecurityQuestions');
};

const UserEmailPreferences = t.struct({
  CustomerApprovesInvoice: t.maybe(t.Boolean),
  CustomerRejectsDocument: t.maybe(t.Boolean),
  CustomerVoidsBill: t.maybe(t.Boolean),
  VendorSendsYouAnInvoice: t.maybe(t.Boolean),
  InvoiceReminders: t.maybe(t.Boolean),
  VendorVoidsOrUnvoidsInvoice: t.maybe(t.Boolean),
  InvoiceApprovalRequest: t.maybe(t.Boolean),
  PaymentSent: t.maybe(t.Boolean),
  SendsVpxRequest: t.maybe(t.Boolean),
  EarlyPayNotifications: t.maybe(t.Boolean),
  DeclineOrCancelVpxRequest: t.maybe(t.Boolean),
  ConnectionRequest: t.maybe(t.Boolean),
  UpdateAddressBook: t.maybe(t.Boolean),
  SyncResultsAvailable: t.maybe(t.Boolean),
  ReceiveMarketingEmails: t.maybe(t.Boolean)
});

const UserProfile = defineSchema({
  createdDate: t.maybe(t.String),
  hasPasswordChanged: t.maybe(t.Boolean),
  hasPendingEmailChange: t.maybe(t.Boolean),
  passwordLastChanged: t.maybe(t.Date),
  securityQuestions: t.maybe(t.Array),
  userSecurityQuestionIds: t.maybe(t.Array),
  pendingEmailAddress: t.maybe(t.String)
}, 'UserProfile');

export const SecurityQuestionOptions = defineSchema({
  // Currently throwing the ID on the ground
  options: t.list(t.String)
}, 'SecurityQuestionOptions');

const OneClickPayTimingEnum = t.enums({
  [OneClickPayTimings.Immediate]: 'Sends payments immediately',
  [OneClickPayTimings.DueDate]: 'Schedule to pay on due date'
}, 'OneClickPayForm');

const OneClickPayForm = t.struct({
  paymentMethod: PaymentMethod,
  paymentTiming: OneClickPayTimingEnum
}, 'OneClickPayForm');

const EmailPreference = defineSchema({
  id: t.maybe(t.String),
  isSubscribed: t.maybe(t.Boolean),
  title: t.maybe(t.String)
}, 'EmailPreference');

export {
  CompanyUser,
  UsersForCompany,
  UserName,
  UserEmail,
  createUserPassword,
  createUserSecurityQuestions,
  UserEmailPreferences,
  createNewCompanyUserForm,
  UserProfile,
  OneClickPayForm,
  EmailPreference
};
