/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { isFailAction, isFetchAction, isSuccessAction } from 'api/core/utils';
import { QboActions, ModalActionTypes } from 'config/constants';
import { createEntity } from 'schemas/state';
import {
  QBOPayments,
  QBOAccountLink,
  QBOPastPayment,
  QBOConnection
} from 'schemas/qbo';
import { newState } from '../utils';

export function Qbo(state = {}, action) {
  if (isFetchAction(action, 'getBankAccounts')) {
    return newState(state, {
      loadingBankAccounts: true
    });
  }

  if (isSuccessAction(action, 'getBankAccounts')) {
    return newState(state, {
      loadingBankAccounts: false,
      paymentAccounts: action.payload.references,
      paymentsConfirmed: false
    });
  }

  if (isFetchAction(action, 'getQBOPayments')) {
    return newState(state, {
      loadingPayments: true,
      paymentsConfirmed: false
    });
  }

  if (isSuccessAction(action, 'getQBOPayments')) {
    return newState(state, {
      loadingPayments: false,
      payments: action.payload.entities,
      paymentsConfirmed: false
    });
  }

  if (action.type === QboActions.UpdatePayments) {
    let entities = [];

    if (action.payments) {
      action.payments.forEach((payment) => {
        entities.push(createEntity(
          payment.id,
          QBOPayments.meta.name,
          payment
        ));
      });
    }

    return newState(state, {
      payments: entities,
      paymentsConfirmed: false
    });
  }

  if (isFetchAction(action, 'getQBOCompanies')) {
    return newState(state, {
      loadingCompanies: true,
      paymentsConfirmed: false
    });
  }

  if (isSuccessAction(action, 'getQBOCompanies')) {
    // Just get the first company
    let company = action.payload.references[0];
    return newState(state, {
      qboCompany: company,
      isConnectedToQBO: !!company,
      paymentsConfirmed: false,
      loadingCompanies: false
    });
  }

  if (isFetchAction(action, 'getQBOCompany')) {
    return newState(state, {
      loadingCompany: true,
      isConnectedToQBO: false,
      paymentsConfirmed: false
    });
  }

  if (isSuccessAction(action, 'getQBOCompany')) {
    let company = action.payload.references[0];
    return newState(state, {
      qboCompany: company,
      loadingCompany: false,
      isConnectedToQBO: true,
      paymentsConfirmed: false
    });
  }

  if (isFailAction(action, 'getQBOCompanies')
    || isFailAction(action, 'getQBOCompany')) {
    return newState(state, {
      isConnectedToQBO: false,
      paymentsConfirmed: false,
      loadingCompany: false,
      loadingCompanies: false
    });
  }

  // Disconnect actions
  if (isFetchAction(action, 'disconnectQBO')) {
    return newState(state, {
      loadingDisconnect: true,
      paymentsConfirmed: false
    });
  }

  if (isSuccessAction(action, 'disconnectQBO')) {
    return newState(state, {
      loadingDisconnect: false,
      qboCompany: null,
      isConnectedToQBO: false,
      payments: [],
      paymentsConfirmed: false
    });
  }

  // Load Vendors
  if (isFetchAction(action, 'getCompanies')) {
    return newState(state, {
      paymentsConfirmed: false,
      loadingCompanies: true
    });
  }

  if (isSuccessAction(action, 'getCompanies')) {
    return newState(state, {
      companies: action.payload.entities.map(entity => entity.value),
      paymentsConfirmed: false,
      loadingCompanies: false
    });
  }

  if (action.type === ModalActionTypes.Show) {
    return newState(state, {
      modalShown: true
    });
  }

  if (action.type === ModalActionTypes.Close) {
    return newState(state, {
      modalShown: false
    });
  }

  if (isFetchAction(action, 'submitPayments')) {
    return newState(state, {
      paymentsConfirmed: false,
      paymentsSending: true,
      successfulPayments: action.payload.params.payments
    });
  }

  if (isFailAction(action, 'submitPayments')) {
    return newState(state, {
      paymentsConfirmed: false,
      paymentsSending: false
    });
  }

  if (isSuccessAction(action, 'submitPayments')) {
    return newState(state, {
      paymentsConfirmed: true,
      paymentsSending: false
    });
  }

  if (isFetchAction(action, 'getDocumentStoreValue')) {
    if (action.payload.params) {
      if (action.payload.params.key === QBOAccountLink.meta.name) {
        return newState(state, {
          loadingAccountLinks: true
        });
      } if (action.payload.params.key === QBOPastPayment.meta.name
          && action.meta.correlationId === 'QBO_load') {
        return newState(state, {
          loadingPastPayments: true
        });
      } if (action.payload.params.key === QBOConnection.meta.name) {
        return newState(state, {
          loadingLastConnection: true
        });
      }
    }
  }

  if (isSuccessAction(action, 'updateDocumentStoreValue')) {
    if (action.meta.params) {
      if (action.meta.params.key === QBOAccountLink.meta.name) {
        return newState(state, {
          existingAccountLinks: action.meta.params.value
        });
      }
    }
  }

  if (isSuccessAction(action, 'getDocumentStoreValue')) {
    if (action.meta.params) {
      let document = [((action.payload.entities[0] || {}).value || {}).value]
        .filter(value => value && (!!value.id || !!value.clientReferenceId));
      if (action.meta.params.key === QBOAccountLink.meta.name) {
        return newState(state, {
          loadingAccountLinks: false,
          existingAccountLinks: document
        });
      } if (action.meta.params.key === QBOPastPayment.meta.name) {
        return newState(state, {
          loadingPastPayments: false,
          pastPayments: document
        });
      } if (action.meta.params.key === QBOConnection.meta.name) {
        return newState(state, {
          loadingLastConnection: false,
          lastConnection: (((action.payload.entities || [])[0] || {}).value || {}).value || {}
        });
      }
    }
  }

  return state;
}
