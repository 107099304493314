/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { connect } from 'react-redux';
import OnboardingCampaignApi from 'api/onboarding/campaign';
import { createCacheActions } from 'actions/references';


const SubmitVccInterest = createCacheActions(
  'SubmitVccInterest',
  OnboardingCampaignApi.submitCampaignVccInterest
);

const MapStateToProps = state => ({
  isSubmitting: SubmitVccInterest.isCacheLoading(state)
});

const MapDispatchToProps = (dispatch, props) => ({
  onSubmit: (params, onComplete) => dispatch(SubmitVccInterest.makeApiCall(
    {
      ...params,
      secureToken: props.secureTokenContext.token
    },
    {
      apiMetadata: { onComplete }
    }
  ))
});

export default Component => connect(MapStateToProps, MapDispatchToProps)(Component);
