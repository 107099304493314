/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { isFunction, uniqueId } from 'lodash';
import { isApiAction, isFailAction, isFetchAction, isSuccessAction } from 'api/core/utils';
import { NetworkError } from 'schemas/common/network';
import { newState } from 'reducers/utils';
import { getEntity } from 'schemas/state';

export const isCoordinatorLoading = (state, name, defaultValue) => {
  if (!state.coordinator[name]) return defaultValue || false;
  return state.coordinator[name].loading;
};

export const hasCoordinatorFailed = (state, name) => {
  if (!state.coordinator[name]) return false;
  return state.coordinator[name].failed;
};

export const getCoordinatorFailedReason = (state, name) => {
  if (!state.coordinator[name]) return null;
  return state.coordinator[name].failureReason;
};

export const hasCoordinatorFailedWith = (state, name, failureType) => {
  if (!state.coordinator[name]
    || !state.coordinator[name].failed
    || !state.coordinator[name].failures
    || !state.coordinator[name].failures.length) return false;

  return state.coordinator[name].failures.some((failure) => {
    let entity = getEntity(state, failure);
    if (entity && failure.type === NetworkError.meta.name) {
      return entity.type === failureType;
    }
    return false;
  });
};

export const coordinateActions = (name, reducer, initialState) => {
  return (dispatch) => {
    dispatch({
      type: 'COORDINATE_ACTIONS',
      name,
      reducer,
      initialState: initialState || {}
    });
  };
};

const CoordinatorTimeoutType = 'COORDINATE_ACTIONS_TIMEOUT';

export const setCoordinatorTimeout = (func, timeout) => (dispatch) => {
  const requestId = uniqueId('timeout_');
  dispatch({ type: CoordinatorTimeoutType, id: requestId, start: true });
  setTimeout(() => {
    func();
    dispatch({ type: CoordinatorTimeoutType, id: requestId, start: false });
  }, timeout);
};

export const completeCoordinateApiActions = (name) => {
  return (dispatch) => {
    dispatch({
      type: 'COORDINATE_API_ACTIONS_COMPLETE',
      name
    });
  };
};

const checkAndFireFinalAction = (name, action) => (dispatch, getState) => {
  let fetching = getState().coordinator[name].fetching;
  const completeChain = () => dispatch(completeCoordinateApiActions(name));
  // If there is nothing left to do or this is the last API action, mark it down as complete
  if (!fetching.length) completeChain();
  else if (!action) return;
  else if (action.type === CoordinatorTimeoutType && !action.start && fetching.includes(action.id)) {
    completeChain();
  } else if (isApiAction(action) && fetching.length === 1 && fetching.includes(action.meta.requestId)) {
    completeChain();
  }
};

const determineIfFinalAction = (createDispatch, name, action, onDispatch) => (dispatch, getState) => {
  // If there is another action to do on completion of an API action, fire that off first
  if (onDispatch) {
    let onCompleteDispatcher = createDispatch();
    onDispatch(onCompleteDispatcher.dispatch, getState);
  }
  // Then fire off the check if there are more API requests to wait for
  dispatch(checkAndFireFinalAction(name, action));
};

export const coordinateApiActions = (name, apiActionChain) => {
  return (dispatch, getState) => {
    let createEnhancedDispatch = (initialProps = {}) => {
      let fetchesDispatched = 0;
      let props = { ...initialProps };

      let enhancedDispatch = (action) => {
        if (isFunction(action)) {
          return dispatch(
            (innerDispatch, innerGetState) => action(createEnhancedDispatch({...props }).dispatch, innerGetState)
          );
        }

        let modifiedAction = action;
        let onDispatch = null;

        if (isFetchAction(action) || isSuccessAction(action) || isFailAction(action)) {
          onDispatch = action.onDispatch;
          modifiedAction = {
            ...action,
            meta: {
              ...action.meta,
              correlationId: `${name}_Coordinator_${action.meta.requestId}`
            },
            onDispatch: !isFetchAction(action)
              ? determineIfFinalAction(createEnhancedDispatch, name, action, onDispatch)
              : null
          };
        } else if (action.type === CoordinatorTimeoutType) {
          modifiedAction = {
            ...action,
            coordinator: name,
            onDispatch: action.start
              ? null
              : determineIfFinalAction(createEnhancedDispatch, name, action, onDispatch)
          };
        }

        if (!modifiedAction) {
          console.log('Dispatching a null action - probably isn\'t intended');
        }

        return dispatch(modifiedAction);
      };

      return {
        dispatch: enhancedDispatch,
        getFetchesDispatched: () => fetchesDispatched
      };
    };

    let reducer = (state, action) => {
      if (action.type === 'COORDINATE_API_ACTIONS_COMPLETE' && action.name === name) {
        return newState(state, { loading: false });
      }

      if (isFetchAction(action)) {
        if (action.meta.correlationId && action.meta.correlationId.startsWith(`${name}_Coordinator`)) {
          return newState(state, {
            loading: true,
            fetching: [...state.fetching, action.meta.requestId]
          });
        }
      }

      if (isSuccessAction(action) || isFailAction(action)) {
        if (state.fetching.includes(action.meta.requestId)) {
          let nextState = {
            fetching: state.fetching.filter(id => id !== action.meta.requestId),
            failed: isFailAction(action) || state.failed
          };

          if (isFailAction(action)) {
            let failures = action.entities?.reduce?.((val, error) => {
              let newVal = val;
              if (error && error.type === 'NetworkError' && error.value.type === 'TooManyRequests') {
                newVal = newVal.concat(error.createReference());
              }
              return newVal;
            }, []);
            nextState.failures = (state.failures || []).concat(failures);
            nextState.failureReason = action.payload.errorReason;
          } else if (state.failures) {
            nextState.failures = [];
          }

          return newState(state, nextState);
        }
      }

      if (action.type === CoordinatorTimeoutType && action.coordinator === name) {
        if (action.start) {
          return newState(state, {
            loading: true,
            fetching: [...state.fetching, action.id ]
          });
        }

        return newState(state, {
          fetching: state.fetching.filter(id => id !== action.id)
        });
      }

      return state;
    };

    dispatch(coordinateActions(name, reducer, { loading: true, fetching: [], failed: false}));
    apiActionChain(createEnhancedDispatch().dispatch, getState);
    setTimeout(() => dispatch(checkAndFireFinalAction(name)), 1000);
  };
};

export const createCoordinatorActions = name => ({
  coordinateApiActions: (...args) => coordinateApiActions(name, ...args),
  isLoading: (state, ...args) => isCoordinatorLoading(state, name, ...args),
  getFailedReason: state => getCoordinatorFailedReason(state, name)
});
