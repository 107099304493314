/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { toEnumObject } from '../utils';

const LogonFailureReason = toEnumObject([
  // Authentication Failures
  'SessionExpired',
  'SessionKicked',
  'InvalidCredentials',
  'ExistingSession',
  'TwoFactorRequired',
  'SecurityQuestionAuthRequired',
  'VerifyAccountRequired',
  'RecoverPasswordRequired',

  // Authorization Failures
  'EmailVerificationRequired',
  'TemporarilyLocked',
  'CompanyLocked',
  'PermanentlyLocked',
  'CompanyClosed',
  'SimultaneousLogonLocked',
  'PasswordExpired',
  'UserInactive',
  'DoesNotBelongToCompany',
  'ChangeEmailTokenInvalid'
]);

export default LogonFailureReason;

export const CredentialsFailureReason = {
  InvalidUserName: 'InvalidUserName',
  InvalidPassword: 'InvalidPassword'
};

export const TwoFactorFailureReason = {
  InvalidCode: 'InvalidCode'
};

export const SecurityQuestionFailureReason = {
  InvalidAnswer: 'InvalidAnswer'
};