/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { get } from 'lodash';
import { CompanyAccountType } from 'config/constants/session';
import { getEntity } from 'schemas/state';
import logError from 'services/ErrorService';

export const getCompanySettings = (state, { notExpected = false, returnNull = false } = {}) => {
  const identity = getEntity(state, get(state, 'session.identity'));

  if (!notExpected && (!identity || !identity.companySettings)) {
    logError('Expected companySettings, but companySettings is missing.', null, { includeStackTrace: true });
  }

  // Returning at least an empty object so that at least a JS error doesn't
  // take React down. We have the logged error, so you there is visiblity into
  // a problem
  return (identity || {}).companySettings || (returnNull ? null : {});
};

export const isPortalExperience = state => getCompanySettings(state).accountType === CompanyAccountType.Portal;

export const isVendorPortalExperience = (state, { ignoreAccountType } = {}) => {
  const companySettings = getCompanySettings(state);

  if (ignoreAccountType == null || !ignoreAccountType) {
    if (companySettings.accountType !== CompanyAccountType.Portal) return false;
  }

  return companySettings.totalCustomers > 0;
};

export const isCustomerPortalExperience = (state, { ignoreAccountType } = {}) => {
  const companySettings = getCompanySettings(state);

  if (ignoreAccountType == null || !ignoreAccountType) {
    if (companySettings.accountType !== CompanyAccountType.Portal) return false;
  }

  return companySettings.totalVendors > 0;
};

export const isCorporateExperience = state => (
  CompanyAccountType.Corporate === getCompanySettings(state).accountType
);

export const getCompanyAdditionalSetting = (state, key) => {
  const { additionalSettings } = getCompanySettings(state);

  // TODO: Add some error logging similar to what we do for getCompanySettings
  return (additionalSettings || {})[key];
};

export const isVccEnabled = state => getCompanySettings(state).paymentOptimization?.vccEnabled === true;
