/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './index.css';
import classNames from 'classnames';
import Icon, { IconTypes } from 'components/Icon';

/**
 * Adds sort toggling functionality
 */
export default class SortButton extends Component {
  static propTypes = {
    onChange: PropTypes.func,
    sortField: PropTypes.shape({
      isDescending: PropTypes.bool
    })
  };

  static ASCENDING = '+';

  static DESCENDING = '-';

  constructor(props) {
    super(props);
    this.click = this.click.bind(this);
  }

  click(direction) {
    if (this.props.onChange) {
      this.props.onChange(direction);
    }
  }

  render() {
    let styleSuffix = 'none';
    let nextDirection = SortButton.ASCENDING;
    if (this.props.sortField) {
      if (this.props.sortField.isDescending) {
        styleSuffix = 'desc';
        nextDirection = SortButton.ASCENDING;
      } else {
        styleSuffix = 'asc';
        nextDirection = SortButton.DESCENDING;
      }
    }
    let classes = classNames('sort-button', `direction-${styleSuffix}`, 'nowrap');
    return (
      <div className={classes} onClick={() => {
        this.click(nextDirection);
      }}>
        {this.props.children}
        {/* IE needs the height to be set or it will render as 150px tall */}
        <Icon className="arrow-indicator" type={IconTypes.SortArrow} style={{height: 5}}/>
      </div>
    );
  }
}
