/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Tooltip, { Placements } from 'components/Tooltip';
import './index.scss';

/**
  A popover that is actually implemented via the tooltip component.
  There are some differences between react-popover and react-tooltip (e.g. performance, auto-adjustment behavior)
  and sometimes one might be preferred over the other.
 */
export default class TooltipPopover extends Component {
  static propTypes = {
    place: PropTypes.oneOf(['above', 'right', 'below', 'left']),
    isOpen: PropTypes.bool,
    content: PropTypes.node.isRequired, // the content of the popover
    variant: PropTypes.oneOf(['default']),
    className: PropTypes.string,
    style: PropTypes.object,
    onOuterClick: PropTypes.func
  };

  static defaultProps = {
    place: 'above',
    isOpen: true
  };

  render() {
    let placement = null;
    switch (this.props.place) {
      case 'right':
        placement = Placements.Right;
        break;
      case 'left':
        placement = Placements.Left;
        break;
      case 'below':
        placement = Placements.Bottom;
        break;
      default:
        placement = Placements.Top;
    }
    return (
      <Tooltip
        overlay={this.props.content}
        trigger={['click']}
        padContent={false}
        visible={this.props.isOpen}
        placement={placement}
        className={classNames(this.props.className, this.props.variant, this.props.place)}
        overlayStyle={this.props.style}
        onVisibleChange={(value) => {
          if (this.props.onOuterAction && !value) {
            // this is not an exact mapping, but close as we can get with the current impl
            this.props.onOuterAction();
          }
        }}>
        {/* the element to orient the popover around */}
        <span>
          {this.props.children}
        </span>
      </Tooltip>
    );
  }
}
