/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import classNames from 'classnames';
import React, { Component } from 'react';

export default class SubMenu extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    let classes = classNames('item', this.props.className);

    return (
      <div className={classes} style={this.props.style}>
        {this.props.children}
      </div>
    );
  }
}
