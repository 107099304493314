/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import Tooltip, { Placements } from 'components/Tooltip';
import Icon, { IconTypes } from 'components/Icon';

export { IconTypes, Placements };

const stopPropagation = e => e.stopPropagation();

/**
 * A simple tooltip with info icon toggle
 *
 * By default it activates on click
 */
const InfoTooltip = forwardRef(
  (
    {
      children: originalChildren,
      iconClassName,
      iconSize,
      iconStyle,
      iconType,
      padded,
      placement: originalPlacement,
      showOnHover,
      style,
      tooltipWrapperStyle,
      variant,
      wide
    },
    ref
  ) => {
    let placement = originalPlacement;
    let children = originalChildren;
    if (variant === 'tiny-right') {
      placement = Placements.Right;
      children = (
        <span className="text-tiny">
          {originalChildren}
        </span>
      );
    }

    return (
      <Tooltip
        childWrapperStyle={tooltipWrapperStyle}
        overlay={children}
        padContent={padded}
        placement={placement}
        ref={ref}
        trigger={showOnHover ? [ 'hover', 'click' ] : [ 'click' ]}
        wide={wide}
        onClick={stopPropagation}
      >
        <span style={style}>
          <Icon
            className={iconClassName}
            hoverable={true}
            size={iconSize}
            style={iconStyle}
            type={iconType || IconTypes.Info}
          />
        </span>
      </Tooltip>
    );
  }
);

InfoTooltip.propTypes = {
  iconStyle: PropTypes.object,
  placement: PropTypes.string
};

export default InfoTooltip;
