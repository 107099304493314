/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { isFetchAction, isSuccessAction } from 'api/core/utils';
import { newState } from './utils';

export function DownloadView(state = {}, action) {
  if (isFetchAction(action, 'getSyncPackages')) {
    return newState(state, {
      isLoading: true
    });
  }

  if (isSuccessAction(action, 'getSyncPackages')) {
    return newState(state, {
      packages: action.payload.references[0],
      isLoading: false
    });
  }

  return state;
}

export function FileUpload(state = {}, action) {
  if (isFetchAction(action, 'getSyncFiles30days')
    || isFetchAction(action, 'getSyncFiles60days')
    || isFetchAction(action, 'getSyncFiles90days')) {
    return newState(state, {
      isLoading: true
    });
  }

  if (isSuccessAction(action, 'getSyncFiles30days')
    || isSuccessAction(action, 'getSyncFiles60days')
    || isSuccessAction(action, 'getSyncFiles90days')) {
    return newState(state, {
      files: action.payload.files,
      isLoading: false
    });
  }

  if (isSuccessAction(action, 'getUploadLimitBytes')) {
    return newState(state, {
      uploadLimitBytes: action.payload.limit
    });
  }

  return state;
}
