/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React from 'react';
import Border from 'components/Border';
import './MobileListFilterToggle.scss';

const MobileListFilterToggle = ({
  children,
  label,
  onClick
}) => (
  <div onClick={onClick}>
    <Border className="mobile-filter-bar" onClick={onClick}>
      {label}
      <div className="mobile-filter-caret">
        {children}
      </div>
    </Border>
  </div>
);

export default MobileListFilterToggle;
