/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { isFailAction, isFetchAction, isSuccessAction } from 'api/core/utils';
import { QboActions } from 'config/constants';
import {
  QBOCustomerLink,
  QBOPastInvoice,
  QBOConnection
} from 'schemas/qbo';
import { ModalActionTypes } from 'config/constants';
import RetrievableAddressBookEntry from 'schemas/network/RetrievableAddressBookEntry';
import { newState } from '../utils';

export default (state = {}, action) => {
  if (isFetchAction(action, 'getQBOInvoices')) {
    return newState(state, {
      loadingInvoices: true,
      invoicesConfirmed: false
    });
  }

  if (action.type === QboActions.UpdateInvoices) {
    if (action.invoices) {
      return newState(state, {
        invoices: action.invoices
      });
    }
  }

  if (isSuccessAction(action, 'getQBOInvoices')) {
    let pastInvoiceIds = state.pastInvoices ? state.pastInvoices.map(invoice => invoice.id) : [];

    let invoices = action.payload.entities.filter(invoice => !pastInvoiceIds.includes(invoice.id));
    return newState(state, {
      loadingInvoices: false,
      invoices: invoices,
      invoicesConfirmed: false
    });
  }

  if (isFetchAction(action, 'getQBOCompanies')) {
    return newState(state, {
      loadingCompanies: true,
      invoicesConfirmed: false
    });
  }

  if (isSuccessAction(action, 'getQBOCompanies')) {
    // Just get the first company
    let company = action.payload.references[0];
    return newState(state, {
      qboCompany: company,
      isConnectedToQBO: !!company,
      invoicesConfirmed: false,
      loadingCompanies: false
    });
  }

  if (isFetchAction(action, 'getQBOCompany')) {
    return newState(state, {
      loadingCompany: true,
      isConnectedToQBO: false,
      invoicesConfirmed: false
    });
  }

  if (isSuccessAction(action, 'getQBOCompany')) {
    let company = action.payload.references[0];
    return newState(state, {
      qboCompany: company,
      loadingCompany: false,
      isConnectedToQBO: true,
      invoicesConfirmed: false
    });
  }

  if (isFailAction(action, 'getQBOCompanies')
    || isFailAction(action, 'getQBOCompany')) {
    return newState(state, {
      isConnectedToQBO: false,
      invoicesConfirmed: false,
      loadingCompany: false,
      loadingCompanies: false
    });
  }

  // Disconnect actions
  if (isFetchAction(action, 'disconnectQBO')) {
    return newState(state, {
      loadingDisconnect: true,
      invoicesConfirmed: false
    });
  }

  if (isSuccessAction(action, 'disconnectQBO')) {
    return newState(state, {
      loadingDisconnect: false,
      qboCompany: null,
      isConnectedToQBO: false,
      invoices: [],
      invoicesConfirmed: false
    });
  }

  // Load Customers
  if (isFetchAction(action, 'getCompanies')) {
    return newState(state, {
      invoicesConfirmed: false,
      loadingCompanies: true
    });
  }

  if (isSuccessAction(action, 'getCompanies')) {
    return newState(state, {
      companies: action.payload.entities.map(entity => entity.value),
      invoicesConfirmed: false,
      loadingCompanies: false
    });
  }

  if (action.type === ModalActionTypes.Show) {
    return newState(state, {
      modalShown: true
    });
  }

  if (action.type === ModalActionTypes.Close) {
    return newState(state, {
      modalShown: false
    });
  }

  if (isFetchAction(action, 'submitQBOInvoices')) {
    return newState(state, {
      invoicesSending: true,
      invoicesConfirmed: false,
      successfulInvoices: action.payload.params.request
    });
  }

  if (isSuccessAction(action, 'submitQBOInvoices')) {
    return newState(state, {
      invoicesSending: false,
      invoicesConfirmed: true
    });
  }

  if (isFailAction(action, 'submitQBOInvoices')) {
    return newState(state, {
      invoicesSending: false,
      invoicesConfirmed: false
    });
  }

  if (isFetchAction(action, 'getDocumentStoreValue')) {
    if (action.payload.params) {
      if (action.payload.params.key === QBOCustomerLink.meta.name) {
        return newState(state, {
          loadingCustomerLinks: true
        });
      } if (action.payload.params.key === QBOPastInvoice.meta.name) {
        return newState(state, {
          loadingPastInvoices: true
        });
      } if (action.payload.params.key === QBOConnection.meta.name) {
        return newState(state, {
          loadingLastConnection: true
        });
      }
    }
  }

  if (isFetchAction(action, 'batch')
  && action.meta.correlationId === 'qbo_create_address_books') {
    return newState(state, {
      addressBooksSaving: true,
      addressBooksSaved: false
    });
  }

  if (isSuccessAction(action, 'batch')
  && action.meta.correlationId === 'qbo_create_address_books') {
    let companies = state.companies.concat(action.payload.entities.filter((entity) => {
      return entity.type === RetrievableAddressBookEntry.meta.name;
    }).map(entity => entity.value));

    return newState(state, {
      addressBooksSaving: false,
      addressBooksSaved: true,
      companies: companies
    });
  }

  if (isFailAction(action, 'batch')
  && action.meta.correlationId === 'qbo_create_address_books') {
    return newState(state, {
      addressBooksSaving: false,
      addressBooksSaved: false
    });
  }

  if ( isFetchAction(action, 'updateAddressBookEmail')) {
    return newState(state, {
      emailSaving: true
    });
  }

  if (isSuccessAction(action, 'updateAddressBookEmail')) {
    if (state.companies && state.companies.length) {
      return newState(state, {
        emailSaving: false,
        companies: state.companies.map((company) => {
          if (company.id === action.meta.params.id) {
            company.email = action.meta.params.email || company.email;
          }
          return company;
        })
      });
    }
    return newState(state, {
      emailSaving: false
    });
  }

  if (isFailAction(action, 'updateAddressBookEmail')) {
    return newState(state, {
      emailSaving: false
    });
  }

  if (isSuccessAction(action, 'getDocumentStoreValue')) {
    if (action.meta.params) {
      let document = [((action.payload.entities[0] || {}).value || {}).value]
        .filter(value => value && !!value.id);
      if (action.meta.params.key === QBOCustomerLink.meta.name) {
        return newState(state, {
          loadingCustomerLinks: false
        });
      } if (action.meta.params.key === QBOPastInvoice.meta.name) {
        return newState(state, {
          loadingPastInvoices: false,
          pastInvoices: document
        });
      } if (action.meta.params.key === QBOConnection.meta.name) {
        return newState(state, {
          loadingLastConnection: false,
          lastConnection: (((action.payload.entities || [])[0] || {}).value || {}).value || {}
        });
      }
    }
  }

  return state;
};
