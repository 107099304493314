/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */
/* eslint max-len:0 */

import { toMessages } from 'config/messages';

export default toMessages('Registration.Form', {
  Submit: 'Create Account',
  CompanyName: 'Company Name',
  Email: 'Email Address',
  VerifyEmail: 'Verify Email Address',
  Title: 'Title',
  Phone: 'Work Phone',
  FirstName: 'First Name',
  LastName: 'Last Name',
  Password: 'Password',
  PasswordPlaceholder: 'Create Password',
  CompanyNameTooltip: 'Please use the legal name of your company. If you are an individual sole proprietor, your company name is your first and last name.',
  CompanyNameTooltipTitle: 'What\'s in a name?'
});