/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, { Component, isValidElement } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

/**
 * A content block often used in place of list view content when there are no results.
 * Image a usually a specific rounded ernie image and message usually explains what they would normally see here and/or how they can change it
 * so data appears here.
 */
export default class BlankSlate extends Component {
  static propTypes = {
    image: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    imageAlt: PropTypes.string.isRequired,
    primaryText: PropTypes.object,
    secondaryText: PropTypes.object
  };

  constructor(props) {
    super(props);
  }

  renderBlankImage(src, alt) {
    if (!src) return null;
    return (
      <div className="clear ng-scope">
        <img alt={alt} src={src} />
      </div>
    );
  }

  renderPrimaryText(message) {
    if (!message) return null;
    return (
      <h3 className="strong" style={{
        marginTop: '20px',
        marginBottom: '12px',
        wordWrap: 'break-word'
      }}>
        {isValidElement(message) ? message : <FormattedMessage {...message} />}
      </h3>
    );
  }

  renderSecondaryText(message) {
    if (!message) return null;
    return (
      <div className="subtext inline" style={{
        maxWidth: 330,
        color: '#666'
      }}>
        {isValidElement(message) ? message : <FormattedMessage {...message} />}
      </div>
    );
  }

  render() {
    let { image, imageAlt, primaryText, secondaryText, noMargin, children } = this.props;
    return (
      <section className="viewstrap typography text-center blank-slate"
        style={{marginTop: noMargin ? null : '40px'}}>
        <div style={{display: 'inline-block', maxWidth: 600}}>
          {this.renderBlankImage(image, imageAlt)}
          {this.renderPrimaryText(primaryText)}
          {this.renderSecondaryText(secondaryText)}
          <div style={{marginTop: 12}}>
            {children}
          </div>
        </div>
      </section>
    );
  }
}
