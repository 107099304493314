/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React from 'react';
import 'core-js/stable';
import ReactDOM from 'react-dom';
import 'regenerator-runtime/runtime';

import initializeApi from 'api';
import BrickmanApp from './BrickmanApp';

// create a contract on window for strawman to feed in initializing information
global.window.brickmanStart = async (
  elementId,
  modalElementId,
  antiForgeryToken,
  enableReduxDebugging,
  {
    acceptLanguage,
    analyticsId,
    inExternalTestingEnvironment
  } = {}
) => {
  await initializeApi();

  ReactDOM.render(
    (
      <BrickmanApp
        debug={enableReduxDebugging}
        modalElementId={modalElementId}
        acceptLanguage={acceptLanguage}
        analyticsId={analyticsId !== '' ? analyticsId : null}
        csrfToken={antiForgeryToken}
        inExternalTestEnvironment={inExternalTestingEnvironment === true
          || inExternalTestingEnvironment === 'true'}
      />
    ),
    document.getElementById(elementId)
  );
};
