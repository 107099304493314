/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React from 'react';
import { isObject } from 'lodash';
import { defineMessage, FormattedMessage } from 'react-intl';

import VpFormattedMessage from 'components/FormattedMessage';

import logError from 'services/ErrorService';

import isIE11 from 'utils/browser/isIE11';

const getId = ({ prefix, key, originalId }) => {
  // Use originalId if it is set, otherwise generate one
  const postfix = originalId || key;

  return prefix ? `${prefix}.${postfix}` : postfix;
};

const applyComponentFields = (message) => {
  const messageDefinition = { ...message };
  message.Message = ({ values, ...props }) => (
    <FormattedMessage {...messageDefinition} values={values ?? props} />
  );

  message.VpMessage = ({ values, ...props }) => (
    <VpFormattedMessage {...messageDefinition} values={values ?? props} />
  );
};

/*
  Takes in a message object of various formats and generates a react-intl
  message object format, as well as including helper fields to use the messages
  as components. This is trying to make creating messages easier and faster so you
  don't need to copy+paste the root ID for every message and alleviates importing
  FormattedMessage/VpFormattedMessage when needing the message.

  e.g.,
    const Messages = toMessages('Test', { MyMessage: 'Test Message' });
    'Messages.MyMessage' for the react-intl object, with ID 'Test.MyMessage'
    '<Messages.MyMessage.Message/>' for the component, pass in params to the message as props

  e.g.,
    const Messages = toMessages('Test', { MyMessage: { description: 'Test Description', defaultMessage: 'Test Message' }})
    Functionally the same as above, but allows for the description field to be set

  Both object and string values for keys can be intermixed in the object
*/
export const toMessages = (
  prefix,
  messages,
  {
    allowMissing,
    lowerCaseKey,
    ignorePrefix
  } = {}
) => {
  if ((!prefix && !ignorePrefix) || !messages) {
    logError('Missing prefix or messages', { prefix, messages });
    return {};
  }

  return Object.keys(messages).reduce((val, originalKey) => {
    const key = lowerCaseKey ? originalKey.toLowerCase() : originalKey;

    const messageDefinition = isObject(messages[originalKey]) ? {
      ...messages[originalKey],
      //
      id: getId({
        prefix: ignorePrefix ? null : prefix,
        key,
        originalId: messages[originalKey].id
      })
    } : {
      id: getId({
        prefix: ignorePrefix ? null : prefix,
        key
      }),
      defaultMessage: messages[originalKey]
    };

    applyComponentFields(messageDefinition);

    const messageObj = {
      ...val,
      [key]: messageDefinition
    };

    // I would love to rather detect if Proxy is a capability the browser
    // supports rather than this, but this the best we can do.
    if (isIE11 || allowMissing) return messageObj;

    return new Proxy(
      messageObj,
      {
        get: (obj, prop) => {
          if (prop in obj) return obj[prop];

          logError(`Missing message key in ${prefix}`, { messageKey: prop });

          obj[prop] = defineMessage({
            id: `MissingMessageKey-${prefix}-${prop}`,
            defaultMessage: `${prefix}.${prop}`
          });

          applyComponentFields(obj[prop]);

          return obj[prop];
        }
      }
    );
  }, {});
};

/*
  Replaces 'defineMessages' of react-intl and gets you the Component functionality
*/
export const toMessagesLegacy = messages => toMessages(null, messages, { ignorePrefix: true });
