/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import createApiActions from 'api/core';
import {
  queryParamNameMapper,
  defaultMapper
} from 'api/common/mappers';
import { getCustomersEnabledForCreditCardResponseMapper } from 'api/network/mappers/companyMappers';
import NetworkProfileDetails from 'schemas/network/NetworkProfileDetails';
import { createEntity } from 'schemas/state';
import { BankAccount } from 'schemas/payments/bankAccount';
import {
  getBankAccountResponseMapper,
  editBankAccountRequestBodyMapper,
  resetBankAccountRequestBodyMapper,
  disablePaymentAccountRequestMapper,
  checkPaymentToBeSentResponseMapper,
  submitWesternUnionEnrollmentResponseMapper
} from './mappers/bankAccountMappers';

export default createApiActions({
  getBankAccount: {
    method: 'get',
    url: '/ajax/account/viewBankAccount/:id',
    responseMapper: getBankAccountResponseMapper
  },
  editBankAccount: {
    method: 'post',
    url: '/ajax/account/EditPaymentAccount',
    requestBodyMapper: editBankAccountRequestBodyMapper
  },
  checkPaymentToBeSent: {
    method: 'get',
    url: '/ajax/account/paymentsToBeSentByApproverCount',
    queryStringMapper: queryParamNameMapper({
      'id': 'paymentAccountId',
      'numberRequiredApprovers': 'numberRequiredApprovers'
    }),
    responseMapper: checkPaymentToBeSentResponseMapper
  },
  resetBankAccount: {
    method: 'post',
    url: '/ajax/account/resetPaymentAccount',
    requestBodyMapper: resetBankAccountRequestBodyMapper
  },
  deleteBankAccount: {
    method: 'post',
    url: '/ajax/account/TerminatePaymentAccount',
    requestBodyMapper: defaultMapper
  },
  updatePaymentAccountDefaults: {
    method: 'patch',
    url: '/api/payment-accounts/defaults',
    requestBodyMapper: r => r
  },
  updateCustomerEnabledForCreditCard: {
    method: 'put',
    url: '/api/customers/:id/enabled_for_credit_card/:isEnabled',
    markUpdated: { entityType: NetworkProfileDetails.meta.name }
  },
  getCustomersEnabledForCreditCard: {
    method: 'get',
    url: '/api/customers/enabled_for_credit_card',
    responseMapper: getCustomersEnabledForCreditCardResponseMapper
  },
  disablePaymentAccount: {
    method: 'post',
    url: '/ajax/account/DisablePaymentAccount',
    requestBodyMapper: disablePaymentAccountRequestMapper
  },
  enablePaymentAccount: {
    method: 'post',
    url: '/ajax/account/enable/:accountId'
  },
  validateRoutingNumber: {
    method: 'get',
    url: '/api/next/account/get_bank_info/:id',
    queryStringMapper: r => r,
    responseMapper: defaultMapper
  },
  addPaymentAccount: {
    method: 'post',
    url: '/api/next/account/add',
    requestBodyMapper: defaultMapper,
    responseMapper: defaultMapper
  },
  addReceiveOnlyBankAccount: {
    method: 'post',
    url: '/api/next/account/addReceiveOnly',
    requestBodyMapper: defaultMapper,
    responseMapper: defaultMapper
  },
  verifyAccount: {
    method: 'post',
    url: '/ajax/account/verify',
    requestBodyMapper: defaultMapper,
    responseMapper: defaultMapper
  },
  newVerifyPaymentAccount: {
    method: 'post',
    url: '/api/next/account/:accountId/verify_account',
    requestBodyMapper: defaultMapper,
    responseMapper: defaultMapper
  },
  addPlaidPaymentAccount: {
    method: 'post',
    url: '/api/next/account/addPlaid',
    requestBodyMapper: defaultMapper,
    responseMapper: defaultMapper
  },
  addPlaidReceiveOnlyPaymentAccount: {
    method: 'post',
    url: '/api/next/account/addPlaidReceiveOnly',
    requestBodyMapper: defaultMapper,
    responseMapper: defaultMapper
  },
  upsertBankAccount: {
    method: 'post',
    url: '/api/next/account/upsert',
    requestBodyMapper: defaultMapper,
    responseMapper: defaultMapper
  },
  registerPaymentAccount: {
    method: 'post',
    url: '/ajax/account/RegisterPaymentAccount',
    requestBodyMapper: defaultMapper,
    responseMapper: defaultMapper
  },
  submitWesternUnionEnrollment: {
    method: 'post',
    url: '/api/web/submit_westernunion_enrollment',
    requestBodyMapper: submitWesternUnionEnrollmentResponseMapper,
    requestEntityMapper({bankAccount, statuses}) {
      // disabling Direct Pay since we may never use it and need to sort out UX impacts by new multi-account associations
      // statuses.push(BankAccountStatus.meta.map.ThirdPartyACHPending);
      const entity = createEntity(bankAccount, BankAccount.meta.name, {statuses}, null, {merge: true});
      return {entities: [entity]};
    }
  }
});
