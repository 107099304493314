/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { chain, isString } from 'lodash';
import { createRequestBody, createUrl } from 'api/core/utils';

function batchRequestMapper(apiParams, state, api) {
  return apiParams.map((apiCall) => {
    if (isString(apiCall.action)) {
      throw new Error('Batch API Action string name is no longer supported. Use `MyApi.ApiAction.config` instead.');
    } else if (!apiCall.action) {
      throw new Error('Undefined action passed to batch!');
    } else if (!apiCall.action.url) {
      throw new Error('Undefined action url, are you sure you are passing an API config?');
    }

    let config = apiCall.action;
    let url = createUrl(
      config.url,
      apiCall.params,
      config.urlParamMapper,
      config.queryStringMapper
    ).substr(1);
    let body = createRequestBody(apiCall.params, config.requestBodyMapper);

    return {
      method: config.method.toUpperCase(),
      'relative_url': url,
      'include_headers': true,
      body: JSON.stringify(body).replace(/\"/g, '\"') || undefined
    };
  });
}

function batchResponseMapper(batchResponse, state, apiParams, api) {
  let bodies = chain(batchResponse).map('body').value();
  let entities = [];
  let references = [];

  apiParams.forEach((apiCall, index) => {
    if (isString(apiCall.action)) {
      throw new Error('Batch API Action string name is no longer supported. Use `MyApi.ApiAction.config` instead.');
    }

    let config = apiCall.action;
    if (config.responseMapper) {
      let response = config.responseMapper(bodies[index], state, apiCall.params);
      if (response) {
        if (response.entities && response.entities.length) {
          entities = entities.concat(response.entities);
        }

        if (response.references && response.references.length) {
          references = references.concat(response.references);
        }
      }
    }
  });

  return {
    entities,
    references
  };
}

export {
  batchRequestMapper,
  batchResponseMapper
};
