/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { createApiAction } from 'api/core';

import { SecureTokenContextModel } from 'api/onboarding/getSecureTokenData.schemas';

const selfEnrollInVcc = createApiAction({
  method: 'post',
  url: '/api/landing/selfEnroll/vcc',
  requestBodyMapper: ({
    contactTitle,
    secureToken,
    firstName,
    lastName,
    phone,
    email,
    isAssistedPay,
    paymentInstructions
  }) => ({
    contactTitle,
    secureToken,
    firstName,
    lastName,
    phone,
    email,
    isAssistedPay,
    paymentInstructions
  }),
  markUpdated: {
    entityType: SecureTokenContextModel.meta.name
  }
});

export default selfEnrollInVcc;