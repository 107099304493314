/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import Paths from 'config/paths';
import { isEnabled } from 'services/FeatureToggles';

export const BusinessInvoicesLinks = [
  Paths.Invoices.List,
  Paths.Invoices.Summary,
  {
    path: Paths.Invoices.Vpx,
    badge: ({ invoiceEarlyPayCount }) => invoiceEarlyPayCount
  },
  Paths.Invoices.Recurring,
  Paths.Invoices.QbOnline
];

export const BusinessBillsLinks = [
  {
    path: Paths.Bills.List,
    badge: ({ billsInboxCount }) => billsInboxCount
  },
  Paths.Bills.Statements,
  {
    path: Paths.Bills.Vpx,
    badge: ({ billsEarlyPayCount }) => billsEarlyPayCount
  },
  {
    path: Paths.Bills.PaymentQueue,
    badge: ({ paymentQueueCount }) => paymentQueueCount
  }
];

export const BusinessNetworkLinks = [
  Paths.Network.List,
  {
    path: Paths.Network.Requests,
    badge: ({ connectionRequestCount }) => connectionRequestCount
  }
];

export const createBusinessPaymentsLinks = withReceived => [
  Paths.Payments.SendPayment,
  Paths.Payments.Sent,
  withReceived ? Paths.Payments.Received : null,
  Paths.Payments.QbOnline,
  Paths.Payments.AutoPay,
  {
    path: Paths.Payments.Approvals,
    badge: ({ approvalQueueCount }) => approvalQueueCount
  }
];

export const BusinessCompanySettingsLinks = [
  Paths.Settings.Profile,
  Paths.Settings.Preferences,
  Paths.Settings.Logos,
  Paths.Settings.Groups,
  Paths.Settings.Users,
  isEnabled('advancedApprovals') ? Paths.Settings.PaymentApproval : null
];

export const BusinessDiscountSettingsLinks = [
  Paths.Settings.Discounts,
  Paths.Settings.Discounts.ManageVpxVendors
];

export const BusinessSyncLinks = [
  Paths.Sync.Payments,
  Paths.Sync.History,
  Paths.Sync.Settings
];
