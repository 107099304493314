/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { isArray, isObject, mergeWith, remove } from 'lodash';
import { asApiAction } from 'api/core/ApiAction';
import {
  isFetchAction,
  isSuccessAction,
  UpdateEntityActionType
} from 'api/core/utils';

/*
    Clones a new state with optional state changes
*/
export const newState = (state, stateChange) => {
  const customizer = (objectValue, sourceValue, key, object, source) => {
    if (isArray(objectValue) || isObject(objectValue)) {
      return sourceValue;
    }
  };

  return mergeWith({}, state, stateChange, customizer);
};

/*
    moves items from one array to another based on a predicate function
*/
export const moveItemsToOtherArray = (sourceArray, destinationArray, predicateFunc) => {
  let removedItems = remove(sourceArray, predicateFunc);
  if (removedItems) {
    removedItems.forEach((item) => {
      destinationArray.push(item);
    });
  }
};

export function isSomeSuccessApiAction(action, ...apiActions) {
  return apiActions.some((apiAction) => {
    return isSuccessAction(action, apiAction);
  });
}

export function isSomeFetchApiAction(action, ...apiActions) {
  return apiActions.some((apiAction) => {
    return isFetchAction(action, apiAction);
  });
}

export const isEntityUpdateApiAction = action => action.type === UpdateEntityActionType;

export function isFailAction(action, codes) {
  const apiAction = asApiAction(action);

  if(!apiAction?.isFailure) return false;

  return (codes || [401, 403, 500]).includes(apiAction.getStatusCode());
}

const isNotAuthorizedAnymoreEntity = ({
  type,
  value
}) => type === 'UseAppAuthorizationStatus' && !value?.isAuthorizedToUseApp;

export const isUserNowUnauthenticated = (action) => {
  const apiAction = asApiAction(action);

  if (!apiAction) return false;

  return !!apiAction.getEntities({
    filter: isNotAuthorizedAnymoreEntity,
    valueOnly: false
  }).length;
};

export function isNukeStateAction(action, reducerKey, nukeOnLogout = true, httpFailureCodes) {
  return (nukeOnLogout && isSuccessAction(action, 'logout'))
    || (action.type === 'NukeState' && action.reducer === reducerKey)
    || (isFailAction(action, httpFailureCodes));
}
