/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { SecureTokenIdentity } from 'schemas/network/session';
import { createEntityReference, getEntity } from 'schemas/state';

import logError from 'services/ErrorService';

const getSecureTokenIdentity = (state, { notExpected = false, returnNull = false } = {}) => {
  const secureTokenIdentity = getEntity(
    state,
    createEntityReference('current', SecureTokenIdentity.meta.name)
  );

  if (!secureTokenIdentity && !notExpected) {
    logError('Expected secureTokenIdentity, but secureTokenIdentity is missing.', null, { includeStackTrace: true });
  }

  // Returning at least an empty object so that at least a JS error doesn't take React down. We have
  // the logged error, so you there is visiblity into a problem.
  return secureTokenIdentity || (returnNull ? null : {});
};

export default getSecureTokenIdentity;