/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import t from 'tcomb-validation';

import { defineSchema } from 'schemas';
import ConnectionStatusEnum from 'schemas/network/ConnectionStatusEnum';
import RetrievableAddressBookEntry from 'schemas/network/RetrievableAddressBookEntry';
import PaymentMethodTypes from 'schemas/payments/PaymentMethodTypes';
import { EntityReference } from 'schemas/state';

const NetworkProfileDetails = defineSchema({
  id: t.String,
  name: t.String,
  companyStatus: t.struct({
    connectionStatus: t.maybe(ConnectionStatusEnum),
    sendPaymentOptions: t.maybe(t.list(PaymentMethodTypes)),
    receivePaymentOptions: t.maybe(t.list(PaymentMethodTypes)),
    achPlusFeePercent: t.maybe(t.Number),
    achPlusFeeAmount: t.maybe(t.Number),
    sendAchPlusFeePercent: t.maybe(t.Number),
    sendAchPlusFeeAmount: t.maybe(t.Number),
    achData: t.maybe(t.struct({
      routingNumber: t.maybe(t.String),
      accountNumber: t.maybe(t.String)
    }))
  }),
  profile: t.maybe(EntityReference),
  addressBooks: t.maybe(t.list(EntityReference)),
  theirAddressBooks: t.maybe(t.list(EntityReference)),
  manyToOneSuggestions: t.maybe(t.list(RetrievableAddressBookEntry)),
  availableActions: t.maybe(t.list(t.String))
}, 'NetworkProfileDetails');

export default NetworkProfileDetails;