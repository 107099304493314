/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import t from 'tcomb-validation';
import Messages from './string.messages';

export const createRegexType = (regex, validationErrorMessage) => {
  let regexpression = regex;
  if (typeof regex === 'string') {
    regexpression = new RegExp(regex);
  }

  function isValid(string) {
    return regexpression.test(string);
  }

  let type = t.subtype(t.String, isValid, 'RegexMatch');
  type.getValidationErrorMessage = (value, path, context) => {
    if (!isValid(value) && context && context.intl) {
      return validationErrorMessage;
    }

    return '';
  };
  return type;
};

export const createExactLengthStringType = (length) => {
  function isValid(string) {
    if (!string) return true;
    return string.length === length;
  }

  let type = t.subtype(t.String, isValid, 'ExactLength');
  type.getValidationErrorMessage = (value, path, context) => {
    if (!isValid(value) && context && context.intl) {
      return context.intl.formatMessage(Messages.IncorrectLength, { length });
    }

    return '';
  };
  return type;
};

export const createLengthRangeStringType = (minLength, maxLength) => {
  function isValid(string) {
    if (!string) return true;
    return string.length >= minLength && string.length <= maxLength;
  }

  let type = t.subtype(t.String, isValid, 'LengthRange');
  type.getValidationErrorMessage = (value, path, context) => {
    if (!isValid(value) && context && context.intl) {
      return context.intl.formatMessage(Messages.IncorrectLengthRange, { minLength, maxLength });
    }

    return '';
  };
  return type;
};

function createMaxLengthStringType(length) {
  function isValid(string) {
    if (!string) return true;
    return string.length <= length;
  }

  let type = t.subtype(t.String, isValid, 'MaxLength');
  type.getValidationErrorMessage = (value, path, context) => {
    if (!isValid(value) && context && context.intl) {
      return context.intl.formatMessage(Messages.TooLong, {maxLength: length});
    }

    return '';
  };
  return type;
}

function createUniqueStringType(list = [], {maxLength = 240, errorMessage} = {}) {
  const isValid = string => !string || !list.includes(string);

  const maxLengthType = createMaxLengthStringType(maxLength);
  let type = t.subtype(maxLengthType, isValid, 'UniqueString');
  type.getValidationErrorMessage = (value, path, context) => {
    if (!isValid(value) && context && context.intl) {
      return context.intl.formatMessage(errorMessage || Messages.AlreadyTaken);
    }
    return maxLengthType.getValidationErrorMessage(value, path, context);
  };
  return type;
}

function isValidMatch(value1, value2) {
  return value1 === value2;
}

const createMatchingString = (value1) => {
  let MatchingString = t.subtype(t.String, (value2) => {
    return isValidMatch(value1, value2);
  }, 'MatchingPassword');

  MatchingString.getValidationErrorMessage = (value2, path, context) => {
    if (!isValidMatch(value1, value2) && context && context.intl) {
      return context.intl.formatMessage(Messages.DoNotMatch);
    }

    return '';
  };

  return MatchingString;
};

export {
  createMaxLengthStringType,
  createMatchingString,
  createUniqueStringType
};
