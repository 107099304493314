/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

export function getTwoFactorAuthenticationResponseMapper(apiResponse) {
  if (!apiResponse.response) {
    return {};
  }

  let { isTwoFactorEnabled, canDisableTwoFactor, isVerificationPending, phoneNumber } = apiResponse.response;

  return {
    isTwoFactorEnabled,
    canDisableTwoFactor,
    isVerificationPending,
    phoneNumber
  };
}
