/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import t from 'tcomb-validation';

import { toEnumObject } from 'config/constants/utils';

import { defineSchema } from 'schemas';
import { createEntity } from 'schemas/state';

export const UseAppAuthorizationReason = toEnumObject([
  'EmailVerificationRequired',
  'SessionExpired',
  'SessionKicked',
  'VerifyAccount',
  'LogOut',
  'LogOn',
  'Registration',
  'ExistingSession'
]);

const UseAppAuthorizationStatus = defineSchema(
  {
    encryptedUserId: t.maybe(t.String),
    isAuthorizedToUseApp: t.Boolean,
    reason: t.maybe(t.String)
  },
  'UseAppAuthorizationStatus'
);

export const createUseAppAuthorizedEntity = reason => createEntity(
  'current',
  UseAppAuthorizationStatus.meta.name,
  {
    isAuthorizedToUseApp: true,
    reason
  },
  null,
  { scope: 'global' }
);

export const IsAuthorizedToUseAppEntity = createUseAppAuthorizedEntity();

export const createUseAppUnauthorizedEntity = (reason, params) => createEntity(
  'current',
  UseAppAuthorizationStatus.meta.name,
  {
    isAuthorizedToUseApp: false,
    reason,
    ...(params || {})
  },
  null,
  { scope: 'global' }
);

export const IsUnauthorizedToUseAppEntity = createUseAppUnauthorizedEntity();

export default UseAppAuthorizationStatus;