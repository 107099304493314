/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './index.scss';

const createDivWithClass = className => ({
  children,
  onClick
}) => (
  <div className={className} onClick={onClick}>
    {children}
  </div>
);

export const TlrHeader = createDivWithClass('rowHeader');
export const TlrLeftHeader = createDivWithClass('rowHeaderLeft');
export const TlrRightHeader = createDivWithClass('rowHeaderRight');
export const TlrTitle = createDivWithClass('rowTitle');
export const TlrFooter = createDivWithClass('rowFooter');
export const TlrLeftFooter = createDivWithClass('rowFooterLeft');
export const TlrRightFooter = createDivWithClass('rowFooterRight');
export const TlrSubFooter = createDivWithClass('rowSubFooter');
export const TlrLeftSubFooter = createDivWithClass('rowSubFooterLeft');
export const TlrRightSubFooter = createDivWithClass('rowSubFooterRight');

/** @docsignore - the TinyListRow from ./index.js will be documented instead */
class TinyListRow extends Component {
  static propTypes = {
    onClick: PropTypes.func,
    isClickable: PropTypes.bool,
    isNew: PropTypes.bool
  };

  static defaultProps = {
    isClickable: true
  };

  constructor(props) {
    super(props);
    this.state = {
      touched: false
    };
    this.onTouchEnd = () => this.setState({touched: true});
  }

  render() {
    let className = classNames(
      'tinyListRow',
      {touched: this.state.touched},
      {isClickable: this.props.isClickable},
      {isSelected: this.props.isSelected},
      {isNew: this.props.isNew},
      {hasFooterBar: this.props.hasFooterBar}
    );
    return (
      <div className={className} onClick={this.props.onClick} onTouchStart={() => null} onTouchEnd={this.onTouchEnd}>
        {this.props.children}
      </div>
    );
  }
}

export default TinyListRow;
