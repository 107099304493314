/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */
/* eslint max-len:0 */

import { toMessages } from 'config/messages';

export default toMessages('AccountSetup.WelcomeStep.WelcomeContent', {
  Header: 'Congrats! You’re connected to {companyName}.',
  UnconnectedHeader: 'Create your {relationship} Portal and connect with {companyName}.',
  Footer: 'A {relationship} Portal enables you to securely connect and transact exclusively with the company that invited you.',
  UnconnectedFooter: 'Your account will be set up exclusively for secure transactions with {companyName}.'
});

export const AsVendorOnboardMessages = toMessages('AccountSetup.WelcomeStep.WelcomeContent.AsVendorOnboard', {
  Instructions: 'You’re just a few clicks away from activating electronic payments and a great new way to do business with your customer.',
  InstructionsWithoutElectonicPayments: 'You’re just a few clicks away from a great new way to do business with your customer.',
  Reason1: 'Register for FREE — no monthly subscription fee',
  Reason2: 'Receive electronic payments into your bank account',
  Reason3: 'Create, send and track the status of online invoices*',
  Reason4: 'View invoice and payment history'
});

export const AsVendorMessages = toMessages('AccountSetup.WelcomeStep.WelcomeContent.AsVendor', {
  Instructions: 'You’re just a few clicks away from activating electronic payments and a great new way to do business with your customer.',
  InstructionsWithoutElectonicPayments: 'You’re just a few clicks away from a great new way to do business with your customer.',
  Reason1: 'Receive electronic payments into your bank account',
  Reason2: 'Create, send and track electronic invoices*',
  Reason3: 'Set up recurring invoices',
  Reason4: 'View payment history'
});

export const AsCustomerOnboardMessages = toMessages('AccountSetup.WelcomeStep.WelcomeContent.AsCustomerOnboard', {
  Instructions: 'You’re just a few clicks away from activating electronic payments and a great new way to receive and pay your vendor’s bills.',
  Reason1: 'Register for FREE — no monthly subscription fee',
  Reason2: 'Receive electronic bills from your vendor',
  Reason3: 'Pay their bills electronically',
  Reason4: 'View bills and payments history'
});

export const AsCustomerMessages = toMessages('AccountSetup.WelcomeStep.WelcomeContent.AsCustomer', {
  Instructions: 'You’re just a few clicks away from activating electronic payments and a great new way to receive and pay your vendor’s bills.',
  Reason1: 'Receive electronic bills from your vendor',
  Reason2: 'Pay their bills electronically',
  Reason3: 'Setup automatic payment for bills from this company',
  Reason4: 'View bills and payments history'
});
