/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { useEffect, useRef } from 'react';

// This whole file is to support the need when two independent ref provides want
// access to the same component, but the current ref API is not well setup to
// access X number of refs. This issue is quite well discussed here:
// https://github.com/facebook/react/issues/13029

// From https://github.com/facebook/react/issues/13029#issuecomment-522632038
export const combineRefs = (...refs) => (target) => {
  refs.forEach((ref) => {
    if (!ref) return;

    if (typeof ref === 'function') {
      ref(target);
    } else {
      ref.current = target;
    }
  });
};

// I leave it up to the user of this to realize the parameters are being spread
// as the dependency array and that they don't go changing the number of
// parameters passed in between executions.
export default function useCombinedRefs(...refs) {
  const targetRef = useRef();

  // useEffect should be sufficient here. We can go to useLayoutEffect if
  // there's a need, but that should only be if there is a combined ref being
  // used within a useLayoutEffect.
  useEffect(
    () => combineRefs(...refs)(targetRef.current),
    [ ...refs ]
  );

  return targetRef;
}