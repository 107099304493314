/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { createApiAction } from 'api/core';

const updateCompanyUserAdditionalSettings = createApiAction({
  method: 'post',
  url: '/api/applicationDocumentStore/settings/companyUser/appSettings',
  requestBodyMapper: value => ({
    keyStyle: 'ScopeNamespaced',
    value: JSON.stringify(value)
  })
});

export default updateCompanyUserAdditionalSettings;