/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, { Component } from 'react';
import Textarea from 'react-textarea-autosize';
import classNames from 'classnames';
import BrowserDetection from 'services/BrowserDetection';

export default class ExpandableTextArea extends Component {
  constructor(props) {
    super(props);
  }

  getTextBoxClasses() {
    return classNames(
      'vp-input',
      {'error': this.props.hasError},
      {'text-right': this.props.alignRight}
    );
  }

  onChange(value) {
    if (this.props.onChange) {
      this.props.onChange(value ? value : null);
    }
  }

  render() {
    let value = this.props.value === 0 || this.props.value ? this.props.value : '';
    let onChangeProps = {};
    if (BrowserDetection.isIE()) {
      // using onInput instead of onChange to fix an IE issue. This can be changed back to onChange when we are on React v16.
      onChangeProps.onInput = (evt) => {
        evt.preventDefault();
        this.onChange(evt.target.value);
      };
    } else {
      onChangeProps.onChange = evt => this.onChange(evt.target.value);
    }
    return (
      <Textarea
        className={this.getTextBoxClasses()}
        style={this.props.style}
        disabled={this.props.disabled}
        name={this.props.name}
        { ...onChangeProps }
        placeholder={this.props.placeholder}
        autoFocus={this.props.autoFocus}
        minRows={this.props.minRows}
        maxRows={this.props.maxRows}
        value={value} />
    );
  }
}
