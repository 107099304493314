/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { camelCase, upperFirst } from 'lodash';
import moment from 'moment';
import {
  InvoiceType,
  InvoiceRelationship
} from 'schemas/invoicing/invoice';

import { dateMapper } from 'api/common/mappers';

export function determineInvoiceRelationship(invoice) {
  if (!invoice) return;

  if (invoice.isMine
    && invoice.invoiceType === InvoiceType.meta.map.Receivable) {
    invoice.myRelationship = InvoiceRelationship.meta.map.Vendor;
  }

  if (invoice.isMine
    && invoice.invoiceType === InvoiceType.meta.map.Payable) {
    invoice.myRelationship = InvoiceRelationship.meta.map.Customer;
  }

  if (!invoice.isMine
    && invoice.invoiceType === InvoiceType.meta.map.Receivable) {
    invoice.myRelationship = InvoiceRelationship.meta.map.Customer;
  }

  if (!invoice.isMine
    && invoice.invoiceType === InvoiceType.meta.map.Payable) {
    invoice.myRelationship = InvoiceRelationship.meta.map.Vendor;
  }

  if (invoice.invoiceType === InvoiceType.meta.map.OutOfBandInvoice) {
    invoice.myRelationship = InvoiceRelationship.meta.map.Customer;
  }

  if (invoice.invoiceType === InvoiceType.meta.map.Series) {
    invoice.myRelationship = InvoiceRelationship.meta.map.Vendor;
  }
}

export function invoiceStatusMapper(apiStatus) {
  // api returns status inconsistently (i.e. sometimes PascalCase sometimes 'lowercase with spaces')
  if (!apiStatus) {
    return null;
  }

  // normalize to PascalCase
  return upperFirst(camelCase(apiStatus));
}

export function mapVpxDiscount(apiDiscount, isLocked = false, isSuggested = false, isCancelBlocked = false) {
  if (!apiDiscount || !(apiDiscount.discountAmount > 0)) {
    return null;
  }

  let discountDecimal = (apiDiscount.discountRate) ? apiDiscount.discountRate : 0;
  let discountPercent = discountDecimal * 100;
  if (discountPercent !== Math.floor(discountPercent)) {
    // round decimals
    discountPercent = parseFloat(discountPercent.toFixed(2));
  }

  let apy = (apiDiscount.apy || 0) * 100;
  // If it's a whole number, don't round it
  if (apy !== Math.floor(apy)) {
    apy = parseFloat(apy.toFixed(2));
  }

  let ratePercent = null;
  let rateDays = null;
  let rateFunctionId = null;
  if (apiDiscount.discountFunction) {
    ratePercent = apiDiscount.discountFunction.rateAtDaysEarly * 100;
    rateDays = apiDiscount.discountFunction.daysEarly;
    rateFunctionId = apiDiscount.discountFunction.id;
  }

  return {
    discountPercent: discountPercent,
    discountAmount: apiDiscount.discountAmount,
    discountedBalance: apiDiscount.discountedAmount,
    annualPercentageYield: apy,

    ratePercent,
    rateDays,
    rateFunctionId,

    date: apiDiscount.effectiveDate ? dateMapper(apiDiscount.effectiveDate) : null,

    discountEndDaysEarly: apiDiscount.discountFunction ? apiDiscount.discountFunction.endDaysEarly : null,
    isLocked,
    isSuggested,
    isAutoAccept: null,
    isCancelBlocked
  };
}

export function setOverdue(invoice) {
  let dueDate = moment(invoice.dueDate);
  let today = moment().subtract(1, 'day').startOf('day');
  invoice.isOverdue = dueDate.isBefore(today);
}
