/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, { useEffect } from 'react';

import Button from 'components/Button';
// import Tooltip, { Placements } from 'components/Tooltip';
import Icon, { IconTypes } from 'components/Icon';
import LoadingIndicator from 'components/LoadingIndicator';

import Currency from 'components/Currency';
import FormattedDate from 'components/FormattedDate';
import Address from 'components/Address';

import { Row, Column } from 'components/Viewstrap/Grid';

import useApi from 'api/hooks/useApi';
import getPaymentsSentDetails from 'api/payments/getPaymentsSentDetails';
import { downloadRemittance } from 'api/payments/remittance';

import ActionMenu from 'components/Tooltip/ActionMenu';

import useWindowWidth from 'hooks/browser/useWindowWidth';

import RemittanceDetails from '../RemittanceDetails';
import Attachments from './Attachments';

function Field({ label, value}) {
  return (
    <div style={{margin: '12px'}}>
      <div className="vp-label">
        {label}
      </div>
      <div>
        {value}
      </div>
    </div>
  );
}

function AddressValue({ name, address }) {
  return (
    <div>
      <strong>{name}</strong>
      {address
        ? (
          <Address
            linesWithoutAddressee={[
              address.line1,
              address.line2,
              address.line3,
              address.line4
            ]}
            city={address.city}
            stateProvince={address.stateProvince}
            postalCode={address.postalCode}
            country={address.country}
          />
        )
        : null}
    </div>
  );
}

function DesktopLayout({
  details,
  secureToken,
  download,
  isDownloading
}) {

  let actions = [
    {
      name: 'csv',
      label: 'CSV File',
      onClick: () => download('csv')
    },
    {
      name: 'excel',
      label: 'Microsoft Excel File',
      onClick: () => download('excel')
    }
  ];

  return (
    <div style={{marginBottom: '50px'}}>

      <div style={{ backgroundColor: '#f7f7f7', marginTop: '60px', marginBottom: '32px' }}
        className="no-print">
        <Row>
          <Column size={6} style={{fontWeight: 'bold', fontSize: '16px', padding: '16px'}}>
            Payment Details
          </Column>
          <Column size={6} style={{textAlign: 'right', padding: '8px'}}>
            <Attachments settlementId={details.settlementId} secureToken={secureToken} />
            <div style={{ display: 'inline-block', marginRight: '20px' }}>
              <Button variant="utility" onClick={() => global.window.print()}>
                <Icon type={IconTypes.Print} /> Print
              </Button>
            </div>
            <ActionMenu actions={actions} header="Download As...">
              <Button isDisabled={isDownloading} isProcessing={isDownloading}>
                Download Remittance
              </Button>
            </ActionMenu>
          </Column>
        </Row>
      </div>

      <Row>
        <Column size={9}>
          <Row>
            <Column size={6}>
              <Field label="Check From" value={<AddressValue address={null} name={details.customerName} />} />
            </Column>
            <Column size={6}>
              <Field label="Check To"
                value={<AddressValue address={details.companyAddress} name={details.companyName} />} />
            </Column>
          </Row>
          <div style={{ borderTop: '1px solid #ddd'}}>
            <Field label="Memo" value={details.memo} />
          </div>
        </Column>
        <Column size={3} style={{borderLeft: '1px solid #ddd'}}>
          <Field label="Check Number" value={<strong style={{ fontSize: '16px' }}>
            {details.referenceNumber}
          </strong>} />
          <Field label="Date Issued" value={<strong style={{ fontSize: '16px' }}>
            <FormattedDate value={details.sentDate} />
          </strong>} />
          <Field label="Payment Amount" value={<strong style={{fontSize: '24px'}}>
            <Currency value={details.totalAmount} />
          </strong>} />
        </Column>
      </Row>
    </div>
  );
}

function MobileLayout({
  details,
  secureToken,
  download,
  isDownloading
}) {

  let actions = [
    {
      name: 'csv',
      label: 'CSV File',
      onClick: () => download('csv')
    },
    {
      name: 'excel',
      label: 'Microsoft Excel File',
      onClick: () => download('excel')
    }
  ];

  return (
    <div>

      <Row style={{ backgroundColor: '#f7f7f7', marginTop: '30px', marginBottom: '10px' }}>
        <Column size={6} style={{ fontWeight: 'bold', fontSize: '16px', padding: '16px' }}>
          Payment Details
        </Column>
        <Column size={6} style={{ textAlign: 'right', padding: '8px' }}>
          <ActionMenu actions={actions} header="Download As...">
            <Button isDisabled={isDownloading} isProcessing={isDownloading}>
              Download
            </Button>
          </ActionMenu>
        </Column>
      </Row>

      <div style={{textAlign: 'right'}}>
        <Attachments settlementId={details.settlementId} secureToken={secureToken} />
      </div>


      <Field label="Check From" value={<AddressValue address={null} name={details.customerName} />} />
      <Field label="Check To"
        value={<AddressValue address={details.companyAddress} name={details.companyName} />} />

      <div style={{ borderTop: '1px solid #ddd' }}>
        <Field label="Check Number" value={<strong style={{ fontSize: '16px' }}>
          {details.referenceNumber}
        </strong>} />
        <Field label="Date Issued" value={<strong style={{ fontSize: '16px' }}>
          <FormattedDate value={details.sentDate} />
        </strong>} />
        <Field label="Payment Amount" value={<strong style={{ fontSize: '24px' }}>
          <Currency value={details.totalAmount} />
        </strong>} />
      </div>

      <div style={{ borderTop: '1px solid #ddd' }}>
        <Field label="Memo" value={details.memo} />
      </div>

    </div>
  );
}

function SettlementDetails({ settlementId, secureToken }) {
  const windowWidth = useWindowWidth();
  const api = useApi(getPaymentsSentDetails, 'GetSettlementDetails');
  const downloadApi = useApi(downloadRemittance, 'DownloadRemittanceCsv');

  useEffect(() => {
    if (settlementId) {
      api.call({ settlementId, secureToken });
    }
  }, [settlementId]);

  if (api.isLoading()) {
    return <LoadingIndicator>
      Loading Payment Details...
    </LoadingIndicator>;
  }

  const details = api.getEntity();
  if (!details) return null;

  const download = (format) => {
    downloadApi.call({
      settlementId: details.settlementId,
      secureToken,
      resultBehavior: format
    });
  };

  let isDownloading = downloadApi.isLoading();

  const layoutProps = {
    details,
    secureToken,
    download,
    isDownloading
  };

  return (
    <div className="view-remittance-landing">
      {windowWidth < 800
        ? <MobileLayout {...layoutProps} />
        : <DesktopLayout {...layoutProps} />}

      <RemittanceDetails settlementId={settlementId} secureToken={secureToken} />
    </div>
  );
}

export default SettlementDetails;
