/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import isCapabilityEnabled from 'actions/app/isCapabilityEnabled';
import isAuthorizedToUseApp from 'actions/common/isAuthorizedToUseApp';

import RouteLink from 'components/Link/RouteLink';
import Logo from 'components/Logo';
import { LinkRef } from 'config/links';

import useIsTablet from 'hooks/browser/useIsTablet';

import StaticHeaderOnWhiteLayout from '../StaticHeaderOnWhiteLayout';
import Messages from './index.messages';
import './index.scss';

const BlueHeaderOnWhiteLayout = ({
  backgrounds,
  headerMessage,
  header: headerElement,
  subheaderMessage,
  subheader,
  children,
  headerWidth,
  contentWidth,
  hideLinks,
  hideLoginLink,

  // allow changing the "OnWhite" part based on new designs coming from Damien
  backgroundColor
}) => {
  // Hooks
  const isTablet = useIsTablet();
  const canOpenRegister = useSelector(
    state => isCapabilityEnabled(state, 'OpenRegistration') && !isAuthorizedToUseApp(state)
  );

  // Render
  let headerHeight = 180;
  let header = (
    <div className="headerSection viewstrap">
      <div className="container">
        <span className="viewpostLogo">
          <Logo height={isTablet ? 20 : undefined} width={isTablet ? 100 : undefined} />
        </span>
        {isTablet ? null : backgrounds}
        {hideLinks ? null : (
          <div className="links">
            {canOpenRegister ? (
              <RouteLink linkRef={LinkRef.registration.index} activeClassName="active">
                <FormattedMessage {...Messages.SignUp} />
              </RouteLink>
            ) : null}
            {hideLoginLink ? null : (
              <RouteLink linkRef={LinkRef.logon.index} activeClassName="active">
                <FormattedMessage {...Messages.LogIn} />
              </RouteLink>
            )}
          </div>
        )}
        <div className="message">
          {headerMessage ? (
            <span className="header" style={{width: headerWidth ? headerWidth : null}}>
              <FormattedMessage {...headerMessage} />
            </span>
          ) : null}
          {headerElement ? (
            <span className="header" style={{width: headerWidth ? headerWidth : null}}>
              {headerElement}
            </span>
          ) : null}
          {headerMessage || headerElement ? <br/> : null}
          {subheaderMessage ? (
            <span className="subHeader">
              <FormattedMessage {...subheaderMessage} />
            </span>
          ) : null}
          {subheader ? (
            <span className="subHeader">
              {subheader}
            </span>
          ) : null}
        </div>
      </div>
    </div>
  );

  if (!backgrounds) {
    header = (
      <div className="skinny-header viewstrap">
        <div className="container">
          <span className="viewpostLogo">
            <Logo height={isTablet ? 20 : undefined} width={isTablet ? 100 : undefined} />
          </span>
          {hideLinks ? null : (
            <div className="links">
              {canOpenRegister ? (
                <RouteLink linkRef={LinkRef.registration.index} activeClassName="active">
                  <FormattedMessage {...Messages.SignUp} />
                </RouteLink>
              ) : null}
              {hideLoginLink ? null : (
                <RouteLink linkRef={LinkRef.logon.index} activeClassName="active">
                  <FormattedMessage {...Messages.LogIn} />
                </RouteLink>
              )}
            </div>
          )}
        </div>
      </div>
    );
    headerHeight = 52;
  }

  return (
    <StaticHeaderOnWhiteLayout
      header={(
        <div className="blue-header">
          {header}
        </div>
      )}
      headerHeight={headerHeight}
      contentWidth={contentWidth}
      backgroundColor={backgroundColor}
    >
      {children}
    </StaticHeaderOnWhiteLayout>
  );
};

BlueHeaderOnWhiteLayout.propTypes = {
  headerMessage: PropTypes.object, // Intl
  subheaderMessage: PropTypes.object // Intl
};

export default BlueHeaderOnWhiteLayout;
