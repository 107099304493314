/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { connect } from 'react-redux';

import isCapabilityEnabled from 'actions/app/isCapabilityEnabled';
import { getIdentity } from 'actions/common';

import getIdentityEndpoint from 'api/session/getIdentity';

const MapStateToProps = (state) => {
  const viewState = state.settings && state.settings.AccountSettingsPage
    ? state.settings.AccountSettingsPage
    : null;
  const { twoFactorSecuredRequirementDisabled } = getIdentity(state, { notExpected: true });

  return {
    showOnlyTwoFactor: isCapabilityEnabled(state, 'TwoFactorRequired')
      && !twoFactorSecuredRequirementDisabled,
    isVerifying: viewState.isVerifying,
    phone: viewState.phone
  };
};

const MapDispatchToProps = dispatch => ({
  loadIdentity: () => dispatch(getIdentityEndpoint())
});

export default connect(MapStateToProps, MapDispatchToProps);
