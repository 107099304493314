/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { initializeApi } from 'api/core/init';

import { NetworkErrors } from 'config/constants';

import AppSessionSettings from 'schemas/app/AppSessionSettings';
import { NetworkError } from 'schemas/common/network';
import {
  UseAppAuthorizationReason,
  createUseAppUnauthorizedEntity,
  IsUnauthorizedToUseAppEntity
} from 'schemas/session/UseAppAuthorizationStatus';
import { ApiFatalError } from 'schemas/network/session';
import { createEntity } from 'schemas/state';

const mapErrorToPayload = (errorResponse, config) => {
  if (!errorResponse) return null;

  if (errorResponse.body) {
    let entities = [];

    if (!errorResponse.body.success && errorResponse.body.redirectUrl) {
      entities.push(IsUnauthorizedToUseAppEntity);
    } else if (errorResponse.body.messages && errorResponse.body.messages.length) {
      errorResponse.body.messages.forEach((message) => {
        if (!message) return;

        if (message.type === 'Error' && message.key === 'SessionExpired') {
          entities.push(createUseAppUnauthorizedEntity(UseAppAuthorizationReason.SessionExpired));
        } else if (message.type === 'Error' && message.key === 'SessionKicked') {
          entities.push(createUseAppUnauthorizedEntity(UseAppAuthorizationReason.SessionKicked));
        } else if (message.type === 'Error' && message.key === 'AuthenticationRequired') {
          entities.push(IsUnauthorizedToUseAppEntity);
        }
      });
    }

    if (entities.length) return { entities };
  }

  if (errorResponse.statusCode === 429) {
    let rateLimitedEntity = createEntity(
      'current',
      NetworkError.meta.name,
      {
        type: NetworkErrors.TooManyRequests
      }
    );

    return { entities: [ rateLimitedEntity ], reason: NetworkErrors.TooManyRequests };
  }

  if (errorResponse.statusCode === 401) {
    if (errorResponse.body?.messages?.some(message => message.key === 'SessionExpired')) {
      return {
        entities: [
          createUseAppUnauthorizedEntity(UseAppAuthorizationReason.SessionExpired)
        ]
      };
    }

    return {
      entities: [ IsUnauthorizedToUseAppEntity ]
    };
  }

  if (errorResponse.statusCode === 403) {
    return {
      entities: [createEntity(
        'current',
        ApiFatalError.meta.name,
        { type: 'NotAuthorized' }
      )]
    };
  }

  if (errorResponse.statusCode === 404) {
    return {
      entities: [createEntity(
        'current',
        ApiFatalError.meta.name,
        { type: 'NotFound' }
      )]
    };
  }

  if (errorResponse.statusCode === 500) {
    return {
      entities: [createEntity(
        'current',
        ApiFatalError.meta.name,
        { type: 'ServerError' }
      )]
    };
  }
};

export default () => initializeApi({
  headerProviders: [
    ({ isInternalApi }) => isInternalApi ? ({
      // Adding app version for tracking purposes
      'x-vp-meta-app-version': process.build.VERSION
    }) : ({}),
    (params, { emailId }) => emailId ? ({
      'VpEmailId': emailId
    }) : ({})
  ],
  isSuccessAnError: (response) => {
    if (!response) return false;

    if (!response.success && response.redirectUrl) return true;

    if (!response.messages || !response.messages.length) return false;

    return response.messages.some((message) => {
      if (!message) return false;

      if (message.type === 'Error' && message.key === 'AuthenticationRequired') return true;

      return false;
    });
  },
  mapErrorToPayload,
  mapHeadersToEntities: (headers) => {
    let csrfToken = headers.get('requestverificationtoken');

    if (!csrfToken) return [];

    return [
      createEntity(
        'current',
        AppSessionSettings.meta.name,
        {
          csrfToken
        },
        null,
        {
          scope: 'global'
        }
      )
    ];
  }
});
