/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React from 'react';
import Logo from 'components/Logo';
import ProvideIsTablet from 'decorators/ProvideIsTablet';
import StaticHeaderOnWhiteLayout from '../StaticHeaderOnWhiteLayout';
import './index.scss';

const WhiteHeaderOnWhiteLayout = ({
  headerMessage,
  subheaderMessage,
  children,
  headerWidth,
  contentWidth,
  isTablet
}) => {
  const header = (
    <div className="plainWhiteLogoHeader">
      <div className="headerSection viewstrap">
        <div className="container">
          <span className="viewpostLogo">
            <Logo
              variant="blue"
              height={isTablet ? 20 : undefined}
              width={isTablet ? 100 : undefined}
            />
          </span>
        </div>
      </div>
    </div>
  );

  return (
    <StaticHeaderOnWhiteLayout
      header={header}
      headerHeight={70}
      contentWidth={contentWidth}
    >
      {children}
    </StaticHeaderOnWhiteLayout>
  );
};


export default ProvideIsTablet(WhiteHeaderOnWhiteLayout);
