/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import t from 'tcomb-validation';
import { defineSchema } from 'schemas';
import { EntityReference } from 'schemas/state';
import Email from 'schemas/common/email/Email';
import { RetrievableAddress } from 'schemas/common/address';
import AddressBookRelationship from 'schemas/network/AddressBookRelationship';
import { ConnectionRequestTypes } from 'config/constants';
import NetworkActions from 'config/constants/network/NetworkActions';

const ConnectionRequestActions = t.enums({
  [NetworkActions.Resend]: '',
  [NetworkActions.Accept]: '',
  [NetworkActions.Cancel]: '',
  [NetworkActions.Decline]: ''
});

const ConnectionRequestType = t.enums({
  [ConnectionRequestTypes.Received]: '',
  [ConnectionRequestTypes.Sent]: ''
});

const ConnectionRequestOriginEvent = t.enums({
  'None': '',
  'Email': '',
  'Invoice': '',
  'Payable': '',
  'ViewpostCheck': '',
  'Viewpost': ''
});

const ConnectionRequestCompany = t.struct({
  name: t.maybe(t.String),
  phone: t.maybe(t.String),
  address: t.maybe(RetrievableAddress),
  firstName: t.maybe(t.String),
  lastName: t.maybe(t.String),
  email: t.maybe(Email),
  numberOfConnections: t.maybe(t.Number)
});

const ConnectionRequestContact = t.struct({
  firstName: t.maybe(t.String),
  lastName: t.maybe(t.String),
  email: t.maybe(Email)
});

export const ConnectionRequest = defineSchema({
  id: t.String,
  type: ConnectionRequestType,
  date: t.Date, // sent or received date
  originEvent: ConnectionRequestOriginEvent, // what caused the connection request to be created
  addressBookId: t.maybe(t.String),
  companyProfileId: t.maybe(t.String),
  companyId: t.maybe(t.String),
  relationship: t.maybe(AddressBookRelationship),
  isPendingApproval: t.Boolean,
  // the 'other' company (could be send or receiver)
  company: ConnectionRequestCompany,
  sentFrom: ConnectionRequestContact,
  sentTo: ConnectionRequestContact,
  availableActions: t.list(ConnectionRequestActions)
}, 'ConnectionRequest');

/**
 * Doing this to trick the old Company schema into believing it has a connection
 * request and has all the information about it - which we don't get with the new
 * details call - nor (believed) that we need all the request information when
 * accepting it
 */
export const FakeConnectionRequest = defineSchema({
  id: t.String
}, 'FakeConnectionRequest');

export const ConnectionRequests = defineSchema({
  received: t.list(EntityReference),
  sent: t.list(EntityReference)
}, 'ConnectionRequests');

export const AddressBookLink = defineSchema({
  id: t.String,
  links: t.list(
    t.struct({
      addressBook: EntityReference,
      weight: t.Number
    })
  )
}, 'AddressBookLink');

export const ErpVendorConnectLink = t.struct({
  selection: t.Object
});
