/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

function isEnabled() {
  return typeof global.ga !== 'undefined';
}

function initialize({trackerId, userId, appVersion}) {
  if (!trackerId) return;

  if (!global.ga) {
    // https://developers.google.com/analytics/devguides/collection/analyticsjs/
    /* eslint-disable */
    (function(i, s, o, g, r, a, m) {
      i['GoogleAnalyticsObject'] = r;
      i[r] = i[r] || function() {
        (i[r].q = i[r].q || []).push(arguments)
      }, i[r].l = 1 * new Date();
      a = s.createElement(o),
      m = s.getElementsByTagName(o)[0];
      a.async = 1;
      a.src = g;
      m.parentNode.insertBefore(a, m)
    })(window, document, 'script',
      '//www.google-analytics.com/analytics.js', 'ga'
    );
    /* eslint-enable */
  }

  global.ga('create', trackerId, 'auto');

  if (userId) {
    global.ga('set', 'userId', userId);
    global.ga('set', 'dimension1', userId);
  }

  if (appVersion) {
    global.ga('set', 'dimension2', appVersion);
  }
}

function trackEvent(category, action, {value, page, nonInteraction} = {}) {
  if (!category || !action || !isEnabled()) return;

  const eventParams = {
    hitType: 'event',
    eventCategory: category,
    eventAction: action,
    // GA defines event values as being integers, so we will co-op
    // event label to store the value of a event
    eventLabel: value ? String(value) : undefined,
    // If the event isn't a direct result of the user taking an action
    // e.g., tracking an event when a user enters a state
    nonInteraction: nonInteraction ? nonInteraction : undefined,
    page: page ? page : undefined
  };

  global.ga('send', eventParams);
}

function trackPageView(pageUrl, {srcToken} = {}) {
  global.ga('send', 'pageview', srcToken ? `${pageUrl}?src=${srcToken}` : pageUrl);
}

export default {
  isEnabled,
  initialize,
  trackEvent,
  trackPageView
};
