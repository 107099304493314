/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { toEnumObject } from './utils';

export const CompanyStatus = toEnumObject([
  'Unknown',
  'PendingRegistration',
  'Active'
]);

export const BusinessType = toEnumObject([
  'None',
  'Corporation',
  'LimitedLiability',
  'Partnership',
  'NotForProfit',
  'SoleProprietorship',
  'Other',
  'Personal'
]);

export const KnowledgeAuthenticationRequired = toEnumObject([
  'Required',
  'NotRequired',
  'PermanentFailure'
]);

export const ResetPasswordAction = {
  SetPassword: 'SetPassword',
  TwoFactorRequired: 'TwoFactorRequired',
  SecurityQuestionAuthRequired: 'SecurityQuestionAuthRequired'
};

export const CompanyAccountType = toEnumObject([
  'None',
  'Corporate',
  'Portal'
]);
