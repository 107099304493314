/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { createFormattedDateString } from 'components/FormattedDate';
import { createBoundedWholeNumber } from 'schemas/common/number';
import moment from 'moment';
import t from 'tcomb-validation';
import Messages from './date.messages';

const toMomentDate = (testDate) => {
  if (testDate) {
    let date;
    if (typeof testDate === 'string') {
      date = moment(testDate, 'M/D/YYYY');
    } else {
      date = moment(testDate);
    }
    return date;
  }

  return null;
};

const createIsSameOrAfterDate = afterDateFunc => (testDate) => {
  if (testDate) {
    let date = toMomentDate(testDate);
    if (date.isValid()) {
      return date.isSameOrAfter(afterDateFunc(), 'day');
    }
  }

  return false;
};

const createIsSameOrBeforeDate = beforeDateFunc => (testDate) => {
  if (testDate) {
    let date = toMomentDate(testDate);
    if (date.isValid()) {
      return date.isSameOrBefore(beforeDateFunc(), 'day');
    }
  }

  return false;
};

let getTodaysDate = () => moment();
let isCurrentOrFutureDate = createIsSameOrAfterDate(getTodaysDate);
let CurrentOrFutureDate = t.subtype(t.Date, isCurrentOrFutureDate, 'DateCurrentOrFuture');
CurrentOrFutureDate.getValidationErrorMessage = (value, path, context) => {
  if (!isCurrentOrFutureDate(value) && context && context.intl) {
    return context.intl.formatMessage(Messages.CurrentOrFutureDate);
  }

  return '';
};

let isCurrentOrPastDate = createIsSameOrBeforeDate(getTodaysDate);
let CurrentOrPastDate = t.subtype(t.Date, isCurrentOrPastDate, 'CurrentOrPastDate');
CurrentOrPastDate.getValidationErrorMessage = (value, path, context) => {
  if (!isCurrentOrPastDate(value) && context && context.intl) {
    return context.intl.formatMessage(Messages.CurrentOrPastDate);
  }

  return '';
};

let createSameOrAfterDate = (afterDate, { message, name } = {}) => {
  let isSameOrAfterDate = createIsSameOrAfterDate(() => toMomentDate(afterDate));
  let SameOrAfterDateType = t.subtype(t.Date, isSameOrAfterDate, name);
  SameOrAfterDateType.getValidationErrorMessage = (value, path, context) => {
    if (!isSameOrAfterDate(value) && context && context.intl) {
      return context.intl.formatMessage(
        message || Messages.SameOrFutureDate,
        { date: createFormattedDateString(context.intl, afterDate) }
      );
    }

    return '';
  };

  return SameOrAfterDateType;
};

let createSameOrBeforeDate = (beforeDate, { message } = {}) => {
  let isSameOrBeforeDate = createIsSameOrBeforeDate(() => toMomentDate(beforeDate));
  let SameOrBeforeDateType = t.subtype(t.Date, isSameOrBeforeDate);
  SameOrBeforeDateType.getValidationErrorMessage = (value, path, context) => {
    if (!isSameOrBeforeDate(value) && context && context.intl) {
      return context.intl.formatMessage(
        message || Messages.SameOrBeforeDate,
        { date: createFormattedDateString(context.intl, beforeDate) }
      );
    }

    return '';
  };

  return SameOrBeforeDateType;
};

/** A date that is within a specific min and max */
let createDateRange = (minDate, maxDate) => {
  let minMoment = toMomentDate(minDate);
  let maxMoment = toMomentDate(maxDate);
  let schema = t.subtype(t.Date, (value) => {
    return minMoment.isSameOrBefore(value, 'd') && maxMoment.isSameOrAfter(value, 'd');
  });
  schema.getValidationErrorMessage = (value, path, context) => {
    if (context && context.intl) {
      if (!minMoment.isSameOrBefore(value, 'd')) {
        return context.intl.formatMessage(
          Messages.MinDateExceeded,
          {
            date: createFormattedDateString(context.intl, minDate)
          }
        );
      } if (!maxMoment.isSameOrAfter(value, 'd')) {
        return context.intl.formatMessage(
          Messages.MaxDateExceeded,
          {
            date: createFormattedDateString(context.intl, maxDate)
          }
        );
      }
    }
  };

  return schema;
};

let TomorrowOrFutureDate = createSameOrAfterDate(
  moment().add(1, 'day'),
  { name: 'DateTomorrowOrFuture' }
);

const createYearRange = (lowerBound = 1900, upperBound = moment().year()) => {
  const type = createBoundedWholeNumber(lowerBound, upperBound);
  type.getValidationErrorMessage = (value, path, context) => {
    let num = +value;
    if (value % 1 !== 0 || num < lowerBound || num > upperBound && context && context.intl) {
      return context.intl.formatMessage(Messages.BoundedYear, {lowerBound, upperBound});
    }
  };
  return type;
};

export {
  createYearRange,
  createSameOrAfterDate,
  createSameOrBeforeDate,
  createDateRange,
  isCurrentOrFutureDate,
  isCurrentOrPastDate,
  CurrentOrPastDate,
  CurrentOrFutureDate,
  TomorrowOrFutureDate
};
