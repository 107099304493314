/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */
/* eslint max-len:0 */

import { toMessages } from 'config/messages';

export default toMessages('Registration.AchPlusEnrollment.EnterBankInformation', {
  Plaid: 'Use Your Bank Log In',
  Manually: 'Enter Manually',
  Skip: 'Remind Me Later',
  EnterManuallyReturn: 'Want to login into your bank\'s website instead? {returnLink}',
  EnterPlaidSuccess: 'Bank account was successfully added.',
  EnterPlaidReturn: 'Want to choose another bank account? {returnLink}',
  SelectOptionFailure: 'Please enter your bank account information.'
});
