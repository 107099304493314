/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import t from 'tcomb-validation';

import createAvailableActions from 'config/constants/actionsRegistry';



import { defineSchema } from 'schemas';
import { RetrievableAddress } from 'schemas/common/address';
import { AvailableActionsState } from 'schemas/common/availableActions';
import AddressBookRelationship from 'schemas/network/AddressBookRelationship';
import ConnectionStatusEnum from 'schemas/network/ConnectionStatusEnum';
import { EntityReference } from 'schemas/state';

import {
  PaymentMethods,
  PendingConnection
} from '../queryRelationships/index.schemas';

export const ContactDetailsActions = createAvailableActions(
  'ContactDetailsActions',
  [
    'ViewDefaultInfo',
    'ViewLinkedContacts',
    'ViewNetworkProfile',
    'ViewTheirContacts'
  ]
);

export const ContactDetailsAddressBook = defineSchema(
  {
    id: t.maybe(t.String),
    uri: t.maybe(t.String),
    isMine: t.maybe(t.Bool),
    companyName: t.maybe(t.String),
    firstName: t.maybe(t.String),
    lastName: t.maybe(t.String),
    email: t.maybe(t.String),
    phone: t.maybe(t.String),
    address: t.maybe(RetrievableAddress),
    relationship: t.maybe(AddressBookRelationship),
    clientReferenceId: t.maybe(t.String),
    companyNodeId: t.maybe(t.String),
    companyNodeName: t.maybe(t.String),
    isCreditCardEnabled: t.maybe(t.Bool),
    canVendorPayFees: t.maybe(t.Bool),
    isIndividual: t.maybe(t.Bool),
    targetCompanyId: t.maybe(t.String),
    achPlusFeePercent: t.maybe(t.Number),
    achPlusFeeAmount: t.maybe(t.Number),
    paymentMethods: t.maybe(PaymentMethods),
    hasPaymentDisposition: t.maybe(t.Bool),
    availableActions: AvailableActionsState,
    delegatedPaymentAccounts: t.maybe(t.list(t.struct({
      routingNumber: t.maybe(t.String),
      accountNumber: t.maybe(t.String)
    }))),
    lastPaymentDestinations: t.maybe(t.list(t.struct({
      address: RetrievableAddress,
      lastUsedDate: t.maybe(t.Date),
      payToTheOrderOf1: t.maybe(t.String),
      payToTheOrderOf2: t.maybe(t.String)
    }))),
    lastPaymentEmails: t.maybe(t.list(t.struct({
      lastUsedDate: t.maybe(t.Date),
      overrideEmail: t.maybe(t.String)
    })))
  },
  'ContactDetailsAddressBook'
);

export const ContactDetails = defineSchema(
  {
    addressBook: t.maybe(EntityReference),
    addressBookId: t.maybe(t.String),
    availableActions: AvailableActionsState,
    company: t.maybe(t.struct({
      id: t.String,
      uri: t.maybe(t.String),
      companyName: t.String,
      firstName: t.maybe(t.String),
      lastName: t.maybe(t.String),
      emailDomain: t.maybe(t.String),
      phone: t.maybe(t.String),
      address: t.maybe(RetrievableAddress),
      description: t.maybe(t.String),
      connectByClientReferenceId: t.maybe(t.Bool)
    })),
    companyId: t.maybe(t.String),
    connectionStatus: t.maybe(ConnectionStatusEnum),
    pendingConnection: PendingConnection,
    relatedAddressBooks: t.maybe(t.list(EntityReference)),
    theirAddressBooks: t.maybe(t.list(EntityReference))
  },
  'ContactDetails'
);
