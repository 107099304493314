/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */
/* eslint max-len:0 */

import { toMessages, toMessagesLegacy } from 'config/messages';

const common = toMessages('LogonWorkflow', {
  UserLockedInstructions: 'Your account is locked for your protection. If you want to unlock your account, please contact Customer Support at {contact}.',
  AssociateInvitation: 'Sign in to connect with your trading partner'
});

export const HeaderMessages = toMessagesLegacy({
  Login: {
    id: 'LoginView.Header',
    description: 'TODO',
    defaultMessage: 'Welcome back!'
  },
  LoginSubheader: {
    id: 'LoginView.LoginSubheader',
    description: 'TODO',
    defaultMessage: 'Please log into your account.'
  },
  AccessDenied: {
    id: 'LoginView.AccessDenied',
    description: 'TODO',
    defaultMessage: 'Access Denied'
  },
  CompanyClosed: {
    id: 'LoginView.CompanyClosed',
    description: 'TODO',
    defaultMessage: 'Account Closed'
  },
  WelcomeHeader1: {
    id: 'LoginView.WelcomeHeader[0]',
    description: 'TODO',
    defaultMessage: 'Welcome to'
  },
  WelcomeHeader2: {
    id: 'LoginView.WelcomeHeader[1]',
    description: 'TODO',
    defaultMessage: 'everyday business.'
  },
  WelcomeHeader3: {
    id: 'LoginView.WelcomeHeader[2]',
    description: 'TODO',
    defaultMessage: 'Reinvented.'
  },
  WelcomeBackHeader1: {
    id: 'LoginView.WelcomeBackHeader[0]',
    description: 'TODO',
    defaultMessage: 'Welcome back.'
  },
  WelcomeBackHeader2: {
    id: 'LoginView.WelcomeBackHeader[1]',
    description: 'TODO',
    defaultMessage: 'We\'ve missed you.'
  },
  KickExistingSession: {
    id: 'LoginView.KickExistingSession',
    description: 'TODO',
    defaultMessage: 'Interrupt Active Session?'
  },
  AcceptTermsAndConditions: {
    id: 'LoginView.AcceptTermsAndConditions',
    description: 'TODO',
    defaultMessage: 'We\'ll Bring You to Your Dashboard Momentarily'
  }
});

export const TextMessages = toMessagesLegacy({
  AccessDenied: {
    id: 'LoginView.Text[AccessDenied]',
    description: 'TODO',
    defaultMessage: 'Your Viewpost account has been locked. Please contact Customer Support at 1.888.248.9190.'
  },
  TemporaryAccessDenied: {
    id: 'LoginView.Text[AccessDenied]',
    description: 'TODO',
    defaultMessage: 'Your Viewpost account has been locked. You may retry in 60 minutes.'
  },
  CompanyClosed: {
    id: 'LoginView.Text[CompanyClosed]',
    description: 'TODO',
    defaultMessage: 'Your Viewpost account has been closed. If you change your mind and decide to return to Viewpost, we’d be more than happy to have you! If so, please contact Customer Support at support@viewpost.com.'
  },
  UserInactive: {
    id: 'LoginView.Text[CompanyClosed]',
    description: 'TODO',
    defaultMessage: 'Your Viewpost account has been made inactive by your company\'s admin. Contact the admin of your company\'s Viewpost account to regain access to your account.'
  },
  WelcomeMessage: {
    id: 'LoginView.WelcomeMessage',
    description: 'TODO',
    defaultMessage: 'We believe time is precious. We think the world of business should be more efficient and less cumbersome. More clear and less foggy. More Free. Less Fee. So we built a network that could bring our vision to life. For everyone.'
  },
  WelcomeBackMessage: {
    id: 'LoginView.WelcomeBackMessage',
    description: 'TODO',
    defaultMessage: 'Your session expired due to inactivity. Please sign in again to continue using Viewpost. For your security, always log out of the application when finished. If you suspect unauthorized access to your account, please notify Customer Support at 888.248.9190.'
  },
  TestEnvDisclaimer: {
    id: 'LoginView.TestEnvDisclaimer',
    description: 'TODO',
    defaultMessage: 'This is a cloud environment that is not managed or secured by Viewpost. Any data that you provide to the cloud environment is done at your own risk.'
  },
  VerifyChangeEmailInvalid: {
    id: 'LoginView.VerifyChangeEmailInvalid',
    description: 'TODO',
    defaultMessage: 'Your change email link is no longer valid. Log in with your email address that you last successfully used.'
  }
});

let buttons = toMessagesLegacy({
  Login: {
    id: 'LoginView.Button[login]',
    description: 'TODO',
    defaultMessage: 'Log In'
  },
  Continue: {
    id: 'LoginView.Button[continue]',
    description: 'TODO',
    defaultMessage: 'Continue'
  },
  AccessDenied: {
    id: 'LoginView.Button[AccessDenied]',
    description: 'TODO',
    defaultMessage: 'Ok, Got It'
  }
});

let errors = toMessagesLegacy({
  InvalidUsername: {
    id: 'LoginView.Error[invalidUsername]',
    description: 'TODO',
    defaultMessage: 'Sorry, we don\'t recognize that email address.'
  },
  InvalidPassword: {
    id: 'LoginView.Error[invalidPassword]',
    description: 'TODO',
    defaultMessage: 'Incorrect username or password. As a reminder, passwords must be 8-32 characters, contain at least one uppercase letter and one lowercase letter, and include one number or one symbol.'
  }
});

const formLabels = toMessagesLegacy({
  'userName': {
    id: 'LoginView.Field.Label[userName]',
    description: 'TODO',
    defaultMessage: 'Email Address'
  },
  'password': {
    id: 'LoginView.Field.Label[password]',
    description: 'TODO',
    defaultMessage: 'Password'
  }
});

const formPlaceholders = toMessagesLegacy({
  'userName': {
    id: 'LoginView.Field.Placeholder[userName]',
    description: 'TODO',
    defaultMessage: 'email'
  },
  'password': {
    id: 'LoginView.Field.Placeholder[password]',
    description: 'TODO',
    defaultMessage: 'password'
  }
});

export default {
  ...common,
  Headers: HeaderMessages,
  Text: TextMessages,
  errors,
  buttons,
  formLabels,
  formPlaceholders
};
