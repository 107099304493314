/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, { createRef, memo, useMemo } from 'react';
import classNames from 'classnames';
import { isObject } from 'lodash';
import { useLocation } from 'react-router-dom';
import generateDropdown from 'components/Dropdown/generateDropdown';
import Icon, { IconTypes } from 'components/Icon';
import PathLink from 'components/Link/PathLink';
import HorizontalNav from 'components/HorizontalNav';
import MenuItem from 'components/Menu/MenuItem';
import SubMenu from 'components/Menu/SubMenu';
import AnalyticsCategory from 'components/AnalyticsCategory';
import { hasPermissionForPath } from 'config/paths';
import useIsTablet from 'hooks/browser/useIsTablet';
import useIdentity from 'hooks/identity/useIdentity';
import useUserAvailableActions from 'hooks/identity/useUserAvailableActions';
import { getPathname } from 'routes/utils';
import { memoizeOne } from 'utils/memoize';
import getDesktopConfig from '../config/desktop';
import { isPathConfig } from '../config/utils';
import useNavigationParams from '../hooks/useNavigationParams';
import LogoLink from '../LogoLink';
import { PrimaryNavLabel } from '../NavLabel';
import TasksMenuDropdown from '../TasksMenuDropdown';
import SecondaryNav from './SecondaryNav';
import SettingsMenu from './SettingsMenu';
import './index.scss';

const Caret = ({ defaultCaret }) => {
  const isTablet = useIsTablet();

  if (isTablet) {
    return <Icon type={IconTypes.Hamburger} size={30} className="dropdown-settings-mobile-caret" />;
  }

  const DefaultCaret = defaultCaret;
  return <DefaultCaret/>;
};

const DesktopToggle = ({
  children,
  onClick
}) => {
  const isTablet = useIsTablet();
  const { companyName } = useIdentity();

  if (isTablet) {
    return (
      <span onClick={onClick}>
        {children}
      </span>
    );
  }

  return (
    <span className="viewstrap dropdown-settings-toggle-component" onClick={onClick}>
      <span className="company-name type-strong" title={companyName}>
        {companyName}
      </span>
      <span className="settings-divider"/>
      <span className="navSettingsMenu">
        {children}
      </span>
    </span>
  );
};

const DesktopSettingsMenuToggle = generateDropdown(
  DesktopToggle,
  {
    caretComponent: Caret,
    passCaretToMobileToggle: true
  }
);

const DesktopNav = memo(({
  primaryNavPaths,
  secondaryNavPaths,
  settingsMenuPaths
}) => {
  const {
    isPersonalUser,
    isPortalUser
  } = useNavigationParams();

  const dropdownRef = createRef();

  return (
    <AnalyticsCategory name="PrimaryNav" style={null}>
      <HorizontalNav key="desktop" className="primary-nav" addAppContainerPadding={true}>
        <SubMenu style={{ float: 'left', overflow: 'hidden' }}>
          <MenuItem className="logo">
            <LogoLink />
          </MenuItem>
          {primaryNavPaths.map(({ path }) => (
            <MenuItem key={path.id} style={{ paddingRight: 40 }}>
              <span className="viewstrap">
                <PathLink
                  path={path}
                  analyticsId={path.id}
                  className="header-link"
                  activeClassName={classNames(
                    'active',
                    'navCaret',
                    {'noSecNav': !secondaryNavPaths.length }
                  )}
                >
                  <PrimaryNavLabel id={path.id} />
                </PathLink>
              </span>
            </MenuItem>
          ))}
        </SubMenu>
        <SubMenu style={{ float: 'right' }}>
          {isPersonalUser || isPortalUser ? null : (
            <MenuItem style={{ paddingRight: 15 }}>
              <TasksMenuDropdown />
            </MenuItem>
          )}
          <MenuItem>
            <DesktopSettingsMenuToggle
              ref={dropdownRef}
            >
              <SettingsMenu
                closeMenu={() => dropdownRef.current.close()}
                paths={settingsMenuPaths}
              />
            </DesktopSettingsMenuToggle>
          </MenuItem>
        </SubMenu>
      </HorizontalNav>
      {secondaryNavPaths.length ? (
        <SecondaryNav paths={secondaryNavPaths} />
      ) : null}
    </AnalyticsCategory>
  );
});

const getValidPaths = (
  pathList,
  { availableActions }
) => {
  const paths = [];

  pathList.forEach((path) => {
    if (isPathConfig(path)) {
      if (hasPermissionForPath(path, availableActions)) {
        paths.push({ path });
      }
    } else if (isObject(path)) {
      const {
        path: innerPath,
        ...params
      } = path;

      if (isPathConfig(innerPath)
        && hasPermissionForPath(innerPath, availableActions)) {

        let matched = !params.children || params.ignoreEmptyChildren;

        if (!matched) {
          matched = params.children.some((childConfig) => {
            if (!childConfig) return false;

            if (isPathConfig(childConfig)) {
              return hasPermissionForPath(childConfig, availableActions);
            }

            const { path: innerChildPath } = childConfig;

            return hasPermissionForPath(innerChildPath, availableActions);
          });
        }

        if (matched) {
          paths.push({
            path: innerPath,
            ...params
          });
        }
      }
    }
  });

  return paths;
};

const getValidSecondaryNavPaths = memoizeOne(({
  paths,
  availableActions
}) => {
  if (!paths || !paths.length) return [];

  return getValidPaths(
    paths,
    { availableActions }
  );
});

const getSecondaryNavPaths = (
  secondaryNavs,
  pathname,
  availableActions
) => {
  const matched = secondaryNavs.find(
    (config) => {
      if (!config) return false;
      if (Array.isArray(config.matchOn)) {
        return config.matchOn.some(({ match }) => match(pathname, { alsoMatchChildren: true }));
      }

      return config.matchOn.match(pathname, { alsoMatchChildren: true });
    }
  ) || {};

  const secondaryNavPaths = matched.children || [];

  return getValidSecondaryNavPaths({
    paths: secondaryNavPaths,
    availableActions
  });
};

/**
 * Efficiency is the name of the game here - the combination of navigation params, available
 * actions, and even location* don't change often enough to necessitate that this nav should be
 * rerendering often. The way we facilitate this is a heavy reliance on memoization and referential
 * equality. While the amount of calculation is still very heavy, it reasons that 1) that
 * calculation was going to happen anyway 2) given the promenince of the nav on the page, denying
 * renders should give a moderate performance gain.
 */
const DesktopNavCalculator = memo(() => {
  const {
    accountingPackage,
    isPersonalUser,
    isPortalUser,
    isVccEnabled
  } = useNavigationParams();

  const {
    PrimaryNav,
    SecondaryNavs,
    SettingsMenu: SettingsMenuConfig
  } = useMemo(
    () => getDesktopConfig({
      accountingPackage,
      isPersonalUser,
      isPortalUser,
      isVccEnabled
    }),
    [ accountingPackage, isPersonalUser, isPortalUser, isVccEnabled ]
  );

  const availableActions = useUserAvailableActions();

  const location = useLocation();
  const pathname = getPathname({ location });

  const primaryNavPaths = useMemo(
    () => getValidPaths(
      PrimaryNav,
      { availableActions }
    ),
    [ availableActions, PrimaryNav ]
  );

  // We are not going to memoize at this level because, while the pathname will change often enough,
  // the calculated secondary navs doesn't necessarily change, so leave the referential equality
  // memoization to the function after it determines the secondary nav hasn't changed.
  const secondaryNavPaths = getSecondaryNavPaths(
    SecondaryNavs,
    pathname,
    availableActions
  );

  const settingsMenuPaths = useMemo(
    () => getValidPaths(
      SettingsMenuConfig,
      { availableActions }
    ),
    [ availableActions, SettingsMenuConfig ]
  );

  // We are relying on React.memo on DesktopNav and referential equality to stop the render here
  return (
    <DesktopNav
      primaryNavPaths={primaryNavPaths}
      secondaryNavPaths={secondaryNavPaths}
      settingsMenuPaths={settingsMenuPaths}
    />
  );
});

export default DesktopNavCalculator;
