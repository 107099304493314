/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import createApiActions from 'api/core';
import { createEntity } from 'schemas/state';
import { SecurityQuestionOptions, UserProfile } from 'schemas/settings/user';
import { isEnabled } from 'services/FeatureToggles';
import { parseDate } from 'utils/dateUtils';

const getSecurityQuestionsResponseMapper = response => ({
  entities: [createEntity(
    'current',
    SecurityQuestionOptions.meta.name,
    { options: response.items }
  )]
});

const getUserProfilesResponseMapper = (apiResponse) => {
  const {
    hasPendingEmailChange,
    passwordLastChanged,
    securityQuestions,
    userSecurityQuestions,
    pendingEmailAddress,
    hasPasswordChanged,
    createdDate
  } = apiResponse;

  const entities = [
    createEntity(
      'current',
      UserProfile.meta.name,
      {
        createdDate,
        hasPasswordChanged,
        hasPendingEmailChange,
        passwordLastChanged: parseDate(passwordLastChanged),
        securityQuestions,
        userSecurityQuestionIds: userSecurityQuestions.map(item => item.id),
        pendingEmailAddress
      }
    ),
    createEntity(
      'current',
      SecurityQuestionOptions.meta.name,
      { options: securityQuestions }
    )
  ];

  return {
    // returning all data in addition to the entity so that I don't have to touch the existing User Settings page
    createdDate: parseDate(createdDate),
    hasPasswordChanged,
    hasPendingEmailChange,
    passwordLastChanged: parseDate(passwordLastChanged),
    securityQuestions,
    userSecurityQuestionIds: userSecurityQuestions.map(item => item.id),
    pendingEmailAddress,
    entities
  };
};


const createTwoFactorAuthenticationResponseBodyMapper = (apiResponse) => {
  return {
    isPhoneNumberLocked: apiResponse.isPhoneNumberLocked,
    skipValidation: apiResponse.skipValidation
  };
};

const resendCodeResponseMapper = (apiResponse) => {
  return {
    isPhoneNumberLocked: apiResponse.isPhoneNumberLocked
  };
};

export default createApiActions({
  getUserProfile: {
    method: 'get',
    url: '/api/userProfiles',
    responseMapper: getUserProfilesResponseMapper
  },
  getSecurityQuestions: {
    method: 'get',
    url: '/api/webApp/securityQuestions',
    responseMapper: getSecurityQuestionsResponseMapper
  },
  createTwoFactorAuthentication: {
    method: 'post',
    url: '/api/next/user-profile/twoFactor/create',
    requestBodyMapper: ({ phoneNumber }) => ({
      phoneNumber,
      twoFactorAuthenticationType: isEnabled('enableAuthy2fa') ? 'Authy' : null
    }),
    responseMapper: createTwoFactorAuthenticationResponseBodyMapper
  },
  resendTwoFactorAuthentication: {
    method: 'post',
    url: '/api/next/user-profile/twoFactor/resend',
    requestBodyMapper: ({}) => ({
      twoFactorAuthenticationType: isEnabled('enableAuthy2fa') ? 'Authy' : null
    }),
    responseMapper: resendCodeResponseMapper
  }
});
