/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import t from 'tcomb-validation';
import Messages from './number.messages';

const isPositive = value => value > 0;
const isPositiveWithZero = value => 0 || value === 0;

function isWholeNumber(value) {
  return value % 1 === 0;
}

function isInBounds(value, lowerBound, upperBound) {
  return lowerBound <= value && value <= upperBound;
}

export const PositiveNumber = t.subtype(t.Number, isPositive);

PositiveNumber.getValidationErrorMessage = (value) => {
  if (!isPositive(value)) {
    return 'Please enter a positive value.';
  }
};

export const PositiveOrZeroNumber = t.subtype(t.Number, isPositiveWithZero);

PositiveOrZeroNumber.getValidationErrorMessage = (value) => {
  if (!isPositiveWithZero(value)) {
    return 'Please enter a positive value.';
  }
};

const WholeNumber = t.subtype(t.Number, (value) => {
  return isWholeNumber(value);
});

WholeNumber.getValidationErrorMessage = (value) => {
  if (!isWholeNumber(value)) {
    return 'Please enter a whole number.';
  }
};

const PositiveWholeNumber = t.subtype(t.Number, (value) => {
  return isPositive(value) && isWholeNumber(value);
});

PositiveWholeNumber.getValidationErrorMessage = (value) => {
  return PositiveNumber.getValidationErrorMessage(value)
    || WholeNumber.getValidationErrorMessage(value);
};

function createNumberWithUnits(
  numberType = t.Number,
  unitsType = t.enums({
    Day: 'day',
    Week: 'week',
    Month: 'month'
  })
) {
  let type = t.struct({
    number: numberType,
    units: unitsType
  }, 'NumberWithUnits');

  type.getValidationErrorMessage = (value) => {
    if (value) {
      let message;
      if (!message && numberType && numberType.getValidationErrorMessage) {
        message = numberType.getValidationErrorMessage(value.number);
      } else if (!message && unitsType && unitsType.getValidationErrorMessage) {
        message = unitsType.getValidationErrorMessage(value.units);
      }
      return message;
    }
  };

  return type;
}

const createPositiveWholeNumberLessThanDigits = (maxDigits) => {
  let type = t.subtype(PositiveWholeNumber, (value) => {
    return value.toString().length < maxDigits;
  }, 'PositiveWholeNumberLessThanDigits');

  type.getValidationErrorMessage = (value, path, context) => {
    if ((!isWholeNumber(value) || value <= 0 || value.toString().length >= maxDigits) && context && context.intl) {
      return context.intl.formatMessage(Messages.NegativeOrTooLargeOrNotInteger, {maxDigits});
    }
  };

  return type;
};

const createBoundedWholeNumber = (lowerBound = 1, upperBound = 99999) => {
  let type = t.subtype(WholeNumber, (value) => {
    let num = +(value);
    return isInBounds(num, lowerBound, upperBound);
  }, 'BoundedWholeNumberLessThanDigits');

  type.getValidationErrorMessage = (value, path, context) => {
    let num = +value;
    if ((!isWholeNumber(value) || !isInBounds(num, lowerBound, upperBound)) && context && context.intl) {
      return context.intl.formatMessage(Messages.BoundedWholeNumber, {lowerBound, upperBound});
    }
  };

  return type;
};

function getMaxDigits(type) {
  if (!type) return null;
  if (type.maxDigits) return type.maxDigits;
  if (!type.type) return null;
  return getMaxDigits(type.type.meta);
}

function getMaxDecimals(type) {
  if (!type) return null;
  if (type.maxDecimals) return type.maxDecimals;
  if (!type.type) return null;
  return getMaxDecimals(type.type.meta);
}

function isDigitsValid(length, number) {
  if (!number) return true;
  return number.toString().length <= length;
}

function isDigitsGreaterThan(length, number) {
  if (!number) return true;
  return number.toString().length >= length;
}

function isDecimalsValid(length, number) {
  if (!number) return true;
  let parsedNumber = number.toString().split('.');
  return !parsedNumber[1] || parsedNumber[1].length <= length;
}

function createMaxDigitsNumberType(length) {
  let type = t.subtype(t.Number, isDigitsValid.bind(this, length), 'MaxDigits');
  type.meta.maxDigits = length;
  type.getValidationErrorMessage = (value, path, context) => {
    if (!isDigitsValid(length, value) && context && context.intl) {
      return context.intl.formatMessage(Messages.TooManyDigits, {maxDigits: length});
    } if (context && context.intl) {
      return context.intl.formatMessage(Messages.NonNumericString);
    }

    return '';
  };
  return type;
}

function createMaxDecimalsNumberType(length) {
  let type = t.subtype(t.Number, isDecimalsValid.bind(this, length), 'MaxDecimals');
  type.meta.maxDecimals = length;
  type.getValidationErrorMessage = (value, path, context) => {
    if (!isDecimalsValid(length, value) && context && context.intl) {
      return context.intl.formatMessage(Messages.TooManyDecimals, {maxDecimals: length});
    } if (context && context.intl) {
      return context.intl.formatMessage(Messages.NonNumericString);
    }
  };
  return type;
}

function createPositiveMaxDigitsAndDecimalsNumberType(digits, decimals) {
  function isValid(number) {
    return isDigitsValid(digits, number) && isDecimalsValid(decimals, number) && number > 0;
  }

  let type = t.subtype(t.Number, isValid, 'MaxDigitsAndDecimals');
  type.meta.maxDigits = digits;
  type.meta.maxDecimals = decimals;
  type.getValidationErrorMessage = (value, path, context) => {
    if (value <= 0 && context && context.intl) {
      return context.intl.formatMessage(Messages.GreaterThanZero);
    } if (!isValid(value) && context && context.intl) {
      return context.intl.formatMessage(Messages.TooManyDigitsOrDecimals, {maxDigits: digits, maxDecimals: decimals});
    } if (context && context.intl) {
      return context.intl.formatMessage(Messages.NonNumericString);
    }

    return '';
  };
  return type;
}

let numericStringRegex = /^\d+$/;
function isValidNumericString(value) {
  return numericStringRegex.test(value);
}

const NumericString = t.subtype(t.String, isValidNumericString, 'NumericString');
NumericString.getValidationErrorMessage = (value, path, context) => {
  if (!isValidNumericString(value) && context && context.intl) {
    return context.intl.formatMessage(Messages.NonNumericString);
  }

  return '';
};

function createBoundedLengthNumberType(minLength, maxLength) {
  function isValid(number) {
    if (!number) return true;
    return isDigitsGreaterThan(minLength, number) && isDigitsValid(maxLength, number);
  }

  let type = t.subtype(NumericString, isValid, 'BoundedLengthNumber');
  type.meta.maxDigits = maxLength;
  type.meta.minDigits = minLength;
  type.getValidationErrorMessage = (value, path, context, ...args) => {
    let baseErrorMessage = NumericString.getValidationErrorMessage(value, path, context, ...args);
    if (baseErrorMessage) return baseErrorMessage;

    if (context && context.intl) {
      if (minLength === maxLength && value.length !== minLength) {
        return context.intl.formatMessage(Messages.WrongNumberOfDigits, {digits: minLength});
      } if (!isDigitsValid(maxLength, value) && context && context.intl) {
        return context.intl.formatMessage(Messages.TooManyDigits, {maxDigits: maxLength});
      } if (!isDigitsGreaterThan(minLength, value) && context && context.intl) {
        return context.intl.formatMessage(Messages.TooFewDigits, {minDigits: minLength});
      }
    }

    return '';
  };
  return type;
}

const createBoundedDigitOnlyNonSequentialId = (length) => {
  const isValid = (id) => {
    if (id.toString().length !== length) {
      return false;
    }

    const sequenceString = '01234567890123456789';
    const reverseSequenceString = '98765432109876543210';
    const isSequentialAscending = sequenceString.includes(id);
    const isSequentialDescending = reverseSequenceString.includes(id);
    const isSameDigit = /^(.)\1+$/.test(id);

    return !isSequentialAscending && !isSequentialDescending && !isSameDigit;
  };

  const type = t.subtype(NumericString, isValid, 'BoundedDigitOnlyNonSequentialId');

  type.meta.maxDigits = length;
  type.meta.minDigits = length;
  type.getValidationErrorMessage = (value, path, context, ...args) => {
    const baseErrorMessage = NumericString.getValidationErrorMessage(value, path, context, ...args);

    if (baseErrorMessage) {
      return 'Tax ID must contain only numbers.';
    }

    if (value.toString().length !== length) {
      return 'Tax ID must be nine digits long.';
    }

    return 'Please enter a valid Tax ID number.';
  };

  return type;
};

export {
  createMaxDigitsNumberType,
  createMaxDecimalsNumberType,
  createPositiveMaxDigitsAndDecimalsNumberType,
  createBoundedLengthNumberType,
  getMaxDigits,
  getMaxDecimals,
  NumericString,
  WholeNumber,
  PositiveWholeNumber,
  createBoundedWholeNumber,
  createPositiveWholeNumberLessThanDigits,
  createBoundedDigitOnlyNonSequentialId,
  createNumberWithUnits
};
