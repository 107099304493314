/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import moment from 'moment';
import { Children } from 'react';

function verifyEl({ type }, compName, validatingComponents) {
  if (!type) {
    throw new Error(`${compName} does not have a 'type'.`);
  }
  if (!validatingComponents.includes(type)) {
    let names = validatingComponents.map(c => c.name);
    throw new Error(`${compName} only accepts children of these types: [${names.join(', ')}].`);
  }
}

/*
  Validate that all children must be of a specific type.

  **Example**:
    import MenuItem from 'components/Menu/MenuItem';
    export default class Menu extends Component {
      static propTypes = {
        children: validateChildren(MenuItem)
      }
      ...
    }
*/
export function validateChildren(...validatingComponents) {
  return (props, propName, componentName) => {
    // this gets the children from props
    let prop = props[propName];
    Children.forEach(prop, (el) => {
      verifyEl(el, componentName, validatingComponents);
    });
  };
}

export function addClass(domNode, className) {
  if (domNode && typeof className === 'string'
    && domNode.className.indexOf(className) === -1) {
    domNode.className += ` ${className}`;
  }
}

export function removeClass(domNode, className) {
  if (domNode && typeof className === 'string'
    && domNode.className.indexOf(className) > -1) {
    domNode.className = domNode.className.replace(className, '');
  }
}

export function removeTabIndex(domNode) {
  if (domNode) {
    domNode.tabIndex = -1;
  }
}

export function getMoment(date) {
  if (typeof date === 'string') {
    return moment(date, ['M/D/YYYY', 'M/D/YY', 'YYYY-M-D', moment.ISO_8601], true);
  } if (date !== null && typeof date === 'object') {
    return moment(date);
  }

  return moment.invalid();
}
