/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, { useEffect } from 'react';

import TextBox from 'components/Form/Inputs/TextBox';

import useFuncRef from 'hooks/react/useFuncRef';
import usePropRef from 'hooks/react/usePropRef';

const strip = (value) => {
  if (value == null) return null;

  return value.replace(/\D/g, '');
};

const prettify = (originalValue) => {
  const value = strip(originalValue);
  const parts = [];

  if (value) {
    if (value.length) {
      parts.push(value.slice(0, 5));
    }

    if (value.length > 5) {
      parts.push(`-${value.slice(5)}`);
    }
  }

  return value ? parts.join('') : null;
};

const PostalCodeTextBox = ({
  onChange: originalOnChange,
  value: originalValue,
  ...props
}) => {
  // Hooks
  // Capture onChange in a ref so we don't need to worry about it changing as we
  // use it in the functions below that only have their scope established once.
  const onChangeRef = usePropRef(originalOnChange);

  const value = prettify(originalValue);

  useEffect(
    () => {
      // No value yet, no need to format
      if (value == null) {
        return;
      }

      const cleanedValue = strip(value);
      // If our prettified version, once cleaned up, doesn't match the original
      // value, pass that value up - we only want strings that are numbers for
      // the value.
      if (cleanedValue !== originalValue) {
        onChangeRef.current(cleanedValue);
      }
    },
    [ value ]
  );

  const { current: onChange } = useFuncRef(
    () => (inputValue) => {
      const newValue = strip(inputValue);
      onChangeRef.current(newValue);
    }
  );

  // Render
  return (
    <TextBox
      {...props}
      onChange={onChange}
      value={value}
    />
  );
};

export default PostalCodeTextBox;
