/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */
/* eslint max-len:0 */

import { toMessages } from 'config/messages';

export default toMessages('Subscription.SubscriptionPaymentForm', {
  SubscriptionInstructionsHeader: 'Viewpost Monthly Subscription',
  SubscriptionInstructions: 'The credit card you enter below will be charged {amount} immediately for your first month of service and {billingDay} as long as your subscription is active. {feesNotice}',
  FutureSubscriptionInstructions: 'Your card will be charged {amount} for your first month of service and {billingDay} as long as your subscription is active. {feesNotice}',
  DelinquentSubscriptionInstructions: 'The credit card you enter below will be charged {amount} immediately to continue your service and {billingDay} as long as your subscription is active. {feesNotice}',
  FeesSection: 'Transaction fees incurred using your Viewpost account are billed separately at the beginning of each month.',
  MidMonthBilling: 'on the {day, selectordinal, one {#st} two {#nd} few {#rd} other {#th}} of each month',
  LastDayBilling: 'on the last day of the month',
  SuccessHeader: 'Subscription Setup Complete',
  SuccessInstructions: '{amount} has been charged to your credit card for the balance of this month. Future monthly payments of {amount} will occur automatically {billingDay}.'
});
