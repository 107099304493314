/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { isString } from 'lodash';
import { Row } from 'components/Viewstrap/Grid';

const FieldSection = (props) => {
  const { children, ...config } = props;

  const { className, component: WrapperComponent, style } = config;

  if (WrapperComponent) {
    return (
      <WrapperComponent {...config}>
        {children}
      </WrapperComponent>
    );
  }

  return (
    <div className={className} style={style}>
      {children}
    </div>
  );
};

/**
 * Builds a form section based on a tcomb struct type
 * @docsignore - automatically works with FormJSX, don't really need this documented
 */
class Struct extends Component {
  static propTypes = {
    addPadding: PropTypes.string
  };

  static defaultProps = {
    addPadding: 'y-half'
  };

  constructor(props) {
    super(props);
  }

  /** get all the field names to render */
  getFieldNames() {
    let options = this.props.options;
    let order = options.config.order || options.order;
    if (order) {
      return order;
    }

    // use default ordering
    return Object.keys(options.inputs);
  }

  /** get the renderable component (layout+input) for a single field */
  getFieldComponent(fieldName, fieldKey) {
    if (typeof fieldName === 'object') {
      // custom element, it should be a renderable component
      let fieldOptions = this.getFieldOptions(fieldName);
      return (
        <span key={`field-${fieldKey}`}
          className={classNames(`col-${fieldOptions.config.cols}`, fieldName.props.layoutClass)}>
          {fieldName}
        </span>
      );
    }

    // tcomb-forms provides a React component via the inputs property
    if (!this.props.options.inputs) {
      return null;
    }

    return this.props.options.inputs[fieldName];
  }

  /** get the tcomb-form options specific to a field */
  getFieldOptions(fieldName) {
    if (typeof fieldName === 'object') {
      // custom element, fabricate an fieldOptions object that provides minimal info
      return {
        config: {
          cols: fieldName.props.cols || 12
        }
      };
    }

    let options = this.props.options;
    return options.inputs[fieldName] && options.inputs[fieldName].props
      ? options.inputs[fieldName].props.options
      : null;
  }

  /** group fields and components into sections */
  buildFieldSections() {
    let fieldNames = this.getFieldNames();
    let fieldSections = [];
    let unsectionedCount = 0;

    if (fieldNames) {
      fieldNames.forEach((fieldName) => {
        let fieldOptions = this.getFieldOptions(fieldName);

        let sectionConfig = fieldOptions ? fieldOptions.config.section : null;
        let sectionId = null;
        if (sectionConfig && sectionConfig.sectionId) {
          // FieldSection section
          sectionId = sectionConfig.sectionId;
          unsectionedCount += 1;
        } else if (fieldName.section) {
          sectionId = fieldName.section.sectionId;
          unsectionedCount += 1;
        } else {
          // not in a section
          sectionId = `unsectioned-${unsectionedCount}`;
        }

        // add field to section
        if (fieldSections.length === 0
          || fieldSections[fieldSections.length - 1].sectionId !== sectionId) {
          // create new section
          fieldSections.push({
            sectionId,
            config: sectionConfig,
            fields: [fieldName]
          });
        } else {
          // add field to existing section
          fieldSections[fieldSections.length - 1].fields.push(fieldName);
        }
      });
    }

    // console.log('Field Sections', fieldSections);
    return fieldSections;
  }

  renderSections() {
    let fieldSections = this.buildFieldSections();
    let sections = [];

    fieldSections.forEach((fieldSection, index) => {
      if (!fieldSection.sectionId
        || (isString(fieldSection.sectionId) && fieldSection.sectionId.startsWith('unsectioned'))) {
        // not really a section, just render the rows
        sections = sections.concat(this.renderRows(fieldSection.fields, fieldSection.sectionId));
      } else {
        if (fieldSection.config) {
          sections.push(
            <FieldSection key={index} {...fieldSection.config}>
              {this.renderRows(fieldSection.fields, fieldSection.sectionId)}
            </FieldSection>
          );
        }
      }

    });

    return sections;
  }

  renderRows(fieldNames, sectionId) {
    let rowElements = [];

    let pendingRowFieldNames = [];

    let currentRowCols = 0;
    let containsOnlyHidden = true;

    fieldNames.forEach((fieldName, rowIndex) => {
      let component = this.getFieldComponent(fieldName);
      if (!component) {
        return;
      }

      let fieldOptions = this.getFieldOptions(fieldName);

      // support grid-based layout
      let fieldCols = fieldOptions ? fieldOptions.config.cols : null;
      if (!fieldCols || isNaN(fieldCols)) {
        fieldCols = 12;
      }
      fieldCols = parseInt(fieldCols, 10);

      if (currentRowCols + fieldCols > 12) {
        // field won't fit, finish last row, and start a new one
        rowElements.push(this.renderRow(
          pendingRowFieldNames,
          containsOnlyHidden,
          `${rowIndex}${sectionId ? (`-${sectionId}`) : ''}`
        ));

        pendingRowFieldNames = [];
        currentRowCols = 0;
        containsOnlyHidden = true;
      }

      // Determine if adding this element would require the row to be displayed
      containsOnlyHidden = containsOnlyHidden && fieldOptions.type === 'hidden';

      // add field name to row
      pendingRowFieldNames.push(fieldName);
      currentRowCols += fieldCols;
    });

    if (pendingRowFieldNames.length > 0) {
      // render the last row
      rowElements.push(this.renderRow(
        pendingRowFieldNames,
        containsOnlyHidden,
        `last${sectionId ? (`-${sectionId}`) : ''}`
      ));
    }

    return rowElements;
  }

  renderRow(fieldNames, containsOnlyHidden, rowKey) {
    let options = this.props.options;
    let rowElements = [];
    let hasFields = false;

    fieldNames.forEach((fieldName, index) => {
      const fieldComponent = this.getFieldComponent(fieldName, index);
      hasFields = !!(fieldComponent.props.options && fieldComponent.props.options.fields);
      rowElements.push(fieldComponent);
    });

    if (containsOnlyHidden) {
      return (
        <div key={`row-${rowKey}`} style={{
          display: 'none'
        }}>
          {rowElements}
        </div>
      );
    }

    const rowAddColumnGap = this.props.rowAddColumnGap === false
      ? false
      : options.config.rowAddColumnGap !== false;

    return (
      <Row key={`row-${rowKey}`}
        addColumnGap={rowAddColumnGap}
        stackOn={!this.props.dontStack ? 'small' : null}
        addPadding={hasFields ? null : this.props.addPadding}>
        {rowElements}
      </Row>
    );
  }

  render() {
    return (
      <div className="vp-form-struct">
        {this.renderSections()}

        {this.props.options.hasError
          ? <span className="vp-error-message">{this.props.options.error}</span>
          : null}
      </div>
    );
  }
}

export default Struct;
