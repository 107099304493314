/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

// KEEP THIS FILE ES5 COMPLIANT UNTIL MODULES WITHOUT BABEL IS SUPPORTED IN NODE
/* eslint max-len:0 */

module.exports = {
  allowMissingSigner: {
    name: 'Allow Clearing of Authorized Signer (for testing)',
    author: 'jc',
    description: 'This disables the validation check for Authorized Signer when editing a Bank Account on the Payment Methods page. This makes it easy to get a Payment Method into a Missing Signature state by clearing the Authorized Signer field (and avoid have to recreate accounts from the native mobile app).',
    category: 'debugging'
  },
  allowClearAddress: {
    name: 'Allow Clearing of an address book\'s address (for testing)',
    author: 'jc',
    description: 'This disables the validation check for required address fields when editing an address. This is useful for getting into a no address state for testing.',
    category: 'debugging'
  },
  spoofKBA: {
    name: 'Spoof KBA 👻',
    author: 'jc',
    description: 'Always run KBA flows using spoofed values (so it does not require real validation or effect the state of the account at all). Useful for testing KBA UI flow without having to create new accounts.',
    category: 'debugging'
  },
  forceAccountSetup: {
    name: 'Force Account Setup',
    author: 'jleonard',
    description: 'Erases the existence of the accountSetup acknowledgement to force account setup',
    category: 'debugging'
  },
  vendorPaysFees: {
    name: 'Vendor Pays Customer\'s Fees',
    author: 'jc',
    description: 'Allow vendors to opt in to paying their customer\'s payment transaction fees.'
  },
  vpxBillsOnboardLanding: {
    name: 'VPX Bills Onboarding',
    author: 'jc',
    description: 'Show Vpx info on the bills onboarding landing page'
  },
  newLoadingIndicator: {
    name: 'New Loading Indicator',
    author: 'jleonard',
    description: 'Changes the loading indicator to a spinning circle, per UX request'
  },
  enableAuthy2fa: {
    name: 'Enable Authy 2FA',
    author: 'jleonard',
    description: 'Changes 2FA registration to go through Authy'
  },
  individualWorkflow: {
    name: 'Individual Registration Workflow',
    author: 'jleonard',
    description: 'Allow for individuals to register for the personal portal',
    forced: true
  },
  advancedApprovals: {
    name: 'Enterprise Payment Approvals',
    author: 'jc',
    description: 'Support more complicated payment approval rule set configuration for enterprise companies',
    forced: true
  },
  oumcNetworkList: {
    name: 'OUMC Network List',
    author: 'jleonard',
    description: 'Use the Network list across multiple companies',
    forced: true
  }
};
