/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { intersection } from 'lodash';
import CommonApi from 'api/common';
import TelemetryApi from 'api/telemetry';
import { getEntity } from 'schemas/state';
import { BusinessType } from 'config/constants/session';
import logError from 'services/ErrorService';

import getIdentity from './common/getIdentity';
import isAuthorizedToUseApp from './common/isAuthorizedToUseApp';
import isUserOfType from './common/isUserOfType';

// Support the aggregate index for a little bit while everyone is still using
// this file to get these functions from.
export {
  getIdentity,
  isAuthorizedToUseApp
};

export const batch = (batchedList, chainedAction, correlationId) => {
  return (dispatch) => {
    // there is a server-side limitation of 50 requests at a time
    let chunk = 49;
    for (let i = 0; i < batchedList.length; i += chunk) {
      dispatch(CommonApi.batch(batchedList.slice(i, i + chunk), chainedAction, correlationId));
    }
  };
};

export const isAcknowledged = (state, acknowledgement) => {
  if (!state || !state.entities || !state.entities.Acknowledgement) return false;

  if (state.entities.Acknowledgement[acknowledgement]) {
    return state.entities.Acknowledgement[acknowledgement].value.completed;
  }

  return false;
};

export const isPartiallyAuthenticated = (state) => {
  return !!getEntity(state, state.session.partialIdentity);
};

export const isAccountSecure = (state) => {
  let identity = getEntity(state, state.session.identity);
  return identity ? identity.isAccountSecure : false;
};

export const doesUserHaveAvailableAction = (state, availableAction) => {
  const identity = getIdentity(state);

  if (!identity.availableActions) {
    logError('Expected availableActions, but availableActions is missing.', null, { includeStackTrace: true });
    return false;
  }

  return identity.availableActions.includes(availableAction);
};

// Returns if a user has all or some of the provided available actions
// NeedAll = true, needs all. NeedAll = false, needs at least one
export const doesUserHaveAvailableActions = (state, needAll, ...availableActions) => {
  const identity = getIdentity(state);

  if (!identity.availableActions) {
    logError('Expected availableActions, but availableActions is missing.');
    return false;
  }

  const intersectCount = intersection(identity.availableActions, availableActions || []).length;

  return needAll
    ? intersectCount === (availableActions || []).length
    : intersectCount;
};

export const getAvailableActionDeclinedReason = (state, availableAction) => {
  const identity = getIdentity(state);

  if (!identity.deniedAvailableActions) {
    logError('Expected deniedAvailableActions, but deniedAvailableActions is missing.');
    return null;
  }

  if (identity.deniedAvailableActions[availableAction]) {
    return identity.deniedAvailableActions[availableAction][0];
  }

  return null;
};

export const getAvailableActionsState = (state) => {
  const identity = getIdentity(state);

  if (!identity.availableActions) {
    logError('Expected availableActions, but availableActions is missing.');
    return null;
  }

  if (!identity.deniedAvailableActions) {
    logError('Expected deniedAvailableActions, but deniedAvailableActions is missing.');
    return null;
  }

  return { availableActions: identity.availableActions, deniedReasons: identity.deniedAvailableActions };
};

export const getAvailableActionState = (state, availableAction, canDoPropName, declinedReasonPropName) => ({
  [canDoPropName]: doesUserHaveAvailableAction(state, availableAction),
  [declinedReasonPropName]: getAvailableActionDeclinedReason(state, availableAction)
});

export const isPersonalPortal = (state) => {
  return isUserOfType(state, null, BusinessType.Personal);
};

export const getApiFailure = (state) => {
  if (!state || !state.entities || !state.entities.ApiFatalError) return false;

  if (state.entities.ApiFatalError.current) {
    return state.entities.ApiFatalError.current.value;
  }

  return false;
};

export const nukeState = reducerKey => dispatch => dispatch({
  type: 'NukeState',
  reducer: reducerKey
});

export const nukeEntities = entityKey => dispatch => dispatch({
  type: 'NukeEntities',
  entity: entityKey
});

export const recordEvent = (name, data) => (dispatch, getState) => dispatch(
  TelemetryApi.recordEvent({
    name,
    ...data
  })
);
