/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

/* eslint react/no-multi-comp:0 */
/* eslint max-classes-per-file:0 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FormJSX from 'components/Form/FormJSX';
import FieldOption from 'components/Form/FieldOption';
import { createUserSecurityQuestions } from 'schemas/settings/user';
import LoadingIndicator from 'components/LoadingIndicator';
import PasswordToggle from 'components/Form/Inputs/PasswordToggleTextBox';
import classNames from 'classnames';
import Messages from './index.messages.js';
import './index.scss';

class BaseSecurityQuestionsForm extends Component {
  static propTypes = {
    showLabels: PropTypes.bool,
    variant: PropTypes.oneOf(['default', 'stacked'])
  };

  static defaultProps = {
    showLabels: false,
    variant: 'default'
  };

  constructor(props) {
    super(props);

    this.state = { formState: {} };

    this.onFormChange = this.onFormChange.bind(this);
    this.getValue = () => this.formRef.getValue();
  }

  onFormChange(formState) {
    const { onShouldSubmitDisable } = this.props;

    if (onShouldSubmitDisable) {
      let allAreCompleted = formState.question1 && formState.question2 && formState.question3
        && formState.answer1 && formState.answer2 && formState.answer3;
      let allWereCompleted = this.state.formState.question1 && this.state.formState.question2
        && this.state.formState.question3 && this.state.formState.answer1 && this.state.formState.answer2
        && this.state.formState.answer3;
      if (allAreCompleted && !allWereCompleted) {
        onShouldSubmitDisable(false);
      } else if (allWereCompleted && !allAreCompleted) {
        onShouldSubmitDisable(true);
      }
    }

    this.setState({
      formState
    });
  }

  render() {
    const { variant, showLabels, securityQuestions } = this.props;

    if (!securityQuestions) {
      return <LoadingIndicator />;
    }

    let selectedQuestions = [
      this.state.formState.question1,
      this.state.formState.question2,
      this.state.formState.question3
    ];
    let schema = createUserSecurityQuestions(securityQuestions
      ? securityQuestions : [], selectedQuestions);
    return (
      <div>
        <FormJSX
          ref={ref => this.formRef = ref}
          onChange={this.onFormChange}
          className="security-questions-form"
          modelType={schema}
          value={this.state.formState}>
          <FieldOption name="question1"
            cols={variant === 'stacked' ? 12 : 8}
            label={Messages.formLabels.Question1}
            willHideLabel={!showLabels}
            placeholder={showLabels ? null : Messages.formLabels.Question1}
            searchable={false}
            customInputClass={classNames({
              'is-selected': this.state.formState.question1,
              'grouped-question': variant === 'stacked'
            })} />
          <FieldOption name="answer1"
            willHideLabel={!showLabels}
            emptyLabel={showLabels}
            cols={variant === 'stacked' ? 12 : 4}
            placeholder={Messages.placeholders.YourAnswer}
            customInputClass={classNames({'grouped-answer': variant === 'stacked'})}
            inputComponent={PasswordToggle} />
          <FieldOption name="question2"
            cols={variant === 'stacked' ? 12 : 8}
            label={Messages.formLabels.Question2}
            willHideLabel={!showLabels}
            placeholder={showLabels ? null : Messages.formLabels.Question2}
            searchable={false}
            customInputClass={classNames({
              'is-selected': this.state.formState.question2,
              'grouped-question': variant === 'stacked'
            })} />
          <FieldOption name="answer2"
            willHideLabel={!showLabels}
            emptyLabel={showLabels}
            cols={variant === 'stacked' ? 12 : 4}
            placeholder={Messages.placeholders.YourAnswer}
            customInputClass={classNames({'grouped-answer': variant === 'stacked'})}
            inputComponent={PasswordToggle} />
          <FieldOption name="question3"
            cols={variant === 'stacked' ? 12 : 8}
            label={Messages.formLabels.Question3}
            willHideLabel={!showLabels}
            placeholder={showLabels ? null : Messages.formLabels.Question3}
            searchable={false}
            customInputClass={classNames({
              'is-selected': this.state.formState.question3,
              'grouped-question': variant === 'stacked'
            })} />
          <FieldOption name="answer3"
            willHideLabel={!showLabels}
            emptyLabel={showLabels}
            cols={variant === 'stacked' ? 12 : 4}
            placeholder={Messages.placeholders.YourAnswer}
            customInputClass={classNames({'grouped-answer': variant === 'stacked'})}
            inputComponent={PasswordToggle} />
        </FormJSX>
      </div>
    );
  }
}

const createBaseSecurityQuestionsForm = (WrappingComponent) => {
  return class ManagedSecurityQuestions extends Component {
    constructor(props) {
      super(props);

      this.state = {
        submitButtonDisabled: true
      };

      this.onShouldSubmitDisable = this.onShouldSubmitDisable.bind(this);
      this.onSubmitQuestions = this.onSubmitQuestions.bind(this);
    }

    onShouldSubmitDisable(isDisabled) {
      this.setState({
        submitButtonDisabled: isDisabled
      });
    }

    onSubmitQuestions() {
      const value = this.securityQuestionsRef.getValue();
      if (value) {
        this.props.onSubmitQuestions(value);
      }
    }

    render() {
      const { variant, showLabels, securityQuestions, ...props } = this.props;

      return (
        <WrappingComponent
          {...props}
          onSubmitQuestions={this.onSubmitQuestions}
          isSubmitButtonDisabled={this.state.submitButtonDisabled}
        >
          <BaseSecurityQuestionsForm
            ref={ref => this.securityQuestionsRef = ref}
            onShouldSubmitDisable={this.onShouldSubmitDisable}
            variant={variant}
            showLabels={showLabels}
            securityQuestions={securityQuestions}
          />
        </WrappingComponent>
      );
    }
  };
};

export default createBaseSecurityQuestionsForm;
