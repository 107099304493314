/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { canUseDOM } from 'fbjs/lib/ExecutionEnvironment';
import fetch from 'isomorphic-fetch';
import { isObject } from 'lodash';

function isJsonResponse(response) {
  let contentHeader = response.headers['Content-Type'];
  if (contentHeader) {

    let contentTypes = contentHeader.split(';');
    if (contentTypes && contentTypes.length > 0) {
      let contentType = contentTypes[0];

      return contentType === 'application/json';
    }
  }
  return false;
}

const callApi = async (
  url,
  method,
  {
    body,
    headers
  }
) => {
  let headersToSend = headers || {};
  // default expected GET responses to JSON
  if (!headersToSend.Accept) {
    headersToSend.Accept = 'application/json';
  }

  if (!headersToSend['X-Requested-With']) {
    headersToSend['X-Requested-With'] = 'XMLHttpRequest';
  }

  let bodyContent = body;
  if (body && canUseDOM && body instanceof FormData) {
    // we're currently only using FormData for multipart file uploads

    // Don't set the Content-Type header and the browser should automatically generate it with the
    // appropriate boundary
    // e.g. Content-Type: multipart/form-data; boundary=<some boundary in the body>
  } else if (isObject(body)) {
    bodyContent = JSON.stringify( body );
    if (!headersToSend['Content-Type']) {
      headersToSend['Content-Type'] = 'application/json';
    }
  }

  try {
    const response = await fetch(
      url,
      {
        method: method.toUpperCase(),
        headers: headersToSend,
        body: (method.toLowerCase() !== 'get') && bodyContent != null
          ? bodyContent
          : undefined,
        credentials: 'same-origin', // include cookies
        mode: 'cors' // Because our scripts live on a different origin than the app
      }
    );

    let responseBody = await response.text();

    // TODO: Can we totally rely on the response's content type header?
    const checkBody = responseBody.startsWith('{') || responseBody.startsWith('[');

    if (isJsonResponse(response) || checkBody) {
      responseBody = JSON.parse(responseBody);
    }

    return [
      response.ok,
      {
        headers: response.headers,
        body: responseBody,
        statusCode: response.status
      }
    ];
  } catch (e) {
    return [ false, { error: e } ];
  }
};

export default callApi;
