/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React from 'react';
import BlueHeaderOnWhiteLayout from 'layouts/BlueHeaderOnWhite';
import {
  AlignBackground,
  CityThree,
  ErnieHighfiveAnother
} from 'layouts/BlueHeaderOnWhite/HeaderBackgrounds';
import Messages from './index.messages';

const Backgrounds = [
  (
    <AlignBackground key="bg-1" bottom={0} left={0}>
      <CityThree />
    </AlignBackground>
  ),
  (
    <AlignBackground key="bg-2" bottom={0} left={210}>
      <ErnieHighfiveAnother />
    </AlignBackground>
  )
];

const LogonLayout = ({ children }) => (
  <BlueHeaderOnWhiteLayout
    backgrounds={Backgrounds}
    headerMessage={Messages.Header}
    subheaderMessage={Messages.Subheader}
    contentWidth={320}
  >
    <div className="viewstrap">
      {children}
    </div>
  </BlueHeaderOnWhiteLayout>
);

export default LogonLayout;