/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */
/* eslint jsx-a11y/label-has-associated-control: 0 */

import React, { useMemo, useState } from 'react';
import classNames from 'classnames';
import { uniqueId } from 'lodash';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import t from 'tcomb-form';

import Icon, { IconTypes } from 'components/Icon';
import HelpLink from 'components/HelpLink';
import Tooltip from 'components/Tooltip';

import { stopPropagation } from 'config/utils/click';

import './index.scss';

const CheckBox = ({
  appendContent,
  checkboxTooltipMessage,
  children,
  className,
  config,
  ctx,
  customInputClass,
  customLabelClass,
  disabled,
  helpLink,
  indeterminate,
  isCustomCheckBox,
  labelMessage: originalLabelMessage,
  name,
  onChange: originalOnChange,
  placeholder,
  prependIcon,
  style,
  tooltip,
  toolTipMessage,
  value,
  variant,
  willHideLabel
}) => {
  // Hooks
  const [ elementId ] = useState(() => `checkbox-${uniqueId()}`);

  const onChange = useMemo(
    () => (evt) => {
      const newValue = t.form.Checkbox.transformer.parse(evt.target.checked);
      originalOnChange?.(newValue);
    },
    [ originalOnChange ]
  );

  // Occasionally the checkbox/label will be in the context of something that
  // also is reacting to clicks - this overrides that.
  const labelOnClick = useMemo(() => stopPropagation(), []);

  // Render
  const renderLabel = () => {
    if (willHideLabel != null && willHideLabel) {
      return null;
    }

    const labelClasses = classNames(
      'vp-label',
      'inline',
      'type-normal',
      customLabelClass,
      {
        'is-disabled': disabled
      }
    );

    if (variant === 'children') {
      return (
        <label
          className={labelClasses}
          htmlFor={elementId}
          onClick={labelOnClick}>
          {children}
        </label>
      );
    }

    let labelMessage = originalLabelMessage;
    if (!labelMessage && ctx?.i18n) {
      labelMessage = ctx.i18n.getLabelMessage(config.path);
    }

    if (!labelMessage) return null;

    const actualMessage = labelMessage.id ? (
      <FormattedMessage
        {...labelMessage}
        values={config?.labelValues}
      />
    ) : labelMessage;

    return (
      <label
        className={labelClasses}
        htmlFor={elementId}
        onClick={labelOnClick}
      >
        {prependIcon}
        {actualMessage}
      </label>
    );
  };

  // we need an id to able to use checkbox with label's for (HTML)
  // see label below
  const labelFirst = !!config?.labelFirst;

  let checkbox = (
    <div className="checkbox-style-container">
      <input
        checked={value ?? false}
        className="vp-toggle"
        disabled={disabled}
        id={elementId}
        name={name}
        onChange={onChange}
        placeholder={placeholder}
        type="checkbox"
      />
      {!isCustomCheckBox ? (
        <label
          aria-label={name}
          className="checkbox-label-hack vp-toggle"
          htmlFor={elementId}
          onClick={labelOnClick}
        />
      ) : null}
    </div>
  );

  if (tooltip) {
    checkbox = (
      <Tooltip overlay={tooltip}>
        {checkbox}
      </Tooltip>
    );
  } else if (checkboxTooltipMessage) {
    checkbox = (
      <Tooltip overlay={<FormattedMessage {...checkboxTooltipMessage} />}>
        {checkbox}
      </Tooltip>
    );
  }

  return (
    <div
      className={classNames(
        'vp-checkbox',
        className,
        {
          indeterminate
        }
      )}
      style={style}
    >
      {/* Browser stylability of native checkboxes historically hasn't been good
        Which borught about the CSS checkbox hack:
        input[type='checkbox']:checked + label */}
      {labelFirst ? renderLabel() : null}
      {checkbox}
      {!labelFirst ? renderLabel() : null}
      {/* add a help if specified */}
      {helpLink ? (
        <HelpLink href={helpLink} />
      ) : null}
      {toolTipMessage ? (
        <Tooltip overlay={<FormattedMessage {...toolTipMessage} />}>
          <Icon className="subtext" type={IconTypes.Question} />
        </Tooltip>
      ) : null}
      {appendContent}
    </div>
  );
};

CheckBox.propTypes = {
  variant: PropTypes.oneOf(['default', 'children']),
  /** shows an 'indeterminate' style checkbox instead of a normal check */
  indeterminate: PropTypes.bool,
  onChange: PropTypes.func,
  labelMessage: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
    PropTypes.element
  ]),
  labelClass: PropTypes.string,
  disabled: PropTypes.bool,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  value: PropTypes.bool,
  willHideLabel: PropTypes.bool,
  isCustomCheckBox: PropTypes.bool,
  checkboxTooltipMessage: PropTypes.object,
  toolTip: PropTypes.node
};

export default CheckBox;
