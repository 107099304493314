/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React from 'react';
import PropTypes from 'prop-types';
import createModuleLoader from 'decorators/ModuleLoader';
import TextBox from 'components/Form/Inputs/TextBox';
import { getCardType, isStripeLoaded, StripeJsScript } from 'api/stripe';
import CreditCardIcon from 'components/Icon/CreditCardIcon';

/**
 * A Text box that represents a credit card number. Indended for use in forms (as inputComponent).
 */
const CreditCardNumberTextBox = ({
  appendIcon: iconToAppend,
  value,
  ...props
}) => {
  let cardType = null;
  if (value && isStripeLoaded()) {
    cardType = getCardType(value);
  }

  const appendIcon = cardType
    ? <span><CreditCardIcon type={cardType} /></span>
    : iconToAppend;

  return (
    <TextBox
      {...props}
      value={value}
      appendIcon={appendIcon || <span/>}
    />
  );
};

CreditCardNumberTextBox.propTypes = {
  /** Current value of the text box - the CC number */
  value: PropTypes.string,
  /** called when the text box value changes */
  onChange: PropTypes.func
};


export default createModuleLoader({
  scriptToLoad: StripeJsScript,
  innerComponent: CreditCardNumberTextBox,
  loadingIndicator: () => <span>Loading...</span>
});
