/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */
/* eslint max-len:0 */

import React, { Component } from 'react';
import IsoImage from 'images/footer/iso27001_blue.png';
import Logo from 'components/Logo';
import './index.scss';

import SeeBusinessBetterImage from 'public/images/vpSeeBusinessBetter.svg';

export default class ErrorLayout extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className={`errorLayout ${this.props.className}`}>
        <div className="container">
          <div className="header">
            <div className="content">
              <Logo />
            </div>
          </div>

          <div className="body">
            <div className="content">
              {this.props.children}
            </div>
          </div>

          <div className="footer">
            <div className="content">

              <ul className="footer-nav">
                <li><strong><a href="https://www.viewpost.com">Viewpost.com</a></strong></li>
                <li><a href="https://www.viewpost.com/about-us">About Us</a></li>
                <li><a href="/terms">Terms</a></li>
                <li><a href="/privacy">Privacy</a></li>
                <li><a href="https://www.viewpost.com/security">Security</a></li>
                <li><a href="/contact/">Contact</a></li>
              </ul>
              <p className="copyright">Copyright &copy; {new Date().getFullYear()}. Viewpost IP Holdings, LLC. All Rights Reserved.</p>

              <div className="seals">
                <div className="seal trust">
                  <a href="https://privacy.truste.com/privacy-seal/validation?rid=584bd713-ac40-43e5-9776-efb54200aa18"
                    title="TRUSTe online privacy certification" target="_blank">
                    <img style={{border: 'none'}}
                      src="https://privacy-policy.truste.com/privacy-seal/seal?rid=584bd713-ac40-43e5-9776-efb54200aa18"
                      alt="TRUSTe online privacy certification" />
                  </a>
                </div>
                <div id="nortonSeal" className="seal norton">
                  <script type="text/javascript"
                    src="https://seal.verisign.com/getseal?host_name=www.viewpost.com&amp;size=S&amp;use_flash=NO&amp;use_transparent=NO&amp;lang=en"/>
                </div>
                <div id="isoSeal" className="seal iso">
                  <img src={IsoImage} />
                </div>
              </div>

            </div>
          </div>
          <div className="sub-footer">
            <div className="content">
              <a href="https://www.viewpost.com">
                <img src={SeeBusinessBetterImage}/>
              </a>
            </div>
          </div>

        </div>
      </div>
    );
  }
}
