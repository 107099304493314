/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import SettingsViewActions from './constants/settings/SettingsViewActions';
import { toEnumObject } from './constants/utils';

export { SettingsViewActions };

export const MOBILE_BREAK = 768;
export const TABLET_BREAK = 930;
export const LOADING_ERROR_TIMEOUT = 30000;
export const VIEWPOSTBILLINGCOMPANY = 'Viewpost North America, LLC';

export const ModalActionTypes = {
  Close: 'CloseModal',
  Show: 'ShowModal'
};

export const PlaidInstitutions = {
  BankOfAmerica: 'ins_1',
  CapitalOne: 'ins_9',
  Chase: 'ins_3',
  Schwab: 'schwab',
  Citi: 'citi',
  Fidelity: 'fidelity',
  PNC: 'ins_13',
  SVB: 'svb',
  UsBank: 'ins_6',
  USAA: 'ins_7',
  WellsFargo: 'ins_4',
  Suntrust: 'suntrust',
  TD: 'td',
  NFCU: 'nfcu',
  FiveThree: 'ins_26'
};

export const ToastTypes = {
  info: 'info',
  success: 'success',
  warning: 'warning',
  error: 'error',
  terms: 'terms'

  // small Banner
};

export const ConnectionRequestTypes = {
  Received: 'Received',
  Sent: 'Sent'
};

export const DashboardActions = {
  HideCashflowGraph: 'HideCashflowGraph',
  HideGettingStartedSteps: 'HideGettingStartedSteps',
  SkipGettingStartedStep: 'SkipGettingStartedStep',
  DismissGettingStartedStep: 'DismissGettingStartedStep',
  LoadAdditionalActivities: 'LoadAdditionalActivities'
};

export const InvoiceActions = {
  Approve: 'ApproveInvoice',
  ApproveAndQuickPay: 'ApproveAndQuickPayInvoice',
  ApproveAndPaymentQueue: 'ApproveAndPaymentQueueInvoice',
  BatchApproveAndPaymentQueue: 'BatchApproveAndPaymentQueue', // can both Approve+PaymentQueue and PaymentQueue and batch them together
  Comment: 'CommentInvoice',
  Copy: 'CopyInvoice',
  CreateInvoice: 'CreateInvoice',
  CreateLateFee: 'CreateInvoiceLateFee',
  CreateBill: 'CreateBill',
  Delete: 'DeleteInvoice',
  Dispute: 'DisputeInvoice',
  Edit: 'EditInvoice',
  EndDispute: 'EndDisputeInvoice',
  FileInsuranceClaim: 'FileInsuranceClaim',
  GetInvoiceInsuranceQuote: 'GetInvoiceInsuranceQuote',
  Link: 'LinkInvoice',
  AddToPaymentQueue: 'AddToPaymentQueue',
  PurchaseInvoiceInsurance: 'PurchaseInvoiceInsurance',
  QuickPay: 'QuickPay',
  RecordPayment: 'RecordPayment',
  Reject: 'RejectInvoice',
  RemoveLateFee: 'RemoveInvoiceLateFee',
  RespondToApprovalRequest: 'RespondToApprovalRequest',
  RouteForApproval: 'RouteForApproval',
  RouteForAdditionalApproval: 'RouteForAdditionalApproval',
  Send: 'SendInvoice',
  SendAndQuickPay: 'SendAndQuickPay',
  SaveAsPdf: 'SaveAsPdfInvoice',
  SendToPrinter: 'SendToPrinter',
  Share: 'ShareInvoice',
  Unlink: 'UnlinkInvoice',
  Unvoid: 'UnvoidInvoice',
  UpdateRecurrence: 'UpdateRecurrence',
  ViewApprovalRequests: 'ViewApprovalRequests',
  ViewAttachments: 'ViewAttachments',
  ViewDetails: 'ViewInvoiceDetails',
  ViewOpenBills: 'ViewOpenBills',
  Void: 'VoidInvoice',
  VpxCancel: 'VpxCancel',
  VpxRequest: 'VpxRequest',
  MarkAsPaid: 'MarkAsPaid',
  SetUpAutoPay: 'SetUpAutoPay',
  EditAutoPay: 'EditAutoPay',
  SendReminder: 'SendReminder',
  StartRecurrence: 'StartRecurrence'
};

export const InvoiceRecurrenceActions = {
  Preview: 'PreviewInvoiceRecurrence',
  Edit: 'EditInvoiceRecurrence',
  Delete: 'DeleteInvoiceRecurrence',
  Pause: 'PauseInvoiceRecurrence',
  Resume: 'ResumeInvoiceRecurrence',
  VpxRequest: 'VpxRequestRecurring',
  VpxCancel: 'VpxCancelRecurring',
  Start: 'StartRecurrence',
  Copy: 'CopyInvoice'
};

export const InvoiceCommentActions = {
  Edit: 'EditInvoiceComment',
  Delete: 'DeleteInvoiceComment'
};

export const BillViewActions = {
  ViewList: 'ViewBillsList',
  ViewStatements: 'ViewBillStatements',
  ViewBillEarlyPay: 'ViewBillEarlyPay',
  ViewPersonalOpen: 'ViewPersonalOpenBills',
  ViewPersonalClosed: 'ViewPersonalClosedBills'
};

export const InvoiceViewActions = {
  ViewList: 'ViewInvoiceList',
  ViewSummary: 'ViewInvoiceSummary',
  ViewInbox: 'ViewInvoiceInbox',
  ViewInvoiceEarlyPay: 'ViewInvoiceEarlyPay',
  ViewRecurring: 'ViewRecurringInvoices',
  ViewQbo: 'ViewQboInvoices'
};

export const QboActions = {
  UpdatePayments: 'UpdatePayments',
  UpdateCustomerLinks: 'UpdateCustomerLinks',
  UpdateInvoices: 'UpdateInvoices'
};

export const SettingsActions = {
  UpdateCompanyProfile: 'UpdateCompanyProfile',
  EnrollWithStripe: 'EnrollWithStripe',
  UpdateEarlyPay: 'UpdateEarlyPay',
  UpdateMultiStepApprovals: 'UpdateMultiStepApprovalsSetting',
  UpdateAutoApplyPayments: 'UpdateAutoApplyPaymentsSetting',
  CanEnrollConsumerPayments: 'CanEnrollConsumerPayments',
  CanReceiveConsumerPayments: 'CanReceiveConsumerPayments',
  ConnectAccountingPackage: 'ConnectAccountingPackage',
  CreateSwitchCompany: 'CreateSwitchCompany'
};

export const SyncActions = {
  Connect: 'SyncConnect',
  Disconnect: 'SyncDisconnect',
  ModifySettings: 'SyncModifySettings',
  ModifyAutoSend: 'SyncModifyAutoSend'
};

export const ButtonVariants = {
  Primary: 'primary',
  Secondary: 'secondary'
};

export const CheckingAccountStatus = {
  ActiveVerified: 'ActiveVerified',
  Unverified: 'Unverified',
  Unregistered: 'Unregistered'
};

export const QuickBooksOnlineAddressBookMismatchKey = 'QuickBooksOnline Address Book Mismatch';

export const NetworkErrors = toEnumObject([
  'TooManyRequests'
]);

export const PaymentMethodType = {
  DigitalCheck: 'ViewpostDigitalCheck', // 2 step
  DigitalCheckICL: 'ViewpostDigitalCheckICL', // 1 step / ICL
  PaperCheck: 'ViewpostPaperCheck',
  VirtualCreditCard: 'VirtualCreditCard',
  CreditCardStripe: 'CreditCardStripe',
  ThirdPartyDigitalCheck: 'ThirdPartyDigitalCheck', // WU ACH (Direct Pay...obsolete)
  DigitalCheckPlus: 'ViewpostDigitalCheckPlus', // ACH+
  DigitalCheckAch: 'ViewpostDigitalCheckAch', // One Step ACH

  OutOfBandCheck: 'OutOfBandCheck',
  OutOfBandAch: 'OutOfBandAch',
  OutOfBandWire: 'OutOfBandWire',
  OutOfBandCreditCard: 'OutOfBandCreditCard',
  OutOfBandEft: 'OutOfBandEft',
  OutOfBandOther: 'OutOfBandOther',
  OutOfBandEpiq: 'OutOfBandEpiq',

  Multiple: 'Multiple' // multiple payment methods at the same time
};

export const SyncPackages = {
  QuickBooksOnline: {short: 'QuickBooks', full: 'QuickBooks Online'},
  Xero: {short: 'Xero', full: 'Xero'},
  NetSuite: {short: 'NetSuite', full: 'NetSuite'},
  SageLive: {short: 'Sage Live', full: 'Sage Live'},
  Riskmaster: {short: 'RISKMASTER', full: 'RISKMASTER Accelerator'}
};

export const OneClickPayTimings = {
  Immediate: 'Immediate',
  DueDate: 'DueDate'
};

export const LoginCoordinatorName = toEnumObject([
  'Login',
  'InitApp',
  'Register'
]);

export const DEFAULT_EMPTY_GUID = '00000000-0000-0000-0000-000000000000';
export const VPX_EXPIRES_DEFAULT_HOURS = 48;
