/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

// import { getEntity, getEntityList } from 'schemas/state';

export function MapStateToProps(state) {
  return {
  };
}

export function MapDispatchToProps(dispatch) {
  return {
  };
}
