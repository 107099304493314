/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, { useMemo, useRef, useState } from 'react';
import t from 'tcomb-validation';

import RegistrationApi from 'api/registration';

import Button from 'components/Button';
import FormJSX, { FieldOption } from 'components/Form/FormJSX';
import Image from 'components/Viewstrap/Image';

import { toEnumObject } from 'config/constants/utils';
import { Knight, Shrugging } from 'config/images/ernie';
import CommonMessages from 'config/messages/common';

import createExecuteApiResultHooks from 'hooks/apiResult/createExecuteApiResultHooks';

import Messages from './index.messages';

const ReviewReactivateAccount = t.struct({
  comment: t.String
});

export const LockTypes = toEnumObject([
  'CompanyClosed',
  'CompanyLocked',
  'UserLocked'
]);

const LockConfig = {
  [LockTypes.CompanyClosed]: {
    image: Shrugging,
    message: <Messages.Instructions.Message />
  },
  [LockTypes.CompanyLocked]: {
    image: Knight,
    message: <Messages.LockedInstructions.Message />
  },
  [LockTypes.UserLocked]: {
    image: Knight,
    message: <Messages.UserLockedInstructions.Message />
  }
};

const RequestReactivateAccountHooks = createExecuteApiResultHooks({
  endpoint: RegistrationApi.requestReactivateAccount
});

const ClosedCompanyLogon = ({
  onUpdate,
  type,
  userName
}) => {
  // Hooks
  const formRef = useRef();

  const [ formState, setFormState ] = useState({});

  const executeRequestReactivateAccount = RequestReactivateAccountHooks.useExecuteRequest();
  const isLoading = RequestReactivateAccountHooks.useIsLoading();

  const onFinish = useMemo(
    () => () => onUpdate({}, true),
    []
  );

  const onSubmit = useMemo(
    () => async () => {
      const value = formRef.current.getValue();

      if (value) {
        const [ success ] = await executeRequestReactivateAccount(
          {
            userName,
            comment: value.comment
          },
          {
            successMessage: <Messages.RequestSuccess.Message/>
          }
        );

        if (success) {
          onFinish();
        }
      }
    },
    [ executeRequestReactivateAccount, onUpdate, userName ]
  );

  // Render
  return (
    <div>
      <Image image={LockConfig[type].image} willCenter={true} />
      <p style={{marginTop: '3rem'}}>
        {LockConfig[type].message}
      </p>
      <FormJSX
        ref={formRef}
        onChange={v => setFormState(v)}
        value={formState}
        modelType={ReviewReactivateAccount}
        hasSubmitButton={true}
      >
        <FieldOption name="comment" type="textarea" label={Messages.Comment}/>
        <Button
          onClick={() => onSubmit()}
          expand={true}
          isProcessing={isLoading}
          isDisabled={isLoading}
        >
          <CommonMessages.Submit.Message />
        </Button>
      </FormJSX>
      <Button
        variant="secondary"
        onClick={onFinish}
        expand={true}
        isDisabled={isLoading}
      >
        <CommonMessages.Cancel.Message />
      </Button>
    </div>
  );
};

export default ClosedCompanyLogon;
