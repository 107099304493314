/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import SettingsApi from 'api/settings';
import { cacheOrDispatchApiCall, getCacheEntities } from 'actions/references';

function MapStateToProps(state) {
  return {
    industryTypes: getCacheEntities(state, 'SelectIndustryType')
  };
}

function MapDispatchToProps(dispatch, props) {
  return {
    onLoad: () => dispatch(cacheOrDispatchApiCall(
      'SelectIndustryType',
      SettingsApi.getIndustryTypes
    ))
  };
}

export {
  MapStateToProps,
  MapDispatchToProps
};
