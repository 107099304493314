/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, { Children, forwardRef } from 'react';
import PropTypes from 'prop-types';

import MeatballDropdownToggle from 'components/Dropdown/MeatballDropdownToggle';

import useIsMobile from 'hooks/browser/useIsMobile';

import { createListOptions } from 'utils/actions';

import Messages from './index.messages';
import './index.scss';

const ActionDropdown = forwardRef(
  (
    {
      actions,
      children,
      header: headerProp,
      footer,
      toggle: toggleComponent
    },
    ref
  ) => {
    // Hooks
    const isMobile = useIsMobile();

    // Render
    let header = headerProp;
    if (!isMobile) {
      header = header || <Messages.DesktopDropdownHeader.Message />;
    }

    const Toggle = toggleComponent || MeatballDropdownToggle;

    // Don't render the dropdown if there aren't any actions to render
    if (!children && (!actions || !actions.length)) {
      return <></>;
    }

    // Don't render the dropdown if there aren't any options to render
    if (children) {
      let hasValidChildren = false;

      Children.forEach(children, (child) => {
        hasValidChildren = hasValidChildren || !!child;
      });

      if (!hasValidChildren) {
        return <></>;
      }
    }

    return (
      <Toggle
        ref={ref}
        dropdownType="list"
        popoverStyle={{ minWidth: 150 }}
        useTooltip={true}
        header={header}
        footer={footer}
      >
        {createListOptions(
          actions,
          {
            useDropdownWithHelp: true
          }
        )}
        {children}
      </Toggle>
    );
  }
);

ActionDropdown.propTypes = {
  // actions should be:
  // {
  //   label: PropTypes.node
  //   onClick: PropTypes.func
  // }
  actions: PropTypes.array,
  header: PropTypes.element, // add a title to the dropdown
  toggle: PropTypes.elementType // custom component to use as toggle for dropdown
};

export default ActionDropdown;
