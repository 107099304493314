/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, { useEffect, useMemo } from 'react';

import resendTwoFactorCode from 'api/session/resendTwoFactorCode';
import twoFactorSubmit from 'api/session/twoFactorSubmit';

import TwoFactorPrompt from 'containers/Logon/TwoFactorPrompt';

import createExecuteApiResultHooks from 'hooks/apiResult/createExecuteApiResultHooks';

const TwoFactorSubmitHooks = createExecuteApiResultHooks({
  endpoint: twoFactorSubmit
});

const ResendTwoFactorCodeHooks = createExecuteApiResultHooks({
  endpoint: resendTwoFactorCode
});

const LogonTwoFactorPrompt = ({
  continueMessage,
  error,
  header,
  onUpdate,
  phoneNumber,
  userName
}) => {
  // Hooks
  useEffect(
    () => {
      if (userName) {
        // If the user sees this screen, clearly the device token was ignored
        try {
          localStorage.remove(`DeviceId-${userName}`);
        } catch {
          // Local storage no-worky, nothing we can do
        }
      }
    },
    []
  );

  const executeSubmit = TwoFactorSubmitHooks.useExecuteRequest();
  const isSubmitting = TwoFactorSubmitHooks.useIsLoading();

  const onSubmit = useMemo(
    () => async (code, rememberDevice) => {
      const [ , action ] = await executeSubmit({ code, rememberDevice, userName });

      onUpdate(action.payload.result);
    },
    [ onUpdate, executeSubmit ]
  );

  const executeResend = ResendTwoFactorCodeHooks.useExecuteRequest();
  const isResending = ResendTwoFactorCodeHooks.useIsLoading();

  const onResendCode = useMemo(
    () => options => executeResend({}, options),
    [ executeResend ]
  );

  // Render
  return (
    <TwoFactorPrompt
      canRememberDevice={true}
      error={error}
      isResending={isResending}
      isSubmitting={isSubmitting}
      onResendCode={onResendCode}
      onSubmit={onSubmit}
      phoneNumber={phoneNumber}
    />
  );
};

export default LogonTwoFactorPrompt;
