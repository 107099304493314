/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import Cookies from 'js-cookie';

const UpdateFeatureToggleActionName = 'UpdateFeatureToggle';

const createFeatureToggleService = (FeatureToggleList) => {
  const isLocalBuild = process.env.NODE_ENV !== 'production';

  function isEnabled(featureName) {
    let cookie = Cookies.get(`feature.${featureName}`);

    if (!FeatureToggleList[featureName]) {
      console.warn(`Unknown feature toggle: ${featureName}`);
      return false;
    }

    return cookie === 'true'
      || cookie === true
      || FeatureToggleList[featureName].forced
      || (FeatureToggleList[featureName].forceLocally && isLocalBuild);
  }

  function isForceEnabled(featureName) {
    return FeatureToggleList[featureName].forced
      || (FeatureToggleList[featureName].forceLocally && isLocalBuild);
  }

  function saveFeatureToggles(features) {
    let cookies = Object.keys(Cookies.get());
    cookies.forEach((cookieName) => {
      if (cookieName.startsWith('feature.')) {
        Cookies.remove(cookieName);
      }
    });
    Object.keys(features).forEach((featureName) => {
      let feature = features[featureName];
      if (feature) {
        Cookies.set(`feature.${featureName}`, true);
      }
    });
  }

  const saveFeatureToggle = (featureName, value) => {
    if (value) {
      Cookies.set(`feature.${featureName}`, true);
    } else {
      Cookies.remove(`feature.${featureName}`);
    }
  };

  function getFeatureToggles() {
    let cookies = Object.keys(Cookies.get());
    return cookies.reduce((features, cookieName) => {
      if (cookieName.startsWith('feature.')) {
        let featureName = cookieName.substr('feature.'.length);
        let cookie = Cookies.get(`feature.${featureName}`);
        let value = cookie === 'true'
          || cookie === true
          || FeatureToggleList[featureName].forced
          || (FeatureToggleList[featureName].forceLocally && isLocalBuild);
        features[featureName] = value;
      }
      return features;
    }, {});
  }

  const getAllFeatureToggles = () => {
    return Object.keys(FeatureToggleList).reduce((val, name) => ({
      ...val,
      [name]: isEnabled(name) || isForceEnabled(name) || false
    }), {});
  };

  const createFeatureToggleReducer = ({ newState }) => (state = {}, { type, featureName, value }) => {
    if (type === UpdateFeatureToggleActionName) {
      return newState(state, {
        ...state,
        [featureName]: value
      });
    }

    return state;
  };

  const createFeatureToggleDefaultState = () => {
    const currentFeatureToggleState = getAllFeatureToggles();

    return Object.keys(currentFeatureToggleState)
      .reduce((val, key) => ({
        ...val,
        [key]: currentFeatureToggleState[key]
      }), {});
  };

  const updateFeatureToggleAction = (featureName, value, { refreshOnUpdate } = {}) => (dispatch) => {
    dispatch({
      type: UpdateFeatureToggleActionName,
      featureName,
      value
    });
    saveFeatureToggle(featureName, value);
    if (refreshOnUpdate) {
      window.location.reload();
    }
  };

  const getFeatureToggleState = (state, featureName) => state.featureToggles[featureName];

  return {
    isEnabled,
    isForceEnabled,
    saveFeatureToggles,
    saveFeatureToggle,
    getFeatureToggles,
    getAllFeatureToggles,
    createFeatureToggleReducer,
    createFeatureToggleDefaultState,
    updateFeatureToggleAction,
    getFeatureToggleState
  };
};

export default createFeatureToggleService;
