/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */
/* eslint max-len:0 */

import { defineMessages } from 'react-intl';

const common = defineMessages({
  Default: {
    id: 'LoadingIndicatorDefault',
    description: 'Literally the last thing to show with the loading indicator if nothing has been specified',
    defaultMessage: 'Loading'
  },
  ErrorMessage: {
    id: 'LoadingIndicator.ErrorMessage',
    description: 'Error message line 1',
    defaultMessage: 'An unexpected error occurred. Please refresh the page.'
  },
  ErrorMessage2: {
    id: 'LoadingIndicator.ErrorMessage2',
    description: 'Error message line 2',
    defaultMessage: 'If the error continues, please contact customer support at {phone} or {email}.'
  },
  ErrorMessage3: {
    id: 'LoadingIndicator.ErrorMessage3',
    description: 'Error message line 3',
    defaultMessage: 'Unique Error ID: {id}'
  }
});

export default {
  ...common
};
