/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

// What is the action registry?
//
// TL;DR - collects all available actions into a single list and provides helper
//         functions to ensure/help uniqueness
//
// In this web app that has its code split in many ways, it is imperative for code
// splitting to effective by separating configuration that is used across the app,
// such as actions, in a way that allows for 'specific access' vs 'non-specific access'.
// When you are checking to see if a user has X available action to hide or show something,
// that's specific access. That demonstrates a need to have that value be loaded into memory
// as it is vital for that code chunk to perform as expected. On the flip side, the identity
// has a list of available actions and has no care to know all the available actions in the
// system specifically, but would like to know if a string passed to it is known as an available
// action, thus 'non-specific access'. By using this registry, when code that defines schemas are
// imported, the set of available actions are added to so that non-specific access can be
// facilitated.
//
// The ability for something like the identity to have non-specific access to
// all available actions currently loaded into memory allows the identity, which lies in the
// core chunk of the application to not need to 1) know about every available action, which means as
// new groups of available actions are created, you need to add a reference to it, and 2) in order
// to reference it, you need to import it, which means it needs to be brought into the core chunk,
// most likely bloating the core with actions that are only used deeper in the app.
//
// There is one problem with reason #2, while the identity schema doesn't need specific access
// to the available actions, its primary utlizer, the usermodel does (to calculate permissions).
// So we are still stuck needing to import every group of available actions in the main chunk,
// albiet in a specific context, so it feels natural. At the very least, this allows available
// actions to be utilized naturally and provide a way to get all of them at any time easily,
// without unintentionally restricting code splitting.

const createActionRegistry = () => {
  let availableActions = [];

  // Keep this returning a reference at all times, this could be invoked many times
  // when doing schema validations. We want it to be dynamic but performant. The case of
  // having actions added as the application running is rare (it'll be when chunks load in)
  // so the burden of recalulating the constant should happen then.
  const getAllAvailableActionsList = () => availableActions;

  const createAvailableActions = (prefix, availableActionsList) => {
    availableActions = [
      ...availableActions,
      ...availableActionsList
        .reduce((val, action) => ([...val, `${prefix}.${action}`]), [])
    ];

    return availableActionsList
      .reduce((val, action) => ({...val, [action]: `${prefix}.${action}`}), {});
  };

  // This differs by taking in an object with key-value pairs. It preprends the prefix
  // to the values, adds those to the registry, and returns the passed in object with
  // that prefix on the values.
  const createLegacyAvailableActions = (prefix, availableActionsMap) => {
    availableActions = [
      ...availableActions,
      ...Object.keys(availableActionsMap)
        .reduce((val, key) => ([...val, `${prefix}.${availableActionsMap[key]}`]), [])
    ];

    return Object.keys(availableActionsMap)
      .reduce((val, key) => ({...val, [key]: `${prefix}.${availableActionsMap[key]}`}), {});
  };

  return {
    getAllAvailableActionsList,
    createAvailableActions,
    createLegacyAvailableActions
  };
};

export default createActionRegistry;
