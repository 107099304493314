/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, { Component } from 'react';
import Icon, { IconTypes } from 'components/Icon';
import TextBox from 'components/Form/Inputs/TextBox';

export default class PercentTextBox extends Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
  }

  onChange(value) {
    if (!this.props.onChange) return;
    // remove everything but - . and numbers
    this.props.onChange(value ? value.replace(/[^\d-.]/g, '') : value);
  }

  render() {
    let icon = <Icon type={IconTypes.Percent} />;

    return (
      <TextBox {...this.props}
        onChange={this.onChange}
        appendIcon={icon} />
    );
  }
}
