/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, { Component, forwardRef, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import t from 'tcomb-validation';

import AutoRow from 'components/AutoRow';
import BodyText, { BodyTextVariants } from 'components/BodyText';
import Button from 'components/Button';
import FormV2, { createOnClassComponentChange, FieldInput, FieldOption } from 'components/Form/V2';
import HeaderText from 'components/HeaderText';
import SimpleModal from 'components/Modal/Simple';
import { SmallBannerNotification, NotificationStyleTypes } from 'components/Notification';

import { createLaunchHook } from 'config/modals/registry';

import SubscriptionCreditCardForm from 'containers/Subscription/CreditCardForm';
import MonthlyPaymentDescriptionPanel from 'containers/Subscription/MonthlyPaymentDescriptionPanel';

import { createUniqueStringType } from 'schemas/common/string';
import Truthy from 'schemas/common/truthy';

import Messages from './index.messages';
import connect from './index.connect';

const InitializeCreateSwitchCompanyForm = forwardRef(
  (
    { companyNames },
    ref
  ) => {
    // Hooks
    const [ formState, setFormState ] = useState({});

    const ModelType = useMemo(
      () => t.struct({
        companyName: createUniqueStringType(
          companyNames,
          {
            maxLength: 100
          }
        ),
        willAcceptTerms: Truthy
      }),
      [] // Kinda hoping the list of company names doesn't change after mount
    );

    // Render
    return (
      <FormV2
        formTag={false}
        modelType={ModelType}
        onChange={setFormState}
        ref={ref}
        value={formState}
      >
        <AutoRow gutter={true}>
          <FieldOption
            name="companyName"
            cols={12}
            label={<Messages.CompanyName.Message />}
          />
          <FieldOption
            name="willAcceptTerms"
            cols={12}
          >
            <FieldInput variant="children">
              <Messages.WillAcceptTerms.Message />
            </FieldInput>
          </FieldOption>
        </AutoRow>
      </FormV2>
    );
  }
);

export class CreateSwitchCompanyModal extends Component {
  static propTypes = {
    /** List of OUMC companies */
    switchCompanies: PropTypes.array // redux
  };

  constructor(props) {
    super(props);
    this.state = { ccFormState: {} };
    this.onCreditCardFormChange = createOnClassComponentChange('ccFormState').bind(this);
  }

  onSubmit = async () => {
    const { createNewCompanyWithCreditCard, createNewPortalCompany, onClose } = this.props;
    const { createStep } = this.state;

    if (createStep === 'corporate') {
      const value = this.ccForm.getValue();

      if (value) {
        this.setState(
          { error: null, isLoading: true },
          async () => {
            const [ success, error ] = await createNewCompanyWithCreditCard(this.state.newCompany, value);
            this.setState({ isLoading: false });

            if (success) {
              onClose();
            } else {
              this.setState({ error });
            }
          }
        );
      }
    } else if (createStep === 'portal') {
      this.setState({ error: null });
      const [ success, error ] = await createNewPortalCompany(this.state.newCompany);

      if (success) {
        onClose();
      } else {
        this.setState({ error });
      }
    } else {
      const newCompany = this.form.getValue();

      if (newCompany && newCompany.willAcceptTerms) {
        this.setState({
          newCompany: {
            companyName: newCompany.companyName
          },
          createStep: 'corporate'
        });
      }
    }
  }

  render() {
    const { isLoading, switchCompanies } = this.props || {};
    const { createStep, isLoading: isLoadingState } = this.state;

    let content = (
      <InitializeCreateSwitchCompanyForm
        companyNames={switchCompanies.map(({ companyName }) => companyName)}
        ref={r => this.form = r}
      />
    );

    if (createStep === 'portal') {
      content = (
        <div>
          <HeaderText>
            <Messages.PortalHeader.Message />
          </HeaderText>
          <BodyText style={{ paddingTop: 36, paddingBottom: 12 }}>
            <Messages.PortalInstructions.Message />
          </BodyText>
          <BodyText style={{ paddingBottom: 12 }}>
            <Messages.PortalInstructionsTwo.Message />
          </BodyText>
          {this.state.error ? (
            <SmallBannerNotification type={NotificationStyleTypes.Warning} shakeOnEnter={true}>
              {this.state.error === true ? 'There was a problem adding a company.' : this.state.error}
            </SmallBannerNotification>
          ) : null}
          <BodyText variant={BodyTextVariants.Smaller}>
            <Button anchor={true} onClick={() => this.setState({ error: null, createStep: 'corporate' })}>
              <Messages.CreateCorporateAccount.Message />
            </Button>
          </BodyText>
        </div>
      );
    } else if (createStep === 'corporate') {
      content = (
        <div>
          <HeaderText>
            <Messages.CorporateHeader.Message />
          </HeaderText>
          <BodyText style={{ paddingBottom: 8 }}>
            <Messages.CorporateSubheader.Message />
          </BodyText>
          <MonthlyPaymentDescriptionPanel amount={19.99} billingDate={new Date()} />
          {this.state.error ? (
            <SmallBannerNotification type={NotificationStyleTypes.Warning} shakeOnEnter={true}>
              {this.state.error === true ? 'There was a problem adding a company.' : this.state.error}
            </SmallBannerNotification>
          ) : null}
          <SubscriptionCreditCardForm
            formTag={false}
            onChange={this.onCreditCardFormChange}
            ref={r => this.ccForm = r}
            value={this.state.ccFormState}
          />
          <div style={{ paddingTop: 20 }}>
            <BodyText variant={BodyTextVariants.Smaller}>
              <Button anchor={true} onClick={() => this.setState({ error: null, createStep: 'portal' })}>
                <Messages.CreatePortalAccount.Message />
              </Button>
            </BodyText>
          </div>
        </div>
      );
    }

    return (
      <SimpleModal
        header={Messages.AddHeader}
        onClose={this.props.onClose}
        submit={{
          onClick: this.onSubmit,
          message: Messages.SubmitButton,
          isProcessing: isLoading || isLoadingState,
          isDisabled: isLoading || isLoadingState
        }}
        close={{message: Messages.CloseButton}}
        isForm={true}
      >
        {content}
      </SimpleModal>
    );
  }
}

export default createLaunchHook(connect(CreateSwitchCompanyModal));
