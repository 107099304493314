/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */
/* eslint max-len:0 */

import { defineMessages } from 'react-intl';

const common = defineMessages({
  Header: {
    id: 'SecureAccount.Header',
    defaultMessage: 'Sign and secure your account.'
  },
  TwoFactorInstructions: {
    id: 'SecureAccount.TwoFactor.TwoFactorInstructions',
    defaultMessage: 'Keep your account safe by enabling two-factor authentication. Don\’t want to use a smartphone? {toggleLink}'
  },
  SecurityQuestionsInstructions: {
    id: 'SecureAccount.TwoFactor.SecurityQuestionsInstructions',
    defaultMessage: 'Keep your account safe by answering these security questions. Prefer to use a smartphone? {toggleLink}'
  }
});

const twoFactor = defineMessages({
  SecurityQuestionsLink: {
    id: 'SecureAccount.TwoFactor.SecurityQuestionsLink',
    defaultMessage: 'Answer security questions.'
  },
  Enable: {
    id: 'SecureAccount.TwoFactor.buttons.Enable',
    defaultMessage: 'Enable'
  },
  Confirm: {
    id: 'SecureAccount.TwoFactor.buttons.Confirm',
    defaultMessage: 'Confirm'
  },
  Save: {
    id: 'SecureAccount.TwoFactor.buttons.Save',
    defaultMessage: 'Save'
  },
  StandardMessagingRates: {
    id: 'SecureAccount.TwoFactor.StandardMessagingRates',
    defaultMessage: 'Message and data rates may apply.'
  },
  StepOneHeader: {
    id: 'SecureAccount.TwoFactor.StepOneHeader',
    defaultMessage: 'Step 1 of 2'
  },
  StepTwoHeader: {
    id: 'SecureAccount.TwoFactor.StepTwoHeader',
    defaultMessage: 'Step 2 of 2'
  }
});

const securityQuestions = defineMessages({
  TwoFactorLink: {
    id: 'SecureAccount.SecurityQuestions.TwoFactorLink',
    defaultMessage: 'Enable two-factor verification.'
  },
  Submit: {
    id: 'SecureAccount.SecurityQuestions.Save',
    defaultMessage: 'Save'
  }
});

const buttons = defineMessages({
  Cancel: {
    id: 'SecureAccount.buttons.Cancel',
    defaultMessage: 'Cancel'
  },
  Send: {
    id: 'SecureAccount.buttons.Send',
    defaultMessage: 'Send'
  },
  DoLater: {
    id: 'SecureAccount.buttons.DoLater',
    defaultMessage: 'I\'ll do this later'
  },
  Continue: {
    id: 'SecureAccount.buttons.Continue',
    defaultMessage: 'Continue'
  },
  Save: {
    id: 'SecureAccount.buttons.Save',
    defaultMessage: 'Save'
  }
});

export default {
  ...common,
  twoFactor,
  securityQuestions,
  buttons
};
