/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { toMessages } from 'config/messages';

export default toMessages('DateRange', {
  Today: 'Today',
  DateRange: '{begin} - {end}',
  BeforeRange: 'Until {date}',
  AfterRange: 'Since {date}'
});
