/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */
/* eslint max-len:0 */

import { toMessages } from 'config/messages';

export const AccountingTypeUsageMessages = toMessages('AccountingTypeUsage', {
  Header: 'How will you use Viewpost?',
  Subheader: 'We’ll help you get your account set up quickly and efficiently.',
  Instructions: '{b}Select one or both.{b} All features will be available, regardless of your selection.',
  AccountsReceivableHeader: 'Invoice and Get Paid',
  AccountsPayableHeader: 'Pay Bills',
  OneSelectionContinue: 'Continue with One Selection'
});

export const BusinessRelationshipUsageMessages = toMessages('AccountingTypeUsage', {
  Header: 'What type of customers pay you?',
  Subheader: 'This helps us ensure your account is set up correctly.',
  Instructions: '{b}Select one or both.{b} Some users only have one type of customer.',
  ConsumerOnlyHeader: 'I get paid by individuals.',
  ConsumerOnlyDescription: 'This includes people paying you for things like rent, lawn care, youth sports, or other personal products or services.',
  BusinessOnlyHeader: 'I get paid by businesses.',
  BusinessOnlyDescription: 'Business customers could be LLCs, corporations, or sole proprietorships that are formally set up as a business.',
  OneSelectionContinue: 'Continue with One Selection'
});
