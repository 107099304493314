/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import t from 'tcomb-validation';

import { InvoiceActions } from 'config/constants';
import NetworkActions from 'config/constants/network/NetworkActions';
import PaymentActions from 'config/constants/PaymentActions';

const CompanyActions = t.enums({
  [NetworkActions.UpdateAddressBook]: '',
  [NetworkActions.Invite]: '',
  [NetworkActions.Reinvite]: '',
  [NetworkActions.Send]: '',
  [NetworkActions.Resend]: '',
  [NetworkActions.Accept]: '',
  [NetworkActions.Decline]: '',
  [NetworkActions.Cancel]: '',
  [NetworkActions.Disconnect]: '',
  [NetworkActions.DeleteAddressBook]: '',
  [NetworkActions.ViewNetworkProfile]: '',
  [InvoiceActions.CreateInvoice]: '',
  [PaymentActions.SendPayment]: '',
  [InvoiceActions.CreateBill]: '',
  [InvoiceActions.ViewOpenBills]: ''
});

export default CompanyActions;