/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React from 'react';
import { get, isFunction } from 'lodash';
import { areEntitiesUpdated, getEntity, getEntityUpdated, getEntityList } from 'schemas/state';
import { NotificationStyleTypes } from 'components/Notification';
import { showSmallBanner } from 'actions/notification';
import ApiAction from 'api/core/ApiAction';
import { deleteEntitiesAction } from 'api/core/utils';
import Messages from './references.messages';

// cacheTime - time in MS
export function createMetadata(referenceName, { referenceSelector, cacheTime = 0 } = {}) {
  return {
    correlationId: `Cache_${referenceName}`,
    reference: {
      name: referenceName,
      selector: referenceSelector || (entities => entities),
      cacheUntil: cacheTime > 0 ? new Date().getTime() + cacheTime : 0
    }
  };
}

function getCacheLastParams(state, referenceName) {
  return state?.references?.[referenceName]?.lastParams;
}

export function isCacheLoading(state, referenceName, defaultValue = false) {
  if (!state || !state.references || !state.references[referenceName]) return defaultValue;

  return state.references[referenceName].loading;
}

export function areCachesLoading(state, referenceNames) {
  if (!state || !state.references || !Array.isArray(referenceNames) || !referenceNames.length) {
    return false;
  }

  return !!referenceNames.map(referenceName => isCacheLoading(state, referenceName))
    .filter(isLoading => !!isLoading).length;
}

export const hasCacheBeenCalled = (state, referenceName) => {
  if (!state || !state.references) return false;

  return !!state.references[referenceName] && !isCacheLoading(state, referenceName);
};

export const hasCachesBeenCalled = (state, referenceNames) => {
  if (!state || !state.references) return false;

  return !referenceNames.some(referenceName => !hasCacheBeenCalled(state, referenceName));
};

export const isCacheLoadingOrNotCalled = (state, referenceName) => {
  if (!state || !state.references) return false;

  return isCacheLoading(state, referenceName) || !state.references[referenceName];
};

export function hasCacheFailed(state, referenceName) {
  if (!state || !state.references || !state.references[referenceName]) return false;

  return state.references[referenceName].error;
}

export function getCacheEntities(state, referenceName) {
  if (!state || !state.references || !state.references[referenceName]) return [];

  if (!state.references[referenceName].references) return [];

  return getEntityList(state, state.references[referenceName].references);
}

export function getCacheEntity(state, referenceName, selector) {
  if (!state || !state.references || !state.references[referenceName]) return null;

  if (!state.references[referenceName].references) return null;

  if (selector) {
    let ref = state.references[referenceName].references.find(selector);
    return ref ? getEntity(state, ref) : null;
  }

  return getEntity(state, state.references[referenceName].references[0]);
}

export function getCacheEntityUpdated(state, referenceName) {
  if (!state || !state.references || !state.references[referenceName]) return null;

  if (!state.references[referenceName].references) return null;

  return getEntityUpdated(state, state.references[referenceName].references[0]);
}

export function getIsCacheUpdated(state, referenceName) {
  if (!state || !state.references || !state.references[referenceName]) return false;

  return areEntitiesUpdated(state, state.references[referenceName].references || []);
}

export function getCachePagination(state, referenceName) {
  if (!state || !state.references || !state.references[referenceName]) return null;

  return state.references[referenceName].pagination;
}

export function getCacheResponse(state, referenceName) {
  if (!state || !state.references || !state.references[referenceName]) return null;

  return state.references[referenceName].response;
}

function getCacheMappedResponse(state, referenceName, apiAction) {
  let response = getCacheResponse(state, referenceName);

  if (response && apiAction.config.responseMapper) {
    response = apiAction.config.responseMapper(response, state);
  }

  return response;
}

export function getCacheStatusCode(state, referenceName) {
  if (!state || !state.references || !state.references[referenceName]) return null;

  return state.references[referenceName].statusCode;
}

export const deleteCacheEntities = referenceName => (dispatch, getState) => {
  const state = getState();

  if (!state || !state.references || !state.references[referenceName]) return;

  if (!state.references[referenceName].references) return;

  dispatch(deleteEntitiesAction(state.references[referenceName].references));
};

export const dispatchApiCall = (
  referenceName,
  apiAction,
  apiParams,
  apiMetadata,
  referenceMetadata = {}
) => async (dispatch, getState) => {
  const referenceState = getState().references[referenceName];

  if (!referenceState || !referenceState.loading) {
    return dispatch(apiAction.create(apiParams, {
      ...apiMetadata,
      ...createMetadata(referenceName, referenceMetadata)
    }));
  }

  return [
    false,
    new ApiAction({
      type: apiAction.actionTypes.fail,
      payload: {
        reason: 'isLoading'
      }
    })
  ];
};

const handleSuccessMessageFunction = (successFunc, response) => (dispatch) => {
  successFunc(get(response, 'meta.response.body') || {}, (message, warning) => dispatch(showSmallBanner({
    type: warning ? NotificationStyleTypes.Warning : NotificationStyleTypes.Success,
    message
  })));
};

/**
 * Helper method for making an API call to track the status of it, retrieve the response afterward,
 * and display notifications if things go well or badly.
 */
export const makeApiCall = (
  referenceName,
  apiAction,
  apiParams,
  {
    successMessage,
    failureMessage,
    hideError,
    extractFirstErrorMessage,
    apiMetadata = {},
    referenceMetadata
  } = {}
) => async (
  dispatch,
  getState
) => {

  if (!apiAction) {
    console.error(`No ApiAction was passed in for ${referenceName}, ignoring makeApiCall`);
    return;
  }

  const [ success, action ] = await dispatch(dispatchApiCall(
    referenceName,
    apiAction,
    apiParams,
    // This will take on calling any passed in onComplete
    { ...apiMetadata, onComplete: null },
    referenceMetadata
  ));

  const isLoadingError = action?.payload?.reason === 'isLoading';

  if (action && !isLoadingError && (successMessage || failureMessage == null || failureMessage)) {
    const firstMessage = action.getFirstMessage();
    const isOldApiFailure = action.getResponseBody().success === false
      && !!firstMessage
      && extractFirstErrorMessage;

    if (isOldApiFailure) {
      dispatch(showSmallBanner({
        type: NotificationStyleTypes.Error,
        message: firstMessage
      }));
    } else if (success && successMessage) {
      if (isFunction(successMessage)) {
        dispatch(handleSuccessMessageFunction(successMessage, action));
      } else if (successMessage === true) {
        dispatch(showSmallBanner({
          type: NotificationStyleTypes.Success,
          message: <Messages.DefaultSuccessMessage.Message />
        }));
      } else {
        dispatch(showSmallBanner({
          type: NotificationStyleTypes.Success,
          message: successMessage
        }));
      }
    } else if (!success) {
      if ((failureMessage == null || failureMessage) && !hideError) {
        let actualErrorMessage = null;
        if (extractFirstErrorMessage) {
          actualErrorMessage = firstMessage;
        }

        dispatch(showSmallBanner({
          type: NotificationStyleTypes.Error,
          message: failureMessage || actualErrorMessage || <Messages.DefaultErrorMessage.Message/>
        }));
      }
    }

    // I recommend not passing onComplete if you are doing async/await
    if (apiMetadata.onComplete) {
      apiMetadata.onComplete(success, action);
    }
  }

  return [ success, action ];
};

export function cacheOrDispatchApiCall(referenceName, apiAction, apiParams, apiMetadata = {}, referenceMetadata = {}) {
  return (dispatch, getState) => {
    const referenceState = getState().references[referenceName];

    if (referenceState && (
      referenceState.cacheUntil <= 0
        || referenceState.cacheUntil > new Date().getTime())) {
      if (apiMetadata.onComplete) {
        if (referenceState.loading) {
          console.warn(`cacheOrDispatchApiCall: Trying to call onComplete before data has come back.
            This is not yet supported.`);
        } else {
          const completed = apiMetadata.onComplete(true);
          // Some onCompletes return thunks
          if (isFunction(completed)) {
            completed(dispatch, getState);
          }
        }
      }
    } else {
      dispatch(dispatchApiCall(referenceName, apiAction, apiParams, apiMetadata, referenceMetadata));
    }
  };
}

export function clearCache(name) {
  return dispatch => dispatch({
    type: 'REFERENCE_CACHE_CLEAR',
    name
  });
}

export const createCacheActions = (referenceName, apiAction, {
  getParamsFromState
} = {}) => {
  return {
    makeApiCall: (...args) => dispatch => dispatch(makeApiCall(referenceName, apiAction, ...args)),
    makeStateParamsApiCall: (params, ...args) => (dispatch, getState) => dispatch(
      makeApiCall(referenceName, apiAction, getParamsFromState(getState(), params), ...args)
    ),
    refreshCache: () => (dispatch, getState) => {
      let state = getState();
      let lastParams = getCacheLastParams(state, referenceName);

      // this is supposed to be used when something was already called
      // ignore when no record of a previous call
      if (!lastParams) return;
      dispatch(makeApiCall(referenceName, apiAction, lastParams));
    },
    dispatchApiCall: (...args) => dispatch => dispatch(dispatchApiCall(referenceName, apiAction, ...args)),
    isCacheLoading: (state, ...args) => isCacheLoading(state, referenceName, ...args),
    getCacheLastParams: state => getCacheLastParams(state, referenceName),
    getCachePagination: state => getCachePagination(state, referenceName),
    hasCacheBeenCalled: state => hasCacheBeenCalled(state, referenceName),
    hasCacheFailed: state => hasCacheFailed(state, referenceName),
    getCacheEntity: (state, ...args) => getCacheEntity(state, referenceName, ...args),
    getCacheEntities: state => getCacheEntities(state, referenceName),
    getCacheEntityUpdated: state => getCacheEntityUpdated(state, referenceName),
    getCacheResponse: state => getCacheResponse(state, referenceName),
    getCacheMappedResponse: state => getCacheMappedResponse(state, referenceName, apiAction),
    getCacheStatusCode: state => getCacheStatusCode(state, referenceName),
    clearCache: () => dispatch => dispatch(clearCache(referenceName)),
    deleteCacheEntities: () => dispatch => dispatch(deleteCacheEntities(referenceName)),
    wipeState: () => (dispatch) => {
      dispatch(deleteCacheEntities(referenceName));
      dispatch(clearCache(referenceName));
    }
  };
};

// Allows you to create a way to differentiate between cache actions sharing the same referenceName
// but are components for different entities.
export const createCacheActionsGroup = (referenceName, ...args) => {
  const cache = [];

  return (uniqueId) => {
    if (!uniqueId) {
      throw new Error('Did not pass ID to Cache Actions Group.');
    }

    if (!cache[uniqueId]) {
      cache[uniqueId] = createCacheActions(`${referenceName}-${uniqueId}`, ...args);
    }

    return cache[uniqueId];
  };
};
