/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { connect } from 'react-redux';

import { getIdentity } from 'actions/common';

const MapStateToProps = (state) => {
  return {
    isShown: getIdentity(state, { notExpected: true })
      ?.nextSetupSteps
      ?.some(x => x === 'AddPayeeBankAccount')
  };
};

export default Component => connect(MapStateToProps)(Component);
