/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, { memo } from 'react';
import classNames from 'classnames';
import AppContainer from 'components/AppContainer';
import Badge from 'components/Badge';
import PathLink from 'components/Link/PathLink';
import AnalyticsCategory from 'components/AnalyticsCategory';
import { connectNavigationData } from '../../index.connect';
import { isPathConfig } from '../../config/utils';
import GreyNavBar from '../../GreyNavBar';
import { SecondaryNavLabel } from '../../NavLabel';
import './index.scss';

const SecondaryNav = memo(connectNavigationData()(({
  navData,
  paths
}) => (
  <GreyNavBar>
    <AppContainer>
      <AnalyticsCategory name="SecondaryNav" className="desktop-secondary-nav" style={null}>
        {paths.map((pathConfig) => {
          const {
            badge,
            path
          } = isPathConfig(pathConfig)
            ? { path: pathConfig }
            : pathConfig;

          const badgeContent = badge ? badge(navData || {}) : null;

          return (
            <PathLink
              key={path.id}
              analyticsId={path.id}
              exact={true}
              path={path}
              className={classNames('secondary-link', `sec-nav-${path.id}`)}
              activeClassName="active"
            >
              <SecondaryNavLabel id={path.id}/>
              {badgeContent ? (
                <Badge>{badgeContent}</Badge>
              ) : null}
            </PathLink>
          );
        })}
      </AnalyticsCategory>
    </AppContainer>
  </GreyNavBar>
)));

export default SecondaryNav;