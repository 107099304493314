/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React from 'react';
import PropTypes from 'prop-types';
import PathLink from 'components/Link/PathLink';
import RouteLink from 'components/Link/RouteLink';
import { LinkRef } from 'links';
import { InvoiceType } from 'schemas/invoicing/invoice';

const getPathLink = (taskEntityType, taskEntityId) => {
  switch (taskEntityType) {
    case 'confirmUnintendedConnectionRequest':
      return <PathLink.Network.Requests />;
    default:
      break;
  }

  return;
};

function getLinkRef(taskEntityType, taskEntityId) {
  switch (taskEntityType) {
    case 'eprQueueLink':
      return {
        linkRef: LinkRef.bills.vpx,
        status: 'Vpx'
      };
    case 'viewPayableLink':
      return {
        linkRef: LinkRef.bills.details,
        invoiceId: taskEntityId,
        invoiceType: InvoiceType.meta.map.Payable,
        isMine: true
      };
    case 'viewUnapprovedReceivableLink':
      return {
        linkRef: LinkRef.bills.inbox,
        status: 'New'
      };
    case 'viewUnapprovedReceivablesLink':
      return {
        linkRef: LinkRef.bills.inbox,
        addressBookId: taskEntityId
      };
    case 'viewUnapprovedPayableLink':
      return {
        linkRef: LinkRef.bills.details,
        invoiceId: taskEntityId,
        invoiceType: InvoiceType.meta.map.Receivable,
        isMine: false
      };
    case 'scheduledPayment':
      return {
        linkRef: LinkRef.payments.sent,
        status: 'Scheduled'
      };
    case 'unappliedPayment':
      return {
        linkRef: LinkRef.payments.detailsReceived,
        id: taskEntityId
      };
    case 'viewPendingPaymentLink':
      return {
        linkRef: LinkRef.payments.detailsSent,
        settlementId: taskEntityId
      };
    default:
      return {};
  }
}

const TaskActionLink = ({
  children,
  taskEntityId,
  taskEntityType
}) => {
  const pathLink = getPathLink(taskEntityType, taskEntityId);

  if (pathLink) return React.cloneElement(pathLink, {}, children);

  return (
    <RouteLink
      button={true}
      {...getLinkRef(taskEntityType, taskEntityId)}
    >
      {children}
    </RouteLink>
  );
};

TaskActionLink.propTypes = {
  taskEntityType: PropTypes.string.isRequired,
  taskEntityId: PropTypes.string.isRequired
};

export default TaskActionLink;
