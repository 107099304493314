/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { createContext } from 'react';

const UpdateContext = createContext({
  subscribeListener: null,
  subscribeListeners: null,
  useInvalidateEffect: null,
  useUpdateParams: null
});

export default UpdateContext;