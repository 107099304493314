/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React from 'react';
import { endsWith, forEach } from 'lodash';

import NumberWithUnitsInput from 'components/Form/Inputs/NumberWithUnitsInput';

import classNames from 'classnames';

import AddressInput from './Inputs/AddressInput';
import FormList from './Containers/List';
import FormStruct from './Containers/Struct';
import FormField from './Containers/Field';

import Messages from './utils.messages';

import getDefaultInput from './getDefaultInput';

function getLabelMessage(path) {
  if (!this.labelMessages) return null;
  return this.labelMessages[path];
}

function getPlaceholderMessage(path) {
  if (!this.placeholderMessages) return null;
  return this.placeholderMessages[path];
}

function getOptionMessages(path) {
  if (!this.optionMessages) return null;
  return this.optionMessages[path];
}

/**
 * Builds a default tcomb-forms options object based on a tcomb type
 * @param {tcombType} modelType
 * @param {tcombFormOptions?} options - tcombs-form options on which to base the built options
 * @param {string?} path - a string representation of where we are in traversing the object model (e.g. "myField" or "myField.subField.subField" )
 * @returns {tcombFormOptions}
 */
export const defaultOptions = (
  modelType,
  options = {},
  path = '',
  fieldSpecificOptionsReferences = {},
  isMaybe = false
) => {
  if (!modelType) throw new Error('Model Type cannot be null');

  // TODO: determine if there's any reason to care about maybes at this level
  if (modelType.meta.kind === 'maybe') {
    options.isMaybe = true;
    // 'maybe's just wrap our real models, so just pass through
    return defaultOptions(modelType.meta.type, options, path, fieldSpecificOptionsReferences, true);
  }
  options.config = options.config || {};
  options.attrs = options.attrs || {};

  options.i18n = {
    optional: '',               // suffix added to optional fields
    required: '',               // suffix added to required fields

    // we use these as keys to identify the list buttons (since we don't get types for them)
    add: 'add',                 // add button
    down: 'down',               // move down button
    remove: 'remove',           // remove button
    up: 'up',                    // move up button
    ...options.i18n
  };

  options.i18n.getLabelMessage = getLabelMessage.bind(options.i18n);
  options.i18n.getPlaceholderMessage = getPlaceholderMessage.bind(options.i18n);
  options.i18n.getOptionMessages = getOptionMessages.bind(options.i18n);

  options.config.path = path;

  // keep a reference to each field-specific option (so we can quickly look them up later)
  fieldSpecificOptionsReferences[path] = options;

  // container TEMPLATES
  if (!options.template) {
    if (modelType.meta.kind === 'struct') {
      // Respect the passed in inputComponent
      if (options.config.inputComponent) {
        options.factory = FormField;
      } else if (modelType.meta.name === 'NumberWithUnits') {
        // treat NumberWithUnit structs as a single field
        options.factory = FormField;
        options.config.inputComponent = NumberWithUnitsInput;
      } else if (modelType.meta.name === 'Address') {
        // treat NumberWithUnit structs as a single field
        options.factory = FormField;
        options.config.inputComponent = AddressInput;
      } else {
        // normal structs will be treated as an entire form (fieldset)
        let className = classNames(options.config.className, `col-${options.config.cols}`);
        options.template = (containerOptions) => {
          const dontStack = options.dontStack || (options.config && options.config.dontStack);
          return (!options.config || !options.config.cols) ? (
            <FormStruct
              dontStack={dontStack}
              addPadding={options.addPadding}
              options={containerOptions}
              {...(options.structProps || {})}
            />
          ) : (
            <div className={className}>
              <FormStruct
                dontStack={options.dontStack}
                addPadding={options.addPadding}
                options={containerOptions}
                {...(options.structProps || {})}
              />
            </div>
          );
        };
      }
    } else if (modelType.meta.kind === 'list' && !options.config.inputComponent) {
      options.template = (containerOptions) => {
        return <FormList options={containerOptions} />;
      };
    } else if (options.config.template) {
      options.template = options.config.template;
    } else {
      // field type
      options.factory = FormField;
    }
  }

  // INPUT TYPES
  if (!options.config.inputComponent) {
    if (
      modelType.meta.kind === 'irreducible'
      && modelType.meta.name === 'String'
      && endsWith(path, 'email')
    ) {
      // TODO: do we need this anymore since email type is just a string with regex validation??
      options.config.type = 'email';
    } else {
      let defaultInput = getDefaultInput(modelType);
      options.config.inputComponent = defaultInput.inputComponent;

      // continue to support legacy cases where we pulled out specific named props
      if (defaultInput?.inputProps?.type) {
        options.config.type = defaultInput?.inputProps?.type;
      }

      if (defaultInput?.inputProps?.willHideLabel) {
        options.config.willHideLabel = defaultInput?.inputProps?.willHideLabel;
      }
    }

  }

  if (options.config.inputComponent) {
    if (options.config.inputComponent.createTransformer) {
      options.transformer = options.config.inputComponent.createTransformer(modelType, isMaybe);
    }
  }

  options.error = (value, fieldPath, context) => {
    if (options.config.error) return options.config.error;

    let message = modelType.getValidationErrorMessage
      ? modelType.getValidationErrorMessage(value, fieldPath, context)
      : null;

    if (!message && !isMaybe && !value) {
      // TODO: make it easier to be assured this context is set
      if (!context || !context.intl) {
        return '';
      }
      message = context.intl.formatMessage(Messages.NotSetDefaultError);
    }

    return message;
  };

  // recurse into struct's child properties/fields: myStructField.myChildField
  if (modelType.meta.props) {
    options.fields = options.fields || {};
    forEach(modelType.meta.props, (childType, childName) => {
      let childPath = childName;
      if (path.length > 0) {
        childPath = `${path}.${childPath}`;
      }

      let childOptions = {
        i18n: options.i18n,
        isMobile: options.isMobile,
        ...options.fields[childName]
      };

      options.fields[childName] = defaultOptions(
        childType,
        childOptions,
        childPath,
        fieldSpecificOptionsReferences
      );
    });
  }

  // recurse into list children: myListField[].myChildField
  // all items will use the options config
  if (modelType.meta.kind === 'list') {
    let childOptions = options.item || {};

    options.item = defaultOptions(modelType.meta.type, childOptions, `${path}[]`, fieldSpecificOptionsReferences);
  }

  return options;
};
