/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import PasswordToggleTextBox from 'components/Form/Inputs/PasswordToggleTextBox';
import Tooltip from 'components/Tooltip';
import { getPasswordStrengthScore, MIN_LENGTH, MAX_LENGTH } from 'schemas/common/password';
import Messages from './index.messages';
import './index.scss';

/**
 * Password text box that also includes indication of password strength (per current VP rules)
 */
export default class PasswordStrengthTextBox extends Component {
  static propTypes = {
    /** If enabled, adds a Show/Hide toggle */
    isToggleEnabled: PropTypes.bool,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func
  };

  static defaultProps = {
    isToggleEnabled: true,
    onFocus: () => null,
    onBlur: () => null
  };

  constructor(props) {
    super(props);

    this.state = {
      currentStrengthText: '',
      currentStrengthClass: '',
      isFocused: false
    };

    this.onChange = this.onChange.bind(this);

    this.minLength = MIN_LENGTH;
    this.maxLength = MAX_LENGTH;
    this.strengthStatuses = ['Very Weak', 'Very Weak', 'Weak', 'Good', 'Strong'];
  }

  onChange(password) {
    if (!password || password.length < this.minLength) {
      this.setState({
        currentStrengthText: 'Too Short',
        currentStrengthClass: 'very-weak'
      });
    } else if (password.length > this.maxLength) {
      this.setState({
        currentStrengthText: 'Too Long',
        currentStrengthClass: 'very-weak'
      });
    } else {
      let score = getPasswordStrengthScore(password);
      let currentStrengthText = this.strengthStatuses[score];

      this.setState({
        currentStrengthText,
        currentStrengthClass: currentStrengthText.toLowerCase().replace(' ', '-')
      });
    }

    if (this.props.onChange) {
      this.props.onChange(password);
    }
  }

  renderStrengthIndicator(style = {}) {
    return (
      <ul className="password-strength-indicator" style={style}>
        <li className="item very-weak">1</li>
        <li className="item weak">2</li>
        <li className="item good">3</li>
        <li className="item strong">4</li>
      </ul>
    );
  }

  renderPasswordStrength() {
    const strength = (
      <span className="password-strength-current color-black">
        {this.state.currentStrengthText}
      </span>
    );
    return (
      <div>
        <div className={`password-strength-wrapper color-black ${this.state.currentStrengthClass}`}>
          <strong>
            <FormattedMessage {...Messages.PasswordStrength} values={{strength}}/>
          </strong>
          {this.renderStrengthIndicator()}
        </div>
        <div className="color-secondary">
          <FormattedMessage {...Messages.PasswordRules} />
        </div>
      </div>
    );
  }

  renderPasswordStrengthTooltip() {
    return (
      <div className="password-tooltip-variant">
        <div className={`password-strength-wrapper ${this.state.currentStrengthClass}`}>
          {this.renderStrengthIndicator({marginBottom: 4, marginTop: -4})}
        </div>
        <div>
          <FormattedMessage {...Messages.PasswordRules} />
        </div>
      </div>
    );
  }

  render() {
    const {
      value,
      placeholder,
      isMobile,
      isToggleEnabled,
      onBlur,
      onFocus,
      hasError,
      variant,
      options
    } = this.props;

    const statusText = this.state.currentStrengthText;
    const passwordToggleTextBox = (
      <PasswordToggleTextBox
        hasError={hasError}
        onBlur={() => this.setState({isFocused: false}, () => onBlur())}
        onFocus={() => this.setState({isFocused: true}, () => onFocus())}
        value={value}
        onChange={this.onChange}
        placeholder={placeholder}
        isToggleEnabled={isToggleEnabled}
      />
    );

    return (
      <div className="control-group">
        <div className="controls">
          {variant === 'tooltip'
            && <Tooltip
              visible={this.state.isFocused && !!value}
              header={
                <FormattedMessage {...Messages.Password} values={{status: statusText.toLowerCase()}} />
              }
              headerColor="white"
              placement={(options || {}).isMobile || isMobile ? 'top' : 'left'}
              variant="blue-tooltip"
              childWrapperStyle={{display: 'block'}}
              overlay={this.renderPasswordStrengthTooltip()}>
              {passwordToggleTextBox}
            </Tooltip>}
          {variant !== 'tooltip' && <div>{passwordToggleTextBox}{value && this.renderPasswordStrength()}</div>}
        </div>
      </div>
    );
  }
}
