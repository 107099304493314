/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import t from 'tcomb-validation';

import { toMessages } from 'config/messages';

import StringMessages from '../string.messages';
import { isValidEmail, MAX_EMAIL_LENGTH } from './constants';

const Messages = toMessages('EmailSchema', {
  Invalid: 'Please enter a valid email address.'
});

const Email = t.subtype(
  t.String,
  (value) => {
    if (value?.length && value.length > MAX_EMAIL_LENGTH) return false;
    return isValidEmail(value);
  },
  'Email'
);

Email.getValidationErrorMessage = (value, _, context) => {
  if (value?.length && value.length > MAX_EMAIL_LENGTH) {
    if (context?.intl) {
      return context.intl.formatMessage(
        StringMessages.TooLong,
        { maxLength: MAX_EMAIL_LENGTH }
      );
    }

    return StringMessages.TooLong.defaultMessage;
  }

  if (!isValidEmail(value)) {
    if (context?.intl) {
      return context.intl.formatMessage(Messages.Invalid);
    }

    return Messages.Invalid.defaultMessage;
  }
};

export default Email;