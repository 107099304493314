/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import t from 'tcomb-validation';
import { defineSchema } from 'schemas';

const PaymentMethodOption = t.struct({
  name: t.String,
  businessDaysToDelivery: t.maybe(t.Number)
});

/**
  Provides pertinent information related to making a payment for a specific vendor
 */
// JL Note: I removed this extending addSchema/CreatableAddress as there is a problem with the T1 response
// and I don't believe we actually use the address data off this model anymore
export const PaymentData = defineSchema(
  {
    id: t.maybe(t.String),
    companyName: t.maybe(t.String),
    companyId: t.maybe(t.String),
    addressBookId: t.maybe(t.String),
    connectionStatus: t.maybe(t.String),
    isCreditCardEnabled: t.Boolean, // TODO: Move to use PaymentDataMethodOptions
    canVendorPayFees: t.maybe(t.Boolean),
    isWhitelistedToUseUnverfiedAccount: t.maybe(t.Boolean),

    // indicates that electronic payment WOULD be available if the user completed enrollment for it (WU)
    isElectronicAvailableAfterEnroll: t.maybe(t.Boolean),

    // when true, indicates that delivery methods may be blocked due to intl destination
    internationalPaperCheckDisabled: t.maybe(t.Boolean),

    // beneficial owner information is needed for the company
    needsBeneficialOwnerInfo: t.maybe(t.Boolean),

    // currently selected payment method type
    paymentMethodType: t.maybe(t.String),

    // what payment method type options are available
    paymentMethodOptions: t.list(PaymentMethodOption), // TODO: Move to use PaymentDataMethodOptions

    achPaymentAccountId: t.maybe(t.String),
    achRoutingNumber: t.maybe(t.String),
    achAccountNumber: t.maybe(t.String), // the full account number, usually only used in transition for security
    achAccountLastFour: t.maybe(t.String),
    twoStepOverLimit: t.maybe(t.Boolean)
  },
  'PaymentData'
);

// Broke the payment method options into its own entity because it is not influenced the payment
// method ID changing - in fact many of the fields of PaymentData aren't affected by that param of
// in the API call, but these fields are especially so. With this being a separate entity, we can
// use coalscing logic to ignore when the API call is being made and get whatever
// PaymentDataMethodOptions entity was last gotten for an addressBookId as its still valid.
export const PaymentDataMethodOptions = defineSchema(
  {
    addressBookId: t.String,
    isCreditCardEnabled: t.Boolean,
    isWhitelistedToUseUnverfiedAccount: t.Boolean,
    needsBeneficialOwnerInfo: t.Boolean,
    options: t.list(PaymentMethodOption)
  },
  'PaymentDataMethodOptions'
);

export const DeliveryDateData = defineSchema({
  dates: t.list(t.struct({
    sendDate: t.maybe(t.Date),
    deliverByDate: t.maybe(t.Date)
  }))
}, 'PaymentDeliveryDate');
