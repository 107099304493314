/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { useRef } from 'react';

// Takes a value and puts it in a ref and updates the ref's value every render.
// For using props within functions so you always have the last render's value.
const usePropRef = (value) => {
  // Hooks
  const ref = useRef(value);
  ref.current = value;

  // Action
  return ref;
};

export default usePropRef;