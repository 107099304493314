/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, { useContext } from 'react';

import FormContext, { NamePrefixContext } from './context';

// This is a helper component that you can use to wrap form inputs that you know conditionally
// display, so you don't need to mimic/copy your form model creation logic in your form again
const IfFieldDefined = ({
  children,
  name: originalName,
  not
}) => {
  const { paths } = useContext(FormContext);

  const namePrefix = useContext(NamePrefixContext);

  const name = `${namePrefix}${namePrefix ? '.' : ''}${originalName}`;
  const typePath = name.replace(/\[[0-9]+\]/g, '[]'); // Normalize a[0].b[2] to a[].b[]
  const hasType = !!paths[typePath];

  return (!not && hasType) || (!!not && !hasType) ? (
    <>
      {children}
    </>
  ) : <></>;
};

export default IfFieldDefined;