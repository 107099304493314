/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { useSelector } from 'react-redux';

import isUserOfType from 'actions/common/isUserOfType';

import { BusinessType, CompanyStatus } from 'config/constants/session';

export { BusinessType, CompanyStatus };

const useIsUserOfType = (status, businessType, useSecureTokenIdentity) => useSelector(
  state => isUserOfType(state, status, businessType, useSecureTokenIdentity)
);

export default useIsUserOfType;