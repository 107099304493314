/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { isFetchAction, isSuccessAction } from 'api/core/utils';
import { DashboardActions } from 'config/constants';
import { isNukeStateAction, newState } from './utils';

export function DashboardView(state = {}, action) {
  if (action.type === DashboardActions.LoadAdditionalActivities) {
    return newState(state, {
      isLoadingMoreActivities: true
    });
  }

  if (isFetchAction(action, 'getDashboardRecentActivities')) {
    return newState(state, {
      isLoadingMoreActivities: true
    });
  }

  if (isFetchAction(action, 'getCashflowData')) {
    return newState(state, {
      isLoadingCashflowGraph: true
    });
  }

  if (isSuccessAction(action, 'getDashboardRecentActivities')) {
    return newState(state, {
      isLoadingRecentActivities: false,
      isLoadingMoreActivities: false,
      activities: action.payload.references[0]
    });
  }

  if (isSuccessAction(action, 'getCashflowData')) {
    return newState(state, {
      isLoadingCashflowGraph: false,
      cashflowData: action.payload.references[0]
    });
  }

  if (isNukeStateAction(action, 'DashboardView')) {
    return {};
  }

  return state;
}
