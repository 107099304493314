/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, { Component } from 'react';
import Modal, { ModalBody, ModalFooter, ModalFooterButton } from 'components/Modal';

import HeaderText from 'components/HeaderText';
import BodyText from 'components/BodyText';
import { addModal } from 'config/modals/registry';
import BulletedList, { Bullet } from 'components/BulletedList';
import ProvideIsMobile from 'decorators/ProvideIsMobile';

import connector from './index.connect';


class AnnualUpdateReminderModal extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  renderMessage() {
    return (
      <div>
        <HeaderText>
          Is your company information up to date?
        </HeaderText>
        <br/>
        <BodyText>
          Please take a moment to review your company's information on Viewpost
          to ensure we have the correct:
        </BodyText>
        <br/><br/>
        <BulletedList variant="bullets">
          <Bullet>
            Company Address
          </Bullet>
          <Bullet>
            Bank Account Information
          </Bullet>
          <Bullet>
            Company Employees / Users
          </Bullet>
          <Bullet>
            Beneficial Owner Information
          </Bullet>
        </BulletedList>
      </div>
    );
  }

  render() {

    return (
      <Modal header="Review Company Information">
        <ModalBody style={{ padding: '22px 28px' }}>
          <BodyText style={{ marginBottom: 12, display: 'block' }}>
            {this.renderMessage()}
          </BodyText>
        </ModalBody>
        <ModalFooter>
          <ModalFooterButton variant="primary" onClick={() => this.props.onSubmit()}>
            {this.props.isMobile
              ? 'Review'
              : 'Review & Update Company Information'}
          </ModalFooterButton>
          <ModalFooterButton onClick={() => this.props.onClose()}>
            Do This Later
          </ModalFooterButton>
        </ModalFooter>
      </Modal>
    );
  }
}

export default addModal('AnnualUpdateReminderModal', connector(ProvideIsMobile(AnnualUpdateReminderModal)));
