/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, { Component } from 'react';

const createSecureAccountToggle = ({
  ParentWrappingComponent,
  TwoStepForm,
  SecurityQuestionsForm
}) => {
  class SecureAccountToggle extends Component {
    constructor(props) {
      super(props);
      this.state = { isTwoFactor: true };
      this.toggleMethod = this.toggleMethod.bind(this);
    }

    toggleMethod() {
      this.setState({
        isTwoFactor: !this.state.isTwoFactor
      });
    }

    render() {
      return (
        <ParentWrappingComponent
          isTwoFactor={this.state.isTwoFactor}
          toggleMethod={this.toggleMethod}
        >
          <div>
            {this.state.isTwoFactor ? (
              <TwoStepForm {...this.props} />
            ) : (
              <SecurityQuestionsForm {...this.props} />
            )}
          </div>
        </ParentWrappingComponent>
      );
    }
  }

  return SecureAccountToggle;
};

export default createSecureAccountToggle;
