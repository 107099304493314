/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, { forwardRef, useState } from 'react';
import t from 'tcomb-validation';

import Button from 'components/Button';
import PasswordStrengthTextBox from 'components/Form/Inputs/PasswordStrengthTextBox';
import FormV2, { FieldInput, FieldOption, IfFieldDefined } from 'components/Form/V2';
import { passthroughToInput } from 'components/Form/V2/FieldOption';
import Tooltip from 'components/Tooltip';

import useIsMobile from 'hooks/browser/useIsMobile';
import useFormatMessage from 'hooks/intl/useFormatMessage';

import { CompanyNameStrict } from 'schemas/common/companyName';
// import createMatchingEmail from 'schemas/common/email/createMatchingEmail';
import { Address } from 'schemas/common/address';
import Email from 'schemas/common/email/Email';
import { FirstName, LastName } from 'schemas/common/name';
import { Password } from 'schemas/common/password';
import { Phone } from 'schemas/common/phone';
import FormDecisionSelect from 'components/Form/Inputs/FormDecisionSelect';
import { IconTypes } from 'components/Icon';

import Messages from './index.messages';
// import './index.scss';

const FieldInputTooltip = passthroughToInput(Tooltip);

const RegistrationForm = forwardRef(
  (
    {
      invitationId,
      offerPersonalPortal,
      isSkinny,
      isSubmitting,
      onChange,
      onSubmit,
      value
    },
    ref
  ) => {
    // Hooks
    const isMobile = useIsMobile();
    const [isCompanyFocused, setIsCompanyFocused] = useState(false);
    const formatMessage = useFormatMessage();

    const schema = {
      email: Email,
      // verifyEmail: createMatchingEmail(email),
      firstName: FirstName,
      lastName: LastName,
      password: Password,
      address: Address,
      phone: Phone
    };

    if (offerPersonalPortal) {
      schema.companyOrPersonal = t.enums({
        'company': <div>
          A Business
          <div className="text-tiny">
            (or Sole Proprietorship)
          </div>
        </div>,
        'personal': 'An Individual'
      });
    }

    let isCompany = !offerPersonalPortal || value?.companyOrPersonal === 'company';
    if (isCompany) {
      schema.companyName = CompanyNameStrict;
    }

    // Render
    return (
      <FormV2
        ref={ref}
        className="registrationForm"
        display="flex"
        onChange={onChange}
        modelType={t.struct(schema)}
        value={value}
      >
        {offerPersonalPortal
          ? (
            <FieldOption
              name="companyOrPersonal"
              cols={12}
              label="This account is for"
            >
              <FormDecisionSelect
                maxOptionsForSingleLine={9}
                disableCollapse={true}
                icons={[
                  {
                    value: 'company',
                    type: IconTypes.CompanyBuilding,
                    style: { color: '#1e88e5' }
                  },
                  {
                    value: 'personal',
                    type: IconTypes.Contact,
                    style: { color: '#1e88e5' }
                  }
                ]}
              />
            </FieldOption>
          )
          : null}

        <div className="flex-grid gutter">
          <IfFieldDefined name="companyName">
            <FieldOption
              label={<Messages.CompanyName.Message />}
              name="companyName"
              onBlur={() => setIsCompanyFocused(false)}
              onFocus={() => setIsCompanyFocused(true)}
              cols={isMobile ? 12 : 6}
            >
              <FieldInputTooltip
                placement={isMobile ? 'top' : 'left'}
                variant="blue-tooltip"
                childWrapperStyle={{ display: 'block' }}
                visible={isCompanyFocused}
                header={<Messages.CompanyNameTooltipTitle.Message />}
                headerColor="white"
                overlay={<Messages.CompanyNameTooltip.Message />}
              >
                <FieldInput />
              </FieldInputTooltip>
            </FieldOption>
          </IfFieldDefined>
          <FieldOption
            name="firstName"
            cols={isMobile || !isCompany ? 6 : 3}
            label={<Messages.FirstName.Message />}
          />
          <FieldOption
            name="lastName"
            cols={isMobile || !isCompany ? 6 : 3}
            label={<Messages.LastName.Message />}
          />
          <FieldOption
            cols={isSkinny ? 12 : 6}
            disabled="true"
            name="email"
            label={<Messages.Email.Message />}
          />
          {/* <FieldOption
            cols={isSkinny ? 12 : 6}
            name="verifyEmail"
            label={<Messages.VerifyEmail.Message />}
          /> */}
          <FieldOption
            cols={6}
            name="phone"
            label={<Messages.Phone.Message />}
          />
          <FieldOption
            name="address"
          />
          <FieldOption
            name="password"
            label={<Messages.Password.Message />}
          >
            <PasswordStrengthTextBox
              isMobile={isMobile}
              isToggleEnabled={true}
              placeholder={formatMessage(Messages.PasswordPlaceholder)}
              variant="tooltip"
            />
          </FieldOption>
        </div>
        <div style={{
          paddingTop: '20px',
          marginTop: '20px',
          borderTop: '1px solid #ddd',
          textAlign: 'right'
        }}>
          <Button
            analyticsId="RegistrationButton"
            type="submit"
            isDisabled={isSubmitting}
            isProcessing={isSubmitting}
            onClick={onSubmit}
          >
            Create Account and Continue
          </Button>
        </div>
      </FormV2>
    );
  }
);

export default RegistrationForm;