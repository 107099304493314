/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import t from 'tcomb-validation';

import { defineSchema } from 'schemas';
import { BankAccountActions } from 'config/constants/bankAccount';
import { isValidAccount, isVerifiableAccount } from 'schemas/payments/bankAccount/isAccount';
import {
  createMaxDigitsNumberType,
  createBoundedDigitOnlyNonSequentialId
} from 'schemas/common/number';
import { createYearRange } from 'schemas/common/date';
import { BusinessTypeForForm, IndustryType } from 'schemas/network/companyProfile';
import { Phone } from 'schemas/common/phone';
import { createMaxLengthStringType, createUniqueStringType } from 'schemas/common/string';
import { isEnabled } from 'services/FeatureToggles';
import Messages from './bankAccount.messages';

const BankAccountStatus = t.enums({
  DefaultPaymentAccount: 'DefaultPaymentAccount',
  DefaultFeeAccount: 'DefaultFeeAccount',
  DefaultDepositAccount: 'DefaultDepositAccount',
  VerificationExpired: 'VerificationExpired',
  AccountInactive: 'AccountInactive',
  AccountLocked: 'AccountLocked',
  AccountVerified: 'AccountVerified',
  AwaitingMicro: 'AwaitingMicro',
  IclEnabled: 'IclEnabled',
  AccountAuthFormRegions: 'AccountAuthFormRegions',
  DualSigner: 'DualSigner',
  ThirdPartyACHEnabled: 'ThirdPartyACHEnabled',
  ThirdPartyACHPending: 'ThirdPartyACHPending',
  WU2StepEnabled: 'WU2StepEnabled',
  WU2StepPending: 'WU2StepPending',
  ReceiveOnly: 'ReceiveOnly'
}, 'BankAccountStatus');

const BankAccountModel = {
  id: t.String,
  companyId: t.maybe(t.String),
  accountName: t.String,
  accountType: t.maybe(t.String),
  companyName: t.maybe(t.String),
  accountNumberSuffix: t.maybe(t.String),
  bankName: t.maybe(t.String),
  currencyCode: t.maybe(t.String),
  numberRequiredSignatures: t.Number,
  canEnrollAch: t.maybe(t.Boolean),
  isNiclWhitelisted: t.maybe(t.Boolean),
  routingNumber: t.maybe(t.String),

  // old flag-based statuses
  statuses: t.list(BankAccountStatus),

  // newer simplified status string
  status: t.maybe(t.String),

  availableActions: t.list(t.String),

  // whether or not this bank account will require approval from someone else if used for payment
  isAdditionalApprovalRequired: t.maybe(t.Boolean),

  // whether or not this account has a signature on it at all (at least 1)
  hasSignature: t.maybe(t.Boolean)
};

const BankAccount = defineSchema(BankAccountModel, 'BankAccount');

const EditBankAccountAction = t.enums({
  [BankAccountActions.Reset]: '',
  [BankAccountActions.Disable]: '',
  [BankAccountActions.Enable]: '',
  [BankAccountActions.Delete]: ''
}, 'EditBankAccountAction');

const EditBankAccount = defineSchema({
  id: t.String,
  accountName: t.maybe(t.String),
  accountNumber: t.maybe(t.String),
  availableActions: t.maybe(t.list(EditBankAccountAction)),
  bankName: t.maybe(t.String),
  nextCheckNumber: t.maybe(t.String),
  routingNumber: t.maybe(t.String),
  signatureImage1: t.maybe(t.String),
  authorizedSigner: t.maybe(t.String),
  authorizedSigner2: t.maybe(t.String),
  signatureType: t.maybe(t.Number),
  numberRequiredSignatures: t.maybe(t.Number)
}, 'EditBankAccount');

const AuthorizedSigner = t.struct({
  authorizedSigner: t.String,
  signature: t.String
}, 'AuthorizedSigner');

const AuthorizedSignerTin = t.struct({
  authorizedSigner: t.String,
  taxPayerId: createBoundedDigitOnlyNonSequentialId(9),
  signature: t.String
}, 'AuthorizedSigner');

export const createPlaidAccountForm = bankAccounts => t.struct({
  accountName: createUniqueStringType(bankAccounts, {
    errorMessage: Messages.BankAccountNameTaken
  })
});

const createEditBankAccountForm = (bankAccounts, isReceiveOnly) => {
  const baseEditForm = t.struct({
    accountName: createUniqueStringType(bankAccounts),
    routingNumber: t.String,
    accountNumber: t.String
  });

  return isReceiveOnly ? baseEditForm : baseEditForm.extend({
    nextCheckNumber: t.String,
    authorizedSigner: isEnabled('allowMissingSigner') ? t.maybe(t.String) : createMaxLengthStringType(50),
    authorizedSigner2: t.maybe(createMaxLengthStringType(50))
  });
};

const PaymentsToBeSent = defineSchema({
  checkNumber: t.maybe(t.String),
  approverName: t.String,
  paymentAmount: t.Number,
  vendorName: t.String
}, 'PaymentsToBeSent');

const VerifyMicroDeposits = t.struct({
  depositAmount1: createMaxDigitsNumberType(2),
  depositAmount2: createMaxDigitsNumberType(2)
}, 'VerifyMicroDeposits');

const SignatureEnums = t.enums({
  mobile: 'Sign by Phone',
  draw: 'Draw'
});

const AddSignatureMethod = t.struct({
  signatureMethod: SignatureEnums
});

export const PaymentMethod = t.subtype(t.String, (() => true), 'PaymentMethod');

const getPaymentMethodValidation = (account) => {
  const {
    canUseCreditCard,
    hasSignature,
    isCreditCard,
    isSendingPayment,
    isWhitelistedToUseUnverfiedAccount
  } = account;
  // Ignore everything else if its a credit card
  if (isCreditCard) {
    return !!canUseCreditCard
      ? null
      : 'CannotUseCreditCard';
  }

  if (isSendingPayment && !hasSignature) {
    return 'NeedsSignature';
  }

  if (isVerifiableAccount(account) && !isWhitelistedToUseUnverfiedAccount) {
    return 'NeedsToVerify';
  }

  if (!isValidAccount(account, {
    includeVerifiable: true,
    isRecipientWhitelisted: isWhitelistedToUseUnverfiedAccount
  })) {
    return 'InvalidAccount';
  }
};

export const PaymentMethodV2 = t.subtype(
  t.struct({
    ...BankAccountModel,
    canUseCreditCard: t.maybe(t.Boolean),
    // Because of canUseCreditCard, this might be null now
    id: t.maybe(t.String),
    isCreditCard: t.maybe(t.Boolean),
    isSendingPayment: t.maybe(t.Boolean),
    isWhitelistedToUseUnverfiedAccount: t.maybe(t.Boolean)
  }),
  val => !getPaymentMethodValidation(val),
  'PaymentMethodV2'
);

PaymentMethodV2.getValidationErrorMessage = (value, path, context) => {

  if (!(context || {}).intl) return null;

  if (!value || (!value.id && !value.isCreditCard)) {
    return context.intl.formatMessage(Messages.SelectAccount);
  }

  const errorCode = getPaymentMethodValidation(value);

  if (!errorCode) return null;

  if (errorCode === 'InvalidAccount') {
    return context.intl.formatMessage(Messages.InvalidAccount);
  }
};

const createEnrollInACHForm = (requireTIN, requireBusinessType) => {
  let struct = {
    phone: Phone,
    yearOfIncorporation: createYearRange(),
    industryId: IndustryType,
    bankAccount: PaymentMethod
  };

  if (requireTIN) {
    struct.taxPayerId = createBoundedDigitOnlyNonSequentialId(9);
  } else {
    struct.taxPayerId = t.maybe(t.String);
  }

  if (requireBusinessType) {
    struct.businessType = BusinessTypeForForm;
  }

  return t.struct(struct, 'EnrollInACHForm');
};

export {
  AuthorizedSigner,
  AuthorizedSignerTin,
  BankAccountStatus,
  BankAccount,
  EditBankAccount,
  createEditBankAccountForm,
  PaymentsToBeSent,
  VerifyMicroDeposits,
  AddSignatureMethod,
  createEnrollInACHForm
};
