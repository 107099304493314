/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React from 'react';
import { FormattedMessage } from 'react-intl';

import ErnieImage from 'images/ernie/shrugging.png';
import Messages from './index.messages';

const InvalidTokenInline = () => (
  <div style={{textAlign: 'center'}}>
    <h1>
      <FormattedMessage {...Messages.Header}/>
    </h1>

    <img className="ernie" src={ErnieImage} />

    <p>
      <FormattedMessage {...Messages.Message}/>
    </p>
  </div>
);

export default InvalidTokenInline;
