/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, { Children, Component } from 'react';
import classnames from 'classnames';
import { isNil } from 'lodash';
import ListDropdownOption from '../ListDropdownOption';
import createDropdown from '../createDropdown';
import './index.scss';

/**
 * The innards for a dropdown that applies our list style
 */
export const ListDropdownPanel = ({
  header,
  footer,
  rightHeader,
  activeId,
  isMobile,
  onMouseEnter,
  onMouseLeave,
  children,
  className,
  closeMenu,
  closeMenuOnClick
}) => (
  <div className={classnames(
    isMobile ? 'dropdown-list-mobile' : 'dropdown-list-popover',
    {'has-header': !!header},
    className)}>
    {header ? (
      <ListDropdownOption isMobile={isMobile} type="header">
        {header}
        {isMobile && rightHeader ? (
          <span className="dropdown-list-item-right">
            {rightHeader}
          </span>
        ) : null}
      </ListDropdownOption>
    ) : null}
    {Children.map(children, (child, index) => {
      if (!child) return null;

      let actuallyCloseMenuOnClick = closeMenuOnClick
        && (isNil(child.props.closeMenuOnClick) || child.props.closeMenuOnClick);
      let onClick = actuallyCloseMenuOnClick ? () => {
        closeMenu();
        if (child.props.onClick) child.props.onClick();
      } : child.props.onClick;
      let childOnMouseEnter = onMouseEnter && child.props.help
        ? () => onMouseEnter(child.props.help)
        : null;
      let childOnMouseLeave = onMouseLeave
        ? () => onMouseLeave()
        : null;

      return (
        <ListDropdownOption key={child.props.id || index}
          analyticsId={child.props.id}
          isMobile={isMobile}
          isActive={child.props.isActive || activeId && child.props.id === activeId}
          isDisabled={child.props.isDisabled}
          onClick={onClick}
          link={child.props.link}
          linkProps={child.props.linkProps}
          path={child.props.path}
          rightContent={child.props.rightContent}
          type={child.props.type}
          onMouseEnter={childOnMouseEnter}
          onMouseLeave={childOnMouseLeave}
          help={child.props.help}
        >
          {child.props.children}
        </ListDropdownOption>
      );
    })}
    {footer ? (
      <div style={{borderTop: '1px solid #ddd', backgroundColor: '#f7f7f7', padding: '2px 5px'}}>
        {footer}
      </div>
    ) : null}
  </div>
);

ListDropdownPanel.defaultProps = {
  closeMenuOnClick: true
};

export class ListDropdownPanelWrapper extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.shown && !this.state.shown && !this.clearTimer) {
      this.clearTimer = setTimeout(
        () => {
          this.setState({help: null}); // eslint-disable-line react/no-did-update-set-state
          this.clearTimer = null;
        }, 800);
    } else if (this.clearTimer && !prevState.shown && this.state.shown) {
      clearTimeout(this.clearTimer);
      this.clearTimer = null;
    }
  }

  render() {
    let helpComponent = null;

    if (!this.props.isMobile && this.state.help) {
      helpComponent = (
        <div
          className={classnames(
            'dropdown-list-hover-tooltip',
            {'ignoreAnimation': this.props.ignoreHelpAnimation},
            {'closed': !this.state.shown}
          )}
          onMouseEnter={() => this.setState({shown: true})}
          onMouseLeave={() => this.setState({shown: false})}
        >
          {this.state.help}
        </div>
      );
    }

    return (
      <div className={classnames('dropdown-list-container', this.props.className)}>
        <ListDropdownPanel {...this.props}
          className="dropdown-list-panel"
          onMouseEnter={help => this.setState({help, shown: true})}
          onMouseLeave={() => this.setState({shown: false})} />
        {!this.props.isMobile && this.props.rightContent ? (
          <div className="dropdown-list-right-content">
            {this.props.rightContent}
          </div>
        ) : null}
        {helpComponent}
      </div>
    );
  }
}

const createListDropdown = (ToggleComponent, dropdownParams = {}) => createDropdown(ToggleComponent, {
  ...dropdownParams,
  childComponent: ListDropdownPanelWrapper,
  childPropNames: [
    'header',
    'footer',
    'rightHeader',
    'rightContent',
    'activeId',
    'isLoading',
    'closeMenu',
    'closeMenuOnClick',
    'ignoreHelpAnimation'
  ]
});

export default createListDropdown;
