/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */
/* eslint max-len:0 */

import { toMessages } from 'config/messages';

export default toMessages('Navigation.TasksMenu', {
  ConnectionRequestItemHeader: 'Connection Request',
  PaymentApprovalsItemHeader: 'Payment Approvals',
  TaskItemHeader: 'Task',
  TaskPrimaryAction: 'Go',
  TaskDismissAction: 'Ignore',
  NoTasksHeader: 'Nothing to do here',
  NoTasksSubheader: 'but a celebratory fist pump',
  ViewAllTasks: 'View All Tasks ({ count, number })'
});
