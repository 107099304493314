/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import t from 'tcomb-validation';
import { defineSchema } from 'schemas';
import { convertObjectToValueArray } from 'schemas/utils';
import { PaymentMethodType } from 'config/constants';

export const PaymentTrackerStepType = t.enums({
  Unknown: 'Unknown',
  Initiated: 'Initiated',
  BeingProcessed: 'BeingProcessed',
  TwoStepClearance: 'TwoStepClearance',
  EstimatedDelivery: 'EstimatedDelivery',
  PaperCheckSent: 'PaperCheckSent',
  PaymentPosted: 'PaymentPosted',
  DigitalSent: 'DigitalSent',
  BankError: 'BankError',
  ViewpostBankError: 'ViewpostBankError',

  Approved: 'Approved',
  Scheduled: 'Scheduled',
  Rejected: 'Rejected',
  Cancelled: 'Cancelled',

  VccIssued: 'VccIssued',
  VccPosted: 'VccPosted',
  VccBlocked: 'VccBlocked',
  VccFullyPaid: 'VccFullyPaid',
  VccExpired: 'VccExpired',
  VccPaymentSplit: 'VccPaymentSplit',
  VccEstimatedReroute: 'VccEstimatedReroute',
  VccEstimatedRelease: 'VccEstimatedRelease',
  VccAwaitingFundsFromProvider: 'VccAwaitingFundsFromProvider'
});

const PaymentTrackerStep = t.struct({
  date: t.Date,
  stepType: PaymentTrackerStepType,
  deliverByDate: t.maybe(t.Date),
  amount: t.maybe(t.Number),
  splitAmount: t.maybe(t.Number),
  isRefund: t.maybe(t.Boolean),
  referenceNumber: t.maybe(t.String),
  splitReferenceNumber: t.maybe(t.String),
  isVoid: t.maybe(t.Boolean),
  firstName: t.maybe(t.String),
  lastName: t.maybe(t.String),
  reason: t.maybe(t.String),
  paymentMethodType: t.maybe(t.enums.of(convertObjectToValueArray(PaymentMethodType))),
  splitPaymentMethodType: t.maybe(t.enums.of(convertObjectToValueArray(PaymentMethodType)))
}, 'PaymentTrackerStep');

export const PaymentTracker = defineSchema({
  steps: t.list(PaymentTrackerStep),
  paymentMethodType: t.maybe(t.enums.of(convertObjectToValueArray(PaymentMethodType))),
  masterInvoiceId: t.maybe(t.String)
}, 'PaymentTracker');
