/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, { useMemo, useRef, useState } from 'react';
import t from 'tcomb-validation';

import Button from 'components/Button';
import FormV2, { FieldInput, FieldOption } from 'components/Form/V2';
import { SmallBannerNotification, NotificationStyleTypes } from 'components/Notification';

import CommonMessages from 'config/messages/common';
import { preventDefault } from 'config/utils/click';

import FloatingAlert from 'containers/Logon/FloatingAlert';

import Messages from './index.messages';

const SecurityQuestionPrompt = ({
  error,
  isSubmitting,
  onSubmit: onSubmitProp,
  securityQuestions
}) => {
  // Hooks
  const formRef = useRef();

  const [ formState, setFormState ] = useState({});

  const modelType = useMemo(
    () => {
      const structSchema = {};

      for (let i = 0; i < securityQuestions.length; i += 1) {
        structSchema[`securityQuestion${i + 1}`] = t.String;
      }

      return t.struct(structSchema, 'SecurityQuestionFormPrompt');
    },
    [ securityQuestions.length ]
  );

  const onSubmit = useMemo(
    () => preventDefault(
      () => {
        const value = formRef.current.getValue();

        if (value) {
          onSubmitProp(
            Object.values(value).map(
              (answer, index) => ({
                questionId: securityQuestions[index].id,
                answer
              })
            )
          );
        }
      }
    ),
    [ onSubmitProp, securityQuestions ]
  );

  // Render
  let alert = null;

  if (!isSubmitting) {
    if (error === 'InvalidAnswer') {
      alert = (
        <SmallBannerNotification
          type={NotificationStyleTypes.Warning}
          shakeOnEnter={true}
        >
          <Messages.IncorrectAnswer.Message />
        </SmallBannerNotification>
      );
    }
  }

  return (
    <form>
      {alert ? (
        <FloatingAlert>
          {alert}
        </FloatingAlert>
      ) : null}
      <Messages.Instructions.Message />
      <FormV2
        ref={formRef}
        modelType={modelType}
        onChange={setFormState}
        value={formState}
      >
        {securityQuestions.map(
          ({ question }, index) => (
            <FieldOption
              key={question}
              label={question}
              name={`securityQuestion${index + 1}`}
            >
              <FieldInput type="password" />
            </FieldOption>
          )
        )}
      </FormV2>
      <Button
        type="submit"
        isDisabled={isSubmitting}
        isProcessing={isSubmitting}
        onClick={onSubmit}
        expand={true}
      >
        <CommonMessages.Continue.Message />
      </Button>
      <p className="vp-footer pad-y">
        <Messages.FooterText.Message />
      </p>
    </form>
  );
};

export default SecurityQuestionPrompt;