/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { newState } from './utils';

export default function selectCompany(state = {}, action) {

  if (action.type === 'PopupDrawer_open') {
    return newState(state, {
      isOpen: true,
      params: action.params
    });
  }

  if (action.type === 'PopupDrawer_close') {
    return newState(state, {
      isOpen: false,
      params: {}
    });
  }

  return state;
}
