/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */
/* eslint max-len:0 */

import { toMessages } from 'config/messages';

export default toMessages('VerifyPaymentAccountOnboarding', {
  Header: 'Verify Account: XX-{accountNumber}',
  Instructions: 'Please enter the {b}exact{b} amounts that Viewpost deposited to your account.',
  DepositOne: 'Deposit 1',
  DepositTwo: 'Deposit 2',
  Placeholder: '00',
  IncorrectMicroDeposit: 'One or both of the micro-deposit amounts you entered is incorrect. Please enter the correct value.',
  SuccessHeader: 'Your account has been verified. You can now start receiving electronic payments.',
  FailureHeader: 'This account is locked because of failed verification attempts. Please contact Customer Support to get this account unlocked.'
});
