/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

const transformValue = (value, {
  lowerCaseValue,
  lowerCaseFirstChar
}) => {
  if (lowerCaseValue) return value.toLowerCase();
  if (lowerCaseFirstChar) return `${value.charAt(0).toLowerCase()}${value.substr(1)}`;

  return value;
};

/**
 * Converts a string array to an object with the values mirroring the keys
 * e.g., ['a', 'b'] -> { a: 'a', b: 'b' }
 *
 * Use this to create enumerations easily which can then easily be use for references
 * e.g., MyEnum.a = 'a'
 */
export const toEnumObject = (array, config) => array.reduce((v, i) => ({
  ...v,
  [i]: transformValue(i, config || {})
}), {});
