/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */
/* eslint max-len:0 */

import { toMessages } from 'config/messages';

export default toMessages('LogonView.Workflow', {
  ExpiredText1: 'Your session expired due to inactivity.',
  ExpiredText2: 'Please log in again to continue using Viewpost.',
  ExpiredText3: 'For your security, always log out of the application when finished. If you suspect unauthorized access to your account, please contact Customer Support at {email}.',
  VerifyAccount: 'Sign in to verify your account',
  SessionKicked: 'Someone has logged into Viewpost using your credentials, which closed your session. If you believe the other session is not authorized, please contact Customer Support at 888-248-9190',
  VerifyChangeEmail: 'Log into your account with your new email address to verify it.',
  CompanyClosed: 'Your Viewpost account has been closed. If you change your mind and decide to return to Viewpost, we’d be more than happy to have you! If so, please contact Customer Support at support@viewpost.com.',
  OnAppRoute: 'Sign in to continue to the page you want to access.'
});
