/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

export const API_ACTION_PREFIX = 'API@';
export const API_ACTION_SUFFIX_FETCH = '_fetch';
export const API_ACTION_SUFFIX_SUCCESS = '_success';
export const API_ACTION_SUFFIX_FAIL = '_fail';
export const ENTITY_ACTION_PREFIX = 'ENTITY@';

// This can optionally support matching against a specific apiAction
const isActionOfState = suffix => (action, apiAction) => {
  if (!action || !action.type) return false;
  if (apiAction) {
    return suffix
      ? action.type === `${API_ACTION_PREFIX}${apiAction}${suffix}`
      : action.type.startsWith(`${API_ACTION_PREFIX}${apiAction}`);
  }

  return action.type.startsWith(API_ACTION_PREFIX)
    && (!suffix || action.type.endsWith(suffix));
};

export const isApiAction = isActionOfState();

export const isFetchAction = isActionOfState(API_ACTION_SUFFIX_FETCH);

export const isSuccessAction = isActionOfState(API_ACTION_SUFFIX_SUCCESS);

export const isFailAction = isActionOfState(API_ACTION_SUFFIX_FAIL);

export const isEntityAction = action => action
  && action.type.startsWith(ENTITY_ACTION_PREFIX);