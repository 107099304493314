/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, { forwardRef, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { cloneDeep, difference, get, pick, set } from 'lodash';
import { useIntl } from 'react-intl';
import t from 'tcomb-validation';

import '../Containers/FieldLayout.scss';
import FormContext, { FormStateContext } from './context';
import FieldOption, { FieldInput } from './FieldOption';
import IfFieldDefined from './IfFieldDefined';
import { getActualType, getErrorMessage, getKind } from './utils';

export { FieldOption, FieldInput, IfFieldDefined };

const typeToPathMap = (type) => {
  if (!(type || {}).meta) return null;

  const actualType = getActualType(type);
  const kind = getKind(actualType);
  if (kind !== 'struct') {
    if (kind === 'list') {
      return {
        '': type,
        '[]': actualType.meta.type
      };
    }

    return null;
  }

  const fieldMap = {};
  Object.keys(actualType.meta.props).forEach((key) => {
    const childType = actualType.meta.props[key];
    const childMap = typeToPathMap(childType);

    fieldMap[key] = childType;
    if (childMap) {
      Object.keys(childMap).forEach((childKey) => {
        const infix = childKey && childKey !== '' && childKey !== '[]' ? '.' : '';
        fieldMap[`${key}${infix}${childKey}`] = childMap[childKey];
      });
    }
  });

  return fieldMap;
};

export function createOnClassComponentChange(fieldName) {
  return function onChange(updateFunc) {
    this.setState((prevState) => {
      return {
        [fieldName]: {
          ...(prevState[fieldName] || {}),
          ...updateFunc(prevState[fieldName] || {})
        }
      };
    });
  };
}

const FormV2 = (
  {
    children,
    disabled,
    // nil or 'flex'
    display,
    formTag,
    highlightRequired,
    modelType: type,
    // This will be called with the (prevState) => newState style function for state updates. There
    // are a lot of components that can call this now between renders and that is the only way to
    // ensure no state updates are eaten. Similarly, anything that is updating the form state, that
    // isn't the form, should also be using (prevState) => newState for the same reason.
    // If you are using the useState hook, it's as simple as passing the 'setState' function from
    // the hook to onChange.
    // If you are using this from a class component, you will need to map the result to the correct
    // field, which you can facilitate in the constructor with the helper function above:
    //   e.g., this.onChange = createOnClassComponentChange('myFormState').bind(this);
    // Then you can pass this.onChange directly into onChange:
    //   onChange={this.onChange}
    //   value={this.state.myFormState}
    // This overall is actually a net gain, especially when using the useState hook, because we are
    // now, more often than not, going to pass something directly to a prop (no need to create an
    // intermediate function like we often have done) and this will also be a stable reference, so
    // future efforts to cut out renders if props don't change won't be sabotaged by this prop.
    onChange,
    value,
    variant
  },
  ref
) => {
  // Hooks
  const intl = useIntl();
  const fieldsRef = useRef({});
  const [ unhandledFieldErrors, setUnhandledFieldErrors ] = useState([]);

  const paths = useMemo(
    () => ({
      '': type,
      ...typeToPathMap(type)
    }),
    [ type ]
  );

  const validate = () => {
    const result = t.validate(value, type, { context: { intl } });

    if (result.errors) {
      const errorPaths = result.errors.reduce(
        (val, { path }) => ([
          ...val,
          path.join('.').replace(/\.[0-9]+/, x => `[${x.substr(1)}]`)
        ]),
        []
      );

      let unhandled = [ ...errorPaths ];

      for (const fieldId of Object.keys(fieldsRef.current)) {
        const { onError } = fieldsRef.current[fieldId];
        const handled = onError?.(errorPaths) || [];
        unhandled = difference(unhandled, handled);
      }

      setUnhandledFieldErrors(unhandled);
    }

    return result;
  };

  if (!value) {
    throw new Error('No value was passed to the form!');
  }

  useImperativeHandle(
    ref,
    () => ({
      getValue: () => {
        const result = validate();
        return result.isValid() ? result.value : null;
      },
      validate
    })
  );

  const registerField = useMemo(
    () => (
      id,
      options
    ) => {
      fieldsRef.current[id] = options;

      // Return a function to call when the field is being removed so it can
      // unsubscribe from future events.
      return () => {
        delete fieldsRef.current[id];
      };
    },
    []
  );

  const onFieldChange = useMemo(
    () => (name, v) => {
      onChange((prevState) => {
        const newState = cloneDeep(prevState);
        set(newState, name, v);
        return newState;
      });
    },
    [ onChange ]
  );

  // Render

  // TODO: Have all this work happen at the tail end of validation, whereas now
  // it's happening on each render.
  const getUnhandledErrors = () => {
    if (!unhandledFieldErrors.length) return [];

    const unhandledTypes = pick(paths, unhandledFieldErrors);

    return Object.keys(unhandledTypes).map(
      (key) => {
        if (!unhandledTypes[key]) return null;

        return getErrorMessage(
          unhandledTypes[key],
          key === '' ? value : get(value, key),
          intl
        );
      }
    ).filter(a => a);
  };

  const unhandledErrors = getUnhandledErrors();

  const Wrapper = useMemo(
    () => ({ children: wrapperChildren }) => {
      let className = null;
      if (formTag == null || !!formTag) {
        return (
          <form className={className}>
            {wrapperChildren}
          </form>
        );
      }

      return className ? (
        <div className={className}>
          {wrapperChildren}
        </div>
      ) : (
        <>
          {wrapperChildren}
        </>
      );
    },
    [ formTag ]
  );

  return (
    <FormContext.Provider
      value={{
        disabled,
        display,
        highlightRequired,
        onChange: onFieldChange,
        paths,
        register: registerField
      }}
    >
      <FormStateContext.Provider value={value}>
        <Wrapper>
          {children}
        </Wrapper>
        {unhandledErrors.length ? (
          <div className="vp-error-message">{unhandledErrors.join(', ')}</div>
        ) : null}
      </FormStateContext.Provider>
    </FormContext.Provider>
  );
};

export default forwardRef(FormV2);
