/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

export function loadDocumentsFromTokenResponseMapper(apiResponse) {
  let entities = [];
  let references = [];
  if (!apiResponse) {
    return {
      entities,
      references
    };
  }

  return {
    entities,
    references,
    documents: apiResponse
  };
}
