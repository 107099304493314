/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import NotificationActions from 'config/constants/NotificationActions';
import { NotificationStyleTypes, NotificationTypes } from 'components/Notification';

export { NotificationStyleTypes, NotificationTypes };

export function showNotification(options) {
  return (dispatch) => {
    dispatch({
      type: NotificationActions.Show,
      options
    });
  };
}

export function showSmallBanner(options) {
  return showNotification({
    ...options,
    notificationType: NotificationTypes.SmallBanner
  });
}

export function showToast(options) {
  return showNotification({
    ...options,
    notificationType: NotificationTypes.Toast
  });
}
