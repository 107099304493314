/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './native.scss';

export default class NativeSelect extends Component {
  static propTypes = {
    name: PropTypes.string,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    hasError: PropTypes.bool,
    disabled: PropTypes.bool,
    placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    value: PropTypes.string,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string.isRequired,
        disabled: PropTypes.bool,
        text: PropTypes.string.isRequired
      })
    )
  };

  static defaultProps = {
    disabled: false,
    hasError: false
  };

  getSelectClasses() {
    return classNames(
      'vp-input',
      {'error': this.props.hasError}
    );
  }

  render() {
    const { name, disabled, onChange, value, options } = this.props;

    const listOptions = options.map((option) => {
      return (
        <option key={`select_options_${option.value}`}
          value={option.value}
          hidden={option.hidden}
          disabled={option.disabled}>
          {option.text}
        </option>
      );
    });

    return (
      <select name={name}
        disabled={disabled}
        onChange={onChange}
        onFocus={this.props.onFocus}
        onBlur={this.props.onBlur}
        value={value}
        // this padding is needed so the options do not overlap the dropdown arrow
        style={{paddingRight: 30}}
        className={this.getSelectClasses()}>
        {listOptions}
      </select>
    );
  }
}
