/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import t from 'tcomb-validation';

import { defineSchema } from 'schemas';
import { AvailableActionsState } from 'schemas/common/availableActions';
import PaymentMethodTypes from 'schemas/payments/PaymentMethodTypes';

export const PaymentOptions = t.list(PaymentMethodTypes);

export const PaymentMethods = t.list(t.struct({
  displayType: t.maybe(t.String),
  status: t.maybe(t.String),
  fee: t.maybe(t.Number),
  feeType: t.maybe(t.String),
  isPending: t.maybe(t.Boolean),
  sourceCurrency: t.maybe(t.String)
}));

export const PendingConnection = t.struct({
  receivedConnectionRequestId: t.maybe(t.String),
  sentConnectionRequestId: t.maybe(t.String),
  lastConnectionRequestNotifyDate: t.maybe(t.Date),
  targetCompanyId: t.maybe(t.String),
  targetCompanyName: t.maybe(t.String),
  lastInvitationNotifyDate: t.maybe(t.Date)
});

export const NetworkListEntry = defineSchema(
  {
    id: t.String,
    uri: t.maybe(t.String),
    targetCompanyId: t.maybe(t.String),
    name: t.String,
    registeredName: t.maybe(t.String),
    isVerified: t.maybe(t.Boolean),
    contactEmail: t.maybe(t.String),
    contactFirstName: t.maybe(t.String),
    contactLastName: t.maybe(t.String),
    phone: t.maybe(t.String),
    numberOfAccounts: t.maybe(t.Number),
    paymentMethods: t.maybe(PaymentMethods),
    address: t.Object,
    lastPaymentAddress: t.maybe(t.Object),
    connectionStatus: t.String,
    resultType: t.String,
    clientReferenceId: t.maybe(t.String),
    ownerCompanyId: t.String,
    ownerCompanyName: t.String,
    isIndividual: t.Bool,
    hasPaymentDisposition: t.Bool,
    achPlusFeePercent: t.maybe(t.Number),
    achPlusFeeAmount: t.maybe(t.Number),
    pendingConnection: PendingConnection,
    availableActionsState: AvailableActionsState
  },
  'NetworkListEntry'
);
