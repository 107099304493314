/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */
/* eslint max-len:0 */

import { defineMessages } from 'react-intl';
import { InvoiceActions } from 'config/constants';

export const ActionDescription = defineMessages({
  [InvoiceActions.Approve]: {
    id: 'ActionTooltip.action.Approve',
    defaultMessage: `Move bill along to be paid.`
  },
  [InvoiceActions.ApproveAndQuickPay]: {
    id: 'ActionTooltip.action.ApproveAndQuickPay',
    defaultMessage: `Pay this bill in 1 step.`
  },
  [InvoiceActions.QuickPay]: {
    id: 'ActionTooltip.action.Quickpay',
    defaultMessage: `Pay this bill in 1 step.`
  },
  [InvoiceActions.ApproveAndPaymentQueue]: {
    id: 'ActionTooltip.action.ApproveAndPaymentQueue',
    defaultMessage: `Add this bill to your payment queue and pay all at once.`
  },
  [InvoiceActions.AddToPaymentQueue]: {
    id: 'ActionTooltip.action.AddToPaymentQueue',
    defaultMessage: `Add this bill to your payment queue and pay all at once.`
  },
  [InvoiceActions.Copy]: {
    id: 'ActionTooltip.action.Copy',
    defaultMessage: `Create a new invoice/bill with the info from this one.`
  },
  [InvoiceActions.Delete]: {
    id: 'ActionTooltip.action.Delete',
    defaultMessage: `Delete this draft invoice/bill.`
  },
  [InvoiceActions.Dispute]: {
    id: 'ActionTooltip.action.Dispute',
    defaultMessage: `Let trading partners know that you disagree.`
  },
  [InvoiceActions.EndDispute]: {
    id: 'ActionTooltip.action.EndDispute',
    defaultMessage: `Let trading partners know that you no longer disagree.`
  },
  [InvoiceActions.Edit]: {
    id: 'ActionTooltip.action.Edit',
    defaultMessage: `Go back and edit the invoice/bill.`
  },
  [InvoiceActions.Link]: {
    id: 'ActionTooltip.action.Link',
    defaultMessage: `Manually associate this invoice/bill with your trading partner's invoice/bill.`
  },
  [InvoiceActions.Unlink]: {
    id: 'ActionTooltip.action.Unlink',
    defaultMessage: `Unassociate this invoice/bill from your trading partner's invoice/bill.`
  },
  [InvoiceActions.RecordPayment]: {
    id: 'ActionTooltip.action.RecordPayment',
    defaultMessage: `Enter payment details for a payment sent or received outside of Viewpost (Cash, check, credit card).`
  },
  [InvoiceActions.Reject]: {
    id: 'ActionTooltip.action.Reject',
    defaultMessage: `Inform your vendor and remove the bill from your list.`
  },
  [InvoiceActions.RouteForApproval]: {
    id: 'ActionTooltip.action.RouteForApproval',
    defaultMessage: `Request to get this bill approved by others.`
  },
  [InvoiceActions.RespondToApprovalRequest]: {
    id: 'ActionTooltip.action.RespondToApprovalRequest',
    defaultMessage: `Action the approval request.`
  },
  [InvoiceActions.Send]: {
    id: 'ActionTooltip.action.Send',
    defaultMessage: `Finalize and send this invoice/bill.`
  },
  [InvoiceActions.SendAndQuickPay]: {
    id: 'ActionTooltip.action.Send',
    defaultMessage: `Finalize and send this invoice/bill.`
  },
  [InvoiceActions.SaveAsPdf]: {
    id: 'ActionTooltip.action.SaveAsPdf',
    defaultMessage: `Download this as a PDF.`
  },
  [InvoiceActions.SendToPrinter]: {
    id: 'ActionTooltip.action.SendToPrinter',
    defaultMessage: `Print this invoice/bill.`
  },
  [InvoiceActions.Share]: {
    id: 'ActionTooltip.action.Share',
    defaultMessage: `Email a PDF copy of this invoice/bill to any email address.`
  },
  [InvoiceActions.Void]: {
    id: 'ActionTooltip.action.Void',
    defaultMessage: `Void and close this invoice/bill.`
  },
  [InvoiceActions.Unvoid]: {
    id: 'ActionTooltip.action.Unvoid',
    defaultMessage: `Unvoid and reopen this invoice/bill.`
  },
  [InvoiceActions.VpxRequest]: {
    id: 'ActionTooltip.action.VpxRequest',
    defaultMessage: `Offer a discount for early payment.`
  },
  [InvoiceActions.VpxCancel]: {
    id: 'ActionTooltip.action.VpxCancel',
    defaultMessage: `Cancel the discount for early payment.`
  },
  [InvoiceActions.SendReminder]: {
    id: 'ActionTooltip.action.SendReminder',
    defaultMessage: `Send your customer a reminder email to pay this invoice`
  },
  // For Consumer
  [InvoiceActions.SetUpAutoPay]: {
    id: 'ActionTooltip.action.SetUpAutoPay',
    defaultMessage: `Set up Auto Pay for the vendor who sent you this bill.`
  },
  [InvoiceActions.MarkAsPaid]: {
    id: 'ActionTooltip.action.MarkAsPaid',
    defaultMessage: `Close this bill and mark it as having been paid.`
  },
  [InvoiceActions.Comment]: {
    id: 'ActionTooltip.action.Comment',
    defaultMessage: `Comment on this invoice/bill`
  },
  [InvoiceActions.GetInvoiceInsuranceQuote]: {
    id: 'ActionTooltip.action.GetInvoiceInsuranceQuote',
    defaultMessage: `Get invoice insurance and guarantee payment on your invoices, minimize the risk of sudden or unexpected customer insolvency, and enjoy Euler Hermes’ easy claims process.`
  }
});
