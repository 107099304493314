/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { useRef } from 'react';
import reactFastIsEqual from 'react-fast-compare';
import shallowEqual from 'shallowequal';

// This is not related to useRef. This asserts, given a val, that if the
// previous value and the current value are equal, using a shallow comparison,
// that the same reference is returned. This is useful in cases where you have a
// complex datatype (array, object) that is a dependency/input to a something
// that, if it sees the inputs haven't changed via simple check (e.g., ===),
// then it can shortcircuit execution, this faciliates that.
const useStableRef = (val, { comparison: originalComparison } = {}) => {
  // Hooks
  const stableRef = useRef(val);

  const comparison = originalComparison == null
    ? 'shallowequal'
    : originalComparison;

  switch (comparison) {
    case 'shallowequal':
      if (!shallowEqual(val, stableRef.current)) {
        stableRef.current = val;
      }
      break;
    case 'react-fast-compare':
      if (!reactFastIsEqual(val, stableRef.current)) {
        stableRef.current = val;
      }
      break;
    default:
  }

  // Action
  return stableRef.current;
};

export default useStableRef;