/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';
import ProvideIsMobile from 'decorators/ProvideIsMobile';
import classNames from 'classnames';
import './index.scss';

/**
 * This is the underlying Modal implementation, not to be used directly!
 */
export class BaseModal extends Component {
  static propTypes = {
    /** Managed by the Modal Service. This is if the modal is visible or not. */
    isOpen: PropTypes.bool,
    /** Managed by the Modal Service. How long the modal open/close animation takes. */
    closeTimeoutMS: PropTypes.number,
    /** Can be passed via modalParams. Sets the modal to the 'wide' width. */
    wide: PropTypes.bool,
    /** Can be passed via modalParams. Sets the modal to the 'skinny' width. */
    skinny: PropTypes.bool,
    hidden: PropTypes.bool
  };

  static defaultProps = {
    isOpen: false
  };

  render() {
    let classes = classNames('react-modal', {
      'mobile': this.props.isMobile,
      'wide': this.props.wide,
      'extra-wide': this.props.extraWide,
      'full': this.props.full,
      'skinny': this.props.skinny
    });

    return (
      <ReactModal isOpen={this.props.isOpen}
        closeTimeoutMS={this.props.closeTimeoutMS}
        className={classes}
        overlayClassName={`${this.props.hidden ? 'hidden' : ''}`}>
        <div className="vp-modal" style={this.props.style}>
          <div className="viewstrap">
            {this.props.children}
          </div>
        </div>
      </ReactModal>
    );
  }
}

export default ProvideIsMobile(BaseModal);
