/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import uniqueId from 'lodash/uniqueId';

import NotificationActions from 'config/constants/NotificationActions';

export default function notifications(state = [], action) {
  if (action.type === NotificationActions.Show) {
    return [
      ...state,
      { ...action.options, id: uniqueId() }
    ];
  }

  if (action.type === NotificationActions.Hide) {
    return state.filter(({ id }) => id !== action.id);
  }

  if (action.type === NotificationActions.ClearAll) {
    return [];
  }

  return state;
}
