/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import createApiActions from 'api/core';

export default createApiActions({
  submitCampaignVccInterest: {
    method: 'post',
    url: '/api/token/:secureToken/submitCampaignVccInterest',
    requestBodyMapper: r => r
  }
});
