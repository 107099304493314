/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */
/* eslint max-len:0 */

import { toMessages } from 'config/messages';

export default toMessages('DelinquentAccountSpeedBump', {
  Header: 'Subscription Setup',
  Subheader: 'Please enter your credit card information below.',
  RiskFree: '<b>NO-RISK GUARANTEE</b>: If you are not satisfied, simply contact our support team within the next 30 days, and we’ll refund your first month’s subscription fee. {cancellationPolicy}',
  Submit: 'Submit Payment',
  OptOut: 'You may access your existing Viewpost account to view historical data without subscribing. However, functionality is limited and you cannot create new invoices or send and receive payments. Subscribing will unlock these features. {optOut}.',
  OptOutLink: 'View data only',
  ConfirmationHeader: 'Payment Confirmation',
  ConfirmationSubheader: 'A confirmation email has been sent to <b>{email}</b>.'
});
