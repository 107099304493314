/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, { Component } from 'react';
import classNames from 'classnames';

/**
 * Renders a section for a single item in a form list section.
 */
export default class ListItem extends Component {
  constructor(props) {
    super(props);
  }

  change(evt) {
    this.props.options.onChange(evt.target.value);
  }

  render() {
    return (
      <div className="vp-form-list-item">
        {this.props.heading ? <div className="vp-form-heading">{this.props.heading}</div> : null}

        {/*
            TODO: figure out how we can pass down the index-aware options we have here to the child
            so we can do thing like set placeholder for nth item??
        tcomb-forms builds the actual input/element content from the option/template configured */}
        {this.props.options.input}

        {/* tcomb-forms supports Remove, Up, Down buttons per list item */}
        <div className="vp-form-list-item-buttons">
          {this.props.options.buttons.map((buttonOptions) => {

            let buttonType = buttonOptions.label;

            if (!this.props.options.canRemove && buttonType === 'remove') {
              return null;
            }

            if (!this.props.options.canUp && buttonType === 'up') {
              return null;
            }

            if (!this.props.options.canDown && buttonType === 'down') {
              return null;
            }

            let classes = classNames('vp-button', `button-${buttonType}`);

            return (
              <button
                type="button"
                className={classes}
                onClick={buttonOptions.click}
                onTouchStart={buttonOptions.click}>
                {/* element we can use to dynamically set button 'content' via CSS:
              .button-content:before */}
                <div className="button-content">{buttonType}</div>
              </button>
            );
          })}
        </div>
      </div>
    );
  }
}
