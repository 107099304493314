/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React from 'react';
import Image from 'components/Viewstrap/Image';
import CityOneImage from 'public/images/backgrounds/city1.svg';
import CityTwoImage from 'public/images/backgrounds/city2.svg';
import CityThreeImage from 'public/images/backgrounds/city3.svg';
import ErnieWithBinocularsImage from 'public/images/ernie/with-binoculars.svg';
import ErnieFemaleWavingImage from 'public/images/ernie/female-waving.svg';
import ErnieHighfiveAnotherImage from 'public/images/ernie/highfive-another.svg';
import ErnieTangledUpImage from 'public/images/ernie/untangling-wires-red.svg';

export const CityOne = () => {
  return <Image image={{src: CityOneImage}} />;
};

export const CityTwo = () => {
  return <Image image={{src: CityTwoImage}} />;
};

export const CityThree = () => {
  return <Image image={{src: CityThreeImage}} />;
};

export const ErnieWithBinoculars = () => {
  return <Image image={{src: ErnieWithBinocularsImage}} />;
};

export const ErnieFemaleWaving = () => {
  return <Image image={{src: ErnieFemaleWavingImage}} />;
};

export const ErnieHighfiveAnother = () => {
  return <Image image={{src: ErnieHighfiveAnotherImage}} />;
};

export const ErnieTangledUp = () => {
  return <Image image={{src: ErnieTangledUpImage}} />;
};

export const AlignBackground = ({top, left, right, bottom, children}) => {
  return (
    <div style={{position: 'absolute', top, left, right, bottom}}>
      {children}
    </div>
  );
};
