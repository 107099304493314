/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import t from 'tcomb-validation';
import { defineSchema } from 'schemas';
import Email from 'schemas/common/email/Email';
import { PostalCode, USStatesAbbrv } from 'schemas/common/address';
import { Memo } from 'schemas/common/memo';

const QboAddress = t.struct({
  line1: t.String,
  line2: t.maybe(t.String),
  line3: t.maybe(t.String),
  city: t.String,
  stateProvince: USStatesAbbrv,
  postalCode: PostalCode
}, 'QboAddress');

const QBOBillingAddress = t.struct({
  contactName: t.maybe(t.String),
  line1: t.maybe(t.String),
  line2: t.maybe(t.String),
  line3: t.maybe(t.String),
  line4: t.maybe(t.String),
  city: t.maybe(t.String),
  stateProvince: t.maybe(t.String),
  postalCode: t.maybe(t.String),
  country: t.maybe(t.String)
}, 'QBOBillingAddress');


const QboAddressSoftValidation = t.struct({
  line1: t.maybe(t.String),
  line2: t.maybe(t.String),
  line3: t.maybe(t.String),
  line4: t.maybe(t.String),
  city: t.maybe(t.String),
  stateProvince: t.maybe(t.String),
  postalCode: t.maybe(t.String)
}, 'QboAddress');

const QBOTradingPartnerContact = t.struct({
  address: t.maybe(QBOBillingAddress),
  email: t.maybe(t.String),
  firstName: t.maybe(t.String),
  lastName: t.maybe(t.String),
  phone: t.maybe(t.String)
}, 'QBOTradingPartnerContact');


const QBOCompanies = defineSchema({
  id: t.String,
  expirationTimestamp: t.String
}, 'QBOCompanies');

const QBOLinkedCompany = defineSchema({
  id: t.String,
  name: t.String,
  expirationTimestamp: t.String
}, 'QBOLinkedCompany');

const CheckNumber = t.refinement(t.maybe(t.String), (checkNumber) => {
  return /^[0-9]{0,10}$/.test(checkNumber || '');
}, 'CheckNumber');

CheckNumber.getValidationErrorMessage = () => {
  return 'Check numbers must be numeric and 10 characters or less.';
};

const QBOPayments = defineSchema({
  id: t.String,
  amount: t.Number,
  contact: t.maybe(QBOTradingPartnerContact),
  currencyCode: t.maybe(t.String),
  paymentDate: t.String,
  vendorId: t.maybe(t.String),
  vendorName: t.maybe(t.String),
  toBePrinted: t.Boolean,
  emailAddress: t.maybe(t.String),
  vendorPhoneNumber: t.maybe(t.String),
  memo: t.maybe(t.String),
  paymentAccountId: t.String,
  paymentAccountName: t.String,
  paymentType: t.String,
  paymentMethodType: t.String,
  payToTheOrderOf: t.maybe(t.String),
  remittance: t.maybe(t.Array),
  referenceNumber: t.maybe(t.String),
  address: t.maybe(QboAddressSoftValidation),
  viewpostPaymentMethodId: t.maybe(t.String)
}, 'QBOPayments');

const QBOFormPayment = t.struct({
  id: t.String,
  amount: t.Number,
  contact: t.maybe(QBOTradingPartnerContact),
  currencyCode: t.maybe(t.String),
  paymentDate: t.String,
  vendorId: t.maybe(t.String),
  vendorName: t.maybe(t.String),
  toBePrinted: t.Boolean,
  emailAddress: t.maybe(t.String),
  vendorPhoneNumber: t.maybe(t.String),
  memo: t.maybe(t.String),
  paymentAccountId: t.String,
  paymentAccountName: t.String,
  paymentMethodType: t.String,
  paymentType: t.String,
  payToTheOrderOf: t.maybe(t.String),
  remittance: t.maybe(t.Array),
  referenceNumber: t.maybe(CheckNumber),
  address: t.maybe(QboAddressSoftValidation),
  viewpostPaymentMethodId: t.maybe(t.String)
}, 'QBOFormPayment');

const QBOInvoice = defineSchema({
  id: t.String,
  addressBookId: t.maybe(t.String),
  invoiceDate: t.maybe(t.String),
  dueDate: t.maybe(t.String),
  invoiceNumber: t.maybe(t.String),
  lineItems: t.list(t.Object),
  taxPercentage: t.maybe(t.Number),
  terms: t.maybe(t.String),
  customerId: t.maybe(t.String),
  customerName: t.maybe(t.String),
  notes: t.maybe(t.String),
  invoiceStatus: t.maybe(t.String),
  emailAddress: t.maybe(t.String),
  customerPhoneNumber: t.maybe(t.String),
  billToAddress: t.maybe(QBOBillingAddress),
  shipToAddress: t.maybe(QboAddressSoftValidation),
  project: t.maybe(t.String),
  balance: t.Number,
  totalAmount: t.Number,
  subTotal: t.Number,
  taxAmount: t.Number
}, 'QBOInvoice');

// Form fields for editing payment details
const QBOFormAddress = QboAddress;
const QBOFormPayTo = t.struct({
  payToTheOrderOf: t.String
}, 'QBOFormPayTo');
const QBOFormEmail = t.struct({
  emailAddress: Email
}, 'QBOFormEmail');
const QBOFormMemo = t.struct({
  memo: Memo
}, 'QBOFormMemo');
const QBOFormCheckNumber = t.struct({
  checkNumber: CheckNumber
}, 'QBOFormCheckNumber');

const QBOCustomerLink = t.struct({
  clientReferenceId: t.String,
  viewpostId: t.String
}, 'QBOCustomerLink');

const QBOAccountLink = t.struct({
  clientReferenceId: t.String,
  viewpostId: t.String
}, 'QBOAccountLink');

const QBOPastPayment = t.struct({
  id: t.String
}, 'QBOPastPayment');

const QBOPastInvoice = t.struct({
  id: t.String
}, 'QBOPastInvoice');

const QBOConnection = t.struct({
  name: t.String,
  date: t.String
}, 'QBOConnection');

export {
  QboAddress,
  QboAddressSoftValidation,
  QBOCompanies,
  QBOLinkedCompany,
  QBOPayments,
  QBOInvoice,
  QBOFormPayment,
  QBOFormAddress,
  QBOFormPayTo,
  QBOFormEmail,
  QBOFormMemo,
  CheckNumber,
  QBOFormCheckNumber,
  QBOAccountLink,
  QBOPastPayment,
  QBOPastInvoice,
  QBOConnection,
  QBOCustomerLink,
  QBOTradingPartnerContact
};
