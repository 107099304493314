/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import t from 'tcomb-validation';
import { defineSchema } from 'schemas';
import Email from 'schemas/common/email/Email';
import { Phone } from 'schemas/common/phone';
import { createMaxLengthStringType } from 'schemas/common/string';
import {
  RetrievableAddress,
  Address
} from 'schemas/common/address';
import { CompanyNameStrict } from 'schemas/common/companyName';
import { BusinessType as BaseBusinessType } from 'config/constants/session';
import { createYearRange } from 'schemas/common/date';
import { optionallyApplyMaybe } from 'schemas/utils';

const AccountingPackages = t.enums({
  'AccountingSeed': 'Accounting Seed',
  'AccPac': 'Accpac/Sage 300 ERP',
  'Lawson': 'Lawson',
  'Mas200Sage100': 'MAS 200/Sage 100 ERP',
  'Mas500Sage500': 'MAS 500/Sage 500 ERP',
  'Mas90Sage100': 'MAS 90/Sage 100 ERP',
  'DynamicsGp': 'Microsoft Dynamics GP',
  'NetSuiteErp': 'NetSuite ERP',
  'NetSuiteSmallBusiness': 'NetSuite Small Business',
  'OracleEBusiness': 'Oracle E-Business Suite',
  'Peachtree': 'Peachtree/Sage 50',
  'PeopleSoft': 'PeopleSoft',
  'QuickBooksEnterprise': 'QuickBooks Enterprise',
  'QuickBooksForMac': 'QuickBooks for MAC',
  'QuickBooksOnline': 'QuickBooks Online',
  'QuickBooksPro': 'QuickBooks Pro/Premier',
  'SageLive': 'Sage Live',
  'Riskmaster': 'RISKMASTER Accelerator',
  'SageMasterBuilder': 'Sage Master Builder',
  'SapBusinessOne': 'SAP Business One',
  'SapErp': 'SAP ERP',
  'Xero': 'Xero',
  'None': 'No Accounting System',
  'Other': 'Other'
});

export const BusinessTypeForForm = t.enums({
  Corporation: 'Corporation',
  LimitedLiability: 'Limited Liability (LLC)',
  Partnership: 'Partnership (GP, LP, LLP, LLLP)',
  NotForProfit: 'Not For Profit Corporation',
  SoleProprietorship: 'Sole Proprietor'
});

export const BusinessTypeForDisplay = t.enums({
  Corporation: 'Corporation',
  LimitedLiability: 'Limited Liability (LLC)',
  Partnership: 'Partnership (GP, LP, LLP, LLLP)',
  NotForProfit: 'Not For Profit Corporation',
  SoleProprietorship: 'Sole Proprietor',
  Other: 'Other'  // for backward compatibility
});

const CompanySize = t.enums({
  'JustMe': 'Just Me',
  '2to5': '2 - 5',
  '6to14': '6 - 14',
  '15to49': '15 - 49',
  '50to99': '50 - 99',
  '100to499': '100 - 499',
  '500to999': '500 - 999',
  '1000to2499': '1,000 - 2,499',
  '2500to9999': '2,500 - 9,999',
  '10000Plus': '10,000+'
});

const IndustryType = t.subtype(t.String, () => true, 'IndustryType');

const RetrievableIndustryType = defineSchema({
  id: t.String,
  name: t.String
}, 'RetrievableIndustryType');

const CompanyLogoType = t.enums({
  Profile: 'Profile',
  InvoiceScreen: 'InvoiceScreen',
  InvoicePrint: 'InvoicePrint'
}, 'CompanyLogoType');

const CompanyLogo = t.struct({
  type: CompanyLogoType,
  url: t.String
}, 'CompanyLogo');

export const RetrievableCompanyProfile = defineSchema({
  id: t.maybe(t.String),
  isVerified: t.maybe(t.Boolean),
  companyName: t.maybe(t.String),
  clientReferenceId: t.maybe(t.String),
  firstName: t.maybe(t.String),
  lastName: t.maybe(t.String),
  email: t.maybe(Email),
  phone: t.maybe(t.String),
  address: t.maybe(RetrievableAddress),
  description: t.maybe(t.String),
  website: t.maybe(t.String),
  fax: t.maybe(t.String),
  size: t.maybe(t.String),
  industry: t.maybe(t.String),
  industryId: t.maybe(t.String),
  dbaName: t.maybe(t.String),
  businessType: t.maybe(t.enums(BaseBusinessType, 'BusinessType')),
  yearOfIncorporation: t.maybe(t.String),
  taxpayerIdDisplay: t.maybe(t.String),
  logoUrl: t.maybe(t.String), // primary logo (Profile)
  logos: t.list(CompanyLogo), // all logos
  accountingPackage: t.maybe(t.String)
}, 'RetrievableCompanyProfile');

const createEditCompanyProfile = (
  hasBankAccount = false,
  partialAddress = false,
  onlyAddressRequired = false,
  needsOtherAccountingPackage = false,
  requireWuFields = false
) => {
  const struct = {
    companyName: CompanyNameStrict,
    dbaName: t.maybe(createMaxLengthStringType(100)),
    fax: t.maybe(Phone),
    website: t.maybe(t.String),
    industryId: optionallyApplyMaybe(!requireWuFields, IndustryType),
    size: t.maybe(CompanySize),
    accountingPackage: t.maybe(AccountingPackages),
    otherAccountingPackage: t.maybe(t.String),
    businessType: optionallyApplyMaybe(!requireWuFields, BusinessTypeForForm),
    description: t.maybe(createMaxLengthStringType(2000)),
    yearOfIncorporation: optionallyApplyMaybe(!requireWuFields, createYearRange())
  };

  if (onlyAddressRequired) {
    struct.address = Address;
  } else if (hasBankAccount) {
    struct.phone = Phone;
    struct.address = Address;
    struct.businessType = BusinessTypeForForm;
  } else {
    struct.phone = t.maybe(Phone);

    if (partialAddress) {
      struct.address = Address;
      struct.businessType = BusinessTypeForForm;
    } else {
      struct.address = Address;
    }
  }

  if (needsOtherAccountingPackage) {
    struct.otherAccountingPackage = t.String;
  }

  return t.struct(struct, 'EditCompanyProfile');
};

const InboxEmail = t.struct({
  email: t.String
});

export {
  CompanyLogoType,
  createEditCompanyProfile,
  InboxEmail,
  CompanySize,
  IndustryType,
  RetrievableIndustryType
};
