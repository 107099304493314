/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TextBox from 'components/Form/Inputs/TextBox';
import Select from 'components/Form/Inputs/Select';
import { toNumber } from 'schemas/common/currency';
import './index.scss';

/**
 * Default form input for a NumberWithUnits field (a combined number+enum used as a single field)
 */
export default class NumberWithUnitsInput extends Component {
  static propTypes = {
    value: PropTypes.object,
    onChange: PropTypes.func
  };

  static defaultProps = {
    onChange: () => null
  };

  constructor(props) {
    super(props);
    this.state = {
      ...this.getStateFromProps(props)
    };
  }

  componentWillMount() {
    this.setState({
      ...this.getStateFromProps(this.props)
    });
  }

  componentWillReceiveProps(props) {
    if (this.hasValueChanged(props.value, this.props.value)) {
      this.setState({
        ...this.getStateFromProps(props)
      });
    }
  }

  hasValueChanged(value1, value2) {
    if (!value1 && !value2) {
      return false;
    }

    if (
      (value1 && !value2)
      ||      (!value1 && value2)
    ) {
      return true;
    }

    if (value1.number !== value2.number || value1.units !== value2.units) {
      return true;
    }

    return false;
  }

  getStateFromProps(props) {
    return {
      value: {
        number: props.value ? props.value.number : null,
        units: props.value ? props.value.units : null
      }
    };
  }

  getNumber() {
    if (!this.state.value) {
      return '';
    }
    return this.state.value.number;
  }

  getChildUnitType() {
    let type = this.props.type;
    if (type && type.meta) {
      return type.meta.props.units;
    }
    return type;
  }

  onChangeNumber(number) {
    let value = this.state.value;
    if (!value) value = {};

    if (value.number === number) {
      return;
    }

    this.setState({
      value: {
        number,
        units: value.units
      }
    }, () => {
      this.updateValue(number, value.units);
    });
  }

  onChangeUnit(units) {
    let value = this.state.value;
    if (!value) value = {};

    if (value.units === units) {
      return;
    }

    this.setState({
      value: {
        number: value.number,
        units
      }
    }, () => {
      this.updateValue(value.number, units);
    });
  }

  updateValue(number, units) {
    if (this.props.onChange) {
      // coerce valid numbers to actual Number type
      let numValue = toNumber(number);
      if (isNaN(numValue)) {
        numValue = number;
      }
      this.props.onChange({
        number: numValue,
        units
      });
    }
  }

  render() {

    return (
      <div className="component-form-number-with-units-input">
        <div className="number-field" style={{
          display: 'inline-block',
          width: '25%',
          verticalAlign: 'top'
        }}>
          <TextBox {...this.props}
            value={this.getNumber()}
            onChange={number => this.onChangeNumber(number)}/>
        </div>
        <div className="unit-field" style={{
          display: 'inline-block',
          width: '75%',
          verticalAlign: 'top'
        }}>
          <Select {...this.props}
            type={this.getChildUnitType()}
            value={this.state.value.units}
            onChange={unit => this.onChangeUnit(unit)}/>
        </div>
      </div>
    );
  }
}
