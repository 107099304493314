/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { connect } from 'react-redux';
import { getEntity, getEntityList } from 'schemas/state';
import { InvoiceActions } from 'config/constants';
import PaymentActions from 'config/constants/PaymentActions';
import NetworkActions from 'config/constants/network/NetworkActions';
import { filterForValidAccounts } from 'views/Settings/utils';

export default Component => connect(
  (state) => {
    const identity = getEntity(state, state.session.identity);
    const validPaymentMethods = filterForValidAccounts(getEntityList(state, state.session.bankAccounts));

    return {
      canCreateInvoice: identity.availableActions.includes(InvoiceActions.CreateInvoice),
      canCreateBill: identity.availableActions.includes(InvoiceActions.CreateBill),
      canSendPayment: validPaymentMethods.length > 0 && identity.availableActions.includes(PaymentActions.SendPayment),
      canCreateAddressBook: identity.availableActions.includes(NetworkActions.CreateAddressBook)
    };
  }
)(Component);
