/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import moment from 'moment';
import t from 'tcomb-validation';

/**
 * Convert a date range value to Date objects
 */
export const dateRangeToDates = (dateRangeValue) => {
  if (!dateRangeValue) return null;

  // FORMATS:
  // 1) a single date string: YYYY-MM-DD
  // 2) an underscore separated date string pair: YYYY-MM-DD_YYYY-MM-DD
  let dateStrings = dateRangeValue.split('_');

  let date1 = null;
  let date2 = null;

  // 1st date is the start date
  if (dateStrings?.[0]) {
    date1 = moment(dateStrings?.[0]).toDate();
  }

  // 2nd date is end date
  // when 2nd date not present assume a range of a single day
  if (dateStrings?.[1]) {
    date2 = moment(dateStrings?.[1]).toDate();
  } else {
    date2 = date1;
  }

  return {
    date1,
    date2
  };
};

/**
 * Convert date objects to a date range value
 */
export const dateRangeFromDates = (date1, date2) => {
  if (!date1) return null;

  let value = moment(date1).format('YYYY-MM-DD');

  if (date2) {
    let date2String = moment(date2).format('YYYY-MM-DD');
    if (value !== date2String) {
      // only use 2nd date if its different
      value += `_${date2String}`;
    }
  }

  return value;
};

/**
 * Type representing a date range
 * (e.g. a start date to an end date)
 *
 * Intended to work with DateRangePicker.
 * Stored as special string to be able to be used as-is in query string
 * (for page filters using query string params)
 */
export const dateRange = ({
  /** Optional: minimum date allowed for the range */
  minDate,
  /** Optional maximum date allowed for the range */
  maxDate
}) => {

  return t.subtype(t.String, (v) => {

    // store date range as specially formatted string
    // in a format that inherently works as a query string value
    let dates = dateRangeToDates(v);

    if (!dates || !dates.date1 || !dates.date2) return false;

    if (minDate && moment(minDate).isAfter(dates?.date1)) {
      return false;
    }

    if (maxDate && maxDate(maxDate).isBefore(dates?.date2)) {
      return false;
    }

    return true;
  }, 'DateRange');
};