/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

export const TagVariants = {
  Default: 'default',
  Failure: 'failure',
  Secondary: 'secondary',
  Success: 'success',
  Warning: 'warning'
};

/**
 * Simple tag component
 */
const Tag = ({
  children,
  isBlock,
  style,
  variant
}) => {
  return (
    <span
      className={classNames(
        'vp-tag',
        variant,
        { block: isBlock }
      )}
      style={style}
    >
      {children}
    </span>
  );
};

Tag.propTypes = {
  variant: PropTypes.oneOf([
    'default',
    'secondary',
    'success',
    'warning',
    'failure'
  ]),
  isBlock: PropTypes.bool
};

Tag.defaultProps = {
  variant: 'default',
  isBlock: false
};

export default Tag;
