/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { PersonalPortalViewActions } from 'config/constants/personalPortal';

import InvoicesPaths from './invoices';
import BillsPaths from './bills';
import PaymentsPaths from './payments';
import NetworkPaths from './network';
import SyncPaths from './sync';
import SettingsPaths from './settings';
import LogonPaths from './logon';
import RegistrationPaths from './registration';
import createPathConfig from './createPathConfig';

const {
  Paths,
  hasPermissionForPath,
  findPath,
  GetAllPaths
} = createPathConfig({
  Dashboard: {
    path: '/dashboard'
  },
  Invoices: InvoicesPaths,
  Bills: BillsPaths,
  Payments: PaymentsPaths,
  Network: NetworkPaths,
  Sync: SyncPaths,
  Settings: SettingsPaths,
  FileImport: {
    path: '/fileImport/list'
  },
  Tasks: {
    path: '/tasks'
  },
  ConsumerAutoPay: {
    path: '/autopay',
    requiredAction: PersonalPortalViewActions.ViewAutoPay
  },
  ConsumerFees: {
    path: '/fees',
    requiredAction: PersonalPortalViewActions.ViewFees
  },
  ConsumerSettings: {
    path: '/userSettings'
  },
  Help: {
    path: 'https://support.viewpost.com',
    isOutsideApp: true,
    children: {
      WhereIsMyMoney: {
        path: '/hc/en-us/articles/115000590907'
      },
      SendingPayments: {
        path: '/hc/en-us/articles/204165678-Sending-Payments'
      }
    }
  },
  MarketingSite: {
    path: 'https://www.viewpost.com'
  },
  Assets: {
    path: '/static',
    isOutsideApp: true,
    children: {
      PricingDocument: {
        path: '/resources/ViewpostPricing.pdf'
      }
    }
  },
  ApiResources: {
    path: '/api',
    isOutsideApp: true,
    children: {
      VccMonthlyReport: {
        path: '/reports/company/vccReport',
        queryStringMapper: ({ dateFrom, dateTo }) => ({ dateFrom, dateTo })
      },
      BulkSessionAcknowledgementDownload: {
        path: '/bulkSession/acknowledgement/:sessionId'
      }
    }
  },
  Logon: LogonPaths,
  Registration: RegistrationPaths
});

export {
  hasPermissionForPath,
  findPath,
  GetAllPaths
};

export default Paths;
