/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { defineMessages } from 'react-intl';

export default defineMessages({
  TooFewDigits: {
    id: 'ValidationError.Number.TooFewDigits',
    description: 'TODO',
    defaultMessage: 'Enter more than {minDigits} digits.'
  },
  TooManyDigits: {
    id: 'ValidationError.Number.TooManyDigits',
    description: 'TODO',
    defaultMessage: 'Enter no more than {maxDigits} digits.'
  },
  TooManyDecimals: {
    id: 'ValidationError.Number.TooManyDecimals',
    description: 'TODO',
    defaultMessage: 'Enter no more than {maxDecimals} decimal places.'
  },
  TooManyDigitsOrDecimals: {
    id: 'ValidationError.Number.TooManyDigitsOrDecimals',
    description: 'TODO',
    defaultMessage: 'Enter no more than {maxDigits} digits and {maxDecimals} decimal places.'
  },
  BoundedWholeNumber: {
    id: 'ValidationError.Number.BoundedWholeNumber',
    defaultMessage: 'Enter a whole number between {lowerBound, number} and {upperBound, number}.'
  },
  NegativeOrTooLargeOrNotInteger: {
    id: 'ValidationError.Number.NegativeOrTooLargeOrNotInteger',
    description: 'TODO',
    defaultMessage: 'Enter a positive, whole number, less than {maxDigits} digits.'
  },
  WrongNumberOfDigits: {
    id: 'ValidationError.Number.WrongNumberOfDigits',
    description: 'TODO',
    defaultMessage: 'Enter exactly {digits} digits.'
  },
  NonNumericString: {
    id: 'ValidationError.Number.NonNumericString',
    description: 'TODO',
    defaultMessage: 'Enter a number'
  },
  GreaterThanZero: {
    id: 'ValidationError.Number.GreaterThanZero',
    description: 'TODO',
    defaultMessage: 'Enter a number greater than zero'
  }
});
