/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { doServerErrorsContain } from 'api/core/utils.state';
import { RegistrationErrors } from 'config/constants/registration';
import { DEFAULT_EMPTY_GUID } from 'config/constants';
import { asApiAction } from 'api/core/ApiAction';
import { isFetchAction, isSuccessAction } from 'api/core/utils';
import { isNukeStateAction, newState } from './utils';

export default (state = {}, action) => {
  if (isFetchAction(action, 'registerNewAccount')) {
    return newState(state, {
      submitLoading: true,
      error: null,
      selectedExistingCompany: action.payload.params.companyMatchId
        && action.payload.params.companyMatchId !== DEFAULT_EMPTY_GUID
    });
  }

  if (isSuccessAction(action, 'registerNewAccount')) {
    return newState(state, {
      submitLoading: false,
      ...action.meta.response.body
    });
  }

  const apiAction = asApiAction(action);
  if (apiAction?.isFailure && apiAction.isEndpointOfName('registerNewAccount')) {
    let error = null;

    const messages = apiAction.getMessages();

    if (doServerErrorsContain(messages, 'EmailAddress', 'already in use')) {
      error = RegistrationErrors.EmailAddressInUse;
    } else if (doServerErrorsContain(messages, 'Password', 'too common')) {
      error = RegistrationErrors.CommonPassword;
    } else if (doServerErrorsContain(messages, 'InvitationCode', 'is incorrect')) {
      error = RegistrationErrors.InvalidPaperCheckInfo;
    } else if (doServerErrorsContain(messages, 'InvalidCodeOrCheck', 'is incorrect')) {
      error = RegistrationErrors.InvalidPaperCheckInfo;
    } else if (doServerErrorsContain(messages, 'InvitationCodeExpired', 'has expired')) {
      error = RegistrationErrors.PaperCheckExpired;
    } else if (doServerErrorsContain(messages, 'captcha', 'token')) {
      error = RegistrationErrors.InvalidCaptchaCode;
    } else if (doServerErrorsContain(messages, 'partnerUserReference', 'reference')) {
      error = RegistrationErrors.RegistrationBlocked;
    }

    if (!error) error = RegistrationErrors.Unknown;

    return newState(state, {
      submitLoading: false,
      error
    });
  }

  if (isNukeStateAction(action, 'Registration', true, [401, 403])) {
    return {};
  }

  return state;
};
