/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { isFetchAction, isSuccessAction } from 'api/core/utils';
import { newState } from '../utils';

export function PayLater(state = {}, action) {
  if (isFetchAction(action, 'getBankAccounts')) {
    return newState(state, {
      loadingBankAccounts: true
    });
  }

  if (isSuccessAction(action, 'getBankAccounts')) {
    return newState(state, {
      loadingBankAccounts: false,
      paymentAccounts: action.payload.references
    });
  }

  if (isSuccessAction(action, 'getRepaymentSchedules')) {
    return newState(state, {
      terms: action.payload.references
    });
  }

  return state;
}
