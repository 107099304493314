/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { connect } from 'react-redux';
import { getIdentity } from 'actions/common';

const MapStateToProps = state => ({
  isLoggedIn: !!getIdentity(state, { notExpected: true, returnNull: true })
});

export default connect(MapStateToProps);

