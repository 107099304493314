/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import ConnectionStatus from 'config/constants/network/ConnectionStatus';

const toConnectionStatus = (connectionStatus, onNetwork) => {
  switch (connectionStatus) {
    case 'Unconnected':
    case 'NotConnected':
    case 'None':
      return onNetwork
        ? ConnectionStatus.Connectable
        : ConnectionStatus.Unconnected;
    case 'Connecting':
      return onNetwork
        ? ConnectionStatus.NetworkConnecting
        : ConnectionStatus.InviteConnecting;
    case 'Invited':
    case 'InvitationPending':
      return ConnectionStatus.InviteConnecting;
    case 'PendingConnection':
    case 'ConnectionPending':
      return ConnectionStatus.NetworkConnecting;
    case 'Connected':
      return ConnectionStatus.Connected;
    default:
      return null;
  }
};

export default toConnectionStatus;