/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */
/* eslint max-len:0 */

import { defineMessages } from 'react-intl';

export default defineMessages({
  Downloading: {
    id: 'PrintService.Downloading',
    defaultMessage: 'Downloading document to print...'
  },
  Error: {
    id: 'PrintService.Error',
    defaultMessage: 'There was a browser error when trying to print. Try saving the document instead.'
  }
});
