/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import Paths from 'config/paths';
import LogicalPaths from 'config/paths/logical';
import createPathLink, { createLogicalPathLink } from './createPathLink';

const {
  PathLink,
  getPathLink
} = createPathLink(Paths);

const {
  LogicalPathLink
} = createLogicalPathLink(LogicalPaths, getPathLink);

export {
  getPathLink,
  LogicalPathLink
};

export default PathLink;
