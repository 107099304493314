/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, { forwardRef, useMemo, useState } from 'react';
import t from 'tcomb-validation';

import Button from 'components/Button';
import PasswordStrengthTextBox from 'components/Form/Inputs/PasswordStrengthTextBox';
import FormV2, { FieldInput, FieldOption, IfFieldDefined } from 'components/Form/V2';
import { passthroughToInput } from 'components/Form/V2/FieldOption';
import Tooltip from 'components/Tooltip';

import useIsMobile from 'hooks/browser/useIsMobile';
import useFormatMessage from 'hooks/intl/useFormatMessage';

import { CaptchaToken } from 'schemas/common/captcha';
import { CompanyNameStrict } from 'schemas/common/companyName';
import createMatchingEmail from 'schemas/common/email/createMatchingEmail';
import Email from 'schemas/common/email/Email';
import { FirstName, LastName } from 'schemas/common/name';
import { Password } from 'schemas/common/password';
import { Phone } from 'schemas/common/phone';

import Messages from './index.messages';
import './index.scss';

const FieldInputTooltip = passthroughToInput(Tooltip);

const createSchema = ({
  email,
  proveIsHuman,
  showCompanyName,
  showTitle,
  showPhone
}) => {
  const struct = {
    email: Email,
    verifyEmail: createMatchingEmail(email),
    firstName: FirstName,
    lastName: LastName,
    password: Password
  };

  if (showCompanyName) {
    struct.companyName = CompanyNameStrict;
  }

  if (showTitle) {
    struct.title = t.maybe(t.String);
  }

  if (showPhone) {
    struct.phone = t.maybe(Phone);
  }

  if (proveIsHuman) {
    struct.captchaToken = CaptchaToken;
  }

  return t.struct(struct);
};

const RegistrationForm = forwardRef(
  (
    {
      invitationId,
      isSkinny,
      isSubmitting,
      onChange,
      onSubmit,
      recaptchaRef,
      value
    },
    ref
  ) => {
    // Hooks
    const isMobile = useIsMobile();
    const [ isCompanyFocused, setIsCompanyFocused ] = useState(false);
    const formatMessage = useFormatMessage();

    const modelType = useMemo(
      () => createSchema({
        email: value.email,
        proveIsHuman: !invitationId,
        showCompanyName: true,
        showTitle: true,
        showPhone: true
      }),
      [ value.email, invitationId ]
    );

    // Render
    return (
      <FormV2
        ref={ref}
        className="registrationForm"
        display="flex"
        onChange={onChange}
        modelType={modelType}
        value={value}
      >
        <div className="flex-grid gutter">
          <IfFieldDefined name="companyName">
            <FieldOption
              label={<Messages.CompanyName.Message/>}
              name="companyName"
              onBlur={() => setIsCompanyFocused(false)}
              onFocus={() => setIsCompanyFocused(true)}
            >
              <FieldInputTooltip
                placement={isMobile ? 'top' : 'left'}
                variant="blue-tooltip"
                childWrapperStyle={{ display: 'block' }}
                visible={isCompanyFocused}
                header={<Messages.CompanyNameTooltipTitle.Message />}
                headerColor="white"
                overlay={<Messages.CompanyNameTooltip.Message />}
              >
                <FieldInput />
              </FieldInputTooltip>
            </FieldOption>
          </IfFieldDefined>
          <FieldOption
            name="firstName"
            cols={6}
            label={<Messages.FirstName.Message />}
          />
          <FieldOption
            name="lastName"
            cols={6}
            label={<Messages.LastName.Message />}
          />
          <IfFieldDefined name="title">
            <FieldOption
              cols={6}
              name="title"
              label={<Messages.Title.Message/>}
            />
          </IfFieldDefined>
          <IfFieldDefined name="phone">
            <FieldOption
              cols={6}
              name="phone"
              label={<Messages.Phone.Message/>}
            />
          </IfFieldDefined>
          <FieldOption
            cols={isSkinny ? 12 : 6}
            name="email"
            label={<Messages.Email.Message />}
          />
          <FieldOption
            cols={isSkinny ? 12 : 6}
            name="verifyEmail"
            label={<Messages.VerifyEmail.Message />}
          />
          <FieldOption
            name="password"
            label={<Messages.Password.Message/>}
          >
            <PasswordStrengthTextBox
              isMobile={isMobile}
              isToggleEnabled={true}
              placeholder={formatMessage(Messages.PasswordPlaceholder)}
              variant="tooltip"
            />
          </FieldOption>
          <IfFieldDefined name="captchaToken">
            <FieldOption name="captchaToken">
              <FieldInput
                ref={recaptchaRef}
                center={true}
              />
            </FieldOption>
          </IfFieldDefined>
        </div>
        <Button
          analyticsId="RegistrationButton"
          expand={true}
          type="submit"
          isDisabled={isSubmitting}
          isProcessing={isSubmitting}
          onClick={onSubmit}
        >
          <Messages.Submit.Message />
        </Button>
      </FormV2>
    );
  }
);

export default RegistrationForm;