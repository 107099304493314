/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { createFeatureToggleDefaultState } from 'services/FeatureToggles';

const getInitialState = () => {
  const initialState = {};

  try {
    const embedded = global.document.getElementById('initialState').innerHTML;
    if (embedded) {
      const parsed = JSON.parse(embedded);
      Object.assign(
        initialState,
        parsed
      );
    }
  } catch (e) {
    // Do nothing
  }

  Object.assign(
    initialState,
    {
      featureToggles: createFeatureToggleDefaultState()
    }
  );

  return initialState;
};

export default getInitialState;