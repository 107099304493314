/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import t from 'tcomb-validation';
import { defineSchema } from 'schemas';
import { EntityReference } from 'schemas/state';

const ApiResultBase = {
  loading: t.Boolean,
  error: t.maybe(t.String),
  refs: t.maybe(t.list(EntityReference)),
  request: t.maybe(t.union([t.Object, t.Array])),
  response: t.Any,
  responseMapperResult: t.Any,
  pagination: t.maybe(t.struct({
    page: t.Number,
    pageSize: t.Number,
    totalItems: t.maybe(t.Number),
    totalPages: t.maybe(t.Number),
    isCapped: t.maybe(t.Boolean),
    sortFields: t.maybe(t.Array)
  })),
  // This is going to be removed in favor of the more defined 'completed' field
  created: t.maybe(t.Number),
  completed: t.maybe(t.Number)
};

export const ApiResult = defineSchema({
  ...ApiResultBase,
  previous: t.maybe(t.struct(ApiResultBase))
}, 'ApiResult');

export const EntryApiResult = defineSchema({
  current: t.maybe(EntityReference),
  previous: t.list(EntityReference)
}, 'EntryApiResult');

export const ListApiResult = defineSchema({
  pages: t.dict(t.String, EntityReference),
  fetching: t.maybe(EntityReference),
  currentPageId: t.maybe(t.String),
  lastPageId: t.maybe(t.String),
  updatedDate: t.Date
}, 'ListApiResult');
