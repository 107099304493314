/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, { useEffect, useMemo } from 'react';
import ReactModal from 'react-modal';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { closeModal } from 'actions/modal';

import AnalyticsCategory from 'components/AnalyticsCategory';

import { getModal } from 'config/modals/registry';

import BaseModal from './BaseModal';

const ModalInstance = React.memo(
  ({
    isTop,
    modalId
  }) => {
    // Hooks
    const dispatch = useDispatch();
    const {
      modalName,
      modalParams,
      onCloseCallbacks,
      viewParams
    } = useSelector(
      state => state.modals.config[modalId],
      shallowEqual
    );

    const onClose = useMemo(
      () => (value) => {
        dispatch(closeModal());
        onCloseCallbacks?.resolve(value);
      },
      [ onCloseCallbacks ]
    );

    const ModalComponent = getModal(modalName);

    // Render
    if (!ModalComponent) {
      console.warn(`No modal name '${modalName}' found in the registry,
        make sure you are using 'addModal' to define your modal.`);
      return <></>;
    }

    return (
      <BaseModal
        {...modalParams}
        isOpen={!!modalName}
        hidden={!isTop}
        modalName={modalName}
      >
        <AnalyticsCategory
          name={modalName}
          style={{}}
        >
          <div className="modals-container">
            <ModalComponent
              {...viewParams}
              closeModal={onClose}
              // Maintaining onClose because everything uses it, but
              // close/closeModal makes more gramatical sense.
              onClose={onClose}
            />
          </div>
        </AnalyticsCategory>
      </BaseModal>
    );
  }
);

/**
 * Manages the opening and closing of modals for the entire application
 */
const ModalService = React.memo(
  ({
    elementId
  }) => {
    // Hooks
    const modalStack = useSelector(
      state => state.modals.stack || [],
      shallowEqual
    );

    useEffect(
      () => {
        if (elementId) {
          ReactModal.setAppElement(`#${elementId}`);
        }
      },
      [ elementId ]
    );

    // Render
    return (
      <>
        {modalStack.map(
          (modalId, index) => (
            <ModalInstance
              key={modalId}
              isTop={index === modalStack.length - 1}
              modalId={modalId}
            />
          )
        )}
      </>
    );
  }
);

export default ModalService;
