/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { wrapImport } from 'decorators/ModuleLoader';
import VerifyPaymentAccountOnboarding from 'views/Onboarding/VerifyPaymentAccountOnboarding';
import TokenLanding from 'views/Onboarding/TokenLanding';
import { NotFoundRedirect } from './utils';

const InvoiceOnboarding = wrapImport(
  () => import(/* webpackChunkName: "onboarding" */ 'views/Onboarding/InvoiceOnboarding')
);
const InvitationOnboarding = wrapImport(
  () => import(/* webpackChunkName: "onboarding" */ 'views/Onboarding/InvitationOnboarding')
);
const VccDigits = wrapImport(() => import(/* webpackChunkName: "onboarding" */ 'views/Onboarding/VccDigits'));
const VccHelp = wrapImport(() => import(/* webpackChunkName: "onboarding" */ 'views/Onboarding/VccHelp'));


const GetStartedRedirect = ({ match }) => {
  if (match.params.invitationId) {
    return <Redirect to={`/register/invitation/${match.params.invitationId}`} />;
  }

  return NotFoundRedirect;
};

const InvitationOnboardRedirect = ({ match }) => {
  if (match.params.invitationId) {
    return <Redirect to={`/onboarding/invitation/${match.params.invitationId}`} />;
  }

  return NotFoundRedirect;
};

export const GetStartedRedirects = ({ match }) => (
  <Switch>
    <Route path={`${match.url}/vendor/:invitationId`}
      exact={true}
      component={InvitationOnboardRedirect} />
    <Route path={`${match.url}/customerFromReceivable/:invitationId`}
      exact={true}
      component={GetStartedRedirect} />
    <Route path={`${match.url}/vendor/payment/nonvp/:invitationId`}
      exact={true}
      component={InvitationOnboardRedirect} />
    <Route path={`${match.url}/paymentInvitation/:invitationId`}
      exact={true}
      component={InvitationOnboardRedirect} />
    <Route path={`${match.url}/customer/:invitationId`}
      exact={true}
      component={GetStartedRedirect} />
    <Route path={`${match.url}/:invitationId`}
      exact={true}
      component={GetStartedRedirect} />
  </Switch>
);

const ReferralRedirect = ({ match }) => {
  if (match.params.referralToken) {
    return <Redirect to={`/register/referral/${match.params.referralToken}`} />;
  }

  return NotFoundRedirect;
};

export const ReferralRedirects = ({ match }) => (
  <Switch>
    <Route path={`${match.url}/landing/:referralToken`} exact={true} component={ReferralRedirect} />
  </Switch>
);

const DocumentRedirect = ({ match }) => {
  if (match.params.secureToken) {
    return <Redirect to={`/onboarding/invoice/${match.params.secureToken}`} />;
  }

  return NotFoundRedirect;
};

export const DocumentRedirects = ({ match }) => (
  <Switch>
    <Route path={`${match.url}/:secureToken`} exact={true} component={DocumentRedirect} />
    <Route path={`${match.url}/contextType/:secureToken`} exact={true} component={DocumentRedirect} />
  </Switch>
);

export const LandingRoutes = ({ match }) => (
  <Switch>
    <Route path={`${match.url}/token/:secureToken`} exact={true}>
      <div className="viewstrap" style={{ height: '100%' }}>
        <TokenLanding />
      </div>
    </Route>
  </Switch>
);

export default ({ match }) => (
  <Switch>
    <Route path={`${match.url}/invoice/:secureToken`} exact={true}>
      <div className="viewstrap" style={{ height: '100%'}}>
        <InvoiceOnboarding />
      </div>
    </Route>
    <Route path={`${match.url}/invitation/:invitationId`} exact={true}>
      <div className="viewstrap">
        <InvitationOnboarding />
      </div>
    </Route>
    <Route path={`${match.url}/vccDigits/:vccVendorId/:token`} exact={true} component={VccDigits} />
    <Route path={`${match.url}/vccHelp/:token`} exact={true} component={VccHelp} />
    <Route path={`${match.url}/account/:token`} exact={true} component={VerifyPaymentAccountOnboarding} />
    {NotFoundRedirect}
  </Switch>
);
