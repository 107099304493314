/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import isFunction from 'lodash/isFunction';
import uniqueId from 'lodash/uniqueId';
import { createEntity } from 'schemas/state';

const entityMapper = (
  type,
  id,
  overrides,
  original,
  mapperParams
) => {
  const value = { ...original };

  for (const [ key, override ] of Object.entries(overrides ?? {})) {
    if (isFunction(override)) {
      Object.assign(
        value,
        {
          [key]: override(original, mapperParams ?? {})
        }
      );
    }
  }

  const entity = createEntity(
    id || uniqueId(),
    type.meta.name,
    value
  );

  return {
    entities: [ entity ],
    reference: entity.createReference()
  };
};

export default entityMapper;