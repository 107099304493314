/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, { useEffect, useState } from 'react';

import acceptVccTerms from 'api/onboarding/acceptVccTerms';
import sendVccDigits from 'api/onboarding/sendVccDigits';

import BodyText, { BodyTextVariants } from 'components/BodyText';
import Button from 'components/Button';
import { BaseFieldLayout } from 'components/Form/Containers/FieldLayout';
import CheckBox from 'components/Form/Inputs/CheckBox';
import HeaderText, { HeaderTextVariants } from 'components/HeaderText';

import {
  MailToCustomerSupport,
  PaymentSupportEmail,
  ViewpostCustomerSupport,
  ViewpostSupport
} from 'config/messageLinks';

import createExecuteApiResultHooks from 'hooks/apiResult/createExecuteApiResultHooks';
import createProvideApiResultHooks from 'hooks/apiResult/createProvideApiResultHooks';
import useStatusIndicator from 'hooks/apiResult/useStatusIndicator';
import { GetSecureTokenDataHooks } from 'hooks/registration';

import BlueHeaderOnWhiteLayout from 'layouts/BlueHeaderOnWhite';

import ActiveEyeImage from 'public/images/icons/eye-active.svg';
import HideEyeImage from 'public/images/icons/eye-hide.svg';
import InactiveEyeImage from 'public/images/icons/eye-inactive.svg';
import GreenCheckmark from 'public/images/icons/green-checkmark.svg';

import Messages, {
  ConfirmationMessages,
  InvalidMessages,
  ResendMessages
} from './index.messages';
import './index.scss';

const LargePasswordBox = ({
  isShown,
  children,
  onClick
}) => {
  let icon = InactiveEyeImage;

  if (children) {
    icon = isShown ? ActiveEyeImage : HideEyeImage;
  }

  return (
    <div className="large-password-box">
      {children ? (
        <div className="active">
          {isShown ? (
            <span className="password">
              {children}
            </span>
          ) : (
            <span className="dots">
              &#9679;&nbsp;&#9679;&nbsp;&#9679;&nbsp;&#9679;&nbsp;&#9679;&nbsp;&#9679;
            </span>
          )}
        </div>
      ) : (
        <div className="inactive dots">
          &#9679;&nbsp;&#9679;&nbsp;&#9679;&nbsp;&#9679;&nbsp;&#9679;&nbsp;&#9679;
        </div>
      )}
      {isShown && children ? (
        <span className="color-failure pad-8-left">
          <ConfirmationMessages.SaveWarning.Message />
        </span>
      ) : null}
      <div className="display-toggle">
        <img
          onClick={onClick}
          src={icon}
          style={{ cursor: 'pointer' }}
        />
      </div>
    </div>
  );
};

const AcceptVccTermsHooks = createExecuteApiResultHooks({
  endpoint: acceptVccTerms
});

const SendVccDigitsHooks = createProvideApiResultHooks({
  endpoint: sendVccDigits
});

const SendVccDigitsStatusIndicatorConfig = [{
  useWillLoadOrIsLoading: SendVccDigitsHooks.useIsLoading,
  useRequestError: SendVccDigitsHooks.useRequestError
}];

export const InvalidAcceptVccTerms = () => {
  return (
    <BlueHeaderOnWhiteLayout
      contentWidth={650}
      hideLinks={true}
    >
      <div className="viewstrap">
        <div className="text-center">
          <HeaderText className="pad-12-bottom">
            <InvalidMessages.Header.Message />
          </HeaderText>
          <HeaderText className="pad-12-bottom" variant={HeaderTextVariants.Small}>
            <InvalidMessages.Subheader.Message
              supportLink={ViewpostCustomerSupport}
            />
          </HeaderText>
        </div>
      </div>
    </BlueHeaderOnWhiteLayout>
  );
};

const SupportLink = MailToCustomerSupport(<Messages.SupportLink.Message />);

// This page also does double duty to also service the 'resend my digits' action
const AcceptVccTerms = ({
  context,
  secureToken,
  secureTokenContext: {
    data: {
      cardBrand,
      customerCompany,
      // Email will only be there until you accept terms, as it could update
      // later
      email: originalEmail,
      hasAcceptedTerms,
      isAssistedPay,
      paymentTerms,
      sixDigitPrefix
    }
  }
}) => {
  // Hooks
  const [ hadAcceptedTerms ] = useState(hasAcceptedTerms);
  const [ email ] = useState(originalEmail);
  const [ acceptTerms, setAcceptTerms ] = useState(false);
  const [ isShown, setIsShown ] = useState(false);
  const [ error, setError ] = useState();

  const onToggle = newValue => setAcceptTerms(newValue);

  const executeAcceptTerms = AcceptVccTermsHooks.useExecuteRequest();

  const onClick = async () => {
    if (!acceptTerms) {
      setError('mustCheck');
      return;
    }

    setError();

    await executeAcceptTerms({
      secureToken
    });

    setIsShown(true);
  };

  const onPasswordClick = () => {
    if (hasAcceptedTerms || hadAcceptedTerms) {
      setIsShown(prevValue => !prevValue);
      return;
    }

    if (!acceptTerms) {
      setError('mustCheck');
    } else {
      setError('mustSubmit');
    }
  };

  useEffect(
    () => {
      if (!error) return;

      if (error === 'mustCheck' && acceptTerms) {
        setError(null);
      }
    },
    [ acceptTerms ]
  );

  const isAccepting = AcceptVccTermsHooks.useIsLoading();
  const isRefreshingToken = GetSecureTokenDataHooks.useIsLoading();

  const isResendingSixDigits = context === 'VccResendSixDigits';

  SendVccDigitsHooks.useProvideParams(isResendingSixDigits && hadAcceptedTerms
    ? { secureToken }
    : null);

  const SendStatusIndicator = useStatusIndicator(SendVccDigitsStatusIndicatorConfig);

  // Render
  let content;

  if (context === 'VccAcceptTerms' || !hadAcceptedTerms) {
    content = (
      <>
        {!hasAcceptedTerms && !hadAcceptedTerms ? (
          <div className="pad-20-x text-center">
            <HeaderText className="pad-12-bottom">
              {isAssistedPay ? (
                <Messages.AssistedPayHeader.Message />
              ) : (
                <>
                  {isResendingSixDigits
                    ? <Messages.DirectPayResendHeader.Message />
                    : <Messages.DirectPayHeader.Message />}
                </>
              )}
            </HeaderText>
            <HeaderText className="pad-12-bottom" variant={HeaderTextVariants.Small}>
              {isAssistedPay ? (
                <Messages.AssistedPaySubheader.Message
                  companyName={customerCompany}
                  supportLink={SupportLink}
                />
              ) : (
                <>
                  {isResendingSixDigits ? (
                    <Messages.DirectPayResendSubheader.Message
                      companyName={customerCompany}
                      remitToEmail={email}
                      supportLink={SupportLink}
                    />
                  ) : (
                    <Messages.DirectPaySubheader.Message
                      companyName={customerCompany}
                      remitToEmail={email}
                      supportLink={SupportLink}
                    />
                  )}
                </>
              )}
            </HeaderText>
          </div>
        ) : (
          <div className="text-center">
            <HeaderText className="pad-12-bottom">
              <ConfirmationMessages.Header.Message />
            </HeaderText>
            <HeaderText className="pad-12-bottom" variant={HeaderTextVariants.Small}>
              {isAssistedPay ? (
                <ConfirmationMessages.AssistedPaySubheader.Message
                  companyName={customerCompany}
                  supportLink={SupportLink}
                />
              ) : (
                <ConfirmationMessages.DirectPaySubheader.Message
                  cardBrand={cardBrand}
                  companyName={customerCompany}
                />
              )}
            </HeaderText>
          </div>
        )}
        {!isAssistedPay && (hasAcceptedTerms || isResendingSixDigits) ? (
          <div className="pad-20-top">
            <LargePasswordBox
              isShown={isShown}
              onClick={onPasswordClick}
            >
              {sixDigitPrefix}
            </LargePasswordBox>
          </div>
        ) : null}
        <div className="pad-20-x pad-20-top">
          {!hasAcceptedTerms && !hadAcceptedTerms ? (
            <>
              <BodyText variant={BodyTextVariants.Smallest}>
                <Messages.Terms.Message
                  paymentTerms={paymentTerms}
                />
              </BodyText>
              <div
                className="pad-20-top"
                style={{ display: 'flex', alignItems: 'center' }}
              >
                <div style={{ flexGrow: 2 }}>
                  <BaseFieldLayout
                    error={error === 'mustCheck' ? (
                      <Messages.MustCheckValidationError.Message />
                    ) : null}
                  >
                    <CheckBox
                      onChange={onToggle}
                      variant="children"
                      value={acceptTerms}
                    >
                      <span className="text-tiny">
                        <Messages.AgreeCheckbox.Message />
                      </span>
                    </CheckBox>
                  </BaseFieldLayout>
                </div>
                <div>
                  <BaseFieldLayout
                    error={error === 'mustSubmit' ? (
                      <Messages.MustClickValidationError.Message />
                    ) : null}
                  >
                    <Button
                      isDisabled={isAccepting || isRefreshingToken}
                      isProcessing={isAccepting || isRefreshingToken}
                      style={{ display: 'block' }}
                      onClick={onClick}
                    >
                      {(isResendingSixDigits
                        ? <Messages.ResendSubmit.Message />
                        : <Messages.Submit.Message />
                      )}
                    </Button>
                  </BaseFieldLayout>
                </div>
              </div>
            </>
          ) : (
            <>
              {!isAssistedPay ? (
                <>
                  {hadAcceptedTerms ? (
                    <BodyText>
                      <ConfirmationMessages.LaterInstructions.Message
                        supportLink={ViewpostSupport}
                      />
                    </BodyText>
                  ) : null}
                  {!hadAcceptedTerms && hasAcceptedTerms ? (
                    <BodyText>
                      <ConfirmationMessages.NowInstructions.Message
                        email={email}
                        supportLink={ViewpostSupport}
                      />
                    </BodyText>
                  ) : null}
                </>
              ) : null}
            </>
          )}
        </div>
      </>
    );
  } else if (isResendingSixDigits) {
    content = SendStatusIndicator ? (
      <SendStatusIndicator />
    ) : (
      <div className="text-center pad-20-top">
        <img src={GreenCheckmark} />
        <HeaderText bold={false} className="pad-8-y" variant={HeaderTextVariants.Medium}>
          <ResendMessages.Header.Message />
        </HeaderText>
        <BodyText>
          <ResendMessages.Subheader.Message
            supportLink={PaymentSupportEmail}
          />
        </BodyText>
      </div>
    );
  }

  return (
    <BlueHeaderOnWhiteLayout
      contentWidth={650}
      hideLinks={true}
    >
      <div className="viewstrap">
        {content}
      </div>
    </BlueHeaderOnWhiteLayout>
  );
};

export default AcceptVccTerms;