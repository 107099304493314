/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import t from 'tcomb-validation';

import { toMessages } from 'config/messages';

const Messages = toMessages('RecaptchaValidation', {
  NoValue: 'Please verify reCaptcha.'
});

export const CaptchaToken = t.subtype(
  t.String,
  captchaToken => !!captchaToken,
  'CaptchaToken'
);

CaptchaToken.getValidationErrorMessage = (value, path, context) => {
  if (!value) {
    return context.intl.formatMessage(Messages.NoValue);
  }

  return '';
};

