/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */
/* eslint max-len:0 */

import { toMessages } from 'config/messages';

export default toMessages('DelinquentAccountSpeedBump', {
  Header: 'Just a moment.',
  Subheader: 'We need you to do something before sending you in.',
  SelectCorporate: 'Select Corporate Account'
});
