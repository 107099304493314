/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import PropTypes from 'prop-types';
/**
  Configuration component representing a styleable container for fields in a <FormJSX>
  @docscomponent
*/
function FieldSection(props) {
  return props;
}

FieldSection.HasFieldOptions = true;

FieldSection.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object
};

export const hasFieldOptions = Component => !!(Component || {}).HasFieldOptions;

export default FieldSection;
