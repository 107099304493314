/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { isFunction, omit, pick } from 'lodash';
import { toEnumObject } from 'config/constants/utils';

export const PathPrefix = toEnumObject([
  'Domain', // Prefix with 'https://thisdomain.com/'
  'Relative', // Pefix with nothing
  'DomainRelative' // Prefix with '/'
]);

export const getPathPrefix = (prefix) => {
  if (isFunction(prefix)) return prefix;

  switch (prefix) {
    case PathPrefix.Domain:
      return `https://${global.window.location.host}`;
    case PathPrefix.Relative:
      return '';
    case PathPrefix.DomainRelative:
      return '/';
    default:
  }

  return '';
};

export const mapFilterParams = keys => query => ({
  ...Object.keys(pick(query, keys))
    .reduce(
      (val, key) => ({
        ...val,
        [`filter_${key}`]: query[key]
      }),
      {}
    ),
  ...omit(query, keys)
});