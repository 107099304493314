/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { get } from 'lodash';
import { getEntity } from 'schemas/state';
import logError from 'services/ErrorService';

const getCompanyUserSettings = (state, { notExpected = false, returnNull = false } = {}) => {
  const identity = getEntity(state, get(state, 'session.identity'));

  if (!notExpected && (!identity || !identity.companyUserSettings)) {
    logError('Expected companyUserSettings, but companyUserSettings is missing.', null, { includeStackTrace: true });
  }

  // Returning at least an empty object so that at least a JS error doesn't
  // take React down. We have the logged error, so you there is visiblity into
  // a problem
  return (identity || {}).companyUserSettings || (returnNull ? null : {});
};

export default getCompanyUserSettings;