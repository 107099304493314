/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import { showToast } from 'actions/notification';
import { getTwoStepSettings } from 'actions/settings';

import getIdentity from 'api/session/getIdentity';
import verifyTwoFactorAuthentication from 'api/settings/verifyTwoFactorAuthentication';

import Button from 'components/Button';

import { ToastTypes } from 'config/constants';
import CommonMessages from 'config/messages/common';

import createExecuteApiResultHooks from 'hooks/apiResult/createExecuteApiResultHooks';

import createBaseTwoStepSetupForm from './Base';
import Messages from './index.messages';
import connect from './index.connect';

const VerifyTwoFactorHooks = createExecuteApiResultHooks({
  endpoint: verifyTwoFactorAuthentication
});

const createTwoStepSetupForm = (WrappingComponent, connectSetupForm) => {
  let BaseSetupForm = createBaseTwoStepSetupForm(WrappingComponent);
  if (connectSetupForm) {
    BaseSetupForm = connectSetupForm(BaseSetupForm);
  }

  const WrappedComponent = (props) => {
    // Hooks
    const dispatch = useDispatch();
    const {
      enableTwoStepVerification,
      isPhoneNumberLocked,
      isTwoFactorVerificationPending,
      onComplete,
      phoneNumber,
      resendTwoFactorCode,
      resetTwoFactorVerification,
      skipValidation
    } = props;

    useEffect(
      () => {
        if (skipValidation) {
          onComplete?.();
        }
      },
      [ skipValidation ]
    );

    const [
      {
        success: codeVerificationSuccess,
        allowAdditionalAttempts
      },
      setVerifyState
    ] = useState({});

    const executeVerify = VerifyTwoFactorHooks.useExecuteRequest();
    const isVerifying = VerifyTwoFactorHooks.useIsLoading();

    const onVerifyCode = useMemo(
      () => async (
        authenticationCode,
        showConfirmation,
        callback
      ) => {
        const [ success, action ] = await executeVerify({ authenticationCode });

        if (!success) return;

        setVerifyState(action.payload.result);

        if (!action.payload.result.success) return;

        if (showConfirmation) {
          dispatch(showToast({
            type: ToastTypes.success,
            message: <Messages.Enabled.Message />
          }));
        }

        dispatch(getIdentity());
        dispatch(getTwoStepSettings());
        callback?.();
      },
      [ executeVerify ]
    );

    // Render
    return (
      <BaseSetupForm
        {...props}
        isVerificationPending={isTwoFactorVerificationPending}
        isVerificationSuccessful={codeVerificationSuccess}
        isVerifying={isVerifying}
        isPhoneNumberLocked={isPhoneNumberLocked || allowAdditionalAttempts === false}
        onEnable={enableTwoStepVerification}
        onResendCode={resendTwoFactorCode}
        onResetVerification={resetTwoFactorVerification}
        onVerifyCode={onVerifyCode}
        phoneNumber={phoneNumber}
      />
    );
  };

  return connect(WrappedComponent);
};

export const TwoStepSetupFormCard = createTwoStepSetupForm(null, InnerComponent => (props) => {
  const {
    canDisableTwoFactor,
    disableTwoFactorAuthentication,
    isTwoFactorEnabled,
    onComplete,
    showDisableButton
  } = props;

  const enterActions = isTwoFactorEnabled && showDisableButton
    ? <div>
      {canDisableTwoFactor
        ? <Button onClick={() => {
          disableTwoFactorAuthentication();
          onComplete();
        }} anchor={true}>
          <CommonMessages.Disable.Message />
        </Button>
        : <span className="vp-label">
          <Messages.MustCompleteQuestions.Message />
        </span>}
    </div>
    : null;

  const sendActions = ({
    isVerifying,
    sendVerifyCode
  }) => (
    <div style={{marginTop: 12}}>
      <Button variant="secondary" style={{width: 'calc((100% - 12px) / 2)'}}
        onClick={onComplete}>
        <CommonMessages.Cancel.Message />
      </Button>
      <Button
        isDisabled={isVerifying}
        isProcessing={isVerifying}
        style={{marginLeft: 12, width: 'calc((100% - 12px) / 2)'}}
        type="submit"
        onClick={(e) => {
          sendVerifyCode();
          onComplete();
        }}>
        <CommonMessages.Verify.Message />
      </Button>
    </div>
  );

  return <InnerComponent {...props} enterActions={enterActions} sendActions={sendActions} />;
});

export default createTwoStepSetupForm;
