/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { useContext } from 'react';
import { uniqueId } from 'lodash';
import { shallowEqual, useDispatch, useSelector, useStore } from 'react-redux';

import {
  getApiResultEntities,
  getApiResultError,
  getApiResultPagination,
  getApiResultRequestParams,
  getApiResultResponse,
  getApiResultResponseMapperResult,
  getEntryApiResult,
  isApiResultLoading,
  invalidateEntryApiResults
} from 'api/core/utils.state';

import LoadingContext from '../loadingState/LoadingContext';
import calculateShouldCall, { getCalculateShouldCallState } from './calculateShouldCall';

const createBaseApiResultHooks = ({
  // This is provided if the create implementation wants all hooks to have additional functionality
  applyHookWrapper,
  // This is similar to, but differs in behavior from, the correlationId that the ARDS took in.
  // Since the ARDS handled multiple endpoints at once, the correlationId was a prefix to the
  // ApiResults that it created, because a hook here is correlated to only a single endpoint, we
  // don't need use this as a prefix. So this is used literally as the correlationId (albeit with a
  // generic prefix to somewhat separate it from other ApiResult/CorrelationIds - I hope you really
  // don't need to access the entities backed by this ID)
  // Unlike the ARDS, this is not necessary to be set in many cases, particularly if you are using
  // create*ApiResultHooks in a static/global context. An ID will be generated if you do not provide
  // this.
  id: apiResultHookId,
  // An API Generator (Generated) Action Creator, the only required parameter
  endpoint,
  // The scope to apply to all the entities created from this hook
  entityScope
}) => {
  if (!endpoint) {
    console.error('Endpoint must be defined');
    return {};
  }

  // Not providing an id is largely benign when done in a static/global context, but special care
  // needs to be taken when doing it in something like a render method where your ID might be
  // regenerated on a rerender.
  const correlationId = apiResultHookId
    ? `ApiResultHooks-${apiResultHookId}`
    : `ApiResultHooks-${uniqueId()}-${endpoint.config.name}`;

  const entryApiResultId = correlationId;

  const getCurrentApiResultId = (state) => {
    const entryApiResult = getEntryApiResult(state, entryApiResultId) || {};
    const { id: currentApiResultId } = entryApiResult.current || {};
    return currentApiResultId;
  };

  const hookConfig = {
    correlationId,
    endpoint,
    entityScope,
    entryApiResultId
  };

  const applyWrapper = (
    func,
    flagsFunc
  ) => {
    const applyMeta = (
      target,
      additionalMeta
    ) => {
      target.meta = {
        ...(target.meta || {}),
        correlationId,
        endpoint,
        entryApiResultId,
        ...(additionalMeta || {})
      };

      return target;
    };

    const applyInnerWrapper = applyHookWrapper?.(
      hookConfig,
      flagsFunc
    );

    const wrapped = applyMeta(
      applyInnerWrapper?.(func) || func,
      // The initial run of this doesn't have params, hopefully your flagsFunc can handle this
      flagsFunc?.() || {}
    );

    // Provide a way for the hook to be bind'd to new params while still having the meta
    wrapped.meta.bind = (...boundParams) => {
      // Now this is some array arithmetic - I am taking the params that you
      // pass to the rebound function and then applying the remaining params
      // from the binding after that. This is to support when the first params
      // are still providable by the user of the function, but the remaining
      // ones should be bound. Anything more complicated than this would
      // require likely a function that takes in the passed params and then
      // returns out the params to the rebound function.
      const boundFunc = (...params) => func(...[...params, ...boundParams.slice(params.length)]);

      return applyMeta(
        applyInnerWrapper?.(boundFunc) || boundFunc,
        {
          ...wrapped.meta,
          ...(flagsFunc?.(...boundParams) || {})
        }
      );
    };

    return wrapped;
  };

  const getIsLoading = (
    state,
    getConfig,
    params
  ) => {
    if (isApiResultLoading(state, getCurrentApiResultId(state), getConfig)) {
      return true;
    }

    if (params?.loadingContext?.[entryApiResultId]) {
      return true;
    }

    return false;
  };

  const useIsLoading = applyWrapper(
    (getConfig) => {
      // Hooks
      // Making a special exception here to go directly to the latest and greatest redux state
      // This is, frankly, necessary because of useProvideParams will make a request out during the
      // execution of its hook and update the redux state to indicate the hook is loading, which in
      // normal React world would wait for a render to come around with the redux state updated, but
      // this causes an issue that we will be thinking the call isn't loading when it is, which
      // will cause issues with loading states not displaying. While all this could handled with
      // returning that the call was made and then using that to make decisions, this saves a lot of
      // code and complexity. Again, this is just an exception for the loading state.
      const store = useStore();

      const {
        stateRef: { current: loadingContextState }
      } = useContext(LoadingContext);

      const currentValue = useSelector(
        state => getIsLoading(state, getConfig),
        shallowEqual
      );

      // Action
      // Just because we want to be on the bleeding edge of the loading state
      // doesn't mean we always defer to it - all the other hooks change when
      // the redux state gets applied to the component. We need to also stay
      // in a loading state if that's what the component is still on so we don't
      // say its not loading when that state is not well known yet.
      return currentValue
        || getIsLoading(store.getState(), getConfig, { loadingContext: loadingContextState });
    },
    getConfig => ({
      selector: (
        state,
        overrideGetConfig,
        params
      ) => getIsLoading(state, overrideGetConfig || getConfig, params)
    })
  );

  const getWillLoadOrIsLoading = (state, getConfig, params) => {
    const shouldCallState = getCalculateShouldCallState(
      state,
      entryApiResultId,
      getConfig
    );

    const { isLoading, isSuccessful, lastRequestParams } = shouldCallState;

    return isLoading
      || calculateShouldCall(
        shouldCallState,
        lastRequestParams,
        {
          // Only consider isUpdated as a loading state if the ApiResult is not
          // valid. This is so coalesced ApiResults aren't considered in a loading
          // state if one of their entities are updated.
          isUpdated: !isSuccessful
        }
      )
      || params?.loadingContext?.[entryApiResultId];
  };

  const useWillLoadOrIsLoading = applyWrapper(
    (getConfig) => {
      // Hooks
      // Making a special exception here to go directly to the latest and greatest redux state
      // This is, frankly, necessary because of useProvideParams will make a request out during the
      // execution of its hook and update the redux state to indicate the hook is loading, which in
      // normal React world would wait for a render to come around with the redux state updated, but
      // this causes an issue that we will be thinking the call isn't loading when it is, which
      // will cause issues with loading states not displaying. While all this could handled with
      // returning that the call was made and then using that to make decisions, this saves a lot of
      // code and complexity. Again, this is just an exception for the loading state.
      const store = useStore();

      const {
        stateRef: { current: loadingContextState }
      } = useContext(LoadingContext);

      const currentValue = useSelector(
        state => getWillLoadOrIsLoading(state, getConfig),
        shallowEqual
      );

      // Action
      // Just because we want to be on the bleeding edge of the loading state
      // doesn't mean we always defer to it - all the other hooks change when
      // the redux state gets applied to the component. We need to also stay
      // in a loading state if that's what the component is still on so we don't
      // say its not loading when that state is not well known yet.
      return currentValue
        || getWillLoadOrIsLoading(store.getState(), getConfig, { loadingContext: loadingContextState });
    },
    getConfig => ({
      selector: (
        state,
        overrideGetConfig,
        params
      ) => getWillLoadOrIsLoading(state, overrideGetConfig || getConfig, params)
    })
  );

  const getRequestError = (state, getConfig) => getApiResultError(
    state,
    getCurrentApiResultId(state),
    getConfig
  );

  const useRequestError = applyWrapper(
    getConfig => useSelector(
      state => getRequestError(state, getConfig),
      shallowEqual
    ),
    getConfig => ({
      selector: (state, overrideGetConfig) => getRequestError(state, overrideGetConfig || getConfig)
    })
  );

  const invalidateStateAction = () => invalidateEntryApiResults([ entryApiResultId ], true);

  const useInvalidateState = applyWrapper(
    () => {
      const dispatch = useDispatch();
      return () => dispatch(invalidateStateAction());
    }
  );

  const getEntities = (state, getConfig) => getApiResultEntities(
    state,
    getCurrentApiResultId(state),
    getConfig
  );

  const useEntities = applyWrapper(
    getConfig => useSelector(
      state => getEntities(state, getConfig),
      shallowEqual
    ),
    getConfig => ({
      selector: (state, overrideGetConfig) => getEntities(state, overrideGetConfig || getConfig)
    })
  );

  const getEntity = (state, getConfig) => (getEntities(state, getConfig) || [])[0];

  const useEntity = applyWrapper(
    getConfig => useSelector(
      state => getEntity(state, getConfig),
      shallowEqual
    ),
    getConfig => ({
      selector: (state, overrideGetConfig) => getEntity(state, overrideGetConfig || getConfig)
    })
  );

  const getRequestParams = (state, getConfig) => getApiResultRequestParams(
    state,
    getCurrentApiResultId(state),
    getConfig
  );

  const useRequestParams = applyWrapper(
    getConfig => useSelector(
      state => getRequestParams(state, getConfig),
      shallowEqual
    ),
    getConfig => ({
      selector: (state, overrideGetConfig) => getRequestParams(state, overrideGetConfig || getConfig)
    })
  );

  // This will only be populated if 'saveResponseToResult' is set on the endpoint config
  const getResponse = (state, getConfig) => getApiResultResponse(
    state,
    getCurrentApiResultId(state),
    getConfig
  );

  // This will only be populated if 'saveResponseToResult' is set on the endpoint config
  const useResponse = applyWrapper(
    getConfig => useSelector(
      state => getResponse(state, getConfig)
    ),
    getConfig => ({
      selector: (state, overrideGetConfig) => getResponse(state, overrideGetConfig || getConfig)
    })
  );

  // This will only be populated if 'saveResponseMapperResultToResult' is set on the endpoint config
  const getResponseMapperResult = (state, getConfig) => getApiResultResponseMapperResult(
    state,
    getCurrentApiResultId(state),
    getConfig
  );

  // This will only be populated if 'saveResponseMapperResultToResult' is set on the endpoint config
  const useResponseMapperResult = applyWrapper(
    getConfig => useSelector(
      state => getResponseMapperResult(state, getConfig)
    ),
    getConfig => ({
      selector: (state, overrideGetConfig) => getResponseMapperResult(state, overrideGetConfig || getConfig)
    })
  );

  const getPagination = (state, getConfig) => getApiResultPagination(
    state,
    getCurrentApiResultId(state),
    getConfig
  );

  const usePagination = applyWrapper(
    getConfig => useSelector(
      state => getPagination(state, getConfig)
    ),
    getConfig => ({
      selector: (state, overrideGetConfig) => getPagination(state, overrideGetConfig || getConfig)
    })
  );

  return {
    // To apply to any hooks that the create implementation generates
    applyWrapper,
    entryApiResultId,
    hookConfig,
    // These should all be exposed with the implementation (execute or provide)
    Hooks: {
      useEntities,
      useEntity,
      useInvalidateState,
      useIsLoading,
      usePagination,
      useRequestError,
      useRequestParams,
      useResponse,
      useResponseMapperResult,
      useWillLoadOrIsLoading
    },
    Redux: {
      getEntities,
      getEntity,
      getIsLoading,
      getPagination,
      getRequestError,
      getRequestParams,
      getResponse,
      getResponseMapperResult,
      getWillLoadOrIsLoading,
      invalidateStateAction
    }
  };
};

export default createBaseApiResultHooks;