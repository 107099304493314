/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, { useState } from 'react';
import { Provider } from 'react-redux';

import createDefaultStore from 'stores';
import instrumentStore from 'stores/instrumentStore';

import getInitialState from './getInitialState';

let _store = null;

const getStore = () => {
  if (_store == null) {
    _store = createDefaultStore({
      initialState: getInitialState()
    });
  }

  return _store;
};

const StoreService = ({
  children,
  csrfToken,
  inExternalTestEnvironment
}) => {
  // Hooks
  const [ store ] = useState(
    () => instrumentStore(
      getStore(),
      {
        csrfToken,
        inExternalTestEnvironment
      }
    )
  );

  // Render
  return (
    <Provider store={store}>
      {children}
    </Provider>
  );
};

export default StoreService;