/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ActionDropdown from 'components/ActionDropdown';
import Button from 'components/Button';
import Checkbox from 'components/Form/Inputs/CheckBox';
import TinyListRowInternal, {
  TlrHeader,
  TlrLeftHeader,
  TlrRightHeader,
  TlrTitle,
  TlrFooter,
  TlrLeftFooter,
  TlrRightFooter,
  TlrSubFooter,
  TlrLeftSubFooter,
  TlrRightSubFooter
} from './Components';

/**
 * A mobile-friendly generic list item
 */
export default class TinyListRow extends Component {
  static propTypes = {
    /** Center content */
    title: PropTypes.node,

    leftFooter: PropTypes.node,
    rightFooter: PropTypes.node,
    leftHeader: PropTypes.node,
    rightHeader: PropTypes.node,
    leftSubFooter: PropTypes.node,
    rightSubFooter: PropTypes.node,

    onClick: PropTypes.func,
    /** Adds a click visual effect */
    isClickable: PropTypes.bool,
    /** Adds a new indicator/style */
    isNew: PropTypes.bool,

    canSelect: PropTypes.bool,
    isSelected: PropTypes.bool,
    onSelect: PropTypes.func,

    /** Optional title to use for actions dropdown (if it applies) */
    dropdownTitle: PropTypes.node,
    /** Forces dropdown to appear even if there is only one item */
    forceDropdown: PropTypes.bool,
    /** Displays footer elements inside of a discrete footer bar */
    hasFooterBar: PropTypes.bool,
    actions: PropTypes.arrayOf(PropTypes.shape({
      label: PropTypes.node,
      onClick: PropTypes.func
    }))
  };

  static defaultProps = {
    isClickable: true
  };

  renderActions() {
    let actions = null;
    let className = '';
    if (this.props.actions.length === 1 && !this.props.forceDropdown) {
      className = 'single-action';
      actions = (
        <Button anchor={true}
          onClick={this.props.actions[0].onClick}>
          {this.props.actions[0].label}
        </Button>
      );
    } else {
      className = 'multi-action';
      actions = (
        <ActionDropdown
          actions={this.props.actions}
          header={this.props.dropdownTitle}
        />
      );
    }
    return (
      <div className={`rowActions ${className}`}>
        <div onClick={e => e.stopPropagation()}>
          {actions}
        </div>
      </div>
    );
  }

  render() {
    let header = null;
    if (this.props.leftHeader || this.props.rightHeader || (this.props.actions && this.props.actions.length)) {
      header = (
        <TlrHeader>
          {this.props.canSelect ? (
            <span onClick={e => e.stopPropagation()} className="selectBox">
              <Checkbox value={this.props.isSelected}
                onChange={v => this.props.onSelect(v)} />
            </span>
          ) : null}
          {this.props.isNew
            ? <div className="newIndicator"><div className="indicator"></div></div>
            : null}
          {this.props.leftHeader
            ? <TlrLeftHeader>{this.props.leftHeader}</TlrLeftHeader>
            : null}
          {this.props.rightHeader
            ? <TlrRightHeader>{this.props.rightHeader}</TlrRightHeader>
            : null}
          {this.props.actions && this.props.actions.length > 0
            ? this.renderActions()
            : null}
        </TlrHeader>
      );
    }

    let title = null;
    if (this.props.title) {
      title = (
        <TlrTitle>
          {this.props.title}
        </TlrTitle>
      );
    }

    let footer = null;
    if (this.props.leftFooter || this.props.rightFooter) {
      footer = (
        <TlrFooter>
          {this.props.leftFooter
            ? <TlrLeftFooter>{this.props.leftFooter}</TlrLeftFooter>
            : null}
          {this.props.rightFooter
            ? <TlrRightFooter>{this.props.rightFooter}</TlrRightFooter>
            : null}
        </TlrFooter>
      );
    }

    let subFooter = null;
    if (this.props.leftSubFooter || this.props.rightSubFooter) {
      subFooter = (
        <TlrSubFooter>
          {this.props.leftSubFooter
            ? <TlrLeftSubFooter>{this.props.leftSubFooter}</TlrLeftSubFooter>
            : null}
          {this.props.rightSubFooter
            ? <TlrRightSubFooter>{this.props.rightSubFooter}</TlrRightSubFooter>
            : null}
        </TlrSubFooter>
      );
    }

    let onClick = null;

    if (this.props.onClick) {
      onClick = (e) => {
        if (this.props.canSelect) {
          if (e.target && e.target.type === 'checkbox') {
            return;
          }
        }
        this.props.onClick(e);
      };
    }

    return (
      <TinyListRowInternal onClick={onClick}
        isClickable={this.props.isClickable}
        isNew={this.props.isNew}
        isSelected={this.props.isSelected}
        hasFooterBar={this.props.hasFooterBar}>
        {header}
        {title}
        {footer}
        {subFooter}
      </TinyListRowInternal>
    );
  }
}
