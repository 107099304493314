/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React from 'react';
import objectHash from 'object-hash';
import qs from 'qs';
import { Redirect, Route, withRouter } from 'react-router';

import useSessionInfo from 'hooks/identity/useSessionInfo';

import logError from 'services/ErrorService';

import { ErrorLoggingInAppNotFound } from 'views/Static/InAppNotFound';

export const flattenHashLocation = (location, newBase) => {
  let hash = '';
  if (location.hash) {
    if (location.hash.startsWith('#/')) {
      hash = location.hash.replace('#/', newBase || '/');
    } else {
      hash = location.hash.replace('#', newBase || '/');
    }
  }

  return {
    ...location,
    hash: undefined,
    pathname: `${location.pathname}${hash}`
  };
};

export const InAppNotFoundView = (
  <Route path="*">
    <ErrorLoggingInAppNotFound />
  </Route>
);

// Doing this to preserve the current URL on state change. When implemented, I believe this didn't
// cause the URL to to be changed to the root URL. At minimum we want to stay on the same URL for
// logging purposes, but the intent was never to send someone to a new URL on a pushState change.
// This behavior was most likely introduced with the upgrade from react router 4.2 to 4.3 or it
// could have been introduced with connected-react-router
const InnerNotFound = withRouter(({ location: { pathname, search } }) => (
  <Redirect to={{ pathname, search, state: { hasNotFoundError: true } }} />
));

export const NotFoundRedirect = <InnerNotFound path="*" />;

export const VendorPersonalPortalDefaultRedirect = <Redirect from="*" to="/payments/received" />;

export const CustomerPersonalPortalDefaultRedirect = <Redirect from="*" to="/bills/open" />;

export const VendorPortalDefaultRedirect = <Redirect from="*" to="/payments/received" />;

export const CustomerPortalDefaultRedirect = <Redirect from="*" to="/bills/list" />;

export const ConditionalNotFound = isTrueThenNotFound => isTrueThenNotFound ? NotFoundRedirect : null;

export const ConditionalInAppNotFound = isTrueThenNotFound => isTrueThenNotFound ? InAppNotFoundView : null;

export const getAuthRedirect = ({
  isPersonal,
  isCustomerPortal,
  isVendorPortal
}) => {
  if (isPersonal) {
    if (isVendorPortal) return VendorPersonalPortalDefaultRedirect;
    if (isCustomerPortal) return CustomerPersonalPortalDefaultRedirect;
  } else {
    if (isVendorPortal) return VendorPortalDefaultRedirect;
    if (isCustomerPortal) return CustomerPortalDefaultRedirect;
  }
};

export const AuthRedirect = () => {
  const {
    isPersonal,
    isCustomerPortal,
    isVendorPortal
  } = useSessionInfo();

  const redirect = getAuthRedirect({
    isPersonal,
    isCustomerPortal,
    isVendorPortal
  });

  if (redirect) return redirect;

  return <Redirect to="/dashboard" />;
};

export const DefaultAuthRedirect = <Route path="*" component={AuthRedirect} />;

export const getRouteParam = (props, name) => {
  if (!props || !props.match) {
    logError('getRouteParam: Params are missing');
    return null;
  }

  return props.match.params[name];
};

export const getQuery = (location) => {
  const searchString = location.search;
  const query = qs.parse(searchString.startsWith('?')
    ? searchString.substr(1)
    : searchString);

  return query || {};
};

export const getQueryParam = (props, param) => {
  if (!props || !props.location) {
    // logError('getQueryParam: Location is missing');
    return null;
  }

  const query = getQuery(props.location);
  let keyMap = Object.keys(query).reduce(
    (val, key) => ({...val, [key.toLowerCase()]: key}),
    {});
  if (keyMap[param.toLowerCase()]) return query[keyMap[param.toLowerCase()]];
  return undefined;
};

export const getQueryParams = (
  props,
  {
    paramKeys,
    removeKeys
  } = {}
) => {
  if (!props || !props.location) {
    logError('getQueryParams: Location is missing');
    return {};
  }

  let query = { ...getQuery(props.location) };

  if (removeKeys) {
    Object.keys(query).forEach((key) => {
      if (removeKeys(key, query[key])) {
        delete query[key];
      }
    });
  }

  if (!paramKeys) return query;

  return paramKeys.reduce((val, key) => {
    return {
      ...val,
      [key]: query[key]
    };
  }, {});
};

export const getPathname = (props) => {
  if (!props || !props.location) {
    logError('getPathname: Location is missing');
    return null;
  }
  return props.location.pathname;
};

export const createSearchString = (query) => {
  return `?${qs.stringify(query)}`;
};

const warningsAlreadyShown = {};

const displayWarningIfNotShown = (warning, isError) => {
  const err = new Error(warning);
  const errorHash = objectHash(err.stack.toString());
  if (!warningsAlreadyShown[errorHash]) {
    if (isError) console.error(err);
    else console.warn(err);
    warningsAlreadyShown[errorHash] = true;
  }
};

/**
 * This function is a crutch to identify paths where router props are in play or not
 */
export const getRouteProps = (props, routerState, quietWarning) => {
  if (props && props.location && props.route) {
    if (!quietWarning) {
      displayWarningIfNotShown(`Routing props are being used.
        Remove the usage of 'getRouteProps' on this path and replace with 'props'.`);
    }
    return props;
  }

  if (!quietWarning) {
    displayWarningIfNotShown(`Routing props are missing.
      Use 'react-router withRouter' to inject them into the component`, true);
  }
  return routerState;
};
