/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { createApiAction } from 'api/core';

export const submitElectronicPaymentInterest = createApiAction({
  method: 'post',
  url: '/api/token/:secureToken/submitElectronicPaymentInterest',
  requestBodyMapper: ({
    firstName,
    lastName,
    title,
    phoneNumber,
    phoneExtension
  }) => ({
    firstName,
    lastName,
    title,
    phoneNumber,
    phoneExtension
  })
});