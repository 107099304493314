/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, { memo, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Route, Redirect, Switch } from 'react-router';
import { useLocation } from 'react-router-dom';

import { getUseAppAuthorizationStatus } from 'actions/common/isAuthorizedToUseApp';

import { wrapImport } from 'decorators/ModuleLoader';

import useIsAuthorizedToUseApp from 'hooks/identity/useIsAuthorizedToUseApp';

import { UseAppAuthorizationReason } from 'schemas/session/UseAppAuthorizationStatus';

import LogonLayout from 'views/Logon/LogonLayout';
import LogonWorkflowView from 'views/Logon/Workflow';
import { ErrorLoggingNotFoundView } from 'views/Static/notFound';
import NotAuthorized from 'views/Static/notAuthorized';
import ServerError from 'views/Static/serverError';
import RemittanceLanding from 'views/Onboarding/RemittanceLanding';
import RemittanceLandingDetails from 'views/Onboarding/RemittanceLanding/Details';

import InAppRoutes, { InAppPages, InAppRoots } from './inApp';
import LogOnRoutes from './logon';
import OnboardingRoutes, {
  DocumentRedirects,
  LandingRoutes,
  ReferralRedirects,
  GetStartedRedirects
} from './onboarding';
import TermsRoutes from './terms';
import RegistrationRoutes from './registration';
import { getPathname, NotFoundRedirect } from './utils';

const SignWithMobile = wrapImport(
  () => import(/* webpackChunkName: "signByMobile" */ 'views/Settings/PaymentMethods/SignWithMobile')
);
// const FeatureTogglePage = wrapImport(() => import(/* webpackChunkName: "aux" */ 'views/Auxillary/FeatureToggles'));

const ResetPasswordRedirect = ({ match }) => {
  if (match.params.id) {
    return <Redirect to={`/logon/resetPassword/${match.params.id}`} />;
  }

  return NotFoundRedirect;
};

const ResetPasswordLegacyRedirects = ({ match }) => (
  <Switch>
    <Redirect from={match.url} exact={true} to="/logon/resetPassword" />
    <Route path={`${match.url}/reset/:id`} exact={true} component={ResetPasswordRedirect} />
    {NotFoundRedirect}
  </Switch>
);

const FatalErrorRoutes = ({ match }) => (
  <Switch>
    <Route path={`${match.url}/not-authorized`} exact={true} component={NotAuthorized} />
    <Route path={`${match.url}/not-found`} exact={true}>
      <ErrorLoggingNotFoundView />
    </Route>
    <Route path={`${match.url}/general`} exact={true} component={ServerError} />
    {NotFoundRedirect}
  </Switch>
);

const AppRoutes = memo(() => {
  const isAuthorizedToUseApp = useIsAuthorizedToUseApp();
  const isLoggingOut = useSelector(
    state => getUseAppAuthorizationStatus(state)?.reason === UseAppAuthorizationReason.LogOut
  );
  const location = useLocation();
  const pathname = getPathname({ location });
  const { hasNotFoundError } = (location.state || {});

  const [
    {
      hasNotFoundError: hasNotFoundErrorState,
      prevPathname
    },
    setState
  ] = useState({});

  useEffect(
    () => {
      if (hasNotFoundErrorState) {
        if (pathname !== prevPathname) {
          setState({ hasNotFoundError: false, prevPathname: null });
        }
      }

      if ((location.state || {}).hasNotFoundError) {
        setState({ hasNotFoundError: true, prevPathname: pathname });
      }
    },
    [ pathname, hasNotFoundError ]
  );

  const inAppRoutes = useMemo(
    () => isAuthorizedToUseApp ? (
      <InAppRoutes />
    ) : (
      <Switch>
        <Route path="/" exact={true}>
          <Redirect to="/logon" />
        </Route>
        {isLoggingOut ? (
          <Redirect to="/logon" />
        ) : (
          <LogonLayout>
            <LogonWorkflowView isOnAppRoute={true} />
          </LogonLayout>
        )}
      </Switch>
    ),
    [ isAuthorizedToUseApp, isLoggingOut ]
  );

  return (
    <Switch>
      {hasNotFoundErrorState ? (
        <Route path="*">
          <ErrorLoggingNotFoundView />
        </Route>
      ) : null}
      <Route path={InAppPages} exact={true}>
        {inAppRoutes}
      </Route>
      <Route path={InAppRoots}>
        {inAppRoutes}
      </Route>
      <Route path="/register" component={RegistrationRoutes} />
      <Route path="/logon">
        <LogOnRoutes />
      </Route>
      <Route path="/login" exact={true}>
        <Redirect to="/logon" />
      </Route>
      <Route path="/resetPassword" component={ResetPasswordLegacyRedirects} />
      <Route path="/join" exact={true}>
        <Redirect to="/register" />
      </Route>
      <Route path="/document" component={DocumentRedirects} />
      <Route path="/landing" component={LandingRoutes} />
      <Route path="/remittance" component={RemittanceLanding} exact={true} />
      <Route path="/remittance/:secureToken" component={RemittanceLandingDetails} exact={true} />
      <Route path="/referral" component={ReferralRedirects} />
      <Route path="/getStarted" component={GetStartedRedirects} />
      <Route path="/get-started" component={GetStartedRedirects} />
      <Route path="/onboarding" component={OnboardingRoutes} />
      <Route path="/signWithMobile/:secureToken" exact={true} component={SignWithMobile} />
      <Route path="/terms-and-conditions" component={TermsRoutes} />

      {/* Legacy terms routes */}
      <Redirect from="/terms" exact={true} to="/terms-and-conditions" />
      <Redirect from="/privacy" exact={true} to="/terms-and-conditions/privacy" />
      <Redirect from="/payment-terms" exact={true} to="/terms-and-conditions/payment" />

      {/* <Route path="/features" exact={true}>
        <FeatureTogglePage />
      </Route> */}
      <Route path="/error" component={FatalErrorRoutes} />
    </Switch>
  );
});

export default AppRoutes;
