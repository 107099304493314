/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import InvoiceFilterApi from 'api/invoice/filter';
import { CorrelationId, ListStatusFilters, ListDateFields } from 'config/constants/invoice';
import moment from 'moment';
import { getFilters, setFilters } from 'actions/route';

function areSameDates(date1, date2) {
  if (!date1 && !date2) {
    return true;
  }
  if (date1 && date1.getTime
      && date2 && date2.getTime) {
    // Date
    return date1.getTime() === date2.getTime();
  }
  return date1 === date2;
}

export function haveFiltersChanged(filterSetA, filterSetB) {
  if (!filterSetA && !filterSetB) {
    return false;
  }

  if (!areSameDates(filterSetA.beginDate, filterSetB.beginDate)
    || !areSameDates(filterSetA.endDate, filterSetB.endDate)) {
    return true;
  }

  let keysToCompare = [
    'status',
    'addressBookId',
    'companyId',
    'companyNodeId',
    'search',
    'searchType',
    'accountId',
    'inbox'
  ];

  let anyChanged = keysToCompare.some((key) => {
    return filterSetA[key] !== filterSetB[key];
  });

  return anyChanged;
}

// Check if the page has changed
export function hasPageChanged(page1, page2) {
  if (!page1 && !page2) {
    return false;
  }
  return Number(page1) !== Number(page2);
}

export function getCurrentFilters(state) {
  if (!state) throw new Error('Redux state is required to get current filters!');

  let filters = getFilters(state);

  // string -> Date
  let beginDate = null;
  if (filters.beginDate) {
    beginDate = moment(filters.beginDate, 'MM/DD/YYYY').toDate();
  }
  let endDate = null;
  if (filters.endDate) {
    endDate = moment(filters.endDate, 'MM/DD/YYYY').toDate();
  }

  let status = null;
  if (filters.status) {
    status = filters.status.toLowerCase();
  }

  return {
    ...filters,
    status: status,
    beginDate,
    endDate
  };
}

export const getDateFieldFromStatus = (originalStatus) => {
  if (!originalStatus) {
    return 'DueDate';
  }

  const status = originalStatus.toLowerCase();
  if (status === ListStatusFilters.AllClosed.toLowerCase()
    || status === ListStatusFilters.Closed.toLowerCase()) {
    return ListDateFields.CloseDate;
  } if (status === ListStatusFilters.Scheduled.toLowerCase()) {
    return ListDateFields.ScheduledPayDate;
  } if (status === ListStatusFilters.Void.toLowerCase()) {
    return ListDateFields.InvoiceDate;
  }

  return ListDateFields.DueDate;
};

export const getDateField = state => getDateFieldFromStatus(getCurrentFilters(state).status);

export function setDateFilters(newFilters) {
  return (dispatch, getState) => {
    let filters = getCurrentFilters(getState());

    // Date -> string
    if (newFilters.beginDate) {
      filters.beginDate = moment(newFilters.beginDate).format('MM/DD/YYYY');
    } else {
      filters.beginDate = null;
    }
    if (newFilters.endDate) {
      filters.endDate = moment(newFilters.endDate).format('MM/DD/YYYY');
    } else {
      filters.endDate = null;
    }

    dispatch(setFilters(filters));
  };
}

export function invoiceSearchForInvoiceList(searchPhrase) {
  return dispatch => dispatch(InvoiceFilterApi.invoiceSearchForInvoiceList({
    searchPhrase: searchPhrase || ''
  }, null, CorrelationId.SearchCompanies)
  );
}

export function invoiceSearchForBillsInbox(searchPhrase) {
  return dispatch => dispatch(InvoiceFilterApi.invoiceSearchForBillsInbox({
    searchPhrase: searchPhrase || ''
  }, null, CorrelationId.SearchCompanies)
  );
}

export function invoiceSearchForBillsList(searchPhrase) {
  return dispatch => dispatch(InvoiceFilterApi.invoiceSearchForBillsList({
    searchPhrase: searchPhrase || ''
  }, null, CorrelationId.SearchCompanies)
  );
}

export function statusLoadForInvoiceList() {
  return (dispatch, getState) => {
    let filters = getCurrentFilters(getState());
    dispatch(InvoiceFilterApi.statusLoadForInvoiceList({
      companyId: filters.companyId,
      addressBookId: filters.addressBookId,
      beginDate: filters.beginDate || '',
      endDate: filters.endDate || '',
      dateField: getDateField(getState())
    }, null, CorrelationId.SearchStatuses));
  };
}

export function statusLoadForBillsInbox() {
  return (dispatch, getState) => {
    let filters = getCurrentFilters(getState());
    dispatch(InvoiceFilterApi.statusLoadForBillsInbox({
      companyId: filters.companyId,
      addressBookId: filters.addressBookId,
      beginDate: filters.beginDate,
      endDate: filters.endDate
    }, null, CorrelationId.SearchStatuses));
  };
}

export function statusLoadForBillsList() {
  return (dispatch, getState) => {
    let filters = getCurrentFilters(getState());
    dispatch(InvoiceFilterApi.statusLoadForBillsList({
      companyId: filters.companyId,
      addressBookId: filters.addressBookId,
      beginDate: filters.beginDate,
      endDate: filters.endDate,
      dateField: getDateField(getState())
    }, null, CorrelationId.SearchStatuses));
  };
}
