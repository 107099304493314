/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import LogonFailureReason, {
  CredentialsFailureReason,
  SecurityQuestionFailureReason,
  TwoFactorFailureReason
} from 'config/constants/session/LogonFailureReason';

import {
  UseAppAuthorizationReason,
  createUseAppAuthorizedEntity
} from 'schemas/session/UseAppAuthorizationStatus';

const actionRequiredNumberToEnum = (actionRequired) => {
  switch (actionRequired) {
    case 0: return 'None';
    case 1: return 'SecurityChallenge';
    case 2: return 'PromptToKickExistingSession';
    case 3: return 'MustRecoverPassword';
    case 4: return 'TwoFactorAuthenticationRequired';
    default: throw new Error(`Unknown actionRequired value ${actionRequired}`);
  }
};

const failureReasonNumberToEnum = (failureReason) => {
  switch (failureReason) {
    case 0: return 'None';
    case 1: return 'InvalidUserName';
    case 2: return 'InvalidPassword';
    case 3: return 'UserInactive';
    case 4: return 'UserTemporaryLockOccurred';
    case 5: return 'UserTemporarilyLockedOut';
    case 6: return 'UserLockedOut';
    case 7: return 'UserLockedOutGeneric';
    case 8: return 'UserVerificationRequired';
    case 9: return 'UserHasActiveSession';
    case 10: return 'InvalidTwoFactorAuthenticationCode';
    case 11: return 'PasswordExpired';
    case 12: return 'CompanyLocked';
    case 13: return 'InvalidSecurityAnswer';
    case 14: return 'CompanyClosed';
    case 15: return 'UserDoesNotBelongToCompany';
    default: throw new Error(`Unknown failureReason value ${failureReason}`);
  }
};

function createFailureResult(
  // Response
  {
    actionRequired,
    encryptedUserId,
    failureReason,
    lastFourOfTwoFactorPhoneNumber,
    securityQuestions
  },
  // Request Params
  {
    username,
    userName
  }
) {
  const createResult = result => ({
    ...result,
    userName: username || userName
  });

  function mapSecurityQuestions() {
    if (securityQuestions && securityQuestions.length) {
      return securityQuestions.map(
        (securityQuestion) => {
          return {
            id: securityQuestion.id,
            question: securityQuestion.question
          };
        }
      );
    }
  }

  if (actionRequired === 'None') {
    switch (failureReason) {
      case 'InvalidUserName':
        return createResult({
          failure: LogonFailureReason.InvalidCredentials,
          credentialsFailure: CredentialsFailureReason.InvalidUserName
        });
      case 'InvalidPassword':
        return createResult({
          failure: LogonFailureReason.InvalidCredentials,
          credentialsFailure: CredentialsFailureReason.InvalidPassword
        });
      case 'UserInactive':
        return createResult({
          failure: LogonFailureReason.UserInactive
        });
      case 'UserTemporaryLockOccurred':
        return createResult({
          failure: LogonFailureReason.TemporarilyLocked
        });
      case 'UserTemporarilyLockedOut':
        return createResult({
          failure: LogonFailureReason.TemporarilyLocked
        });
      case 'UserLockedOut':
        return createResult({
          failure: LogonFailureReason.PermanentlyLocked
        });
      case 'UserLockedOutGeneric':
        return createResult({
          failure: LogonFailureReason.PermanentlyLocked
        });
      case 'UserVerificationRequired':
        return createResult({
          failure: LogonFailureReason.EmailVerificationRequired,
          encryptedUserId
        });
      case 'UserHasActiveSession':
        return createResult({
          failure: LogonFailureReason.ExistingSession
        });
      case 'InvalidTwoFactorAuthenticationCode':
        return createResult({
          failure: LogonFailureReason.TwoFactorRequired,
          twoFactorFailure: TwoFactorFailureReason.InvalidCode,
          twoFactorPhoneNumber: lastFourOfTwoFactorPhoneNumber
            ? `000000${lastFourOfTwoFactorPhoneNumber}`
            : null
        });
      case 'PasswordExpired':
        return createResult({
          failure: LogonFailureReason.PasswordExpired
        });
      case 'CompanyLocked':
        return createResult({
          failure: LogonFailureReason.CompanyLocked
        });
      case 'InvalidSecurityAnswer':
        return createResult({
          failure: LogonFailureReason.SecurityQuestionAuthRequired,
          securityQuestionFailure: SecurityQuestionFailureReason.InvalidAnswer,
          securityQuestions: mapSecurityQuestions()
        });
      case 'CompanyClosed':
        return createResult({
          failure: LogonFailureReason.CompanyClosed
        });
      case 'UserDoesNotBelongToCompany':
        return createResult({
          failure: LogonFailureReason.DoesNotBelongToCompany
        });
      default: break;
    }
  }

  switch (actionRequired) {
    case 'SecurityChallenge':
      return createResult({
        failure: LogonFailureReason.SecurityQuestionAuthRequired,
        securityQuestions: mapSecurityQuestions()
      });
    case 'PromptToKickExistingSession':
      return createResult({
        failure: LogonFailureReason.ExistingSession
      });
    case 'MustRecoverPassword':
      return createResult({
        failure: LogonFailureReason.RecoverPasswordRequired
      });
    case 'TwoFactorAuthenticationRequired':
      return createResult({
        failure: LogonFailureReason.TwoFactorRequired,
        twoFactorPhoneNumber: lastFourOfTwoFactorPhoneNumber
          ? `000000${lastFourOfTwoFactorPhoneNumber}`
          : null
      });
    default: break;
  }
}

const logonResponseMapper = (
  {
    messages,
    response,
    success: isRealSuccess
  },
  state,
  requestParams
) => {
  let result;
  const entities = [];

  if (isRealSuccess) {
    const {
      actionRequired: originalActionRequired,
      failureReason: originalFailureReason,
      success: isLogonSuccess,
      token
    } = response;

    // The logon endpoint has a tertiary (!!!) success flag to indicate the
    // the logon request was complete and we have established authentication
    // (but not necessarily authorization yet). Not every endpoint does this so
    // if we don't see it, assume all is good.
    const isReallySuccessful = isLogonSuccess == null ? true : isLogonSuccess;

    const actionRequired = Number.isInteger(originalActionRequired)
      ? actionRequiredNumberToEnum(originalActionRequired)
      : originalActionRequired;

    const failureReason = Number.isInteger(originalFailureReason)
      ? failureReasonNumberToEnum(originalFailureReason)
      : originalFailureReason;

    if (isReallySuccessful && actionRequired === 'None' && failureReason === 'None') {
      if (token === 'Created') {
        entities.push(createUseAppAuthorizedEntity(UseAppAuthorizationReason.LogOn));
      } else {
        result = {
          failure: LogonFailureReason.VerifyAccountRequired
        };
      }
    }

    result = result
      || createFailureResult(
        {
          ...response,
          actionRequired,
          failureReason
        },
        requestParams
      );
  } else {
    if (messages?.length) {
      if (messages.some(
        ({ context, type }) => context === 'ChangeEmailToken' && type === 'Validation')
      ) {
        result = {
          failure: LogonFailureReason.ChangeEmailTokenInvalid
        };
      }
    }
  }

  // JL Note: I have a separate refactor in-flight to have all additional fields
  // in the payload moved to 'result', so I'm doing that ahead of that refactor
  // going in. Maybe some day it will see the light of day.
  return { entities, result };
};

export default logonResponseMapper;