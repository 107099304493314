/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, { Component } from 'react';
import GuidedTour from 'components/GuidedTour';
import { connect } from 'react-redux';
import moment from 'moment';
import { MapStateToProps, MapDispatchToProps } from './index.connect';

/** @docsignore - This should be documented via GuidedTour component */
class GuidedTourService extends Component {

  componentDidUpdate(props) {
    if (this.tourRef
      && !moment(props.timestamp).isSame(this.props.timestamp)
      && this.props.steps) {
      this.startTour();
    }
  }

  startTour() {
    if (this.props.tourOptions.acknowledgementKey
      && this.props.isAcknowledged(this.props.tourOptions.acknowledgementKey)) {
      // tour has already been seen
      return;
    }

    // restart the tour with the current steps
    this.tourRef.reset();
    let delay = this.props.tourOptions && this.props.tourOptions.delay
      ? this.props.tourOptions.delay
      : 1000;
    setTimeout(this.tourRef.start(this.props.tourOptions.startWithFirstTooltipOpen), delay);
  }

  onEndTour(...args) {
    if (this.props.tourOptions.acknowledgementKey) {
      this.props.setAcknowledgement(this.props.tourOptions.acknowledgementKey);
    }

    if (this.props.tourOptions.onEnd) {
      this.props.tourOptions.onEnd(...args);
    }
  }

  render() {
    return (
      <GuidedTour
        ref={(ref) => {
          this.tourRef = ref;
          if (this.props.tourOptions && this.props.tourOptions.tourRef) {
            this.props.tourOptions.tourRef(ref);
          }
        }}
        steps={this.props.steps || []}
        runOnMount={false}
        {...this.props.tourOptions}
        onEnd={(...args) => this.onEndTour(...args)}/>
    );
  }
}

export default connect(MapStateToProps, MapDispatchToProps)(GuidedTourService);
