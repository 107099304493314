/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React from 'react';
import { Route, Redirect, Switch } from 'react-router';
import { wrapImport } from 'decorators/ModuleLoader';
import { ListStatusFilters } from 'config/constants/invoice';
import { InvoiceType } from 'schemas/invoicing/invoice';
import { ConditionalInAppNotFound, flattenHashLocation, InAppNotFoundView } from '../utils';
import { createOldInvoiceDetailsRedirect } from './invoices.utils';

const BillsListPage = wrapImport(() => import(/* webpackChunkName: "views" */ 'views/Invoices/BillsList'));
const InvoiceStatementsList = wrapImport(() => import(/* webpackChunkName: "views" */ 'views/Invoices/Statements'));
const InvoiceStatementDetailsList = wrapImport(
  () => import(/* webpackChunkName: "views" */ 'views/Invoices/Statements/detailsList')
);
const SelectOutOfBandInvoiceAttachment = wrapImport(
  () => import(/* webpackChunkName: "views" */ 'views/Invoices/selectOutOfBandInvoiceAttachment')
);
const EditOutOfBandInvoice = wrapImport(
  () => import(/* webpackChunkName: "views" */ 'views/Invoices/editOutOfBandInvoice')
);
const CreateInvoice = wrapImport(() => import(/* webpackChunkName: "views" */ 'views/Invoices/CreateInvoice'));
const PaymentsQueue = wrapImport(() => import(/* webpackChunkName: "views" */ 'views/Payments/Queue/EditStep'));
const PaymentsQueueReview = wrapImport(() => import(/* webpackChunkName: "views" */ 'views/Payments/Queue/ReviewStep'));
const RecordBillPayment = wrapImport(
  () => import(/* webpackChunkName: "views" */ 'views/Invoices/RecordPayment/payablesPage')
);
const EarlyPayOffersListView = wrapImport(() => import(/* webpackChunkName: "views" */ 'views/Bills/EarlyPayOffers'));
const APInboxCreateBills = wrapImport(
  () => import(/* webpackChunkName: "views" */ 'views/Invoices/APInbox/createBills')
);
const PersonalBillDetails = wrapImport(
  () => import(/* webpackChunkName: "personalPortal" */ 'views/PersonalPortal/Bills/BillDetails')
);
const InvoiceDetails = wrapImport(() => import(/* webpackChunkName: "views" */ 'views/Invoices/InvoiceDetails'));
const PersonalBillsList = wrapImport(
  () => import(/* webpackChunkName: "personalPortal" */ 'views/PersonalPortal/Bills/BillsList')
);

export const LegacyPayablesRoutes = ({ match, location }) => {
  return (
    <Switch location={flattenHashLocation(location)}>
      <Redirect path={match.url} exact={true} to="/bills" />
      <Redirect path={`${match.url}/inbox`} exact={true} to="/bills/inbox" />
      <Redirect path={`${match.url}/companySummary`} exact={true} to="/bills/summary" />
      <Redirect path={`${match.url}/payableInvoices`} exact={true} to="/bills/list" />
      <Redirect path={`${match.url}/vpx`} exact={true} to="/bills/vpx" />
      <Redirect path={`${match.url}/create`} exact={true} to="/bills/create" />
      <Redirect path={`${match.url}/settlement/view/details/:id`} exact={true} to="/payments/details/sent/:id" />
      <Redirect path={`${match.url}/payments/:id`} exact={true} to="/payments/details/sent/:id" />
      <Redirect path={`${match.url}/payments`} exact={true} to="/payments/sent" />
      <Redirect path={`${match.url}/payment`} exact={true} to="/bills/recordPayment" />
      <Redirect path={`${match.url}/paymentQueue`} exact={true} to="/bills/paymentQueue" />
      <Route path={`${match.url}/:context/view/:id`} exact={true}
        component={createOldInvoiceDetailsRedirect()} />
      <Route path={`${match.url}/:context/view/:id/:ownership`} exact={true}
        component={createOldInvoiceDetailsRedirect()} />
      <Redirect path="*" to="/bills/list" />
    </Switch>
  );
};

export const BusinessBillsRoutes = ({ isPortalExperience, match }) => (
  <Switch>
    {/* Redirects */}
    <Route path={match.url} exact={true} render={() => <Redirect to={`${match.url}/list`} />} />
    <Route path={`${match.url}/ng`} component={LegacyPayablesRoutes} />
    <Route path={`${match.url}/inbox`} exact={true} render={() => <Redirect to={`${match.url}/list`} />} />
    <Route path={`${match.url}/mailbox`} exact={true} render={() => <Redirect to={`${match.url}/list`} />} />
    {/* Routes */}
    <Route
      path={`${match.url}/create`}
      exact={true}
      component={props => <CreateInvoice invoiceType={InvoiceType.meta.map.Payable} {...props} />}
    />
    <Route
      path={`${match.url}/edit/:type/:id`}
      exact={true}
      component={props => <CreateInvoice invoiceType={InvoiceType.meta.map.Payable} {...props} />}
    />
    <Route path={`${match.url}/list`} exact={true} component={BillsListPage} />
    <Route path={`${match.url}/details/:type/:id`} exact={true} component={InvoiceDetails} />
    <Route path={`${match.url}/vpx`} exact={true} component={EarlyPayOffersListView} />
    <Route path={`${match.url}/statements`} exact={true} component={InvoiceStatementsList} />
    <Route path={`${match.url}/statements/:id`} exact={true} component={InvoiceStatementDetailsList} />
    <Route path={`${match.url}/paymentQueue`} exact={true} component={PaymentsQueue} />
    <Route path={`${match.url}/paymentQueue/review`} exact={true} component={PaymentsQueueReview} />
    <Route path={`${match.url}/recordPayment`} exact={true} component={RecordBillPayment} />
    {/* Corporate Routes */}
    {ConditionalInAppNotFound(isPortalExperience)}
    <Route path={`${match.url}/oob/:id/attachments`} exact={true} component={SelectOutOfBandInvoiceAttachment} />
    <Route path={`${match.url}/oob/:id/edit`} exact={true} component={EditOutOfBandInvoice} />
    <Route path={`${match.url}/vpinbox/:id/create`} exact={true} component={APInboxCreateBills} />
    {InAppNotFoundView}
  </Switch>
);

export const PersonalPortalBillsRoutes = ({ match }) => (
  <Switch>
    {/* Redirects */}
    <Redirect path={match.url} exact={true} to={`${match.url}/open`} />
    <Redirect path={`${match.url}/list`} exact={true} to={`${match.url}/open`} />
    {/* Routes */}
    <Route path={`${match.url}/open`} exact={true}
      component={props => (
        <PersonalBillsList filter={ListStatusFilters.AllOpen} {...props} />
      )}
    />
    <Route path={`${match.url}/closed`} exact={true}
      component={props => (
        <PersonalBillsList filter={ListStatusFilters.AllClosed} {...props} />
      )}
    />
    <Route path={`${match.url}/details/:type/:id`} exact={true} component={PersonalBillDetails} />
    {InAppNotFoundView}
  </Switch>
);

