/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { connect } from 'react-redux';

const ProvideIsTablet = connect(
  ({ deviceInfo: { isTablet} }) => ({
    isTablet
  }),
  null,
  null,
  { forwardRef: true }
);

export default ProvideIsTablet;
