/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */
/* eslint max-len:0 */

import { defineMessages } from 'react-intl';

let common = defineMessages({
  AddCompany: {
    id: 'AppFloatingActionButton.AddCompany',
    defaultMessage: 'Add Company'
  },
  CreateInvoice: {
    id: 'AppFloatingActionButton.CreateInvoice',
    defaultMessage: 'Create Invoice'
  },
  CreateBill: {
    id: 'AppFloatingActionButton.CreateBill',
    defaultMessage: 'Create Bill'
  },
  SendPayment: {
    id: 'AppFloatingActionButton.SendPayment',
    defaultMessage: 'Send Payment'
  },
  CreateSwitchCompany: {
    id: 'AppFloatingActionButton.CreateSwitchCompany',
    defaultMessage: 'Create New Company'
  }
});

export default {
  ...common
};
