/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import TextBox from 'components/Form/Inputs/TextBox';
import CheckBox from 'components/Form/Inputs/CheckBox';
import CurrencyTextBox from 'components/Form/Inputs/CurrencyTextBox';
import PhoneTextBox from 'components/Form/Inputs/PhoneTextBox';
import PostalCodeTextBox from 'components/Form/Inputs/PostalCodeTextBox';
import CreditCardDateTextBox from 'components/Form/Inputs/CreditCardDateTextBox';
import PercentTextBox from 'components/Form/Inputs/PercentTextBox';
import SelectPaymentMethod from 'containers/Form/Inputs/SelectPaymentMethod';
import SelectPaymentMethodV2 from 'containers/Form/Inputs/SelectPaymentMethod/V2.js';
import SelectIndustryType from 'containers/Form/Inputs/SelectIndustryType';
import IOSNativeDate from 'components/Form/Inputs/IOSNativeDate';
import RecaptchaInput from 'components/Recaptcha';

import BrowserDetection from 'services/BrowserDetection';
import Select from './Inputs/Select';
import DatePicker from './Inputs/DatePicker';
import DateRangePicker from './Inputs/DateRangePicker';
import DateTimePicker from './Inputs/DateTimePicker';

const isSubTypeWithName = (name, modelType) => modelType.meta.kind === 'subtype'
  && modelType.meta.name
  && modelType.meta.name.startsWith(name);

/**
 * Determine what the default form input component (and default props for it)
 * are for a given tcomb type
 */
export default function getDefaultInput(tcombType) {

  // drill into t.maybe()
  if (tcombType?.meta?.kind === 'maybe') {
    return getDefaultInput(tcombType.meta.type);
  }

  if (tcombType.meta.name === 'DateTime') {
    return { inputComponent: DateTimePicker };
  }

  // Date
  if (
    tcombType.meta.name === 'Date'
    || (tcombType.meta.kind === 'subtype' && tcombType.meta.type.meta.name === 'Date')
  ) {
    if (!BrowserDetection.isIOS() && !BrowserDetection.isAndroid()) {
      // Custom Datepicker
      return { inputComponent: DatePicker };
    }

    if (BrowserDetection.isIOS()) {
      return { inputComponent: IOSNativeDate };
    }

    // standard HTML5 date input
    return {
      inputComponent: TextBox,
      inputProps: {
        type: 'date'
      }
    };
  }

  if (tcombType.meta.name === 'DateRange') {
    return { inputComponent: DateRangePicker };
  }

  if (tcombType.meta.kind === 'enums') {
    return { inputComponent: Select };
  }

  if (tcombType.meta.kind === 'subtype' && tcombType.meta.name === 'CaptchaToken') {
    return { inputComponent: RecaptchaInput };
  }

  if (tcombType.meta.name === 'Boolean'
    || (tcombType.meta.kind === 'subtype' && tcombType.meta.type.meta.name === 'Boolean')) {
    return {
      inputComponent: CheckBox,
      inputProps: {
        willHideLabel: true
      }
    };
  }

  if (isSubTypeWithName('Currency', tcombType)) {
    return { inputComponent: CurrencyTextBox };
  }

  if (isSubTypeWithName('Phone', tcombType)) {
    return { inputComponent: PhoneTextBox };
  }

  if (isSubTypeWithName('PostalCode', tcombType)) {
    return { inputComponent: PostalCodeTextBox };
  }

  if (isSubTypeWithName('Percent', tcombType)) {
    return { inputComponent: PercentTextBox };
  }

  if (isSubTypeWithName('CardExpirationDate', tcombType)) {
    return { inputComponent: CreditCardDateTextBox };
  }

  if (isSubTypeWithName('PaymentMethodV2', tcombType)) {
    return { inputComponent: SelectPaymentMethodV2 };
  }

  if (isSubTypeWithName('PaymentMethod', tcombType)) {
    return { inputComponent: SelectPaymentMethod };
  }

  if (isSubTypeWithName('IndustryType', tcombType)) {
    return { inputComponent: SelectIndustryType };
  }

  // default to text input
  return { inputComponent: TextBox };
}