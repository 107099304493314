/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import qs from 'qs';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import useFuncRef from 'hooks/react/useFuncRef';

import setQuery from './utils/setQuery';

// Maintains the existing query string and modifies it with the provided values
const useChangeQueryParams = () => {
  // Hooks
  const dispatch = useDispatch();

  // Since we don't care about the location until we are changing it, just
  // get the reference to the history so we can get it later.
  const history = useHistory();

  const { current: changeQuery } = useFuncRef(
    () => (queryChange) => {
      const search = history.location?.search;

      const query = search ? qs.parse(
        search.startsWith('?')
          ? search.substr(1)
          : search
      ) : {};

      setQuery(
        {
          ...query,
          ...queryChange
        },
        {
          dispatch,
          history
        }
      );
    }
  );

  // Return
  return changeQuery;
};

export default useChangeQueryParams;