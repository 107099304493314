/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { cloneDeep } from 'lodash';

import { ModalActionTypes } from 'config/constants';

import { isNukeStateAction, newState } from './utils';

export default (
  state = {},
  action
) => {
  const stack = state.stack || [];
  const lastModalId = state.lastModalId || 0;

  if (action.type === ModalActionTypes.Show) {
    const {
      modalType,
      modalParams,
      onCloseCallbacks,
      viewParams
    } = action;

    if (!modalType) throw new Error(`Cannot have null modalType`);

    const modalId = lastModalId + 1;

    return newState(
      state,
      {
        stack: stack.concat(modalId),
        lastModalId: modalId,
        config: {
          ...(state.config || {}),
          [modalId]: {
            modalName: modalType,
            modalParams: cloneDeep(modalParams || {}),
            onCloseCallbacks,
            viewParams: cloneDeep(viewParams || {})
          }
        }
      }
    );
  } if (action.type === ModalActionTypes.Close) {
    if (stack.length < 1) return state;

    const newStack = [ ...stack ];

    const modalIdToClose = newStack.pop();

    const newConfig = { ...(state.config || {}) };
    delete newConfig[modalIdToClose];

    return newState(state, {
      stack: newStack,
      config: newConfig
    });
  }

  if (isNukeStateAction(action, 'Modals', true, [401, 403])) {
    return {};
  }

  return state;
};
