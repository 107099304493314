/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import OnboardingApi, { AccountSetupData, getAccountSetup } from 'api/onboarding';
import { createCacheActions } from 'actions/references';

export const GetAccountSetupDataActions = createCacheActions(
  'GetAccountSetupData',
  getAccountSetup
);

export const loadDocumentsFromToken = (secureToken) => {
  return (dispatch) => {
    dispatch(OnboardingApi.loadDocumentsFromToken({secureToken}));
  };
};

export const isAccountSetupDataLoading = state => GetAccountSetupDataActions.isCacheLoading(state);

export const getAccountSetupData = state => GetAccountSetupDataActions.getCacheEntity(
  state,
  ({ type }) => type === AccountSetupData.meta.name
);

export const getFirstOnboardingProfile = (state) => {
  const accountSetupData = getAccountSetupData(state);
  const { onboardingProfiles } = accountSetupData || {};
  return (onboardingProfiles || [])[0];
};

export const getFirstInvitationDocument = (state) => {
  const accountSetupData = getAccountSetupData(state);
  const { invitationDocuments } = accountSetupData || {};
  return (invitationDocuments || [])[0];
};
