/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { createLegacyAvailableActions } from 'config/constants/actionsRegistry';

const NetworkActions = createLegacyAvailableActions('NetworkActions', {
  Invite: 'InviteCompany',
  Reinvite: 'ReinviteCompany',
  Send: 'SendConnectionRequest',
  Resend: 'ResendConnectionRequest',
  Accept: 'AcceptConnectionRequest',
  Cancel: 'CancelConnectionRequest',
  Decline: 'DeclineConnectionRequest',
  Disconnect: 'DisconnectCompany',
  CreateAddressBook: 'CreateAddressBook',
  UpdateAddressBook: 'UpdateAddressBook',
  ViewAddressBookDetails: 'ViewAddressBookDetails',
  DeleteAddressBook: 'DeleteAddressBook',
  ViewNetworkProfile: 'ViewNetworkProfile',
  EditReceivePaymentByCreditCard: 'EditReceivePaymentByCreditCard',
  AddAccount: 'AddAccount',
  AssistContact: 'AssistContact'
});

export default NetworkActions;