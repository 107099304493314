/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import createAvailableActions from 'config/constants/actionsRegistry';

export const PersonalPortalViewActions = createAvailableActions(
  'PersonalPortalViewActions',
  [
    'ViewAutoPay',
    'ViewFees'
  ]
);
