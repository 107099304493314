/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, { useEffect } from 'react';

import WhiteHeaderOnWhiteLayout from 'layouts/WhiteHeaderOnWhiteLayout';
import LoadingIndicator from 'components/LoadingIndicator';

import { useParams } from 'react-router';
import useWindowWidth from 'hooks/browser/useWindowWidth';

import useApi from 'api/hooks/useApi';
import getSecureTokenData from 'api/onboarding/getSecureTokenData';

import SettlementDetails from './SettlementDetails';

import './index.scss';

function RemittanceLandingDetails() {

  const windowWidth = useWindowWidth();

  const api = useApi(getSecureTokenData, 'GetSecureTokenData');
  const { secureToken } = useParams();

  useEffect(() => {
    if (secureToken) {
      api.call({ secureToken });
    }
  }, [secureToken]);

  if (api.isLoading()) {
    return <LoadingIndicator />;
  }

  let settlementId = api.getResponse()?.data?.entities?.find(x => x.domainType === 'Settlement')?.domainId;

  return (
    <WhiteHeaderOnWhiteLayout>
      <div className="viewstrap">
        <div style={{ textAlign: 'center' }}>
          <div style={{
            width: windowWidth >= 1000 ? '1000px' : null,
            padding: windowWidth >= 1000 ? null : '0px 10px',
            display: 'inline-block'
          }}>

            <div style={{ fontSize: '31px', fontWeight: 'bold', marginBottom: '10px' }}
              className="no-print">
              Remittance Advice
            </div>

            <div style={{ textAlign: 'center', fontSize: '16px', lineHeight: '24px' }}
              className="no-print">
              View or download remittance data below. If you have questions about this payment,
              please contact your customer for additional details.
            </div>

            <div style={{textAlign: 'left'}}>
              <SettlementDetails settlementId={settlementId} secureToken={secureToken} />
            </div>
          </div>
        </div>
      </div>
    </WhiteHeaderOnWhiteLayout>
  );
}


export default RemittanceLandingDetails;