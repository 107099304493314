/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { get } from 'lodash';
import CommonApi from 'api/common';
import { getEntity, createEntityReference, createEntity } from 'schemas/state';
import { DocumentStoreKVP } from 'schemas/common/documentStore';
import { updateEntityAction } from 'api/core/utils';

export const DocumentStoreNamespace = {
  // These should stay aligned with the definition in the API
  // See Applications set in ApplicationDocumentStoreController.cs
  // https://github.com/Viewpost/appservices/blob/develop/strawman/source/application/Viewpost.Api.Service/Controllers/Integration/ApplicationDocumentStore/ApplicationDocumentStoreController.cs
  Settings: 'settings',
  QBD: 'qbd',
  GP: 'gp',
  QBO: 'qbo',
  SSO: 'sso',
  MobileSignature: 'mobileSignature'
};


const documentStoreNamespaces = Object.keys(DocumentStoreNamespace).map(key => DocumentStoreNamespace[key]);

/**
  Retrieves the document store value from the app state (assuming it has already been fetched)
*/
export function getDocumentStoreValue(state, namespace, key) {
  if (state) {
    let entity = getEntity(state, createEntityReference(`${namespace}/${key}`, DocumentStoreKVP.meta.name));
    if (entity) {
      return entity.value;
    }
  }

  return null;
}

export function loadDocumentStoreValue(namespace, isCompanyPermitted, key, chainedAction, correlationId) {
  // thunk
  let permissions = isCompanyPermitted ? 'company' : 'user';
  if (isCompanyPermitted === 'anonymous') {
    permissions = isCompanyPermitted;
  }

  return (dispatch) => {
    dispatch(CommonApi.getDocumentStoreValue({
      namespace,
      permissions,
      key
    }, chainedAction, correlationId));
  };
}

export function getStoreValueResponseBody(response) {
  return get(response, 'payload.entities[0].value.value', null);
}

/**
  @param namespace - one of the platform-supported category names (See DocumentStoreNamespace)
  @param key - A key (unique in the namespace) for of the stored value/setting
  @param isCompanyPermitted - whether this key applies to the entire company (all employees) or only this specific user
*/
export function updateDocumentStoreValue(namespace, isCompanyPermitted, key, value,
  triggerFetch = true, secureToken, chainedAction = () => {}, optimisticUpdate = false) {
  // thunk
  return (dispatch, getState) => {

    if (!documentStoreNamespaces.includes(namespace)) {
      console.error(`Unsupported Document Store Namespace (${namespace}). Must be one of:`, documentStoreNamespaces);
      return;
    }
    let permissions = isCompanyPermitted ? 'company' : 'user';
    if (isCompanyPermitted === 'anonymous') {
      permissions = isCompanyPermitted;
    }

    dispatch(CommonApi.updateDocumentStoreValue({
      namespace,
      permissions,
      key,
      value,
      secureToken: secureToken ? secureToken : undefined
    }, (success) => {
      if (success && optimisticUpdate) {
        // the doc store is *eventually* consistent so triggerFetch may not be reliable if we want the value again immediately
        // instead we'll do an optimistic update and assume it worked
        dispatch(updateEntityAction([
          createEntity(
            `${namespace}/${key}`,
            DocumentStoreKVP.meta.name,
            {
              value
            }
          )
        ]));
      } else if (success && triggerFetch) {
        dispatch(loadDocumentStoreValue(namespace, isCompanyPermitted, key, chainedAction));
      } else if (success) {
        chainedAction();
      }
    }));
  };
}

export function deleteDocumentStoreValue(namespace,
  isCompanyPermitted,
  key,
  { correlationId, onComplete } = {}
) {
  // thunk
  let permissions = isCompanyPermitted ? 'company' : 'user';
  if (isCompanyPermitted === 'anonymous') {
    permissions = isCompanyPermitted;
  }

  return (dispatch) => {
    dispatch(CommonApi.deleteDocumentStoreValue({
      namespace,
      permissions,
      key
    }, onComplete, correlationId));
  };
}
