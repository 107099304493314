/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { cloneDeep, merge } from 'lodash';

import updateCompanyUserAdditionalSettings from 'api/settings/updateCompanyUserAdditionalSettings';

import getCompanyUserSettings from './getCompanyUserSettings';

const updateCompanyUserAdditionalSetting = (
  key,
  value
) => (
  dispatch,
  getState
) => {
  const { additionalSettings } = getCompanyUserSettings(getState());
  let settings = cloneDeep(additionalSettings || {});
  settings = merge(settings, { [key]: value });
  dispatch(updateCompanyUserAdditionalSettings(settings));
};

export default updateCompanyUserAdditionalSetting;