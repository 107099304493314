/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { BankAccountActions } from 'config/constants/bankAccount';

import {
  BankAccount,
  BankAccountStatus
} from 'schemas/payments/bankAccount';
import { createEntity } from 'schemas/state';

function addBankAccountStatus(platformAccount, bankAccount) {
  if (platformAccount.isDefaultForPayment) {
    bankAccount.statuses.push(BankAccountStatus.meta.map.DefaultPaymentAccount);
  }
  if (platformAccount.isDefaultForReceipt) {
    bankAccount.statuses.push(BankAccountStatus.meta.map.DefaultDepositAccount);
  }
  if (platformAccount.isDefaultForFees) {
    bankAccount.statuses.push(BankAccountStatus.meta.map.DefaultFeeAccount);
  }

  if (!platformAccount.isActive) {
    bankAccount.statuses.push(BankAccountStatus.meta.map.AccountInactive);
  }
  if (platformAccount.isLocked) {
    bankAccount.statuses.push(BankAccountStatus.meta.map.AccountLocked);
  }

  if (platformAccount.hasVerificationExpired) {
    bankAccount.statuses.push(BankAccountStatus.meta.map.VerificationExpired);
  } else if (platformAccount.isVerified) {
    bankAccount.statuses.push(BankAccountStatus.meta.map.AccountVerified);
  } else if (platformAccount.isPendingAbaRoutingVerification
      || (platformAccount.bankName && platformAccount.isPendingVerification)) {
    bankAccount.statuses.push(BankAccountStatus.meta.map.AwaitingMicro);
  }

  // if (platformAccount.optimizationStatus !== 2 && platformAccount.requiresUserActionToEnableIcl) {
  //   bankAccount.statuses.push(BankAccountStatus.meta.map.AccountAuthFormRegions);
  // }

  if (platformAccount.optimizationStatus === 2) {
    bankAccount.statuses.push(BankAccountStatus.meta.map.IclEnabled);
  }

  if (platformAccount.numberRequiredSignatures > 1) {
    bankAccount.statuses.push(BankAccountStatus.meta.map.DualSigner);
  }

  if (platformAccount.isWesternUnionTwoStepPending) {
    bankAccount.statuses.push(BankAccountStatus.meta.map.WU2StepPending);
  }

  if (platformAccount.isWesternUnionTwoStepEnabled) {
    bankAccount.statuses.push(BankAccountStatus.meta.map.WU2StepEnabled);
  }

  if (platformAccount.isReceiveOnly) {
    bankAccount.statuses.push(BankAccountStatus.meta.map.ReceiveOnly);
  }

  // disabling Direct Pay since we may never use it and need to sort out UX impacts by new multi-account associations
  // if (platformAccount.isWesternUnionAchPending) {
  //   bankAccount.statuses.push(BankAccountStatus.meta.map.ThirdPartyACHPending);
  // }
  //
  // if (platformAccount.isWesternUnionAchEnabled) {
  //   bankAccount.statuses.push(BankAccountStatus.meta.map.ThirdPartyACHEnabled);
  // }
}

function bankAccountEntityMapper(apiBankAccount, identity) {
  let bankAccount = {
    id: apiBankAccount.id,
    accountName: apiBankAccount.name,
    accountType: apiBankAccount.accountType,
    accountNumberSuffix: apiBankAccount.accountSuffix,
    bankName: apiBankAccount.bankName,
    currencyCode: apiBankAccount.currencyCode,
    numberRequiredSignatures: apiBankAccount.numberRequiredSignatures,
    canEnrollAch: apiBankAccount.canEnrollAch,
    isNiclWhitelisted: apiBankAccount.isNiclWhitelisted,
    statuses: [],
    availableActions: [],
    isAdditionalApprovalRequired: !!(
      apiBankAccount.numberRequiredSignatures > 1
      || !identity.permissions.signCheck
    ),
    hasSignature: !apiBankAccount.isReceiveOnly,
    status: apiBankAccount.status,
    companyId: apiBankAccount.companyId,
    companyName: apiBankAccount.companyName,
    routingNumber: apiBankAccount.routingNumber
  };
  addBankAccountStatus(apiBankAccount, bankAccount);

  if (apiBankAccount.availableActions?.includes('Edit')) {
    bankAccount.availableActions.push(BankAccountActions.Edit);
  }

  if (apiBankAccount.availableActions?.includes('Delete')) {
    bankAccount.availableActions.push(BankAccountActions.Delete);
  }

  if (apiBankAccount.availableActions?.includes('Verify')) {
    bankAccount.availableActions.push(BankAccountActions.Verify);
  }

  if (apiBankAccount.availableActions?.includes('Setup')) {
    bankAccount.availableActions.push(BankAccountActions.Register);
  }

  if (apiBankAccount.availableActions?.includes('SetDefault')) {
    bankAccount.availableActions.push(BankAccountActions.SetDefault);
  }

  if (apiBankAccount.availableActions?.includes('Reset')) {
    bankAccount.availableActions.push(BankAccountActions.Reset);
  }

  if (apiBankAccount.availableActions?.includes('Disable')) {
    bankAccount.availableActions.push(BankAccountActions.Disable);
  }

  if (apiBankAccount.availableActions?.includes('Enable')) {
    bankAccount.availableActions.push(BankAccountActions.Enable);
  }

  return createEntity(
    apiBankAccount.id,
    BankAccount.meta.name,
    bankAccount
  );
}

export default bankAccountEntityMapper;