/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { connect } from 'react-redux';
import getIdentity from 'api/session/getIdentity';

function MapStateToProps(state) {
  let viewState = state.settings && state.settings.AccountSettingsPage ? state.settings.AccountSettingsPage : null;

  return {
    isVerifying: viewState.isVerifying,
    phone: viewState.phone
  };
}

function MapDispatchToProps(dispatch) {
  return {
    loadIdentity: () => dispatch(getIdentity())
  };
}

export default connect(MapStateToProps, MapDispatchToProps);
