/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import Messages from './index.messages';
import { getMoment } from '../utils';

const momentRef = moment();
const TODAY = momentRef.clone().startOf('day');
const YESTERDAY = momentRef.clone().subtract(1, 'days').startOf('day');
const fixedFormat = 'MMM D, YYYY'; // Ex: Jun 10, 2016
const shortFormat = 'MMM D'; // Ex: Jun 10

const renderRelativeDate = (props, momentDate) => {
  return momentDate.isSameOrAfter(TODAY) ? 'Today' : 'Yesterday';
};

const renderFixedDate = ({ excludeYear, allCaps }, momentDate) => {
  let formattedDate = momentDate.format(excludeYear ? shortFormat : fixedFormat);
  if (allCaps) {
    return formattedDate.toUpperCase();
  }
  return formattedDate;
};

const createFormattedDate = (props, toRelativeDate) => {
  let { value, includeTime, includeSeconds } = props;
  if (!value) return null;
  const momentDate = getMoment(value);

  // If the date is not in a moment readable format, return null
  if (!momentDate.isValid()) return null;

  // If rendering a date without time, it will always be fixed
  if (!includeTime) {
    return renderFixedDate(props, momentDate);
  }

  // Only render a relative date if it is within the last two days
  const formattedDate = momentDate.isSameOrAfter(YESTERDAY)
    && momentDate.isSameOrBefore(momentRef.endOf('day'))
    ? renderRelativeDate(props, momentDate)
    : renderFixedDate(props, momentDate);

  let timeFormat = includeSeconds ? 'h:mm:ss a' : 'h:mm a';
  const formattedTime = momentDate.format(timeFormat);

  return toRelativeDate(formattedDate, formattedTime);
};

export const createFormattedDateString = (intl, date, props = {}) => createFormattedDate(
  { value: date, ...props },
  (formattedDate, formattedTime) => intl.formatMessage(Messages.FormattedDateTime, {formattedDate, formattedTime})
);

/**
 * Formats a date into a standard date string
 */
class FormattedDate extends Component {
  static propTypes = {
    /** The date value */
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    /** If the time should be included in the output */
    includeTime: PropTypes.bool,
    /** Shown if the date is invalid or empty */
    emptyValue: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    /** Excludes the year for a more concise date */
    excludeYear: PropTypes.bool,
    allCaps: PropTypes.bool,
    renderDateTime: PropTypes.func
  };

  static defaultProps = {
    includeTime: false,
    emptyValue: '',
    excludeYear: false,
    allCaps: false,
    renderDateTime: null
  };

  renderDate() {
    if (this.props.renderDateTime) {
      return createFormattedDate(this.props, this.props.renderDateTime);
    }
    return createFormattedDate(this.props, (formattedDate, formattedTime) => (
      <FormattedMessage {...Messages.FormattedDateTime} values={{formattedDate, formattedTime}} />
    ));
  }

  render() {
    return (
      <span className="vp-formatted-date">
        {this.renderDate() || this.props.emptyValue}
      </span>
    );
  }
}

export default FormattedDate;
