/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, {
  forwardRef,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import classNames from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';

import useCombinedRefs from 'hooks/react/useCombinedRefs';
import useFuncRef from 'hooks/react/useFuncRef';
import usePropRef from 'hooks/react/usePropRef';

import BrowserDetection from 'services/BrowserDetection';

import './index.scss';

const HTML5_DATE_FORMAT = 'YYYY-MM-DD';

const NumberMetaTypes = [
  'AuthenticationCode',
  'RoutingNumber',
  'CardNumber',
  'CardVerificationCode'
];

const valueToDate = value => !value || value.length < 1
  ? null
  : moment(value, HTML5_DATE_FORMAT).toDate();

const TextBox = forwardRef((
  {
    alignRight,
    appendIcon,
    autoFocus,
    containerStyle,
    disabled,
    hasError,
    inputType: inputTypeProp,
    name,
    onBlur: onBlurProp,
    onChange: onChangeProp,
    onCopy,
    onFocus: onFocusProp,
    onKeyDown,
    options: optionsProp,
    placeholder,
    prependIcon,
    searchClassName,
    style,
    tabIndex: tabIndexProp,
    textRows,
    type: typeProp,
    value: valueProp,
    footer,
    autocomplete,
    wrap
  },
  ref
) => {
  // Hooks
  const textBoxRef = useRef();
  const allRefs = useCombinedRefs(textBoxRef, ref);

  // This was originally component state set on creation, but since it never changes, a ref is just as well
  const { current: isRenderedInModal } = useRef(
    document.body.className === 'ReactModal__Body--open'
  );

  const onBlurRef = usePropRef(onBlurProp);
  const onChangeRef = usePropRef(onChangeProp);
  const onFocusRef = usePropRef(onFocusProp);

  const [ hasFocus, setHasFocus ] = useState();

  useEffect(
    () => {
      // Handling the autofilled values
      if (textBoxRef.current) {
        setTimeout(
          () => {
            const value = textBoxRef.current?.value;
            // Only indicate there was a change if something was autofilled
            if (value && value !== valueProp) {
              onChangeRef.current(value);
            }
          },
          300
        );
      }
    },
    []
  );

  const {
    config: optionsConfig,
    type: optionsType
  } = optionsProp || {};

  const {
    tabIndex: tabIndexConfig,
    type: configType
  } = optionsConfig || {};

  const inputType = useMemo(
    () => {
      // Check that there is a valid type passed into either options.config or just options
      // Use this because props.type refers to the tcomb type
      if (inputTypeProp) {
        return inputTypeProp;
      }

      if (configType) {
        return configType;
      }

      if (optionsType) {
        return optionsType;
      }

      if (typeProp && typeProp.meta) {
        const metaType = typeProp.meta.kind === 'maybe'
          ? typeProp.meta.type.meta
          : typeProp.meta;

        if (metaType.name === 'Phone') {
          return 'tel';
        }

        if (NumberMetaTypes.includes(metaType.name)) {
          return 'number';
        }
      }

      return 'text';
    },
    [
      configType,
      optionsType,
      typeProp,
      inputTypeProp
    ]
  );

  const inputTypeRef = usePropRef(inputType);

  const { current: onChange } = useFuncRef(
    () => (evt) => {
      const value = evt.target.value;
      if (onChangeRef.current) {
        if (inputTypeRef.current === 'date') {
          onChangeRef.current(valueToDate(value));
        } else {
          onChangeRef.current(value ? value : null);
        }
      }
    }
  );

  const { current: onBlur } = useFuncRef(
    () => (evt) => {
      setHasFocus(false);
      onBlurRef.current?.(evt);
    }
  );

  const { current: onFocus } = useFuncRef(
    () => (evt) => {
      setHasFocus(false);
      onFocusRef.current?.(evt);
    }
  );

  const { current: onClick } = useFuncRef(
    () => () => textBoxRef.current?.focus()
  );

  // Render
  const TagComponent = inputType === 'textarea'
    ? 'textarea'
    : 'input';

  let value = valueProp === 0 || valueProp
    ? valueProp
    : '';

  if ((inputType === 'date') && value instanceof Date) {
    value = moment(value).format(HTML5_DATE_FORMAT);
  }

  let input = (
    <TagComponent
      autoFocus={autoFocus}
      className={classNames(
        'vp-input',
        {
          error: hasError,
          'text-right': alignRight
        }
      )}
      disabled={disabled}
      name={name}
      onBlur={onBlur}
      onChange={onChange}
      onCopy={onCopy}
      onFocus={onFocus}
      onKeyDown={onKeyDown}
      pattern={inputType === 'number' ? '\\d*' : null}
      placeholder={placeholder}
      ref={allRefs}
      rows={textRows}
      style={style}
      tabIndex={tabIndexProp || tabIndexConfig}
      type={inputType}
      value={value}
      autoComplete={autocomplete}
      wrap={wrap}
    />
  );

  // This is needed to help prevent the background from scrolling in IOS when dragging input elements
  if (BrowserDetection.isIOS() && isRenderedInModal) {
    input = (
      <div>
        {input}
        <div
          className="textbox-touch-layer"
          onClick={onClick}
        />
      </div>
    );
  }

  if (appendIcon || prependIcon) {
    return (
      <div
        className={classNames('vp-search', searchClassName)}
        style={containerStyle}
      >
        <div
          className={classNames(
            'vp-group',
            'input',
            {
              focus: hasFocus,
              error: hasError,
              disabled: disabled
            }
          )}
          onClick={onClick}
        >
          {prependIcon}
          {input}
          {appendIcon}
        </div>
        {footer}
      </div>
    );
  }

  return <>
    {input}
    {footer}
  </>;
});

TextBox.propTypes = {
  placeholder: PropTypes.string,
  appendIcon: PropTypes.oneOfType([ PropTypes.array, PropTypes.element ]),
  prependIcon: PropTypes.oneOfType([ PropTypes.array, PropTypes.element ]),
  hasError: PropTypes.bool,
  alignRight: PropTypes.bool,
  tabIndex: PropTypes.number,
  disabled: PropTypes.bool,
  onKeyDown: PropTypes.func
};

TextBox.displayName = 'TextBox';

export default TextBox;