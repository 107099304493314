/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { createCacheActions } from 'actions/references';

/**
 * Provides an object that allows API interaction
 */
function useApi(
  /** API endpoint to call */
  apiEndpoint,

  /**
   * Unique id for the instance/result
   * this can be a shared global key like 'GetXApi'
   * (for sharing the result across instances)
   * OR something instance specific like 'GetXApi-{someId}'
   */
  id,

  /** Additional configuration parameters around the api
   * paged - whether or not this is a paginated api
   * sorted - whether or not this is a sortable api
   * onComplete - callback func when api call completes
   * popupError - whether to show 1st failure in toast
   * successMessage - message to show when api call suceeds
   */
  config
) {
  const apiActions = createCacheActions(id, apiEndpoint);
  const dispatch = useDispatch();
  const state = useSelector(s => s);

  const isLoading = useSelector(s => apiActions.isCacheLoading(s));

  const [page, setPage] = useState(config?.defaultPage ?? 1);
  const [pageSize, setPageSize] = useState(config?.defaultPageSize ?? 50);

  const [sortFieldName, setSortFieldName] = useState(null);
  const [sortDescending, setSortDescending] = useState(null);

  /**
   * Makes the call to the api
   */
  const call = (params, newPage, newPageSize) => {
    let request = { ...params };

    // support standard backend paging requests
    if (config?.paged) {
      request.page = newPage ?? page;
      request.pageSize = newPageSize ?? pageSize;

      // support backend IPaginatedRequest contract
      request.includeTotalCount = true;

      // support backend DynamicQuery contract
      request.resultBehavior = 'PageWithCount';
    }

    if (config?.sorted) {
      request.sortFields = [{
        fieldName: sortFieldName,
        direction: sortDescending ? 'Descending' : 'Ascending'
      }];
    }

    dispatch(apiActions.makeApiCall(request, {
      // popup the first error in a toast on failure
      extractFirstErrorMessage: config?.popupError ?? true,
      hideError: config?.popupError === false,
      successMessage: config?.successMessage,
      apiMetadata: {
        onComplete: (success, action) => config?.onComplete?.(success, action, action?.payload?.response?.body)
      }
    }));
  };

  // respond to pagination changes
  useEffect(() => {
    if (!config?.paged) return;
    let lastParams = apiActions.getCacheLastParams(state);
    if (lastParams && (lastParams?.page !== page || lastParams?.pageSize !== pageSize)) {
      call(lastParams);
    }
  }, [page, pageSize]);

  // respond to sort changes
  useEffect(() => {
    if (!config?.sorted) return;
    let lastParams = apiActions.getCacheLastParams(state);
    if (lastParams
      && (
        lastParams?.sortFieldName !== sortFieldName
        || lastParams?.sortDescending !== sortDescending
      )) {
      call(lastParams);
    }
  }, [sortFieldName, sortDescending]);

  return {
    /** Whether or not the api is fetching */
    isLoading: () => isLoading,

    /** Whether or not the api call has failed */
    isError: () => apiActions.hasCacheFailed(state),

    /** The last params used to call this api */
    getParams: () => apiActions.getCacheLastParams(state),

    /** Gets a single entity mapped from the last response */
    getEntity: () => apiActions.getCacheEntity(state),

    /** Gets an array of entities mapped from the last response */
    getEntities: () => apiActions.getCacheEntities(state),

    /** Gets the last response (assumed to be a JSON response)  */
    getResponse: () => apiActions.getCacheResponse(state),

    /** Gets a list of error codes (e.g. for better mapping to custom messages) returned in the response */
    getErrorCodes: () => {
      if (!apiActions.hasCacheFailed(state)) return null;
      let response = apiActions.getCacheResponse(state);

      // standard location for validation error messages
      return response?.messages?.map(m => m.context) ?? [];
    },

    /** Gets a list of error messages returned in the response */
    getErrors: () => {
      if (!apiActions.hasCacheFailed(state)) return null;
      let response = apiActions.getCacheResponse(state);

      // standard location for validation error messages
      return response?.messages?.map(m => m.message) ?? [];
    },

    /** Calls the api with the given parameters.*/
    call: params => call(params),

    /** Special version of call designed for
     * paged (e.g. Search) form listviews that always resets the page when submitted
     * (since filters might be changing)
    */
    submit: (params) => {
      call(params, 1);
      setPage(1);
    },

    /** Calls the api again using the parameters that were used in the last call() */
    refresh: () => dispatch(apiActions.refreshCache()),

    // PAGINATION

    /** The pagination information from the response (if any) */
    getPagination: () => {

      if (isLoading) {
        return {
          ...apiActions.getCachePagination(state),

          // use local state over API state so ApiPagination will have intended page information faster
          // than waiting for API response (i.e. show next selected page immediately on click)
          // NOTE: REQUIRES use of useApi's pagination
          page,
          pageSize
        };
      }

      // NOTE: we don't always use useApi's pagination (e.g. web-app)
      // so consider API rails to be the reliable source of truth here once we have a response
      return {
        ...apiActions.getCachePagination(state)
      };
    },

    /** whether or not this api is considered a paged API */
    isPaged: () => !!config?.paged,

    /** Change pagination information for Paged API request */
    setPage: (newPage, newPageSize) => {
      if (newPage) setPage(newPage);
      if (newPageSize) setPageSize(newPageSize);
    },

    // SORTING

    /**
     * Get sort fields from standard location provided by dynamic query endpoints
     * These can be used with the <Sort> component
    */
    getSortFields: () => apiActions
      .getCacheResponse(state)
      ?.sortFields
      ?.map(x => ({
        fieldName: x.fieldName,
        isDescending: x.direction === 'Descending'
      })),

    /** Change the sort (based on a single field) for Sorted API request */
    setSort: (fieldName, isDescending) => {
      setSortFieldName(fieldName);
      setSortDescending(!!isDescending);
    },

    /** Clear the cached last api response */
    clearCache: () => apiActions.clearCache()
  };
}

export default useApi;