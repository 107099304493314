/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './index.scss';

/** @docsignore - let documentation occur via LoadingIndicator */
const BaseLoadingIndicator = ({
  centerInPage,
  children,
  className,
  style,
  variant
}) => (
  <div
    className={classNames(
      'loading-indicator',
      { wholePage: centerInPage },
      variant,
      className
    )}
    style={style}
  >
    <div className="spinner">
      <div className="rect1"></div>
      <div className="rect2"></div>
      <div className="rect3"></div>
      <div className="rect4"></div>
      <div className="rect5"></div>
    </div>
    <div className="message">
      {children}
    </div>
  </div>
);

BaseLoadingIndicator.propTypes = {
  variant: PropTypes.oneOf(['default', 'light', 'small'])
};

BaseLoadingIndicator.defaultProps = {
  variant: 'default'
};

export default BaseLoadingIndicator;
