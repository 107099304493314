/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { createApiAction } from 'api/core';

const sendVccDigits = createApiAction({
  method: 'post',
  url: '/api/landing/sendVccFirst6',
  requestBodyMapper: ({ secureToken }) => ({ secureToken })
});

export default sendVccDigits;
