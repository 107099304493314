/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import qs from 'qs';
import pathToRegexp from 'path-to-regexp';
import { pick } from 'lodash';
import { getPathPrefix } from './utils';

const processRoutePaths = (
  routes,
  {
    idPrefix,
    routePrefix, // route = this/is/my/route
    prefix: parentPathPrefix, // path = (mydomain.com|/|<nothing>), prepended to routePrefix
    requiredAction: parentRequiredAction,
    isOutsideApp: parentIsOutsideApp
  } = {}
) => Object.keys(routes).reduce(
  (val, key) => {
    const idPrefixPart = idPrefix ? `${idPrefix}.` : '';
    const id = `${idPrefixPart}${key}`;
    const {
      path: originalPath,
      queryStringMapper,
      children,
      prefix: pathPrefix,
      requiredAction,
      isOutsideApp: pathIsOutsideApp,
      ...routeProps
    } = routes[key];

    // Allow for empty 'container' paths without warning
    if (!originalPath && !children) {
      console.warn(`Missing path for ${id}`);
    }

    const path = `${routePrefix || ''}${originalPath || ''}`;
    const params = [];
    const createBasePath = pathToRegexp.compile(path);
    const isOutsideApp = pathIsOutsideApp != null ? pathIsOutsideApp : parentIsOutsideApp;
    const prefix = pathPrefix || parentPathPrefix;

    const toPath = (
      pathParams,
      query,
      {
        prefix: toPathPrefix
      } = {}
    ) => {
      let queryString = null;
      if (query) {
        const actualQuery = queryStringMapper ? queryStringMapper(query) : query;

        // Remove null/undefined values
        // I don't know of a use case yet where we need a query parameter present but not set
        Object.keys(actualQuery).forEach((queryKey) => {
          if (actualQuery[queryKey] == null) {
            delete actualQuery[queryKey];
          }
        });

        queryString = actualQuery && Object.keys(actualQuery).length
          ? `?${qs.stringify(actualQuery || {})}`
          : null;
      }

      return `${getPathPrefix(toPathPrefix || prefix)}${createBasePath(pathParams)}${queryString || ''}`;
    };

    function getBaseUrl() {
      let getUrl = window.location;
      return `${getUrl.protocol}//${getUrl.host}`;
    }

    function toUrl(...args) {
      let relativePath = toPath(...args);
      return `${getBaseUrl()}${relativePath}`;
    }

    const matchRegex = pathToRegexp(path, params);
    const childMatchRegex = pathToRegexp(`${path}/(.*)`);

    const matchFunc = (pathname, { alsoMatchChildren } = {}) => {
      if (pathname.match(matchRegex)) return true;
      if (alsoMatchChildren && children) return pathname.match(childMatchRegex);
      return false;
    };

    const routeChildren = processRoutePaths(
      children || {},
      {
        idPrefix: id,
        routePrefix: path,
        requiredAction,
        isOutsideApp
      }
    );

    return {
      ...val,
      [key]: {
        id,
        path,
        params: params.map(({ name }) => name),
        toPath,
        toUrl,
        requiredAction: requiredAction || parentRequiredAction,
        isOutsideApp,
        ...routeProps,
        match: matchFunc,
        children: Object.keys(routeChildren),
        ...routeChildren
      }
    };
  },
  {}
);

const createPathConfig = (PathConfig) => {
  const Paths = processRoutePaths(PathConfig);

  const GetAllPaths = (config = Paths, prefix = '/') => {
    return Object.keys(config).reduce((val, key) => {
      let { path, children } = config[key];
      return [...val, path.toLowerCase(), ...GetAllPaths(pick(config[key], children), path)];
    }, []);
  };

  const findPath = (
    pathname,
    { onlyBestMatch } = {}
  ) => {
    const findIn = (children) => {
      const perfectMatch = Object.keys(children)
        .map(key => children[key])
        .find(({ match }) => match(pathname));

      if (perfectMatch) return [ perfectMatch ];

      const path = Object.keys(children)
        .map(key => children[key])
        .find(({ match }) => match(pathname, { alsoMatchChildren: true }));

      if (!path) return [];

      const subPaths = findIn(pick(path, path.children));

      if (!subPaths.length) {
        console.warn(`'${pathname}' is missing from the paths config`);
        return [];
      }

      return [ path, ...subPaths ];
    };

    const result = findIn(Paths);

    if (onlyBestMatch) {
      return result.length ? result.slice(-1)[0] : null;
    }

    return result;
  };

  const hasPermissionForPath = (path, availableActions) => {
    if (!path.requiredAction) return true;
    return availableActions.includes(path.requiredAction);
  };

  return {
    Paths,
    hasPermissionForPath,
    findPath,
    GetAllPaths
  };
};

const processLogicalPaths = (routes, {
  idPrefix
} = {}) => {
  return Object.keys(routes).reduce((val, key) => {
    const idPrefixPart = idPrefix ? `${idPrefix}.` : '';
    const id = `${idPrefixPart}${key}`;
    const {
      path,
      children,
      ...routeProps
    } = routes[key];

    const routeChildren = processLogicalPaths(children || {}, {
      idPrefix: id
    });

    return {
      ...val,
      [key]: {
        id,
        path,
        isLogical: true,
        ...routeProps,
        children: Object.keys(routeChildren),
        ...routeChildren
      }
    };
  }, {});
};

export const createLogicalPathConfig = (PathConfig) => {
  const LogicalPaths = processLogicalPaths(PathConfig);

  return {
    LogicalPaths
  };
};

export default createPathConfig;
