/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { createApiAction } from 'api/core';

const trackPageViewed = createApiAction({
  method: 'post',
  url: '/api/telemetry/pageViewed',
  ignoreDispatch: true,
  requestBodyMapper: ({
    emailId,
    linkContext
  }) => ({
    emailId,
    linkContext
  })
});

export default trackPageViewed;
