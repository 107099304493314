/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { shallowEqual, useSelector } from 'react-redux';

import getApplicationSetting, { AppSetting } from 'actions/app/getApplicationSetting';

export { AppSetting };

const useApplicationSetting = (
  appSetting,
  options
) => useSelector(
  state => getApplicationSetting(
    state,
    appSetting,
    options
  ),
  shallowEqual
);

export default useApplicationSetting;