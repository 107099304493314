/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isEqual, isFunction, isString } from 'lodash';
import Tooltip, { Placements } from 'components/Tooltip';
import ReactTruncate from 'react-truncate';

export const TruncationStrategy = {
  Beginning: 'Beginning', // e.g. ...xxx
  Middle: 'Middle', // e.g. xxx...xxx
  End: 'End' // e.g. xxx...
};

/**
 * Truncates strings to keep them under a specified maximum character length
 * (+ truncation indicator and tooltip for showing the full string)
 */
export default class Truncate extends Component {
  static propTypes = {
    /** string to truncate */
    value: PropTypes.node,
    /** The string, highlighted. Used if truncation is not applied or in the tooltip */
    highlightedValue: PropTypes.element,
    /** whether or not to nowrap the truncated value (not the tooltip) */
    nowrap: PropTypes.bool,
    /** max number of chars before truncation */
    maxLength: PropTypes.number,
    /** max number of lines before truncation (only works with End strategy) */
    maxLines: PropTypes.number,
    toolTipPlacement: PropTypes.oneOf(Object.keys(Placements).map(key => Placements[key])),
    /** string to use to indicate truncation has occurred */
    omissionString: PropTypes.string,
    strategy: PropTypes.oneOf([
      TruncationStrategy.Beginning,
      TruncationStrategy.End,
      TruncationStrategy.Middle]),
    /** whether or not to hide overflow */
    overflowHidden: PropTypes.bool,

    /** used instead of maxLength for Middle truncation strategy */
    maxBeginLength: PropTypes.number,
    /** used instead of maxLength for Middle truncation strategy */
    maxEndLength: PropTypes.number
  };

  static defaultProps = {
    maxLength: 20,
    maxBeginLength: 4,
    maxEndLength: 4,
    omissionString: '...',
    strategy: TruncationStrategy.End
  };

  constructor(props) {
    super(props);

    this.state = {
      isTruncated: false
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    // Added this because the component went crazy with updating in the BusinessCard component,
    // despite there being no changes to the props or state
    return !isEqual(this.props, nextProps)
      || !isEqual(this.state, nextState);
  }

  componentWillUnmount() {
    // onTruncate sometimes gets called after the component is unmounted
    this.onTruncate = () => null;
  }

  /**
   * A shortcut to render values as-is if truncation is calculated as
   * not necessary
   */
  willActuallyNeedTruncation(value) {
    const {
      strategy,
      maxLines,
      maxLength,
      maxBeginLength,
      maxEndLength
    } = this.props;

    // Can't calculate without rendering non-strings, so return false so its rendered as-is
    // Except in the case where we use a component that can accept non-strings
    if (!isString(value)) return strategy === TruncationStrategy.End && maxLines;
    // Can't calculate without rendering multiline strings
    if (maxLines) return true;

    if (strategy === TruncationStrategy.Middle) {
      return value.length <= maxBeginLength + maxEndLength;
    }

    return value.length >= maxLength;
  }

  onTruncate = isTruncated => this.setState({ isTruncated });

  truncatedValue() {
    const {
      maxBeginLength,
      maxEndLength,
      maxLength,
      maxLines,
      omissionString,
      strategy,
      value: originalValue
    } = this.props;

    const value = originalValue || '';

    if (strategy === TruncationStrategy.Beginning) {
      if (maxLines) console.warn('maxLines prop is not supported with Beginning trunction.');
      return `${omissionString}${value.substring(value.length - maxLength)}`;
    }

    if (strategy === TruncationStrategy.Middle) {
      if (maxLines) console.warn('maxLines prop is not supported with Middle trunction.');
      return `${value.substring(0, maxBeginLength)}${omissionString}${value.substring(value.length - maxEndLength)}`;
    }

    if (strategy === TruncationStrategy.End) {
      if (maxLines) {
        return (
          <ReactTruncate
            lines={maxLines}
            ellipsis={omissionString}
            onTruncate={this.onTruncate}
          >
            {value}
          </ReactTruncate>
        );
      }
      return `${value.substring(0, maxLength)}${omissionString}`;
    }
  }

  getStyle() {
    const { maxLines, nowrap, overflowHidden } = this.props;

    return {
      display: maxLines || overflowHidden
        ? 'block' // needed for Firefox to properly hide overflow
        : 'inline',
      whiteSpace: nowrap ? 'nowrap' : null,
      overflow: overflowHidden ? 'hidden' : null
    };
  }

  render() {
    const {
      children,
      highlightedValue,
      maxLines,
      toolTipPlacement,
      value
    } = this.props;

    const {
      isTruncated
    } = this.state;

    if (!value) {
      return null;
    }

    if (!this.willActuallyNeedTruncation(value)) {
      let content = highlightedValue || value;

      // Pass a function in the children in order to control the render of the truncation
      if (isFunction(children)) {
        content = children(content);
      }

      return (
        <span style={this.getStyle()}>
          {content}
        </span>
      );
    }

    const charTruncationNeeded = !maxLines;
    const lineTruncationNeeded = maxLines && isTruncated;

    const truncatedValue = (
      <div style={this.getStyle()}>
        {this.truncatedValue()}
      </div>
    );

    let content = charTruncationNeeded || lineTruncationNeeded
      ? highlightedValue || truncatedValue
      : truncatedValue;

    // Pass a function in the children in order to control the render of the truncation
    if (isFunction(children)) {
      content = children(content);
    }

    return (
      <span>
        {charTruncationNeeded || lineTruncationNeeded ? (
          <Tooltip
            wide={true}
            placement={toolTipPlacement || Placements.Top}
            overlayStyle={{ wordBreak: 'break-all' }}
            overlay={value}
            childWrapperStyle={maxLines ? {display: 'block'} : undefined}
          >
            {content}
          </Tooltip>
        ) : content}
      </span>
    );
  }
}
