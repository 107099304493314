/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */
/* eslint max-len:0 */

import { toMessages } from 'config/messages';

export default toMessages('Logon.CompleteResetPassword.SetPassword', {
  Instructions: 'Now you just need to create a new password below.',
  InProgressHeader: 'Almost there.',
  NewPassword: 'New Password',
  ConfirmPasswordPlaceholder: 'Confirm Password'
});
