/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, { Component } from 'react';

import ProfessionalHeaderOnWhiteLayout from 'layouts/ProfessionalHeaderOnWhiteLayout';

import FormJSX from 'components/Form/FormJSX';
import FieldOption from 'components/Form/FieldOption';

import Button from 'components/Button';

import t from 'tcomb-validation';
import { Phone } from 'schemas/common/phone';
import { createMaxLengthStringType } from 'schemas/common/string';

import Messages from './index.messages';
import connector from './index.connect';

function createSchema() {
  return t.struct({
    firstName: createMaxLengthStringType(50),
    lastName: createMaxLengthStringType(50),
    title: createMaxLengthStringType(50),
    phoneNumber: Phone,
    phoneExtension: t.maybe(createMaxLengthStringType(10))
  });
}

class VccInterest extends Component {

  constructor(props) {
    super(props);
    this.state = {
      formState: {},
      submitted: false
    };
  }

  onSubmit(e) {
    if (e) {
      e.preventDefault();
    }

    let value = this.formRef.getValue();
    if (value) {
      this.props.onSubmit(value);
      this.setState({submitted: true});
    }
  }

  renderHeading() {
    if (!this.props.secureTokenContext || !this.props.secureTokenContext.data) {
      return null;
    }

    let relationship = this.props.secureTokenContext.data.relationships.length
      && this.props.secureTokenContext.data.relationships[0];
    return (
      <div style={{border: '1px solid #ddd', borderRadius: '2px', marginBottom: '30px'}}>
        <div style={{
          display: 'inline-block',
          verticalAlign: 'middle',
          marginRight: '30px',
          borderRight: ' 1px solid #ddd',
          padding: '8px'
        }}>
          <img src={relationship.customerLogoUrl} width="120" height="72" />
        </div>
        <div style={{ display: 'inline-block', verticalAlign: 'middle', color: '#666'}}>
          <strong style={{fontSize: '16px'}}>
            Submit this form and a payment specialist will contact you.
          </strong>
          <br/>
          Viewpost is an authorized payments partner of&nbsp;{relationship.customerName}.
        </div>
      </div>
    );
  }

  renderForm() {
    return (
      <form style={{marginBottom: '80px'}}>
        <FormJSX ref={ref => this.formRef = ref}
          modelType={createSchema(this.state.formState)}
          value={this.state.formState}
          onChange={v => this.setState({formState: v})}>

          <FieldOption name="firstName" label="First Name" cols={6} />
          <FieldOption name="lastName" label="Last Name" cols={6} />
          <FieldOption name="title" label="Title / Company Role" cols={6}
            placeholder="e.g. AR Department, CFO, Customer Support, etc" />
          <FieldOption name="phoneNumber" label="Phone Number" cols={4} />
          <FieldOption name="phoneExtension" label="Ext" cols={2} />
        </FormJSX>

        <Button type="submit" expand={true} onClick={e => this.onSubmit(e)}>Submit</Button>
      </form>
    );
  }

  renderSubmitted() {
    return (
      <div style={{fontSize: '20px', textAlign: 'center'}}>
        Thank you for contacting us.
        <br/>
        <br/>
        A payment specialist will call you at the phone number provided.
      </div>
    );
  }

  render() {
    let { isMobile } = this.props;
    return (
      <ProfessionalHeaderOnWhiteLayout
        headerMessage={Messages.Header}
        subheaderMessage={Messages.Subheader}
        contentWidth={isMobile ? 320 : 650}
      >
        {!this.state.submitted ? this.renderHeading() : null}
        {!this.state.submitted ? this.renderForm() : null}
        {this.state.submitted ? this.renderSubmitted() : null}
      </ProfessionalHeaderOnWhiteLayout>
    );
  }
}

export default connector(VccInterest);