/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { Children } from 'react';

const View = ({ children }) => ({
  workflowType: 'View',
  // Let Children.only throw an error if you pass in more than one
  child: Children.only(children)
});

export default View;