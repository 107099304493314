/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import t from 'tcomb-validation';

import { defineSchema } from 'schemas';
import { RetrievableAddress } from 'schemas/common/address';

import AddressBookRelationship from './AddressBookRelationship';

const RetrievableAddressBookEntry = defineSchema(
  {
    id: t.maybe(t.String),
    isVerified: t.maybe(t.Boolean),
    companyName: t.maybe(t.String),
    firstName: t.maybe(t.String),
    lastName: t.maybe(t.String),
    email: t.maybe(t.String),
    phone: t.maybe(t.String),
    address: t.maybe(RetrievableAddress),
    relationship: t.maybe(AddressBookRelationship),
    clientReferenceId: t.maybe(t.String),
    companyNodeId: t.maybe(t.String),
    companyNodeName: t.maybe(t.String),
    isCreditCardEnabled: t.maybe(t.Boolean),
    canVendorPayFees: t.maybe(t.Boolean),
    isIndividual: t.maybe(t.Boolean),
    availableActions: t.maybe(t.list(t.String))
  },
  'RetrievableAddressBookEntry'
);

export default RetrievableAddressBookEntry;