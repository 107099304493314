/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */
/* eslint max-len:0 */

import { defineMessages } from 'react-intl';

const common = defineMessages({
  Instructions: {
    id: 'TwoFactorPrompt.Instructions',
    description: 'TODO',
    defaultMessage: 'We just sent a two-step verification code to {target}. Please enter your two-step verification code.'
  },
  LogonInstructions: {
    id: 'TwoFactorPrompt.LogonInstructions',
    defaultMessage: 'Looks like you’re using a new device. To help keep your information safe, we sent a text message with a verification code to {phoneNumber}.'
  },
  IncorrectCodeError: {
    id: 'TwoFactorPrompt.IncorrectCodeError',
    description: 'TODO',
    defaultMessage: 'Please check your code and try again'
  },
  CodeResentAlert: {
    id: 'TwoFactorPrompt.CodeResentAlert',
    description: 'TODO',
    defaultMessage: 'Code has been sent.'
  },
  ContinueButton: {
    id: 'TwoFactorPrompt.ContinueButton',
    description: 'TODO',
    defaultMessage: 'Continue'
  },
  ResendCode: {
    id: 'TwoFactorPrompt.ResendCode',
    description: 'TODO',
    defaultMessage: 'Resend Code'
  },
  FooterText: {
    id: 'TwoFactorPrompt.FooterText',
    description: 'TODO',
    defaultMessage: 'If you don\'t have access to your device, please contact Customer Support at {contact}.'
  },
  YourDevice: {
    id: 'TwoFactorPrompt.YourDevice',
    defaultMessage: 'your device'
  }
});

const labelMessages = defineMessages({
  code: {
    id: 'TwoFactorPrompt.Labels.code',
    description: 'TODO',
    defaultMessage: 'Verification Code'
  },
  rememberDevice: {
    id: 'TwoFactorPrompt.Labels.rememberDevice',
    description: 'TODO',
    defaultMessage: 'Don\'t ask again on this device'
  }
});

const placeholderMessages = defineMessages({
  code: {
    id: 'TwoFactorPrompt.Placeholders.code',
    description: 'TODO',
    defaultMessage: ' '
  }
});

export default {
  ...common,
  labelMessages,
  placeholderMessages
};
