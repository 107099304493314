/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Calendar from 'components/Calendar';
import DatePicker from 'components/Form/Inputs/DatePicker';
import Button from 'components/Button';
import moment from 'moment';

/**
  Renders a 2-month calendar for date range selection
*/
export default class DualCalendar extends Component {
  static propTypes = {
    date1: PropTypes.instanceOf(Date),
    date1Label: PropTypes.string,
    date2: PropTypes.instanceOf(Date),
    date2Label: PropTypes.string,
    onChange: PropTypes.func,

    /** Include a submit button for changes (vs. change constantly on each update) */
    useSubmitButton: PropTypes.bool,
    submitLabel: PropTypes.string
  };

  constructor(props) {
    super(props);
    this.state = {
      date1: null,
      date2: null,
      error: null
    };
  }

  componentWillMount() {
    this.updateStateFromProps(this.props);
  }

  componentWillReceiveProps(props) {
    if (!moment(props.date1).isSame(this.props.date1)
      || !moment(props.date2).isSame(this.props.date2)
    ) {
      this.updateStateFromProps(props);
    }
  }

  updateStateFromProps(props) {
    let date1 = moment(props.date1);
    let date2 = moment(props.date2);
    if (date1.isValid() && date2.isValid()) {
      this.setState({
        date1: date1.toDate(),
        date2: date2.toDate()
      });
    }
  }

  onChange() {
    if (!this.state.date1 || !this.state.date2) {
      // need to both values to change the range
      this.setState({
        error: 'Both date values required.'
      });
      return;
    }
    if (this.state.date1 > this.state.date2) {
      // needs to be a valid date range
      this.setState({
        error: 'First date must be before second date.'
      });
      return;
    }

    this.setState({
      error: null
    });
    if (this.props.onChange) {
      this.props.onChange(this.state.date1, this.state.date2);
    }
  }

  onChangeDate1(date) {
    this.setState({
      date1: date
    }, () => {
      if (!this.props.useSubmitButton) {
        this.onChange();
      }
    });
  }

  onChangeDate2(date) {
    this.setState({
      date2: date
    }, () => {
      if (!this.props.useSubmitButton) {
        this.onChange();
      }
    });
  }

  renderCalendar(label, date, onChange) {
    return (
      <div style={{padding: '5px'}}>
        <div className="vp-label">
          {label}
        </div>
        <div style={{marginBottom: '5px'}}>
          <DatePicker value={date}
            useCalendar={false}
            onChange={v => onChange(v)} />
        </div>
        <Calendar
          value={date}
          viewDate={date}
          onChange={v => onChange(v)} />
      </div>
    );
  }

  render() {
    let borderStyle = '1px solid #ddd';

    return (
      <div>
        <div style={{
          display: 'inline-block'
        }}>
          <div style={{
            display: 'inline-block',
            borderRight: borderStyle
          }}>
            {this.renderCalendar(
              this.props.date1Label,
              this.state.date1,
              v => this.onChangeDate1(v))}
          </div>
          <div style={{
            display: 'inline-block'
          }}>
            {this.renderCalendar(
              this.props.date2Label,
              this.state.date2,
              v => this.onChangeDate2(v))}
          </div>
        </div>
        {!!this.props.useSubmitButton
          ? (
            <div style={{textAlign: 'center', borderTop: '1px solid #ddd', padding: '8px'}}>
              <Button size="small" style={{ fontSize: '12px' }}
                onClick={() => this.onChange()}>
                {this.props.submitLabel || 'Submit'}
              </Button>
            </div>
          )
          : null}
        {this.state.error
          ? (
            <div style={{textAlign: 'center', padding: '8px'}}>
              <span className="vp-error">{this.state.error}</span>
            </div>
          )
          : null}
      </div>
    );
  }
}
