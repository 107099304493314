/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

export default {
  path: '/register',
  children: {
    Invitation: {
      path: '/invitation/:invitationId',
      queryStringMapper: ({ ignoreWelcomeModal }) => ({ ignoreWelcomeModal })
    },
    CorporatePaymentsContact: {
      path: '/corporatePayments'
    },
    SmallBusinessRegistration: {
      path: '/smallBusiness',
      children: {
        Features: {
          path: '/features'
        }
      }
    }
  }
};
