/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { shallowEqual, useSelector } from 'react-redux';

const useIsTablet = () => useSelector(
  (state) => {
    return state.deviceInfo.isTablet || false;
  },
  shallowEqual
);

export default useIsTablet;