/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { push } from 'connected-react-router';
import { GetPath, IsSpaLinkMap } from 'config/links';
import logError from 'services/ErrorService';

export const pushLink = (linkReference, linkParams) => (dispatch, getState) => {
  if (!linkReference) {
    logError('No linkReference passed, ignoring.');
    return;
  }

  let link = GetPath(linkReference, linkParams);

  let isLinkInApp = IsSpaLinkMap[linkReference] && link.startsWith('/');

  if (isLinkInApp) {
    dispatch(push(link));
  } else {
    global.window.location.href = link;
  }
};
