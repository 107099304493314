/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, { useEffect, useMemo, useRef, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import { isPersonalPortal } from 'actions/common';
import { getCompanySettings, isPortalExperience } from 'actions/common/companySettings';
import { getCurrentFeeSettings } from 'actions/userModel';

import selectSubscriptionOption from 'api/settings/subscription/selectSubscriptionOption';

import BodyText, { BodyTextVariants } from 'components/BodyText';
import Button from 'components/Button';
import CancellationPolicyTooltip from 'components/CancellationPolicyTooltip';
import HeaderText, { HeaderTextVariants } from 'components/HeaderText';

import CommonMessages from 'config/messages/common';

import { MonthlyPaymentSuccessDescriptionPanel } from 'containers/Subscription/MonthlyPaymentDescriptionPanel';
import SetupSubscriptionForm, {
  useSetupSubscriptionData
} from 'containers/Subscription/SetupSubscriptionForm';

import createExecuteApiResultHooks from 'hooks/apiResult/createExecuteApiResultHooks';
import useIdentity from 'hooks/identity/useIdentity';
import useQueryParams from 'hooks/location/useQueryParams';

import BlueHeaderOnWhiteLayout from 'layouts/BlueHeaderOnWhite';

import Messages from './index.messages';

const SelectSubscriptionOptionHooks = createExecuteApiResultHooks({
  endpoint: selectSubscriptionOption
});

const EngageLegacyUsersPrompt = ({
  campaignId,
  onComplete
}) => {
  // Hooks
  const formRef = useRef();

  const [ formState, setFormState ] = useState({});

  const {
    delinquencyDate,
    isAllSet,
    isLoading,
    submitError,
    submitSetup
  } = useSetupSubscriptionData({
    campaignId,
    formRef
  });

  const monthlySubscriptionFee = useSelector(
    state => getCurrentFeeSettings(state).monthlySubscriptionFee
  );

  const { email } = useIdentity();

  const executeSelectOption = SelectSubscriptionOptionHooks.useExecuteRequest();
  const isCancelling = SelectSubscriptionOptionHooks.useIsLoading();

  const onSubmit = useMemo(
    () => async () => {
      await submitSetup();
    }
  );

  const onCancel = useMemo(
    () => async () => {
      const [ success ] = await executeSelectOption({
        campaignId,
        selection: 'Portal'
      });

      if (success) onComplete();
    }
  );

  // Render
  if (isAllSet) {
    return (
      <>
        <HeaderText>
          <Messages.ConfirmationHeader.Message />
        </HeaderText>
        <HeaderText variant={HeaderTextVariants.Small} className="pad-20-bottom">
          <Messages.ConfirmationSubheader.Message
            email={email}
          />
        </HeaderText>
        <MonthlyPaymentSuccessDescriptionPanel
          amount={monthlySubscriptionFee}
          billingDate={new Date()}
        />
        <div className="pad-12-top">
          <Button
            expand={true}
            onClick={onComplete}
          >
            <CommonMessages.Continue.Message />
          </Button>
        </div>
      </>
    );
  }

  return (
    <>
      <HeaderText>
        <Messages.Header.Message />
      </HeaderText>
      <HeaderText variant={HeaderTextVariants.Small} className="pad-20-bottom">
        <Messages.Subheader.Message />
      </HeaderText>
      <SetupSubscriptionForm
        billingDate={new Date()}
        delinquencyDate={delinquencyDate}
        onChange={setFormState}
        ref={formRef}
        showCancellationPolicy={false}
        subcriptionAmount={monthlySubscriptionFee}
        submitError={submitError}
        value={formState}
      >
        <BodyText
          className="pad-8-x pad-16-bottom"
          variant={BodyTextVariants.Smaller}
        >
          <Messages.RiskFree.Message
            cancellationPolicy={<CancellationPolicyTooltip />}
          />
        </BodyText>
      </SetupSubscriptionForm>
      <div className="pad-12-top">
        <Button
          expand={true}
          isDisabled={isLoading || isCancelling}
          isProcessing={isLoading}
          onClick={onSubmit}
        >
          <Messages.Submit.Message />
        </Button>
      </div>
      <BodyText
        className="pad-40-top pad-20-x text-center"
        variant={BodyTextVariants.Smaller}
      >
        <Messages.OptOut.Message
          optOut={(
            <Button
              anchor={true}
              isDisabled={isCancelling || isLoading}
              isProcessing={isCancelling}
              onClick={onCancel}
            >
              <Messages.OptOutLink.Message/>
            </Button>
          )}
        />
      </BodyText>
    </>
  );
};

const EngageLegacyUsersPromptWorkflowStep = ({
  active,
  updateState
}) => {
  // Hooks
  const { forceNotification } = useQueryParams([ 'forceNotification' ]);

  const [ isInCampaign, originalCampaignId ] = useSelector(
    (state) => {
      const { campaigns } = getCompanySettings(state);

      const {
        id: subscriptionCampaignId,
        outcome: campaignOutcome
      } = campaigns?.find?.(
        ({ type }) => type === 'EngageLegacyUsers'
      ) || {};

      if (!subscriptionCampaignId) return [ false, null ];

      if (campaignOutcome !== 'None') return [ false, null ];

      return [ true, subscriptionCampaignId ];
    },
    shallowEqual
  );

  // Cache these so we don't lose them if the user model takes them away once
  // the user has paid. Use the array notation to make this look confusing but
  // with less code.
  const [ [ wasInCampaign, campaignId ] ] = useState([ isInCampaign, originalCampaignId ]);

  const shouldCheck = wasInCampaign;

  const canShow = useSelector(state => !isPersonalPortal(state) && isPortalExperience(state));

  const willCheck = canShow && (forceNotification === 'engageLegacyUsers' || shouldCheck);

  useEffect(
    () => {
      if (!willCheck) {
        updateState('skipped');
      } else {
        updateState('loaded');
      }
    },
    []
  );

  const onComplete = useMemo(
    () => () => updateState('completed')
  );

  // Render
  if (!active) return <></>;

  return (
    <BlueHeaderOnWhiteLayout
      contentWidth={650}
      hideLoginLink={true}
    >
      <div className="viewstrap">
        <EngageLegacyUsersPrompt
          campaignId={campaignId}
          onComplete={onComplete}
        />
      </div>
    </BlueHeaderOnWhiteLayout>
  );
};

export default EngageLegacyUsersPromptWorkflowStep;