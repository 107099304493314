/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

const applyExtendHooks = (
  extendHooks,
  output,
  {
    applyWrapper,
    entryApiResultId,
    hookConfig
  }
) => {
  const {
    Redux: reduxOutput,
    ...hooksOutput
  } = extendHooks(
    output,
    {
      applyWrapper,
      entryApiResultId,
      hookConfig
    }
  );

  Object.assign(output, hooksOutput);
  Object.assign(output.Redux, reduxOutput);
};

export default applyExtendHooks;