/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React from 'react';

const TableRow = ({ children, ...props }) => (
  <tr {...props}>
    {children}
  </tr>
);

export default TableRow;
