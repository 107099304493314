/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, { Children, useEffect } from 'react';
import classNames from 'classnames';
import { IntlProvider } from 'react-intl';

import logError from 'services/ErrorService';
import Image from 'components/Viewstrap/Image';
import UntanglingWiresErnie from 'public/images/ernie/untangling-wires.png';
import { CustomerSupportEmail } from 'config/messageLinks';
import Messages from './index.messages';
import './index.scss';

export const BaseProblemErnie = ({
  centerInPage,
  children,
  ernieImage,
  message: formattedMessage,
  messageValues,
  showPhoneNumber
}) => {
  let message = null;

  if (formattedMessage) {
    message = formattedMessage;
  } else {
    message = showPhoneNumber
      ? (
        <Messages.Message.Message
          link={CustomerSupportEmail}
        />
      ) : (
        <Messages.MessageWithoutPhoneNumber.Message
          link={CustomerSupportEmail}
        />
      );
  }

  return (
    <IntlProvider locale="en">
      {
        // We have several cases where we render this component outside of an
        // intl context and this ensures we always have one - an english error
        // is going to be better than no error. There's no good way to
        // conditionally check if we are in an intl context. We can always
        // expose the configuration of a parent intl context and use it here if
        // we want parity - if we get serious about i18n.
      }
      <div
        className={classNames(
          'problemErnie',
          { wholePage: centerInPage },
          { centerHorizonal: !centerInPage }
        )}
      >
        {
          // Apply viewstrap at the problem content layer because it sets
          // styling like position that messes with the wholePage setting. This
          // also takes the burden off the person using ProblemErnie to not need
          // to worry about viewstrap being in the hierarchy.
        }
        <div className="viewstrap problemContent">
          <Image
            className="pad-20-y"
            src={ernieImage || UntanglingWiresErnie}
            willCenter={true}
          />
          {message}
          {children ? (
            <div className="pad-y">
              {Children.only(children)}
            </div>
          ) : null}
        </div>
      </div>
    </IntlProvider>
  );
};

const LoggingProblemErnie = ({
  ignoreLogging,
  errorReason,
  errorValues,
  ...props
}) => {
  // Hooks
  useEffect(
    () => {
      // Consider just using BaseProblemErnie directly if you want to ignore logging
      if (ignoreLogging) return;
      logError('User saw Problem Ernie', {
        reason: errorReason || 'Unknown',
        ...(errorValues || {})
      });
    },
    []
  );

  // Render
  return (
    <BaseProblemErnie {...props} />
  );
};

export default LoggingProblemErnie;
