/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React from 'react';
import PropTypes from 'prop-types';
import Header from './Header';
import Body from './Body';
import Footer from './Footer';
import FooterButton, { ModalFooterText } from './FooterButton';

export const ModalHeader = Header;
export const ModalBody = Body;
export const ModalFooter = Footer;
export const ModalFooterButton = FooterButton;
export { ModalFooterText };

const Modal = ({
  className,
  header,
  isForm = false,
  onHeaderClose,
  children
}) => {
  let Wrapper = isForm ? 'form' : 'div';

  return (
    <Wrapper className={className}>
      <Header onClose={onHeaderClose}>
        {header}
      </Header>
      {children}
    </Wrapper>
  );
};

Modal.propTypes = {
  /** The content in the title bar of the modal */
  header: PropTypes.node,
  /** Whether or not to wrap this in a form */
  isForm: PropTypes.bool,
  onHeaderClose: PropTypes.func
};

export default Modal;
