/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { isNil } from 'lodash';
import { asApiAction } from 'api/core/ApiAction';
import { isFetchAction, isSuccessAction } from 'api/core/utils';
import { isUserNowUnauthenticated, newState } from './utils';

function autoMapPagination(response) {
  if (!response?.page && !response?.pageSize) return null;

  return {
    page: response?.page,
    pageSize: response?.pageSize,
    totalItems: response?.totalCount ?? 0,
    isCapped: response?.isTotalCountAtMaxLimit ?? false
  };
}

export default (state = {}, action) => {
  if (isFetchAction(action) && action.meta.reference) {
    let modifiedState = {
      [action.meta.reference.name]: {
        ...(state[action.meta.reference.name] || {}),
        loading: true,
        error: false
      }
    };
    return newState(state, modifiedState);
  }

  if (isSuccessAction(action) && action.meta.reference) {
    let isActuallyOldError = action.meta.response
      ? (() => {
        if (action.meta.response.body
          && action.meta.response.body.response !== undefined
          && action.meta.response.body.success !== undefined) {
          return !action.meta.response.body.success;
        }

        return false;
      })() : false;

    const cacheUntil = isNil(action.meta?.response?.cacheUntil) || action.meta?.response?.cacheUntil === 0
      ? state.cacheUntil || 0
      : action.meta.response.cacheUntil;

    let modifiedState = {
      [action.meta.reference.name]: {
        ...(state[action.meta.reference.name] || {}),
        loading: false,
        error: isActuallyOldError,
        lastParams: action.meta.params,
        references: action.meta.reference.selector(action.payload.entities)
          .map(entity => entity.createReference()),
        pagination: action.payload.pagination ?? autoMapPagination(action.meta.response?.body),
        response: action.meta.response
          ? (() => {
            if (action.meta.response.body && action.meta.response.body.response !== undefined) {
              if (isActuallyOldError) return action.meta.response.body.messages;
              return action.meta.response.body.response;
            }

            return action.meta.response.body;
          })() : null,
        statusCode: action.meta?.response?.statusCode,
        cacheUntil
      }
    };
    return newState(state, modifiedState);
  }

  const apiAction = asApiAction(action);
  if (apiAction?.isFailure) {
    const { reference: referenceInfo } = apiAction.getMeta();

    if (referenceInfo) {
      const {
        name: referenceName
      } = referenceInfo;

      let modifiedState = {
        [referenceName]: {
          ...(state[referenceName] || {}),
          loading: false,
          error: apiAction.getErrorReason() ?? true,
          response: apiAction.getResponseBody({ expected: false }),
          statusCode: apiAction.getStatusCode()
        }
      };
      return newState(state, modifiedState);
    }
  }

  if (action.type === 'REFERENCE_CACHE_CLEAR') {
    return newState(state, {
      [action.name]: null
    });
  }

  if (isUserNowUnauthenticated(action)) {
    return {};
  }

  return state;
};
