/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */
/* eslint max-len:0 */

import { toMessages } from 'config/messages';

export default toMessages('Registration.AchPlusEnrollment.Success', {
  Instructions: 'Now that you are setup to receive electronic payment, you can create an account on Viewpost to view past and future payments and their invoices. Just provide us a password and secure your account to continue.',
  LandingHeader: 'View Payments and Invoices',
  LandingHeaderPendingAccount: 'In the Meantime, View Payments and Invoices',
  LandingInstructions: 'Now that you\'re set up to receive electronic payment you can create an account to view payment details and track the status of your unpaid invoices to {companyName}. There is no cost to create your account or to view payment and invoice data.',
  LandingInstructionsPendingAccount: 'Now that you’ve registered to receive electronic payment you can create an account to view payment details and track the status of your unpaid invoices to {companyName}. There is no cost to create your account or to view payment and invoice data',
  LandingContinueButton: 'Create Account',
  LandingCancelButton: 'No Thanks',
  FormHeader: 'Secure your account.',
  TwoFactorInstructions: 'Keep your account safe by enabling two-factor authentication. Don\’t want to use a smartphone? {toggleLink}',
  SecurityQuestionsInstructions: 'Keep your account safe by answering these security questions. Prefer to use a smartphone? {toggleLink}',
  SwitchToTwoFactorLink: 'Enable two-factor verification.',
  SwitchToSecurityQuestions: 'Answer security questions.',
  Password: 'Password',
  VerifyPassword: 'Verify Password',
  ResentCode: 'Code has been sent.',
  PhoneNumberLabel: 'Step 1: Enter and Send Phone Number to Receive Code',
  PhoneNumberPlaceholder: '(000) 000-0000',
  VerifyCodeInstructions: 'A verification code was sent to {phoneNumber}',
  ResendCode: 'Resend Code',
  ChangeNumber: 'Change Number',
  VerifyCodeLabel: 'Step 2: Enter Verification Code',
  VerifyCodePlaceholder: '000000',
  SendButton: 'Send',
  SubmitButton: 'Complete Steps Above to Create Account',
  IncorrectCode: 'You have entered an incorrect code. Please try again.',
  PhoneNumberLocked: 'You have entered your code incorrectly too many times. Please try another phone number or contact customer service.',
  CreatePassword: 'Create Password',
  SecureYourAccount: 'Secure Your Account'
});
