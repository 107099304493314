/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { isFunction } from 'lodash';
import t from 'tcomb-validation';

import logonEndpoint from 'api/session/logon';

import Button from 'components/Button';
import PasswordToggleTextBox from 'components/Form/Inputs/PasswordToggleTextBox';
import FormV2, { FieldInput, FieldOption } from 'components/Form/V2';
import PathLink from 'components/Link/PathLink';
import { SmallBannerNotification, NotificationStyleTypes } from 'components/Notification';

import FloatingAlert from 'containers/Logon/FloatingAlert';

import { NetworkErrors } from 'config/constants';
import LogonFailureReason, {
  CredentialsFailureReason
} from 'config/constants/session/LogonFailureReason';

import createExecuteApiResultHooks from 'hooks/apiResult/createExecuteApiResultHooks';
import useIsMobile from 'hooks/browser/useIsMobile';
import useFormatMessage from 'hooks/intl/useFormatMessage';
import useQueryParams from 'hooks/location/useQueryParams';

import { getUtcOffset, getTimeZone } from 'utils/dateUtils';

import Messages from './index.messages';
import './index.scss';

const LogonHooks = createExecuteApiResultHooks({
  endpoint: logonEndpoint
});

const CredentialsFormType = t.struct({
  userName: t.String,
  password: t.String
});

const CredentialsForm = ({
  changeEmailToken,
  credentialsFailure,
  failure,
  ignoreWipe,
  invitationId,
  onUpdate,
  redirect,
  // function or 'link' (default)
  resetPasswordMethod,
  // 'small' (default) or 'default'
  variant: variantProp
}) => {
  // Hooks
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const formatMessage = useFormatMessage();
  const formRef = useRef();

  const {
    destinationCompanyId,
    userName: prefilledUserName
  } = useQueryParams([
    'destinationCompanyId',
    'userName'
  ]);

  const executeLogon = LogonHooks.useExecuteRequest();
  const isLoading = LogonHooks.useIsLoading();
  const error = LogonHooks.useRequestError();

  useEffect(
    () => {
      if (redirect) {
        dispatch({ type: 'SaveLogonRedirect', redirect });
      }
    },
    [ redirect ]
  );

  const onSubmit = useMemo(
    () => async (event) => {
      event.preventDefault();
      event.stopPropagation();

      if (formRef.current) {
        const value = formRef.current.getValue();

        if (value) {
          let deviceToken = null;

          try {
            deviceToken = localStorage.getItem(`DeviceId-${value.userName}`);
          } catch {
            // Local storage no-worky, nothing we can do
          }

          const [ , action ] = await executeLogon(
            {
              userName: value.userName,
              password: value.password,
              deviceToken,
              ignoreWipe,
              changeEmailToken,
              invitationId,
              companyId: failure !== LogonFailureReason.DoesNotBelongToCompany
                ? destinationCompanyId
                : null,
              clientInfo: {
                localDeviceTimeZone: getTimeZone(),
                localDeviceUtcOffset: getUtcOffset()
              }
            },
            {
              failureMessage: false
            }
          );

          onUpdate(action.payload.result);
        }
      }
    },
    []
  );

  const [ formState, setFormState ] = useState({
    userName: prefilledUserName
  });

  const resetPasswordLink = useMemo(
    () => {
      if (isFunction(resetPasswordMethod)) {
        return (
          <Button
            onClick={resetPasswordMethod}
            className="forgot-password"
            anchor={true}
            tabIndex={4}
          >
            <Messages.ResetPassword.Message />
          </Button>
        );
      }

      if (resetPasswordMethod == null || resetPasswordMethod === 'link') {
        return (
          <PathLink.Logon.ResetPassword
            className="forgot-password"
            tabIndex={4}
          >
            <Messages.ResetPassword.Message />
          </PathLink.Logon.ResetPassword>
        );
      }
    },
    [ resetPasswordMethod ]
  );

  // Render
  const isRateLimited = error === NetworkErrors.TooManyRequests;

  let alert = null;

  if (!isLoading) {
    if (isRateLimited) {
      alert = (
        <SmallBannerNotification type={NotificationStyleTypes.Error} shakeOnEnter={true}>
          <Messages.TooManyRequests.Message />
        </SmallBannerNotification>
      );
    } else if (failure === LogonFailureReason.InvalidCredentials
      && credentialsFailure === CredentialsFailureReason.InvalidUserName) {
      alert = (
        <SmallBannerNotification type={NotificationStyleTypes.Warning} shakeOnEnter={true}>
          <Messages.InvalidUserName.Message />
        </SmallBannerNotification>
      );
    } else if (failure === LogonFailureReason.InvalidCredentials
      && credentialsFailure === CredentialsFailureReason.InvalidPassword) {
      alert = (
        <SmallBannerNotification type={NotificationStyleTypes.Warning} shakeOnEnter={true}>
          <Messages.InvalidPassword.Message />
        </SmallBannerNotification>
      );
    } else if (failure === LogonFailureReason.DoesNotBelongToCompany) {
      alert = (
        <SmallBannerNotification type={NotificationStyleTypes.Warning} shakeOnEnter={true}>
          <Messages.DoesNotBelongToCompany.Message />
        </SmallBannerNotification>
      );
    }

    if (alert) alert = <FloatingAlert>{alert}</FloatingAlert>;
  }

  const variant = variantProp == null ? 'small' : variantProp;

  const isMinimalVariant = variant === 'small';

  const form = (
    <FormV2
      ref={formRef}
      formTag={false}
      modelType={CredentialsFormType}
      value={formState}
      onChange={setFormState}
    >
      <FieldOption
        name="userName"
        label={isMinimalVariant ? null : <Messages.UserName.Message />}
        validateOnBlur={false}
      >
        <FieldInput
          autoFocus={true}
          placeholder={isMinimalVariant
            ? formatMessage(Messages.UserName)
            : null}
          tabIndex={1}
        />
      </FieldOption>
      <FieldOption
        name="password"
        label={isMinimalVariant ? null : <Messages.Password.Message />}
        rightLabel={resetPasswordLink}
      >
        <PasswordToggleTextBox
          placeholder={isMinimalVariant
            ? formatMessage(Messages.Password)
            : null}
          tabIndex={2}
        />
      </FieldOption>
    </FormV2>
  );

  if (isMobile && variant === 'small') {
    return (
      <form onSubmit={onSubmit}>
        {alert}
        <div style={{padding: '12px 40px calc(32px - 1rem) 40px'}}>
          {form}
        </div>
        <Button
          type="submit"
          isDisabled={isLoading}
          isProcessing={isLoading}
          expand={true}
          tall={true}
          bold={true}
          sharpCorners={true}
          uppercase={true}
          tabIndex={3}
        >
          <Messages.Submit.Message />
        </Button>
      </form>
    );
  }

  return (
    <form onSubmit={onSubmit}>
      {alert}
      {form}
      <Button
        analyticsId="LoginButton"
        type="submit"
        isDisabled={isLoading}
        isProcessing={isLoading}
        expand={true}
        tabIndex="3"
      >
        <Messages.Submit.Message />
      </Button>
    </form>
  );
};

export default CredentialsForm;
