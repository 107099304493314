/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, { Component } from 'react';
import _ from 'lodash';
import FormListItem from './ListItem';

/**
 * Renders a section of a form that represents a list of items.
 * New list items can be added dynamically.
 */
export default class List extends Component {
  static propTypes = {};

  constructor(props) {
    super(props);
  }

  change(evt) {
    this.props.options.onChange(evt.target.value);
  }

  render() {
    return (
      <div className="vp-form-list">

        {/* Support fancy add/remove transitions */}
        {this.props.options.items.map((itemOptions, index) => {

          // allow index-aware item option configuration
          let mergedOptions = itemOptions;
          if (_.isFunction(this.props.options.config.perItem)) {
            let indexAwareItemOptions = this.props.options.config.perItem(index);
            mergedOptions = {
              ...itemOptions,
              ...indexAwareItemOptions
            };
          }

          return (
            <FormListItem
              key={itemOptions.key}
              options={mergedOptions}
              index={index} />
          );
        })}

        <button
          type="button"
          className="vp-button button-add"
          onClick={this.props.options.add.click}
        >
          Add Item
        </button>
      </div>
    );
  }
}
