/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import t from 'tcomb-validation';
import { getSchema } from 'schemas';
import { CreatableEntity } from 'schemas/state';
import logError from 'services/ErrorService';

export const validationErrorsToString = validationResult => ((validationResult || {}).errors || [])
  .map(({ path, ...err }) => ({ path: (path ?? []).join('->'), ...err }))
  .sort((a, b) => a.path.localeCompare(b.path))
  .map(({ message, path }) => `Path: ${path}, Message: ${message || 'No error message.'}`)
  .join(',\n');

export const validateEntity = (entity, { method, url }) => {
  // If it is an entity to merge, all bets are off as we don't have all the information right now.
  // Just assume success and hope for the best.
  if (entity.merge) return true;

  // TODO: Determine if it would be worthwhile to skip these checks in prod since it is mainly
  // useful during develop?
  const validationResult = t.validate(entity, CreatableEntity);
  if (validationResult.errors.length > 0) {
    if (method && url) {
      return {
        errorMessage: [
          `Cannot write entity to state for endpoint
          (${method} ${url}).
          Entity does not follow schema for Entity.`,
          validationResult
        ]
      };
    }

    return {
      errorMessage: [
        'Cannot write entity to state. Entity does not follow schema for Entity.',
        validationResult
      ]
    };
  }

  // lookup model schema
  const entityType = entity.type;
  const schema = getSchema(entityType);
  if (!schema) {
    return {
      logError: [
        `No schema found for ${entityType}`,
        {
          apiUrl: url,
          method: method,
          schema: entityType
        }
      ],
      errorMessage: [
        `Cannot write entity to state for endpoint
        (${method} ${url}).
        No Schema found for entity type: ${entityType}`
      ]
    };
  }

  if (entity.value) {
    // ensure entity conforms to schema
    const entityValidation = t.validate(entity.value, schema);
    if (entityValidation.errors.length > 0) {
      if (method && url) {
        return {
          logError: [
            `Entity does not follow schema: ${validationErrorsToString(entityValidation)}`,
            {
              apiUrl: url,
              method: method,
              schema: schema.meta.name
            }
          ],
          errorMessage: [ entityValidation ]
        };
      }

      return {
        logError: [
          `Entity does not follow schema: ${validationErrorsToString(entityValidation)}`,
          {
            schema: schema.meta.name
          }
        ],
        errorMessage: [ entityValidation ]
      };
    }
  }

  return true;
};

export const logValidateEntityError = (validationResult) => {
  if (validationResult !== true) {
    const { errorMessage, logError: logErrorParams } = validationResult || {};

    if (logErrorParams) {
      logError(...logErrorParams);
    }

    if (errorMessage) {
      console.error(...errorMessage);
    }

    return;
  }
};
