/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, { isValidElement } from 'react';
import { isString } from 'lodash';
import hash from 'object-hash';

import { NotificationStyleTypes, showSmallBanner } from 'actions/notification';

import { toMessages } from 'config/messages';

const Messages = toMessages('ApiResultHooks', {
  DefaultSuccessMessage: 'The action has completed successfully',
  DefaultErrorMessage: 'An unexpected error occurred.'
});

const executeRequest = async ({
  callConfig: {
    assertSuccess,
    extractFirstErrorMessage,
    failureMessage,
    successMessage
  },
  dispatch,
  entryApiResultId,
  hookConfig: {
    correlationId,
    endpoint,
    entityScope,
    onCall,
    onComplete: parentOnComplete
  },
  requestParams
}) => {
  // Hold onto the promise for a moment so we can invoke the callback when a request is made
  const resultPromise = dispatch(endpoint(
    requestParams,
    null,
    correlationId,
    {
      apiResultId: `${entryApiResultId}-${hash(requestParams)}`,
      entityScope,
      saveResult: true,
      entryApiResultId
    }
  ));

  // Because we want to make sure the request is fully and totally made, we are making a thunk here
  // to have it processed in the queue after the request kickoff is totally done as well. This
  // should help prevent any race conditions if redux doesn't immediately fire off and process the
  // endpoint action.
  if (onCall) {
    dispatch(() => onCall(requestParams));
  }

  const [ success, action ] = await resultPromise;

  if (success) {
    if (!successMessage) {
      parentOnComplete?.(success, action);
      return resultPromise;
    }

    dispatch(showSmallBanner({
      type: NotificationStyleTypes.Success,
      message: isValidElement(successMessage) || isString(successMessage)
        ? successMessage
        : <Messages.DefaultSuccessMessage.Message />
    }));

    parentOnComplete?.(success, action);
    return resultPromise;
  }

  // If the request was cancelled, no need to display an error message
  if (action?.cancelled) {
    // However still throw an error if that needs to be asserted - the
    // assumption of those that need to assert is that the execution stops after
    // a failure of any sort so the everything underneath it knows all is good.
    if (assertSuccess) {
      throw new Error('API returned error');
    }

    parentOnComplete?.(success, action);
    return resultPromise;
  }

  let failureNotificationMessage = null;

  if (isValidElement(failureMessage) || isString(failureMessage)) {
    failureNotificationMessage = failureMessage;
  }

  if (extractFirstErrorMessage) {
    failureNotificationMessage = failureNotificationMessage
      || action.getFirstMessage();
  }

  if (failureNotificationMessage || failureMessage || extractFirstErrorMessage) {
    dispatch(showSmallBanner({
      type: NotificationStyleTypes.Error,
      message: failureNotificationMessage || <Messages.DefaultErrorMessage.Message />
    }));
  }

  parentOnComplete?.(success, action);

  if (assertSuccess) {
    throw new Error('API returned error');
  }

  return resultPromise;
};

export default executeRequest;