/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './CreditCardIcon.css';
import { CreditCardType } from 'schemas/payments/creditCard';
import classNames from 'classnames';

import AmexImage from 'images/icons/creditcards/amex.png';
import DinersClubImage from 'images/icons/creditcards/diners_club.png';
import DiscoverImage from 'images/icons/creditcards/discover.png';
import MastercardImage from 'images/icons/creditcards/mastercard.png';
import VisaImage from 'images/icons/creditcards/visa.png';

function getType(vccProvider) {
  switch (vccProvider?.toLowerCase()) {
    case 'usbankvisa':
    case 'usbankvisa2':
    case 'casseml':
    case 'jpmc':
      return CreditCardType.meta.map.Visa;
    case 'comdata':
    case 'usbankmastercard':
    case 'usbankmastercard2':
      return CreditCardType.meta.map.MasterCard;
    default: return null;
  }
}

function getImage(type) {
  switch (type) {
    case CreditCardType.meta.map.Visa: return VisaImage;
    case CreditCardType.meta.map.MasterCard: return MastercardImage;
    case CreditCardType.meta.map.Discover: return DiscoverImage;
    case CreditCardType.meta.map.Amex: return AmexImage;
    case CreditCardType.meta.map.DinersClub: return DinersClubImage;
    default: return null;
  }
}

/**
 * A credit card icon
 */
export default class CreditCardIcon extends Component {
  static propTypes = {
    type: PropTypes.oneOf([
      CreditCardType.meta.map.Unknown,
      CreditCardType.meta.map.Visa,
      CreditCardType.meta.map.MasterCard,
      CreditCardType.meta.map.Discover,
      CreditCardType.meta.map.Amex,
      CreditCardType.meta.map.DinersClub
    ])
  };

  render() {

    let type = this.props.type;
    if (!type) {
      type = getType(this.props.vccProvider);
    }
    if (!type) {
      return null;
    }

    let image = getImage(type);

    let classes = classNames('vp-credit-card-icon', type.toLowerCase());

    return image
      ? <img className={classes} src={image} style={this.props.style} />
      : null;
  }
}
