/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { ModalActionTypes } from 'config/constants';
import { addModal } from 'config/modals/registry';
import SimpleModal from 'components/Modal/Simple';
import launchKnowledgeAuthenticationModal from 'views/Settings/KnowledgeAuthenticationWorkflow/Async';
import launchDisputeInvoicesModal from 'containers/Invoicing/DisputeModal/Async';
import launchShareInvoiceModal from 'containers/Invoicing/ShareViaEmailModal/Async';
import launchDisableDeleteAccountModal from 'views/Settings/PaymentMethods/DisableDeleteAccount/Async';

import launchSecureAccountModal from 'views/Settings/PaymentMethods/SecureAccount/Async';
import launchSubmitQboPaymentsModal from 'containers/Payments/SubmitPaymentsModal/Async';
import launchLinkQboCustomersModal from 'containers/Invoicing/LinkQboCustomersModal/Async';
import launchSubmitPayLaterModal from 'containers/Payments/SubmitPayLaterModal/Async';
import launchRejectInvoiceModal from 'containers/Invoicing/RejectModal/Async';
import launchUnvoidInvoiceModal from 'containers/Invoicing/UnvoidModal/Async';
import launchVoidInvoiceModal from 'containers/Invoicing/VoidModal/Async';
import launchRememberApInboxVendorModal from 'containers/Invoicing/RememberAPInboxVendorModal/Async';
import launchVerifyAccountModal from 'containers/Payments/VerifyAccountModal/Async';
import launchSetupAutoPayModal from 'containers/Payments/SetupAutoPayModal/Async';
import launchLinkInvoiceModal from 'containers/Invoicing/LinkModal/Async';
import launchPaymentQueueErrorModal from 'containers/Payments/PaymentQueueErrorModal/Async';
import launchCommentInvoiceModal from 'containers/Invoicing/CommentInvoiceModal/Async';
import launchEditBankAccountModal from 'views/Settings/PaymentMethods/EditBankAccount/Async';
import { KnowledgeAuthenticationRequired } from 'config/constants/session';
import { getEntity } from 'schemas/state';
import launchConfirmationModal from './launchConfirmationModal';
import Messages from './index.messages';

// Maintain this until everything that references it is gone
export { launchConfirmationModal };

export const launchSimpleModal = addModal('SimpleModal', SimpleModal);

// generic/common modals

export function showAlert(headerMessage, content, options = {}) {
  const { onConfirm, submitMessage, modalParams } = options;
  return (dispatch) => {
    dispatch(launchSimpleModal({
      header: headerMessage,
      body: content,
      submit: {
        onClick: (closeModalFunc) => {
          (onConfirm || (() => null))();
          closeModalFunc();
        },
        message: submitMessage || Messages.ConfirmSubmitButton
      },
      close: false
    }, modalParams));
  };
}

export function knowledgeAuthentication(onComplete, { prefillAddress, onQuit } = {}) {
  return (dispatch) => {
    dispatch(launchKnowledgeAuthenticationModal({
      onComplete,
      onQuit,
      prefillAddress
    }));
  };
}

export function ensureKnowledgeAuthentication(onComplete, options) {
  return (dispatch, getState) => {
    const identity = getEntity(getState(), getState().session.identity);
    const { knowledgeAuthenticationRequired } = identity;
    if (knowledgeAuthenticationRequired !== KnowledgeAuthenticationRequired.NotRequired) {
      dispatch(knowledgeAuthentication(onComplete, options));
    } else {
      onComplete();
    }
  };
}

export function submitQboPaymentsModal(unlinkedPayments, accountLinks, callback) {
  // thunk
  return (dispatch) => {
    dispatch(launchSubmitQboPaymentsModal({
      unlinkedPayments,
      accountLinks,
      callback
    }));
  };
}

export function linkQboCustomersModal(unlinkedVPCustomers, unlinkedInvoices, onCustomersLinked, onCancel) {
  // thunk
  return (dispatch) => {
    dispatch(launchLinkQboCustomersModal({
      unlinkedVPCustomers,
      unlinkedInvoices,
      onCustomersLinked,
      onCancel
    }));
  };
}

export function submitPayLaterModal({
  invoice,
  amount,
  isPaperCheck,
  sendOnDate,
  deliverByDate,
  onSubmit,
  onComplete,
  confirmVendor = false
}) {
  return (dispatch) => {
    dispatch(launchSubmitPayLaterModal({
      invoice,
      amount: parseFloat(amount),
      sendOnDate,
      deliverByDate,
      isPaperCheck,
      onSubmit,
      onComplete,
      confirmVendor
    }));
  };
}

export function closeModal(modalValue) {
  return (dispatch, getState) => {
    dispatch({
      type: ModalActionTypes.Close
    });
  };
}

export function editBankAccount(bankAccountId, onComplete) {
  return (dispatch) => {
    dispatch(launchEditBankAccountModal({
      bankAccountId,
      onComplete,
      allowDelete: false
    }));
  };
}

export function disableAccount(bankAccountId, onComplete) {
  return (dispatch) => {
    dispatch(launchDisableDeleteAccountModal({
      bankAccountId,
      onComplete
    }));
  };
}

export function deleteAccount(bankAccountId, onComplete) {
  return (dispatch) => {
    dispatch(launchDisableDeleteAccountModal({
      bankAccountId,
      onComplete,
      shouldDelete: true
    }));
  };
}

export function secureAccount(twoFactorInstruction, securityQuestionsInstruction, canCancel, callback) {
  return (dispatch) => {
    let params = {};
    if (twoFactorInstruction) {
      params.twoFactorInstruction = twoFactorInstruction;
    }
    if (securityQuestionsInstruction) {
      params.securityQuestionsInstruction = securityQuestionsInstruction;
    }
    params.canCancel = canCancel;
    if (typeof callback === 'function') {
      params.onComplete = callback;
    }

    dispatch(launchSecureAccountModal(params));
  };
}

export function disputeInvoices(invoices, onComplete) {
  return (dispatch) => {
    dispatch(launchDisputeInvoicesModal({
      invoices,
      onComplete
    }));
  };
}

export function rejectInvoices(invoices, onComplete) {
  return (dispatch) => {
    dispatch(launchRejectInvoiceModal({
      invoices: invoices,
      onComplete
    }));
  };
}

export function unvoidInvoices(invoices, onComplete) {
  return (dispatch) => {
    dispatch(launchUnvoidInvoiceModal({
      invoices,
      onComplete
    }));
  };
}

export function voidInvoices(invoices, onComplete) {
  return (dispatch) => {
    dispatch(launchVoidInvoiceModal({
      invoices,
      onComplete
    }));
  };
}

export function rememberAPInboxVendor(invoice, companySelection) {
  return (dispatch) => {
    dispatch(launchRememberApInboxVendorModal({
      invoices: [invoice],
      companySelection
    }));
  };
}

export const shareInvoice = (invoice) => {
  return (dispatch) => {
    dispatch(launchShareInvoiceModal({
      invoice
    }));
  };
};

export function verifyAccount(accountId, onComplete) {
  return (dispatch) => {
    dispatch(launchVerifyAccountModal({
      accountId,
      onComplete
    }));
  };
}

export const setupAutoPay = ({
  isEdit = false,
  existingAccounts,
  addressBookId,
  companyName,
  endDate,
  memoLine,
  paymentAccountId,
  paymentAmount,
  paymentMethodType,
  payPastDueBills,
  period,
  periodStartDate,
  type,
  isPersonal = false
}, onComplete) => {
  return (dispatch) => {
    dispatch(launchSetupAutoPayModal({
      isEdit,
      existingAccounts,
      addressBookId,
      companyName,
      endDate,
      memoLine,
      paymentAccountId,
      paymentAmount,
      paymentMethodType,
      payPastDueBills,
      period,
      periodStartDate,
      type,
      onComplete,
      isPersonal
    }));
  };
};

export function handleSubmitError(submitError, onComplete) {
  return (dispatch) => {
    dispatch(launchPaymentQueueErrorModal({
      submitError,
      onComplete
    }));
  };
}

export function commentInvoice(invoices, onComplete) {
  return (dispatch) => {
    dispatch(launchCommentInvoiceModal({
      invoices,
      onComplete
    }));
  };
}

export function linkInvoice(invoice, possibilities, onComplete) {
  return (dispatch) => {
    dispatch(launchLinkInvoiceModal({
      invoice,
      possibilities,
      onComplete
    }));
  };
}

export const showConfirmationModal = (
  title,
  subtitle,
  primaryButtonText,
  secondaryButtonText,
  primaryButtonConfig,
  secondaryButtonConfig,
  useCloseButton = false,
  isSkinny = true
) => {
  return (dispatch) => {
    dispatch(launchConfirmationModal({
      title,
      subtitle,
      primaryButtonText,
      secondaryButtonText,
      primaryButtonConfig,
      secondaryButtonConfig,
      useCloseButton
    }, {
      skinny: isSkinny
    }));
  };
};
