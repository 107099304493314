/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { addModal } from 'config/modals/registry';
import { wrapImport } from 'decorators/ModuleLoader';

const AddMissingSignatureModal = wrapImport(
  () => import(/* webpackChunkName: "modals" */ './index')
);

export default addModal('AddMissingSignatureModal', AddMissingSignatureModal, { wide: true });
