/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { shallowEqual, useSelector } from 'react-redux';
import isCapabilityEnabled from 'actions/app/isCapabilityEnabled';

const useIsCapabilityEnabled = capability => useSelector(
  state => isCapabilityEnabled(state, capability),
  shallowEqual
);

export default useIsCapabilityEnabled;