/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { defineMessages } from 'react-intl';

export default defineMessages({
  InvalidPaymentDate: {
    id: 'ValidationError.CreditCard.InvalidCardNumber',
    defaultMessage: 'A payment can either be made today or scheduled up to a year from now.'
  }
});
