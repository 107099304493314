/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

/**
 * Us tcomb type information to determine possible options for a t.enums type
 * @param {*} props
 * @returns array of options
 */
export function getOptionsFromEnum(type) {
  if (!type || !type.meta) {
    // no tcomb type
    return null;
  }

  if (type.meta.kind === 'maybe') {
    // pass over maybe for t.maybe(t.enums(...))
    type = type.meta.type;
  }

  if (type.meta.kind !== 'enums') {
    // not an enum
    return null;
  }

  let enumMap = type.meta.map;
  let enumKeys = Object.keys(enumMap);

  return enumKeys.map((enumKey) => {
    let enumValue = enumMap[enumKey];
    // follow option format
    return {
      value: enumKey,
      text: enumValue
    };
  });
}