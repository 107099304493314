/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { newState } from './utils';

export default function deviceInfo(state = {}, action) {

  if (action.type === 'DeviceInfoService_isMobile') {
    return newState(state, {
      isMobile: action.isMobile
    });
  }

  if (action.type === 'DeviceInfoService_isTablet') {
    return newState(state, {
      isTablet: action.isTablet
    });
  }

  return state;
}
