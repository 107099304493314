/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { queryParamNameMapper } from 'api/common/mappers/coreMappers';
import { createApiAction } from 'api/core';

import actionNumberToAction from './mappers/actionNumberToAction';
import mapSecurityQuestions from './mappers/mapSecurityQuestions';

const getResetPasswordRequest = createApiAction({
  method: 'get',
  url: '/ajax/resetPassword',
  queryStringMapper: queryParamNameMapper({
    'id': 'id'
  }),
  responseMapper: ({ success, response }) => {
    if (!success || !response) return {};

    const {
      action,
      emailAddress,
      isExpired,
      isLocked,
      newPasswordToken
    } = response;

    if (isLocked) {
      return {
        result: { failure: 'Locked' }
      };
    }

    if (isExpired) {
      return {
        result: {
          emailAddress,
          failure: 'Expired'
        }
      };
    }

    const actionEnum = actionNumberToAction(action, newPasswordToken);

    return {
      result: {
        action: actionEnum,
        newPasswordToken: newPasswordToken,
        securityQuestion: actionEnum === 'SecurityQuestionAuthRequired' ? {
          questions: mapSecurityQuestions(response)
        } : null
      }
    };
  }
});

export default getResetPasswordRequest;