/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { createApiAction } from 'api/core';

import { IsUnauthorizedToUseAppEntity } from 'schemas/session/UseAppAuthorizationStatus';

import logonRequestBodyMapper from './mappers/logonRequestBodyMapper';
import logonResponseMapper from './mappers/logonResponseMapper';

const logon = createApiAction({
  method: 'post',
  url: '/ajax/logon',
  requestBodyMapper: logonRequestBodyMapper,
  // Wipe out any authentication status if logon is done - this is effectively
  // done to wipe out an unauthenticated status with state in it.
  // Also wipe out all non-global entity state so the user doesn't see data from
  // a previous session
  requestEntityMapper: ({ ignoreWipe }) => ({
    entities: [ IsUnauthorizedToUseAppEntity ],
    updated: ignoreWipe ? null : {
      anyOutsideScope: 'global',
      reason: 'Deleted'
    }
  }),
  responseMapper: logonResponseMapper
});

export default logon;