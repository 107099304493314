/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React from 'react';
import Panel, { PanelVariants } from 'components/Panel';
import BodyText, { BodyTextVariants } from 'components/BodyText';
import Currency from 'components/Currency';
import FormattedDate from 'components/FormattedDate';
import Icon, { IconTypes } from 'components/Icon';
import CancellationPolicyTooltip from 'components/CancellationPolicyTooltip';
import Messages from './index.messages';

// Starts with 'on the ...'
export const BillingDateMessage = ({ dayOfTheMonth }) => dayOfTheMonth > 28
  ? <Messages.LastDayBilling.Message />
  : <Messages.MidMonthBilling.Message day={dayOfTheMonth} />;

export const MonthlyPaymentSuccessDescriptionPanel = ({
  amount,
  billingDate
}) => {
  const dayOfTheMonth = billingDate.getDate();

  return (
    <Panel
      header={(
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Icon
            className="margin-8-right"
            type={IconTypes.CheckmarkCircleGreen}
          />
          <Messages.SuccessHeader.Message/>
          <div className="text-right" style={{ flexGrow: 2 }}>
            <Currency value={amount} />
          </div>
        </div>
      )}
      variant={PanelVariants.Clean}
    >
      <Messages.SuccessInstructions.Message
        amount={<Currency value={amount} />}
        billingDay={<BillingDateMessage dayOfTheMonth={dayOfTheMonth} />}
      />
    </Panel>
  );
};

const MonthlyPaymentDescriptionPanel = ({
  amount,
  billingDate,
  delinquencyDate,
  inFuture,
  overrideHeader,
  showCancellationPolicy
}) => {
  const dayOfTheMonth = billingDate.getDate();
  let InstructionMessage = Messages.SubscriptionInstructions;

  if (delinquencyDate) InstructionMessage = Messages.DelinquentSubscriptionInstructions;
  else if (inFuture) InstructionMessage = Messages.FutureSubscriptionInstructions;

  return (
    <Panel
      header={(
        <div>
          {overrideHeader || <Messages.SubscriptionInstructionsHeader.Message/>}
          <div style={{right: 0, top: 0, position: 'absolute'}}>
            <Currency value={amount} />
          </div>
        </div>
      )}
      variant={PanelVariants.Clean}
    >
      <BodyText variant={BodyTextVariants.Smaller} color="darkGrey">
        <InstructionMessage.VpMessage
          amount={<Currency value={amount} />}
          billingDay={<BillingDateMessage dayOfTheMonth={dayOfTheMonth} />}
          billingDate={<FormattedDate value={billingDate} />}
          feesNotice={<Messages.FeesSection.Message />}
        />
        {showCancellationPolicy == null || showCancellationPolicy ? (
          <>
            <br/>
            <CancellationPolicyTooltip />
          </>
        ) : null}
      </BodyText>
    </Panel>
  );
};

export default MonthlyPaymentDescriptionPanel;
