/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, { useMemo, useState } from 'react';

import BlueHeaderOnWhiteLayout from 'layouts/BlueHeaderOnWhite';
import ProfessionalHeaderOnWhiteLayout from 'layouts/ProfessionalHeaderOnWhiteLayout';

import BodyText from 'components/BodyText';
import HeaderText, { HeaderTextVariants } from 'components/HeaderText';
import PathLink from 'components/Link/PathLink';

import { MetadataTargetCompanyStatus } from 'config/constants/registration';

import StandardRegistration from 'containers/Registration/StandardRegistration';
import BasicRegistrationWorkflow from 'containers/Registration/BasicRegistrationWorkflow';
import ClosedRegistration from 'containers/Registration/ClosedRegistration';
import ExpeditedRegistrationWorkflow from 'containers/Registration/ExpeditedRegistrationWorkflow';
import UserRegistrationWorkflow from 'containers/Registration/UserRegistrationWorkflow';

import useIsMobile from 'hooks/browser/useIsMobile';
import useIsAuthorizedToUseApp from 'hooks/identity/useIsAuthorizedToUseApp';
import useQueryParams from 'hooks/location/useQueryParams';
import {
  useRegistrationMetadata,
  useRegistrationResult
} from 'hooks/registration';
import useIsCapabilityEnabled from 'hooks/settings/useIsCapabilityEnabled';

import { AuthRedirect } from 'routes/utils';

import AdditionalRegistrationContext from './AdditionalContext';
import RegistrationOpenLanding from './OpenLanding';
import Messages from './index.messages.js';

const calculateWillSeeClosedRegistration = ({
  canOpenRegister,
  forceShowTarget,
  invitationId,
  referralToken
}) => {
  const isOpenRegistration = !invitationId && !referralToken;
  const cannotOpenReg = !canOpenRegister || forceShowTarget === 'closed';
  return isOpenRegistration && (cannotOpenReg && forceShowTarget !== 'open');
};

const useGetRegistrationView = ({
  invitationId,
  referralToken
}) => {
  // Hooks
  const {
    expedited: forceExpeditedRegistration,
    target: forceShowTarget
  } = useQueryParams([
    'expedited',
    'target'
  ]);

  const {
    invitationType,
    isUserRegistration,
    context
  } = useRegistrationMetadata() || {};

  if (context === 'StandardRegistration') {
    return ['standard', StandardRegistration];
  }

  const canOpenRegister = useIsCapabilityEnabled('OpenRegistration');

  const willSeeClosedRegistration = calculateWillSeeClosedRegistration({
    canOpenRegister,
    forceShowTarget,
    invitationId,
    referralToken
  });

  // Return

  return useMemo(
    () => {
      if (willSeeClosedRegistration) {
        return [ 'closed', ClosedRegistration ];
      }

      if (isUserRegistration) {
        return [ 'user', UserRegistrationWorkflow ];
      }

      if (invitationType === 'Company' || (invitationId && forceExpeditedRegistration)) {
        return [ 'expedited', ExpeditedRegistrationWorkflow ];
      }

      if (invitationId || referralToken) {
        return [ 'basic', BasicRegistrationWorkflow ];
      }


      return [ 'open', RegistrationOpenLanding ];
    },
    [
      willSeeClosedRegistration,
      isUserRegistration,
      invitationId,
      invitationType
    ]
  );
};

export const RegistrationLayout = ({
  children,
  invitationId,
  referralToken: originalReferralToken,
  width
}) => {
  // Hooks
  const [ registrationType ] = useGetRegistrationView({
    invitationId,
    referralToken: originalReferralToken
  });

  const isMobile = useIsMobile();

  const {
    companyName,
    displayAddPaymentAccount,
    firstName,
    from,
    lastName,
    targetCompanyInfo,
    context
  } = useRegistrationMetadata() || {};

  const referralToken = (originalReferralToken || '').toLowerCase();

  const { connectionType, isBankUnitedInvitedCompany, status } = targetCompanyInfo || {};
  const { companyName: fromCompanyName } = from || {};

  const isCustomerInvite = connectionType === 'Customer';

  const { isRegistered } = useRegistrationResult() || {};

  const isPartiallyRegistered = status === MetadataTargetCompanyStatus.PartialRegistration
    || isRegistered;

  let contentWidth = isMobile ? 320 : 650;
  let backgroundColor = null;
  if (!isMobile && referralToken === 'riskmaster') {
    contentWidth = 900;
  } else if (!isMobile && width) {
    contentWidth = width;
  }

  if (context === 'StandardRegistration') {
    backgroundColor = '#f7f7f7';
  }

  // Render
  if (registrationType === 'closed' || registrationType === 'open' || registrationType === 'standard') {
    return (
      <BlueHeaderOnWhiteLayout contentWidth={contentWidth} backgroundColor={backgroundColor}>
        {children}
      </BlueHeaderOnWhiteLayout>
    );
  }

  if (registrationType === 'user') {
    return (
      <ProfessionalHeaderOnWhiteLayout
        header={<Messages.UserHeader.Message/>}
        subheader={<Messages.UserSubheader.Message/>}
        contentWidth={contentWidth}
      >
        <div style={{ maxWidth: 480, margin: '0 auto' }}>
          <HeaderText variant={HeaderTextVariants.Small} style={{ paddingBottom: 12 }}>
            About your Viewpost Account
          </HeaderText>
          <BodyText style={{ paddingBottom: 12 }}>
            {isBankUnitedInvitedCompany ? (
              <Messages.BankUnitedUserInstructions.Message />
            ) : (
              <Messages.UserInstructions.Message />
            )}
          </BodyText>
          <br/>
          <BodyText style={{ paddingBottom: 12 }}>
            Company: <span className="type-strong">{companyName}</span>&nbsp;
            User: <span className="type-strong">{firstName} {lastName}</span>
          </BodyText>
          {children}
        </div>
      </ProfessionalHeaderOnWhiteLayout>
    );
  }

  let header = null;
  let subheader = null;

  if (!isPartiallyRegistered) {
    if (displayAddPaymentAccount === 'Required') {
      header = <Messages.SetupElectronicPaymentsHeader.Message />;
      subheader = isCustomerInvite
        ? <Messages.CustomerSetupElectronicPaymentsSubheader.Message companyName={fromCompanyName} />
        : <Messages.VendorSetupElectronicPaymentsSubheader.Message companyName={fromCompanyName} />;
    }
  } else {
    header = <Messages.PartialRegistrationHeader.Message />;
    subheader = displayAddPaymentAccount === 'Required' && !isCustomerInvite
      ? <Messages.PaymentAccountRequiredSubheader.Message />
      : <Messages.AddPasswordSubheader.Message />;
  }

  return (
    <ProfessionalHeaderOnWhiteLayout
      header={header || <Messages.NoContextHeader.Message />}
      subheader={subheader ||  <Messages.NoContextSubheader.Message />}
      contentWidth={contentWidth}
    >
      <AdditionalRegistrationContext>
        {children}
      </AdditionalRegistrationContext>
    </ProfessionalHeaderOnWhiteLayout>
  );
};

const RegistrationView = ({
  invitationId,
  referralToken
}) => {
  // Hooks
  const isAuthorizedToUseApp = useIsAuthorizedToUseApp();

  const [ wasAuthorizedToUseApp ] = useState(isAuthorizedToUseApp);

  const [ , RegistrationWorkflow ] = useGetRegistrationView({
    invitationId,
    referralToken
  });

  // Render
  if (isAuthorizedToUseApp) {
    if (!wasAuthorizedToUseApp) {
      return <AuthRedirect />;
    }

    return (
      <div className="text-center">
        <BodyText>You are already logged in.</BodyText>
        <div>
          <PathLink.Dashboard button={true}>
            Go to App
          </PathLink.Dashboard>
        </div>
      </div>
    );
  }

  return <RegistrationWorkflow />;
};

export default RegistrationView;
