/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import './index.scss';
import classNames from 'classnames';
import ProvideIsMobile from 'decorators/ProvideIsMobile';
import Icon, { IconTypes } from 'components/Icon';
import WatchWindowSizeWrapper from 'decorators/WatchWindowSize';

class HorizontalScrollBox extends Component {
  static propTypes = {
    mobileOnly: PropTypes.bool // whether or not the scroller should only be applied for mobile
  };

  static defaultProps = {
    mobileOnly: true
  }

  constructor(props) {
    super(props);
    this.state = {
      canScrollLeft: false,
      canScrollRight: false
    };

    this.onWindowSizeChange = this.onWindowSizeChange.bind(this);
  }

  onWindowSizeChange() {
    if (this.scroller) {
      this.updateScrollState();
    }
  }

  updateScrollState() {
    let scroller = ReactDOM.findDOMNode(this.scroller);
    if (scroller) {
      let canScrollHoriz = scroller.scrollWidth > scroller.offsetWidth;
      let leftPosition = scroller.scrollLeft;
      let rightPosition = scroller.offsetWidth + scroller.scrollLeft;

      let canScrollLeft = canScrollHoriz && leftPosition > 0;
      let canScrollRight = canScrollHoriz && rightPosition < scroller.scrollWidth;
      if (canScrollLeft !== this.state.canScrollLeft || canScrollRight !== this.state.canScrollRight) {
        this.setState({
          canScrollLeft,
          canScrollRight
        });
      }
    }
  }

  onScroll(willScrollRight) {
    let scroller = ReactDOM.findDOMNode(this.scroller);
    if (scroller) {
      scroller.scrollLeft += willScrollRight ? 20 : -20;
      this.updateScrollState();
    }
  }

  setScrollerRefAndUpdate(ref) {
    this.scroller = ref;
    if (this.scroller) {
      this.updateScrollState();
    }
  }

  render() {
    if (this.props.mobileOnly && !this.props.isMobile) {
      return this.props.children;
    }

    let classes = classNames('component-horizontal-scroller', {
      'scrollable-left': this.state.canScrollLeft,
      'scrollable-right': this.state.canScrollRight,
      'border': this.props.showBorder
    });

    return (
      <div className={classes}>
        <div ref={r => this.setScrollerRefAndUpdate(r)}
          className={classNames('scroller-content', {
            'is-left-arrow-visibile': this.state.canScrollLeft,
            'is-right-arrow-visibile': this.state.canScrollRight
          })} onScroll={() => this.updateScrollState()}>
          {this.props.children}
        </div>
        {this.state.canScrollLeft
          ? <div className="scroll-left-indicator" onClick={() => this.onScroll(false)}>
            <Icon type={IconTypes.LeftArrow} />
          </div>
          : null}
        {this.state.canScrollRight
          ? <div className="scroll-right-indicator" onClick={() => this.onScroll(true)}>
            <Icon type={IconTypes.RightArrow} />
          </div>
          : null}
      </div>
    );
  }
}

export default ProvideIsMobile(WatchWindowSizeWrapper(HorizontalScrollBox));
