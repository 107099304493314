/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React from 'react';

import LanguageMap from 'messages';
import createLanguageService from './createLanguageService';

const BaseLanguageService = createLanguageService(LanguageMap);

const LanguageService = ({
  acceptLanguage,
  children
}) => (
  <BaseLanguageService acceptLanguage={acceptLanguage}>
    {children}
  </BaseLanguageService>
);

export default LanguageService;
