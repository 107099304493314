/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import t from 'tcomb-validation';

/**
 * Created this to deal with a tcomb-form issue where it tries to
 * calculate the type of a union, but undefined/null values result
 * in nothing causing errors. This defaults to the first type if
 * none match.
 */
export const createUnionTuple = (...types) => {
  const union = t.union([...types]);
  const defaultUnionDispatch = union.dispatch;
  union.dispatch = (x) => {
    const type = defaultUnionDispatch(x);
    return type || types[0];
  };
  return union;
};

export const StringOrNumberUnion = createUnionTuple(t.String, t.Number);
