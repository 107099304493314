/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, { Fragment, memo } from 'react';
import { ConnectedRouter } from 'connected-react-router';
import Cookies from 'js-cookie';
import PropTypes from 'prop-types';
import { GatewayProvider } from 'react-gateway';

import { isApiResultSuccessful } from 'api/core/utils.state';
import getIdentity from 'api/session/getIdentity';
import getApplicationSettings from 'api/settings/getApplicationSettings';

import DisplayWorkflow from 'components/DisplayWorkflow';

import history from 'config/history';
import AppRoutes from 'config/routes';

import AnalyticsCategoryService from 'containers/AnalyticsCategoryService';
import CatchAppError from 'containers/CatchAppError';
import FeatureBranchWarning from 'containers/FeatureBranchWarning';
import ModalService from 'containers/ModalService';
import NotificationManager from 'containers/NotificationManager';
import AnalyticsService, { AnalyticsPathService } from 'containers/Services/AnalyticsService';
import ApiConfigService from 'containers/Services/ApiConfigService';
import MixpanelService from 'containers/Services/MixpanelService';
import LanguageService from 'containers/Services/LanguageService';
import KeepAliveService from 'containers/Services/KeepAliveService';
import GuidedTourService from 'containers/Services/GuidedTourService';
import DeviceInfoService from 'containers/Services/DeviceInfoService';
import PrintService from 'containers/Services/PrintService';
import StripeSettingsService from 'containers/Services/StripeSettingsService';
import TrackEmailIdService from 'containers/Services/TrackEmailIdService';
import ZendeskHelpService from 'containers/ZendeskHelpService';

import createModuleLoader from 'decorators/ModuleLoader';

import createProvideApiResultHooks from 'hooks/apiResult/createProvideApiResultHooks';
import useStatusIndicator from 'hooks/apiResult/useStatusIndicator';
import ProvideApiResultHooksUpdateService from 'hooks/apiResult/provideHooks/UpdateService';

import ChangeEmailWorkflowStep from './OnAppLoadedWorkflowSteps/ChangeEmail';
import VerifyUserAccountWorkflowStep from './OnAppLoadedWorkflowSteps/VerifyUserAccount';
import DefaultStoreService from './StoreService';

import 'components/Viewstrap/Styles';
import 'css/fonts.css';

const useDebugStore = process.env.NODE_ENV === 'production'
  ? Cookies.get('DevTools') === 'true'
  : Cookies.get('DevTools') !== 'false';

const importDebugStoreModule = () => import(/* webpackChunkName: "aux" */ './StoreService/debug');

const StoreService = useDebugStore ? createModuleLoader({
  importModule: importDebugStoreModule,
  isWholePageLoader: true
}) : DefaultStoreService;

const ToolDockService = useDebugStore ? createModuleLoader({
  importModule: importDebugStoreModule,
  importModuleKey: 'DebugToolDockService'
}) : Fragment;

const ToolDock = useDebugStore ? createModuleLoader({
  importModule: importDebugStoreModule,
  importModuleKey: 'DebugToolDock'
}) : Fragment;

const GetIdentityHooks = createProvideApiResultHooks({
  endpoint: getIdentity,
  // This *really* isn't global scope, but this conveniently gives us the
  // behavior that the identity is only loaded once by this hook and we never
  // lose that state. This does give us the effect that the identity is also
  // given a global context but that will get wiped out various ways when a new
  // identity is created or the identity is deleted.
  entityScope: 'global'
});

const GetAppSettingsHooks = createProvideApiResultHooks({
  endpoint: getApplicationSettings,
  entityScope: 'global'
});

const StatusIndicatorConfig = [
  {
    // Only coalesce if there is a previous result to coalesce to
    // TODO: I'm surprised I need to do this - I feel like coalescing shouldn't
    // happen on the first call.
    useWillLoadOrIsLoading: GetIdentityHooks
      .useWillLoadOrIsLoading.meta.bind({
        coalesce: (current, prev) => !isApiResultSuccessful(current) && !!prev
          ? true
          : false
      }),
    useRequestError: null
  },
  GetAppSettingsHooks
];

const RootLayout = memo(
  ({
    children
  }) => {
    // Hooks
    GetIdentityHooks.useProvideParams({ createUseAppAuthorizedEntity: true });
    GetAppSettingsHooks.useProvideParams({});

    const StatusIndicator = useStatusIndicator(StatusIndicatorConfig);

    // Render
    return (
      <div
        style={{
          position: 'relative',
          height: '100%'
        }}
      >
        {StatusIndicator
          ? <StatusIndicator centerInPage={true} />
          : children}
      </div>
    );
  }
);

const WorkflowSteps = [
  {
    id: 'ChangeEmail',
    element: <ChangeEmailWorkflowStep />
  },
  {
    id: 'VerifyUserAccount',
    element: <VerifyUserAccountWorkflowStep />
  }
];

const ContextServices = ({
  acceptLanguage,
  analyticsId,
  children
}) => (
  <AnalyticsService analyticsId={analyticsId}>
    <LanguageService acceptLanguage={acceptLanguage}>
      <GatewayProvider>
        <ToolDockService>
          {children}
        </ToolDockService>
      </GatewayProvider>
    </LanguageService>
  </AnalyticsService>
);

const BrickmanApp = ({
  acceptLanguage,
  analyticsId,
  modalElementId,
  ...appInitProps
}) => {
  return (
    <CatchAppError
      context="AppRoot"
      ignoreReactIntl={true}
      isWholePage={true}
    >
      <StoreService {...appInitProps}>
        <>
          <ApiConfigService />
          <DeviceInfoService />
          <KeepAliveService />
          <MixpanelService />
          <PrintService />
          <StripeSettingsService />
          <ZendeskHelpService />
        </>
        <ContextServices
          acceptLanguage={acceptLanguage}
          analyticsId={analyticsId}
        >
          <>
            <GuidedTourService />
            <ToolDock />
          </>
          <CatchAppError
            context="AppRoot"
            isWholePage={true}
          >
            <FeatureBranchWarning/>
            <ConnectedRouter history={history}>
              <TrackEmailIdService />
              <ProvideApiResultHooksUpdateService>
                <NotificationManager/>
                <ModalService elementId={modalElementId} />
                <AnalyticsCategoryService>
                  <RootLayout>
                    <AnalyticsPathService />
                    <DisplayWorkflow workflow={WorkflowSteps}>
                      <AppRoutes />
                    </DisplayWorkflow>
                  </RootLayout>
                </AnalyticsCategoryService>
              </ProvideApiResultHooksUpdateService>
            </ConnectedRouter>
          </CatchAppError>
        </ContextServices>
      </StoreService>
    </CatchAppError>
  );
};

BrickmanApp.propTypes = {
  csrfToken: PropTypes.string,
  analyticsId: PropTypes.string,
  inExternalTestEnvironment: PropTypes.bool
};

export default BrickmanApp;
