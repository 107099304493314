/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { defineMessages } from 'react-intl';

export default defineMessages({
  IncorrectLength: {
    id: 'ValidationError.String.IncorrectLength',
    defaultMessage: 'Must be {length} characters.'
  },
  IncorrectLengthRange: {
    id: 'ValidationError.String.IncorrectLength',
    defaultMessage: 'Must be between {minLength} and {maxLength} characters.'
  },
  TooLong: {
    id: 'ValidationError.String.TooLong',
    description: 'TODO',
    defaultMessage: 'Enter no more than {maxLength} characters.'
  },
  DoNotMatch: {
    id: 'ValidationError.String.DoNotMatch',
    description: 'TODO',
    defaultMessage: 'The values you have entered do not match'
  },
  AlreadyTaken: {
    id: 'ValidationError.String.AlreadyTaken',
    description: 'TODO',
    defaultMessage: 'Already Exists'
  }
});
