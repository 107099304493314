/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { toEnumObject } from 'config/constants/utils';

import { createEntityReference, getEntity } from 'schemas/state';

import logError from 'services/ErrorService';

export const AppSetting = toEnumObject([
  'Plaid',
  'Capabilities',
  'Stripe',
  'Attachments',
  'ReCaptcha',
  'Environment',
  'Session'
]);

const getApplicationSetting = (
  state,
  appSetting,
  options
) => {
  const {
    notExpected = false,
    returnNull = false
  } = options || {};

  let schemaName;

  switch (appSetting) {
    case AppSetting.Plaid:
      schemaName = 'AppPlaidSettings';
      break;
    case AppSetting.Capabilities:
      schemaName = 'AppCapabilitySettings';
      break;
    case AppSetting.Stripe:
      schemaName = 'AppStripeSettings';
      break;
    case AppSetting.Attachments:
      schemaName = 'AppAttachmentSettings';
      break;
    case AppSetting.ReCaptcha:
      schemaName = 'AppReCaptchaSettings';
      break;
    case AppSetting.Environment:
      schemaName = 'AppEnvironmentSettings';
      break;
    case AppSetting.Session:
      schemaName = 'AppSessionSettings';
      break;
    default:
      throw new Error(`Unknown app setting ${appSetting}`);
  }

  const setting = getEntity(
    state,
    createEntityReference(
      'current',
      schemaName
    )
  );

  if (!setting && !notExpected) {
    logError(
      `Expected settings ${appSetting}, but setting is missing.`,
      null,
      { includeStackTrace: true }
    );
  }

  // Returning at least an empty object so that a JS error doesn't take React
  // down. We have the logged error, so you there is visiblity into a problem.
  // Unless its not expected, then we want to return a falsy value to reflect
  // it isn't there.
  return setting || (returnNull ? null : {});
};

export default getApplicationSetting;