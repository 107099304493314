/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, { useEffect, useState } from 'react';

import useIsMobile from 'hooks/browser/useIsMobile';
import AddBankAccount from 'containers/AddBankAccount';
import BlueHeaderOnWhiteLayout from 'layouts/BlueHeaderOnWhite';

import connector from './index.connect';


const AddPayeePaymentAccount = ({
  active,
  updateState,
  isShown
}) => {
  const [wasShown] = useState(isShown);

  const isMobile = useIsMobile();

  useEffect(
    () => {
      updateState('loaded');
    },
    []
  );

  useEffect(
    () => {
      if (!wasShown && !isShown) {
        updateState('skipped');
      }
    },
    [isShown]
  );

  // Render
  if (!active) return <></>;

  return (
    <BlueHeaderOnWhiteLayout
      backgroundColor="#f7f7f7"
      contentWidth={isMobile ? null : 750}
      hideLoginLink={true}
    >
      <div className="viewstrap" style={{ paddingTop: '50px' }}>
        <AddBankAccount
          isReceiptAccount={true}
          onSkip={() => updateState('skipped')}
          onComplete={() => updateState('completed')} />
      </div>
    </BlueHeaderOnWhiteLayout>
  );
};

export default connector(AddPayeePaymentAccount);
