/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import logError from 'services/ErrorService';
import Messages from './index.messages';
import connect from './index.connect';

let Instance = null;

export const printPdf = (url) => {
  if (Instance) Instance.printPdf(url);
};

class PrintService extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.printPdf = (url) => {
      let update = () => {
        this.props.showSmallBanner(<FormattedMessage {...Messages.Downloading} />);
        this.setState({printUrl: url});
      };
      if (this.state.printUrl) {
        this.setState({printUrl: null}, update);
      } else {
        update();
      }
    };
  }

  componentDidMount() {
    Instance = this;
  }

  componentWillUnmount() {
    Instance = null;
  }

  onIframeLoad() {
    const { showSmallBanner } = this.props;

    try {
      if (this.iframe) {
        if (this.iframe.contentWindow && this.iframe.contentWindow.print) {
          this.iframe.contentWindow.focus();
          this.iframe.contentWindow.print();
        }
      }
    } catch (e) {
      showSmallBanner(<FormattedMessage {...Messages.Error} />, true);
      logError('Error when trying to print document.', {
        message: e.message
      });
    }
  }

  render() {
    if (!this.state.printUrl) return null;

    return (
      <iframe ref={r => this.iframe = r}
        style={{display: 'none'}}
        onLoad={() => this.onIframeLoad()}
        src={this.state.printUrl}/>
    );
  }
}

export default connect(PrintService);
