/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, { useState, useRef } from 'react';

import Tooltip, { Placements } from 'components/Tooltip';

import './index.scss';

/**
 * Implement an Action menu using the tooltip component
 */
function TooltipActionMenu({
  /** Optional header content to show at top of menu */
  header,

  /** array of action objects that can have: name, label, help, onClick */
  actions,

  children,
  ...props
}) {
  const [activeAction, setActiveAction] = useState(null);
  const tooltipRef = useRef();

  let hasHelp = actions.some(a => !!a.help);

  const onClickAction = (action) => {
    action?.onClick();

    // always close the menu when selecting an action
    tooltipRef?.current?.close();
  };

  const onVisibleChange = (visible) => {
    // clear selection when toggling the menu
    setActiveAction(null);
  };

  let overlay = (
    <div className="component-tooltip-action-menu"
      style={{
        minHeight: hasHelp ? '150px' : null
      }}>
      {hasHelp
        ? (
          <div className={`help-section ${!activeAction ? 'no-content' : ''}`}>
            {activeAction?.help}
          </div>
        )
        : null}
      {header
        ? (
          <div className="header">
            {header}
          </div>
        )
        : null}
      {actions?.map((action) => {
        return (
          <div className={`action-item ${activeAction?.name === action.name ? 'active' : ''}`}
            key={action.name}
            onClick={() => onClickAction(action)}
            onMouseOver={() => setActiveAction(action)}>
            {action.label}
          </div>
        );
      })}
    </div>
  );

  // as a menu default to a bottom placement (rather than top as per normal tooltip)
  let placement = props.placement ?? Placements.Bottom;

  return (
    <Tooltip {...props}
      ref={tooltipRef}
      overlay={overlay}
      placement={placement}
      padContent={false}
      onVisibleChange={onVisibleChange}
      trigger={['click']}>
      {children}
    </Tooltip>
  );
}

export default TooltipActionMenu;