/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { useMemo } from 'react';
import { useDispatch } from 'react-redux';

import applyExtendHooks from './utils/applyExtendHooks';
import createBaseApiResultHooks from './utils/createBaseApiResultHooks';
import createUseApiResultHooks from './utils/createUseApiResultHooks';
import executeRequest from './utils/executeRequest';

// See createBaseApiResultHooks for the common params
const createExecuteApiResultHooks = (
  args,
  // An optional function to take in the output of this function and add
  // additional hooks to it. (hooks, additionalInfo) => null. Modify the hooks
  // param directly.
  extendHooks
) => {
  const {
    applyWrapper,
    entryApiResultId,
    hookConfig,
    Hooks: BaseHooks,
    Redux: BaseRedux
  } = createBaseApiResultHooks(args);

  const executeRequestAction = (
    requestParams,
    callConfig
  ) => dispatch => executeRequest({
    callConfig: {
      // Because the call is being made on demand, displaying an error message if it fails is
      // very tactile and helpful for the user
      failureMessage: true,
      ...(callConfig || {})
    },
    dispatch,
    entryApiResultId,
    hookConfig,
    requestParams
  });

  const useExecuteRequest = applyWrapper(
    () => {
      const dispatch = useDispatch();

      return useMemo(
        () => async (
          requestParams,
          callConfig
        ) => executeRequest({
          callConfig: {
            // Because the call is being made on demand, displaying an error message if it fails is
            // very tactile and helpful for the user
            failureMessage: true,
            ...(callConfig || {})
          },
          dispatch,
          entryApiResultId,
          hookConfig,
          requestParams
        }),
        [] // No deps, just need a stable reference
      );
    }
  );

  const output = {
    ...BaseHooks,
    Redux: {
      ...BaseRedux,
      executeRequestAction
    },
    useExecuteRequest
  };

  if (extendHooks) {
    applyExtendHooks(
      extendHooks,
      output,
      {
        applyWrapper,
        entryApiResultId,
        hookConfig
      }
    );
  }

  return output;
};

export const useExecuteApiResultHooks = createUseApiResultHooks(createExecuteApiResultHooks);

export default createExecuteApiResultHooks;