/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { isFunction, isNil } from 'lodash';
import {
  createEntity,
  createEntityReference,
  getEntity,
  getEntityList,
  areEntitiesUpdated
} from 'schemas/state';
import { ApiResult, EntryApiResult } from 'schemas/api';
import { deleteEntitiesAction, markEntitiesUpdatedAction, updateEntityAction } from './utils';

export function doServerErrorsContain(serverMessages, context, message) {
  return serverMessages.some(serverMessage => serverMessage.context === context
      && (isNil(message) ? true : serverMessage.message.includes(message)));
}

export const doServerErrorsContainId = (serverMessages, id, context) => {
  if (!serverMessages || !serverMessages.length) return false;

  return serverMessages.some(message => message.id === id
    && (isNil(context) ? true : message.context === context));
};

export const isApiResultSuccessful = apiResult => !apiResult.loading
  && !apiResult.error
  && !!apiResult.created;

const baseGetApiResultEntity = (
  apiResult,
  state,
  config
) => {
  if (apiResult?.refs?.length == null) return null;

  let filter = null;
  if (isFunction(config)) {
    console.warn(
      'The third paramter is now a config object. Pass in object with a filter field to properly filter now.'
    );
    filter = config;
  } else if (config) {
    filter = config.filter;
  }

  const entityRefs = filter ? apiResult.refs.filter(ref => filter(ref)) : apiResult.refs;
  if (entityRefs.length == null) return null;

  const entity = getEntity(state, entityRefs[0]);

  return config?.selector ? config.selector(entity) : entity;
};

/**
 * previous - get the previous result
 * coalesce - if previous is set, return the previous result or the current result if that doesn't exist.
 *            if previous is not set, return the current result or return the previous result if the current
 *            result doesn't match the coalesceRule parameters
 * coalesceRule - function(currentEntity, previousEntity ): return true if the value that should be coalesced from should be returned
 *                so if previous is set, the entity will be the previous result, and if not, the current result will
 *                by default, this checks if the result is loaded and successful
 */
export const getApiResult = (
  state,
  id,
  config
) => {
  const entity = getEntity(state, createEntityReference(id, ApiResult.meta.name));

  if (!entity) return null;

  const {
    previous: usePreviousResult,
    coalesce: originalShouldCoalesce,
    filter
  } = config || {};

  const checkShouldCoalesce = () => {
    if (!isFunction(originalShouldCoalesce)) {
      if (originalShouldCoalesce !== true) return false;

      // If there is no previous result, never coalesce
      if (!entity.previous) return false;

      return !isApiResultSuccessful(usePreviousResult
        ? entity.previous
        : entity
      );
    }

    return originalShouldCoalesce(
      entity,
      entity.previous,
      {
        isApiResultSuccessful,
        getApiResultEntity: apiResult => baseGetApiResultEntity(apiResult, state, { filter })
      }
    );
  };

  const shouldCoalesce = checkShouldCoalesce();

  if (usePreviousResult) return shouldCoalesce ? entity : entity.previous;

  return shouldCoalesce ? entity.previous : entity;
};

export const isApiResultLoading = (state, id, config) => {
  let apiResult = getApiResult(state, id, config);
  if (!apiResult) return false;
  return apiResult.loading;
};

export const areApiResultEntitiesUpdated = (state, id, config) => {
  let apiResult = getApiResult(state, id, config);
  if (!apiResult) return null;

  if (areEntitiesUpdated(state, [createEntityReference(id, ApiResult.meta.name)])) return true;

  if (!apiResult.refs) return null;

  let filter = null;
  if (isFunction(config)) {
    console.warn(
      'The third paramter is now a config object. Pass in object with a filter field to properly filter now.'
    );
    filter = config;
  } else if (config) {
    filter = config.filter;
  }

  if (filter) return areEntitiesUpdated(state, apiResult.refs.filter(ref => filter(ref)));
  return areEntitiesUpdated(state, apiResult.refs);
};

export const getApiResultRequestParams = (state, id, config) => {
  let apiResult = getApiResult(state, id, config);
  if (!apiResult) return null;
  return apiResult.request;
};

// This will only be populated if 'saveResponseToResult' is set on the endpoint config
export const getApiResultResponse = (state, id, config) => {
  let apiResult = getApiResult(state, id, config);
  if (!apiResult) return null;
  return apiResult.response;
};

// This will only be populated if 'saveResponseMapperResultToResult' is set on the endpoint config
export const getApiResultResponseMapperResult = (state, id, config) => {
  let apiResult = getApiResult(state, id, config);
  if (!apiResult) return null;
  return apiResult.responseMapperResult;
};

export const getApiResultPagination = (state, id, config) => {
  let apiResult = getApiResult(state, id, config);
  if (!apiResult) return null;
  return apiResult.pagination;
};

export const getApiResultError = (state, id, config) => {
  let apiResult = getApiResult(state, id, config);
  if (!apiResult) return null;
  return apiResult.error;
};

export const getApiResultIsSuccessful = (state, id, config) => {
  let apiResult = getApiResult(state, id, config);
  if (!apiResult) return null;
  return isApiResultSuccessful(apiResult);
};

export const hasApiResultCompleted = (state, id, config) => {
  const apiResult = getApiResult(state, id, config);
  if (!apiResult) return false;
  const { completed } = apiResult;
  return !!completed;
};

export const getApiResultEntities = (state, id, config) => {
  let apiResult = getApiResult(state, id, config);
  if (apiResult?.refs?.length == null) return null;

  let filter = null;
  if (isFunction(config)) {
    console.warn(
      'The third paramter is now a config object. Pass in object with a filter field to properly filter now.'
    );
    filter = config;
  } else if (config) {
    filter = config.filter;
  }

  const refs = filter
    ? apiResult.refs.filter(ref => filter(ref))
    : apiResult.refs;

  const entities = getEntityList(state, refs);

  return config?.selector ? entities.map(config.selector) : entities;
};

export const getApiResultEntity = (state, id, config) => baseGetApiResultEntity(
  getApiResult(state, id, config),
  state,
  config
);

export const getEntryApiResult = (state, id) => {
  const ref = createEntityReference(id, EntryApiResult.meta.name);
  return getEntity(state, ref);
};

export const isEntryApiResultUpdated = (state, id) => areEntitiesUpdated(
  state,
  [ createEntityReference(id, EntryApiResult.meta.name) ]
);

export const removeApiResultAction = id => updateEntityAction([
  createEntity(id, ApiResult.meta.name, null)
]);

export const getEntryApiResultReferences = (state, ids, getAllResults) => {
  const references = [];

  ids.forEach((id) => {
    const entryApiResult = getEntryApiResult(state, id);
    if (entryApiResult) {
      references.push(createEntityReference(id, EntryApiResult.meta.name));

      if (getAllResults) {
        if (entryApiResult.current) {
          references.push(entryApiResult.current);
        }

        entryApiResult.previous.forEach(x => references.push(x));
      }
    }
  });

  return references;
};

export const invalidateEntryApiResults = (ids, invalidateEverything) => (dispatch, getState) => {
  const state = getState();
  const entitesToInvalidate = getEntryApiResultReferences(state, ids, invalidateEverything);

  if (entitesToInvalidate.length) {
    dispatch(markEntitiesUpdatedAction(entitesToInvalidate, 'Invalid'));
  }
};

export const deleteEntryApiResults = (ids, deleteEverything) => (dispatch, getState) => {
  const state = getState();
  const entitesToInvalidate = getEntryApiResultReferences(state, ids, deleteEverything);

  if (entitesToInvalidate.length) {
    dispatch(deleteEntitiesAction(entitesToInvalidate));
  }
};

export const createEntryApiResultReference = id => createEntityReference(id, EntryApiResult.meta.name);
