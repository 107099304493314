/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React from 'react';
import classNames from 'classnames';
import CompanyLogo from 'components/Network/CompanyLogo';
import './index.scss';

const CompanyRadioButton = ({
  company,
  className,
  showLogo = true,
  onClick,
  isChecked,
  tag
}) => {
  if (company === null) {
    return <div/>;
  }

  const lines = company.address.linesWithoutAddressee
    ? company.address.linesWithoutAddressee
    : [company.address.line1, company.address.line2, company.address.line3, company.address.line4 ].filter(a => a);

  return (
    <div className={classNames('company-radio-button', className, {'no-logo': !showLogo})}
      onClick={onClick}>
      {showLogo
        ? <CompanyLogo className="logo-image" id={`1${company.id}`} width={120} height={72}/>
        : null}
      <input type="radio" name="company" className="radio" value={company.id}
        checked={isChecked(company.id)} onChange={() => null}/>
      <div className="content">
        <strong>{company.companyName}</strong>
        {company.address && company.address.city
          ? <div>
            {lines.map((line, i) => <span key={i}>{line}</span>)}
            <span>,&nbsp;</span>
            {`${company.address.city}, ${company.address.stateProvince} ${company.address.postalCode}`}
          </div>
          : null}
      </div>
      {tag}
    </div>
  );
};

export default CompanyRadioButton;
