/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { Component } from 'react';
import PropTypes from 'prop-types';

/**
  Configuration component representing a column in a <TableView>
  @docscomponent
*/
class TableViewColumn extends Component {
  static propTypes = {
    /** The table header cell content to render for this column */
    header: PropTypes.node,
    /** The table footer cell content to render for this column */
    footer: PropTypes.node,
    /** CSS classes to apply to all cells in this column */
    className: PropTypes.string,
    /** style to apply to all cells in this column */
    style: PropTypes.object,
    /** Returns cell content for the row for the given item */
    renderCell: PropTypes.func.isRequired,
    /** Returns cell content for a column rendered directly ABOVE this column (in its own row) */
    renderCellAbove: PropTypes.func,
    /** Returns cell content for a column rendered directly BELOW this column (in its own row) */
    renderCellBelow: PropTypes.func,

    /** Enables sort toggling for this column (by clicking the table header) */
    isSortable: PropTypes.bool,
    /** When isSortable=true, defines the sortField name to use for this column (effects the onSort of the table) */
    sortField: PropTypes.string,

    /** Defines the <FieldOption> to use for this cell (if the row is editable)  */
    fieldOption: PropTypes.object,
    /** Alternative to fieldOption prop. Allows dynamic generation of the form field based on the current row's item */
    fieldGenerator: PropTypes.func
  };

  static defaultProps = {
    isSortable: false
  };

  constructor(props) {
    super(props);
  }
}

export default TableViewColumn;
