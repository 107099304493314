/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React from 'react';

export default ({ children }) => (
  <div
    style={{
      position: 'absolute',
      bottom: '100%',
      width: '100%',
      paddingBottom: 12
    }}
  >
    {children}
  </div>
);
