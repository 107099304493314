/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { InvoiceViewActions } from 'config/constants';
export default {
  path: '/invoices',
  children: {
    List: {
      path: '/list',
      requiredAction: InvoiceViewActions.ViewList
    },
    Details: {
      path: '/details/:type/:id'
    },
    Summary: {
      path: '/summary',
      requiredAction: InvoiceViewActions.ViewSummary
    },
    Vpx: {
      path: '/vpx',
      requiredAction: InvoiceViewActions.ViewInvoiceEarlyPay
    },
    Recurring: {
      path: '/recurring',
      requiredAction: InvoiceViewActions.ViewRecurring
    },
    RecordPayment: {
      path: '/recordPayment'
    },
    QbOnline: {
      path: '/qbonline',
      requiredAction: InvoiceViewActions.ViewQbo
    },
    Create: {
      path: '/create'
    },
    Edit: {
      path: '/edit/:type/:id'
    }
  }
};
