/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import t from 'tcomb-validation';
import { defineSchema } from 'schemas';
import { createMaxLengthStringType } from 'schemas/common/string';

const GroupPermissions = defineSchema({
  approveInvoices: t.Boolean,
  createCheck: t.Boolean,
  manageConnections: t.Boolean,
  manageContacts: t.Boolean,
  mapGLAccounts: t.Boolean,
  modifyBills: t.Boolean,
  modifyCompanySettings: t.Boolean,
  modifyDiscountTables: t.Boolean,
  modifyInvoices: t.Boolean,
  modifyUsersAndGroups: t.Boolean,
  modifyVendorCreditMemos: t.Boolean,
  overrideApprovalWorkflows: t.Boolean,
  reactToDisputes: t.Boolean,
  recordPayments: t.Boolean,
  requestEarlyPayment: t.Boolean,
  signCheck: t.Boolean,
  unvoidChecks: t.Boolean,
  unvoidInvoices: t.Boolean,
  viewFees: t.Boolean,
  voidChecks: t.Boolean,
  voidInvoices: t.Boolean
}, 'GroupPermissions');

const CompanyGroup = defineSchema({
  id: t.String,
  name: createMaxLengthStringType(30),
  deletable: t.Boolean,
  readOnly: t.Boolean,
  permissions: GroupPermissions
}, 'CompanyGroup');

export {
  GroupPermissions,
  CompanyGroup
};
