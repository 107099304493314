/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { BankAccountActions } from 'config/constants/bankAccount';

export const isVerifiableAccount = (account) => {
  const { availableActions } = account || {};

  return (availableActions || []).includes(BankAccountActions.Verify);
};

export const isInactiveAccount = (account) => {
  const { statuses } = account || {};
  return (statuses || []).includes('AccountInactive')
    || (statuses || []).includes('AccountLocked');
};

export const isValidForWhitelisted = (account) => {
  const { statuses } = account || {};
  return (statuses || []).includes('VerificationExpired')
    || (statuses || []).includes('AwaitingMicro');
};

export const isValidAccount = (
  account,
  {
    includeVerifiable,
    isRecipientWhitelisted
  }
) => {
  if (isInactiveAccount(account)) return false;
  if (isRecipientWhitelisted && isValidForWhitelisted(account)) {
    // customer is whitelisted to pay with any unverfied account
    return true;
  } if (includeVerifiable && isVerifiableAccount(account)) {
    // include accounts that can be verified inline
    return true;
  }

  const { statuses } = account || {};

  return (statuses || []).includes('AccountVerified');
};