/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { isEqual, pick, uniqueId, without } from 'lodash';
import { useDispatch, useSelector, useStore } from 'react-redux';

import {
  deleteEntryApiResults,
  getEntryApiResultReferences,
  invalidateEntryApiResults
} from 'api/core/utils.state';

import useQueryParams from 'hooks/location/useQueryParams';
import useFuncRef from 'hooks/react/useFuncRef';
import useImmediateEffect from 'hooks/react/useImmediateEffect';
import useStableRef from 'hooks/utils/useStableRef';

import calculateShouldCall, {
  getCalculateShouldCallState
} from '../utils/calculateShouldCall';
import executeRequest from '../utils/executeRequest';

import LoadingContext from '../loadingState/LoadingContext';
import UpdateContext from './UpdateContext';

const attemptExecuteRequest = ({
  callConfig,
  dispatch,
  dryRun,
  entryApiResultId,
  hookConfig,
  getState,
  requestParams
}) => {
  const shouldCallState = getCalculateShouldCallState(
    getState(),
    entryApiResultId
  );

  if (!calculateShouldCall(
    shouldCallState,
    requestParams,
    callConfig.shouldCallCheck
  )) {
    return dryRun ? false : [ false, { reason: 'ShouldNotCall' } ];
  }

  if (dryRun) return true;

  return executeRequest({
    callConfig,
    dispatch,
    entryApiResultId,
    hookConfig,
    requestParams
  });
};

// This tries to take anything defined in the hook config and normalizes it to a single model so the
// update service doesn't need to sniff out data types when its in operation.
const normalizeHookConfig = ({
  mapQueryParamsToRequest,
  ...config
}) => {

  // The array notation of mapQueryParamsToRequest is just a shorthand for the more expressive
  // object
  if (Array.isArray(mapQueryParamsToRequest)) {
    config.mapQueryParamsToRequest = {
      params: mapQueryParamsToRequest
    };
  }

  return config;
};

const ProvideApiResultHooksUpdateService = ({ children }) => {
  // We are only using this to get the redux state when used outside of
  // rendering, misusing this can lead to bad problems
  const store = useStore();
  const dispatch = useDispatch();

  const { stateRef: loadingStateRef } = useContext(LoadingContext);

  const [ listeners, setListeners ] = useState({});
  const [ listenerConfig, setListenerConfig ] = useState({});
  const [ hookConfig, setHookConfig ] = useState({});
  const [ pendingUpdates, setPendingUpdates ] = useState({});

  // To get query param state, first lets get all the hooks with params to get in one big bucket
  const hooksWithMapQueryParams = Object.keys(hookConfig).reduce(
    (val, entryApiResultId) => ((hookConfig[entryApiResultId].mapQueryParamsToRequest || {}).params || []).length
      ? { ...val, [entryApiResultId]: hookConfig[entryApiResultId].mapQueryParamsToRequest }
      : val,
    {}
  );

  // Then lets get out all the query param keys out and into another bucket
  const allQueryParamKeys = Object.keys(hooksWithMapQueryParams).reduce(
    (val, entryApiResultId) => [ ...val, ...hooksWithMapQueryParams[entryApiResultId].params ],
    []
  );

  // The need to know what query params to expect is a performance enhancement so this hook isn't
  // firing off rerenders on every URL change and, moreover, not on every query param change.
  const allQueryParamValues = useQueryParams(allQueryParamKeys);

  // Calculating the hooks/endpoints that need to be called
  const hooksToCall = useSelector(
    state => Object.keys(listeners).reduce(
      (val, entryApiResultId) => {
        // Check if there is anyone listening for this ID, no need to update if there isn't
        if (!(listeners[entryApiResultId] || []).length) return val;

        // Check if any of the listeners are flagged for refreshOnUpdate to know if we care about
        // an updated entity being the reason to refresh data
        const checkIsUpdated = listeners[entryApiResultId].some(listenerId => listenerConfig[listenerId]
          && listenerConfig[listenerId].refreshOnUpdate);

        const shouldCallState = getCalculateShouldCallState(
          state,
          entryApiResultId
        );

        let requestParams = shouldCallState.lastRequestParams;

        // Apply any transformations to the request params before we calculate should call
        // Starting with query params
        if (hooksWithMapQueryParams[entryApiResultId]) {
          const queryParams = pick(allQueryParamValues, hooksWithMapQueryParams.params);

          const toApplyToRequest = hooksWithMapQueryParams[entryApiResultId].transform
            ? hooksWithMapQueryParams[entryApiResultId].transform(queryParams)
            : queryParams;

          requestParams = { ...(requestParams || {}), ...toApplyToRequest };
        }

        // If we have no request params, we can't make a call
        if (!requestParams) return val;

        const shouldCall = calculateShouldCall(
          shouldCallState,
          requestParams,
          { isUpdated: checkIsUpdated }
        );

        return shouldCall ? [ ...val, { entryApiResultId, requestParams } ] : val;
      },
      []
    ),
    isEqual
  );

  // The useEffect is likely overkill here, given that the useSelector above is doing all the real
  // work to prevent rerenders, but it does feel right to have a side-effect like calling the API in
  // this hook.
  useEffect(
    () => {
      hooksToCall.forEach(
        ({ entryApiResultId, requestParams }) => {
          attemptExecuteRequest({
            callConfig: {
              // We shouldn't make a call if one is already in flight
              shouldCallCheck: { default: true, isLoading: false }
            },
            dispatch,
            entryApiResultId,
            getState: store.getState,
            hookConfig: hookConfig[entryApiResultId],
            requestParams
          });
        }
      );
    },
    [ hooksToCall ]
  );

  useEffect(
    () => {
      const keys = Object.keys(pendingUpdates);

      if (!keys.length) return;

      for (const entryApiResultId of keys) {
        for (const action of pendingUpdates[entryApiResultId]) {
          action();
        }

        // Once we are done processing updates for an entryApiResultId, assume
        // that means we don't need the temporary loading state for it anymore
        delete loadingStateRef.current[entryApiResultId];
      }

      setPendingUpdates(
        (prevState) => {
          const newState = { ...prevState };

          for (const entryApiResultId of Object.keys(newState)) {

            // Check if nothing needs to be removed
            if (!pendingUpdates[entryApiResultId]) continue;

            newState[entryApiResultId] = without(
              newState[entryApiResultId],
              ...pendingUpdates[entryApiResultId]
            );

            if (!newState[entryApiResultId].length) {
              delete newState[entryApiResultId];
            }
          }

          return newState;
        }
      );
    },
    [ pendingUpdates ]
  );

  const { current: addPendingUpdates } = useFuncRef(
    () => (entryApiResultId, actions) => setPendingUpdates(
      (prevState) => {
        if (!actions.length) return prevState;

        const newState = { ...prevState };
        if (newState[entryApiResultId] == null) {
          newState[entryApiResultId] = [];
        }

        newState[entryApiResultId] = [ ...newState[entryApiResultId] ];

        for (const action of actions) {
          newState[entryApiResultId].push(action);
        }

        return newState;
      }
    )
  );

  const { current: useInvalidateEffect } = useFuncRef(
    () => ({
      entryApiResultId,
      deleteOnMount,
      invalidateOnMount,
      invalidateResultOnMount,
      invalidateOnUnmount
    }) => {
      // Hooks
      if (invalidateOnUnmount) {
        // invalidateOnUnmount is dead. While this works well enough for some cases, we run into
        // issues where the entity updates either outside of the purview of the web app or we
        // aren't marking that there's been a change. By changing the invalidate to happen on
        // mount, we are guaranteed to get the latest and greatest when we need it.
        console.warn('invalidateOnUnmount was set - did you mean invalidateOnMount?');
      }

      const newUpdates = [];
      useImmediateEffect(
        () => {
          // Invalidating is useful when the request/entities that would be gotten closely relate to
          // the spirit of the parameters it was previously invoked with, but the data might have
          // changed since the the last time data was gotten outside of our control. By 'spirit of
          // of the parameters', I mean that the previous result might not be entirely wrong - e.g.,
          // that we are refreshing static data, like settings. The power of doing this is shown if
          // you also coalesce to the last result while a new one is loading. For everything else,
          // see deleteOnMount
          if (invalidateOnMount) {
            const willChange = getEntryApiResultReferences(
              store.getState(),
              [ entryApiResultId ],
              true
            );

            if (willChange) {
              loadingStateRef.current[entryApiResultId] = true;
            }

            newUpdates.push(
              () => dispatch(invalidateEntryApiResults([ entryApiResultId ], true))
            );
          }

          if (invalidateResultOnMount) {
            const willChange = getEntryApiResultReferences(
              store.getState(),
              [ entryApiResultId ],
              true
            );

            if (willChange) {
              loadingStateRef.current[entryApiResultId] = true;
            }

            newUpdates.push(
              () => dispatch(invalidateEntryApiResults([ entryApiResultId ]))
            );
          }

          // Deleting is when the last result of the request is is no way guaranteed to be anything
          // you'd want to even consider - maybe the entity you are requesting data on is completely
          // different this time
          if (deleteOnMount) {
            const willChange = getEntryApiResultReferences(
              store.getState(),
              [ entryApiResultId ],
              true
            );

            if (willChange) {
              loadingStateRef.current[entryApiResultId] = true;
            }

            newUpdates.push(
              () => dispatch(deleteEntryApiResults([ entryApiResultId ], true))
            );
          }
        },
        []
      );

      const updateCountRef = useRef(0);

      if (newUpdates.length) {
        updateCountRef.current += 1;
      }

      useEffect(
        () => {
          if (newUpdates.length) {
            addPendingUpdates(entryApiResultId, newUpdates);
          }
        },
        [ updateCountRef.current ]
      );

      // Action

      // None
    }
  );

  const { current: useUpdateParams } = useFuncRef(
    () => ({
      callConfig,
      entryApiResultId,
      hookConfig: updateHookConfig,
      params: originalParams,
      updateRules: provideUpdateRules
    }) => {
      // Hooks
      const stoppedRef = useRef();

      const {
        deleteOnMount,
        invalidateOnMount,
        invalidateResultOnMount,
        invalidateOnUnmount
      } = provideUpdateRules || {};

      // Stablize the params to limit calls to updateParams
      const params = useStableRef(originalParams);

      // Run this once and before we run updateParams so that the entities are
      // properly flagged with being deleted or updated so a loading call
      // happens like you would expect.
      useInvalidateEffect({
        deleteOnMount,
        entryApiResultId,
        invalidateOnMount,
        invalidateResultOnMount,
        invalidateOnUnmount
      });

      let updateAction;
      useImmediateEffect(
        () => {
          if (params && !stoppedRef.current) {
            loadingStateRef.current[entryApiResultId] = loadingStateRef.current[entryApiResultId]
              || attemptExecuteRequest({
                callConfig: callConfig || {},
                dispatch,
                dryRun: true,
                entryApiResultId,
                hookConfig: updateHookConfig,
                getState: store.getState,
                requestParams: params
              });

            updateAction = () => attemptExecuteRequest({
              callConfig: callConfig || {},
              dispatch,
              entryApiResultId,
              hookConfig: updateHookConfig,
              getState: store.getState,
              requestParams: params
            });

            if (provideUpdateRules?.onlyOnce) {
              stoppedRef.current = true;
            }
          }
        },
        [ params, hookConfig ]
      );

      useEffect(
        () => {
          if (updateAction) {
            addPendingUpdates(entryApiResultId, [ updateAction ]);
          }
        },
        [ updateAction ]
      );

      // Action

      // Returns if params were provided so that you can use the decision to
      // optionally disregard
      // results
      return stoppedRef.current ? false : !!params;
    }
  );

  const unsubscribeListener = (entryApiResultId, listenerId) => {
    setListeners((prevState) => {
      if (!prevState[entryApiResultId]) return prevState; // wat.

      const newState = { ...prevState };
      const newListeners = newState[entryApiResultId].filter(id => id !== listenerId);

      if (newListeners.length) {
        newState[entryApiResultId] = newListeners;
      } else {
        delete newState[entryApiResultId];
      }

      return newState;
    });

    setListenerConfig((prevState) => {
      if (!prevState[listenerId]) return prevState;

      const newState = { ...prevState };
      delete newState[listenerId];
      return newState;
    });
  };

  const subscribeListener = useMemo(
    () => (
      entryApiResultId,
      flags,
      listenerHookConfig
    ) => {
      const listenerId = uniqueId();

      setListeners(prevState => ({
        ...prevState,
        [entryApiResultId]: [ ...(prevState[entryApiResultId] || []), listenerId ]
      }));

      // Only add them to the listener config if there is some configuration to know about
      if (flags && Object.keys(flags).length) {
        setListenerConfig(prevState => ({
          ...prevState,
          [listenerId]: flags
        }));
      }

      // Only add to the hookConfig if it hasn't been added already
      if (listenerHookConfig) {
        setHookConfig(prevState => prevState[entryApiResultId] ? prevState : ({
          ...prevState,
          [entryApiResultId]: normalizeHookConfig(listenerHookConfig)
        }));
      }

      return () => unsubscribeListener(entryApiResultId, listenerId);
    },
    []
  );

  const subscribeListeners = useMemo(
    () => entryApiResultIds => entryApiResultIds.reduce(
      // We have to step through these individually to get the unsubscribe function for each ID
      (val, id) => ({ ...val, [id]: subscribeListener(id) }),
      {}
    ),
    []
  );

  const value = useMemo(
    () => ({ subscribeListener, subscribeListeners, useInvalidateEffect, useUpdateParams }),
    [ subscribeListener, subscribeListeners, useInvalidateEffect, useUpdateParams ]
  );

  return (
    <UpdateContext.Provider value={value}>
      {children}
    </UpdateContext.Provider>
  );
};

export default ProvideApiResultHooksUpdateService;