/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import Paths from 'config/paths';
import {
  BusinessInvoicesLinks,
  BusinessBillsLinks,
  BusinessNetworkLinks,
  createBusinessPaymentsLinks,
  BusinessCompanySettingsLinks,
  BusinessDiscountSettingsLinks,
  BusinessSyncLinks
} from './common';

const ignoreSecondaryNav = path => ({ matchOn: path, children: [] });

const BusinessInvoicesConfig = {
  path: Paths.Invoices,
  matchOn: Paths.Invoices,
  children: BusinessInvoicesLinks
};

const BusinessBillsConfig = {
  path: Paths.Bills,
  matchOn: Paths.Bills,
  children: BusinessBillsLinks
};

const BusinessNetworkConfig = {
  path: Paths.Network,
  matchOn: Paths.Network,
  children: BusinessNetworkLinks
};

const BusinessPaymentsConfig = ({
  path: Paths.Payments,
  matchOn: Paths.Payments,
  children: createBusinessPaymentsLinks(true)
});

const BusinessSendOnlyPaymentsConfig = ({
  path: Paths.Payments.Sent,
  matchOn: Paths.Payments,
  children: createBusinessPaymentsLinks(false)
});

const BusinessSettingsConfig = {
  matchOn: Paths.Settings,
  children: BusinessCompanySettingsLinks
};

const BusinessDiscountSettingsConfig = {
  path: Paths.Settings.Discounts,
  matchOn: Paths.Settings.Discounts,
  children: BusinessDiscountSettingsLinks
};

const BusinessSyncConfig = {
  path: Paths.Sync,
  matchOn: Paths.Sync,
  children: BusinessSyncLinks,
  ignoreEmptyChildren: true
};

const DefaultNoSecondaryNav = ignoreSecondaryNav([
  Paths.Invoices.Details,
  Paths.Bills.Details,
  Paths.Settings.UserAccount,
  Paths.Settings.Reports,
  Paths.Settings.Fees,
  Paths.Settings.PaymentMethods,
  Paths.Settings.SwitchCompanies,
  Paths.Settings.Subscription
]);

const getBusinessNavConfig = ({
  accountingPackage,
  isPortalUser,
  isVccEnabled
}) => {
  const hasAccountingPackage = !!accountingPackage && accountingPackage !== 'None';

  if (isPortalUser) {
    return {
      PrimaryNav: [
        Paths.Payments.Received,
        BusinessInvoicesConfig,
        BusinessBillsConfig,
        BusinessSendOnlyPaymentsConfig,
        Paths.Settings.Subscription
      ],
      SecondaryNavs: [
        DefaultNoSecondaryNav,
        ignoreSecondaryNav(Paths.Payments.Received),
        BusinessInvoicesConfig,
        BusinessBillsConfig,
        BusinessSendOnlyPaymentsConfig,
        BusinessNetworkConfig,
        BusinessSettingsConfig
      ],
      SettingsMenu: [
        Paths.Settings.UserAccount,
        Paths.Settings.Profile,
        Paths.Settings.PaymentMethods,
        BusinessNetworkConfig,
        Paths.Settings.Fees,
        isVccEnabled ? Paths.Settings.Reports : null,
        Paths.Settings.SwitchCompanies
      ]
    };
  }

  return {
    PrimaryNav: [
      BusinessInvoicesConfig,
      BusinessBillsConfig,
      BusinessPaymentsConfig,
      BusinessNetworkConfig,
      hasAccountingPackage ? BusinessSyncConfig : null,
      Paths.Settings.Subscription
    ],
    SecondaryNavs: [
      DefaultNoSecondaryNav,
      BusinessInvoicesConfig,
      BusinessBillsConfig,
      BusinessPaymentsConfig,
      BusinessNetworkConfig,
      BusinessSyncConfig,
      BusinessDiscountSettingsConfig,
      BusinessSettingsConfig
    ],
    SettingsMenu: [
      Paths.Settings.UserAccount,
      Paths.Settings.Profile,
      Paths.Settings.PaymentMethods,
      BusinessDiscountSettingsConfig,
      Paths.Settings.Fees,
      isVccEnabled ? Paths.Settings.Reports : null,
      Paths.FileImport,
      Paths.Settings.SwitchCompanies
    ]
  };
};

const PersonalPortalConfig = {
  PrimaryNav: [
    Paths.Payments.Received,
    Paths.Invoices.List,
    Paths.Bills.ConsumerOpenBills,
    Paths.Bills.ConsumerClosedBills,
    Paths.ConsumerAutoPay,
    Paths.ConsumerFees
  ],
  SecondaryNavs: [],
  SettingsMenu: [
    Paths.Payments.Received,
    Paths.Invoices.List,
    Paths.Bills.ConsumerOpenBills,
    Paths.Bills.ConsumerClosedBills,
    Paths.ConsumerAutoPay,
    Paths.ConsumerSettings,
    Paths.ConsumerFees,
    Paths.Settings.SwitchCompanies
  ]
};

const getDesktopConfig = ({
  accountingPackage,
  isPersonalUser,
  isPortalUser,
  isVccEnabled
}) => {
  if (isPersonalUser) return PersonalPortalConfig;
  return getBusinessNavConfig({
    accountingPackage,
    isPortalUser,
    isVccEnabled
  });
};

export default getDesktopConfig;
