/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { GetPath, IsSpaLinkMap } from 'links';
import AnalyticsContext from 'components/AnalyticsCategory/context';
import Link from 'components/Link';

/**
 * Utilize the linkRef config to make links
 */
const RouteLink = ({
  linkRef,
  ...props
}) => {
  const {
    urlToken
  } = useContext(AnalyticsContext) || {};

  if (!linkRef) {
    console.warn('No linkRef was passed, use the standard Link instead');
    return <Link {...props} />;
  }

  let toLink = GetPath(
    linkRef,
    props,
    urlToken
  );

  if (!toLink) {
    console.warn(`No path found for ${linkRef}`);
  }

  let isSpa = IsSpaLinkMap[linkRef];
  let target = null;

  if (props.openNewWindow) {
    target = '_blank';
  } else if (!isSpa) {
    target = '_self';
  }

  let className = null;
  // default Link behavior only uses route (sans query string)
  // to identify 'active' route style
  // to enable custom query processing to identify active state,
  // we need to add it manually
  if (props.isActive) {
    let activeClassName = props.activeClassName || '';
    let isActive = false;
    // TODO: I think this has been broke as a joke for a couple of years, these were concepts back
    // in react-router v2 days.
    if (props.isActive) {
      isActive = props.isActive(props.router);
    }
    className = classNames(props.className, {
      [activeClassName]: isActive
    });
  }

  return (
    <Link {...props}
      to={toLink}
      target={target}
      className={className || props.className} />
  );
};

RouteLink.propTypes = {
  linkRef: PropTypes.string
};

export default RouteLink;
