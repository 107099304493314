/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Modal, { ModalBody, ModalFooter, ModalFooterButton } from 'components/Modal';
import HeaderText from 'components/HeaderText';
import BodyText from 'components/BodyText';

import CommonMessages from 'config/messages/common';

const ConfirmationModal = ({
  closeModal,
  header,
  primaryButtonConfig,
  primaryButtonText,
  secondaryButtonConfig,
  secondaryButtonText,
  subtitle,
  title,
  useCloseButton
}) => {
  // Hooks
  const onHeaderClose = useMemo(
    () => useCloseButton ? () => closeModal(false) : null,
    [ closeModal, useCloseButton ]
  );

  const onPrimaryClick = useMemo(
    () => () => {
      primaryButtonConfig?.onClick?.();
      closeModal(true);
    },
    [ closeModal, primaryButtonConfig?.onClick ]
  );

  const onSecondaryClick = useMemo(
    () => () => {
      secondaryButtonConfig?.onClick?.();
      closeModal(false);
    },
    [ closeModal, secondaryButtonConfig?.onClick ]
  );

  // Render
  return (
    <div className="confirmation-modal">
      <Modal
        header={header}
        onHeaderClose={onHeaderClose}
      >
        <ModalBody style={{padding: '22px 28px'}}>
          {title ? (
            <div style={{marginBottom: 8}}>
              <HeaderText variant="medium">{title}</HeaderText>
            </div>
          ) : null}
          <BodyText style={{marginBottom: 12}}>
            {subtitle}
          </BodyText>
        </ModalBody>
        <ModalFooter>
          <ModalFooterButton
            isSubmit={true}
            {...(primaryButtonConfig || {})}
            onClick={onPrimaryClick}
          >
            {primaryButtonText || <CommonMessages.Yes.Message />}
          </ModalFooterButton>
          <ModalFooterButton
            isRight={true}
            variant="tertiary"
            {...(secondaryButtonConfig || {})}
            onClick={onSecondaryClick}
          >
            {secondaryButtonText || <CommonMessages.Cancel.Message />}
          </ModalFooterButton>
        </ModalFooter>
      </Modal>
    </div>
  );
};

ConfirmationModal.propTypes = {
  title: PropTypes.node.isRequired,
  subtitle: PropTypes.node,
  primaryButtonText: PropTypes.node,
  secondaryButtonText: PropTypes.node,
  primaryButtonConfig: PropTypes.shape({
    onClick: PropTypes.func,
    isSubmit: PropTypes.bool,
    variant: PropTypes.oneOf([
      'primary',
      'secondary',
      'tertiary',
      'success',
      'transparent',
      'utility',
      'link',
      'delete'
    ]),
    isRight: PropTypes.bool
  }),
  secondaryButtonConfig: PropTypes.shape({
    onClick: PropTypes.func,
    isSubmit: PropTypes.bool,
    variant: PropTypes.oneOf([
      'primary',
      'secondary',
      'tertiary',
      'success',
      'transparent',
      'utility',
      'link',
      'delete'
    ]),
    isRight: PropTypes.bool
  }),
  closeModal: PropTypes.func,
  useCloseButton: PropTypes.bool
};

export default ConfirmationModal;
