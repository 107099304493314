/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React from 'react';

import updateUserProfile from 'api/settings/updateUserProfile';
import UserSettingsApi from 'api/settings/user';

import { toMessages } from 'config/messages';

import createExecuteApiResultHooks from 'hooks/apiResult/createExecuteApiResultHooks';

const UpdateProfileHooks = createExecuteApiResultHooks({
  endpoint: updateUserProfile
});

const Messages = toMessages('Actions.Settings.UpdateProfile', {
  Success: 'Email sent.'
});

const updateProfile = (
  {
    firstName,
    lastName,
    email,
    confirmPassword,
    oldPassword,
    password,
    resend: resendChangeEmail,
    securityQuestions
  },
  callback
) => async (
  dispatch,
  getState
) => {
  const [ success ] = await dispatch(UpdateProfileHooks.Redux.executeRequestAction(
    {
      firstName,
      lastName,
      email,
      confirmPassword,
      oldPassword,
      password,
      securityQuestions
    },
    {
      extractFirstErrorMessage: true,
      successMessage: resendChangeEmail ? <Messages.Success.Message /> : null
    }
  ));

  if (!success) return;

  dispatch(UserSettingsApi.getUserProfile());
  if (!resendChangeEmail) {
    callback?.();
  }
};

export default updateProfile;