/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import moment from 'moment';
import classNames from 'classnames';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import ProvideIsMobile from 'decorators/ProvideIsMobile';
import PlainDropdownToggle from 'components/Dropdown/PlainDropdownToggle';
import Calendar, { CalendarStyles } from 'components/Calendar';
import { getMoment } from 'components/utils';
import FormattedDate from 'components/FormattedDate';
import Tooltip, { Placements } from 'components/Tooltip';
import './index.scss';

export const DATE_FORMAT = 'M/D/YYYY';

const disablePastDates = (date) => {
  return moment(date).isBefore(moment(), 'day');
};

class DatePicker extends Component {
  static propTypes = {
    value: PropTypes.oneOfType([
      PropTypes.instanceOf(Date),
      PropTypes.string
    ]),
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    disabled: PropTypes.bool,
    config: PropTypes.shape({
      tabIndex: PropTypes.number,
      className: PropTypes.string
    }),
    hasError: PropTypes.bool,
    customIcon: PropTypes.node,
    nullableWrapper: PropTypes.bool,
    nullableLabel: PropTypes.object,
    placeholder: PropTypes.any,

    isDateDisabled: PropTypes.func,
    shouldDisablePastDates: PropTypes.bool,

    /** Whether or not to use a calendar popup vs just text-only entry */
    useCalendar: PropTypes.bool,

    /** Whether or not to use the <Tooltip> component for the dropdown (or <DropdownPanel>) */
    useTooltip: PropTypes.bool,
    /** Props for the underlying tooltip component (is useTooltip=true) */
    tooltipProps: PropTypes.object,

    /** Adds a label for the selected node */
    selectedDateLabel: PropTypes.oneOfType([PropTypes.node, PropTypes.object]),

    /** Allows highlighting specific date ranges on the calendar. */
    highlightRanges: PropTypes.arrayOf(PropTypes.shape({
      start: PropTypes.instanceOf(Date),
      end: PropTypes.instanceOf(Date),
      /** CSS color for text */
      color: PropTypes.string,
      /** CSS color for background */
      backgroundColor: PropTypes.string,
      label: PropTypes.oneOfType([PropTypes.node, PropTypes.object])
    })),
    /** Allows custom highlighting of specfic dates on the calendar */
    highlightDates: PropTypes.arrayOf(PropTypes.shape({
      date: PropTypes.instanceOf(Date),
      /** CSS color for text */
      color: PropTypes.string,
      /** CSS color for background */
      backgroundColor: PropTypes.string,
      label: PropTypes.oneOfType([PropTypes.node, PropTypes.object])
    })),
    shouldSlashPastDates: PropTypes.bool
  };

  static defaultProps = {
    onBlur: () => {},
    onChange: () => {},
    useTooltip: false,
    useCalendar: true,
    shouldSlashPastDates: true,
    shouldDisablePastDates: false
  };

  constructor(props) {
    super(props);

    this.onInputBlur = this.onInputBlur.bind(this);
    this.onChangeCalendar = this.onChangeCalendar.bind(this);
    this.createDateLabel = this.createDateLabel.bind(this);
  }

  onInputBlur(event) {
    // Determine if the input's value changed
    let { beginDate = this.props.value } = this.props.config || {};
    let inputValue = event && event.target ? event.target.value : null;
    let originalValue = this.getFormattedDate(beginDate) || '';
    if (inputValue !== originalValue) {
      // If it changed, consider it a new date selection
      this.onChangeCalendar(inputValue);
      setTimeout(this.props.onBlur.bind(this, event), 0);
    }
  }

  onChangeCalendar(value) {
    let selectedDate = value;

    if (selectedDate === '') {
      // treat empty values as null
      this.props.onChange(null);
      setTimeout(this.props.onBlur.bind(this, null), 0);
      return;
    }

    let selectedMoment = getMoment(value);
    if (value && selectedMoment && selectedMoment.isValid()) {
      // Strip the timezone and convert to local time
      selectedDate = moment(selectedMoment.format('YYYY-MM-DD'), 'YYYY-MM-DD').toDate();
    }

    if (this.dropdown) {
      this.dropdown.close();
    }
    if (this.tooltipRef) {
      this.tooltipRef.close();
    }

    this.props.onChange(selectedDate);
    setTimeout(this.props.onBlur.bind(this, null), 0);
  }

  createDateLabel(label, date, color, key) {
    if (label) {
      let displayLabel = label;
      if (typeof label === 'object') {
        displayLabel = <FormattedMessage {...label} />;
      }

      let momentDate = getMoment(date);

      return (
        <div className="datepicker-label" key={key}>
          <div className="datepicker-label-icon" style={{
            backgroundColor: color
          }} />
          <div className="datepicker-label-text">{displayLabel}</div>
          <div className="datepicker-label-value">
            <FormattedDate value={momentDate} />
          </div>
        </div>
      );
    }
  }

  getFormattedDate(dateValue) {
    if (dateValue) {
      const momentDate = getMoment(dateValue);
      if (momentDate.isValid()) {
        return momentDate.format(DATE_FORMAT);
      }
    }

    return dateValue;
  }

  renderLabels() {
    let labels = [];

    if (this.props.selectedDateLabel) {
      labels.push(
        this.createDateLabel(this.props.selectedDateLabel,
          this.props.value,
          CalendarStyles.SelectedDateStyle.backgroundColor,
          'selected-date-label')
      );
    }

    // custom labels matching date highlights
    if (this.props.highlightDates) {
      this.props.highlightDates.forEach((highlightDate, index) => {
        if (highlightDate.label) {
          labels.push(
            this.createDateLabel(highlightDate.label,
              highlightDate.date,
              highlightDate.backgroundColor,
              `highlight-date-label${index}`)
          );
        }
      });
    }
    if (this.props.highlightRanges) {
      this.props.highlightRanges.forEach((highlightRange, index) => {
        if (highlightRange.label) {
          labels.push(
            this.createDateLabel(highlightRange.label, null, highlightRange.backgroundColor,
              `highlight-range-label${index}`)
          );
        }
      });
    }

    return labels;
  }

  render() {
    // Pull off any supplied config for this form component
    let {
      tabIndex,
      customIcon,
      popoverPlace
    } = this.props.config || {};

    // Get the formatted date for use in display and whether or not this date is valid
    let formattedDate = this.getFormattedDate(this.props.value);
    // let isValidDate = getMoment(beginDate).isValid();

    let placeholder = DATE_FORMAT;
    if (this.props.placeholder && this.props.placeholder.defaultMessage) {
      placeholder = this.props.placeholder.defaultMessage;
    } else if (this.props.placeholder) {
      placeholder = this.props.placeholder;
    }

    // Setup the text input
    let input = (
      <div className="datepicker-input-container">
        <input key={formattedDate}
          tabIndex={tabIndex}
          disabled={this.props.disabled}
          className={classNames('vp-input', {
            error: this.props.hasError
          })}
          placeholder={placeholder}
          defaultValue={formattedDate}
          onBlur={this.onInputBlur}
          style={{
            paddingLeft: this.props.nullableWrapper && this.props.nullableLabel ? 24 : null
          }} />
        { this.props.nullableWrapper && this.props.nullableLabel ? (
          <input name="nullableDate" type="radio" className="radio" style={{
            position: 'absolute',
            top: 1,
            left: 8,
            padding: 0,
            margin: 0
          }} defaultChecked={!!this.props.value} />
        ) : null }
        <div className={classNames('datepicker-icon', {
          'custom-icon': !!customIcon,
          'disabled': this.props.disabled
        })}>
          {customIcon}
        </div>
      </div>
    );

    // Create a nullable wrapper
    let wrapper;
    if (this.props.nullableWrapper && this.props.nullableLabel) {
      wrapper = (
        <div>
          <div className="vp-input" style={{
            position: 'relative',
            float: 'left',
            width: '50%',
            borderRight: 'none'
          }} onClick={event => event.stopPropagation()}>
            <input name="nullableDate" type="radio" className="radio" style={{
              padding: 0,
              margin: '0px 8px 0px 0px'
            }} defaultChecked={!this.props.value}
            onChange={(event) => {
              event.stopPropagation();
              this.onChangeCalendar(null);
            }} />
            <FormattedMessage {...this.props.nullableLabel} />
          </div>
          <div style={{
            position: 'relative',
            float: 'left',
            width: '50%'
          }}>{input}
          </div>
        </div>
      );
    }

    let selectedDate = null;
    if (this.props.value) {
      let date = moment(this.props.value, DATE_FORMAT);
      if (date.isValid()) {
        selectedDate = date.toDate();
      }
    }

    let field = wrapper || input;

    if (this.props.disabled) {
      // no dropdowns when disabled
      return field;
    }

    if (!this.props.useCalendar) {
      // no dropdowns if explicitly turned off
      return field;
    }

    let dropdownContent = (
      <div>
        {this.renderLabels()}
        <Calendar
          onChange={this.onChangeCalendar}
          value={selectedDate}
          viewDate={selectedDate}
          highlightDates={this.props.highlightDates}
          highlightRanges={this.props.highlightRanges}
          isDateDisabled={this.props.shouldDisablePastDates ? disablePastDates : this.props.isDateDisabled} />
      </div>
    );

    if (this.props.useTooltip) {
      return (
        <Tooltip ref={ref => this.tooltipRef = ref}
          overlay={dropdownContent}
          placement={Placements.BottomRight}
          trigger={['click']}
          overlayClassName="datepicker-tooltip"
          childWrapperStyle={{
            display: 'block'
          }}
          {...this.props.tooltipProps}>
          {field}
        </Tooltip>
      );
    }

    return (
      <PlainDropdownToggle ref={r => this.dropdown = r}
        popoverPlace={popoverPlace || 'above'}
        toggle={field}>
        {dropdownContent}
      </PlainDropdownToggle>
    );
  }
}

export default ProvideIsMobile(DatePicker);
