/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, { isValidElement } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import NotOnViewpost from 'images/icons/NotOnNetwork.svg';
import CheckmarkCircleGreen from 'images/icons/check-green.png';
import GripperIcon from '!!babel-loader!svg-react-loader!images/icons/Gripper.svg';
import DeleteIcon from '!!babel-loader!svg-react-loader!images/icons/Delete.svg';
import ViewpostActive from '!!babel-loader!svg-react-loader!images/icons/ConnectedIcon.svg';
import ViewpostInactive from '!!babel-loader!svg-react-loader!images/icons/NotConnectedIcon.svg';
import SortArrow from '!!babel-loader!svg-react-loader!public/images/icons/SortArrow.svg';
import EarlyPayIcon from '!!babel-loader!svg-react-loader!images/icons/early-pay-icon.svg';
import PayRightIcon from '!!babel-loader!svg-react-loader!images/icons/pay-direction-right.svg';
import PayLeftIcon from '!!babel-loader!svg-react-loader!images/icons/pay-direction-left.svg';
import MedicineIcon from '!!babel-loader!svg-react-loader!images/icons/medicine-suitcase.svg';
import VccFeesIcon from '!!babel-loader!svg-react-loader!images/icons/vcc-fees.svg';
import VccFeesRedIcon from '!!babel-loader!svg-react-loader!images/icons/vcc-fees-red.svg';
import AlertIcon from '!!babel-loader!svg-react-loader!images/icons/alert-red.svg';
import LateFeeIcon from '!!babel-loader!svg-react-loader!images/icons/late-fee-icon.svg';
import CreditMemoIcon from '!!babel-loader!svg-react-loader!images/icons/credit-memo-icon.svg';
import BackArrowIcon from '!!babel-loader!svg-react-loader!images/icons/back-arrow.svg';
import TooltipIcon from '!!babel-loader!svg-react-loader!images/icons/tool-tip-icon.svg';
import GearIcon from '!!babel-loader!svg-react-loader!images/icons/gear-icon.svg';
import Plus2 from '!!babel-loader!svg-react-loader!images/icons/add-icon.svg';
import LockIcon from '!!babel-loader!svg-react-loader!images/icons/lock-icon.svg';
import RecurringIcon from '!!babel-loader!svg-react-loader!images/icons/recurring-icon.svg';
import PaperCheck2Icon from '!!babel-loader!svg-react-loader!images/icons/paper-check-icon.svg';
import EditIcon from '!!babel-loader!svg-react-loader!images/icons/edit-icon.svg';
import ShieldIcon from '!!babel-loader!svg-react-loader!images/icons/shield-icon.svg';
import Shield2Icon from '!!babel-loader!svg-react-loader!images/icons/shield-icon2.svg';
import ExpiredShieldIcon from '!!babel-loader!svg-react-loader!images/icons/shield-expired-icon.svg';
import PrinterIcon from '!!babel-loader!svg-react-loader!images/icons/printer-icon.svg';
import ClockIcon from '!!babel-loader!svg-react-loader!images/icons/clock-icon.svg';
import PaymentEngineIcon from '!!babel-loader!svg-react-loader!images/icons/payment-engine.svg';
import PeopleIcon from '!!babel-loader!svg-react-loader!images/icons/people-icon.svg';
import StripeCreditCardIcon from '!!babel-loader!svg-react-loader!images/icons/c-c-icon.svg';
import VendorPaysFeesIcon from '!!babel-loader!svg-react-loader!images/icons/vendor-pay-fee-icon.svg';
import WarningIcon from '!!babel-loader!svg-react-loader!images/icons/alert-icon.svg';
import ReceiptIcon from '!!babel-loader!svg-react-loader!images/icons/receipt-icon.svg';
import SuitcaseIcon from '!!babel-loader!svg-react-loader!images/icons/suitcase-icon.svg';
import LinkIcon from '!!babel-loader!svg-react-loader!images/icons/link-icon.svg';
import PaymentGroupIcon from '!!babel-loader!svg-react-loader!images/icons/payment-group.svg';
import CompanyGroupIcon from '!!babel-loader!svg-react-loader!images/icons/company-group.svg';
import CompanyStackIcon from '!!babel-loader!svg-react-loader!images/icons/companystack.svg';
import AddressBookGroupIcon from '!!babel-loader!svg-react-loader!images/icons/vendor-group.svg';
import PaymentOptimizeIcon from '!!babel-loader!svg-react-loader!images/icons/payment-optimize.svg';
import ACHPlusIcon from '!!babel-loader!svg-react-loader!images/icons/third-party-digital-check-icon.svg';
import ElectronicIcon from '!!babel-loader!svg-react-loader!images/icons/lightning-bolt-icon.svg';
import IclIcon from '!!babel-loader!svg-react-loader!images/icons/electronic-icl.svg';
import SplitPayment from '!!babel-loader!svg-react-loader!images/icons/vcc-split.svg';
import OneStepIcon from '!!babel-loader!svg-react-loader!images/icons/electronic-onestep.svg';
import TwoStepIcon from '!!babel-loader!svg-react-loader!images/icons/electronic-twostep.svg';
import PaperCheckIcon from '!!babel-loader!svg-react-loader!images/icons/paper-check-icon.svg';
import MicroDepositIcon from '!!babel-loader!svg-react-loader!images/icons/lightning-bolt-icon-silver.svg';
import OutOfBandIcon from '!!babel-loader!svg-react-loader!images/icons/other-icon.svg';
import VirtualCreditCardIcon from '!!babel-loader!svg-react-loader!images/icons/c-c-icon2.svg';
import SimilarIcon from '!!babel-loader!svg-react-loader!images/icons/similar.svg';
import UnsimilarIcon from '!!babel-loader!svg-react-loader!images/icons/unsimilar.svg';
import BatchIcon from '!!babel-loader!svg-react-loader!images/icons/batch.svg';
import BatchQueueIcon from '!!babel-loader!svg-react-loader!images/icons/batch-queue.svg';
import FireIcon from '!!babel-loader!svg-react-loader!images/icons/fire.svg';
import AssistedPayIcon from '!!babel-loader!svg-react-loader!images/icons/assisted-pay.svg';
import AutoPayIcon from '!!babel-loader!svg-react-loader!images/icons/autopay.svg';
import PriorityIcon from '!!babel-loader!svg-react-loader!images/icons/priority.svg';
import VisaIcon from '!!babel-loader!svg-react-loader!images/icons/visa-blue-icon.svg';
import CanadaIcon from '!!babel-loader!svg-react-loader!images/icons/canada.svg';
import FastPaymentsIcon from '!!babel-loader!svg-react-loader!images/icons/fastpayments.svg';
import VerifiedIcon from '!!babel-loader!svg-react-loader!images/icons/verified.svg';
import UnverifiedIcon from '!!babel-loader!svg-react-loader!images/icons/unverified.svg';
import './index.scss';

export const IconTypes = {
  AccountNumber: 'AccountNumber',
  Alert: 'alert',
  AlarmClock: 'AlarmClock',
  Address: 'Address',
  AddressBook: 'addressBook',
  AddressBookRelationship: 'AddressBookRelationship',
  AddressBookGroup: 'AddressBookGroup',
  Approval: 'approval',
  AssistedPay: 'AssistedPay',
  Audit: 'Audit',
  AutoPay: 'AutoPay',
  Bank: 'Bank',
  Batch: 'Batch',
  BatchQueue: 'BatchQueue',
  BulletedList: 'BulletedList',
  Burst: 'Burst',
  Calculator: 'Calculator',
  Calendar: 'calendar',
  Campaign: 'Campaign',
  Canada: 'Canada',
  Caution: 'Caution',
  Clipboard: 'Clipboard',
  ClipboardPlus: 'ClipboardPlus',
  ClipboardCheck: 'ClipboardCheck',
  ClipboardX: 'ClipboardX',
  ClipboardCopy: 'clipboardcopy',
  Chain: 'Chain',
  Checkmark: 'checkmark',
  CheckmarkCircle: 'checkmarkCircle',
  CheckmarkCircleGreen: 'checkmarkCircleGreen',
  Circle: 'Circle',
  CircleQuestion: 'CircleQuestion',
  CircleCheck: 'CircleCheck',
  CircleUp: 'CircleUp',
  CircleDown: 'CircleDown',
  CircleX: 'CircleX',
  CircleXHollow: 'CircleXHollow',
  CircleMinus: 'CircleMinus',
  CircleExclamation: 'CircleExclamation',
  CirclePause: 'CirclePause',
  CirclePlay: 'CirclePlay',
  CirclePlus: 'CirclePlus',
  Cube: 'Cube',
  Diagram: 'Diagram',
  Play: 'Play',
  Clock: 'clock',
  Comment: 'comment',
  CompanyBuilding: 'CompanyBuilding',
  CompanyGroup: 'companyGroup',
  CompanyStack: 'CompanyStack',
  Contact: 'Contact',
  ContactPlus: 'ContactPlus',
  ContactInfo: 'ContactInfo',
  Similar: 'Similar',
  Unsimilar: 'Unsimilar',
  CreditMemo: 'creditMemo',
  CustomField: 'CustomField',
  Document: 'document',
  DocumentGroup: 'documentGroup',
  DocumentSource: 'documentSource',
  DocumentRight: 'documentRight',
  DocumentLeft: 'documentLeft',
  Dollar: 'dollar',
  Dollar2: 'dollar2',
  Email: 'Email',
  EnrollmentTaskNotStarted: 'EnrollmentTaskNotStarted',
  EnrollmentTaskInProgress: 'EnrollmentTaskInProgress',
  EnrollmentTaskCompleted: 'EnrollmentTaskCompleted',
  EnrollmentTaskCanceled: 'EnrollmentTaskCanceled',
  EnrollmentTaskFollowUp: 'EnrollmentTaskFollowUp',
  Flag: 'Flag',
  FlagCheckered: 'FlagCheckered',
  File: 'File',
  FileUp: 'FileUp',
  FilePlus: 'FilePlus',
  Folder: 'Folder',
  Grid: 'Grid',
  StripeCreditCard: 'stripeCreditCard',
  DropdownArrow: 'dropdownArrow',
  VendorPaysFees: 'vendorPaysFees',
  ViewpostActive: 'activeViewpost',
  ViewpostInactive: 'inactiveViewpost',
  Search: 'search',
  Question: 'question',
  Gripper: 'gripper',
  Hamburger: 'hamburger',
  History: 'history',
  Hierarchy: 'Hierarchy',
  Inbox: 'Inbox',
  Bell: 'bell',
  Exit: 'exit',
  Eye: 'eye',
  Eye2: 'eye2',
  Feather: 'Feather',
  Fire: 'Fire',
  Hyperlink: 'Hyperlink',
  Info: 'info',
  Info2: 'info2',
  InfoBlock: 'infoblock',
  Image: 'Image',
  Key: 'Key',
  Key2: 'Key2',
  LateFee: 'lateFee',
  Layout: 'Layout',
  Lightbulb: 'Lightbulb',
  Lock: 'lock',
  Medicine: 'Medicine',
  Mention: 'Mention',
  Minus: 'Minus',
  MobilePhone: 'MobilePhone',
  Movie: 'Movie',
  Multiple: 'Multiple',
  Notes: 'Notes',
  Phone: 'Phone',
  Plus: 'plus',
  Plus2: 'plus2',
  Pencil: 'pencil',
  Percent: 'percent',
  Print: 'print',
  Print2: 'Print2',
  Presentation: 'Presentation',
  PopOut: 'PopOut',
  Portal: 'Portal',
  Projected: 'Projected',
  Refresh: 'refresh',
  Refresh2: 'refresh2',
  Refresh3: 'refresh3',
  PaperClip: 'paperClip',
  Recurring: 'recurring',
  Recurring2: 'recurring2',
  Recycle: 'Recycle',
  Trash: 'trash',
  Trophy: 'Trophy',
  RightArrow: 'rightArrow',
  RightArrow2: 'rightArrow2',
  RightArrow3: 'rightArrow3',
  LeftArrow: 'leftArrow',
  LeftArrow2: 'leftArrow2',
  LeftArrow3: 'leftArrow3',
  DownArrow: 'downArrow',
  UpArrow: 'upArrow',
  DownArrow2: 'downArrow2',
  UpArrow2: 'upArrow2',
  BackArrow: 'backArrow',
  Download: 'download',
  Download1: 'download1',
  Download2: 'download2',
  CreditCard: 'creditCard',
  PaperCheck: 'paperCheck',
  PaperCheck2: 'paperCheck2',
  DigitalCheck: 'digitalCheck',
  DigitalCheck2: 'digitalCheck2',
  PeopleIcon: 'peopleIcon',
  PadLock: 'padlock',
  PadUnlock: 'padunlock',
  Share: 'share',
  Shield: 'shield',
  Shield2: 'shield2',
  Shop: 'Shop',
  SubscriptionLock: 'SubscriptionLock',
  Scheduled: 'Scheduled',
  Scissors: 'Scissors',
  Sent: 'Sent',
  Source: 'Source',
  Spreadsheet: 'Spreadsheet',
  Star: 'Star',
  StopWatch: 'StopWatch',
  ExpiredShield: 'expiredShield',
  SortArrow: 'sortArrow',
  Tag: 'Tag',
  EarlyPay: 'earlyPay',
  Delete: 'delete',
  Upload: 'upload',
  Upload2: 'upload2',
  UserProfile: 'userprofile',
  UserGroup: 'usergroup',
  UserAssigned: 'userassigned',
  UserUnassigned: 'userunassigned',
  UserOutline: 'UserOutline',
  Warning: 'warning',
  VerticalDots: 'verticalDots',
  Gear: 'gear',
  Gear2: 'Gear2',
  Graph: 'graph',
  Edit: 'edit',
  Receipt: 'receipt',
  Shuffle: 'Shuffle',
  SpecialDelivery: 'SpecialDelivery',
  Suitcase: 'suitcase',
  Suitcase2: 'suitcase2',
  Timeline: 'timeline',
  Timeout: 'timeout',
  TooltipDots: 'TooltipDots',
  Link: 'link',
  Optimizable: 'Optimizable',
  Paying: 'Paying',
  Receiving: 'Receiving',
  PaymentEngine: 'PaymentEngine',
  PaymentGroup: 'PaymentGroup',
  PaymentIssueResolved: 'paymentissueresolved',
  PaymentIssueEscalated: 'paymentissueescalated',
  PaymentIssueNew: 'paymentissuenew',
  PaymentIssueInProgress: 'paymentissueInProgress',
  PaymentIssue: 'paymentissue',
  VirtualCardFees: 'virtualCardFees',
  VirtualCardFeesRed: 'virtualCardFeesRed',
  Priority: 'Priority',
  PaymentVCC: 'PaymentVCC',
  PaymentAchPlus: 'PaymentAchPlus',
  PaymentElectronic: 'PaymentElectronic',
  PaymentIcl: 'PaymentIcl',
  PaymentOneStep: 'PaymentOneStep',
  PaymentTwoStep: 'PaymentTwoStep',
  PaymentPaperCheck: 'PaymentPaperCheck',
  PaymentMicroDeposit: 'PaymentMicroDeposit',
  PaymentOutOfBand: 'PaymentOutOfBand',
  SplitPayment: 'SplitPayment',
  Temperature: 'Temperature',
  World: 'World',
  Wifi: 'Wifi',
  Wrench: 'Wrench',
  WrenchEdit: 'WrenchEdit',
  Unverified: 'Unverified',
  Verified: 'Verified',
  Visa: 'Visa'
};

// map friendly names to viewpost theme colors
function getColor(color) {
  if (!color) return null;
  switch (color?.toLowerCase()) {

    // viewpost brand colors
    case 'blue': return '#66bbee';
    case 'gold':
    case 'yellow':
      return '#ffbb33';
    case 'green': return '#99cc33';
    case 'red': return '#ff553d';
    case 'violet':
    case 'purple':
      return '#a266a2';
    case 'black': return '#333';

    // secondary app theme colors
    case 'primaryblue': return '#1e88e5';

    // used for icons that represent Sprocket features
    case 'featureblue': return '#216c9c';

    // function colors
    case 'warning': return '#ffbb33';
    case 'success': return '#99cc33';
    case 'error': return '#ff553d';

    // grays
    case 'darkgray': return '#666';
    case 'gray': return '#999';
    case 'lightgray': return '#c1c1c1';
    case 'bordergray': return '#ddd';

    // background / highlight colors
    case 'backgroundgreen': return '#a4d04a';
    case 'backgroundred': return '#f4d7d7';
    case 'backgroundblue': return '#ecf7fd';
    case 'backgroundpurple': return '#f1e8f1';
    case 'backgroundyellow': return '#faeec4';
    case 'backgroundgray': return '#f7f7f7';

    case 'white': return 'white';

    default: return null;
  }
}

const createStyle = (
  {
    color,
    size,
    style
  },
  additionalStyle
) => {
  const newStyle = {
    ...(style ?? {}),
    ...(additionalStyle ?? {})
  };

  Object.assign(newStyle, {
    color: getColor(color) ?? newStyle.color
  });

  if (size) {
    Object.assign(newStyle, {
      height: `${size}px`,
      width: `${size}px`,
      fontSize: `${size}px`,
      minWidth: 'initial'
    });
  }

  return newStyle;
};

const baseCreateIcon = (
  additionalClassName,
  additionalStyle,
  {
    children,
    onClick
  },
  {
    iconClasses,
    iconStyle
  }
) => (
  <i
    onClick={onClick}
    style={{
      ...iconStyle,
      ...(additionalStyle ?? {})
    }}
    className={classNames(
      iconClasses,
      additionalClassName
    )}
  >
    {children}
  </i>
);

const SingleIcon = ({
  children,
  className,
  color,
  hoverable,
  onClick,
  pad: shouldPad,
  size: iconSize,
  style,
  type: iconType

}) => {
  const iconStyle = createStyle(
    {
      color,
      size: iconSize,
      style
    }
  );

  const iconClasses = classNames(
    'vp-icon',
    {
      hoverable,
      pad: shouldPad
    },
    className
  );

  const createIcon = (
    additionalClassName,
    additionalStyle
  ) => baseCreateIcon(
    additionalClassName,
    additionalStyle,
    {
      children,
      onClick
    },
    {
      iconClasses,
      iconStyle
    }
  );

  const createClasses = additionalClassName => classNames(
    iconClasses,
    additionalClassName
  );

  switch (iconType) {
    case IconTypes.SortArrow:
      return (
        <SortArrow
          className={classNames(iconClasses)}
          style={{ minWidth: 0, margin: '0 0 0 4px', ...style }}
        />
      );
    case IconTypes.ViewpostActive:
      // need to explicitly set the height for IE11
      return (
        <ViewpostActive
          style={{ height: iconSize || 16, width: iconSize || 16, ...style}}
          className={createClasses('connection')}
        />
      );
    case IconTypes.ViewpostInactive:
      return (
        <ViewpostInactive
          style={{ height: iconSize || 16, width: iconSize || 16, ...style }}
          className={createClasses('connection')}
        />
      );
    case IconTypes.AddressBook:
      return (
        <img
          src={NotOnViewpost}
          style={{ height: iconSize || 16, width: iconSize || 16, ...style}}
          className={createClasses('connection')}
        />
      );
    case IconTypes.AssistedPay:
      return <AssistedPayIcon style={iconStyle} />;
    case IconTypes.Audit:
      return createIcon('flaticon solid audit');
    case IconTypes.AutoPay:
      return <AutoPayIcon style={iconStyle} />;
    case IconTypes.Calculator:
      return createIcon('flaticon solid calculator');
    case IconTypes.Calendar:
      return createIcon('flaticon stroke calendar-3');
    case IconTypes.Hyperlink:
      return createIcon('flaticon solid hyperlink');
    case IconTypes.Image:
      return createIcon('flaticon stroke image');
    case IconTypes.Key:
      return createIcon('flaticon solid key');
    case IconTypes.Key2:
      return createIcon('flaticon solid key2');
    case IconTypes.Lightbulb:
      return createIcon('flaticon solid lightbulb');
    case IconTypes.Movie:
      return createIcon('flaticon solid movie');
    case IconTypes.Multiple:
      return createIcon('flaticon solid multiple');
    case IconTypes.Spreadsheet:
      return createIcon('flaticon stroke spreadsheet');
    case IconTypes.Presentation:
      return createIcon('flaticon solid presentation');
    case IconTypes.Portal:
      return createIcon('flaticon stroke portal');
    case IconTypes.PopOut:
      return createIcon('flaticon stroke popout');
    case IconTypes.Document:
      return createIcon('flaticon solid document');
    case IconTypes.DocumentGroup:
      return (
        <div style={{ position: 'relative', display: 'inline-block', verticalAlign: 'middle'}}>
          <div style={{ position: 'absolute', top: '-6px', left: '-6px' }}>
            {createIcon('flaticon solid document', { backgroundColor: 'white' })}
          </div>
          <div style={{ position: 'absolute', top: '-3px', left: '-3px' }}>
            {createIcon('flaticon solid document', { backgroundColor: 'white' })}
          </div>
          {createIcon('flaticon solid document', { backgroundColor: 'white' })}
        </div>
      );
    case IconTypes.DocumentSource:
    case IconTypes.DocumentRight:
      return createIcon('flaticon stroke documentsource');
    case IconTypes.DocumentLeft:
      return createIcon('flaticon stroke documentleft');
    case IconTypes.Circle:
      return createIcon('flaticon solid circle', { backgroundColor: style?.color });
    case IconTypes.CircleQuestion:
      return createIcon('flaticon solid circle-question');
    case IconTypes.CircleCheck:
      return createIcon('flaticon solid circle-check');
    case IconTypes.CircleUp:
      return createIcon('flaticon solid circle-up');
    case IconTypes.CircleDown:
      return createIcon('flaticon solid circle-down');
    case IconTypes.CircleX:
      return createIcon('flaticon solid circle-x');
    case IconTypes.CircleXHollow:
      return createIcon('flaticon stroke circle-x');
    case IconTypes.CircleMinus:
      return createIcon('flaticon solid circle-minus');
    case IconTypes.CircleExclamation:
      return createIcon('flaticon solid circle-exclamation');
    case IconTypes.CirclePause:
      return createIcon('flaticon solid circle-pause');
    case IconTypes.CirclePlay:
      return createIcon('flaticon solid circle-play');
    case IconTypes.CirclePlus:
      return createIcon('flaticon solid circle-plus');
    case IconTypes.Cube:
      return createIcon('flaticon stroke cube');
    case IconTypes.Diagram:
      return createIcon('flaticon stroke diagram');
    case IconTypes.Play:
      return createIcon('flaticon solid play');
    case IconTypes.Source:
      return createIcon('flaticon solid source');
    case IconTypes.Star:
      return createIcon('flaticon solid star');
    case IconTypes.Tag:
      return createIcon('flaticon solid tag');
    case IconTypes.Recycle:
      return createIcon('flaticon solid recycle');
    case IconTypes.Chain:
      return createIcon('flaticon solid chain');
    case IconTypes.Checkmark:
      return (
        <span>
          {createIcon('flaticon solid checkmark-1')}
        </span>
      );
    case IconTypes.CheckmarkCircle:
      return (
        <span>
          {createIcon('flaticon solid checkmark-2')}
        </span>
      );
    case IconTypes.CheckmarkCircleGreen:
      return (
        <span className={className}>
          <img src={CheckmarkCircleGreen} />
        </span>
      );
    case IconTypes.Gripper:
      return <GripperIcon />;
    case IconTypes.Delete:
      return (
        <DeleteIcon
          className={className}
          onClick={onClick}
          style={iconStyle}
        />
      );
    case IconTypes.BackArrow:
      return <BackArrowIcon style={iconStyle} />;
    case IconTypes.PaperCheck2:
      return <PaperCheck2Icon className={createClasses('connection flaticon stroke paper-check-2')}/>;
    case IconTypes.Edit:
      return <EditIcon className={createClasses('flaticon stroke edit-icon')}/>;
    case IconTypes.Shield:
      return <ShieldIcon className={createClasses('flaticon stroke shield-icon')}/>;
    case IconTypes.Shield2:
      return <Shield2Icon style={iconStyle} />;
    case IconTypes.ExpiredShield:
      return <ExpiredShieldIcon className={createClasses('flaticon stroke shield-icon')}/>;
    case IconTypes.Clock:
      return (
        <ClockIcon
          className={createClasses('flaticon stroke clock-icon')}
          style={iconStyle}
        />
      );
    case IconTypes.AlarmClock:
      return createIcon('flaticon stroke alarm-clock');
    case IconTypes.StopWatch:
      return createIcon('flaticon solid stopwatch');
    case IconTypes.Caution:
      return createIcon('flaticon solid caution');
    case IconTypes.Campaign:
      return createIcon('flaticon solid campaign');
    case IconTypes.Canada:
      return <CanadaIcon style={iconStyle} />;
    case IconTypes.CompanyBuilding:
      return createIcon('flaticon solid companybuilding');
    case IconTypes.CompanyGroup:
      return <CompanyGroupIcon style={iconStyle} />;
    case IconTypes.CompanyStack:
      return <CompanyStackIcon style={iconStyle} />;
    case IconTypes.Approval:
      return createIcon('flaticon solid approval');
    case IconTypes.Comment:
      return createIcon('flaticon solid comment');
    case IconTypes.Notes:
      return createIcon('flaticon solid notes');
    case IconTypes.Download1:
      return createIcon('flaticon stroke download-1');
    case IconTypes.Download2:
      return createIcon('flaticon solid download2');
    case IconTypes.Search:
      return createIcon('flaticon stroke zoom-2');
    case IconTypes.Contact:
      return createIcon('flaticon solid contact');
    case IconTypes.ContactPlus:
      return createIcon('flaticon solid contact-plus');
    case IconTypes.ContactInfo:
      return createIcon('flaticon solid contact-info');
    case IconTypes.Similar:
      return <SimilarIcon style={iconStyle} />;
    case IconTypes.Unsimilar:
      return <UnsimilarIcon style={iconStyle} />;
    case IconTypes.CustomField:
      return createIcon('flaticon stroke custom-field');
    case IconTypes.Question:
      return createIcon('flaticon solid question-2');
    case IconTypes.Hamburger:
      return createIcon('flaticon stroke menu-2');
    case IconTypes.History:
      return createIcon('flaticon stroke history');
    case IconTypes.Hierarchy:
      return createIcon('flaticon solid hierarchy');
    case IconTypes.Inbox:
      return createIcon('flaticon solid inbox');
    case IconTypes.Bell:
      return createIcon('flaticon solid bell-1');
    case IconTypes.Exit:
      return createIcon('flaticon stroke x-1');
    case IconTypes.Minus:
      return createIcon('flaticon solid minus');
    case IconTypes.Burst:
      return createIcon('flaticon stroke burst');
    case IconTypes.BulletedList:
      return createIcon('flaticon solid bulleted-list');
    case IconTypes.Batch:
      return <BatchIcon style={iconStyle} />;
    case IconTypes.BatchQueue:
      return <BatchQueueIcon style={iconStyle} />;
    case IconTypes.AccountNumber:
      return createIcon('flaticon solid account-number');
    case IconTypes.EnrollmentTaskNotStarted:
      return createIcon('flaticon solid task-not-started');
    case IconTypes.EnrollmentTaskInProgress:
      return createIcon('flaticon solid task-in-progress');
    case IconTypes.EnrollmentTaskCompleted:
      return createIcon('flaticon solid task-completed');
    case IconTypes.EnrollmentTaskCanceled:
      return createIcon('flaticon solid task-canceled');
    case IconTypes.EnrollmentTaskFollowUp:
      return createIcon('flaticon solid task-follow-up');
    case IconTypes.PaymentIssue:
      return createIcon('flaticon solid payment-issue');
    case IconTypes.PaymentIssueInProgress:
      return createIcon('flaticon solid payment-issue-inprogress');
    case IconTypes.PaymentIssueResolved:
      return createIcon('flaticon solid payment-issue-resolved');
    case IconTypes.PaymentIssueEscalated:
      return createIcon('flaticon solid payment-issue-escalated');
    case IconTypes.PaymentIssueNew:
      return createIcon('flaticon solid payment-issue-new');
    case IconTypes.InfoBlock:
      return createIcon('flaticon solid info-block');
    case IconTypes.Info:
      return (
        <TooltipIcon
          style={{
            ...iconStyle,
            verticalAlign: 'middle',
            marginLeft: 4
          }}
        />
      );
    case IconTypes.Info2:
      return createIcon('flaticon stroke info-2');
    case IconTypes.Plus:
      return createIcon('flaticon stroke plus');
    case IconTypes.Plus2:
      return <Plus2 style={style} />;
    case IconTypes.Pencil:
      return createIcon('flaticon solid pencil');
    case IconTypes.Address:
      return createIcon('flaticon solid address');
    case IconTypes.AddressBookRelationship:
      return createIcon('flaticon solid addressbook-relationship');
    case IconTypes.AddressBookGroup:
      return <AddressBookGroupIcon style={iconStyle} />;
    case IconTypes.Phone:
      return createIcon('flaticon solid phone');
    case IconTypes.Email:
      return createIcon('flaticon solid email');
    case IconTypes.Flag:
      return createIcon('flaticon solid flag');
    case IconTypes.FlagCheckered:
      return createIcon('flaticon solid flag-checkered');
    case IconTypes.File:
      return createIcon('flaticon solid file');
    case IconTypes.FileUp:
      return createIcon('flaticon solid fileup');
    case IconTypes.FilePlus:
      return createIcon('flaticon solid fileplus');
    case IconTypes.Folder:
      return createIcon('flaticon solid folder');
    case IconTypes.Grid:
      return createIcon('flaticon stroke grid');
    case IconTypes.UserProfile:
      return createIcon('flaticon solid user-profile');
    case IconTypes.UserGroup:
      return createIcon('flaticon solid user-group');
    case IconTypes.UserOutline:
      return createIcon('flaticon stroke user-outline');
    case IconTypes.UserAssigned:
      return createIcon('flaticon solid user-assigned');
    case IconTypes.UserUnassigned:
      return createIcon('flaticon solid user-unassigned');
    case IconTypes.Print:
      return (
        <PrinterIcon
          className={createClasses('vp-icon printer-icon')}
          style={{
            ...iconStyle,
            width: 16,
            height: 14
          }}
        />
      );
    case IconTypes.Print2:
      return createIcon('flaticon solid print');
    case IconTypes.Gear:
      return <GearIcon style={style}/>;
    case IconTypes.Gear2:
      return createIcon('flaticon solid gear-2');
    case IconTypes.Graph:
      return createIcon('flaticon solid graph-1');
    case IconTypes.Lock:
      return (
        <span
          className="text-center lock-icon"
          style={{ padding: '0 4px', ...style }}
        >
          <LockIcon />
        </span>
      );
    case IconTypes.PadLock:
      return createIcon('flaticon solid padlock');
    case IconTypes.PadUnlock:
      return createIcon('flaticon solid padunlock');
    case IconTypes.SubscriptionLock:
      return createIcon('flaticon solid subscription-lock');
    case IconTypes.Refresh:
      return createIcon('flaticon solid refresh');
    case IconTypes.Refresh2:
      return createIcon('flaticon solid refresh2');
    case IconTypes.Refresh3:
      return createIcon('flaticon solid refresh3');
    case IconTypes.Warning:
      return <WarningIcon className={createClasses('vp-icon warning-icon')} />;
    case IconTypes.PaperClip:
      return createIcon('flaticon stroke paperclip-1');
    case IconTypes.Clipboard:
      return createIcon('flaticon solid clipboard');
    case IconTypes.ClipboardPlus:
      return createIcon('flaticon solid clipboard-plus');
    case IconTypes.ClipboardCheck:
      return createIcon('flaticon solid clipboard-check');
    case IconTypes.ClipboardX:
      return createIcon('flaticon solid clipboard-x');
    case IconTypes.ClipboardCopy:
      return createIcon('flaticon stroke clipboardcopy');
    case IconTypes.Trash:
      return createIcon('flaticon stroke trash');
    case IconTypes.Trophy:
      return createIcon('flaticon solid trophy');
    case IconTypes.Download:
      return createIcon('flaticon solid download');
    case IconTypes.Eye:
      return createIcon('flaticon solid eye');
    case IconTypes.Eye2:
      return createIcon('flaticon stroke eye');
    case IconTypes.Mention:
      return createIcon('flaticon stroke mention');
    case IconTypes.Feather:
      return createIcon('flaticon solid feather');
    case IconTypes.Fire:
      return <FireIcon style={iconStyle} />;
    case IconTypes.Upload:
      return createIcon('flaticon solid upload');
    case IconTypes.Upload2:
      return createIcon('flaticon solid upload2');
    case IconTypes.Recurring:
      return (
        <RecurringIcon
          className={iconClasses}
          style={{
            ...iconStyle,
            height: 16,
            width: 14
          }}
        />
      );
    case IconTypes.VendorPaysFees:
      return <VendorPaysFeesIcon className="vp-icon vendor-pays-fees-icon"
        style={iconStyle} />;
    case IconTypes.Receipt:
      return <ReceiptIcon className="vp-icon receipt-icon" style={iconStyle} />;
    case IconTypes.Shuffle:
      return createIcon('flaticon solid shuffle');
    case IconTypes.SpecialDelivery:
      return <FastPaymentsIcon style={iconStyle} />;
    case IconTypes.Suitcase:
      return (
        <SuitcaseIcon
          className={classNames(className, 'vp-icon suitcase-icon')}
          style={style}
        />
      );
    case IconTypes.Suitcase2:
      return createIcon('flaticon solid suitcase-2');
    case IconTypes.LeftArrow:
    case IconTypes.LeftArrow2:
      return createIcon('flaticon stroke left-2');
    case IconTypes.LeftArrow3:
      return createIcon('flaticon solid left-3');
    case IconTypes.RightArrow:
    case IconTypes.RightArrow2:
      return createIcon('flaticon stroke right-2');
    case IconTypes.RightArrow3:
      return createIcon('flaticon solid right-3');
    case IconTypes.DownArrow:
      return createIcon('flaticon stroke down-arrow');
    case IconTypes.UpArrow:
      return createIcon('flaticon stroke up-arrow');
    case IconTypes.UpArrow2:
      return createIcon('flaticon solid up-arrow2');
    case IconTypes.DownArrow2:
      return createIcon('flaticon solid down-arrow2');
    case IconTypes.CreditCard:
      return createIcon('flaticon solid credit-card');
    case IconTypes.PaperCheck:
      return createIcon('flaticon stroke mail');
    case IconTypes.Share:
      return createIcon('flaticon stroke share');
    case IconTypes.DigitalCheck:
      return createIcon('flaticon stroke lightning-bolt');
    case IconTypes.DigitalCheck2:
      return createIcon('flaticon stroke lightning-bolt2');
    case IconTypes.Timeline:
      return createIcon('flaticon solid timeline');
    case IconTypes.Recurring2:
      return createIcon('flaticon solid recurring2');
    case IconTypes.Scheduled:
      return createIcon('flaticon stroke scheduled');
    case IconTypes.Scissors:
      return createIcon('flaticon solid scissors');
    case IconTypes.Sent:
      return createIcon('flaticon stroke sent');
    case IconTypes.Bank:
      return createIcon('flaticon solid bank');
    case IconTypes.Timeout:
      return createIcon('flaticon solid timeout');
    case IconTypes.TooltipDots:
      return createIcon('flaticon solid tooltipdots');
    case IconTypes.World:
      return createIcon('flaticon solid world');
    case IconTypes.Wifi:
      return createIcon('flaticon solid wifi');
    case IconTypes.Wrench:
      return createIcon('flaticon solid wrench');
    case IconTypes.WrenchEdit:
      return createIcon('flaticon solid wrenchedit');
    case IconTypes.MobilePhone:
      return createIcon('flaticon solid mobile-phone');
    case IconTypes.Priority:
      return <PriorityIcon style={iconStyle} />;
    case IconTypes.Visa:
      return <VisaIcon style={iconStyle} />;
    case IconTypes.Shop:
      return createIcon('flaticon solid shop');
    case IconTypes.Temperature:
      return createIcon('flaticon solid temperature');
    case IconTypes.DropdownArrow:
      return createIcon('dropdown-arrow');
    case IconTypes.Percent:
      return <span style={{width: '24px', textAlign: 'center'}}>%</span>;
    case IconTypes.Projected:
      return createIcon('flaticon stroke projected');
    case IconTypes.Dollar:
      return <span style={{width: '24px', textAlign: 'center'}} className="dollar">$</span>;
    case IconTypes.Dollar2:
      return createIcon('flaticon solid dollar2');
    case IconTypes.EarlyPay:
      return <EarlyPayIcon className="vp-icon early-pay" style={iconStyle} />;
    case IconTypes.Paying:
      return <PayRightIcon className="vp-icon paying" style={iconStyle} />;
    case IconTypes.Receiving:
      return <PayLeftIcon className="vp-icon paying" style={iconStyle} />;
    case IconTypes.VirtualCardFees:
      return <VccFeesIcon className="vp-icon vcc-fees" style={iconStyle} />;
    case IconTypes.VirtualCardFeesRed:
      return <VccFeesRedIcon className="vp-icon vcc-fees" style={iconStyle} />;
    case IconTypes.Alert:
      return <AlertIcon className="vp-icon alert" style={iconStyle} />;
    case IconTypes.LateFee:
      return <LateFeeIcon className="vp-icon late-fee" style={iconStyle} />;
    case IconTypes.CreditMemo:
      return <CreditMemoIcon className="vp-icon credit-memo" />;
    case IconTypes.PeopleIcon:
      return (
        <PeopleIcon
          className={createClasses('vp-icon people-icon')}
          style={iconStyle}
        />
      );
    case IconTypes.StripeCreditCard:
      return <StripeCreditCardIcon style={iconStyle}/>;
    case IconTypes.PaymentGroup:
      return <PaymentGroupIcon style={iconStyle}/>;
    case IconTypes.Layout:
      return createIcon('flaticon stroke layout');
    case IconTypes.Medicine:
      return <MedicineIcon style={iconStyle} />;
    case IconTypes.Optimizable:
      return <PaymentOptimizeIcon style={iconStyle} />;
    case IconTypes.PaymentVCC:
      return <VirtualCreditCardIcon style={iconStyle} />;
    case IconTypes.PaymentAchPlus:
      return <ACHPlusIcon style={iconStyle} />;
    case IconTypes.PaymentElectronic:
      return <ElectronicIcon style={iconStyle} />;
    case IconTypes.PaymentIcl:
      return <IclIcon style={iconStyle} />;
    case IconTypes.PaymentOneStep:
      return <OneStepIcon style={iconStyle} />;
    case IconTypes.PaymentTwoStep:
      return <TwoStepIcon style={iconStyle} />;
    case IconTypes.PaymentPaperCheck:
      return <PaperCheckIcon style={iconStyle} />;
    case IconTypes.PaymentMicroDeposit:
      return <MicroDepositIcon style={iconStyle} />;
    case IconTypes.PaymentOutOfBand:
      return <OutOfBandIcon style={iconStyle} />;
    case IconTypes.PaymentEngine:
      return <PaymentEngineIcon style={iconStyle} />;
    case IconTypes.SplitPayment:
      return <SplitPayment style={iconStyle} />;
    case IconTypes.Unverified:
      return <UnverifiedIcon style={iconStyle} />;
    case IconTypes.Verified:
      return <VerifiedIcon style={iconStyle} />;
    case IconTypes.VerticalDots:
      return (
        <div
          className={createClasses('vertical-dots')}
          style={style}
        >
          <div className="dot"></div>
          <div className="dot"></div>
          <div className="dot"></div>
        </div>
      );
    case IconTypes.LinkIcon:
      return <LinkIcon className={createClasses('link-icon')} />;
    default:
      return null;
  }
};

SingleIcon.propTypes = {
  type: PropTypes.oneOf(Object.keys(IconTypes).map(key => IconTypes[key])),
  pad: PropTypes.bool,
  onClick: PropTypes.func,
  size: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]),
  style: PropTypes.object,
  color: PropTypes.string
};

export default function Icon(props) {
  if (props.secondaryType) {
    // support nesting a secondary icon
    let size = props.size || 16;
    let defaultOffset = size * 0.5;
    return (
      <div style={{display: 'inline-block', position: 'relative'}}>
        <SingleIcon {...props} secondaryType={null} />
        {/* Render a smaller icon on top of this icon */}
        <div style={{
          position: 'absolute',
          top: `${props.secondaryTopOffset || defaultOffset}px`,
          left: `${props.secondaryLeftOffset || defaultOffset}px`
        }}>
          {isValidElement(props.secondaryType) ? props.secondaryType : (
            <SingleIcon
              {...props}
              type={props.secondaryType}
              secondaryType={null}
              color={props.secondaryColor}
              style={{ top: 0, ...props.secondaryStyle }}
              size={props.secondarySize || (size * 0.75)}
            />
          )}
        </div>
      </div>
    );
  }

  if (props.badgeText) {
    // support a secondary 'badge' (typically to show a count)
    let size = props.size || 16;
    return (
      <div style={{ display: 'inline-block', position: 'relative' }}>
        <SingleIcon {...props} secondaryType={null} />
        {/* Render a smaller badge on top of this icon */}
        <div style={{
          position: 'absolute',
          top: `${size * 0.5}px`,
          left: `${size * 0.5}px`,
          textAlign: 'center'
        }}>
          <div style={{
            width: `${size * 0.75}px`,
            height: `${size * 0.75}px`,
            lineHeight: `${size * 0.75}px`,
            fontSize: size * 0.5,
            borderRadius: '50%',
            color: props.badgeColor || 'white',
            backgroundColor: props.badgeBackground || '#66bbee'
          }}>
            {props.badgeText}
          </div>
        </div>
      </div>
    );
  }

  return <SingleIcon {...props} />;
}
