/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

export default function Action(props) {
  if (!props.name) {
    throw new Error('Workflow Actions require a name.');
  } else if (!props.step) {
    throw new Error(`Workflow Actions require a step. name=${props.name}`);
  }

  return {
    workflowType: 'Action',
    name: props.name,
    step: props.step,
    action: props.action,
    callback: props.callback
  };
}
