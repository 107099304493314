/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { isAuthorizedToUseApp } from 'actions/common';
import { signWithMobile } from 'actions/settings';
import { loadDocumentStoreValue, deleteDocumentStoreValue } from 'actions/documentStore';
import { CorrelationId } from 'config/constants/dashboard';
import { getEntity } from 'schemas/state';

function MapStateToProps(state, props) {
  let viewState = state.accountSetup.PaymentDetailsStep;
  let mobileSignature = getEntity(state, viewState.mobileSignature);
  return {
    mobileSignature: mobileSignature ? mobileSignature.value : null,
    isAuthorizedToUseApp: isAuthorizedToUseApp(state)
  };
}

function MapDispatchToProps(dispatch, props) {
  return {
    loadMobileSignature: (id, isAnonymous) => dispatch(loadDocumentStoreValue(
      'mobileSignature',
      isAnonymous ? 'anonymous' : false,
      id,
      null,
      CorrelationId.FetchMobileSignature
    )),
    signWithMobile: (id, phone, sendConfirmation, invitationId) => dispatch(
      signWithMobile(id, phone, sendConfirmation, invitationId)
    ),
    deleteSignatureStorage: (id, isAnonymous) => dispatch(deleteDocumentStoreValue(
      'mobileSignature',
      isAnonymous ? 'anonymous' : false,
      id
    ))
  };
}

export {
  MapStateToProps,
  MapDispatchToProps
};
