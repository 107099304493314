/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, { Component } from 'react';
// import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Select from 'components/Form/Inputs/Select';
import { MapStateToProps, MapDispatchToProps } from './index.connect';

export class SelectIndustryType extends Component {
  static propTypes = {
  };

  static defaultProps = {
  };

  componentWillMount() {
    this.props.onLoad();
  }

  render() {
    let { industryTypes } = this.props;

    return (
      <Select
        {...this.props}
        disabled={!industryTypes.length}
        selectOptions={industryTypes.map(({id, name}) => ({
          value: id,
          text: name
        }))} />
    );
  }
}

export default connect(MapStateToProps, MapDispatchToProps)(SelectIndustryType);
