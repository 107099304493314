/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, {
  Children,
  cloneElement,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { get, uniqueId } from 'lodash';
import { useIntl } from 'react-intl';
import { form as TcombForm } from 'tcomb-form';
import t from 'tcomb-validation';

import { BaseFieldLayout } from '../Containers/FieldLayout';
import FormContext, { FormStateContext, NamePrefixContext } from './context';
import FieldInput, { useInputComponent } from './FieldInput';
import { getActualType, getErrorMessage, getKind } from './utils';

export { FieldInput };

const DefaultTransformer = ({
  format: value => value == null ? null : value,
  parse: value => value
});

const useFormValue = (path) => {
  const formState = useContext(FormStateContext);

  return get(formState, path);
};

const calculateError = ({
  error,
  errorBehavior,
  hasError,
  intl,
  reason,
  setError,
  setHasError,
  type,
  value
}) => {
  if (!hasError) {
    if (error) {
      setError();
    }

    return;
  }

  if (errorBehavior === 'resetOnChange' && reason === 'inputChange') {
    if (error) {
      setError();
    }
    setHasError(false);
    return;
  }

  if (t.validate(value, type, { context: { intl }}).isValid()) {
    if (error) {
      setError();
    }
    setHasError(false);

    return;
  }

  const message = getErrorMessage(type, value, intl);

  if (message !== error) {
    setError(message);
  }
};

const FieldOption = ({
  children,
  className,
  cols,
  // false by default. Use to indicate the schema field may not always exist
  // because it is conditionally applied to the schema. Supresses an error
  // notifying a FieldOption does not have an associated field, to notify the
  // developer they might have a problem. While conditional schemas fields are
  // not a problem, the component <IfFieldDefined/> allows you to control
  // complex rendering if a field isn't part of the schema. For example, if once
  // the field is defined you know for certain that X fields will all be defined
  // and you want the validation not using this flag provides.
  conditional,
  disabled: disabledProp,
  // Values: nil (default), 'resetOnChange'
  // nil (default): once there is an error, the error remains until the value is
  //                valid
  // 'resetOnChange': The error state resets as soon there is a change to the
  //                  value
  errorBehavior,
  footerLabel,
  label,
  name: originalName,
  onBlur: onBlurProp,
  onFocus: onFocusProp,
  rightLabel,
  style,
  transformer
}) => {
  // Hooks
  const intl = useIntl();

  const [ id ] = useState(() => uniqueId());
  const calculateErrorRef = useRef();

  const [ hasError, setHasError ] = useState(false);
  const [ hasFocus, setHasFocus ] = useState(false);
  const [ error, setError ] = useState();
  const {
    disabled: globalDisable,
    display,
    highlightRequired,
    onChange,
    paths,
    register,
    validateOnBlur
  } = useContext(FormContext);

  const namePrefix = useContext(NamePrefixContext);

  const name = `${namePrefix}${namePrefix ? '.' : ''}${originalName}`;
  const typePath = name.replace(/\[[0-9]+\]/g, '[]'); // Normalize a[0].b[2] to a[].b[]
  const type = paths[typePath];
  const InputComponent = useInputComponent(type);

  const value = useFormValue(name);

  const [ isStructType, isTupleType ] = useMemo(
    () => {
      if (!type) return [ false, false ];

      const actualType = getActualType(type);
      const kind = getKind(actualType);

      return [ kind === 'struct', kind === 'tuple' ];
    },
    [ type ]
  );

  const onError = useMemo(
    () => (errorPaths) => {
      // If we are dealing with a field that is a struct, the expectation being
      // there are no field options for the subfields of this struct, then we
      // are really handling everything south of this struct in the hierarchy.
      if (isStructType) {
        const subFields = errorPaths.filter(
          path => path.match(`^${name}\.*`)
        );

        if (subFields.length) {
          setHasError(true);

          // This struct might not have been in the error fields, but there's
          // no harm in returning it as being handled if one of the subfields
          // are being handled - there's no sense in adding code to check if its
          // there. Also, it is, in essence, being handled.
          return [
            name,
            ...subFields
          ];
        }
      } else if (isTupleType) {
        const subFields = errorPaths.filter(
          path => path.match(`^${name}\\[\.*\\]`)
        );

        if (subFields.length) {
          setHasError(true);

          return [
            name,
            ...subFields
          ];
        }
      }


      if (errorPaths.includes(name)) {
        setHasError(true);
        return [ name ];
      }

      return [];
    },
    [ isStructType, isTupleType ]
  );

  useEffect(
    () => {
      if (!paths[typePath]) {
        if (conditional == null || !conditional) {
          console.error(`Unknown path ${typePath}. Check the field's path and the form to make sure it exists.`);
        }
        return;
      }

      // register() returns a function to unsubscribe this form field when it
      // unmounts
      return register(
        id,
        {
          onError
        }
      );
    },
    [ name, paths, typePath ]
  );

  // This is ...a hack, kindasorta. It's definitely different. The goal here is to, no matter what
  // the state of the input is, to have the latest and greatest scope captured so when we want to
  // recalculate (read: reset) the error state, we aren't doing it from an outdated state. There are
  // other ways to do this, first that comes to mind is a class component (but I wanted to see
  // how this panned out as a functional component), the other way that comes to mind is useMemo
  // but this has the issue of needing to rebind the function every rerender in such a way that the
  // functions that use it also need to be rebound, so using the stable useRef reference as the
  // gateway gets us around that. Maybe there's a better way to do this with a hook, but you can
  // imagine what I'm doing here is similar to useMemo. This also allows you to override the scope
  // if you have more up-to-date information.
  calculateErrorRef.current = params => calculateError({
    error,
    errorBehavior,
    hasError,
    intl,
    setError,
    setHasError,
    type,
    value,
    ...(params || {})
  });

  useEffect(
    calculateErrorRef.current,
    [ hasError ]
  );

  const {
    format: formatValue,
    parse: parseValue
  } = useMemo(
    () => {
      if (!type) return DefaultTransformer;

      if (transformer) {
        return transformer;
      }

      if (InputComponent.createTransformer) {
        return InputComponent.createTransformer(type);
      }

      if (type.meta.name === 'String') {
        return TcombForm.Textbox.transformer;
      }

      if (type.meta.name === 'Number'
        || getActualType(type)?.meta.name === 'Number') {
        return TcombForm.Textbox.numberTransformer;
      }

      return DefaultTransformer;
    },
    [ type, transformer ]
  );

  const onInputChange = useMemo(
    () => (newValue) => {
      const parsedNewValue = parseValue(newValue);
      calculateErrorRef.current({
        reason: 'inputChange',
        value: parsedNewValue
      });

      onChange(name, parsedNewValue);
    },
    [ onChange, parseValue ]
  );

  // Render

  if (!type) return <></>;

  const onBlur = () => {
    setHasFocus(false);
    if (onBlurProp) {
      onBlurProp();
    }

    if (validateOnBlur == null || validateOnBlur === true) {
      calculateErrorRef.current();
    }
  };

  const onFocus = () => {
    setHasFocus(true);
    if (onFocusProp) {
      onFocusProp();
    }
  };

  const disabled = disabledProp ?? globalDisable;
  const actualValue = formatValue(value);

  let content = null;

  if (children) {
    const child = Children.only(children);
    content = cloneElement(
      child,
      {
        disabled,
        hasError,
        inputComponent: InputComponent,
        name,
        onChange: onInputChange,
        onBlur,
        onFocus,
        type,
        value: actualValue
      }
    );
  } else {
    content = (
      <InputComponent
        disabled={disabled}
        hasError={hasError}
        name={name}
        onChange={onInputChange}
        onBlur={onBlur}
        onFocus={() => setHasFocus(true)}
        type={type}
        value={actualValue}
      />
    );
  }

  return (
    <BaseFieldLayout
      className={className}
      cols={display === 'flex'
        ? cols || 12
        : null}
      disabled={disabled}
      display={display}
      error={error}
      footerLabel={footerLabel}
      hasError={hasError}
      hasFocus={hasFocus}
      isRequired={type.meta.kind !== 'maybe'}
      label={label}
      rightLabel={rightLabel}
      showRequiredLabel={highlightRequired}
      style={style}
    >
      {content}
    </BaseFieldLayout>
  );
};

export const passthroughToInput = Component => ({
  children,
  disabled,
  hasError,
  inputComponent,
  name,
  onChange,
  onBlur,
  onFocus,
  type,
  value,
  ...props
}) => (
  <Component {...props}>
    {cloneElement(
      Children.only(children),
      {
        disabled,
        hasError,
        inputComponent,
        name,
        onChange,
        onBlur,
        onFocus,
        type,
        value
      }
    )}
  </Component>
);

export default FieldOption;
