/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { connect } from 'react-redux';
import RegistrationApi from 'api/registration';
import { createRegistrationFlow, isRegistering } from 'actions/registration';
import { isAuthorizedToUseApp, isPartiallyAuthenticated } from 'actions/common';
import { createCacheActions } from 'actions/references';

const ProvideCredentialsActions = createCacheActions(
  'RegisterCompanyExpedited_ProvideCredentials',
  RegistrationApi.expeditedRegistrationProvideCredentials
);

const MapStateToProps = (state, { invitationId }) => ({
  isLoading: ProvideCredentialsActions.isCacheLoading(state) || isRegistering(state),
  didProvideFail: ProvideCredentialsActions.hasCacheFailed(state),
  provideResponse: ProvideCredentialsActions.getCacheResponse(state),
  isAuthorizedToUseApp: isAuthorizedToUseApp(state) || isPartiallyAuthenticated(state)
});

const fullyRegisterCompany = params => createRegistrationFlow(
  dispatch => dispatch(RegistrationApi.expeditedRegistrationProvideCredentials(
    params
  ))
);

const MapDispatchToProps = dispatch => ({
  onProvideCredentials: params => dispatch(fullyRegisterCompany(params))
});

export default Component => connect(MapStateToProps, MapDispatchToProps)(Component);
