/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */
/* eslint max-len:0 */

import { toMessages } from 'config/messages';

export default toMessages('RegistrationContext', {
  PendingPaymentInstructions: `Enter your bank account information and verify the account to have
  {count, plural, one {this payment} other {these payments}} deposited into your account.`,
  AchPlusPendingPayments: '{count, plural, one {Pending Payment} other {{count} Pending Payments}}',
  UsbankNotificationHeader: 'We welcome you, in partnership with US Bank.',
  UsbankNotificationSubheader: 'Sign up now to start sending electronic invoices and payments.',
  DxcNotificationHeader: 'We welcome you, in partnership with DXC technology.',
  DxcNotificationSubheader: 'Sign up now to start sending electronic invoices and payments.',
  FifthThirdNotificationHeader: 'We welcome you, in collaboration with Fifth Third Bank.',
  FifthThirdNotificationSubheader: 'Sign up now to start sending electronic invoices and payments.'
});

export const DxcMessages = toMessages('Registration.DxcMessages', {
  Header: '{b}Viewpost integrates seamlessly with RISKMASTER,{b} enabling you to send electronic claims payments with ease.',
  StepCounter: 'Step {number}:',
  Step1Instructions: '{b}Create a Viewpost account.{b} Use the form on this page to get started.',
  Step2Instructions: '{b}Register your bank account.{b} You’ll be guided through this step as you create your account.',
  Step3InstructionsOne: 'Contact {supportEmail}. ',
  Step3InstructionsTwo: 'We’ll assist in finishing the implementation process (typically completed within two weeks).',
  Step3Bullet1: 'Creating a paper check',
  Step3Bullet2: 'Adding Viewpost as a vendor',
  Step3Bullet3: 'Testing check stock with your bank*',
  Step3Bullet4: 'Communication to Vendors*',
  Step3Asterisk: '* {i}if needed{i}',
  Step4Instructions: '{b}Generate an API key.{b} You’ll copy this key back into RISKMASTER.'
});