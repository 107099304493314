/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React from 'react';
import PropTypes from 'prop-types';
import './index.scss';

/**
 * The body section of a Modal
 */
const ModalBody = ({children, style}) => (
  <div className="basicModal body" style={style}>
    {children}
  </div>
);

ModalBody.propTypes = {
  style: PropTypes.object
};

export default ModalBody;
