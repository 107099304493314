/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { isApiResultSuccessful } from 'api/core/utils.state';
import getIdentity from 'api/session/getIdentity';

import createProvideApiResultHooks from 'hooks/apiResult/createProvideApiResultHooks';
import useStatusIndicator from 'hooks/apiResult/useStatusIndicator';

// TODO: Figure out how to get this and the getIdentity hooks at the root on the
// same page.
const EnsureIdentityHooks = createProvideApiResultHooks({
  endpoint: getIdentity
});

EnsureIdentityHooks.useWillLoadOrIsLoading = EnsureIdentityHooks
  .useWillLoadOrIsLoading.meta.bind({
    coalesce: (current, prev) => !isApiResultSuccessful(current) && !!prev
      ? true
      : false
  });

EnsureIdentityHooks.useProvideParams = EnsureIdentityHooks
  .useProvideParams.meta.bind(
    {},
    { onlyOnce: true, invalidateResultOnMount: true }
  );

export const useEnsureIdentityStatusIndicator = () => useStatusIndicator([
  EnsureIdentityHooks
]);

export default EnsureIdentityHooks;