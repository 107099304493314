/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { mapFilterParams } from './utils';

export default {
  path: '/network',
  children: {
    List: {
      path: '/list',
      queryStringMapper: mapFilterParams([
        'individual',
        'relationship',
        'search'
      ])
    },
    Requests: {
      path: '/connectionRequests'
    },
    Profile: {
      path: '/profile',
      children: {
        AddressBook: {
          path: '/addressBook/:addressBookId'
        },
        Company: {
          path: '/company/:companyId'
        }
      }
    }
  }
};
