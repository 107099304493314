/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React from 'react';
import { useParams } from 'react-router';

import ProblemErnie from 'components/ProblemErnie';

import { wrapImport } from 'decorators/ModuleLoader';

import useStatusIndicator from 'hooks/apiResult/useStatusIndicator';
import useQueryParams from 'hooks/location/useQueryParams';
import {
  GetSecureTokenDataHooks,
  useRegistrationMetadata
} from 'hooks/registration';

import AcceptVccTerms, { InvalidAcceptVccTerms } from 'views/Onboarding/AcceptVccTerms';
import VccInterest from 'views/Onboarding/Campaign/VccInterest';
import ElectronicPaymentInterest from 'views/Onboarding/ElectronicPaymentInterest';
import SelfEnrollment from 'views/Onboarding/SelfEnrollment';
import VerifyPaymentAccountOnboarding from 'views/Onboarding/VerifyPaymentAccountOnboarding';
import VccHelp from 'views/Onboarding/VccHelp';
import RegistrationView, { RegistrationLayout } from 'views/Registration';

const InvoiceOnboarding = wrapImport(
  () => import(/* webpackChunkName: "onboarding" */ 'views/Onboarding/InvoiceOnboarding')
);

const StatusIndicatorConfig = [{
  useWillLoadOrIsLoading: GetSecureTokenDataHooks.useWillLoadOrIsLoading.meta.bind({
    coalesce: true
  }),
  useRequestError: GetSecureTokenDataHooks.useRequestError
}];

const TokenLanding = () => {
  // Hooks
  const { secureToken } = useParams();

  const { context: queryContext } = useQueryParams(['context']);

  GetSecureTokenDataHooks.useProvideParams({
    secureToken,
    context: queryContext
  });

  const metadata = useRegistrationMetadata();

  const { invitationId } = metadata || {};

  const secureTokenContext = GetSecureTokenDataHooks.useContext() || {};

  const { context, isValid } = secureTokenContext;

  const StatusIndicator = useStatusIndicator(StatusIndicatorConfig);

  // Render
  if (StatusIndicator) return <StatusIndicator centerInPage={true} />;

  if (context === 'CampaignVccInterest') {
    // TODO: Make this page support when the token is invalid
    if (!isValid) {
      return (
        <ProblemErnie
          centerInPage={true}
          errorReason="Invalid Token"
        />
      );
    }

    return (
      <VccInterest
        context={context}
        invitationId={invitationId}
        metadata={metadata}
        secureToken={secureToken}
        secureTokenContext={secureTokenContext}
      />
    );
  }

  if (context === 'ElectronicPaymentOptions') {
    // TODO: Make this page support when the token is invalid
    if (!isValid) {
      return (
        <ProblemErnie
          centerInPage={true}
          errorReason="Invalid Token"
        />
      );
    }

    return (
      <ElectronicPaymentInterest
        context={context}
        invitationId={invitationId}
        metadata={metadata}
        secureToken={secureToken}
        secureTokenContext={secureTokenContext}
      />
    );
  }

  if (context === 'InvoiceOnboarding') {
    return (
      <InvoiceOnboarding
        loadSecureTokenData={false}
      />
    );
  }

  if (context === 'VccAcceptTerms' || context === 'VccResendSixDigits') {
    if (!isValid) {
      return (
        <InvalidAcceptVccTerms />
      );
    }

    return (
      <AcceptVccTerms
        context={context}
        isValid={isValid}
        secureToken={secureToken}
        secureTokenContext={secureTokenContext}
      />
    );
  }

  if (context === 'AchPlusVerifyPaymentAccount' || context === 'VerifyPaymentAccount') {
    // TODO: Make this page support when the token is invalid
    if (!isValid) {
      return (
        <ProblemErnie
          centerInPage={true}
          errorReason="Invalid Token"
        />
      );
    }

    return (
      <VerifyPaymentAccountOnboarding secureToken={secureToken} />
    );
  }

  if (context === 'VccPaymentHelp') {
    // TODO: Make this page support when the token is invalid
    if (!isValid) {
      return (
        <ProblemErnie
          centerInPage={true}
          errorReason="Invalid Token"
        />
      );
    }

    return (
      <VccHelp secureToken={secureToken} secureTokenContext={secureTokenContext} />
    );
  }

  if (context === 'PaymentOptimizationSelfEnrollment') {
    return (
      <SelfEnrollment
        secureToken={secureToken}
      />
    );
  }

  if (invitationId) {
    // TODO: Make this page support when the token is invalid
    if (!isValid) {
      return (
        <ProblemErnie
          centerInPage={true}
          errorReason="Invalid Token"
        />
      );
    }

    return (
      <RegistrationLayout invitationId={invitationId}>
        <RegistrationView invitationId={invitationId} />
      </RegistrationLayout>
    );
  }

  return (
    <ProblemErnie
      centerInPage={true}
      errorReason={`Unknown Context: ${context}`}
    />
  );
};

export default TokenLanding;
