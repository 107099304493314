/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, { useEffect, useState, forwardRef, useRef } from 'react';
import FormJSX, { FieldOption } from 'components/Form/FormJSX';

import { generateFakeGuid } from 'config/utils';
import { FormEditOrAddSignature } from 'containers/AddSignature/editOrAddSignature';
import TaxIdTextBox from 'components/Form/Inputs/TaxIdTextBox';
import InfoTooltip from 'components/InfoTooltip';

import t from 'tcomb-validation';
import {
  createBoundedDigitOnlyNonSequentialId
} from 'schemas/common/number';

const SignAccount = forwardRef((props, ref) => {
  let {
    isReceiptAccount,
    isPersonalAccount,
    includeTaxId,
    hasBankAccount,
    accountName
  } = props;
  const [value, setValue] = useState({
    accountName
  });

  // existing endpoint code requires passing of a signatureId of guid form to the mobile-signature endpoint.
  // Even if it is not unique, it must follow the regex pattern
  const [signatureId] = useState(generateFakeGuid());
  const signatureRef = useRef();

  // make sure we delete any stored signature data when this component unmounts
  useEffect(() => {
    return () => {
      signatureRef?.current?.cleanupStorage();
    };
  }, []);

  const schema = {
    signer1: t.String
  };

  if (includeTaxId && !isPersonalAccount) {
    schema.taxIdNumber = createBoundedDigitOnlyNonSequentialId(9);
  }

  if (!isReceiptAccount) {
    schema.signatureImage = t.String;
  }

  if (hasBankAccount) {
    // we need unique names for each account
    schema.accountName = t.String;
  }

  return (
    <div>
      <FormJSX
        ref={ref}
        modelType={t.struct(schema)}
        value={value}
        onChange={v => setValue(v)}>

        <FieldOption
          name="accountName"
          label="Bank Account Name (Nickname)"
          placeholder="e.g. Secondary Checking"
          appendLabelContent={(
            <InfoTooltip>
              Provide a unique name for this account. When multiple bank accounts are used,
              the name helps to distinguish them.
              This name can be changed at any time by editing the bank account from the Payment Methods page.
            </InfoTooltip>
          )}
          cols={6}
        />

        <FieldOption
          name="taxIdNumber"
          inputComponent={TaxIdTextBox}
          label="Tax ID Number (TIN, EIN)"
          appendLabelContent={(
            <InfoTooltip>
              Please enter the taxpayer identification number (TIN) for the company
              you are registering. This may either be your employer identification
              number (EIN) or, in some instances, such as for a sole proprietor,
              may be your social security number (SSN).
            </InfoTooltip>
          )}
          cols={6}
        />

        <FieldOption
          name="signer1"
          label="Authorized Signer"
          placeholder="e.g. John Doe"
          cols={6}
        />

        <FieldOption
          name="signatureImage"
          label="Authorized Signature"
          inputComponent={FormEditOrAddSignature}
          inputProps={{
            formRef: signatureRef,
            signatureId,
            variant: 'new'
          }}
        />

      </FormJSX>
    </div>
  );
});

export default SignAccount;