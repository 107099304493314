/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import classNames from 'classnames';
import moment from 'moment';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import RcCalendar from 'rc-calendar';
import 'style-loader!css-loader!rc-calendar/assets/index.css';
// import { removeTabIndex } from 'components/utils';
import './index.scss';

export const CalendarStyles = {
  SelectedDateStyle: {
    color: 'white',
    backgroundColor: '#1e88e5'
  },
  SelectedRangeStyle: {
    color: '#333',
    backgroundColor: '#C9E7F9'
  },
  OrangeStyle: {
    color: 'white',
    backgroundColor: '#fdaf28'
  },
  GreenStyle: {
    color: 'white',
    backgroundColor: '#8EB43D'
  }
};

export default class Calendar extends Component {
  static propTypes = {
    /** The date value that is actually selected */
    value: PropTypes.instanceOf(Date),
    /*
      the date currently being viewed by the calendar
    */
    viewDate: PropTypes.instanceOf(Date),

    /** Fires when the date value/selection changes */
    onChange: PropTypes.func,

    /** Fires when the selected date changes */
    onChangeViewDate: PropTypes.func,

    /** Allows disabling specific dates in the calendar.
      A function that takes a date and returns true if the date should be disabled.
    */
    isDateDisabled: PropTypes.func,

    /** Allows highlighting specific date ranges on the calendar */
    highlightRanges: PropTypes.arrayOf(PropTypes.shape({
      start: PropTypes.instanceOf(Date),
      end: PropTypes.instanceOf(Date),
      /** CSS color for text */
      color: PropTypes.string,
      /** CSS color for background */
      backgroundColor: PropTypes.string
    })),
    /** Allows custom highlighting of specfic dates on the calendar */
    highlightDates: PropTypes.arrayOf(PropTypes.shape({
      date: PropTypes.instanceOf(Date),
      /** CSS color for text */
      color: PropTypes.string,
      /** CSS color for background */
      backgroundColor: PropTypes.string
    }))
  };

  static defaultProps = {
    viewDate: new Date(),
    onChange: () => { },
    value: null
  };

  constructor(props) {
    super(props);
    this.state = {
      viewDate: this.getViewDateFromProps(props)
    };
  }

  componentWillMount() {
    this.setState({
      value: this.props.value,
      viewDate: this.props.viewDate || this.props.value || new Date()
    });
  }

  componentWillReceiveProps(props) {
    if (!this.isSameDate(this.props.value, props.value)) {
      this.setState({
        value: props.value
      });
    }
    if (props.viewDate && !this.isSameDate(props.viewDate, this.props.viewDate)) {
      this.setState({
        viewDate: this.getViewDateFromProps(props)
      });
    }
  }

  isSameDate(date1, date2) {
    return moment(date1).isSame(date2, 'date');
  }

  getViewDateFromProps(props) {
    if (!props.viewDate) {
      return new Date();
    }
    let md = moment(props.viewDate);
    return md.isValid() ? md.toDate() : new Date();
  }

  isSelectedDate(date) {
    if (this.state.value) {
      return this.isSameDate(date, this.state.value);
    }
    return false;
  }

  isInMonth(date) {
    if (this.state.viewDate) {
      return this.state.viewDate.getMonth() === date.month();
    }
    return false;
  }

  isInRange(date, startDate, endDate) {
    return date.isSameOrAfter(startDate, 'date')
      && date.isSameOrBefore(endDate, 'date');
  }

  onMouseOverDate(date) {
  }

  onMouseOutDate(date) {
  }

  onChange(momentDate) {
    if (momentDate) {
      this.props.onChange(momentDate.toDate());
    } else {
      this.props.onChange(null);
    }
  }

  onChangeViewDate(momentDate) {
    this.setState({
      viewDate: momentDate.toDate()
    }, () => {
      if (this.props.onChangeViewDate) {
        this.props.onChangeViewDate(this.state.viewDate);
      }
    });
  }

  getRangesForCell(date) {
    let ranges = [].concat(this.props.highlightRanges);

    // custom range highlights
    ranges = ranges.filter((range) => {
      return !!range
        && this.isInRange(date, range.start, range.end);
    });

    return ranges;
  }

  getStyleForDate(date, ranges) {
    let color = null;
    let backgroundColor = null;

    if (ranges) {
      // let the range also override the color
      ranges.forEach((range) => {
        if (range.color) color = range.color;
      });
    }

    // custom date highlights
    if (this.props.highlightDates) {
      this.props.highlightDates.forEach((highlightedDate) => {
        if (this.isSameDate(date, highlightedDate.date)) {
          if (highlightedDate.color) color = highlightedDate.color;
          if (highlightedDate.backgroundColor) backgroundColor = highlightedDate.backgroundColor;
        }
      });
    }

    // selection
    if (this.isSelectedDate(date)) {
      return CalendarStyles.SelectedDateStyle;
    }

    return {
      backgroundColor,
      color
    };
  }

  renderDateCell(date, today) {
    let classes = classNames(
      'calendar-day',
      {
        // 'before-today': this.props.shouldSlashPastDates && this.isBeforeToday(date),
        'outside-month': !this.isInMonth(date),
        'selected': this.isSelectedDate(date),
        'disabled': this.props.isDateDisabled && this.props.isDateDisabled(date.toDate())
      }
    );

    let ranges = this.getRangesForCell(date);

    return (
      <div className={classes}>
        {/* background coloring for ranges */}
        {ranges.map((range, index) => {
          return (
            <div key={index} className={classNames('range',
              {
                'range-start': date.isSame(range.start, 'date'),
                'range-end': date.isSame(range.end, 'date'),
                'line-start': date.day() === 0, // sunday
                'line-end': date.day() === 6 // saturday
              }
            )} style={{
              backgroundColor: range.backgroundColor
            }}>
            </div>
          );
        })}

        {/* background coloring for specific dates */}
        <div className="date"
          style={this.getStyleForDate(date, ranges)}
          onMouseOver={() => this.onMouseOverDate(date)}
          onMouseOut={() => this.onMouseOutDate(date)}>
          {date.date()}
        </div>
      </div>
    );
  }

  render() {
    return (
      <RcCalendar
        className={classNames('component-calendar', 'single-calendar-mode')}
        value={moment(this.state.viewDate)}
        defaultValue={moment(this.state.value)}
        onChange={v => this.onChangeViewDate(v)}
        onSelect={(...args) => this.onChange(...args)}
        showToday={false}
        showDateInput={false}
        disabledDate={(momentDate) => {
          if (this.props.isDateDisabled) {
            return this.props.isDateDisabled(momentDate);
          }
          return false;
        }}
        dateRender={(...args) => this.renderDateCell(...args)}
      />
    );
  }
}
