/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { isFetchAction, isSuccessAction } from 'api/core/utils';
import { CorrelationId } from 'config/constants/dashboard';
import { newState } from './utils';

export function PaymentDetailsStep(state = {}, action) {
  if (isFetchAction(action, 'createTwoFactorAuthentication')) {
    return newState(state, {
      phoneNumber: action.payload.params.phoneNumber
    });
  }

  if (isSuccessAction(action, 'getDocumentStoreValue')
    && action.meta.correlationId === CorrelationId.FetchMobileSignature) {
    return newState(state, {
      mobileSignature: action.payload.references[0]
    });
  }

  if (isSuccessAction(action, 'deleteDocumentStoreValue')
    && action.meta.correlationId === CorrelationId.FetchMobileSignature) {
    return newState(state, {
      mobileSignature: null
    });
  }

  return state;
}
