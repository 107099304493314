/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, { useEffect, useMemo } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { VelocityTransitionGroup } from 'velocity-react';

import Notification, { NotificationTypes, NotificationStyleTypes } from 'components/Notification';

import NotificationActions from 'config/constants/NotificationActions';

import useIsMobile from 'hooks/browser/useIsMobile';
import useIsAuthorizedToUseApp from 'hooks/identity/useIsAuthorizedToUseApp';

import './index.scss';

const DefaultTimeouts = {
  'default': 15000,
  error: 30000
};

const NotificationManager = () => {
  // Hooks
  const dispatch = useDispatch();
  const isMobile = useIsMobile();

  const notifications = useSelector(
    state => state.notifications,
    shallowEqual
  );

  const isAuthorizedToUseApp = useIsAuthorizedToUseApp();

  // If we switch authentication states, clear the notifications
  useEffect(
    () => {
      dispatch({ type: NotificationActions.ClearAll });
    },
    [ isAuthorizedToUseApp ]
  );

  const elements = useMemo(
    () => notifications.reduce(
      (
        val,
        {
          closeOnClick,
          closeOnTimeout,
          id,
          notificationType: originalNotificationType,
          timeOut,
          type,
          ...notificationParams
        }
      ) => {
        const notificationType = isMobile
          ? NotificationTypes.Toast
          : originalNotificationType || NotificationTypes.Toast;
        const styleType = type || NotificationStyleTypes.Default;

        const closeHandler = () => dispatch({ type: NotificationActions.Hide, id: id });

        let clickHandler = null;
        if (closeOnClick === true) {
          clickHandler = closeHandler;
        }

        if (closeOnTimeout !== false) {
          setTimeout(
            closeHandler,
            timeOut || DefaultTimeouts[type] || DefaultTimeouts.default
          );
        }

        if (val[notificationType] == null) {
          val[notificationType] = [];
        }

        val[notificationType].push(
          <Notification
            key={`notification-${id}`}
            onClick={clickHandler}
            onClose={closeHandler}
            styleType={styleType}
            type={type}
            {...notificationParams}
          />
        );

        return val;
      },
      {}
    ),
    [ notifications ]
  );

  // Render
  return (
    <>
      <VelocityTransitionGroup
        className="viewstrap vp-notification-manager full-banner-manager"
        aria-live="polite"
        role="alert"
        enter={{ animation: 'slideDown' }}
        leave={{ animation: 'slideUp' }}
      >
        {elements[NotificationTypes.FullBanner]}
      </VelocityTransitionGroup>
      <VelocityTransitionGroup
        className="viewstrap vp-notification-manager toast-manager"
        aria-live="polite"
        role="alert"
        enter={{ animation: 'slideDown' }}
        leave={{ animation: 'slideUp' }}
      >
        {elements[NotificationTypes.Toast]}
      </VelocityTransitionGroup>
      <VelocityTransitionGroup
        className="viewstrap vp-notification-manager small-banner-manager"
        aria-live="polite"
        role="alert"
        enter={{ animation: 'slideDown' }}
        leave={{ animation: 'slideUp' }}
      >
        {elements[NotificationTypes.SmallBanner]}
      </VelocityTransitionGroup>
    </>
  );
};

export default NotificationManager;
