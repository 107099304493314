/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */
/* eslint max-len:0 */

import { defineMessages } from 'react-intl';

let common = defineMessages({
  New: {
    id: 'GuidedTour.New',
    defaultMessage: 'NEW'
  },
  Progress: {
    id: 'GuidedTour.Progress',
    defaultMessage: '{current} of {total}'
  }
});

export default {
  ...common
};
