/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

function mapAddressToApi(createableAddress) {
  if (!createableAddress) {
    return null;
  }
  return {
    line1: createableAddress.line1,
    line2: createableAddress.line2,
    line3: createableAddress.line3,
    line4: createableAddress.line4,
    city: createableAddress.city,
    stateProvince: createableAddress.stateProvince,
    postalCode: createableAddress.postalCode,
    country: createableAddress.country
  };
}

export default mapAddressToApi;