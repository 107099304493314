/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, { useLayoutEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';

import getResetPasswordRequest from 'api/session/resetPassword/getResetPasswordRequest';

import { SmallBannerNotification, NotificationStyleTypes } from 'components/Notification';

import { ResetPasswordAction } from 'config/constants/session';
import CommonMessages from 'config/messages/common';

import AccessDenied from 'containers/Logon/AccessDenied';
import InitiateResetPassword from 'containers/Logon/LogonWorkflow/InitiateResetPassword';

import createExecuteApiResultHooks from 'hooks/apiResult/createExecuteApiResultHooks';
import useStatusIndicator from 'hooks/apiResult/useStatusIndicator';

import SecurityQuestionPrompt from './SecurityQuestionPrompt';
import TwoFactorPrompt from './TwoFactorPrompt';
import SetPassword from './SetPassword';
import Messages from './index.messages';

const GetRequestPasswordStatus = createExecuteApiResultHooks({
  endpoint: getResetPasswordRequest
});

const CompleteResetPassword = () => {
  // Hooks
  const { token } = useParams();

  const [
    {
      action: currentAction,
      emailAddress,
      failure,
      newPasswordToken,
      securityQuestion,
      twoFactor
    },
    setStatus
  ] = useState({});

  const executeGetStatus = GetRequestPasswordStatus.useExecuteRequest();

  const onUpdate = useMemo(
    () => (status) => {
      if (!!status) {
        setStatus(status);
      }
    },
    []
  );

  useLayoutEffect(
    () => {
      const execute = async () => {
        const [ success, action ] = await executeGetStatus(
          { id: token },
          { failureMessage: false }
        );

        if (success) {
          onUpdate(action.payload.result);
        }
      };
      execute();
    },
    [ token ]
  );

  const StatusIndicator = useStatusIndicator([ GetRequestPasswordStatus ]);

  // Render
  if (StatusIndicator) {
    return <StatusIndicator />;
  }

  if (failure === 'Locked') {
    return (
      <>
        <h2 style={{ textAlign: 'center' }}>
          <Messages.AccessDeniedHeader.Message />
        </h2>
        <AccessDenied
          submitMessage={<Messages.OkGotIt.Message />}
        >
          <Messages.AccessDeniedInstructions.Message
            contact={<CommonMessages.CustomerSupportEmail.Message />}
          />
        </AccessDenied>
      </>
    );
  }

  if (currentAction === ResetPasswordAction.SetPassword) {
    return (
      <SetPassword
        newPasswordToken={newPasswordToken}
        token={token}
      />
    );
  }
  if (currentAction === ResetPasswordAction.TwoFactorRequired) {
    return (
      <>
        <h2 style={{ textAlign: 'center' }}>
          <Messages.InProgressHeader.Message />
        </h2>
        <TwoFactorPrompt
          error={twoFactor?.reason}
          onUpdate={onUpdate}
          token={token}
        />
      </>
    );
  }

  if (currentAction === ResetPasswordAction.SecurityQuestionAuthRequired) {
    return (
      <>
        <h2 style={{ textAlign: 'center' }}>
          <Messages.InProgressHeader.Message />
        </h2>
        <SecurityQuestionPrompt
          error={securityQuestion?.reason}
          token={token}
          onUpdate={onUpdate}
          securityQuestions={securityQuestion?.questions}
        />
      </>
    );
  }

  return (
    <>
      {failure === 'Expired' ? (
        <SmallBannerNotification
          type={NotificationStyleTypes.Warning}
          className="margin-20-bottom"
        >
          <Messages.ExpiredInstructions.Message />
        </SmallBannerNotification>
      ) : null}
      <InitiateResetPassword
        key={emailAddress}
        emailAddress={emailAddress}
      />
    </>
  );
};

export default CompleteResetPassword;
