/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';

import kickSession from 'api/session/kickSession';

import Button from 'components/Button';

import CommonMessages from 'config/messages/common';

import createExecuteApiResultHooks from 'hooks/apiResult/createExecuteApiResultHooks';

import Messages from './index.messages';

const KickSessionHooks = createExecuteApiResultHooks({
  endpoint: kickSession
});

const KickExistingSession = ({
  onUpdate
}) => {
  // Hooks
  const dispatch = useDispatch();

  const executeKickSession = KickSessionHooks.useExecuteRequest();
  const isLoading = KickSessionHooks.useIsLoading();

  const onKickSession = useMemo(
    () => async () => {
      const [ , action ] = await executeKickSession({});
      onUpdate(action.payload.result);
    },
    [ executeKickSession ]
  );

  const onCancel = useMemo(
    () => () => dispatch(onUpdate({}, true)),
    []
  );

  // Render
  return (
    <div>
      <p>
        <Messages.Instructions.Message />
      </p>
      <p>
        <Messages.Warning.Message
          contact={<CommonMessages.CustomerSupportEmail.Message />}
        />
      </p>
      <div className="text-center">
        <Button
          variant="secondary"
          onClick={onCancel}
        >
          <CommonMessages.Cancel.Message />
        </Button>
        <Button
          isProcessing={isLoading}
          isDisabled={isLoading}
          onClick={onKickSession}
        >
          <CommonMessages.Continue.Message />
        </Button>
      </div>
    </div>
  );
};

export default KickExistingSession;