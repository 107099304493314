/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

export default class MenuToggle extends Component {
  static propTypes = {
    label: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.string
    ]).isRequired,
    active: PropTypes.bool
  };

  static defaultProps = {
    active: false
  };

  constructor(props) {
    super(props);
  }

  render() {
    let containerStyle = {
      cursor: 'pointer'
    };

    let label;
    if (typeof this.props.label === 'string'
      || React.isValidElement(this.props.label)) {
      label = this.props.label;
    } else {
      label = <FormattedMessage {...this.props.label} />;
    }

    return (
      <span className="viewstrap" style={containerStyle}>
        <span style={this.props.style}>
          {label}
        </span>
        <span>
          <span className="caret" />
        </span>
      </span>
    );
  }
}
