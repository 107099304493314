/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, { Children, Fragment, isValidElement } from 'react';
import classNames from 'classnames';

const AutoRow = ({
  children,
  emptyFallback,
  gutter
}) => {
  const rows = [];

  let currentColCount = 0;
  let currentRow = [];

  const commitCurrentRow = () => {
    if (currentRow.length) {
      rows.push(currentRow);
    }

    currentRow = [];
    currentColCount = 0;
  };

  const handleChild = (child) => {
    if (!isValidElement(child)) return;

    if (child.type === Fragment) {
      Children.forEach(child.props.children, handleChild);
      return;
    }

    const {
      cols,
      startNewRowAfter
    } = child.props || {};

    if (!cols) {
      commitCurrentRow();
      rows.push(
        <div className="col-12">
          {child}
        </div>
      );
      return;
    }

    if (cols + currentColCount > 12) {
      commitCurrentRow();
    }

    currentRow.push(
      <div key={currentColCount} className={`col-${cols}`}>
        {child}
      </div>
    );
    currentColCount += cols;

    if (startNewRowAfter) {
      commitCurrentRow();
    }
  };

  Children.forEach(children, handleChild);

  commitCurrentRow();

  return (
    <>
      {rows.map((row, index) => (
        <div
          key={index}
          className={classNames(
            'row',
            {
              gutter,
              'pad-y-half': gutter
            }
          )}
        >
          {row}
        </div>
      ))}
      {!rows.length ? emptyFallback : null}
    </>
  );
};

export default AutoRow;