/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, { forwardRef } from 'react';
import { stripFormatting } from 'schemas/common/phone';
import MaskedTextInput from 'react-text-mask';
import BrowserDetection from 'services/BrowserDetection';
import TextBox from 'components/Form/Inputs/TextBox';

const MaskedTextInputMask = ['(', /[1-9]/, /\d/, /\d/, ')', '\u2000', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];

const PhoneTextBox = forwardRef((
  {
    onChange,
    placeholder,
    value,
    ...props
  },
  ref
) => {
  /** This prevents an Android bug that reorders numbers because of a delay on input*/
  if (BrowserDetection.isAndroid()) {
    const inputValue = value === 0 || value ? value : '';
    return (
      <MaskedTextInput
        className="vp-input"
        type="tel"
        value= {inputValue}
        guide={true}
        placeholderChar=" "
        placeholder={placeholder}
        mask={MaskedTextInputMask}
        onChange={e => onChange(e.target.value)}
      />
    );
  }

  const strippedValue = stripFormatting(value);
  let firstThree = '';
  let secondThree = '';
  let lastFour = '';

  if (strippedValue) {
    if (strippedValue.length) {
      firstThree = `(${strippedValue.slice(0, 3)}`;
    }

    if (strippedValue.length > 3) {
      secondThree += `) ${strippedValue.slice(3, 6)}`;
    }

    if (strippedValue.length > 6) {
      lastFour = `-${strippedValue.slice(6)}`;
    }
  }

  const formattedValue = strippedValue ? `${firstThree}${secondThree}${lastFour}` : null;

  return (
    <TextBox
      ref={ref}
      {...props}
      onChange={onChange}
      placeholder={placeholder}
      value={formattedValue}
    />
  );
});

// This is interesting - we can get any form of phone number as the input, so we normalize it with
// the 'format' here so we can reformat it to something pretty. Then that prety value gets passed to
// the text box, which were feeding that prettified result back to onChange because the parse was
// doing nothing more than passing whatever value through. This causes the state of the form to have
// an invalid value for this phone number field (it needs to be the stripped version). This wasn't
// an issue for FormJSX, but this is an issue for FormV2.
// FormJSX works by having the parent form state pass down the values to the form input children,
// which it in turns runs the format on and saves the value to the input's internal state. When it
// comes time to get the value of the entire form, FormJSX ignores its state and goes to each form
// field to get that saved, formatted value. So in the FormJSX world, parse is meaningless. (This
// might explain some fights I've had with formatting/parsing form values)
// FormV2's input components format the value on the way down and parses the value on the way back
// up to the parent form state and uses the parent form state when it comes time to get the form's
// value.
// Because TextBox is going to feed us the same pretty phone number back to us, simply reapplying
// the strip gets the value back into a valid state
PhoneTextBox.createTransformer = () => ({
  format: val => stripFormatting(val),
  parse: val => stripFormatting(val)
});

export default PhoneTextBox;
