/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React from 'react';
import FormJSX, { FieldOption } from 'components/Form/FormJSX';
import { AuthorizedSigner, AuthorizedSignerTin } from 'schemas/payments/bankAccount';
import { FormEditOrAddSignature } from 'containers/AddSignature/editOrAddSignature';
import TaxIdTextBox from 'components/Form/Inputs/TaxIdTextBox';
import InfoTooltip from 'components/InfoTooltip';
import Messages from './index.messages';

const AddSignatureAndTinForm = React.forwardRef((
  {
    invitationId,
    onChange,
    showTin,
    signatureId,
    signatureRef,
    value
  },
  ref
) => (
  <FormJSX
    ref={ref}
    modelType={showTin ? AuthorizedSignerTin : AuthorizedSigner}
    showRequiredLabels={true}
    hideFormLegend={true}
    onChange={onChange}
    value={value}
  >
    <FieldOption
      name="authorizedSigner"
      label={Messages.AuthorizedSigner}
      cols={6}
    />
    {showTin ? (
      <FieldOption
        name="taxPayerId"
        inputComponent={TaxIdTextBox}
        label={Messages.TaxIdNumber}
        appendLabelContent={(
          <InfoTooltip>
            <Messages.TaxIdTooltip.Message />
          </InfoTooltip>
        )}
        cols={6}
      />
    ) : null}
    <FieldOption
      name="signature"
      label={Messages.Signature}
      inputComponent={FormEditOrAddSignature}
      inputProps={{
        formRef: signatureRef,
        signatureId,
        variant: 'new',
        invitationId
      }}
    />
  </FormJSX>
));

export default AddSignatureAndTinForm;
