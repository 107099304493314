/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import moment from 'moment';

// serialized DateTime -> JS Date
function dateMapper(dateString, adjustForTimezone = false) {
  if (!dateString || moment(dateString).year() === 0) return null;
  // Json.NET serialzes as: 2015-10-19T22:38:54.0595191Z

  if (adjustForTimezone) {
    // if server actually gives us accurate time, we should localize it
    return moment(dateString).toDate();
  }

  return moment(dateString, 'YYYY-MM-DDThh:mm:ss').toDate();
}

export default dateMapper;