/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import classNames from 'classnames';
import moment from 'moment';

const HTML5_DATE_FORMAT = 'YYYY-MM-DD';

/**
   An uncontrolled React date field.
   This component only exists as a HACK (instead of using TextBox) because of this react bug on iOS:
   https://github.com/facebook/react/issues/8938

   This causes iOS Safari's Clear button to not work normally in React.

   When passing the value to the date input, the built-in Safari clear button does not return a cleared value
   in the onchange event.
 */
export default class IOSNativeDate extends Component {
  static propTypes = {
    placeholder: PropTypes.string,
    hasError: PropTypes.bool,
    tabIndex: PropTypes.number,
    disabled: PropTypes.bool
  };

  static defaultProps = {
    hasError: false
  };

  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);
    this.onFocus = this.onFocus.bind(this);
    this.onBlur = this.onBlur.bind(this);
  }

  componentDidMount() {
    this.setValue(this.props.value);
  }

  componentDidReceiveProps(props) {
    if (this.props.value !== props.value) {
      this.setValue(this.props.value);
    }
  }

  setValue(value) {
    // manually set the value in the DOM
    const textBox = ReactDOM.findDOMNode(this.textBox);
    if (textBox) {
      textBox.value = this.dateToValue(value);
    }
  }

  dateToValue(value) {
    return moment(value).format(HTML5_DATE_FORMAT);
  }

  valueToDate(value) {
    if (!value || value.length < 1) {
      return null;
    }

    return moment(value, HTML5_DATE_FORMAT).toDate();
  }

  onFocus(e) {
    if (this.props.onFocus) {
      this.props.onFocus(e);
    }
  }

  onBlur(e) {
    if (this.props.onBlur) {
      this.props.onBlur(e);
    }
  }

  onChange(value) {
    if (this.props.onChange) {
      this.props.onChange(this.valueToDate(value));
    }
  }

  getTextBoxClasses() {
    return classNames(
      'vp-input',
      {'error': this.props.hasError}
    );
  }

  render() {
    let options = this.props.options || {};
    let config = options.config || {};

    return (
      <input
        ref={r => this.textBox = r}
        tabIndex={config.tabIndex}
        style={this.props.style}
        className={this.getTextBoxClasses()}
        disabled={this.props.disabled}
        name={this.props.name}
        placeholder={this.props.placeholder}
        autoFocus={this.props.autoFocus}
        type="date"
        onChange={evt => this.onChange(evt.target.value)}
        onFocus={this.onFocus}
        onBlur={this.onBlur}
        key={config.instanceKey} />
    );
  }
}
