/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, { useMemo, useRef, useState } from 'react';
import t from 'tcomb-validation';

import createResetPassword from 'api/session/resetPassword/createResetPassword';

import Image from 'components/Viewstrap/Image';
import Button from 'components/Button';
import FormV2, { FieldInput, FieldOption } from 'components/Form/V2';
import PathLink from 'components/Link/PathLink';

import { Knight } from 'config/images/ernie';
import CommonMessages from 'config/messages/common';
import { preventDefault } from 'config/utils/click';

import AccessDenied from 'containers/Logon/AccessDenied';

import createExecuteApiResultHooks from 'hooks/apiResult/createExecuteApiResultHooks';
import useFormatMessage from 'hooks/intl/useFormatMessage';

import Email from 'schemas/common/email/Email';

import Messages from './index.messages';

const CreateResetPasswordHooks = createExecuteApiResultHooks({
  endpoint: createResetPassword
});

const FormSchema = t.struct({
  emailAddress: Email
});

const InitiateResetPassword = ({
  emailAddress: prefilledEmailAddress,
  onComplete
}) => {
  // Hooks
  const formRef = useRef();
  const formatMessage = useFormatMessage();

  const [ formState, setFormState ] = useState({
    emailAddress: prefilledEmailAddress
  });

  const [
    {
      initiated,
      failure
    },
    setResult
  ] = useState({});

  const executeCreateRequest = CreateResetPasswordHooks.useExecuteRequest();
  const isLoading = CreateResetPasswordHooks.useIsLoading();

  const onSubmit = useMemo(
    () => preventDefault(
      async () => {
        const value = formRef.current.getValue();

        if (value) {
          const [ , action ] = await executeCreateRequest({
            emailAddress: value.emailAddress
          });

          setResult(action.payload.result);
        }
      }
    ),
    [ executeCreateRequest ]
  );

  // Render
  if (failure === 'Locked') {
    return (
      <div>
        <h2 style={{ textAlign: 'center' }}>
          <Messages.AccessDeniedHeader.Message />
        </h2>
        <AccessDenied
          onComplete={onComplete}
          submitMessage={<Messages.AccessDeniedButton.Message/>}
        >
          <Messages.AccessDeniedInstructions.Message />
        </AccessDenied>
      </div>
    );
  }

  if (initiated) {
    return (
      <div>
        <h2 style={{ textAlign: 'center' }}>
          <Messages.ResetPasswordSentHeader.Message />
        </h2>
        <div>
          <p>
            <Messages.SentInstructions.Message />
          </p>
          {onComplete ? (
            <Button expand={true} onClick={onComplete}>
              <CommonMessages.Continue.Message />
            </Button>
          ) : (
            <PathLink.Logon button={{ expand: true }}>
              <CommonMessages.Continue.Message />
            </PathLink.Logon>
          )}
        </div>
      </div>
    );
  }

  return (
    <div>
      <h2 style={{ textAlign: 'center' }}>
        <Messages.Header.Message />
      </h2>
      <form>
        <Image image={Knight} willCenter={true} />
        <FormV2
          ref={formRef}
          formTag={false}
          modelType={FormSchema}
          onChange={setFormState}
          value={formState}
        >
          <FieldOption
            name="emailAddress"
            validateOnBlur={false}
            label={<Messages.EmailAddress.Message/>}
          >
            <FieldInput
              placeholder={formatMessage(Messages.EmailAddressPlaceholder)}
            />
          </FieldOption>
        </FormV2>
        <Button
          type="submit"
          isDisabled={isLoading}
          isProcessing={isLoading}
          onClick={onSubmit}
          expand={true}
        >
          <CommonMessages.Continue.Message />
        </Button>
        <p
          className="vp-footer pad-y"
          style={{
            marginTop: '1rem',
            textAlign: 'center'
          }}
        >
          {onComplete ? (
            <Button anchor={true} onClick={onComplete}>
              <CommonMessages.Cancel.Message />
            </Button>
          ) : (
            <PathLink.Logon>
              <CommonMessages.Cancel.Message />
            </PathLink.Logon>
          )}
        </p>
      </form>
    </div>
  );
};

export default InitiateResetPassword;
