/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

/*
  Converts a Data Uri (e.g. from a canvas) into a binary blob
  This is useful for uploading images from a canvas.
  Reference: http://stackoverflow.com/questions/4998908/convert-data-uri-to-file-then-append-to-formdata
*/
function dataUriToBlob(dataUri) {
  if (!dataUri) {
    return null;
  }

  if (!global.Blob) { // Note: this is not supported by IE9
    throw new Error('Unable to locate Blob object in your environment.');
  }

  let byteString;

  if (dataUri.split(',')[0].indexOf('base64') >= 0) {
    byteString = global.atob(dataUri.split(',')[1]);
  } else {
    byteString = global.decodeURI(dataUri.split(',')[1]);
  }

  const mimeString = dataUri.split(',')[0]
    .split(':')[1]
    .split(';')[0];

  const ia = new global.Uint8Array(byteString.length);

  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  return new global.Blob([ia], { type: mimeString });
}

export default dataUriToBlob;