/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

export const START_ACTION_TYPE = 'WORKFLOW_START';
export const STEP_ACTION_TYPE = 'WORKFLOW_STEP';
export const END_ACTION_TYPE = 'WORKFLOW_END';
export const FINISH_ACTION_TYPE = 'WORKFLOW_FINISH';

import { START_STEP, END_STEP, FINISH_STEP } from 'containers/Workflow';

export function startWorkflow(workflowId) {
  return (dispatch) => {
    dispatch({
      type: START_ACTION_TYPE,
      id: workflowId
    });
  };
}

export function endWorkflow(workflowId) {
  return (dispatch) => {
    dispatch({
      type: END_ACTION_TYPE,
      id: workflowId
    });
  };
}

export function finishWorkflow(workflowId) {
  return (dispatch) => {
    dispatch({
      type: FINISH_ACTION_TYPE,
      id: workflowId
    });
  };
}

export function goToStep(step, workflowId) {
  return (dispatch) => {

    if (step === START_STEP) {
      dispatch(startWorkflow(workflowId));
    } else if (step === END_STEP) {
      dispatch(endWorkflow(workflowId));
    } else if (step === FINISH_STEP) {
      dispatch(finishWorkflow(workflowId));
    } else {
      dispatch({
        type: STEP_ACTION_TYPE,
        payload: step,
        id: workflowId
      });
    }

  };
}
