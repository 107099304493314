/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Joyride from 'react-joyride';
import { isEqual } from 'lodash';
import { FormattedMessage } from 'react-intl';
import { logException } from 'services/ErrorService';
import Messages from './index.messages';
import './index.scss';

/**
 * A sequence of overlays that highlight specific elements on the page and give additional information about them.
 * Could be useful for introducing new features or explaining UI to new users.
 */
export default class GuidedTour extends Component {
  static propTypes = {
    runOnMount: PropTypes.bool,
    runOnMountDelay: PropTypes.number,

    /** Fires when user goes back a step */
    onBack: PropTypes.func,
    /** Fires when user goes forward a step (including last/done step) */
    onNext: PropTypes.func,
    /** Fires when the user chose to skip the rest of steps */
    onSkip: PropTypes.func,
    /** Fires when tour ended (skipped or ended normally) */
    onEnd: PropTypes.func,

    // joyride props
    steps: PropTypes.arrayOf(PropTypes.shape({
      /** Content for title/header of step overlay/modal */
      title: PropTypes.node,
      /** Content for body of step overlay/modal */
      text: PropTypes.node.isRequired,
      /**
        DOM selector for the element this step should highlight (point to). (First matching element is used)
        NOTE: The DOM element MUST be present AND visible on the screen. View is responsible for ensuring this when step is passed to guided tour.
      */
      selector: PropTypes.node.isRequired,
      /** The location where the tooltip will show relative to its matching target element (defined by the selector field) */
      position: PropTypes.oneOf([
        'top', 'right', 'left', 'bottom',
        'top-left', 'top-right', 'bottom-left', 'bottom-right'
      ]).isRequired
    })),

    /**
      When scrollToSteps=true, the browsers scrollTop is set to the target element's (defined by selector) minus this offset.
      Example: If your tooltip is a lot taller than the target element you may need to increase this to make sure it is entirely in view when scrolled to
    */
    scrollOffset: PropTypes.number, // offset from the top of the target to scroll to

    /** Whether or not to scroll to the highlighted element for each step */
    scrollToSteps: PropTypes.bool,
    /** Whether or not to scroll to the highlighted element for the first step */
    scrollToFirstStep: PropTypes.bool,
    /** Whether or not to show the step progess (e.g. 1 of 5) on each step */
    showStepsProgress: PropTypes.bool,

    /** When beacon is shown, what event triggers it to show its step */
    beaconTrigger: PropTypes.oneOf(['hover', 'click']),


    // Documenting below items for styleguide...but currently only used by GuidedTourService (TODO: should we move that functionality to this component?)

    /** Whether or not to immediately open the guided tour tooltip or show a beacon. Set this to false if you want a beacon */
    startWithFirstTooltipOpen: PropTypes.bool,

    /** Amount of milliseconds to delay before showing the tour after starting it */
    delay: PropTypes.number,

    /** Unique key for an acknowlegment to get/set as an indicator that this tour has already been shown */
    acknowledgementKey: PropTypes.string,

    /** Allows getting a ref to this tour component (so method here can be called by a consumer) */
    tourRef: PropTypes.func
  };

  static defaultProps = {
    runOnMount: true,
    runOnMountDelay: 500,
    scrollToSteps: true,
    scrollToFirstStep: true,
    scrollOffset: 20,
    showStepsProgress: true,
    beaconTrigger: 'click',

    startWithFirstTooltipOpen: true,
    delay: 1000
  };

  constructor(props) {
    super(props);
    this.onEvent = this.onEvent.bind(this);
    this.updateIndex = this.updateIndex.bind(this);
    this.start = this.start.bind(this);
    this.stop = this.stop.bind(this);
    this.reset = this.reset.bind(this);
    this.getProgress = this.getProgress.bind(this);
    this.state = {
      index: null,
      run: false,
      autoStart: false
    };
  }

  componentDidMount() {
    if (this.props.runOnMount) {
      setTimeout(
        () => this.setState({ run: true }),
        this.props.runOnMountDelay
      );
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !isEqual(nextProps, this.props)
      || !isEqual(this.state, nextState);
  }

  updateIndex() {
    if (this.joyrideRef
      && this.joyrideRef.getProgress().index !== this.state.index) {
      this.setState({
        index: this.joyrideRef.getProgress().index
      });
    }
  }

  onEvent(e) {
    this.updateIndex();
    if (e.type === 'step:after' && e.action === 'back' && this.props.onBack) {
      this.props.onBack(e);
    }
    if (e.type === 'step:after' && e.action === 'next' && this.props.onNext) {
      this.props.onNext(e);
    }
    if (e.type === 'finished' && e.action === 'skip' && this.props.onSkip) {
      // skipped
      this.props.onSkip(e);
    }
    if (e.type === 'finished'
      && this.props.onEnd

      // ignore finished with undefined action due to joyride bug:
      // https://github.com/gilbarbara/react-joyride/issues/103
      && e.action
    ) {
      // ended flow
      this.props.onEnd(e);
    }
  }

  render() {

    let steps = this.props.steps || [];

    let showSkip = true;
    let type = 'continuous';
    if (steps.length === 1) {
      type = 'single';
      showSkip = false;
    }

    if (this.state.index && this.state.index + 1 >= steps.length) {
      // last step
      showSkip = false;
    }

    let progressText = <FormattedMessage {...Messages.New} />;
    if (this.props.showStepsProgress) {
      progressText = (
        <FormattedMessage {...Messages.Progress} values={{
          current: this.state.index + 1,
          total: this.props.steps.length
        }}/>
      );
    }

    steps = steps.map((step) => {
      return {
        ...step,
        title: (
          <div style={{position: 'relative'}}>
            <div className="progress-text">
              {progressText}
            </div>
            <div>
              {step.title}
            </div>
          </div>
        ),
        type: this.props.beaconTrigger
      };
    });

    return (
      <Joyride
        ref={ref => this.joyrideRef = ref}
        type={type}
        showSkipButton={showSkip}
        tooltipOffset={this.props.tooltipOffset}
        scrollOffset={this.props.scrollOffset}
        scrollToFirstStep={this.props.scrollToFirstStep}
        scrollToSteps={this.props.scrollToSteps}
        run={this.state.run}
        autoStart={this.state.autoStart}
        disableOverlay={true}
        showStepsProgress={false}
        showBackButton={false}
        callback={this.onEvent}
        steps={steps}
        locale={{
          back: 'Back',
          close: 'Done',
          last: 'Done',
          next: 'Next',
          skip: 'Skip'
        }} />
    );
  }


  // expose key joyride API methods

  start(startWithFirstTooltipOpen = true) {
    this.setState({ run: true, autoStart: startWithFirstTooltipOpen });
  }

  stop() {
    this.setState({ run: false });
  }

  reset(restart = false) {
    this.joyrideRef.reset(restart);
  }

  getProgress() {
    return this.joyrideRef.getProgress();
    /* Should return an object like:
    {
      index: 2,
      percentageComplete: 50,
      step: {
          title: "...",
          text: "...",
          selector: "...",
          position: "...",
          ...
      }
    }} */
  }

  /** force an update to reposition the beacon/tooltips on its target (e.g. when target is a scrolling container other than body) */
  forceReposition() {
    try {
      this.joyrideRef.calcPlacement();
    } catch (e) {
      logException(e, {
        errorSource: 'GuidedTour',
        steps: JSON.stringify(this.props.steps)
      });
    }
  }
}
