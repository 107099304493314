/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { uniq } from 'lodash';
import entityMapper from 'api/common/mappers/entityMapper';
import dateMapper from 'api/common/mappers/dateMapper';
import { createApiAction } from 'api/core';
import { mapPaymentTracker, mapSettlementRole } from 'api/payments/utils.details';
import { mapSentPaymentStatus } from 'api/payments/utils.sent';
import { determineAvailableActionsFromState } from 'api/permissions';
import PaymentActions from 'config/constants/PaymentActions';
import { CreditCardType } from 'schemas/payments/creditCard';
import PaymentDetails from 'schemas/payments/PaymentDetails';
import { parseDate } from 'utils/dateUtils';
import { getPaymentMethodEnum, getPaymentMethodString } from 'utils/paymentUtils';
import { getIdentity } from 'api/permissions';

const getPaymentsSentDetails = createApiAction({
  method: 'get',
  url: '/api/settlement/details/:settlementId',
  queryStringMapper: r => r,
  responseMapper: (apiResponse, state, apiParams) => entityMapper(
    PaymentDetails,
    apiParams.settlementId,
    {
      id: ({ paymentId }) => paymentId === '00000000-0000-0000-0000-000000000000' ? null : paymentId,
      isOumc: p => p.ownerCompanyId !== getIdentity(state)?.companyId,
      settlementId: () => apiParams.settlementId,
      companyName: ({ payeeName }) => payeeName,
      customerName: ({ payorName }) => payorName,
      companyAddress: ({ payeeAddress = {} }) => {
        return payeeAddress ? {
          stateProvince: payeeAddress.state,
          ...payeeAddress
        } : null;
      },
      status: ({ status, isVoid }) => {
        return mapSentPaymentStatus(status, isVoid);
      },
      date: ({ status, scheduledDate, sentDate }) => status === 'Scheduled'
        ? parseDate(scheduledDate) : parseDate(sentDate),
      sentDate: payment => dateMapper(payment.sentDate),
      scheduledDate: payment => dateMapper(payment.scheduledDate),
      memo: ({ memo }) => {
        if (memo) {
          if (typeof memo === 'string') {
            return memo;
          } if (Array.isArray(memo)) {
            return memo.filter(line => line !== null).join('\n');
          }
        }

        return null;
      },
      method: ({ paymentMethod, maskedCardNumber }) => getPaymentMethodString({
        methodValue: paymentMethod,
        maskedCardNumber
      }),
      paymentMethodEnum: ({ paymentMethod }) => getPaymentMethodEnum(paymentMethod),
      allPaymentMethodTypes: (payment) => {
        let methods = [
          getPaymentMethodEnum(payment.paymentMethod)
        ];
        if (payment.paymentBreakdown) {
          methods = methods.concat(...payment.paymentBreakdown.map(p => getPaymentMethodEnum(p.paymentMethodType)));
        }
        return uniq(methods.filter(i => !!i));
      },
      gross: ({ grossTotal }) => grossTotal,
      creditMemoTotal: ({ creditMemoTotal }) => creditMemoTotal,
      remittanceDiscrepancyAmount: ({ remittanceDiscrepancyAmount }) => remittanceDiscrepancyAmount,
      remittanceTotal: ({ remittanceTotal }) => remittanceTotal,
      discounts: ({ discounts }) => discounts,
      lateFees: payment => payment.lateFee,
      totalAmount: payment => payment.amountPaid,
      currencyCode: payment => payment.currencyCode,
      isVoided: ({ isVoid }) => !!isVoid,
      maskedCardNumber: ({ maskedCardNumber }) => maskedCardNumber,
      cardType: payment => payment.cardType === 'Visa'
        ? CreditCardType.meta.map.Visa
        : CreditCardType.meta.map.MasterCard,
      paymentTracker: payment => mapPaymentTracker(payment.paymentTracker,
        payment.paymentMethod,
        payment.paymentEngineMode === 'Icl'),
      approvalDetails: ({ approvalDetails, numberApprovals, numberRequiredApprovals }) => {
        return !approvalDetails
          ? {
            approvedCount: numberApprovals ?? 0,
            remainingCount: (numberRequiredApprovals ?? 0) - (numberApprovals ?? 0),
            totalCount: numberRequiredApprovals ?? 0,
            isAdvanced: false
          }
          : {
            isAdvanced: approvalDetails.method === 0,
            approvedCount: approvalDetails.approvalCount,
            totalCount: approvalDetails.totalRequiredApprovalCount,
            remainingCount: approvalDetails.totalRequiredApprovalCount
              - (approvalDetails.approvalCount || 0),
            approved: approvalDetails.approvedByUsers.map(u => ({
              ...u,
              date: dateMapper(u.approvedTimestamp, true)
            })),
            remaining: approvalDetails.incompleteApprovalGroups.map(g => ({
              ...g,
              totalCount: g.requiredApprovalCount,
              remainingCount: g.requiredApprovalCount - g.currentApprovalCount
            })),
            levels: approvalDetails.approvalGroups.map(g => ({
              ...g,
              totalCount: g.requiredApprovalCount,
              remainingCount: g.remainingApprovalCount
            }))
          };
      },
      paymentBreakdown: payment => payment.paymentBreakdown
        ? (
          payment.paymentBreakdown.map((p) => {
            return {
              settlementId: p.settlementId,
              referenceNumber: p.referenceNumber,
              companyName: p.payeeName,
              status: mapSentPaymentStatus(p.status, p.isVoid),
              totalAmount: p.amount,
              availableAmount: p.availableAmount,
              sentDate: dateMapper(p.date),
              paymentMethodEnum: getPaymentMethodEnum(p.paymentMethodType),
              role: mapSettlementRole(p.settlementRole),
              description: p.description
            };
          })
        )
        : [],
      availableActionsState: ({canApprovePayment, status}) => {
        let actions = [PaymentActions.RequestPaymentSupport];
        if (canApprovePayment && status === 'PendingApproval') {
          actions.push(PaymentActions.Approve);
        }
        if (status === 'Scheduled' || status === 'PendingApproval') {
          actions.push(PaymentActions.CancelScheduled);
        }
        return determineAvailableActionsFromState(state, actions);
      }
    },
    apiResponse
  )
});

export default getPaymentsSentDetails;