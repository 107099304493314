/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React from 'react';
import { camelCase, upperFirst } from 'lodash';
import AnalyticsCategory from 'components/AnalyticsCategory';
import usePathname from 'hooks/location/usePathname';

const AnalyticsCategoryService = ({
  children
}) => {
  const pathname = usePathname();

  const analyticsCategory = pathname.split('/')
    .filter(part => part.length)
    .filter(part => part.match(/^[a-zA-Z].*/))
    .map(part => upperFirst(camelCase(part)))
    .join('.');

  return (
    <AnalyticsCategory
      name={analyticsCategory}
    >
      {children}
    </AnalyticsCategory>
  );
};

export default AnalyticsCategoryService;
