/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { isFetchAction, isSuccessAction } from 'api/core/utils';
import { newState } from '../utils';

export const ReceivedDetails = (state = {
  isLoadingRemittables: true
}, action) => {
  // Handle the load of the remittables
  if (isFetchAction(action, 'getReceivedPaymentRemittableItems')) {
    return newState(state, {
      isLoadingRemittables: true
    });
  } if (isSuccessAction(action, 'getReceivedPaymentRemittableItems')) {
    return newState(state, {
      isLoadingRemittables: false,
      remittables: action.payload.references
    });
  }

  // Handle the load of the attachments
  if (isSuccessAction(action, 'getPaymentAttachments')) {
    return newState(state, {
      attachments: action.payload.references
    });
  }

  if (action.type === '@@router/LOCATION_CHANGE') {
    return newState(state, {
      isLoadingRemittables: true,
      remittables: null,
      attachments: null
    });
  }

  return state;
};
