/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */
/* eslint max-len:0 */

import { defineMessages } from 'react-intl';

const common = defineMessages({
  SubmitButton: {
    id: 'InvitePartnerModal[Submit]',
    description: 'TODO',
    defaultMessage: 'Submit'
  },
  CloseButton: {
    id: 'InvitePartnerModal[Close]',
    description: 'TODO',
    defaultMessage: 'Close'
  }
});

export default {
  ...common
};
