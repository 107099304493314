/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { createApiAction } from 'api/core';

import logonResponseMapper from './mappers/logonResponseMapper';

const securityQuestionsSubmit = createApiAction({
  method: 'post',
  url: '/ajax/logon/securityChallenge',
  requestBodyMapper: (params) => {
    return {
      securityAnswers: params.map((answer) => {
        return {
          questionId: answer.questionId,
          answer: answer.answer
        };
      })
    };
  },
  responseMapper: logonResponseMapper
});

export default securityQuestionsSubmit;