/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, { useMemo, useRef } from 'react';
import { Menu, MainButton, ChildButton } from '@Viewpost/react-mfb';
import 'style-loader!css-loader!@Viewpost/react-mfb/mfb.css';

import PathLink from 'components/Link/PathLink';

import Paths from 'config/paths';

import { useLaunchCreateEditAddressBookModal } from 'containers/Modals/CreateEditAddressBook/Async';

import useFormatMessage from 'hooks/intl/useFormatMessage';
import usePathname from 'hooks/location/usePathname';

import useLaunchCreateSwitchCompanyModal from 'views/Settings/SwitchCompanies/CreateSwitchCompanyModal';

import BuildingIcon from './building.svg';
import PlusIcon from './plus.svg';
import CrossIcon from './cross.svg';
import PencilIcon from './pencil.svg';
import DollarSignIcon from './dollarSign.svg';
import Messages from './index.messages';
import connect from './index.connect';
import './index.scss';

const FloatingButtonLink = (
  label,
  path,
  onClick
) => ({
  children,
  className
}) => (
  <PathLink
    data-mfb-label={label}
    path={path}
    className={className}
  >
    {children}
  </PathLink>
);

const FloatingButtonAction = (
  label,
  onClick
) => ({
  children,
  className
}) => (
  <div
    data-mfb-label={label}
    className={className}
    onClick={onClick}
  >
    {children}
  </div>
);

const ActionButtonConfig = [{
  matchOn: Paths.Bills,
  additionalActions: ['CreateBill']
}, {
  matchOn: Paths.Settings.SwitchCompanies,
  additionalActions: ['CreateSwitchCompany']
}];

const AppFloatingActionButton = ({
  canCreateAddressBook,
  canCreateBill,
  canCreateInvoice,
  canSendPayment
}) => {
  // Hooks
  const formatMessage = useFormatMessage();
  const pathname = usePathname();

  const toggleMenuRef = useRef();

  const onChildButtonClick = useMemo(
    () => evt => toggleMenuRef.current?.toggleMenu?.(evt)
  );

  const launchCreateSwitchCompanyModal = useLaunchCreateSwitchCompanyModal();
  const launchCreateEditAddressBookModal = useLaunchCreateEditAddressBookModal();

  // Render
  if (!canCreateInvoice && !canSendPayment) return null;

  let match = ActionButtonConfig.find(
    ({ matchOn }) => matchOn.match(pathname, { alsoMatchChildren: true })
  );
  let additionalActions = [];

  if (match) {
    if (match.hide) return null;

    additionalActions = match.additionalActions || [];
  }

  let actions = [];

  let showCreateBill = canCreateBill && additionalActions.includes('CreateBill');

  if (showCreateBill) {
    actions = actions.concat(
      <ChildButton
        key="create_invoice"
        className="fab-invoice-button"
        linkComponent={FloatingButtonLink(
          formatMessage(Messages.CreateBill),
          Paths.Bills.Create,
          onChildButtonClick
        )}
        iconComponent={({className}) => <img className={`fab-icon ${className}`} src={PencilIcon} />}
      />
    );
  }

  if (!showCreateBill && canCreateInvoice) {
    actions = actions.concat(
      <ChildButton
        key="create_invoice"
        className="fab-invoice-button"
        linkComponent={FloatingButtonLink(
          formatMessage(Messages.CreateInvoice),
          Paths.Invoices.Create,
          onChildButtonClick
        )}
        iconComponent={({className}) => <img className={`fab-icon ${className}`} src={PencilIcon} />}
      />
    );
  }

  if (canSendPayment) {
    actions = actions.concat(
      <ChildButton
        key="send_payment"
        className="fab-payment-button"
        linkComponent={FloatingButtonLink(
          formatMessage(Messages.SendPayment),
          Paths.Payments.SendPayment,
          onChildButtonClick
        )}
        iconComponent={({className}) => <img className={`fab-icon ${className}`} src={DollarSignIcon} />}
      />
    );
  }

  if (canCreateAddressBook) {
    actions = actions.concat(
      <ChildButton
        key="create_address_book"
        className="fab-create-address-book-button"
        linkComponent={FloatingButtonAction(
          formatMessage(Messages.AddCompany),
          (e) => {
            onChildButtonClick(e);
            launchCreateEditAddressBookModal();
          }
        )}
        iconComponent={({ className }) => (
          <img className={`fab-icon ${className}`} src={BuildingIcon} />
        )}
      />
    );
  }

  // Completely usurp FAB if on Switch Company Page
  if (additionalActions.includes('CreateSwitchCompany')) {
    actions = [(
      <ChildButton
        key="create_address_book"
        className="fab-create-address-book-button"
        linkComponent={FloatingButtonAction(
          formatMessage(Messages.CreateSwitchCompany),
          (e) => {
            onChildButtonClick(e);
            launchCreateSwitchCompanyModal();
          }
        )}
        iconComponent={({ className }) => (
          <img className={`fab-icon ${className}`} src={BuildingIcon} />
        )}
      />
    )];
  }

  return (
    <Menu
      effect="slidein"
      method="click"
      position="br"
      ref={toggleMenuRef}
      preventDefault={false}
    >
      <MainButton
        className="fab-main-button"
        noHref={true}
        restingIcon={({className}) => <img className={`fab-icon ${className}`} src={PlusIcon} />}
        activeIcon={({className}) => <img className={`fab-icon ${className}`} src={CrossIcon} />}
      />
      {actions}
    </Menu>
  );
};

export default connect(AppFloatingActionButton);
