/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, { useState } from 'react';

import Accordion from 'components/Accordion';
import BodyText from 'components/BodyText';
import Border from 'components/Border';
import BulletedList, { AutoBullet } from 'components/BulletedList';
import HeaderText, { HeaderTextVariants } from 'components/HeaderText';
import PathLink from 'components/Link/PathLink';
import { FlexColumn, FlexGrid } from 'components/Viewstrap/Grid';

import useStatusIndicator from 'hooks/apiResult/useStatusIndicator';
import useQueryParams from 'hooks/location/useQueryParams';
import { GetRegistrationMetadataHooks } from 'hooks/registration';

import SmallBusinessIcon from 'public/images/registration/smallBusinessIcon.svg';
import InvoicingIcon from 'public/images/registration/invoicingIcon.png';
import PaymentsIcon from 'public/images/registration/paymentsIcon.svg';

import Messages, {
  InvoicingMessages,
  PaymentsMessages,
  ReasonMessages
} from './index.messages';
import './index.scss';

const InvoicingFeaturesAccordion = [
  [ 'one', <InvoicingMessages.One.Message />, <InvoicingMessages.OneExpanded.Message /> ],
  [ 'two', <InvoicingMessages.Two.Message />, <InvoicingMessages.TwoExpanded.Message /> ],
  [ 'three', <InvoicingMessages.Three.Message />, <InvoicingMessages.ThreeExpanded.Message /> ],
  [ 'four', <InvoicingMessages.Four.Message />, <InvoicingMessages.FourExpanded.Message /> ],
  [ 'five', <InvoicingMessages.Five.Message />, <InvoicingMessages.FiveExpanded.Message /> ],
  [ 'six', <InvoicingMessages.Six.Message />, <InvoicingMessages.SixExpanded.Message /> ],
  [ 'seven', <InvoicingMessages.Seven.Message />, <InvoicingMessages.SevenExpanded.Message /> ]
];

const PaymentsFeaturesAccordion = [
  [ 'one', <PaymentsMessages.One.Message />, <PaymentsMessages.OneExpanded.Message /> ],
  [ 'two', <PaymentsMessages.Two.Message />, <PaymentsMessages.TwoExpanded.Message /> ],
  [ 'three', <PaymentsMessages.Three.Message />, <PaymentsMessages.ThreeExpanded.Message /> ],
  [ 'four', <PaymentsMessages.Four.Message />, <PaymentsMessages.FourExpanded.Message /> ],
  [ 'five', <PaymentsMessages.Five.Message />, <PaymentsMessages.FiveExpanded.Message /> ],
  [ 'six', <PaymentsMessages.Six.Message />, <PaymentsMessages.SixExpanded.Message /> ],
  [ 'seven', <PaymentsMessages.Seven.Message />, <PaymentsMessages.SevenExpanded.Message /> ]
];

const SmallBusinessSubscriptionAdvert = () => {
  // Hooks
  GetRegistrationMetadataHooks.useProvideParams({ isOpenRegistration: true });

  const StatusIndicator = useStatusIndicator([ GetRegistrationMetadataHooks ]);

  const { userName } = useQueryParams(['userName']);

  const [ activeInvoicingSection, setActiveInvoicingSection ] = useState();
  const [ activePaymentsSection, setActivePaymentsSection ] = useState();

  // Render
  if (StatusIndicator) return <StatusIndicator />;

  return (
    <>
      <div className="text-center pad-40-bottom">
        <img src={SmallBusinessIcon} />
        <HeaderText className="pad-16-bottom" variant={HeaderTextVariants.Larger}>
          <Messages.Header.Message />
        </HeaderText>
        <BodyText color="darkGrey">
          <Messages.Subheader.Message />
        </BodyText>
      </div>
      <Border
        style={{
          borderRadius: 10,
          padding: 28,
          marginBottom: 28
        }}
      >
        <FlexGrid>
          <FlexColumn size={6}>
            <BulletedList bulletStyle={{ color: '#65BBEE' }} variant="checkmarks">
              <AutoBullet>
                <ReasonMessages.One.VpMessage />
                <ReasonMessages.Two.VpMessage />
                <ReasonMessages.Three.VpMessage />
                <ReasonMessages.Four.VpMessage />
                <ReasonMessages.Five.VpMessage />
              </AutoBullet>
            </BulletedList>
          </FlexColumn>
          <FlexColumn size={6} className="text-center">
            <HeaderText variant={HeaderTextVariants.PageHeader}>
              $19.99/mo.
            </HeaderText>
            <div className="pad-16-bottom">
              +Transaction Fees
            </div>
            {userName ? (
              <PathLink.Logon button={true} query={{ userName }}>
                <Messages.LogOn.Message />
              </PathLink.Logon>
            ) : (
              <PathLink.Registration.SmallBusinessRegistration button={true}>
                <Messages.SignUp.Message />
              </PathLink.Registration.SmallBusinessRegistration>
            )}
          </FlexColumn>
        </FlexGrid>
      </Border>
      <FlexGrid gutter={true}>
        <FlexColumn size={6}>
          <Border
            style={{ borderRadius: 10, padding: '0px 28px' }}
          >
            <div className="text-center pad-40-y">
              <img src={InvoicingIcon} />
              <HeaderText className="pad-8-bottom" variant={HeaderTextVariants.Larger}>
                <InvoicingMessages.Header.Message />
              </HeaderText>
              <BodyText color="darkGrey">
                <InvoicingMessages.Subheader.Message />
              </BodyText>
            </div>
            <Accordion
              className="smb-advert-invoicing"
              expandedSections={[ activeInvoicingSection ]}
              headerColor="none"
              onToggle={key => (
                setActiveInvoicingSection(prevKey => prevKey === key ? null : key)
              )}
              sections={InvoicingFeaturesAccordion}
            />
          </Border>
        </FlexColumn>
        <FlexColumn size={6}>
          <Border
            style={{ borderRadius: 10, padding: '0px 28px' }}
          >
            <div className="text-center pad-40-y">
              <img src={PaymentsIcon} />
              <HeaderText className="pad-8-bottom" variant={HeaderTextVariants.Larger}>
                <PaymentsMessages.Header.Message />
              </HeaderText>
              <BodyText color="darkGrey">
                <PaymentsMessages.Subheader.Message />
              </BodyText>
            </div>
            <Accordion
              className="smb-advert-payments"
              expandedSections={[ activePaymentsSection ]}
              headerColor="none"
              onToggle={key => (
                setActivePaymentsSection(prevKey => prevKey === key ? null : key)
              )}
              sections={PaymentsFeaturesAccordion}
            />
          </Border>
        </FlexColumn>
      </FlexGrid>
      <div className="text-center pad-40-top">
        <HeaderText className="pad-16-bottom" variant={HeaderTextVariants.Larger}>
          <Messages.FooterHeader.Message />
        </HeaderText>
        <BodyText color="darkGrey" className="pad-40-bottom">
          <Messages.FooterBody.Message />
        </BodyText>
        {userName ? (
          <PathLink.Logon button={true} query={{ userName }}>
            <Messages.LogOn.Message />
          </PathLink.Logon>
        ) : (
          <PathLink.Registration.SmallBusinessRegistration button={true}>
            <Messages.SignUp.Message />
          </PathLink.Registration.SmallBusinessRegistration>
        )}
      </div>
    </>
  );
};

export default SmallBusinessSubscriptionAdvert;