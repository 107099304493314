/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import getApplicationSetting, { AppSetting } from './getApplicationSetting';

const isInExternalEnvironment = (state, capability) => {
  const { isExternal } = getApplicationSetting(
    state,
    AppSetting.Environment,
    { notExpected: true }
  );

  return isExternal ?? false;
};

export default isInExternalEnvironment;