/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import createDropdown from './createDropdown';
import createListDropdown from './createListDropdown';
import createSearchDropdown from './createSearchDropdown';

export default (ToggleComponent, dropdownParams) => {
  const PlainDropdown = createDropdown(ToggleComponent, dropdownParams);
  const ListDropdown = createListDropdown(ToggleComponent, dropdownParams);
  const SearchDropdown = createSearchDropdown(ToggleComponent, dropdownParams);

  return class DropdownSwitch extends Component {
    static propTypes = {
      dropdownType: PropTypes.oneOf(['plain', 'list', 'search'])
    };

    static defaultProps = {
      dropdownType: 'plain'
    };

    constructor(props) {
      super(props);
      this.close = () => {
        if (this.dropdown) {
          this.dropdown.close();
        }
      };
      this.open = () => {
        if (this.dropdown) {
          this.dropdown.open();
        }
      };
    }

    render() {
      let {
        dropdownType,
        ...props
      } = this.props;
      const ref = r => this.dropdown = r;

      if (dropdownType === 'list') return <ListDropdown ref={ref} {...props} />;
      if (dropdownType === 'search') return <SearchDropdown ref={ref} {...props} />;
      if (dropdownType === 'plain') return <PlainDropdown ref={ref} {...props} />;

      return <span/>;
    }
  };
};
