/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { useLocation } from 'react-router-dom';
import { getPathname } from 'routes/utils';

const usePathname = () => {
  const location = useLocation();
  return getPathname({ location });
};

export default usePathname;