/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React from 'react';
import _ from 'lodash';
import classNames from 'classnames';

export default class VerticalMenuRenderer {
  constructor(props) {
    this.props = props;
  }

  renderMenu(items) {
    let flattenedItems = _.flatten(items);
    let menuItems = flattenedItems.map((menuItem, index) => {
      if (!menuItem) return null;
      return this.renderMenuItem(menuItem, index);
    });

    let classes = classNames(
      'VerticalMenuRenderer',
      { mobile: this.props.isMobile },
      this.props.className
    );

    return (
      <ul className={classes} >
        {menuItems}
      </ul>
    );
  }

  renderMenuItem(menuItem, index) {
    if (!menuItem) {
      throw new Error('Must pass in a "menuItem" instance');
    }

    let props = menuItem.props;

    if (props.divider) {
      const { divider, ...safeProps } = props;
      return (<li key={index} className={classNames('menuItem', 'divider', props.className)} {...safeProps}></li>);
    }

    let classes = classNames(
      'dropdownItem',
      {
        'selected': !!props.selected,
        'disabled': !!props.disabled
      },
      props.className
    );
    return (
      <li key={index} className={classes} onClick={props.disabled ? null : props.onClick}>
        {props.children}
      </li>
    );
  }
}
