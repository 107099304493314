/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React from 'react';
import { Route, Switch } from 'react-router';
import Terms from 'views/Terms';
import ViewpostControls from 'views/Terms/viewpostControls';
import { useParams } from 'react-router';
import { NotFoundRedirect } from './utils';

function TermsView() {
  const { type } = useParams();
  return <Terms type={type} />;
}

export default ({ match }) => (
  <Switch>

    {/* Special hard coded info page for compliance */}
    <Route path={`${match.url}/vp-controls`} exact={true}>
      <ViewpostControls />
    </Route>

    <Route path={`${match.url}`} exact={true}>
      <Terms type="TermsOfUse" />
    </Route>
    <Route path={`${match.url}/privacy`} exact={true}>
      <Terms type="PrivacyPolicy" />
    </Route>
    <Route path={`${match.url}/payment`} exact={true}>
      <Terms type="PaymentTerms" />
    </Route>
    <Route path={`${match.url}/consumer-payment`} exact={true}>
      <Terms type="ConsumerPaymentTerms" />
    </Route>
    <Route path={`${match.url}/cass-terms`} exact={true}>
      <Terms type="CassTerms" />
    </Route>
    <Route path={`${match.url}/eula`} exact={true}>
      <Terms type="EndUserLicenseAgreement" />
    </Route>

    {/* One route to support types dynamically (used by emails) */}
    <Route path={`${match.url}/:type`} component={TermsView} />
    {NotFoundRedirect}
  </Switch>
);
