/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { createEntity, createEntityReference } from 'schemas/state';
import { DocumentStoreKVP } from 'schemas/common/documentStore';

export function documentStoreKvpRequestMapper(apiParams) {
  return {
    value: JSON.stringify(apiParams.value)
  };
}

export function getDocumentStoreResponseMapper(apiDocumentStoreResponse, state, apiParams, api) {
  let entities = [];
  let references = [];

  try {
    if (apiDocumentStoreResponse && apiDocumentStoreResponse.value) {
      let payload = JSON.parse(apiDocumentStoreResponse.value);
      entities.push(createEntity(
        `${apiParams.namespace}/${apiParams.key}`,
        DocumentStoreKVP.meta.name,
        {
          value: payload
        }
      ));
      references.push(createEntityReference(
        `${apiParams.namespace}/${apiParams.key}`,
        DocumentStoreKVP.meta.name
      ));
    }
  } catch (e) {
    console.warn(e);
  }


  return {
    entities,
    references
  };
}

export function documentStoreKvpErrorMapper(response) {
  if (response.statusCode === 404) {
    return true;
  }
  return false;
}
