/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, { useRef } from 'react';
import useIsMobile from 'hooks/browser/useIsMobile';
import LoadingIndicator from 'components/LoadingIndicator';

import Button from 'components/Button';

import SignAccount from '../SignAccount';

function PlaidAccount({
  /* from Plaid Connector */
  hasPlaidLoadFailed,
  isPlaidLoading,
  onOpenPlaid,
  plaidAccount,

  isReceiptAccount,
  hasTaxId,
  hasBankAccount,
  isPersonalAccount,

  onSubmit
}) {
  const isMobile = useIsMobile();
  const signRef = useRef();

  const isValidPlaidAccount = plaidAccount?.accountType === 'checking';
  const submit = () => {
    // validate
    const signValue = signRef?.current?.getValue();
    if (signValue && isValidPlaidAccount) {
      onSubmit({
        publicToken: plaidAccount?.publicToken,
        accountId: plaidAccount?.accountId,
        taxIdNumber: signValue.taxIdNumber,
        signer1: signValue.signer1,
        signatureImage: signValue.signatureImage,
        accountName: signValue.accountName
      });
    }
  };

  if (isPlaidLoading) {
    return <LoadingIndicator />;
  }

  if (hasPlaidLoadFailed) {
    return <div className="vp-error">
      Bank log in is not available at this time.
    </div>;
  }

  if (!plaidAccount) {
    return null;
  }

  return (
    <div>
      <div style={{
        backgroundColor: 'white',
        border: isValidPlaidAccount
          ? '1px solid #ddd'
          : '2px solid #ff553d',
        borderRadius: '2px',
        marginTop: '15px',
        padding: '20px',
        display: isMobile ? 'block' : 'table',
        width: '100%'
      }}>
        <div style={{ display: isMobile ? 'block' : 'table-cell' }}>
          <div className="vp-label">
            Bank
          </div>
          <div>
            <strong>{plaidAccount?.bankName}</strong>
          </div>
        </div>
        <div style={{ display: isMobile ? 'block' : 'table-cell' }}>
          <div className="vp-label">
            Account
          </div>
          <div>
            <strong>{plaidAccount?.accountName}</strong>
            &nbsp;&nbsp;|&nbsp;&nbsp;
            {plaidAccount?.accountType} account
          </div>
          {plaidAccount?.accountType !== 'checking'
            ? <div className="vp-error">
              Only checking accounts can be used. Please select a different account.
            </div>
            : null}
        </div>
        <div style={{
          display: isMobile ? 'block' : 'table-cell',
          textAlign: 'right',
          verticalAlign: 'middle',

          position: isMobile ? 'absolute' : null,
          top: isMobile ? '15px' : null,
          right: isMobile ? '15px' : null
        }}>
          <a onClick={onOpenPlaid}>
            Change
          </a>
        </div>
      </div>

      <SignAccount ref={signRef}
        accountName={plaidAccount?.accountName}
        includeTaxId={!hasTaxId}
        isReceiptAccount={isReceiptAccount}
        isPersonalAccount={isPersonalAccount}
        hasBankAccount={hasBankAccount} />

      <div style={{ textAlign: 'right', paddingTop: '30px' }}>
        <Button onClick={submit}>Add This Account</Button>
      </div>
    </div>
  );
}

export default PlaidAccount;