/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import t from 'tcomb-validation';

import { State } from 'schemas/state';

export default store => next => (action) => {
  // run reducer pipeline
  const result = next(action);

  // validate that the state is still adhereing to the schema
  let state = store.getState();
  let validationResult = t.validate(state, State);

  if (validationResult.errors.length > 0) {
    console.error(`App State does not validate against schema with action: ${action.type}`, validationResult);
  }

  return result;
};
