/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, { useRef, useState } from 'react';
import useIsMobile from 'hooks/browser/useIsMobile';
import FormJSX, { FieldOption } from 'components/Form/FormJSX';
import MaskedField from 'components/Form/Inputs/MaskedTextBox';
import FlagIcon from 'components/Icon/FlagIcon';

import LoadingSpinner from 'components/LoadingSpinner';
import Icon, { IconTypes } from 'components/Icon';
import t from 'tcomb-validation';
import { createBoundedLengthNumberType, NumericString } from 'schemas/common/number';
import { createRegexType } from 'schemas/common/string';

import Button from 'components/Button';

import Tooltip from 'components/Tooltip';
import CheckIcon from 'images/icons/check-icon.png';
import USExample from 'images/icons/routing-number-US.png';
import CANExample from 'images/icons/routing-number-CAN.png';

import SignAccount from '../SignAccount';
import connector from './index.connect';

const createRoutingNumberSchema = (routingNumberValid, country = 'US') => {
  let base = country === 'US' ? NumericString : t.String;
  let RoutingNumber = t.subtype(base, (value) => {
    return value.length === 9 && routingNumberValid;
  }, 'RoutingNumber');

  RoutingNumber.getValidationErrorMessage = (value, path, context) => {
    if (value && value.length !== 9) {
      return 'Routing number must be 9 characters';
    }
    if (!routingNumberValid) {
      return 'Routing number is not valid';
    }

    return '';
  };

  return RoutingNumber;
};

const createMatchingAccountNumber = (value1) => {
  let MatchingAccountNumber = t.subtype(t.String, (value2) => {
    return value1 === value2;
  }, 'MatchingAccountNumber');

  MatchingAccountNumber.getValidationErrorMessage = (value2, path, context) => {
    if (value1 !== value2) {
      return 'Account Number does not match.';
    }

    return '';
  };

  return MatchingAccountNumber;
};


function ManualAccount({
  isCadAllowed,

  isRoutingNumberLoading,
  isRoutingNumberValid,
  routingBankName,
  validateRoutingNumber,

  isReceiptAccount,
  isPersonalAccount,
  hasTaxId,
  hasBankAccount,

  onSubmit
}) {
  const isMobile = useIsMobile();
  const manualRef = useRef();
  const signRef = useRef();
  const [value, setValue] = useState({
    currency: 'USD'
  });

  // convert account currency code to standard country code
  let country = value?.currency === 'CAD' ? 'CA' : 'US';

  let bankName = isRoutingNumberLoading || value?.routingNumber?.length !== 9
    ? null
    : routingBankName;

  const changeManualForm = (v) => {
    let nextCountry = v?.currency === 'CAD' ? 'CA' : 'US';
    if (v?.routingNumber?.length === 9) {
      if (v?.routingNumber !== value?.routingNumber || v.currency !== value?.currency) {
        // dynamically verify routing numbers on the fly with the server
        validateRoutingNumber(v.routingNumber, nextCountry);
      }
    }
    setValue(v);
  };

  const submit = () => {
    const manualValue = manualRef?.current?.getValue();
    const signValue = signRef?.current?.getValue();
    if (manualValue && signValue) {
      onSubmit({
        routingNumber: manualValue?.routingNumber,
        accountNumber: manualValue?.accountNumber,
        currency: manualValue?.currency,
        taxIdNumber: signValue.taxIdNumber,
        signer1: signValue.signer1,
        signatureImage: signValue.signatureImage,
        accountName: signValue.accountName
      });
    }
  };

  /*
   * Build Schema
   */
  let currencies = {
    'USD': isMobile
      ? 'USD'
      : <div><FlagIcon country="US" /> USD</div>
  };
  if (isCadAllowed) {
    currencies.CAD = isMobile
      ? 'CAD'
      : <div><FlagIcon country="CA" /> CAD</div>;
  }

  const schema = {
    currency: t.enums(currencies),
    routingNumber: createRoutingNumberSchema(isRoutingNumberValid, country),
    accountNumber: country === 'CA'
      ? createRegexType(/^[0-9-]{1,17}$/, 'Numbers and dashes only')
      : createBoundedLengthNumberType(0, 17),
    verifyAccountNumber: createMatchingAccountNumber(value?.accountNumber)
  };

  /*
    Per Garrett, we're also not going to collect tax id for CAD receive only accounts
    (since we can't use our fraud check service for them)
  */
  let excludeTaxId = hasTaxId
    || (country === 'CA' && isReceiptAccount);

  return (
    <div>
      <FormJSX ref={manualRef}
        modelType={t.struct(schema)}
        onChange={v => changeManualForm(v)}
        value={value}>
        <FieldOption name="currency"
          label="Currency"
          disabled={!isCadAllowed}
          cols={2} />

        {/* Routing Number Example  */}
        <div cols={1} style={{paddingTop: '25px', cursor: 'pointer'}}>
          <Tooltip trigger={['click']} wide={true}
            overlay={<div>
              <div><FlagIcon country={country} includeName={true} /> Check</div>
              <img src={country === 'CA' ? CANExample : USExample}
                width={isMobile ? '320px' : '400px'} />
            </div>}>
            <div style={{ display: 'table'}}>
              <div style={{display: 'table-cell', verticalAlign: 'middle', height: '30px'}}>
                <img src={CheckIcon} width="23px" height="10px" style={{ marginTop: '2px' }} />
                <Icon type={IconTypes.Info} />
              </div>
            </div>
          </Tooltip>
        </div>

        <FieldOption name="routingNumber"
          type="text"
          rightLabel={isRoutingNumberLoading
            ? <div style={{ width: '15px', height: '15px' }}><LoadingSpinner /></div>
            : null}
          label="Routing Number"
          placeholder={country === 'CA'
            ? '12345-123 or 012312345'
            : 'e.g. 123456789'}
          footerLabel={<div>
            {!bankName ? null
              : (
                <div style={{
                  border: '1px solid #ccc',
                  padding: '2px 5px',
                  backgroundColor: '#ecf7fd'
                }}>
                  <Icon type={IconTypes.Bank} style={{ color: '#999' }} size={12} />&nbsp;
                  Bank: <strong>{bankName}</strong>
                </div>
              )}
          </div>}
          cols={3} />

        <FieldOption name="accountNumber"
          label="Account Number"
          cols={3}
          inputComponent={MaskedField}
        />
        <FieldOption name="verifyAccountNumber"
          cols={3}
          label="Verify Account Number"
          inputComponent={MaskedField}
        />
      </FormJSX>

      <SignAccount ref={signRef}
        includeTaxId={!excludeTaxId}
        isReceiptAccount={isReceiptAccount}
        isPersonalAccount={isPersonalAccount}
        hasBankAccount={hasBankAccount} />

      <div style={{textAlign: 'right', paddingTop: '30px'}}>
        <Button onClick={submit}>Add This Account</Button>
      </div>
    </div>
  );
}

export default connector(ManualAccount);