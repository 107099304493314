/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { HIGHLIGHT_ACTION_TYPE } from 'actions/highlights';
import { newState } from './utils';


export default (state = {}, action) => {
  if (action.type === HIGHLIGHT_ACTION_TYPE) {
    return newState(state, {
      [action.payload.viewKey]: action.payload.isOn ? action.payload.itemKeys : null
    });
  }

  return state;
};
