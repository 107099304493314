/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { isEqual } from 'lodash';

import {
  areApiResultEntitiesUpdated,
  getApiResultError,
  getApiResultIsSuccessful,
  getApiResultRequestParams,
  getEntryApiResult,
  isApiResultLoading,
  isEntryApiResultUpdated
} from 'api/core/utils.state';

export const getCalculateShouldCallState = (state, entryApiResultId, getConfig) => {
  const entryApiResult = getEntryApiResult(state, entryApiResultId);
  const { id: currentApiResultId } = (entryApiResult || {}).current || {};

  return {
    hasError: !!getApiResultError(state, currentApiResultId, getConfig),
    isLoading: isApiResultLoading(state, currentApiResultId, getConfig),
    isSuccessful: getApiResultIsSuccessful(state, currentApiResultId, getConfig),
    isUpdated: isEntryApiResultUpdated(state, entryApiResultId)
      || areApiResultEntitiesUpdated(state, currentApiResultId, getConfig),
    lastRequestParams: getApiResultRequestParams(state, currentApiResultId)
  };
};

// The first parameter should be the result of getCalculateShouldCallState,
// The third parameter controls what qualifies what parts of the state should be
// considered when checking if the call should be made
// Valid Values
//   true, falsy values - All checks are enabled
//   false - Immediately returns false
//   Object: key -> boolean {
//     default - the value to fallback to if a flag below is not set.
//       Allows for all checks to be disabled by default
//     isLoading,
//     isUpdated,
//     paramsChanged
//   }
const calculateShouldCall = (
  {
    hasError,
    isLoading,
    isUpdated,
    lastRequestParams
  },
  requestParams,
  originalEnabledChecks
) => {
  if (originalEnabledChecks === false) return false;

  const {
    default: defaultValue,
    isLoading: checkIsLoading,
    isUpdated: checkIsUpdated,
    paramsChanged: checkParamsChanged
  } = (originalEnabledChecks === true || !originalEnabledChecks)
    ? {}
    : originalEnabledChecks;

  const shouldCheck = (value) => {
    if (value == null) return defaultValue != null ? defaultValue : true;
    return value;
  };

  // We don't need to call if the call is already being made. This should be tied as close to if
  // the API call is in flight as possible.
  // JL Note: This could be made oh so slightly more interesting if it were to compare the
  // in-flight request params to the calculated request params and evaluate to true if there are
  // new params so that another request is made with the new params. We'd need to make sure the
  // previous call goes the way of the dinosaur, but this would make this function more accurate
  // and helpful.
  if (shouldCheck(checkIsLoading) && isLoading) return false;

  // This is slightly interesting - if you have an error with the previous call, if your request
  // params change, we will try to call again.
  // This currently doesn't have a place in the shouldCheck system as it so rarely happens
  if (lastRequestParams && hasError && isEqual(requestParams, lastRequestParams)) return false;

  // If there is an update to the entities or the request params change, make the call.
  return (shouldCheck(checkIsUpdated) && isUpdated)
    || (shouldCheck(checkParamsChanged) && !isEqual(requestParams, lastRequestParams));
};

export default calculateShouldCall;