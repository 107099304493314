/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React from 'react';
import moment from 'moment';
import useIsPortalUser from 'hooks/identity/useIsPortalUser';
import connect from './index.connect';
import Messages from './index.messages';

const CountdownSubscribeLink = ({
  defaultLabel,
  isInFreeTrial,
  nextBillingDate
}) => {
  const isPortalUser = useIsPortalUser();

  if (!isInFreeTrial || isPortalUser) {
    return defaultLabel;
  }

  const daysDiff = moment(nextBillingDate).diff(moment().startOf('day'), 'days');

  return <Messages.Link.Message days={daysDiff} />;
};

export default connect(CountdownSubscribeLink);
