/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, { Component } from 'react';
import FatalErrorLayout from 'layouts/FatalError';

import './serverError.scss';

export default class ServerError extends Component {
  static pageTitle = 'Internal Server Error - Viewpost';

  constructor(props) {
    super(props);
  }

  render() {
    return (
      <FatalErrorLayout className="view-error500 gold">
        <p className="error-code">
          <strong>500:</strong> Internal Server Error
        </p>
        <h1>Well, this is embarrassing.</h1>
        <p className="error-message">
          <strong>Something’s wonky with our server.</strong>
          <br />
          Excuse us while we get right on that.
          We apologize for the inconvenience.
          Please come back soon.
        </p>

        <div className="ernie"></div>
      </FatalErrorLayout>
    );
  }
}
