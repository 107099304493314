/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import t from 'tcomb-validation';

import { defineSchema } from 'schemas';

// attempt at a contract for determining different landing page scenarios
export const SecureTokenScenario = t.enums({
  InvoiceOnboarding: 'InvoiceOnboarding',
  InvoicePayAndOnboard: 'InvoicePayAndOnboard',
  InvoiceLanding: 'InvoiceLanding',
  InvoiceLandingUnconnected: 'InvoiceLandingUnconnected',
  InvoiceLandingRoutingApproval: 'InvoiceLandingRoutingApproval'
});

export const SecureTokenContextModel = defineSchema({
  token: t.maybe(t.String),
  context: t.maybe(t.String),
  data: t.maybe(t.Object),
  isValid: t.maybe(t.Boolean),
  state: t.maybe(t.String)
}, 'SecureTokenContext');

export const InvoiceOnboardingData = defineSchema({
  approvalRequests: t.list(t.String), // List of IDs
  hasOutOfBandInvoices: t.Boolean,
  invoices: t.list(t.struct({
    id: t.String,
    type: t.String
  })),
  // some indicator of the scenario/source in which the token was created
  // TODO: see if there's a better way to deal with these?
  scenario: t.maybe(SecureTokenScenario)
}, 'InvoiceOnboardingData');

export const AchPlusInvitationData = defineSchema({
  paymentCount: t.Number,
  pendingPaymentAmount: t.Number
}, 'AchPlusInvitationData');