/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

const mapSecurityQuestions = ({
  securityQuestions
}) => securityQuestions?.map(
  ({
    id,
    question
  }) => ({
    id,
    question
  })
);

export default mapSecurityQuestions;