/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { createEntityReference, getEntity, getEntityList } from 'schemas/state';
import { CompanyFeeSettings } from 'schemas/network/session';
import { filterForMicroDeposits, filterForValidAccounts } from 'views/Settings/utils';
import logError from 'services/ErrorService';

export const hasPendingPaymentAccount = state => !!((filterForMicroDeposits(
  getEntityList(state, state.session.bankAccounts)) || []).length);

export const hasActivePaymentAccount = state => !!((filterForValidAccounts(
  getEntityList(state, state.session.bankAccounts)) || []).length);

export const getCurrentFeeSettings = (state) => {
  const feeSettings = getEntity(state, createEntityReference('current', CompanyFeeSettings.meta.name));

  if (!feeSettings) {
    logError('Expected feeSettings, but feeSettings is missing.');
  }

  return feeSettings || {};
};
