/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { isFunction } from 'lodash';

import { endWorkflow, goToStep as goToStepAction } from 'actions/workflow';

function MapStateToProps(state, props) {
  let workflowState = props.workflowId ? state.workflow[props.workflowId] : state.workflow;
  return {
    step: workflowState ? workflowState.step : null,
    complete: workflowState ? workflowState.complete : false,
    finished: workflowState ? workflowState.finished : false
  };
}

function MapDispatchToProps(dispatch, props) {
  let goToStep = (step, id) => {
    if (props.onStep) {
      props.onStep(step);
    }
    return goToStepAction(step, id);
  };
  return {
    endWorkflow: id => dispatch((endWorkflow(id))),
    goToStep: (step, id) => dispatch(goToStep(step, id)),
    onAction: (actionConfig, actionParams, id) => {
      let stepAction = goToStep(actionConfig.step, id);
      if (actionConfig.action) {
        const actionResult = actionConfig.action(stepAction, actionParams);
        // Assume if the result is a function, it is a thunk that needs to be dispatched.
        if (isFunction(actionResult)) {
          dispatch(actionResult);
        }
      } else {
        if (actionConfig.callback) {
          actionConfig.callback(actionParams, () => dispatch(stepAction));
        } else {
          dispatch(stepAction);
        }
      }
    }
  };
}

export {
  MapStateToProps,
  MapDispatchToProps
};
