/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

function addressLineIncludesCompanyName(line, companyName) {
  if (!companyName || !line) {
    return false;
  }

  // only handled case now is the company name being embedded exactly as a line
  // https://wiki:8443/display/VPDesign/Display+addresses+consistently
  return companyName.toLowerCase() === line.toLowerCase();
}

function mapApiToAddress(apiAddress, companyName) {
  if (!apiAddress) {
    return null;
  }

  let lines = [];
  let linesWithoutAddressee = [];
  for (let i = 1; i <= 4; i++) {
    let line = apiAddress[`line${i}`] || apiAddress[`address${i}`];
    if (line) {
      lines.push(line);
      if (!addressLineIncludesCompanyName(line, companyName)) {
        linesWithoutAddressee.push(line);
      }
    }
  }

  return {
    lines: lines,
    city: apiAddress.city,
    stateProvince: apiAddress.stateProvince,
    postalCode: apiAddress.postalCode,
    country: apiAddress.country,
    linesWithoutAddressee
  };
}

export default mapApiToAddress;