/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import './index.scss';

/**
 * Small loading spinner. Originally intended for buttons.
 */
const LoadingSpinner = ({
  children,
  onDark,
  style
}) => (
  <div
    className={classNames(
      'component-loading-spinner',
      {'on-dark': onDark}
    )}
    style={style}
  >
    {children}
  </div>
);

LoadingSpinner.propTypes = {
  onDark: PropTypes.bool
};

export default LoadingSpinner;
