/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */
import { defineMessages } from 'react-intl';

export default defineMessages({
  CurrentOrFutureDate: {
    id: 'ValidationError.Date.CurrentOrFutureDate',
    defaultMessage: 'The date must be today or in the future.'
  },
  SameOrFutureDate: {
    id: 'ValidationError.Date.CurrentOrFutureDate',
    defaultMessage: 'The date must be on or after {date}'
  },
  MinDateExceeded: {
    id: 'ValidationError.Date.MinDateExceeded',
    defaultMessage: 'The date must be on or after {date}'
  },
  MaxDateExceeded: {
    id: 'ValidationError.Date.MaxDateExceeded',
    defaultMessage: 'The date must be on or before {date}'
  },
  BoundedYear: {
    id: 'ValidationError.Date.YearOutOfRange',
    defaultMessage: 'Enter a year between {lowerBound} and {upperBound}'
  },
  SameOrBeforeDate: {
    id: 'ValidationError.Date.SameOrBeforeDate',
    defaultMessage: 'The date must be on or before {date}.'
  },
  CurrentOrPastDate: {
    id: 'ValidationError.Date.CurrentOrPastDate',
    defaultMessage: 'The date must be today or in the past.'
  }
});
