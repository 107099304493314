/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React from 'react';
import HeaderText from 'components/HeaderText';
import BodyText, { BodyTextVariants } from 'components/BodyText';
import BulletedList, { AutoBullet } from 'components/BulletedList';
import Messages, {
  AsCustomerMessages,
  AsCustomerOnboardMessages,
  AsVendorMessages,
  AsVendorOnboardMessages
} from './index.messages';

const getMessages = ({
  canReceivePaymentsIntoBank,
  canSendInvoicesToCustomer,
  target,
  isOnboarding
}) => {
  const isCustomer = target === 'Customer';

  if (isOnboarding) {
    return isCustomer ? {
      instructions: <AsCustomerOnboardMessages.Instructions.Message />,
      messages: (
        <>
          <AsCustomerOnboardMessages.Reason1.Message />
          <AsCustomerOnboardMessages.Reason2.Message />
          <AsCustomerOnboardMessages.Reason3.Message />
          <AsCustomerOnboardMessages.Reason4.Message />
        </>
      )
    } : {
      instructions: canReceivePaymentsIntoBank
        ? <AsVendorOnboardMessages.Instructions.Message />
        : <AsVendorOnboardMessages.InstructionsWithoutElectonicPayments.Message />,
      messages: (
        <>
          <AsVendorOnboardMessages.Reason1.Message />
          {canReceivePaymentsIntoBank
            ? <AsVendorOnboardMessages.Reason2.Message />
            : null}
          {canSendInvoicesToCustomer
            ? <AsVendorOnboardMessages.Reason3.Message />
            : null}
          <AsVendorOnboardMessages.Reason4.Message />
        </>
      )
    };
  }

  return isCustomer ? {
    instructions: <AsCustomerMessages.Instructions.Message />,
    messages: (
      <>
        <AsCustomerMessages.Reason1.Message />
        <AsCustomerMessages.Reason2.Message />
        <AsCustomerMessages.Reason3.Message />
        <AsCustomerMessages.Reason4.Message />
      </>
    )
  } : {
    instructions: canReceivePaymentsIntoBank
      ? <AsVendorMessages.Instructions.Message />
      : <AsVendorMessages.InstructionsWithoutElectonicPayments.Message />,
    messages: (
      <>
        {canReceivePaymentsIntoBank
          ? <AsVendorMessages.Reason1.Message />
          : null}
        {canSendInvoicesToCustomer
          ? <AsVendorMessages.Reason2.Message />
          : null}
        {canSendInvoicesToCustomer
          ? <AsVendorMessages.Reason3.Message />
          : null}
        <AsVendorMessages.Reason4.Message />
      </>
    )
  };
};

const WelcomeContent = ({
  canReceivePaymentsIntoBank,
  canSendInvoicesToCustomer,
  children,
  companyName,
  variant,
  subvariant,
  target
}) => {
  const isOnboarding = variant === 'onboarding';
  const isOnboardingSidebar = isOnboarding && subvariant === 'sidebar';

  const TargetMessages = getMessages({
    canReceivePaymentsIntoBank,
    canSendInvoicesToCustomer,
    target,
    isOnboarding
  });

  const reasons = (
    <BulletedList variant="checkmarks" subVariant="large">
      <AutoBullet>
        {TargetMessages.messages}
      </AutoBullet>
    </BulletedList>
  );

  return (
    <>
      <div>
        <HeaderText>
          {isOnboarding ? (
            <Messages.UnconnectedHeader.Message
              companyName={companyName}
              relationship={target}
            />
          ) : (
            <Messages.Header.Message
              companyName={companyName}
              relationship={target}
            />
          )}
        </HeaderText>
        <BodyText style={{ paddingTop: 8 }}>
          {TargetMessages.instructions}
        </BodyText>
      </div>
      <div style={{ paddingTop: 20, paddingBottom: 20 }}>
        {reasons}
      </div>
      {children}
      <BodyText variant={BodyTextVariants.Smallest}>
        {isOnboardingSidebar ? (
          <Messages.UnconnectedFooter.Message
            companyName={companyName}
            relationship={target}
          />
        ) : (
          <Messages.Footer.Message
            companyName={companyName}
            relationship={target}
          />
        )}
      </BodyText>
    </>
  );
};

export default WelcomeContent;
