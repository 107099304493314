/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, { Component } from 'react';
import { isNil } from 'lodash';
import t from 'tcomb-validation';
import EnterBankInformation from './index';
import Messages from './index.messages';

const BaseBankInformationType = t.struct({
  mode: t.maybe(t.String),
  manualPaymentAccount: t.maybe(t.struct({
    routingNumber: t.String,
    verifyAccountNumber: t.String,
    accountNumber: t.String
  })),
  plaidPaymentAccount: t.maybe(t.struct({
    publicToken: t.String,
    accountId: t.String
  })),
  completePaymentAccount: t.maybe(t.struct({
    authorizedSigner: t.String,
    taxPayerId: t.String,
    signature: t.String
  }))
});

const isValid = value => !isNil(value)
  && (!isNil(value.manualPaymentAccount || value.plaidPaymentAccount) || value.mode === 'skipped');

const BankInformationType = t.refinement(
  BaseBankInformationType,
  value => isValid(value)
);

BankInformationType.getValidationErrorMessage = (value, path, context) => {
  if (context && context.intl) {
    if (!isValid(value)) {
      return context.intl.formatMessage(Messages.SelectOptionFailure);
    }
  }
};

export { BankInformationType };

class EnterBankInformationFormInputShim extends Component {
  render() {
    const {
      onChange,
      ...props
    } = this.props;

    return (
      <EnterBankInformation {...props}
        onChange={value => onChange(value)}
      />
    );
  }
}

export default EnterBankInformationFormInputShim;
