/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, { useEffect, useMemo, useRef, useState } from 'react';
import t from 'tcomb-validation';

import selfEnrollInVcc from 'api/onboarding/selfEnrollInVcc';

import Button from 'components/Button';
import BodyText from 'components/BodyText';
import BulletedList, { AutoBullet } from 'components/BulletedList';
import TextBox from 'components/Form/Inputs/TextBox';
import FormV2, { FieldInput, FieldOption, IfFieldDefined } from 'components/Form/V2';
import HeaderText, { HeaderTextVariants } from 'components/HeaderText';
import ImageTextLargeButton from 'components/ImageTextLargeButton';
import { FlexColumn, FlexGrid } from 'components/Viewstrap/Grid';

import { PaymentSupport } from 'config/messageLinks';

import createExecuteApiResultHooks from 'hooks/apiResult/createExecuteApiResultHooks';
import useFormatMessage from 'hooks/intl/useFormatMessage';
import useQueryParams from 'hooks/location/useQueryParams';
import useFuncRef from 'hooks/react/useFuncRef';
import usePropRef from 'hooks/react/usePropRef';
import { GetSecureTokenDataHooks } from 'hooks/registration';

import GreenCheckmark from 'public/images/icons/green-checkmark.svg';

import createMatchingEmail from 'schemas/common/email/createMatchingEmail';
import Email from 'schemas/common/email/Email';
import { Phone } from 'schemas/common/phone';
import { createMaxLengthStringType } from 'schemas/common/string';
import Truthy from 'schemas/common/truthy';

import CrossMailCircleImage from './crossMailCircle.svg';
import Messages, {
  AboutMessages,
  AssistedFaqMessages,
  ConfirmationMessages,
  FaqMessages,
  FormMessages
} from './index.messages';
import MoneyMailCircleImage from './moneyMailCircle.svg';

const SelfEnrollHooks = createExecuteApiResultHooks({
  endpoint: selfEnrollInVcc
});

const VccSelfEnrollment = () => {
  // Hooks
  const formatMessage = useFormatMessage();

  const [ flow, setFlow ] = useState();
  const [ selectedFlow, setSelectedFlow ] = useState();

  const { emailAddress } = useQueryParams([ 'emailAddress' ]);

  const {
    data: {
      companyName,
      customerCompany,
      isEnrolledInVcc,
      vccPaymentTerms
    },
    token: secureToken
  } = GetSecureTokenDataHooks.useContext() ?? {};

  const formRef = useRef();
  const [ formState, setFormState ] = useState({
    companyName,
    email: emailAddress
  });

  useEffect(
    () => {
      // Reset value when done
      if (!flow) return;
      setSelectedFlow();
    },
    [ flow ]
  );

  const { current: [ selectDirectFlow, selectAssistedFlow ] } = useFuncRef(
    () => [
      () => setSelectedFlow('direct'),
      () => setSelectedFlow('assisted')
    ]
  );

  const { current: [ setDirectFlow, setAssistedFlow ] } = useFuncRef(
    () => [
      () => setFlow('direct'),
      () => setFlow('assisted')
    ]
  );

  const selectedFlowRef = usePropRef(selectedFlow);
  const { current: onSetFlow } = useFuncRef(
    () => () => {
      if (selectedFlowRef.current === 'direct') {
        setDirectFlow();
      } else if (selectedFlowRef.current === 'assisted') {
        setAssistedFlow();
      }
    }
  );

  const formSchema = useMemo(
    () => {
      const schema = {
        companyName: t.Any,
        firstName: createMaxLengthStringType(50),
        lastName: createMaxLengthStringType(50),
        title: createMaxLengthStringType(50),
        phone: Phone,
        email: Email,
        verifyEmail: createMatchingEmail(formState.email),
        acceptTerms: Truthy
      };

      if (flow === 'assisted') {
        Object.assign(schema, {
          paymentInstructions: createMaxLengthStringType(1000)
        });
      }

      return t.struct(schema);
    },
    [ flow, formState.email ]
  );

  const executeSelfEnroll = SelfEnrollHooks.useExecuteRequest();
  const isEnrolling = SelfEnrollHooks.useIsLoading();

  const flowRef = usePropRef(flow);
  const { current: onSubmit } = useFuncRef(
    () => async () => {
      const value = formRef.current.getValue();

      if (!value) return;

      const {
        firstName,
        lastName,
        phone,
        email,
        paymentInstructions,
        title
      } = value;

      await executeSelfEnroll({
        contactTitle: title,
        secureToken,
        firstName,
        lastName,
        phone,
        email,
        isAssistedPay: flowRef.current === 'assisted',
        paymentInstructions
      });
    }
  );

  if (isEnrolledInVcc) {
    return (
      <div className="text-center pad-20 border">
        <img src={GreenCheckmark} />
        <HeaderText bold={false} className="pad-8-y" variant={HeaderTextVariants.Medium}>
          <ConfirmationMessages.Header.Message />
        </HeaderText>
        <BodyText>
          {formState.email ? (
            <ConfirmationMessages.InstructionsBeingSent.Message
              email={formState.email}
              followUp={(
                <ConfirmationMessages.InstructionsFollowUp.Message
                  supportLink={PaymentSupport}
                />
              )}
            />
          ) : (
            <ConfirmationMessages.InstructionsSent.Message
              followUp={(
                <ConfirmationMessages.InstructionsFollowUp.Message
                  supportLink={PaymentSupport}
                />
              )}
            />
          )}
        </BodyText>
      </div>
    );
  }

  // Render
  if (!flow) {
    return (
      <>
        <HeaderText className="text-center">
          <Messages.SelectFlowHeader.Message />
        </HeaderText>
        <BodyText className="text-center pad-20-y">
          <Messages.SelectFlowSubheader.Message />
        </BodyText>
        <div className="pad-20-y" style={{ width: 430, margin: '0 auto' }}>
          <FlexGrid gutter={true}>
            <FlexColumn size={6}>
              <ImageTextLargeButton
                imgSrc={MoneyMailCircleImage}
                onClick={selectDirectFlow}
                selected={selectedFlow === 'direct'}
                skinny={true}
                style={{ height: 'auto' }}
              >
                YES, email is fine.
              </ImageTextLargeButton>
            </FlexColumn>
            <FlexColumn size={6}>
              <ImageTextLargeButton
                imgSrc={CrossMailCircleImage}
                onClick={selectAssistedFlow}
                selected={selectedFlow === 'assisted'}
                skinny={true}
                style={{ height: 'auto' }}
              >
                NO, assistance is required.
              </ImageTextLargeButton>
            </FlexColumn>
          </FlexGrid>
          <Button
            expand={true}
            isDisabled={!selectedFlow}
            onClick={onSetFlow}
          >
            Continue
          </Button>
        </div>
        <BodyText className="text-center pad-20-y">
          <Messages.SelectFlowFooter.Message />
        </BodyText>
      </>
    );
  }

  return (
    <>
      <HeaderText
        className="text-center"
        variant={HeaderTextVariants.Larger}
      >
        <Messages.Header.Message />
      </HeaderText>
      <BodyText className="text-center pad-20-y">
        <Messages.Subheader.Message
          companyName={customerCompany}
        />
      </BodyText>
      <FormV2
        display="flex"
        modelType={formSchema}
        onChange={setFormState}
        ref={formRef}
        value={formState}
      >
        <FlexGrid
          className="pad-20-y"
          gutter={true}
        >
          <FieldOption
            cols={6}
            disabled={true}
            label={<FormMessages.CompanyName.Message />}
            name="companyName"
          >
            <TextBox
              disabled={true}
            />
          </FieldOption>
          <FieldOption
            cols={3}
            label={<FormMessages.FirstName.Message />}
            name="firstName"
          />
          <FieldOption
            cols={3}
            label={<FormMessages.LastName.Message />}
            name="lastName"
          />
          <FieldOption
            cols={6}
            label={<FormMessages.Title.Message />}
            name="title"
          />
          <FieldOption
            cols={6}
            label={<FormMessages.Phone.Message />}
            name="phone"
          />
          <FieldOption
            cols={6}
            label={flow === 'direct'
              ? <FormMessages.PaymentInstructionsEmail.Message />
              : <FormMessages.Email.Message />}
            name="email"
          />
          <FieldOption
            cols={6}
            label={<FormMessages.VerifyEmail.Message />}
            name="verifyEmail"
          />
          <IfFieldDefined name="paymentInstructions">
            <FieldOption
              cols={12}
              label={<FormMessages.PaymentInstructions.Message/>}
              name="paymentInstructions"
            >
              <FieldInput
                inputType="textarea"
                placeholder={formatMessage(FormMessages.PaymentInstructionsPlaceHolder)}
                style={{ minHeight: 100, maxWidth: '100%' }}
              />
            </FieldOption>
          </IfFieldDefined>
          <FlexColumn size={12}>
            <div className="text-tiny border pad-16">
              <Messages.TermsOfService.Message
                terms={vccPaymentTerms}
              />
            </div>
          </FlexColumn>
          <FieldOption
            cols={9}
            name="acceptTerms"
          >
            <FieldInput
              variant="children"
            >
              <FormMessages.TermsOfService.Message />
            </FieldInput>
          </FieldOption>
          <FlexColumn size={3} className="text-right">
            <Button
              isDisabled={isEnrolling}
              isProcessing={isEnrolling}
              onClick={onSubmit}
            >
              <FormMessages.Submit.Message />
            </Button>
          </FlexColumn>
        </FlexGrid>
      </FormV2>
      {flow === 'direct' ? (
        <>
          <HeaderText
            className="pad-40-top"
          >
            <AboutMessages.Header.Message />
          </HeaderText>
          <BodyText className="pad-16-top">
            <AboutMessages.Subheader.Message
              companyName={customerCompany}
            />
          </BodyText>
          <BulletedList className="spaced pad-12-top" variant="bullets">
            <AutoBullet>
              <AboutMessages.Bullet1.Message />
              <AboutMessages.Bullet2.Message />
              <AboutMessages.Bullet3.Message />
              <AboutMessages.Bullet4.Message />
              <AboutMessages.Bullet5.Message />
              <AboutMessages.Bullet6.Message
                companyName={customerCompany}
              />
            </AutoBullet>
          </BulletedList>
          <HeaderText
            className="pad-40-top"
          >
            <FaqMessages.Header.Message/>
          </HeaderText>
          <HeaderText
            className="pad-24-top"
            variant={HeaderTextVariants.Small}
            bold={true}
          >
            <FaqMessages.Question1.Message />
          </HeaderText>
          <BodyText className="pad-12-top">
            <FaqMessages.Question1Answer.Message />
          </BodyText>
          <HeaderText
            className="pad-24-top"
            variant={HeaderTextVariants.Small}
            bold={true}
          >
            <FaqMessages.Question2.Message />
          </HeaderText>
          <BodyText className="pad-12-top">
            <FaqMessages.Question2Answer.Message
              companyName={customerCompany}
            />
          </BodyText>
          <HeaderText
            className="pad-24-top"
            variant={HeaderTextVariants.Small}
            bold={true}
          >
            <FaqMessages.Question3.Message />
          </HeaderText>
          <BodyText className="pad-12-top">
            <FaqMessages.Question3Answer.Message />
          </BodyText>
          <HeaderText
            className="pad-24-top"
            variant={HeaderTextVariants.Small}
            bold={true}
          >
            <FaqMessages.Question4.Message />
          </HeaderText>
          <BodyText className="pad-12-top">
            <FaqMessages.Question4Answer.Message />
          </BodyText>
          <HeaderText
            className="pad-24-top"
            variant={HeaderTextVariants.Small}
            bold={true}
          >
            <FaqMessages.Question5.Message />
          </HeaderText>
          <BodyText className="pad-12-top">
            <FaqMessages.Question5Answer.Message
              companyName={customerCompany}
            />
          </BodyText>
          <HeaderText
            className="pad-24-top"
            variant={HeaderTextVariants.Small}
            bold={true}
          >
            <FaqMessages.Question6.Message />
          </HeaderText>
          <BodyText className="pad-12-top">
            <FaqMessages.Question6Answer.Message
              switchToAssisted={(
                <Button
                  anchor={true}
                  onClick={setAssistedFlow}
                >
                  <FaqMessages.SwitchLink.Message />
                </Button>
              )}
            />
          </BodyText>
          <BulletedList className="spaced pad-12-top" variant="bullets">
            <AutoBullet>
              <FaqMessages.Question6Bullet1.Message />
              <FaqMessages.Question6Bullet2.Message />
              <FaqMessages.Question6Bullet3.Message />
              <FaqMessages.Question6Bullet4.Message />
              <FaqMessages.Question6Bullet5.Message />
            </AutoBullet>
          </BulletedList>
        </>
      ) : null}
      {flow === 'assisted' ? (
        <>
          <HeaderText
            className="pad-40-top"
          >
            <AssistedFaqMessages.Header.Message/>
          </HeaderText>
          <BodyText className="pad-20-top">
            <AssistedFaqMessages.Instructions1.Message
              companyName={customerCompany}
            />
          </BodyText>
          <BodyText className="pad-20-top">
            <AssistedFaqMessages.Instructions2.Message
              switchToDirect={(
                <Button
                  anchor={true}
                  onClick={setDirectFlow}
                >
                  <AssistedFaqMessages.SwitchLink.Message />
                </Button>
              )}
            />
          </BodyText>
        </>
      ) : null}
    </>
  );
};

export default VccSelfEnrollment;