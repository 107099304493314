/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */
/* eslint max-len:0 */

import React from 'react';

function MicrSymbol({ value, size, color }) {
  switch (value) {
    case '1':
      return (
        <svg viewBox="0 0 10 20" preserveAspectRatio="xMinYMin" height={`${size}px`} width={`${size * 0.5}px`}>
          <g transform="translate(-3, 0)" fill={color}>
            <path d="M7.211,14.029c-0.261,0-0.434-0.121-0.518-0.363c-0.019-0.057-0.028-0.117-0.028-0.182v-3.277 c0-0.261,0.121-0.433,0.364-0.517c0.056-0.019,0.117-0.028,0.182-0.028c0.261,0,0.434-0.122,0.518-0.364 c0.019-0.056,0.028-0.117,0.028-0.182v-2.73c0-0.261-0.121-0.434-0.364-0.518L7.211,5.84c-0.261,0-0.434-0.121-0.518-0.364 C6.675,5.42,6.666,5.359,6.666,5.294V4.748c0-0.262,0.121-0.435,0.364-0.519c0.056-0.019,0.117-0.028,0.182-0.028h1.092 c0.261,0,0.434,0.122,0.518,0.364L8.85,4.748v4.368c0,0.261,0.121,0.434,0.364,0.518C9.27,9.652,9.33,9.662,9.396,9.662h1.092 c0.261,0,0.434,0.122,0.518,0.364l0.028,0.182v3.277c0,0.261-0.121,0.434-0.364,0.518l-0.182,0.027H7.211z" />
          </g>
        </svg>
      );
    case '2':
      return (
        <svg viewBox="0 0 10 20" preserveAspectRatio="xMinYMin" height={`${size}px`} width={`${size * 0.5}px`}>
          <g transform="translate(-14, 0)" fill={color}>
            <path d="M17.739,14.029l-0.014-0.014c-0.252,0-0.42-0.117-0.504-0.35c-0.019-0.057-0.028-0.117-0.028-0.182V9.116 c0-0.261,0.121-0.434,0.364-0.518c0.056-0.019,0.117-0.028,0.182-0.028h2.184c0.261,0,0.434-0.121,0.518-0.363 c0.019-0.056,0.028-0.117,0.028-0.182V5.84c0-0.261-0.121-0.434-0.364-0.518l-0.182-0.028h-2.184c-0.261,0-0.434-0.122-0.518-0.364 c-0.019-0.056-0.028-0.117-0.028-0.182c0-0.262,0.121-0.435,0.364-0.519c0.056-0.019,0.117-0.028,0.182-0.028h3.276 c0.261,0,0.434,0.122,0.518,0.364l0.028,0.182v4.368c0,0.261-0.121,0.434-0.364,0.518l-0.182,0.028h-2.184 c-0.262,0-0.435,0.122-0.519,0.364l-0.028,0.182v2.185c0,0.262,0.122,0.435,0.364,0.519c0.056,0.019,0.116,0.027,0.182,0.027h2.184 c0.261,0,0.434,0.121,0.518,0.364c0.019,0.056,0.028,0.116,0.028,0.183c0,0.261-0.121,0.434-0.364,0.518l-0.182,0.027H17.739z" />
          </g>
        </svg>
      );
    case '3':
      return (
        <svg viewBox="0 0 10 20" preserveAspectRatio="xMinYMin" height={`${size}px`} width={`${size * 0.5}px`}>
          <g transform="translate(-24, 0)" fill={color}>
            <path d="M27.174,14.029c-0.262,0-0.435-0.121-0.519-0.363c-0.019-0.057-0.028-0.117-0.028-0.182c0-0.262,0.122-0.435,0.364-0.519 c0.056-0.019,0.116-0.028,0.182-0.028h2.184c0.261,0,0.434-0.121,0.518-0.363c0.019-0.057,0.028-0.117,0.028-0.183v-2.185 c0-0.261-0.122-0.433-0.364-0.517l-0.182-0.028h-2.184c-0.262,0-0.435-0.122-0.519-0.364c-0.019-0.056-0.028-0.117-0.028-0.182 c0-0.261,0.122-0.434,0.364-0.518c0.056-0.019,0.116-0.028,0.182-0.028h2.184c0.261,0,0.434-0.121,0.518-0.363 c0.019-0.056,0.028-0.117,0.028-0.182V5.84c0-0.261-0.122-0.434-0.364-0.518l-0.182-0.028h-2.184c-0.262,0-0.435-0.122-0.519-0.364 c-0.019-0.056-0.028-0.117-0.028-0.182c0-0.262,0.122-0.435,0.364-0.519c0.056-0.019,0.116-0.028,0.182-0.028h3.276 c0.261,0,0.434,0.122,0.518,0.364l0.028,0.182v3.821c0,0.262,0.122,0.435,0.364,0.519c0.056,0.019,0.117,0.028,0.182,0.028 c0.261,0,0.434,0.121,0.518,0.364l0.028,0.182v3.823c0,0.261-0.121,0.434-0.364,0.518l-0.182,0.027H27.174z" />
          </g>
        </svg>
      );
    case '4':
      return (
        <svg viewBox="0 0 10 20" preserveAspectRatio="xMinYMin" height={`${size}px`} width={`${size * 0.5}px`}>
          <g transform="translate(-34, 0)" fill={color}>
            <path d="M40.978,14.029c-0.261,0-0.434-0.121-0.518-0.363c-0.019-0.057-0.028-0.117-0.028-0.182v-1.093 c0-0.261-0.122-0.434-0.364-0.518l-0.182-0.028h-3.276c-0.261,0-0.434-0.121-0.518-0.364c-0.019-0.056-0.028-0.116-0.028-0.182 V4.748c0-0.262,0.121-0.435,0.364-0.519c0.056-0.019,0.117-0.028,0.182-0.028h1.092c0.261,0,0.434,0.122,0.518,0.364l0.028,0.182 v5.459c0,0.262,0.121,0.436,0.364,0.52c0.056,0.018,0.117,0.027,0.182,0.027h1.092c0.261,0,0.434-0.121,0.518-0.364 c0.019-0.056,0.028-0.116,0.028-0.183c0-0.261,0.121-0.433,0.364-0.517c0.056-0.019,0.117-0.028,0.182-0.028h1.092 c0.261,0,0.434,0.122,0.518,0.364l0.028,0.182v3.277c0,0.261-0.122,0.434-0.364,0.518l-0.182,0.027H40.978z" />
          </g>
        </svg>
      );
    case '5':
      return (
        <svg viewBox="0 0 10 20" preserveAspectRatio="xMinYMin" height={`${size}px`} width={`${size * 0.5}px`}>
          <g transform="translate(-45, 0)" fill={color}>
            <path d="M48.229,14.029c-0.262,0-0.435-0.121-0.519-0.363c-0.019-0.057-0.028-0.117-0.028-0.182c0-0.262,0.122-0.435,0.364-0.519 c0.056-0.019,0.116-0.028,0.182-0.028h3.276c0.261,0,0.434-0.121,0.518-0.363c0.019-0.057,0.028-0.117,0.028-0.183v-2.185 c0-0.261-0.121-0.433-0.364-0.517l-0.182-0.028h-3.276c-0.262,0-0.435-0.122-0.519-0.364c-0.019-0.056-0.028-0.117-0.028-0.182 V4.748c0-0.262,0.122-0.435,0.364-0.519c0.056-0.019,0.116-0.028,0.182-0.028h4.368c0.261,0,0.434,0.122,0.518,0.364l0.028,0.182 c0,0.261-0.122,0.434-0.364,0.518l-0.182,0.028h-3.276c-0.261,0-0.434,0.121-0.518,0.364L48.775,5.84v2.184 c0,0.261,0.121,0.434,0.364,0.518c0.056,0.019,0.117,0.027,0.182,0.027h3.276c0.261,0,0.434,0.122,0.518,0.364l0.028,0.182v4.369 c0,0.261-0.122,0.434-0.364,0.518l-0.182,0.027H48.229z" />
          </g>
        </svg>
      );
    case '6':
      return (
        <svg viewBox="0 0 10 20" preserveAspectRatio="xMinYMin" height={`${size}px`} width={`${size * 0.5}px`}>
          <g transform="translate(-55, 0)" fill={color}>
            <path d="M63.642,10.025c-0.084-0.242-0.257-0.364-0.518-0.364h-4.368c-0.066,0-0.126-0.009-0.182-0.028 C58.332,9.55,58.21,9.377,58.21,9.116V5.84l0.028-0.182c0.084-0.243,0.257-0.364,0.519-0.364h1.092l0.182,0.028 c0.243,0.084,0.364,0.257,0.364,0.518v0.546c0,0.065,0.009,0.126,0.028,0.182c0.084,0.243,0.257,0.364,0.519,0.364l0.182-0.028 c0.243-0.084,0.364-0.257,0.364-0.518V4.748l-0.028-0.182c-0.084-0.243-0.256-0.364-0.518-0.364h-3.276 c-0.065,0-0.126,0.009-0.182,0.028c-0.243,0.084-0.364,0.257-0.364,0.519v8.736c0,0.064,0.009,0.125,0.028,0.182 c0.083,0.242,0.256,0.363,0.518,0.363h5.46l0.182-0.027c0.242-0.084,0.364-0.257,0.364-0.518v-3.277L63.642,10.025z M62.578,12.392 c0,0.065-0.009,0.126-0.028,0.183c-0.084,0.242-0.257,0.363-0.518,0.363h-3.276c-0.066,0-0.126-0.009-0.182-0.027 c-0.243-0.084-0.364-0.257-0.364-0.519V11.3c0-0.065,0.009-0.126,0.028-0.183c0.084-0.242,0.257-0.363,0.519-0.363h3.276 l0.182,0.027c0.243,0.084,0.364,0.258,0.364,0.519V12.392z" />
          </g>
        </svg>
      );
    case '7':
      return (
        <svg viewBox="0 0 10 20" preserveAspectRatio="xMinYMin" height={`${size}px`} width={`${size * 0.5}px`}>
          <g transform="translate(-66, 0)" fill={color}>
            <path d="M71.468,14.029c-0.262,0-0.435-0.121-0.519-0.363c-0.019-0.057-0.028-0.117-0.028-0.182V9.116 c0-0.243,0.112-0.411,0.336-0.504l1.512-0.63c0.224-0.093,0.336-0.261,0.336-0.503V5.84c0-0.261-0.121-0.434-0.364-0.518 L72.56,5.294h-2.184c-0.261,0-0.434,0.121-0.518,0.364L69.83,5.84v1.092c0,0.261-0.122,0.434-0.364,0.518l-0.182,0.028 c-0.262,0-0.435-0.122-0.519-0.364c-0.019-0.056-0.028-0.117-0.028-0.182V4.748c0-0.262,0.122-0.435,0.364-0.519 c0.056-0.019,0.116-0.027,0.182-0.028h4.368c0.261,0,0.434,0.122,0.518,0.364l0.028,0.182V8.22c0,0.243-0.112,0.411-0.336,0.504 L72.35,9.326c-0.224,0.094-0.336,0.262-0.336,0.504v3.654c0,0.261-0.122,0.434-0.364,0.518L71.468,14.029z" />
          </g>
        </svg>
      );
    case '8':
      return (
        <svg viewBox="0 0 10 20" preserveAspectRatio="xMinYMin" height={`${size}px`} width={`${size * 0.5}px`}>
          <g transform="translate(-76, 0)" fill={color}>
            <path d="M84.697,9.479c-0.084-0.243-0.257-0.364-0.518-0.364c-0.065,0-0.126-0.009-0.182-0.028 c-0.242-0.084-0.364-0.257-0.364-0.519V4.748l-0.028-0.182c-0.084-0.243-0.257-0.364-0.518-0.364h-4.368 c-0.065,0-0.126,0.009-0.182,0.028c-0.243,0.084-0.364,0.257-0.364,0.519v3.821c0,0.066-0.009,0.126-0.028,0.182 c-0.083,0.243-0.256,0.364-0.518,0.364c-0.065,0-0.126,0.009-0.182,0.028c-0.243,0.084-0.364,0.257-0.364,0.518v3.823 c0,0.064,0.01,0.125,0.028,0.182c0.084,0.242,0.257,0.363,0.518,0.363h6.552l0.182-0.027c0.242-0.084,0.364-0.257,0.364-0.518V9.662 L84.697,9.479z M82.541,12.392c0,0.065-0.009,0.126-0.028,0.183c-0.084,0.242-0.256,0.363-0.518,0.363h-2.184 c-0.066,0-0.126-0.009-0.182-0.027c-0.243-0.084-0.364-0.257-0.364-0.519v-2.185l0.028-0.182c0.084-0.242,0.257-0.364,0.519-0.364 h2.184l0.182,0.028c0.243,0.084,0.364,0.256,0.364,0.517V12.392z M82.541,8.024c0,0.065-0.009,0.126-0.028,0.182 c-0.084,0.242-0.256,0.363-0.518,0.363h-2.184c-0.066,0-0.126-0.009-0.182-0.027c-0.243-0.084-0.364-0.257-0.364-0.518V5.84 l0.028-0.182c0.084-0.243,0.257-0.364,0.519-0.364h2.184l0.182,0.028c0.243,0.084,0.364,0.257,0.364,0.518V8.024z" />
          </g>
        </svg>
      );
    case '9':
      return (
        <svg viewBox="0 0 10 20" preserveAspectRatio="xMinYMin" height={`${size}px`} width={`${size * 0.5}px`}>
          <g transform="translate(-87, 0)" fill={color}>
            <path d="M95.225,4.566c-0.084-0.243-0.258-0.364-0.519-0.364h-5.46c-0.064,0-0.126,0.009-0.182,0.028 C88.821,4.313,88.7,4.486,88.7,4.748v4.368c0,0.065,0.009,0.126,0.028,0.182c0.084,0.242,0.256,0.364,0.518,0.364h3.276l0.182,0.028 c0.243,0.084,0.364,0.256,0.364,0.517v3.277c0,0.064,0.01,0.125,0.027,0.182c0.084,0.242,0.258,0.363,0.519,0.363h1.092l0.183-0.027 c0.242-0.084,0.363-0.257,0.363-0.518V4.748L95.225,4.566z M94.16,8.024c0,0.065-0.01,0.126-0.027,0.182 c-0.084,0.242-0.258,0.363-0.519,0.363h-3.276c-0.065,0-0.126-0.009-0.182-0.027c-0.242-0.084-0.364-0.257-0.364-0.518V5.84 l0.028-0.182c0.084-0.243,0.256-0.364,0.518-0.364h3.276l0.183,0.028c0.242,0.084,0.363,0.257,0.363,0.518V8.024z" />
          </g>
        </svg>
      );
    case '0':
      return (
        <svg viewBox="0 0 10 20" preserveAspectRatio="xMinYMin" height={`${size}px`} width={`${size * 0.5}px`}>
          <g transform="translate(-97, 0)" fill={color}>
            <path d="M105.317,5.056c-0.438-0.569-1.013-0.854-1.722-0.854h-3.276c-0.495,0-0.938,0.154-1.329,0.462 c-0.57,0.439-0.855,1.013-0.855,1.722v5.46c0,0.494,0.154,0.938,0.463,1.33c0.438,0.569,1.013,0.854,1.722,0.854h3.276 c0.494,0,0.938-0.153,1.33-0.461c0.568-0.439,0.854-1.014,0.854-1.723v-5.46C105.779,5.891,105.625,5.448,105.317,5.056z  M104.688,11.846c0,0.188-0.047,0.364-0.141,0.531c-0.205,0.374-0.521,0.561-0.951,0.561h-3.276c-0.187,0-0.364-0.047-0.532-0.14 c-0.373-0.205-0.56-0.522-0.56-0.952v-5.46c0-0.187,0.047-0.364,0.14-0.532c0.205-0.373,0.523-0.56,0.952-0.56h3.276 c0.187,0,0.363,0.046,0.531,0.14c0.374,0.206,0.561,0.523,0.561,0.952V11.846z" />
          </g>
        </svg>
      );
    case 'T': // Transit Symbol ⑆
      return (
        <svg viewBox="0 0 10 20" preserveAspectRatio="xMinYMin" height={`${size}px`} width={`${size * 0.5}px`}>
          <g transform="translate(-111, 0)" fill={color}>
            <path d="M114.372,5.844h-1.275c-0.234,0-0.425,0.19-0.425,0.425v5.712c0,0.234,0.19,0.425,0.425,0.425h1.275 c0.234,0,0.425-0.19,0.425-0.425V6.269C114.797,6.034,114.606,5.844,114.372,5.844z"/>
            <path d="M119.888,4.203h-2.4c-0.234,0-0.425,0.19-0.425,0.425v2.384c0,0.235,0.19,0.425,0.425,0.425h2.4 c0.234,0,0.425-0.19,0.425-0.425V4.628C120.312,4.394,120.122,4.203,119.888,4.203z"/>
            <path d="M119.888,10.781h-2.4c-0.234,0-0.425,0.19-0.425,0.426v2.383c0,0.235,0.19,0.426,0.425,0.426h2.4 c0.234,0,0.425-0.19,0.425-0.426v-2.383C120.312,10.972,120.122,10.781,119.888,10.781z"/>
          </g>
        </svg>
      );
    case 'U': // On-Us symbol ⑈
      return (
        <svg viewBox="0 0 10 20" preserveAspectRatio="xMinYMin" height={`${size}px`} width={`${size * 0.5}px`}>
          <g transform="translate(-126, 0)" fill={color}>
            <path d="M128.367,5.854h-0.191c-0.234,0-0.425,0.19-0.425,0.425v5.67c0,0.234,0.19,0.425,0.425,0.425h0.191 c0.234,0,0.425-0.19,0.425-0.425V6.28C128.792,6.045,128.602,5.854,128.367,5.854z"/>
            <path d="M130.514,5.854h-0.191c-0.234,0-0.425,0.19-0.425,0.425v5.67c0,0.234,0.19,0.425,0.425,0.425h0.191 c0.234,0,0.425-0.19,0.425-0.425V6.28C130.938,6.045,130.748,5.854,130.514,5.854z"/>
            <path d="M134.909,4.729h-2.4c-0.234,0-0.425,0.19-0.425,0.425v3.524c0,0.235,0.19,0.425,0.425,0.425h2.4 c0.234,0,0.425-0.19,0.425-0.425V5.155C135.334,4.92,135.144,4.729,134.909,4.729z"/>
          </g>
        </svg>
      );
    case 'A': // Amount symbol ⑇
      return (
        <svg viewBox="0 0 10 20" preserveAspectRatio="xMinYMin" height={`${size}px`} width={`${size * 0.5}px`}>
          <g transform="translate(-141, 0)" fill={color}>
            <path d="M144.118,9.667h-1.297c-0.234,0-0.425,0.19-0.425,0.425v3.483c0,0.234,0.19,0.425,0.425,0.425h1.297 c0.234,0,0.425-0.19,0.425-0.425v-3.483C144.543,9.857,144.353,9.667,144.118,9.667z"/>
            <path d="M149.556,4.208h-1.297c-0.234,0-0.425,0.19-0.425,0.425v3.483c0,0.234,0.19,0.425,0.425,0.425h1.297 c0.234,0,0.425-0.19,0.425-0.425V4.633C149.98,4.398,149.79,4.208,149.556,4.208z"/>
            <path d="M146.306,6.667h-0.213c-0.234,0-0.425,0.19-0.425,0.425v4.046c0,0.234,0.19,0.425,0.425,0.425h0.213 c0.234,0,0.425-0.19,0.425-0.425V7.092C146.73,6.857,146.54,6.667,146.306,6.667z"/>
          </g>
        </svg>
      );
    case 'D': // Dash symbol ⑉
    case '-': // Dash symbol ⑉
      return (
        <svg viewBox="0 0 10 20" preserveAspectRatio="xMinYMin" height={`${size}px`} width={`${size * 0.5}px`}>
          <g transform="translate(-156, 0)" fill={color}>
            <path d="M159.181,6.979h-1.297c-0.234,0-0.425,0.19-0.425,0.425v3.484c0,0.234,0.19,0.424,0.425,0.424h1.297 c0.234,0,0.425-0.189,0.425-0.424V7.404C159.605,7.169,159.415,6.979,159.181,6.979z"/>
            <path d="M162.451,6.979h-1.297c-0.234,0-0.425,0.19-0.425,0.425v3.484c0,0.234,0.19,0.424,0.425,0.424h1.297 c0.234,0,0.425-0.189,0.425-0.424V7.404C162.876,7.169,162.686,6.979,162.451,6.979z"/>
            <path d="M164.639,6.953h-0.213c-0.234,0-0.425,0.19-0.425,0.425v3.489c0,0.234,0.19,0.424,0.425,0.424h0.213 c0.234,0,0.425-0.189,0.425-0.424V7.378C165.063,7.144,164.873,6.953,164.639,6.953z"/>
          </g>
        </svg>
      );
    case ' ': // space
      return (
        <svg viewBox="0 0 10 20" preserveAspectRatio="xMinYMin" height={`${size}px`} width={`${size * 0.5}px`}>
        </svg>
      );
    default: return null;
  }
}

function MicrLine({ value, size = 20, color = '#333' }) {
  if (!value) return null;
  let chars = value.split('');
  return (
    <div>
      {chars.map(c => <div style={{display: 'inline-block', verticalAlign: 'middle'}}>
        <MicrSymbol value={c} size={size} color={color} />
      </div>)}
    </div>
  );
}

export default MicrLine;