/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */
import { toMessages } from 'config/messages';

export default toMessages('ValidationError.Currency', {
  Invalid: 'Please enter a valid amount',
  InvalidGreaterThanEqualToAmount: 'Please enter an amount greater than or equal to ${amount, number}',
  InvalidLessThanEqualToAmount: 'Please enter an amount less than or equal to ${amount}',
  InvalidLessThanAmount: 'Please enter an amount less than ${amount}',
  InvalidWholeNumber: 'Amount must be a whole number',
  GreaterThanZeroLessThanAmount: 'Please enter an amount between 0 and {amount}'
});
