/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Truncate from 'components/Truncate';

export const Props = {
  value: PropTypes.string,
  disabled: PropTypes.bool
};

export const DefaultProps = {
  value: ''
};

export default class EmailAddress extends Component {
  static propTypes = Props;

  static defaultProps = DefaultProps;

  render() {
    let {
      toolTipPlacement,
      value
    } = this.props;

    if (!value) return <span/>;

    // assume that this is a masked email when we just have the domain portion of the email
    if (value.startsWith('@')) {
      value = `***********${value}`;
    }

    if (this.props.disabled || value.startsWith('**')) {
      return <span className="ellipsis">{value}</span>;
    }

    let mailTo = `mailto:${value}`;

    return (
      <a className="ellipsis" href={mailTo}>
        <Truncate
          value={value}
          maxLength={this.props.maxLength || 50}
          toolTipPlacement={toolTipPlacement}
        />
      </a>
    );
  }
}
