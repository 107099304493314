/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import t from 'tcomb-validation';
import { defineSchema } from 'schemas';

const DashboardActivity = t.struct({
  id: t.String,
  activityType: t.maybe(t.String),
  activityDate: t.maybe(t.Date),
  content: t.maybe(t.String),
  parameters: t.maybe(t.Any)
}, 'DashboardActivity');

export const DashboardRecentActivities = defineSchema({
  total: t.Number,
  activities: t.list(DashboardActivity),
  nextPageToken: t.maybe(t.String)
}, 'DashboardRecentActivities');

const CashflowDataPoint = t.struct({
  cashPoint: t.Number,
  date: t.Date,
  receivablesTotal: t.maybe(t.Number),
  payablesTotal: t.maybe(t.Number),
  startingCashPoint: t.Number
}, 'CashflowDataPoint');

export const CashflowData = defineSchema({
  cashflowAlertThreshold: t.maybe(t.Number),
  data: t.list(CashflowDataPoint)
}, 'CashflowData');

const DashboardSummaryItem = t.struct({
  open: t.maybe(t.Number),
  openAmount: t.maybe(t.Number),
  vpx: t.maybe(t.Number),
  vpxAmount: t.maybe(t.Number),
  overdue: t.maybe(t.Number),
  scheduled: t.maybe(t.Number),
  newItems: t.maybe(t.Number)
}, 'DashboardSummaryItem');

export const DashboardSummary = defineSchema({
  payables: DashboardSummaryItem,
  receivables: DashboardSummaryItem
}, 'DashboardSummary');

export const Acknowledgement = defineSchema({
  id: t.String,
  completed: t.Boolean,
  isCompanyWide: t.Boolean
}, 'Acknowledgement');
