/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';

import { dispatchApiCall } from 'actions/references';
import requestPaymentHelp from 'api/onboarding/requestPaymentHelp';

import GuidedHelp from './Guided';

const ConnectedVccHelp = ({
  secureToken,
  secureTokenContext
}) => {
  // Hooks
  const dispatch = useDispatch();
  const { token: secureTokenParam } = useParams();

  const onSubmit = (
    formValue,
    onComplete
  ) => dispatch(dispatchApiCall(
    'VccHelp',
    requestPaymentHelp,
    {
      ...formValue,
      secureToken: secureToken || secureTokenParam
    },
    {
      onComplete
    }
  ));

  return (
    <GuidedHelp
      onSubmit={onSubmit}
      secureToken={secureToken || secureTokenParam}
      secureTokenContext={secureTokenContext} />
  );
};

export default ConnectedVccHelp;
