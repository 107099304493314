/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './index.scss';

export const LargeButtonRow = ({
  children,
  style
}) => (
  <div
    className="image-text-large-button-row"
    style={style}
  >
    {children}
  </div>
);

export const LargeButtonHeader = ({
  children
}) => (
  <div
    className="image-text-header"
    style={{
      visibility: children === true ? 'hidden' : null
    }}
  >
    {children === true
      ? <>&nbsp;</>
      : children}
  </div>
);

LargeButtonHeader.isLargeButtonHeader = true;

const ImageTextLargeButton = ({
  analyticsId,
  belowChildren,
  children,
  childType,
  className,
  contentAlign,
  disabled,
  header,
  img,
  imgSrc,
  imgSrcSet,
  imgProps,
  onClick,
  pad = true,
  selected,
  skinny,
  style
}) => (
  <div
    style={style}
    className={classNames(
      'image-text-large-button',
      'text-center',
      className,
      {
        'no-pad': !pad,
        'selected': selected,
        'skinny': skinny || !children,
        disabled: disabled || !onClick
      }
    )}
  >
    <div className="wrapper" onClick={onClick} id={analyticsId} />
    <div className="content-wrapper">
      {header ? LargeButtonHeader({ children: header }) : null}
      <div
        className={classNames(
          'content',
          contentAlign == null
            ? 'middle'
            : contentAlign
        )}
      >
        {imgSrc || img ? (
          <div className="image-text-image">
            {img ? img : (
              <img
                src={imgSrc}
                srcSet={imgSrcSet}
                {...(imgProps || {})}
              />
            )}
          </div>
        ) : null}
        {children ? (
          <div className={classNames('image-text-text', childType)}>
            {children}
          </div>
        ) : null}
        {belowChildren ? (
          <div className={classNames('image-text-text below', childType)}>
            {belowChildren}
          </div>
        ) : null}
      </div>
    </div>
  </div>
);

ImageTextLargeButton.propTypes = {
  /** Class name to pass to set on parent div (e.g., 'col-6') */
  className: PropTypes.string,
  /** Function to execute on click of button */
  onClick: PropTypes.func,
  /** The <img/> src */
  imgSrc: PropTypes.string,
  /** The props to pass through to the image */
  imgProps: PropTypes.object
};

export default ImageTextLargeButton;
