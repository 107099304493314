/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { PaymentMethodType } from 'config/constants';
import Select from 'components/Form/Inputs/Select';
import Truncate from 'components/Truncate';
import { BankAccountStatus } from 'schemas/payments/bankAccount';
import filterForValidAccounts from 'schemas/payments/bankAccount/filterForValidAccounts';
import { isVerifiableAccount } from 'schemas/payments/bankAccount/isAccount';
import FlagIcon from 'components/Icon/FlagIcon';
import Messages from './index.messages';
import connect from './index.connect';

const ADD_BANK_ACCOUNT_VALUE = '___addaccount___';

export class SelectPaymentMethod extends Component {
  static propTypes = {
    /** Adds a label on the account name that is the Default Payment Account */
    includeDefaultPaymentLabel: PropTypes.bool,
    /** By default, only show verified accounts that are not locked, expired, inactive, or awaiting micro deposits */
    filterForValidAccounts: PropTypes.bool,
    /** All accounts must match at least one of these statuses */
    filterStatuses: PropTypes.array,
    /** No accounts can match any of these statuses */
    filterOutStatuses: PropTypes.array,
    /** Allow credit card as an option */
    includeCreditCard: PropTypes.bool,
    /** Whether or not to include payment accounts that can be verified inline */
    allowInlineVerify: PropTypes.bool,
    /** Whether or not to allow them add a new bank account inline */
    allowAddBankAccount: PropTypes.bool,
    /** Whether or not the vendor has whitelisted us to pay with an unverified account (so they don't need to be verified) */
    isWhitelistedToUseUnverfiedAccount: PropTypes.bool,
    /* Provide filter for bank accounts */
    accountFilter: PropTypes.func,
    onChange: PropTypes.func.isRequired,
    bankAccounts: PropTypes.array.isRequired
  };

  static defaultProps = {
    includeDefaultPaymentLabel: false,
    filterForValidAccounts: true,
    filterStatuses: [],
    filterOutStatuses: [],
    includeCreditCard: false,
    allowInlineVerify: false,
    allowAddBankAccount: true,
    isWhitelistedToUseUnverfiedAccount: false
  };

  // TODO: Figure out how to move to a transformer or anything else that modifies the value inline
  // instead of doing callbacks, which can cause infinite loops
  componentWillMount() {
    const { onChange, value } = this.props;

    if (!value || this.isInvalidValue(value)) {
      onChange(this.getDefaultValue());
    }
  }

  // TODO: Figure out how to move to a transformer or anything else that modifies the value inline
  // instead of doing callbacks, which can cause infinite loops
  componentWillReceiveProps({ includeCreditCard, value: nextValue }) {
    const { onChange, value } = this.props;

    // credit card has been removed, don't allow it to remain a value
    if (value === PaymentMethodType.CreditCardStripe && !includeCreditCard) {
      onChange(null);
    }

    if (this.isInvalidValue(nextValue)) {
      onChange(this.getDefaultValue());
    }
  }

  getDefaultValue() {
    const { bankAccounts } = this.props;
    const defaultAccount = bankAccounts.find(({ statuses }) => statuses
      .includes(BankAccountStatus.meta.map.DefaultPaymentAccount));

    if (!defaultAccount) return null;

    if (this.isInvalidValue(defaultAccount.id)) return null;

    return defaultAccount.id;
  }

  isInvalidValue(value) {
    const { bankAccounts } = this.props;
    const bankAccount = bankAccounts.find(({ id }) => id === value);
    if (bankAccount && !bankAccount.hasSignature) {
      // don't allow missing sig account to be selected by default (so they have to select it and add the signature)
      return true;
    }

    return false;
  }

  onChange = (value) => {
    const {
      allowInlineVerify,
      bankAccounts,
      isWhitelistedToUseUnverfiedAccount,
      onAddSignature,
      onChange,
      onVerifyAccount
    } = this.props;

    const selectedBankAccount = bankAccounts.find(({ id }) => id === value);
    if (selectedBankAccount
      && !isWhitelistedToUseUnverfiedAccount
      && allowInlineVerify
      && isVerifiableAccount(selectedBankAccount)) {
      // INLINE VERIFY
      onVerifyAccount(selectedBankAccount.id, () => onChange(selectedBankAccount.id));
      return;
    }

    if (selectedBankAccount && !selectedBankAccount.hasSignature) {
      // MISSING Signature
      onAddSignature(selectedBankAccount.id, () => onChange(selectedBankAccount.id));
      return;
    }

    if (value === ADD_BANK_ACCOUNT_VALUE) {
      onChange(null);
    } else {
      onChange(value);
    }
  }

  render() {
    const {
      accountFilter,
      allowAddBankAccount,
      allowInlineVerify,
      bankAccounts,
      disabled,
      filterForValidAccounts: willFilterForValidAccounts,
      filterOutStatuses,
      filterStatuses,
      includeCreditCard,
      includeDefaultPaymentLabel,
      isWhitelistedToUseUnverfiedAccount,
      onAddAccount,
      placeholder,
      value
    } = this.props;

    let filteredBankAccounts = bankAccounts;

    if (willFilterForValidAccounts) {
      filteredBankAccounts = filterForValidAccounts(
        filteredBankAccounts,
        allowInlineVerify,
        isWhitelistedToUseUnverfiedAccount
      );
    }

    if (filterStatuses.length) {
      filteredBankAccounts = filteredBankAccounts.filter(
        paymentMethod => filterStatuses.some(status => paymentMethod.statuses.includes(status))
      );
    }

    if (filterOutStatuses.length) {
      filteredBankAccounts = filteredBankAccounts.filter(
        paymentMethod => !filterOutStatuses.some(status => paymentMethod.statuses.includes(status))
      );
    }

    if (accountFilter) {
      filteredBankAccounts = accountFilter(filteredBankAccounts);
    }

    const options = filteredBankAccounts.map(({
      id,
      accountName,
      currencyCode,
      statuses,
      accountNumberSuffix
    }) => {
      const formattedName = `${accountName} - ${accountNumberSuffix}`;
      let richName = formattedName;

      if (includeDefaultPaymentLabel && statuses.includes('DefaultPaymentAccount')) {
        richName = (
          <Messages.DefaultAccount.Message
            name={accountName}
            lastFourDigits={accountNumberSuffix}
          />
        );
      }

      let hasIntlCurrency = filteredBankAccounts.some(a => a.currencyCode !== 'USD');

      return {
        value: id,
        text: formattedName,
        richText: <div>
          <Truncate value={richName} maxLength={50} />
          {hasIntlCurrency && currencyCode === 'CAD'
            ? (
              <div className="subtext" style={{ fontSize: '12px' }}>
                <FlagIcon country="CA" /> CAD
              </div>
            )
            : null}
          {hasIntlCurrency && currencyCode === 'USD'
            ? (
              <div className="subtext" style={{ fontSize: '12px' }}>
                <FlagIcon country="US" /> USD
              </div>
            )
            : null}
        </div>
      };
    });

    if (includeCreditCard) {
      options.push({
        value: PaymentMethodType.CreditCardStripe,
        text: Messages.CreditCard.defaultMessage,
        richText: <Messages.CreditCard.Message />
      });
    }

    if (allowAddBankAccount) {
      if (options.length === 0) {
        // no accounts
        return (
          <div
            style={{
              border: '1px dashed #b3b3b3',
              padding: '8px 10px',
              backgroundColor: 'white'
            }}
          >
            <a onClick={() => onAddAccount()}>
              <Messages.PlusAction.Message
                action={<Messages.AddBankAccount.Message />}
              />
            </a>
          </div>
        );
      }

      options.push({
        value: ADD_BANK_ACCOUNT_VALUE,
        text: Messages.AddBankAccount.defaultMessage,
        richText: (
          <a>
            <Messages.PlusAction.Message
              action={<Messages.AddBankAccount.Message />}
            />
          </a>
        ),
        onClick: () => onAddAccount(),
        style: {
          borderTop: '1px solid #ddd'
        }
      });
    }

    return (
      <Select
        {...this.props}
        value={value}
        onChange={this.onChange}
        placeholder={(
          !placeholder && options.length === 0
            ? 'No payment methods available'
            : placeholder
        )}
        disabled={!options.length || disabled}
        selectOptions={options}
      />
    );
  }
}

export default connect(SelectPaymentMethod);
