/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import KnightErnie from 'images/ernie/knight.png';
import SadErnie from 'images/ernie/sad.png';
import ShruggingErnie from 'images/ernie/shrugging.png';

export const Knight = {
  id: 'ErnieKnight',
  description: 'Ernie, but in knight form',
  src: KnightErnie
};

export const Sad = {
  id: 'ErnieSad',
  description: 'A very sad ernie',
  src: SadErnie
};

export const Shrugging = {
  id: 'ErnieShrugging',
  description: 'A confused ernie',
  src: ShruggingErnie
};
