/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, { forwardRef, isValidElement, useImperativeHandle, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import { isFunction, isString } from 'lodash';

import HelpLink from 'components/HelpLink';
import InfoTooltip from 'components/InfoTooltip';
import Tooltip from 'components/Tooltip';

import useIsMobile from 'hooks/browser/useIsMobile';

import './FieldLayout.scss';

const RequiredAsterisk = <span className="required-asterisk">&nbsp;*</span>;

export const FieldLabel = ({
  children,
  className,
  disableIndent,
  htmlFor,
  inline
}) => {

  return (
    <label
      htmlFor={htmlFor}
      className={classNames(
        'type-normal',
        className,
        {
          'vp-label-indent': !disableIndent,
          'vp-inline-label': inline,
          'vp-label': !inline
        }
      )}
    >
      {children}
    </label>
  );
};

export const BaseFieldLayout = ({
  children,
  className,
  cols,
  disabled,
  display,
  error,
  footerLabel,
  hasError,
  hasFocus,
  isRequired,
  label,
  rightLabel,
  showRequiredLabel,
  style
}) => {
  const getLabel = () => {
    if (isValidElement(label) || isString(label)) {
      return (
        <FieldLabel>
          {label}
          {showRequiredLabel && isRequired ? RequiredAsterisk : null}
        </FieldLabel>
      );
    }

    if (isFunction(label)) {
      const LabelComponent = label;

      return (
        <LabelComponent />
      );
    }

    return;
  };

  const actualLabel = getLabel();

  return (
    <div
      className={classNames(
        'vp-field',
        cols ? `${display === 'flex' ? 'flex-' : ''}col-${cols}` : 'v2',
        className,
        {
          'vp-error': hasError || !!error,
          'vp-disabled': disabled,
          'vp-focus': hasFocus
        }
      )}
      style={style}
    >
      {actualLabel || rightLabel ? (
        <div>
          {actualLabel}
          {rightLabel ? (
            <span className="type-normal vp-label" style={{ float: 'right' }}>
              {rightLabel}
            </span>
          ) : null}
        </div>
      ) : null}
      {children}
      {footerLabel ? (
        <div>{footerLabel}</div>
      ) : null}
      {error ? (
        <div className="vp-error-message">{error}</div>
      ) : null}
    </div>
  );
};

export const FlattenedFieldLayout = ({
  appendLabelContent,
  children,
  className,
  style,
  cols,
  customErrorClass,
  customInputClass,
  customLabelClass,
  disabled,
  disableIndent,
  emptyLabel,
  error,
  fieldClassName,
  fieldStyle,
  fieldTooltip,
  fieldTooltipHeader,
  fieldTooltipPlacement,
  fieldTooltipVariant,
  fieldTooltipVisible,
  hasError,
  hasFocus,
  helpLink,
  hidden,
  htmlFor,
  ignoreErrorStyle,
  inlineLabel,
  inlineLabelCols,
  inlineLabelMobileStack,
  isRequired,
  labelValues,
  message: actualMessage,
  overrideLabel,
  rightLabel: originalRightLabel,
  footerLabel,
  showRequiredLabel,
  toolTipMessage,
  willHideLabel
}) => {
  const isMobile = useIsMobile();

  if (hidden) return <></>;

  const getContainerClasses = () => {
    let colClass = 'col-12'; // default to full row width
    if (cols) {
      colClass = `col-${cols}`;
    }

    return classNames(
      'vp-field',
      className,
      {
        'vp-error': !ignoreErrorStyle && hasError,
        'vp-disabled': disabled,
        'vp-focus': hasFocus
      },
      colClass
    );
  };

  let label = null;
  let rightLabel = null;
  let inlineCols = inlineLabelCols ? 12 - inlineLabelCols : 5;
  let labelCols = 12 - inlineCols;

  const labelClasses = classNames(
    'type-normal',
    {
      'vp-label-indent': !disableIndent,
      'vp-inline-label': inlineLabel,
      [`col-${labelCols}`]: inlineLabel,
      'stack-small': inlineLabel && inlineLabelMobileStack,
      'vp-label': !inlineLabel
    },
    customLabelClass
  );

  if (overrideLabel) {
    label = overrideLabel;
  } else if (emptyLabel) {
    label = <span className={labelClasses}>&nbsp;</span>;
  } else if (!willHideLabel) {
    let message = actualMessage;

    if (!message) {
      // console.warn(`Could not find a message label. Set emptyLabel/willHideLabel to true if you don't intend to set a label.`); // eslint-disable-line max-len
    } else {
      let actualLabel = null;
      if (typeof message === 'string') {
        actualLabel = message;
      } else {
        actualLabel = <FormattedMessage {...message} values={labelValues} />;
      }

      label = (
        <label
          key="label"
          htmlFor={htmlFor}
          className={labelClasses}
        >
          {actualLabel}
          {showRequiredLabel && isRequired
            ? <span className="required-asterisk">&nbsp;*</span> : null}
          {/* add a help if specified */}
          {helpLink
            ? <HelpLink href={helpLink} />
            : null}
          {toolTipMessage
            ? <InfoTooltip>
              {isString(toolTipMessage)
                ? toolTipMessage
                : <FormattedMessage {...toolTipMessage} />}
            </InfoTooltip>
            : null}
          {appendLabelContent}
        </label>
      );
    }
  }

  if (originalRightLabel) {
    rightLabel = (
      <span className="type-normal vp-label" style={{ float: 'right' }}>
        {originalRightLabel}
      </span>
    );
  }

  const fieldClasses = classNames(customInputClass, {
    [`col-${inlineCols}`]: inlineLabel,
    'stack-small': inlineLabel && inlineLabelMobileStack
  }, fieldClassName);

  const errorClasses = classNames('vp-error-message', customErrorClass);

  return (
    <div className={getContainerClasses()} style={style}>
      {/* add a label if specified */}
      {label}
      {rightLabel}
      {/* the children should be the form input element/widget */}
      <div className={fieldClasses} key="input" style={fieldStyle}>
        {fieldTooltip
          ? (
            <Tooltip
              placement={fieldTooltipPlacement || (isMobile ? 'top' : 'left')}
              variant={fieldTooltipVariant || 'blue-tooltip'}
              childWrapperStyle={{display: 'block'}}
              visible={fieldTooltipVisible}
              header={fieldTooltipHeader}
              headerColor="white"
              overlay={fieldTooltip}
            >
              {children}
            </Tooltip>
          ) : children}
      </div>

      {footerLabel
        ? (
          <div className="type-normal vp-label" style={{display: 'block'}}>
            {footerLabel}
          </div>
        )
        : null}

      {/* add an error if specified */}
      {hasError
        ? <span key="errorMessage" className={errorClasses}>{error}</span>
        : null}
    </div>
  );
};

const FieldLayout = forwardRef(
  (
    {
      appendLabelContent,
      children,
      className,
      cols,
      config: {
        emptyLabel,
        style,
        fieldTooltip,
        fieldTooltipHeader,
        fieldTooltipPlacement,
        fieldTooltipVariant,
        fieldTooltipVisible,
        htmlFor: configHtmlFor,
        ignoreErrorStyle,
        overrideLabel,
        path,
        rightLabel,
        footerLabel,
        willHideLabel
      },
      ctx,
      customErrorClass,
      customInputClass,
      customLabelClass,
      disabled,
      disableIndent,
      error,
      fieldClassName,
      fieldStyle,
      hasError,
      helpLink,
      htmlFor,
      inlineLabel,
      inlineLabelCols,
      inlineLabelMobileStack,
      labelValues,
      message,
      showRequiredLabel,
      toolTipMessage,
      typeInfo,
      options
    },
    ref
  ) => {
    const [ hasFocus, setHasFocus ] = useState(false);

    useImperativeHandle(
      ref,
      () => ({
        onInputFocus: () => setHasFocus(true),
        onInputBlur: () => setHasFocus(false)
      })
    );

    const actualMessage = message || (ctx ? ctx.i18n.getLabelMessage(path) : null);

    return (
      <FlattenedFieldLayout
        appendLabelContent={appendLabelContent}
        className={className}
        style={style}
        cols={cols}
        customErrorClass={customErrorClass}
        customInputClass={customInputClass}
        customLabelClass={customLabelClass}
        disabled={disabled}
        disableIndent={disableIndent}
        emptyLabel={emptyLabel}
        error={error}
        fieldClassName={fieldClassName}
        fieldStyle={fieldStyle}
        fieldTooltip={fieldTooltip}
        fieldTooltipHeader={fieldTooltipHeader}
        fieldTooltipPlacement={fieldTooltipPlacement}
        fieldTooltipVariant={fieldTooltipVariant}
        fieldTooltipVisible={fieldTooltipVisible}
        hasError={hasError}
        hasFocus={hasFocus}
        helpLink={helpLink}
        hidden={((options || {}).type || {}).hidden}
        htmlFor={configHtmlFor || htmlFor}
        ignoreErrorStyle={ignoreErrorStyle}
        inlineLabel={inlineLabel}
        inlineLabelCols={inlineLabelCols}
        inlineLabelMobileStack={inlineLabelMobileStack}
        isRequired={(typeInfo || {}).isMaybe}
        labelValues={labelValues}
        message={actualMessage}
        overrideLabel={overrideLabel}
        rightLabel={rightLabel}
        footerLabel={footerLabel}
        showRequiredLabel={showRequiredLabel}
        toolTipMessage={toolTipMessage}
        willHideLabel={willHideLabel}
      >
        {children}
      </FlattenedFieldLayout>
    );
  }
);

export default FieldLayout;

export const StandaloneFieldLayout = ({
  children,
  config,
  label,
  cols, // Pulling this out so it get passed on
  ...props
}) => (
  <FieldLayout config={config || {}} message={label} {...props}>
    {children}
  </FieldLayout>
);
