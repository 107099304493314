/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React from 'react';
import PropTypes from 'prop-types';
import Icon, { IconTypes } from 'components/Icon';
import './index.scss';

function HelpLink(props) {
  let iconType = props.iconType || IconTypes.Question;
  let size = props.size || null;

  let content = props.children;
  if (!content) {
    content = (
      <Icon type={iconType} size={size} />
    );
  }

  return (
    <div className="help-link">
      <a href={props.href} target="_help">
        {content}
      </a>
    </div>
  );
}

HelpLink.propTypes = {
  /**
   * Optional alternate icon type to use (from constants IconTypes)
   */
  iconType: PropTypes.string,
  size: PropTypes.string,
  /**
   * Url to the help page
   */
  href: PropTypes.string.isRequired
};

export default HelpLink;
