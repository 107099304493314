/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { createApiAction } from 'api/core';

const createResetPassword = createApiAction({
  method: 'post',
  url: '/ajax/resetPassword/create',
  requestBodyMapper: ({
    emailAddress
  }) => ({
    emailAddress,
    isResend: false
  }),
  responseMapper: ({ success, response }) => {
    if (!success || !response) return {};

    const { isLocked } = response;

    if (isLocked) {
      return {
        result: { failure: 'Locked' }
      };
    }

    return {
      result: { initiated: true }
    };
  }
});

export default createResetPassword;