/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, { Component } from 'react';
import { connect, connectAdvanced } from 'react-redux';
import { isEqual, isFunction, omit, omitBy, pickBy } from 'lodash';
import hash from 'object-hash';
import {
  areApiResultEntitiesUpdated,
  createEntryApiResultReference,
  getApiResultEntities,
  getApiResultError,
  getApiResultRequestParams,
  getApiResultPagination,
  getEntryApiResult,
  hasApiResultCompleted,
  invalidateEntryApiResults,
  isApiResultLoading,
  isEntryApiResultUpdated
} from 'api/core/utils.state';
import logError from 'services/ErrorService';
import LoadingIndicator from 'components/LoadingIndicator';
import ProblemErnie from 'components/ProblemErnie';

export const createDefaultGetId = ({ correlationId, id }) => `${correlationId}-${id}`;

/**
 * See ApiResultDataSource.README.md
 */
const createDataSource = ({
  apiConfig: originalApiConfig,
  correlationId: originalCorrelationId,
  getId: originalGetId,
  mapStateToProps,
  mergeProps
}) => {
  if (!originalCorrelationId) {
    throw new Error('Missing a correlationId');
  }

  const createCorrelationId = isFunction(originalCorrelationId)
    ? props => originalCorrelationId(props || {})
    : () => originalCorrelationId;

  const rootCreateId = originalGetId || (({ id }) => createDefaultGetId({
    correlationId: createCorrelationId(),
    id
  }));

  const apiConfig = originalApiConfig.filter((config) => {
    // Start by doing a little bit of validation and filtering to only get good API Configs
    if (!config) return false;

    if (!config.endpoint && !config.selectEndpoint) {
      // Log an error and filter it out since we will never be able to execute anything with this config.
      logError(
        'API Result Data Source created without an endpoint.',
        { correlationId: createCorrelationId() }
      );
      return false;
    }

    if (config.endpoint && config.selectEndpoint) {
      logError(
        'API Result Data Source created with both an endpoint and selectEndpoint. Pick one.',
        { correlationId: createCorrelationId() }
      );
      return false;
    }

    if (isFunction(config.selectEndpoint) && !config.name) {
      // We can normally utilize the endpoint name to generate a unique name, but we can't if the endpoint is dynamically selected.
      logError(
        'API Result Data Source with function endpoint needs a name if you have selectEndpoint.',
        { correlationId: createCorrelationId() }
      );
      return false;
    }

    if (!config.mapToProps) {
      // It's possible this is very heavy handed to make mapToProps required, but the way I see it,
      // this whole thing is useless if you aren't mapping in props
      console.warn(
        'API Result Data Source created without a mapToProps.',
        createCorrelationId()
      );
      return false;
    }

    return true;
  }).reduce(
    (val, config) => ([
      ...val,
      {
        ...config,
        getEndpoint: (getParams) => { // Making the param a function as 99% of the time, we don't need the params
          if (config.selectEndpoint) return config.selectEndpoint(getParams());
          return config.endpoint;
        },
        // Infusing the getID onto the config
        getId: props => (config.getId || rootCreateId)({
          correlationId: createCorrelationId(props),
          id: config.name || config.endpoint.config.name,
          props
        })
      }
    ]),
    []
  );

  if (apiConfig.some(x => x.getParams == null)) {
    console.warn(`getParams is not defined, it should be set to denote if, when, and how the data should be automatically retreived. Return or set to false if you don't want to automatically do anything. CorrelationId=${createCorrelationId()}`);  //eslint-disable-line max-len
  }

  const bindStateToApplyStateToParams = (applyFunc, state) => {
    if (!applyFunc) return null;

    return props => applyFunc(state, props);
  };

  const applyStateToParams = (applyFunc, props) => {
    if (!applyFunc) return props;

    return applyFunc(props);
  };

  const evaluateGetParams = (
    getParams,
    props,
    {
      mapStateToParams
    } = {}
  ) => {
    if (getParams === false) return false;
    if (getParams === true) return {};
    // If anything bad gets returned from getParams, that's on you.
    // Note that returning 'true' here doesn't have the same behavior as if you statically set
    // getParams as true. Just return an empty object as that makes more sense when executing this
    // function. Conversely, you can return (and should) return false/null/anything falsy to not
    // execute.
    return isFunction(getParams)
      ? getParams(applyStateToParams(mapStateToParams, props))
      : false;
  };

  const executeRequest = (
    { getEndpoint, getId, getParams, referenceId },
    // If params are provided, then those are used, if props are provided, then getParams is
    // executed on it to calculate the props
    { params, props, mapStateToParams },
    metadata
  ) => (dispatch) => {
    const id = getId(props);

    let requestParams = params;

    if (!requestParams) {
      const defaultParams = evaluateGetParams(
        getParams,
        props,
        {
          mapStateToParams
        }
      );

      if (!defaultParams) throw new Error('No params were returned from getParams.');

      requestParams = defaultParams;
    }

    // getEndpoint gets the same props as getParams does
    const endpoint = getEndpoint(
      () => applyStateToParams(mapStateToParams, props)
    );

    if (!endpoint) {
      console.error('No endpoint provided, ignoring execution. Utilize getParams to indicate no execution should happen.'); //eslint-disable-line max-len
      return;
    }

    try {
      dispatch(endpoint(
        requestParams,
        (metadata || {}).onComplete,
        createCorrelationId(props),
        {
          // Excluding the nonce field so that the additional part of the ID can be the same
          // between executions since I want to be able to lookup previous results with the same
          // request params. This is statically defined until we have a case where there is another
          // 'cache busting' param, then this will move to apiConfig.
          apiResultId: `${id}-${hash(requestParams.nonce ? omit(requestParams, ['nonce']) : requestParams)}`,
          saveResult: true,
          entryApiResultId: id,
          // This is secret behavior to allow for the use of reference actions that are being ported
          // to the ApiResultDataSource
          reference: referenceId ? {
            name: referenceId,
            selector: e => e
          } : null
        }
      ));
    } catch (e) {
      logError('Error occurred when trying to dispatch the request', {
        error: e
      });
    }
  };

  // Invalidate is the concept that replaces 'alwaysRefresh', which would have been better named
  // as 'alwaysRefreshOnMount'. To continue supporting that behavior, but in a way that removes the
  // onMount distinction (no bueno for replayability and generally makes why a call needs to be made
  // artifically harder to determine), we are going to 'update' ApiResult entities so that
  // shouldCall should evaluate to true when the component mounts. Now its all in redux for
  // debugging purposes.
  //
  // There's a good chance this will expose issues with your component unmounting when it shouldn't
  // so this might actually do some good to highlight problems with unnecessary renders.
  const executeInvalidate = props => (dispatch) => {
    const idsToInvalidate = apiConfig
      .filter(({ invalidateOnUnmount }) => invalidateOnUnmount)
      .map(({ getId }) => getId(props));

    if (!idsToInvalidate.length) return;

    dispatch(invalidateEntryApiResults(idsToInvalidate, true));
  };

  // This is the magical part of this component - executing the endpoint when appropriate so you
  // don't need to.
  const calculateShouldCall = ({
    getId,
    getParams
  }, {
    hasError,
    isLoading,
    isUpdated,
    mapStateToParams,
    props,
    requestParams
  }) => {
    // We don't need to call if the call is already being made. This should be tied as close to if
    // the API call is in flight as possible.
    // JL Note: This could be made oh so slightly more interesting if it were to compare the
    // in-flight request params to the calculated request params and evaluate to true if there are
    // new params so that another request is made with the new params. We'd need to make sure the
    // previous call goes the way of the dinosaur, but this would make this function more accurate
    // and helpful.
    if (isLoading) return false;

    // Get the current params. Anything falsy immediately disqualifies the endpoint from being
    // called.
    const params = evaluateGetParams(
      getParams,
      props,
      { mapStateToParams }
    );
    if (!params) return false;

    // This is slightly interesting - if you have an error with the previous call, if your request
    // params change, we will try to call again.
    if (hasError && isEqual(requestParams, params)) return false;

    // If there is an update to the entities or the request params change, make the call.
    return isUpdated || !isEqual(requestParams, params);
  };

  const transformGetConfig = (config, props) => {
    if (!config) return config;
    if (config.filter) {
      const newStyleFilter = config.filter;
      config.filter = ({ id, type }) => newStyleFilter({ entityId: id, entityType: type, props });
    }

    return config;
  };

  const getResultState = (state, props, { additionalParams, config, dispatch }) => {
    const {
      getId,
      getParams,
      mapStateToParams,
      mapToProps
    } = config;

    const entryApiResultId = getId(props);

    const entryApiResult = getEntryApiResult(state, entryApiResultId) || {};
    const { id: currentApiResultId } = entryApiResult.current || {};

    const isLoading = isApiResultLoading(state, currentApiResultId);
    const responseError = getApiResultError(state, currentApiResultId);
    const isUpdated = isEntryApiResultUpdated(state, entryApiResultId)
      || areApiResultEntitiesUpdated(state, currentApiResultId);

    const getResults = getConfig => getApiResultEntities(
      state,
      currentApiResultId,
      transformGetConfig(getConfig, props)
    );

    const resultProps = mapToProps({
      additionalParams: additionalParams || {},
      dispatch,
      executeRequest: (params, metadata) => dispatch(executeRequest(
        config,
        {
          mapStateToParams: bindStateToApplyStateToParams(mapStateToParams, state),
          params,
          props
        },
        metadata
      )),
      getHasCompleted: getConfig => hasApiResultCompleted(
        state,
        currentApiResultId,
        transformGetConfig(getConfig, props)
      ),
      getIsLoading: getConfig => isApiResultLoading(
        state,
        currentApiResultId,
        transformGetConfig(getConfig, props)
      ),
      getResult: getConfig => (getResults(getConfig) || [])[0],
      getResults,
      getPagination: getConfig => getApiResultPagination(
        state,
        currentApiResultId,
        transformGetConfig(getConfig, props)
      ),
      isLoading,
      props,
      responseError,
      state
    });

    const shouldCall = calculateShouldCall(
      config,
      {
        hasError: !!responseError,
        isLoading,
        isUpdated,
        mapStateToParams: bindStateToApplyStateToParams(mapStateToParams, state),
        props,
        requestParams: getApiResultRequestParams(state, currentApiResultId)
      }
    );

    return {
      config,
      id: config.getId(props),
      // If mapToProps returns hasError, defer to that value
      hasError: resultProps.hasError != null
        ? resultProps.hasError
        : !!responseError,
      // If mapToProps returns isLoading, defer to that value
      isLoading: resultProps.isLoading != null
        ? resultProps.isLoading
        : isLoading || !!(
          !hasApiResultCompleted(state, currentApiResultId)
          && evaluateGetParams(
            getParams,
            props,
            {
              mapStateToParams: bindStateToApplyStateToParams(mapStateToParams, state)
            }
          )
        ),
      resultProps,
      shouldCall
    };
  };

  const createBaseDataSource = ({
    additionalParams,
    errorErnieProps,
    isChildComponent,
    loadingIndicatorProps,
    showLoadingIndicator,
    showErrorErnie
  }) => {
    const omitInternalFunctions = obj => omitBy(
      obj,
      (val, key) => isFunction(val) && key.startsWith('_')
    );

    const arePropsEqual = (prevProps, nextProps) => {
      // Filtering out internal functions _invalidate and _execute because they change with each
      // render and they are not good indicators of something actually changing.
      return isEqual(
        omitInternalFunctions(prevProps),
        omitInternalFunctions(nextProps)
      );
    };

    // The name of the game for this connect is to pass as few props as possible and to make
    // prev-now prop comparisons fast and accurate. This effectively can stand in for a
    // shouldComponentUpdate
    const connectRootDataSource = connectAdvanced((dispatch) => {
      let prevResult = {};

      return (nextState, nextOwnProps) => {
        const state = apiConfig.map(config => getResultState(
          nextState,
          nextOwnProps,
          {
            additionalParams,
            config,
            dispatch
          }
        ));

        const {
          hasError,
          isLoading,
          shouldCall
        } = state.reduce((val, result) => ({
          hasError: val.hasError || result.hasError,
          isLoading: val.isLoading || result.isLoading,
          shouldCall: result.shouldCall ? [ ...val.shouldCall, result.id ] : val.shouldCall
        }), { shouldCall: [] });

        const nextResult = mergeProps
          ? mergeProps(nextOwnProps, state.map(({ config, resultProps }) => ({
            config, // Pass in the config if you want to pull off something useful there
            props: resultProps
          }))) : {
            ...nextOwnProps,
            ...state.reduce((val, { resultProps }) => ({ ...val, ...resultProps }), {})
          };

        // Update props like these are only used by the master component
        if (!isChildComponent) {
          // JL Note: I'm a little weirded out by binding nextOwnProps to the execution of this
          // request because I'm worried changes to nextOwnProps won't cause this function to be
          // recreated, but I think the shallowEqual below should cause any prop changes to result in
          // execute being changed as well.
          nextResult._execute = (id) => {
            const { config } = state.find(({ id: configId }) => configId === id) || {};
            if (!config) return;
            dispatch(executeRequest(
              config,
              {
                props: nextOwnProps,
                mapStateToParams: bindStateToApplyStateToParams(config.mapStateToParams, nextState)
              }
            ));
          };
          nextResult._invalidate = () => dispatch(executeInvalidate(nextOwnProps));
          nextResult._shouldCall = shouldCall;
        }

        // Only set these props if they are used by the wrapper component
        if (showErrorErnie) nextResult._hasError = hasError;
        if (showLoadingIndicator) nextResult._isLoading = isLoading;

        if (arePropsEqual(prevResult, nextResult)) return prevResult;
        prevResult = nextResult;
        return nextResult;
      };
    }, {
      getDisplayName: () => `ConnectedApiResultDataSource-${createCorrelationId()}`
    });

    // To keep 'yours' and 'mine' props distinct, all of the internal 'mine' props of this component
    // are prefixed with an underscore and are omitted when being passed down to the InnerComponent.
    // I chose this implementation over passing 'your' props in its own prop because I feel like this
    // is cleaner for prop difference checks (a prop with an object vs the props being spread at the
    // root), though I will grant that 'pickBy' has a greater potential execution time.
    const filterInternalProps = props => pickBy(props, (val, key) => !key.startsWith('_'));

    return (InnerComponent) => {
      class ApiResultDataSource extends Component {
        componentDidMount() {
          this.refreshDataIfNeeded({ _shouldCall: [] });
        }

        componentDidUpdate(prevProps) {
          this.refreshDataIfNeeded(prevProps);
        }

        componentWillUnmount() {
          const { _invalidate } = this.props;

          if (!_invalidate) return;

          _invalidate();
        }

        refreshDataIfNeeded({ _shouldCall: prevShouldCall }) {
          const { _execute, _shouldCall } = this.props;

          if (!_execute) return;

          // If an id is present in _shouldCall, then it should be called. We use the fact it wasn't
          // present in the previous _shouldCall as the limiting factor to only do this once.
          _shouldCall.forEach((id) => {
            if (!prevShouldCall.includes(id)) {
              // _execute is bound with the props that were passed into the wrapping component so
              // any props that are generated from mapToProps doesn't interfere with getParams
              _execute(id);
            }
          });
        }

        render() {
          const { _hasError, _isLoading } = this.props;

          if (showErrorErnie && _hasError) {
            return <ProblemErnie {...(errorErnieProps || {})} />;
          } if (showLoadingIndicator && _isLoading) {
            return <LoadingIndicator {...(loadingIndicatorProps || {})} />;
          }

          return (
            <InnerComponent
              {...filterInternalProps(this.props)}
            />
          );
        }
      }

      const ConnectedDataSource = connectRootDataSource(ApiResultDataSource);

      if (mapStateToProps) {
        return connect(mapStateToProps)(
          ConnectedDataSource
        );
      }

      return ConnectedDataSource;
    };
  };

  return (params) => {
    const createMasterDataSource = createBaseDataSource({
      ...params,
      isChildComponent: false
    });

    // See createBaseDataSource for the params that are available to set
    createMasterDataSource.createChildWrapper = childParams => createBaseDataSource({
      ...childParams,
      isChildComponent: true
    });

    createMasterDataSource.getEntryApiResultReference = (id, props) => createEntryApiResultReference(
      rootCreateId({ id, props: props || {} })
    );

    return createMasterDataSource;
  };
};

// No need to impose double function execution to configure the two parts of the ApiResultDataSource
// (the common part and the base part), each part has params that don't collide so there's no
// problem just defining them in a single object to setup the master.
// See createDataSource createBaseDataSource for the params that are available to set
export default params => createDataSource(params)(params);
