/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import t from 'tcomb-validation';
import { defineSchema } from 'schemas';

export const CompanyTask = defineSchema({
  id: t.String,
  taskEntityType: t.maybe(t.String),
  taskEntityId: t.maybe(t.String),
  message: t.maybe(t.String),
  isCompleted: t.maybe(t.Boolean)
}, 'CompanyTask');
