/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import getApplicationSetting, { AppSetting } from 'actions/app/getApplicationSetting';
import { setPublishableKey } from 'api/stripe';

const StripeSettingsService = () => {
  // Hooks
  const publishableKey = useSelector(
    state => getApplicationSetting(state, AppSetting.Stripe, { notExpected: true }).publishableKey
  );

  useEffect(
    () => {
      if (publishableKey) {
        setPublishableKey(publishableKey);
      }
    },
    [ publishableKey ]
  );

  // Render
  return <></>;
};

export default StripeSettingsService;
