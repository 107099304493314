/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './index.scss';

export default class Badge extends Component {
  static propTypes = {
    affix: PropTypes.string,
    type: PropTypes.oneOf(['primary', 'secondary', 'attention'])
  };

  static defaultProps = {
    type: 'primary'
  };

  constructor(props) {
    super(props);
  }

  render() {
    let classes = classNames('vp-badge', this.props.type);

    // Viewstrap applies a white border to badges for some reason
    let style = {
      borderStyle: 'initial',
      // TODO: Add this to viewstrap styles
      borderColor: !this.props.type ? '#66bbee' : undefined
    };

    // TODO: I know this shouldn't be a badge's concern, but we don't have
    // a clear pattern on how to absolutely position a component
    if (this.props.affix === 'top-right') {
      style = {
        ...style,
        position: 'absolute',
        top: '-10px',
        right: '-12px',
        zIndex: 1
      };
    }

    return (
      <span className={classes} style={style}>{this.props.children}</span>
    );
  }
}
