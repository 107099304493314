/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */
/* eslint max-len:0 */

import { toMessages } from 'config/messages';

export default toMessages('LogonWorkflow.CredentialsForm', {
  ResetPassword: 'Forgot password?',
  Submit: 'Log In',
  UserName: 'Email Address',
  Password: 'Password',
  InvalidUserName: 'Sorry, we don\'t recognize that email address.',
  InvalidPassword: 'Incorrect username or password. As a reminder, passwords must be 8-32 characters, contain at least one uppercase letter and one lowercase letter, and include one number or one symbol.',
  DoesNotBelongToCompany: 'The email link is not associated with this account. Try another account or press \'Log In\' again to ignore and log in normally.',
  TooManyRequests: 'You have tried logging in too many times too quickly. Try logging in later.'
});
