/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, { Component, Children, Fragment } from 'react';
import PropTypes from 'prop-types';
import { isObject } from 'lodash';
import ReactDOM from 'react-dom';
import { defaultOptions } from 'components/Form/utils';
import classNames from 'classnames';
import FieldOption from 'components/Form/FieldOption';
import { hasFieldOptions } from 'components/Form/FieldSection';
import FormLegend from 'components/FormLegend';
import ProvideIsMobile from 'decorators/ProvideIsMobile';
import t from 'tcomb-form';
import './Containers/FieldLayout.scss';
const TForm = t.form.Form;

export function SectionHeading({ icon, label }) {
  return (
    <div style={{ fontSize: '18px', padding: '8px', backgroundColor: '#f7f7f7' }}>
      <div style={{ display: 'inline-block', verticalAlign: 'middle' }}>
        {icon}&nbsp;
      </div>
      <div style={{ display: 'inline-block', verticalAlign: 'middle' }}>
        {label}
      </div>
    </div>
  );
}

// Exporting this because 100% of the times FormJSX is used, FieldOption is also used
export { FieldOption };

class FormJSX extends Component {
  static propTypes = {
    /** The schema that defines the form structure */
    modelType: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
    /** The object that describes the state of the form */
    value: PropTypes.object,
    /** Called on every change event. The arguement is an object describing the state of the form. */
    onChange: PropTypes.func,
    /** Any custom configurations to be passed to the Form */
    customConfig: PropTypes.object,
    /** Wrap the tcomb-form in a <form> element,
      * allowing you to render a <button> as a child with type="submit" for handling the 'enter' key
      */
    hasSubmitButton: PropTypes.bool,
    /** Show a red asterisk next to all required fields. Also show the legend at the top of the form */
    showRequiredLabels: PropTypes.bool,
    /** Hide the form legend even if showRequiredLabels is true */
    hideFormLegend: PropTypes.bool,
    /** Apply a single onFocus function to all form fields */
    onFocus: PropTypes.func,
    /** Apply a single onBlur function to all form fields */
    onBlur: PropTypes.func,
    disabled: PropTypes.bool,
    style: PropTypes.object,
    /** If getValue() is called and a field is invalid, scroll the window to ensure it is visible */
    focusInvalidFieldOnValidation: PropTypes.bool
  };

  static defaultProps = {
    customConfig: {},
    hasSubmitButton: false,
    showRequiredLabels: false,
    focusInvalidFieldOnValidation: true
  };

  constructor(props) {
    super(props);
    this.fieldRefs = {};
  }

  // expose tcomb form methods to parent
  validate = () => this.form.validate()

  getValue = () => {
    let value = this.form.getValue();
    if (!value && this.props.focusInvalidFieldOnValidation) {
      let validation = t.validate(this.props.value, this.props.modelType);
      if (validation.errors.length && validation.errors[0].path) {
        let fieldRef = ReactDOM.findDOMNode(this.fieldRefs[validation.errors[0].path[0]]);
        if (fieldRef) {
          let offset = window.scrollY + fieldRef.getBoundingClientRect().top;
          window.scrollTo(0, offset);
        }
      }
    }
    return value;
  }

  shouldDisableSubmitButton() {
    let validation = t.validate(this.props.value, this.props.modelType);
    if (validation.errors.length) {
      return validation.errors.reduce((prev, error) => prev || error.actual === '', false);
    }
    return false;
  }

  getFormOptions = () => {
    let sectionedChildren = this.getSectionedChildren(this.props.children);
    let nestedOptions = this.processFields(sectionedChildren);

    let formConfig = {
      ...this.props.customConfig,
      structProps: this.props.structProps,
      i18n: this.processLocales(sectionedChildren),
      isMobile: this.props.isMobile,
      ...nestedOptions
    };

    return defaultOptions(this.props.modelType, formConfig);
  }

  getSectionedChildren(childArray) {
    let sectionedChildArray = [];
    let sectionId = 0;

    Children.forEach(childArray, (child) => {
      if (!child) {
        // handle null children
        return;
      }

      if (child.type === Fragment) {
        const innerSectionChildArray = this.getSectionedChildren(
          child.props.children
        );

        sectionedChildArray = sectionedChildArray.concat(innerSectionChildArray);
        return;
      }

      if (!hasFieldOptions(child.type)) {
        sectionedChildArray.push(child);
        return;
      }

      // manually run (instantiate) children (instead of rendering them)
      // (because they are only configuration functions and not intended to be react elements)
      const {
        id,
        children: sectionChildren,
        ...sectionProps
      } = child.type(child.props);

      if (sectionChildren && Children.count(sectionChildren)) {
        sectionId += 1;

        Children.forEach(sectionChildren, (sectionChild) => {
          if (sectionChild) {
            sectionedChildArray.push({
              ...sectionChild,
              // store section metadata
              section: {
                ...sectionProps,
                sectionId: id || sectionId
              }
            });
          }
        });
      }
    });

    return sectionedChildArray;
  }

  processLocales(childArray, prefix) {
    if (!childArray || !Children.count(childArray) || !isObject(childArray)) {
      return {};
    }

    let locales = {
      labelMessages: {},
      placeholderMessages: {}
    };

    Children.forEach(childArray, (child) => {
      if (!child) {
        // skip over null children
        return;
      }

      if (!isObject(child)) {
        console.warn(`Unsupported child: ${child}`);
        return;
      }

      if (child.type === Fragment) {
        const innerLocales = this.processLocales(
          child.props.children,
          prefix
        );

        locales.labelMessages = {
          ...locales.labelMessages,
          ...innerLocales.labelMessages
        };
        locales.placeholderMessages = {
          ...locales.placeholderMessages,
          ...innerLocales.placeholderMessages
        };
        return;
      }

      let prefixedName = (prefix) ? `${prefix}.${child.props.name}` : child.props.name;
      if (child.props.label) {
        locales.labelMessages[prefixedName] = child.props.label;
      }
      if (child.props.placeholder) {
        locales.placeholderMessages[prefixedName] = child.props.placeholder;
      }
      if (child.type === FieldOption && child.props.children && Children.count(child.props.children)) {
        let nestedLocales = this.processLocales(child.props.children, prefixedName);
        locales.labelMessages = {...locales.labelMessages, ...nestedLocales.labelMessages};
        locales.placeholderMessages = {...locales.placeholderMessages, ...nestedLocales.placeholderMessages};
      }
    });

    return locales;
  }

  processFields(childArray, prefix) {
    let fields = {};
    let order = [];

    Children.forEach(childArray, (child) => {
      if (!child) {
        // skip over null children
        return;
      }

      if (child.type === Fragment) {
        const {
          order: innerOrder,
          fields: innerFields
        } = this.processFields(
          child.props.children,
          prefix
        );

        fields = { ...fields, ...innerFields };
        order = order.concat(innerOrder);
        return;
      }

      if (child.type !== FieldOption) {
        // skip over custom components (non FieldOptions)
        order.push(child);
        return;
      }

      order.push(child.props.name);

      // manually run (instantiate) children (instead of rendering them)
      // (because they are only configuration functions and not intended to be react elements)
      let childConfig = child.type(child.props);

      // build the json options
      let fieldName = childConfig.name;
      fields[fieldName] = {};
      if (childConfig.config) {
        let config = {
          showRequiredLabel: childConfig.showRequiredLabel || this.props.showRequiredLabels,
          onFocus: this.props.onFocus ? this.props.onFocus : null,
          onBlur: this.props.onBlur ? this.props.onBlur : null,
          disabled: this.props.disabled ? this.props.disabled : null,
          inputWrapperProps: {
            ref: ref => this.fieldRefs[child.props.name] = ref
          },
          ...childConfig.config,
          section: child.section
        };
        fields[fieldName].config = config;
      }
      if (childConfig.type) {
        fields[fieldName].type = childConfig.type;
      }

      if (childConfig.children && Children.count(childConfig.children)) {
        fields[fieldName] = {
          ...this.processFields(childConfig.children, fields[fieldName], fieldName),
          ...fields[fieldName]
        };
      }
    });

    return { order, fields };
  }

  getFormClasses() {
    return classNames('vp-form', this.props.className);
  }

  render() {
    let formOptions = this.getFormOptions();
    let requiredFieldPresent = false;
    if (this.props.modelType && this.props.showRequiredLabels) {
      for (let fieldName in this.props.modelType.meta.props) {
        if (this.props.modelType.meta.props.hasOwnProperty(fieldName)) {
          let kind;
          let innerType = this.props.modelType.meta.props[fieldName];
          while (innerType) {
            kind = innerType.meta.kind;
            if (kind === 'maybe') {
              innerType = innerType.meta.type;
              break;
            }
            if (kind === 'subtype') {
              innerType = innerType.meta.type;
              continue;
            }
            requiredFieldPresent = true;
            break;
          }
        }
      }
    }
    let tform = (
      <div>
        {this.props.showRequiredLabels && requiredFieldPresent && !this.props.hideFormLegend ? <FormLegend /> : null}
        <TForm
          ref={r => this.form = r}
          type={this.props.modelType}
          options={formOptions}
          value={this.props.value}
          onChange={this.props.onChange}
        />
      </div>
    );

    return (
      <div style={this.props.style} className={this.getFormClasses()}>
        {this.props.hasSubmitButton
          ? <form>
            {tform}
          </form>
          : tform}
      </div>
    );
  }
}

// Making a component out of this so that it can potentially be omitted in responsive situations
export const EmptyColumn = ({ cols }) => <div>&nbsp;</div>;

export default ProvideIsMobile(FormJSX);
