/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import t from 'tcomb-validation';

import { createApiAction } from 'api/core';
import { determineAvailableActionsFromState } from 'api/permissions';

import NetworkActions from 'config/constants/network/NetworkActions';
import EntityContext from 'config/constants/schemas/EntityContext';

import { defineSchema } from 'schemas';
import { createAvailableActionsType } from 'schemas/common/availableActions';
import { createEntity } from 'schemas/state';

const NavigationData = defineSchema(
  {
    billsInboxCount: t.Number,
    billsEarlyPayCount: t.Number,
    connectionRequestCount: t.Number,
    invoiceEarlyPayCount: t.Number,
    paymentQueueCount: t.Number,
    approvalQueueCount: t.maybe(t.Number),
    receivedConnectionRequests: t.list(t.struct({
      connectionRequestId: t.String,
      companyId: t.String,
      companyName: t.String,
      relationship: t.String,
      availableActions: createAvailableActionsType(NetworkActions)
    })),
    tasks: t.list(t.struct({
      controlType: t.String,
      id: t.String,
      domainId: t.String,
      message: t.String
    })),
    totalTaskCount: t.Number
  },
  'NavigationData',
  { contexts: [ EntityContext.Navigation ] }
);

const getNavigationData = createApiAction({
  method: 'get',
  url: '/api/webApp/navigation',
  responseMapper: ({ receivedConnectionRequests, ...response }, state) => ({
    entities: [
      createEntity(
        'current',
        NavigationData.meta.name,
        {
          ...response,
          receivedConnectionRequests: receivedConnectionRequests.map(request => ({
            ...request,
            availableActions: determineAvailableActionsFromState(
              state,
              [ NetworkActions.Accept, NetworkActions.Decline ]
            ).availableActions
          }))
        }
      )
    ]
  })
});

export default getNavigationData;