/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import t from 'tcomb-validation';

const isValidMatchingEmail = (value1, value2) => !!value1
  && !!value2
  && value1.toLowerCase() === value2.toLowerCase();

const createMatchingEmail = (value1) => {
  const MatchingEmail = t.subtype(
    t.String,
    value2 => isValidMatchingEmail(value1, value2),
    'MatchingEmail'
  );

  MatchingEmail.getValidationErrorMessage = (value2, path, context) => {
    if (!isValidMatchingEmail(value1, value2) && context && context.intl) {
      return 'Emails must match';
    }

    return '';
  };

  return MatchingEmail;
};

export default createMatchingEmail;