/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { createContext } from 'react';

const AnalyticsContext = createContext({
  category: null,
  urlToken: null
});

export default AnalyticsContext;
