/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React from 'react';

import Button from 'components/Button';
import PathLink from 'components/Link/PathLink';
import Image from 'components/Viewstrap/Image';

import { Knight } from 'config/images/ernie';

const AccessDenied = ({
  children,
  ernieImage,
  onComplete,
  submitMessage
}) => {
  return (
    <div>
      <Image image={ernieImage || Knight} willCenter={true} />
      <p style={{ marginTop: '3rem' }}>
        {children}
      </p>
      {onComplete ? (
        <Button
          onClick={onComplete}
          expand={true}
        >
          {submitMessage}
        </Button>
      ) : (
        <PathLink.Logon button={{ expand: true }}>
          {submitMessage}
        </PathLink.Logon>
      )}
    </div>
  );
};

export default AccessDenied;
