/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import t from 'tcomb-validation';
import { createMaxLengthStringType } from 'schemas/common/string';
import messages from './address.messages';

const USStates = t.enums({
  AL: 'Alabama',
  AK: 'Alaska',
  AS: 'American Samoa',
  AZ: 'Arizona',
  AR: 'Arkansas',
  CA: 'California',
  CO: 'Colorado',
  CT: 'Connecticut',
  DE: 'Delaware',
  DC: 'District of Columbia',
  FL: 'Florida',
  FM: 'Micronesia',
  GA: 'Georgia',
  GU: 'Guam',
  HI: 'Hawaii',
  ID: 'Idaho',
  IL: 'Illinois',
  IN: 'Indiana',
  IA: 'Iowa',
  KS: 'Kansas',
  KY: 'Kentucky',
  LA: 'Louisiana',
  ME: 'Maine',
  MD: 'Maryland',
  MA: 'Massachusetts',
  MH: 'Marshall Islands',
  MI: 'Michigan',
  MN: 'Minnesota',
  MS: 'Mississippi',
  MO: 'Missouri',
  MP: 'Northern Mariana Islands',
  MT: 'Montana',
  NE: 'Nebraska',
  NV: 'Nevada',
  NH: 'New Hampshire',
  NJ: 'New Jersey',
  NM: 'New Mexico',
  NY: 'New York',
  NC: 'North Carolina',
  ND: 'North Dakota',
  OH: 'Ohio',
  OK: 'Oklahoma',
  OR: 'Oregon',
  PA: 'Pennsylvania',
  PR: 'Puerto Rico',
  PW: 'Palau',
  RI: 'Rhode Island',
  SC: 'South Carolina',
  SD: 'South Dakota',
  TN: 'Tennessee',
  TX: 'Texas',
  UM: 'US Minor Outlying Islands',
  UT: 'Utah',
  VT: 'Vermont',
  VA: 'Virginia',
  VI: 'US Virgin Islands',
  WA: 'Washington',
  WV: 'West Virginia',
  WI: 'Wisconsin',
  WY: 'Wyoming',

  // military overseas addresses
  AA: 'AA - Armed Forces (Americas excluding Canada)',
  AE: 'AE - Armed Forces (Europe, Middle East, Africa, Canada)',
  AP: 'AP - Armed Forces (Pacific)'
}, 'USStates');

const USStatesAbbrv = t.enums({
  AL: 'AL',
  AK: 'AK',
  AS: 'AS',
  AZ: 'AZ',
  AR: 'AR',
  CA: 'CA',
  CO: 'CO',
  CT: 'CT',
  DE: 'DE',
  DC: 'DC',
  FL: 'FL',
  FM: 'FM',
  GA: 'GA',
  GU: 'GU',
  HI: 'HI',
  ID: 'ID',
  IL: 'IL',
  IN: 'IN',
  IA: 'IA',
  KS: 'KS',
  KY: 'KY',
  LA: 'LA',
  ME: 'ME',
  MD: 'MD',
  MA: 'MA',
  MH: 'MH',
  MI: 'MI',
  MN: 'MN',
  MS: 'MS',
  MO: 'MO',
  MP: 'MP',
  MT: 'MT',
  NE: 'NE',
  NV: 'NV',
  NH: 'NH',
  NJ: 'NJ',
  NM: 'NM',
  NY: 'NY',
  NC: 'NC',
  ND: 'ND',
  OH: 'OH',
  OK: 'OK',
  OR: 'OR',
  PA: 'PA',
  PR: 'PR',
  PW: 'PW',
  RI: 'RI',
  SC: 'SC',
  SD: 'SD',
  TN: 'TN',
  TX: 'TX',
  UM: 'UM',
  UT: 'UT',
  VT: 'VT',
  VA: 'VA',
  VI: 'VI',
  WA: 'WA',
  WV: 'WV',
  WI: 'WI',
  WY: 'WY',

  // military overseas addresses
  AA: 'AA',
  AE: 'AE',
  AP: 'AP'
}, 'USStatesAbbrv');

const Countries = t.enums({
  US: 'United States',
  USA: 'United States'
});

let postalCodeRegex = /^\d{5}(\-?\d{4})?$/;
function isValidPostalCode(value) {
  return postalCodeRegex.test(value);
}

const PostalCode = t.subtype(t.String, (value) => {
  return isValidPostalCode(value);
}, 'PostalCode');

PostalCode.getValidationErrorMessage = (value, path, context) => {
  if (!isValidPostalCode(value) && context && context.intl) {
    return context.intl.formatMessage(messages.Invalid);
  }

  return '';
};

const isValid = (value, regex) => {
  if (!value) {
    return false;
  }

  return regex.test(value);
};

/**
 * Accented Chars for better INTL address support
 *
 * JC note: Since its hard to know what characters might be used for INTL addresses,
 *          I'm just going to allow a large collection of extended latin characters
 *
 * Latin-1 Supplement (https://en.wikipedia.org/wiki/Latin-1_Supplement_(Unicode_block))
 * \u00C0-\u00FF  (À-ÿ) (NOTE: also annoyingly includes ÷ and ×...fine I guess)
 *
 * Latin Extended-A (https://en.wikipedia.org/wiki/Latin_Extended-A)
 * \u0100-\u017F (Ā-ſ)
 *
 * Latin Extended-B (https://en.wikipedia.org/wiki/Latin_Extended-B)
 * \u0180-\u024F (ƀ-ɏ)
 *
 * Latin Extended Additional (https://en.wikipedia.org/wiki/Latin_Extended_Additional)
 * \u1E00-\u1EFF (Ḁ-ỿ)
 *
 * So...
 * [\u00C0-\u00FF\u0100-\u017F\u0180-\u024F\u1E00-\u1EFF]
 */

// 10/16/2019 JL Note: I am adding a few more non-alphabet characters. Reason tells me to just allow
// anything and everything for the city field, but today I am not bold enough to do so
const cityRegex = /^[a-zA-Z\u00C0-\u00FF\u0100-\u017F\u0180-\u024F\u1E00-\u1EFF\s\.\-']*$/;
const CityStrict = t.subtype(t.String, (value) => {
  return isValid(value, cityRegex) && value.length <= 35;
}, 'CityStrict');

CityStrict.getValidationErrorMessage = (value, path, context, ...args) => {
  if (!value) {
    return 'Please enter a value.';
  }

  if (value.length > 35) {
    return 'Cannot exceed 35 characters';
  }

  if (!isValid(value, cityRegex)) {
    return 'Cannot contain numbers or special characters';
  }

  return '';
};

const addressRegex = /[a-zA-Z\u00C0-\u00FF\u0100-\u017F\u0180-\u024F\u1E00-\u1EFF]+/;
const AddressLineStrict = t.subtype(t.String, (value) => {
  return value && isValid(value, addressRegex) && value.length <= 240;
}, 'AddressLineStrict');

AddressLineStrict.getValidationErrorMessage = (value, path, context, ...args) => {
  if (!value) {
    return 'Please enter a value.';
  }

  if (value.length > 240) {
    return 'Cannot exceed 240 characters';
  }

  if (!isValid(value, addressRegex)) {
    return 'Cannot contain only numbers or special characters';
  }

  return '';
};

const RetrievableAddress = t.struct({
  // raw lines stored for address (possibly containing companyName)
  lines: t.maybe(t.list(t.String)),
  city: t.maybe(t.String),
  stateProvince: t.maybe(t.String),
  postalCode: t.maybe(t.String),
  country: t.maybe(t.String),

  // some users embed the company name (addressee)
  // in the address lines (for mailing purposes, sync, etc)
  // and some views are designed to specially (manually) render the addressee
  // while other views desire to show the lines as-is
  // this alternate lines list is inteded to make it easy for views to render
  // address without addressee (without having to know the strategy to determine that)
  linesWithoutAddressee: t.maybe(t.list(t.String))
}, 'RetrievableAddress');

const LooseAddress = t.struct({
  line1: t.maybe(t.String),
  line2: t.maybe(t.String),
  line3: t.maybe(t.String),
  line4: t.maybe(t.String),
  city: t.maybe(t.String),
  stateProvince: t.maybe(t.String),
  postalCode: t.maybe(t.String),
  country: t.maybe(t.String)
}, 'LooseAddress');

const CreatableAddress = t.struct({
  line1: t.maybe(createMaxLengthStringType(240)),
  line2: t.maybe(createMaxLengthStringType(240)),
  line3: t.maybe(createMaxLengthStringType(240)),
  line4: t.maybe(createMaxLengthStringType(240)),
  city: t.maybe(createMaxLengthStringType(35)),
  stateProvince: t.maybe(USStates),
  postalCode: t.maybe(PostalCode),
  country: t.maybe(Countries)
}, 'CreateableAddress');

const CreatableAddressStrict = t.struct({
  line1: AddressLineStrict,
  line2: t.maybe(createMaxLengthStringType(240)),
  line3: t.maybe(createMaxLengthStringType(240)),
  line4: t.maybe(createMaxLengthStringType(240)),
  city: CityStrict,
  stateProvince: USStates,
  postalCode: PostalCode,
  country: t.maybe(Countries)
}, 'CreatableAddressStrict');

const CreatableAddressAbbrvState = t.struct({
  line1: t.maybe(createMaxLengthStringType(240)),
  line2: t.maybe(createMaxLengthStringType(240)),
  line3: t.maybe(createMaxLengthStringType(240)),
  line4: t.maybe(createMaxLengthStringType(240)),
  city: CityStrict,
  stateProvince: t.maybe(USStatesAbbrv),
  postalCode: t.maybe(PostalCode),
  country: t.maybe(Countries)
}, 'CreatableAddressAbbrvState');

const CreatableAddressAbbrvStateStrict = t.struct({
  line1: AddressLineStrict,
  line2: t.maybe(createMaxLengthStringType(240)),
  line3: t.maybe(createMaxLengthStringType(240)),
  line4: t.maybe(createMaxLengthStringType(240)),
  city: CityStrict,
  stateProvince: USStatesAbbrv,
  postalCode: PostalCode,
  country: t.maybe(Countries)
}, 'CreatableAddressAbbrvStateStrict');


// new generic address model works with AddressInput to handle INTL (and US) addresses
const Address = t.struct({
  line1: createMaxLengthStringType(240),
  line2: t.maybe(createMaxLengthStringType(240)),
  line3: t.maybe(createMaxLengthStringType(240)),
  line4: t.maybe(createMaxLengthStringType(240)),
  city: createMaxLengthStringType(35),
  stateProvince: t.maybe(createMaxLengthStringType(150)),
  postalCode: t.maybe(createMaxLengthStringType(20)),
  country: createMaxLengthStringType(30),
  isValid: t.subtype(t.Boolean, v => v === true)
}, 'Address');


export {
  Countries,
  USStates,
  Address,
  USStatesAbbrv,
  RetrievableAddress,
  LooseAddress,
  CreatableAddress,
  CreatableAddressAbbrvState,
  PostalCode,
  CityStrict,
  CreatableAddressAbbrvStateStrict,
  CreatableAddressStrict,
  AddressLineStrict
};
