/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */
/* eslint max-len:0 */

import { toMessages } from 'config/messages';

export default toMessages('OpenRegistrationLanding', {
  Header: 'Get started, today.',
  Subheader: '{b}Are you a large enterprise or a smaller business?{b} Corporate payment services are designed for businesses with an annual AP spend of $10 million or above. Select the option that’s right for you.',
  CorporateHeader: 'Corporate Payments',
  CorporateSubheader: 'Check Printing & Virtual Cards',
  CorporateDescription: 'Viewpost helps large organizations increase payments efficiency, reduce risk, and earn rebates on supplier payments.',
  CorporateContactUs: 'Contact Us',
  SmallBusinessHeader: 'Small Business',
  SmallBusinessSubheader: 'Invoicing & Payments',
  SmallBusinessDescription: 'Our self-service platform empowers smaller businesses to send, track and manage invoices and payments with ease.'
});
