/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */
/* eslint-disable consistent-return */

import React, { useEffect, useState } from 'react';
import { MOBILE_BREAK } from 'config/constants';

const RenderMobileDecorator = (BaseComponent) => {
  const defaultRender = BaseComponent.prototype.render;

  return React.forwardRef((props, ref) => {
    const [ isMobile, setIsMobile ] = useState(window.innerWidth < MOBILE_BREAK);

    useEffect(() => {
      const handleResize = () => {
        if (isMobile && window.innerWidth >= MOBILE_BREAK) {
          setIsMobile(false);
        } else if (!isMobile && window.innerWidth < MOBILE_BREAK) {
          setIsMobile(true);
        }
      };

      window.addEventListener('resize', handleResize);

      return () => window.removeEventListener('resize', handleResize);
    }, []);

    if (!BaseComponent.prototype.renderMobile) {
      // via props
      return (
        <BaseComponent
          {...props}
          ref={ref}
          isMobile={isMobile}
        />
      );
    }

    // via special method
    BaseComponent.prototype.render = isMobile
      ? BaseComponent.prototype.renderMobile
      : defaultRender;
    return <BaseComponent ref={ref} {...props} />;
  });
};

export default RenderMobileDecorator;
