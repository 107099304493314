/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React from 'react';
import Tooltip from 'components/Tooltip';
import Messages from './index.messages';

const CancellationPolicyTooltip = ({ style }) => (
  <span style={style}>
    <Tooltip
      overlay={<Messages.Tooltip.Message />}
      wide={true}
    >
      <span className="vp-anchor">
        <Messages.Link.Message />
      </span>
    </Tooltip>
  </span>
);

export default CancellationPolicyTooltip;
