/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { defineMessages } from 'react-intl';

export default defineMessages({
  DoNotMatch: {
    id: 'ValidationError.Password.DoNotMatch',
    description: 'validation text for when the confirmation password field doesn\'t match the original',
    defaultMessage: 'The passwords you have entered do not match'
  }
});
