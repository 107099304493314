/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { useMemo } from 'react';

// Just a useMemo in disguise, but with added validation and just being easier to discern what the
// intent is.
// This is a performant way to generate hooks during renders because you might have your component
// instantiated multiple times, but with different params across them (e.g., different entity IDs)
// Defining the hooks outside of the render in that case will cause all sorts of issues with
// useProvideParams fighting over who is right because they all share the same correlationId and
// thus, the same EntryApiResult. This avoids that by aligning hooks with the entity you intend for
// them to serve when you are not dealing with a 'singleton' component situation.
const createUseApiResultHooks = createImpl => (params, deps) => {
  if (!params.endpoint) {
    console.error('Endpoint must be defined');
    return;
  }

  if (!params.id) {
    console.error('useApiResultHooks is missing an id');
    return;
  }

  return useMemo(
    () => createImpl(params),
    [ deps ]
  );
};

export default createUseApiResultHooks;