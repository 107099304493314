/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { dispatchApiCall } from 'actions/references';

import callVerifyPlaidAccount from 'api/paymentAccounts/verifyPlaidAccount';

const verifyPlaidAccount = (
  accessTokenId,
  accountId,
  onComplete,
  onError
) => async (dispatch, getState) => {
  const result = await dispatch(dispatchApiCall(
    'VerifyPlaidAccount',
    callVerifyPlaidAccount,
    {
      accessTokenId,
      accountId,
      time: new Date()
    }
  ));

  const [ success, action ] = result;

  const {
    isInUse,
    messages
  } = action.getResponseBody();

  if (success && !isInUse) {
    onComplete?.();
  } else if (onError) {
    let message;
    if (messages?.length) {
      message = messages[0].message;
    }

    onError(message);
  }

  return [ success, action ];
};

export default verifyPlaidAccount;