/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { uniqueId } from 'lodash';

import { isAuthorizedToUseApp } from 'actions/common';

import updateSession from 'api/session/updateSession';

function MapStateToProps(state) {
  return {
    isAuthorizedToUseApp: isAuthorizedToUseApp(state)
  };
}

function MapDispatchToProps(dispatch) {
  return {
    keepAlive: () => dispatch(updateSession({
      nonce: uniqueId()
    }))
  };
}

export {
  MapStateToProps,
  MapDispatchToProps
};
