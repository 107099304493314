/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */
/* eslint max-len:0 */

import { toMessages } from 'config/messages';

export default toMessages('BeneficalOwnerCollectionForm', {
  TitleLabel: 'Title',
  FirstNameLabel: 'Legal First Name',
  LastNameLabel: 'Legal Last Name',
  Line1Label: '{prefix} Residential Address',
  Line2Label: ' ',
  DateOfBirthLabel: 'Date Of Birth',
  UsCitizenLabel: 'US Citizen?',
  CountryLabel: 'Country Of Residence',
  CountryOfBirthLabel: 'Country Of Birth',
  SSNLabel: `Social Security #`,
  PassportNumberLabel: `Passport #`,
  DocumentTypeLabel: 'Form of identification',
  OtherNumberLabel: `Document #`,
  OtherTypeLabel: `Docment Type`,
  IssuingCountryLabel: `Issuing Country`
});
