/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */
/* eslint max-len:0 */

import { defineMessages } from 'react-intl';

const headers = defineMessages({
  InvitationSent: {
    id: 'Headers.InvitationSent',
    defaultMessage: 'Invitation Sent'
  },
  ConnectionRequestSent: {
    id: 'Headers.ConnectionRequestSent',
    defaultMessage: 'Connection Request Sent'
  },
  InvitationAccepted: {
    id: 'Headers.InvitationAccepted',
    defaultMessage: 'Invitation Accepted'
  },
  AddressBookDeleted: {
    id: 'Headers.AddressBookDeleted',
    defaultMessage: 'Company Deleted'
  },
  UserInvitationSent: {
    id: 'Headers.UserInvitationSent',
    defaultMessage: 'User Invitation Sent'
  },
  Groups: {
    id: 'Headers.Groups',
    defaultMessage: 'Groups'
  },
  Users: {
    id: 'Headers.Users',
    defaultMessage: 'Users'
  },
  UpdatePreferences: {
    id: 'Headers.UpdatePreferences',
    defaultMessage: 'Preferences Updated'
  },
  AccountVerification: {
    id: 'Headers.AccountVerification',
    defaultMessage: 'Account Verification'
  },
  MicroDepositsExpired: {
    id: 'Headers.MicroDepositsExpired',
    defaultMessage: 'Expired Micro-Deposits'
  }
});

const messages = defineMessages({
  InvitationSent: {
    id: 'Messages.InvitationSent',
    defaultMessage: 'Your invitation to {name} has been sent.'
  },
  ConnectionRequestSent: {
    id: 'Messages.ConnectionRequestsSent',
    defaultMessage: 'A connection request to {name} has been sent.'
  },
  AnonymousConnectionRequestSent: {
    id: 'Messages.IncognitoConnectionRequestSent',
    defaultMessage: 'Your connection request has been sent.'
  },
  InvitationAccepted: {
    id: 'Messages.InvitationAccepted',
    defaultMessage: 'Good! You\'re now connected to {name}.'
  },
  CompanyAdded: {
    id: 'Messages.CompanyAdded',
    defaultMessage: '{companyName} has been added.'
  },
  AddressBookDeleted: {
    id: 'Messages.AddressBookDeleted',
    defaultMessage: '{name} has been successfully deleted.'
  },
  UserInvitationSent: {
    id: 'Messages.UserReinvited',
    defaultMessage: '{firstName} {lastName} has been invited to join your organization on Viewpost!'
  },
  UserReinvited: {
    id: 'Messages.UserReinvited',
    defaultMessage: 'An invitation has been resent to {firstName} {lastName}.'
  },
  CodeSent: {
    id: 'Messages.CodeSent',
    defaultMessage: 'Code has been sent.'
  },
  LinkSent: {
    id: 'Messages.LinkSent',
    defaultMessage: 'The link has been sent.'
  },
  TwoStepVerificationDisabled: {
    id: 'Messages.TwoStepVerificationDisabled',
    defaultMessage: 'Two-step verification has been disabled.'
  },
  TwoStepUpdated: {
    id: 'Messages.TwoStepUpdated',
    defaultMessage: 'Your two-step verification information has been successfully updated.'
  },
  EmailPreferences: {
    id: 'Messages.EmailPreferences',
    defaultMessage: 'Your email preferences have been updated.'
  },
  GroupAdded: {
    id: 'Messages.GroupAdded',
    defaultMessage: 'The new group \'{groupName}\' has been added.'
  },
  GroupNameExists: {
    id: 'Messages.GroupNameExists',
    defaultMessage: 'A group with that name already exists.'
  },
  GroupAddFailed: {
    id: 'Messages.GroupAddFailed',
    defaultMessage: 'Failed to add group.'
  },
  GroupUpdateFailed: {
    id: 'Messages.GroupUpdateFailed',
    defaultMessage: 'Failed to update group.'
  },
  ChangesSaved: {
    id: 'Messages.ChangesSaved',
    defaultMessage: 'Your changes have been saved.'
  },
  GroupDeleted: {
    id: 'Messages.GroupDeleted',
    defaultMessage: 'The group \'{groupName}\' has been deleted.'
  },
  UserInvited: {
    id: 'Messages.UserInvited',
    defaultMessage: '{firstName} {lastName} has been invited to join your organization on Viewpost!'
  },
  UserAdded: {
    id: 'Messages.UserAdded',
    defaultMessage: '{firstName} {lastName} has been added to your organization.'
  },
  UserAddError: {
    id: 'Messages.UserAddError',
    defaultMessage: 'Err, that didn\'t work. Try adding the new user again.'
  },
  UserUpdated: {
    id: 'Messages.UserUpdated',
    defaultMessage: 'The user has been updated.'
  },
  UserUpdateError: {
    id: 'Messages.UserUpdateError',
    defaultMessage: 'Err, that didn\'t work. Try updating again.'
  },
  UpdatePreferences: {
    id: 'Messages.UpdatePreferences',
    defaultMessage: 'Your changes have been applied.'
  },
  VerifySuccess: {
    id: 'Messages.VerifySuccess',
    defaultMessage: 'Account has been verified. Fist pump!'
  },
  VerifyAccountLocked: {
    id: 'Messages.VerifyAccountLocked',
    defaultMessage: 'Sorry, the amounts don\'t seem to be correct. For security purposes, the bank account has been locked. Please call Viewpost at 888-248-9190 to unlock this bank account.'
  },
  MicroDepositsExpired: {
    id: 'Messages.MicroDepositsExpired',
    defaultMessage: 'The micro-deposits for this account have expired. Please re-register it on the Payment Methods settings page from the top menu.'
  },
  IncorrectMicroDeposit: {
    id: 'Messages.IncorrectMicroDeposit',
    defaultMessage: 'One or both of the micro-deposit amounts you entered is incorrect. Please enter the correct value.'
  },
  IncorrectMicroDepositWarning: {
    id: 'Messages.IncorrectMicroDepositWarning',
    defaultMessage: 'One or both of the micro-deposit amounts you entered is incorrect. Please enter the correct value. Warning! This bank account will be locked if you enter an incorrect amount again. If you are having trouble verifying the amounts deposited into your bank account, please call Customer Support at 888-248-9190.'
  },
  EnrolledConsumerPayments: {
    id: 'Messages.EnrolledConsumerPayments',
    defaultMessage: 'Activated! You can now receive electronic payments from consumers.'
  },
  UnenrolledConsumerPayments: {
    id: 'Messages.UnenrolledConsumerPayments',
    defaultMessage: 'Consumer-to-business payments is now turned off. You will no longer be able to receive payments from consumers. Click Enroll to turn back on.'
  },
  StrategyDeleted: {
    id: 'Messages.StrategyDeleted',
    defaultMessage: '{strategyName} was successfully deleted.'
  },
  VendorsMovedToStrategy: {
    id: 'Messages.VendorsMovedToStrategy',
    defaultMessage: '{count} vendors were moved to {strategyName}.'
  },
  ConnectionRequestSendFailure: {
    id: 'Messages.ConnectionRequestSendFailure',
    defaultMessage: 'There was a problem with sending the connection request.'
  },
  ConnectionRequestAcceptFailure: {
    id: 'Messages.ConnectionRequestAcceptFailure',
    defaultMessage: 'There was a problem with accepting the connection request.'
  },
  DisconnectCompany: {
    id: 'Messages.DisconnectCompany',
    defaultMessage: 'You are now disconnected from {companyName}.'
  },
  OneClickPay: {
    id: 'Messages.OneClickPay',
    defaultMessage: 'Your 1-Click Pay settings have been updated.'
  },
  ErrorToast: {
    id: 'Messages.ErrorToast',
    defaultMessage: 'An error occurred.'
  }
});

const ConsumerPaymentEnrollment = defineMessages({
  Header: {
    id: 'Modals.ConsumerPaymentEnrollment.Header',
    defaultMessage: 'Turn Off Consumer-to-Business Payments?'
  },
  Subheader: {
    id: 'Modals.ConsumerPaymentEnrollment.Subheader',
    defaultMessage: 'If you choose to disconnect consumer payments, please note:'
  },
  Line1: {
    id: 'Modals.ConsumerPaymentEnrollment.Line1',
    defaultMessage: 'In-progress payments from consumers will be completed.'
  },
  Line2: {
    id: 'Modals.ConsumerPaymentEnrollment.Line2',
    defaultMessage: 'You will only be able to accept payments from consumers via Stripe.'
  },
  Line3: {
    id: 'Modals.ConsumerPaymentEnrollment.Line3',
    defaultMessage: 'Unpaid invoices to consumers must be paid outside of Viewpost.'
  },
  TurnOff: {
    id: 'Modals.ConsumerPaymentEnrollment.TurnOff',
    defaultMessage: 'Turn Off'
  },
  Cancel: {
    id: 'Modals.ConsumerPaymentEnrollment.Cancel',
    defaultMessage: 'Cancel'
  }
});

const buttons = defineMessages({
  View: {
    id: 'Messages.buttons.View',
    defaultMessage: 'View'
  }
});

export default {
  headers,
  messages,
  ConsumerPaymentEnrollment,
  buttons
};
