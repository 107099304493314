/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

const extractFromCompanyBookId = (companyBookId) => {
  if (companyBookId.charAt(0) === '1') {
    return { companyId: companyBookId.substr(1) };
  } if (companyBookId.charAt(0) === '2') {
    return { addressBookId: companyBookId.substr(1) };
  }
  return {};
};

export default extractFromCompanyBookId;