/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, { useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import DateRange from 'components/DateRange';
import Icon, { IconTypes } from 'components/Icon';
import Tooltip from 'components/Tooltip';
import DualCalendar from 'components/Calendar/dual';
import moment from 'moment';
import ListFilterDropdownToggle from 'components/Dropdown/ListFilterDropdownToggle';
import OldListFilterDropdownToggle from 'components/Dropdown/OldListFilterDropdownToggle';
import ListDropdownOption from 'components/Dropdown/ListDropdownOption';

import t from 'tcomb-validation';

import Button from 'components/Button';
import FormJSX, { FieldOption } from 'components/Form/FormJSX';

import useIsMobile from 'hooks/browser/useIsMobile';
import useFuncRef from 'hooks/react/useFuncRef';
import usePropRef from 'hooks/react/usePropRef';

import Messages from './index.messages';
import 'style-loader!css-loader!rc-calendar/assets/index.css';
import { getMoment } from '../utils';
import './index.scss';

/**
 * Displays a date filter with fixed ranges, and displays the Calendar component for
 * custom selections.
 */
const DateFilter = ({
  beginDate,
  daysAgo,
  endDate,
  includeAllDates: originalIncludeAllDates,
  maximumDayRange,
  onChange: onChangeProp,
  variant
}) => {
  // Hooks
  const isMobile = useIsMobile();
  const dropdownRef = useRef();
  const mobileCustomBeginRef = useRef();
  const mobileCustomEndRef = useRef();

  const includeAllDates = originalIncludeAllDates == null ? true : originalIncludeAllDates;

  const isMobileRef = usePropRef(isMobile);
  const onChangePropRef = usePropRef(onChangeProp);
  const maximumDayRangeRef = usePropRef(maximumDayRange);

  const [ isCustomRangeOpen, setIsCustomRangeOpen ] = useState(false);
  const [ mobileCustomBegin, setMobileCustomBegin ] = useState({ date: beginDate ?? new Date() });
  const [ mobileCustomEnd, setMobileCustomEnd ] = useState({ date: endDate ?? new Date() });

  const {
    current: [
      Yesterday,
      Today,
      SevenDaysAgo,
      ThirtyDaysAgo,
      NinetyDaysAgo
    ]
  } = useFuncRef(
    () => [
      moment().subtract(1, 'days').startOf('day'),
      moment().startOf('day'),
      moment().subtract(7 - 1, 'days').startOf('day'), // today counts as a day
      moment().subtract(30 - 1, 'days').startOf('day'), // today counts as a day
      moment().subtract(90 - 1, 'days').startOf('day') // today counts as a day
    ]
  );

  const { current: closeDropdown } = useFuncRef(
    () => () => dropdownRef.current?.close()
  );

  const { current: closeCustomRange } = useFuncRef(
    () => () => setIsCustomRangeOpen(false)
  );

  const { current: toggleCustomRange } = useFuncRef(
    () => () => setIsCustomRangeOpen(v => !v)
  );

  const { current: isWithinRange } = useFuncRef(
    () => (checkBeginDate, checkEndDate) => {
      if (!maximumDayRangeRef.current) return true;
      if (!checkBeginDate || !checkEndDate) return !maximumDayRangeRef.current;

      const begin = moment(checkBeginDate);
      const end = moment(checkEndDate);
      const diffInDays = end.diff(begin, 'days');
      return diffInDays <= maximumDayRangeRef.current;
    }
  );

  const { current: onChange } = useFuncRef(
    () => (newBeginDate, newEndDate, newDaysAgo) => {
      if (!isWithinRange(newBeginDate, newEndDate)) {
        // switch to default if invalid date range is used
        onChangePropRef.current(null, null);
      } else {
        onChangePropRef.current(newBeginDate, newEndDate, newDaysAgo);
      }
    }
  );

  const [ selectedOption, isOptionInferred ] = useMemo(
    () => {
      if (beginDate || endDate) {
        if (beginDate == null || endDate == null) {
          return [ null, false ];
        }

        const beginMoment = getMoment(beginDate).startOf('d');
        const endMoment = getMoment(endDate).startOf('d');
        if (!(beginMoment.isValid() && endMoment.isValid())) {
          return [ 'AllDates', false ];
        }

        if (beginMoment.isSame(Yesterday) && endMoment.isSame(Yesterday)) {
          return [ 'Yesterday', true ];
        }

        if (beginMoment.isSame(Today) && endMoment.isSame(Today)) {
          return [ 'Today', true ];
        }

        if (beginMoment.isSame(SevenDaysAgo) && endMoment.isSame(Today)) {
          return [ 'Last7Days', true ];
        }

        if (beginMoment.isSame(ThirtyDaysAgo) && endMoment.isSame(Today)) {
          return [ 'Last30Days', true ];
        }

        if (beginMoment.isSame(NinetyDaysAgo) && endMoment.isSame(Today)) {
          return [ 'Last90Days', true ];
        }

        if (isCustomRangeOpen) {
          return [ 'CustomRange', true ];
        }
      } else if (daysAgo) {
        const [ min, max ] = daysAgo;

        if (min == null && max == null) {
          return [ 'AllDates', false ];
        }

        if (min === 1 && max === 1) {
          return [ 'Yesterday', false ];
        }

        if (min === 0 && max === 0) {
          return [ 'Today', false ];
        }

        if (min === 0 && max === 7) {
          return [ 'Last7Days', false ];
        }

        if (min === 0 && max === 30) {
          return [ 'Last30Days', false ];
        }

        if (min === 0 && max === 90) {
          return [ 'Last90Days', false ];
        }
      }

      return [ null, null ];
    },
    [ beginDate, endDate, daysAgo, isCustomRangeOpen ]
  );

  const options = useMemo(
    () => {
      const createOption = ({
        beginDate: newBeginDate,
        daysAgo: newDaysAgo,
        endDate: newEndDate,
        id
      }) => {
        const LabelMessage = Messages[id];
        return (
          <ListDropdownOption
            key={id}
            onClick={() => {
              onChange(
                newBeginDate ? moment(newBeginDate).startOf('day').toDate() : null,
                newEndDate ? moment(newEndDate).endOf('day').toDate() : null,
                newDaysAgo
              );
              closeCustomRange();
              closeDropdown();
            }}
            isActive={selectedOption === id}
            closeMenuOnClick={true}
          >
            <LabelMessage.Message />
          </ListDropdownOption>
        );
      };

      const listOptions = [];

      if (includeAllDates) {
        listOptions.push(createOption({
          beginDate: null,
          endDate: null,
          id: 'AllDates'
        }));
      }

      listOptions.push(createOption({
        beginDate: Yesterday,
        daysAgo: [ 1, 1 ],
        endDate: Yesterday,
        id: 'Yesterday'
      }));

      listOptions.push(createOption({
        beginDate: Today,
        endDate: Today,
        daysAgo: [ 0, 0 ],
        id: 'Today'
      }));

      listOptions.push(createOption({
        beginDate: SevenDaysAgo,
        endDate: Today,
        daysAgo: [ 0, 7 ],
        id: 'Last7Days'
      }));

      listOptions.push(createOption({
        beginDate: ThirtyDaysAgo,
        endDate: Today,
        daysAgo: [ 0, 30 ],
        id: 'Last30Days'
      }));

      listOptions.push(createOption({
        beginDate: NinetyDaysAgo,
        endDate: Today,
        daysAgo: [ 0, 90 ],
        id: 'Last90Days'
      }));

      listOptions.push((
        <ListDropdownOption
          closeMenuOnClick={false}
          key="CustomRange"
          onClick={toggleCustomRange}
        >
          <Messages.CustomRange.Message />
        </ListDropdownOption>
      ));

      return listOptions;
    },
    [ includeAllDates, selectedOption ]
  );

  const { current: onCustomRange } = useFuncRef(
    () => (newBeginDate, newEndDate) => {
      if (newBeginDate && newEndDate) {
        if (newBeginDate > newEndDate) {
          let swap = newBeginDate;
          newBeginDate = newEndDate;
          newEndDate = swap;
        }

        setIsCustomRangeOpen(isMobileRef.current ? false : true);
        onChange(newBeginDate, newEndDate);
        closeDropdown();
      }
    }
  );

  const rightContent = useMemo(
    () => {
      if (!isCustomRangeOpen || isMobile) return null;

      return (
        <div className="date-filter-calendar">
          <DualCalendar
            date1={getMoment(beginDate ?? ThirtyDaysAgo).toDate()}
            date1Label="From"
            date2={getMoment(endDate ?? Today).toDate()}
            date2Label="To"
            onChange={onCustomRange}
            useSubmitButton={true}
            submitLabel="Apply Date Range"
          />
        </div>
      );
    },
    [ isCustomRangeOpen, beginDate, endDate ]
  );

  const { current: onSubmitMobileCustomRange } = useFuncRef(
    () => () => {
      let beginForm = mobileCustomBeginRef.current.getValue();
      let endForm = mobileCustomEndRef.current.getValue();
      if (beginForm && endForm) {
        onCustomRange(beginForm.date, endForm.date);
      } else {
        // form error
        // TODO: can we find room somewhere to indicate this?
      }
    }
  );

  // Render
  if (isMobile && isCustomRangeOpen) {
    return (
      <div style={{ display: 'inline-block' }} className="component-date-filter">
        <div style={{display: 'inline-block', verticalAlign: 'middle'}} className="begin-date">
          <FormJSX
            ref={mobileCustomBeginRef}
            modelType={t.struct({ date: t.maybe(t.Date) })}
            value={mobileCustomBegin}
            onChange={setMobileCustomBegin}
          >
            <FieldOption name="date" placeholder="From" />
          </FormJSX>
        </div>
        <div style={{ display: 'inline-block', verticalAlign: 'middle' }}>
          -
        </div>
        <div style={{ display: 'inline-block', verticalAlign: 'middle'}} className="end-date">
          <FormJSX
            ref={mobileCustomEndRef}
            modelType={t.struct({ date: t.maybe(t.Date) })}
            value={mobileCustomEnd}
            onChange={setMobileCustomEnd}
          >
            <FieldOption name="date" placeholder="To" />
          </FormJSX>
        </div>
        <div style={{ display: 'inline-block', verticalAlign: 'middle', marginLeft: '4px' }}>
          <Button
            variant="secondary"
            className="mobile-custom-confirm"
            onClick={onSubmitMobileCustomRange}
            style={{minWidth: 0}}
          >
            <Icon type={IconTypes.Checkmark} />
          </Button>
        </div>
        <div style={{ display: 'inline-block', verticalAlign: 'middle', marginLeft: '2px' }}>
          <Button
            variant="secondary"
            className="mobile-custom-cancel"
            onClick={closeCustomRange}
            style={{ minWidth: 0}}
          >
            <Icon type={IconTypes.Exit} />
          </Button>
        </div>
      </div>
    );
  }

  const ToggleDropdownComponent = variant === 'new'
    ? ListFilterDropdownToggle
    : OldListFilterDropdownToggle;

  let label = null;

  if (selectedOption && !isOptionInferred && Messages[selectedOption]) {
    const LabelMessage = Messages[selectedOption];
    label = <LabelMessage.Message />;
  } else if (beginDate || endDate) {
    label = (
      <DateRange
        beginDate={beginDate}
        endDate={endDate}
        showToday={false}
      />
    );
  } else if (includeAllDates) {
    label = <Messages.AllDates.Message />;
  }

  // A max day range is automatically applied by platform to invoice lists for all companies
  // who exceed a certain (globally configured) number of invoices
  // we should inform users that they're not seeing all their invoices
  if (!isWithinRange(beginDate, endDate)) {
    const overlay = (
      <Messages.MaximumDayRangeWarning.Message
        maximumDayRange={maximumDayRange}
      />
    );

    label = (
      <span>
        {label}
        <Tooltip overlay={overlay} >
          <Icon className="date-filter-warning" type={IconTypes.Warning} />
        </Tooltip>
      </span>
    );
  }

  return (
    <ToggleDropdownComponent
      dropdownType="list"
      ref={dropdownRef}
      label={label}
      rightContent={rightContent}
    >
      {options}
    </ToggleDropdownComponent>
  );
};

DateFilter.propTypes = {
  /** Begin date for custom range */
  beginDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  /** End date for custom range */
  endDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  /** Max number of days that can be filtered at one time */
  maximumDayRange: PropTypes.number,
  /** Callback function for when the date is changed */
  onChange: PropTypes.func,
  /** whether to include 'all dates' as a valid option **/
  includeAllDates: PropTypes.bool
};

export default DateFilter;
