/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import AddressBookRelationships from 'config/constants/network/AddressBookRelationships';

function toRelationshipEnum(relationship) {
  if (relationship.toLowerCase() === AddressBookRelationships.Vendor.toLowerCase()) {
    return AddressBookRelationships.Vendor;
  } if (relationship.toLowerCase() === AddressBookRelationships.Customer.toLowerCase()) {
    return AddressBookRelationships.Customer;
  }

  return relationship;
}

export default toRelationshipEnum;