/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import t from 'tcomb-validation';
import Messages from './password.messages';

/* eslint-disable max-len */

let lowercasePattern = /[a-z]+/;
let uppercasePattern = /[A-Z]+/;
let digitPattern = /[0-9]+/;
let specialCharPattern = /[!@#$%^&\*]+/;
export const MIN_LENGTH = 8;
export const MAX_LENGTH = 32;

// return 0-4 scale value from most weak to strongest (3-4 is passing)
export function getPasswordStrengthScore(password) {
  let score = 0;
  if (!password || password.length < MIN_LENGTH || password.length > MAX_LENGTH) {
    return score;
  }
  if (lowercasePattern.test(password)) score++;
  if (uppercasePattern.test(password)) score++;
  if (digitPattern.test(password) || specialCharPattern.test(password)) {
    score++;
    if (score === 3
      && digitPattern.test(password)
      && specialCharPattern.test(password)) {
      score++;
    }
  }
  return score;
}

function isValidPassword(value) {
  let stringValue = `${value}`; // coerce numbers into strings if needed
  return getPasswordStrengthScore(stringValue) >= 3;
}

/* PASSWORD */
export const Password = t.subtype(t.String, (value) => {
  return isValidPassword(value);
}, 'Password');

function isValidMatch(value1, value2) {
  return value1 === value2;
}

export const createMatchingPassword = (value1) => {
  let MatchingPassword = t.subtype(Password, (value2) => {
    return isValidMatch(value1, value2);
  }, 'MatchingPassword');

  MatchingPassword.getValidationErrorMessage = (value2, path, context) => {
    if (!isValidMatch(value1, value2) && context && context.intl) {
      return context.intl.formatMessage(Messages.DoNotMatch);
    }

    return '';
  };

  return MatchingPassword;
};
