/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { useEffect, useState } from 'react';
import { debounce } from 'lodash';

const useWindowWidth = () => {
  const [ windowWidth, setWindowWidth ] = useState(
    global.window.innerWidth
  );

  const updateWindowWidth = debounce(
    () => {
      if (windowWidth !== global.window.innerWidth) {
        setWindowWidth(global.window.innerWidth);
      }
    },
    250
  );

  useEffect(
    () => {
      global.window.addEventListener(
        'resize',
        updateWindowWidth
      );

      return () => {
        global.window.removeEventListener(
          'resize',
          updateWindowWidth
        );
      };
    }
  );

  return windowWidth;
};

export default useWindowWidth;