/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { shallowEqual, useSelector } from 'react-redux';
import {
  getIdentity,
  isAuthorizedToUseApp,
  isPersonalPortal
} from 'actions/common';
import {
  isPortalExperience,
  isCustomerPortalExperience,
  isVendorPortalExperience
} from 'actions/common/companySettings';

const useSessionInfo = () => useSelector(
  (state) => {
    const isFullyAuthenticated = isAuthorizedToUseApp(state)
      && !!getIdentity(state, { notExpected: true, returnNull: true });

    return {
      isAuthorizedToUseApp: isFullyAuthenticated,
      isBusiness: isFullyAuthenticated && !isPersonalPortal(state),
      isPersonalPortal: isFullyAuthenticated && isPersonalPortal(state),
      isPortalExperience: isFullyAuthenticated && isPortalExperience(state),
      isVendorPortal: isFullyAuthenticated && isVendorPortalExperience(state),
      isCustomerPortal: isFullyAuthenticated && isCustomerPortalExperience(state)
    };
  },
  shallowEqual
);

export default useSessionInfo;