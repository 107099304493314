/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { newState } from './utils';

export default (state = {}, action) => {
  if (action.type === 'UpdateComponentSaveState') {
    return newState(state, {
      [action.name]: {
        ...action.state
      }
    });
  } if (action.type === 'ReplaceComponentSaveState') {
    return newState(state, {
      [action.name]: action.state
    });
  } if (action.type === 'BulkClearComponentSaveState') {
    return newState(state, action.names.reduce((val, name) => {
      return {
        ...val,
        [name]: action.defaultState
      };
    }, state));
  }

  return state;
};
