/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, { memo } from 'react';
import classNames from 'classnames';

import AppContainer from 'components/AppContainer';
import DisplayWorkflow from 'components/DisplayWorkflow';

import { PageCatchAppError } from 'containers/CatchAppError';
import Footer from 'containers/Footer';
import { FloatingActionBarDest } from 'containers/FloatingActionBar';
import { FloatingAppContentDest } from 'containers/FloatingAppContent';
import Navigation from 'containers/Navigation';

import EnsureIdentityHooks, { useEnsureIdentityStatusIndicator } from 'hooks/identity/EnsureIdentityHooks';
import useIdentity from 'hooks/identity/useIdentity';

import AccountPortalizedWorkflowStep from './InAppOverlayWorkflowSteps/AccountPortalized';
import AnnualUpdateReminderWorkflowStep from './InAppOverlayWorkflowSteps/AnnualUpdateReminder';
import BeneficialOwnerPromptWorkflowStep from './InAppOverlayWorkflowSteps/BeneficialOwnerPrompt';
import BusinessAccountSetupWorkflowStep from './InAppOverlayWorkflowSteps/BusinessAccountSetup';
import FreeTrialNotificationWorkflowStep from './InAppOverlayWorkflowSteps/FreeTrialNotification';

import OnLogonRedirectWorkflowStep from './OnLogonWorkflowSteps/OnLogonRedirect';
import TermsAndConditionsWorkflowStep from './OnLogonWorkflowSteps/TermsAndConditions';

import DenyPendingRegistrationWorkflowStep from './RequiredAppAccessWorkflowSteps/DenyPendingRegistration';
import EngageLegacyUsersPromptWorkflowStep from './RequiredAppAccessWorkflowSteps/EngageLegacyUsersPrompt';
import HandleDelinquentAccountWorkflowStep from './RequiredAppAccessWorkflowSteps/HandleDelinquentAccount';
import SecureAccountWorkflowStep from './RequiredAppAccessWorkflowSteps/SecureAccount';
import AddPayeePaymentAccountWorkflowStep from './RequiredAppAccessWorkflowSteps/AddPayeePaymentAccount';
import SwitchCompanyWorkflowStep from './RequiredAppAccessWorkflowSteps/SwitchCompany';
import UsagePromptWorkflowStep from './RequiredAppAccessWorkflowSteps/UsagePrompt';

const WorkflowSteps = [
  {
    id: 'DenyPendingRegistration',
    element: <DenyPendingRegistrationWorkflowStep />
  },
  {
    id: 'HandleDelinquentAccount',
    element: <HandleDelinquentAccountWorkflowStep />
  },
  {
    id: 'SecureAccount',
    element: <SecureAccountWorkflowStep />
  },
  {
    id: 'AddPayeeAccount',
    element: <AddPayeePaymentAccountWorkflowStep />
  },
  {
    id: 'EngageLegacyUsersPrompt',
    element: <EngageLegacyUsersPromptWorkflowStep />
  },
  {
    id: 'SwitchCompany',
    element: <SwitchCompanyWorkflowStep />
  },
  {
    id: 'UsagePrompt',
    element: <UsagePromptWorkflowStep/>
  },
  {
    id: 'AccountPortalized',
    element: <AccountPortalizedWorkflowStep />,
    overlay: true
  },
  {
    id: 'BusinessAccountSetup',
    element: <BusinessAccountSetupWorkflowStep />,
    overlay: true
  },
  {
    id: 'OnLogonRedirect',
    element: <OnLogonRedirectWorkflowStep/>,
    overlay: true
  },
  {
    id: 'FreeTrialNotification',
    element: <FreeTrialNotificationWorkflowStep />,
    overlay: true
  },
  {
    id: 'BeneficialOwnerPrompt',
    element: <BeneficialOwnerPromptWorkflowStep />,
    overlay: true
  },
  {
    id: 'AnnualUpdateReminder',
    element: <AnnualUpdateReminderWorkflowStep />,
    overlay: true
  },
  {
    id: 'TermsAndConditions',
    element: <TermsAndConditionsWorkflowStep />,
    overlay: true
  }
];

export const EnsureInAppWorkflowAccess = memo(({
  children
}) => {
  // Hooks
  EnsureIdentityHooks.useProvideParams();

  const StatusIndicator = useEnsureIdentityStatusIndicator();

  const { companyId } = useIdentity({ notExpected: true });

  // Render
  if (StatusIndicator) {
    return (
      <StatusIndicator centerInPage={true} />
    );
  }

  // Using if the company ID changes as a mechanism to run through the display
  // workflow again
  return (
    <DisplayWorkflow
      key={companyId}
      workflow={WorkflowSteps}
    >
      {children}
    </DisplayWorkflow>
  );
});

const InAppLayout = memo(({
  containerClass,
  children
}) => (
  <div style={{ height: '100%' }}>
    <div
      className={classNames('container', containerClass)}
      style={{
        minHeight: `calc(100% - (${Footer.getHeight()}px) - 40px)`,
        marginBottom: '40px'
      }}
    >
      <div style={{minHeight: '400px'}}>
        <PageCatchAppError context="InAppLayout">
          <Navigation />
          <FloatingAppContentDest/>
          <AppContainer>
            <PageCatchAppError context="AuthenticatedApp">
              {children}
            </PageCatchAppError>
          </AppContainer>
        </PageCatchAppError>
      </div>
    </div>
    <Footer dockBottom={false} />
    <FloatingActionBarDest />
  </div>
));

export default InAppLayout;
