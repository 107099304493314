/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, { Children } from 'react';
import ProvideIsMobile from 'decorators/ProvideIsMobile';
import './index.scss';

/**
 * Footer section of a Modal
 */
const ModalFooter = ({children, isMobile}) => {
  let footerChildren = children;
  let isOnlyButton = false;

  if (isMobile) {
    const filteredChildren = [];

    Children.forEach(children, (child) => {
      if (child) filteredChildren.push(child);
    });

    isOnlyButton = filteredChildren.length === 1 && filteredChildren[0].type.isModalButton;

    if (isOnlyButton) {
      filteredChildren[0] = React.cloneElement(filteredChildren[0], {key: 'button', id: 0, isOnlyButton});
      footerChildren = filteredChildren;
    } else {
      footerChildren = filteredChildren.map((child, index) => React.cloneElement(child, {id: index}));
    }
  } else {
    footerChildren = Children.map(
      children,
      (child, index) => child ? React.cloneElement(child, { id: child.props.isSubmit ? 'submit' : index + 1 }) : null
    );
  }

  return (
    <div className={`basicModal footer ${isOnlyButton ? 'tall' : ''}`}>
      {footerChildren}
    </div>
  );
};

export default ProvideIsMobile(ModalFooter);
