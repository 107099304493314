/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { connect } from 'react-redux';
import { getCompanySettings } from 'actions/common/companySettings';

export default connect(
  (state) => {
    const { campaigns, nextBillingDate } = getCompanySettings(state);

    const { type: campaignType } = (campaigns || [])[0] || {};

    return {
      isInFreeTrial: campaignType === 'FreeTrial',
      nextBillingDate
    };
  }
);
