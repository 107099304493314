/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */
/* eslint max-len:0 */

import { toMessages } from 'config/messages';

export default toMessages('BeneficalOwnerCollectionForm', {
  Header: 'Beneficial Ownership Information',
  Submit: 'Submit',
  Cancel: 'Cancel'
});
