/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { defineMessages } from 'react-intl';

export default defineMessages({
  InvalidCardNumber: {
    id: 'ValidationError.CreditCard.InvalidCardNumber',
    defaultMessage: 'Invalid card number.'
  },
  InvalidExpiration: {
    id: 'ValidationError.CreditCard.InvalidExpiration',
    defaultMessage: 'Invalid expiration date. Use MM/YY.'
  },
  InvalidCVC: {
    id: 'ValidationError.CreditCard.InvalidCVC',
    defaultMessage: 'Invalid security code.'
  },
  InvalidAmount: {
    id: 'ValidationError.CreditCard.InvalidCVC',
    defaultMessage: 'Invalid amount. Must be greater than 0.'
  }
});
