/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */
/* eslint max-len:0 */

import { toMessagesLegacy } from 'config/messages';

const common = toMessagesLegacy({
  PhoneNumberLocked: {
    id: 'TwoStepVerification.PhoneNumberLocked',
    defaultMessage: 'You have entered your code incorrectly too many times. Please click the button below to try another phone number or call customer service at 888.248.9190.'
  },
  IncorrectCode: {
    id: 'TwoStepVerification.IncorrectCode',
    defaultMessage: 'You have entered an incorrect code. Please try again.'
  },
  StandardMessagingRates: {
    id: 'TwoStepVerification.StandardMessagingRates',
    defaultMessage: 'Message and data rates may apply.'
  },
  DidntGetIt: {
    id: 'UserSettingsAccount.DidntGetIt',
    defaultMessage: 'Didn\'t get it? {resendButton}'
  },
  Step1Instructions: {
    id: 'TwoStepVerification.Step1Instructions',
    defaultMessage: 'Enter a mobile phone number to receive a verification code by text.'
  },
  Step2Header: {
    id: 'TwoStepVerification.Step1Header',
    defaultMessage: 'Step 2 of 2'
  },
  MustCompleteQuestions: {
    id: 'TwoStepVerification.MustCompleteQuestions',
    defaultMessage: 'To keep your account secure, you must complete the security questions above before disabling Two-Step Verification.'
  }
});

const FormLabels = toMessagesLegacy({
  MobilePhone: {
    id: 'TwoStepVerification.formLabels.MobilePhone',
    defaultMessage: 'Enter your mobile smartphone number:'
  },
  EnterCode: {
    id: 'TwoStepVerification.formLabels.EnterCode',
    defaultMessage: 'A verification code was sent to {phoneNumber}'
  },
  MobilePhoneCardVariant: {
    id: 'TwoStepVerification.formLabels.MobilePhoneCardVariant',
    defaultMessage: 'Enter your mobile phone number'
  }
});

const Placeholders = toMessagesLegacy({
  ConfirmPassword: {
    id: 'TwoStepVerification.placeholders.ConfirmPassword',
    defaultMessage: 'Confirm Password'
  },
  PhoneNumber: {
    id: 'TwoStepVerification.placeholders.PhoneNumber',
    description: 'Sample phone number',
    defaultMessage: '(000) 000-0000'
  },
  EnterCode: {
    id: 'TwoStepVerification.placeholders.EnterCode',
    defaultMessage: 'Enter Code'
  }
});

const Buttons = toMessagesLegacy({
  Enable: {
    id: 'TwoStepVerification.buttons.Enable',
    defaultMessage: 'Enable'
  },
  Confirm: {
    id: 'TwoStepVerification.buttons.Confirm',
    defaultMessage: 'Confirm'
  },
  DisableTwoStep: {
    id: 'TwoStepVerification.buttons.DisableTwoStep',
    defaultMessage: 'Disable'
  },
  Save: {
    id: 'TwoStepVerification.buttons.Save',
    defaultMessage: 'Save'
  },
  ResendCode: {
    id: 'TwoStepVerification.buttons.ResendCode',
    defaultMessage: 'Resend Code'
  },
  ChangeNumber: {
    id: 'TwoStepVerification.buttons.ChangeNumber',
    defaultMessage: 'Change Number'
  },
  Send: {
    id: 'TwoStepVerification.buttons.Send',
    defaultMessage: 'Send'
  },
  SendCode: {
    id: 'TwoStepVerification.buttons.SendCode',
    defaultMessage: 'Send Code'
  },
  Cancel: {
    id: 'TwoStepVerification.buttons.Cancel',
    defaultMessage: 'Cancel'
  },
  Verify: {
    id: 'TwoStepVerification.buttons.Verify',
    defaultMessage: 'Verify'
  }
});

export default {
  ...common,
  FormLabels,
  Placeholders,
  Buttons
};
