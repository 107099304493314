/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, { useMemo } from 'react';

import securityQuestionSubmitResetPasswordRequest
  from 'api/session/resetPassword/securityQuestionSubmitResetPasswordRequest';

import SecurityQuestionPrompt from 'containers/Logon/SecurityQuestionPrompt';

import createExecuteApiResultHooks from 'hooks/apiResult/createExecuteApiResultHooks';

const SubmitCodeHooks = createExecuteApiResultHooks({
  endpoint: securityQuestionSubmitResetPasswordRequest
});

const ResetPasswordTwoFactorPrompt = ({
  error,
  onUpdate,
  securityQuestions,
  token
}) => {
  // Hooks
  const executeSubmit = SubmitCodeHooks.useExecuteRequest();
  const isSubmitting = SubmitCodeHooks.useIsLoading();

  const onSubmit = useMemo(
    () => async (answers) => {
      const [ success, action ] = await executeSubmit({
        answers,
        token
      });

      if (success) {
        onUpdate(action.payload.result);
      }
    }
  );

  // Render
  return (
    <SecurityQuestionPrompt
      error={error}
      isSubmitting={isSubmitting}
      onSubmit={onSubmit}
      securityQuestions={securityQuestions}
    />
  );
};

export default ResetPasswordTwoFactorPrompt;