/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { shallowEqual, useSelector } from 'react-redux';

import { getRegistrationResult } from 'actions/registration';

import getSecureTokenData from 'api/onboarding/getSecureTokenData';
import { InvoiceOnboardingData, SecureTokenContextModel } from 'api/onboarding/getSecureTokenData.schemas';
import { getRegistrationMetadata } from 'api/registration';
import { InvitationMetadata } from 'api/registration/utils';

import createProvideApiResultHooks from 'hooks/apiResult/createProvideApiResultHooks';

import { SecureTokenIdentity } from 'schemas/network/session';

// For when you have an invitation ID or referral ID
export const GetRegistrationMetadataHooks = createProvideApiResultHooks({
  endpoint: getRegistrationMetadata
});

// For when you have a secure token
export const GetSecureTokenDataHooks = createProvideApiResultHooks(
  {
    endpoint: getSecureTokenData,
    entityScope: 'global'
  },
  ({
    useEntity
  }) => {
    const useContext = useEntity.meta.bind({
      coalesce: true,
      filter: ({ type }) => type === SecureTokenContextModel.meta.name
    });

    return {
      useContext,
      useInvoiceData: useEntity.meta.bind({
        coalesce: true,
        filter: ({ type }) => type === InvoiceOnboardingData.meta.name
      }),
      useMetadata: useEntity.meta.bind({
        filter: ({ type }) => type === InvitationMetadata.meta.name
      }),
      useSecureTokenIdentity: useEntity.meta.bind({
        coalesce: true,
        filter: ({ type }) => type === SecureTokenIdentity.meta.name
      }),
      useSecureToken: () => useContext()?.token
    };
  }
);

const getMetadataConfig = {
  filter: ({ type }) => type === InvitationMetadata.meta.name
};

export const useRegistrationMetadata = () => {
  // Hooks
  const fromRegistrationMetadata = GetRegistrationMetadataHooks.useEntity(getMetadataConfig);
  const fromSecureTokenData = GetSecureTokenDataHooks.useEntity(getMetadataConfig);

  // Action
  return fromRegistrationMetadata || fromSecureTokenData;
};

export const useRegistrationResult = () => useSelector(
  getRegistrationResult,
  shallowEqual
);