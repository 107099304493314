/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */
/*
  Base class for components that intend to have only "configuration" children
  (children that will be consumed and manually processed by the parent instead of
  simply rendered)
*/

import { Children, Component } from 'react';
import PropTypes from 'prop-types';
import { isArray } from 'lodash';

const MAX_RECURSE_LEVELS = 10;

function findConfigurationChild(child, isConfigChild, recurseLevel = 0, context) {
  // support an array of child types
  if (isArray(child)) {
    if (child.every(item => isConfigChild(item.type, item.props))) {
      return child;
    }

    console.warn(`ComponentWithConfigurationChildren was given a child that is
        an array of config AND non-config children. This is not supported.`);
  }

  if (!child || !child.type) {
    // ignore null children
    return null;
  }

  if (isConfigChild(child.type, child.props)) {
    return child;
  }

  // since configuration children might be wrapped by other components,
  // recurse into children looking for them

  if (recurseLevel > MAX_RECURSE_LEVELS) {
    throw new Error(
      `ComponentWithConfigurationChildren reached maximum recursion limit
      when trying determine configuration children. Make sure all children eventually result in a configuration
      child matching its isConfigurationChild method check.`);
  }

  // stateless component children
  let childRendering = new child.type(child.props, context);
  if (childRendering.render) {
    // the construction above does not apply props to childRendering in IE9/10 (which is the 'this' for the render)
    childRendering.props = child.props;
    childRendering.context = context;

    // class component children
    childRendering = childRendering.render();
  }

  return findConfigurationChild(
    childRendering,
    isConfigChild,
    recurseLevel + 1
  );
}

export function getConfigurationChildren(children, isConfigChild, component) {
  let configChildren = [];
  Children.forEach(children, (child, index) => {
    let configChild = findConfigurationChild(child, isConfigChild, 0, component.context);
    if (isArray(configChild)) {
      // support arrays as children
      configChildren = configChildren.concat(configChild);
    } else if (configChild) {
      configChildren.push(configChild);
    }
  });
  return configChildren;
}

export function createGetConfigurationChildren(isConfigurationChild) {
  return function get() {
    return getConfigurationChildren(this.props.children, isConfigurationChild, this);
  };
}

// I would avoid using this in favor of using createGetConfigurationChildren, binding to
// getConfigurationChildren on your component
export default class ComponentWithConfigurationChildren extends Component {
  static contextTypes = {
    store: PropTypes.object
  };

  constructor(props) {
    super(props);
  }

  getConfigurationChildren() {
    if (!this.isConfigurationChild) {
      throw new Error(
        `ComponentWithConfigurationChildren (${this.constructor.name}) did not implement required method:
        Boolean isConfigurationChild(childType, childProps)`);
    }

    return getConfigurationChildren(this.props.children, this.isConfigurationChild.bind(this), this);
  }

}
