/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React from 'react';
import { FormattedMessage } from 'react-intl';
import messages from './index.messages.js';

export default function Copyright(props) {
  return (
    <p className="copyright-statement">
      <FormattedMessage {...messages.Copyright} values={{
        year: new Date().getFullYear()
      }}/>
    </p>
  );
}
