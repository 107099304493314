/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import {
  START_ACTION_TYPE,
  STEP_ACTION_TYPE,
  END_ACTION_TYPE,
  FINISH_ACTION_TYPE
} from 'actions/workflow';
import { newState } from './utils';

export default function workflow(state = {}, action) {

  if (action.type === START_ACTION_TYPE) {
    let startState = {
      step: null,
      complete: false,
      finished: false
    };
    if (action.id) {
      startState = {
        [action.id]: startState
      };
    }
    return newState(state, startState);
  }

  if (action.type === STEP_ACTION_TYPE) {
    let stepState = {
      step: action.payload,
      complete: false,
      finished: false
    };
    if (action.id) {
      stepState = {
        [action.id]: stepState
      };
    }
    return newState(state, stepState);
  }

  if (action.type === END_ACTION_TYPE) {
    let endState = {
      step: null,
      complete: true,
      finished: false
    };
    if (action.id) {
      endState = {
        [action.id]: endState
      };
    }
    return newState(state, endState);
  }

  if (action.type === FINISH_ACTION_TYPE) {
    let finishedState = {
      step: null,
      complete: true,
      finished: true
    };
    if (action.id) {
      finishedState = {
        [action.id]: finishedState
      };
    }
    return newState(state, finishedState);
  }

  return state;
}
