/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, { forwardRef, useEffect } from 'react';
import Recaptcha from 'react-recaptcha';

import createModuleLoader from 'decorators/ModuleLoader';

import useApplicationSetting, { AppSetting } from 'hooks/settings/useApplicationSetting';

import './index.scss';

const RecaptchaInput = forwardRef(
  (
    {
      center: isCentered,
      onChange,
      value
    },
    ref
  ) => {
    // Hooks
    // Recaptcha is not setup in all environments
    const { siteKey } = useApplicationSetting(AppSetting.ReCaptcha, { notExpected: true });

    useEffect(
      () => {
        if (!siteKey && value !== 'ignored') {
          onChange('ignored');
        }
      },
      []
    );

    // Render
    if (!siteKey) return <></>;

    const content = (
      <Recaptcha
        ref={ref}
        sitekey={siteKey}
        render="explicit"
        onloadCallback={() => null}
        verifyCallback={secret => onChange(secret)}
        expiredCallback={() => onChange()}
      />
    );

    return isCentered ? (
      <div className="recaptcha-centered">
        {content}
      </div>
    ) : content;
  }
);

const RecaptchaModuleLoader = createModuleLoader({
  scriptToLoad: 'https://www.google.com/recaptcha/api.js',
  innerComponent: RecaptchaInput,
  loadingIndicator: () => <span>Loading...</span>,
  forwardRef: true
});

export default RecaptchaModuleLoader;
