/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { isFailAction, isFetchAction, isSuccessAction } from 'api/core/utils';
import { newState } from '../utils';

export const Queue = (state = {
  isAmountValidForQueue: true,
  isLoading: true,
  isSubmitting: false,
  paymentDate: new Date()
}, action) => {
  // Handle the load of the settlements in the queue
  if (isFetchAction(action, 'getPaymentQueue')) {
    return newState(state, {
      isLoading: true,
      submitResults: null
    });
  } if (isSuccessAction(action, 'getPaymentQueue')) {
    return newState(state, {
      isLoading: false,
      paymentQueue: action.payload.paymentReferences,
      settlementFees: action.payload.settlementFeesReference
    });
  }

  // Handle the an update to the fees
  if (isSuccessAction(action, 'onUpdatePaymentMethod')) {
    return newState(state, {
      paymentQueue: action.payload.paymentReferences,
      settlementFees: action.payload.settlementFeesReference
    });
  }

  // Handle the load of the attachments
  if (isSuccessAction(action, 'getPaymentQueueAttachments')) {
    if (action && action.meta && action.meta.params && action.meta.params.settlementId) {
      return newState(state, {
        attachments: {
          ...(state.attachments || {}),
          [action.meta.params.settlementId]: action.payload.references
        }
      });
    }
  }

  // Handle the submission of payments
  if (isFetchAction(action, 'submitPaymentQueue')) {
    return newState(state, {
      isSubmitting: true,
      submitResults: null,
      submitError: null
    });
  } if (isSuccessAction(action, 'submitPaymentQueue')) {
    return newState(state, {
      isSubmitting: false,
      submitResults: action.payload.reference,
      submitError: action.payload.errorReference
    });
  }

  // Handle the update of payments
  if (isSuccessAction(action, 'updatePayments')) {
    return newState(state, {
      // isReviewStep: true
    });
  } if (isFailAction(action, 'updatePayments')) {
    return newState(state, {
    });
  }

  return state;
};
