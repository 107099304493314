/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { setAcknowledgement } from 'actions/settings';
import { isAcknowledged } from 'actions/common';

export function MapStateToProps(state, props) {
  return {
    steps: state.guidedTour.steps,
    timestamp: state.guidedTour.timestamp,
    tourOptions: state.guidedTour.tourOptions,
    isAcknowledged: key => isAcknowledged(state, key)
  };
}

export function MapDispatchToProps(dispatch) {
  return {
    setAcknowledgement: key => dispatch(setAcknowledgement(key))
  };
}
