/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { createApiAction } from 'api/core';

import { createEntity } from 'schemas/state';

import {
  AppPlaidSettings,
  AppCapabilitySettings,
  AppStripeSettings,
  AppAttachmentSettings,
  AppReCaptchaSettings,
  AppEnvironmentSettings
} from './getApplicationSettings.schemas';

const getApplicationSettings = createApiAction({
  method: 'get',
  url: '/api/webApp/settings',
  responseMapper: (apiResponse) => {
    let entities = [];

    if (apiResponse) {
      if (apiResponse.capabilities) {
        entities.push(createEntity(
          'current',
          AppCapabilitySettings.meta.name,
          {
            apiCapabilities: apiResponse.capabilities
          }
        ));
      }

      if (apiResponse.plaid) {
        const plaidSettings = { ...apiResponse.plaid };
        if (plaidSettings.environment === 'tartan') {
          plaidSettings.environment = 'development';
        }

        entities.push(createEntity(
          'current',
          AppPlaidSettings.meta.name,
          plaidSettings
        ));
      }

      if (apiResponse.stripe) {
        entities.push(createEntity(
          'current',
          AppStripeSettings.meta.name,
          {
            ...apiResponse.stripe
          }
        ));
      }

      if (apiResponse.attachments) {
        entities.push(createEntity(
          'current',
          AppAttachmentSettings.meta.name,
          {
            ...apiResponse.attachments
          }
        ));
      }

      if (apiResponse.reCaptcha) {
        entities.push(createEntity(
          'current',
          AppReCaptchaSettings.meta.name,
          {
            ...apiResponse.reCaptcha
          }
        ));
      }

      if (apiResponse.environment) {
        entities.push(createEntity(
          'current',
          AppEnvironmentSettings.meta.name,
          {
            isExternal: apiResponse.environment.isExternal || false
          }
        ));
      }
    }

    return {
      entities,
      references: []
    };
  }
});

export default getApplicationSettings;