/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { defaultMapper } from 'api/common/mappers/default';
import { createApiAction } from 'api/core';

const exchangePlaidToken = createApiAction(
  {
    method: 'post',
    url: '/ajax/account/exchangePlaidToken',
    requestBodyMapper: defaultMapper,
    responseMapper: defaultMapper
  },
  // Still used by settings.AddCheckingAccount reducer
  'exchangePlaidToken'
);

export default exchangePlaidToken;