/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React from 'react';
import Modal, { ModalBody } from 'components/Modal';
import { addModal } from 'config/modals/registry';

import AddBankAccount from '../index';

function AddBankAccountModal({
  onClose,
  onComplete
}) {
  return (
    <Modal header="Add Bank Account"
      onHeaderClose={() => onClose()}>
      <ModalBody style={{ padding: '15px', backgroundColor: '#f7f7f7' }}>
        <AddBankAccount onComplete={() => {
          onComplete();
          onClose();
        }} />
      </ModalBody>
    </Modal>
  );
}

export default addModal('AddBankAccountModal', AddBankAccountModal, { extraWide: true });