/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React from 'react';
import PropTypes from 'prop-types';
import Tag from 'components/Tag';
import { FormattedMessage } from 'react-intl';
import Messages from './index.messages';
import './index.scss';

/**
 * The base component of a tip used in dropdowns or in mobile tooltips
 */
const Tip = ({
  title,
  message,
  isNew
}) => {
  let content = null;

  if (title || message) {
    content = (
      <div>
        {title ? (
          <div className="title">
            {title}
          </div>
        ) : null}
        <div className="message">
          {message}
        </div>
      </div>
    );
  }

  let tag = null;
  if (isNew) {
    tag = (
      <div style={{paddingBottom: '10px'}}>
        <Tag>
          <FormattedMessage {...Messages.New}/>
        </Tag>
      </div>
    );
  }

  return (
    <div className="action-tooltip-tip-component">
      {tag}
      {content}
    </div>
  );
};

Tip.propTypes = {
  /** The title of the tip */
  title: PropTypes.node,
  /** The message of the tip */
  message: PropTypes.node,
  /** Show a new indicator for a new action */
  isNew: PropTypes.bool
};

Tip.defaultProps = {
  isNew: false
};

export default Tip;
