/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React from 'react';
import PropTypes from 'prop-types';
import ShouldLinkPhoneNumberContext from './context';

const PhoneNumber = ({
  dashStyle,
  extension,
  link,
  masked,
  value
}) => {
  const renderPhoneNumber = () => {
    if (!value) return '';

    const formattedValue = value
      .toString()
      .trim()
      .replace(/^\+/, '')
      .replace(/[-\(\)\s]*/g, '');

    if (formattedValue.match(/[^0-9]/)) {
      return value;
    }

    const useDashes = formattedValue.length === 10 && dashStyle;

    let country;
    let city;
    let number;

    switch (formattedValue.length) {
      case 10: // +1PPP####### -> C (PPP) ###-####
        country = 1;
        city = formattedValue.slice(0, 3);
        number = formattedValue.slice(3);
        break;

      case 11: // +CPPP####### -> C (PPP) ###-####
        country = formattedValue[0];
        city = formattedValue.slice(1, 4);
        number = formattedValue.slice(4);
        break;

      case 12: // +CCCPP####### -> CCC (PP) ###-####
        country = formattedValue.slice(0, 3);
        city = formattedValue.slice(3, 5);
        number = formattedValue.slice(5);
        break;

      default:
        return value;
    }

    if (country === 1) {
      country = '';
    } else {
      country += '.';
    }

    let firstNumberPart = number.slice(0, 3);
    let secondNumberPart = number.slice(3);

    if (masked) {
      firstNumberPart = firstNumberPart.replace(/[0-9]/g, '*');
      city = city.replace(/[0-9]/g, '*');
    }

    number = `${firstNumberPart}${useDashes ? '-' : '.'}${secondNumberPart}`;

    if (useDashes) {
      return `${country} (${city}) ${number}`.trim();
    }

    return `${country}${city}.${number}`.trim();
  };

  const renderExtension = () => {
    if (!extension) return null;
    return (
      <span>
        , ext: {extension}
      </span>
    );
  };

  const content = (
    <>
      {renderPhoneNumber()}
      {renderExtension()}
    </>
  );

  return (
    <ShouldLinkPhoneNumberContext.Consumer>
      {(shouldLinkContext) => {
        if (link != null ? link : shouldLinkContext) {
          const phone = value || '';
          const phoneNumbersOnly = phone.replace(/\D/g, '');
          return (
            <a href={`tel:${phoneNumbersOnly}`}>
              {content}
            </a>
          );
        }

        return (
          <span>
            {content}
          </span>
        );
      }}
    </ShouldLinkPhoneNumberContext.Consumer>
  );
};


PhoneNumber.propTypes = {
  value: PropTypes.string,
  link: PropTypes.bool
};

PhoneNumber.defaultProps = {
  dashStyle: true
};

export default PhoneNumber;
