/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React from 'react';

import { AchPlusInvitationData } from 'api/onboarding/getSecureTokenData.schemas';

import BodyText, { BodyTextVariants } from 'components/BodyText';
import BulletedList, { Bullet } from 'components/BulletedList';
import Currency from 'components/Currency';
import HeaderText, { HeaderTextVariants } from 'components/HeaderText';
import CompanyLogo from 'components/Network/CompanyLogo';
import { SmallBannerNotification, NotificationStyleTypes } from 'components/Notification';
import { Column, Row } from 'components/Viewstrap/Grid';
import Image from 'components/Viewstrap/Image';

import { CustomerSupportEmail } from 'config/messageLinks';

import {
  GetSecureTokenDataHooks,
  useRegistrationMetadata
} from 'hooks/registration';

import UsbankLogo from 'public/images/logos/usbank.png';
import DxcLogo from 'public/images/registration/dxcLogo.png';

import Messages, { DxcMessages } from './index.messages';
import './index.scss';

const AchPlusInvitationContext = ({
  achPlusInvitationData: {
    paymentCount,
    pendingPaymentAmount
  }
}) => {
  // Hooks
  const { from: { companyId, companyName} } = useRegistrationMetadata();

  // Render
  return (
    <div className="achPlusPendingPayment">
      <div className="topRow">
        <BodyText color="darkGrey" variant={BodyTextVariants.Smaller}>
          <Messages.PendingPaymentInstructions.Message count={paymentCount} />
        </BodyText>
      </div>
      <div className="middleRow">
        <div className="section">
          <CompanyLogo companyId={companyId} width={120} />
        </div>
        <div className="section" style={{ paddingTop: 12 }}>
          <BodyText
            color="darkGrey"
            style={{ lineHeight: '1.4' }}
            variant={BodyTextVariants.Smallest}
          >
            <Messages.AchPlusPendingPayments.Message count={paymentCount} />
            <br/>
            from {companyName}
          </BodyText>
          <HeaderText
            style={{ paddingTop: 4 }}
            variant={HeaderTextVariants.Larger}
          >
            <Currency value={pendingPaymentAmount} />
          </HeaderText>
        </div>
      </div>
    </div>
  );
};

const DxcStepColumn = ({ number }) => (
  <Column size={2}>
    <span className="dxcStep type-strong">
      <DxcMessages.StepCounter.Message number={number} />
    </span>
  </Column>
);

const AdditionalRegistrationContext = ({
  children
}) => {
  // Hooks
  const achPlusInvitationData = GetSecureTokenDataHooks.useEntity({
    filter: ({ type }) => type === AchPlusInvitationData.meta.name
  });

  const { referralToken: originalReferralToken } = useRegistrationMetadata() || {};

  const referralToken = (originalReferralToken || '').toLowerCase();

  // Render
  if (referralToken === 'usbank') {
    return (
      <>
        <SmallBannerNotification
          type={NotificationStyleTypes.Info}
          style={{
            marginBottom: 8,
            maxWidth: 480,
            marginLeft: 'auto',
            marginRight: 'auto'
          }}
        >
          <div className="referralNotification logoRightSide">
            <div className="message">
              <div className="header">
                <Messages.UsbankNotificationHeader.Message />
              </div>
              <Messages.UsbankNotificationSubheader.Message />
            </div>
            <div className="bankImage">
              <Image image={{ src: UsbankLogo }} />
            </div>
          </div>
        </SmallBannerNotification>
        {children}
      </>
    );
  }

  if (referralToken === 'riskmaster') {
    return (
      <>
        <div style={{
          maxWidth: 450,
          marginLeft: 'auto',
          marginRight: 'auto',
          paddingBottom: 24
        }}>
          <SmallBannerNotification
            type={NotificationStyleTypes.Info}
            style={{ marginBottom: 8 }}
          >
            <div className="referralNotification">
              <div className="message" style={{ textAlign: 'center' }}>
                <Image image={{ src: DxcLogo }} />
                <div className="header">
                  <Messages.DxcNotificationHeader.Message />
                </div>
                <Messages.DxcNotificationSubheader.Message />
              </div>
            </div>
          </SmallBannerNotification>
        </div>
        <Row className="twoColumnRegistration">
          <Column size={6} className="leftColumn" style={{ paddingTop: 12 }}>
            <BodyText style={{ paddingBottom: 16 }}>
              <DxcMessages.Header.VpMessage/>
            </BodyText>
            <Row style={{ paddingBottom: 12 }}>
              <DxcStepColumn number={1} />
              <Column size={10}><BodyText><DxcMessages.Step1Instructions.VpMessage /></BodyText></Column>
            </Row>
            <Row style={{ paddingBottom: 12 }}>
              <DxcStepColumn number={2} />
              <Column size={10}><BodyText><DxcMessages.Step2Instructions.VpMessage /></BodyText></Column>
            </Row>
            <Row style={{ paddingBottom: 12 }}>
              <DxcStepColumn number={3} />
              <Column size={10}>
                <BodyText>
                  <span className="type-strong">
                    <DxcMessages.Step3InstructionsOne.Message supportEmail={CustomerSupportEmail} />
                  </span>
                  <DxcMessages.Step3InstructionsTwo.Message />
                </BodyText>
                <BodyText>
                  <BulletedList
                    variant="bullets"
                    subVariant="large2"
                    style={{ paddingTop: 12, paddingBottom: 12 }}
                  >
                    <Bullet><DxcMessages.Step3Bullet1.Message /></Bullet>
                    <Bullet><DxcMessages.Step3Bullet2.Message /></Bullet>
                    <Bullet><DxcMessages.Step3Bullet3.Message /></Bullet>
                    <Bullet><DxcMessages.Step3Bullet4.Message /></Bullet>
                  </BulletedList>
                  <DxcMessages.Step3Asterisk.VpMessage />
                </BodyText>
              </Column>
            </Row>
            <Row>
              <DxcStepColumn number={4} />
              <Column size={10}><BodyText><DxcMessages.Step4Instructions.VpMessage /></BodyText></Column>
            </Row>
          </Column>
          <Column size={6} className="rightColumn">
            {children}
          </Column>
        </Row>
      </>
    );
  }

  if ((achPlusInvitationData || {}).paymentCount) {
    return (
      <>
        <div style={{ paddingBottom: 24 }}>
          <AchPlusInvitationContext achPlusInvitationData={achPlusInvitationData} />
        </div>
        {children}
      </>
    );
  }

  return <>{children}</>;
};

export default AdditionalRegistrationContext;
