/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

const logonRequestBodyMapper = ({
  changeEmailToken,
  companyId,
  deviceToken,
  invitationId,
  password,
  userName,
  username,
  clientInfo
}) => ({
  userName: userName || username,
  password,
  changeEmailToken,
  deviceToken,
  invitationId,
  companyId,
  clientInfo
});

export default logonRequestBodyMapper;