/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

// import { getEntity, getEntityList } from 'schemas/state';
import { makeApiCall } from 'actions/references';
import NetworkApi from 'api/network';

export function MapStateToProps(state) {
  return {
  };
}

export function MapDispatchToProps(dispatch) {
  return {
    onUpdate: (formData, onComplete) => dispatch(makeApiCall(
      'updateBeneficialOwnerInfo',
      NetworkApi.updateBeneficialOwnerInfo,
      formData,
      {
        successMessage: true,
        apiMetadata: { onComplete }
      }
    ))
  };
}
