/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React from 'react';
import PropTypes from 'prop-types';
import './index.scss';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import Messages from './index.messages';

const FormLegend = ({ style, align, showRule }) => (
  <div className={classNames(`text-${align}`, {'legend-bottom-rule': showRule})} style={style}>
    <span className="vp-label form-legend-text"><FormattedMessage {...Messages.Required} /></span>
    <span className="required-asterisk">&nbsp;*</span>
  </div>
);

FormLegend.propTypes = {
  align: PropTypes.oneOf(['left', 'right']),
  style: PropTypes.object
};

FormLegend.defaultProps = {
  align: 'right'
};

export default FormLegend;
