/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */
/* eslint max-len:0 */

import { toMessages } from 'config/messages';
import { RegistrationErrors } from 'config/constants/registration';
import { ExpeditedRegistrationError } from 'api/registration';

export default toMessages('Registration', {
  CreateAccountButton: 'Create Account',
  Disclaimer: 'By creating an account, you accept our {tou}, {paymentTerms}, and {privacyPolicy}.',
  AdminNotified: 'We\'ve sent your company\'s Viewpost administrator an email to let them know you want to join the network. They\'ll contact you about registering under your company account. If you have any questions, please contact Customer Support at 888.248.9190.',
  TestEnvDisclaimer: 'This is a cloud environment that is not managed or secured by Viewpost. Any data that you provide to the cloud environment is done at your own risk.',
  NotRegisteredQuestion: 'Don\'t have an account?',
  NotRegisteredAction: 'Register Now',
  RegisteredQuestion: 'Already have an account?',
  RegisteredAction: 'Sign In',
  LoggedInInstructions: 'You are already logged in. Continue to do more.',
  LoggedInButton: 'Continue',
  PaymentConfirmationSubheader: 'Thanks! You\'re all set.',
  PaymentConfirmationWithVerificationSubheader: 'Thanks! Just one more thing.',
  PaymentConfirmationMessageHeader: 'Payment confirmation has been sent to {emailAddress}.',
  PaymentConfirmationMessage: '{amount} has been charged to your credit card for the balance of this month. Future monthly payments of {amount} will occur automatically on the {day, selectordinal, one {#st} two {#nd} few {#rd} other {#th}} of each month.',
  PaymentConfirmationButton: 'View Your Viewpost Account'
});

export const ErrorMessages = toMessages('Registration.Error', {
  Default: 'Hmmm. Something went wrong here. Contact Customer Support for assistance with registering.',
  [RegistrationErrors.EmailAddressInUse]: 'This email address is already registered. If this email belongs to you, please log in to access your account.',
  [RegistrationErrors.CommonPassword]: 'The password you entered is too common, please create another.',
  [RegistrationErrors.InvalidCaptchaCode]: 'Can you validate you aren\'t a robot below?',
  [ExpeditedRegistrationError.CreditCardProcessingError]: 'There was a problem processing your credit card. Please verify your information and try again.'
});
