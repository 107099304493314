/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React from 'react';
import './index.scss';

const GreyNavBar = ({
  onClick,
  style,
  children
}) => (
  <div className="viewstrap grey-nav-bar" onClick={onClick} style={style}>
    {children}
  </div>
);

export default GreyNavBar;