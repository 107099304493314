/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */
/* eslint max-len:0 */

import { toMessages } from 'config/messages';

export default toMessages('AddCheckingAccount.AddBankAccountManually.Form', {
  AccountName: 'Account Nickname',
  AccountNamePlaceholder: 'E.g. corporate checking',
  RoutingNumber: 'Routing Number',
  AccountNumber: 'Account Number',
  VerifyAccountNumber: 'Verify Account Number',
  SampleCheck: 'Locate Routing and Account numbers from a check or bank statement',
  BankName: 'Bank Name',
  DoNotMatch: 'Please enter a matching account number',
  RoutingNumberInvalid: 'Routing number is not valid',
  RoutingNumberLength: 'Routing number must be 9 digits',
  BankAccountNameTaken: 'Account name already in use'
});
