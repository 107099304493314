/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { isPersonalPortal } from 'actions/common';
import getCompanyFeeSettings from 'actions/common/getCompanyFeeSettings';
import getSecureTokenIdentity from 'actions/common/getSecureTokenIdentity';

import {
  BillViewActions,
  InvoiceActions,
  InvoiceViewActions,
  InvoiceRecurrenceActions,
  InvoiceCommentActions,
  SettingsActions,
  SettingsViewActions,
  SyncActions
} from 'config/constants';
import { SyncViewActions, ViewActions } from 'config/constants/app';
import { AddBankAccountActions, BankAccountActions } from 'config/constants/bankAccount';
import NetworkActions from 'config/constants/network/NetworkActions';
import PaymentActions from 'config/constants/PaymentActions';
import PaymentViewActions from 'config/constants/PaymentViewActions';
import { PersonalPortalViewActions } from 'config/constants/personalPortal';
import { KnowledgeAuthenticationRequired, CompanyAccountType } from 'config/constants/session';

import { getEntity } from 'schemas/state';
import { InvoiceType } from 'schemas/invoicing/invoice';

import {
  isActionAllowed as baseIsActionAllowed,
  determineAvailableActions as baseDetermineAvailableActions
} from './permissions.utils';

export function getIdentity(state) {
  if (!state || !state.session || !state.session.identity) {
    return {};
  }

  let identity = getEntity(state, state.session.identity);
  if (!identity) {
    return {};
  }
  return identity;
}

function getPermissionsFromState(state) {
  const identity = getIdentity(state);
  const secureTokenIdentity = getSecureTokenIdentity(state, { notExpected: true, returnNull: true });

  if (!identity.permissions && secureTokenIdentity) {
    return secureTokenIdentity.permissions;
  }

  return identity.permissions;
}

function getAccountingPackageFromState(state) {
  let identity = getIdentity(state);
  return identity.accountingPackage;
}

function getCompanySettingsFromState(state) {
  let identity = getIdentity(state);
  return identity.companySettings;
}

function getUserSettingsFromState(state) {
  let identity = getIdentity(state);
  return identity.userSettings;
}

// These are actions we give because there isn't
// an immediate reason to disqualify them, so let
// the user have them unless something else disagrees
const isFreeAction = () => (action) => {
  switch (action) {
    case NetworkActions.ViewNetworkProfile:
    case NetworkActions.ViewAddressBookDetails:
    case InvoiceActions.CreateLateFee:
    case InvoiceActions.RemoveLateFee:
    case InvoiceViewActions.ViewList:
    case InvoiceViewActions.ViewSummary:
    case InvoiceViewActions.ViewRecurring:
    case BillViewActions.ViewList:
    case BillViewActions.ViewStatements:
    case PaymentActions.ApplyPayments:
    case InvoiceActions.RespondToApprovalRequest:
    case InvoiceActions.ViewApprovalRequests:
    case InvoiceActions.ViewAttachments:
    case PaymentActions.ViewReceivedDate:
    case ViewActions.CanFilterInvoiceListByCompany:
    case ViewActions.CanFilterPaymentsReceivedListByCompany:
    case ViewActions.CanFilterPaymentsReceivedListByStatus:
    case AddBankAccountActions.AddSendAndReceiveAccount:
    case SettingsViewActions.CanSwitchCompanies:
    case SettingsActions.CreateSwitchCompany:
    case NetworkActions.AssistContact:
      return true;
    default:
  }
};

const hasPermissionForAction = permissions => (action) => {
  if (!permissions) return false;
  switch (action) {
    case NetworkActions.CreateAddressBook:
    case NetworkActions.UpdateAddressBook:
    case SettingsViewActions.UseFileImport:
      return permissions.manageContacts;

    case NetworkActions.Invite:
    case NetworkActions.Reinvite:
      return permissions.manageConnections && permissions.canInvite;

    case PaymentActions.UpdateAddressBook:
      return permissions.manageConnections && permissions.canInvite && permissions.manageContacts;

    case NetworkActions.Send:
    case NetworkActions.Resend:
    case NetworkActions.Cancel:
    case NetworkActions.Accept:
    case NetworkActions.Decline:
    case NetworkActions.Disconnect:
    case NetworkActions.AddAccount:
      return permissions.manageConnections;

    case NetworkActions.DeleteAddressBook:
      return permissions.manageConnections && permissions.manageContacts;

    case BankAccountActions.Disable:
    case BankAccountActions.Reset:
    case BankAccountActions.Enable:
    case BankAccountActions.Add:
    case BankAccountActions.Verify:
    case BankAccountActions.Register:
    case BankAccountActions.Reregister:
    case BankAccountActions.Delete:
    case BankAccountActions.EditACH:
    case SettingsViewActions.ViewCompanySettings:
    case SettingsViewActions.ViewPaymentMethods:
    case SettingsViewActions.ViewFees:
    case SettingsActions.EnrollWithStripe:
    case SettingsActions.UpdateCompanyProfile:
    case SettingsActions.UpdateMultiStepApprovals:
    case SettingsActions.UpdateAutoApplyPayments:
    case SettingsActions.ConnectAccountingPackage:
      return permissions.modifyCompanySettings;

    case SettingsActions.UpdateEarlyPay:
    case SettingsViewActions.ViewEarlyPay:
      return permissions.modifyDiscountTables;

    case PaymentViewActions.ViewReceived:
      return permissions.recordPayments;

    case PaymentActions.SignCheck:
    case PaymentActions.Approve:
    case PaymentViewActions.ViewApprovals:
      return permissions.signCheck;

    case PaymentViewActions.ViewSent:
    case PaymentViewActions.ViewPayments:
      return permissions.createCheck || permissions.modifyBills || permissions.signCheck
        || permissions.viewFees || permissions.recordPayments || permissions.unvoidChecks
        || permissions.voidChecks;

    case PaymentActions.VoidPayment:
      return permissions.voidChecks;

    case PaymentActions.GetAccounts:
      return permissions.modifyCompany || permissions.createCheck;

    case PaymentActions.SendPayment:
    case BillViewActions.ViewBillEarlyPay:
      return permissions.createCheck;

    case InvoiceActions.CreateBill:
    case InvoiceActions.ViewOpenBills:
      return permissions.modifyBills;

    case InvoiceActions.CreateInvoice:
    case InvoiceActions.GetInvoiceInsuranceQuote:
    case InvoiceActions.UpdateRecurrence:
    case InvoiceActions.StartRecurrence:
    case InvoiceActions.PurchaseInvoiceInsurance:
    case InvoiceRecurrenceActions.Edit:
    case InvoiceRecurrenceActions.Delete:
    case InvoiceRecurrenceActions.Pause:
    case InvoiceRecurrenceActions.Resume:
      return permissions.modifyInvoices;

    case InvoiceActions.Void:
      return permissions.voidInvoices;

    case InvoiceActions.Unvoid:
      return permissions.unvoidInvoices;

    case InvoiceActions.Dispute:
    case InvoiceActions.EndDispute:
    case InvoiceActions.Reject:
      return permissions.approveInvoices;

    case InvoiceActions.RouteForApproval:
    case InvoiceActions.RouteForAdditionalApproval:
    case InvoiceActions.Approve:
      return permissions.approveInvoices || permissions.overrideApprovalWorkflows;

    case InvoiceActions.ApproveAndQuickPay:
    case InvoiceActions.ApproveAndPaymentQueue:
      return (permissions.approveInvoices || permissions.overrideApprovalWorkflows)
        && permissions.createCheck;

    case InvoiceActions.BatchApproveAndPaymentQueue:
      return permissions.approveInvoices && permissions.createCheck && permissions.modifyBills;

    case InvoiceActions.QuickPay:
    case InvoiceActions.AddToPaymentQueue:
      return permissions.modifyBills && permissions.createCheck;

    case InvoiceActions.VpxRequest:
    case InvoiceActions.VpxCancel:
    case InvoiceViewActions.ViewInvoiceEarlyPay:
      return permissions.requestEarlyPayment;

    case SettingsViewActions.ViewUsers:
    case SettingsViewActions.ViewGroups:
    case NetworkActions.EditReceivePaymentByCreditCard:
      return permissions.modifyUsersAndGroups;

    case PaymentViewActions.ViewAutoPay:
    case PaymentActions.EnrollAutoPay:
      return permissions.signCheck && permissions.createCheck;

    case PaymentActions.CancelScheduled:
      return permissions.signCheck || permissions.createCheck;

    default: return undefined;
  }
};

const hasConfigurationForAction = (
  {
    hasSubscriptionPaymentMethod,
    isInSubscriptionTrial,
    ...companySettings
  }
) => (action) => {
  switch (action) {
    case InvoiceViewActions.ViewInbox:
      return !companySettings.autoApprovesInvoices;

    case PaymentViewActions.ViewQbo:
      return companySettings.qboEnabled;

    case InvoiceViewActions.ViewQbo:
      return companySettings.qboEnabled;

    case SettingsViewActions.UseFileImport:
      return !companySettings.qboEnabled;

    case InvoiceActions.PurchaseInvoiceInsurance:
    case InvoiceActions.GetInvoiceInsuranceQuote:
      return companySettings.canPurchaseInvoiceInsurance;

    case SettingsActions.CanEnrollConsumerPayments:
      return companySettings.canEnrollConsumerPayments;

    case SettingsActions.CanReceiveConsumerPayments:
      return companySettings.canReceiveConsumerPayments;

    case PaymentActions.RequestPaymentSupport:
      return companySettings.paymentOptimization.vccEnabled
        || companySettings.paymentOptimization.achPlusEnabled;

    case BankAccountActions.Delete:
      return companySettings.kbaRequired !== KnowledgeAuthenticationRequired.PermanentFailure;

    case SettingsViewActions.CanUpgradeToCorporate:
      if (isInSubscriptionTrial && !hasSubscriptionPaymentMethod) return true;
      break;
    default:
  }
};

const hasPermissionForInvoiceAction = (permissions, invoiceType, isMine) => (action) => {
  if (!permissions) return false;
  let isPayable = invoiceType === InvoiceType.meta.map.Payable;
  let isReceivable = invoiceType === InvoiceType.meta.map.Receivable;
  let isOutOfBandInvoice = invoiceType === InvoiceType.meta.map.OutOfBandInvoice;
  let isAPInboxItem = invoiceType === InvoiceType.meta.map.APInboxItem;
  let isMyPayable = isPayable && isMine;
  let isTheirPayable = isPayable && !isMine;
  let isMyReceivable = !isPayable && isMine;
  let isTheirReceivable = !isPayable && !isMine;

  switch (action) {

    case InvoiceActions.Comment:
    case InvoiceCommentActions.Edit:
    case InvoiceCommentActions.Delete:
      if (isMyPayable) return permissions.modifyBills;
      if (isMyReceivable) return permissions.modifyInvoices;
      if (isTheirPayable) return permissions.approveInvoices || permissions.modifyBills;
      if (isTheirReceivable) return permissions.approveInvoices || permissions.modifyInvoices;
      break;

    case InvoiceActions.Link:
      return isMyPayable
        ? permissions.approveInvoices || permissions.overrideApprovalWorkflows
        : permissions.modifyInvoices;

    case InvoiceActions.Copy:
    case InvoiceActions.Edit:
    case InvoiceActions.Delete:
      if (isOutOfBandInvoice || isAPInboxItem) return permissions.modifyBills || permissions.approveInvoices;
      if (isReceivable) return permissions.modifyInvoices;
      if (isPayable) return permissions.modifyBills;
      break;

    case InvoiceActions.Send:
    case InvoiceActions.Unlink:
      return isPayable ? permissions.modifyBills : permissions.modifyInvoices;

    case InvoiceActions.SendAndQuickPay:
      return permissions.createCheck
        && isPayable ? permissions.modifyBills : permissions.modifyInvoices;

    case InvoiceActions.RecordPayment:
    case InvoiceActions.MarkAsPaid:
      return isPayable ? permissions.signCheck : permissions.recordPayments;

    case InvoiceActions.SendReminder:
    case InvoiceActions.Share:
    case InvoiceActions.SaveAsPdf:
    case InvoiceActions.SendToPrinter:
      return true;

    default: return hasPermissionForAction(permissions)(action);
  }
};

export const isSync2AccountingPackage = accountingPackage => [
  'Xero',
  'QuickBooksOnline',
  'NetSuite',
  'SageLive',
  'Riskmaster'
].includes(accountingPackage);

export const needsErpLinkOnConnect = accountingPackage => ['Xero', 'QuickBooksOnline'].includes(accountingPackage);

export const needsAddressBookLinkOnConnect = accountingPackage => ['NetSuite', 'File'].includes(accountingPackage);

const isActionAllowedForAccountingPackage = (accountingPackage, permissions) => (action) => {
  const isWebCompany = !accountingPackage
    || accountingPackage === 'None'
    || accountingPackage === 'QuickBooksOnlineV1';
  const isQuickBooksDesktop = accountingPackage === 'QuickBooksDesktop';
  const isFileUpload = accountingPackage === 'File';

  switch (action) {
    case SyncViewActions.ViewPayments:
      return !isWebCompany
        && !isFileUpload
        && !['NetSuite', 'SageLive', 'Riskmaster'].includes(accountingPackage);
    case SyncViewActions.ViewHistory:
    case SyncViewActions.ViewSettings:
      return !isWebCompany && !isFileUpload;
    default:
      break;
  }

  if (isSync2AccountingPackage(accountingPackage)) {
    let canDisconnect = permissions.modifyCompanySettings && accountingPackage !== 'Riskmaster';

    switch (action) {
      case SyncActions.Disconnect:
        return canDisconnect;
      case SyncActions.Connect:
      case SyncActions.ModifySettings:
        return permissions.modifyCompanySettings;
      case SyncActions.ModifyAutoSend:
        return permissions.signCheck;
      case InvoiceActions.CreateInvoice:
      case InvoiceActions.UpdateRecurrence:
      case InvoiceActions.StartRecurrence:
      case PaymentActions.SendPayment:
      case InvoiceActions.CreateBill:
      case SettingsActions.UpdateMultiStepApprovals:
      case SettingsActions.UpdateAutoApplyPayments:
      case InvoiceActions.Delete:
      case InvoiceActions.ApproveAndQuickPay:
      case InvoiceActions.ApproveAndPaymentQueue:
      case InvoiceActions.SendAndQuickPay:
      case InvoiceActions.Send:
      case InvoiceActions.Copy:
      case InvoiceActions.QuickPay:
      case InvoiceActions.VpxRequest:
      case InvoiceActions.VpxCancel:
      case InvoiceActions.AddToPaymentQueue:
      case SettingsActions.EnrollWithStripe:
      case InvoiceViewActions.ViewRecurring:
      case InvoiceActions.SetUpAutoPay:
      case PaymentActions.ApplyPayments:
      case PaymentActions.UpdateReceived:
      case NetworkActions.CreateAddressBook:
      case NetworkActions.DeleteAddressBook:
      case NetworkActions.Send:
      case SettingsViewActions.ViewEarlyPay:
      case PaymentActions.EnrollAutoPay:
      case PaymentViewActions.ViewAutoPay:
      case SettingsViewActions.UseFileImport:
      case BillViewActions.ViewBillEarlyPay:
        return false;
      default:
        return undefined;
    }
  }

  switch (action) {
    case NetworkActions.CreateAddressBook:
    case InvoiceActions.CreateInvoice:
    case InvoiceActions.UpdateRecurrence:
    case InvoiceActions.StartRecurrence:
    case PaymentActions.SendPayment:
    case InvoiceActions.CreateBill:
    case SettingsActions.UpdateMultiStepApprovals:
    case SettingsActions.UpdateAutoApplyPayments:
    case InvoiceActions.Delete:
    case InvoiceActions.RecordPayment:
    case InvoiceActions.Approve:
    case InvoiceActions.ApproveAndQuickPay:
    case InvoiceActions.ApproveAndPaymentQueue:
    case InvoiceActions.Copy:
    case SettingsViewActions.UseFileImport:
      return isWebCompany || isQuickBooksDesktop;

    case InvoiceActions.CreateLateFee:
    case InvoiceActions.RemoveLateFee:
      return isWebCompany;

    case PaymentActions.VoidPayment:
    case BankAccountActions.Add:
      return true;
    case SettingsActions.EnrollWithStripe:
    case InvoiceViewActions.ViewRecurring:
    case PaymentActions.ApplyPayments:
    case PaymentActions.UpdateReceived:
      return !isFileUpload;

    default: return undefined;
  }
};

const isVendorPortalCompany = companySettings => !!(companySettings
    && companySettings.accountType === CompanyAccountType.Portal
    && companySettings.totalCustomers);

const isCustomerPortalCompany = companySettings => !!(companySettings
  && companySettings.accountType === CompanyAccountType.Portal
  && companySettings.totalVendors);

const CommonBlockedPersonalPortalActions = [
  NetworkActions.CreateAddressBook,
  NetworkActions.UpdateAddressBook,
  InvoiceActions.CreateInvoice,
  InvoiceActions.UpdateRecurrence,
  InvoiceActions.StartRecurrence,
  PaymentActions.SendPayment,
  InvoiceActions.CreateBill,
  SettingsActions.UpdateMultiStepApprovals,
  SettingsActions.UpdateAutoApplyPayments,
  InvoiceActions.Copy,
  InvoiceViewActions.ViewRecurring,
  PaymentActions.ApplyPayments,
  PaymentActions.UpdateReceived,
  SettingsActions.CreateSwitchCompany
];

const isActionAllowedForPersonalPortal = (
  companySettings,
  associatedCompaniesCount
) => (action) => {
  if (CommonBlockedPersonalPortalActions.includes(action)) return false;

  const isCustomer = isCustomerPortalCompany(companySettings);
  const isVendor = isVendorPortalCompany(companySettings);

  switch (action) {
    case SettingsViewActions.CanSwitchCompanies:
      return associatedCompaniesCount > 1;
    case BillViewActions.ViewPersonalOpen:
    case BillViewActions.ViewPersonalClosed:
    case PersonalPortalViewActions.ViewAutoPay:
    case PersonalPortalViewActions.ViewFees:
      return isCustomer;
    case InvoiceViewActions.ViewList:
    case PaymentViewActions.ViewReceived:
      return isVendor;
    default:
  }
};

const CommonBlockedPortalActions = [
  PaymentActions.ApplyPayments,
  PaymentActions.UpdateReceived,
  SettingsActions.CanEnrollConsumerPayments,
  SettingsActions.CanReceiveConsumerPayments,
  PaymentActions.ViewReceivedDate,
  NetworkActions.Invite,
  NetworkActions.Reinvite,
  NetworkActions.ViewNetworkProfile,
  ViewActions.CanFilterPaymentsReceivedListByStatus,
  InvoiceViewActions.ViewSummary
];

const isActionAllowedForPortal = companySettings => (action) => {
  if (!companySettings || companySettings.accountType !== CompanyAccountType.Portal) return;
  if (CommonBlockedPortalActions.includes(action)) return false;

  switch (action) {
    case SettingsViewActions.CanUpgradeToCorporate:
      return true;
    default:
  }
};

const isActionAllowedForVendorPortal = companySettings => (action) => {
  if (!isVendorPortalCompany(companySettings)) return;

  switch (action) {
    case AddBankAccountActions.AddSendAndReceiveAccount:
      return companySettings.totalVendors > 1;
    case AddBankAccountActions.AddReceiveOnlyAccount:
      return companySettings.totalVendors <= 1;
    case InvoiceActions.CreateInvoice:
      return companySettings.vendorsAllowCreateInvoice;
    case InvoiceActions.CreateBill:
    case InvoiceActions.AddToPaymentQueue:
    case InvoiceActions.ApproveAndPaymentQueue:
    case InvoiceActions.BatchApproveAndPaymentQueue:
    case BillViewActions.ViewList:
    case BillViewActions.ViewStatements:
    case BillViewActions.ViewBillEarlyPay:
    case PaymentActions.SendPayment:
    case PaymentViewActions.ViewSent:
    case PaymentViewActions.ViewApprovals:
    case PaymentViewActions.ViewAutoPay:
      return isCustomerPortalCompany(companySettings) ? undefined : false;
    case ViewActions.CanFilterInvoiceListByCompany:
    case ViewActions.CanFilterPaymentsReceivedListByCompany:
      return companySettings.totalCustomers > 1;
    default:
  }
};

const isActionAllowedForCustomerPortal = companySettings => (action) => {
  if (!isCustomerPortalCompany(companySettings)) return;

  switch (action) {
    case SettingsActions.EnrollWithStripe:
    case InvoiceViewActions.ViewList:
    case InvoiceViewActions.ViewInvoiceEarlyPay:
    case InvoiceViewActions.ViewRecurring:
    case PaymentViewActions.ViewReceived:
    case InvoiceActions.CreateInvoice:
      // Hide these unless they are vendor portal
      return isVendorPortalCompany(companySettings) ? undefined : false;
    default:
  }
};

export const determineAvailableActions = (
  availableActions,
  {
    identityPermissions,
    accountingPackage,
    companySettings,
    userSettings,
    isPersonal,
    associatedCompaniesCount,
    companyFeeSettings
  }
) => baseDetermineAvailableActions(availableActions, [
  isFreeAction(),
  hasPermissionForAction(identityPermissions),
  hasConfigurationForAction(companySettings ?? {}, userSettings ?? {}, companyFeeSettings ?? {}),
  isActionAllowedForAccountingPackage(accountingPackage, identityPermissions),
  isPersonal ? isActionAllowedForPersonalPortal(companySettings, associatedCompaniesCount) : null,
  isActionAllowedForPortal(companySettings, associatedCompaniesCount),
  isActionAllowedForVendorPortal(companySettings),
  isActionAllowedForCustomerPortal(companySettings)
]);

export const determineAvailableActionsFromState = (state, availableActions) => {
  const identityPermissions = getPermissionsFromState(state);
  const accountingPackage = getAccountingPackageFromState(state);
  const companySettings = getCompanySettingsFromState(state);
  const userSettings = getUserSettingsFromState(state);
  const isPersonal = isPersonalPortal(state);
  const associatedCompaniesCount = getIdentity(state).associatedCompaniesCount;
  const companyFeeSettings = getCompanyFeeSettings(state);

  return determineAvailableActions(availableActions, {
    identityPermissions,
    accountingPackage,
    companySettings,
    userSettings,
    isPersonal,
    associatedCompaniesCount,
    companyFeeSettings
  });
};

/**
 * Legacy, replace with isActionAllowed in permissions.utils
 */
export function isActionAllowed(checkers, action) {
  // Drop the rest of the data on the ground
  const { allowed } = baseIsActionAllowed(checkers, action);

  return allowed;
}

/**
 * Legacy, replace with determineAvailableActions
 */
export function filterAvailableActions(
  identityPermissions,
  accountingPackage,
  companySettings,
  userSettings,
  availableActions,
  isPersonal,
  associatedCompaniesCount,
  companyFeeSettings
) {
  // Drop the rest of the data on the ground
  const {
    availableActions: filteredAvailableActions
  } =  determineAvailableActions(
    availableActions,
    {
      identityPermissions,
      accountingPackage,
      companySettings,
      userSettings,
      isPersonal,
      associatedCompaniesCount,
      companyFeeSettings
    }
  );

  return filteredAvailableActions;
}

/**
 * Legacy, replace with determineAvailableActionsFromState
 */
export function filterAvailableActionsFromState(state, availableActions) {
  const {
    availableActions: filteredAvailableActions
  } = determineAvailableActionsFromState(state, availableActions);

  // Drop the rest of the data on the ground
  return filteredAvailableActions;
}

export function filterInvoiceActionsFromState(state, availableActions, invoiceType, isMine) {
  const permissions = getPermissionsFromState(state);
  const accountingPackage = getAccountingPackageFromState(state);
  const companySettings = getCompanySettingsFromState(state);

  return availableActions.filter((action) => {
    return isActionAllowed([
      isFreeAction(),
      hasPermissionForInvoiceAction(permissions, invoiceType, isMine),
      isActionAllowedForAccountingPackage(accountingPackage, permissions),
      isActionAllowedForVendorPortal(companySettings),
      isActionAllowedForCustomerPortal(companySettings)
    ].filter(a => a), action);
  });
}

// FIXME: this doesn't belong here
function getVPCompanyIdFromState(state) {
  let identity = getIdentity(state);
  return identity.viewpostBillingCompanyId;
}
export function canDisconnectFromCompany(state, companyId) {
  let vpCompanyId = getVPCompanyIdFromState(state);
  return vpCompanyId !== companyId;
}
