/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { isObject, uniq } from 'lodash';
import logError from 'services/ErrorService';

export const isActionAllowed = (checkers, action) => {
  // Letting the checker return undefined means that it
  // has no opinion if it should be allowed, so it shouldn't
  // be immediately disqualified, but if everything returns
  // undefined, then default to it not being allowed
  let anyoneReturnedValue = false;
  let deniedReasons = [];
  const allAllowed = checkers.every((checker) => {
    let isAllowed = checker(action);

    if (isAllowed !== undefined) anyoneReturnedValue = true;

    if (isAllowed === undefined || isAllowed) {
      if (isObject(isAllowed)) {
        if (isAllowed.deniedReason) {
          deniedReasons = deniedReasons.concat([ isAllowed.deniedReason ]);
          return false;
        } if (isAllowed.deniedReasons) {
          deniedReasons = deniedReasons.concat(isAllowed.deniedReasons);
          return false;
        }

        logError(`Unknown permissions check object returned for ${action}. Assuming true.`);
      }

      return true;
    }

    return false;
  });

  return {
    action,
    allowed: anyoneReturnedValue ? allAllowed : false,
    deniedReasons
  };
};

const getCheckers = (checkers) => {
  if (!checkers) return null;

  return Array.isArray(checkers)
    ? checkers
    : [ checkers ];
};

// This is an alternative implementation of available actions that ultimately
// has the same output but differs completely in how you set it up and use it.
// The previous implementation worked by building up all checks in one
// mega-function and being passed a set of available actions and the check
// parameters all at once. This worked when there was a small set of permissions
// but is unweidly at a large number of them and having different aspects that
// qualify/disqualify an available action. There is also the problem that rules
// themselves are static but the params do change but there is no clear path how
// to recalculate the state.
// This implementation works by taking the available action calculation and
// separating it into the three parts: rules -> action -> params
// The rules are markedly different from the previous implementation where it
// was a single function that takes the action to calculate the state for. This
// makes the action a key in map of the rules to apply it. A map value can have
// multiple rules, this is so you can have as many rules that make sense for
// that action (e.g., a permissions check, a server setting check, etc)
// A rule is a function takes in a params object and returns the result of the
// check.
// A rule can optionally have a 'requirements' field set on it with an array of
// strings that can be used to assist the more dynamic cases in filling out the
// params where it can - such as pulling in the user's current permissions.
// It is also expected many maps will be passed in, to be used however you find
// it best to organize your checks, but is a necessity if you want all actions
// to be subject to a rule, which you set up by having a 'default' key. If an
// action is not present in the map, the 'default' rule is applied.
// The result of passing in all the maps is a function that takes in an action
// and that function returns a function that takes in the parameters to pass to
// the rules.
// Because there are all these levels, going from the most static to the least
// static, as well as making the checks individual to each action, this allows
// for availableActions to be calculated individually and with the latest and
// greatest data. This opens up the possiblity of moving availableActions out of
// the entity and into connect/hooks to be calculated on the fly. This works
// really well to open up a new possibility of available actions being comprised
// of not just global state and a single entity's state, but also with maybe
// another entity or component state, all while conforming to the standard
// available action model.
export const createDetermineAvailableActionState = (
  actionCheckMaps
) => {
  const calculateAvailableActionCache = {};

  const getCalculateAvailableAction = (
    action
  ) => {
    if (!calculateAvailableActionCache[action]) {
      const actionCheckers = actionCheckMaps.reduce(
        (val, actionCheckMap) => {
          const checkers = getCheckers(actionCheckMap[action]);

          if (!checkers) {
            const defaultCheckers = getCheckers(actionCheckMap.default);

            return defaultCheckers
              ? [ ...val, ...defaultCheckers ]
              : val;
          }

          return [ ...val, ...checkers ];
        },
        []
      );

      const calculateRequirements = uniq(
        actionCheckers.reduce(
          (val, checker) => checker.requirements
            ? [ ...val, ...checker.requirements ]
            : val,
          []
        )
      );

      const calculateAvailableAction = (params) => {
        // Letting the checker return nil means that it has no opinion if it
        // should be allowed, so it shouldn't be immediately disqualified, but
        // if everything returns nil, then default to it not being allowed
        let anyoneReturnedValue = false;
        let deniedReasons = [];
        const allAllowed = actionCheckers.every((checker) => {
          const isAllowed = checker(params);

          if (isAllowed != null) anyoneReturnedValue = true;

          if (isAllowed == null || isAllowed) {
            if (isObject(isAllowed)) {
              if (isAllowed.deniedReason) {
                deniedReasons = deniedReasons.concat([ isAllowed.deniedReason ]);
                return false;
              } if (isAllowed.deniedReasons) {
                deniedReasons = deniedReasons.concat(isAllowed.deniedReasons);
                return false;
              }

              logError(`Unknown permissions check object returned for ${action}. Assuming true.`);
            }

            return true;
          }

          return false;
        });

        return {
          // Not necessary to have if you are using calculateAvailableAction directly, but is
          // helpful to have when combining the results of many actions.
          action,
          allowed: anyoneReturnedValue ? allAllowed : false,
          deniedReasons
        };
      };

      calculateAvailableActionCache[action] = {
        calculateAvailableAction,
        calculateRequirements
      };
    }

    return calculateAvailableActionCache[action];
  };

  const determineAvailableActions = (availableActions, params) => {
    if (!availableActions) {
      return { availableActions: [], deniedReasons: {} };
    }

    const availableActionResults = availableActions
      .map(action => getCalculateAvailableAction(action).calculateAvailableAction(params));

    return {
      availableActions: availableActionResults
        .filter(x => x.allowed)
        .map(x => x.action),
      deniedReasons: availableActionResults
        .filter(x => !x.allowed && x.deniedReasons.length)
        .reduce(
          (
            val,
            {
              action,
              deniedReasons
            }
          ) => {
            val[action] = deniedReasons;
            return val;
          },
          {}
        )
    };
  };

  return {
    determineAvailableActions,
    getCalculateAvailableAction
  };
};

export const determineAvailableActions = (availableActions, checkers) => {
  if (!availableActions) {
    return { availableActions: [], deniedReasons: {} };
  }

  const availableActionResults = availableActions
    .map(action => isActionAllowed(checkers.filter(a => a), action));

  return {
    availableActions: availableActionResults
      .filter(x => x.allowed)
      .map(x => x.action),
    deniedReasons: availableActionResults
      .filter(x => !x.allowed && x.deniedReasons.length)
      .reduce((val, { action, deniedReasons }) => {
        val[action] = deniedReasons;
        return val;
      }, {})
  };
};
