/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React from 'react';

import { Row, Column } from 'components/Viewstrap/Grid';
import Address, { LinesProp } from 'components/Address';
import FormattedDate from 'components/FormattedDate';
import Icon, { IconTypes } from 'components/Icon';
import Tooltip from 'components/Tooltip';
import Currency from 'components/Currency';
import HeaderText from 'components/HeaderText';

import moment from 'moment';

import Guidepoint from './Guidepoint';
import CommonProblems from './CommonProblems';
import HelpForm from './HelpForm';
import './index.scss';

function Field({label, value}) {
  return (
    <div style={{ padding: '16px'}}>
      <div style={{ fontSize: '14px', color: '#8B8B8B', lineHeight: '18px'}}>
        {label}
      </div>
      <div>
        <strong>{value}</strong>
      </div>
    </div>
  );
}

function SixDigitLink({ vccPayment, secureToken, children }) {
  return (
    <a href={`/onboarding/vccDigits/${vccPayment.id}/${secureToken}`} target="_blank">
      {children}
    </a>
  );
}

function getTooltipMessage(key, vccPayment, secureToken) {
  switch (key) {
    case 'CardStatus':
      return <div>
        Each virtual card payment has a valid
        payment period in which time the payment must be processed.
        Note that this is different from the card expiration date.
        <br /><br />
        If your payment is still active, please process it right away.
      </div>;
    case 'CardStatusPartial':
      return <div>
        <strong>This card was processed for less than the authorized payment amount.</strong> As
        long as this card is within its valid payment period you can still process the remaining balance.
        If you have questions, or believe there is a mistake, use the form below to reach Viewpost support,
        or contact your customer directly.
      </div>;
    case 'CardStatusProcessed':
      return <div>
        <strong>The card was processed successfully.</strong> Virtual cards are single-use payments,
        so this card number can no longer be used.
        <br/><br/>
        If you have question about the payment amount, please contact your customer.
        <br/><br/>
        For technical questions regarding card payments, use the form below.
      </div>;
    case 'CardStatusInactive':
      return <div>
        Each virtual card payment has a valid
        payment period in which time the payment must be processed.
        Note that this is different from the card expiration date.
        <br /><br />
        Inactive virtual cards can no longer be processed.
      </div>;
    case 'CardStatusInactivePartial':
      return <div>
        This card was processed for less than the authorized payment amount.
        To avoid payment delays, always process virtual card payments
        for the full amount. If you have questions about the payment amount,
        contact your customer after you have processed the payment.
      </div>;
    case 'CardNumber':
      return <div>
        The 10-digit card number is unique to each payment. It must be preceded by your
        unique 6-digit code, which you must use for all virtual card payments.
        Ensure you enter your card number correctly.
        <br/><br/>
        If needed, <SixDigitLink vccPayment={vccPayment} secureToken={secureToken}>
          get your 6-digit prefix
        </SixDigitLink>.
      </div>;
    case 'CardExpiration':
      return <div>
        The expiration date must be entered correctly when you process a payment.
        It is different from the valid payment period, which tells you how long you
        have to process a payment before the virtual card is cancelled and a paper check is issued
        to replace it.
      </div>;
    case 'CardTotal':
      return <div>
        This is the exact amount that your customer has authorized for payment. In order to avoid
        possible payment delays, always process the total amount shown.
        <br/><br/>
        If a payment is a duplicate or wrong amount, process the payment and contact your customer
        to resolve the issue.
      </div>;
    case 'CardBalance':
      return <div>
        <strong>Only a portion of the authorized payment amount was processed.</strong> You can process the
        remaining balance up until the end of the valid payment period.
        <br/><br/>
        If the authorized payment is different from what you believe it should be, process the payment in full
        and contact your customer to resolve the issue.
      </div>;
    case 'CardBalanceInactive':
      return <div>
        Only a portion of the authorized payment amount was processed. Always process the payment
        for the full amount and then contact your customer to resolve any payment discrepancies.
      </div>;
    case 'CardTimeout':
      return <div>
        Each virtual card payment has a specific date range in which it must be processed. This is
        different from the card expiration date that you enter when processing a payment.
        <br/><br/>
        If you are within the valid payment period, process the payment right away in order to avoid delays.
      </div>;
    case 'CardTimeoutInactive':
      return <div>
        Each virtual card payment has a specific date range in which it must be processed. This is
        different from the card expiration date that you enter when processing a payment.
      </div>;
    case 'CardReroute':
      return <div>
        The balance of a virtual card payment may be reissued as a paper check if the card is not fully
        processed within its valid payment period or if it is cancelled by request.
        <br/><br/>
        If you have questions, please contact your customer for more information.
      </div>;
    case 'CardRefund':
      return <div>
        If a card payment is not processed within its valid payment period and there are no additional
        payment instructions from the customer, the payment balance is cancelled.
        <br/><br/>
        If you have questions, please contact your customer for more information.
      </div>;
    default: return null;
  }
}

const VccPaymentDetails = ({
  paymentData,
  secureToken,
  onSubmit
}) => {
  let vccPayment = paymentData?.vccPaymentDetails;
  let cardTotal = vccPayment?.cardPaymentAmount ?? 0;
  let postedAmount = vccPayment?.postedAmount ?? 0;

  let cardEndDate = vccPayment.cardTimeoutDate;
  if (moment().isAfter(cardEndDate)) {
    // in some cases the card timeout might be skipped...e.g. late credit onto a Closed card
    // so technically the card is open until expiration
    cardEndDate = moment(vccPayment.expiration, 'MM/YYYY').endOf('month').toDate();
  }

  let daysToTimeout = moment(cardEndDate).diff(moment(), 'days');

  // scenario main factors
  let isBlocked = !!vccPayment.blockDateTime;
  if (moment().isAfter(cardEndDate)) {
    // the card should be considered effectively blocked if it has expired
    isBlocked = true;
  }
  let expiresSoon = daysToTimeout < 3;
  let isPartiallyPaid = postedAmount > 0 && postedAmount < cardTotal;
  let isFullyPaid = postedAmount === cardTotal;

  let balance = cardTotal - postedAmount;
  if (isBlocked) {
    // this is an incomplete "balance", as it would not really be correct for multiple reroute scenarios
    // Per Katie, this is mainly being used for happier cases so we'll keep this logic simple for now
    balance = !!paymentData?.currentPaymentMethod
      ? Math.abs(paymentData?.currentPaymentAmount)
      : null;
  }

  let hasReroute = paymentData?.currentPaymentMethod === 'ViewpostPaperCheck'
    || paymentData?.currentPaymentMethod === 'ViewpostDigitalCheck';
  let isRefund = paymentData?.currentPaymentIsRefund;

  let rerouteFinalMessage = (
    <div style={{textAlign: 'left'}}>
      <HeaderText style={{ paddingBottom: 25 }}>
        Contact Your Customer for Further Assistance
      </HeaderText>
      {isRefund
        ? (
          <div style={{ paddingBottom: 15, lineHeight: '1.5' }}>
            If you have questions regarding this payment, please contact your customer directly.
          </div>
        )
        : (
          <div style = {{ paddingBottom: 15, lineHeight: '1.5' }}>
            {paymentData?.currentPaymentMethod === 'ViewpostPaperCheck'
              ? <span>
                This payment is being mailed to the address your customer has on file.&nbsp;
              </span>
              : null}
            {paymentData?.currentPaymentMethod === 'ViewpostDigitalCheck'
              ? <span>This payment has been sent electronically.&nbsp;</span>
              : null}
            If you have questions regarding the payment amount, delivery address, or other details,
            please contact your customer directly.
          </div>
        )}
    </div>
  );

  let title = null;
  let subtitle = null;
  let cardStatus = null;
  let finalContent = null;
  let guides = {};

  let isAssistedPay = !!paymentData.vccPaymentDetails?.assistedPayTeam;

  // SCENARIOS
  // wade through all these different cases laid out by the design....
  if (!isBlocked && isFullyPaid) {
    // Processed In Full
    title = 'This card has been processsed.';
    subtitle = 'If you believe there is a mistake with this transaction, use the form below to contact us.';
    cardStatus = 'Processed';
    finalContent = <div>
      <HeaderText variant="page-header" style={{paddingBottom: '30px'}}>
        <strong>Need Help?</strong>
      </HeaderText>
      <HelpForm onSubmit={onSubmit} />
    </div>;
    guides = {
      cardStatus: {
        color: 'green',
        message: getTooltipMessage('CardStatusProcessed'),
        arrow: 'right'
      }
    };
  } else if (!isBlocked && !isPartiallyPaid && !expiresSoon) {
    // Not Processed
    title = 'Let\'s get you paid.';
    subtitle = 'Click the numbered circles below to resolve the most common virtual card payment issues.';
    cardStatus = 'Active';
    finalContent = <CommonProblems cardTimeoutDate={cardEndDate} onSubmit={onSubmit} isAssistedPay={isAssistedPay} />;
    guides = {
      cardStatus: {
        message: getTooltipMessage('CardStatus'),
        number: 1
      },
      cardNumber: {
        message: getTooltipMessage('CardNumber', vccPayment, secureToken),
        number: 2
      },
      cardExpiration: {
        message: getTooltipMessage('CardExpiration'),
        number: 3
      },
      cardTotal: {
        message: getTooltipMessage('CardTotal'),
        number: 4
      },
      cardTimeout: {
        message: getTooltipMessage('CardTimeout'),
        number: 5
      }
    };
  } else if (!isBlocked && isPartiallyPaid && !expiresSoon) {
    // Partially Processed
    title = 'This card has been partially processed.';
    subtitle = 'Please process the remaining balance and contact us if you need assistance.';
    cardStatus = 'Partially Processed';
    finalContent = <CommonProblems cardTimeoutDate={cardEndDate} onSubmit={onSubmit} isAssistedPay={isAssistedPay} />;
    guides = {
      cardStatus: {
        color: 'yellow',
        message: getTooltipMessage('CardStatusPartial'),
        number: 1
      },
      cardNumber: {
        message: getTooltipMessage('CardNumber', vccPayment, secureToken),
        number: 2
      },
      cardExpiration: {
        message: getTooltipMessage('CardExpiration'),
        number: 3
      },
      cardTotal: {
        message: getTooltipMessage('CardTotal'),
        number: 4
      },
      cardBalance: {
        color: 'yellow',
        message: getTooltipMessage('CardBalance'),
        number: 5
      },
      cardTimeout: {
        color: 'yellow',
        message: getTooltipMessage('CardTimeout'),
        number: 6
      }
    };
  } else if (!isBlocked && !isPartiallyPaid && expiresSoon) {
    // Expires Soon Not Processed
    title = 'This card expires soon.';
    subtitle = 'Process this payment immediately. Click the numbers below to resolve most issues.';
    cardStatus = 'Active';
    finalContent = <CommonProblems cardTimeoutDate={cardEndDate} onSubmit={onSubmit} isAssistedPay={isAssistedPay} />;
    guides = {
      cardStatus: {
        color: 'yellow',
        message: getTooltipMessage('CardStatus'),
        number: 1
      },
      cardNumber: {
        message: getTooltipMessage('CardNumber', vccPayment, secureToken),
        number: 2
      },
      cardExpiration: {
        message: getTooltipMessage('CardExpiration'),
        number: 3
      },
      cardTotal: {
        message: getTooltipMessage('CardTotal'),
        number: 4
      },
      cardTimeout: {
        color: 'yellow',
        message: getTooltipMessage('CardTimeout'),
        number: 5
      }
    };
  } else if (!isBlocked && isPartiallyPaid && expiresSoon) {
    // Expires Soon Partially Processed
    title = 'This card has been partially processed.';
    subtitle = 'Please process the remaining balance and contact us if you need assistance.';
    cardStatus = 'Partially Processed';
    finalContent = <CommonProblems cardTimeoutDate={cardEndDate} onSubmit={onSubmit} isAssistedPay={isAssistedPay} />;
    guides = {
      cardStatus: {
        color: 'yellow',
        message: getTooltipMessage('CardStatusPartial'),
        number: 1
      },
      cardNumber: {
        message: getTooltipMessage('CardNumber', vccPayment, secureToken),
        number: 2
      },
      cardExpiration: {
        message: getTooltipMessage('CardExpiration'),
        number: 3
      },
      cardTotal: {
        message: getTooltipMessage('CardTotal'),
        number: 4
      },
      cardBalance: {
        color: 'yellow',
        message: getTooltipMessage('CardBalance'),
        number: 5
      },
      cardTimeout: {
        color: 'yellow',
        message: getTooltipMessage('CardTimeout'),
        number: 6
      }
    };
  } else if (isBlocked && !isPartiallyPaid) {
    // Not Processed, Full Reroute/Refund
    title = 'This card is no longer active.';
    subtitle = null;
    if (isRefund) {
      subtitle = 'Contact your customer for more information.';
    } else if (paymentData?.currentPaymentMethod === 'ViewpostDigitalCheck') {
      subtitle = `An electronic payment is being issued to replace this payment
        and typically arrives within 10 business days.`;
    } else if (paymentData?.currentPaymentMethod === 'ViewpostPaperCheck') {
      subtitle = 'A paper check is being issued to replace this payment and typically arrives within 10 business days.';
    }
    cardStatus = 'Inactive';
    finalContent = rerouteFinalMessage;
    guides = {
      cardStatus: {
        color: 'red',
        message: getTooltipMessage('CardStatusInactive'),
        arrow: 'right'
      },
      cardTimeout: {
        color: 'red',
        message: getTooltipMessage('CardTimeoutInactive'),
        arrow: 'left'
      },
      cardReroute: {
        color: isRefund ? 'red' : 'green',
        title: isRefund ? 'Payment Status: Cancelled' : 'Payment Status: Reissued',
        message: isRefund ? getTooltipMessage('CardRefund') : getTooltipMessage('CardReroute'),
        arrow: 'right'
      }
    };
  } else if (isBlocked && isPartiallyPaid) {
    // Partial Reroute/Refund
    title = 'This card is no longer active.';
    if (isRefund) {
      subtitle = 'Contact your customer for more information.';
    } else if (paymentData?.currentPaymentMethod === 'ViewpostDigitalCheck') {
      subtitle = `An electronic payment is being issued for the balance due
        and typically arrives within 10 business days.`;
    } else if (paymentData?.currentPaymentMethod === 'ViewpostPaperCheck') {
      subtitle = 'A paper check is being issued for the balance due and typically arrives within 10 business days.';
    }
    cardStatus = isRefund
      ? 'Inactive'
      : 'Inactive: Partially Processed';
    finalContent = rerouteFinalMessage;
    guides = {
      cardStatus: {
        color: 'red',
        message: getTooltipMessage('CardStatusInactivePartial'),
        arrow: 'right'
      },
      cardBalance: {
        color: 'red',
        message: getTooltipMessage('CardBalance'),
        arrow: 'left'
      },
      cardTimeout: {
        color: 'red',
        message: getTooltipMessage('CardTimeoutInactive'),
        arrow: 'left'
      },
      cardReroute: {
        color: isRefund ? 'red' : 'green',
        title: isRefund ? 'Payment Status: Cancelled' : 'Payment Status: Reissued',
        message: isRefund ? getTooltipMessage('CardRefund') : getTooltipMessage('CardReroute'),
        arrow: 'right'
      }
    };
  }

  return (
    <>
      <div style={{ paddingBottom: '30px' }}>
        <HeaderText variant="page-header" style={{ paddingBottom: 11 }}>
          <strong>{title}</strong>
        </HeaderText>
        <div style={{fontSize: '16px'}}>
          {subtitle}
        </div>
      </div>
      <div style={{
        border: '1px solid #ddd',
        borderTopLeftRadius: '12px',
        borderTopRightRadius: '12px',
        width: '660px',
        textAlign: 'left'
      }}>
        <Row style={{borderBottom: '1px solid #ddd'}}>
          <Column size={7}>
            {guides.cardStatus
              ? <Guidepoint {...guides.cardStatus} left="-30px" top="30px" title="Card Status" />
              : null}
            <div style={{fontSize: '22px'}}>
              <Field label="Card Status" value={<>
                {cardStatus}&nbsp;
                {!isBlocked && daysToTimeout > 0
                  ? <div style={{color: '#999', fontWeight: 'normal'}}>
                    ({expiresSoon ? `only ${daysToTimeout}` : daysToTimeout} days to process)
                  </div>
                  : null}
              </>}/>
            </div>
          </Column>
          <Column size={2} style={{ textAlign: 'center', fontSize: '22px', color: isBlocked ? '#999' : null }}>
            <Field label="Attempts" value={vccPayment.attempts?.length ?? 0} />
          </Column>
          <Column size={3} style={{ textAlign: 'right', fontSize: '22px', color: isBlocked ? '#999' : null }}>
            <Field label="Amount Posted" value={
              <Currency value={postedAmount} />
            } />
          </Column>
        </Row>

        {vccPayment.attempts?.map((attempt, index) => {
          return (
            <Row style={{ borderBottom: '1px solid #ddd', fontSize: '16px', color: isBlocked ? '#999' : null }}>
              <Column size={2}>
                <Field label={`Attempt ${index + 1}:`} />
              </Column>
              <Column size={10} style={{ textAlign: 'right' }}>
                <Field value={
                  <div>
                    <FormattedDate value={attempt.date} includeTime={true}/>
                    <div style={{display: 'inline-block', margin: '0px 10px'}}>|</div>
                    {attempt.description}
                    {attempt.declineReason
                      ? (
                        <Tooltip overlay={<div>
                          {attempt.declineReason}<br/>{attempt.correctiveAction}
                        </div>}>
                          <Icon type={IconTypes.Info} />
                        </Tooltip>
                      )
                      : null}
                    <div style={{ display: 'inline-block', margin: '0px 10px' }}>|</div>
                    <Currency value={attempt.amount}/>
                  </div>
                } />
              </Column>
            </Row>
          );
        })}

        <Row style={{ borderBottom: '1px solid #ddd', fontSize: '16px', color: isBlocked ? '#999' : null }}>
          <Column size={4}>
            <Field label="Card Name" value={vccPayment.billingName} />
          </Column>
          <Column size={8}>
            <Field label="Billing Address" value={
              <Address {...vccPayment.billingAddress} stateProvince={vccPayment.billingAddress?.state}
                linesProp={LinesProp.LineNumber} variant="singleLine"/>
            } />
          </Column>
        </Row>
        <Row style={{ borderBottom: '1px solid #ddd', fontSize: '18px', color: isBlocked ? '#999' : null }}>
          <Column size={8}>
            {guides.cardNumber
              ? <Guidepoint {...guides.cardNumber} title="Virtual Card Number" left="-30px" top="30px" />
              : null}
            <Field label={<>{vccPayment.cardType} #:</>} value={<>
              {vccPayment.maskedCardNumber}
              <div className="subtext" style={{ fontSize: '14px', fontWeight: 'normal' }}>
                {isBlocked
                  ? (
                    <div style={{ margin: '10px 0px' }}>
                      This card number was not used within the valid payment period and can no longer be processed.
                    </div>
                  )
                  : (
                    <>
                      <div style={{ margin: '10px 0px'}}>
                        Replace "XXXXXX" with your 6-digit {vccPayment.cardType} prefix, which was previously
                        provided to you.
                      </div>
                      <SixDigitLink vccPayment={vccPayment} secureToken={secureToken}>
                        Need your 6-digit prefix?
                      </SixDigitLink>
                    </>
                  )}
              </div>
            </>} />
          </Column>
          <Column size={4} style={{ borderLeft: '1px solid #ddd', color: isBlocked ? '#999' : null }}>
            {guides.cardExpiration
              ? <Guidepoint {...guides.cardExpiration} title="Expiration Date" right="-30px" top="30px" />
              : null}
            <div style={{minHeight: '80px'}}>
              <Field label="Exp:" value={vccPayment.expiration} />
            </div>
            <div style={{ borderTop: '1px solid #ddd', minHeight: '80px' }}>
              <Field label="Security:" value={vccPayment.securityCode} />
            </div>
          </Column>
        </Row>
        <Row style={{ borderBottom: '1px solid #ddd', fontSize: '30px' }}>
          {guides.cardTotal
            ? <Guidepoint {...guides.cardTotal} title="Authorized Payment Amount" right="-30px" top="15px" />
            : null}
          <Column size={4}>
            <Field label={<>Authorized<br/>Payment:</>} />
          </Column>
          <Column size={8} style={{ textAlign: 'right' }}>
            <Field value={<Currency value={cardTotal} />} />
          </Column>
        </Row>

        {balance && isPartiallyPaid
          ? (
            <Row style={{ borderBottom: '1px solid #ddd', fontSize: '30px' }}>
              {guides.cardBalance
                ? <Guidepoint {...guides.cardBalance} right="-30px" top="15px" title="Balance Remaining" />
                : null}
              <Column size={4}>
                <Field label={<>Balance Remaining<br />to be Processed:</>} />
              </Column>
              <Column size={8} style={{ textAlign: 'right' }}>
                <Field value={<Currency value={balance} />} />
              </Column>
            </Row>
          )
          : null}

        <Row style={{ borderBottom: '1px solid #ddd', fontSize: '16px' }}>
          <Column size={4}>
            <Field label="From:" />
          </Column>
          <Column size={8} style={{ textAlign: 'right' }}>
            <Field value={paymentData.payorName} />
          </Column>
        </Row>
        <Row style={{ borderBottom: '1px solid #ddd', fontSize: '16px' }}>
          <Column size={4}>
            <Field label="To:" />
          </Column>
          <Column size={8} style={{textAlign: 'right'}}>
            <Field value={paymentData.payeeName} />
          </Column>
        </Row>
        <Row style={{ fontSize: '16px', color: isBlocked ? '#999' : null }}>
          {guides.cardTimeout
            ? <Guidepoint {...guides.cardTimeout} right="-30px" top="15px" title="Valid Payment Period" />
            : null}
          <Column size={4}>
            <Field label="Valid:" />
          </Column>
          <Column size={8} style={{ textAlign: 'right' }}>
            <Field value={<>
              <FormattedDate value={vccPayment.cardSentTimestamp} />
              &nbsp;–&nbsp;
              <FormattedDate value={cardEndDate} />
            </>} />
          </Column>
        </Row>

        {hasReroute
          ? (
            <Row style={{ borderTop: '1px solid #ddd', fontSize: '22px' }}>
              <Column size={6}>
                {guides.cardReroute
                  ? <Guidepoint {...guides.cardReroute} left="-30px" top="30px" />
                  : null}
                {isRefund
                  ? (
                    <Field label="Payment Status" value={<div>
                      {isPartiallyPaid ? 'Balance' : 'Payment'} Cancelled
                    </div>} />
                  )
                  : (
                    <Field label="Payment Status" value={<div>
                      Balance Issued as&nbsp;
                      {paymentData?.currentPaymentMethod === 'ViewpostDigitalCheck'
                        ? 'Electronic Payment'
                        : 'a Check'}
                    </div>} />
                  )}
              </Column>
              {isRefund
                ? (
                  <>
                    <Column size={6} style={{ textAlign: 'right' }}>
                      {!isPartiallyPaid
                        ? (
                          <div style={{color: '#999'}}>
                            <Field label="Reason" value={paymentData?.rerouteReason} />
                          </div>
                        )
                        : (
                          <Field label="Amount" value={<Currency value={balance} />} />
                        )}
                    </Column>
                  </>
                )
                : (
                  <>
                    <Column size={3}>
                      <Field label="Expected Delivery" value="7-10 days" />
                    </Column>
                    <Column size={3} style={{ textAlign: 'right' }}>
                      <Field label="Amount" value={<Currency value={paymentData?.currentPaymentAmount} />} />
                    </Column>
                  </>
                )}
            </Row>
          )
          : null}
      </div>

      <div style={{ textAlign: 'center', marginTop: '50px'}}>
        {guides.final
          ? <Guidepoint {...guides.final} />
          : null}
      </div>
      {finalContent}
    </>
  );
};

export default VccPaymentDetails;