/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, { Children, useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import isCapabilityEnabled from 'actions/app/isCapabilityEnabled';
import { getIdentity } from 'actions/common';

import Button from 'components/Button';
import HeaderText, { HeaderTextVariants } from 'components/HeaderText';
import BlueHeaderOnWhiteLayout from 'layouts/BlueHeaderOnWhite';
import { connectSecureAccountSingleStep } from 'containers/Settings/SecureAccountModalWorkflowStep';
import createSecureAccountToggle from 'containers/Settings/SecureAccountModalWorkflowStep/Toggle';
import createSecurityQuestionsForm from 'containers/Settings/SecurityQuestionsForm';
import createTwoStepSetupForm from 'containers/Settings/TwoStepVerification';
import Messages from './index.messages';
import connect from './index.connect';

const SecureAccountWrapper = ({
  isTwoFactor,
  noToggle,
  toggleMethod,
  children
}) => {
  const toggleLink = (
    <Button anchor={true} onClick={toggleMethod}>
      {isTwoFactor
        ? <Messages.SwitchToSecurityQuestions.Message />
        : <Messages.SwitchToTwoFactorLink.Message />}
    </Button>
  );

  const InstructionMessage = isTwoFactor
    ? Messages.TwoFactorInstructions.Message
    : Messages.SecurityQuestionsInstructions.Message;

  let body = <InstructionMessage toggleLink={toggleLink} />;

  if (noToggle) {
    body = <Messages.TwoFactorInstructionsWithoutToggle.Message />;
  }

  return (
    <div>
      <div style={{ paddingBottom: 24 }}>
        <HeaderText>
          <Messages.FormHeader.Message />
        </HeaderText>
        <HeaderText variant={HeaderTextVariants.Small} style={{ paddingTop: '14px', paddingBottom: '20px' }}>
          {body}
        </HeaderText>
      </div>
      {Children.only(children)}
    </div>
  );
};

const SecureAccountFooter = ({
  isLoading,
  isVerifying,
  isSubmitButtonDisabled,
  onSubmit,
  children
}) => (
  <div>
    {Children.only(children)}
    {!isSubmitButtonDisabled
      ? (
        <div style={{ textAlign: 'center', paddingTop: 24 }}>
          <Button
            onClick={onSubmit}
            isDisabled={isVerifying || isLoading || isSubmitButtonDisabled}
            isProcessing={isVerifying || isLoading}
          >
            Submit
          </Button>
        </div>
      )
      : null}
  </div>
);

const TwoStepForm = createTwoStepSetupForm(({ isSendCodeDisabled, sendVerifyCode, ...props }) => (
  <SecureAccountFooter
    {...props}
    onSubmit={sendVerifyCode}
    isSubmitButtonDisabled={isSendCodeDisabled}
  />
));

const SingleStepSecureAccount = createSecureAccountToggle({
  ParentWrappingComponent: SecureAccountWrapper,
  TwoStepForm,
  SecurityQuestionsForm: createSecurityQuestionsForm(({ onSubmitQuestions, ...props }) => (
    <SecureAccountFooter {...props} onSubmit={onSubmitQuestions} />
  ))
});

const ConnectedSingleStepSecureAccount = connectSecureAccountSingleStep(connect(({
  loadIdentity,
  onDismissTwoFactor,
  showOnlyTwoFactor,
  ...props
}) => {
  const onComplete = () => loadIdentity();

  if (showOnlyTwoFactor) {
    return (
      <SecureAccountWrapper
        isTwoFactor={true}
        noToggle={true}
        onDismiss={onDismissTwoFactor}
      >
        <TwoStepForm {...props} onComplete={onComplete} />
      </SecureAccountWrapper>
    );
  }

  return <SingleStepSecureAccount {...props} onComplete={onComplete} />;
}));

const SecureAccountWorkflowStep = ({
  active,
  updateState
}) => {
  // Hooks
  const [ isAccountSecure, needsAccountSecured ] = useSelector(
    (state) => {
      const {
        isAccountSecure: isAccountSecureState,
        isTwoFactorSecured,
        twoFactorSecuredRequirementDisabled
      } = getIdentity(state);

      if (isCapabilityEnabled(state, 'TwoFactorRequired')) {
        return [ isTwoFactorSecured, !twoFactorSecuredRequirementDisabled ];
      }

      return [ isAccountSecureState, true ];
    },
    shallowEqual
  );

  const [ wasAccountSecured ] = useState(isAccountSecure);

  useEffect(
    () => {
      updateState('loaded');
    },
    []
  );

  useEffect(
    () => {
      if (wasAccountSecured || !needsAccountSecured) {
        updateState('skipped');
      } else if (isAccountSecure) {
        updateState('completed');
      }
    },
    [ isAccountSecure, needsAccountSecured ]
  );

  // Render
  if (!active) return <></>;

  return (
    <BlueHeaderOnWhiteLayout
      backgroundColor="#f7f7f7"
      contentWidth={650}
      hideLoginLink={true}
    >
      <div className="viewstrap">
        <div style={{ paddingTop: '56px' }}>
          <ConnectedSingleStepSecureAccount />
        </div>
      </div>
    </BlueHeaderOnWhiteLayout>
  );
};

export default SecureAccountWorkflowStep;
