/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React from 'react';
import MeatballCaret from './MeatballCaret';
import './MeatballToggle.scss';

const MeatballToggle = ({
  isOpen,
  onClick,
  children
}) => (
  <span>
    <span className="dropdown-meatball-toggle-component"
      onClick={onClick}>
      <MeatballCaret isOpen={isOpen} />
    </span>
    {children}
  </span>
);

export default MeatballToggle;
