/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React from 'react';
import LabelToggle from '../LabelDropdownToggle/LabelToggle';
import generateDropdown from '../generateDropdown';

export const OldListFilterToggle = (props) => {
  let { isMobile } = props;
  return (
    <LabelToggle {...props} labelStyle={{paddingLeft: isMobile ? 12 : null}} />
  );
};

export default generateDropdown(OldListFilterToggle, { passCaretToMobileToggle: true });
