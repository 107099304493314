/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React from 'react';
import { Route, Redirect, Switch } from 'react-router';
import { wrapImport } from 'decorators/ModuleLoader';
import useSessionInfo from 'hooks/identity/useSessionInfo';
import {
  ConditionalInAppNotFound,
  flattenHashLocation,
  getRouteParam,
  InAppNotFoundView
} from '../utils';

const PaymentsSent = wrapImport(() => import(/* webpackChunkName: "views" */ 'views/Payments/Sent'));
const PaymentsSentDetails = wrapImport(() => import(/* webpackChunkName: "views" */ 'views/Payments/SentDetails'));
const PaymentsReceived = wrapImport(() => import(/* webpackChunkName: "views" */ 'views/Payments/Received'));
const PaymentsReceivedDetails = wrapImport(
  () => import(/* webpackChunkName: "views" */ 'views/Payments/ReceivedDetails')
);
const PaymentsSend = wrapImport(() => import(/* webpackChunkName: "views" */ 'views/Payments/Send'));
const PaymentsAutoPay = wrapImport(() => import(/* webpackChunkName: "views" */ 'views/Payments/AutoPay'));
const PaymentsApprovals = wrapImport(() => import(/* webpackChunkName: "views" */ 'views/Payments/ApprovalQueue'));
const PaymentsQBOnline = wrapImport(() => import(/* webpackChunkName: "views" */ 'views/Payments/Qbonline'));

export const CheckbookLegacyRedirects = ({ match }) => (
  <Switch location={flattenHashLocation(location)}>
    <Redirect path={match.url} exact={true} to="/payments/send" />
    <Redirect path="*" to="/payments/send" />
  </Switch>
);

const LegacyPaymentRoutes = ({ match }) => (
  <Switch>
    <Redirect path={match.url} exact={true} to="/payments" />
    <Redirect path={`${match.url}/sent`} exact={true} to="/payments/sent" />
    <Redirect path={`${match.url}/received`} exact={true} to="/payments/received" />
    <Redirect path={`${match.url}/sent/:id`} exact={true} to="/payments/details/sent/:id" />
    <Redirect path={`${match.url}/received/:id`} exact={true} to="/payments/details/received/:id" />
    <Redirect path="*" to="/payments" />
  </Switch>
);

export const BusinessPaymentsRoutes = ({ location, match }) => {
  const { isPortalExperience } = useSessionInfo();

  return (
    <Switch location={flattenHashLocation(location)}>
      {/* Redirects */}
      <Route path={match.url} exact={true} render={() => <Redirect to={`${match.url}/sent`} />} />
      <Route path={`${match.url}/old`} component={LegacyPaymentRoutes} />
      {/* Routes */}
      <Route path={`${match.url}/sent`} exact={true} component={PaymentsSent} />;
      <Route path={`${match.url}/received`} exact={true} component={PaymentsReceived} />
      <Route path={`${match.url}/details/sent/:id`}
        exact={true}
        component={props => (
          <PaymentsSentDetails
            settlementId={getRouteParam(props, 'id')}
            {...props}
          />
        )}
      />
      <Route path={`${match.url}/details/received/:id`}
        exact={true}
        component={props => (
          <PaymentsReceivedDetails
            isReceived={true}
            settlementId={getRouteParam(props, 'id')}
            {...props}
          />
        )}
      />
      <Route path={`${match.url}/send`} exact={true} component={PaymentsSend} />
      <Route path={`${match.url}/auto`} exact={true} component={PaymentsAutoPay} />
      <Route path={`${match.url}/approvals`} exact={true} component={PaymentsApprovals} />
      {/* Corporate Routes */}
      {ConditionalInAppNotFound(isPortalExperience)}
      <Route path={`${match.url}/qbonline`} exact={true} component={PaymentsQBOnline} />
      {InAppNotFoundView}
    </Switch>
  );
};

export const PersonalPortalPaymentsRoutes = ({ match }) => (
  <Switch>
    <Route path={`${match.url}/received`} exact={true} component={PaymentsReceived} />
    <Route path={`${match.url}/details/received/:id`}
      exact={true}
      component={props => (
        <PaymentsReceivedDetails
          isReceived={true}
          settlementId={getRouteParam(props, 'id')}
          {...props}
        />
      )}
    />
  </Switch>
);
