/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */
/* eslint max-len:0 */

import { toMessages } from 'config/messages';

export default toMessages('Format.Currency', {
  PositivePattern: {
    description: 'Format used for positive currency values',
    defaultMessage: '{value}'
  },
  NegativePattern: {
    description: 'Format used for negative currency values',
    defaultMessage: '({value})'
  },
  Millions: '{value}M',
  Cents: '{value}¢'
});
