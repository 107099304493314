/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { isObject } from 'lodash';

import getCompanyFeeSettings from 'actions/common/getCompanyFeeSettings';
import getIdentity from 'actions/common/getIdentity';

import { determineIdentityActions } from 'api/session/getIdentity';

import { Identity } from 'schemas/network/session';
import { createEntity } from 'schemas/state';

const patchIdentity = (identityPatch, state) => {
  const identity = { ...getIdentity(state) };
  Object.keys(identity).forEach((key) => {
    if (!identityPatch[key] || !isObject(identity[key])) return;
    identity[key] = {
      ...identity[key],
      ...identityPatch[key]
    };
  });

  identity.availableActions = determineIdentityActions(
    identity,
    getCompanyFeeSettings(state)
  );

  return createEntity(
    'current', // only 1 identity globally
    Identity.meta.name,
    identity
  );
};

export default patchIdentity;