/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { createEntity, createEntityReference } from 'schemas/state';
import {
  DiscountStrategy,
  DiscountStrategyVendor,
  AutoAcceptDiscountSettings
} from 'schemas/settings/vpx';
import dateMapper from 'api/common/mappers/dateMapper';
import pagedResponseMapper from 'api/common/mappers/pagedResponseMapper';

export function discountStrategiesResponseMapper(apiResponse) {
  let entities = [];
  let references = [];

  if (apiResponse && apiResponse.entries) {
    apiResponse.entries.forEach((strategy) => {
      entities.push(createEntity(
        strategy.discountStrategyId,
        DiscountStrategy.meta.name,
        {
          id: strategy.discountStrategyId,
          name: strategy.name,
          isDefault: strategy.isDefault,
          blackoutWindowDays: strategy.blackoutWindowDays.toString(),
          minimumReturnMode: strategy.minimumReturnMode,
          targetReturnPercent: strategy.targetReturnPercent,
          targetDiscountPercent: strategy.targetDiscountPercent,
          actualReturnPercent: strategy.actualReturnPercent,
          actualDiscountPercent: strategy.actualDiscountPercent,
          assignedVendorCount: strategy.assignedVendorCount,
          totalSavedDollars: strategy.totalSavedDollars,
          discountStrategyType: strategy.discountStrategyType
        }
      ));
      references.push(createEntityReference(
        strategy.discountStrategyId,
        DiscountStrategy.meta.name
      ));
    });
  }

  return {
    entities,
    references
  };
}

export function getVendorListByDiscountStrategyResponseMapper(apiResponse, state, apiParams) {
  let entities = [];
  let references = [];

  if (apiResponse && apiResponse.items) {
    apiResponse.items.forEach((vendor) => {
      entities.push(createEntity(
        vendor.customerAddressBookForVendorId,
        DiscountStrategyVendor.meta.name,
        {
          id: vendor.customerAddressBookForVendorId,
          name: vendor.vendorName,
          assignedDiscountStrategyId: vendor.assignedDiscountStrategyId,
          assignedDiscountStrategyName: vendor.assignedDiscountStrategyName,
          lastOfferReturnPercent: vendor.lastOfferReturnPercent,
          lastOfferDate: dateMapper(vendor.lastOfferDate),
          totalInvoicesReceivedCount: vendor.totalInvoicesReceivedCount,
          totalSavingsDollars: vendor.totalSavingsDollars,
          averageInvoiceAmount: vendor.averageInvoiceAmount
        }
      ));
      references.push(createEntityReference(
        vendor.customerAddressBookForVendorId,
        DiscountStrategyVendor.meta.name
      ));
    });
  }

  let mappingResult = pagedResponseMapper(apiResponse, apiParams);
  mappingResult.entities = entities;
  mappingResult.references = references;
  return mappingResult;
}

export function getAutoAcceptDiscountSettingsResponseMapper(apiResponse) {
  let entities = [];
  let references = [];

  const id = 'AutoAcceptDiscount';

  if (apiResponse) {
    entities.push(createEntity(
      id,
      AutoAcceptDiscountSettings.meta.name,
      {
        isEnabled: apiResponse.isEnabled,
        minReturn: apiResponse.minReturn,
        dailyLimit: apiResponse.dailyLimit,
        paymentAccountId: apiResponse.paymentAccountId
      }
    ));
    references.push(createEntityReference(
      id,
      AutoAcceptDiscountSettings.meta.name
    ));
  }

  return {
    entities,
    references
  };
}
