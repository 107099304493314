/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import t from 'tcomb-validation';
import { clone } from 'lodash';
import { addSchema } from './index';

// reference to a entity in the state
// can be used to reference the same entity from multiple parts of the state
// (instead of duplictating those entities in the state)
// can be named for better contextual distinction
// (e.g. an AddressBook entity might be named 'customer' or 'vendor' in certain contexts)
const EntityReference = addSchema(t.struct({
  type: t.String,
  id: t.String,
  name: t.maybe(t.String) // contextual name for the entity
}, 'EntityReference'));

// represents a specific client-side domain model in the state (e.g. the addressbook with id 1)
const Entity = addSchema(t.struct({
  type: t.String,
  id: t.String,
  value: t.Object,
  updated: t.maybe(t.struct({ reason: t.String })),
  merge: t.maybe(t.Bool),
  createReference: t.maybe(t.Function),
  // The scope of the entity, currently only 'global' is used for some entities
  // that persist between users
  scope: t.maybe(t.String)
}, 'Entity'));

const CreatableEntity = addSchema(t.struct({
  type: t.String,
  id: t.String,
  value: t.maybe(t.Object)
}, 'Entity'));

// Holds Entity's in state. Maps ids to indiviual  models
/* e.g.
    {
        '<id 1>': {
            // entity for address book for id 1
        },
        '<id 2>': {
            // entity for address book for id 2
        }
        // ...
    }
*/
const EntitySet = addSchema(t.dict(t.String, Entity, 'EntitySet'));

// Maps  model types to the table holding all the models of that type
/* e.g.
    {
        'addressBooks': <address book table>,
        'companyProfiles': <company profile table>,
        'invoices': <invoice table>,
        // ...
    }
*/
const EntitySets = addSchema(t.dict(t.String, EntitySet, 'EntitySets'));


// view-specific state container. maps view names/ids to a piece of the state specific to that view
/* e.g.
    {
        'network/viewCompany' : {
            // view-specific state, e.g.
            customer: {type: 'addressBooks', id: 1}, // EntityReference
            vendor: {type: 'addressBooks', id: 2}, // EntityReference
            profile: {type: 'companyProfiles', id: 1}, // EntityReference
            activeSelection: 'vendor' // other view-specific state
        }
    }
*/
// const ViewStates = t.dict(t.String, t.Object, 'ViewStateMap');

const State = t.struct({
  // api status information?
  // apiStatus: t.Object,

  //  models
  entities: EntitySets

  // view-specific states?
  // OR domains?
  // network: ...
  // invoicing: ...
});

const createEntityReference = (id, type, name) => {
  return {
    id,
    type,
    name
  };
};

export const createReferenceFromEntity = entity => createEntityReference(entity.id, entity.type, entity.name);

const createEntity = (
  id,
  type,
  value,
  name,
  {
    scope,
    merge
  } = {}
) => ({
  id,
  type,
  value,
  merge,
  createReference: () => createEntityReference(id, type, name),
  scope
});

const getEntity = (
  state,
  entityReference,
  { valueOnly } = {}
) => {
  if (!state) throw new Error('Did not pass state to getEntity!');
  if (!entityReference) return null;

  if (state.entities?.[entityReference.type]?.[entityReference.id]) {
    const entity = state.entities[entityReference.type][entityReference.id];

    // give a cloned object (we don't want the consumer to be able to update our object)
    if (valueOnly == null || !!valueOnly) {
      return clone(entity.value, true);
    }

    return clone(entity, true);
  }

  return null;
};

export const getAllEntities = (state, type) => {
  if (state && type && state.entities && state.entities[type]) {
    // give a cloned object (we don't want the consumer to be able to update our object)
    return Object.keys(state.entities[type])
      .map(key => clone(state.entities[type][key].value, true));
  }

  return [];
};

export const getEntityUpdated = (state, entityReference) => {
  if (state && entityReference
    && state.entities
    && state.entities[entityReference.type]
    && state.entities[entityReference.type][entityReference.id]) {
    return state.entities[entityReference.type][entityReference.id].updated;
  }

  return null;
};

export const areEntitiesUpdated = (state, entityReferences, withReason) => {
  return entityReferences.some((ref) => {
    const updated = getEntityUpdated(state, ref);
    return withReason && (updated || {}).reason
      ? updated.reason === withReason
      : !!updated;
  });
};

const getEntityList = (state, entityReferences) => {
  if (!entityReferences) {
    return [];
  }

  return entityReferences.map((entityReference) => {
    return getEntity(state, entityReference);
  }).filter(entity => entity !== null);
};

export {
  Entity,
  CreatableEntity,
  EntityReference,
  State,
  getEntity,
  getEntityList,
  createEntity,
  createEntityReference
};
