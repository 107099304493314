/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, { Component } from 'react';
import TextBox from 'components/Form/Inputs/TextBox';

export default class TaxIdTextBox extends Component {
  constructor(props) {
    super(props);

    this.hyphenIndexes = [];
    this.onChange = this.onChange.bind(this);
  }

  onChange(value) {
    if (this.props.onChange) {
      let hyphenIndexes = [];
      if (value) {
        for (let i = 0; i < value.length; i++) {
          if (value[i] === '-') {
            hyphenIndexes.push(i);
          }
        }
      }
      this.hyphenIndexes = hyphenIndexes;

      const strippedValue = value && value.length ? value.replace(/-/g, '') : value;
      this.props.onChange(strippedValue);
    }
  }

  render() {
    const { value } = this.props;

    let formattedValue = value;
    for (let i = 0; i < this.hyphenIndexes.length; i++) {
      const index = this.hyphenIndexes[i];
      const head = formattedValue.substring(0, index);
      const tail = formattedValue.substring(index, formattedValue.length);

      formattedValue = `${head}-${tail}`;
    }

    return (
      <TextBox {...this.props}
        value={formattedValue}
        onChange={this.onChange} />
    );
  }
}
