/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { PaymentMethodType } from 'config/constants';

export const getPaymentMethodString = ({ methodValue = '', maskedCardNumber = null }) => {
  let paymentMethod;

  switch (methodValue) {
    case 1:
    case 'ViewpostPaperCheck':
      paymentMethod = 'Viewpost (paper)';
      break;
    case 3:
    case 'ViewpostDigitalCheck':
      paymentMethod = 'Viewpost (electronic)';
      break;
    case 9:
    case 'CreditCardStripe':
      paymentMethod = 'Credit Card (Stripe)';
      break;
    case 2:
    case 'PaperCheck':
      paymentMethod = 'Paper Check';
      break;
    case 4:
    case 'OutOfBandAch':
    case 'OutOfBandACH':
      paymentMethod = 'ACH';
      break;
    case 'OutOfBandCheck':
      paymentMethod = 'Check';
      break;
    case 6:
    case 'OutOfBandCreditCard':
      paymentMethod = 'Credit Card';
      break;
    case 8:
    case 'OutOfBandEft':
    case 'OutOfBandEFT':
      paymentMethod = 'EFT';
      break;
    case 7:
    case 'OutOfBandOther':
      paymentMethod = 'Other';
      break;
    case 5:
    case 'OutOfBandWire':
      paymentMethod = 'Wire';
      break;
    case 10:
    case 'ThirdPartyDigitalCheck':
    case 'ViewpostAch':
    case 'ViewpostACH':
      paymentMethod = 'Electronic (ACH)';
      break;
    case 11:
    case 'VirtualCreditCard':
      paymentMethod = maskedCardNumber
        ? `Virtual Card (${maskedCardNumber})` : 'Virtual Card';
      break;
    case 12:
    case 'ViewpostDigitalCheckICL':
      paymentMethod = 'Electronic (ICL)';
      break;
    default:
      paymentMethod = 'Unknown';
  }

  return paymentMethod;
};

function sumMoney(...args) {
  let nums = [...args];
  return nums.reduce((total, num) => {
    // workaround for floating point/rounding issues
    return Number((total + num).toFixed(2));
  }, 0);
}

export const getPaymentMethodEnum = (methodValue, isIclOptimized = false) => {
  switch (methodValue) {
    case 1:
    case 'PaperCheck':
    case 'ViewpostPaperCheck':
    case 'Unknown':
      return PaymentMethodType.PaperCheck;
    case 3:
    case 'ElectronicDeposit':
    case 'FreeElectronicDeposit':
    case 'ViewpostDigitalCheck':
      return isIclOptimized ? PaymentMethodType.DigitalCheckICL : PaymentMethodType.DigitalCheck;
    case 10:
    case 'ViewpostAch':
    case 'ViewpostACH':
    case 'Ach':
      return PaymentMethodType.DigitalCheckAch;
    case 11:
    case 'VirtualCreditCard':
      return PaymentMethodType.VirtualCreditCard;
    case 9:
    case 'CreditCardStripe':
      return PaymentMethodType.CreditCardStripe;
    case 'ElectronicDepositICL':
      return PaymentMethodType.DigitalCheckICL;
    case 2:
    case 'OutOfBandCheck':
      return PaymentMethodType.OutOfBandCheck;
    case 4:
    case 'OutOfBandAch':
    case 'OutOfBandACH':
      return PaymentMethodType.OutOfBandAch;
    case 5:
    case 'OutOfBandWire':
      return PaymentMethodType.OutOfBandWire;
    case 6:
    case 'OutOfBandCreditCard':
      return PaymentMethodType.OutOfBandCreditCard;
    case 7:
    case 'OutOfBandOther':
      return PaymentMethodType.OutOfBandOther;
    case 8:
    case 'OutOfBandEft':
    case 'OutOfBandEFT':
      return PaymentMethodType.OutOfBandEft;
    default:
      return null;
  }
};

export const getPaymentMethodOptionType = (paymentMethodType) => {
  switch (paymentMethodType) {
    case PaymentMethodType.PaperCheck:
      return 'PaperCheck';
    case PaymentMethodType.DigitalCheck:
    case PaymentMethodType.DigitalCheckICL:
      return 'ElectronicDeposit';
    case PaymentMethodType.DigitalCheckAch:
    case PaymentMethodType.ThirdPartyDigitalCheck:
      return 'Ach';
    case PaymentMethodType.VirtualCreditCard:
      return 'VirtualCreditCard';
    // API doesn't like us sending this to them
    // case PaymentMethodType.CreditCardStripe:
    //   return 'CreditCardStripe';
    default: return null;
  }
};


/**
  Gets the applicable discount for the given invoice
  @param {Object} invoice - the invoice (InvoiceBase)
  @param {Object[]} discounts - Optionally provide calculated discounts if not paying today (VpxDiscount)
 */
export function getVpxDiscount(invoice, discounts) {
  if (discounts) {
    // discounts provided (from VPX calc endpoint)
    let discount = discounts.find((d) => {
      return d
        && d.masterInvoiceId === invoice.logicalDocumentId
        && d.discountAmount > 0;
    });

    return discount;
  }

  // fallback to discount on invoice itself (today)
  return invoice.vpxActiveDiscount;
}

/**
  Gets the applicable late fee for the given invoice
  @param {Object} invoice - the invoice (InvoiceBase)
  @param {Object[]} lateFees - Optionally provide calculated late fees if not paying today (InvoiceLateFee)
 */
export function getLateFee(invoice, lateFees) {
  if (lateFees) {
    let lateFee = lateFees.find((f) => {
      return f
        && f.masterInvoiceId === invoice.logicalDocumentId
        && f.totalAmount > 0;
    });

    return lateFee;
  }

  return invoice.lateFee;
}

/**
  Gets the VPX fees that would be charged if the given invoices are paid
  @param {Object[]} invoices - Array of invoices (InvoiceBase)
  @param {Object[]} discounts - Optionally provide calculated discounts if not paying today (VpxDiscount)
 */
export function getVpxFeeAmount(invoices, discounts) {
  if (!invoices) {
    return 0;
  }

  let fees = 0;
  invoices.forEach((invoice) => {
    let discount = getVpxDiscount(invoice, discounts);
    if (discount) {
      // TODO: Would be great if the platform always provided this
      fees += 0.3 * discount.discountAmount;
    }
  });
  return fees;
}

/**
  Gets the total amount that should be paid for the given invoices including VPX discounts and late fee adjustments
  @param {Object[]} invoices - Array of invoices (InvoiceBase)
  @param {Object[]} discounts - Optionally provide calculated discounts if not paying today (VpxDiscount)
  @param {Object[]} lateFees - Optionally provide calculated late fees if not paying today (InvoiceLateFee)
 */
export function getAmountDue(invoices, discounts, lateFees) {
  if (!invoices) {
    return 0;
  }

  let totalAmountDue = 0;
  invoices.forEach((invoice) => {
    let discount = getVpxDiscount(invoice, discounts);
    let lateFee = getLateFee(invoice, lateFees);
    if (discount) {
      totalAmountDue = sumMoney(totalAmountDue, discount.discountedBalance);
    } else if (lateFee) {
      totalAmountDue = sumMoney(totalAmountDue, invoice.balance, lateFee.balance);
    } else if (invoice.balance) {
      totalAmountDue = sumMoney(totalAmountDue, invoice.balance);
    } else {
      totalAmountDue = sumMoney(totalAmountDue, invoice.totalAmount);
    }
  });
  return totalAmountDue;
}

/**
  Gets a mapping of delivery times for payment methods
  */
export function getDeliveryWindow(paymentMethodType) {
  switch (paymentMethodType) {
    case PaymentMethodType.DigitalCheck:
      return {
        start: 3,
        end: 5
      };
    case PaymentMethodType.DigitalCheckICL:
      return {
        start: 1,
        end: 2
      };
    case PaymentMethodType.PaperCheck:
      return {
        start: 4,
        end: 5
      };
    case PaymentMethodType.DigitalCheckAch:
    case PaymentMethodType.ThirdPartyDigitalCheck:
      return {
        start: 2,
        end: 4
      };
    case PaymentMethodType.CreditCardStripe:
    case PaymentMethodType.VirtualCreditCard:
    default:
      return null;
  }
}

/**
  Gets various total amounts calculated from a list of invoices including VPX discounts and late fee adjustments
  @param {Object[]} invoices - Array of invoices (InvoiceBase)
  @param {Object[]} discounts - Optionally provide calculated discounts if not paying today (VpxDiscount)
  @param {Object[]} lateFees - Optionally provide calculated late fees if not paying today (InvoiceLateFee)
 */
export function getInvoiceTotals(invoices, discounts, lateFees) {
  let invoiceAmount = 0;
  let invoicePaidAmount = 0;
  let invoiceBalance = 0;

  let discountAmount = 0;
  let lateFeeAmount = 0;
  let lateFeeBalance = 0;

  let adjustedInvoiceAmount = 0;
  let adjustedInvoicePaidAmount = 0;
  let adjustedInvoiceBalance = 0;

  invoices.forEach((invoice) => {
    let discount = getVpxDiscount(invoice, discounts);
    let lateFee = getLateFee(invoice, lateFees);

    let paidAmount = invoice.paidAmount || 0;
    let balance = invoice.balance || invoice.balance === 0
      ? invoice.balance
      : invoice.totalAmount;

    invoiceAmount = sumMoney(invoiceAmount, invoice.totalAmount);
    invoicePaidAmount = sumMoney(invoicePaidAmount, paidAmount);
    invoiceBalance = sumMoney(invoiceBalance, balance);

    if (discount) {
      discountAmount = sumMoney(discountAmount, discount.discountAmount);
      adjustedInvoiceAmount = sumMoney(adjustedInvoiceAmount, invoice.totalAmount, -discount.discountAmount);
      adjustedInvoicePaidAmount = sumMoney(adjustedInvoicePaidAmount, paidAmount);
      adjustedInvoiceBalance = sumMoney(adjustedInvoiceBalance, balance, -discount.discountAmount);
    } else if (invoice.vpxAppliedDiscountAmount) {
      discountAmount = sumMoney(discountAmount, invoice.vpxAppliedDiscountAmount);
      adjustedInvoiceAmount = sumMoney(adjustedInvoiceAmount, invoice.totalAmount, -invoice.vpxAppliedDiscountAmount);
      adjustedInvoicePaidAmount = sumMoney(adjustedInvoicePaidAmount, paidAmount);
      adjustedInvoiceBalance = sumMoney(adjustedInvoiceBalance, balance);
    } else if (lateFee) {
      let lfBalance = lateFee.balance || lateFee.balance === 0
        ? lateFee.balance
        : lateFee.totalAmount;
      lateFeeAmount = sumMoney(lateFeeAmount, lateFee.totalAmount);
      lateFeeBalance = sumMoney(lateFeeBalance, lfBalance);
      adjustedInvoiceAmount = sumMoney(adjustedInvoiceAmount, invoice.totalAmount, lateFee.totalAmount);
      adjustedInvoicePaidAmount = sumMoney(adjustedInvoicePaidAmount, paidAmount, lateFee.totalAmount, -lfBalance);
      adjustedInvoiceBalance = sumMoney(adjustedInvoiceBalance, balance, lfBalance);
    } else {
      adjustedInvoiceAmount = sumMoney(adjustedInvoiceAmount, invoice.totalAmount);
      adjustedInvoicePaidAmount = sumMoney(adjustedInvoicePaidAmount, paidAmount);
      adjustedInvoiceBalance = sumMoney(adjustedInvoiceBalance, balance);
    }
  });

  return {

    // Totals BEFORE discounts or late fees

    /** Total invoice amount */
    invoiceAmount,
    /** Total amount already paid against the invoice amount */
    invoicePaidAmount,
    /** Remaining amount to be paid against the invoice */
    invoiceBalance,

    /** Total amount of all early pay discounts being applied */
    discountAmount,
    /** Total amount of all late fees accrued */
    lateFeeAmount,
    /** Total amount of all late fees remaining to be paid */
    lateFeeBalance,

    // Totals AFTER discount + late fee adjustments

    adjustedInvoiceAmount,
    adjustedInvoicePaidAmount,
    adjustedInvoiceBalance
  };
}
