/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React from 'react';
import { useDispatch } from 'react-redux';
import logout from 'api/session/logout';
import PathLink from 'components/Link/PathLink';
import Menu from 'components/Menu/Menu';
import MenuItem from 'components/Menu/MenuItem';
import VerticalMenuRenderer from 'components/Menu/VerticalMenuRenderer';
import useIdentity from 'hooks/identity/useIdentity';
import { LogOutNavLabel, PrimaryNavLabel } from '../../NavLabel';
import './index.scss';

const SettingsNavMenu = ({
  closeMenu,
  paths
}) => {
  const dispatch = useDispatch();
  const { firstName, lastName } = useIdentity();
  const userName = `${firstName} ${lastName}`;

  return (
    <div className="viewstrap navSettingsMenu">
      <Menu renderer={VerticalMenuRenderer}>
        <MenuItem text={true} className="menu-header">
          <strong className="loggedInUserName">
            {userName}
          </strong>
        </MenuItem>
        <MenuItem divider={true}/>
        {paths.map(({ path }) => (
          <MenuItem key={path.id} className="menu-link" onClick={closeMenu}>
            <PathLink path={path} analyticsId={path.id}>
              <PrimaryNavLabel id={path.id} />
            </PathLink>
          </MenuItem>
        ))}
        <MenuItem button={true} className="sign-out" onClick={() => {
          closeMenu();
          dispatch(logout());
        }}>
          <LogOutNavLabel />
        </MenuItem>
      </Menu>
    </div>
  );
};

export default SettingsNavMenu;
