/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import createApiActions from 'api/core';

export default createApiActions({
  recordEvent: {
    method: 'post',
    url: '/api/telemetry/addWebAppEvent',
    ignoreDispatch: true,
    requestBodyMapper: ({name, ...data}) => ({
      name: name,
      data
    })
  }
});
