/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
// import { FormattedMessage } from 'react-intl';
import TextBox from 'components/Form/Inputs/TextBox';
import classNames from 'classnames';

/**
 * A textbox that masks/hides the value entered
 */
export default class MaskedTextBox extends Component {
  static propTypes = {
    onChange: PropTypes.func,
    value: PropTypes.string,
    disabled: PropTypes.bool
  };

  static defaultProps = {
    disabled: false
  };

  constructor(props) {
    super(props);

    this.state = {
      isMasked: props.startMasked || false,
      value: props.value
    };

    this.onFocus = this.onFocus.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  getTextBoxClasses() {
    return classNames(
      'vp-input',
      {'error': this.props.hasError}
    );
  }

  onBlur(evt) {
    this.setState({
      isMasked: true
    });
    if (this.props.onBlur) {
      this.props.onBlur(evt);
    }
  }

  onFocus(evt) {
    this.setState({
      isMasked: false
    });
    if (this.props.onFocus) {
      this.props.onFocus(evt);
    }
  }

  onChange(value) {
    this.setState({
      value
    });
    if (this.props.onChange) {
      this.props.onChange(value);
    }
  }

  onCopy(e) {
    e.preventDefault();
  }

  render() {
    let maskedValue = Array(this.state.value ? this.state.value.length + 1 : 0).join('\u2022');

    return (
      <TextBox
        {...this.props}
        value={this.state.isMasked ? maskedValue : this.state.value}
        onChange={this.onChange} onFocus={this.onFocus} onBlur={this.onBlur}
        onCopy={this.onCopy}/>
    );
  }
}
