/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React from 'react';
import PropTypes from 'prop-types';
import t from 'tcomb-form';
import { isEqual } from 'lodash';
import { getTypeInfo } from 'tcomb-form/lib/util';
import { injectIntl } from 'react-intl';
import logError from 'services/ErrorService';
import DefaultFieldInput from 'components/Form/Inputs/TextBox';
import DefaultFieldLayout from './FieldLayout';

/**
 * @docsignore - this is really an internal component to our form infrastructure and doesn't need public docs since it shouldn't really be used on its own
 */
class FormField extends t.form.Component {
  static propTypes = {
    options: PropTypes.object
  };

  constructor(props) {
    super(props);
    this.getValidationOptions = this.getValidationOptions.bind(this);
    this.onInputBlur = this.onInputBlur.bind(this);
    this.onInputFocus = this.onInputFocus.bind(this);
  }

  getValidationOptions() {
    let options = super.getValidationOptions();
    // inject react-intl context so getValidationErrorMessage has access to it
    options.context = { intl: this.props.intl };
    return options;
  }

  onInputFocus(evt) {
    // propagate input events to layout
    // Autofocus won't have refs defined
    if (this.layout && this.layout.onInputFocus) {
      this.layout.onInputFocus(evt);
    }
    if (this.props.options.config && this.props.options.config.onFocus) {
      this.props.options.config.onFocus(evt);
    }
  }

  onInputBlur(evt) {
    // propagate input events to layout
    if (this.layout && this.layout.onInputBlur) {
      this.layout.onInputBlur(evt);
    }

    if (this.props.options.config && this.props.options.config.onBlur) {
      this.props.options.config.onBlur(evt, this.state ? this.state.value : null);
    }

    if (this.props.options.config && this.props.options.config.doNotValidateOnBlur) {
      return;
    }

    this.validate();
  }

  componentDidUpdate(nextProps, nextState) {
    if (this.layout && this.layout.props && this.layout.props.hasError) {
      this.validate();
    }
  }

  getPlaceholderText() {
    let message = this.props.ctx.i18n.getPlaceholderMessage(this.props.options.config.path);
    if (!message) return null;

    if (typeof message === 'string') {
      // raw string passed
      return message;
    }

    // object - expect react-intl message descriptor
    let messageValues = {...this.props.ctx.i18n.placeholderValues};
    Object.keys(messageValues).forEach((key) => {
      let value = messageValues[key];
      if (typeof value === 'object' && value.props && value.props.id && value.props.defaultMessage) {
        messageValues[key] = this.props.intl.formatMessage(value.props, messageValues);
      }
    });

    return this.props.intl.formatMessage(message, messageValues);
  }

  // Overriding the default behavior of componentWillReceiveProps because the
  // super behavior is to call setState no matter what. This causes an infinite
  // loop because setState causes componentWillReceiveProps to execute which causes
  // setState to call...you get the picture.
  componentWillReceiveProps(props) {
    if (props.type !== this.props.type) {
      this.typeInfo = getTypeInfo(props.type);
    }

    const value = this.getTransformer().format(props.value);
    this.setState(({ value: oldValue }) => {
      if (isEqual(oldValue, value)) return null;
      return { value };
    });
  }

  getTransformer() {
    if (this.props.options.transformer) {
      return this.props.options.transformer;
    }
    // if (this.typeInfo && this.typeInfo.innerType.meta.name === 'Date') {
    //   let dateTransformer = {
    //     format: getMoment,
    //     parse: getMoment
    //   };
    //   return dateTransformer;
    // }
    if (this.typeInfo && this.typeInfo.innerType.meta.name === 'String') {
      return t.form.Textbox.transformer;
    }
    if (this.typeInfo && this.typeInfo.innerType.meta.name === 'Number') {
      return t.form.Textbox.numberTransformer;
    }
    return t.form.Component.transformer;
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.state.overrideValidationMessage !== nextState.overrideValidationMessage
      || this.state.validateCalled !== nextState.validateCalled
    ) {
      return true;
    }

    return super.shouldComponentUpdate(nextProps, nextState);
  }

  validate() {
    if (!this.state.validateCalled) {
      this.setState({
        validateCalled: true
      });
    }

    const result = super.validate();
    if (this.state.overrideValidationMessage) {
      return {
        ...result,
        isValid: () => false
      };
    } if (this.props.options.config.error) {
      return {
        ...result,
        errors: [...result.errors, {
          message: this.props.options.config.error,
          actual: this.getValue(),
          expected: () => null,
          path: this.props.ctx.path
        }],
        isValid: () => false
      };
    }

    return result;
  }

  hasError() {
    const { ctx, options } = this.props;
    const { overrideValidationMessage, validateCalled } = this.state;
    const { config } = options || {};
    const { error, name } = config || {};
    const { path } = ctx || {};

    if (validateCalled && overrideValidationMessage) {
      return true;
    }

    if (validateCalled && error) {
      return true;
    }

    // There is some weird error in Chrome that causes this to fail as it passes through
    // some getter causing a circular JSON Stringify error. Even though we can't get the
    // error, we can log the problem to the server and at least not break the app.
    try {
      return super.hasError();
    } catch (e) {
      logError('An error occurred when getting if there is a form error.', {
        error: e,
        name: name || this.getName(),
        path: (path || []).join(', ')
      });
    }

    return true;
  }

  getError() {
    const { ctx, options } = this.props;
    const { overrideValidationMessage, validateCalled } = this.state;
    const { config } = options || {};
    const { error, name } = config || {};
    const { path } = ctx || {};

    if (validateCalled && overrideValidationMessage) {
      return overrideValidationMessage;
    }

    if (validateCalled && error) {
      return error;
    }

    // There is some weird error in Chrome that causes this to fail as it passes through
    // some getter causing a circular JSON Stringify error. Even though we can't get the
    // error, we can log the problem to the server and at least not break the app.
    try {
      return super.getError();
    } catch (e) {
      logError('An error occurred when getting the form error.', {
        error: e,
        name: name || this.getName(),
        path: (path || []).join(', ')
      });
    }

    return 'An unknown error occurred.';
  }

  onOverrideValidationMessage(validationMessage) {
    if (this.state.overrideValidationMessage !== validationMessage) {
      this.setState({
        overrideValidationMessage: validationMessage
      });
    }

  }

  // override base tcomb Component onChange to support a 2nd custom option parameter
  // we mainly want to support a way to fire an "after change" callback from here
  // so input components can do things like revalidate after the change has been applied to state
  onChange = (value, changeOptions) => {
    this.setState({ value, isPristine: false }, () => {
      this.props.onChange(value, this.props.ctx.path);
      if (typeof changeOptions?.onAfterChange === 'function') changeOptions.onAfterChange();
    });
  }

  render() {
    // normalize props provided to field components
    let { ctx, options } = this.props;
    let { config, attrs, disabled } = options;

    config = config || {};
    attrs = attrs || {};

    let FieldLayout = DefaultFieldLayout;
    let FieldInput = DefaultFieldInput;

    let fieldProps = {
      // expose standard component parameters
      ctx: ctx,
      type: this.typeInfo.type,
      options: options,
      value: this.state.value,
      onChange: this.onChange.bind(this),
      typeInfo: this.typeInfo,

      // expose props normally provided via 'locals'
      path: ctx.path,
      error: this.getError(),
      hasError: this.hasError(),
      label: config.label || this.getLabel(),
      config: this.getConfig(),
      disabled: config.disabled || disabled,
      helpLink: config.helpLink,

      // other common props
      id: config.id || this.getId(),
      name: config.name || this.getName(),
      placeholder: config.placeholder || attrs.placeholder || this.getPlaceholderText(),
      autoFocus: attrs.autoFocus || config.autoFocus,
      textRows: attrs.textRows,
      cols: config.cols,
      appendIcon: config.appendIcon,
      prependIcon: config.prependIcon,
      alignRight: config.alignRight,
      inlineLabel: config.inlineLabel,
      inlineLabelCols: config.inlineLabelCols,
      inlineLabelMobileStack: config.inlineLabelMobileStack,
      customLabelClass: config.customLabelClass,
      customInputClass: config.customInputClass,
      customErrorClass: config.customErrorClass,
      labelValues: config.labelValues,
      toolTipMessage: config.toolTipMessage,
      appendContent: config.appendContent,
      appendLabelContent: config.appendLabelContent,
      minRows: config.minRows,
      maxRows: config.maxRows
    };

    if (config.supressTcombType) {
      delete fieldProps.type;
    }

    if (config.icon || config.iconPosition) {
      let sentence1 = 'The \'icon\' and \'iconPosition\' config params have been removed.';
      let sentence2 = 'Please use \'appendIcon\' and/or \'prependIcon\', appropriately';
      console.warn(`${sentence1} ${sentence2}`);
    }

    // allow overriding of any field layout or input component and their props
    let layoutProps = {
      ...fieldProps,
      ...config.layoutProps
    };
    let inputProps = {
      ...fieldProps,
      ...config.inputProps
    };
    if (config.layoutComponent) {
      FieldLayout = config.layoutComponet;
    }
    if (config.inputComponent) {
      FieldInput = config.inputComponent;
    }

    // In case we use a native tcomb-form component as an input (which come with their own labels by default)
    // e.g. we still use t.form.Radio
    // always clear out the label for consistency, b/c we want to use our own label (via FieldLayout) instead
    inputProps.options = inputProps.options || {};
    inputProps.options.label = <span></span>;

    return (
      <FieldLayout
        ref={r => this.layout = r}
        {...layoutProps}
      >
        <div {...config.inputWrapperProps}>
          <FieldInput
            onFocus={this.onInputFocus}
            onBlur={this.onInputBlur}
            validate={() => this.validate()} // allow inputs to manually control when to revalidate
            formOverrideValidationMessage={(...args) => this.onOverrideValidationMessage(...args)}
            {...inputProps}
          />
        </div>
      </FieldLayout>
    );
  }
}

export default injectIntl(FormField, { forwardRef: true });
