/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, { cloneElement, useEffect, useMemo, useState } from 'react';
import LoadingIndicator from 'components/LoadingIndicator';

const DisplayWorkflow = ({
  children,
  workflow
}) => {
  // Hooks
  const [ areAllLoaded, setAreAllLoaded ] = useState(false);
  const [ areAllCompleted, setAreAllCompleted ] = useState(false);

  const [ workflowState, setWorkflowState ] = useState(
    () => workflow.map(
      ({ id, overlay }) => ({ id, loaded: false, completed: false, skipped: false, overlay })
    )
  );

  const updateState = useMemo(
    () => (id, state) => {
      switch (state) {
        case 'loaded':
        case 'completed':
        case 'skipped':
          break;
        default:
          throw new Error(`Unknown state transition ${state}`);
      }

      setWorkflowState(
        (prevState) => {
          const nextState = prevState.map(
            (step, index) => {
              if (step.id !== id) return step;

              return {
                ...step,
                loaded: step.loaded
                  || state === 'loaded' || state === 'completed' || state === 'skipped',
                completed: step.completed
                  || state === 'completed' || state === 'skipped',
                skipped: step.skipped
                  || state === 'skipped'
              };
            }
          );

          if (!nextState.some(({ loaded }) => !loaded)) {
            setAreAllLoaded(true);
          }

          if (!nextState.some(({ completed }) => !completed)) {
            setAreAllCompleted(true);
          }

          return nextState;
        }
      );
    },
    []
  );

  const [ workflowElements, setWorkflowElements ] = useState(
    () => workflow.map(
      ({ id, element }) => cloneElement(
        element,
        { key: id, id, updateState: state => updateState(id, state) }
      )
    )
  );

  const { id: activeId, overlay: isOverlayActive } = (areAllLoaded ? workflowState.find(
    ({ completed, loaded, skipped }) => !completed && loaded && !skipped
  ) : null) || {};

  useEffect(
    () => {
      setWorkflowElements(prevState => prevState.map(
        (element) => {
          const stepState = workflowState.find(({ id }) => id === element.props.id);

          if (stepState.completed) return null;

          if (element.props.id === activeId && !element.props.active) {
            return cloneElement(element, { active: true });
          }

          if (element.props.id !== activeId && element.props.active) {
            return cloneElement(element, { active: false });
          }

          return element;
        }
      ).filter(x => !!x));
    },
    [ activeId, workflowState ]
  );

  // Render
  let content;
  if (!areAllLoaded) {
    content = <LoadingIndicator centerInPage={true} />;
  } else if (areAllCompleted || isOverlayActive) {
    content = children;
  }

  return (
    <>
      {workflowElements}
      {content}
    </>
  );
};

export default DisplayWorkflow;