/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import AddSignature from 'containers/AddSignature';
import { FormattedMessage } from 'react-intl';
import Button from 'components/Button';
import Messages from './index.messages';
import './editOrAddSignature.scss';

export default class EditOrAddSignature extends Component {
  static propTypes = {
    /**
     * Base 64 encoded image of the signature
     */
    signatureImage: PropTypes.string,
    /**
     * Called with new signature image when it changes
     */
    onChange: PropTypes.func,
    signatureId: PropTypes.string
  };

  constructor(props) {
    super(props);

    this.state = {
      isEditing: !props.signatureImage
    };
  }

  cleanupStorage = () => {
    if (this.addSignatureRef) {
      this.addSignatureRef.cleanupStorage();
    }
  }

  renderPreview() {
    return (
      <div className="component-edit-or-add-signature">
        <span className="pad-half vp-label">
          <FormattedMessage {...Messages.formLabels.Signature} />
        </span>
        <div className="preview-signature-border">
          <Button
            anchor={true}
            className="pad edit-button"
            onClick={() => this.setState({ isEditing: true })}
          >
            <FormattedMessage {...Messages.buttons.Edit} />
          </Button>
          <img src={`data:image/png;base64, ${this.props.signatureImage}`}
            style={{width: '100%'}} />
        </div>
      </div>
    );
  }

  render() {
    if (!this.props.signatureId) {
      return null;
    }

    return this.state.isEditing || !this.props.signatureImage ? (
      <AddSignature
        ref={ref => this.addSignatureRef = ref}
        variant={this.props.variant}
        signatureId={this.props.signatureId}
        invitationId={this.props.invitationId}
        onChange={this.props.onChange}
      />
    ) : this.renderPreview();
  }
}

export const FormEditOrAddSignature = ({
  value,
  onChange,
  formRef = () => null,
  ...props
}) => (
  <EditOrAddSignature
    ref={formRef}
    signatureImage={value}
    onChange={onChange}
    {...props}
  />
);
