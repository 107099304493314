/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React from 'react';
import { Route, Redirect, Switch } from 'react-router';

import Paths from 'config/paths';

import { wrapImport } from 'decorators/ModuleLoader';

import extractFromCompanyBookId from 'schemas/network/extractFromCompanyBookId';

import { flattenHashLocation, InAppNotFoundView } from '../utils';

const NetworkConnectionRequests = wrapImport(
  () => import(/* webpackChunkName: "views" */ 'views/Network/connectionrequestsPage')
);
const NetworkList = wrapImport(() => import(/* webpackChunkName: "views" */ 'views/Network/List'));
const NetworkProfile = wrapImport(() => import(/* webpackChunkName: "views" */ 'views/Network/Profile'));

const LegacyNetworkRedirects = ({ location, match }) => (
  <Switch location={flattenHashLocation(location)}>
    <Route path={match.url} exact={true} render={() => <Redirect to="/network/list" />} />
    <Route path="*" render={() => <Redirect to="/network/list" />} />
  </Switch>
);

const makeProfileRedirect = (companyBookId) => {
  const { addressBookId, companyId } = extractFromCompanyBookId(companyBookId);
  if (companyId) {
    return <Redirect to={Paths.Network.Profile.Company.toPath({ companyId })} />;
  }

  return <Redirect to={Paths.Network.Profile.AddressBook.toPath({ addressBookId })} />;
};

export default ({ match }) => (
  <Switch>
    {/* Redirects */}
    <Redirect path={match.url} exact={true} to={`${match.url}/list`} />
    <Route path="ng" component={LegacyNetworkRedirects} />
    <Redirect path={`${match.url}/network`} exact={true} to="/network/list" />
    <Route path={`${match.url}/network/company/:companyBookId`} exact={true}>
      {({ match: { params: { companyBookId }} }) => makeProfileRedirect(companyBookId)}
    </Route>
    <Redirect path={`${match.url}/requests/received`} exact={true} to="/network/connectionrequests" />
    <Redirect path={`${match.url}/requests/sent`} exact={true} to="/network/connectionrequests" />
    <Redirect path={`${match.url}/requests/received/connection/:id`} exact={true} to="/network/connectionrequests" />
    <Redirect path={`${match.url}/requests/sent/connection/:id`} exact={true} to="/network/connectionrequests" />
    <Redirect path={`${match.url}/openConnectionRequest`} exact={true} to="/network/connectionrequests/received" />
    <Redirect path={`${match.url}/routeConnectionRequest`} exact={true} to="/network/connectionrequests/received" />
    {/* Routes */}
    <Route path={`${match.url}/list`} exact={true}>
      <NetworkList />
    </Route>
    <Route path={`${match.url}/connectionrequests`} exact={true} component={NetworkConnectionRequests} />
    <Route path={`${match.url}/profile/addressBook/:addressBookId`} exact={true}>
      <NetworkProfile />
    </Route>
    <Route path={`${match.url}/profile/company/:companyId`} exact={true}>
      <NetworkProfile />
    </Route>
    <Route path={`${match.url}/profile/:companyBookId`} exact={true}>
      {({ match: { params: { companyBookId }} }) => makeProfileRedirect(companyBookId)}
    </Route>
    {InAppNotFoundView}
  </Switch>
);