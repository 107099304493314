/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, { Component } from 'react';
import FormJSX from 'components/Form/FormJSX';
import FieldOption from 'components/Form/FieldOption';
import MaskedField from 'components/Form/Inputs/MaskedTextBox';

import Icon, { IconTypes }  from 'components/Icon';

import PaperCheckRoutingExample from 'components/PaperCheckRoutingExample';
import LoadingSpinner from 'components/LoadingSpinner';
import FlagIcon from 'components/Icon/FlagIcon';
import ProvideIsMobile from 'decorators/ProvideIsMobile';

import t from 'tcomb-validation';
import { createBoundedLengthNumberType, NumericString } from 'schemas/common/number';
import { createUniqueStringType, createRegexType } from 'schemas/common/string';

import Messages from './index.messages';
import connect from './index.connect';

const createRoutingNumberType = (routingNumberValid, country = 'US') => {
  let base = country === 'US' ? NumericString : t.String;
  let RoutingNumber = t.subtype(base, (value) => {
    return value.length === 9 && routingNumberValid;
  }, 'RoutingNumber');

  RoutingNumber.getValidationErrorMessage = (value, path, context) => {
    if (value && value.length !== 9 && context && context.intl) {
      return context.intl.formatMessage(Messages.RoutingNumberLength);
    } if (!routingNumberValid && context && context.intl) {
      return context.intl.formatMessage(Messages.RoutingNumberInvalid);
    }

    return '';
  };

  return RoutingNumber;
};

function isValidMatch(value1, value2) {
  return value1 === value2;
}

const createMatchingAccountNumber = (value1) => {
  let MatchingAccountNumber = t.subtype(t.String, (value2) => {
    return isValidMatch(value1, value2);
  }, 'MatchingAccountNumber');

  MatchingAccountNumber.getValidationErrorMessage = (value2, path, context) => {
    if (!isValidMatch(value1, value2) && context && context.intl) {
      return context.intl.formatMessage(Messages.DoNotMatch);
    }

    return '';
  };

  return MatchingAccountNumber;
};

const createCreateablePaymentMethod = ({
  accountNumber,
  isRoutingNumberValid,
  bankAccountNames,
  hideAccountName,
  country,
  canadianBankAccounts,
  isMobile
}) => {

  let currencyEnums = {
    'USD': isMobile ? 'USD' : <div>
      <FlagIcon country="US" /> USD
    </div>
  };
  if (canadianBankAccounts) {
    currencyEnums.CAD = isMobile ? 'CAD' : <div>
      <FlagIcon country="CA" /> CAD
    </div>;
  }

  const struct = {
    currency: t.enums(currencyEnums),
    routingNumber: createRoutingNumberType(isRoutingNumberValid, country),
    accountNumber: country === 'CA'
      ? createRegexType(/^[0-9-]{1,17}$/, 'Numbers and dashes only')
      : createBoundedLengthNumberType(0, 17),
    verifyAccountNumber: createMatchingAccountNumber(accountNumber)
  };

  if (!hideAccountName) {
    struct.name = createUniqueStringType(bankAccountNames, {
      errorMessage: Messages.BankAccountNameTaken
    });
  }

  return t.struct(struct, 'CreateablePaymentMethod');
};

class AddBankAccountManuallyStepForm extends Component {

  componentWillMount() {
    if (!this.props.formState?.currency || this.props.existingBankAccount) {
      this.onFormChange({
        currency: 'USD',
        name: this.props.existingBankAccount?.accountName,
        routingNumber: this.props.existingBankAccount?.routingNumber
      });
    }
  }

  getCountry() {
    return this.props.formState?.currency === 'CAD' ? 'CA' : 'US';
  }

  onFormChange(value) {
    if (value.routingNumber?.length === 9) {
      if (value.routingNumber !== this.props.formState.routingNumber
        || value.country !== this.props.formState.country) {
        this.props.validateRoutingNumber(value.routingNumber, this.getCountry());
      }
    }
    this.props.onChange(value);
  }

  render() {
    const {
      isValidationLoading,
      formRef,
      bankAccounts,
      isRoutingNumberValid,
      hideAccountName,
      routingBankName,
      formState,
      isFormDisabled,
      hideBankName,
      canadianBankAccounts,
      isMobile
    } = this.props;

    let country = this.getCountry();

    const modelType = createCreateablePaymentMethod({
      accountNumber: formState.accountNumber,
      isMobile,
      isRoutingNumberValid,
      bankAccountsNames: (bankAccounts || [])
        .map(bankAccount => bankAccount.accountName),
      hideAccountName,
      country,
      canadianBankAccounts
    });

    let bankName = isValidationLoading || hideBankName || formState?.routingNumber?.length !== 9
      ? null
      : routingBankName;

    return (
      <FormJSX ref={formRef}
        modelType={modelType}
        showRequiredLabels={true}
        hideFormLegend={true}
        disabled={isFormDisabled}
        onChange={value => this.onFormChange(value)}
        value={formState}>
        {!hideAccountName
          ? (
            <FieldOption name="name"
              label={Messages.AccountName}
              cols={12}
              placeholder={Messages.AccountNamePlaceholder}
            />
          )
          : null}
        <FieldOption name="currency"
          label="Currency"
          disabled={!canadianBankAccounts}
          cols={3} />
        <div cols={9} style={{textAlign: 'right'}}>
          <div className="vp-label" style={{display: 'block', textAlign: 'right'}}>
            Locate Routing and Account numbers from a check or bank statement
          </div>
          <PaperCheckRoutingExample country={country} />
        </div>
        <FieldOption name="routingNumber"
          disabled={isFormDisabled}
          type={isFormDisabled ? 'password' : 'text'}
          rightLabel={isValidationLoading
            ? <div style={{width: '15px', height: '15px'}}><LoadingSpinner /></div>
            : null}
          label={country === 'CA'
            ? 'Routing or Transit Number'
            : 'Routing Number'}
          placeholder={country === 'CA'
            ? '012345678 or 12345-678'
            : 'e.g. 123456789'}
          footerLabel={<div>
            {!bankName ? null
              : (
                <div style={{
                  border: '1px solid #ccc',
                  padding: '2px 5px',
                  backgroundColor: '#ecf7fd'
                }}>
                  <Icon type={IconTypes.Bank} style={{ color: '#999' }} size={12} />&nbsp;
                  Bank: <strong>{bankName}</strong>
                </div>
              )}
          </div>}
          cols={4} />

        <FieldOption name="accountNumber"
          label={Messages.AccountNumber}
          cols={4}
          disabled={isFormDisabled}
          inputComponent={MaskedField}
          inputProps={{ startMasked: isFormDisabled }}
          supressTcombType={true}
        />
        <FieldOption name="verifyAccountNumber"
          cols={4}
          label={Messages.VerifyAccountNumber}
          inputComponent={MaskedField}
          inputProps={{ startMasked: isFormDisabled }}
        />
      </FormJSX>
    );
  }
}

export default connect(ProvideIsMobile(AddBankAccountManuallyStepForm));
