/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { Component } from 'react';
import { initialize } from 'services/MixpanelService';

class MixpanelService extends Component {
  componentDidMount() {
    initialize();
  }

  render() {
    // Does not provide context, should not be passed children
    return null;
  }
}

export default MixpanelService;
