/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { SyncViewActions } from 'config/constants/app';

export default {
  path: '/sync',
  children: {
    Payments: {
      path: '/payments',
      requiredAction: SyncViewActions.ViewPayments
    },
    History: {
      path: '/history',
      requiredAction: SyncViewActions.ViewHistory
    },
    Settings: {
      path: '/settings',
      requiredAction: SyncViewActions.ViewSettings
    },
    Landing: {
      path: '/landing'
    },
    DownloadXero: {
      path: '/download/Xero'
    },
    DownloadQbOnline: {
      path: '/download/QbOnline'
    },
    DownloadNetsuite: {
      path: '/download/NetSuite'
    },
    DownloadSageLive: {
      path: '/download/SageLive'
    },
    DownloadRiskmaster: {
      path: '/download/Riskmaster'
    },
    DownloadQuickbooksOnline: {
      path: '/download/QuickbooksOnline'
    },
    DownloadQuickBooksDesktop: {
      path: '/download/QuickBooksDesktop'
    },
    DownloadMicrosoftDynamicsGp: {
      path: '/download/MicrosoftDynamicsGp'
    },
    DownloadOracle: {
      path: '/download/Oracle'
    },
    QuickbooksOnlineOnboarding: {
      path: '/onboarding/QuickbooksOnline'
    },
    XeroOnboarding: {
      path: '/onboarding/Xero'
    },
    OracleOnboarding: {
      path: '/onboarding/Oracle'
    }
  }
};
