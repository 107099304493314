/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { createApiAction } from 'api/core';

import { SwitchCompanyProfile } from './getSwitchCompanies.schemas';

const createNewSwitchCompany = createApiAction({
  method: 'post',
  url: '/api/register/add',
  requestBodyMapper: ({
    isCompanyMatchOverride,
    isCompanyMatchOverrideCanceled,
    cardToken,
    companyName
  }) => ({
    companyName,
    creditCardToken: cardToken,
    doingBusinessAs: companyName,
    isCompanyMatchOverride,
    isCompanyMatchOverrideCanceled
  }),
  markUpdated: { entityType: SwitchCompanyProfile.meta.name }
});

export default createNewSwitchCompany;