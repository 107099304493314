/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */
/* eslint max-len:0 */

import { toMessages } from 'config/messages';

export default toMessages('SwitchCompaniesView', {
  TitleSwitchCompanyPrompt: 'Would you like to switch companies?',
  UnknownCompanyTitle: 'There\'s a problem with your link',
  UnknownCompanyInstructions: 'You clicked on a link that isn\'t valid for your account. It might be for another account of yours that you will need to log into first or just that something went wrong. Either way, we can\'t send you where you\'d like to go.',
  DefaultCompany: 'Default',
  DoingBusinessAs: 'dba. {companyName}',
  DbaWithCompanyName: '{companyName} (dba. {dbaCompanyName})',
  SwitchCompanyPromptWarning: 'In another tab you are working as {currentCompany}. To view a document for {targetCompany} '
    + 'you will have to switch companies. If you proceed, your session for {currentCompany} will end, and any '
    + 'unsaved work in progress will be lost. Are you sure you\'d like to proceed?',
  NoSwitch: 'No, don\'t switch',
  YesSwitch: 'Yes, switch companies'
});
