/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

/*
    Returns a simple name mapper for query string parameters
    based on the given input -> query name mapping
    (values remain as-is)
 */
function queryParamNameMapper(paramNameMap) {
  return (inputParams) => {
    let queryParameters = {};
    for (let inputParamName in paramNameMap) {
      if (!paramNameMap.hasOwnProperty(inputParamName)) {
        continue;
      }
      let queryParamName = paramNameMap[inputParamName];
      if (inputParams.hasOwnProperty(inputParamName) && inputParams[inputParamName] !== undefined) {
        queryParameters[queryParamName] = inputParams[inputParamName];
      }
    }
    return queryParameters;
  };
}

export default queryParamNameMapper;