/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */
/* eslint prefer-template:0 */

import React from 'react';

import InputBoxes from 'react-individual-character-input-boxes';

import './index.scss';

class OverriddenInputBoxes extends InputBoxes {

  // override the base paste behavior to only remove invalid characters
  // rather than stopping the paste once you reach one
  // Example: For an alphanum regex,
  // Allow pasting "ABC-123" to go in as "ABC123" rather than "ABC" (base behavior)
  handleOnPaste(event) {
    event.preventDefault();
    const pasted = event.clipboardData.getData('text/plain');
    let pastedArray = pasted.split('')

      // remove invalid characters
      .filter(c => c.match(this.props.inputRegExp))

      .slice(0, this.props.amount);


    let i = 0;
    for (i = 0; i < pastedArray.length; i++) {
      // if (pastedArray[i].match(this.props.inputRegExp)) {
      this.inputElements['input' + i].value = pastedArray[i];
      // }
      // else break; // don't stop
    }
    if (i <= this.props.amount - 1) this.inputElements['input' + i].focus();
    else this.inputElements['input' + (this.props.amount - 1)].focus();
    this.setModuleOutput();
  }


  // fix bug in base component causing an issue when entering
  // multiple chars at the same time
  handleFocus({ target }) {
    target?.select();
  }
}


function SingleCharactersTextBox({
  /** TODO: implement support for prepopulating or syncing value from parent */
  value,

  onChange,
  hasError,

  autoFocus,
  placeholder,

  /** Regex defining allowable characters */
  inputRegExp = /^[0-9A-Za-z]$/,

  /** Number of characters the input needs to capture */
  characterCount = 6,

  /** custom style object OR function of each index  */
  inputStyle
}) {

  let inputProps = {
    className: 'character-input',
    // style: { "color": "orange" },
    placeholder
  };

  if (typeof inputStyle  === 'function') {
    // base component allow input props to be array
    // for character-level styling
    let propArray = [];
    for (let i = 0; i < characterCount; i++) {
      propArray[i] = {
        ...inputProps,
        style: inputStyle?.(i)
      };
    }
    inputProps = propArray;
  } else if (inputStyle) {
    inputProps.style = inputStyle;
  }

  return (
    <div className={`component-single-characters-textbox ${hasError ? 'error' : ''}`}>
      <OverriddenInputBoxes
        amount={characterCount}
        autoFocus={autoFocus}
        handleOutputString={v => onChange(v)}
        inputProps={inputProps}
        inputRegExp={inputRegExp} />
    </div>
  );
}

export default SingleCharactersTextBox;