/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { createApiAction } from 'api/core';

import { createEntity } from 'schemas/state';

import { SwitchCompanyProfile } from './getSwitchCompanies.schemas';

const getSwitchCompanies = createApiAction({
  method: 'get',
  url: '/api/user/switchCompany',
  responseMapper: ({ companies }) => ({
    entities: companies.map(company => createEntity(
      company.companyId,
      SwitchCompanyProfile.meta.name,
      {
        id: company.companyId,
        companyName: company.companyName,
        dbaCompanyName: company.dbaCompanyName,
        address: company.companyAddress || {},
        companyId: company.companyId,
        isActiveUser: company.isActiveUser,
        isOwner: company.isOwner,
        isDefault: company.isDefault
      }
    ))
  })
});

export default getSwitchCompanies;