/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

const pagedResponseMapper = (
  {
    page: responsePage,
    pageSize: responsePageSize,
    sortFields,
    totalCount
  },
  apiParams = {}
) => {
  let pageSize = responsePageSize || apiParams.pageSize;
  if (!pageSize) {
    pageSize = 0;
  }

  // API returns 0 pageSize in some cases
  if (pageSize < 1) {
    pageSize = totalCount;
  }

  if (pageSize < 1) {
    pageSize = 1;
  }

  let page = responsePage || apiParams.page;
  page = page ? Number(page) : 1; // Make sure page is a number

  return {
    pagination: {
      page,
      pageSize,
      sortFields,
      totalItems: Number(totalCount),
      totalPages: Math.ceil(totalCount / pageSize)
    }
  };
};

export default pagedResponseMapper;