/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { createApiAction } from 'api/core';

import LogonFailureReason, {
  TwoFactorFailureReason
} from 'config/constants/session/LogonFailureReason';

import logonResponseMapper from './mappers/logonResponseMapper';

const twoFactorSubmit = createApiAction({
  method: 'post',
  url: '/ajax/logon/twoFactorAuth',
  requestBodyMapper: ({ code, rememberDevice }) => ({
    rememberDevice,
    authenticationCode: code
  }),
  responseMapper: (legacyResponse, state, requestParams) => {
    const {
      entities: logonEntities,
      result: logonResult
    } = logonResponseMapper(
      legacyResponse,
      state,
      requestParams
    );

    const { messages, response, success } = legacyResponse;

    if (success) {
      const { userName } = requestParams || {};
      const { deviceToken } = response;

      if (deviceToken && userName) {
        try {
          localStorage.setItem(`DeviceId-${userName}`, deviceToken);
        } catch {
          // Local storage no-worky, nothing we can do
        }
      }

      return {
        entities: logonEntities,
        result: logonResult
      };
    }

    if (messages.some(({ context }) => context === 'AuthenticationCode')) {
      return {
        result: {
          ...(logonResult || {}),
          failure: LogonFailureReason.TwoFactorRequired,
          twoFactorFailure: TwoFactorFailureReason.InvalidCode
        }
      };
    }

    return {
      entities: logonEntities,
      result: logonResult
    };
  }
});

export default twoFactorSubmit;