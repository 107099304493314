/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import {
  createEntity,
  createEntityReference,
  createReferenceFromEntity
} from 'schemas/state';
import {
  AddressBookLink,
  ConnectionRequest,
  ConnectionRequests
} from 'schemas/network/connectionRequest';
import RetrievableAddressBookEntry from 'schemas/network/RetrievableAddressBookEntry';
import dateMapper from 'api/common/mappers/dateMapper';
import mapApiToAddress from 'api/common/mappers/mapApiToAddress';
import { filterAvailableActionsFromState } from 'api/permissions';
import { ConnectionRequestTypes } from 'config/constants';
import NetworkActions from 'config/constants/network/NetworkActions';
import { companyIdMapper } from './companyMappers';
import toRelationshipEnum from './toRelationshipEnum';

function mapApiToConnectionRequest(apiConnectionRequest, state) {
  let availableActions = [];

  if (apiConnectionRequest.type === ConnectionRequestTypes.Received) {
    availableActions.push(NetworkActions.Accept);
    availableActions.push(NetworkActions.Decline);
  } else {
    availableActions.push(NetworkActions.Resend);
    availableActions.push(NetworkActions.Cancel);
  }

  // permission filter
  availableActions = filterAvailableActionsFromState(state, availableActions);

  return createEntity(
    apiConnectionRequest.id,
    ConnectionRequest.meta.name,
    {
      id: apiConnectionRequest.id,
      type: apiConnectionRequest.type,
      date: (apiConnectionRequest.type === ConnectionRequestTypes.Sent)
        ? dateMapper(apiConnectionRequest.sentDate) : dateMapper(apiConnectionRequest.receivedDate),
      originEvent: apiConnectionRequest.deliveredVia,
      isPendingApproval: apiConnectionRequest.isPendingApproval, //
      relationship: toRelationshipEnum(apiConnectionRequest.relationshipType),
      addressBookId: apiConnectionRequest.addressBookId,
      companyProfileId: apiConnectionRequest.company.id,
      companyId: companyIdMapper(apiConnectionRequest.company.id, apiConnectionRequest.addressBookId),
      company: {
        name: apiConnectionRequest.company.name,
        phone: apiConnectionRequest.company.phone,
        address: mapApiToAddress(apiConnectionRequest.company.address),
        firstName: apiConnectionRequest.company.contact.firstName,
        lastName: apiConnectionRequest.company.contact.lastName,
        // Coalescing with null for cases of empty strings that cause validation errors
        email: apiConnectionRequest.company.contact.email || null,
        numberOfConnections: apiConnectionRequest.company.numberOfConnections
      },
      sentFrom: {
        // Coalescing with null for cases of empty strings that cause validation errors
        firstName: apiConnectionRequest.fromContact.firstName,
        lastName: apiConnectionRequest.fromContact.lastName,
        email: apiConnectionRequest.fromContact.email || null
      },
      sentTo: {
        // firstName: apiConnectionRequest.company.contact.firstName,
        // lastName: apiConnectionRequest.company.contact.lastName,
        // email: apiConnectionRequest.company.contact.email
      },
      companyName: apiConnectionRequest.company.name,
      address: mapApiToAddress(apiConnectionRequest.company.address, apiConnectionRequest.company.name),
      availableActions: availableActions
    }
  );
}


export function getConnectionRequestsResponseMapper(response, state) {
  let entities = [];
  let receivedRequests = [];
  let sentRequests = [];

  if (response && response.length) {
    response.forEach((request) => {
      const entity = mapApiToConnectionRequest(request, state);
      entities.push(entity);
      if (entity.value.type === ConnectionRequestTypes.Received) {
        receivedRequests.push(createReferenceFromEntity(entity));
      } else if (entity.value.type === ConnectionRequestTypes.Sent) {
        sentRequests.push(createReferenceFromEntity(entity));
      }
    });
  }

  entities.push(createEntity(
    'current',
    ConnectionRequests.meta.name,
    {
      received: receivedRequests,
      sent: sentRequests
    }
  ));

  return { entities };
}

export function getLinkAddressBookResponseMapper(
  mvcLinkAddressBookResponse,
  state,
  { companyId }
) {
  let entities = [];
  let references = [];

  if (mvcLinkAddressBookResponse && mvcLinkAddressBookResponse.success) {
    let linkEntity = {
      id: companyId,
      links: []
    };

    mvcLinkAddressBookResponse.response.topMatchingAddressBooks.forEach((match) => {
      linkEntity.links = linkEntity.links.concat({
        addressBook: createEntityReference(
          match.addressBookId,
          RetrievableAddressBookEntry.meta.name
        ),
        weight: match.weight
      });
    });

    entities = entities.concat(createEntity(linkEntity.id, AddressBookLink.meta.name, linkEntity));
  }

  return {
    entities,
    references
  };
}

export function updateConnectionRequestBodyMapper(apiParams) {
  return {
    accept: true,
    addressBookId: apiParams.addressBookId
  };
}
