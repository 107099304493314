/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, { useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import t from 'tcomb-validation';

import { TwoFactorFailureReason } from 'config/constants/session/LogonFailureReason';
import Button from 'components/Button';
import PhoneNumber from 'components/PhoneNumber';
import { SmallBannerNotification, NotificationStyleTypes } from 'components/Notification';
import FloatingAlert from 'containers/Logon/FloatingAlert';
import FormJSX, { FieldOption } from 'components/Form/FormJSX';
import CommonMessages from 'config/messages/common';
import Messages from './index.messages';

const TwoFactorPromptSchema = t.struct({
  code: t.String
}, 'TwoFactorPrompt');

const TwoFactorPromptWithRememberSchema = t.struct({
  code: t.String,
  rememberDevice: t.maybe(t.Bool)
}, 'TwoFactorPrompt');

const TwoFactorPrompt = ({
  canRememberDevice,
  error,
  isResending,
  isSubmitting,
  onResendCode: onResendCodeProp,
  onSubmit,
  phoneNumber: phoneNumberProp
}) => {
  // Hooks
  const formRef = useRef();

  const [ formState, setFormState ] = useState({});

  const onContinue = (e) => {
    e.preventDefault();

    let value = formRef.current.getValue();
    if (value) {
      onSubmit(value.code, value.rememberDevice);
    }
  };

  const onResendCode = () => onResendCodeProp(
    { successMessage: <FormattedMessage {...Messages.CodeResentAlert} /> }
  );

  // Render
  let alert = null;

  if (!isSubmitting) {
    if (error === TwoFactorFailureReason.InvalidCode) {
      alert = (
        <SmallBannerNotification type={NotificationStyleTypes.Warning} shakeOnEnter={true}>
          <FormattedMessage {...Messages.IncorrectCodeError} />
        </SmallBannerNotification>
      );
    }

    if (alert) alert = <FloatingAlert>{alert}</FloatingAlert>;
  }

  const rightLabel = (
    <Button
      className="vp-anchor-label"
      onClick={onResendCode}
      anchor={true}
      tabIndex="4"
      isDisabled={isResending}
      isProcessing={isResending}
    >
      <FormattedMessage {...Messages.ResendCode} />
    </Button>
  );

  const target = phoneNumberProp ? (
    <PhoneNumber
      masked={true}
      dashStyle={true}
      value={phoneNumberProp}
    />
  ) : (
    <FormattedMessage {...Messages.YourDevice} />
  );

  return (
    <form>
      {alert}
      <FormattedMessage
        {...Messages.Instructions}
        values={{ target }}
      />
      <FormJSX
        ref={formRef}
        modelType={canRememberDevice ? TwoFactorPromptWithRememberSchema : TwoFactorPromptSchema}
        onChange={v => setFormState(v)}
        value={formState}
      >
        <FieldOption
          name="code"
          autoFocus={true}
          label={Messages.labelMessages.code}
          rightLabel={rightLabel}
          type="number"
          doNotValidateOnBlur={true}
        />
        {canRememberDevice
          ? <FieldOption name="rememberDevice" cols={7} label={Messages.labelMessages.rememberDevice} />
          : null}
        <Button
          type="submit"
          isDisabled={isSubmitting}
          isProcessing={isSubmitting}
          onClick={onContinue}
          cols={canRememberDevice ? 5 : null}
          expand={true}
        >
          <FormattedMessage {...Messages.ContinueButton} />
        </Button>
      </FormJSX>
      <div className="vp-footer" style={{marginTop: '1rem'}}>
        <FormattedMessage
          {...Messages.FooterText}
          values={{ contact: <CommonMessages.CustomerSupportEmail.Message/> }}
        />
      </div>
    </form>
  );
};

export default TwoFactorPrompt;
