/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

export default store => next => (action) => {
  const result = next(action);
  if (action && action.onDispatch) {
    next(action.onDispatch);
  }

  return result;
};
