/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { createApiAction } from 'api/core';

import logonRequestBodyMapper from './mappers/logonRequestBodyMapper';
import logonResponseMapper from './mappers/logonResponseMapper';

const kickSession = createApiAction({
  method: 'post',
  url: '/ajax/logon/kickExistingSession',
  requestBodyMapper: logonRequestBodyMapper,
  responseMapper: logonResponseMapper
});

export default kickSession;