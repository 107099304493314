/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { isValidElement } from 'react';
import ComponentWithConfigurationChildren from '../ComponentWithConfigurationChildren';

export default class Menu extends ComponentWithConfigurationChildren {
  render() {
    if (this.props.renderer) {
      let renderer = new this.props.renderer(this.props);

      if (isValidElement(renderer)) return renderer;

      return renderer.render ? renderer.render() : renderer.renderMenu(this.props.children, this.props);
    }

    console.warn('Cannot render a menu without passing a renderer');
    return null;
  }
}
