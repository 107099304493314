/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */
/* eslint max-len:0 */

import { toMessages } from 'config/messages';

export default toMessages('RegistrationView', {
  UserHeader: 'Secure your account.',
  UserSubheader: 'Create a password and complete account setup.',
  UserInstructions: 'This account has been created by an administrator to provide you access to your company\'s Viewpost account. This will allow you to view the status of invoices and payments, as well as managing the account (where permission has been given).',
  BankUnitedUserInstructions: 'This account has been created to provide enhanced payment services in partnership with BankUnited. Account information, payment methods, and security settings can be managed easily from within your Viewpost account.',
  NoContextHeader: 'Creating an account is painless.',
  NoContextSubheader: '(Isn\'t often you can say that in business.)',
  SetupElectronicPaymentsHeader: 'Smart, simple, and secure.',
  VendorSetupElectronicPaymentsSubheader: 'Submit this form to begin receiving electronic payment from {companyName}.',
  CustomerSetupElectronicPaymentsSubheader: 'Submit this form to begin sending electronic payments to {companyName}.',
  PartialRegistrationHeader: 'Ahh, the hard part is over.',
  PaymentAccountRequiredSubheader: 'Thanks for enrolling with Viewpost.',
  AddPasswordSubheader: 'You’re almost done.'
});
