/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import moment from 'moment';

export const parseDate = (date) => {
  if (date) {
    const utcDate = moment.utc(date).format('YYYY-MM-DD');
    return moment(utcDate, 'YYYY-MM-DD').toDate();
  }

  return null;
};

export const parseDateTime = (date) => {
  return moment.utc(date).format();
};

export const getUtcOffset = () => {
  return moment().utcOffset();
};

export const getTimeZone = () => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone ?? null;
};

/**
 * DEPRECATED: Use `components/FormattedDate` for displaying dates
 */
export const formatDate = (date) => {
  let formattedDate = null;

  console.warn('Please use <FormattedDate> for displaying dates');

  const momentDate = moment(date);
  if (date && momentDate.isValid()) {
    formattedDate = momentDate.format('M/D/YYYY');
  }

  return formattedDate;
};
