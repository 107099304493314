/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

// Helper function to apply preventDefault to an onClick function
export const preventDefault = innerFunc => (e, ...args) => {
  if (e) {
    e.preventDefault();
  }

  innerFunc?.(e, ...args);
};

// Helper function to apply stopPropagation to an onClick function
export const stopPropagation = innerFunc => (e, ...args) => {
  if (e) {
    e.stopPropagation();
  }

  innerFunc?.(e, ...args);
};

export const willStopPropagtion = stopPropagation();

// JL Note: I am amazing at naming things
// Helper function to apply preventDefault and stopPropagation to an onClick function
export const preventStopAll = innerFunc => (e, ...args) => {
  if (e) {
    e.preventDefault();
    e.stopPropagation();
  }

  innerFunc?.(e, ...args);
};
