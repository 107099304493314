/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React from 'react';
import Pagination from 'components/Pagination/v2';
import LoadingIndicator from 'components/LoadingIndicator';

/**
 * UI Pagination component that works with a useApi API
 */
function ApiPagination({
  children,

  /** api model provided by useApi */
  api,

  /** min number of totalItems that must be present before paging controls should be rendered */
  minItemCount = 4,

  /** Whether or not to swap out a loading indicator for the content while API is loading
   *  Turn this off if you don't want the children to be unmounted everytime the API data is reloaded
   */
  showLoadingIndicator = true,

  /** support all other props supported by Pagination component */
  ...props
}) {

  if (api?.isPaged?.() !== true) return 'Paged Api not provided (set useApi config to { paged: true })';

  const pagination = api.getPagination();

  let content = children;
  if (showLoadingIndicator && api.isLoading()) content = <LoadingIndicator />;

  let showPaging = pagination?.totalItems != null
    && pagination?.totalItems > minItemCount;

  return (
    <div>
      {showPaging
        ? (
          <Pagination {...props}
            page={pagination.page}
            pageSize={pagination.pageSize}
            totalCount={pagination.totalItems}
            isCapped={pagination.isCapped}
            onChange={(...args) => api.setPage(...args)} />
        )
        : null}
      {content}
      {showPaging
        ? (
          <Pagination {...props}
            page={pagination.page}
            pageSize={pagination.pageSize}
            totalCount={pagination.totalItems}
            isCapped={pagination.isCapped}
            onChange={(...args) => api.setPage(...args)} />
        )
        : null}
    </div>
  );
}

export default ApiPagination;
