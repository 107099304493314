/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { isValidAccount } from './isAccount';

const filterForValidAccounts = (
  accounts,
  includeVerifiable = false,
  isWhitelisted = false
) => (accounts || [])
  .filter(account => isValidAccount(
    account,
    {
      includeVerifiable,
      isRecipientWhitelisted: isWhitelisted
    }
  ));

export default filterForValidAccounts;