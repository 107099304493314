/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import t from 'tcomb-validation';

const HightlightMatch = t.struct({
  start: t.Number,
  length: t.Number
}, 'HightlightMatch');

export const HighlightMatches = t.dict(t.String, t.list(HightlightMatch), 'HighlightMatches');
