/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import Paths from 'config/paths';
import {
  BusinessInvoicesLinks,
  BusinessBillsLinks,
  BusinessNetworkLinks,
  createBusinessPaymentsLinks,
  BusinessCompanySettingsLinks,
  BusinessDiscountSettingsLinks,
  BusinessSyncLinks
} from './common';

const BusinessInvoicesNavConfig = {
  navLabel: Paths.Invoices,
  matchOn: Paths.Invoices,
  children: BusinessInvoicesLinks
};

const BusinessBillsNavConfig = {
  navLabel: Paths.Bills,
  matchOn: Paths.Bills,
  children: BusinessBillsLinks
};

const createBusinessPaymentsNavConfig = withReceived => ({
  navLabel: Paths.Payments,
  matchOn: Paths.Payments,
  children: createBusinessPaymentsLinks(withReceived)
});

const BusinessNetworkNavConfig = {
  navLabel: Paths.Network,
  matchOn: Paths.Network,
  children: BusinessNetworkLinks
};

const BusinessCompanySettingsNavConfig = {
  navLabel: Paths.Settings.Profile,
  matchOnChildren: true,
  children: BusinessCompanySettingsLinks
};

const BusinessDiscountSettingsNavConfig = {
  navLabel: Paths.Settings.Discounts,
  matchOn: Paths.Settings.Discounts,
  children: BusinessDiscountSettingsLinks
};

const BusinessSyncNavConfig = {
  navLabel: Paths.Sync,
  fallbackPath: Paths.Sync,
  matchOn: Paths.Sync,
  children: BusinessSyncLinks
};

const getBusinessNavConfig = ({
  accountingPackage,
  isPortalUser,
  isVccEnabled
}) => {
  const hasAccountingPackage = !!accountingPackage && accountingPackage !== 'None';

  if (isPortalUser) {
    return {
      Navigation: [
        Paths.Payments.Received,
        BusinessInvoicesNavConfig,
        BusinessBillsNavConfig,
        createBusinessPaymentsNavConfig(false),
        Paths.Settings.Subscription
      ],
      SettingsMenu: [
        Paths.Settings.UserAccount,
        BusinessCompanySettingsNavConfig,
        Paths.Settings.PaymentMethods,
        BusinessNetworkNavConfig,
        Paths.Settings.Fees,
        isVccEnabled ? Paths.Settings.Reports : null,
        Paths.Settings.SwitchCompanies
      ]
    };
  }

  return {
    Navigation: [
      BusinessInvoicesNavConfig,
      BusinessBillsNavConfig,
      createBusinessPaymentsNavConfig(true),
      BusinessNetworkNavConfig,
      hasAccountingPackage ? BusinessSyncNavConfig : null,
      Paths.Settings.Subscription
    ],
    SettingsMenu: [
      Paths.Settings.UserAccount,
      BusinessCompanySettingsNavConfig,
      Paths.Settings.PaymentMethods,
      BusinessDiscountSettingsNavConfig,
      Paths.Settings.Fees,
      isVccEnabled ? Paths.Settings.Reports : null,
      Paths.Settings.SwitchCompanies
    ]
  };
};

const PersonalPortalConfig = {
  Navigation: [
    Paths.Payments.Received,
    Paths.Invoices.List,
    Paths.Bills.ConsumerOpenBills,
    Paths.Bills.ConsumerClosedBills,
    Paths.ConsumerAutoPay,
    Paths.ConsumerSettings,
    Paths.ConsumerFees,
    Paths.Settings.SwitchCompanies
  ],
  SettingsMenu: []
};

const getMobileConfig = ({
  accountingPackage,
  isPersonalUser,
  isPortalUser,
  isVccEnabled
}) => {
  if (isPersonalUser) return PersonalPortalConfig;
  return getBusinessNavConfig({
    accountingPackage,
    isPortalUser,
    isVccEnabled
  });
};

export default getMobileConfig;