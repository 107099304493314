/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React from 'react';
import Icon, { IconTypes } from 'components/Icon';

const DropdownArrowCaret = ({
  style
}) => (
  <span style={style}>
    <Icon type={IconTypes.DropdownArrow} />
  </span>
);

export default DropdownArrowCaret;
