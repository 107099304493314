/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

export default {
  path: '/logon',
  queryStringMapper: ({
    reason,
    userName
  }) => ({
    reason,
    userName
  }),
  children: {
    ResetPassword: {
      path: '/resetPassword'
    }
  }
};
