/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { before, debounce, isError } from 'lodash';
import TelemetryApi from 'api/telemetry';
import { setHandler as setErrorHandler, setExceptionHandler } from 'services/ErrorService';

const AppErrorName = 'WebAppError';
const SCRIPT_URL_REGEX = /vpcdn|vpsandbox-cf|localhost|corppanvpn/;
const LOG_LIMIT = 50;

let hasLoggedLogFailure = false;

// This is to get around that when we minify, we strip console.*
// However, in this case, we really want it to show, so I'm being clever.
// It's the same 'ole console.error, just obfuscated.
const obfuscatedConsoleError = (...args) => (console || {})['error'](...args); //eslint-disable-line dot-notation

const instrumentErrorHandling = (store) => {
  // This might seem a little confusing, to log an error when there's an error logging an error
  // but that's why it should only ever happen once
  // This is chiefly to catch problems where the server is failing to log an error because of the
  // content of the error
  const logLoggingFailure = originalMessage => (success) => {
    if (success || hasLoggedLogFailure) return;

    hasLoggedLogFailure = true;
    store.dispatch(TelemetryApi.recordEvent.create({
      name: AppErrorName,
      message: 'There was an error logging an error.',
      originalMessage
    }));
  };

  const logFunc = (message, data, { includeStackTrace } = {}) => {
    data = data || {};
    obfuscatedConsoleError(message, data);
    const errorData = {
      message,
      ...data
    };

    let stacktrace = null;

    if (includeStackTrace) {
      try {
        throw Error(message);
      } catch (ex) {
        stacktrace = ex.stack;
      }
    }

    if (stacktrace) errorData.trace = stacktrace;

    // Break down an error passed in and log it to the console
    if (data.error && isError(data.error)) {
      obfuscatedConsoleError(data.error);

      const { message: errorMessage, stack } = data.error;
      errorData.error = errorMessage;
      errorData.errorSource = stack ? stack : data.error.toString();
    }

    store.dispatch(TelemetryApi.recordEvent.create({
      name: AppErrorName,
      ...errorData
    }, {
      onComplete: logLoggingFailure(message)
    }));
  };

  const throttledFunc = debounce(logFunc, 500, {
    leading: true,
    trailing: false
  });

  setErrorHandler((message, data, { throttle, includeStackTrace } = {}) => {
    if (throttle) throttledFunc(message, data, { includeStackTrace });
    else logFunc(message, data, { includeStackTrace });
  });

  const handleJsError = (message, source, error, extraData) => {
    // Good errors have an error param filled out
    if (!error) return;

    let errorMsg = error.toString();

    if (error.stack) errorMsg = error.stack;

    let isBadError = true;

    // If we can't search the content of the error message,
    // it's probably a bad error
    if (errorMsg && errorMsg.match) {
      // If there's no mention of one of our script locations,
      // it's probably not a great error
      if (errorMsg.match(SCRIPT_URL_REGEX)) {
        isBadError = false;
      }
    }

    // Same as above, but sometimes the source is set to be one
    // of our scripts. It would be ideal if we could rely on this,
    // but it seems this is more often than not the page the error
    // happened on
    if (source && source.match) {
      if (source.match(SCRIPT_URL_REGEX)) {
        isBadError = false;
      }
    }

    // Trying to filter out errors that are noise
    if (isBadError) return;

    const errorData = {
      message,
      source,
      error: errorMsg
    };

    const { errorSource, ...params } = extraData || {};
    if (errorSource) errorData.errorSource = errorSource;
    if (params && Object.keys(params).length) errorData.parameters = params;

    store.dispatch(TelemetryApi.recordEvent.create({
      name: 'JsError',
      ...errorData
    }, {
      onComplete: logLoggingFailure(message)
    }));
  };

  window.onerror = before(LOG_LIMIT, (
    message,
    source,
    lineno,
    colno,
    error
  ) => handleJsError(message, source, error, { errorSource: 'WindowOnError' }));

  setExceptionHandler(before(LOG_LIMIT, (err, extraData) => {
    if (!err) return;
    obfuscatedConsoleError(err);
    handleJsError(err.message, null, err, extraData);
  }));

  window.addEventListener('unhandledrejection', before(LOG_LIMIT, (err, promise) => {
    if (!err || !err.reason) return;
    handleJsError(err.reason.message, null, err.reason, { errorSource: 'UnhandledRejection' });
  }));

  return store;
};

export default instrumentErrorHandling;