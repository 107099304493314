/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { push } from 'connected-react-router';
import { GetPath, LinkRef } from 'links';
import { connect } from 'react-redux';
import patchCompanySettings from 'api/settings/patchCompanySettings';
import { createCacheActions } from 'actions/references';

const UpdateAction = createCacheActions(
  'ShowAnnualUpdateReminder_UpdateAction',
  patchCompanySettings
);

function MapStateToProps(state) {
  return {
  };
}

function MapDispatchToProps(dispatch, props) {
  return {
    onSubmit: () => {
      dispatch(push(GetPath(LinkRef.companySettings.index)));
      dispatch(UpdateAction.makeApiCall({
        lastUpdateReminder: true
      }));
      props.onClose();
    }
  };
}

export default Component => connect(MapStateToProps, MapDispatchToProps)(Component);
