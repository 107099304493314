/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { useSelector } from 'react-redux';

import { UseAppAuthorizationReason } from 'schemas/session/UseAppAuthorizationStatus';

export const useHasNotBeenRedirected = () => useSelector(
  state => !state.session.hasBeenRedirected
);

export const useHasNotDoneBigAction = () => useSelector(
  state => !state.session.hasDoneOncePerSessionAction
);

export const useHasLoggedInOnThisPage = () => useSelector(
  (state) => {
    return state.session.authReason === UseAppAuthorizationReason.LogOn
    || state.session.authReason === UseAppAuthorizationReason.Registration;
  }
);