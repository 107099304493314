/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React from 'react';
import { isString } from 'lodash';

// This does two things:
//   1) Breaks aparts already title-cased Enum-like values into a title case string
//      e.g., ThisIsAnEnum -> This Is An Enum
//   2) Takes normal strings and title cases them
//      e.g., i want to be title cased -> I Want To Be Title Cased
//
// The former was the original goal of this component, but I realized a component simply named
// 'TitleCase' might lead someone else to think this can be applied to normal strings, so this
// supports that as well.
export const toTitleCase = str => str
  .split(/(?=[A-Z|\s])/) // Use lookahead to keep already capitalized words
  .map(x => x.trim()) // Remove the whitespace that was kept by the lookahead
  .map(x => x.charAt(0).toUpperCase() + x.slice(1))
  .join(' ');

const TitleCase = ({ children }) => {
  // Don't blow up if we get non string input, just roll with it
  if (!children) return null;
  if (!isString(children)) return children;

  const titleCaseString = toTitleCase(children);

  return (
    <>
      {titleCaseString}
    </>
  );
};

export default TitleCase;