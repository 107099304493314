/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { createEntity } from 'schemas/state';
import { Acknowledgement } from 'schemas/dashboard';

export function getAcknowledgementsResponseMapper(mvcResponse, state, apiParams) {
  if (!mvcResponse.success) return null;

  const response = mvcResponse.response;
  const acknowledgements = response.acknowledgements;

  // we're always only looking at 1 at a time
  let id = apiParams.id;
  let isOn = false;

  if (acknowledgements && acknowledgements.includes(id)) {
    // if set/present, it is on
    isOn = true;
  }

  // set or unset acknowledgment
  let entities = [
    createEntity(id, Acknowledgement.meta.name, {
      id,
      completed: isOn,
      isCompanyWide: false
    })
  ];

  return {
    entities,
    references: []
  };
}
