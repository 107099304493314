/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { toEnumObject } from 'config/constants/utils';

export const MetadataTargetCompanyStatus = toEnumObject([
  'Unregistered',
  'PartialRegistration',
  'Registered'
]);

export const MetadataTargetCompanyPaymentStatus = toEnumObject([
  'NoAccounts',
  'Registered',
  'Pending'
]);

export const RegistrationErrors = {
  Unknown: 'Unknown',
  EmailAddressInUse: 'EmailAddressInUse',
  CommonPassword: 'CommonPassword',
  InvalidPaperCheckInfo: 'InvalidPaperCheckInfo',
  InvalidCaptchaCode: 'InvalidCaptchaCode',
  PaperCheckExpired: 'PaperCheckExpired',
  RegistrationBlocked: 'RegistrationBlocked'
};

export const ShowAddPaymentAccountStatus = toEnumObject([
  'Shown',
  'Required',
  'Hidden'
]);