/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React from 'react';
import classNames from 'classnames';
import './index.scss';

export const BaseTable = ({
  children,
  className
}) => (
  <table className={classNames('vp-table', className)}>
    {children}
  </table>
);

const Table = ({
  children,
  className,
  footer,
  header,
  tbodyClassName
}) => {
  return (
    <div className="viewstrap">
      <table
        className={classNames(
          'vp-table',
          {
            'auto': !className?.includes('fixed')
          },
          className
        )}
      >
        {header ? (
          <thead>
            {header}
          </thead>
        ) : null}
        <tbody className={tbodyClassName}>
          {children}
        </tbody>
        {footer ? (
          <tfoot>
            {footer}
          </tfoot>
        ) : null}
      </table>
    </div>
  );
};

export default Table;