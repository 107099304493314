/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import logout from 'api/session/logout';

import useQueryParams from 'hooks/location/useQueryParams';

const ChangeEmailWorkflowStep = ({
  active,
  updateState
}) => {
  // Hooks
  const dispatch = useDispatch();
  const { changeEmailToken } = useQueryParams([ 'changeEmailToken' ]);

  const willShow = !!changeEmailToken;

  useEffect(
    () => {
      updateState(willShow ? 'loaded' : 'skipped');
    },
    []
  );

  const execute = async () => {
    await dispatch(logout());
    updateState('completed');
  };

  useEffect(
    () => {
      if (willShow && active) {
        // useEffect doesn't like being passed an async function - hence why this broken out
        execute();
      }
    },
    [ active ]
  );

  // Render
  return <></>;
};

export default ChangeEmailWorkflowStep;
