/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, { useEffect, useMemo, useRef, useState } from 'react';
import Cookies from 'js-cookie';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import isInExternalEnvironment from 'actions/app/isInExternalEnvironment';
import {
  getCoordinatorFailedReason,
  isCoordinatorLoading
} from 'actions/coordinator';
import { dispatchApiCall } from 'actions/references';
import {
  createRegistrationFlow,
  getRegistrationResult,
  RegistrationReferenceName
} from 'actions/registration';

import { createEntityAction } from 'api/core/utils';
import RegistrationApi, { ExpeditedRegistrationError } from 'api/registration';

import BodyText, { BodyTextVariants } from 'components/BodyText';
import Border from 'components/Border';
import Button from 'components/Button';
import Currency from 'components/Currency';
import { SmallBannerNotification, NotificationStyleTypes } from 'components/Notification';

import { TermsOfUse, PaymentTerms, PrivacyPolicy } from 'config/messageLinks';

import VerifyAccount from 'containers/Registration/VerifyAccount';

import { LoginCoordinatorName } from 'config/constants';

import useQueryParams from 'hooks/location/useQueryParams';
import { useRegistrationMetadata } from 'hooks/registration';

import GreenCheckmark from 'public/images/icons/green-checkmark.svg';

import {
  UseAppAuthorizationReason,
  createUseAppAuthorizedEntity
} from 'schemas/session/UseAppAuthorizationStatus';

import CompanyMatches from './CompanyMatches';
import Messages, { ErrorMessages } from './index.messages';
import RegistrationForm from './Form';
import SubscriptionPaymentForm from './SubscriptionPayment';

const RegistrationErrorNotification = ({ error, style }) => {
  if (!error) return <></>;

  const ErrorMessage = ErrorMessages[error] || ErrorMessages.Default;
  return (
    <div style={style}>
      <SmallBannerNotification type={NotificationStyleTypes.Warning} shakeOnEnter={true}>
        <ErrorMessage.Message />
      </SmallBannerNotification>
    </div>
  );
};

const reinitiateFinishRegisterCompanyAccount = createRegistrationFlow(
  dispatch => dispatch(createEntityAction([
    createUseAppAuthorizedEntity(UseAppAuthorizationReason.Registration)
  ]))
);

const registerCompanyAccount = ({
  invitationId,
  referralToken,
  companyName,
  email,
  phone,
  firstName,
  lastName,
  password,
  onboardingFlow,
  companyMatchId,
  captchaToken,
  captchaTokenType,
  creditCardToken,
  ignoreAuthEntityCreation
}) => createRegistrationFlow((dispatch, getState) => {
  dispatch(dispatchApiCall(
    RegistrationReferenceName,
    RegistrationApi.registerNewAccount,
    {
      invitationId,
      referralToken,
      companyName,
      emailAddress: email,
      phoneNumber: phone,
      firstName,
      lastName,
      password,
      onboardingFlow,
      companyMatchId,
      captchaToken,
      captchaTokenType,
      creditCardToken,
      ignoreAuthEntityCreation
    }
  ));
});

const connectSelector = (state) => {
  const {
    captchaToken,
    error,
    isCreditCardTokenRequired,
    matches: companyMatches,
    selectedExistingCompany
  } = state.registration;
  const isRegistering = isCoordinatorLoading(state, LoginCoordinatorName.Register);
  const { encryptedUserId, isRegistered, isVerified } = getRegistrationResult(state) || {};

  return {
    companyMatches,
    coordinatorError: getCoordinatorFailedReason(state, LoginCoordinatorName.Register),
    encryptedUserId,
    error,
    isCreditCardTokenRequired,
    inExternalTestEnvironment: isInExternalEnvironment(state),
    isRegistered,
    isVerified,
    selectedExistingCompany,
    submitLoading: isRegistering,
    vpCaptchaToken: captchaToken
  };
};

const BasicRegistrationWorkflow = ({
  isSkinny,
  prefill,
  registrationFormHeader
}) => {
  // Hooks
  const dispatch = useDispatch();
  const formRef = useRef();
  const recaptchaRef = useRef();
  const metadata = useRegistrationMetadata();

  const queryPrefill = useQueryParams([
    'companyName',
    'firstName',
    'lastName',
    'emailAddress'
  ]);

  const {
    companyName: metadataCompanyName,
    email: metadataEmail,
    firstName: metadataFirstName,
    invitationId,
    isValid: isMetadataValid,
    lastName: metadataLastName,
    phoneNumber: metadataPhoneNumber,
    referralToken
  } = metadata || {};

  const [ formState, setFormState ] = useState({
    companyName: queryPrefill.companyName || (prefill || {}).companyName || metadataCompanyName,
    email: queryPrefill.emailAddress || (prefill || {}).email || metadataEmail,
    firstName: queryPrefill.firstName || (prefill || {}).firstName || metadataFirstName,
    lastName: queryPrefill.lastName || (prefill || {}).lastName || metadataLastName,
    phoneNumber: metadataPhoneNumber
  });
  const [ requestParams, setRequestParams ] = useState({});

  const {
    companyMatches,
    coordinatorError,
    error,
    encryptedUserId,
    isCreditCardTokenRequired,
    inExternalTestEnvironment,
    isRegistered,
    isVerified,
    selectedExistingCompany,
    submitLoading,
    vpCaptchaToken
  } = useSelector(
    connectSelector,
    shallowEqual
  );

  const { onboardingFlow } = useQueryParams([ 'onboardingFlow' ]);

  useEffect(
    () => {
      if (onboardingFlow) {
        Cookies.set('OnboardingFlow', onboardingFlow, { expires: 365, secure: true });
      }
    },
    [ onboardingFlow ]
  );

  useEffect(
    () => {
      if (isMetadataValid) {
        if (invitationId) {
          Cookies.set('InvitationId', invitationId, { expires: 365, secure: true });
        } else if (referralToken) {
          Cookies.set('ReferralToken', referralToken, { expires: 365, secure: true });
        }
      } else {
        Cookies.remove('InvitationId');
        Cookies.remove('ReferralToken');
      }
    },
    [ isMetadataValid, invitationId, referralToken ]
  );

  useEffect(
    () => {
      if (error && (recaptchaRef.current || {}).reset) {
        recaptchaRef.current.reset();
      }
    },
    [ error ]
  );

  const registerAccount = useMemo(
    () => (actualRequestParams) => {
      dispatch(registerCompanyAccount({
        invitationId,
        referralToken,
        captchaTokenType: 'ReCaptcha',
        onboardingFlow,
        ...actualRequestParams
      }));
    },
    [ invitationId, referralToken, onboardingFlow ]
  );

  const onCreateAccount = useMemo(
    () => (e) => {
      e.preventDefault();

      let formValue = formRef.current.getValue();

      if (formValue) {
        formValue = {
          ...formValue,
          captchaToken: vpCaptchaToken || formValue.captchaToken,
          captchaTokenType: vpCaptchaToken ? 'Viewpost' : 'ReCaptcha'
        };

        setRequestParams(formValue);
        registerAccount(formValue);
      }
    }
  );

  // Render
  const { subscription } = metadata || {};

  const currentError = coordinatorError || error;

  if (isRegistered) {
    if (selectedExistingCompany) {
      return <Messages.AdminNotified.Message />;
    }
    if (requestParams.creditCardToken) {
      const paymentConfirmation = (
        <div style={{ textAlign: 'center' }}>
          <div>
            <img src={GreenCheckmark} />
          </div>
          <BodyText
            variant={BodyTextVariants.Smallest}
            bold={true}
            style={{ paddingTop: 12 }}
          >
            <Messages.PaymentConfirmationMessageHeader.Message
              emailAddress={formState.email}
            />
          </BodyText>
          <BodyText variant={BodyTextVariants.Smallest} color="darkGrey">
            <Messages.PaymentConfirmationMessage.Message
              amount={<Currency value={subscription.amount} />}
              day={new Date().getDate()}
            />
          </BodyText>
        </div>
      );

      if (!isVerified) {
        return (
          <div>
            <Border noPadding={true} style={{ padding: 16 }}>
              {paymentConfirmation}
            </Border>
            <VerifyAccount
              canCancel={false}
              encryptedUserId={encryptedUserId}
            />
          </div>
        );
      }

      return (
        <div style={{ textAlign: 'center' }}>
          {paymentConfirmation}
          <Button onClick={() => dispatch(reinitiateFinishRegisterCompanyAccount())}>
            <Messages.PaymentConfirmationButton.Message />
          </Button>
        </div>
      );
    }

    if (!isVerified) {
      return (
        <VerifyAccount
          canCancel={false}
          encryptedUserId={encryptedUserId}
        />
      );
    }
  } else if ((!currentError || currentError === ExpeditedRegistrationError.CreditCardProcessingError)
    && isCreditCardTokenRequired) {
    return (
      <div>
        <RegistrationErrorNotification error={currentError} style={{ paddingBottom: 12 }} />
        <SubscriptionPaymentForm
          isLoading={submitLoading}
          subscription={subscription}
          onSubmit={(creditCardToken) => {
            setRequestParams(prevState => ({
              ...prevState,
              creditCardToken,
              ignoreAuthEntityCreation: true
            }));
            registerAccount({
              ...requestParams,
              creditCardToken,
              ignoreAuthEntityCreation: true
            });
          }}
        />
      </div>
    );
  } else if (!currentError && companyMatches) {
    return (
      <CompanyMatches
        matches={companyMatches}
        onSelect={(companyMatchId) => {
          setRequestParams(prevState => ({ ...prevState, companyMatchId }));
          registerAccount({ ...requestParams, companyMatchId });
        }}
        isLoading={submitLoading}
      />
    );
  }

  return (
    <>
      {registrationFormHeader}
      {inExternalTestEnvironment ? (
        <SmallBannerNotification type={NotificationStyleTypes.Warning}>
          <Messages.TestEnvDisclaimer.Message />
        </SmallBannerNotification>
      ) : null}
      <RegistrationErrorNotification error={currentError} />
      <RegistrationForm
        invitationId={invitationId}
        isSkinny={isSkinny}
        isSubmitting={submitLoading}
        onChange={setFormState}
        onSubmit={onCreateAccount}
        ref={formRef}
        recaptchaRef={recaptchaRef}
        value={formState}
      />
      <div style={{ textAlign: 'center', padding: 16 }}>
        <Messages.Disclaimer.Message
          tou={TermsOfUse}
          paymentTerms={PaymentTerms}
          privacyPolicy={PrivacyPolicy}
        />
      </div>
    </>
  );
};

export default BasicRegistrationWorkflow;
