/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SignaturePad from 'react-signature-pad-wrapper';
import Button from 'components/Button';
import { FormattedMessage } from 'react-intl';
import Messages from './index.messages';
import './index.css';

export default class SignatureCanvas extends Component {
  static propTypes = {
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    required: PropTypes.bool,
    signatureImage: PropTypes.string,
    hideClear: PropTypes.bool,
    hideBorder: PropTypes.bool,
    onBegin: PropTypes.func,
    onEnd: PropTypes.func
  };

  static defaultProps = {
    width: 600,
    height: 200,
    hideClear: false,
    hideBorder: false
  };

  constructor(props) {
    super(props);
    this.state = {
      imageData: null
    };
  }

  componentDidMount() {
    if (this.props.signatureImage && this.signatureRef) {
      this.signatureRef.clear();
      this.signatureRef.fromDataURL(`data:image/png;base64, ${this.props.signatureImage}`);
    }

    window.addEventListener('resize', this.resizeCanvas.bind(this));
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resizeCanvas);
  }

  resizeCanvas() {
    setTimeout(this.resizeTimeoutCall.bind(this), 100);
  }

  resizeTimeoutCall() {
    if (this.signatureRef && this.state.imageData) {
      this.signatureRef.clear();
      this.signatureRef.fromDataURL(this.state.imageData);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.signatureImage !== nextProps.signatureImage) {
      if (this.signatureRef) {
        this.signatureRef.clear();
        this.signatureRef.fromDataURL(`data:image/png;base64, ${nextProps.signatureImage}`);
      }
    }
  }

  getImagePng() {
    if (this.props.required && this.signatureRef && this.signatureRef.isEmpty()) return null;

    return this.signatureRef.toDataURL('image/png');
  }

  getImageData = () => {
    let image = this.getImagePng();
    if (!image) {
      return null;
    }
    return image.split('base64,').pop();
  }

  clear = () => {
    if (this.signatureRef) {
      this.signatureRef.clear();
    }
    this.setState({
      imageData: null
    });
  }

  onEnd = () => {
    this.setState({
      imageData: this.signatureRef.toDataURL()
    });
    if (this.props.onEnd) {
      this.props.onEnd();
    }
  }

  setupRef = (ref) => {
    this.signatureRef = ref;

    const { onBegin } = this.props;

    if (this.signatureRef) {
      this.signatureRef.onEnd = this.onEnd;

      if (onBegin) {
        this.signatureRef.onBegin = onBegin;
      }
    }
  }

  render() {
    let height = !isNaN(this.props.height) ? `${this.props.height}px` : this.props.height;
    return (
      <div className="vp-signature">
        <div className={this.props.hideBorder ? null : 'signatureBorder'} style={{height}}>
          <SignaturePad
            ref={this.setupRef}
            clearButton={false}
          />
          <div className="dotted-line"></div>
        </div>
        {!this.props.hideClear
          ? <Button anchor={true} onClick={this.clear}>
            <FormattedMessage {...Messages.buttons.Clear} />
          </Button>
          : null}
      </div>
    );
  }
}
