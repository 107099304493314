/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import t from 'tcomb-validation';

import AddressBookRelationships from 'config/constants/network/AddressBookRelationships';

const AddressBookRelationship = t.enums({
  [AddressBookRelationships.Vendor]: 'Vendor',
  [AddressBookRelationships.Customer]: 'Customer'
});

export default AddressBookRelationship;