/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

export function updateAddressBookRequestBodyMapper(creatableAddressBook) {
  let address = creatableAddressBook.address || {};

  // convert to NetworkAddressBookModel
  return {
    addressBookId: creatableAddressBook.id,
    company: {
      name: creatableAddressBook.companyName,
      address1: address.line1,
      address2: address.line2,
      address3: address.line3,
      address4: address.line4,
      city: address.city,
      state: address.stateProvince,
      postalCode: address.postalCode,
      country: address.country,
      phone: creatableAddressBook.phone,
      relationship: creatableAddressBook.relationship
    },
    contact: {
      firstName: creatableAddressBook.firstName,
      lastName: creatableAddressBook.lastName,
      email: creatableAddressBook.email
    },
    clientReferenceId: creatableAddressBook.clientReferenceId
  };
}

export function updateAddressBookLinksRequestBodyMapper(links) {
  return {
    addressBookLinks: links
  };
}

export function updateAddressBookEmailRequestBodyMapper(creatableAddressBook) {
  return {
    addressBookId: creatableAddressBook.id,
    contact: {
      email: creatableAddressBook.email
    }
  };
}

export function updateAddressBookAddressRequestBodyMapper(creatableAddressBook) {
  let address = creatableAddressBook.address || {};

  return {
    addressBookId: creatableAddressBook.id,
    company: {
      address1: address.line1,
      address2: address.line2,
      address3: address.line3,
      address4: address.line4,
      city: address.city,
      state: address.stateProvince,
      postalCode: address.postalCode,
      country: address.country
    }
  };
}
