/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React from 'react';
import { FormattedMessage } from 'react-intl';
import Cookies from 'js-cookie';
import Button from 'components/Button';
import Messages from './index.messages';
import './index.scss';

export default () => {
  const featureBranchCookie = Cookies.get('overrideAppKey', { secure: true });

  if (!featureBranchCookie) return <span style={{display: 'none'}}/>;

  const clearCookie = () => {
    Cookies.remove('overrideAppKey');
    window.location.reload();
  };

  return (
    <div className="viewstrap feature-branch-warning">
      <FormattedMessage {...Messages.Message} values={{branch: featureBranchCookie}} />
      <span className="reset-button">
        <Button anchor={true} onClick={clearCookie}>
          <FormattedMessage {...Messages.Button} />
        </Button>
      </span>
    </div>
  );
};
