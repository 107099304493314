/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { createApiAction } from 'api/core';
import { createInvitationMetadata } from 'api/registration/utils';

import { OnboardingActions } from 'config/constants/onboarding';

import { InvoiceApprovalRequest } from 'schemas/invoicing/invoice';
import { SecureTokenIdentity } from 'schemas/network/session';
import { createEntity } from 'schemas/state';

import {
  AchPlusInvitationData,
  InvoiceOnboardingData,
  SecureTokenContextModel,
  SecureTokenScenario
} from './getSecureTokenData.schemas';

const handleInvoiceOnboardingContext = (
  secureToken,
  {
    businessType,
    companyStatus,
    connectionStatus,
    email,
    fromCompanyId,
    hasOutOfBandInvoices,
    invitationId,
    invoices,
    isRegisteredUser,
    permissions: tokenPermissions,
    targetCompanyId,
    viewName
  }
) => {
  let availableActions = [];

  if ((tokenPermissions || []).length) {
    if (tokenPermissions.includes('ConsumerPayment')) {
      availableActions.push(OnboardingActions.SendConsumerPayment);
    }

    if (tokenPermissions.includes('GhostCompany')) {
      availableActions.push(OnboardingActions.RegisterConsumerAccount);
    }

    if (tokenPermissions.includes('CreditCardPayment')) {
      availableActions.push(OnboardingActions.SendCreditCardPayment);
    }
  }

  let scenario = null;
  if (viewName === 'Nonboarding') {
    scenario = SecureTokenScenario.meta.map.InvoicePayAndOnboard;
  } else if (viewName === 'Landing') {
    if (invoices.some(({ invoiceType }) => invoiceType === InvoiceApprovalRequest.meta.name)) {
      scenario = SecureTokenScenario.meta.map.InvoiceLandingRoutingApproval;
    } else if (connectionStatus === 'NotConnected') { // NotConnected status
      if (targetCompanyId) {
        // Although the BE doesn't update the connection status, the presence of a
        // target company ID means the connection was made
        scenario = SecureTokenScenario.meta.map.InvoiceLanding;
        // Wiping out the available actions because this *should* be the 'i am a company'
        // and 'i wasn't connected but now am' case
        availableActions = [];
      } else {
        scenario = SecureTokenScenario.meta.map.InvoiceOnboarding;
      }
    } else {
      scenario = SecureTokenScenario.meta.map.InvoiceLanding;
    }
  } else {
    scenario = SecureTokenScenario.meta.map.InvoiceOnboarding;
  }


  return [
    createEntity(
      'current',
      SecureTokenIdentity.meta.name,
      {
        token: secureToken,
        email,
        fromCompanyId,
        invitationId,
        permissions: [],
        availableActions,
        isRegistered: isRegisteredUser,
        companyStatus,
        businessType
      }
    ),
    createEntity(
      secureToken,
      InvoiceOnboardingData.meta.name,
      {
        approvalRequests: (invoices || [])
          .map(({ approvalRequestIds }) => approvalRequestIds)
          .reduce((val, ids) => [ ...val, ...(ids || [])], []),
        hasOutOfBandInvoices,
        invoices: (invoices || [])
          .map(({ id: invoiceId, invoiceType }) => ({
            id: invoiceId,
            type: invoiceType
          })),
        scenario
      }
    )
  ];
};

const handleAchPlusInvitation = (
  token,
  { paymentCount, pendingPaymentAmount }
) => {
  const achPlusDataEntity = createEntity(
    token,
    AchPlusInvitationData.meta.name,
    { paymentCount, pendingPaymentAmount }
  );

  return [
    achPlusDataEntity
  ];
};

const getSecureTokenData = createApiAction({
  method: 'get',
  url: '/api/landing/token/:secureToken',
  queryStringMapper: ({ context }) => ({ context }),
  responseMapper: (
    {
      context,
      data,
      isValid,
      registrationStatus,
      state: dataState
    },
    state,
    {
      secureToken
    }
  ) => {
    let entities = [];
    if (isValid) {
      if (context === 'InvoiceOnboarding') {
        entities = handleInvoiceOnboardingContext(secureToken, data);
      } else if (context === 'AchPlusInvitation') {
        entities = handleAchPlusInvitation(secureToken, data);
      }
    }

    return {
      entities: [
        ...(entities || []),
        createEntity(
          secureToken,
          SecureTokenContextModel.meta.name,
          {
            token: secureToken,
            context,
            data,
            isValid,
            state: dataState
          }
        ),
        registrationStatus && isValid ? createInvitationMetadata({
          invitationId: registrationStatus.id,
          context,
          token: secureToken,
          ...registrationStatus
        }) : null
      ].filter(a => a)
    };
  }
});

export default getSecureTokenData;