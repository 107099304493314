/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { isAcknowledged, isPersonalPortal } from 'actions/common';
import { isPortalExperience } from 'actions/common/companySettings';
import { setAcknowledgement } from 'actions/settings';

import useQueryParams from 'hooks/location/useQueryParams';

import { useHasLoggedInOnThisPage } from '../utils';
import launchPortalizedModal from './PortalizedModal';

const AccountPortalizedWorkflowStep = ({
  active,
  updateState
}) => {
  // Hooks
  const dispatch = useDispatch();
  const { forceNotification } = useQueryParams([ 'forceNotification' ]);

  const doesNotHaveAck = useSelector(
    state => isAcknowledged('wasPortalized') && !isAcknowledged('shownPortalizedModal')
  );
  const loggedInThisPage = useHasLoggedInOnThisPage();
  const shouldShow = loggedInThisPage && doesNotHaveAck;

  const canShow = useSelector(state => !isPersonalPortal(state) && isPortalExperience(state));

  const willShow = canShow && (forceNotification === 'accountPortalized' || shouldShow);

  useEffect(
    () => {
      updateState(!willShow ? 'skipped' : 'loaded');
    },
    [ willShow ]
  );

  const execute = async () => {
    dispatch(setAcknowledgement('shownPortalizedModal'));
    await dispatch(launchPortalizedModal());
    updateState('completed');
  };

  useEffect(
    () => {
      if (willShow && active) {
        // useEffect doesn't like being passed an async function - hence why this broken out
        execute();
      }
    },
    [ active ]
  );

  // Render
  return <></>;
};

export default AccountPortalizedWorkflowStep;
