/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './Row.scss';

const Row = ({
  stackOn,
  addColumnGap,
  addPadding,
  className,
  style,
  children
}) => {
  const rowClassNames = classNames(
    'row',
    {'stacks-small': stackOn === 'small'},
    {'gutter': addColumnGap},
    {'pad-y': addPadding === 'y'},
    {'pad-y-half': addPadding === 'y-half'},
    {'add-space': addPadding === 'between'},
    className
  );

  return (
    <div
      className={rowClassNames}
      style={style}
    >
      {children}
    </div>
  );
};

Row.propTypes = {
  className: PropTypes.string,
  stackOn: PropTypes.oneOf(['small']),
  addColumnGap: PropTypes.bool,
  addPadding: PropTypes.oneOf(['y', 'between', 'y-half', 'none'])
};

Row.defaultProps = {
  addColumnGap: false,
  className: ''
};

export default Row;
