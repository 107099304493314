/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React from 'react';
import classNames from 'classnames';

const FlexGrid = ({
  children,
  className,
  gutter,
  style
}) => (
  <div
    className={classNames(
      'flex-grid',
      className,
      {
        gutter
      }
    )}
    style={style}
  >
    {children}
  </div>
);

export default FlexGrid;