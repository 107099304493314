/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React from 'react';
import LabelToggle from '../LabelDropdownToggle/LabelToggle';
import MobileListFilterToggle from '../MobileListFilterDropdownToggle/MobileListFilterToggle';
import generateDropdown from '../generateDropdown';

export const ListFilterToggle = (props) => {
  let { isMobile } = props;
  if (isMobile) {
    return <MobileListFilterToggle {...props}/>;
  }

  return <LabelToggle {...props} />;
};

export default generateDropdown(ListFilterToggle, { passCaretToMobileToggle: true });
