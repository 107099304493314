/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { createEntityAction } from 'api/core/utils';
import { AppEnvironmentSettings } from 'api/settings/getApplicationSettings.schemas';

import AppSessionSettings from 'schemas/app/AppSessionSettings';
import { createEntity } from 'schemas/state';

import instrumentErrorHandling from './instrumentErrorHandling';

const instrumentStore = (
  store,
  {
    csrfToken,
    inExternalTestEnvironment
  }
) => {
  if (inExternalTestEnvironment) {
    store.dispatch(createEntityAction([
      createEntity(
        'current',
        AppEnvironmentSettings.meta.name,
        {
          isExternal: inExternalTestEnvironment
        },
        null,
        {
          scope: 'global'
        }
      )
    ]));
  }

  if (csrfToken) {
    store.dispatch(createEntityAction([
      createEntity(
        'current',
        AppSessionSettings.meta.name,
        {
          csrfToken
        },
        null,
        {
          scope: 'global'
        }
      )
    ]));
  }

  instrumentErrorHandling(store);

  return store;
};

export default instrumentStore;