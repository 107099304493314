/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { VelocityTransitionGroup } from 'velocity-react';
import classNames from 'classnames';
import { getOptionsFromEnum } from '../enumUtils';
import './index.scss';

export default class BoxRadioGroup extends Component {
  static propTypes = {
    renderRadioButton: PropTypes.func,
    footerContent: PropTypes.node
  };

  static defaultProps = {
    renderRadioButton: () => null
  };

  constructor(props) {
    super(props);

    const options = getOptionsFromEnum(props.type);
    this.state = {
      selectedIndex: options.findIndex(option => option.value === props.value)
    };
    if (options.length === 1 && this.state.selectedIndex < 0) {
      props.onChange(options[0].value);
    }
  }

  componentWillReceiveProps(newProps) {
    const options = getOptionsFromEnum(newProps.type);
    this.setState({
      selectedIndex: options.findIndex(option => option.value === newProps.value)
    });
  }

  renderOptionTile(option, index) {
    const options = getOptionsFromEnum(this.props.type);
    return (
      <div key={`option-tile-${index}`}
        className={classNames('box-radio-button', {'is-selected': index === this.state.selectedIndex})}
        style={{width: `calc(100% / ${options.length})`}}
        onClick={() => this.props.onChange(option.value)}>
        { this.props.renderRadioButton(option, this.state.selectedIndex === index) }
      </div>
    );
  }

  render() {
    let options = getOptionsFromEnum(this.props.type);
    if (this.props.renderOptionLabel) {
      options.forEach((option, index) => {
        option.text = this.props.renderOptionLabel(option, index);
      });
    }

    let className = classNames(
      'box-radio-group',
      this.props.className
    );

    return (
      <div>
        <div className={className}>
          {options.map((option, index) => this.renderOptionTile(option, index))}
          {this.state.selectedIndex >= 0
            ? <div
              className={classNames('selected-box', {
                'is-first': this.state.selectedIndex === 0,
                'is-last': this.state.selectedIndex === options.length - 1
              })}
              style={{
                width: `calc(100% / ${options.length} + 2px)`,
                left: `calc(${this.state.selectedIndex} * (100% / ${options.length}) - 1px)`
              }} />
            : null}
        </div>
        <VelocityTransitionGroup
          enter={{animation: 'slideDown', easing: 'easeInOutCubic', duration: 300}}
          leave={{animation: 'slideUp', easing: 'easeInOutCubic', duration: 300}}>
          {this.props.footerContent}
        </VelocityTransitionGroup>
      </div>
    );
  }
}
