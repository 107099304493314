/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { createApiAction } from 'api/core';

export const getRemittanceList = createApiAction({
  method: 'post',
  url: '/api/settlement/remittance',
  requestBodyMapper: r => r,
  responseMapper: r => r
});

export const downloadRemittance = createApiAction({
  method: 'post',
  url: '/api/settlement/remittance/export',
  requestBodyMapper: r => r,
  responseMapper: r => r,
  downloadResponse: true
});