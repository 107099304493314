/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import getSwitchCompanies from 'api/settings/switchCompany/getSwitchCompanies';

import createProvideApiResultHooks from 'hooks/apiResult/createProvideApiResultHooks';

const GetSwitchCompaniesHooks = createProvideApiResultHooks(
  {
    endpoint: getSwitchCompanies
  },
  ({
    useEntities,
    useWillLoadOrIsLoading
  }) => {
    const useCompanies = useEntities.meta.bind({
      coalesce: true
    });

    return {
      Redux: {
        getCompanies: useCompanies.meta.selector
      },
      StatusIndicatorHooks: {
        useWillLoadOrIsLoading: useWillLoadOrIsLoading.meta.bind({
          coalesce: true
        })
      },
      useCompanies
    };
  }
);

export default GetSwitchCompaniesHooks;