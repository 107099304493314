/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import isInExternalEnvironment from 'actions/app/isInExternalEnvironment';

import { SmallBannerNotification, NotificationStyleTypes } from 'components/Notification';

import LogonFailureReason from 'config/constants/session/LogonFailureReason';
import { Sad as SadErnie } from 'config/images/ernie';
import CommonMessages from 'config/messages/common';

import AccessDenied from 'containers/Logon/AccessDenied';
import VerifyAccount from 'containers/Registration/VerifyAccount';

import usePathname from 'hooks/location/usePathname';
import useIsCapabilityEnabled from 'hooks/settings/useIsCapabilityEnabled';

import InitiateResetPassword from './InitiateResetPassword';
import LoginSecurityQuestionPrompt from './SecurityQuestionPrompt';
import TwoFactorPrompt from './TwoFactorPrompt';
import CredentialsForm from './CredentialsForm';
import KickExistingSession from './KickExistingSession';
import Messages, { TextMessages } from './index.messages';
import ClosedCompanyLogon, { LockTypes } from './ClosedCompanyLogon';

const LogonWorkflow = ({
  changeEmailToken,
  ignoreWipe,
  invitationId,
  logonAlert,
  redirect,
  resetPasswordMethod,
  showResetPassword: showResetPasswordProp,
  variant
}) => {
  // Hooks
  const [
    {
      credentialsFailure,
      encryptedUserId,
      failure,
      securityQuestionFailure,
      securityQuestions,
      twoFactorFailure,
      twoFactorPhoneNumber,
      userName
    },
    setLogonResult
  ] = useState({});
  const pathname = usePathname();

  const inExternalTestEnvironment = useSelector(
    state => isInExternalEnvironment(state)
  );

  const canRequestReactivateAccount = useIsCapabilityEnabled('ReactivateAccountRequest');

  const [ showResetPassword, setShowResetPassword ] = useState(showResetPasswordProp);

  const onInlineShowResetPassword = useMemo(() => () => setShowResetPassword(true), []);
  const onInlineCancelResetPassword = useMemo(() => () => setShowResetPassword(false), []);
  const onAccessDeniedCompleted = useMemo(() => () => setLogonResult({}), []);

  const onUpdate = useMemo(
    () => (newLogonResult, replace) => {
      if (!newLogonResult) return;

      if (replace) {
        setLogonResult(newLogonResult);
        return;
      }

      setLogonResult(
        prevValue => ({
          ...prevValue,
          ...newLogonResult,
          userName: newLogonResult?.userName === false
            ? null
            : newLogonResult?.userName || prevValue.userName
        })
      );
    },
    []
  );

  useEffect(
    () => {
      setShowResetPassword(showResetPasswordProp);
      setLogonResult({});
    },
    [ showResetPasswordProp ]
  );

  // If the path changes, clear out the state
  useEffect(
    () => {
      setLogonResult({});
    },
    [ pathname ]
  );

  const LogonAccessDenied = useMemo(
    () => props => (
      <AccessDenied
        onComplete={onAccessDeniedCompleted}
        submitMessage={<CommonMessages.Return.Message />}
        {...props}
      />
    ),
    []
  );

  // Render
  if (showResetPassword) {
    return (
      <InitiateResetPassword
        onComplete={resetPasswordMethod === 'inline'
          ? onInlineCancelResetPassword
          : null}
      />
    );
  }

  if (failure === LogonFailureReason.PermanentlyLocked) {
    return (
      <LogonAccessDenied>
        <Messages.UserLockedInstructions.Message
          contact={<CommonMessages.CustomerSupportEmail.Message/>}
        />
      </LogonAccessDenied>
    );
  }

  if (failure === LogonFailureReason.CompanyLocked) {
    return canRequestReactivateAccount ? (
      <ClosedCompanyLogon
        onUpdate={onUpdate}
        type={LockTypes.CompanyLocked}
        userName={userName}
      />
    ) : (
      <LogonAccessDenied>
        <Messages.Text.AccessDenied.Message />
      </LogonAccessDenied>
    );
  }

  if (failure === LogonFailureReason.UserInactive) {
    return (
      <LogonAccessDenied>
        <Messages.Text.UserInactive.Message />
      </LogonAccessDenied>
    );
  }

  if (failure === LogonFailureReason.TemporarilyLocked) {
    return (
      <LogonAccessDenied>
        <Messages.Text.TemporaryAccessDenied.Message />
      </LogonAccessDenied>
    );
  }

  if (failure === LogonFailureReason.ChangeEmailTokenInvalid) {
    // Return the user back to the root logon page with nothing in the URL, now
    // that we know the token is bad
    return (
      <LogonAccessDenied onComplete={null}>
        <Messages.Text.VerifyChangeEmailInvalid.Message />
      </LogonAccessDenied>
    );
  }

  if (failure === LogonFailureReason.ExistingSession) {
    return (
      <KickExistingSession onUpdate={onUpdate} />
    );
  }

  if (failure === LogonFailureReason.TwoFactorRequired) {
    return (
      <TwoFactorPrompt
        changeEmailToken={changeEmailToken}
        error={twoFactorFailure}
        onUpdate={onUpdate}
        phoneNumber={twoFactorPhoneNumber}
        userName={userName}
      />
    );
  }

  if (failure === LogonFailureReason.SecurityQuestionAuthRequired) {
    return (
      <LoginSecurityQuestionPrompt
        error={securityQuestionFailure}
        onUpdate={onUpdate}
        securityQuestions={securityQuestions}
      />
    );
  }

  if (failure === LogonFailureReason.CompanyClosed) {
    return (
      canRequestReactivateAccount ? (
        <ClosedCompanyLogon
          onUpdate={onUpdate}
          type={LockTypes.CompanyClosed}
          userName={userName}
        />
      ) : (
        <LogonAccessDenied ernieImage={SadErnie}>
          <Messages.Text.CompanyClose.Message />
        </LogonAccessDenied>
      )
    );
  }

  if (failure === LogonFailureReason.EmailVerificationRequired) {
    return (
      <VerifyAccount
        encryptedUserId={encryptedUserId}
        onComplete={onAccessDeniedCompleted}
      />
    );
  }

  return (
    <div>
      {inExternalTestEnvironment ? (
        <SmallBannerNotification type={NotificationStyleTypes.Warning} className="margin-8-bottom">
          <TextMessages.TestEnvDisclaimer.Message />
        </SmallBannerNotification>
      ) : null}
      {invitationId ? (
        <SmallBannerNotification type={NotificationStyleTypes.Info} className="margin-8-bottom">
          <Messages.AssociateInvitation.Message />
        </SmallBannerNotification>
      ) : null}
      {logonAlert}
      <CredentialsForm
        changeEmailToken={changeEmailToken}
        credentialsFailure={credentialsFailure}
        failure={failure}
        ignoreWipe={ignoreWipe}
        invitationId={invitationId}
        onUpdate={onUpdate}
        redirect={redirect}
        resetPasswordMethod={resetPasswordMethod === 'inline'
          ? onInlineShowResetPassword
          : resetPasswordMethod}
        variant={variant == null ? 'default' : variant }
      />
    </div>
  );
};

export default LogonWorkflow;
