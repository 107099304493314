/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React from 'react';
import generateDropdown from '../generateDropdown';

export default generateDropdown(({
  onClick,
  toggle,
  children
}) => {
  return (
    <div onClick={onClick}>
      {toggle}
      {children}
    </div>
  );
}, {
  caretComponent: () => <span/>,
  useToggleForPopover: true
});
