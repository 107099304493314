/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { isFailAction, isFetchAction, isSuccessAction } from 'api/core/utils';
import { CorrelationId } from 'config/constants/settings';
import { CompanyUser } from 'schemas/settings/user';
import { newState, isNukeStateAction } from './utils';

let getBankAccounts = {
  type: 'getBankAccounts',
  handler: (data, canAddPaymentAccount) => {
    return {
      canAddPaymentAccount,
      bankAccounts: data,
      isLoading: false
    };
  }
};

const paymentMethodsActions = [ getBankAccounts ];

export function PaymentMethods(state = {}, action) {

  let actionHandler = paymentMethodsActions.find((actionType) => {
    return isSuccessAction(action, actionType.type);
  });

  if (actionHandler) {
    return newState(state, actionHandler.handler(action.payload.references, action.payload.canAddPaymentAccount));
  }

  if (isFetchAction(action, 'getBankAccount')) {
    return newState(state, {
      isEditLoading: true
    });
  }

  if (isFailAction(action, 'getBankAccount')) {
    return newState(state, {
      isEditLoading: false
    });
  }

  if (isSuccessAction(action, 'getBankAccount')) {
    return newState(state, {
      isEditLoading: false
    });
  }

  if (isSuccessAction(action, 'checkPaymentToBeSent')) {
    return newState(state, {
      paymentsToBeSent: action.payload.entities,
      paymentsToBeSentTotal: action.payload.total
    });
  }

  if (isSuccessAction(action, 'getCompanyUsers')) {

    if (action.payload.entities.length && action.payload.entities[0].value) {
      let users = action.payload.entities[0].value.users;
      // Show the secondary signer when there is more than 1 user that has permissions
      // to SignCheck, or they have admin permissions
      let usersWithSignPermission = users.filter((user) => {
        return user.groups.find((group) => {
          return (group.permissions === null || group.permissions.includes('SignCheck'));
        });
      });

      return newState(state, {
        showSecondarySigner: usersWithSignPermission.length > 1
      });
    }
    return newState(state, {
      showSecondarySigner: false
    });
  }

  if (isFetchAction(action, 'getCompanyProfile')) {
    return newState(state, {
      isCompanyProfileLoading: true
    });
  }

  if (isFailAction(action, 'getCompanyProfile')) {
    return newState(state, {
      isCompanyProfileLoading: false
    });
  }

  if (isSuccessAction(action, 'getCompanyProfile')) {
    return newState(state, {
      isCompanyProfileLoading: false,
      companyProfile: action.payload.references[0]
    });
  }

  return state;
}

export function ModifyDefaults(state = {}, action) {

  if (isSuccessAction(action, 'getBankAccounts')) {
    return newState(state, {
      bankAccounts: action.payload.references
    });
  }

  return state;
}

export function DiscountSettings(state = {}, action) {
  if (action.type === 'DiscountSettings_formChange') {
    return newState(state, {
      newStrategyFormState: action.formState
    });
  }

  if (action.type === 'DiscountSettings_createDiscountStrategy') {
    return newState(state, {
      saveButtonClickedToggle: !state.saveButtonClickedToggle
    });
  }

  if (action.type === 'DiscountSettings_setNewStrategyVendorId') {
    return newState(state, {
      newStrategyVendorIds: action.vendorIds
    });
  }

  if (action.type === 'DiscountSettings_setNewStrategyFlow') {
    return newState(state, {
      isNewStrategyFlow: action.isNewStrategyFlow
    });
  }

  if (action.type === 'DiscountSettings_setSelectedStrategyId') {
    return newState(state, {
      selectedStrategyId: action.selectedStrategyId
    });
  }

  if (isSuccessAction(action, 'getDiscountRateFromReturn')) {
    return newState(state, {
      dailyDiscountRate: action.payload.dailyDiscountRate
    });
  }

  if (isFetchAction(action, 'getDiscountStrategies')) {
    return newState(state, {
      isStrategiesLoading: true
    });
  }

  if (isFailAction(action, 'getDiscountStrategies')) {
    return newState(state, {
      isStrategiesLoading: false
    });
  }

  if (isSuccessAction(action, 'getDiscountStrategies')) {
    return newState(state, {
      isStrategiesLoading: false,
      strategies: action.payload.references
    });
  }

  if (isFetchAction(action, 'getVendorDiscountStrategyList')) {
    return newState(state, {
      isVendorsLoading: true
    });
  }

  if (isFailAction(action, 'getVendorDiscountStrategyList')) {
    return newState(state, {
      isVendorsLoading: false
    });
  }

  if (isSuccessAction(action, 'getVendorDiscountStrategyList')) {
    return newState(state, {
      isVendorsLoading: false,
      vendorList: action.payload.references,
      pagination: action.payload.pagination
    });
  }

  if (isFetchAction(action, 'getAutoAcceptDiscountSettings')) {
    return newState(state, {
      isLoading: true
    });
  }

  if (isFailAction(action, 'getAutoAcceptDiscountSettings')) {
    return newState(state, {
      isLoading: false
    });
  }

  if (isSuccessAction(action, 'getAutoAcceptDiscountSettings')) {
    return newState(state, {
      autoAcceptSettings: action.payload.references[0],
      isLoading: false
    });
  }

  if (isFetchAction(action, 'getDiscountReturnFromRate')) {
    if (action.meta.correlationId === 'LIGHT_DISCOUNT_PERCENT') {
      return newState(state, {
        isLightLoading: true
      });
    } if (action.meta.correlationId === 'STANDARD_DISCOUNT_PERCENT') {
      return newState(state, {
        isStandardLoading: true
      });
    } if (action.meta.correlationId === 'AGGRESSIVE_DISCOUNT_PERCENT') {
      return newState(state, {
        isAggressiveLoading: true
      });
    }
  }

  if (isFailAction(action, 'getDiscountReturnFromRate')) {
    if (action.meta.correlationId === 'LIGHT_DISCOUNT_PERCENT') {
      return newState(state, {
        isLightLoading: false
      });
    } if (action.meta.correlationId === 'STANDARD_DISCOUNT_PERCENT') {
      return newState(state, {
        isStandardLoading: false
      });
    } if (action.meta.correlationId === 'AGGRESSIVE_DISCOUNT_PERCENT') {
      return newState(state, {
        isAggressiveLoading: false
      });
    }
  }

  if (isSuccessAction(action, 'getDiscountReturnFromRate')) {
    if (action.meta.correlationId === 'LIGHT_DISCOUNT_PERCENT') {
      return newState(state, {
        isLightLoading: false,
        lightReturn: action.payload.returnPercent
      });
    } if (action.meta.correlationId === 'STANDARD_DISCOUNT_PERCENT') {
      return newState(state, {
        isStandardLoading: false,
        standardReturn: action.payload.returnPercent
      });
    } if (action.meta.correlationId === 'AGGRESSIVE_DISCOUNT_PERCENT') {
      return newState(state, {
        isAggressiveLoading: false,
        aggressiveReturn: action.payload.returnPercent
      });
    }
  }

  if (isNukeStateAction(action, 'DiscountSettings')) {
    return {};
  }

  return state;
}

export function GroupsPage(state = {}, action) {
  if (isFetchAction(action, 'getCompanyGroups')) {
    return newState(state, {
      isLoading: true
    });
  }

  if (isSuccessAction(action, 'getCompanyGroups')) {
    return newState(state, {
      isLoading: false,
      groups: action.payload.references
    });
  }

  if (isFetchAction(action, 'getCompanyUsers')) {
    return newState(state, {
      usersLoading: true
    });
  }

  if (isFailAction(action, 'getCompanyUsers')) {
    return newState(state, {
      usersLoading: false
    });
  }

  if (isSuccessAction(action, 'getCompanyUsers')) {
    return newState(state, {
      users: action.payload.references,
      usersLoading: false
    });
  }

  if (isFetchAction(action, 'getPermissionsForGroup')) {
    return newState(state, {
      editPermissionsLoading: true
    });
  }

  if (isFailAction(action, 'getPermissionsForGroup')) {
    return newState(state, {
      editPermissionsLoading: false
    });
  }

  if (isSuccessAction(action, 'getPermissionsForGroup')) {
    return newState(state, {
      editPermissionsLoading: false,
      editGroupDetails: action.payload.references[0]
    });
  }

  if (isFetchAction(action, 'getUsersForGroup')) {
    return newState(state, {
      editUsersLoading: true
    });
  }

  if (isFailAction(action, 'getUsersForGroup')) {
    return newState(state, {
      editUsersLoading: false
    });
  }

  if (isSuccessAction(action, 'getUsersForGroup')) {
    return newState(state, {
      editUsersLoading: false,
      editUsers: action.payload.users
    });
  }

  return state;
}

export function UsersPage(state = {}, action) {
  if (isFetchAction(action, 'getCompanyGroups')) {
    return newState(state, {
      isGroupsLoading: true
    });
  }

  if (isSuccessAction(action, 'getCompanyGroups')) {
    return newState(state, {
      isGroupsLoading: false,
      groups: action.payload.references
    });
  }

  if (isFetchAction(action, 'getCompanyUsers')) {
    return newState(state, {
      isLoading: true
    });
  }

  if (isSuccessAction(action, 'getCompanyUsers')) {
    return newState(state, {
      isLoading: false,
      users: action.payload.entities
        .filter(val => val.type === CompanyUser.meta.name)
        .map(val => val.createReference())
    });
  }

  return state;
}

export function AddEditUsersPage(state = {}, action) {
  if (isFetchAction(action, 'getAvailableGroups')) {
    return newState(state, {
      isGroupsLoading: true
    });
  }

  if (isFailAction(action, 'getAvailableGroups')) {
    return newState(state, {
      isGroupsLoading: false
    });
  }

  if (isSuccessAction(action, 'getAvailableGroups')) {
    return newState(state, {
      isGroupsLoading: false,
      groups: action.payload.groups
    });
  }

  if (isSuccessAction(action, 'getPermissionsForMultipleGroups')) {
    return newState(state, {
      selectedPermissions: action.payload.permissions
    });
  }

  if (isFetchAction(action, 'getUserDetailsForEdit')) {
    return newState(state, {
      isEditDetailsLoading: true
    });
  }

  if (isFailAction(action, 'getUserDetailsForEdit')) {
    return newState(state, {
      isEditDetailsLoading: false
    });
  }

  if (isSuccessAction(action, 'getUserDetailsForEdit')) {
    return newState(state, {
      isEditDetailsLoading: false,
      editDetails: action.payload.response
    });
  }

  return state;
}

export function AccountSettingsPage(state = {}, action) {
  if (isSuccessAction(action, 'getUserProfile')) {
    return newState(state, {
      hasPendingEmailChange: action.payload.hasPendingEmailChange,
      pendingEmailAddress: action.payload.pendingEmailAddress,
      securityQuestions: action.payload.securityQuestions,
      userSecurityQuestionIds: action.payload.userSecurityQuestionIds,
      passwordLastChanged: action.payload.passwordLastChanged,
      createdDate: action.payload.createdDate,
      hasPasswordChanged: action.payload.hasPasswordChanged
    });
  }

  if (isSuccessAction(action, 'getTwoFactorAuthentication')) {
    return newState(state, {
      isTwoFactorEnabled: action.payload.isTwoFactorEnabled,
      isVerificationPending: action.payload.isVerificationPending,
      phoneNumber: action.payload.phoneNumber,
      saveTwoStepSuccess: false,
      canDisableTwoFactor: action.payload.canDisableTwoFactor
    });
  }

  if (isFetchAction(action, 'createTwoFactorAuthentication')) {
    return newState(state, {
      isVerificationPending: true,
      saveTwoStepSuccess: null,
      isPhoneNumberLocked: null,
      skipValidation: null
    });
  }

  if (isSuccessAction(action, 'createTwoFactorAuthentication')) {
    return newState(state, {
      saveTwoStepSuccess: true,
      isPhoneNumberLocked: action.payload.isPhoneNumberLocked,
      skipValidation: action.payload.skipValidation
    });
  }

  if (isFetchAction(action, 'updateCompanyProfile')) {
    return newState(state, {
      phone: action.payload.params.phone
    });
  }

  if (action.type === 'ResetTwoFactor') {
    return newState(state, {
      isVerificationPending: false
    });
  }

  return state;
}

export function EmailPreferencesPage(state = {}, action) {
  if (isFetchAction(action, 'getEmailPreferences')) {
    return newState(state, {
      isLoading: true
    });
  }

  if (isFailAction(action, 'getEmailPreferences')) {
    return newState(state, {
      isLoading: false
    });
  }

  if (isSuccessAction(action, 'getEmailPreferences')) {
    return newState(state, {
      emailPreferenceReferences: action.payload.references,
      emailPreferences: action.payload.emailPreferences,
      isLoading: false
    });
  }

  return state;
}

export function CompanyProfilePage(state = {}, action) {
  if (isFetchAction(action, 'getCompanyProfile')) {
    return newState(state, {
      isProfileLoading: true
    });
  }

  if (isFailAction(action, 'getCompanyProfile')) {
    return newState(state, {
      isProfileLoading: false
    });
  }

  if (isSuccessAction(action, 'getCompanyProfile')) {
    return newState(state, {
      isProfileLoading: false,
      companyProfile: action.payload.references && action.payload.references.length
        ? action.payload.references[0] : null
    });
  }

  if (isSuccessAction(action, 'getInboxEmailSuggestion')) {
    return newState(state, {
      emailDomain: action.payload.emailDomain,
      suggestedEmailPrefix: action.payload.suggestedEmailPrefix
    });
  }

  if (isSuccessAction(action, 'getBankAccounts')) {
    return newState(state, {
      hasBankAccount: !!action.payload.references.length
    });
  }

  return state;
}

export function ReportsPage(state = {}, action) {
  if (isSuccessAction(action, 'getReports')) {
    return newState(state, {
      reports: action.payload.references
    });
  }

  return state;
}

export function AddCheckingAccount(state = {}, action) {
  if (isSuccessAction(action, 'getBankAccounts')) {
    return newState(state, {
      bankAccounts: action.payload.references
    });
  }

  if (isFetchAction(action, 'getCompanyProfile')
    && action.meta.correlationId === CorrelationId.AddCheckingAccount) {
    return newState(state, {
      companyProfile: null,
      isProfileLoading: true
    });
  }

  if (isFailAction(action, 'getCompanyProfile')
    && action.meta.correlationId === CorrelationId.AddCheckingAccount) {
    return newState(state, {
      isProfileLoading: false
    });
  }

  if (isSuccessAction(action, 'getCompanyProfile')
    && action.meta.correlationId === CorrelationId.AddCheckingAccount) {
    return newState(state, {
      companyProfile: action.payload.references[0],
      isProfileLoading: false
    });
  }

  if (isFetchAction(action, 'getBankAccount')
    && action.meta.correlationId === CorrelationId.AddCheckingAccount) {
    return newState(state, {
      bankAccount: null,
      isAccountLoading: true
    });
  }

  if (isFailAction(action, 'getBankAccount')
    && action.meta.correlationId === CorrelationId.AddCheckingAccount) {
    return newState(state, {
      isAccountLoading: false
    });
  }

  if (isSuccessAction(action, 'getBankAccount')
    && action.meta.correlationId === CorrelationId.AddCheckingAccount) {
    return newState(state, {
      bankAccount: action.payload.references[0],
      isAccountLoading: false
    });
  }

  if (action.type === 'AddCheckingAccount.clearState'
    || isSuccessAction(action, 'logout')) {
    return newState(state, {
      companyProfile: null,
      bankAccount: null
    });
  }

  if (isFetchAction(action, 'exchangePlaidToken')) {
    return newState(state, {
      plaidAccessTokenId: null
    });
  }

  if (isSuccessAction(action, 'exchangePlaidToken')) {
    return newState(state, {
      plaidAccessTokenId: action.payload.response.accessTokenId
    });
  }

  if (isFetchAction(action, 'retrievePlaidAccounts')) {
    return newState(state, {
      plaidAccounts: null,
      isPlaidLoading: true,
      plaidError: false
    });
  }

  if (isFailAction(action, 'retrievePlaidAccounts')) {
    return newState(state, {
      isPlaidLoading: false,
      plaidError: true
    });
  }

  if (isSuccessAction(action, 'retrievePlaidAccounts')) {
    return newState(state, {
      plaidAccounts: action.payload.response.accountsData,
      isPlaidLoading: false,
      plaidError: action.payload.response.error
    });
  }

  return state;
}

export function APInboxSettings(state = {}, action = {}) {

  if (isSuccessAction(action, 'getInboxEmailSuggestion')) {
    return newState(state, {
      emailDomain: action.payload.emailDomain,
      suggestedEmailPrefix: action.payload.suggestedEmailPrefix
    });
  }

  return state;
}

export function VerifyPaymentMethod(state = {}, action) {
  if (isSuccessAction(action, 'getBankAccount')) {
    return newState(state, {
      bankAccount: action.payload.references[0]
    });
  }

  return state;
}

export function SignWithMobile(state = {}, action) {
  if (isFetchAction(action, 'loadDocumentsFromToken')) {
    return newState(state, {
      isLoading: true,
      documents: null
    });
  }

  if (isFailAction(action, 'loadDocumentsFromToken')) {
    return newState(state, {
      isLoading: false,
      documents: []
    });
  }

  if (isSuccessAction(action, 'loadDocumentsFromToken')) {
    return newState(state, {
      isLoading: false,
      documents: action.payload.documents
    });
  }

  return state;
}
