/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import t from 'tcomb-validation';
import { defineSchema } from 'schemas';
import { EntityReference } from 'schemas/state';
import {
  InvoiceActions,
  BillViewActions,
  InvoiceViewActions,
  SettingsActions,
  SettingsViewActions,
  SyncActions
} from 'config/constants';
import PaymentActions from 'config/constants/PaymentActions';
import PaymentViewActions from 'config/constants/PaymentViewActions';
import {
  BusinessType,
  CompanyStatus,
  KnowledgeAuthenticationRequired,
  CompanyAccountType
} from 'config/constants/session';
import { getAllAvailableActionsList } from 'config/constants/actionsRegistry';
import { AccountingType } from 'config/constants/settings';
import { OnboardingActions } from 'config/constants/onboarding';
import EntityContext from 'config/constants/schemas/EntityContext';

const UserPermissions = t.struct({
  approveInvoices: t.Boolean,
  canInvite: t.Boolean,
  createCheck: t.Boolean,
  manageConnections: t.Boolean,
  manageContacts: t.Boolean,
  mapGLAccounts: t.Boolean,
  modifyBills: t.Boolean,
  modifyCompanySettings: t.Boolean,
  modifyDiscountTables: t.Boolean,
  modifyInvoices: t.Boolean,
  modifyUsersAndGroups: t.Boolean,
  modifyVendorCreditMemos: t.Boolean,
  overrideApprovalWorkflows: t.Boolean,
  reactToDisputes: t.Boolean,
  recordPayments: t.Boolean,
  requestEarlyPayment: t.Boolean,
  signCheck: t.Boolean,
  unvoidChecks: t.Boolean,
  unvoidInvoices: t.Boolean,
  view: t.Boolean,
  viewFees: t.Boolean,
  voidChecks: t.Boolean,
  voidInvoices: t.Boolean
}, 'UserPermissions');

const KnowledgeAuthenticationRequiredEnum = t.enums(KnowledgeAuthenticationRequired);

const AccountingTypeEnum = t.enums(AccountingType);

const CompanySettings = t.struct({
  autoApplyPayments: t.Boolean,
  vccAllowPayViaFallback: t.maybe(t.Boolean),
  annualUpdateReminder: t.maybe(t.Boolean),
  multistepApprovals: t.maybe(t.Boolean),
  needsBeneficialOwnerInfo: t.maybe(t.Boolean),
  hasBeneficialOwnerInfo: t.maybe(t.Boolean),
  suggestCompany: t.Boolean,
  qboEnabled: t.Boolean,
  canEnrollConsumerPayments: t.Boolean,
  canPurchaseInvoiceInsurance: t.Boolean,
  canReceiveConsumerPayments: t.Boolean,
  hasOrgHierarchy: t.Boolean,
  hasTaxPayerIdentificationNumber: t.Boolean,
  needsAddressBookLinkOnConnect: t.Boolean,
  needsErpLinkOnConnect: t.Boolean,
  vendorPaysFeesForNewAddressBooks: t.Boolean,
  accountingType: t.maybe(AccountingTypeEnum),
  kbaRequired: t.maybe(KnowledgeAuthenticationRequiredEnum),
  twoStepPaymentLimit: t.Number,
  accountType: t.enums(CompanyAccountType),
  totalVendors: t.Number, // How many people you are paying
  totalCustomers: t.Number, // How many people are paying you
  vendorsAllowCreateInvoice: t.Boolean,
  hasMoreThanOneUser: t.Boolean,
  hasSubscriptionPaymentMethod: t.Boolean,
  isInSubscriptionTrial: t.Boolean,
  registrationSource: t.maybe(t.String),
  paymentOptimization: t.struct({
    vccEnabled: t.Boolean,
    achPlusEnabled: t.Boolean
  }),
  nextBillingDate: t.maybe(t.Date),
  delinquencyDate: t.maybe(t.Date),
  hasCredentials: t.Boolean,
  campaigns: t.list(t.struct({
    startDate: t.Date,
    outcome: t.String,
    id: t.String,
    type: t.String
  })),
  additionalSettings: t.dict(t.String, t.Any)
}, 'CompanySettings');

const UserSettings = t.struct({
  displayCashflowGraph: t.Boolean,
  hideGettingStartedWizard: t.Boolean,
  oneClickPayEnabled: t.Boolean,
  oneClickPaymentMethodId: t.maybe(t.String),
  oneClickPaymentTiming: t.maybe(t.String),
  additionalSettings: t.dict(t.String, t.Any)
}, 'UserSettings');

const CompanyUserSettings = t.struct({
  additionalSettings: t.dict(t.String, t.Any)
}, 'CompanyUserSettings');

function getArrayOfObjectValues(obj) {
  return Object.keys(obj).map(key => obj[key]);
}

// Move these to the actionRegistry
const legacyPossibleActions = getArrayOfObjectValues(InvoiceActions)
  .concat(getArrayOfObjectValues(BillViewActions))
  .concat(getArrayOfObjectValues(InvoiceViewActions))
  .concat(getArrayOfObjectValues(PaymentActions))
  .concat(getArrayOfObjectValues(PaymentViewActions))
  .concat(getArrayOfObjectValues(SettingsActions))
  .concat(getArrayOfObjectValues(SettingsViewActions))
  .concat(getArrayOfObjectValues(SyncActions));

// Until everything is in the actionRegistry, this will provide all
// available actions that the identity accepts
export const GetIdentityAvailableActions = () => [
  ...legacyPossibleActions,
  ...getAllAvailableActionsList()
];

export const IdentityActions = t.refinement(t.String, action => legacyPossibleActions.includes(action)
    || getAllAvailableActionsList().includes(action)
);

const CompanyStatusEnum = t.enums(CompanyStatus);

const BusinessTypeEnum = t.enums(BusinessType);

const BaseIdentity = {
  isFullSession: t.Boolean,
  isOumc: t.maybe(t.Boolean),
  isOumcCrossCompanyDataEnabled: t.maybe(t.Boolean),
  associatedCompaniesCount: t.maybe(t.Number),
  userId: t.String,
  firstName: t.String,
  lastName: t.String,
  phone: t.maybe(t.String),
  email: t.String,
  companyId: t.String,
  companyName: t.String,
  accountingPackage: t.String,
  permissions: UserPermissions,
  availableActions: t.list(IdentityActions),
  viewpostBillingCompanyId: t.String,
  isAccountSecure: t.Boolean,
  isTwoFactorSecured: t.Boolean,
  twoFactorSecuredRequirementDisabled: t.Boolean,
  termsAccepted: t.maybe(t.list(t.struct({
    type: t.String,
    version: t.String,
    acceptedLatest: t.Boolean
  }))),
  needsAccountSecure: t.Boolean,
  userSettings: UserSettings,
  companySettings: CompanySettings,
  companyUserSettings: CompanyUserSettings,
  createdDateTime: t.maybe(t.Date),
  status: t.maybe(CompanyStatusEnum),
  businessType: t.maybe(BusinessTypeEnum),
  hasCompleteAddress: t.maybe(t.Boolean),
  knowledgeAuthenticationRequired: t.maybe(KnowledgeAuthenticationRequiredEnum),
  paymentAccounts: t.maybe(t.list(EntityReference)),
  timestamp: t.Date,
  nextSetupSteps: t.maybe(t.list(t.String))
};

export const Identity = defineSchema(
  BaseIdentity,
  'Identity',
  { contexts: [ EntityContext.Identity ] }
);

export const PartialIdentity = defineSchema({
  firstName: t.maybe(BaseIdentity.firstName),
  lastName: t.maybe(BaseIdentity.lastName),
  companyName: t.maybe(BaseIdentity.companyName),
  email: t.maybe(BaseIdentity.email),
  status: t.maybe(BaseIdentity.status),
  userId: t.maybe(BaseIdentity.userId),
  companyId: t.maybe(BaseIdentity.companyId)
}, 'PartialIdentity');

export const PersonalIdentity = defineSchema({
  ...BaseIdentity,
  firstName: t.maybe(t.String),
  lastName: t.maybe(t.String),
  companyName: t.maybe(t.String),
  email: t.maybe(t.String)
}, 'PersonalIdentity');

export const IdentityTypes = [
  Identity.meta.name,
  PersonalIdentity.meta.name
];

export const SecureTokenIdentity = defineSchema({
  token: t.String,
  email: t.maybe(t.String), // recipient email
  fromCompanyId: t.maybe(t.String), // sender company
  invitationId: t.maybe(t.String),
  permissions: t.list(UserPermissions),
  availableActions: t.list(t.enums.of(getArrayOfObjectValues(OnboardingActions))),
  isRegistered: t.maybe(t.Boolean),
  companyStatus: t.maybe(CompanyStatusEnum),
  businessType: t.maybe(BusinessTypeEnum)
}, 'SecureTokenIdentity');

export const CreatableSession = defineSchema({
  username: t.String,
  password: t.String
}, 'CreatableSession');

const ApiFatalErrorType = t.enums({
  'NotAuthorized': 'NotAuthorized',
  'NotFound': 'NotFound',
  'ServerError': 'ServerError'
});

export const ApiFatalError = defineSchema({
  type: ApiFatalErrorType
}, 'ApiFatalError');

export const CompanyFeeSettings = defineSchema({
  paperCheck: t.Number,
  digitalCheck: t.Number,
  monthlySubscriptionFee: t.maybe(t.Number),
  isAdvanced: t.maybe(t.Boolean)
}, 'CompanyFeeSettings');
