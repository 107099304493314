/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import createApiActions, { createApiAction } from 'api/core';
import { queryParamNameMapper } from 'api/common/mappers';
import { textMapper } from 'api/common/mappers/textMapper';
import patchIdentity from 'api/session/mappers/patchIdentity';
import { PaymentDataMethodOptions } from 'api/payments/quickPay.schemas';

import ConnectionStatusType from 'config/constants/network/ConnectionStatus';
import EntityContext from 'config/constants/schemas/EntityContext';

import { createEntity } from 'schemas/state';
import CompanyOrganizationNode from 'schemas/network/CompanyOrganizationNode';
import NetworkProfileDetails from 'schemas/network/NetworkProfileDetails';
import { ConnectionRequest } from 'schemas/network/connectionRequest';
import {
  updateAddressBookRequestBodyMapper,
  updateAddressBookEmailRequestBodyMapper,
  updateAddressBookAddressRequestBodyMapper,
  updateAddressBookLinksRequestBodyMapper
} from './mappers/addressBookMappers';
import {
  getConnectionRequestsResponseMapper,
  updateConnectionRequestBodyMapper,
  getLinkAddressBookResponseMapper
} from './mappers/connectionRequestMappers';
import {
  getCompanyResponseMapper,
  updateCompanyRequestBodyMapper,
  createCompanyRequestBodyMapper,
  updateBeneficialOwnerInfoRequestMapper
} from './mappers/companyMappers';
import { ContactDetails } from './getRelationshipDetailsV2/index.schemas';
import { NetworkListEntry } from './queryRelationships/index.schemas';

export const updateTaxpayerId = createApiAction({
  acceptEmptyResponse: true,
  method: 'patch',
  url: '/api/company-profiles/current/taxpayerIdNumber',
  requestBodyMapper: textMapper,
  responseMapper: (taxpayerIdNumber, state) => ({
    entities: [
      patchIdentity(
        {
          companySettings: {
            hasTaxPayerIdentificationNumber: true
          }
        },
        state
      )
    ]
  })
});

export const getCountries = createApiAction({
  method: 'get',
  url: '/api/countries',
  responseMapper: r => r
});

export const addWesternUnionPayeeAccount = createApiAction({
  method: 'post',
  url: '/api/bank-accounts/add-westernunion-payee-account',
  requestBodyMapper: ({id, routingNumber, accountNumber}) => {
    return {
      AddressBookId: id,
      RoutingNumber: routingNumber,
      AccountNumber: accountNumber
    };
  },
  markUpdated: {
    entityTypes: [
      NetworkProfileDetails.meta.name
    ]
  }
});

export default createApiActions({
  updateCompany: {
    method: 'patch',
    url: '/api/companies/:id',
    acceptEmptyResponse: true,
    requestBodyMapper: updateCompanyRequestBodyMapper,
    responseMapper: getCompanyResponseMapper,
    markUpdated: {
      entityTypes: [
        NetworkProfileDetails.meta.name,
        NetworkListEntry.meta.name,
        ContactDetails.meta.name
      ]
    }
  },
  deleteAddressBook: {
    method: 'delete',
    url: '/api/addressBooks/:id',
    markUpdated: {
      entityTypes: [
        NetworkListEntry.meta.name
      ]
    }
  },
  linkAddressBook: {
    method: 'post',
    url: '/api/companies',
    requestBodyMapper: ({
      addressBookId,
      companyId,
      relationship
    }) => ({
      id: companyId,
      status: ConnectionStatusType.Connecting,
      relationships: {
        [relationship.toLowerCase()]: {
          id: addressBookId
        }
      }
    }),
    responseMapper: getCompanyResponseMapper,
    markUpdated: {
      entityTypes: [
        NetworkProfileDetails.meta.name,
        NetworkListEntry.meta.name
      ]
    }
  },
  createAddressBook: {
    method: 'post',
    url: '/api/companies',
    requestBodyMapper: createCompanyRequestBodyMapper,
    responseMapper: getCompanyResponseMapper,
    markUpdated: {
      entityTypes: [
        NetworkListEntry.meta.name
      ]
    }
  },
  updateAddressBook: {
    method: 'put',
    url: '/api/addressBooks/:id',
    requestBodyMapper: updateAddressBookRequestBodyMapper,
    markUpdated: {
      entityTypes: [
        NetworkProfileDetails.meta.name,
        NetworkListEntry.meta.name,
        ContactDetails.meta.name
      ]
    }
  },
  updateAddressBookAddress: {
    method: 'patch',
    url: '/api/addressBooks/:id',
    requestBodyMapper: updateAddressBookAddressRequestBodyMapper,
    markUpdated: {
      entityTypes:
      [
        NetworkListEntry.meta.name
      ]
    }
  },
  updateAddressBookEmail: {
    method: 'patch',
    url: '/api/addressBooks/:id',
    requestBodyMapper: updateAddressBookEmailRequestBodyMapper
  },
  getConnectionRequests: {
    method: 'get',
    url: '/api/connection-requests',
    responseMapper: getConnectionRequestsResponseMapper
  },
  updateConnectionRequest: {
    method: 'patch',
    url: '/api/connection-requests/:id',
    requestBodyMapper: updateConnectionRequestBodyMapper,
    markUpdated: {
      entityTypes: [
        NetworkProfileDetails.meta.name,
        NetworkListEntry.meta.name
      ],
      contexts: [ EntityContext.Navigation ]
    }
  },
  deleteConnectionRequest: {
    method: 'delete',
    url: '/api/connection-requests/:id',
    queryStringMapper: queryParamNameMapper({
      'isMine': 'isMine'
    }),
    markUpdated: {
      entityTypes: [
        NetworkProfileDetails.meta.name,
        NetworkListEntry.meta.name,
        ContactDetails.meta.name
      ],
      contexts: [ EntityContext.Navigation ]
    },
    requestEntityMapper: apiParams => ({
      entities: [ createEntity(apiParams.id, ConnectionRequest.meta.name, null) ]
    })
  },
  resendConnectionRequest: {
    method: 'post',
    url: '/api/connection-requests/:id/actions/resend'
  },
  getLinkAddressBooks: {
    method: 'get',
    url: '/ajax/network/searchAddressBooks',
    queryStringMapper: ({
      connectionRequestId,
      companyId,
      criteria
    }) => ({
      connectionRequestId,
      companyId,
      criteria
    }),
    responseMapper: getLinkAddressBookResponseMapper
  },
  resendInvites: {
    method: 'post',
    url: '/api/web/batch-invitation',
    requestBodyMapper: () => ({ customInviteMessage: null }),
    markUpdated: {
      entityTypes: [
        NetworkListEntry.meta.name
      ]
    }
  },
  searchCompanyOrgHierarchy: {
    method: 'get',
    url: '/api/companyNodes/search',
    queryStringMapper: queryParamNameMapper({
      companyId: 'ownerCompanyId',
      pageSize: 'pageSize',
      search: 'queryText'
    }),
    responseMapper: (response) => {
      let entities = [];

      if (response.items && response.items.length) {
        entities = response.items.map(item => createEntity(
          item.id,
          CompanyOrganizationNode.meta.name,
          {
            id: item.id,
            name: item.name,
            path: item.parents
          }
        ));
      }

      return { entities };
    }
  },
  getCompanyOrgNodes: {
    method: 'get',
    url: '/api/companyNodes/list',
    queryStringMapper: queryParamNameMapper({
      ids: 'companyNodeIds'
    }),
    responseMapper: (response) => {
      let entities = [];
      if (response.entries && response.entries.length) {
        entities = response.entries.map(item => createEntity(
          item.id,
          CompanyOrganizationNode.meta.name,
          {
            id: item.id,
            name: item.name
          }
        ));
      }
      return { entities };
    }
  },
  submitAddressBookClientReferenceIds: {
    method: 'post',
    url: '/api/qbo/addressBookLinks',
    requestBodyMapper: updateAddressBookLinksRequestBodyMapper
  },
  updateBeneficialOwnerInfo: {
    method: 'put',
    url: '/api/next/companies/beneficialOwnerInfo',
    requestBodyMapper: updateBeneficialOwnerInfoRequestMapper,
    markUpdated: { entityType: PaymentDataMethodOptions.meta.name }
  },
  queryCompanyOrgNodes: {
    method: 'get',
    url: '/api/companyNodes/query',
    queryStringMapper: queryParamNameMapper({
      id: 'ownerCompanyId',
      take: 'take'
    }),
    responseMapper: (response) => {
      let entities = [];
      if (response.entries && response.entries.length) {
        entities = response.entries.map((item) => {
          if (item.isRoot) return null;
          return createEntity(
            item.id,
            CompanyOrganizationNode.meta.name,
            {
              id: item.id,
              name: item.name,
              path: []
            }
          );
        }
        ).filter(a => !!a);
      }
      return { entities };
    }
  }
});
