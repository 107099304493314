/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */
/* eslint max-len:0 */

import { toMessages } from 'config/messages';

export default toMessages('RegistrationContactForm', {
  Header: 'Contact Corporate Payments',
  Subheader: 'Submit the form below to receive a free consultation with a payments specialist.',
  CompanyName: 'Company Name',
  FirstName: 'First Name',
  LastName: 'Last Name',
  WorkPhone: 'Work Phone',
  WorkEmail: 'Work Email',
  Title: 'Title',
  Website: 'Company Website Address',
  WebsitePlaceholder: 'wwww.example.com',
  ApSpend: 'Annual Non-Payroll AP Spend',
  PaperChecksSent: 'Average Monthly Paper Checks Sent',
  Comments: '{b}How can we help?{b} Please tell us about your business and payments-related needs.',
  ConfirmMessage: 'Thanks! Your email has been sent.'
});
