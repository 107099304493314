/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { shallowEqual, useSelector } from 'react-redux';
import isAuthorizedToUseApp from 'actions/common/isAuthorizedToUseApp';

const useIsAuthorizedToUseApp = () => useSelector(
  state => isAuthorizedToUseApp(state),
  shallowEqual
);

export default useIsAuthorizedToUseApp;