/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React from 'react';
import { addModal } from 'config/modals/registry';
import WelcomeContent from 'containers/Modals/AccountSetup/WelcomeStep/WelcomeContent';
import { useRegistrationMetadata } from 'hooks/registration';
import SimpleModal from 'components/Modal/Simple';
import { ShowAddPaymentAccountStatus } from 'config/constants/registration';
import CommonMessages from 'config/messages/common';
import Messages from './index.messages';

const ExpeditedRegistrationWelcomeModal = ({ onClose }) => {
  // Hooks
  const {
    displayAddPaymentAccount,
    from: { companyName },
    targetCompanyInfo: { connectionType }
  } = useRegistrationMetadata();

  const canReceivePaymentsIntoBank = displayAddPaymentAccount !== ShowAddPaymentAccountStatus.Hidden;
  const canSendInvoicesToCustomer = canReceivePaymentsIntoBank; // This is a horrible, horrible correlation

  // Render
  return (
    <SimpleModal header={Messages.Header}
      onClose={onClose}
      submit={{onClick: onClose, message: CommonMessages.GetStarted}}
      close={false}
      footnote={connectionType === 'Vendor' ? <Messages.InvoiceDataFootnote.Message /> : null}
    >
      <WelcomeContent
        canReceivePaymentsIntoBank={canReceivePaymentsIntoBank}
        canSendInvoicesToCustomer={canSendInvoicesToCustomer}
        companyName={companyName}
        target={connectionType}
        variant="onboarding"
      />
    </SimpleModal>
  );
};

export default addModal(
  'ExpeditedRegistrationWelcomeModal',
  ExpeditedRegistrationWelcomeModal,
  { wide: true }
);
