/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { createReferenceFromEntity } from 'schemas/state';
import { asApiAction } from 'api/core/ApiAction';
import { isApiAction, isSuccessAction } from 'api/core/utils';
import { BankAccount } from 'schemas/payments/bankAccount';
import { IdentityTypes, PartialIdentity } from 'schemas/network/session';
import { newState } from './utils';

const isNotAuthorizedAnymoreEntity = ({
  type,
  value
}) => type === 'UseAppAuthorizationStatus' && !value?.isAuthorizedToUseApp;

export default (state = {}, action) => {
  if (isSuccessAction(action, 'getIdentity')) {
    const entities = action.payload.entities;

    if (entities.length > 0) {
      const identity = entities
        .find(entity => IdentityTypes.includes(entity.type));

      const partialIdentity = entities
        .find(entity => entity.type === PartialIdentity.meta.name);

      return newState(state, {
        identity: identity ? createReferenceFromEntity(identity) : null,
        partialIdentity: partialIdentity ? createReferenceFromEntity(partialIdentity) : null,
        bankAccounts: entities
          .filter(entity => entity.type === BankAccount.meta.name)
          .map(entity => createReferenceFromEntity(entity))
      });
    }
  }

  if (isSuccessAction(action, 'getBankAccounts')) {
    return newState(state, {
      bankAccounts: action.payload.references
    });
  }

  if (isSuccessAction(action, 'logout')) {
    let entities = action.payload.entities;

    if (entities.length > 0) {
      return newState(state, {
        identity: null,
        authReason: null,
        logOnRedirect: null,
        hasBeenRedirected: null,
        hasDoneOncePerSessionAction: null
      });
    }
  }

  if (isApiAction(action) && action.payload?.entities?.length) {
    const authStatusEntity = action.payload.entities.find(
      ({ type, value }) => type === 'UseAppAuthorizationStatus' && value?.isAuthorizedToUseApp
    );

    if (authStatusEntity) {
      return newState(state, {
        authReason: authStatusEntity.value.reason
      });
    }
  }

  const apiAction = asApiAction(action);

  if (apiAction) {
    if (apiAction.getEntities({ filter: isNotAuthorizedAnymoreEntity, valueOnly: false }).length) {
      return newState(state, {
        authReason: null,
        logOnRedirect: null,
        hasBeenRedirected: null,
        hasDoneOncePerSessionAction: null
      });
    }
  }

  if (action.type === 'SaveLogonRedirect') {
    return newState(state, {
      logOnRedirect: action.redirect
    });
  }

  // Specifically on logon
  if (action.type === 'HasBeenRedirected') {
    return newState(state, { hasBeenRedirected: true });
  }

  if (action.type === 'HasDoneOncePerSessionAction') {
    return newState(state, { hasDoneOncePerSessionAction: true });
  }

  return state;
};
