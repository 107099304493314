/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React from 'react';
import { useDispatch } from 'react-redux';

import { showToast } from 'actions/notification';

import resendVerifyAccountEmailEndpoint from 'api/session/resendVerifyAccountEmail';

import Button from 'components/Button';

import { ToastTypes } from 'config/constants';

import Messages from './index.messages';
import './index.scss';

const VerifyAccount = ({
  canCancel,
  encryptedUserId,
  onComplete
}) => {
  // Hooks
  const dispatch = useDispatch();

  const onResend = async () => {
    const [ success ] = await dispatch(resendVerifyAccountEmailEndpoint({
      encryptedUserId
    }));

    if (!success) return;

    dispatch(showToast({
      type: ToastTypes.success,
      message: <Messages.ResendVerification.Message />
    }));
  };

  // Render
  return (
    <div style={{textAlign: 'center'}}>
      <div className="mail-ernie" />
      <p>
        Check your email to verify your account.
      </p>
      <div className="text-center">
        {canCancel == null || !!canCancel ? (
          <Button
            variant="secondary"
            onClick={onComplete}>
            Cancel
          </Button>
        ) : null}
        <Button onClick={onResend}>
          Resend Email
        </Button>
      </div>
    </div>
  );
};

export default VerifyAccount;