/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import getCompanyUserSettings from './getCompanyUserSettings';

const getCompanyUserAdditionalSetting = (state, key) => {
  const { additionalSettings } = getCompanyUserSettings(state);

  // TODO: Add some error logging similar to what we do for getCompanyUserSettings
  return (additionalSettings || {})[key];
};

export default getCompanyUserAdditionalSetting;