/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { createApiAction } from 'api/core';

import EntityContext from 'config/constants/schemas/EntityContext';

const chargeInitial = createApiAction({
  method: 'put',
  url: '/api/subscription/chargeInitial',
  requestBodyMapper: ({
    companyId,
    creditCardToken
  }) => ({
    companyId,
    creditCardToken
  }),
  responseMapper: () => ({
    updated: {
      contexts: [ EntityContext.Identity ]
    }
  })
});

export default chargeInitial;