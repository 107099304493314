/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { useContext, useEffect, useState } from 'react';
import { uniq } from 'lodash';
import { shallowEqual } from 'react-redux';

import UpdateContext from '../provideHooks/UpdateContext';

const getHookMeta = ({ meta }) => meta;
const getEntryApiResultIdFromMeta = ({ entryApiResultId }) => entryApiResultId;
const getIsProvideListenerFromMeta = ({ isProvideListener }) => isProvideListener;

export const createGetAllHooksWith = (
  detector,
  selector
) => hooks => hooks
  .filter(hook => hook && !!(detector || selector)(hook))
  .map(selector || detector);

export const getAllHooksWithIsLoading = createGetAllHooksWith(hook => hook.useWillLoadOrIsLoading);
export const getAllHooksWithRequestError = createGetAllHooksWith(hook => hook.useRequestError);

const useBaseStatusIndicator = (
  getAllHooksWith,
  hooks,
  // Use this to normalize the output of getAllHooksWith to a list of metas
  // If this isn't provided, its assumed that getAllHooksWith returns a list of
  // individual hooks
  getHookMetas
) => {
  // Hooks
  const { subscribeListeners } = useContext(UpdateContext);

  const [ unsubscribers, setUnsubscribers ] = useState({});

  const hooksWith = getAllHooksWith(hooks);

  // Make sure that any hooks that would have registered as a listener with the
  // UpdateService does so
  const hooksWithListenersMetas = (getHookMetas?.(hooksWith) ?? hooksWith.map(getHookMeta))
    .filter(getIsProvideListenerFromMeta);

  const listenerEntryApiResultIds = uniq(
    hooksWithListenersMetas.map(getEntryApiResultIdFromMeta)
  );

  const [ listenerActiveApiResultIds, setListenerActiveApiResultIds ] = useState([]);

  // Because the status indicator hooks are called A LOT, we need to take special care that we
  // aren't adding listeners more than what is necessary. We, slightly annoyingly, faciliate this
  // with state and a shallow equals on the current list of IDs. Ideally react could do a shallow
  // equals itself on the entries in the dependency list or not get fussy with variable lengths of
  // dependency lists, but alas, this does the trick.
  useEffect(
    () => {
      if (!shallowEqual(listenerEntryApiResultIds, listenerActiveApiResultIds)) {
        setListenerActiveApiResultIds(listenerEntryApiResultIds);
        setUnsubscribers(subscribeListeners(listenerEntryApiResultIds));
      }
    },
    [ listenerEntryApiResultIds ]
  );

  useEffect(
    () => {
      Object.keys(unsubscribers).forEach(
        (entryApiResultId) => {
          if (!listenerActiveApiResultIds.includes(entryApiResultId)) {
            unsubscribers[entryApiResultId]();
          }
        }
      );

      // Unsubs all the listeners when this component unmounts
      return () => Object.keys(unsubscribers).forEach(
        entryApiResultId => unsubscribers[entryApiResultId]()
      );
    },
    [ listenerActiveApiResultIds ]
  );

  // Action
  return hooksWith;
};

export default useBaseStatusIndicator;