/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, { Component, Children } from 'react';
import { FormattedMessage } from 'react-intl';
import Button from 'components/Button';
import Messages from './index.messages.js';
import createBaseSecurityQuestionsForm from './Base';
import connect from './index.connect';

const createSecurityQuestionsForm = (WrappingComponent) => {
  const BaseSecurityQuestionForm = createBaseSecurityQuestionsForm(WrappingComponent);
  class WrappedComponent extends Component {
    constructor(props) {
      super(props);
      this.state = { isLoading: false };
      this.onSubmitQuestions = this.onSubmitQuestions.bind(this);
    }

    componentDidMount() {
      const { getUserProfile } = this.props;

      getUserProfile();
    }

    onSubmitQuestions(value) {
      this.setState({ isLoading: true });
      this.props.updateProfile({
        securityQuestions: [
          {
            question: value.question1,
            answer: value.answer1,
            id: this.props.userSecurityQuestionIds ? this.props.userSecurityQuestionIds[0] : null
          },
          {
            question: value.question2,
            answer: value.answer2,
            id: this.props.userSecurityQuestionIds ? this.props.userSecurityQuestionIds[1] : null
          },
          {
            question: value.question3,
            answer: value.answer3,
            id: this.props.userSecurityQuestionIds ? this.props.userSecurityQuestionIds[2] : null
          }
        ]
      }, () => {
        if (this.props.showConfirmation) {
          this.props.showConfirmationToast(<FormattedMessage {...Messages.ProfileChanged} />);
        }
        if (this.props.onComplete) {
          this.props.onComplete();
        }
      });
    }

    render() {
      return (
        <BaseSecurityQuestionForm
          {...this.props}
          isLoading={this.state.isLoading}
          onSubmitQuestions={this.onSubmitQuestions}
        />
      );
    }
  }

  return connect(WrappedComponent);
};

const BaseSecurityQuestionFormCard = createSecurityQuestionsForm(({
  onSubmitQuestions,
  onComplete,
  children
}) => {
  return (
    <div>
      {Children.only(children)}
      <div style={{marginTop: 12}}>
        <Button variant="secondary" style={{width: 'calc((100% - 12px) / 2)'}}
          onClick={onComplete}>
          <FormattedMessage {...Messages.buttons.Cancel} />
        </Button>
        <Button style={{marginLeft: 12, width: 'calc((100% - 12px) / 2)'}} type="submit"
          onClick={(e) => {
            e.preventDefault();
            onSubmitQuestions();
          }}>
          <FormattedMessage {...Messages.buttons.Save} />
        </Button>
      </div>
    </div>
  );
});

export const SecurityQuestionFormCard = props => <BaseSecurityQuestionFormCard {...props} variant="stacked" />;

export default createSecurityQuestionsForm;
