/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { BankAccountStatus } from 'schemas/payments/bankAccount';
import filterForValidAccounts from 'schemas/payments/bankAccount/filterForValidAccounts';

// Need to move users off of importing from this file to the new one
export { filterForValidAccounts };

export const filterForMicroDeposits = (accounts) => {
  return accounts.filter(account => (account.statuses.includes('AwaitingMicro')
      && !(account.statuses.includes('VerificationExpired')
      || account.statuses.includes('AccountInactive')
      || account.statuses.includes('AccountLocked'))));
};

export const filterForMicroAndVerified = (accounts) => {
  return accounts.filter(account => account.statuses.includes(BankAccountStatus.meta.map.AwaitingMicro)
    || account.statuses.includes(BankAccountStatus.meta.map.AccountVerified));
};

export const filterForSingleSigner = (accounts) => {
  return accounts.filter(account => !account.statuses.includes(BankAccountStatus.meta.map.DualSigner));
};

export const filterForActiveAccounts = (accounts) => {
  return accounts.filter(account => !account.statuses.includes(BankAccountStatus.meta.map.AccountInactive));
};

export const filterForAchAccounts = (accounts) => {
  return accounts.filter(account => account.statuses.includes(BankAccountStatus.meta.map.ThirdPartyACHEnabled));
};

export const maskAccountSuffix = (suffix) => {
  if (suffix) {
    return `XX-${suffix.toString()}`;
  }

  return null;
};
