/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Icon from 'components/Icon';

import Select from '../Select';
import BoxRadioGroup from '../BoxRadioGroup';
import { getOptionsFromEnum } from '../enumUtils';


/**
 * A selection component inteded to be used for a branching decision in a form.
 * i.e. A small selection of choices that can decide what comes next in the form
 */

export default class FormDecisionSelect extends Component {
  static propTypes = {
    icons: PropTypes.arrayOf(PropTypes.object),
    maxOptionsForSingleLine: PropTypes.number,
    defaultIcon: PropTypes.object,
    disableCollapse: PropTypes.bool
  };

  static defaultProps = {
    maxOptionsForSingleLine: 6,
    disableCollapse: false
  };

  useSingleLine() {
    if (this.props.disableCollapse) return true;
    let options = getOptionsFromEnum(this.props.type);
    let optionsCount = (options && options.length) || 0;

    return !this.props.value && optionsCount <= this.props.maxOptionsForSingleLine;
  }

  renderOption(option, index) {
    let icons = this.props.icons || [];
    let iconProps = icons.find(i => i.value === option.value);
    let icon = null;

    let isLarge = this.useSingleLine();
    if (iconProps) {
      icon = <Icon {...iconProps} size={isLarge ? 26 : 14} />;
    } else if (this.props.defaultIcon) {
      icon = <Icon {...this.props.defaultIcon} size={isLarge ? 26 : 14} />;
    }

    if (isLarge) {
      return (
        <div style={{
          display: 'table',
          width: '100%',
          height: '100%'
        }}>
          <div style={{
            display: 'table-cell',
            verticalAlign: 'middle',
            textAlign: 'center',
            padding: '8px'
          }}>
            <div style={{marginBottom: '5px'}}>
              {icon}
            </div>
            {option.text}
          </div>
        </div>
      );
    }

    return (
      <div>
        {icon}&nbsp;&nbsp;
        {option.richText || option.text}
      </div>
    );
  }

  render() {
    if (this.useSingleLine()) {
      // highlight values as primary radio buttons when we haven't made a decision here
      return <BoxRadioGroup {...this.props} renderRadioButton={(...args) => this.renderOption(...args)} />;
    }

    // collapse selection into smaller select once decision has been made (value was set)
    return <Select {...this.props} clearable={true} renderOptionLabel={(...args) => this.renderOption(...args)}/>;
  }
}
