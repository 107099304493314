/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { createApiAction } from 'api/core';

import { getEntity } from 'schemas/state';

import bankAccountEntityMapper from './mappers/bankAccountEntityMapper';

const getBankAccounts = createApiAction(
  {
    method: 'get',
    url: '/ajax/account/get/accounts/:filter',
    responseMapper: (apiPaymentAccountsResponse, state) => {
      let entities = [];
      let references = [];
      let canAddPaymentAccount = true;

      let identity = getEntity(state, state.session.identity);

      if (apiPaymentAccountsResponse.success && apiPaymentAccountsResponse.response) {
        const { response } = apiPaymentAccountsResponse;
        canAddPaymentAccount = response.canAddPaymentAccount;

        response.accounts.forEach((account) => {
          const entity = bankAccountEntityMapper(account, identity);
          entities.push(entity);
          references.push(entity.createReference());
        });
      }

      return {
        entities,
        references,
        canAddPaymentAccount
      };
    }
  },
  // Still used by reducers:
  //   session
  //   settings.ModifyDefaults
  //   settings.CompanyProfilePage,
  //   invoice.BillStatementsListView
  //   payments.PayLater
  //   payments.Qbo
  'getBankAccounts'
);

export default getBankAccounts;