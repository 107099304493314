/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React from 'react';
import { FormattedMessage } from 'react-intl';
import CommonApi from 'api/common';
import UserSettingsApi from 'api/settings/user';
import SettingsApi from 'api/settings';
import getIdentity from 'api/session/getIdentity';
import patchCompanySettings from 'api/settings/patchCompanySettings';
import PaymentAccountsApi from 'api/paymentAccounts';
import { createUrl, updateEntityAction } from 'api/core/utils';
import { uniqueId } from 'lodash';
import {
  closeModal as closeCurrentModal,
  ensureKnowledgeAuthentication,
  showConfirmationModal
} from 'actions/modal';
import { showSmallBanner, showToast } from 'actions/notification';
import { getFilters, getSortFields, getPage } from 'actions/route';
import { getCurrentFilters } from 'actions/filter';
import { ToastTypes } from 'config/constants';
import { createEntity, getEntity, createEntityReference } from 'schemas/state';
import { CompanyUser } from 'schemas/settings/user';
import { push } from 'connected-react-router';
import { GetPath, LinkRef } from 'config/links';
import { batch } from 'actions/common';
import { cacheOrDispatchApiCall, dispatchApiCall } from 'actions/references';
import { DiscountStrategy } from 'schemas/settings/vpx';
import { NotificationStyleTypes } from 'components/Notification';
import Button from 'components/Button';
import { VendorPaysFeesSetting, VendorPaysFeesSettingCounts } from 'schemas/settings/fee';
import { BankAccount } from 'schemas/payments/bankAccount';
import RetrievableAddressBookEntry from 'schemas/network/RetrievableAddressBookEntry';
import Messages from './notification.messages';

export const getCompanyUsers = (onComplete, cache = false) => {
  return (dispatch) => {
    dispatch((cache ? cacheOrDispatchApiCall : dispatchApiCall)(
      'CompanyUsers',
      SettingsApi.getCompanyUsers,
      { includeInactiveUsers: true },
      { onComplete },
      { referenceSelector:
        entities => entities.filter(entity => entity.type === CompanyUser.meta.name)
      }
    ));
  };
};

export function loadCompanyUsers() {
  return (dispatch, getState) => {
    let state = getState();
    let filters = getFilters(state);

    dispatch(SettingsApi.getCompanyGroups(null,
      () => dispatch(SettingsApi.getCompanyUsers({includeInactiveUsers: filters.status === 'all'}))
    ));
  };
}

function displayCompanyUserInvitedToast(dispatch, state, companyUserId) {
  let user = state.entities.CompanyUser[companyUserId];

  if (!user || !user.value) return;

  let message = user.value.isInvited
    ? Messages.messages.UserReinvited
    : Messages.messages.UserInvitationSent;

  dispatch(showToast({
    type: ToastTypes.success,
    header: <FormattedMessage {...Messages.headers.UserInvitationSent} />,
    message: <FormattedMessage {...message} values={{
      firstName: user.value.firstName,
      lastName: user.value.lastName
    }}/>
  }));
}

export function inviteCompanyUser(companyUserId, userId) {
  return (dispatch, getState) => {
    dispatch(SettingsApi.inviteCompanyUser({
      userId
    }, (success) => {
      if (!success) return;
      displayCompanyUserInvitedToast(dispatch, getState(), companyUserId);
      dispatch(loadCompanyUsers());
    }));
  };
}

export function loadCompanyGroups() {
  return (dispatch, getState) => {
    dispatch(SettingsApi.getCompanyGroups());
  };
}

export function checkPaymentToBeSent(bankAccountId) {
  return (dispatch, getState) => {
    dispatch(PaymentAccountsApi.checkPaymentToBeSent({
      id: bankAccountId,
      numberRequiredApprovers: 1
    }));
  };
}

export function loadBankAccount(bankAccountId, correlationId, onComplete) {
  return (dispatch, getState) => {
    dispatch(PaymentAccountsApi.getBankAccount({
      id: bankAccountId
    }, (success, action) => {
      if (onComplete) {
        onComplete(success, action);
      }
    }, correlationId));
  };
}

export function resetBankAccount(bankAccountId, onComplete) {
  return (dispatch) => {
    dispatch(PaymentAccountsApi.resetBankAccount({
      id: bankAccountId
    }, onComplete));
  };
}

export function editBankAccount(bankAccountId, account, callback) {
  return (dispatch, getState) => {
    dispatch(PaymentAccountsApi.editBankAccount({
      id: bankAccountId,

      // ignore legacy warning/alert about payments being sent due to approval changes
      skipPaymentsWillBeSentWarning: true,
      ...account
    }, (success, action) => {
      if (success) {
        if (callback) {
          callback();
        }
      }
      dispatch(closeCurrentModal());
    }));
  };
}

export function deleteBankAccount(id, onComplete) {
  const paymentAccountIds = [id];
  return (dispatch, getState) => {
    dispatch(PaymentAccountsApi.deleteBankAccount({
      paymentAccountIds
    }, (success) => {
      if (success) {
        // delete existing bank account (since otherwise it will remain in entity state)
        dispatch(updateEntityAction([createEntity(id, BankAccount.meta.name, null)]));
        onComplete?.(success);
      }
    }));
  };
}

export function updatePaymentAccountDefaults(params, onComplete) {
  return (dispatch) => {
    dispatch(PaymentAccountsApi.updatePaymentAccountDefaults({
      companyId: params.companyId,
      defaultReceiptAccount: params.defaultDepositAccountId,
      defaultPaymentAccount: params.defaultPaymentAccountId
    }, onComplete));
  };
}

export function disablePaymentAccount(id, onComplete) {
  return (dispatch) => {
    dispatch(PaymentAccountsApi.disablePaymentAccount({
      accountId: id
    }, onComplete));
  };
}

export function enablePaymentAccount(id, onComplete) {
  return (dispatch) => {
    dispatch(PaymentAccountsApi.enablePaymentAccount({
      accountId: id
    }, onComplete));
  };
}

export function getTwoStepSettings() {
  return (dispatch, getState) => {
    dispatch(SettingsApi.getTwoFactorAuthentication());
  };
}

export function getUserProfile() {
  return (dispatch, getState) => {
    dispatch(UserSettingsApi.getUserProfile());
  };
}

export function cancelEmailChange(params) {
  return (dispatch, getState) => {
    dispatch(SettingsApi.cancelEmailChange({}, (success) => {
      if (success) {
        dispatch(UserSettingsApi.getUserProfile());
      }
    }));
  };
}

export function enableTwoStepVerification(phoneNumber) {
  return (dispatch, getState) => {
    dispatch(UserSettingsApi.createTwoFactorAuthentication({
      phoneNumber,
      nonce: uniqueId()
    }, (success, action) => {
      if (success && action.payload.skipValidation) {
        dispatch(showToast({
          type: ToastTypes.success,
          message: <FormattedMessage {...Messages.messages.TwoStepUpdated} />
        }));
      } else {
        dispatch(showToast({
          type: ToastTypes.success,
          message: <FormattedMessage {...Messages.messages.CodeSent} />
        }));
      }
    }));
  };
}

export function resendTwoFactorCode() {
  return (dispatch, getState) => {
    dispatch(UserSettingsApi.resendTwoFactorAuthentication({}, (success, action) => {
      if (success && !action.payload.isPhoneNumberLocked) {
        dispatch(showToast({
          type: ToastTypes.success,
          message: <FormattedMessage {...Messages.messages.CodeSent} />
        }));
      }
    }));
  };
}

export function disableTwoFactorAuthentication(authenticationCode) {
  return (dispatch, getState) => {
    dispatch(SettingsApi.disableTwoFactorAuthentication({}, (success, action) => {
      if (success) {
        dispatch(showToast({
          type: ToastTypes.success,
          message: <FormattedMessage {...Messages.messages.TwoStepVerificationDisabled} />
        }));
        dispatch(getIdentity());
        dispatch(getTwoStepSettings());
      }
    }));
  };
}

export function getEmailPreferences() {
  return (dispatch, getState) => {
    dispatch(SettingsApi.getEmailPreferences());
  };
}

export function updateEmailPreferences(groups) {
  return (dispatch, getState) => {
    dispatch(SettingsApi.updateEmailPreferences({groups}, (success) => {
      if (success) {
        dispatch(showToast({
          type: ToastTypes.success,
          message: <FormattedMessage {...Messages.messages.EmailPreferences} />
        }));
      }
    }));
  };
}

export function addNewGroup(params) {
  return (dispatch, getState) => {
    dispatch(SettingsApi.isGroupNameUsed({roleName: params.name}, (success, action) => {
      if (success && action.payload.success) {
        dispatch(SettingsApi.addGroup(params, (addSuccess, addAction) => {
          if (addSuccess) {
            if (addAction.payload.success) {
              dispatch(showToast({
                type: ToastTypes.success,
                message: <FormattedMessage {...Messages.messages.GroupAdded} values={{groupName: params.name}}/>
              }));
              dispatch(push(GetPath(LinkRef.companySettings.groups)));
            } else {
              dispatch(showToast({
                type: ToastTypes.error,
                message: <FormattedMessage {...Messages.messages.GroupAddFailed} />
              }));
            }
          }
        }));
      } else if (!action.payload.success) {
        dispatch(showToast({
          type: ToastTypes.error,
          message: <FormattedMessage {...Messages.messages.GroupNameExists} />
        }));
      }
    }));
  };
}

export function updateGroup(details, editGroupId) {
  return (dispatch, getState) => {
    dispatch(SettingsApi.updateGroup({...details, id: editGroupId}, (success, action) => {
      if (success && action.payload.success) {
        dispatch(showToast({
          type: ToastTypes.success,
          header: <FormattedMessage {...Messages.headers.Groups} />,
          message: <FormattedMessage {...Messages.messages.ChangesSaved} />
        }));
        dispatch(push(GetPath(LinkRef.companySettings.groups)));
      } else {
        dispatch(showToast({
          type: ToastTypes.error,
          message: <FormattedMessage {...Messages.messages.GroupUpdateFailed} />
        }));
      }
    }));
  };
}

export function getGroupDetails(id) {
  return (dispatch, getState) => {
    dispatch(SettingsApi.getPermissionsForGroup({id}));
    dispatch(SettingsApi.getUsersForGroup({id}));
  };
}

export function deleteGroup(id, closeModal) {
  return (dispatch, getState) => {
    dispatch(SettingsApi.deleteGroup({roleId: id, ignoreValidationWarnings: false}, (success, action) => {
      if (success && action.payload.success) {
        dispatch(showToast({
          type: ToastTypes.success,
          header: <FormattedMessage {...Messages.headers.Groups} />,
          message: <FormattedMessage {...Messages.messages.GroupDeleted}
            values={{groupName: action.payload.response.name}} />
        }));
        dispatch(push(GetPath(LinkRef.companySettings.groups)));
        closeModal();
      } else {
        let isInApprovalRuleSet = !!(action.payload.messages
          && action.payload.messages.length
          && action.payload.messages[0].message
          && action.payload.messages[0].message.indexOf('part of the company payment approval rule set') >= 0
        );
        dispatch(showToast({
          type: ToastTypes.error,
          message: isInApprovalRuleSet
            ? `
              This group cannot be deleted while part of your payment approval rules.
              Remove this group from your approval rules before trying to delete it.
            `
            : 'We were unable to delete this group. Please try again later.'
        }));
      }
    }));
  };
}

export function getAvailableGroups(id) {
  return (dispatch, getState) => {
    dispatch(SettingsApi.getAvailableGroups());
  };
}

export function getPermissionsForMultipleGroups(groupIds) {
  return (dispatch, getState) => {
    dispatch(SettingsApi.getPermissionsForMultipleGroups({selectedRoles: groupIds}));
  };
}

export function addUser(params) {
  return (dispatch, getState) => {
    dispatch(SettingsApi.addUser(params, (success, action) => {
      if (success && action.payload.success) {
        let values = {
          firstName: params.firstName,
          lastName: params.lastName
        };
        let message = params.sendInvitation
          ? <FormattedMessage {...Messages.messages.UserInvited} values={values}/>
          : <FormattedMessage {...Messages.messages.UserAdded} values={values}/>;

        dispatch(push(GetPath(LinkRef.companySettings.users)));
        dispatch(showToast({
          type: ToastTypes.success,
          header: <FormattedMessage {...Messages.headers.Users} />,
          message
        }));
      } else {
        dispatch(showToast({
          type: ToastTypes.error,
          header: <FormattedMessage {...Messages.headers.Users} />,
          message: action.payload.messages && action.payload.messages.length
            ? action.payload.messages[0].message : <FormattedMessage {...Messages.messages.UserAddError} />
        }));
      }
    }));
  };
}

export function updateConsumerPaymentEnrollment(enrolled) {
  return (dispatch, getState) => {
    let execute = () => {
      dispatch(patchCompanySettings({
        canReceiveConsumerPayments: enrolled
      }, (success) => {
        if (enrolled && success) {
          dispatch(showSmallBanner({
            type: ToastTypes.success,
            message: <FormattedMessage {...Messages.messages.EnrolledConsumerPayments} />
          }));
        } else if (!enrolled && success) {
          dispatch(showSmallBanner({
            type: ToastTypes.success,
            message: <FormattedMessage {...Messages.messages.UnenrolledConsumerPayments} />
          }));
        }
      }));
    };

    if (enrolled) {
      execute();
    } else {
      dispatch(showConfirmationModal(
        <FormattedMessage {...Messages.ConsumerPaymentEnrollment.Header} />,
        (
          <div>
            <FormattedMessage {...Messages.ConsumerPaymentEnrollment.Subheader} />
            <br/>
            <br/>
            <ul className="bullet">
              <li><FormattedMessage {...Messages.ConsumerPaymentEnrollment.Line1} /></li>
              <li><FormattedMessage {...Messages.ConsumerPaymentEnrollment.Line2} /></li>
              <li><FormattedMessage {...Messages.ConsumerPaymentEnrollment.Line3} /></li>
            </ul>
          </div>
        ),
        <FormattedMessage {...Messages.ConsumerPaymentEnrollment.TurnOff} />,
        <FormattedMessage {...Messages.ConsumerPaymentEnrollment.Cancel} />,
        {onClick: () => execute()},
        undefined,
        true,
        false
      ));
    }
  };
}

export function getUserDetailsForEdit(id) {
  return (dispatch, getState) => {
    dispatch(SettingsApi.getUserDetailsForEdit({id}));
  };
}

export function updateUser(params, userId) {
  return (dispatch, getState) => {
    dispatch(SettingsApi.updateCompanyUser({...params, userId}, (success, action) => {
      if (success && action.payload.success) {
        dispatch(showToast({
          type: ToastTypes.success,
          header: <FormattedMessage {...Messages.headers.Users} />,
          message: <FormattedMessage {...Messages.messages.UserUpdated} />
        }));
        dispatch(push(GetPath(LinkRef.companySettings.users)));
      } else {
        dispatch(showToast({
          type: ToastTypes.error,
          header: <FormattedMessage {...Messages.headers.Users} />,
          message: <FormattedMessage {...Messages.messages.UserUpdateError} />
        }));
      }
    }));
  };
}

export function verifyAccount(params, callback) {
  return (dispatch, getState) => {
    dispatch(PaymentAccountsApi.verifyAccount(params, (success, action) => {
      if (success) {
        if (action.payload.success) {
          if (!callback) {
            dispatch(push(GetPath(LinkRef.companySettings.paymentMethods)));
          } else {
            callback();
          }

          dispatch(showToast({
            type: ToastTypes.success,
            header: <FormattedMessage {...Messages.headers.AccountVerification} />,
            message: <FormattedMessage {...Messages.messages.VerifySuccess} />
          }));
        } else {
          let accountLocked = action.payload.messages.filter(message => message.context === 'AccountLocked');
          let microDepositsExpired = action.payload.messages.filter(
            ({ message }) => message && message.indexOf('ExpiredVerification') > -1
          );
          if (accountLocked && accountLocked.length) {
            if (!callback) {
              dispatch(push(GetPath(LinkRef.companySettings.paymentMethods)));
            }
            dispatch(showToast({
              type: ToastTypes.error,
              header: <FormattedMessage {...Messages.headers.AccountVerification} />,
              message: <FormattedMessage {...Messages.messages.VerifyAccountLocked} />
            }));
          } else if (microDepositsExpired && microDepositsExpired.length) {
            dispatch(showToast({
              type: ToastTypes.error,
              header: <FormattedMessage {...Messages.headers.MicroDepositsExpired} />,
              message: <FormattedMessage {...Messages.messages.MicroDepositsExpired} />
            }));
          } else {
            let failedAttemptsCount = false;
            action.payload.messages.forEach((message) => {
              if (message.context === 'FailedAttemptCount') {
                failedAttemptsCount = message.message;
              }
            });
            if (failedAttemptsCount > 1) {
              dispatch(showToast({
                type: ToastTypes.error,
                header: <FormattedMessage {...Messages.headers.AccountVerification} />,
                message: <FormattedMessage {...Messages.messages.IncorrectMicroDepositWarning} />
              }));
            } else {
              dispatch(showToast({
                type: ToastTypes.error,
                header: <FormattedMessage {...Messages.headers.AccountVerification} />,
                message: <FormattedMessage {...Messages.messages.IncorrectMicroDeposit} />
              }));
            }
          }
        }
      }
    }));
  };
}

export function registerPaymentAccount(params, callback) {
  return (dispatch, getState) => {
    dispatch(PaymentAccountsApi.registerPaymentAccount(params, (success, action) => {
      if (success && action.payload.success) {
        if (callback) {
          callback();
        }
      } else {
        dispatch(showToast({
          type: ToastTypes.error,
          header: <FormattedMessage {...Messages.headers.AddPaymentMethod} />,
          message: action.payload.messages && action.payload.messages.length
            ? action.payload.messages[0].message : null
        }));
      }
    }));
  };
}

export function getAcknowledgement(id) {
  return (dispatch, getState) => {
    dispatch(CommonApi.getAcknowledgement({id}));
  };
}

export function setAcknowledgement(id, onComplete) {
  return (dispatch, getState) => {
    dispatch(CommonApi.setAcknowledgement({
      acknowledgements: id
    }, (success) => {
      if (success) {
        dispatch(getAcknowledgement(id));
      }

      if (onComplete) onComplete(success);
    }));
  };
}

export function clearAcknowledgement(id) {
  return (dispatch, getState) => {
    dispatch(CommonApi.deleteAcknowledgement({
      key: id
    }, (success) => {
      if (success) {
        dispatch(getAcknowledgement(id));
      }
    }));
  };
}

export function signWithMobile(signatureRequestId, phoneNumber, sendConfirmation, invitationId) {
  return (dispatch, getState) => {
    dispatch(SettingsApi.mobileSignature({ signatureRequestId, phoneNumber, invitationId }, (success) => {
      if (success && sendConfirmation) {
        dispatch(showToast({
          type: ToastTypes.success,
          message: <FormattedMessage {...Messages.messages.LinkSent} />
        }));
      }
    }));
  };
}

export function deleteDiscountStrategy(discountStrategyId, onComplete = () => null) {
  return (dispatch, getState) => {
    dispatch(SettingsApi.deleteDiscountStrategy({discountStrategyId}, (success) => {
      if (success) {
        let strategyEntity = getEntity(getState(),
          createEntityReference(discountStrategyId, DiscountStrategy.meta.name));
        dispatch(showSmallBanner({
          type: ToastTypes.success,
          message: (
            <FormattedMessage {...Messages.messages.StrategyDeleted}
              values={{strategyName: strategyEntity.name}}/>
          )
        }));
        onComplete();
      }
    }));
  };
}

export function saveDiscountStrategy({
  discountStrategyId,
  name,
  targetReturnPercent,
  blackoutWindowDays,
  isDefault: setAsDefault,
  minimumReturnMode
}, onComplete = () => null) {
  return (dispatch, getState) => {
    dispatch(SettingsApi.saveDiscountStrategy({
      discountStrategyId,
      name,
      targetReturnPercent,
      blackoutWindowDays,
      setAsDefault,
      minimumReturnMode
    }, (success, action) => {
      if (success) {
        onComplete(action.payload.discountStrategyId);
      }
    }));
  };
}

export function assignVendorsToDiscountStrategy(
  customerAddressBookForVendorIds,
  discountStrategyId,
  goBackToLandingPage = false,
  {
    onComplete = () => null,
    companyName
  } = {}
) {
  return (dispatch, getState) => {
    dispatch(SettingsApi.assignVendorsToDiscountStrategy({
      customerAddressBookForVendorIds,
      discountStrategyId
    }, (success) => {
      if (success) {
        let strategyEntity = getEntity(getState(),
          createEntityReference(discountStrategyId, DiscountStrategy.meta.name));
        let strategyName = strategyEntity ? strategyEntity.name : companyName;
        if (strategyName) {
          dispatch(showSmallBanner({
            type: ToastTypes.success,
            message: (
              <div className="dividers">
                <FormattedMessage {...Messages.messages.VendorsMovedToStrategy}
                  values={{count: customerAddressBookForVendorIds.length, strategyName}}/>
                <Button anchor={true} onClick={() => {
                  dispatch({
                    type: 'DiscountSettings_setSelectedStrategyId',
                    selectedStrategyId: discountStrategyId
                  });
                  dispatch(push(GetPath(LinkRef.companySettings.vpx)));
                }}>
                  <FormattedMessage {...Messages.buttons.View} />
                </Button>
              </div>
            )
          }));
        }
        if (goBackToLandingPage) {
          dispatch(push(GetPath(LinkRef.companySettings.vpx)));
        }
        onComplete();
      }
    }));
  };
}

export function getDiscountRateFromReturn(returnPercent) {
  return (dispatch, getState) => {
    dispatch(SettingsApi.getDiscountRateFromReturn({returnPercent}));
  };
}

export function getDiscountStrategies() {
  return (dispatch, getState) => {
    dispatch(dispatchApiCall(
      'DiscountSettings_getDiscountStrategies',
      SettingsApi.getDiscountStrategies,
      {},
      {},
      {}
    ));
  };
}

export function getVendorDiscountStrategyList() {
  return (dispatch, getState) => {
    let state = getState();
    let page = getPage(state) || 1;
    let sort = getSortFields(state);
    let filters = getCurrentFilters(state);

    dispatch(SettingsApi.getVendorDiscountStrategyList({
      customerAddressBookForVendorQueryString: filters.searchString,
      discountStrategyIds: filters.discountStrategyIds,
      page,
      pageSize: 25,
      sort
    }));
  };
}

export function exportCSV() {
  return (dispatch, getState) => {
    let state = getState();
    let sort = getSortFields(state);
    let filters = getCurrentFilters(state);

    let url = createUrl(SettingsApi.getVendorDiscountStrategyList.config.url, {
      customerAddressBookForVendorQueryString: filters.searchString,
      discountStrategyIds: filters.discountStrategyIds,
      page: 1,
      pageSize: 1000,
      format: 'csv',
      sort
    }, SettingsApi.getVendorDiscountStrategyList.config.urlParamMapper,
    SettingsApi.getVendorDiscountStrategyList.config.queryStringMapper);

    global.window.location = url;
  };
}

export function canVendorPayFees(state, addressBookId) {
  // NOTE: requires that getVendorPaysFeesSettings has been called at least for the given address book
  let entity = getEntity(state, createEntityReference(addressBookId, VendorPaysFeesSetting.meta.name));
  if (!entity) return false;
  return entity.isEnabled;
}

export function getVendorPaysFeesSettings({
  addressBookIds,
  searchPhrase,
  includeNotCovered = false,
  page = 1
}, onComplete, cacheName = 'LoadVendorPaysFeesSettings') {
  return (dispatch) => {
    dispatch(dispatchApiCall(
      cacheName,
      SettingsApi.getVendorPaysFeesSettings,
      {
        addressBookIds,
        companyName: searchPhrase,
        includeNotCovered,
        page,
        pageSize: 100
      },
      {
        onComplete
      }
    ));
  };
}

function getCompanyNameForVendorPaysFeesSetting(state, addressBookId) {
  let entity = null;

  entity = getEntity(state, createEntityReference(
    addressBookId,
    VendorPaysFeesSetting.meta.name
  ));
  if (entity) {
    return entity.companyName;
  }

  entity = getEntity(state, createEntityReference(
    addressBookId,
    RetrievableAddressBookEntry.meta.name
  ));
  if (entity) {
    return entity.companyName;
  }

  return null;
}

/** Manually update state entities based on action (so we don't have to call more APIs) */
function optimisticUpdateVendorPayFeesSettings(dispatch, state, addressBookIds, isEnabled) {

  let isUpdatingAll = !addressBookIds || addressBookIds.length === 0;

  // UPDATE existing setting entitites
  let addressBookIdsToUpdate = [];

  if (!isUpdatingAll) {
    // update given ids
    addressBookIdsToUpdate = [].concat(addressBookIds);
  } else {
    // update all known settings
    let entities = state.entities[VendorPaysFeesSetting.meta.name];
    if (entities) {
      let entityIds = Object.keys(entities);
      if (entityIds) {
        addressBookIdsToUpdate = [].concat(entityIds);
      }
    }
  }

  if (addressBookIdsToUpdate.length > 0) {
    // update settings entities
    let entitiesToUpdate = addressBookIdsToUpdate.map((id) => {
      return createEntity(
        id,
        VendorPaysFeesSetting.meta.name,
        {
          id,
          companyName: getCompanyNameForVendorPaysFeesSetting(state, id),
          isEnabled
        }
      );
    });
    dispatch(updateEntityAction(entitiesToUpdate));
  }

  // UPDATE existing counts
  let existingCounts = getEntity(state, createEntityReference(
    'current',
    VendorPaysFeesSettingCounts.meta.name
  )) || {};
  let updatedEntity = {
    ...existingCounts,
    totalEnabledCount: existingCounts.totalEnabledCount || 0
  };

  if (isUpdatingAll) {
    updatedEntity.totalEnabledCount = isEnabled
      ? updatedEntity.totalCount // select all
      : 0; // remove all
  } else {
    if (isEnabled) {
      updatedEntity.totalEnabledCount += addressBookIds.length;
    } else {
      updatedEntity.totalEnabledCount -= addressBookIds.length;
    }
  }
  dispatch(updateEntityAction([createEntity(
    'current',
    VendorPaysFeesSettingCounts.meta.name,
    updatedEntity
  )]));
}

export function updateVendorPaysFeesSettings(options, onComplete) {
  return (dispatch, getState) => {

    let isEnabled = options.isEnabled;
    let addressBookIds = options.addressBookIds;
    let coverNewCustomers = options.coverNewCustomers;

    // only allow either 'new' setting update OR address book setting update, but not both
    if (options.coverNewCustomers === true || options.coverNewCustomers === false) {
      isEnabled = null;
      addressBookIds = null;
    } else {
      coverNewCustomers = null;
    }

    dispatch(dispatchApiCall(
      'UpdateVendorPaysFeesSettings',
      SettingsApi.updateVendorPaysFeesSettings,
      {
        addressBookIds, // if not set, all address books are updated
        isEnabled,
        coverNewCustomers
      },
      {
        onComplete: (success) => {
          if (onComplete) {
            onComplete();
          }

          if (!success) {
            return;
          }

          let state = getState();
          let identity = getEntity(state, state.session.identity);

          if (isEnabled === true || isEnabled === false) {
            // Manually update settings
            optimisticUpdateVendorPayFeesSettings(dispatch, state, addressBookIds, isEnabled);
          } else if (coverNewCustomers !== identity.companySettings.vendorPaysFeesForNewAddressBooks) {
            dispatch(getIdentity());
          }
        }
      }
    ));
  };
}

export function getAutoAcceptDiscountSettings() {
  return (dispatch, getState) => {
    dispatch(SettingsApi.getAutoAcceptDiscountSettings());
  };
}

export function saveAutoAcceptDiscountSettings(autoAcceptSettings, options) {
  return (dispatch, getState) => {
    dispatch(ensureKnowledgeAuthentication(() => {
      dispatch(SettingsApi.saveAutoAcceptDiscountSettings(autoAcceptSettings, (success) => {
        if (success) {
          dispatch(getAutoAcceptDiscountSettings());
        }
      }));
    }, options));
  };
}

export function updateOneClickPaySettings({ isEnabled, paymentMethod, paymentTiming }, onComplete = () => null) {
  return (dispatch, getState) => {
    const state = getState();
    let identity = getEntity(state, state.session.identity);
    const id = identity.userId;
    let actions = [];
    if (typeof isEnabled !== 'undefined') {
      actions.push({
        action: SettingsApi.setOneClickPayEnabled.config,
        params: { id, isEnabled }
      });
    }
    if (typeof paymentMethod !== 'undefined') {
      actions.push({
        action: SettingsApi.setOneClickPaymentMethod.config,
        params: { id, paymentMethod }
      });
    }
    if (typeof paymentTiming !== 'undefined') {
      actions.push({
        action: SettingsApi.setOneClickPaymentTiming.config,
        params: { id, paymentTiming }
      });
    }
    dispatch(batch(actions, () => {
      dispatch(getIdentity());

      dispatch(showSmallBanner({
        type: NotificationStyleTypes.Success,
        message: <FormattedMessage {...Messages.messages.OneClickPay} />
      }));

      onComplete();
    }));
  };
}
