/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Tooltip from 'components/Tooltip';
import Icon, { IconTypes } from 'components/Icon';
import Button from 'components/Button';

import './v2.scss';

/*

Pagination controls

*/
export default class Pagination2 extends Component {
  static propTypes = {
    totalCount: PropTypes.number.isRequired,
    page: PropTypes.number.isRequired,
    pageSize: PropTypes.number.isRequired,

    /** How many page buttons to we show in between next/prev at the same time */
    maxPagesShownAtOnce: PropTypes.number,

    /** Whether or not the total count has been capped */
    isCapped: PropTypes.bool,

    /** Whether or not pagination info is loading */
    isLoading: PropTypes.bool,

    /** Array of pageSizes that can be set */
    canChangePageSize: PropTypes.bool,
    pageSizeOptions: PropTypes.array
  };

  static defaultProps = {
    maxPagesShownAtOnce: 7, // 3 lower, 3 higher around selected
    canChangePageSize: true,
    pageSizeOptions: [30, 50, 100]
  };

  constructor(props) {
    super(props);
    this.state = {
      page: props.page || 1,
      pageSize: props.pageSize || 50
    };
  }

  componentWillReceiveProps(props) {
    if (this.state.page !== props.page || this.state.pageSize !== props.pageSize) {
      this.setState({
        page: props.page,
        pageSize: props.pageSize
      });
    }
  }

  numberOfPages() {
    return Math.ceil(this.props.totalCount / this.state.pageSize);
  }

  getPagesToShow() {
    let numberOfPagesInResults = this.numberOfPages();

    if (numberOfPagesInResults <= 1) {
      // single page, don't need to show any pages
      return [];
    }

    let showPageLimit = this.props.maxPagesShownAtOnce;
    if (numberOfPagesInResults < showPageLimit) {
      showPageLimit = numberOfPagesInResults;
    }

    // start with page and fill the pages around it
    // until we reach the limit of pages to show
    let pageArray = [this.state.page];

    while (pageArray.length < showPageLimit) {
      let initialLength = pageArray.length;

      // add higher pages at the end
      let nextHigherPage = pageArray[pageArray.length - 1] + 1;
      if (pageArray.length < showPageLimit && nextHigherPage <= numberOfPagesInResults) {
        pageArray.push(nextHigherPage);
      }

      // add lower pages to the beginning
      let nextLowerPage = pageArray[0] - 1;
      if (pageArray.length < showPageLimit && nextLowerPage >= 1) {
        pageArray.unshift(nextLowerPage);
      }

      if (initialLength === pageArray.length) {
        // if we couldn't add higher or lower, get outta here
        break;
      }

    }

    return pageArray;
  }

  onChangePage(pageNumber) {
    if (this.state.page !== pageNumber) {
      this.setState({page: pageNumber});
    }

    if (this.props.onChange) {
      this.props.onChange(pageNumber, this.state.pageSize);
    }
  }

  onChangePageSize(pageSize) {
    if (this.state.pageSize !== pageSize) {
      this.setState({
        // if the pageSize is changing, go back to the beginning
        page: 1,
        pageSize
      });
    }

    if (this.props.onChange) {
      this.props.onChange(1, pageSize);
    }

    if (this.tooltipRef) {
      this.tooltipRef.close();
    }
  }

  renderPageButton(pageNumber) {
    return (
      <Button key={pageNumber}
        anchor={true}
        className={`button-page ${pageNumber === this.state.page ? 'selected' : ''}`}
        onClick={() => this.onChangePage(pageNumber)}>
        {pageNumber}
      </Button>
    );
  }

  renderPageSelection() {
    return (
      <div>
        Number of Results per page:
        {this.props.pageSizeOptions.map((pageSize) => {
          return (
            <div key={pageSize} className="component-pagination-2-page-size-option"
              onClick={() => this.onChangePageSize(pageSize)}>
              {pageSize}
            </div>
          );
        })}
      </div>
    );
  }

  renderPagePosition() {

    if (this.props.totalCount <= 1) {
      return (
        <div className="page-position">
          <strong>{this.props.totalCount}</strong>
          &nbsp;
          {this.props.totalCount === 1 ? 'Result' : 'Results'}
        </div>
      );
    }

    let skip = (this.state.page - 1) * this.state.pageSize;
    let firstItemNumberOfPage = skip + 1;
    let lastItemNumberOfPage = Math.min(skip + this.state.pageSize, this.props.totalCount);
    return (
      <div className="page-position">
        <div style={{ display: 'inline-block', verticalAlign: 'middle'}}>
          <strong>{firstItemNumberOfPage}</strong>
          &nbsp;-&nbsp;
          <strong>{lastItemNumberOfPage}</strong>
          &nbsp;of&nbsp;
          <strong>
            {this.props.totalCount}
            {this.props.isCapped ? '+' : null}
          </strong>
        </div>
        &nbsp;
        <div style={{display: 'inline-block', verticalAlign: 'middle', maxHeight: '36px', overflow: 'hidden'}}>
          {this.props.afterTotalCountSection}
        </div>
      </div>
    );
  }

  renderPageControls() {
    let pagesToShow = this.getPagesToShow();

    let pageSizeChanger = this.props.canChangePageSize
      ? (
        <Tooltip trigger={['click']} overlay={this.renderPageSelection()}
          ref={ref => this.tooltipRef = ref}>
          <div className="page-size" title="Results per page">
            <div className="page-size-selector">
              {this.state.pageSize}
            </div>
          </div>
        </Tooltip>
      )
      : null;

    if (pagesToShow.length < 2) {
      // no need to paginate, but might still want to change page size
      return (
        <div className="page-controls">
          <div style={{ display: 'inline-block', verticalAlign: 'middle', maxHeight: '36px', overflow: 'hidden' }}>
            {this.props.beforePageControlsSection}
          </div>
          {pageSizeChanger}
        </div>
      );
    }

    if (pagesToShow.length === this.props.maxPagesShownAtOnce && !pagesToShow.includes(1)) {
      // since we always want to show page 1, take off the lowest to make room for showing 1 below
      pagesToShow.shift();
    }

    let lastPage = this.numberOfPages();
    let showCapAlert = this.props.isCapped && pagesToShow.includes(lastPage);
    let hasMorePages = pagesToShow[pagesToShow.length - 1] < lastPage;
    return (
      <div className="page-controls">

        <div style={{ display: 'inline-block', verticalAlign: 'middle', maxHeight: '36px', overflow: 'hidden' }}>
          {this.props.beforePageControlsSection}
        </div>

        {pageSizeChanger}

        <Button className="button-prev"
          circle={true}
          variant="secondary"
          style={{
            width: '36px',
            height: '36px'
          }}
          onClick={() => this.onChangePage(this.state.page - 1)}
          isDisabled={this.state.page <= 1}>
          <div className="left-arrow"></div>
        </Button>

        {/* Always show page 1 */}
        {!pagesToShow.includes(1)
          ? this.renderPageButton(1)
          : null}

        {pagesToShow[0] > 2
          ? <span className="ellipsis">...</span>
          : null}

        {pagesToShow.map(page => this.renderPageButton(page))}

        {!showCapAlert && hasMorePages
          ? <span className="ellipsis">...</span>
          : null}

        {showCapAlert
          ? (
            <div className="cap-warning" style={{ display: 'inline-block' }}>
              <Tooltip overlay={`Paging limited to ${this.props.totalCount} results.`}>
                <Icon type={IconTypes.Warning} />
              </Tooltip>
            </div>
          )
          : null}

        <Button className={`button-next ${hasMorePages || showCapAlert ? 'with-more' : ''}`}
          circle={true}
          variant="secondary"
          style={{
            width: '36px',
            height: '36px'
          }}
          onClick={() => this.onChangePage(this.state.page + 1)}
          isDisabled={this.state.page >= lastPage}>
          <div className="right-arrow"></div>
        </Button>
      </div>
    );
  }

  render() {
    if (this.props.isLoading || this.props.hasData === false) return null;
    return (
      <div className="component-pagination-2">
        {this.renderPagePosition()}
        {this.renderPageControls()}
      </div>
    );
  }
}
