/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { createApiAction } from 'api/core';

const requestPaymentHelp = createApiAction({
  method: 'post',
  url: '/api/onboarding/payment/requestHelp',
  requestBodyMapper: r => r
});

export default requestPaymentHelp;
