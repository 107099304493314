/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { asApiAction } from 'api/core/ApiAction';
import { isFetchAction, isSuccessAction } from 'api/core/utils';
import NetworkActionType from 'config/constants/network/NetworkActionType';
import CorrelationId from 'config/constants/network/NetworkCorrelationId';
import { isNukeStateAction, newState } from './utils';

export const InitiateConnection = (state = {}, action) => {
  if (action.type === NetworkActionType.InitiateConnection) {
    return newState(state, {
      [action.companyId]: {
        ...state[action.companyId],
        loading: action.loading,
        relationshipContext: action.relationshipContext
      }
    });
  }

  if (isSuccessAction(action, 'getLinkAddressBooks')) {
    if (!state[action.meta.params.id]) return state;

    let nextState = {...state};

    action.payload.entities.forEach((linkEntity) => {
      let links = [...linkEntity.value.links];
      nextState[linkEntity.id] = {
        ...state[linkEntity.id],
        links: links.map(link => link.addressBook)
      };
    });

    return newState(state, {
      ...nextState
    });
  }

  if (isFetchAction(action, 'createConnectionRequest')
    && action.payload.params.companyProfile) {
    let companyId = `1${action.payload.params.companyProfile.id}`;

    if (state[companyId]) {
      return newState(state, {
        [companyId]: {
          ...state[companyId],
          sendLoading: true,
          sendError: null
        }
      });
    }
  }

  const apiAction = asApiAction(action);
  if (apiAction?.isFailure && apiAction.isEndpointOfName('createConnectionRequest')) {
    const { companyProfile } = apiAction.getRequestParams();

    if (companyProfile?.id) {
      const companyId = `1${companyProfile.id}`;

      if (state[companyId]) {
        const { failureReason } = apiAction.getResponseBody();

        let error = true;

        if (apiAction.getMessage(
          ({ context, type }) => type === 'Validation' && context === 'NoMatch'
        )) {
          error = 'NoMatch';
        } else if (apiAction.getMessage(
          ({ context, type }) => type === 'Validation' && context === 'ClientReferenceId'
        )) {
          error = 'SecureConnectError';
        } else if (failureReason === 'InvalidClientSecret') {
          error = 'SecureConnectError';
        }

        return newState(state, {
          [companyId]: {
            ...state[companyId],
            sendLoading: false,
            sendError: error
          }
        });
      }
    }
  }

  if (isSuccessAction(action, 'createConnectionRequest')
    && action.meta.params
    && action.meta.params.companyProfile) {
    let companyId = `1${action.meta.params.companyProfile.id}`;

    if (state[companyId]) {
      return newState(state, {
        [companyId]: {
          ...state[companyId],
          sendLoading: false,
          sendSuccess: action.payload.connected,
          sendError: action.payload.connected ? false : 'SecureConnectError'
        }
      });
    }
  }

  if (action.type === 'CloseModal' && action.modalType === 'ConnectCompany') {
    return newState(state,
      Object.keys(state).reduce((value, key) => {
        return {
          ...value,
          [key]: {
            ...state[key],
            sendError: null
          }
        };
      }, {})
    );
  }

  if (isNukeStateAction(action, 'InitiateConnection')) {
    return {};
  }

  return state;
};

export const SuggestLinks = (state = {}, action) => {
  if (isFetchAction(action, 'getCompanies')
    && action.meta.correlationId === CorrelationId.SuggestLinkSearch) {
    return newState(state, {
      searchPhrase: action.payload.params.searchPhrase,
      loading: (action.payload.params.page === 1)
    });
  }

  if (isSuccessAction(action, 'getCompanies')
    && action.meta.correlationId === CorrelationId.SuggestLinkSearch) {
    return newState(state, {
      companies: (state.companies && action.meta.params.page > 1)
        ? [...state.companies, ...action.payload.references] : action.payload.references,
      loading: false
    });
  }

  if (action.type === NetworkActionType.ClearSearch
    && action.correlationId === CorrelationId.SuggestLinkSearch) {
    return newState(state, {
      companies: [],
      searchPhrase: '',
      loading: false
    });
  }

  return state;
};
