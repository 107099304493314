/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { toEnumObject } from 'config/constants/utils';

const EntityContext = toEnumObject([
  'Identity',
  'Navigation',
  'Settlement'
]);

export default EntityContext;
