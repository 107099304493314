/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import getApplicationSetting, { AppSetting } from './getApplicationSetting';

const isCapabilityEnabled = (state, capability) => {
  const capabilitySettings = getApplicationSetting(state, AppSetting.Capabilities);

  return capabilitySettings && capabilitySettings.apiCapabilities
    ? capabilitySettings.apiCapabilities.includes(capability)
    : false;
};

export default isCapabilityEnabled;