/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import t from 'tcomb-validation';
import StringMessages from './string.messages';

const Memo = t.subtype(t.maybe(t.String), (value) => {
  return (value || '').length <= 100;
}, 'Memo');

Memo.getValidationErrorMessage = (value, path, context) => {
  if (value && value.length && value.length > 100 && context && context.intl) {
    return context.intl.formatMessage(StringMessages.TooLong, { maxLength: 100 });
  }

  return '';
};

export {
  Memo
};
