/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React from 'react';
import Button from 'components/Button';
import Select from 'components/Form/Inputs/Select';
import Icon, { IconTypes } from 'components/Icon';
import Truncate from 'components/Truncate';
import { isVerifiableAccount } from 'schemas/payments/bankAccount/isAccount';
import FlagIcon from 'components/Icon/FlagIcon';
import Messages from './index.messages';
import connect from './V2.connect';
import './index.scss';

const WarningWrapper = ({
  children
}) => (
  <div className="selectPaymentMethod warningWrapper">
    <Icon type={IconTypes.Alert} style={{ marginRight: 8 }} />
    {children}
  </div>
);

const SelectPaymentMethodV2 = ({
  bankAccounts,
  disabled,
  hasError,
  onAddAccount,
  onAddSignature,
  onChange: originalOnChange,
  onVerifyAccount,
  placeholder,
  value
}) => {
  const {
    canUseCreditCard,
    id: paymentMethodId,
    isCreditCard,
    isSendingPayment,
    isWhitelistedToUseUnverfiedAccount,
    hasSignature
  } = value || {};

  const options = bankAccounts.map((account) => {
    const {
      accountName,
      accountNumberSuffix,
      currencyCode,
      id
    } = account;

    const formattedName = `${accountName} - ${accountNumberSuffix}`;
    let richName = formattedName;

    let hasIntlCurrency = bankAccounts.some(a => a.currencyCode !== 'USD');

    return {
      value: id,
      text: formattedName,
      richText: <div>
        <Truncate value={richName} maxLength={50} />
        {hasIntlCurrency && currencyCode === 'CAD'
          ? (
            <div className="subtext" style={{fontSize: '12px'}}>
              <FlagIcon country="CA"/> CAD
            </div>
          )
          : null}
        {hasIntlCurrency && currencyCode === 'USD'
          ? (
            <div className="subtext" style={{ fontSize: '12px' }}>
              <FlagIcon country="US" /> USD
            </div>
          )
          : null}
      </div>
    };
  });

  if (canUseCreditCard) {
    options.push({
      value: 'CreditCard',
      text: Messages.CreditCard.defaultMessage,
      richText: <Messages.CreditCard.Message />
    });
  }

  if (options.length === 0) {
    // no accounts
    return (
      <div className="selectPaymentMethod addBankAccountBox">
        <a onClick={() => onAddAccount()}>
          <Messages.PlusAction.Message
            action={<Messages.AddBankAccount.Message />}
          />
        </a>
      </div>
    );
  }

  options.push({
    // Not a valid field/model - just passing selection along to the onChange function
    value: 'AddAccount',
    text: Messages.AddBankAccount.defaultMessage,
    richText: (
      <a>
        <Messages.PlusAction.Message
          action={<Messages.AddBankAccount.Message />}
        />
      </a>
    ),
    onClick: () => onAddAccount(
      (newId) => {
        // We aren't actually getting the bank account ID back here, but at least we'll clear out
        // the payment method selection and the user will be able to select the new payment account
        // from the list 🤷‍♂️
        const paymentAccount = bankAccounts.find(({ id }) => id === newId);
        originalOnChange(paymentAccount);
      }
    ),
    style: {
      borderTop: '1px solid #ddd'
    }
  });

  const onChange = (newId) => {

    // Adding an account is not a valid option, null it out - the modal to add the account was
    // launched when clicked and will update the state when done.
    if (newId === 'AddAccount') {
      originalOnChange(null);
      return;
    }

    if (newId === 'CreditCard') {
      originalOnChange({
        accountName: 'Credit Card',
        isCreditCard: true,
        numberRequiredSignatures: 1,
        statuses: [],
        availableActions: []
      });
      return;
    }

    const paymentAccount = bankAccounts.find(({ id }) => id === newId);

    originalOnChange(paymentAccount);
  };

  return (
    <>
      <Select
        disabled={!options.length || disabled}
        hasError={hasError}
        onChange={onChange}
        placeholder={(
          !placeholder && options.length === 0
            ? 'No payment methods available'
            : placeholder
        )}
        selectOptions={options}
        value={isCreditCard ? 'CreditCard' : paymentMethodId}
      />
      {paymentMethodId ? (
        <>
          {isSendingPayment && !hasSignature ? (
            <WarningWrapper>
              <Button anchor={true} onClick={() => onAddSignature(paymentMethodId)}>
                <Messages.MissingSignature.Message />
              </Button>
            </WarningWrapper>
          ) : null}
          {isVerifiableAccount(value) && !isWhitelistedToUseUnverfiedAccount ? (
            <WarningWrapper>
              <Button anchor={true} onClick={() => onVerifyAccount(paymentMethodId)}>
                <Messages.PendingVerification.Message />
              </Button>
            </WarningWrapper>
          ) : null}
        </>
      ) : null}
    </>
  );
};


export default connect(SelectPaymentMethodV2);
