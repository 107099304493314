/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { createEntityReference, getEntity } from 'schemas/state';
import { CompanyFeeSettings } from 'schemas/network/session';
// import logError from 'services/ErrorService';

const getCompanyFeeSettings = (state, { notExpected = false, returnNull = false } = {}) => {
  const companyFeeSettings = getEntity(state, createEntityReference('current', CompanyFeeSettings.meta.name));

  if (!notExpected && !companyFeeSettings) {
    // logError('Expected companyFeeSettings, but companyFeeSettings is missing.', null, { includeStackTrace: true });
  }

  // Returning at least an empty object so that at least a JS error doesn't
  // take React down. We have the logged error, so you there is visiblity into
  // a problem
  return companyFeeSettings || (returnNull ? null : {});
};

export default getCompanyFeeSettings;