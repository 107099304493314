/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { MOBILE_BREAK } from 'config/constants';
import RenderMobile from 'decorators/RenderMobile';
import RouteLink from 'components/Link/RouteLink';
import Slogan from 'components/Slogan';
import Copyright from 'components/Copyright';
import { Column, Row } from 'components/Viewstrap/Grid';
import { LinkRef } from 'links';
import { FormattedMessage } from 'react-intl';
import messages from './index.messages.js';
import { MapStateToProps } from './index.connect';
import './index.scss';

function FooterLink({linkRef, children}) {
  return (
    <RouteLink
      className="footer-link"
      target="vpmarketing"
      linkRef={linkRef || LinkRef.marketingSite.index}>
      {children}
    </RouteLink>
  );
}

/** @docsignore */
function VPLink() {
  return <FooterLink>Viewpost.com</FooterLink>;
}

class Footer extends Component {
  static propTypes = {
    inExternalTestEnvironment: PropTypes.bool,
    padLayout: PropTypes.bool
  };

  static getHeight() {
    return window.innerWidth < MOBILE_BREAK ? 140 : 140;
  }

  constructor(props) {
    super(props);
  }

  getFooterLinks() {
    let links = [
      {
        title: <FormattedMessage {...messages.links.AboutUs} />,
        link: LinkRef.marketingSite.aboutUs
      },
      {
        title: <FormattedMessage {...messages.links.Terms} />,
        link: LinkRef.marketingSite.terms
      },
      {
        title: <FormattedMessage {...messages.links.Privacy} />,
        link: LinkRef.marketingSite.privacy
      },
      {
        title: <FormattedMessage {...messages.links.Security} />,
        link: LinkRef.marketingSite.security
      },
      {
        title: <FormattedMessage {...messages.links.Contact} />,
        link: LinkRef.marketingSite.contact
      },
      {
        title: <FormattedMessage {...messages.links.Support} />,
        link: LinkRef.help.main
      }
    ];

    return links.map(({link, title}, id) => (
      <FooterLink key={id} linkRef={link}>{title}</FooterLink>
    ));
  }

  renderDesktopContent() {
    return (
      <Row>
        <Column size={6}>
          <p className="dividers">
            <VPLink />
            {this.getFooterLinks()}
          </p>
          {this.props.inExternalTestEnvironment ? <span id="testDisclaimer">
            <FormattedMessage {...messages.disclaimers.TestEnvironment} />
          </span> : null}
          <Copyright />
          <Slogan />
        </Column>
      </Row>
    );
  }

  renderMobileContent() {
    return (
      <div>
        <p className="dividers">
          {this.getFooterLinks()}
        </p>
        <p>
          <VPLink />
        </p>
        {this.props.inExternalTestEnvironment ? <span id="testDisclaimer">
          <FormattedMessage {...messages.disclaimers.TestEnvironment} />
        </span> : null}
        <Copyright />
      </div>
    );
  }

  render() {
    let pad = this.props.padLayout ? 35 : 0;

    return (
      <div className="viewstrap">
        <footer id="siteFooter" style={{height: Footer.getHeight() + pad}}>
          <div className="container">
            {this.props.isMobile
              ? this.renderMobileContent()
              : this.renderDesktopContent()}
          </div>
        </footer>
      </div>
    );
  }
}

const WrappedFooter = connect(MapStateToProps)(RenderMobile(Footer));
WrappedFooter.getHeight = Footer.getHeight;

export default WrappedFooter;
