/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import EmailAddress from 'components/EmailAddress';
import PhoneNumber from 'components/PhoneNumber';
import AnalyticsContext from 'components/AnalyticsCategory/context';
import { LOADING_ERROR_TIMEOUT } from 'config/constants';
import { generateFakeGuid } from 'config/utils';
import logError from 'services/ErrorService';
import { isEnabled } from 'services/FeatureToggles';
import classNames from 'classnames';
import BaseLoadingIndicator from './Base';
import { MessageMoonLoadingIndicator } from './Moon';
import Messages from './index.messages';
import './index.scss';

const LoadingIndicator = ({
  centerInPage,
  children,
  disableTimeout,
  loadingIds,
  style,
  variant
}) => {
  const [ error, setError ] = useState(false);
  const {
    category: analyticsCategory
  } = useContext(AnalyticsContext) || {};

  useEffect(
    () => {
      if (disableTimeout) return;

      const timeout = setTimeout(
        () => setError({
          error: true,
          errorGuid: generateFakeGuid().toUpperCase()
        }),
        LOADING_ERROR_TIMEOUT
      );

      return () => {
        clearTimeout(timeout);
      };
    },
    []
  );

  useEffect(
    () => {
      if (error) {
        const { errorGuid } = error;
        logError(
          'User saw loading error',
          {
            errorId: errorGuid,
            context: analyticsCategory,
            loadingIds
          }
        );
      }
    },
    [ !!error ]
  );

  if (error) {
    const { errorGuid } = error;
    return (
      <div
        className={classNames(
          'loading-indicator',
          variant
        )}
        style={style}
      >
        <div className="loading-error">
          <div className="message">
            <FormattedMessage {...Messages.ErrorMessage} />
          </div>
          <div className="message">
            <FormattedMessage {...Messages.ErrorMessage2} values={{
              phone: <PhoneNumber value="8882489190" />,
              email: <EmailAddress value="support@viewpost.com" />
            }} />
            <br/>
            <FormattedMessage
              {...Messages.ErrorMessage3}
              values={{
                id: errorGuid
              }}
            />
          </div>
        </div>
      </div>
    );
  }

  const content = children || <FormattedMessage {...Messages.Default} />;

  if (variant === 'moon') {
    return (
      <MessageMoonLoadingIndicator
        centerInPage={centerInPage}
        style={style}
      >
        {content}
      </MessageMoonLoadingIndicator>
    );
  }

  return (
    <BaseLoadingIndicator
      centerInPage={centerInPage}
      style={style}
      variant={variant}
    >
      {content}
    </BaseLoadingIndicator>
  );
};

LoadingIndicator.propTypes = {
  variant: PropTypes.oneOf(['default', 'light', 'small', 'moon']),
  disableTimeout: PropTypes.bool
};

LoadingIndicator.defaultProps = {
  variant: isEnabled('newLoadingIndicator') ? 'moon' : 'default',
  disableTimeout: false
};

export default LoadingIndicator;
