/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, { useMemo } from 'react';

import securityQuestionsSubmit from 'api/session/securityQuestionsSubmit';

import SecurityQuestionPrompt from 'containers/Logon/SecurityQuestionPrompt';

import createExecuteApiResultHooks from 'hooks/apiResult/createExecuteApiResultHooks';

const SubmitHooks = createExecuteApiResultHooks({
  endpoint: securityQuestionsSubmit
});

const SecurityQuestionLogonPrompt = ({
  error,
  onUpdate,
  securityQuestions
}) => {
  // Hooks
  const executeSubmit = SubmitHooks.useExecuteRequest();
  const isSubmitting = SubmitHooks.useIsLoading();

  const onSubmit = useMemo(
    () => async (answers) => {
      const [ , action ] = await executeSubmit(answers);
      onUpdate(action.payload.result);
    },
    [ executeSubmit ]
  );

  // Render
  return (
    <SecurityQuestionPrompt
      error={error}
      isSubmitting={isSubmitting}
      onSubmit={onSubmit}
      securityQuestions={securityQuestions}
    />
  );
};

export default SecurityQuestionLogonPrompt;