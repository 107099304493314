/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, { memo } from 'react';
import { Redirect, Route, Switch } from 'react-router';

import InAppLayout, { EnsureInAppWorkflowAccess } from 'containers/InAppLayout';

import { wrapImport } from 'decorators/ModuleLoader';

import useIsPersonalPortalUser from 'hooks/identity/useIsPersonalPortalUser';

import { BusinessBillsRoutes, LegacyPayablesRoutes, PersonalPortalBillsRoutes } from './bills';
import { BusinessDashboardRoutes } from './dashboard';
import { BusinessInvoicesRoutes, LegacyReceivablesRoutes, PersonalInvoicesRoutes } from './invoices';
import NetworkRoutes from './network';
import {
  BusinessPaymentsRoutes,
  CheckbookLegacyRedirects,
  PersonalPortalPaymentsRoutes
} from './payments';
import {
  BusinessSettingsRoutes,
  LegacyProfileRedirects,
  LegacyAngularSettingsRedirects,
  PersonalPortalSettingsRoutes
} from './settings';
import SyncRoutes from './sync';
import { AuthRedirect, InAppNotFoundView } from '../utils';

const FileImportListView = wrapImport(
  () => import(/* webpackChunkName: "views" */ 'views/FileImport')
);
const TasksList = wrapImport(() => import(
  /* webpackChunkName: "views" */ 'views/Dashboard/Tasks')
);
const PersonalAutoPay = wrapImport(() => import(
  /* webpackChunkName: "consumerViews" */ 'views/PersonalPortal/AutoPay'
));
const PersonalPortalFees = wrapImport(() => import(
  /* webpackChunkName: "consumerViews" */ 'views/PersonalPortal/Fees'
));
const PersonalPortalUserSettings = wrapImport(() => import(
  /* webpackChunkName: "consumerViews" */ 'views/PersonalPortal/Settings'
));

export const InAppRoots = [
  '/dashboard',
  '/invoices',
  '/receivables',
  '/bills',
  '/payables',
  '/payments',
  '/checkbook',
  '/network',
  '/settings',
  '/profile',
  '/sync',
  '/fileimport',
  '/tasks',
  '/autoPay',
  '/fees',
  '/userSettings'
];

export const InAppPages = [
  // Claiming the root since its likely someone is trying to get into the app
  '/'
];

const PersonalAppRoutes = (
  <Switch>
    <Route path="/" exact={true}>
      <AuthRedirect />
    </Route>
    <Route path="/dashboard">
      <AuthRedirect />
    </Route>
    <Route path="/autoPay" exact={true} component={PersonalAutoPay} />
    <Route path="/bills" component={PersonalPortalBillsRoutes} />
    <Route path="/fees" exact={true} component={PersonalPortalFees} />
    <Route path="/invoices" component={PersonalInvoicesRoutes} />
    <Route path="/payments" component={PersonalPortalPaymentsRoutes} />
    <Route path="/settings" component={PersonalPortalSettingsRoutes} />
    <Route path="/userSettings" exact={true} component={PersonalPortalUserSettings} />
    {InAppNotFoundView}
  </Switch>
);

const BusinessAppRoutes = (
  <Switch>
    <Route path="/" exact={true}>
      <AuthRedirect />
    </Route>
    <Route path="/dashboard" component={BusinessDashboardRoutes} />
    <Route path="/invoices" component={BusinessInvoicesRoutes} />
    <Route path="/receivables" component={LegacyReceivablesRoutes} />
    <Route path="/bills" component={BusinessBillsRoutes} />
    <Route path="/payables" component={LegacyPayablesRoutes} />
    <Route path="/payments" component={BusinessPaymentsRoutes} />
    <Route path="/checkbook" component={CheckbookLegacyRedirects} />
    <Route path="/network" component={NetworkRoutes} />
    <Route path="/settings/ng" component={LegacyAngularSettingsRedirects} />
    <Route path="/settings" component={BusinessSettingsRoutes} />
    <Route path="/profile" component={LegacyProfileRedirects} />
    <Route path="/sync" component={SyncRoutes} />
    <Redirect path="/fileImport" exact={true} to="/fileImport/list" />
    <Route path="/fileImport/list" exact={true} component={FileImportListView} />
    <Route path="/tasks" exact={true} component={TasksList} />
    {InAppNotFoundView}
  </Switch>
);

const InAppRoutes = memo(() => {
  // Hooks
  const isPersonalPortal = useIsPersonalPortalUser();

  // Render
  return (
    <EnsureInAppWorkflowAccess>
      <InAppLayout>
        {isPersonalPortal
          ? PersonalAppRoutes
          : BusinessAppRoutes}
      </InAppLayout>
    </EnsureInAppWorkflowAccess>
  );
});

export default InAppRoutes;