/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

export const getUseAppAuthorizationStatus = state => state
  ?.entities
  ?.UseAppAuthorizationStatus
  ?.current
  ?.value;

const isAuthorizedToUseApp = state => !!getUseAppAuthorizationStatus(state)?.isAuthorizedToUseApp;

export default isAuthorizedToUseApp;