/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { isFailAction, isFetchAction, isSuccessAction } from 'api/core/utils';
import { CorrelationId } from 'config/constants/selectCompany';
import { newState } from './utils';

export default function selectCompany(state = {}, action) {

  if (isFetchAction(action, 'getCompanies')
    && action.meta.correlationId === CorrelationId.SelectCompanyContainer) {
    return newState(state, {
      manuallyAddedCompany: null,
      searchPhrase: action.payload.params.searchPhrase,
      loadingCompanies: (action.payload.params.page === 1)
    });
  }

  if (isSuccessAction(action, 'getCompanies')
    && action.meta.correlationId === CorrelationId.SelectCompanyContainer) {
    return newState(state, {
      manuallyAddedCompany: null,
      companies: (state.companies && action.meta.params.page > 1)
        ? [...state.companies, ...action.payload.references] : action.payload.references,
      pagination: action.payload.pagination,
      loadingCompanies: false
    });
  }

  if (isFetchAction(action, 'updateCompany')
    || isFetchAction(action, 'createAddressBook')
    || (isFetchAction(action, 'getCompany')
      && action.meta.correlationId === CorrelationId.SelectCompanyContainer)) {
    return newState(state, {
      manuallyAddedCompany: null,
      loadingCompany: true
    });
  }

  if (isFailAction(action, 'updateCompany')
    || isFailAction(action, 'createAddressBook')
    || (isFailAction(action, 'getCompany')
      && action.meta.correlationId === CorrelationId.SelectCompanyContainer)) {
    return newState(state, {
      manuallyAddedCompany: null,
      loadingCompany: false
    });
  }

  if (isSuccessAction(action, 'updateCompany')
    || isSuccessAction(action, 'createAddressBook')
    || (isSuccessAction(action, 'getCompany')
      && action.meta.correlationId === CorrelationId.SelectCompanyContainer)) {
    return newState(state, {
      manuallyAddedCompany: action.payload.references[0],
      loadingCompany: false
    });
  }

  return state;
}
