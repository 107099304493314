/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import t from 'tcomb-validation';

import PaymentActions from 'config/constants/PaymentActions';
import EntityContext from 'config/constants/schemas/EntityContext';

import { addSchema } from 'schemas';
import { LooseAddress } from 'schemas/common/address';
import { createAvailableActionsStateType } from 'schemas/common/availableActions';
import { PaymentTracker } from 'schemas/payments/tracker';

import { ApprovalDetails } from './approvals';
import { CreditCardType } from './creditCard';
import PaymentBase from './PaymentBase';

const PaymentDetails = addSchema(
  PaymentBase.extend(
    {
      companyAddress: t.maybe(LooseAddress),
      gross: t.maybe(t.Number),
      creditMemoTotal: t.maybe(t.Number),
      remittanceDiscrepancyAmount: t.maybe(t.Number),
      remittanceDiscrepancyMessage: t.maybe(t.String),
      remittanceTotal: t.maybe(t.Number),
      discounts: t.maybe(t.Number),
      lateFees: t.maybe(t.Number),
      adjustments: t.maybe(t.Number),
      unapplied: t.maybe(t.Number),
      isVoided: t.Boolean,
      memo: t.maybe(t.String),
      maskedCardNumber: t.maybe(t.String),
      cardType: t.maybe(CreditCardType),
      rerouteReason: t.maybe(t.String),
      paymentTracker: t.maybe(PaymentTracker),
      approvalDetails: t.maybe(ApprovalDetails),
      paymentBreakdown: t.maybe(t.list(PaymentBase)),
      specialDeliveryAccountNumber: t.maybe(t.String),
      routingAddress: t.maybe(LooseAddress),
      printedPageCount: t.maybe(t.Number),
      availableActionsState: createAvailableActionsStateType(PaymentActions),

      isOumc: t.maybe(t.Boolean)
    },
    'PaymentDetails'
  ),
  { contexts: [ EntityContext.Settlement ] }
);

export default PaymentDetails;