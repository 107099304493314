/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

function sortFieldsQueryMapper(sortFieldNameMapping, sortParamName = 'sort') {
  return (inputParams) => {

    let sortFields = inputParams[sortParamName];
    if (sortFields && sortFields.length > 0) {
      inputParams.sort = sortFields.map((sortField) => {
        // map name if specified
        let name = sortField.name;
        if (sortFieldNameMapping && sortFieldNameMapping[name]) {
          name = sortFieldNameMapping[name];
        }

        // translate to standard api format: &sort=field1,-field2,field3
        let direction = sortField.isDescending ? '-' : '';
        return `${direction}${name}`;
      }).join(',');
      if (sortParamName !== 'sort') {
        inputParams[sortParamName] = null;
      }
    }
    return inputParams;
  };
}

export default sortFieldsQueryMapper;