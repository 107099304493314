/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */
/* eslint max-len:0 */

import { defineMessages } from 'react-intl';

let common = defineMessages({
  Required: {
    id: 'FormLegend.Required',
    description: 'legend to indicate the \'*\' means required',
    defaultMessage: 'required'
  }
});

export default {
  ...common
};
