/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, { useEffect, useState } from 'react';
import logError from 'services/ErrorService';
import FatalErrorLayout from 'layouts/FatalError';
import './notFound.scss';

const NotFoundView = () => (
  <FatalErrorLayout className="view-error404 violet">

    <p className="error-code">
      <strong>404:</strong> Page Not Found
    </p>

    <h1>
        Hhmmm, it’s around here somewhere.
    </h1>

    <p className="error-message">
      <strong>You zigged, we zagged.</strong>
      Or vice versa. Anyway, something has happened that was obviously not your fault. We apologize.
      <br />
      <a href="/">Take me back</a>
    </p>

    <div className="space-background"></div>
    <div className="astronaut-ernie"></div>

  </FatalErrorLayout>
);

NotFoundView.pageTitle = 'Page Not Found - Viewpost';

export const ErrorLoggingNotFoundView = () => {
  const [ hasLogged, setHasLogged ] = useState(false);

  useEffect(
    () => {
      if (!hasLogged) {
        // Shouldn't need to log anything else, the current page URL should be gotten for free.
        logError('User saw Not Found Ernie.');
        setHasLogged(true);
      }
    },
    []
  );

  return <NotFoundView />;
};

export default NotFoundView;
