/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, { useEffect } from 'react';

import useIdentity from 'hooks/identity/useIdentity';
import usePathname from 'hooks/location/usePathname';
import useQueryParams from 'hooks/location/useQueryParams';
import useFuncRef from 'hooks/react/useFuncRef';

import GoogleAnalytics from 'services/GoogleAnalyticsService';

import AnalyticsActionsContext from './context';

const APP_VERSION = process.build.VERSION;

const AnalyticsService = ({
  analyticsId,
  children
}) => {
  // Hooks
  const { current: trackEvent } = useFuncRef(
    () => (...params) => {
      if (!GoogleAnalytics.isEnabled()) return;

      GoogleAnalytics.trackEvent(...params);
    }
  );

  const { userId } = useIdentity({ notExpected: true });

  useEffect(
    () => {
      GoogleAnalytics.initialize({
        trackerId: analyticsId || process.config.googleAnalyticsId,
        userId,
        appVersion: APP_VERSION
      });
    },
    [ analyticsId, userId ]
  );

  // Render
  return (
    <AnalyticsActionsContext.Provider
      value={{ trackEvent }}
    >
      {children}
    </AnalyticsActionsContext.Provider>
  );
};

export default AnalyticsService;

export const AnalyticsPathService = () => {
  // Hooks
  const pathname = usePathname();
  const { src: srcToken } = useQueryParams([ 'src' ]);

  useEffect(
    () => {
      if (!GoogleAnalytics.isEnabled()) return;

      GoogleAnalytics.trackPageView(
        pathname,
        { srcToken }
      );
    },
    [ pathname, srcToken ]
  );

  // Render
  return <></>;
};
