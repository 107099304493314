/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { sortBy } from 'lodash';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import t from 'tcomb-validation';

import CheckBox from 'components/Form/Inputs/CheckBox';

import { createCacheActions } from 'actions/references';

import { getCountries } from 'api/network';

import {
  createMaxLengthStringType,
  createRegexType
} from 'schemas/common/string';
import {
  AddressLineStrict,
  CityStrict,
  USStates,
  PostalCode
} from 'schemas/common/address';

import FlagIcon from 'components/Icon/FlagIcon';
import CountrySelect from 'components/Form/Inputs/CountrySelect';
import TextBox from 'components/Form/Inputs/TextBox';

import FormJSX, { FieldOption } from 'components/Form/FormJSX';

import useStableRef from 'hooks/utils/useStableRef';

const GetCountries = createCacheActions(
  'GetCountries',
  getCountries
);

function isAddressEmpty(address) {
  let notEmpty = address?.line1?.length > 0
    || address?.line2?.length > 0
    || address?.line3?.length > 0
    || address?.line4?.length > 0
    || address?.city?.length > 0
    || address?.stateProvince?.length > 0
    || address?.postalCode?.length > 0
    || address?.country?.length > 0;
  return !notEmpty;
}

function isIntl(address) {
  let isUS = address?.country === 'US' || !address?.country;
  return !isUS;
}

function createSchema(value, lineCount, countries) {
  let schema = {
    isValid: t.maybe(t.Boolean),
    line1: AddressLineStrict,
    city: CityStrict,
    country: t.enums({ US: 'United States' })
  };

  if (lineCount > 1) {
    schema.line2 = t.maybe(createMaxLengthStringType(240));
  }
  if (lineCount > 2) {
    schema.line3 = t.maybe(createMaxLengthStringType(240));
  }
  if (lineCount > 3) {
    schema.line4 = t.maybe(createMaxLengthStringType(240));
  }

  let currentCountry = null;
  if (countries?.length > 1) {
    let countryEnum = {};
    countries?.forEach((c) => {
      countryEnum[c.code] = <div>
        <FlagIcon country={c.code} />
        <div style={{display: 'inline-block', verticalAlign: 'middle', marginLeft: '10px'}}>
          {c.name}
        </div>
      </div>;

      if (c.code === value?.country) {
        currentCountry = c;
      }
    });
    schema.country = t.enums(countryEnum);
  }

  if (isIntl(value)) {
    // INTL validation
    if (currentCountry?.requireStateOrProvince !== false) {
      if (currentCountry?.stateProvinceValues?.length) {
        // Dynamic stateProvince options from server
        let options = {};
        sortBy(currentCountry?.stateProvinceValues, s => s.name)
          .forEach((s) => {
            options[s.value] = s.name;
          });
        schema.stateProvince = t.enums(options);
      } else {
        schema.stateProvince = createMaxLengthStringType(150);
      }
    }
    if (currentCountry?.requirePostalCode !== false) {
      if (currentCountry?.postalCodeRegex) {
        // Dynamic postal code validation
        let errorMsg = 'Postal code does not follow correct format for this country.';
        if (currentCountry?.postalCodeFormatExamples?.length) {
          errorMsg += ` e.g. ${currentCountry?.postalCodeFormatExamples.join(', ')}`;
        }
        schema.postalCode = createRegexType(currentCountry?.postalCodeRegex, errorMsg);
      } else {
        schema.postalCode = createMaxLengthStringType(20);
      }
    }
  } else {
    // Default to US validation
    schema.stateProvince = USStates;
    schema.postalCode = PostalCode;
  }

  return t.struct(schema);
}

const AddressInput = ({
  frame: addFrame,
  hasError,
  onChange,
  disabled,
  value: originalValue,

  /** Provide custom content for the "Include Address" checkbox for t.maybe(Address) */
  includeAddressLabel = 'Include Address',

  type
}) => {
  // Hooks
  const dispatch = useDispatch();
  const formRef = useRef();
  const [ lineCount, setLineCount ] = useState(1);

  // TODO: this check may not work with the FormV2 component
  const isOptional = type?.meta?.kind === 'maybe';

  // have a way for UI to opt in/out of address
  // i.e. get indication from the user there is no address when address is optional
  const [includeAddress, setIncludeAddress] = useState(true);
  const [lastAddress, setLastAddress] = useState(null);
  const toggleAddress = (isOn) => {
    setLastAddress(originalValue);
    setIncludeAddress(isOn);
    onChange?.(isOn ? lastAddress : null);
  };

  // useStableRef allows us to not worry if an individual field has changed
  // because we can rely on referential equality to be kept when there isn't
  // any changes
  const valueProp = useStableRef(originalValue);

  const countries = useSelector(
    state => GetCountries.getCacheResponse(state)?.countries,
    shallowEqual
  );

  // Effect to call country data if we don't already have it
  useEffect(
    () => {
      if (!countries) {
        dispatch(GetCountries.makeApiCall({}));
      }
    },
    [ !!countries ]
  );

  const modelType = useMemo(
    () => createSchema(
      valueProp,
      lineCount,
      countries
    ),
    [
      valueProp,
      lineCount,
      countries
    ]
  );

  // Automatically expand line count when there is data
  useEffect(
    () => {
      let minLineCount = lineCount;
      if (valueProp?.line4?.length) {
        minLineCount = 4;
      } else if (valueProp?.line3?.length) {
        minLineCount = 3;
      } else if (valueProp?.line2?.length) {
        minLineCount = 2;
      }

      setLineCount(
        prevLineCount => minLineCount > prevLineCount
          ? minLineCount
          : prevLineCount
      );
    },
    [
      !!valueProp?.line1,
      !!valueProp?.line2,
      !!valueProp?.line3,
      !!valueProp?.line4
    ]
  );

  // Effect for normalizing the value
  useEffect(
    () => {
      if (isAddressEmpty(valueProp)) {
        // If all values are blank, consider this a null value so
        // t.maybe(Address) can be used for optional addresses
        if (valueProp != null) {
          onChange(null);
        }

        return;
      }

      // treat nulls as US
      let newCountry = valueProp?.country ?? 'US';
      if (newCountry === 'USA') {
        // normalize previous default
        newCountry = 'US';
      }

      // auto capitalize postals
      let newPostalCode = valueProp?.postalCode?.toUpperCase();

      // auto adjust fields as needed
      if (newCountry !== valueProp?.country
        || newPostalCode !== valueProp?.postalCode) {
        onChange({
          ...valueProp,
          postalCode: newPostalCode,
          country: newCountry
        });
      }
    },
    [ valueProp ]
  );

  // Effect so the Address schema automatically validates/invalidates as the
  // value changes
  useEffect(
    () => {
      // Validity isn't relevent if there isn't a value
      if (valueProp == null) return;

      const isValid = t.validate(valueProp, modelType)?.isValid();

      if (valueProp.isValid !== isValid) {
        onChange({
          ...valueProp,
          isValid
        });
      }

    },
    [ modelType, valueProp ]
  );

  // Effect that causes the subform errors to display when the input is told
  // there is an error with it's value. using useLayoutEffect so this is run
  // before the render runs.
  useLayoutEffect(
    () => {
      if (hasError) {
        formRef.current?.validate();
      }
    },
    [ hasError ]
  );

  // The idea is the thing invoking this will prevent more than 4 lines to be
  // added
  const { current: onAddAddressLine } = useRef(
    () => setLineCount(lc => lc + 1)
  );

  const { current: onChangeCountry } = useRef(
    country => onChange({
      ...valueProp,
      country
    })
  );

  // Render
  // let labelWithCountry = 'Address';
  // if (isIntl(this.state.value)) {
  //   let country = this.props.countries.find(c => c.code === this.state.value?.country);
  //   labelWithCountry = `${country?.name ?? ''} Address`;
  // }
  const labelMap = {
    line1: 'Address',
    line2: '',
    line3: '',
    line4: '',
    city: 'City',
    stateProvince: isIntl(valueProp) ? 'State/Province/Region' : 'State',
    postalCode: isIntl(valueProp) ? 'Postal Code' : 'ZIP Code',
    country: 'Country'
  };

  const placeholderMap = {
    line1: isIntl(valueProp) ? '' : 'Street Address',
    line2: isIntl(valueProp) ? 'Line 2' : 'Suite, Unit, Building, etc.',
    line3: 'Line 3',
    line4: 'Line 4',
    city: 'City',
    stateProvince: isIntl(valueProp) ? 'State/Province/Region' : 'State',
    postalCode: isIntl(valueProp) ? 'Postal Code' : 'ZIP Code',
    country: 'Country'
  };

  const canSupportIntl = countries?.length > 1;

  const colMap = {
    line1: 12,
    line2: 12,
    line3: 12,
    line4: 12,
    city: 4,
    stateProvince: 4,
    postalCode: 4
  };

  const frameStyle = !addFrame ? {} : {
    backgroundColor: '#f7f7f7',
    padding: '8px',
    borderRadius: '2px'
  };

  // provide a way to explicitly indicate a null address
  let toggle = null;
  if (isOptional) {
    toggle = (
      <div>
        <CheckBox value={includeAddress} onChange={toggleAddress} variant="children">
          {includeAddressLabel}
        </CheckBox>
      </div>
    );
  }

  // don't show address form when opting into no address
  const addressForm = !includeAddress
    ? null
    : (
      <FormJSX
        ref={formRef}
        modelType={modelType}
        value={valueProp}
        onChange={onChange}
      >
        <FieldOption
          name="country"
          type="hidden"
          inputComponent={TextBox}
        />

        <FieldOption
          name="line1"
          cols={colMap.line1}
          label={labelMap.line1}
          disabled={disabled}
          placeholder={placeholderMap.line1}
          rightLabel={!canSupportIntl ? null : (
            <div>
              <CountrySelect
                variant="link"
                value={valueProp?.country ?? 'US'}
                countries={countries}
                onChange={onChangeCountry}
                disabled={disabled}
                hasError={hasError}
              />
            </div>
          )}
        />
        <FieldOption
          name="line2"
          disabled={disabled}
          cols={colMap.line2}
          label={labelMap.line2}
          placeholder={placeholderMap.line2}
        />
        <FieldOption
          name="line3"
          disabled={disabled}
          cols={colMap.line3}
          label={labelMap.line3}
          placeholder={placeholderMap.line3}
        />
        <FieldOption
          name="line4"
          disabled={disabled}
          cols={colMap.line4}
          label={labelMap.line4}
          placeholder={placeholderMap.line4}
        />

        {lineCount === 1 || lineCount === 2 ? (
          <div style={{ marginTop: '-10px' }} >
            {lineCount === 1 || lineCount === 2 ? (
              <a
                style={{ fontSize: '12px' }}
                onClick={onAddAddressLine}
              >
                + Add Address Line
              </a>
            ) : null}
          </div>
        ) : null}

        <FieldOption
          name="city"
          disabled={disabled}
          cols={colMap.city}
          label={labelMap.city}
        />
        <FieldOption
          name="stateProvince"
          disabled={disabled}
          cols={colMap.stateProvince}
          label={labelMap.stateProvince}
        />
        <FieldOption
          name="postalCode"
          disabled={disabled}
          cols={colMap.postalCode}
          label={labelMap.postalCode}
        />
      </FormJSX>
    );

  return (
    <div style={frameStyle}>
      {toggle}
      {addressForm}
    </div>
  );
};

export default AddressInput;