/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import RendererProvider from 'components/RendererProvider';

/**
  Configuration component used strictly with other components like <VerticalMenu>, <HoizontalNav>, or <SubMenu>.
  Supported props may differ depending on parent.
*/
export default class MenuItem extends Component {
  static propTypes = {
    divider: PropTypes.bool,
    onClick: PropTypes.func
  };

  static defaultProps = {
    divider: false
  };

  static contextTypes = {
    renderer: PropTypes.object
  };

  constructor(props) {
    super(props);
    this.renderMenuItem = this.renderMenuItem.bind(this);
  }

  renderMenuItem(props) {
    const { divider, ...safeProps } = this.props;

    if (this.context.renderer.renderMenuItem) {
      return this.context.renderer.renderMenuItem({
        ...props,
        ...safeProps,
        children: props.children
      });
    }

    return (
      <div {...props} {...safeProps}>
        {props.children}
      </div>
    );
  }

  render() {
    let children = this.props.children;
    if (this.context.renderer.renderMenuItem) {
      children = this.context.renderer.renderMenuItem(this.props);
    }

    return (
      <RendererProvider renderers={{renderMenuItem: this.renderMenuItem}}>
        {children}
      </RendererProvider>
    );
  }
}
