/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { createApiAction } from 'api/core';
import EntityContext from 'config/constants/schemas/EntityContext';
import { createEntity } from 'schemas/state';
import { CompanyTask } from 'schemas/tasks';

export const getTasks = createApiAction({
  method: 'get',
  url: '/api/user/tasks',
  responseMapper: ({ items }) => ({
    entities: items.map(
      ({
        id,
        controlType,
        domainId,
        message
      }) => createEntity(
        id,
        CompanyTask.meta.name,
        {
          id,
          taskEntityType: controlType,
          taskEntityId: domainId,
          message
        }
      ))
  })
});

export const hideTask = createApiAction({
  method: 'delete',
  url: '/api/user/tasks/:id',
  markUpdated: {
    entityTypes: [ CompanyTask.meta.name ],
    contexts: [ EntityContext.Navigation ]
  }
});
