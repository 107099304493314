/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, { useState } from 'react';

import HeaderText from 'components/HeaderText';
import { Row, Column } from 'components/Viewstrap/Grid';
import BulletedList, { Bullet } from 'components/BulletedList';
import FormattedDate from 'components/FormattedDate';

import AssistedPayHelpForm from '../AssistedPayHelpForm';
import HelpForm from '../HelpForm';

function SelectButton({ isSelected, children, onClick }) {
  return (
    <div style={{
      height: '85px',
      width: '202px',
      textAlign: 'center',
      fontWeight: 'bold',
      display: 'table',
      borderRadius: '4px',
      fontSize: '19px',
      cursor: 'pointer',
      border: isSelected
        ? '2px solid #1E88E5'
        : '1px solid #ddd'
    }} onClick={onClick}>
      <div style={{display: 'table-cell', verticalAlign: 'middle'}}>
        {children}
      </div>
    </div>
  );
}

export default function CommonProblems({ cardTimeoutDate, onSubmit, isAssistedPay }) {
  const [selected, setSelected] = useState(null);
  return (
    <div style={{marginTop: '20px'}}>
      <HeaderText variant="page-header">
        <strong>Still need help?</strong>
      </HeaderText>
      <Row style={{ margin: '30px 0px 40px 0px'}}>
        <Column size={4}>
          <SelectButton isSelected={selected === 'decline'} onClick={() => setSelected('decline')}>
            Declined
          </SelectButton>
        </Column>
        <Column size={4}>
          <SelectButton isSelected={selected === 'wrong'} onClick={() => setSelected('wrong')}>
            Duplicate or<br/>Wrong Amount
          </SelectButton>
        </Column>
        <Column size={4}>
          <SelectButton isSelected={selected === 'other'} onClick={() => setSelected('other')}>
            Other
          </SelectButton>
        </Column>
      </Row>

      {selected === 'decline'
        ? (
          <div style={{textAlign: 'left', fontSize: '16px'}}>
            <HeaderText>
              <strong>Declined Transaction</strong>
            </HeaderText>
            <BulletedList style={{ margin: '15px 0px 20px 0px'}}>
              <Bullet renderBullet={() => <strong>1.</strong>}>
                Confirm that your 6-digit prefix, card number, expiration date and security number are entered
                correctly.
              </Bullet>
              <Bullet renderBullet={() => <strong>2.</strong>}>
                In addition to an expiration date, virtual card payments also have a valid payment period.
                Always confirm you are still within the valid payment dates for each card.
                <br/><br/>
                <strong>
                  The payment above is ACTIVE and available for processing
                  through <FormattedDate value={cardTimeoutDate} />.
                </strong>
              </Bullet>
              <Bullet renderBullet={() => <strong>3.</strong>}>
                Once steps 1 and 2 have been verified, process the the full payment amount in order to prevent
                any processing issues. Communicate any discrepancies directly to your customer.
              </Bullet>
            </BulletedList>
          </div>
        )
        : null}

      {selected === 'wrong'
        ? (
          <div style={{ textAlign: 'left', fontSize: '16px' }}>
            <HeaderText>
              <strong>Duplicate Payment or Wrong Amount</strong>
            </HeaderText>
            <BulletedList style={{ margin: '15px 0px 20px 0px' }}>
              <Bullet renderBullet={() => <strong>1.</strong>}>
                Please process and accept this payment from your customer for the total amount
                issued including any duplicate, short or over payments.
              </Bullet>
              <Bullet renderBullet={() => <strong>2.</strong>}>
                For additional assistance, please communicate directly with your customer regarding any short/over
                payments to ensure any issues are resolved appropriately.
              </Bullet>
            </BulletedList>
          </div>
        )
        : null}

      {selected === 'decline' || selected === 'wrong'
        ? (
          <div style={{textAlign: 'left', fontSize: '16px'}}>
            <strong>Still unable to process your payment?</strong> If you are unable to accept this
            payment for the total amount or require multiple transactions to
            process the payment, please <a onClick={() => setSelected('other')}>contact us</a> for assistance.
          </div>
        )
        : null}

      {selected === 'other'
        ? (
          <div style={{ textAlign: 'left', fontSize: '16px' }}>
            {isAssistedPay
              ? <AssistedPayHelpForm onSubmit={onSubmit} />
              : <HelpForm onSubmit={onSubmit} />}
          </div>
        )
        : null}
    </div>
  );
}