/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Transition } from 'react-transition-group';
import createModuleLoader from 'decorators/ModuleLoader';
import './index.scss';

// Hopefully you come across this if you are wondering why react-overlays
// is breaking the app upon load. Check out this issue:
// https://github.com/react-bootstrap/react-overlays/issues/283
// It's not necessary to do this for every usage of react-overlays, just
// usages of it in the main chunk. Which at the time of writing is just this.
const ReactOverlayModal = createModuleLoader({
  importModule: () => import('react-overlays'),
  importModuleKey: 'Modal',
  hideLoadingIndicator: true,
  ignoreExtraProps: true
});

const renderBackdrop = props => (
  <div
    {...props}
    style={{
      position: 'fixed',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      zIndex: 'auto',
      backgroundColor: '#333',
      opacity: 0.5,
      transform: 'translate3d(0,0,0)'
    }}
  />
);

/** @docsignore */
function actionSheetTransition(props) {
  return (
    <Transition
      {...props}
      timeout={props.in ? 0 : 500}
      appear={true}
    >
      {state => (
        <div className={`action-sheet-transition ${state}`}>
          {props.children}
        </div>
      )}
    </Transition>
  );
}

const ActionSheet = ({
  children,
  className,
  isOpen,
  onOuterClick
}) => (
  <ReactOverlayModal
    containerClassName="actionSheetVisible"
    style={{
      position: 'fixed',
      zIndex: 1040,
      bottom: 0,
      left: 0,
      right: 0,
      display: 'inline-block'
    }}
    renderBackdrop={renderBackdrop}
    onBackdropClick={() => {
      if (onOuterClick) {
        onOuterClick();
      }
    }}
    show={isOpen}
    transition={actionSheetTransition}
  >
    <div
      className={classNames(
        'component-action-sheet',
        'viewstrap',
        className
      )}
    >
      {children}
    </div>
  </ReactOverlayModal>
);

ActionSheet.propTypes = {
  onOuterClick: PropTypes.func, // event when clicking outside an open modal
  isOpen: PropTypes.bool
};

export default ActionSheet;
