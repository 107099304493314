/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React from 'react';
import { useDispatch } from 'react-redux';
import { VelocityTransitionGroup } from 'velocity-react';
import logout from 'api/session/logout';
import Truncate from 'components/Truncate';
import AnalyticsCategory from 'components/AnalyticsCategory';
import PathLink from 'components/Link/PathLink';
import Icon, { IconTypes } from 'components/Icon';
import Button from 'components/Button';
import Badge from 'components/Badge';
import Paths from 'config/paths';
import useIdentity from 'hooks/identity/useIdentity';
import { isPathConfig } from '../../config/utils';
import connectNavigationData from '../../index.connect';
import { LogOutNavLabel, PrimaryNavLabel, SecondaryNavLabel } from '../../NavLabel';
import './index.scss';

const MobileNavigationMenu = ({
  onClose,
  menuPath,
  navigation,
  setMenuPath,
  settingsMenu,
  navData
}) => {
  const NavItem = ({
    badge,
    children,
    menuPath: navMenuPath,
    navLabel,
    path
  }) => {
    if (path) {
      const badgeContent = badge ? badge(navData) : null;
      return (
        <div className="menu-link">
          <PathLink path={path} onClick={onClose}>
            {/* treat the first level of navigation like primary nav */}
            {navMenuPath[0] === 'primary' && navMenuPath.length === 2 ? (
              <PrimaryNavLabel id={path.id} />
            ) : (
              <SecondaryNavLabel id={path.id} />
            )}
            {badgeContent ? (
              <Badge>{badgeContent}</Badge>
            ) : null}
          </PathLink>
        </div>
      );
    }

    if (children) {
      return (
        <div className="menu-link">
          <Button onClick={() => setMenuPath(navMenuPath)} anchor={true}>
            <PrimaryNavLabel id={(navLabel || path).id} />
            <div className="right-arrow">
              <span className="pad-x">
                <Icon size={20} type={IconTypes.RightArrow} />
              </span>
            </div>
          </Button>
        </div>
      );
    }

    return <></>;
  };

  const renderNavItems = (
    paths,
    currentMenuPath,
    { ignoreIndex } = {}
  ) => paths.map(
    (pathConfig, index) => {
      const item = isPathConfig(pathConfig)
        ? { path: pathConfig }
        : pathConfig;

      return (
        <NavItem
          key={(item.path || item.navLabel).id}
          menuPath={ignoreIndex ? currentMenuPath : [
            ...currentMenuPath,
            index
          ]}
          {...item}
        />
      );
    }
  );

  const renderSubMenu = ({
    children,
    depth,
    menuPath: subMenuPath,
    navLabel
  }) => (
    <div>
      <div className="submenu">
        <div className="header"> {/* Move header to individual secondaries */}
          <div
            className="back-arrow inline"
            onClick={() => setMenuPath(menuPath.slice(0, depth - 1))}
          >
            <Icon size={16} type={IconTypes.BackArrow} />
          </div>
          <div className="header-title inline">
            <PrimaryNavLabel id={navLabel.id} />
          </div>
          <div className="inline-close-button" onClick={onClose}>
            <span className="pad-x"><Icon size={20} type={IconTypes.Delete} /></span>
          </div>
        </div>
        {renderNavItems(children, subMenuPath)}
      </div>
    </div>
  );

  const SettingsMenuConfig = {
    menuPath: ['settings'],
    navLabel: Paths.Settings,
    children: settingsMenu
  };

  const isInSettingsMenu = menuPath[0] === 'settings';

  const subNavs = menuPath.reduce(
    (val, menuIndex, index) => {
      if (menuIndex === 'primary') return [{ children: navigation }];
      if (menuIndex === 'settings') return [SettingsMenuConfig];

      const parentConfig = val[val.length - 1];
      if (parentConfig) {
        const { children } = parentConfig;

        if (children && children[menuIndex]) {
          return [
            ...val,
            {
              depth: index + (isInSettingsMenu ? 1 : 0),
              menuPath: menuPath.slice(0, index),
              ...children[menuIndex]
            }
          ];
        }
      }

      return val;
    },
    []
  ).slice(isInSettingsMenu ? 0 : 1);


  const dispatch = useDispatch();
  const { companyName } = useIdentity();

  return (
    <AnalyticsCategory
      name="MobileNav"
      className="mobile-secondary-nav"
      style={null}
    >
      <div className="primary-menu">
        <div className="header company-header">
          <div className="header-title type-strong">
            <Truncate value={companyName} maxLength={30} />
          </div>
          <div className="inline-close-button" onClick={onClose}>
            <span className="pad-x">
              <Icon size={20} type={IconTypes.Delete} />
            </span>
          </div>
        </div>
        {renderNavItems(navigation, ['primary'])}
        <div className="divider" />
        {settingsMenu.length ? (
          renderNavItems(
            [SettingsMenuConfig],
            ['settings'],
            { ignoreIndex: true }
          )
        ) : null}
        <div className="menu-link">
          <Button anchor={true} onClick={() => dispatch(logout())}>
            <LogOutNavLabel />
          </Button>
        </div>
      </div>
      {[...subNavs, null].map(
        (config, index) => (
          <VelocityTransitionGroup
            key={index}
            enter={{ animation: { translateX: [ 0, '100%' ] }, duration: 400 }}
            leave={{ animation: { translateX: [ '100%', 0 ] }, duration: 400 }}
          >
            {config ? renderSubMenu(config) : null}
          </VelocityTransitionGroup>
        ))}
    </AnalyticsCategory>
  );
};

export default connectNavigationData(MobileNavigationMenu);
