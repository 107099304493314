/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

// use same regex used by platform
/* eslint-disable max-len, quotes */
export const emailRegex = new RegExp("^(?:[\\w\\!\\#\\$\\%\\&\\'\\*\\+\\-\\/\\=\\?\\^\\`\\{\\|\\}\\~]+\\.)*[\\w\\!\\#\\$\\%\\&\\'\\*\\+\\-\\/\\=\\?\\^\\`\\{\\|\\}\\~]+@(?:(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9\\-](?!\\.)){0,61}[a-zA-Z0-9]?\\.)+[a-zA-Z0-9](?:[a-zA-Z0-9\\-](?!$)){0,61}[a-zA-Z0-9]?)|(?:\\[(?:(?:[01]?\\d{1,2}|2[0-4]\\d|25[0-5])\\.){3}(?:[01]?\\d{1,2}|2[0-4]\\d|25[0-5])\\]))$");
/* eslint-enable max-len, quotes */

export const isValidEmail = value => emailRegex.test(value);

export const MAX_EMAIL_LENGTH = 254; // platform restriction as of June 2015 (formerly 256)
