/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { toMessages } from 'config/messages';

export default toMessages('DateFilter', {
  AllDates: 'All Dates',
  Yesterday: 'Yesterday',
  Today: 'Today',
  Last7Days: 'Last 7 days',
  Last30Days: 'Last 30 days',
  Last90Days: 'Last 90 days',
  CustomRange: 'Custom Range',
  MaximumDayRangeWarning: 'Date filtering is restricted to {maximumDayRange} days. All invoices may not be visible.'
});
