/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import download from 'downloadjs';

function callDownloadApi(url, requestParts, apiConfig, onComplete, onError) {
  let xhr = new XMLHttpRequest();
  xhr.open(apiConfig.method.toUpperCase(), url, true);
  xhr.responseType = 'blob';
  xhr.onload = (e) => {
    let responseXhr = e.target;

    if (responseXhr.status >= 200 && responseXhr.status < 300) {
      // SUCCESS -> trigger file download in browser

      // look for common file info in standard headers
      let mimeType = responseXhr.getResponseHeader('content-type');
      // content-disposition follows pattern like: attachment; filename="somefile.xyz"
      let disposition = responseXhr.getResponseHeader('content-disposition') || '';
      // try to parse out filename
      let fileName = 'download';
      let matches = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(disposition);
      if (matches != null && matches[1]) {
        fileName = matches[1].replace(/['"]/g, '');
      }

      download(responseXhr.response, fileName, mimeType);
      onComplete?.(xhr);
    } else {
      // FAILURE
      let responseBody = null;

      // attempt to read response as JSON (expecting validation errors to be returned)
      console.log('response', responseXhr.response);
      if (responseXhr.response?.type === 'application/json') {
        try {
          let blob = responseXhr.response;
          blob.text().then((responseJson) => {
            // resolve with
            responseBody = JSON.parse(responseJson);
            onError?.(xhr, responseBody);
          }, () => {
            // reject
            onError?.(xhr, null);
          });
        } catch (error) {
          console.error(error);
          onError?.(xhr, null);
        }
      }
    }
  };
  xhr.onerror = () => {
    onError?.(xhr);
  };

  // include special headers (e.g. like anti forgery token)
  if (requestParts.headers) {
    let headerNames = Object.keys(requestParts.headers);
    headerNames?.forEach((headerName) => {
      xhr.setRequestHeader(headerName, requestParts.headers[headerName]);
    });
  }

  if (apiConfig.method.toUpperCase() === 'GET') {
    // everything in the url
    xhr.send();
  } else {
    // include request body
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.send(JSON.stringify(requestParts.body));
  }
}

function callDownloadApiAsync(url, requestParts, apiConfig) {
  return new Promise((resolve, reject) => {
    callDownloadApi(url, requestParts, apiConfig,
      (xhr) => {
        // success
        resolve({
          success: true
        });
      },
      (xhr, responseBody) => {
        resolve({
          success: false,

          // pass back any JSON response on error which may have validation errors to display
          responseBody
        });
      });
  });
}

export {
  callDownloadApiAsync
};