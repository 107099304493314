/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import t from 'tcomb-validation';

const isValid = (value, regex) => {
  if (!value) {
    return false;
  }

  if (value.length > 100) {
    return false;
  }

  return regex.test(value);
};

const companyNameRegex = /[a-zA-Z]/;

export const CompanyNameStrict = t.subtype(t.String, (value) => {
  return isValid(value, companyNameRegex);
}, 'CompanyNameStrict');

CompanyNameStrict.getValidationErrorMessage = (value, path, conetxt, ...args) => {
  if (!value) {
    return 'Please enter a value.';
  }

  if (value.length > 100) {
    return 'Cannot be longer than 100 characters';
  }

  if (!isValid(value, companyNameRegex)) {
    return 'Cannot contain only numbers or special characters';
  }

  return '';
};