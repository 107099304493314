/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { createContext } from 'react';

// This is meant to provide a way for components/services to have a place, when
// they can immediately calculate it, a place to put that the loading state of
// an ApiResultHook will be loading, after the call has been made within a
// useLayoutEffect/useEffect, but now the hooks that check loading state in the
// interim (likely just those that come in execution later in the render) to act
// like the call has been made.
const LoadingContext = createContext({
  stateRef: { current: {} }
});

export default LoadingContext;