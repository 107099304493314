/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */
/* eslint max-len:0 */

import { toMessages } from 'config/messages';

export default toMessages('Settings.Subscription.SelectPortalModal', {
  ModalHeader: 'Confirmation',
  Header: 'Glad the Portal account fits your needs.',
  Instructions: 'You’ll continue to have access to all Viewpost services until your account is transitioned on {date}. If you change your mind, you can always return to this page.',
  AlreadyPortalInstructions: 'If you change your mind, you can switch to a Corporate account within Company Settings.'
});
