/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Modal, { ModalBody, ModalFooter, ModalFooterButton, ModalFooterText } from 'components/Modal';
import { FormattedMessage } from 'react-intl';
import Messages from './Simple.messages.js';

const ButtonPropType = PropTypes.oneOfType([
  PropTypes.bool,
  PropTypes.shape({
    onClick: PropTypes.func,
    message: PropTypes.object,
    messageComponent: PropTypes.node
  })
]);

export default class SimpleModal extends Component {
  static propTypes = {
    submit: ButtonPropType,
    close: ButtonPropType
  };

  static defaultProps = {
    submit: true,
    close: true
  };

  constructor(props) {
    super(props);
  }

  render() {
    const {
      body,
      children,
      close,
      floatingClose,
      footnote,
      tertiary,
      header,
      hideCloseButton,
      isForm,
      onClose: originalOnClose,
      submit
    } = this.props;
    const onClose = originalOnClose || (() => null);

    const submitOnClick = submit?.onClick
      ? () => submit.onClick(onClose)
      : () => onClose(true);

    const closeOnClick = close?.onClick
      ? () => close.onClick(onClose)
      : () => onClose();

    const tertiaryOnClick = (tertiary || {}).onClick
      ? () => tertiary.onClick(onClose)
      : () => onClose();

    const floatingCloseOnClick = !!floatingClose
      ? (floatingClose || {}).onClick || closeOnClick
      : null;

    const modalHeader = header && header.defaultMessage
      ? <FormattedMessage {...header} />
      : header;

    return (
      <Modal
        header={modalHeader}
        isForm={isForm}
        onHeaderClose={floatingCloseOnClick}
      >
        <ModalBody>
          {body || children}
        </ModalBody>
        <ModalFooter>
          {submit ? (
            <ModalFooterButton
              variant={submit.variant}
              isSubmit={true}
              onClick={submitOnClick}
              isDisabled={submit.isDisabled}
              isProcessing={submit.isProcessing}
            >
              {submit.messageComponent || <FormattedMessage {...(submit.message || Messages.SubmitButton)} />}
            </ModalFooterButton>
          ) : null}
          {close && !hideCloseButton ? (
            <ModalFooterButton onClick={closeOnClick}>
              { close.messageComponent || <FormattedMessage {...(close.message || Messages.CloseButton)} />}
            </ModalFooterButton>
          ) : null}
          {tertiary ? (
            <ModalFooterButton isRight={true} variant="tertiary" onClick={tertiaryOnClick}>
              { tertiary.messageComponent || <FormattedMessage {...(tertiary.message || Messages.CloseButton)} />}
            </ModalFooterButton>
          ) : null}
          {footnote ? (
            <ModalFooterText>
              {footnote}
            </ModalFooterText>
          ) : null}
        </ModalFooter>
      </Modal>
    );
  }
}
