/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */
/* eslint max-len:0 */

import { defineMessages } from 'react-intl';

const common = defineMessages({
  SignUp: {
    id: 'BlueHeaderOnWhiteLayout.SignUp',
    description: 'TODO',
    defaultMessage: 'Sign Up'
  },
  LogIn: {
    id: 'BlueHeaderOnWhiteLayout.LogIn',
    description: 'TODO',
    defaultMessage: 'Log In'
  }
});

export default {
  ...common
};
