/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { GatewayRegistry } from 'react-gateway';

// A copy of https://github.com/cloudflare/react-gateway/blob/master/src/GatewayDest.js to support
// defaults

export default class GatewayDest extends Component {
  static contextTypes = {
    gatewayRegistry: PropTypes.instanceOf(GatewayRegistry).isRequired
  };

  static propTypes = {
    name: PropTypes.string.isRequired,
    component: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.func
    ]),
    default: PropTypes.node
  };

  constructor(props, context) {
    super(props, context);
    this.gatewayRegistry = context.gatewayRegistry;
    this.state = { child: null };
  }

  componentWillMount() {
    this.gatewayRegistry.addContainer(this.props.name, this);
  }

  componentWillUnmount() {
    this.gatewayRegistry.removeContainer(this.props.name, this);
  }

  render() {
    if ((!this.state.children || !this.state.children.length) && this.props.default) {
      return this.props.default;
    }

    const { component, name, ...attrs } = this.props;
    return React.createElement(component || 'div', attrs, this.state.children);
  }
}
