/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */
/* eslint max-len:0 */

import { toMessages } from 'config/messages';

export default toMessages('Logon.CompleteResetPassword', {
  ExpiredInstructions: 'We\'re sorry, but the link to reset your password has expired. Please click below to receive a new link.',
  InProgressHeader: 'Reset Password',
  AccessDeniedHeader: 'Access Denied',
  AccessDeniedInstructions: 'Your Viewpost account has been locked. Please contact Customer Support at {contact}.',
  OkGotIt: 'Ok, Got It'
});
