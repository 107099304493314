/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React from 'react';
import Button from 'components/Button';
import FlagIcon from 'components/Icon/FlagIcon';
import useIsMobile from 'hooks/browser/useIsMobile';

function DelegatedAccount({
  account,
  onConfirm
}) {
  const isMobile = useIsMobile();
  return (
    <div>
      <div>
        The account below is already set up to receive electronic payments.
        You can continue to use this account or add a different bank account.
      </div>

      <div style={{
        backgroundColor: 'white',
        border: '1px solid #ddd',
        borderRadius: '2px',
        marginTop: '15px',
        padding: '20px',
        display: isMobile ? 'block' : 'table',
        width: '100%'
      }}>
        <div style={{ display: isMobile ? 'block' : 'table-cell'}}>
          <div className="vp-label">
            Bank
          </div>
          <div>
            <strong>{account?.bankName}</strong>
          </div>
        </div>
        <div style={{ display: isMobile ? 'block' : 'table-cell' }}>
          <div className="vp-label">
            Account
          </div>
          <div>
            <strong>XX-{account?.accountNumberSuffix}</strong>
          </div>
        </div>
        <div style={{ display: isMobile ? 'block' : 'table-cell' }}>
          <div className="vp-label">
            Routing
          </div>
          <div>
            {account?.routingNumber}
          </div>
        </div>
        <div style={{ display: isMobile ? 'block' : 'table-cell' }}>
          <div className="vp-label">
            Currency
          </div>
          <div>
            <FlagIcon country={account?.currencyCode === 'CAD' ? 'CA' : 'US'} />&nbsp;
            {account?.currencyCode ?? 'USD'}
          </div>
        </div>
        <div style={{
          display: isMobile ? 'block' : 'table-cell',
          textAlign: 'right',
          verticalAlign: 'middle'
        }}>
          <Button onClick={onConfirm}>
            Use This Account
          </Button>
        </div>
      </div>
    </div>
  );
}

export default DelegatedAccount;