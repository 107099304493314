/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './index.scss';

export const HeaderTextVariants = {
  PageHeader: 'page-header',
  Larger: 'larger',
  Large: 'large',
  Medium: 'medium',
  Small: 'small'
};

export default class HeaderText extends Component {
  static propTypes = {
    variant: PropTypes.oneOf(Object.keys(HeaderTextVariants)
      .map(variant => HeaderTextVariants[variant])),
    bold: PropTypes.bool,
    style: PropTypes.object,
    className: PropTypes.string,
    color: PropTypes.oneOf([
      'none', // Provide your own color by other means
      'black',
      'blue',
      'grey',
      'darkGrey',
      'white'
    ])
  };

  static defaultProps = {
    variant: 'large',
    color: 'black'
  };

  render() {
    const { variant, bold, children, className, style, color } = this.props;

    return (
      <div
        className={classNames(
          'header-text-component',
          className,
          !!color && color !== 'none' ? `color-${color}` : null,
          {
            bold,
            'normal-weight': bold === false,
            'page-header': variant === 'page-header',
            'large': variant === 'large',
            'larger': variant === 'larger',
            'medium': variant === 'medium',
            'small': variant === 'small'
          }
        )}
        style={style}
      >
        {children}
      </div>
    );
  }
}
