/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { getMetadataOf, getSchema } from 'schemas';
import { Entity } from 'schemas/state';
import { newState } from 'reducers/utils';
import { isObject, isString } from 'lodash';
import logError from 'services/ErrorService';
import {
  isApiAction,
  isEntityAction
} from './utils';

// Doing it inline in the reducer causes redux to get angry because the reducer is executing and
// getState can't be resolved. This eliminates that problem by causing it to happen in between
// reducer calculations
const logReducerError = (...args) => setTimeout(() => logError(...args));

const writeEntityIntoState = (entity, state) => {
  try {
    entity.value = getSchema(entity.type)(entity.value);

    if (!state[entity.type]) {
      state[entity.type] = {};
    }

    // enforce that entities adhere to schema
    state[entity.type][entity.id] = Entity(entity);

    return true;
  } catch (e) {
    logReducerError('There was an error writing the entity to the state', { error: e });
  }

  return false;
};

const normalizeUpdateState = (updateState) => {
  if (isObject(updateState)) return updateState;
  if (isString(updateState)) return { reason: updateState };
  return { reason: 'None' };
};

const updateByEntityRef = (
  state,
  { id, type },
  updateReasonState
) => {
  if (updateReasonState.reason === 'Deleted') {
    if (!state[type]?.[id]) return false;

    delete state[type][id];
    return true;
  }

  if (state[type]?.[id]) {
    return writeEntityIntoState(
      {
        ...state[type][id],
        updated: updateReasonState
      },
      state
    );
  }

  return false;
};

const updateByEntityType = (
  state,
  entityType,
  updateReasonState
) => {
  if (!state[entityType]) return false;

  let hasChange = false;

  for (const entityId of Object.keys(state[entityType])) {
    if (updateReasonState.reason === 'Deleted') {
      if (!state[entityType][entityId]) continue;

      delete state[entityType][entityId];
      hasChange = true;
    } else if (state[entityType]?.[entityId]) {
      hasChange = writeEntityIntoState(
        {
          ...state[entityType][entityId],
          updated: updateReasonState
        },
        state
      ) || hasChange;
    }
  }

  return hasChange;
};

const updateByEntityContext = (
  state,
  entityContext,
  updateReasonState
) => {
  let hasChange = false;

  for (const entityType of Object.keys(state)) {
    const { contexts } = getMetadataOf(entityType);

    if (!contexts) continue;
    if (!contexts.includes(entityContext)) continue;

    for (const entityId of Object.keys(state[entityType])) {
      if (updateReasonState.reason === 'Deleted') {
        if (!state[entityType][entityId]) continue;

        delete state[entityType][entityId];
        hasChange = true;
      } else {
        hasChange = writeEntityIntoState(
          {
            ...state[entityType][entityId],
            updated: updateReasonState
          },
          state
        ) || hasChange;
      }
    }
  }

  return hasChange;
};

const mergeEntities = (
  state,
  newEntities
) => {
  if (!newEntities.length) return false;

  let hasChange = false;
  for (const entity of newEntities) {
    const {
      id: entityId,
      merge: shouldMerge,
      scope: entityScope,
      type: entityType,
      value: entityValue
    } = entity;

    // If there is a truthy value, replace that with whatever is there
    if (entityValue) {
      const newEntity = {
        id: entityId,
        scope: entityScope,
        type: entityType,
        value: entityValue
      };

      // Unless the merge flag is set, then take whatever value is there and
      // apply the new value on top of it
      if (shouldMerge && state[entityType]?.[entityId]) {
        newEntity.value = {
          ...state[entityType][entityId].value,
          ...entityValue
        };
      }

      // Note if the write operation results in a change
      hasChange = writeEntityIntoState(newEntity, state) || hasChange;

    // If there is a falsy value, take that to delete the entity out of the
    // state.
    } else if (state[entityType]?.[entityId]) {
      hasChange = true;
      delete state[entityType][entityId];
    }
  }

  return hasChange;
};

const markUpdatedEntities = (
  state,
  updatedEntities
) => {
  if (!updatedEntities.length) return false;

  let hasChange = false;
  for (const updatedEntity of updatedEntities) {
    const {
      anyOutsideScope,
      context: singleEntityContext,
      contexts: entityContexts,
      entityRef: singleEntityRef,
      entityRefs,
      entityType: singleEntityType,
      entityTypes,
      reason: originalReason
    } = updatedEntity;

    const updatedReasonState = normalizeUpdateState(originalReason);

    if (entityContexts?.length) {
      for (const entityContext of entityContexts) {
        if (!entityContext) continue;
        hasChange = updateByEntityContext(state, entityContext, updatedReasonState) || hasChange;
      }
    }

    if (singleEntityContext) {
      hasChange = updateByEntityContext(state, singleEntityContext, updatedReasonState) || hasChange;
    }

    if (entityRefs?.length) {
      for (const entityRef of entityRefs) {
        if (!entityRef) continue;
        hasChange = updateByEntityRef(state, entityRef, updatedReasonState) || hasChange;
      }
    }

    if (singleEntityRef) {
      hasChange = updateByEntityRef(state, singleEntityRef, updatedReasonState) || hasChange;
    }

    if (entityTypes?.length) {
      for (const entityType of entityTypes) {
        if (!entityType) continue;
        hasChange = updateByEntityType(state, entityType, updatedReasonState) || hasChange;
      }
    }

    if (singleEntityType) {
      hasChange = updateByEntityType(state, singleEntityType, updatedReasonState) || hasChange;
    }

    if (anyOutsideScope != null) {
      for (const entityType of Object.keys(state)) {
        for (const entityId of Object.keys(state[entityType])) {
          if (state[entityType][entityId].scope === anyOutsideScope) continue;

          if (updatedReasonState.reason === 'Deleted') {
            delete state[entityType][entityId];
            hasChange = true;
          } else {
            hasChange = writeEntityIntoState(
              {
                ...state[entityType][entityId],
                updated: updatedReasonState
              },
              state
            ) || hasChange;
          }
        }
      }
    }
  }

  return hasChange;
};

const entityReducer = (
  state = {},
  action
) => {
  if (isApiAction(action) || isEntityAction(action)) {
    const {
      entities: newEntities,
      metaEntities: newMetaEntities,
      updated: updatedEntities
    } = action.payload || {};

    // There's no changes so let's not even entertain the idea of changing.
    if (!newEntities?.length
      && !newMetaEntities?.length
      && !updatedEntities?.length) return state;

    const nextState = newState(state || {});
    let hasChange = markUpdatedEntities(nextState, updatedEntities || []);
    hasChange = mergeEntities(nextState, newEntities || []) || hasChange;
    hasChange = mergeEntities(nextState, newMetaEntities || []) || hasChange;

    return hasChange ? nextState : state;
  }

  if (action.type === 'NukeEntities') {
    // We only need to delete a property off the top here, so we don't need a
    // deeper clone.
    const modifiedState = { ...state };
    delete modifiedState[action.entity];
    return modifiedState;
  }

  return state;
};

export default entityReducer;
