/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, { Component } from 'react';
import classnames from 'classnames';
import { isUndefined } from 'lodash';
import ProvideIsMobile from 'decorators/ProvideIsMobile';
import DropdownPanel from 'components/DropdownPanel';
import Popover from 'components/Popover';
import DropdownArrowCaret from './DropdownArrowCaret';
import './index.scss';

const createDropdown = (ToggleComponent, {
  caretComponent: parentCaretComponent,
  childComponent: ChildComponent,
  usePopoverForMobile: defaultUsePopoverForMobile,
  useToggleForPopover: defaultUseToggleForPopover,
  passCaretToMobileToggle: defaultPassCaretToMobileToggle,
  childPropNames // Define props used by the child component that need to be passed to it
} = {}) => {
  class Dropdown extends Component {
    constructor(props) {
      super(props);
      this.state = { isOpen: false };
      this.close = () => this.setState({ isOpen: false });
      this.open = () => this.setState({ isOpen: true });
    }

    componentDidUpdate(prevProps, prevState) {
      if (!this.state.isOpen && prevState.isOpen && this.props.onClose) {
        this.props.onClose();
      } else if (this.state.isOpen && !prevState.isOpen && this.props.onOpen) {
        this.props.onOpen();
      }
    }

    onToggle = (evt) => {
      if (evt) {
        evt.stopPropagation();
      }

      const { isOpen } = this.state;

      this.setState({
        isOpen: !isOpen
      });
    }

    render() {
      let {
        children,
        usePopoverForMobile = defaultUsePopoverForMobile,
        useToggleForPopover = defaultUseToggleForPopover,
        passCaretToMobileToggle = defaultPassCaretToMobileToggle,
        isMobile,
        popoverPlace,
        caret: overrideCaret,
        caretStyle,
        caretClassName,
        caretProps,
        popoverClassName,
        popoverStyle,
        onClose,
        ...props
      } = this.props;

      let childProps = {};

      (childPropNames || []).forEach((propName) => {
        if (isUndefined(props[propName]) && !isUndefined(this.props[propName])) {
          console.warn(`Dropdown childProp ${propName} is also used in Dropdown, possible collision`);
        }
        if (!isUndefined(props[propName])) {
          childProps[propName] = props[propName];
        }
      });

      const CaretComponent = parentCaretComponent || DropdownArrowCaret;
      const caret = overrideCaret || (
        <CaretComponent className={caretClassName}
          defaultCaret={DropdownArrowCaret}
          {...(caretProps || {})}/>
      );

      let dropdownContent = ChildComponent
        ? (
          <ChildComponent isMobile={isMobile}
            {...(childProps || {})}
            closeMenu={() => this.setState({isOpen: false})}>
            {children}
          </ChildComponent>
        ) : children;

      if (!dropdownContent) dropdownContent = <span/>;

      let mobilePanel = this.state.isOpen ? (
        <DropdownPanel alwaysShow={true}
          onlyMenu={true}
          onClose={() => this.setState({isOpen: false})}>
          {dropdownContent}
        </DropdownPanel>
      ) : null;

      dropdownContent = (
        <div className="viewstrap" onClick={e => e.stopPropagation()}>
          {dropdownContent}
        </div>
      );

      if (passCaretToMobileToggle) {
        mobilePanel = mobilePanel ? (
          <span>
            {caret}
            {mobilePanel}
          </span>
        ) : caret;
      }

      if (useToggleForPopover && !isMobile) {
        return (
          <Popover isOpen={this.state.isOpen}
            place={popoverPlace}
            content={dropdownContent}
            className={classnames('dropdown-popover-container', this.state.isOpen
              ? 'popover-animation-end' : 'popover-animation-start', popoverClassName)}
            style={popoverStyle}
            useTooltip={props.useTooltip}
            onOuterAction={() => this.setState({isOpen: false})}>
            <ToggleComponent {...props}
              isMobile={isMobile}
              isOpen={this.state.isOpen}
              onClick={this.onToggle}
            />
          </Popover>
        );
      }

      return (
        <ToggleComponent {...props}
          isMobile={isMobile}
          onClick={!this.state.isOpen ? this.onToggle : null}
          isOpen={this.state.isOpen}
        >
          {isMobile && !usePopoverForMobile ? mobilePanel : (
            <span style={caretStyle}>
              <Popover isOpen={this.state.isOpen}
                place={popoverPlace}
                content={dropdownContent}
                className={classnames('dropdown-popover-container', this.state.isOpen
                  ? 'popover-animation-open' : 'popover-animation-close', popoverClassName)}
                style={popoverStyle}
                onOuterAction={() => this.setState({isOpen: false})}>
                {caret}
              </Popover>
            </span>
          )}
        </ToggleComponent>
      );
    }
  }

  Dropdown.defaultProps = {
    popoverPlace: 'below'
  };

  return ProvideIsMobile(Dropdown);
};

export default createDropdown;
