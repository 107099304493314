/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { toMessages } from 'config/messages';

export default toMessages('SelectPaymentMethod', {
  DefaultAccount: 'Default Payment Account ({name} - {lastFourDigits})',
  CreditCard: 'Credit Card',
  AddBankAccount: 'Add Bank Account',
  PlusAction: '+ {action}',
  MissingSignature: 'Account is missing a signature. Click to add signature.',
  PendingVerification: 'This account needs to be verified. Click to verify account.'
});
