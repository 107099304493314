/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */
/* eslint max-len:0 */

import { defineMessages } from 'react-intl';

export default defineMessages({
  New: {
    id: 'Tip.New',
    defaultMessage: `New`
  }
});
