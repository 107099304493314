/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { connect } from 'react-redux';

import createNewSwitchCompany from 'api/settings/switchCompany/createNewSwitchCompany';
import { createCardTokenAsync } from 'api/stripe';

import createExecuteApiResultHooks from 'hooks/apiResult/createExecuteApiResultHooks';
import GetSwitchCompaniesHooks from 'hooks/identity/GetSwitchCompaniesHooks';

const CreateNewSwitchCompanyHooks = createExecuteApiResultHooks({
  endpoint: createNewSwitchCompany
});

export default Component => connect(
  state => ({
    switchCompanies: GetSwitchCompaniesHooks.Redux.getCompanies(state),
    isLoading: CreateNewSwitchCompanyHooks.Redux.getIsLoading(state)
  }),
  (dispatch, { onClose }) => {
    return {
      createNewCompanyWithCreditCard: async (
        { companyName },
        ccFormValue
      ) => {
        const [ tokenSuccess, { payload: tokenPayload } ] = await dispatch(
          createCardTokenAsync(ccFormValue)
        );

        if (!tokenSuccess) {
          return [ false, tokenPayload.error.message ];
        }

        const [ success, action ] = await dispatch(
          CreateNewSwitchCompanyHooks.Redux.executeRequestAction(
            {
              isCompanyMatchOverride: true, // Yeah, we aren't gonna deal with that nonsense
              isCompanyMatchOverrideCanceled: false,
              cardToken: tokenPayload.id,
              companyName
            },
            { failureMessage: false }
          )
        );

        if (!success) return [ false, action.getFirstMessage() || true ];

        return [ action.getResponseBody().isComplete ];
      },
      createNewPortalCompany: async (
        { companyName }
      ) => {
        const [ success, action ] = await dispatch(
          CreateNewSwitchCompanyHooks.Redux.executeRequestAction(
            {
              isCompanyMatchOverride: true, // Yeah, we aren't gonna deal with that nonsense
              isCompanyMatchOverrideCanceled: false,
              companyName
            },
            { failureMessage: false }
          )
        );

        if (!success) return [ false, action.getFirstMessage() || true ];

        return [ action.getResponseBody().isComplete ];
      },
      onClose: (...args) => {
        dispatch(CreateNewSwitchCompanyHooks.Redux.invalidateStateAction());
        if (onClose) {
          onClose(...args);
        }
      }
    };
  }
)(Component);
