/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, { Component } from 'react';
import { addModal } from 'config/modals/registry';
import Modal from 'components/Modal';
import { ModalBody, ModalFooter, ModalFooterButton } from 'components/Modal';
import Messages from './index.messages';

import BeneficialOwnerCollectionPanel from '../index';

class BeneficialOwnerCollectionPanelModal extends Component {

  onCancel() {
    this.props.closeModal();
    if (this.props.onCancel) {
      this.props.onCancel();
    }
  }

  onSubmit() {
    if (this.panelRef) {
      this.panelRef.submit((success, action) => {
        this.props.closeModal(success);
        if (this.props.onComplete) {
          this.props.onComplete(success, action);
        }
      });
    }
  }

  render() {
    return (
      <Modal header={<Messages.Header.Message/>} onHeaderClose={() => this.onCancel()}>
        <ModalBody>
          <BeneficialOwnerCollectionPanel ref={ref => this.panelRef = ref} showSubmit={false} />
        </ModalBody>
        <ModalFooter>
          <ModalFooterButton variant="primary" onClick={() => this.onSubmit()}>
            <Messages.Submit.Message />
          </ModalFooterButton>
          <ModalFooterButton variant="secondary" onClick={() => this.onCancel()}>
            <Messages.Cancel.Message />
          </ModalFooterButton>
        </ModalFooter>
      </Modal>
    );
  }
}

export default addModal(
  'BeneficialOwnerCollectionPanelModal',
  BeneficialOwnerCollectionPanelModal,
  {
    extraWide: true
  }
);
