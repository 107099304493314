/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import createModuleLoader from 'decorators/ModuleLoader';
import TooltipPopover from './tooltipPopover';
import './index.scss';

// react-popover needs the document body to be there when it imports,
// this pushes that off until the Popover component is rendered, well after
// that is done.
const ReactPopover = createModuleLoader({
  importModule: () => import('react-popover'),
  hideLoadingIndicator: true,
  ignoreExtraProps: true
});

/**
 * A tooltip that stays open based on state (and also tracks/follows its target if it moves)
 */
export default class Popover extends Component {
  static propTypes = {
    place: PropTypes.oneOf(['above', 'right', 'below', 'left']),
    isOpen: PropTypes.bool,
    content: PropTypes.node.isRequired, // the content of the popover
    variant: PropTypes.oneOf(['default', 'failure']),
    className: PropTypes.string,
    style: PropTypes.object,
    onOuterClick: PropTypes.func,
    /** Whether or not to use tooltip component as the underlying impl */
    useTooltip: PropTypes.bool
  };

  static defaultProps = {
    place: 'above',
    isOpen: true
  };

  render() {
    let PopoverComponent = this.props.useTooltip ? TooltipPopover : ReactPopover;
    return (
      <PopoverComponent
        body={this.props.content}
        {...this.props}
        className={classNames(this.props.className, this.props.variant, this.props.place)}
      >
        {/* the element to orient the popover around */}
        <>
          {this.props.children}
        </>
      </PopoverComponent>
    );
  }
}
