/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */
/* eslint max-len:0 */

import { defineMessages } from 'react-intl';

const common = defineMessages({
  Slogan: {
    id: 'AppFooter[Slogan]',
    description: 'Viewpost slogan',
    defaultMessage: 'See business better.®'
  }
});

export default {
  ...common
};
