/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import t from 'tcomb-validation';
import { defineSchema } from 'schemas';
import { Currency } from 'schemas/common/currency';
import Truthy from 'schemas/common/truthy';

const FeeDetailItem = t.struct({
  paymentId: t.maybe(t.String),
  settlementId: t.maybe(t.String),
  receiptSettlementId: t.maybe(t.String),
  date: t.maybe(t.Date),
  companyName: t.maybe(t.String),
  description: t.maybe(t.String),
  amount: t.maybe(t.Number),
  savings: t.maybe(t.Number),
  returnType: t.maybe(t.String)
}, 'FeeDetailItem');

const FeeInfo = t.struct({
  totalAmount: t.maybe(t.Number),
  writtenOffAmount: t.maybe(t.Number), // ???
  details: t.maybe(t.list(FeeDetailItem))
}, 'FeeInfo');

export const FeeCategories = t.struct({
  // breaks down fee info into specific categories
  checkIssuance: t.maybe(FeeInfo),
  checkReceipt: t.maybe(FeeInfo),
  vpx: t.maybe(FeeInfo),
  bankReturnedItem: t.maybe(FeeInfo),
  misc: t.maybe(FeeInfo),
  credits: t.maybe(FeeInfo),
  totalAmount: t.maybe(t.Number)
}, 'FeeCategories');

/** Setting for specific customer */
export const VendorPaysFeesSetting = defineSchema({
  id: t.String,
  companyName: t.maybe(t.String),
  isEnabled: t.Boolean
}, 'VendorPaysFeesSetting');

/** Tracks counts across all customers */
export const VendorPaysFeesSettingCounts = defineSchema({
  totalCount: t.maybe(t.Number),
  totalEnabledCount: t.maybe(t.Number)
}, 'VendorPaysFeesSettingCounts');

export const ConsumerPaymentsCalculator = t.struct({
  totalPayments: Currency
});

export const ConsumerPaymentsDisclaimer = t.struct({
  acceptTerms: Truthy
});
