/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, { useEffect, useState } from 'react';
import LoadingIndicator from 'components/LoadingIndicator';
import useIsMobile from 'hooks/browser/useIsMobile';

import Button from 'components/Button';
import HeaderText from 'components/HeaderText';

import PlaidBankSelectorWrapper from 'containers/PlaidBankSelectorWrapper';

import DelegatedAccount from './DelegatedAccount';
import PlaidAccount from './PlaidAccount';
import ManualAccount from './ManualAccount';
import ConfirmAccount from './ConfirmAccount';

import connector from './index.connect';

function AddBankAccount({
  isLoading,
  loadDelegatedAccount,
  delegatedAccount,

  isAdding,
  addBankAccount,

  /** Whether or not we want to add account only for receiving payments
   * (as opposed to paying out of the account)
   */
  isReceiptAccount = false,
  isPersonalAccount,
  hasTaxId,
  hasBankAccount,

  onSkip,
  onComplete,

  /* from Plaid Connector */
  hasPlaidLoadFailed,
  isPlaidLoading,
  onOpenPlaid,
  plaidAccount
}) {
  const isMobile = useIsMobile();
  const [method, setMethod] = useState(null);
  const [isAddComplete, setAddComplete] = useState(false);

  // only allow this for 1st account
  // since when multiples come into play we
  // also need to be collecting a unique account name
  // but Damien's design doesn't nicely support collecting additional fields
  // in addition to "use existing account"
  const isDelegatedAllowed = !hasBankAccount && !isPersonalAccount;
  const isManualAllowed = true;

  // check for existing account
  useEffect(() => {
    if (isDelegatedAllowed) {
      loadDelegatedAccount();
    }
  }, []);

  useEffect(() => {
    if (isDelegatedAllowed && delegatedAccount && !method) {
      // default to delegated when available
      setMethod('delegated');
    }
  }, [delegatedAccount]);

  useEffect(() => {
    if (plaidAccount) {
      // when receiving a new account from plaid,
      // automatically select that "tab" button, since the button itself doesn't do that initially
      setMethod('plaid');
    }
  }, [plaidAccount]);

  if (isAddComplete) {
    // show final confirmation after the add completes
    return <ConfirmAccount method={method}
      onConfirm={() => onComplete()} />;
  }

  if (isLoading || isAdding || isPlaidLoading) {
    return <LoadingIndicator />;
  }

  const selectPlaid = () => {
    if (!plaidAccount) {
      // prior to getting a plaid account loaded, the button acts like
      // a push button and just tries to open the plaid modal
      onOpenPlaid?.();
    } else {
      // once a plaid account is loaded, the button behaves like a tab
      // button toggling to the plaid account view
      setMethod('plaid');
    }
  };

  return (
    <div>
      <HeaderText>
        Add Bank Account
      </HeaderText>

      {/* Damien's design prefers to call out delegated account separately when available */}
      {method === 'delegated'
        ? (
          <div style={{ paddingTop: '15px' }}>
            <DelegatedAccount
              account={delegatedAccount}
              onConfirm={() => addBankAccount('delegated', {
                id: delegatedAccount.id
              }, () => setAddComplete(true))} />
            <div style={{ paddingTop: '15px' }}>
              <a onClick={() => setMethod(null)}>Add New Account</a>
              {onSkip
                ? (
                  <>
                    &nbsp;&nbsp;|&nbsp;&nbsp;
                    <a onClick={() => onSkip()}>Do This Later</a>
                  </>
                )
                : null}
            </div>
          </div>
        )
        : null}

      {/* When multiple option available, show selection */}
      {method !== 'delegated' && isManualAllowed
        ? (
          <div>
            <div style={{ paddingTop: '15px' }}>
              Register a bank account using one of the two methods
            </div>
            <div style={{ paddingTop: '15px' }}>
              {isDelegatedAllowed && delegatedAccount
                ? (
                  <a onClick={() => setMethod('delegated')}>Use Existing Account</a>
                )
                : null}
              {onSkip
                ? (
                  <>
                    &nbsp;&nbsp;|&nbsp;&nbsp;
                    <a onClick={() => onSkip()}>Do This Later</a>
                  </>
                )
                : null}
            </div>
            <div style={{ padding: '30px 0px'}}>
              <div style={{
                display: 'inline-block',
                width: isMobile ? '100%' : '49%',
                verticalAlign: 'middle'
              }}>
                <Button variant="secondary"
                  expand={true}
                  style={{ border: '2px solid blue' }}
                  isSelected={method === 'plaid'}
                  onClick={() => selectPlaid()}>
                  Use Your Bank Log In
                </Button>
              </div>
              <div style={{ display: 'inline-block', width: '2%', verticalAlign: 'middle' }}>
              </div>
              <div style={{
                display: 'inline-block',
                width: isMobile ? '100%' : '49%',
                verticalAlign: 'middle'
              }}>
                <Button variant="secondary"
                  expand={true}
                  isSelected={method === 'manual'}
                  onClick={() => setMethod('manual')}>
                  Use Bank Routing and Account Numbers
                </Button>
              </div>
            </div>
          </div>
        )
        : null}

      {method === 'plaid'
        ? (
          <PlaidAccount
            hasPlaidLoadFailed={hasPlaidLoadFailed}
            isPlaidLoading={isPlaidLoading}
            onOpenPlaid={onOpenPlaid}
            plaidAccount={plaidAccount}
            isReceiptAccount={isReceiptAccount}
            hasTaxId={hasTaxId}
            hasBankAccount={hasBankAccount}
            isPersonalAccount={isPersonalAccount}
            onSubmit={v => addBankAccount('plaid', {
              publicToken: v?.publicToken,
              accountId: v?.accountId,
              taxIdNumber: v?.taxIdNumber,
              signer1: v?.signer1,
              signatureImage: v?.signatureImage,
              accountName: v?.accountName
            }, () => setAddComplete(true))} />
        )
        : null}

      {method === 'manual'
        ? (
          <ManualAccount isReceiptAccount={isReceiptAccount}
            hasTaxId={hasTaxId}
            hasBankAccount={hasBankAccount}
            isPersonalAccount={isPersonalAccount}
            onSubmit={v => addBankAccount('manual', {
              routingNumber: v?.routingNumber,
              accountNumber: v?.accountNumber,
              currency: v?.currency,
              taxIdNumber: v?.taxIdNumber,
              signer1: v?.signer1,
              signatureImage: v?.signatureImage,
              accountName: v?.accountName
            }, () => setAddComplete(true))} />
        )
        : null}
    </div>
  );
}

/*
  Defer entire Add Bank Account UI until Plaid infrastructure loads
*/
function PlaidAccountConnector(props) {
  const [plaidAccount, setPlaidAccount] = useState(null);
  return (
    <PlaidBankSelectorWrapper ignoreLoading={true}
      handleAccountSelected={(publicToken, metadata) => {
        setPlaidAccount({
          publicToken: publicToken,
          accountName: metadata?.account?.name,
          accountId: metadata?.account?.id,
          accountNumberSuffix: metadata?.account?.mask,
          accountType: metadata?.account?.subtype,
          bankName: metadata?.institution?.name,
          instituionId: metadata?.institution?.institution_id
        });
      }}>
      {/*
        Plaid loading happens async and we can't pop the modal
        until it does, so we'll to just wait until it loads to avoid the UI
        trying to do things with plaid before we can
      */}
      <AddBankAccount {...props} plaidAccount={plaidAccount} />
    </PlaidBankSelectorWrapper>
  );
}

export default connector(PlaidAccountConnector);