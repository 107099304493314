/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { uniq } from 'lodash';
import { createEntity, createEntityReference, getEntity } from 'schemas/state';
import Company from 'schemas/network/Company';
import RetrievableAddressBookEntry from 'schemas/network/RetrievableAddressBookEntry';
import { RetrievableCompanyProfile, RetrievableIndustryType } from 'schemas/network/companyProfile';
import { ConnectionRequest } from 'schemas/network/connectionRequest';
import mapAddressToApi from 'api/common/mappers/mapAddressToApi';
import mapApiToAddress from 'api/common/mappers/mapApiToAddress';
import { filterAvailableActionsFromState, getIdentity, canDisconnectFromCompany } from 'api/permissions';
import { InvoiceActions } from 'config/constants';
import AddressBookRelationships from 'config/constants/network/AddressBookRelationships';
import PaymentActions from 'config/constants/PaymentActions';
import ConnectionStatusType from 'config/constants/network/ConnectionStatus';
import NetworkActions from 'config/constants/network/NetworkActions';
import dataUriToBlob from 'api/common/mappers/dataUriToBlob';
import toConnectionStatus from './toConnectionStatus';

/*
    Determine actions for a company
*/

export function determineConnectionAvailableActions({
  companyId,
  connectionStatus,
  hasReceivedConnectionRequest,
  filterFromState
}, state) {
  let availableActions = [];
  let identity = getIdentity(state);
  let currentCompanyId = identity ? identity.companyId : null;

  if (!currentCompanyId || companyId !== currentCompanyId) {
    if (connectionStatus === ConnectionStatusType.Unconnected) {
      // CASE: address book entry for company that doesn't exist in VP yet
      availableActions.push(NetworkActions.Invite);
    } else if (connectionStatus === ConnectionStatusType.Connectable) {
      // CASE: existing VP company but not connected to any address book
      availableActions.push(NetworkActions.Send);
    } else if (connectionStatus === ConnectionStatusType.InviteConnecting) {
      // CASE: attempting connect but no existing VP company yet, so invite must not be accepted yet
      availableActions.push(NetworkActions.Reinvite);
    } else if (hasReceivedConnectionRequest) {
      // CASE: company exists but still pending request that the other company sent to this one
      availableActions.push(NetworkActions.Accept);
      availableActions.push(NetworkActions.Decline);
    } else if (connectionStatus === ConnectionStatusType.NetworkConnecting) {
      // CASE: company exists but still pending request that this company sent to them
      availableActions.push(NetworkActions.Resend);
      availableActions.push(NetworkActions.Cancel);
    } else if (connectionStatus === ConnectionStatusType.Connected) {
      if (canDisconnectFromCompany(state, companyId)) {
        availableActions.push(NetworkActions.Disconnect);
      }
    }
  }

  if (connectionStatus === ConnectionStatusType.Connected) {
    availableActions.push(NetworkActions.ViewNetworkProfile);
  }

  return availableActions;
}

export function determineAddressBookAvailableActions({
  connectionStatus,
  isVendor,
  state,
  companyId
}) {
  let availableActions = [];

  if (connectionStatus === ConnectionStatusType.Connected
    || !isVendor
    || connectionStatus === ConnectionStatusType.Connectable) {
    availableActions.push(InvoiceActions.CreateInvoice);
  }

  if (connectionStatus === ConnectionStatusType.Connected
    || isVendor
    || connectionStatus === ConnectionStatusType.Connectable) {
    availableActions.push(InvoiceActions.ViewOpenBills);
    availableActions.push(PaymentActions.SendPayment);
    availableActions.push(InvoiceActions.CreateBill);
  }

  // if they have an address book and if they have a profile that profile can be disconnected
  // then allow them to delete
  if (connectionStatus !== ConnectionStatusType.Connectable
    && canDisconnectFromCompany(state, companyId)) {
    availableActions.push(NetworkActions.DeleteAddressBook);
  }

  availableActions.push(NetworkActions.UpdateAddressBook);

  return availableActions;
}

function determineAvailableActions(company, apiCompany, state) {
  let connectionStatus = toConnectionStatus(apiCompany.status, !!apiCompany.profile && !!apiCompany.profile.id);
  let availableActions = [];

  availableActions = availableActions.concat(determineConnectionAvailableActions({
    companyId: apiCompany.profile ? apiCompany.profile.id : null,
    connectionStatus,
    hasReceivedConnectionRequest: apiCompany.profile ? !!apiCompany.profile.receivedConnectionRequestId : false
  }));

  if (apiCompany.relationships.vendor) {
    availableActions = availableActions.concat(determineAddressBookAvailableActions({
      connectionStatus,
      isVendor: true,
      companyId: apiCompany.profile ? apiCompany.profile.id : null,
      state
    }));
  }

  if (apiCompany.relationships.customer) {
    availableActions = availableActions.concat(determineAddressBookAvailableActions({
      connectionStatus,
      isVendor: false,
      companyId: apiCompany.profile ? apiCompany.profile.id : null,
      state
    }));
  }

  company.availableActions = filterAvailableActionsFromState(state, uniq(availableActions));
}

export function companyIdMapper(companyProfileId, addressBookId) {
  // repro API's composite generation for company id
  if (companyProfileId) {
    return `1${companyProfileId}`;
  }

  if (addressBookId) {
    return `2${addressBookId}`;
  }

  // return undefined
}

export function getCompanyResponseMapper(apiCompany, state) {
  // split into companyProfile and addressBook

  let entities = [];
  let references = [];


  // company profile
  if (apiCompany && apiCompany.id) {
    let company = {};
    let connectionStatus = toConnectionStatus(apiCompany.status, !!apiCompany.profile && !!apiCompany.profile.id);

    if (apiCompany.profile && apiCompany.profile.id) {
      entities.push(createEntity(
        apiCompany.profile.id,
        RetrievableCompanyProfile.meta.name,
        {
          id: apiCompany.profile.id,
          isVerified: apiCompany.isVerified,
          companyName: apiCompany.profile.name,
          firstName: apiCompany.profile.contact.firstName,
          lastName: apiCompany.profile.contact.lastName,
          email: apiCompany.profile.contact.email,
          phone: apiCompany.profile.phone,
          address: mapApiToAddress(apiCompany.profile.address, apiCompany.profile.name),
          description: apiCompany.profile.description,
          website: apiCompany.profile.website,
          fax: apiCompany.profile.fax,
          logoUrl: (apiCompany.profile.logo) ? apiCompany.profile.logo.selfUrl : null,
          logos: [] // not provided from API here
        }
      ));
      let reference = createEntityReference(apiCompany.profile.id, RetrievableCompanyProfile.meta.name, 'profile');
      company.profile = reference;
      if (apiCompany.profile.sentConnectionRequestId) {
        company.sentConnectionRequest = createEntityReference(
          apiCompany.profile.sentConnectionRequestId,
          ConnectionRequest.meta.name);
      }
      if (apiCompany.profile.receivedConnectionRequestId) {
        company.receivedConnectionRequest = createEntityReference(
          apiCompany.profile.receivedConnectionRequestId,
          ConnectionRequest.meta.name);
      }
      company.numberOfConnections = apiCompany.profile.numberOfConnections;
    }

    // add number of connections company has in case company profile doesnt exists
    if (apiCompany.relationships && !company.numberOfConnections) {
      company.numberOfConnections = apiCompany.relationships.numberOfConnections;
    }

    // vendor address book
    if (apiCompany.relationships && apiCompany.relationships.vendor) {
      let addressBook = apiCompany.relationships.vendor;
      entities.push(createEntity(
        addressBook.id,
        RetrievableAddressBookEntry.meta.name,
        {
          id: addressBook.id,
          isVerified: apiCompany.isVerified,
          companyName: addressBook.name,
          firstName: addressBook.contact.firstName,
          lastName: addressBook.contact.lastName,
          email: addressBook.contact.email,
          phone: addressBook.phone,
          address: mapApiToAddress(addressBook.address, addressBook.name),
          clientReferenceId: addressBook.clientReferenceId,
          relationship: AddressBookRelationships.Vendor,
          availableActions: determineAddressBookAvailableActions({
            connectionStatus,
            isVendor: true,
            state,
            companyId: apiCompany.profile ? apiCompany.profile.id : null
          })
        }
      ));
      let reference = createEntityReference(addressBook.id, RetrievableAddressBookEntry.meta.name, 'vendor');
      company.vendor = reference;
      apiCompany.clientReferenceId = addressBook.clientReferenceId;
    }

    // customer address book
    if (apiCompany.relationships && apiCompany.relationships.customer) {
      let addressBook = apiCompany.relationships.customer;
      let existingEntity = getEntity(state,
        createEntityReference(addressBook.id, RetrievableAddressBookEntry.meta.name));
      entities.push(createEntity(
        addressBook.id,
        RetrievableAddressBookEntry.meta.name,
        {
          id: addressBook.id,
          isVerified: apiCompany.isVerified,
          companyName: addressBook.name,
          firstName: addressBook.contact.firstName,
          lastName: addressBook.contact.lastName,
          email: addressBook.contact.email,
          phone: addressBook.phone,
          address: mapApiToAddress(addressBook.address, addressBook.name),
          clientReferenceId: addressBook.clientReferenceId,
          relationship: AddressBookRelationships.Customer,

          // this endpoint does not provide this value, so at a minimum don't overwrite any existing value here
          isCreditCardEnabled: existingEntity ? existingEntity.isCreditCardEnabled : null,

          availableActions: determineAddressBookAvailableActions({
            connectionStatus,
            isVendor: false,
            state,
            companyId: apiCompany.profile ? apiCompany.profile.id : null
          })
        }
      ));
      let reference = createEntityReference(addressBook.id, RetrievableAddressBookEntry.meta.name, 'customer');
      apiCompany.clientReferenceId = addressBook.clientReferenceId;
      company.customer = reference;
    }

    company.id = apiCompany.id;
    company.status = connectionStatus;
    company.clientReferenceId = apiCompany.clientReferenceId;
    company.requiresSubscription = !!apiCompany.requiresSubscription;
    determineAvailableActions(company, apiCompany, state);

    // company
    entities.push(createEntity(
      apiCompany.id,
      Company.meta.name,
      company
    ));
    references.push(createEntityReference(apiCompany.id, Company.meta.name, 'company'));
  }

  return {
    entities,
    references
  };
}

export function updateCompanyRequestBodyMapper(apiParams) {
  if (apiParams.action === NetworkActions.Disconnect) {
    return {
      shouldDisconnect: true
    };
  }
  return {
    id: apiParams.id,
    status: apiParams.status,
    relationships: apiParams.relationships
  };
}

export function getCustomersEnabledForCreditCardResponseMapper(apiParams) {
  return {
    customersEnabledForCreditCard: apiParams.vendorAddressBookIds
  };
}

export function createCompanyRequestBodyMapper(creatableAddressBook) {
  return {
    relationships: {
      [creatableAddressBook.relationship]: {
        name: creatableAddressBook.companyName,
        phone: creatableAddressBook.phone,
        contact: {
          firstName: creatableAddressBook.firstName,
          lastName: creatableAddressBook.lastName,
          email: creatableAddressBook.email
        },
        address: mapAddressToApi(creatableAddressBook.address),
        clientReferenceId: creatableAddressBook.clientReferenceId
      }
    }
  };
}

export function uploadCompanyLogoRequestMapper(apiParams) {
  let formData = new FormData();

  let imageBlob = dataUriToBlob(apiParams.dataUri);

  formData.append('file', imageBlob);
  return formData;
}

export function uploadCompanyLogoResponseMapper(apiResponse, state, apiParams) {
  let entities = [];

  if (apiResponse && apiResponse.length > 0) {
    let companyId = apiParams.companyId;

    // update existing or create new company profile
    let companyProfile = getEntity(state, createEntityReference(companyId, RetrievableCompanyProfile.meta.name));
    if (!companyProfile) {
      companyProfile = {
        id: companyId
      };
    }

    // update logos
    companyProfile.logos = companyProfile.logos || [];
    apiResponse.forEach((apiLogo) => {

      // replace or create logo
      companyProfile = RetrievableCompanyProfile.update(companyProfile, {
        logos: {
          $apply: (logos) => {
            let newLogos = [];
            logos.forEach((logo) => {
              if (logo.type === apiLogo.logoType) {
                newLogos.push({
                  ...logo,
                  type: apiLogo.logoType,
                  url: apiLogo.imageUrl
                });
              } else {
                newLogos.push(logo);
              }
            });
            if (!newLogos.find(l => l.type === apiLogo.logoType)) {
              newLogos.push({
                type: apiLogo.logoType,
                url: apiLogo.imageUrl
              });
            }
            return newLogos;
          }
        }
      });

      // update primary logo
      if (apiLogo.logoType === 'Profile') {
        companyProfile = RetrievableCompanyProfile.update(companyProfile, {
          logoUrl: { $set: apiLogo.imageUrl }
        });
      }
    });

    let entity = createEntity(companyProfile.id,
      RetrievableCompanyProfile.meta.name,
      companyProfile);
    entities.push(entity);
  }

  return {
    entities
  };
}

export const getIndustryTypesResponseMapper = ({ entries }) => {
  let entities = [];
  let references = [];

  if (entries && entries.length) {
    entries.map((entry) => {
      const industryType = createEntity(
        entry.id,
        RetrievableIndustryType.meta.name, {
          id: entry.id,
          name: entry.name
        }
      );
      entities.push(industryType);
      references.push(industryType.createReference());
    });
  }

  return {
    entities,
    references
  };
};


function toTier2PersonData(formValue) {
  return {
    title: formValue.title,
    firstName: formValue.firstName,
    lastName: formValue.lastName,
    dateOfBirth: formValue.dateOfBirth,
    addressLine1: formValue.line1,
    addressLine2: formValue.line2,
    city: formValue.city,
    stateProvince: formValue.state,
    postalCode: formValue.zip,
    countryOfResidence: formValue.countryOfResidence,
    countryOfBirth: formValue.countryOfBirth,
    socialSecurityNumber: formValue.ssn,
    passportNumber: formValue.passportNumber,
    issuingCountry: formValue.issuingCountry,
    documentNumber: formValue.otherNumber,
    documentType: formValue.otherType
  };
}

export function updateBeneficialOwnerInfoRequestMapper(params) {
  return {
    controllingOwner: toTier2PersonData(params.controllingOwner),
    beneficialOwners: params.beneficialOwners
      ? params.beneficialOwners.map(bo => toTier2PersonData(bo))
      : []
  };
}
