/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { connect } from 'react-redux';
import UserSettingsApi from 'api/settings/user';
import updateProfile from 'actions/settings/updateProfile';
import { ToastTypes } from 'config/constants';
import { showToast } from 'actions/notification';
import { SecurityQuestionOptions } from 'schemas/settings/user';
import { getEntity, createEntityReference } from 'schemas/state';

function MapStateToProps(state, props) {
  const viewState = state.settings && state.settings.AccountSettingsPage
    ? state.settings.AccountSettingsPage
    : null;

  const optionsEntity = getEntity(state, createEntityReference('current', SecurityQuestionOptions.meta.name));

  return {
    securityQuestions: optionsEntity ? optionsEntity.options : null,
    userSecurityQuestionIds: viewState.userSecurityQuestionIds
  };
}

function MapDispatchToProps(dispatch) {
  return {
    updateProfile: (params, callback) => dispatch(updateProfile(params, callback)),
    getUserProfile: () => dispatch(UserSettingsApi.getUserProfile()),
    showConfirmationToast: message => dispatch(showToast({
      type: ToastTypes.success,
      message
    }))
  };
}

export default connect(MapStateToProps, MapDispatchToProps, null, { forwardRef: true });
