/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { Component } from 'react';
import { initialize, hideWidget, showWidget } from 'services/ZendeskHelpService';
import ProvideIsMobile from 'decorators/ProvideIsMobile';
import connect from './index.connect';

class ZendeskHelpService extends Component {
  componentDidMount() {
    if (!process.config.zendeskHelpId) return;
    initialize(process.config.zendeskHelpId);
    if (!this.props.isLoggedIn || this.props.isMobile) {
      hideWidget();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.isLoggedIn && !prevProps.isLoggedIn) {
      if (!this.props.isMobile) {
        showWidget();
      } else {
        hideWidget();
      }
    } else if (!this.props.isLoggedIn && prevProps.isLoggedIn) {
      hideWidget();
    } else if (this.props.isLoggedIn) {
      if (!this.props.isMobile && prevProps.isMobile) {
        showWidget();
      } else if (this.props.isMobile && !prevProps.isMobile) {
        hideWidget();
      }
    }
  }

  render() {
    return null;
  }
}

export default connect(ProvideIsMobile(ZendeskHelpService));
