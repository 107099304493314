/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, { memo, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

import getApplicationSetting, { AppSetting } from 'actions/app/getApplicationSetting';
import { getApi } from 'api/core/init';

// This replaces an implementation where the API Config took a direct dependency
// on react-redux to set the CSRF header. In order to make API Config redux
// agnostic, it now takes a list of 'headerProviders' that generate the headers
// and the component below facilitates a provider for the CSRF token.
// JL Note, this also could have been solved with a state redux subscriber, but
// I have a hammer and I want to nail this screw in - so sue me
const ApiConfigService = memo(() => {
  // Create a ref to store the token. We do this so we don't need to rebind
  // to get the latest and greatest token.
  const csrfValueRef = useRef();

  // Create a ref to store the function that provides the above value as a
  // header. This will never change after creation but we want referential
  // equality so we can remove it if this component ever unmounts
  const { current: setHeaderFunc } = useRef(
    ({ isInternalApi }) => isInternalApi && csrfValueRef.current ? ({
      '__RequestVerificationToken': csrfValueRef.current
    }) : ({})
  );

  // This changing will be only reason this component rerenders
  const csrfToken = useSelector(
    state => getApplicationSetting(state, AppSetting.Session, { notExpected: true }).csrfToken
  );

  // And if we rerender, that means we have a new CSRF token, set the value ref
  // to the latest and greatest
  csrfValueRef.current = csrfToken;

  // We just need to make sure our provider gets added once
  useEffect(
    () => {
      const api = getApi();

      if (!api.headerProviders.includes(setHeaderFunc)) {
        api.headerProviders.push(setHeaderFunc);
      }

      // And if for some reason this component ever unmounts, remove the
      // provider, because its very likely this component is just going to
      // remount and add a new provider - and we don't want old CSRF token
      // providers mucking up the works.
      return () => {
        getApi().headerProviders = api.headerProviders
          .filter(x => x !== setHeaderFunc);
      };
    },
    []
  );

  return <></>;
});

export default ApiConfigService;