/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, { useState } from 'react';
import WhiteHeaderOnWhiteLayout from 'layouts/WhiteHeaderOnWhiteLayout';

import HeaderText from 'components/HeaderText';
import BlankSlate from 'components/BlankSlate';
import ErnieImage from 'images/ernie/email-sent.png';

import Details from './details';

export default function GuidedHelp({ secureToken, secureTokenContext, onSubmit }) {
  const [isSubmitted, setSubmitted] = useState(false);

  let submitHelpRequest = (value, onComplete) => {
    onSubmit(value, (success) => {
      if (success) {
        setSubmitted(true);
      } else {
        // allow children to handle errors
        if (onComplete) onComplete(success);
      }
    });
  };

  let content = null;
  if (isSubmitted) {
    content = (
      <div>
        <HeaderText style={{ paddingBottom: 25 }}>
          Thank You
        </HeaderText>
        We're processing your request. We'll get back with you as soon as possible.
        <BlankSlate image={ErnieImage} imageAlt="Help Request Sent!" />
      </div>
    );
  } else {
    content = (
      <Details paymentData={secureTokenContext?.data} secureToken={secureToken}
        onSubmit={(...args) => submitHelpRequest(...args)} />
    );
  }

  return (
    <WhiteHeaderOnWhiteLayout>
      <div className="viewstrap">
        <div style={{ textAlign: 'center' }}>
          <div style={{ maxWidth: '660px', display: 'inline-block' }}>
            {content}
          </div>
        </div>
      </div>
    </WhiteHeaderOnWhiteLayout>
  );
}
