/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, { useCallback, useMemo } from 'react';
import classNames from 'classnames';
import { isString } from 'lodash';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import ReactSelect from 'react-select';

import BrowserDetection from 'services/BrowserDetection';

import NativeSelect from './native';
import Messages from './index.messages';
import './index.scss';
import 'style-loader!css-loader!react-select/dist/react-select.min.css';

export const getEnumOptionMap = (type) => {
  if (!type || !type.meta) {
    // no tcomb type
    return null;
  }

  if (type.meta.kind === 'maybe') {
    // pass over maybe for t.maybe(t.enums(...))
    type = type.meta.type;
  }

  if (type.meta.kind !== 'enums') {
    // not an enum
    return null;
  }

  return type.meta.map;
};

/**
 * Support reading options out of a tcomb enum
 */
export const getOptionsFromEnum = ({ type, labels }) => {
  if (!type || !type.meta) {
    // no tcomb type
    return null;
  }

  if (type.meta.kind === 'maybe') {
    // pass over maybe for t.maybe(t.enums(...))
    type = type.meta.type;
  }

  if (type.meta.kind !== 'enums') {
    // not an enum
    return null;
  }

  const enumMap = getEnumOptionMap(type);
  if (!enumMap) return null;

  return Object.keys(enumMap).map((enumKey) => {
    let enumValue = enumMap[enumKey];

    if ((labels || {})[enumKey]) {
      enumValue = <FormattedMessage {...labels[enumKey]} />;
    }

    // follow option format
    return {
      value: enumKey,
      text: enumValue
    };
  });
};

const isMaybeType = type => ((type || {}).meta || {}).kind === 'maybe';

/**
 * Select component
 */
const Select = ({
  clearable,
  config,
  disabled,
  hasError,
  inputProps,
  labels,
  name,
  neverNative,
  onBlur,
  onChange,
  onFocus,
  placeholder: placeholderProp,
  renderOptionLabel,
  searchable: searchableProp,
  selectOptions: selectOptionsProp,
  selectProps,
  style,
  type,
  multiple,
  value
}) => {
  // Hooks
  const intl = useIntl();

  const nullValue = isMaybeType(type) ? null : '';
  const defaultPlaceholder = multiple ? 'Select Multiple' : intl.formatMessage(Messages.DefaultOption);
  const placeholder = placeholderProp || defaultPlaceholder;
  const {
    expandDropdownList,
    labels: configLabels,
    searchable: configSearchable
  } = config || {};

  const nullOption = useMemo(
    () => ({
      value: nullValue,
      text: placeholder,
      hidden: true
    }),
    [ nullValue, placeholder ]
  );

  const rawOptions = useMemo(
    () => {
      let options = null;
      if (selectOptionsProp) {
        // manually defined option objects
        options = selectOptionsProp;
      } else {
        // auto generate options from tcomb enums
        options = getOptionsFromEnum({
          type,
          labels: labels || configLabels
        });
      }

      return (options || []).filter(({ hidden }) => !hidden);
    },
    [ type, configLabels, labels, selectOptionsProp ]
  );

  const shouldUseNativeSelect = !neverNative
    && (BrowserDetection.isIOS() || BrowserDetection.isAndroid());

  const options = useMemo(
    () => rawOptions.map((option, index) => {
      let text = null;
      let richText = null;

      if (renderOptionLabel) {
        const rendered = renderOptionLabel(option, index);
        if (isString(rendered)) {
          text = rendered;
        } else {
          richText = rendered;
        }
      }

      text = text || option.text || option.richText;
      richText = richText || option.richText || text || option.text;

      return {
        value: option.value,
        // give priority to text or richText depending on which Select is being used
        text: shouldUseNativeSelect ? text : richText,
        style: option.style
      };
    }),
    [ rawOptions, renderOptionLabel, shouldUseNativeSelect ]
  );

  const onSelectChange = useCallback(
    (event) => {
      // parse value
      let newValues = null;
      if (event && multiple) {
        // multi select - array is returned
        newValues = event?.map(e => e.value) ?? [];
      } else if (event) {
        // single value
        newValues = [event.value];
        if (event.target) {
          newValues = [event.target.value];
        }
      }

      const selectedOption = rawOptions.find(
        option => option.value === newValues?.[Math.max(newValues?.length - 1, 0)]
      );
      selectedOption?.onClick?.();

      if (multiple) {
        onChange?.(newValues);
      } else {
        onChange?.(newValues?.[0]);
      }
    },
    [ rawOptions, onChange ]
  );

  // Render

  const actualValue = value || nullValue;
  const actualStyle = style || (inputProps || {}).style;

  if (shouldUseNativeSelect) {
    return (
      <NativeSelect
        disabled={disabled}
        hasError={hasError}
        name={name}
        onBlur={onBlur}
        onChange={onSelectChange}
        onFocus={onFocus}
        options={[ nullOption, ...options ]}
        placeholder={placeholder}
        style={actualStyle}
        value={actualValue}
      />
    );
  }

  const searchable = configSearchable != null
    ? configSearchable
    : (searchableProp == null || searchableProp);

  return (
    <ReactSelect
      className={classNames({
        'has-error': hasError,
        'expand-dropdown-list': expandDropdownList
      })}
      clearable={!!clearable && !!actualValue && !multiple}
      disabled={disabled}
      labelKey="text"
      matchPos="start"
      matchProp="text"
      multi={multiple}
      name={name}
      onBlur={onBlur}
      onChange={onSelectChange}
      onFocus={onFocus}
      options={options}
      placeholder={placeholder}
      searchable={searchable}
      style={actualStyle}
      value={actualValue}
      {...(selectProps || {})}
    />
  );
};

Select.propTypes = {
  /** HTML name of the form input */
  name: PropTypes.string,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  value: PropTypes.string,

  /** manually defined options */
  selectOptions: PropTypes.arrayOf(
    PropTypes.shape({
      /** value to use for this option */
      value: PropTypes.string.isRequired,
      /** String content to use for the option  */
      text: PropTypes.string,
      /** Rich content to use for the option. This will only be used if ReactSelect is what is being rendered.  */
      richText: PropTypes.node,
      /** Show the option as disabled */
      disabled: PropTypes.bool,
      /** do not show the option at all */
      hidden: PropTypes.bool,
      /** Optional additional handlers to call for this option */
      onClick: PropTypes.func
    })
  ),
  /** optional tcomb schema type (handled by default via FormJSX) */
  type: PropTypes.any, // could be object or func
  /** Whether or not this field is in an error state (e.g. passed down by tcomb form) */
  hasError: PropTypes.bool,
  /** Allows custom rendering of an option label (e.g. if using default tcomb enum, but want something other than enum value)  */
  renderOptionLabel: PropTypes.func,
  /** disable/enable searching within React Select */
  searchable: PropTypes.bool,
  /** Enable a clear button for this select */
  clearable: PropTypes.bool
};

Select.createTransformer = type => ({
  format: (val) => {
    if (isMaybeType(type) && val === '') return null;
    return val;
  },
  parse: val => val
});

export default Select;