/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */
/* eslint max-len:0 */

import { defineMessages } from 'react-intl';

const common = defineMessages({
  Title: {
    id: 'SecurityQuesetionsForm.Title',
    defaultMessage: 'Security Questions'
  }
});

const formLabels = defineMessages({
  Question1: {
    id: 'SecurityQuesetionsForm.formLabels.Question1',
    defaultMessage: 'Question 1'
  },
  Question2: {
    id: 'SecurityQuesetionsForm.formLabels.Question2',
    defaultMessage: 'Question 2'
  },
  Question3: {
    id: 'SecurityQuesetionsForm.formLabels.Question3',
    defaultMessage: 'Question 3'
  },
  ShowAnswers: {
    id: 'SecurityQuesetionsForm.formLabels.ShowAnswers',
    defaultMessage: 'Show Answers'
  }
});

const placeholders = defineMessages({
  YourAnswer: {
    id: 'SecurityQuesetionsForm.placeholders.YourAnswer',
    defaultMessage: 'Your answer'
  }
});

export default {
  ...common,
  formLabels,
  placeholders
};
