/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import createApiActions from 'api/core';
import {
  batchRequestMapper,
  batchResponseMapper,
  documentStoreKvpRequestMapper,
  getDocumentStoreResponseMapper,
  documentStoreKvpErrorMapper,
  getAcknowledgementsResponseMapper,
  queryParamNameMapper,
  defaultMapper
} from './mappers';

export default createApiActions({
  getDocumentStoreValue: {
    method: 'get',
    url: '/api/application-document-store/:namespace/:permissions/:key?no404=true',
    acceptEmptyResponse: true,
    requestBodyMapper: documentStoreKvpRequestMapper,
    responseMapper: getDocumentStoreResponseMapper
  },
  updateDocumentStoreValue: {
    method: 'put',
    url: '/api/application-document-store/:namespace/:permissions/:key',
    requestBodyMapper: documentStoreKvpRequestMapper,
    queryStringMapper: queryParamNameMapper({
      'secureToken': 'secureToken'
    })
  },
  deleteDocumentStoreValue: {
    method: 'delete',
    url: '/api/application-document-store/:namespace/:permissions/:key',
    shouldErrorMaptoSuccess: documentStoreKvpErrorMapper
  },
  batch: {
    method: 'post',
    url: '/api/batch',
    requestBodyMapper: batchRequestMapper,
    responseMapper: batchResponseMapper
  },
  getAcknowledgement: {
    method: 'get',
    url: '/ajax/acknowledgements/:id',
    responseMapper: getAcknowledgementsResponseMapper
  },
  setAcknowledgement: {
    method: 'post',
    url: '/ajax/acknowledgements',
    requestBodyMapper: defaultMapper
  },
  deleteAcknowledgement: {
    method: 'post',
    url: '/ajax/acknowledgements/:key/delete',
    queryStringMapper: queryParamNameMapper({
      'key': 'key'
    })
  },
  getBatchJobStatus: {
    method: 'get',
    url: '/api/processing/jobs/status',
    queryStringMapper: r => r,
    responseBodyMapper: r => r
  }
});
