/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, { useContext } from 'react';
import classNames from 'classnames';
import { isObject, pickBy } from 'lodash';
import { NavLink } from 'react-router-dom';
import AnalyticsContext from 'components/AnalyticsCategory/context';

const getId = ({
  analyticsId,
  category
}) => {
  if (!analyticsId) return null;
  return category ? `${category}.${analyticsId}` : analyticsId;
};

const getDataParams = props => Object.keys(props)
  .filter(key => key.startsWith('data-'))
  .reduce(
    (val, key) => ({
      ...val, [key]: props[key]
    }),
    {}
  );

const removeInvalidProps = props => pickBy(
  props,
  (value, key) => value != null
);

/** @docsname {Link} */
const Link = ({
  activeClassName,
  activeStyle,
  analyticsId,
  button,
  children,
  className,
  exact,
  hash,
  href,
  isOutsideApp,
  onClick,
  onlyActiveOnIndex,
  query,
  style,
  tabIndex,
  target,
  to,
  ...props
}) => {
  const { category } = useContext(AnalyticsContext) || {};

  const anchorProps = {
    children,
    className,
    hash,
    onClick,
    query,
    style,
    tabIndex,
    target,
    ...getDataParams(props)
  };

  if (button) {
    const buttonConfig = {
      variant: 'primary',
      expand: false,
      ...(isObject(button) ? button : {})
    };

    const { variant, expand } = buttonConfig;

    anchorProps.className = classNames(
      'viewstrap',
      anchorProps.className,
      'vp-button',
      { 'primary': variant === 'primary' },
      { 'secondary': variant === 'secondary' },
      { 'tertiary': variant === 'tertiary' },
      { 'success': variant === 'success' },
      { 'utility': variant === 'utility' },
      { 'transparent': variant === 'transparent' },
      { 'block': expand }
    );
  }

  if (!isOutsideApp && (to || '').startsWith('/')) {
    // pull out only the props that can be spread over the <NavLink> element
    const navLinkProps = {
      ...anchorProps,
      id: getId({ analyticsId, category }),
      activeClassName,
      activeStyle,
      exact,
      onlyActiveOnIndex,
      to
    };

    return (
      <NavLink {...removeInvalidProps(navLinkProps)}>
        {children}
      </NavLink>
    );
  }

  return (
    <a
      href={href || to}
      {...anchorProps}
    >
      {children}
    </a>
  );
};

export default Link;
