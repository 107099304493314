/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, { forwardRef, useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ButtonVariants } from 'config/constants';
import AnalyticsContext from 'components/AnalyticsCategory/context';
import generateDropdown from 'components/Dropdown/generateDropdown';
import MeatballCaret from 'components/Dropdown/MeatballDropdownToggle/MeatballCaret';
import LoadingSpinner from 'components/LoadingSpinner';
import AnalyticsActionsContext from 'containers/Services/AnalyticsService/context';
import './index.scss';

export { ButtonVariants };

let lastEventType = null;
let lastTarget = null;

const storeLastEvent = (e) => {
  if (!e) {
    lastTarget = null;
    lastEventType = null;
  } else {
    e.persist();
    lastTarget = e.target;
    lastEventType = e.type;
  }
};

const onMouseUp = (e) => {
  // HACK: This is attempt to fix the issue where a rerender between a mousedown and mouseup does not fire a click
  // e.g. If a rerender occurs onBlur of an input caused by a button's mousedown, the click will not fire on the button
  e.persist();
  // delay to see if click event fires imediately after this
  setTimeout(() => {
    if (e.button === 0 // left mouse button (ignore middle/right clicks)
      && e.target.isEqualNode(lastTarget) // same element
      && lastEventType === 'mousedown' // if click didn't override this, it didn't happen
    ) {
      this.onClick(e); // simulate a click
    }
    storeLastEvent(null);
  }, 10);
};

export const DropdownButton = generateDropdown(
  ({
    children,
    onClick,
    tall,
    isOpen,
    isSplit,
    label,
    sharpCorners,
    squash,
    style,
    variant
  }) => {
    const className = classNames(
      'combo-dropdown',
      {'split-right': isSplit},
      {'squash': squash}
    );

    const buttonStyle = {
      minWidth: tall ? 50 : 36,
      outline: 'none'
    };

    if (label) {
      buttonStyle.minWidth = 100;
    }

    return (
      <Button
        onClick={onClick}
        variant={variant}
        className={className}
        style={{...buttonStyle, ...(style || {})}}
        sharpCorners={sharpCorners}
      >
        {label ? (
          <span className="more-label">{label}</span>
        ) : null}
        <MeatballCaret variant={variant !== 'primary' ? null : 'white'} isOpen={isOpen}/>
        {children}
      </Button>
    );
  },
  {
    caretComponent: () => <span/>,
    useToggleForPopover: true
  }
);

/**
 * Standard all-purpose button
 */
const Button = forwardRef((
  {
    analyticsAction,
    analyticsId,
    anchor,
    bold,
    children,
    className,
    circle,
    dropdownButtonLabel,
    dropdownHeader,
    dropdownPlacement,
    dropdownType,
    expand,
    dropdownPanel,
    ignoreHelpAnimation,
    inline,
    implicit,
    isDisabled,
    isProcessing,
    isSelected,
    onClick: originalOnClick,
    sharpCorners,
    size,
    squashCombo,
    style,
    tabIndex,
    tall,
    type,
    uppercase,
    variant
  },
  ref
) => {

  const {
    category: analyticsCategory
  } = useContext(AnalyticsContext) || {};

  const {
    trackEvent
  } = useContext(AnalyticsActionsContext) || {};

  const getClasses = additionalClasses => classNames(
    'viewstrap',
    className,
    {
      'vp-anchor': anchor,
      'vp-button': !anchor,
      'primary': variant === 'primary' || type === 'submit',
      'secondary': variant === 'secondary',
      'tertiary': variant === 'tertiary',
      'success': variant === 'success',
      'utility': variant === 'utility',
      'transparent': variant === 'transparent',
      'high-impact': variant === 'high-impact',
      'large-step': variant === 'large-step',
      'block': expand,
      'icon': size === 'small',
      'circle': circle,
      'link': variant === 'link' || variant === 'secondary-link',
      'secondary-link': variant === 'secondary-link',
      'delete': variant === 'delete',
      'tall': tall,
      'bold': bold,
      'inline-anchor': inline && anchor,
      'sharp-corners': sharpCorners,
      'uppercase': uppercase,
      'selected': isSelected,
      implicit
    },
    additionalClasses
  );

  const renderButton = (additionalClasses) => {
    const classes = getClasses(additionalClasses);

    let isDark = variant === 'primary' || variant === 'success' || isDisabled;
    let loader = isProcessing
      ? <LoadingSpinner onDark={isDark}/>
      : null;
    const ButtonComponent = inline && anchor ? 'span' : 'button';

    const getId = () => {
      if (!analyticsId) return null;

      if (analyticsCategory) {
        return `${analyticsCategory}.${analyticsId}`;
      }

      return analyticsId;
    };

    const onClick = (e) => {
      storeLastEvent(e);

      // track analytics
      if (analyticsCategory && trackEvent) {
        trackEvent(
          analyticsCategory,
          analyticsAction
        );
      }

      if (originalOnClick && !isDisabled) {
        originalOnClick(e);
      }
    };

    return (
      <ButtonComponent
        id={getId()}
        style={style}
        type={type}
        className={classes}
        onMouseDown={storeLastEvent}
        onMouseUp={onMouseUp}
        onClick={onClick}
        disabled={isDisabled}
        tabIndex={tabIndex}
      >
        {children}
        {loader ? ' ' : null}
        {loader}
      </ButtonComponent>
    );
  };

  if (dropdownPanel) {
    return (
      <div>
        <div
          className={classNames(
            'vp-buttons',
            {
              'block': expand,
              'split': !!children,
              'tall': tall
            }
          )}
        >
          {children
            ? renderButton('split-left')
            : null}
          <DropdownButton
            ref={ref}
            isSplit={!!children}
            popoverPlace={dropdownPlacement}
            dropdownType={dropdownType}
            header={dropdownHeader}
            label={dropdownButtonLabel}
            tall={tall}
            sharpCorners={sharpCorners}
            squash={squashCombo}
            ignoreHelpAnimation={ignoreHelpAnimation}
            variant={variant}
          >
            {dropdownPanel}
          </DropdownButton>
        </div>
      </div>
    );
  }

  return renderButton();
});

Button.propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.string,
  /** the style/look of the button */
  variant: PropTypes.oneOf([
    'primary',
    'secondary',
    'tertiary',
    'success',
    'transparent',
    'utility',
    'link',
    'delete',
    'secondary-link',
    'high-impact',
    'large-step'
  ]),
  /** HTML type attribute for the button */
  type: PropTypes.oneOf(['button', 'submit']),
  isDisabled: PropTypes.bool,
  /** Shows a waiting/loading indicator on the button */
  isProcessing: PropTypes.bool,
  /** a block (width 100%) button */
  expand: PropTypes.bool,
  /** Flags a button that looks like a link (anchor) tag */
  anchor: PropTypes.bool,
  /** flag used for small/icon buttons */
  size: PropTypes.oneOf(['normal', 'small']),
  /** Makes a circular button */
  circle: PropTypes.bool,
  /** Content for a dropdown panel for this button. (This turns the button into a 'combo button') */
  dropdownPanel: PropTypes.node,
  dropdownPlacement: PropTypes.string,
  sharpCorners: PropTypes.bool
};

Button.defaultProps = {
  variant: 'primary',
  type: 'button',
  isDisabled: false,
  isProcessing: false,
  expand: false,
  size: 'normal',
  circle: false, // make a circle button
  dropdownPanel: null, // combobutton
  dropdownPlacement: 'below',
  sharpCorners: false
};

export default Button;