/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React from 'react';
import { Route, Switch } from 'react-router';
import { wrapImport } from 'decorators/ModuleLoader';
import { InAppNotFoundView } from '../utils';

const Sync = wrapImport(() => import(/* webpackChunkName: "views" */ 'views/Sync/sync'));
const SyncHistory = wrapImport(() => import(/* webpackChunkName: "views" */ 'views/Sync/History'));
const SyncOnboarding = wrapImport(() => import(/* webpackChunkName: "views" */ 'views/Sync/Onboarding'));
const SyncPayments = wrapImport(() => import(/* webpackChunkName: "views" */ 'views/Sync/Payments'));
const SyncDownloadResults = wrapImport(() => import(/* webpackChunkName: "views" */ 'views/Sync/DownloadResults'));
const SyncPermissionCheck = wrapImport(() => import(/* webpackChunkName: "views" */ 'views/Sync/permissionCheck'));
const SyncSettings = wrapImport(() => import(/* webpackChunkName: "views" */ 'views/Sync/Settings'));
const MicrosoftDynamicsGp = wrapImport(
  () => import(/* webpackChunkName: "views" */ 'views/Sync/packages/MicrosoftDynamics')
);
const Oracle = wrapImport(() => import(/* webpackChunkName: "views" */ 'views/Sync/packages/oracle'));
const Xero = wrapImport(() => import(/* webpackChunkName: "views" */ 'views/Sync/packages/Xero'));
const QuickBooksDesktop = wrapImport(() => import(/* webpackChunkName: "views" */ 'views/Sync/packages/quickbooks'));
const QuickbooksOnline = wrapImport(
  () => import(/* webpackChunkName: "views" */ 'views/Sync/packages/quickbooksOnline')
);
const QBOnline = wrapImport(() => import(/* webpackChunkName: "views" */ 'views/Sync/packages/QBOnline'));
const NetSuite = wrapImport(() => import(/* webpackChunkName: "views" */ 'views/Sync/packages/NetSuite'));
const SageLive = wrapImport(() => import(/* webpackChunkName: "views" */ 'views/Sync/packages/SageLive'));
const Riskmaster = wrapImport(() => import(/* webpackChunkName: "views" */ 'views/Sync/packages/Riskmaster'));

export default ({ match }) => (
  <Switch>
    <Route path={match.url} exact={true} component={Sync} />
    <Route path={`${match.url}/landing`} exact={true} component={SyncPermissionCheck} />
    <Route path={`${match.url}/settings`} exact={true} component={SyncSettings} />
    <Route path={`${match.url}/history`} exact={true} component={SyncHistory} />
    <Route path={`${match.url}/payments`} exact={true} component={SyncPayments} />
    <Route path={`${match.url}/download`} exact={true} component={SyncDownloadResults} />
    <Route path={`${match.url}/download/Xero`} exact={true} component={Xero} />
    <Route path={`${match.url}/download/QBOnline`} exact={true} component={QBOnline} />
    <Route path={`${match.url}/download/NetSuite`} exact={true} component={NetSuite} />
    <Route path={`${match.url}/download/SageLive`} exact={true} component={SageLive} />
    <Route path={`${match.url}/download/Riskmaster`} exact={true} component={Riskmaster} />
    <Route path={`${match.url}/onboarding/QuickBooksOnline`} exact={true}
      component={props => <SyncOnboarding acctPackage="QuickBooksOnline" {...props}/>} />
    <Route path={`${match.url}/onboarding/Xero`} exact={true}
      component={props => <SyncOnboarding acctPackage="Xero" {...props}/>} />
    <Route path={`${match.url}/download/QuickBooksDesktop`} exact={true} component={QuickBooksDesktop} />
    <Route path={`${match.url}/download/QuickbooksOnline`} exact={true} component={QuickbooksOnline} />
    <Route path={`${match.url}/download/MicrosoftDynamicsGp`} exact={true} component={MicrosoftDynamicsGp} />
    <Route path={`${match.url}/download/Oracle`} exact={true} component={Oracle} />
    {InAppNotFoundView}
  </Switch>
);
