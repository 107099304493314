/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { defineMessages } from 'react-intl';

export default defineMessages({
  Today: {
    id: 'FormattedDate.Today',
    description: 'TODO',
    defaultMessage: 'Today'
  },
  Yesterday: {
    id: 'FormattedDate.Yesterday',
    description: 'TODO',
    defaultMessage: 'Yesterday'
  },
  FormattedDateTime: {
    id: 'FormattedDate.FormattedDateTime',
    description: 'TODO',
    defaultMessage: '{formattedDate} at {formattedTime}'
  }
});
