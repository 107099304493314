/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import t from 'tcomb-validation';
import { defineSchema } from 'schemas';
import Email from 'schemas/common/email/Email';
import { CardNumber, CardExpirationDate, CardVerificationCode } from 'schemas/payments/creditCard';
import { PostalCode } from 'schemas/common/address';

export const StripeAccount = defineSchema({
  email: t.maybe(t.String),
  isActive: t.Boolean
}, 'StripeAccount');

export const StripeSubscriptionPaymentForm = t.struct({
  cardholderName: t.String,
  cardNumber: CardNumber,
  expirationDate: CardExpirationDate,
  cvc: CardVerificationCode,
  postalCode: PostalCode
});

export const StripeOnboardingForm = t.struct({
  email: Email,
  cardholderName: t.String,
  cardNumber: CardNumber,
  expirationDate: CardExpirationDate,
  cvc: CardVerificationCode,
  postalCode: PostalCode
});
