/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, { forwardRef, useMemo } from 'react';

import CheckBox from 'components/Form/Inputs/CheckBox';
import CurrencyTextBox from 'components/Form/Inputs/CurrencyTextBox';
import PhoneTextBox from 'components/Form/Inputs/PhoneTextBox';
import PostalCodeTextBox from 'components/Form/Inputs/PostalCodeTextBox';
import CreditCardDateTextBox from 'components/Form/Inputs/CreditCardDateTextBox';
import PercentTextBox from 'components/Form/Inputs/PercentTextBox';
import SelectPaymentMethod from 'containers/Form/Inputs/SelectPaymentMethod';
import SelectPaymentMethodV2 from 'containers/Form/Inputs/SelectPaymentMethod/V2.js';
import SelectIndustryType from 'containers/Form/Inputs/SelectIndustryType';
import IOSNativeDate from 'components/Form/Inputs/IOSNativeDate';
import RecaptchaInput from 'components/Recaptcha';
import TextBox from 'components/Form/Inputs/TextBox';

import BrowserDetection from 'services/BrowserDetection';

import Select from '../Inputs/Select';
import DatePicker from '../Inputs/DatePicker';
import AddressInput from '../Inputs/AddressInput';
import { getActualType, getKind } from './utils';

// This is a configuration component that'll get replaced with the real input
// component with the props merged with the base input field props. Must be a
// child of FieldOption.
const FieldInput = forwardRef(
  (
    {
      inputComponent: InputComponent,
      ...props
    },
    ref
  ) => (
    <InputComponent ref={ref} {...props} />
  )
);

FieldInput.isFieldInput = true;

export default FieldInput;

const isSubTypeWithName = (name, type) => getKind(type) === 'subtype'
  && (type.meta.name || '').startsWith(name);

const isMaybeTypeMatch = (type, kind, name) => {
  const typeMeta = (type || {}).meta || {};
  if (typeMeta.kind !== 'maybe') return false;
  const subTypeMeta = (typeMeta.type || {}).meta || {};

  if (kind && subTypeMeta.kind !== kind) {
    return false;
  }

  if (name && subTypeMeta.name !== name) {
    return false;
  }

  return true;
};

const getInputComponent = (type) => {
  const { kind, name } = (type || {}).meta || {};
  const subType = getActualType(type, true);
  const { name: actualName, displayName, meta: actualMeta } = getActualType(type);
  const { kind: actualKind } = actualMeta || {};

  if (kind === 'struct' && name === 'Address') {
    return AddressInput;
  }

  if (isMaybeTypeMatch(type, 'struct', 'Address')) {
    // t.maybe(Address)
    return AddressInput;
  }

  if (name === 'Date' || actualName === 'Date' || isSubTypeWithName('Date', subType)) {
    if (!BrowserDetection.isIOS() && !BrowserDetection.isAndroid()) {
      return DatePicker;
    }
    if (BrowserDetection.isIOS()) {
      return IOSNativeDate;
    }
  } else if (kind === 'enums' || actualKind === 'enums') {
    return Select;
  } else if (kind === 'list') {
    // Doing this to ward off a circular dependency, since it uses FieldOption, which uses this
    return require('./List').default;
  } else if (isSubTypeWithName('CaptchaToken', subType)) {
    return RecaptchaInput;
  } else if (name === 'Boolean' || actualName === 'Boolean' || displayName === 'Boolean') {
    return CheckBox;
  } else if (isSubTypeWithName('Currency', subType)) {
    return CurrencyTextBox;
  } else if (isSubTypeWithName('Phone', subType)) {
    return PhoneTextBox;
  } else if (isSubTypeWithName('PostalCode', subType)) {
    return PostalCodeTextBox;
  } else if (isSubTypeWithName('Percent', subType)) {
    return PercentTextBox;
  } else if (isSubTypeWithName('CardExpirationDate', subType)) {
    return CreditCardDateTextBox;
  } else if (isSubTypeWithName('PaymentMethodV2', subType)) {
    return SelectPaymentMethodV2;
  } else if (isSubTypeWithName('PaymentMethod', subType)) {
    return SelectPaymentMethod;
  } else if (isSubTypeWithName('IndustryType', subType)) {
    return SelectIndustryType;
  }

  return TextBox;
};

export const useInputComponent = type => useMemo(
  () => type
    ? getInputComponent(type)
    : null,
  [ type ]
);