/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { connect } from 'react-redux';

import { getCorporateActions } from 'api/onboarding';

import { getCompanySettings, isVendorPortalExperience } from 'actions/common/companySettings';
import getCompanyUserAdditionalSetting from 'actions/common/getCompanyUserAdditionalSetting';
import updateCompanyUserAdditionalSetting from 'actions/common/updateCompanyUserAdditionalSetting';
import PathActions from 'actions/paths';
import { createCacheActions } from 'actions/references';

import { getNotificationToShow, hasTakenAction as hasAlreadyTakenAction } from './index.utils';

export const GetCorporateActionCounts = createCacheActions(
  'OneTimeActions_GetCorporateActions',
  getCorporateActions
);

const MapStateToProps = (state, { forceNotification }) => {
  const { nextBillingDate } = getCompanySettings(state);

  const lastFreeTrialNotification = getCompanyUserAdditionalSetting(
    state,
    'currentFreeTrialNotification'
  );

  const hasAlreadyTakenActionVal = hasAlreadyTakenAction(lastFreeTrialNotification);

  const { createdInvoicesCount, createdPaymentsCount } = GetCorporateActionCounts
    .getCacheEntity(state) || {};

  const notification = getNotificationToShow({
    hasTakenAction: hasAlreadyTakenActionVal || createdInvoicesCount || createdPaymentsCount,
    endDate: nextBillingDate
  });

  return {
    portalType: isVendorPortalExperience(state, { ignoreAccountType: true }) ? 'Vendor' : 'Customer',
    nextBillingDate,
    notification: forceNotification || notification
  };
};

const MapDispatchToProps = dispatch => ({
  updateNotification: notification => dispatch(updateCompanyUserAdditionalSetting(
    'currentFreeTrialNotification',
    notification
  )),
  onCreateInvoice: () => dispatch(PathActions.Invoices.Create()),
  onSendPayment: () => dispatch(PathActions.Payments.SendPayment()),
  onSubscribe: () => dispatch(PathActions.Settings.Subscription())
});

export default connect(MapStateToProps, MapDispatchToProps);
