/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React from 'react';
import { FormattedMessage } from 'react-intl';
import Logo from 'components/Logo';
import ProvideIsTablet from 'decorators/ProvideIsTablet';
import StaticHeaderOnWhiteLayout from '../StaticHeaderOnWhiteLayout';
import './index.scss';

const ProfessionalHeaderOnWhiteLayout = ({
  headerMessage,
  header: headerElement,
  subheader,
  subheaderMessage,
  children,
  headerWidth,
  contentWidth,
  isTablet
}) => {
  const header = (
    <div className="professional-header">
      <div className="headerSection viewstrap">
        <div className="container">
          <span className="viewpostLogo">
            <Logo height={isTablet ? 20 : undefined} width={isTablet ? 100 : undefined} />
          </span>
          <div className="message">
            {headerMessage ? (
              <span className="header" style={{width: headerWidth ? headerWidth : null}}>
                <FormattedMessage {...headerMessage} />
              </span>
            ) : null}
            {headerElement ? (
              <span className="header" style={{width: headerWidth ? headerWidth : null}}>
                {headerElement}
              </span>
            ) : null}
            {headerMessage || headerElement ? <br/> : null}
            {subheader ? (
              <span className="subHeader">
                {subheader}
              </span>
            ) : null}
            {subheaderMessage ? (
              <span className="subHeader">
                <FormattedMessage {...subheaderMessage} />
              </span>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <StaticHeaderOnWhiteLayout
      header={header}
      headerHeight={160}
      contentWidth={contentWidth}
    >
      {children}
    </StaticHeaderOnWhiteLayout>
  );
};


export default ProvideIsTablet(ProfessionalHeaderOnWhiteLayout);
