/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { getEntity } from 'schemas/state';

import isSecureTokenUserOfType from './isSecureTokenUserOfType';

const isUserOfType = (state, status, businessType, useSecureTokenIdentity) => {
  const identity = getEntity(state, state.session.identity);

  const checkSecureTokenIdentity = () => {
    return useSecureTokenIdentity ? isSecureTokenUserOfType(state, status, businessType) : false;
  };

  if (!identity) return checkSecureTokenIdentity();
  if (status && status !== identity.status) return checkSecureTokenIdentity();
  if (businessType && businessType !== identity.businessType) return checkSecureTokenIdentity();

  return true;
};

export default isUserOfType;