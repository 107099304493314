/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, { cloneElement, Component, Children } from 'react';
import { connect } from 'react-redux';
import AnalyticsCategory from 'components/AnalyticsCategory';
import { MapStateToProps, MapDispatchToProps } from './Workflow.connect';

export class Workflow extends Component {

  constructor(props) {
    super(props);
    this.getContractForStepView = this.getContractForStepView.bind(this);
    this.endWorkflow = () => this.props.endWorkflow(this.props.workflowId);
  }

  getContractForStepView(currentStepConfig) {
    let parameters = {};
    currentStepConfig.parameters.forEach((parameterConfig) => {
      parameters[parameterConfig.name] = parameterConfig.value;
    });

    let actions = {};
    currentStepConfig.actions.forEach((actionConfig) => {
      actions[actionConfig.name] = (actionParams) => {
        this.props.onAction(actionConfig, actionParams, this.props.workflowId);
      };
    });

    return {
      parameters,
      actions
    };
  }

  processChildren() {
    let stepConfig = {
      firstStep: this.props.firstStep,
      steps: {}
    };
    Children.forEach(this.props.children, (child) => {
      if (!child) {
        // skip over null children (e.g. if a step is conditional)
        return;
      }

      // manually run (instantiate) children (instead of rendering them)
      // (because they are only configuration functions and not intended to be react elements)
      let childConfig = child.type(child.props);
      if (childConfig.workflowType !== 'Step') {
        throw new Error('Workflow should only have children that are Steps');
      }

      stepConfig.steps[childConfig.name] = childConfig;
      if (!stepConfig.firstStep) {
        stepConfig.firstStep = childConfig.name;
      }
    });
    return stepConfig;
  }

  getFirstStepName() {
    let stepConfig = this.processChildren();
    if (!stepConfig.firstStep) {
      throw new Error('Workflow requires at least 1 Step.');
    }
    return stepConfig.firstStep;
  }

  step(stepName) {
    let stepConfig = this.processChildren();
    if (!stepConfig.steps[stepName]) {
      throw new Error(`Workflow does not contain a step named ${stepName}`);
    }

    this.props.goToStep(stepName, this.props.workflowId);
  }

  componentDidMount() {
    if (!this.props.step) {
      this.step(this.getFirstStepName());
    }
  }

  componentWillUnmount() {
    if (!this.props.complete && !this.props.finished) {
      this.endWorkflow();
    }
  }

  shouldComponentUpdate(props) {
    // Prevents the component from re-rendering if there is not a step to
    // display. Handles the case of a completed workflow not rendering
    // content prior to a modal closing.
    return props.step === null ? false : true;
  }

  componentWillReceiveProps(props) {
    if (props.complete && !this.props.complete) {
      if (this.props.onComplete) {
        this.props.onComplete(props.finished);
      }
    }
  }

  render() {
    if (this.props.complete) {
      return null;
    }

    let stepName = this.props.step;
    if (!stepName) {
      return null;
    }

    let stepConfig = this.processChildren();
    let currentStepConfig = stepConfig.steps[stepName];
    if (!currentStepConfig) {
      throw new Error(`Workflow does not contain a step named ${stepName}`);
    }
    const {
      name,
      workflowType,
      actions: actionsConfig,
      parameters: parametersConfig,
      component: StepComponent,
      view,
      ...stepProps
    } = currentStepConfig;

    let viewContract = this.getContractForStepView(currentStepConfig);

    viewContract.parameters = {
      ...viewContract.parameters,
      ...stepProps
    };

    let {
      parameters = {},
      actions = {}
    } = viewContract || {};

    const content = view ? cloneElement(
      view,
      {
        // Using a view only passes up actions as you can pass in parameters directly to the element
        actions: viewContract.actions || {}
      }
    ) : (
      <StepComponent
        workflow={viewContract}
        {...(parameters || {})}
        {...(actions || {})}
      />
    );

    return (
      <AnalyticsCategory name={name}>
        {content}
      </AnalyticsCategory>
    );
  }
}

export default connect(MapStateToProps, MapDispatchToProps, null, { forwardRef: true })(Workflow);
