/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React from 'react';

import BodyText from 'components/BodyText';
import HeaderText, { HeaderTextVariants } from 'components/HeaderText';

import BasicRegistrationWorkflow from 'containers/Registration/BasicRegistrationWorkflow';

import useStatusIndicator from 'hooks/apiResult/useStatusIndicator';
import { GetRegistrationMetadataHooks } from 'hooks/registration';

import Messages from './index.messages';

const Header = (
  <div className="text-center pad-40-bottom">
    <HeaderText className="pad-16-bottom" variant={HeaderTextVariants.PageHeader}>
      <Messages.Header.Message />
    </HeaderText>
    <BodyText color="darkGrey">
      <Messages.Subheader.VpMessage />
    </BodyText>
  </div>
);

const SmallBusinessOpenRegistration = () => {
  // Hooks
  GetRegistrationMetadataHooks.useProvideParams({ isOpenRegistration: true });

  const StatusIndicator = useStatusIndicator([ GetRegistrationMetadataHooks ]);

  // Render
  if (StatusIndicator) return <StatusIndicator />;

  return (
    <BasicRegistrationWorkflow
      registrationFormHeader={Header}
    />
  );
};

export default SmallBusinessOpenRegistration;