/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import logout from 'api/session/logout';

import Button from 'components/Button';

import BlueHeaderOnWhiteLayout from 'layouts/BlueHeaderOnWhite';

import useIsUserOfType, { CompanyStatus } from 'hooks/identity/useIsUserOfType';

import Messages from './index.messages';

const DenyPendingRegistration = ({
  active,
  updateState
}) => {
  // Hooks
  const dispatch = useDispatch();
  const isPendingRegistration = useIsUserOfType(CompanyStatus.PendingRegistration);

  useEffect(
    () => updateState(isPendingRegistration ? 'loaded' : 'skipped'),
    []
  );

  // Render
  if (!active) return <></>;

  return (
    <BlueHeaderOnWhiteLayout
      contentWidth={650}
      hideLoginLink={true}
    >
      <div className="viewstrap">
        <div className="text-center">
          <Messages.Instructions.Message />
          <br/>
          <Button onClick={() => dispatch(logout())} anchor={true} className="margin-12-top">
            <Messages.LogOut.Message />
          </Button>
        </div>
      </div>
    </BlueHeaderOnWhiteLayout>
  );
};

export default DenyPendingRegistration;