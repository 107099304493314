/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React from 'react';
import SimpleModal from 'components/Modal/Simple';
import HeaderText from 'components/HeaderText';
import BodyText from 'components/BodyText';
import FormattedDate from 'components/FormattedDate';
import { addModal } from 'config/modals/registry';
import ErnieThumbsUpImage from 'public/images/ernie/thumbsUp.svg';
import Messages from './index.messages';

const SubscriptionSelectPortalModal = ({ billingDate, onClose }) => (
  <SimpleModal
    header={Messages.ModalHeader}
    onClose={onClose}
    submit={false}
  >
    <div style={{ textAlign: 'center' }}>
      <div style={{ paddingTop: 20 }}>
        <img src={ErnieThumbsUpImage} />
      </div>
      <HeaderText style={{ paddingTop: 16, paddingBottom: 8 }}>
        <Messages.Header.Message />
      </HeaderText>
      <BodyText style={{ paddingBottom: 20 }}>
        {billingDate ? (
          <Messages.Instructions.Message date={<FormattedDate value={billingDate} />} />
        ) : (
          <Messages.AlreadyPortalInstructions.Message />
        )}
      </BodyText>
    </div>
  </SimpleModal>
);

export default addModal(
  'SubscriptionSelectPortalModal',
  SubscriptionSelectPortalModal,
  { wide: true }
);
