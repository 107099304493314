/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { isPersonalPortal } from 'actions/common';
import { createLogicalPathConfig } from './createPathConfig';
import Paths from './index';

const createBusinessOrPersonalLogicalLink = (
  businessLink,
  personalLink
) => ({
  params: ['isPersonal'],
  path: ({ isPersonal }) => isPersonal ? personalLink : businessLink,
  mapStateToParams: state => ({
    isPersonal: isPersonalPortal(state)
  })
});

const {
  LogicalPaths
} = createLogicalPathConfig({
  Bills: {
    children: {
      List: {
        params: ['isPersonal', 'status'],
        path: ({ isPersonal }) => isPersonal
          ? Paths.Bills.ConsumerOpenBills
          : Paths.Bills.List,
        mapStateToParams: state => ({
          isPersonal: isPersonalPortal(state)
        }),
        mapParamsToProps: ({ status: originalStatus, isPersonal }) => {
          let status = originalStatus;

          if (originalStatus === 'open' && !isPersonal) {
            status = 'allOpen';
          }

          return {
            status
          };
        }
      }
    }
  },
  Settings: {
    ...createBusinessOrPersonalLogicalLink(
      Paths.Settings.Profile,
      Paths.ConsumerSettings
    ),
    children: {
      Fees: createBusinessOrPersonalLogicalLink(
        Paths.Settings.Fees,
        Paths.ConsumerFees
      )
    }
  }
});

export default LogicalPaths;
