/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

const SettingsViewActions = {
  ViewCompanyPreferences: 'ViewCompanyPreferences',
  ViewCompanyProfile: 'ViewCompanyProfile',
  ViewCompanySettings: 'ViewCompanySettings',
  ViewEarlyPay: 'ViewEarlyPay',
  ViewFees: 'ViewFees',
  ViewGroups: 'ViewCompanyGroups',
  ViewPaymentMethods: 'ViewPaymentMethods',
  ViewUsers: 'ViewCompanyUsers',
  UseFileImport: 'UseFileImport',
  CanUpgradeToCorporate: 'CanUpgradeToCorporate',
  CanSwitchCompanies: 'CanSwitchCompanies'
};

export default SettingsViewActions;