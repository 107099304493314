/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import t from 'tcomb-validation';
import { defineSchema } from 'schemas';

const AppSessionSettings = defineSchema(
  {
    csrfToken: t.String
  },
  // This is referenced by getApplicationSetting
  'AppSessionSettings'
);

export default AppSessionSettings;