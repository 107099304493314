/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import t from 'tcomb-validation';
import { StringOrNumberUnion } from './union';
import messages from './percent.messages';

// use same regex used by platform
/* eslint-disable max-len */

let percentRegex = new RegExp('^[0-9]*(?:\\.[0-9]{1,4})?$');

function isValidPercent(value) {
  return percentRegex.test(value);
}

/* PERCENT */
export const Percent = t.subtype(StringOrNumberUnion, (value) => {
  return isValidPercent(value) && parseFloat(value) <= 100;
}, 'Percent');

Percent.getValidationErrorMessage = (value, path, context) => {
  if (!isValidPercent(value) && context && context.intl) {
    return context.intl.formatMessage(messages.Invalid);
  }

  if (parseFloat(value) > 100 && context && context.intl) {
    return context.intl.formatMessage(messages.LessThan100);
  }

  return '';
};

function isValidPercentNotZero(value) {
  return isValidPercent(value) && parseFloat(value) !== 0;
}

export const PercentNotZero = t.subtype(StringOrNumberUnion, (value) => {
  return isValidPercentNotZero(value) && parseFloat(value) <= 100;
}, 'PercentNotZero');

PercentNotZero.getValidationErrorMessage = (value, path, context) => {
  if (!isValidPercentNotZero(value) && context && context.intl) {
    return context.intl.formatMessage(messages.InvalidNotZero);
  }

  if (parseFloat(value) > 100 && context && context.intl) {
    return context.intl.formatMessage(messages.LessThan100);
  }

  return '';
};

export const PercentNotZeroNoMax = t.subtype(StringOrNumberUnion, (value) => {
  return isValidPercentNotZero(value);
}, 'PercentNotZeroNoMax');

PercentNotZeroNoMax.getValidationErrorMessage = (value, path, context) => {
  if (!isValidPercentNotZero(value) && context && context.intl) {
    return context.intl.formatMessage(messages.InvalidNotZero);
  }

  return '';
};

function isValidPercentNotZeroMaxDigits(value, maxDigits) {
  if (value === null) {
    return false;
  }
  let valueWithoutDecimal = value.toString().split('.').join('');
  return isValidPercentNotZero(value) && valueWithoutDecimal.length <= maxDigits;
}

export const createPercentNotZeroMaxDigits = (maxDigits) => {
  let type = t.subtype(StringOrNumberUnion, (value) => {
    return isValidPercentNotZeroMaxDigits(value, maxDigits) && parseFloat(value) <= 100;
  }, 'PercentNotZeroMaxDigits');

  type.getValidationErrorMessage = (value, path, context) => {
    if (!isValidPercentNotZeroMaxDigits(value, maxDigits) && context && context.intl) {
      return context.intl.formatMessage(messages.InvalidNotZeroMaxDigits, {maxDigits});
    }

    if (parseFloat(value) > 100 && context && context.intl) {
      return context.intl.formatMessage(messages.LessThan100);
    }

    return '';
  };

  return type;
};

export const createPercentBetween = (minPercent, maxPercent) => {
  let schema = t.subtype(StringOrNumberUnion, (value) => {
    let num = Number(value);
    return num >= minPercent && num <= maxPercent;
  }, 'PercentBetween');

  schema.getValidationErrorMessage = (value, path, context) => {
    let num = Number(value);
    if (num < minPercent || num > maxPercent) {
      return `Percent must be between ${minPercent} and ${maxPercent}.`;
    }
  };

  return schema;
};
