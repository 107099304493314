/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import IconNotification from 'components/IconNotification';
import { IconTypes } from 'components/Icon';
import { FormattedMessage } from 'react-intl';
import Badge from 'components/Badge';
import LabelDropdownToggle from 'components/Dropdown/LabelDropdownToggle';

export { IconTypes };

class IconDropdownPanel extends Component {
  static propTypes = {
    labelMessage: PropTypes.object,
    showHeader: PropTypes.bool,
    header: PropTypes.node,
    iconType: PropTypes.string,
    count: PropTypes.number,

    onClick: PropTypes.func,
    onClose: PropTypes.func,
    onOpen: PropTypes.func,
    onToggle: PropTypes.func
  };

  static defaultProps = {
    count: 0,
    showHeader: true,
    isOpen: null
  };

  close = () => null

  renderOverlayContent() {
    return (
      <div>
        {this.props.showHeader
          ? (
            <div style={{
              fontWeight: 'bold',
              fontSize: '14px',
              padding: '10px',
              borderBottom: '1px solid #ddd'
            }}>
              {this.props.header
                ? this.props.header
                : <FormattedMessage {...this.props.labelMessage} />}
            </div>
          )
          : null}
        <div style={{
          overflow: 'auto',
          maxHeight: '300px'
        }}>
          {this.props.children}
        </div>
        {this.props.footer
          ? this.props.footer
          : null}
      </div>
    );
  }

  render() {
    let icon = (
      <IconNotification
        style={{minWidth: 0, padding: 0}}
        iconType={this.props.iconType} />
    );

    let label = this.props.labelMessage
      ? (
        <span className="text-small">
          <FormattedMessage {...this.props.labelMessage} />
        </span>
      ) : null;

    if (this.props.count) {
      if (label) {
        label = (
          <span>
            {label}
            <Badge type="primary">{this.props.count}</Badge>
          </span>
        );
      } else {
        label = <Badge type="primary">{this.props.count}</Badge>;
      }
    }

    return (
      <LabelDropdownToggle caret={icon}
        putCaretBeforeLabel={true}
        passCaretToMobileToggle={true}
        hideLabelOnMobile={this.props.hideLabelOnMobile}
        label={label}
        onOpen={this.props.onOpen}
        onClose={this.props.onClose}
        popoverStyle={{width: this.props.width ? this.props.width : 250}}
        style={this.props.noPad ? null : {padding: '8px 4px'}}>
        <div className="viewstrap" style={{display: 'inline'}}>
          {this.renderOverlayContent()}
        </div>
      </LabelDropdownToggle>
    );
  }
}

export default IconDropdownPanel;
