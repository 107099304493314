/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import bowser from 'bowser';

function isIE() {
  return bowser.msie;
}

function isAndroid() {
  return bowser.android;
}

function isIOS() {
  return bowser.ios;
}

function isFirefox() {
  return bowser.firefox;
}

function getVersion() {
  return bowser.version;
}

export default {
  isIE,
  isIOS,
  isAndroid,
  isFirefox,
  getVersion
};
