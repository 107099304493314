/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import t from 'tcomb-validation';
import { get } from 'lodash';
import createApiActions, { createApiAction } from 'api/core';
import { createEntity } from 'schemas/state';
import {
  queryParamNameMapper,
  defaultMapper
} from 'api/common/mappers';
import { defineSchema } from 'schemas';
import {
  UseAppAuthorizationReason,
  createUseAppAuthorizedEntity
} from 'schemas/session/UseAppAuthorizationStatus';
import {
  MetadataTargetCompanyStatus,
  MetadataTargetCompanyPaymentStatus,
  ShowAddPaymentAccountStatus
} from 'config/constants/registration';
import { doServerErrorsContain, doServerErrorsContainId } from 'api/core/utils.state';
import { toEnumObject } from 'config/constants/utils';
import { createInvitationMetadata, InvitationMetadata } from './utils';

export const ExpeditedRegistrationResponse = defineSchema({
  encryptedUserId: t.maybe(t.String),
  isRegistered: t.Bool,
  isVerified: t.Bool,
  isPaymentAccountRegistered: t.Bool,
  wasPaymentAccountSkipped: t.maybe(t.Bool),
  hasCredentials: t.Bool
}, 'ExpeditedRegistrationResponse');

function registrationResponseMapper(
  apiRegistrationResponse,
  state,
  { ignoreAuthEntityCreation }
) {
  let entities = [];
  let references = [];

  if (apiRegistrationResponse.isRegistered && apiRegistrationResponse.isVerified) {
    if (apiRegistrationResponse.isRegistered) {
      if (apiRegistrationResponse.token === 'Created' || apiRegistrationResponse.isLoggedIn) {
        if (!ignoreAuthEntityCreation) {
          entities.push(createUseAppAuthorizedEntity(UseAppAuthorizationReason.Registration));
        }
        entities.push(createEntity(
          'current',
          ExpeditedRegistrationResponse.meta.name,
          {
            isRegistered: true,
            isVerified: true,
            isPaymentAccountRegistered: false,
            wasPaymentAccountSkipped: true,
            hasCredentials: true
          }
        ));
      } else {
        entities.push(createEntity(
          'current',
          ExpeditedRegistrationResponse.meta.name,
          {
            isRegistered: true,
            isVerified: false,
            isPaymentAccountRegistered: false,
            hasCredentials: false
          }
        ));
      }
    }
  } else if (apiRegistrationResponse.isRegistered) {
    entities.push(createEntity(
      'current',
      ExpeditedRegistrationResponse.meta.name,
      {
        encryptedUserId: apiRegistrationResponse.encryptedUserId,
        isRegistered: true,
        isVerified: false,
        isPaymentAccountRegistered: false,
        hasCredentials: false
      }
    ));
  } else {
    entities.push(createEntity(
      'current',
      ExpeditedRegistrationResponse.meta.name,
      {
        isRegistered: false,
        isVerified: false,
        isPaymentAccountRegistered: false,
        hasCredentials: false
      }
    ));
  }

  return {
    entities,
    references
  };
}

const RegistrationMetadataError = toEnumObject([
  'InvalidRegistrationId'
]);

const getKnownRegistrationMetadataError = (messages) => {
  if (messages) {
    if (doServerErrorsContainId(messages, 'InvalidInvitationId')) {
      return RegistrationMetadataError.InvalidRegistrationId;
    }
  }
};

const getRegistrationMetadataResponseMapper = (
  response,
  state,
  {
    invitationId,
    isOpenRegistration,
    referralToken
  }
) => {
  let id = invitationId || referralToken;
  if (isOpenRegistration) {
    id = 'open';
  }

  const validationError = getKnownRegistrationMetadataError(response.messages);

  if (validationError) {
    return {
      entities: [createEntity(
        id,
        InvitationMetadata.meta.name,
        {
          from: {},
          isValid: false,
          isUserRegistration: false,
          id: id,
          address: {},
          displayAddPaymentAccount: ShowAddPaymentAccountStatus.Shown,
          targetCompanyInfo: {
            status: MetadataTargetCompanyStatus.Unregistered,
            paymentAccountStatus: MetadataTargetCompanyPaymentStatus.NoAccounts,
            connectionType: 'None',
            isIndividual: false
          },
          subscription: {
            isRequired: false,
            amount: 0
          }
        }
      )]
    };
  }

  return {
    entities: [
      createInvitationMetadata({
        ...response,
        id: id || response.id,
        invitationId,
        referralToken
      })
    ]
  };
};

export const ExpeditedRegistrationError = toEnumObject([
  'EmailAddressInUse',
  'AccountInUse',
  'AccountWrongType',
  'CreditCardProcessingError'
]);

const registerNewAccountExpeditedResponseMapper = response => ({
  entities: [
    createEntity(
      'current',
      ExpeditedRegistrationResponse.meta.name,
      {
        isRegistered: response.isRegistered || false,
        isVerified: response.isRegistered || false,
        isPaymentAccountRegistered: response.isPaymentAccountRegistered || false,
        hasCredentials: false
      }
    )
  ]
});

export const getRegistrationMetadata = createApiAction({
  method: 'get',
  url: '/api/register/status',
  queryStringMapper: queryParamNameMapper({
    invitationId: 'invitationId',
    referralToken: 'referralToken',
    isOpenRegistration: 'isOpenRegistration'
  }),
  shouldErrorMaptoSuccess: response => getKnownRegistrationMetadataError(get(response, 'body.messages')),
  responseMapper: getRegistrationMetadataResponseMapper
});

export const registerAccountExpedited = createApiAction({
  method: 'post',
  url: '/api/register/create/expedited',
  requestBodyMapper: defaultMapper,
  responseMapper: registerNewAccountExpeditedResponseMapper,
  errorMapper: (response) => {
    const messages = get(response, 'body.messages');
    if (messages) {
      if (doServerErrorsContainId(messages, 'EmailAddressInUse')) {
        return { reason: ExpeditedRegistrationError.EmailAddressInUse };
      } if (doServerErrorsContainId(messages, 'AccountInUse')) {
        return { reason: ExpeditedRegistrationError.AccountInUse };
      } if (doServerErrorsContain(messages, 'InvalidAccountType')) {
        return { reason: ExpeditedRegistrationError.AccountWrongType };
      }
    }
  }
});

export default createApiActions({
  registerNewAccount: {
    method: 'post',
    url: '/api/register/create',
    requestBodyMapper: defaultMapper,
    responseMapper: registrationResponseMapper,
    errorMapper: (response) => {
      const messages = get(response, 'body.messages');
      if (messages) {
        if (doServerErrorsContainId(messages, 'EmailAddressInUse')) {
          return { reason: ExpeditedRegistrationError.EmailAddressInUse };
        } if (doServerErrorsContain(messages, 'CreditCardProcessingError')) {
          return { reason: ExpeditedRegistrationError.CreditCardProcessingError };
        }
      }
    }
  },
  expeditedRegistrationProvideCredentials: {
    method: 'post',
    url: '/api/register/create/expedited/credentials',
    requestBodyMapper: ({
      password,
      securityQuestions,
      twoFactorAuthenticationCode
    }) => ({ password, securityQuestions, twoFactorAuthenticationCode }),
    responseMapper: (response) => {
      if (response.twoFactorStatus === 'Success') {
        return { entities: [ createUseAppAuthorizedEntity(UseAppAuthorizationReason.Registration) ] };
      }
    }
  },
  requestReactivateAccount: {
    method: 'post',
    url: '/api/register/request_reactivate_account',
    requestBodyMapper: ({ userName, comment }) => ({ userName, comment })
  },
  registerCompanyWithToken: {
    method: 'post',
    url: '/api/register/company/token',
    requestBodyMapper: r => r,
    responseMapper: (response) => {
      if (response.isLoggedIn) {
        return { entities: [createUseAppAuthorizedEntity(UseAppAuthorizationReason.Registration)] };
      }
    }
  }
});
