/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import moment from 'moment';
import { toEnumObject } from 'config/constants/utils';

export const Notifications = toEnumObject([
  'Intro',
  'NoAction1',
  'NoAction2',
  'ActionTaken1',
  'ActionTaken2',
  'Expired'
]);

export const hasTakenAction = notification => notification === 'ActionTaken1'
|| notification === 'ActionTaken2';

export const getNotificationToShow = ({
  hasTakenAction: hasTakenActionVal,
  endDate
}) => {
  const daysDiff = moment(endDate).diff(moment(), 'days');

  if (daysDiff === 0) {
    return Notifications.Expired;
  }

  if (hasTakenActionVal) {
    if (daysDiff <= 59 && daysDiff >= 7) {
      return Notifications.ActionTaken1;
    }

    if (daysDiff >= 1) {
      return Notifications.ActionTaken2;
    }
  } else {
    if (daysDiff >= 30) {
      return Notifications.Intro;
    }

    if (daysDiff >= 7) {
      return Notifications.NoAction1;
    }

    if (daysDiff >= 1) {
      return Notifications.NoAction2;
    }
  }
};
