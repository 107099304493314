/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { newState } from './utils';

export default (state = {}, action) => {
  if (action.type === 'COORDINATE_ACTIONS') {
    return newState(state, {
      [action.name]: {
        ...action.initialState,
        reducer: action.reducer
      }
    });
  }

  let updatedState = {};

  let stateChanged = false;
  Object.keys(state).forEach((stateKey) => {
    if (!state[stateKey] || !state[stateKey].reducer) return;
    const originalState = state[stateKey];
    const modifiedState = state[stateKey].reducer(state[stateKey], action);
    stateChanged = stateChanged || originalState !== modifiedState;

    updatedState[stateKey] = {
      ...originalState,
      ...modifiedState
    };
  });

  if (stateChanged) {
    return newState(state, updatedState);
  }

  return state;
};
