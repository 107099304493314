/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React from 'react';
import RouteLink from 'components/Link/RouteLink';
import { LinkRef } from 'links';
import { toMessages } from './messages';

const LinkMessages = toMessages('MessageLinks', {
  PrivacyPolicy: 'Privacy Policy',
  TermsOfUse: 'Terms of Use',
  PaymentTerms: 'Payment Terms',
  EndUserLicense: 'End User License Agreement',
  ViewpostCustomerSupport: 'Viewpost Customer Support',
  ViewpostSupport: 'Viewpost Support',
  CustomerSupport: 'Customer Support',
  CustomerSupportEmail: 'support@viewpost.com',
  PaymentSupport: 'Payment Support',
  PaymentSupportEmail: 'paymentsupport@viewpost.com'
});

export const PrivacyPolicy = (
  <RouteLink linkRef={LinkRef.marketingSite.privacy} openNewWindow={true}>
    <LinkMessages.PrivacyPolicy.Message />
  </RouteLink>
);

export const TermsOfUse = (
  <RouteLink linkRef={LinkRef.marketingSite.terms} openNewWindow={true}>
    <LinkMessages.TermsOfUse.Message />
  </RouteLink>
);

export const PaymentTerms = (
  <RouteLink linkRef={LinkRef.marketingSite.paymentTerms} openNewWindow={true}>
    <LinkMessages.PaymentTerms.Message />
  </RouteLink>
);

export const ConsumerPaymentTerms = (
  <RouteLink linkRef={LinkRef.marketingSite.c2bPaymentTerms} openNewWindow={true}>
    Consumer Payment Terms
  </RouteLink>
);

export const CassTerms = (
  <RouteLink linkRef={LinkRef.marketingSite.cassTerms} openNewWindow={true}>
    CASS Terms
  </RouteLink>
);

export const EndUserLicense = (
  <RouteLink linkRef={LinkRef.marketingSite.eulaPdf} openNewWindow={true}>
    <LinkMessages.EndUserLicense.Message />
  </RouteLink>
);

export const MailToCustomerSupport = content => (
  <a href="mailto:support@viewpost.com">
    {content}
  </a>
);

export const MailToPaymentSupport = content => (
  <a href="mailto:paymentsupport@viewpost.com">
    {content}
  </a>
);

export const ViewpostCustomerSupport =  MailToCustomerSupport(<LinkMessages.ViewpostCustomerSupport.Message />);

export const ViewpostSupport =  MailToCustomerSupport(<LinkMessages.ViewpostSupport.Message />);

export const CustomerSupport =  MailToCustomerSupport(<LinkMessages.CustomerSupport.Message />);

export const CustomerSupportEmail =  MailToCustomerSupport(<LinkMessages.CustomerSupportEmail.Message />);

export const PaymentSupport = MailToPaymentSupport(<LinkMessages.PaymentSupport.Message />);

export const PaymentSupportEmail = MailToPaymentSupport(<LinkMessages.PaymentSupportEmail.Message />);
