/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, { useState } from 'react';

import { debounce } from 'lodash';
import DEFAULT_DEBOUNCE_WAIT from 'config/constants/DefaultDebounceWait';

import Icon, { IconTypes } from 'components/Icon';

import SearchBox from '../SearchBox';

import './index.scss';

const debouncer = debounce((f, s) => f?.(s), DEFAULT_DEBOUNCE_WAIT);

function getPagedOptions(options, page, pageSize) {

  let skip = ((page - 1) * pageSize);
  return {
    pageStart: skip + 1,
    pageEnd: Math.min(skip + pageSize, options.length),
    totalCount: options.length,
    options: options?.slice(skip, skip + pageSize)
  };
}

function Option({key, option, isSelected, onSelect}) {
  return (
    <div key={key} className={`select-option ${isSelected ? 'selected' : ''}`}
      style={{ display: 'table', width: '100%', minHeight: '40px' }}
      onClick={() => onSelect?.(option?.value)}>
      <div style={{
        display: 'table-cell',
        verticalAlign: 'middle',
        padding: '8px 12px',
        fontSize: '14px'
      }}>
        {option?.richText ?? option?.text}
      </div>
    </div>
  );
}

/**
 *  A version of Select that works better from inside a tooltip by showing all the content inline
 *  without having to click to open it like a typical select would
 */
export default function SelectPagingTooltip({
  selectOptions,
  value,
  onChange,
  placeholder = 'Search...',
  autoFocus = true,

  pageSize = 10,

  // the max item number that we can page to
  // really just so we can indicate when it seems like there are more items we're not showing
  pagingCappedAt = null,

  searchable = true,
  pageable = true,
  clearable = true,
  clearOption = null,

  // support async search calls
  onSearch = null,
  isSearching = null
}) {
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);

  let options = [
    ...(selectOptions ?? [])
  ];

  let onChangeSearch;
  if (!!onSearch) {
    // support server-side searching
    onChangeSearch = (v) => {
      setSearch(v);
      setPage(1);
      debouncer(onSearch, v);
    };
  } else {
    // client-side searching
    onChangeSearch = (v) => {
      setSearch(v);
      setPage(1);
    };
    if (search?.length) {
      options = selectOptions?.filter(o => o.text?.toLowerCase().indexOf(search.toLowerCase()) >= 0);
    }
  }

  let pagedOptions = null;
  if (pageSize && pageSize < options.length) {
    pagedOptions = getPagedOptions(options, page, pageSize);
    options = pagedOptions.options;
  }

  let onPrev = () => setPage(page - 1);
  let onNext = () => setPage(page + 1);

  let statusBar = null;
  if (isSearching) {
    statusBar = (
      <div className="status-bar">
        <em>Loading...</em>
      </div>
    );
  } else if (pageable && pagedOptions) {
    statusBar = (
      <div className="status-bar">
        {pagedOptions.pageStart} - {pagedOptions.pageEnd} of {pagedOptions.totalCount}
        {pagingCappedAt && pagingCappedAt === pagedOptions.totalCount ? '+' : null}
        <div style={{float: 'right'}}>
          {pagedOptions.pageStart > 1
            ? <a onClick={onPrev}>Prev</a>
            : null}
          {pagedOptions.pageEnd < pagedOptions.totalCount
            ? <div style={{display: 'inline-block', marginLeft: '10px'}}>
              <a onClick={onNext}>Next</a>
            </div>
            : null}
        </div>
      </div>
    );
  }

  return (
    <div className="component-select-tooltip">
      {clearable && value != null
        ? (
          <div style={{borderBottom: '1px solid #ddd'}}>
            <Option option={clearOption ?? {
              value: null,
              richText: <div>
                <Icon type={IconTypes.Exit} style={{color: '#999'}} />
                Clear
              </div>
            }} onSelect={onChange} />
          </div>
        )
        : null}
      {searchable
        ? (
          <div style={{ padding: '5px' }}>
            <SearchBox iconPlacement="left" placeholder={placeholder} autoFocus={autoFocus}
              value={search} onChange={onChangeSearch} isLoading={isSearching} />
          </div>
        )
        : null}
      {statusBar}

      {options?.map((o, i) => <Option key={i} option={o} isSelected={o?.value === value} onSelect={onChange} />)}
    </div>
  );
}
