/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, { Component } from 'react';
import { connect } from 'react-redux';
// import ProvideIsMobile from 'decorators/ProvideIsMobile';
import FormJSX from 'components/Form/FormJSX';
import FieldOption from 'components/Form/FieldOption';

import t from 'tcomb-validation';
import {
  createExactLengthStringType,
  createMaxLengthStringType
} from 'schemas/common/string';
import { CurrentOrPastDate } from 'schemas/common/date';
import { PostalCode } from 'schemas/common/address';
import Messages from './index.messages.js';
import { MapStateToProps, MapDispatchToProps } from './index.connect';
// import './index.scss';

const nonUsDocumentType = t.enums({
  'passport': 'Passport',
  'other': 'Other'
});

function createSchema(formValue, isUsAddress) {
  let schema = {
    title: createMaxLengthStringType(50),
    firstName: createMaxLengthStringType(100),
    lastName: createMaxLengthStringType(100),
    dateOfBirth: CurrentOrPastDate,
    line1: createMaxLengthStringType(240),
    line2: t.maybe(createMaxLengthStringType(240)),
    city: createMaxLengthStringType(100),
    countryOfBirth: createMaxLengthStringType(100),
    isUsCitizen: t.Bool
  };

  if (isUsAddress) {
    schema.state = createMaxLengthStringType(100);
    schema.zip = PostalCode;
  } else {
    schema.state = t.maybe(createMaxLengthStringType(100));
    schema.zip = t.maybe(createMaxLengthStringType(100));
    schema.countryOfResidence = createMaxLengthStringType(100);
  }

  if (formValue.isUsCitizen) {
    schema.ssn = createExactLengthStringType(9);
  } else {
    schema.nonUsDocumentType = nonUsDocumentType;

    if (formValue.nonUsDocumentType === 'passport') {
      schema.passportNumber = createMaxLengthStringType(20);
      schema.issuingCountry = createMaxLengthStringType(100);
    } else if (formValue.nonUsDocumentType === 'other') {
      schema.otherNumber = createMaxLengthStringType(20);
      schema.otherType = createMaxLengthStringType(100);
      schema.issuingCountry = createMaxLengthStringType(100);
    }
  }

  return t.struct(schema);
}

class BeneficialOwnerCollectionForm extends Component {

  constructor(props) {
    super(props);
    this.state = {
      formState: {
        isUsCitizen: true
      },
      isUsAddress: true
    };
  }

  getValue = () => {
    let formValue = this.form.getValue();
    if (!formValue) {
      return formValue;
    }

    return {
      ...formValue,

      // don't make the user enter country of residence if we already know it
      countryOfResidence: this.state.isUsAddress ? 'USA' : formValue.countryOfResidence
    };
  }

  render() {
    return (
      <FormJSX ref={ref => this.form = ref}
        modelType={createSchema(this.state.formState, this.state.isUsAddress)}
        showRequiredLabels={true}
        value={this.state.formState}
        onChange={val => this.setState({formState: val})}>

        <FieldOption name="title"
          label={Messages.TitleLabel}
          placeholder="e.g. CEO, CTO, President, etc"
          cols={4} />
        <FieldOption name="firstName"
          label={Messages.FirstNameLabel}
          cols={4} />
        <FieldOption name="lastName"
          label={Messages.LastNameLabel}
          cols={4} />


        <FieldOption name="line1"
          label={Messages.Line1Label}
          labelValues={{
            prefix: this.state.isUsAddress ? 'US' : 'Non-US'
          }}
          placeholder="Address Line 1"
          cols={6} />
        <FieldOption
          name="line2"
          placeholder="Address Line 2"
          rightLabel={this.state.isUsAddress
            ? (
              <a style={{fontSize: '12px', zIndex: 1}} onClick={() => this.setState({isUsAddress: false})}>
                I have a non-US address
              </a>
            )
            : (
              <a style={{fontSize: '12px', zIndex: 1}} onClick={() => this.setState({isUsAddress: true})}>
                I have a US address
              </a>
            )}
          cols={6}
          emptyLabel={true}
        />

        <FieldOption
          name="city"
          placeholder="City"
          cols={4}
          willHideLabel={true}
        />
        <FieldOption
          name="state"
          placeholder={this.state.isUsAddress ? 'State' : 'State/Province/Region' }
          cols={4}
          willHideLabel={true}
        />
        <FieldOption
          name="zip"
          placeholder={this.state.isUsAddress ? 'Zip' : 'Postal Code' }
          cols={4}
          willHideLabel={true}
        />
        <FieldOption
          name="countryOfResidence"
          cols={3}
          label={Messages.CountryLabel}
        />
        <FieldOption
          name="countryOfBirth"
          cols={3}
          label={Messages.CountryOfBirthLabel}
        />
        <FieldOption
          name="dateOfBirth"
          cols={3}
          label={Messages.DateOfBirthLabel}
          inputProps={{
            shouldSlashPastDates: false
          }}
        />

        <FieldOption name="isUsCitizen"
          cols={3} label={Messages.UsCitizenLabel}
          inputProps={{
            style: {paddingTop: '22px'}
          }}/>

        <FieldOption name="ssn"
          cols={3}
          label={Messages.SSNLabel} />

        <FieldOption name="nonUsDocumentType"
          cols={3} label={Messages.DocumentTypeLabel}/>

        <FieldOption name="issuingCountry"
          cols={3}
          label={Messages.IssuingCountryLabel} />
        <FieldOption name="passportNumber"
          cols={3}
          label={Messages.PassportNumberLabel} />


        <FieldOption name="otherType"
          cols={3}
          label={Messages.OtherTypeLabel}
          placeholder="e.g. alien id card" />
        <FieldOption name="otherNumber"
          cols={3}
          label={Messages.OtherNumberLabel} />
      </FormJSX>
    );
  }
}

export default connect(MapStateToProps, MapDispatchToProps, null, { forwardRef: true })(
  BeneficialOwnerCollectionForm
);
