/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { useSelector } from 'react-redux';
import { getIdentity } from 'actions/common';
import logError from 'services/ErrorService';

const useIdentity = (
  {
    notExpected,
    returnNull
  } = {}
) => useSelector(
  (state) => {
    const identity = getIdentity(
      state,
      { notExpected: true, returnNull: true }
    );

    if (!identity && !notExpected) {
      logError('Expected identity, but identity is missing.', null, { includeStackTrace: true });
    }

    if (identity != null) return identity;

    return returnNull ? null : {};
  },
  (a, b) => (a || {}).timestamp === (b || {}).timestamp
);

export default useIdentity;
