/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import t from 'tcomb-validation';
import { Phone } from 'schemas/common/phone';

const TwoFactorAction = t.enums({
  Initiate: 'InitiateTwoFactor',
  Resend: 'ResendTwoFactor',
  Verify: 'VerifyTwoFactor',
  Disable: 'DisableTwoFactor'
});

const TwoFactorSettings = t.struct({
  phone: t.maybe(Phone),
  availableActions: t.list(TwoFactorAction)
});

// step 1 to enroll 2FA
const TwoFactorInitiation = t.struct({
  phone: Phone
}, 'TwoFactorInitiation');

// step 2 to enroll 2FA
const TwoFactorVerification = t.struct({
  verificationCode: t.String
}, 'TwoFactorVerification');

export const TwoFactorVerificationV2 = t.struct({
  authenticationCode: t.String
}, 'TwoFactorVerificationV2');


// These are duplicates but are named based on the API
const TwoFactorInit = t.struct({
  phoneNumber: Phone
}, 'TwoFactorInit');

const createAuthenticationCode = (hasExternalFailure, errorMsg) => {
  let AuthenticationCode = t.subtype(t.String, () => hasExternalFailure, 'AuthenticationCode');
  AuthenticationCode.getValidationErrorMessage = (value, path, context) => {
    if (hasExternalFailure && context && context.intl) {
      return context.intl.formatMessage(errorMsg);
    }

    return '';
  };
  return AuthenticationCode;
};

const createTwoFactorVerificationCode = (hasExternalFailure, errorMsg) => {
  return t.struct({
    authenticationCode: createAuthenticationCode(hasExternalFailure, errorMsg)
  }, 'TwoFactorVerificationCode');
};

export {
  TwoFactorAction,
  TwoFactorSettings,
  TwoFactorInitiation,
  TwoFactorVerification,
  TwoFactorInit,
  createTwoFactorVerificationCode
};
