/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React from 'react';
import Cookies from 'js-cookie';
import { Redirect, Route, Switch, useParams } from 'react-router';
import qs from 'qs';

import useStatusIndicator from 'hooks/apiResult/useStatusIndicator';
import useIsAuthorizedToUseApp from 'hooks/identity/useIsAuthorizedToUseApp';
import { GetRegistrationMetadataHooks } from 'hooks/registration';

import RegistrationView, { RegistrationLayout } from 'views/Registration';
import CorporatePaymentsContact from 'views/Registration/CorporatePaymentsContact';
import SmallBusinessSubscriptionAdvert from 'views/Registration/SmallBusinessSubscriptionAdvert';
import SmallBusinessOpenRegistration from 'views/Registration/SmallBusinessOpenRegistration';
import { DefaultAuthRedirect, getQuery, NotFoundRedirect } from './utils';

const addQueryParams = (path, query) => {
  let addedQuery = {};

  if (!query.onboardingFlow) {
    let onboardingFlow = Cookies.get('OnboardingFlow', { secure: true });
    if (onboardingFlow) {
      addedQuery.onboardingFlow = onboardingFlow;
    }
  }

  if (!Object.keys(addedQuery).length) return path;

  let newQuery = {
    ...query,
    ...addedQuery
  };

  return Object.keys(newQuery).length ? `${path}?${qs.stringify(newQuery)}` : path;
};

const createCookieRedirect = (location) => {
  let redirectPath = null;

  let infuse = path => addQueryParams(path, getQuery(location));
  if (Cookies.get('ReferralToken', { secure: true})) {
    redirectPath = infuse(`/register/referral/${Cookies.get('ReferralToken', { secure: true })}`);
  } else if (Cookies.get('InvitationId', { secure: true})) {
    redirectPath = infuse(`/register/invitation/${Cookies.get('InvitationId', { secure: true })}`);
  }

  return redirectPath ? <Redirect path="*" to={redirectPath} /> : null;
};

const RegistrationRedirect = ({ match }) => {
  if (match.params.invitationId) {
    return <Redirect to={`/register/invitation/${match.params.invitationId}`} />;
  } if (match.params.referralToken) {
    return <Redirect to={`/register/referral/${match.params.referralToken}`} />;
  }

  return <Redirect to="/register" />;
};

const RootRegistrationView = ({
  children
}) => {
  // Hooks
  const { invitationId, referralToken } = useParams();

  let getRegistrationMetadataParams = null;
  if (invitationId) getRegistrationMetadataParams = { invitationId };
  else if (referralToken) getRegistrationMetadataParams = { referralToken };

  GetRegistrationMetadataHooks.useProvideParams(getRegistrationMetadataParams);

  const StatusIndicator = useStatusIndicator([ GetRegistrationMetadataHooks ]);

  // Render
  if (StatusIndicator) return <StatusIndicator centerInPage={true} />;

  return (
    <div className="viewstrap" style={{ height: '100%' }}>
      <RegistrationLayout
        invitationId={invitationId}
        referralToken={referralToken}
      >
        <RegistrationView
          invitationId={invitationId}
          referralToken={referralToken}
        >
          {children}
        </RegistrationView>
      </RegistrationLayout>
    </div>
  );
};

export default ({ location, match }) => {
  const isAuthorizedToUseApp = useIsAuthorizedToUseApp();

  return (
    <Switch>
      {isAuthorizedToUseApp ? DefaultAuthRedirect : null}
      <Route path={`${match.url}/referral/:referralToken`} exact={true}>
        <RootRegistrationView />
      </Route>
      <Route path={`${match.url}/invitation/:invitationId`} exact={true}>
        <RootRegistrationView />
      </Route>
      {createCookieRedirect(location)}
      <Route path={match.url} exact={true}>
        <RootRegistrationView />
      </Route>
      <Route path={`${match.url}/corporatePayments`} exact={true}>
        <div className="viewstrap" style={{ height: '100%' }}>
          <RegistrationLayout>
            <CorporatePaymentsContact />
          </RegistrationLayout>
        </div>
      </Route>
      <Route path={`${match.url}/smallBusiness`} exact={true}>
        <div className="viewstrap" style={{ height: '100%' }}>
          <RegistrationLayout width={650}>
            <SmallBusinessOpenRegistration />
          </RegistrationLayout>
        </div>
      </Route>
      <Route path={`${match.url}/smallBusiness/features`} exact={true}>
        <div className="viewstrap" style={{ height: '100%' }}>
          <RegistrationLayout width={800}>
            <SmallBusinessSubscriptionAdvert />
          </RegistrationLayout>
        </div>
      </Route>
      <Redirect from={`${match.url}/landing`} exact={true} to="/register" />
      <Redirect from={`${match.url}/step2`} exact={true} to="/register" />
      <Route path={`${match.url}/by-invitation/:invitationId`} exact={true} component={RegistrationRedirect} />
      <Route path={`${match.url}/byInvitation/:invitationId`} exact={true} component={RegistrationRedirect} />
      <Route path={`${match.url}/form/:referralToken`} exact={true} component={RegistrationRedirect} />
      <Route path={`${match.url}/:referralToken`} exact={true} component={RegistrationRedirect} />
      {NotFoundRedirect}
    </Switch>
  );
};