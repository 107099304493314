/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, { forwardRef } from 'react';

import AutoRow from 'components/AutoRow';
import FormV2, { FieldInput, FieldOption } from 'components/Form/V2';
import CreditCardNumberTextBox from 'components/Form/Inputs/CreditCardNumberTextBox';

import useFormatMessage from 'hooks/intl/useFormatMessage';

import { StripeSubscriptionPaymentForm } from 'schemas/payments/stripeAccount';

import Messages from './index.messages';

const SubscriptionCreditCardForm = (
  {
    formTag,
    onChange,
    value
  },
  ref
) => {
  // Hooks
  const formatMessage = useFormatMessage();

  // Render
  return (
    <FormV2
      formTag={formTag}
      modelType={StripeSubscriptionPaymentForm}
      onChange={onChange}
      ref={ref}
      value={value}
    >
      <AutoRow gutter={true}>
        <FieldOption
          name="cardholderName"
          cols={6}
          label={<Messages.CardNameLabel.Message />}
        />
        <FieldOption
          name="cardNumber"
          cols={6}
          label={<Messages.CardNumberLabel.Message />}
        >
          <CreditCardNumberTextBox />
        </FieldOption>
        <FieldOption
          name="expirationDate"
          cols={4}
          label={<Messages.CardExpirationLabel.Message />}
        >
          <FieldInput
            placeholder={formatMessage(Messages.CardExpirationPlaceholder)}
          />
        </FieldOption>
        <FieldOption
          name="cvc"
          cols={4}
          label={<Messages.CardCvcLabel.Message />}
        />
        <FieldOption
          name="postalCode"
          cols={4}
          label={<Messages.CardZipLabel.Message />}
        />
      </AutoRow>
    </FormV2>
  );
};

export default forwardRef(SubscriptionCreditCardForm);
