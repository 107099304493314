/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { createEntity, createEntityReference } from 'schemas/state';
import { CompanyGroup, GroupPermissions } from 'schemas/settings/groups';
import { CompanyUser, UsersForCompany, EmailPreference } from 'schemas/settings/user';
import _ from 'lodash';

let lowercaseUserPermissions = Object.keys(GroupPermissions.meta.props).reduce((val, perm) => {
  return {
    [perm.toLowerCase()]: perm,
    ...val
  };
}, {});
lowercaseUserPermissions.approvebills = lowercaseUserPermissions.approveinvoices;
delete lowercaseUserPermissions.approveinvoices;

export function getCompanyUsersResponseMapper(apiResponse) {
  let entities = [];
  let references = [];

  if (apiResponse.success && apiResponse.response) {
    let response = apiResponse.response;
    entities.push(createEntity(
      'usersforcompany',
      UsersForCompany.meta.name,
      {
        hasInactiveUsers: response.hasInactiveUsers,
        users: response.users
      }
    ));
    references.push(createEntityReference(
      'usersforcompany',
      UsersForCompany.meta.name
    ));

    response.users.forEach((user) => {
      entities.push(createEntity(
        user.companyUserId,
        CompanyUser.meta.name,
        {
          id: user.companyUserId,
          userId: user.userId,
          companyUserId: user.companyUserId,
          firstName: user.firstName,
          lastName: user.lastName,
          isRegistered: user.status === 2,
          isActive: user.isActive,
          isInvited: user.isInvited,
          isOwner: user.isOwner,
          lastLogonDate: user.lastLogOnDate,
          groups: user.groups.map(group => group.id)
        }
      ));
      references.push(createEntityReference(
        user.companyUserId,
        CompanyUser.meta.name
      ));
    });
  }

  return {
    entities,
    references
  };
}

export function getCompanyGroupsResponseMapper(apiResponse) {
  let entities = [];
  let references = [];

  if (apiResponse.success && apiResponse.response) {
    let response = apiResponse.response;
    response.roles.forEach((role) => {
      entities.push(createEntity(
        role.id,
        CompanyGroup.meta.name,
        {
          id: role.id,
          name: role.name,
          readOnly: role.systemRoleType === 1,
          deletable: role.systemRoleType !== 1,
          permissions: response.permissionCategories.reduce((value, category) => {
            let permissions = category.permissions.reduce((innerValue, permission) => {
              let key = lowercaseUserPermissions[permission.id.toLowerCase()];
              if (!key) {
                console.warn(`Unknown permission: ${permission.id}`);
                return innerValue;
              }
              return {
                ...innerValue,
                [key]: permission.roles.some(permRole => role.id === permRole.roleId && permRole.granted)
              };
            }, {});
            return {
              ...value,
              ...permissions
            };
          }, {})
        }
      ));
      references.push(createEntityReference(
        role.id,
        CompanyGroup.meta.name
      ));
    });
  }

  return {
    entities,
    references
  };
}

export function getEmailPreferencesResponseMapper(apiResponse) {
  let entities = [];
  let references = [];

  const groups = apiResponse.response && apiResponse.response.groups;
  if (groups) {
    // note that not every EmailPreference has an ID, so using the title as the ID instead
    groups.forEach((group) => {
      group.children.forEach(({ id, isSubscribed, title }) => {
        entities.push(createEntity(
          title,
          EmailPreference.meta.name,
          {
            id,
            isSubscribed,
            title
          }
        ));
        references.push(createEntityReference(
          title,
          EmailPreference.meta.name
        ));
      });
    });
  }

  return {
    // still return emailPreferences for backward compatibility
    emailPreferences: apiResponse.response ? apiResponse.response.groups : [],
    entities,
    references
  };
}

export function updateEmailPreferencesRequestMapper(apiParams) {
  return {
    groups: apiParams.groups,
    nonce: _.uniqueId()
  };
}

export function addGroupRequestMapper(apiParams) {
  let permissionsArray = [];
  let permissions = apiParams.permissions;
  for (let prop in permissions) {
    if (permissions[prop]) {
      if (prop === 'approveInvoices') {
        prop = 'approveBills';
      }
      permissionsArray.push(prop.charAt(0).toUpperCase() + prop.slice(1));
    }
  }

  return {
    name: apiParams.name,
    permissionIds: permissionsArray,
    companyUserIds: apiParams.companyUserIds,
    ignoreValidationWarnings: false,
    roleId: apiParams.id
  };
}

export function getUsersForGroupResponseMapper(apiResponse) {
  return {
    users: apiResponse.users.filter(user => user.inRole)
  };
}

export function getAvailableGroupsResponseMapper(apiResponse) {
  return {
    groups: apiResponse.response.roles
  };
}

export function getPermissionsForGroupsResponseMapper(apiResponse) {
  let permissions = [];

  if (apiResponse.success && apiResponse.response) {
    let response = apiResponse.response;
    permissions = response.permissionCategories.reduce((value, category) => {
      let categoryPermissions = category.permissions.reduce((innerValue, permission) => {
        let key = lowercaseUserPermissions[permission.id.toLowerCase()];
        if (!key) {
          console.warn(`Unknown permission: ${permission.id}`);
          return innerValue;
        }
        return {
          ...innerValue,
          [key]: permission.granted
        };
      }, {});
      return {
        ...value,
        ...categoryPermissions
      };
    }, {});
  }

  return {
    permissions
  };
}
