/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */
/* eslint max-len:0 */

import { toMessages } from 'config/messages';

export default toMessages('RegistrationWorkflow.ClosedRegistration', {
  Header: 'Join Viewpost.',
  Subheader: 'Viewpost helps companies large and small earn cash back on their outbound vendor payments. Learn how to join and begin savings immediately.',
  InvitationHeader: 'By Invitation',
  InvitationSubheader: 'Businesses on the Viewpost network invite their customers and suppliers to join simply by sending invoices or payments. When you receive an invitation from one of your trading partners, simply follow the link to sign up.',
  SalesHeader: 'Contact Enterprise Sales',
  SalesSubheader: 'Viewpost helps large organizations optimize payment delivery and earn cash back on their AP spend. {contactUs}',
  ContactUsMessage: 'Contact us today for a free consultation. {contactUs}',
  ContactUs: 'Contact Us',
  AlreadyHaveAccount: 'Already have an account? {logonLink}',
  LogIn: 'Log In'
});
