/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { ExpeditedRegistrationResponse } from 'api/registration';
import { coordinateApiActions, isCoordinatorLoading } from 'actions/coordinator';
import { getCacheEntity } from 'actions/references';
import { LoginCoordinatorName } from 'config/constants';

export const createRegistrationFlow = (...args) => coordinateApiActions(LoginCoordinatorName.Register, ...args);

export const isRegistering = state => isCoordinatorLoading(state, LoginCoordinatorName.Register);

export const RegistrationReferenceName = 'RegisterCompany';

export const getRegistrationResult = state => getCacheEntity(
  state,
  RegistrationReferenceName,
  ref => ref.type === ExpeditedRegistrationResponse.meta.name
);
