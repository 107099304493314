/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, { cloneElement, useEffect, useRef } from 'react';
import classNames from 'classnames';
import loadjs from 'loadjs';
import { useDispatch } from 'react-redux';

import verifyPlaidAccount from 'actions/settings/verifyPlaidAccount';
import { assertApiSuccess } from 'actions/utils/promises';

import createPlaidLinkToken from 'api/paymentAccounts/createPlaidLinkToken';
import exchangePlaidToken from 'api/paymentAccounts/exchangePlaidToken';

import BaseLoadingIndicator from 'components/LoadingIndicator/Base';

import createProvideApiResultHooks from 'hooks/apiResult/createProvideApiResultHooks';
import useLoadScript from 'hooks/browser/useLoadScript';
import useFuncRef from 'hooks/react/useFuncRef';
import usePropRef from 'hooks/react/usePropRef';

import './index.scss';

const CreatePlaidLinkTokenHooks = createProvideApiResultHooks({
  endpoint: createPlaidLinkToken
});

let _loadScriptPromise;

const getLoadScriptPromise = () => {
  if (!_loadScriptPromise) {
    _loadScriptPromise = loadjs(
      'https://cdn.plaid.com/link/v2/stable/link-initialize.js',
      { returnPromise: true }
    );
  }

  return _loadScriptPromise;
};

const PlaidBankSelectorWrapper = ({
  children,
  // Overrides the exchanging and verifying of the payment account to be handled
  // by whomever provides this.
  handleAccountSelected: handleAccountSelectedProp,
  ignoreLoading,
  innerProps,
  onLoad: onLoadProp,
  onAccountSelected: onAccountSelectedProp,
  onAccountSelectedAndVerified: onAccountSelectedAndVerifiedProp,
  onPlaidExit: onPlaidExitProp,
  onPlaidOpen: onPlaidOpenProp,
  selectComponent: PlaidSelectionGrid
}) => {
  // Hooks
  const callbackRef = usePropRef({
    handleAccountSelected: handleAccountSelectedProp,
    onAccountSelected: onAccountSelectedProp,
    onAccountSelectedAndVerified: onAccountSelectedAndVerifiedProp,
    onLoad: onLoadProp,
    onPlaidExit: onPlaidExitProp,
    onPlaidOpen: onPlaidOpenProp
  });

  const dispatch = useDispatch();

  const createLinkHandlerRef = useRef();
  const linkHandlerRef = useRef();

  const { current: onLinkSuccess } = useFuncRef(
    () => async (
      publicToken,
      metadata
    ) => {
      const {
        handleAccountSelected,
        onAccountSelected,
        onAccountSelectedAndVerified
      } = callbackRef.current;

      if (handleAccountSelected) {
        return handleAccountSelected(
          publicToken,
          metadata
        );
      }

      const accountId = metadata.account_id;

      const [ success, action ] = await dispatch(exchangePlaidToken({ publicToken }));

      if (!success) return;

      const { accessTokenId } = action.payload.response;

      if (onAccountSelected) {
        onAccountSelected(
          metadata.institution.type,
          accountId,
          {
            accessTokenId,
            ...metadata
          }
        );

        return;
      }

      await dispatch(assertApiSuccess(verifyPlaidAccount(
        accessTokenId,
        accountId
      )));

      onAccountSelectedAndVerified?.(
        metadata.institution.type,
        accountId,
        {
          accessTokenId,
          ...metadata
        }
      );
    }
  );

  const { current: onPlaidLoad } = useFuncRef(
    () => (...args) => callbackRef.current.onLoad?.(...args)
  );

  const { current: onPlaidExit } = useFuncRef(
    () => (err, metadata) => {
      const { onPlaidExit: onPlaidExitCallback } = callbackRef.current;

      if (err?.error_code === 'INVALID_LINK_TOKEN') {
        linkHandlerRef.current.destroy();
        linkHandlerRef.current = createLinkHandlerRef.current();
        return;
      }

      onPlaidExitCallback?.(err, metadata);
    }
  );

  CreatePlaidLinkTokenHooks.useProvideParams(
    {},
    { deleteOnMount: true }
  );

  const isCreatingPlaidLinkToken = CreatePlaidLinkTokenHooks.useIsLoading();
  const hasCreatePlaidLinkTokenFailed = CreatePlaidLinkTokenHooks.useRequestError();
  const { linkToken } = CreatePlaidLinkTokenHooks.useResponse() || {};

  const [ isPlaidLoaded, hasPlaidLoadFailed ] = useLoadScript(
    getLoadScriptPromise()
  );

  const hasFailed = hasCreatePlaidLinkTokenFailed || hasPlaidLoadFailed;
  const isLoading = hasFailed || !isPlaidLoaded || isCreatingPlaidLinkToken;

  const canCreateLinkHandler = !isLoading && !!linkToken;

  useEffect(
    () => {
      if (!canCreateLinkHandler) return;

      createLinkHandlerRef.current = () => global.Plaid.create({
        onExit: onPlaidExit,
        onLoad: onPlaidLoad,
        onSuccess: onLinkSuccess,
        token: linkToken
      });

      linkHandlerRef.current?.destroy();
      linkHandlerRef.current = createLinkHandlerRef.current();
    },
    [ canCreateLinkHandler ]
  );

  const { current: openPlaid } = useFuncRef(
    () => (institution) => {
      const { onPlaidOpen } = callbackRef.current;

      linkHandlerRef?.current?.open(institution);
      onPlaidOpen?.();
    }
  );

  // Render
  return (
    <div className="plaid-selector-wrapper">
      {!ignoreLoading && isLoading ? (
        <div className="loading-overlay">
          <div className="message">
            {hasFailed ? (
              <>There has been a failure</>
            ) : (
              <BaseLoadingIndicator
                variant="light"
                className="no-margin"
              >
                Loading...
              </BaseLoadingIndicator>
            )}
          </div>
        </div>
      ) : null}
      <div className={classNames({ 'disabled-content': !ignoreLoading && isLoading })}>
        {cloneElement(
          children,
          {
            hasPlaidLoadFailed: hasFailed,
            isPlaidLoading: isLoading,
            onSelectPlaidBank: openPlaid,
            onOpenPlaid: () => {
              linkHandlerRef?.current?.open();
            }
          }
        )}
      </div>
    </div>
  );
};

export default PlaidBankSelectorWrapper;
