/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, { isValidElement } from 'react';
import { isString } from 'lodash';

import { NotificationStyleTypes, showSmallBanner } from 'actions/notification';

import { toMessages } from 'config/messages';

const Messages = toMessages('AssertApiSuccess', {
  DefaultErrorMessage: 'An unexpected error occurred.'
});

/**
  Wrap a API Action and throw an error and, optionally, display a toast if the call fails.
  This must be placed within a dispatch: e.g., dispatch(assertApiSuccess(myApiAction()))
*/
export const assertApiSuccess = (promiseAction, options) => async (dispatch) => {
  const {
    failureMessage,
    successMessage
  } = options || {};

  const result = await dispatch(promiseAction);

  const [ success ] = result;

  if (!success) {
    if (!!failureMessage || failureMessage == null) {
      dispatch(showSmallBanner({
        type: NotificationStyleTypes.Error,
        message: isValidElement(failureMessage) || isString(failureMessage)
          ? failureMessage
          : <Messages.DefaultErrorMessage.Message />
      }));
    }

    throw new Error('API called failed');
  } else {
    if (successMessage) {
      dispatch(showSmallBanner({
        type: NotificationStyleTypes.Success,
        message: successMessage
      }));
    }
  }

  return result;
};