/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import PaymentActions from 'config/constants/PaymentActions';
import PaymentViewActions from 'config/constants/PaymentViewActions';

export default {
  path: '/payments',
  requiredAction: PaymentViewActions.ViewPayments,
  children: {
    SendPayment: {
      path: '/send',
      requiredAction: PaymentActions.SendPayment
    },
    Sent: {
      path: '/sent',
      requiredAction: PaymentViewActions.ViewSent
    },
    SentDetails: {
      path: '/details/sent/:id'
    },
    Received: {
      path: '/received',
      requiredAction: PaymentViewActions.ViewReceived
    },
    ReceivedDetails: {
      path: '/details/received/:id'
    },
    QbOnline: {
      path: '/qbonline',
      requiredAction: PaymentViewActions.ViewQbo
    },
    AutoPay: {
      path: '/auto',
      requiredAction: PaymentViewActions.ViewAutoPay
    },
    Approvals: {
      path: '/approvals',
      requiredAction: PaymentViewActions.ViewApprovals
    }
  }
};
