/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { isPersonalPortal } from 'actions/common';
import { getCompanySettings, isCorporateExperience } from 'actions/common/companySettings';
import getCompanyUserAdditionalSetting from 'actions/common/getCompanyUserAdditionalSetting';

import useQueryParams from 'hooks/location/useQueryParams';

import { useHasLoggedInOnThisPage, useHasNotDoneBigAction } from '../utils';
import launchFreeTrialNotification from './FreeTrialNotificationsModal';
import { GetCorporateActionCounts } from './FreeTrialNotificationsModal/index.connect';
import {
  getNotificationToShow,
  hasTakenAction as checkHasAlreadyTakenAction
} from './FreeTrialNotificationsModal/index.utils';

const FreeTrialNotificationWorkflowStep = ({
  active,
  updateState
}) => {
  // Hooks
  const dispatch = useDispatch();

  const { freeTrialNotification, forceNotification } = useQueryParams([
    'forceNotification',
    'freeTrialNotification'
  ]);

  const isInCampaign = useSelector(
    (state) => {
      const {
        campaigns,
        hasSubscriptionPaymentMethod
      } = getCompanySettings(state);

      if (hasSubscriptionPaymentMethod) return false;

      const { type: campaignType } = (campaigns || [])[0] || {};

      if (campaignType !== 'FreeTrial') return false;

      return true;
    }
  );
  const hasNotDoneBigAction = useHasNotDoneBigAction();
  const loggedInThisPage = useHasLoggedInOnThisPage();
  const shouldCheck = isInCampaign && loggedInThisPage && hasNotDoneBigAction;

  const lastNotification = useSelector(
    state => getCompanyUserAdditionalSetting(
      state,
      'currentFreeTrialNotification'
    ),
    shallowEqual
  );

  const hasAlreadyTakenAction = useSelector(
    state => checkHasAlreadyTakenAction(lastNotification)
  );

  const shouldLoad = useSelector(
    state => !hasAlreadyTakenAction && !GetCorporateActionCounts.hasCacheBeenCalled(state)
  );

  const canShow = useSelector(state => !isPersonalPortal(state) && isCorporateExperience(state));

  const executeLoad = async () => {
    const [ success ] = await dispatch(GetCorporateActionCounts.dispatchApiCall());
    updateState(success ? 'loaded' : 'completed');
  };

  const willCheck = canShow && (forceNotification === 'freeTrialNotification' || shouldCheck);

  useEffect(
    () => {
      if (!willCheck) {
        updateState('skipped');
      } else if (shouldLoad) {
        // useEffect doesn't like being passed an async function - hence why this broken out
        executeLoad();
      } else {
        updateState('loaded');
      }
    },
    [ willCheck ]
  );

  const currentNotification = useSelector(
    (state) => {
      if (!shouldCheck) return;

      const { nextBillingDate } = getCompanySettings(state);

      const { createdInvoicesCount, createdPaymentsCount } = GetCorporateActionCounts
        .getCacheEntity(state) || {};

      return getNotificationToShow({
        hasTakenAction: hasAlreadyTakenAction || createdInvoicesCount || createdPaymentsCount,
        endDate: nextBillingDate
      });

    }
  );

  const willShow = willCheck && (!!currentNotification && currentNotification !== lastNotification);

  useEffect(
    () => {
      if (!willShow) {
        updateState('skipped');
      }
    },
    [ willShow ]
  );

  const execute = async () => {
    dispatch({ type: 'HasDoneOncePerSessionAction' });
    await dispatch(launchFreeTrialNotification({ forceNotification: freeTrialNotification }));
    updateState('completed');
  };

  useEffect(
    () => {
      if (willShow && active) {
        // useEffect doesn't like being passed an async function - hence why this broken out
        execute();
      }
    },
    [ active ]
  );

  // Render
  return <></>;
};

export default FreeTrialNotificationWorkflowStep;