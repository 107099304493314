/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { push, replace } from 'connected-react-router';
import { some } from 'lodash';
import {
  createSearchString,
  getQuery as getQueryFromLocation,
  getQueryParam as getQueryParamFromProps,
  getQueryParams
} from 'config/routes/utils';

const legacyGetQueryParam = param => getQueryParamFromProps(
  { location: global.window.location },
  param
);

const legacyGetQueryParams = paramKeys => getQueryParams(
  { location: global.window.location },
  paramKeys
);

const legacyGetPathname = () => global.window.location.pathname;

function updateQuery(dispatch, getState, addHistory, queryUpdateFn) {
  let query = { ...legacyGetQueryParams() };
  queryUpdateFn(query);

  // delete all null items (so we don't clutter the url)
  let keys = Object.keys(query);
  keys.forEach((key) => {
    if (query[key] === null) {
      delete query[key];
    }
  });

  let updateRoute = addHistory ? push : replace;
  dispatch(updateRoute({
    pathname: legacyGetPathname(),
    search: createSearchString(query)
  }));
}

// Treat these query params same as filters, but they don't get the filter_ prefix
const SpecialFilterNames = ['page', 'pageSize', 'sort'];

export function filtersToQuery(filters, query) {
  let filterNames = Object.keys(filters);
  filterNames.forEach((filterName) => {
    let filterValue = filters[filterName];
    if (SpecialFilterNames.includes(filterName)) {
      query[filterName] = filterValue;
    } else {
      query[`filter_${filterName}`] = filterValue;
    }
  });
  return query;
}

function queryToFilters(query, transformValue, applySpecial) {
  let filters = {};
  let queryNames = Object.keys(query);
  queryNames.forEach((queryName) => {
    if (queryName.startsWith('filter_')) {
      let filterName = queryName.substring(7);
      let filterValue = query[queryName];
      filters[filterName] = transformValue ? transformValue(filterName, filterValue) : filterValue;
    } else if (applySpecial && SpecialFilterNames.includes(queryName)) {
      let filterValue = query[queryName];
      filters[queryName] = transformValue ? transformValue(queryName, filterValue) : filterValue;
    }
  });
  return filters;
}


function serializeSortFields(sortFields) {
  // We'd like to avoid lengthy query strings, so using a custom serialization strategy
  // can help this. Especially if we avoid characters that get url encoded
  // These characters do not get encoded: - . _
  if (!sortFields || !sortFields.length) {
    return '';
  }

  // example: sort=field1_-field2_field3
  return sortFields.map((field) => {
    let direction = field.isDescending ? '-' : '';
    return `${direction}${field.name}`;
  }).join('_');
}

function deserializeSortFields(sortFieldString) {
  if (!sortFieldString) {
    return [];
  }

  return sortFieldString.split('_')
    .map((fieldString) => {
      return {
        name: fieldString.replace('-', ''),
        isDescending: fieldString.startsWith('-')
      };
    });
}

/**
 * Gets the currently applied filters
 * @constructor
 * @param {ReduxState} state - Redux state
 * @return {object} Map of filter name -> filter value
 */
export function getFilters(state, transformValue) {
  return queryToFilters(legacyGetQueryParams(), transformValue);
}

export function getFiltersFromProps({ location }, transformValue, applySpecial) {
  return queryToFilters(getQueryFromLocation(location), transformValue, applySpecial);
}

/**
 * Gets the currently applied sort fields
 * @constructor
 * @param {ReduxState} state - Redux state
 * @return {array} List of Object containing name and isDescending
 */
export function getSortFields(state) {
  return deserializeSortFields(legacyGetQueryParam('sort'));
}

/**
 * Gets the currently applied page
 * @constructor
 * @param {ReduxState} state - Redux state
 * @return {number} Current page number
 */
export function getPage(state) {
  return legacyGetQueryParam('page');
}

export function getPageFromProps(props, transformValue) {
  return getQueryParamFromProps(props, 'page');
}

/**
 * Creates a redux action that will set the filters to be applied to the current route
 * @constructor
 * @param {object} filters - Map of filter name -> filter value
 * @param {boolean} addHistory - Whether or not this change should add a new history item
 * @return {ReduxAction} The action to dispatch
 */
export function setFilters(filters, addHistory = false) {
  return (dispatch, getState) => {
    updateQuery(dispatch, getState, addHistory, (query) => {
      filtersToQuery(filters, query);
    });
  };
}

/**
 * Creates a redux action that will set the value of a specific filter
 * @constructor
 * @param {string} filterName - name of the filter to set
 * @param {any} filterValue - value of the filter
 * @param {boolean} addHistory - Whether or not this change should add a new history item
 * @return {ReduxAction} The action to dispatch
 */
export function setFilter(filterName, filterValue, addHistory = false) {
  return (dispatch, getState) => {
    let filters = getFilters(getState());
    filters[filterName] = filterValue;
    dispatch(setFilters(filters, addHistory));
  };
}

/**
 * Creates a redux action that will set the sort fields to be applied to the current route.
 * Sort fields represent the sorting state of the current view
 * @constructor
 * @param {array} sortFields - array of objects containing the name of the field and sort direction
 *                             each object (field) should have a name property and optionally isDescending
 * @param {boolean} addHistory - Whether or not this change should add a new history item
 * @return {ReduxAction} The action to dispatch
 */
export function setSortFields(sortFields, addHistory = false) {
  // enforce contract
  if (!sortFields || !sortFields.length) {
    throw new Error('Sort fields must be an array.', sortFields);
  }
  if (some(sortFields, (sortField) => {
    return !sortField.name;
  })) {
    throw new Error('Every sort field must be named.', sortFields);
  }

  return (dispatch, getState) => {
    updateQuery(dispatch, getState, addHistory, (query) => {
      query.sort = serializeSortFields(sortFields);
    });
  };
}

/**
 * Creates a redux action that will set the current page (for pagination) for the current route
 * @constructor
 * @param {number} pageNumber - the current page (starts at 1)
 * @param {boolean} addHistory - Whether or not this change should add a new history item
 * @return {ReduxAction} The action to dispatch
 */
export function setPage(pageNumber, addHistory = true) {
  if (isNaN(pageNumber) || pageNumber < 1) {
    throw new Error('Page number cannot be less than 1');
  }

  return (dispatch, getState) => {
    updateQuery(dispatch, getState, addHistory, (query) => {
      if (pageNumber === 1) {
        delete query.page; // Don't bother putting page in the query when it is 1
      } else {
        query.page = pageNumber;
      }
    });
  };
}
