/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { shallowEqual, useSelector } from 'react-redux';
import { isAuthorizedToUseApp, isPersonalPortal } from 'actions/common';

const useIsPersonalPortalUser = () => useSelector(
  (state) => {
    const isFullyAuthenticated = isAuthorizedToUseApp(state);

    return isFullyAuthenticated && isPersonalPortal(state);
  },
  shallowEqual
);

export default useIsPersonalPortalUser;