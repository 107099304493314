/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import {
  createEntity,
  createEntityReference
} from 'schemas/state';
import {
  EditBankAccount,
  PaymentsToBeSent
} from 'schemas/payments/bankAccount';
import { isPersonalPortal } from 'actions/common';
import _ from 'lodash';
import moment from 'moment';
import { BankAccountActions } from 'config/constants/bankAccount';
import { filterAvailableActionsFromState } from 'api/permissions';

/*
    Determine actions for a bank account
*/
function determineAvailableActions(account, apiBankAccountResponse, state) {
  if (!apiBankAccountResponse || !account) {
    return;
  }

  let response = apiBankAccountResponse.response;
  account.availableActions = [BankAccountActions.Delete];
  if (response.canReset && response.isResetPermitted) {
    account.availableActions.push(BankAccountActions.Reset);
  }
  if (!isPersonalPortal(state)) {
    // Consumers can't enable/disable bank accounts
    if (response.isActive && !response.isPendingVerification) {
      account.availableActions.push(BankAccountActions.Disable);
    } else if (!response.isPendingVerification) {
      account.availableActions.push(BankAccountActions.Enable);
    }
  }

  // filter based on permissions
  account.availableActions = filterAvailableActionsFromState(state, account.availableActions);
}

export function getBankAccountResponseMapper(apiBankAccountResponse, state) {
  let entities = [];
  let references = [];

  if (apiBankAccountResponse.success && apiBankAccountResponse.response) {
    let response = apiBankAccountResponse.response;
    let account = {
      id: response.paymentAccountId,
      accountName: response.accountName,
      accountNumber: response.accountNumberDisplayDigits,
      bankName: response.bankName,
      nextCheckNumber: response.nextCheckNumber,
      routingNumber: response.routingNumber,
      signatureImage1: response.signatureImage1,
      authorizedSigner: response.signatureText1,
      authorizedSigner2: response.signatureText2,
      signatureType: response.signatureType,
      numberRequiredSignatures: response.numberRequiredSignatures
    };

    determineAvailableActions(account, apiBankAccountResponse, state);

    entities.push(createEntity(
      response.paymentAccountId,
      EditBankAccount.meta.name,
      account
    ));

    references.push(createEntityReference(
      response.paymentAccountId,
      EditBankAccount.meta.name
    ));
  }

  return {
    entities,
    references
  };
}

export function checkPaymentToBeSentResponseMapper(apiPaymentResponse) {
  const entities = [];
  const references = [];
  let total = 0;

  if (apiPaymentResponse.success && apiPaymentResponse.response) {
    const payments = apiPaymentResponse.response.payments;
    payments.forEach((payment) => {
      const uniqueId = _.uniqueId();
      entities.push(createEntity(
        payment.checkNumber || uniqueId,
        PaymentsToBeSent.meta.name,
        {
          checkNumber: payment.checkNumber,
          approverName: payment.approverName,
          paymentAmount: payment.paymentAmount,
          vendorName: payment.vendorName
        }
      ));

      references.push(createEntityReference(
        payment.checkNumber || uniqueId,
        PaymentsToBeSent.meta.name
      ));
    });
    total = apiPaymentResponse.response.paymentTotal;
  }

  // TODO: Find a better way to expose this 'total' data.
  // MVC endpoint could return data in different formats
  return {
    entities,
    references,
    total
  };
}

export function disablePaymentAccountRequestMapper(apiParams) {
  return {
    paymentAccountId: apiParams.accountId
  };
}

export function editBankAccountRequestBodyMapper(apiParams) {
  return {
    PaymentAccountId: apiParams.id,
    Name: apiParams.accountName,
    NextCheckNumber: apiParams.nextCheckNumber,
    SignatureText1: apiParams.signatureText1,
    SignatureText2: apiParams.signatureText2,
    SignatureImage: apiParams.signatureImage1,
    numberRequiredSignatures: apiParams.numberRequiredSignatures,
    skipPaymentsWillBeSentWarning: apiParams.skipPaymentsWillBeSentWarning
  };
}

export function resetBankAccountRequestBodyMapper(apiParams) {
  return {
    paymentAccountId: apiParams.id
  };
}

export function submitWesternUnionEnrollmentResponseMapper({
  companyName,
  dbaName,
  address,
  yearOfIncorporation,
  taxPayerId,
  industryId,
  bankAccount,
  phone,
  businessType
}) {
  let mappedAddress = {};
  if (address) {
    mappedAddress.address1 = address.line1;
    mappedAddress.address2 = address.line2;
    mappedAddress.city = address.city;
    mappedAddress.state = address.stateProvince;
    mappedAddress.postalCode = address.postalCode;
  }
  return {
    fullLegalName: companyName,
    doingBusinenssAs: dbaName,
    businessCreationDate: moment(`01/01/${yearOfIncorporation}`, 'MM/DD/YYYY').toDate(),
    businessPhoneNumber: phone,
    businessType,
    taxIdentifier: taxPayerId || null,
    industryId,
    paymentAccountIds: [bankAccount],
    ...mappedAddress
  };
}
