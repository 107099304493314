/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */
/* eslint max-len:0 */

import { toMessages } from 'config/messages';

export default toMessages('SecureAccountSpeedBump', {
  Header: 'Just a moment.',
  Subheader: 'We need you to do something before sending you in.',
  FormHeader: 'Secure Your Account',
  TwoFactorInstructions: 'Keep your account safe by enabling two-factor authentication. Don\’t want to use a smartphone? {toggleLink}',
  TwoFactorInstructionsWithoutToggle: 'Keep your account safe by enabling two-factor authentication.',
  TwoFactorInstructionsWithoutToggleButDismiss: 'Keep your account safe by enabling two-factor authentication. {dismiss}',
  SecurityQuestionsInstructions: 'Keep your account safe by answering these security questions. Prefer to use a smartphone? {toggleLink}',
  SwitchToTwoFactorLink: 'Enable two-factor verification.',
  SwitchToSecurityQuestions: 'Answer security questions.',
  Dismiss: 'Dismiss'
});
