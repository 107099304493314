/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { shallowEqual, useSelector } from 'react-redux';
import { getIdentity } from 'actions/common';
import { isPersonalPortal } from 'actions/common';
import {
  isPortalExperience,
  isCustomerPortalExperience,
  isVendorPortalExperience,
  isVccEnabled
} from 'actions/common/companySettings';

const useNavigationParams = () => useSelector(
  (state) => {
    const { accountingPackage } = getIdentity(state);

    return {
      accountingPackage,
      isPersonalUser: isPersonalPortal(state),
      isPortalUser: isPortalExperience(state),
      isCustomer: isCustomerPortalExperience(state),
      isVendor: isVendorPortalExperience(state),
      isVccEnabled: isVccEnabled(state)
    };
  },
  shallowEqual
);

export default useNavigationParams;