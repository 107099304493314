/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

const PaymentActions = {
  SendPayment: 'SendPayment',
  SignCheck: 'SignCheck',
  GetAccounts: 'GetPaymentAccounts',
  ApplyPayments: 'ApplyPayments',
  VoidPayment: 'VoidPayment',
  ViewReceivedDate: 'ViewReceivedDate',
  UpdateReceived: 'UpdateReceived',
  UpdateAddressBook: 'UpdatePaymentAddressBook',
  EnrollAutoPay: 'EnrollAutoPay',
  RequestPaymentSupport: 'RequestPaymentSupport',
  Approve: 'Approve',
  RejectApproval: 'RejectApproval',
  CancelScheduled: 'CancelScheduled'
};

export default PaymentActions;