/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import HeaderText from 'components/HeaderText';

import Tooltip from 'components/Tooltip';
import Icon, { IconTypes } from 'components/Icon';
import Button from 'components/Button';

import FormJSX from 'components/Form/FormJSX';
import FieldOption from 'components/Form/FieldOption';
import t from 'tcomb-validation';
import Messages from './index.messages';
import { MapStateToProps, MapDispatchToProps } from './index.connect';

import BeneficialOwnerCollectionForm from './Form';

import './index.scss';

let mustBeTrue = t.subtype(t.Bool, val => val === true);

let beneficialCountSchema = t.struct({
  count: t.enums.of([0, 1, 2, 3, 4])
});

let confirmationSchema = t.struct({
  confirmed: mustBeTrue
});

class BeneficialOwnerCollectionPanel extends Component {

  constructor(props) {
    super(props);
    this.state = {
      beneficialOwnerFormState: null,
      confirmationFormState: null
    };
    this.beneficialOwnerFormRefs = [];
  }

  getValue = () => {
    let mergedForm = {
      beneficialOwners: []
    };
    mergedForm.controllingOwner = this.controllingOwnerFormRef.getValue();
    if (!mergedForm.controllingOwner) {
      return null;
    }

    for (let x = 0; x < this.beneficialOwnerFormRefs.length; x++) {
      if (this.beneficialOwnerFormRefs[x]) {
        let value = this.beneficialOwnerFormRefs[x].getValue();
        if (!value) {
          return null;
        }
        mergedForm.beneficialOwners[x] = value;
      }
    }

    // validate internal UI forms

    let beneficialCountValue = this.beneficialCountFormRef.getValue();
    if (!beneficialCountValue) {
      return null;
    }

    let confirmFormValue = this.confirmationFormRef.getValue();
    if (!confirmFormValue) {
      return null;
    }

    return mergedForm;
  }

  submit = (onComplete) => {
    let formValue = this.getValue();
    if (formValue) {
      this.props.onUpdate(formValue, onComplete);
    }
  }

  renderControllingOwner() {
    return (
      <div className="form-section">
        <HeaderText>
          Individual with Control
        </HeaderText>
        <Messages.ControllingOwnerInstruction.Message />
        <BeneficialOwnerCollectionForm ref={ref => this.controllingOwnerFormRef = ref} />
      </div>
    );
  }

  renderBeneficialOwners() {
    return (
      <div>
        {this.renderBeneficialOwnerCount()}
        {this.renderBeneficialOwnerForms()}
      </div>
    );
  }

  renderBeneficialOwnerCount() {
    let tooltip = (
      <Tooltip overlay={<Messages.IndirectOwnershipTooltip.VpMessage />}>
        <Icon type={IconTypes.Info}/>
      </Tooltip>
    );

    return (
      <div className="form-section">
        <Messages.BeneficialOwnerCount.Message tooltip={tooltip}/> <br/><br/>
        <FormJSX ref={ref => this.beneficialCountFormRef = ref}
          modelType={beneficialCountSchema}
          value={this.state.beneficialCountFormState}
          onChange={val => this.setState({beneficialCountFormState: val})}>
          <FieldOption name="count" willHideLabel={true} />
        </FormJSX>
      </div>
    );
  }

  renderBeneficialOwnerForms() {
    this.beneficialOwnerFormRefs = [];

    const { count: originalCount } = this.state.beneficialCountFormState || {};
    const count = parseInt(originalCount || 0, 10);

    if (!count) {
      return <></>;
    }

    let forms = [];
    for (let x = 0; x < count; x++) {
      forms.push(
        <div className="form-section" key={x}>
          <HeaderText>
            Owner #{x + 1}
          </HeaderText>
          <BeneficialOwnerCollectionForm ref={ref => this.beneficialOwnerFormRefs[x] = ref} />
        </div>
      );
    }

    return (
      <div>
        <Messages.BeneficialOwnerInstruction.Message count={count} />
        {forms}
      </div>
    );
  }

  renderConfirmation() {
    return (
      <div className="form-section">
        <FormJSX ref={ref => this.confirmationFormRef = ref}
          modelType={confirmationSchema}
          value={this.state.confirmationFormState}
          onChange={val => this.setState({confirmationFormState: val})}>
          <FieldOption name="confirmed"
            label={Messages.CertifyStatement} />
        </FormJSX>
      </div>
    );
  }

  render() {
    return (
      <div className="component-beneficial-owner-collection-panel">
        <Messages.Instruction.VpMessage />
        {this.renderControllingOwner()}
        {this.renderBeneficialOwners()}
        {this.renderConfirmation()}
        {this.props.showSubmit === false
          ? null
          : (
            <Button onClick={() => this.submit()}>
              Submit
            </Button>
          )}
      </div>
    );
  }
}

export default connect(MapStateToProps, MapDispatchToProps, null, { forwardRef: true })(
  BeneficialOwnerCollectionPanel
);
