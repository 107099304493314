/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import _ from 'lodash';

const DocumentType = {
  CreditMemo: 'CreditMemo',
  Invoice: 'Invoice',
  Document: 'Document'
};

const InvoiceListType = {
  InvoiceInbox: 'InvoiceInbox',
  InvoiceList: 'InvoiceList',
  InvoiceCompanySummary: 'InvoiceCompanySummary',
  BillsInbox: 'BillsInbox',
  BillsList: 'BillsList',
  BillsCompanySummary: 'BillsCompanySummary',
  MailboxList: 'MailboxList'
};

const InvoiceType = {
  Invoice: 'Invoice',
  Bill: 'Bill',
  Document: 'Document'
};

const SchemaInvoiceType = {
  Payable: 'Payable',
  Receivable: 'Receivable',
  OutOfBandInvoice: 'OutOfBandInvoice',
  Series: 'Series',
  APInboxItem: 'APInboxItem',
  APInboxItemBill: 'APInboxItemBill',
  LogicalDocument: 'LogicalDocument'
};

const CorrelationId = {
  SearchCompanies: 'SearchCompanies',
  SearchStatuses: 'SearchStatuses',
  NetworkCompanies: 'NetworkCompanies',
  FetchInitialCompany: 'FetchInitialCompany'
};

const ListFilters = {
  Status: 'status',
  Category: 'category',
  CompanyId: 'companyId',
  CompanyNodeId: 'companyNodeId',
  AddressBookId: 'addressBookId',
  BeginDate: 'beginDate',
  EndDate: 'endDate',
  Search: 'search',
  SearchType: 'searchType',
  SettlementId: 'settlementId'
};

const ListStatusFilters = {
  All: 'all',
  AllOpen: 'allOpen',
  AllClosed: 'allClosed',
  Approved: 'approved',
  InReview: 'inreview',
  Disputed: 'disputed',
  Mailbox: 'mailbox',
  New: 'new',
  Open: 'open',
  Overdue: 'overdue',
  Scheduled: 'scheduled',
  Undelivered: 'undelivered',
  Draft: 'draft',
  Closed: 'closed',
  Unapplied: 'unapplied',
  Void: 'void',
  AdminSet: 'adminSet',
  PayOnlySet: 'payOnlySet'
};

const ListStatusCategoryFilters = {
  All: 'all',
  Open: 'open',
  Closed: 'closed'
};

const ListDateFields = {
  DueDate: 'DueDate',
  CloseDate: 'CloseDate',
  ScheduledPayDate: 'ScheduledPayDate',
  InvoiceDate: 'InvoiceDate'
};

const InvoiceRecurrenceValues = {
  Days: 'Days',
  Weeks: 'Weeks',
  Months: 'Months',
  DayOffset: 'DayOffset',
  OnFirst: 'OnFirst',
  OnLast: 'OnLast',
  DayOfMonth: 'DayOfMonth',
  Monday: 'Monday',
  Tuesday: 'Tuesday',
  Wednesday: 'Wednesday',
  Thursday: 'Thursday',
  Friday: 'Friday',
  Saturday: 'Saturday',
  Sunday: 'Sunday',
  PerSetTerms: 'PerSetTerms',
  OnReceipt: 'OnReceipt',
  Never: 'Never',
  Occurrences: 'Occurrences',
  Date: 'Date'
};

function getDocumentType(documents) {
  if (_.every(documents, doc => doc.isCreditMemo)) {
    return DocumentType.CreditMemo;
  } if (_.some(documents, doc => doc.isCreditMemo)) {
    return DocumentType.Document;
  }

  return DocumentType.Invoice;
}

function getInvoiceType(documents) {
  if (_.every(documents, doc => doc.invoiceType === 'receivable')) {
    return InvoiceType.Invoice;
  } if (_.every(documents, doc => doc.invoiceType === 'payable')) {
    return InvoiceType.Bill;
  }

  return InvoiceType.Document;
}

export {
  DocumentType,
  getDocumentType,
  getInvoiceType,
  InvoiceType,
  SchemaInvoiceType,
  CorrelationId,
  ListFilters,
  ListStatusFilters,
  ListStatusCategoryFilters,
  ListDateFields,
  InvoiceListType,
  InvoiceRecurrenceValues
};
