/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React from 'react';
import classNames from 'classnames';
import RouteLink from 'components/Link/RouteLink';

export default class StandardMenuRenderer {

  renderItems(items) {
    return items.map((menuItem, index) => {
      this.renderMenuItem(menuItem, index);
    });
  }

  renderMenuItem(menuItem, index) {
    if (!menuItem) {
      throw new Error('Must pass in a "menuItem" instance');
    }

    let props = menuItem.props;

    if (props.divider) {
      return <li className={classNames('divider', props.className)}></li>;
    }

    return (
      <li key={index}>
        <RouteLink
          linkRef={props.link}
          activeClassName="active"
          onClick={props.onClick}>
          {props.children}
        </RouteLink>
      </li>
    );
  }
}
