/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { createApiAction } from 'api/core';

import logonResponseMapper from './mappers/logonResponseMapper';

const verifyUserAccount = createApiAction({
  method: 'post',
  url: '/api/register/verify',
  requestBodyMapper: ({
    signature,
    userId
  }) => ({
    signature,
    userId
  }),
  responseMapper: (
    response,
    state,
    requestParams
  ) => logonResponseMapper(
    {
      messages: [],
      response,
      success: true
    },
    state,
    requestParams
  )
});

export default verifyUserAccount;