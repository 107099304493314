/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */
/* eslint max-len:0 */

import { defineMessages } from 'react-intl';

const common = defineMessages({
  Instructions: {
    id: 'Registration.CompanyMatches.Instructions',
    description: 'TODO',
    defaultMessage: 'Our records show that someone with a similar email address already has a Viewpost account. If your company is already registered, please select it.'
  },
  SelectButton: {
    id: 'Registration.CompanyMatches.SelectButton',
    description: 'TODO',
    defaultMessage: 'Create Account'
  }
});

export default {
  ...common
};
