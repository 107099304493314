/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { connect } from 'react-redux';
import { showSmallBanner } from 'actions/notification';
import { NotificationStyleTypes } from 'components/Notification';

export default Component => connect(null, dispatch => ({
  showSmallBanner: (message, isError) => dispatch(showSmallBanner({
    type: isError ? NotificationStyleTypes.Error : NotificationStyleTypes.Info,
    message
  }))
}))(Component);
