/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { createApiAction } from 'api/core';

const updateSession = createApiAction({
  method: 'patch',
  url: '/api/sessions/current',
  requestBodyMapper: ({ nonce, currentCompanyProfileId }) => {
    return {
      nonce,
      currentCompanyProfileId
    };
  }
});

export default updateSession;