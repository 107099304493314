/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */
/* eslint max-len:0 */

import { toMessages } from 'config/messages';

export default toMessages('Logon.ClosedCompanyLogon', {
  Continue: 'Continue',
  Comment: 'Comment',
  Instructions: 'Your Viewpost account is closed. If you want to reactivate your account, please let us know why and we will promptly review your comments and reply.',
  LockedInstructions: 'Your Viewpost account is locked. If you want to unlock your account, please let us know why and we will promptly review your comments and reply.',
  UserLockedInstructions: 'Your account is locked for your protection. If you want to unlock your account, please let us know why and we will promptly review your comments and reply.',
  RequestSuccess: 'Thank you for your request, we will promptly review your comments and reply.'
});
