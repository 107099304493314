/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Button from 'components/Button';
import './index.scss';

export const ModalFooterText = ({
  children
}) => (
  <div className="basicModal footerText text-tiny">
    {children}
  </div>
);

/**
 * Button inside the footer section of a modal
 */
const ModalFooterButton = ({
  id,
  isSubmit = false,
  isDisabled = false,
  isProcessing = false,
  variant,
  isOnlyButton = false,
  isRight = true,
  onClick,
  style,
  children
}) => {
  if (!onClick) {
    return (
      <ModalFooterText>{children}</ModalFooterText>
    );
  }

  let modifiedOnClick = (e, ...args) => {
    if (e) {
      e.preventDefault();
    }
    return onClick ? onClick(e, ...args) : undefined;
  };

  const classes = classNames('basicModal', isOnlyButton ? null : 'footerButton',
    isRight && !isOnlyButton ? 'right' : null);

  let modifiedVariant;
  if (!isSubmit) {
    if (isRight) {
      modifiedVariant = variant || 'secondary';
    } else {
      modifiedVariant = 'secondary-link';
    }
  }

  return (
    <div className={classes}>
      <Button analyticsId={id}
        type={isSubmit ? 'submit' : 'button'}
        variant={modifiedVariant || variant}
        isDisabled={isDisabled}
        isProcessing={isProcessing}
        expand={isOnlyButton}
        tall={isOnlyButton}
        bold={isOnlyButton}
        style={style}
        onClick={modifiedOnClick}>
        {children}
      </Button>
    </div>
  );
};

ModalFooterButton.propTypes = {
  isProcessing: PropTypes.bool,
  isRight: PropTypes.bool,
  isDisabled: PropTypes.bool,
  /** Whether or not the button is type="submit" */
  isSubmit: PropTypes.bool,
  variant: PropTypes.oneOf([
    'primary',
    'secondary',
    'tertiary',
    'success',
    'transparent',
    'utility',
    'link',
    'delete',
    'high-impact'
  ])
};

ModalFooterButton.defaultProps = {
  isProcessing: false,
  isRight: true,
  isDisabled: false,
  isSubmit: false
};

ModalFooterButton.isModalButton = true;

export default ModalFooterButton;
