/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

export function mapSentPaymentStatus(apiStatus, isVoid) {
  if (isVoid) {
    return 'Void';
  }
  switch (apiStatus) {
    case 'AwaitingFunds':
      return 'Processing';
    case 'Closed':
      return 'Sent';
    default:
      return apiStatus;
  }
}
