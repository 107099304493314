/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import t from 'tcomb-validation';
import { defineSchema } from 'schemas';
import { NetworkErrors as NetworkErrorsConstantEnum } from 'config/constants';

export const NetworkErrorType = t.enums(NetworkErrorsConstantEnum, 'NetworkErrorType');

export const NetworkError = defineSchema({
  type: NetworkErrorType
}, 'NetworkError');
