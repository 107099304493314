/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Icon, { IconTypes } from 'components/Icon';
import './MeatballCaret.scss';

const MeatballCaret = ({
  isOpen,
  style,
  variant,
  className
}) => (
  <Icon type={IconTypes.VerticalDots}
    className={classnames(
      'subtext',
      'mobile-large',
      isOpen ? 'open' : 'closed',
      'dropdown-caret-component',
      'meatball-caret',
      variant === 'white' ? 'white-meatball' : null,
      className
    )}
    style={style} />
);

MeatballCaret.propTypes = {
  variant: PropTypes.oneOf(['white'])
};

export default MeatballCaret;
