/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { NotificationStyleTypes, showNotification } from 'actions/notification';

import acceptTerms from 'api/session/acceptTerms';

import Button from 'components/Button';

import {
  ConsumerPaymentTerms,
  EndUserLicense,
  PaymentTerms,
  PrivacyPolicy,
  TermsOfUse,
  CassTerms
} from 'config/messageLinks';
import { toMessages } from 'config/messages';

import useIdentity from 'hooks/identity/useIdentity';

const Messages = toMessages('TermsAndConditionsNotification', {
  Preamble: 'Viewpost has revised the {documents}',
  // eslint-disable-next-line max-len
  Instructions: '{preamble}. By continuing to use Viewpost, you agree to the updated terms.'
});

function getLink(type) {
  switch (type) {
    case 'CassTerms': return CassTerms;
    case 'EndUserLicenseAgreement': return EndUserLicense;
    case 'ConsumerPaymentTerms': return ConsumerPaymentTerms;
    case 'PaymentTerms': return PaymentTerms;
    case 'PrivacyPolicy': return PrivacyPolicy;
    default: return TermsOfUse;
  }
}

const TermsAndConditionsMessage = () => {
  // Hooks
  const { termsAccepted } = useIdentity();

  const changedDocuments = [];

  termsAccepted?.forEach((terms) => {
    if (!terms.acceptedLatest) {
      changedDocuments.push(getLink(terms.type));
    }
  });

  const documentsMessage = changedDocuments.reduce(
    (val, message, index) => [ ...val, (
      <span key={index}>
        {message}
        {index < changedDocuments.length - 2
          ? ', '
          : null}
        {index === changedDocuments.length - 2
          ? ' and '
          : null}
      </span>
    )],
    []
  );

  // Render
  return (
    <Messages.Instructions.Message
      preamble={(
        <span className="type-strong">
          <Messages.Preamble.Message documents={documentsMessage} />
        </span>
      )}
    />
  );
};

const TermsAndConditionsFooter = ({ onClose }) => {
  // Hooks
  const dispatch = useDispatch();

  const execute = () => {
    dispatch(acceptTerms());
    onClose();
  };

  // Render
  return (
    <div className="text-right" style={{marginTop: '10px'}}>
      <Button onClick={execute}>
        Accept These Terms
      </Button>
    </div>
  );
};

const TermsAndConditionsWorkflowStep = ({
  active,
  updateState
}) => {
  // Hooks
  const dispatch = useDispatch();
  const { termsAccepted } = useIdentity();

  const shouldShow = termsAccepted?.some(x => !x.acceptedLatest) === true;

  useEffect(
    () => {
      updateState(!shouldShow ? 'skipped' : 'loaded');
    },
    [shouldShow ]
  );

  const execute = async () => {
    dispatch(showNotification({
      type: NotificationStyleTypes.Terms,
      closeOnClick: false,
      closeOnTimeout: false,
      message: <TermsAndConditionsMessage />,
      footer: <TermsAndConditionsFooter />
    }));
    updateState('completed');
  };

  useEffect(
    () => {
      if (shouldShow && active) {
        // useEffect doesn't like being passed an async function - hence why this broken out
        execute();
      }
    },
    [ active ]
  );

  // Render
  return <></>;
};

export default TermsAndConditionsWorkflowStep;
