/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */
/* eslint max-len:0 */

import { defineMessages } from 'react-intl';

const common = defineMessages({
  Header: {
    id: 'InvalidToken.Header',
    defaultMessage: 'Oops.'
  },
  Message: {
    id: 'InvalidToken.Message',
    defaultMessage: 'Looks like the link you followed is no longer valid. We apologize for this pesky inconvenience.'
  },
  ErrorMessage: {
    id: 'InvalidToken.Message',
    defaultMessage: 'There\'s a problem with this link. Please contact {supportLink} for assistance.'
  }
});

export default {
  ...common
};
