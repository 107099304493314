/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React from 'react';
import { Route, Redirect, Switch } from 'react-router';
import { wrapImport } from 'decorators/ModuleLoader';
import { SchemaInvoiceType } from 'config/constants/invoice';
import useSessionInfo from 'hooks/identity/useSessionInfo';
import { InvoiceType } from 'schemas/invoicing/invoice';
import { createOldInvoiceDetailsRedirect } from './invoices.utils';
import { ConditionalInAppNotFound, flattenHashLocation, InAppNotFoundView } from '../utils';

const InvoicesList = wrapImport(() => import(/* webpackChunkName: "views" */ 'views/Invoices/InvoicesList'));
const CreateInvoice = wrapImport(() => import(/* webpackChunkName: "views" */ 'views/Invoices/CreateInvoice'));
const GetPaidEarlyListView = wrapImport(() => import(/* webpackChunkName: "views" */ 'views/Invoices/GetPaidEarly'));
const RecurringInvoiceList = wrapImport(
  () => import(/* webpackChunkName: "views" */ 'views/Invoices/RecurringInvoiceList')
);
const InvoicesQBOnline = wrapImport(() => import(/* webpackChunkName: "views" */ 'views/Invoices/Qbonline'));
const InvoiceDetails = wrapImport(() => import(/* webpackChunkName: "views" */ 'views/Invoices/InvoiceDetails'));
const InvoicesCompanySummary = wrapImport(
  () => import(/* webpackChunkName: "views" */ 'views/Invoices/CompanySummary')
);
const RecordInvoicePayment = wrapImport(
  () => import(/* webpackChunkName: "views" */ 'views/Invoices/RecordPayment/receivablesPage')
);
const PersonalPortalInvoicesList = wrapImport(
  () => import(/* webpackChunkName: "personalPortal" */ 'views/PersonalPortal/Invoices/List')
);

export const LegacyReceivablesRoutes = ({ match, location }) => {
  return (
    <Switch location={flattenHashLocation(location)}>
      <Redirect path={match.url} exact={true} to="/invoices" />
      <Redirect path={`${match.url}/inbox`} exact={true} to="/invoices/inbox" />
      <Redirect path={`${match.url}/companySummary`} exact={true} to="/invoices/summary" />
      <Redirect path={`${match.url}/receivableInvoices`} exact={true} to="/invoices/list" />
      <Redirect path={`${match.url}/recurring`} exact={true} to="/invoices/recurring" />
      <Redirect path={`${match.url}/create`} exact={true} to="/invoices/create" />
      <Redirect path={`${match.url}/payment/view/:id`} exact={true} to="/payments/details/received/:id" />
      <Redirect path={`${match.url}/paymentReceipts`} exact={true} to="/payments/received" />
      <Redirect path={`${match.url}/payments/:id`} exact={true} to="/payments/details/received/:id" />
      <Redirect path={`${match.url}/payment`} exact={true} to="/invoices/recordPayment" />
      <Route path={`${match.url}/:context/view/:id`} exact={true}
        component={createOldInvoiceDetailsRedirect()} />
      <Route path={`${match.url}/:context/view/:id/:ownership`} exact={true}
        component={createOldInvoiceDetailsRedirect()} />
      <Redirect path="*" to="/invoices/list" />
    </Switch>
  );
};

export const BusinessInvoicesRoutes = ({ match }) => {
  const { isPortalExperience } = useSessionInfo();

  return (
    <Switch>
      {/* Redirects */}
      <Route path={match.url} exact={true} render={() => <Redirect to={`${match.url}/list`} />} />
      <Route path={`${match.url}/ng`} component={LegacyReceivablesRoutes} />
      {/* Routes */}
      <Route path={`${match.url}/list`} exact={true} component={InvoicesList} />
      <Route
        path={`${match.url}/create`}
        exact={true}
        component={props => (
          <CreateInvoice invoiceType={InvoiceType.meta.map.Receivable} {...props} />
        )}
      />
      <Route
        path={`${match.url}/edit/:type/:id`}
        exact={true}
        component={props => (
          <CreateInvoice invoiceType={InvoiceType.meta.map.Receivable} {...props} />
        )}
      />
      <Route path={`${match.url}/details/:type/:id`} exact={true} component={InvoiceDetails} />
      <Route path={`${match.url}/vpx`} exact={true} component={GetPaidEarlyListView} />
      <Route path={`${match.url}/recurring`} exact={true} component={RecurringInvoiceList} />
      <Route path={`${match.url}/recordPayment`} exact={true} component={RecordInvoicePayment} />
      {/* Corporate Routes */}
      {ConditionalInAppNotFound(isPortalExperience)}
      <Route
        path={`${match.url}/summary`}
        exact={true}
        component={props => (
          <InvoicesCompanySummary type={SchemaInvoiceType.Receivable} {...props} />
        )}
      />
      <Route path={`${match.url}/qbonline`} exact={true} component={InvoicesQBOnline} />
      {InAppNotFoundView}
    </Switch>
  );
};

export const PersonalInvoicesRoutes = ({ match }) => (
  <Switch>
    {/* Redirects */}
    <Route path={match.url} exact={true} render={() => <Redirect to={`${match.url}/list`} />} />
    {/* Routes */}
    <Route path={`${match.url}/list`} exact={true} component={PersonalPortalInvoicesList} />
    <Route path={`${match.url}/details/:type/:id`} exact={true} component={InvoiceDetails} />
    {InAppNotFoundView}
  </Switch>
);