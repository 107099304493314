/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

export default function Parameter(props) {
  if (!props.name || !props.hasOwnProperty('value')) {
    throw new Error('Workflow Parameters require a name and value.');
  }

  return {
    workflowType: 'Parameter',
    name: props.name,
    value: props.value
  };
}
