/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { createApiAction } from 'api/core';

import EntityContext from 'config/constants/schemas/EntityContext';

const updateUserProfile = createApiAction({
  method: 'patch',
  url: '/api/user-profiles/current',
  requestBodyMapper: ({
    firstName,
    lastName,
    email,
    confirmPassword,
    oldPassword,
    password,
    securityQuestions
  }) => {
    let patchObject = {};

    if (firstName) {
      patchObject.firstName = firstName;
    }
    if (lastName) {
      patchObject.lastName = lastName;
    }
    if (email) {
      patchObject.emails = [{email: email}];
    }
    if (password && oldPassword && confirmPassword) {
      patchObject.confirmPassword = confirmPassword;
      patchObject.oldPassword = oldPassword;
      patchObject.password = password;
    }
    if (securityQuestions) {
      patchObject.securityQuestions = securityQuestions;
    }

    return patchObject;
  },
  responseMapper: () => ({
    updated: {
      contexts: [ EntityContext.Identity ]
    }
  })
});

export default updateUserProfile;