/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

function MapStateToProps(state, props) {
  return {
    isMobile: state.deviceInfo.isMobile,
    isTablet: state.deviceInfo.isTablet
  };
}

function MapDispatchToProps(dispatch, props) {
  return {
    onUpdateIsMobile: isMobile => dispatch({
      type: 'DeviceInfoService_isMobile',
      isMobile
    }),
    onUpdateIsTablet: isTablet => dispatch({
      type: 'DeviceInfoService_isTablet',
      isTablet
    })
  };
}

export {
  MapStateToProps,
  MapDispatchToProps
};
