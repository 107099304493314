/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { isFunction, isNil, isObject } from 'lodash';
import SortButton from 'components/SortButton';
import Table from 'components/Viewstrap/Table';
import TableColumn from 'components/Viewstrap/Table/Column';
import TableRow from 'components/Viewstrap/Table/Row';
import { VelocityTransitionGroup } from 'velocity-react';
import FormJSX from 'components/Form/FormJSX';
import FieldOption from 'components/Form/FieldOption';
import './index.scss';
import HorizontalScrollBox from 'components/HorizontalScrollBox';
import ComponentWithConfigurationChildren from '../ComponentWithConfigurationChildren';
import TableViewColumn from './TableColumn';

export { TableViewColumn };

/**
  Generates a table from an array of objects. See <TableViewColumn> for defining columns in this component.
*/
class TableView extends ComponentWithConfigurationChildren {
  static propTypes = {
    /** An array of objects to render rows for in the table */
    items: PropTypes.array,
    /** style types for the table */
    variant: PropTypes.oneOf(['default', 'listview', 'subtable']),
    /** Fires when the item row is clicked (not additional rows) */
    onRowClicked: PropTypes.func,

    /** A renderer for an additional row in the table AFTER each item row  */
    renderAdditionalRows: PropTypes.func,
    /** A renderer for an additional row in the table BEFORE each item row  */
    renderAdditionalRowsBefore: PropTypes.func,

    additionalRowClasses: PropTypes.string,

    /** Fires when a sortable column is clicked (to toggle the current sort) */
    onSort: PropTypes.func,

    /** Form: indicates which rows in the table are editable (will render form fields for columns with fieldOption) */
    editableRows: PropTypes.arrayOf(PropTypes.number),
    /** Form: Returns a form schema for the current row */
    editableRowSchema: PropTypes.func,
    rowClasses: PropTypes.func,
    /** Form: Fires when an editable row changes one of the item values. Returns an updates items array. Note: items prop itself is used as value */
    onChange: PropTypes.func,

    /** Enables/disables horizontal table scrolling at smaller widths */
    responsiveScrolling: PropTypes.bool,

    /** Optionally provide a card that will be shown as the full width of the table in between two rows */
    advertisementCard: PropTypes.node,

    /** Key override field in case there is an issue with using the index. Will append the override field to the index (i.e. 1-[keyOverride]) */
    keyOverride: PropTypes.string
  };

  static defaultProps = {
    items: [],
    onRowClicked: (rowIndex) => {},
    variant: 'default',
    responsiveScrolling: true
  };

  constructor(props) {
    super(props);

    this.renderItemRow = this.renderItemRow.bind(this);
    this.onFormChange = this.onFormChange.bind(this);
    this.getValue = this.getValue.bind(this);
    this.formRefs = {};
  }

  isConfigurationChild(childType, childProps) {
    return childType === TableViewColumn;
  }

  renderHeader(child, index) {
    let header = typeof child.props.header === 'function'
      ? child.props.header(this.props.items) // support header being a func of items too
      : child.props.header;
    let sortField = child.props.sortField;
    let canSort = isNil(this.props.isSortable) || this.props.isSortable;
    let isSortable = canSort && child.props.isSortable;
    let sortInfoField = (canSort && this.props.sortInfo
      && this.props.sortInfo.length
      && this.props.sortInfo[0].name === sortField)
      ? this.props.sortInfo[0] : null;
    return (
      <TableColumn key={index} isHeader={true}
        className={classNames(child.props.className,
          {'selected-sort-header': sortInfoField},
          {'sortable-header': isSortable})}
        style={child.props.style}>
        {sortField && isSortable && this.props.onSort && this.props.sortInfo
          ? <SortButton sortField={sortInfoField}
            onChange={(direction) => {
              this.props.onSort([{
                name: sortField,
                isDescending: direction === SortButton.DESCENDING
              }]);
            }}>
            {header}
          </SortButton>
          : header}
      </TableColumn>
    );
  }

  renderHeaderRow() {
    let columns = [];
    this.getConfigurationChildren().map((child, index) => {
      columns.push(this.renderHeader(child, index));
    });

    return (
      <TableRow className={`tableview-row-cols-${columns.length}`}>
        {columns}
      </TableRow>
    );
  }

  renderFooter(child, index) {
    return (
      <TableColumn key={index}
        className={child.props.className}
        style={child.props.style}>
        {child.props.footer}
      </TableColumn>
    );
  }

  renderFooterRow() {
    if (!this.getConfigurationChildren().some(child => !!child.props.footer)) return null;

    let columns = [];
    this.getConfigurationChildren().map((child, index) => {
      columns.push(
        this.renderFooter(child, index)
      );
    });

    if (columns && columns.length) {
      return (
        <TableRow className={`tableview-row-cols-${columns.length}`}>
          {columns}
        </TableRow>
      );
    }

    return null;
  }

  onFormChange(rowIndex, value) {
    let formStates = [...this.props.items];
    formStates[rowIndex] = {...this.props.items[rowIndex], ...value};

    if (this.props.onChange) {
      this.props.onChange(formStates);
    }
  }

  getValue(row) {
    let isValid = true;
    if (!isNaN(row)) {
      for (let colRef in this.formRefs[row]) {
        if (this.formRefs[row].hasOwnProperty(colRef) && this.formRefs[row][colRef]) {
          isValid = isValid && this.formRefs[row][colRef].getValue();
        }
      }
      if (isValid) {
        return this.props.items[row];
      }
      return null;
    }

    Object.keys(this.formRefs).forEach((ref) => {
      if (this.formRefs.hasOwnProperty(ref)) {
        Object.keys(this.formRefs[ref]).forEach((colRef) => {
          if (this.formRefs[ref].hasOwnProperty(colRef) && this.formRefs[ref][colRef]) {
            isValid = isValid && this.formRefs[ref][colRef].getValue();
          }
        });
      }
    });

    if (isValid) {
      return this.props.items;
    }
    return null;
  }

  onClickCell(clickOptions) {
    if (this.props.onCellClick) {
      this.props.onCellClick(clickOptions);
    }
  }

  renderItemRow(item, rowIndex, items) {
    let editableRows = this.props.editableRows;
    let columnsBelow = [];
    let columnsAbove = [];
    let areAllColumnsAboveNull = true;
    let areAllColumnsBelowNull = true;
    let columns = this.getConfigurationChildren().map((child, index, array) => {
      if (!child.props.renderCell) {
        console.warn(`Missing renderCell prop at index ${index}`);
        return;
      }

      let cell = child.props.renderCell(item, rowIndex, items);

      let fieldOption;
      if (child.props.fieldOption) {
        fieldOption = child.props.fieldOption;
      } else if (child.props.fieldGenerator) {
        fieldOption = child.props.fieldGenerator(item, rowIndex);
      }

      let clickOptions = {
        item,
        rowIndex,
        colIndex: index,
        columns: array
      };

      let aboveCellContent = null;
      if (child.props.renderCellAbove) {
        aboveCellContent = child.props.renderCellAbove(item, rowIndex);
        if (!!aboveCellContent) areAllColumnsAboveNull = false;
      }

      let belowCellContent = null;
      if (child.props.renderCellBelow) {
        belowCellContent = child.props.renderCellBelow(item, rowIndex);
        if (!!belowCellContent) areAllColumnsBelowNull = false;
      }

      columnsAbove.push(
        <TableColumn key={index} className={child.props.className} style={child.props.style}>
          {aboveCellContent}
        </TableColumn>
      );
      columnsBelow.push(
        <TableColumn key={index} className={child.props.className} style={child.props.style}>
          {belowCellContent}
        </TableColumn>
      );

      let itemStyle = null;
      if (typeof child.props.cellStyle === 'function') {
        itemStyle = child.props.cellStyle(item, rowIndex, items);
      }

      return (
        <TableColumn key={index} className={child.props.className}
          style={{
            ...child.props.style,
            ...itemStyle
          }}
          onClick={this.onClickCell.bind(this, clickOptions)}>
          {(!editableRows || editableRows.includes(rowIndex)) && fieldOption
            && fieldOption.type === FieldOption
            ? <FormJSX onChange={this.onFormChange.bind(this, rowIndex)}
              ref={(ref) => {
                if (typeof this.formRefs[rowIndex] === 'undefined') {
                  this.formRefs[rowIndex] = {};
                }
                this.formRefs[rowIndex][index] = ref;
              }}
              value={this.props.items[rowIndex]} modelType={this.props.editableRowSchema(item)}>
              {fieldOption}
            </FormJSX>
            : cell}
        </TableColumn>
      );
    });

    let rowClasses = null;
    if (this.props.rowClasses) {
      rowClasses = this.props.rowClasses(item, rowIndex);
    }

    let rowStyle = {};
    if (this.props.rowStyle) {
      if (isFunction(this.props.rowStyle)) {
        rowStyle = this.props.rowStyle(item, rowIndex);
      } else if (isObject(this.props.rowStyle)) {
        rowStyle = this.props.rowStyle;
      }
    }

    let rows = [];

    // FULL ADDL ABOVE ROW
    if (this.props.renderAdditionalRowsBefore) {
      let additionalRows = [].concat(this.props.renderAdditionalRowsBefore(item, rowIndex));

      rows = rows.concat(additionalRows.map((row, index) => {
        if (!row) return null;
        return (
          <TableRow key={`before-${rowIndex}-${index}`} style={this.props.additionalRowStyle}>
            <TableColumn columnSpan={React.Children.count(this.props.children)}>
              {row}
            </TableColumn>
          </TableRow>
        );
      }));
    }

    const key = this.props.keyOverride ? item[this.props.keyOverride] : rowIndex;

    // COLUMN-SPECIFIC ADDL ABOVE ROW
    if (!areAllColumnsAboveNull) {
      rows.push(
        <TableRow key={`${key}_above`}
          className={classNames(`tableview-row-cols-${columns.length}-above`, rowClasses)}>
          {columnsAbove}
        </TableRow>
      );
    }

    rows = rows.concat([(
      <TableRow key={key}
        className={classNames(`tableview-row-cols-${columns.length}`, rowClasses)}
        style={rowStyle}
        onClick={() => this.props.onRowClicked(item, rowIndex)}
      >
        {columns}
      </TableRow>
    )]);

    // COLUMN-SPECIFIC ADDL BELOW ROW
    if (!areAllColumnsBelowNull) {
      rows.push(
        <TableRow key={`${key}_below`}
          className={classNames(`tableview-row-cols-${columns.length}-below`, rowClasses)}>
          {columnsBelow}
        </TableRow>
      );
    }

    // FULL ADDL BELOW ROW
    if (this.props.renderAdditionalRows) {
      let additionalRows = [].concat(this.props.renderAdditionalRows(item, rowIndex));

      rows = rows.concat(additionalRows.map((row, index) => {
        if (this.props.additionalRowsDisableAnimation && !row) return null;
        const children = this.props.additionalRowsDisableAnimation
          ? row
          : (
            <VelocityTransitionGroup
              enter={{animation: 'slideDown'}}
              leave={{animation: 'slideUp'}}>
              {row}
            </VelocityTransitionGroup>
          );
        // Always render the table row so that we can do the animation of slide in/out
        return (
          <TableRow key={`${rowIndex}-${index}`} style={this.props.additionalRowStyle}
            className={classNames('additional-row', this.props.additionalRowClasses, {
              'hide-row': !row
            })}>
            <TableColumn columnSpan={React.Children.count(this.props.children)}>
              {children}
            </TableColumn>
          </TableRow>
        );
      }));
    }

    return rows;
  }

  renderTheseItems() {
    const numberOfCols = this.getConfigurationChildren().length;
    let rows = this.props.items.map(this.renderItemRow);
    if (!!this.props.advertisementCard) {
      const advertisementRow = (
        <TableRow key="advertisement-card" className="advertisement-card-row">
          <td colSpan={numberOfCols} style={{padding: '12px 0'}}>
            {this.props.advertisementCard}
          </td>
        </TableRow>
      );

      if (rows.length > 3) {
        rows.splice(3, 0, advertisementRow);
      } else {
        rows.push(advertisementRow);
      }
    }

    if (this.props.lastRow) {
      const {
        className: lastRowClassName,
        render: renderLastRow,
        style: lastRowStyle
      } = this.props.lastRow;

      rows = rows.concat(
        <TableRow key="lastRow" className={lastRowClassName} style={lastRowStyle}>
          <TableColumn columnSpan={numberOfCols}>
            {renderLastRow()}
          </TableColumn>
        </TableRow>
      );
    }

    return rows;
  }

  render() {
    let classes = classNames(this.props.variant, this.props.className);

    let table = (
      <Table className={classes}
        header={this.renderHeaderRow()}
        footer={this.renderFooterRow()}>
        {this.renderTheseItems()}
      </Table>
    );

    return this.props.responsiveScrolling
      ? (
        <HorizontalScrollBox>
          {table}
        </HorizontalScrollBox>
      )
      : table;
  }
}

export default TableView;
