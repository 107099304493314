/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { isAcknowledged, isPersonalPortal } from 'actions/common';
import {
  getCompanySettings,
  isCustomerPortalExperience,
  isVendorPortalExperience
} from 'actions/common/companySettings';
import { pushLink } from 'actions/links';
import { getAccountSetupData, GetAccountSetupDataActions } from 'actions/onboarding';
import PathActions from 'actions/paths';
import { setAcknowledgement } from 'actions/settings';

import { LinkRef } from 'config/links';

import launchAccountSetup from 'containers/Modals/AccountSetup/Async';

import useQueryParams from 'hooks/location/useQueryParams';

import { InvoiceType } from 'schemas/invoicing/invoice';

import { useHasNotDoneBigAction, useHasLoggedInOnThisPage } from '../utils';

const BusinessAccountSetupWorkflowStep = ({
  active,
  updateState
}) => {
  // Hooks
  const dispatch = useDispatch();
  const { forceNotification } = useQueryParams([ 'forceNotification' ]);

  const doesNotHaveAck = useSelector(
    (state) => {
      const { hasMoreThanOneUser } = getCompanySettings(state);

      if (hasMoreThanOneUser || isAcknowledged(state, 'accountSetup')) return false;

      return true;
    }
  );
  const hasNotDoneBigAction = useHasNotDoneBigAction();
  const loggedInThisPage = useHasLoggedInOnThisPage();
  const shouldShow = doesNotHaveAck && loggedInThisPage && hasNotDoneBigAction;

  const canShow = useSelector(state => !isPersonalPortal(state));

  const willShow = canShow && (forceNotification === 'accountSetup' || shouldShow);

  const needsToLoad = useSelector(
    state => !GetAccountSetupDataActions.hasCacheBeenCalled(state)
  );

  const loadData = async () => {
    const [ success ] = await dispatch(GetAccountSetupDataActions.dispatchApiCall());
    updateState(success ? 'loaded' : 'completed');
  };

  useEffect(
    () => {
      if (!willShow) {
        updateState('skipped');
      } else if (needsToLoad) {
        // useEffect doesn't like being passed an async function - hence why this broken out
        loadData();
      } else {
        updateState('loaded');
      }
    },
    [ willShow ]
  );

  const [
    redirectAction,
    accountSetupMetadata,
    markAccountSetupDoneOnRedirect
  ] = useSelector(
    (state) => {
      const { invitationDocuments } = getAccountSetupData(state) || {};

      if (invitationDocuments?.length) {
        const {
          type: documentType,
          sourceCompany,
          documentId
        } = invitationDocuments[0];

        if (documentType === 'Statement') {
          return [
            pushLink(LinkRef.bills.statementDetails, {
              companyId: sourceCompany.id
            }),
            null,
            true
          ];
        }

        if (invitationDocuments.length === 1) {
          if (documentType === 'Bill') {
            return [
              pushLink(LinkRef.invoices.details, { // their bill -> my invoice
                invoiceId: documentId,
                invoiceType: InvoiceType.meta.map.Payable,
                isMine: false
              }),
              {
                invoiceId: documentId,
                invoiceType: InvoiceType.meta.map.Payable
              },
              true
            ];
          }

          if (documentType === 'Invoice') {
            return [
              pushLink(LinkRef.bills.details, { // their invoice -> my bill
                invoiceId: documentId,
                invoiceType: InvoiceType.meta.map.Receivable,
                isMine: false
              }),
              {
                invoiceId: documentId,
                invoiceType: InvoiceType.meta.map.Receivable
              },
              true
            ];
          }
        } else {
          if (documentType === 'Bill') {
            return [
              pushLink(LinkRef.invoices.list), // their bills -> my invoices
              null,
              true
            ];
          }

          if (documentType === 'Invoice') {
            return [
              pushLink(LinkRef.bills.list),
              null,
              true
            ];
          }
        }
      }

      // Send portal users off to their respective dashboards and
      // let the account setup wrapper sort them out
      if (isVendorPortalExperience(state)) {
        return [ PathActions.Payments.Received() ];
      }

      if (isCustomerPortalExperience(state)) {
        return [ PathActions.Bills.List() ];
      }

      return [ pushLink(LinkRef.dashboard.index) ];
    }
  );

  const execute = async () => {
    dispatch({ type: 'HasDoneOncePerSessionAction' });
    dispatch(redirectAction);
    dispatch({ type: 'HasBeenRedirected', source: 'BusinessAccountSetup' });

    if (markAccountSetupDoneOnRedirect) {
      dispatch(setAcknowledgement('accountSetup'));
      updateState('completed');
      return;
    }


    await dispatch(launchAccountSetup(accountSetupMetadata));
    updateState('completed');
  };

  useEffect(
    () => {
      if (willShow && active) {
        // useEffect doesn't like being passed an async function - hence why this broken out
        execute();
      }
    },
    [ active ]
  );

  // Render
  return <></>;
};

export default BusinessAccountSetupWorkflowStep;
