/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { get } from 'lodash';
import { isFailAction, isFetchAction, isSuccessAction } from 'api/core/utils';
import { CorrelationId } from 'config/constants/invoice';
import { InvoiceActions } from 'config/constants';
import AddressBookRelationships from 'config/constants/network/AddressBookRelationships';
import RetrievableAddressBookEntry from 'schemas/network/RetrievableAddressBookEntry';
import { newState } from '../utils';
import Qbo from './qbo';

export { Qbo };

export function ReceivablesListView(state = {}, action) {
  if (isFetchAction(action, 'getInvoicesList')) {
    return newState(state, {
      isLoading: true
    });
  }

  if (isSuccessAction(action, 'getInvoicesList')) {
    return newState(state, {
      invoices: action.payload.references,
      pagination: action.payload.pagination,
      dateField: action.meta.params.dateField,
      isLoading: false
    });
  }

  return state;
}

export function InvoicesCompanySummary(state = {}, action) {
  if (isFetchAction(action, 'getInvoicesCompanySummary')) {
    return newState(state, {
      isLoading: true
    });
  }

  if (isSuccessAction(action, 'getInvoicesCompanySummary')) {
    return newState(state, {
      summaries: action.payload.references,
      isLoading: false
    });
  }

  return state;
}

export function RecurringInvoiceListView(state = {}, action) {
  if (isFetchAction(action, 'getRecurringInvoicesList')) {
    return newState(state, {
      isLoading: true
    });
  }

  if (isFailAction(action, 'getRecurringInvoicesList')) {
    return newState(state, {
      isLoading: false
    });
  }

  if (isSuccessAction(action, 'getRecurringInvoicesList')) {
    return newState(state, {
      recurrences: action.payload.references,
      pagination: action.payload.pagination,
      isLoading: false
    });
  }

  return state;
}

export function InvoiceVpxListView(state = {}, action) {

  if (isFetchAction(action, 'getInvoicesEarlyPay')) {
    return newState(state, {
      isLoading: true
    });
  }

  if (isFailAction(action, 'getInvoicesEarlyPay')) {
    return newState(state, {
      isLoading: false
    });
  }

  // When the request vpx endpoint is queried, show the loading state
  if (isFetchAction(action, 'earlyPayRequest')) {
    return newState(state, {
      isLoading: true
    });
  }

  // When the cancel vpx endpoint is queried, show the loading state
  if (isFetchAction(action, 'earlyPayCancel')) {
    return newState(state, {
      isLoading: true
    });
  }

  if (isSuccessAction(action, 'getInvoicesEarlyPay')) {
    return newState(state, {
      invoices: action.payload.references,
      pagination: action.payload.pagination,
      isLoading: false
    });
  }

  return state;
}

export function BillVpxListView(state = {}, action) {

  if (isFetchAction(action, 'getBills') && action.meta.correlationId === 'EarlyPayOffers') {
    return newState(state, {
      isLoading: true
    });
  }

  if (isSuccessAction(action, 'getBills') && action.meta.correlationId === 'EarlyPayOffers') {
    return newState(state, {
      invoices: action.payload.references,
      pagination: action.payload.pagination,
      isLoading: false
    });
  }

  return state;
}

export function BillsListView(state = {}, action) {

  if (isFetchAction(action, 'getBills')
    || isFetchAction(action, 'getInboxSettings')) {
    return newState(state, {
      isLoading: true
    });
  }

  if (isSuccessAction(action, 'getInboxSettings')) {
    return newState(state, {
      isLoading: false
    });
  }

  if (isSuccessAction(action, 'getBills')) {
    return newState(state, {
      payableReferences: action.payload.references,
      pagination: action.payload.pagination,
      dateField: action.meta.params.dateField,
      isLoading: false
    });
  }

  if (isFetchAction(action, 'submitInvoicePayment')) {
    return newState(state, {
      isPaymentProcessing: true
    });
  }

  if (isFailAction(action, 'submitInvoicePayment')) {
    return newState(state, {
      isPaymentProcessing: false
    });
  }

  if (isSuccessAction(action, 'submitInvoicePayment')) {
    return newState(state, {
      isPaymentProcessing: false
    });
  }

  return state;
}

export function InvoiceDetailView(state = {}, action) {

  if (isFetchAction(action, 'getOutOfBandInvoice')
    || isFetchAction(action, 'getRecurringInvoicePreview')
    || isFetchAction(action, 'getInvoice')) {
    if (!action.payload.params.loadInBackground) {
      return newState(state, {
        loading: true,
        invoice: null,
        failure: null
      });
    }
  }

  if (isSuccessAction(action, 'getOutOfBandInvoice')
    || isSuccessAction(action, 'getInvoice')
    || isSuccessAction(action, 'getRecurringInvoicePreview')) {
    return newState(state, {
      loading: false,
      invoice: action.payload.references[0]
    });
  }

  if (isFailAction(action, 'getInvoice')) {
    return newState(state, {
      loading: false,
      failure: get(action, 'payload.entities[0].value.type', null)
    });
  }

  if (isSuccessAction(action, 'getAttachments')) {
    return newState(state, {
      attachments: action.payload.references
    });
  }

  if (isSuccessAction(action, 'getInvoiceHistory')) {
    return newState(state, {
      historyItems: action.payload.historyItems
    });
  }

  if (isSuccessAction(action, 'getInvoiceComments')) {
    return newState(state, {
      comments: action.payload.comments
    });
  }

  if (isSuccessAction(action, 'getInvoiceApprovalRequests')) {
    return newState(state, {
      approvalRequests: action.payload.references
    });
  }

  if (isSuccessAction(action, 'getInvoiceApprovalRecipients')) {
    return newState(state, {
      approvalRecipients: action.payload.recipients
    });
  }

  return state;
}

export function EditOutOfBandInvoice(state = {}, action) {

  if (isFetchAction(action, 'getOutOfBandInvoice')) {
    return newState(state, {
      loading: true
    });
  }

  if (isSuccessAction(action, 'getOutOfBandInvoice')) {
    return newState(state, {
      loading: false,
      invoice: action.payload.references[0]
    });
  }

  if (isSuccessAction(action, 'getAttachments')) {
    return newState(state, {
      attachments: action.payload.references
    });
  }

  return state;
}

export function InvoiceListActionNav(state = {}, action) {
  if (isFetchAction(action, 'invoiceSearchForInvoiceList')) {
    return newState(state, {
      isLoadingInvoiceSearch: true
    });
  }

  if (isSuccessAction(action, 'invoiceSearchForInvoiceList')) {
    return newState(state, {
      invoiceSearchResults: action.payload.references,
      isLoadingInvoiceSearch: false
    });
  }

  if (isSuccessAction(action, 'statusLoadForInvoiceList')) {
    return newState(state, {
      statuses: action.payload.references
    });
  }

  if (isSuccessAction(action, 'getCompanies')
    && action.meta.correlationId === CorrelationId.NetworkCompanies) {
    return newState(state, {
      networkCompanies: (state.companies && action.meta.params.page > 1)
        ? [...state.companies, ...action.payload.references] : action.payload.references
    });
  }

  return state;
}

export function InvoiceCompanySummaryActionNav(state = {}, action) {
  if (isFetchAction(action, 'invoiceSearchForInvoiceList')) {
    return newState(state, {
      isLoadingInvoiceSearch: true
    });
  }

  if (isSuccessAction(action, 'invoiceSearchForInvoiceList')) {
    return newState(state, {
      invoiceSearchResults: action.payload.references,
      isLoadingInvoiceSearch: false
    });
  }

  if (isSuccessAction(action, 'statusLoadForInvoiceList')) {
    return newState(state, {
      statuses: action.payload.references
    });
  }

  if (isSuccessAction(action, 'getCompanies')
    && action.meta.correlationId === CorrelationId.NetworkCompanies) {
    return newState(state, {
      networkCompanies: (state.companies && action.meta.params.page > 1)
        ? [...state.companies, ...action.payload.references] : action.payload.references
    });
  }

  return state;
}

export function BillStatementsListView(state = {}, action) {
  if (isFetchAction(action, 'getBillStatements')
    || isFetchAction(action, 'getBillStatementDetails')) {
    return newState(state, {
      isLoading: true
    });
  }

  if (isSuccessAction(action, 'getBillStatements')) {
    return newState(state, {
      statements: action.payload.additionalInfo.statements,
      isLoading: false
    });
  }

  if (isSuccessAction(action, 'getBillStatementDetails')) {
    return newState(state, {
      statements: action.payload.additionalInfo.statements,
      isLoading: false
    });
  }

  if (isFailAction(action, 'getBillStatements')
    || isFailAction(action, 'getBillStatementDetails')) {
    return newState(state, {
      statements: [],
      isLoading: false
    });
  }

  if (isSuccessAction(action, 'getCompanies')) {
    let firstVendorAddressBookId = null;
    if (action.payload.entities) {
      let firstVendorAddressBook = action.payload.entities.find((e) => {
        // find the first vendor
        return e.type === RetrievableAddressBookEntry.meta.name
          && e.value
          && e.value.relationship === AddressBookRelationships.Vendor;
      });
      if (firstVendorAddressBook) {
        firstVendorAddressBookId = firstVendorAddressBook.id;
      }
    }
    return newState(state, {
      firstVendorAddressBookId
    });
  }

  if (isFetchAction(action, 'getCompanySettings')) {
    return newState(state, {
      companySettings: null
    });
  }

  if (isSuccessAction(action, 'getCompanySettings')) {
    return newState(state, {
      companySettings: action.payload.references[0]
    });
  }

  if (isFetchAction(action, 'getBankAccounts')) {
    return newState(state, {
      isLoadingAccounts: true
    });
  }

  if (isSuccessAction(action, 'getBankAccounts')) {
    return newState(state, {
      isLoadingAccounts: false
    });
  }

  return state;
}

export function BillsInboxActionNav(state = {}, action) {
  if (isFetchAction(action, 'invoiceSearchForBillsInbox')) {
    return newState(state, {
      isLoadingInvoiceSearch: true
    });
  }

  if (isSuccessAction(action, 'invoiceSearchForBillsInbox')) {
    return newState(state, {
      invoiceSearchResults: action.payload.references,
      isLoadingInvoiceSearch: false
    });
  }

  if (isSuccessAction(action, 'statusLoadForBillsInbox')) {
    return newState(state, {
      statuses: action.payload.references
    });
  }

  if (isSuccessAction(action, 'getCompanies')
    && action.meta.correlationId === CorrelationId.NetworkCompanies) {
    return newState(state, {
      networkCompanies: (state.companies && action.meta.params.page > 1)
        ? [...state.companies, ...action.payload.references] : action.payload.references
    });
  }

  return state;
}

export function BillsListActionNav(state = {}, action) {
  if (isFetchAction(action, 'invoiceSearchForBillsList')) {
    return newState(state, {
      isLoadingInvoiceSearch: true
    });
  }

  if (isSuccessAction(action, 'invoiceSearchForBillsList')) {
    return newState(state, {
      invoiceSearchResults: action.payload.references,
      isLoadingInvoiceSearch: false
    });
  }

  if (isSuccessAction(action, 'statusLoadForBillsList')) {
    return newState(state, {
      statuses: action.payload.references
    });
  }

  if (isSuccessAction(action, 'getCompanies')
    && action.meta.correlationId === CorrelationId.NetworkCompanies) {
    return newState(state, {
      networkCompanies: (state.companies && action.meta.params.page > 1)
        ? [...state.companies, ...action.payload.references] : action.payload.references
    });
  }

  return state;
}

export function BillsCompanySummaryActionNav(state = {}, action) {
  if (isFetchAction(action, 'invoiceSearchForBillsList')) {
    return newState(state, {
      isLoadingInvoiceSearch: true
    });
  }

  if (isSuccessAction(action, 'invoiceSearchForBillsList')) {
    return newState(state, {
      invoiceSearchResults: action.payload.references,
      isLoadingInvoiceSearch: false
    });
  }

  if (isSuccessAction(action, 'statusLoadForBillsList')) {
    return newState(state, {
      statuses: action.payload.references
    });
  }

  if (isSuccessAction(action, 'getCompanies')
    && action.meta.correlationId === CorrelationId.NetworkCompanies) {
    return newState(state, {
      networkCompanies: (state.companies && action.meta.params.page > 1)
        ? [...state.companies, ...action.payload.references] : action.payload.references
    });
  }

  return state;
}

export function CreateInvoice(state = {}, action) {
  if (isFetchAction(action, 'invoiceTotalCalculation')) {
    return newState(state, {
      calculatingTotals: true,
      totalAmount: null,
      subtotal: null,
      taxAmount: null,
      shipAmount: null
    });
  }

  if (isFailAction(action, 'invoiceTotalCalculation')) {
    return newState(state, {
      calculatingTotals: false
    });
  }

  if (isSuccessAction(action, 'invoiceTotalCalculation')) {
    return newState(state, {
      calculatingTotals: false,
      totalAmount: action.payload.totalAmount,
      subtotal: action.payload.subtotal,
      taxAmount: action.payload.taxAmount,
      lineItems: action.payload.lineItems,
      shipAmount: action.payload.shipAmount
    });
  }

  if (isFetchAction(action, 'getInvoiceSettings')
    || isFailAction(action, 'getInvoiceSettings')) {
    return newState(state, {
      isInvoiceReceiptDisabled: false
    });
  }

  if (isSuccessAction(action, 'getInvoiceSettings')) {
    return newState(state, {
      isInvoiceReceiptDisabled: action.payload.isInvoiceReceiptDisabled
    });
  }

  if (isFetchAction(action, 'editInvoice')
    || isFetchAction(action, 'invoices')
    || isFetchAction(action, 'updateInvoiceRecurrence')) {
    return newState(state, {
      isInvoiceSaving: true
    });
  }

  if (isFailAction(action, 'editInvoice')
    || isFailAction(action, 'invoices')
    || isFailAction(action, 'updateInvoiceRecurrence')) {
    return newState(state, {
      isInvoiceSaving: false
    });
  }

  if (isSuccessAction(action, 'editInvoice')
    || isSuccessAction(action, 'invoices')
    || isSuccessAction(action, 'updateInvoiceRecurrence')) {
    return newState(state, {
      isInvoiceSaving: false
    });
  }

  return state;
}

export function EarlyPaySidebarPanel(state = {}, action) {

  if (isSuccessAction(action, 'getEarlyPayDiscounts')) {
    return newState(state, {
      discounts: action.payload.discounts,
      discountsByType: action.payload.discountsByType
    });
  }

  return state;
}

export function RecordPayment(state = {}, action) {
  if (action.type === InvoiceActions.RecordPayment) {
    return newState(state, {
      invoices: action.invoices
    });
  }

  if (isSuccessAction(action, 'getCompany')) {
    return newState(state, {
      addressBook: action.payload.references[0]
    });
  }

  if (isSuccessAction(action, 'getInvoicesList')
    || isSuccessAction(action, 'getBills')) {
    return newState(state, {
      invoicePage: action.payload.references,
      pagination: action.payload.pagination
    });
  }

  return state;
}
