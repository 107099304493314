/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, { useEffect, useMemo, useState } from 'react';
import { IntlProvider } from 'react-intl';

import { logWarning } from 'services/ErrorService';

const acceptLanguageToLanguages = (value) => {
  if (!value) return;
  // Example Accept-Language: en-US,en;q=0.5
  return value.split(',').map(v => v.split(';')[0]);
};

const DefaultRichTextElements = {
  b: chunks => <span className="type-strong">{chunks}</span>
};

const createLanguageService = (MessagesConfig) => {
  const LanguageService = ({
    acceptLanguage: rawAcceptLanguage,
    children
  }) => {
    // Hooks
    const [
      acceptLanguages,
      setAcceptLanguages
    ] = useState(
      () => acceptLanguageToLanguages(rawAcceptLanguage) || ['en']
    );

    useEffect(
      () => {
        setAcceptLanguages(
          prevValue => acceptLanguageToLanguages(rawAcceptLanguage) || prevValue
        );
      },
      [ rawAcceptLanguage ]
    );

    const {
      locale,
      messages
    } = useMemo(
      () => {
        const matchedLanguage = acceptLanguages
          .find(lang => !!MessagesConfig[lang]);

        if (matchedLanguage) {
          return {
            locale: matchedLanguage,
            messages: MessagesConfig[matchedLanguage]
          };
        }

        logWarning(
          'LanguageService: No matched locales, falling back to en. Accept Languages: ',
          ...acceptLanguages
        );

        return { locale: 'en', messages: MessagesConfig.en || {} };
      },
      [ acceptLanguages ]
    );

    // Render
    return (
      <IntlProvider
        defaultRichTextElements={DefaultRichTextElements}
        locale={locale}
        messages={messages}
      >
        {children}
      </IntlProvider>
    );
  };

  return LanguageService;
};

export default createLanguageService;