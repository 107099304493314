/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, { useMemo } from 'react';
import classNames from 'classnames';
import { shallowEqual, useSelector } from 'react-redux';

import { getUseAppAuthorizationStatus } from 'actions/common/isAuthorizedToUseApp';

import { SmallBannerNotification, NotificationStyleTypes } from 'components/Notification';

import { Sad as SadErnie } from 'config/images/ernie';
import CommonMessages from 'config/messages/common';

import AccessDenied from 'containers/Logon/AccessDenied';
import LogonWorkflow from 'containers/Logon/LogonWorkflow';

import useIsMobile from 'hooks/browser/useIsMobile';
import useQueryParams from 'hooks/location/useQueryParams';

import { UseAppAuthorizationReason } from 'schemas/session/UseAppAuthorizationStatus';

import Messages from './index.messages';
import './index.scss';

const SessionExpiredNotification = () => {
  // Hooks
  const isMobile = useIsMobile();

  // Render
  return (
    <div
      className={classNames(
        'login-session-expired',
        { 'text-small': isMobile }
      )}
    >
      <Messages.ExpiredText1.Message />
      <br/>
      <Messages.ExpiredText2.Message />
      <br/><br/>
      <Messages.ExpiredText3.Message
        email={(
          <span className="type-strong">
            <CommonMessages.CustomerSupportEmail.Message />
          </span>
        )}
      />
    </div>
  );
};

const LogonWorkflowView = ({
  invitationId,
  isOnAppRoute,
  showResetPassword
}) => {
  // Hooks
  const {
    changeEmailToken,
    reason,
    redirectPath
  } = useQueryParams([
    'changeEmailToken',
    'reason',
    'redirectPath'
  ]);

  const appAuthorizationStatus = useSelector(
    state => getUseAppAuthorizationStatus(state),
    shallowEqual
  );

  const logonAlert = useMemo(
    () => {
      let authenticationAlert = null;

      switch (appAuthorizationStatus?.reason) {
        case UseAppAuthorizationReason.EmailVerificationRequired:
          authenticationAlert = (
            <SmallBannerNotification type={NotificationStyleTypes.Info} className="margin-8-bottom">
              <Messages.VerifyAccount.Message />
            </SmallBannerNotification>
          );
          break;
        case UseAppAuthorizationReason.SessionExpired:
          authenticationAlert = <SessionExpiredNotification />;
          break;
        case UseAppAuthorizationReason.SessionKicked:
          authenticationAlert = (
            <SmallBannerNotification type={NotificationStyleTypes.Warning} className="margin-8-bottom">
              <Messages.SessionKicked.Message />
            </SmallBannerNotification>
          );
          break;
        case UseAppAuthorizationReason.VerifyAccount:
          authenticationAlert = (
            <SmallBannerNotification type={NotificationStyleTypes.Info} className="margin-8-bottom">
              <Messages.VerifyAccount.Message />
            </SmallBannerNotification>
          );
          break;
        default: break;
      }

      return (
        <>
          {authenticationAlert}
          {changeEmailToken ? (
            <SmallBannerNotification type={NotificationStyleTypes.Info} className="margin-8-bottom">
              <Messages.VerifyChangeEmail.Message />
            </SmallBannerNotification>
          ) : null}
          {isOnAppRoute ? (
            <SmallBannerNotification type={NotificationStyleTypes.Info} className="margin-8-bottom">
              <Messages.OnAppRoute.Message />
            </SmallBannerNotification>
          ) : null}
        </>
      );
    },
    [
      appAuthorizationStatus?.reason,
      changeEmailToken
    ]
  );

  // Render
  if ((reason || '').toLowerCase() === 'companyclosed') {
    <AccessDenied
      ernieImage={SadErnie}
      submitMessage={<CommonMessages.Continue.Message/>}
    >
      <Messages.CompanyClosed.Message />
    </AccessDenied>;
  }

  return (
    <LogonWorkflow
      changeEmailToken={changeEmailToken}
      invitationId={invitationId}
      isOnAppRoute={isOnAppRoute}
      logonAlert={logonAlert}
      redirect={redirectPath}
      resetPasswordMethod="link"
      showResetPassword={showResetPassword}
    />
  );
};

export default LogonWorkflowView;
