/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React from 'react';

import IconXad from 'images/icons/flags/ad.png';
import IconXae from 'images/icons/flags/ae.png';
import IconXaf from 'images/icons/flags/af.png';
import IconXag from 'images/icons/flags/ag.png';
import IconXai from 'images/icons/flags/ai.png';
import IconXal from 'images/icons/flags/al.png';
import IconXam from 'images/icons/flags/am.png';
import IconXan from 'images/icons/flags/an.png';
import IconXao from 'images/icons/flags/ao.png';
import IconXar from 'images/icons/flags/ar.png';
import IconXas from 'images/icons/flags/as.png';
import IconXat from 'images/icons/flags/at.png';
import IconXau from 'images/icons/flags/au.png';
import IconXaw from 'images/icons/flags/aw.png';
import IconXax from 'images/icons/flags/ax.png';
import IconXaz from 'images/icons/flags/az.png';
import IconXba from 'images/icons/flags/ba.png';
import IconXbb from 'images/icons/flags/bb.png';
import IconXbd from 'images/icons/flags/bd.png';
import IconXbe from 'images/icons/flags/be.png';
import IconXbf from 'images/icons/flags/bf.png';
import IconXbg from 'images/icons/flags/bg.png';
import IconXbh from 'images/icons/flags/bh.png';
import IconXbi from 'images/icons/flags/bi.png';
import IconXbj from 'images/icons/flags/bj.png';
import IconXbm from 'images/icons/flags/bm.png';
import IconXbn from 'images/icons/flags/bn.png';
import IconXbo from 'images/icons/flags/bo.png';
import IconXbr from 'images/icons/flags/br.png';
import IconXbs from 'images/icons/flags/bs.png';
import IconXbt from 'images/icons/flags/bt.png';
import IconXbv from 'images/icons/flags/bv.png';
import IconXbw from 'images/icons/flags/bw.png';
import IconXby from 'images/icons/flags/by.png';
import IconXbz from 'images/icons/flags/bz.png';
import IconXca from 'images/icons/flags/ca.png';
import IconXcc from 'images/icons/flags/cc.png';
import IconXcd from 'images/icons/flags/cd.png';
import IconXcf from 'images/icons/flags/cf.png';
import IconXcg from 'images/icons/flags/cg.png';
import IconXch from 'images/icons/flags/ch.png';
import IconXci from 'images/icons/flags/ci.png';
import IconXck from 'images/icons/flags/ck.png';
import IconXcl from 'images/icons/flags/cl.png';
import IconXcm from 'images/icons/flags/cm.png';
import IconXcn from 'images/icons/flags/cn.png';
import IconXco from 'images/icons/flags/co.png';
import IconXcr from 'images/icons/flags/cr.png';
import IconXcs from 'images/icons/flags/cs.png';
import IconXcu from 'images/icons/flags/cu.png';
import IconXcv from 'images/icons/flags/cv.png';
import IconXcx from 'images/icons/flags/cx.png';
import IconXcy from 'images/icons/flags/cy.png';
import IconXcz from 'images/icons/flags/cz.png';
import IconXde from 'images/icons/flags/de.png';
import IconXdj from 'images/icons/flags/dj.png';
import IconXdk from 'images/icons/flags/dk.png';
import IconXdm from 'images/icons/flags/dm.png';
import IconXdo from 'images/icons/flags/do.png';
import IconXdz from 'images/icons/flags/dz.png';
import IconXec from 'images/icons/flags/ec.png';
import IconXee from 'images/icons/flags/ee.png';
import IconXeg from 'images/icons/flags/eg.png';
import IconXeh from 'images/icons/flags/eh.png';
import IconXer from 'images/icons/flags/er.png';
import IconXes from 'images/icons/flags/es.png';
import IconXet from 'images/icons/flags/et.png';
import IconXfi from 'images/icons/flags/fi.png';
import IconXfj from 'images/icons/flags/fj.png';
import IconXfk from 'images/icons/flags/fk.png';
import IconXfm from 'images/icons/flags/fm.png';
import IconXfo from 'images/icons/flags/fo.png';
import IconXfr from 'images/icons/flags/fr.png';
import IconXga from 'images/icons/flags/ga.png';
import IconXgb from 'images/icons/flags/gb.png';
import IconXgd from 'images/icons/flags/gd.png';
import IconXge from 'images/icons/flags/ge.png';
import IconXgf from 'images/icons/flags/gf.png';
import IconXgh from 'images/icons/flags/gh.png';
import IconXgi from 'images/icons/flags/gi.png';
import IconXgl from 'images/icons/flags/gl.png';
import IconXgm from 'images/icons/flags/gm.png';
import IconXgn from 'images/icons/flags/gn.png';
import IconXgp from 'images/icons/flags/gp.png';
import IconXgq from 'images/icons/flags/gq.png';
import IconXgr from 'images/icons/flags/gr.png';
import IconXgs from 'images/icons/flags/gs.png';
import IconXgt from 'images/icons/flags/gt.png';
import IconXgu from 'images/icons/flags/gu.png';
import IconXgw from 'images/icons/flags/gw.png';
import IconXgy from 'images/icons/flags/gy.png';
import IconXhk from 'images/icons/flags/hk.png';
import IconXhm from 'images/icons/flags/hm.png';
import IconXhn from 'images/icons/flags/hn.png';
import IconXhr from 'images/icons/flags/hr.png';
import IconXht from 'images/icons/flags/ht.png';
import IconXhu from 'images/icons/flags/hu.png';
import IconXid from 'images/icons/flags/id.png';
import IconXie from 'images/icons/flags/ie.png';
import IconXil from 'images/icons/flags/il.png';
import IconXin from 'images/icons/flags/in.png';
import IconXio from 'images/icons/flags/io.png';
import IconXiq from 'images/icons/flags/iq.png';
import IconXir from 'images/icons/flags/ir.png';
import IconXis from 'images/icons/flags/is.png';
import IconXit from 'images/icons/flags/it.png';
import IconXjm from 'images/icons/flags/jm.png';
import IconXjo from 'images/icons/flags/jo.png';
import IconXjp from 'images/icons/flags/jp.png';
import IconXke from 'images/icons/flags/ke.png';
import IconXkg from 'images/icons/flags/kg.png';
import IconXkh from 'images/icons/flags/kh.png';
import IconXki from 'images/icons/flags/ki.png';
import IconXkm from 'images/icons/flags/km.png';
import IconXkn from 'images/icons/flags/kn.png';
import IconXkp from 'images/icons/flags/kp.png';
import IconXkr from 'images/icons/flags/kr.png';
import IconXkw from 'images/icons/flags/kw.png';
import IconXky from 'images/icons/flags/ky.png';
import IconXkz from 'images/icons/flags/kz.png';
import IconXla from 'images/icons/flags/la.png';
import IconXlb from 'images/icons/flags/lb.png';
import IconXlc from 'images/icons/flags/lc.png';
import IconXli from 'images/icons/flags/li.png';
import IconXlk from 'images/icons/flags/lk.png';
import IconXlr from 'images/icons/flags/lr.png';
import IconXls from 'images/icons/flags/ls.png';
import IconXlt from 'images/icons/flags/lt.png';
import IconXlu from 'images/icons/flags/lu.png';
import IconXlv from 'images/icons/flags/lv.png';
import IconXly from 'images/icons/flags/ly.png';
import IconXma from 'images/icons/flags/ma.png';
import IconXmc from 'images/icons/flags/mc.png';
import IconXmd from 'images/icons/flags/md.png';
import IconXme from 'images/icons/flags/me.png';
import IconXmg from 'images/icons/flags/mg.png';
import IconXmh from 'images/icons/flags/mh.png';
import IconXmk from 'images/icons/flags/mk.png';
import IconXml from 'images/icons/flags/ml.png';
import IconXmm from 'images/icons/flags/mm.png';
import IconXmn from 'images/icons/flags/mn.png';
import IconXmo from 'images/icons/flags/mo.png';
import IconXmp from 'images/icons/flags/mp.png';
import IconXmq from 'images/icons/flags/mq.png';
import IconXmr from 'images/icons/flags/mr.png';
import IconXms from 'images/icons/flags/ms.png';
import IconXmt from 'images/icons/flags/mt.png';
import IconXmu from 'images/icons/flags/mu.png';
import IconXmv from 'images/icons/flags/mv.png';
import IconXmw from 'images/icons/flags/mw.png';
import IconXmx from 'images/icons/flags/mx.png';
import IconXmy from 'images/icons/flags/my.png';
import IconXmz from 'images/icons/flags/mz.png';
import IconXna from 'images/icons/flags/na.png';
import IconXnc from 'images/icons/flags/nc.png';
import IconXne from 'images/icons/flags/ne.png';
import IconXnf from 'images/icons/flags/nf.png';
import IconXng from 'images/icons/flags/ng.png';
import IconXni from 'images/icons/flags/ni.png';
import IconXnl from 'images/icons/flags/nl.png';
import IconXno from 'images/icons/flags/no.png';
import IconXnp from 'images/icons/flags/np.png';
import IconXnr from 'images/icons/flags/nr.png';
import IconXnu from 'images/icons/flags/nu.png';
import IconXnz from 'images/icons/flags/nz.png';
import IconXom from 'images/icons/flags/om.png';
import IconXpa from 'images/icons/flags/pa.png';
import IconXpe from 'images/icons/flags/pe.png';
import IconXpf from 'images/icons/flags/pf.png';
import IconXpg from 'images/icons/flags/pg.png';
import IconXph from 'images/icons/flags/ph.png';
import IconXpk from 'images/icons/flags/pk.png';
import IconXpl from 'images/icons/flags/pl.png';
import IconXpm from 'images/icons/flags/pm.png';
import IconXpn from 'images/icons/flags/pn.png';
import IconXpr from 'images/icons/flags/pr.png';
import IconXps from 'images/icons/flags/ps.png';
import IconXpt from 'images/icons/flags/pt.png';
import IconXpw from 'images/icons/flags/pw.png';
import IconXpy from 'images/icons/flags/py.png';
import IconXqa from 'images/icons/flags/qa.png';
import IconXre from 'images/icons/flags/re.png';
import IconXro from 'images/icons/flags/ro.png';
import IconXrs from 'images/icons/flags/rs.png';
import IconXru from 'images/icons/flags/ru.png';
import IconXrw from 'images/icons/flags/rw.png';
import IconXsa from 'images/icons/flags/sa.png';
import IconXsb from 'images/icons/flags/sb.png';
import IconXsc from 'images/icons/flags/sc.png';
import IconXsd from 'images/icons/flags/sd.png';
import IconXse from 'images/icons/flags/se.png';
import IconXsg from 'images/icons/flags/sg.png';
import IconXsh from 'images/icons/flags/sh.png';
import IconXsi from 'images/icons/flags/si.png';
import IconXsj from 'images/icons/flags/sj.png';
import IconXsk from 'images/icons/flags/sk.png';
import IconXsl from 'images/icons/flags/sl.png';
import IconXsm from 'images/icons/flags/sm.png';
import IconXsn from 'images/icons/flags/sn.png';
import IconXso from 'images/icons/flags/so.png';
import IconXsr from 'images/icons/flags/sr.png';
import IconXst from 'images/icons/flags/st.png';
import IconXsv from 'images/icons/flags/sv.png';
import IconXsy from 'images/icons/flags/sy.png';
import IconXsz from 'images/icons/flags/sz.png';
import IconXtc from 'images/icons/flags/tc.png';
import IconXtd from 'images/icons/flags/td.png';
import IconXtf from 'images/icons/flags/tf.png';
import IconXtg from 'images/icons/flags/tg.png';
import IconXth from 'images/icons/flags/th.png';
import IconXtj from 'images/icons/flags/tj.png';
import IconXtk from 'images/icons/flags/tk.png';
import IconXtl from 'images/icons/flags/tl.png';
import IconXtm from 'images/icons/flags/tm.png';
import IconXtn from 'images/icons/flags/tn.png';
import IconXto from 'images/icons/flags/to.png';
import IconXtr from 'images/icons/flags/tr.png';
import IconXtt from 'images/icons/flags/tt.png';
import IconXtv from 'images/icons/flags/tv.png';
import IconXtw from 'images/icons/flags/tw.png';
import IconXtz from 'images/icons/flags/tz.png';
import IconXua from 'images/icons/flags/ua.png';
import IconXug from 'images/icons/flags/ug.png';
import IconXum from 'images/icons/flags/um.png';
import IconXus from 'images/icons/flags/us.png';
import IconXuy from 'images/icons/flags/uy.png';
import IconXuz from 'images/icons/flags/uz.png';
import IconXva from 'images/icons/flags/va.png';
import IconXvc from 'images/icons/flags/vc.png';
import IconXve from 'images/icons/flags/ve.png';
import IconXvg from 'images/icons/flags/vg.png';
import IconXvi from 'images/icons/flags/vi.png';
import IconXvn from 'images/icons/flags/vn.png';
import IconXvu from 'images/icons/flags/vu.png';
import IconXwf from 'images/icons/flags/wf.png';
import IconXws from 'images/icons/flags/ws.png';
import IconXye from 'images/icons/flags/ye.png';
import IconXyt from 'images/icons/flags/yt.png';
import IconXza from 'images/icons/flags/za.png';
import IconXzm from 'images/icons/flags/zm.png';

const COUNTRY_ICONS = {
  'ad': IconXad,
  'ae': IconXae,
  'af': IconXaf,
  'ag': IconXag,
  'ai': IconXai,
  'al': IconXal,
  'am': IconXam,
  'an': IconXan,
  'ao': IconXao,
  'ar': IconXar,
  'as': IconXas,
  'at': IconXat,
  'au': IconXau,
  'aw': IconXaw,
  'ax': IconXax,
  'az': IconXaz,
  'ba': IconXba,
  'bb': IconXbb,
  'bd': IconXbd,
  'be': IconXbe,
  'bf': IconXbf,
  'bg': IconXbg,
  'bh': IconXbh,
  'bi': IconXbi,
  'bj': IconXbj,
  'bm': IconXbm,
  'bn': IconXbn,
  'bo': IconXbo,
  'br': IconXbr,
  'bs': IconXbs,
  'bt': IconXbt,
  'bv': IconXbv,
  'bw': IconXbw,
  'by': IconXby,
  'bz': IconXbz,
  'ca': IconXca,
  'cc': IconXcc,
  'cd': IconXcd,
  'cf': IconXcf,
  'cg': IconXcg,
  'ch': IconXch,
  'ci': IconXci,
  'ck': IconXck,
  'cl': IconXcl,
  'cm': IconXcm,
  'cn': IconXcn,
  'co': IconXco,
  'cr': IconXcr,
  'cs': IconXcs,
  'cu': IconXcu,
  'cv': IconXcv,
  'cx': IconXcx,
  'cy': IconXcy,
  'cz': IconXcz,
  'de': IconXde,
  'dj': IconXdj,
  'dk': IconXdk,
  'dm': IconXdm,
  'do': IconXdo,
  'dz': IconXdz,
  'ec': IconXec,
  'ee': IconXee,
  'eg': IconXeg,
  'eh': IconXeh,
  'er': IconXer,
  'es': IconXes,
  'et': IconXet,
  'fi': IconXfi,
  'fj': IconXfj,
  'fk': IconXfk,
  'fm': IconXfm,
  'fo': IconXfo,
  'fr': IconXfr,
  'ga': IconXga,
  'gb': IconXgb,
  'gd': IconXgd,
  'ge': IconXge,
  'gf': IconXgf,
  'gh': IconXgh,
  'gi': IconXgi,
  'gl': IconXgl,
  'gm': IconXgm,
  'gn': IconXgn,
  'gp': IconXgp,
  'gq': IconXgq,
  'gr': IconXgr,
  'gs': IconXgs,
  'gt': IconXgt,
  'gu': IconXgu,
  'gw': IconXgw,
  'gy': IconXgy,
  'hk': IconXhk,
  'hm': IconXhm,
  'hn': IconXhn,
  'hr': IconXhr,
  'ht': IconXht,
  'hu': IconXhu,
  'id': IconXid,
  'ie': IconXie,
  'il': IconXil,
  'in': IconXin,
  'io': IconXio,
  'iq': IconXiq,
  'ir': IconXir,
  'is': IconXis,
  'it': IconXit,
  'jm': IconXjm,
  'jo': IconXjo,
  'jp': IconXjp,
  'ke': IconXke,
  'kg': IconXkg,
  'kh': IconXkh,
  'ki': IconXki,
  'km': IconXkm,
  'kn': IconXkn,
  'kp': IconXkp,
  'kr': IconXkr,
  'kw': IconXkw,
  'ky': IconXky,
  'kz': IconXkz,
  'la': IconXla,
  'lb': IconXlb,
  'lc': IconXlc,
  'li': IconXli,
  'lk': IconXlk,
  'lr': IconXlr,
  'ls': IconXls,
  'lt': IconXlt,
  'lu': IconXlu,
  'lv': IconXlv,
  'ly': IconXly,
  'ma': IconXma,
  'mc': IconXmc,
  'md': IconXmd,
  'me': IconXme,
  'mg': IconXmg,
  'mh': IconXmh,
  'mk': IconXmk,
  'ml': IconXml,
  'mm': IconXmm,
  'mn': IconXmn,
  'mo': IconXmo,
  'mp': IconXmp,
  'mq': IconXmq,
  'mr': IconXmr,
  'ms': IconXms,
  'mt': IconXmt,
  'mu': IconXmu,
  'mv': IconXmv,
  'mw': IconXmw,
  'mx': IconXmx,
  'my': IconXmy,
  'mz': IconXmz,
  'na': IconXna,
  'nc': IconXnc,
  'ne': IconXne,
  'nf': IconXnf,
  'ng': IconXng,
  'ni': IconXni,
  'nl': IconXnl,
  'no': IconXno,
  'np': IconXnp,
  'nr': IconXnr,
  'nu': IconXnu,
  'nz': IconXnz,
  'om': IconXom,
  'pa': IconXpa,
  'pe': IconXpe,
  'pf': IconXpf,
  'pg': IconXpg,
  'ph': IconXph,
  'pk': IconXpk,
  'pl': IconXpl,
  'pm': IconXpm,
  'pn': IconXpn,
  'pr': IconXpr,
  'ps': IconXps,
  'pt': IconXpt,
  'pw': IconXpw,
  'py': IconXpy,
  'qa': IconXqa,
  're': IconXre,
  'ro': IconXro,
  'rs': IconXrs,
  'ru': IconXru,
  'rw': IconXrw,
  'sa': IconXsa,
  'sb': IconXsb,
  'sc': IconXsc,
  'sd': IconXsd,
  'se': IconXse,
  'sg': IconXsg,
  'sh': IconXsh,
  'si': IconXsi,
  'sj': IconXsj,
  'sk': IconXsk,
  'sl': IconXsl,
  'sm': IconXsm,
  'sn': IconXsn,
  'so': IconXso,
  'sr': IconXsr,
  'st': IconXst,
  'sv': IconXsv,
  'sy': IconXsy,
  'sz': IconXsz,
  'tc': IconXtc,
  'td': IconXtd,
  'tf': IconXtf,
  'tg': IconXtg,
  'th': IconXth,
  'tj': IconXtj,
  'tk': IconXtk,
  'tl': IconXtl,
  'tm': IconXtm,
  'tn': IconXtn,
  'to': IconXto,
  'tr': IconXtr,
  'tt': IconXtt,
  'tv': IconXtv,
  'tw': IconXtw,
  'tz': IconXtz,
  'ua': IconXua,
  'ug': IconXug,
  'um': IconXum,
  'us': IconXus,
  'uy': IconXuy,
  'uz': IconXuz,
  'va': IconXva,
  'vc': IconXvc,
  've': IconXve,
  'vg': IconXvg,
  'vi': IconXvi,
  'vn': IconXvn,
  'vu': IconXvu,
  'wf': IconXwf,
  'ws': IconXws,
  'ye': IconXye,
  'yt': IconXyt,
  'za': IconXza,
  'zm': IconXzm
};

const COUNTRY_NAMES = {
  'af': 'Afghanistan',
  'ax': 'Åland Islands',
  'al': 'Albania',
  'dz': 'Algeria',
  'as': 'American Samoa',
  'ad': 'Andorra',
  'ao': 'Angola',
  'ai': 'Anguilla',
  'aq': 'Antarctica',
  'ag': 'Antigua and Barbuda',
  'ar': 'Argentina',
  'am': 'Armenia',
  'aw': 'Aruba',
  'au': 'Australia',
  'at': 'Austria',
  'az': 'Azerbaijan',
  'bh': 'Bahrain',
  'bs': 'Bahamas',
  'bd': 'Bangladesh',
  'bb': 'Barbados',
  'by': 'Belarus',
  'be': 'Belgium',
  'bz': 'Belize',
  'bj': 'Benin',
  'bm': 'Bermuda',
  'bt': 'Bhutan',
  'bo': 'Bolivia',
  'bq': 'Bonaire, Sint Eustatius and Saba',
  'ba': 'Bosnia and Herzegovina',
  'bw': 'Botswana',
  'bv': 'Bouvet Island',
  'br': 'Brazil',
  'io': 'British Indian Ocean Territory',
  'bn': 'Brunei Darussalam',
  'bg': 'Bulgaria',
  'bf': 'Burkina Faso',
  'bi': 'Burundi',
  'kh': 'Cambodia',
  'cm': 'Cameroon',
  'ca': 'Canada',
  'cv': 'Cape Verde',
  'ky': 'Cayman Islands',
  'cf': 'Central African Republic',
  'td': 'Chad',
  'cl': 'Chile',
  'cn': 'China',
  'cx': 'Christmas Island',
  'cc': 'Cocos (Keeling) Islands',
  'co': 'Colombia',
  'km': 'Comoros',
  'cg': 'Congo-Brazzaville',
  'cd': 'Congo-Kinshasa',
  'ck': 'Cook Islands',
  'cr': 'Costa Rica',
  'ci': 'Côte d\'Ivoire',
  'hr': 'Croatia',
  'cu': 'Cuba',
  'cw': 'Curaçao',
  'cy': 'Cyprus',
  'cz': 'Czech Republic',
  'dk': 'Denmark',
  'dj': 'Djibouti',
  'dm': 'Dominica',
  'do': 'Dominican Republic',
  'ec': 'Ecuador',
  'eg': 'Egypt',
  'sv': 'El Salvador',
  'gq': 'Equatorial Guinea',
  'er': 'Eritrea',
  'ee': 'Estonia',
  'et': 'Ethiopia',
  'fk': 'Falkland Islands (Malvinas)',
  'fo': 'Faroe Islands',
  'fj': 'Fiji',
  'fi': 'Finland',
  'fr': 'France',
  'gf': 'French Guiana',
  'pf': 'French Polynesia',
  'tf': 'French Southern Territories',
  'ga': 'Gabon',
  'gm': 'Gambia',
  'ge': 'Georgia',
  'de': 'Germany',
  'gh': 'Ghana',
  'gi': 'Gibraltar',
  'gr': 'Greece',
  'gl': 'Greenland',
  'gd': 'Grenada',
  'gp': 'Guadeloupe',
  'gu': 'Guam',
  'gt': 'Guatemala',
  'gg': 'Guernsey',
  'gn': 'Guinea',
  'gw': 'Guinea-Bissau',
  'gy': 'Guyana',
  'ht': 'Haiti',
  'hm': 'Heard Island and McDonald Islands',
  'va': 'Vatican City State',
  'hn': 'Honduras',
  'hk': 'Hong Kong',
  'hu': 'Hungary',
  'is': 'Iceland',
  'in': 'India',
  'id': 'Indonesia',
  'ir': 'Iran',
  'iq': 'Iraq',
  'ie': 'Ireland',
  'im': 'Isle of Man',
  'il': 'Israel',
  'it': 'Italy',
  'jm': 'Jamaica',
  'jp': 'Japan',
  'je': 'Jersey',
  'jo': 'Jordan',
  'kz': 'Kazakhstan',
  'ke': 'Kenya',
  'ki': 'Kiribati',
  'kp': 'North Korea',
  'kr': 'South Korea',
  'kw': 'Kuwait',
  'kg': 'Kyrgyzstan',
  'la': 'Laos',
  'lv': 'Latvia',
  'lb': 'Lebanon',
  'ls': 'Lesotho',
  'lr': 'Liberia',
  'ly': 'Libya',
  'li': 'Liechtenstein',
  'lt': 'Lithuania',
  'lu': 'Luxembourg',
  'mo': 'Macao',
  'mk': 'Macedonia',
  'mg': 'Madagascar',
  'mw': 'Malawi',
  'my': 'Malaysia',
  'mv': 'Maldives',
  'ml': 'Mali',
  'mt': 'Malta',
  'mh': 'Marshall Islands',
  'mq': 'Martinique',
  'mr': 'Mauritania',
  'mu': 'Mauritius',
  'yt': 'Mayotte',
  'mx': 'Mexico',
  'fm': 'Micronesia',
  'md': 'Moldova',
  'mc': 'Monaco',
  'mn': 'Mongolia',
  'me': 'Montenegro',
  'ms': 'Montserrat',
  'ma': 'Morocco',
  'mz': 'Mozambique',
  'mm': 'Myanmar',
  'na': 'Namibia',
  'nr': 'Nauru',
  'np': 'Nepal',
  'nl': 'Netherlands',
  'nc': 'New Caledonia',
  'nz': 'New Zealand',
  'ni': 'Nicaragua',
  'ne': 'Niger',
  'ng': 'Nigeria',
  'nu': 'Niue',
  'nf': 'Norfolk Island',
  'mp': 'Northern Mariana Islands',
  'no': 'Norway',
  'om': 'Oman',
  'pk': 'Pakistan',
  'pw': 'Palau',
  'ps': 'Palestine',
  'pa': 'Panama',
  'pg': 'Papua New Guinea',
  'py': 'Paraguay',
  'pe': 'Peru',
  'ph': 'Philippines',
  'pn': 'Pitcairn',
  'pl': 'Poland',
  'pt': 'Portugal',
  'pr': 'Puerto Rico',
  'qa': 'Qatar',
  're': 'Réunion',
  'ro': 'Romania',
  'ru': 'Russian Federation',
  'rw': 'Rwanda',
  'bl': 'Saint Barthélemy',
  'sh': 'Saint Helena, Ascension and Tristan da Cunha',
  'kn': 'Saint Kitts and Nevis',
  'lc': 'Saint Lucia',
  'mf': 'Saint Martin',
  'pm': 'Saint Pierre and Miquelon',
  'vc': 'Saint Vincent and the Grenadines',
  'ws': 'Samoa',
  'sm': 'San Marino',
  'st': 'Sao Tome and Principe',
  'sa': 'Saudi Arabia',
  'sn': 'Senegal',
  'rs': 'Serbia',
  'sc': 'Seychelles',
  'sl': 'Sierra Leone',
  'sg': 'Singapore',
  'sx': 'Sint Maarten',
  'sk': 'Slovakia',
  'si': 'Slovenia',
  'sb': 'Solomon Islands',
  'so': 'Somalia',
  'za': 'South Africa',
  'gs': 'South Georgia and the South Sandwich Islands',
  'ss': 'South Sudan',
  'es': 'Spain',
  'lk': 'Sri Lanka',
  'sd': 'Sudan',
  'sr': 'Suriname',
  'sj': 'Svalbard and Jan Mayen',
  'sz': 'Swaziland',
  'se': 'Sweden',
  'ch': 'Switzerland',
  'sy': 'Syrian Arab Republic',
  'tw': 'Taiwan',
  'tj': 'Tajikistan',
  'tz': 'Tanzania',
  'th': 'Thailand',
  'tl': 'Timor-Leste',
  'tg': 'Togo',
  'tk': 'Tokelau',
  'to': 'Tonga',
  'tt': 'Trinidad and Tobago',
  'tn': 'Tunisia',
  'tr': 'Turkey',
  'tm': 'Turkmenistan',
  'tc': 'Turks and Caicos Islands',
  'tv': 'Tuvalu',
  'ug': 'Uganda',
  'ua': 'Ukraine',
  'ae': 'United Arab Emirates',
  'gb': 'United Kingdom',
  'us': 'United States',
  'um': 'United States Minor Outlying Islands',
  'uy': 'Uruguay',
  'uz': 'Uzbekistan',
  'vu': 'Vanuatu',
  've': 'Venezuela',
  'vn': 'Viet Nam',
  'vg': 'British Virgin Islands',
  'vi': 'US Virgin Islands',
  'wf': 'Wallis and Futuna',
  'eh': 'Western Sahara',
  'ye': 'Yemen',
  'zm': 'Zambia',
  'zw': 'Zimbabwe'
};

export function isKnownCountry(country) {
  return !!COUNTRY_NAMES[country?.toLowerCase()];
}

function FlagIcon({ country, includeName = false }) {
  let code = country?.toLowerCase();
  return (
    <>
      <div style={{
        display: 'inline-block',
        verticalAlign: 'middle',
        width: '16px',
        height: '11px',
        backgroundImage: `url(${COUNTRY_ICONS[code]})`
      }}>
      </div>
      {includeName && COUNTRY_NAMES[code]
        ? <div style={{display: 'inline-block', verticalAlign: 'middle', marginLeft: '5px'}}>
          {COUNTRY_NAMES[code]}
        </div>
        : null}
    </>
  );
}

export default FlagIcon;
