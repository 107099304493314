/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage as ReactIntlFormattedMessage } from 'react-intl';

/**
  Extends react-intl's Formatted message with some basic formatting support.
 */
export default class FormattedMessage extends Component {
  static propTypes = {
    /** unique key for react-intl */
    id: PropTypes.string,
    /** display text */
    defaultMessage: PropTypes.string,
    /** map of message parameters */
    values: PropTypes.object
  };

  constructor(props) {
    super(props);
    this.key = 0;
    this.values = {
      br: <br/>
    };
  }

  replaceTag(string, regex, replacerFn, regexHasParameter = false) {
    let tokens = string.split(regex);
    if (tokens.length === 1) {
      // no matching tags found in message
      return string;
    }

    let updatedString = tokens[0];
    for (let x = 1; x < tokens.length; x++) {

      // opening tag parameter
      let parameter = null;
      if (regexHasParameter) {
        // expect format :[string]
        parameter = tokens[x].substring(1);
        x++;
      }

      // content between tags
      this.values[`key${this.key}`] = replacerFn(tokens[x], parameter);
      updatedString += `{key${this.key}}`;
      x++;
      this.key++;

      // closing tag parameter
      if (regexHasParameter) {
        x++;
      }

      // content before next tag
      updatedString += tokens[x];
    }

    return updatedString;
  }

  format(defaultMessage) {
    if (!defaultMessage) {
      return defaultMessage;
    }

    let updatedMessage = defaultMessage;

    // support bold
    updatedMessage = this.replaceTag(updatedMessage, '{b}', (tagContent) => {
      return <strong>{tagContent}</strong>;
    });

    // support italic
    updatedMessage = this.replaceTag(updatedMessage, '{i}', (tagContent) => {
      return <i>{tagContent}</i>;
    });

    // support underline
    updatedMessage = this.replaceTag(updatedMessage, '{u}', (tagContent) => {
      return <u>{tagContent}</u>;
    });

    // support color
    updatedMessage = this.replaceTag(updatedMessage, /{color(\:[\#A-Za-z0-9]+)?}/, (tagContent, parameter) => {
      return (
        <span style={{color: parameter}}>{tagContent}</span>
      );
    }, true);

    // support classes
    updatedMessage = this.replaceTag(updatedMessage, /{class(\:[A-Za-z0-9-]+)?}/, (tagContent, parameter) => {
      return (
        <span className={parameter}>{tagContent}</span>
      );
    }, true);

    return updatedMessage;
  }

  render() {
    let formattedMessage = this.format(this.props.defaultMessage);
    return (
      <ReactIntlFormattedMessage {...this.props}
        values={{
          ...this.values,
          ...this.props.values
        }}
        defaultMessage={formattedMessage}/>
    );
  }
}
