/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import FormJSX, { FieldOption }  from 'components/Form/FormJSX';
import {
  TwoFactorInit,
  TwoFactorVerificationV2,
  createTwoFactorVerificationCode
} from 'schemas/settings/twoFactorAuthentication';
import Button from 'components/Button';
import PhoneNumber from 'components/PhoneNumber';
import InfoTooltip from 'components/InfoTooltip';
import Messages from './index.messages.js';
import './index.scss';

const createBaseTwoStepSetupForm = (WrappingComponent, ContentComponent) => {
  return class BaseTwoStepSetupForm extends Component {
    static propTypes = {
      variant: PropTypes.oneOf(['card', 'default']),
      onCancel: PropTypes.func,
      className: PropTypes.string,
      autoFocus: PropTypes.bool
    };

    static defaultProps = {
      showMessagingRatesTooltip: false,
      variant: 'default',
      onCancel: () => null,
      autoFocus: true
    }

    constructor(props) {
      super(props);

      this.state = {
        twoStepForm: {
          phoneNumber: props.phoneNumber
        },
        codeForm: {}
      };

      this.sendPhoneNumber = this.sendPhoneNumber.bind(this);
      this.sendVerifyCode = this.sendVerifyCode.bind(this);
    }

    componentDidMount() {
      if (this.phoneInputRef && this.props.autoFocus) {
        ReactDOM.findDOMNode(this.phoneInputRef).focus();
      }
    }

    componentWillReceiveProps(newProps) {
      if (newProps.phoneNumber && !this.props.phoneNumber) {
        this.setState({
          twoStepForm: {
            phoneNumber: newProps.phoneNumber
          }
        });
      }

      if (!newProps.isVerifying && this.props.isVerifying) {
        if (newProps.isVerificationSuccessful) {
          this.setState({
            codeForm: {
              authenticationCode: ''
            }
          });
        } else {
          this.setState({
            codeError: newProps.isPhoneNumberLocked
              ? 'PhoneLocked'
              : 'IncorrectCode'
          });
          this.codeFormRef.validate();
        }
      }
    }

    sendPhoneNumber(evt) {
      if (evt) {
        evt.preventDefault();
      }
      const formValue = this.twoStepFormRef.getValue();
      if (formValue) {
        this.props.onEnable(formValue.phoneNumber);
      }
    }

    sendVerifyCode(evt) {
      if (evt) {
        evt.preventDefault();
      }

      const value = this.codeFormRef.getValue();
      if (value) {
        this.props.onVerifyCode(value.authenticationCode);
      }
    }

    renderEnterMobilePanel() {
      const {
        onCancel,
        showMessagingRatesTooltip,
        enterActions,
        variant
      } = this.props;

      if (variant === 'card') {
        return (
          <FormJSX
            ref={ref => this.twoStepFormRef = ref}
            onChange={twoStepForm => this.setState({ twoStepForm })}
            hasSubmitButton={true}
            modelType={TwoFactorInit}
            value={this.state.twoStepForm}
          >
            <FieldOption
              name="phoneNumber"
              doNotValidateOnBlur={true}
              label={Messages.FormLabels.MobilePhoneCardVariant}
              placeholder={Messages.Placeholders.PhoneNumber}
              inputProps={{
                ref: ref => this.phoneInputRef = ref
              }}
            />
            <div style={{marginTop: 4}}>
              <Button
                variant="secondary"
                style={{width: 'calc((100% - 12px) / 2)'}}
                onClick={onCancel}>
                <Messages.Buttons.Cancel.Message />
              </Button>
              <Button
                style={{marginLeft: 12, width: 'calc((100% - 12px) / 2)'}}
                type="submit"
                onClick={this.sendPhoneNumber}
              >
                <Messages.Buttons.SendCode.Message />
              </Button>
            </div>
            {enterActions}
          </FormJSX>
        );
      }

      return (
        <FormJSX ref={ref => this.twoStepFormRef = ref}
          onChange={twoStepForm => this.setState({ twoStepForm })}
          modelType={TwoFactorInit}
          value={this.state.twoStepForm}
          customConfig={{ addPadding: 'none' }}
        >
          <span cols={7}>
            {showMessagingRatesTooltip ? (
              <InfoTooltip>
                <Messages.StandardMessagingRates.Message />
              </InfoTooltip>
            ) : null}
            <Messages.Step1Instructions.Message />
          </span>
          <FieldOption
            name="phoneNumber"
            willHideLabel={true}
            cols={3}
            doNotValidateOnBlur={true}
            placeholder={Messages.Placeholders.PhoneNumber}
            inputProps={{
              ref: ref => this.phoneInputRef = ref
            }}
          />
          <Button
            cols={2}
            onClick={this.sendPhoneNumber}
            className="skinny-button"
            type="submit"
          >
            <Messages.Buttons.Send.Message />
          </Button>
          {enterActions}
        </FormJSX>
      );
    }

    renderConfirmCodePanel() {
      const {
        isVerificationSuccessful,
        isPhoneNumberLocked,
        isVerifying,
        onResendCode,
        onResetVerification,
        phoneNumber,
        sendActions,
        variant,
        verifyFormHasSubmitButton
      } = this.props;

      let errorMsg = null;

      if(!isVerificationSuccessful) {
        errorMsg = isPhoneNumberLocked
          ? Messages.PhoneNumberLocked
          : Messages.IncorrectCode;
      }

      const isCardVariant = variant === 'card';

      return (
        <div>
          <FormJSX
            ref={ref => this.codeFormRef = ref}
            hasSubmitButton={verifyFormHasSubmitButton}
            onChange={codeForm => this.setState({ codeForm, codeError: null })}
            modelType={createTwoFactorVerificationCode(!!errorMsg, errorMsg)}
            value={this.state.codeForm}
          >
            <div cols={isCardVariant ? 12 : 8}>
              <Messages.FormLabels.EnterCode.Message phoneNumber={(
                <strong>
                  <PhoneNumber value={this.state.twoStepForm.phoneNumber || phoneNumber} />
                </strong>
              )}/>
              <br/>
              <Button anchor={true} onClick={onResendCode}>
                <Messages.Buttons.ResendCode.Message />
              </Button>
              &nbsp;|&nbsp;
              <Button anchor={true} onClick={onResetVerification}>
                <Messages.Buttons.ChangeNumber.Message />
              </Button>
            </div>
            <FieldOption
              name="authenticationCode"
              cols={isCardVariant ? 12 : 4}
              willHideLabel={true}
              doNotValidateOnBlur={true}
              placeholder={Messages.Placeholders.EnterCode}
              inputProps={{ ref: ref => this.codeInputRef = ref }}
            />
            {sendActions?.({
              isVerifying,
              sendVerifyCode: this.sendVerifyCode
            })}
          </FormJSX>
        </div>
      );
    }

    render() {
      const {
        className,
        isVerificationPending
      } = this.props;

      let content = null;

      if (ContentComponent) {

        content = (
          <ContentComponent
            {...this.props}
            state={this.state}
            onPhoneFormChange={twoStepForm => this.setState({ twoStepForm })}
            phoneFormRef={ref => this.twoStepFormRef = ref}
            phoneFormModelType={TwoFactorInit}
            onVerifyFormChange={codeForm => this.setState({ codeForm, codeError: null })}
            verifyFormRef={ref => this.codeFormRef = ref}
            verifyFormModelType={TwoFactorVerificationV2}
            sendPhoneNumber={this.sendPhoneNumber}
          />
        );
      } else {
        content = (
          <div className={className}>
            {!isVerificationPending
              ? this.renderEnterMobilePanel()
              : this.renderConfirmCodePanel()}
          </div>
        );
      }

      if (WrappingComponent) {
        return (
          <WrappingComponent
            {...this.props}
            sendVerifyCode={this.sendVerifyCode}
            isSendCodeDisabled={!this.state.codeForm.authenticationCode}
          >
            {content}
          </WrappingComponent>
        );
      }

      return (
        <>
          {content}
        </>
      );
    }
  };
};

export default createBaseTwoStepSetupForm;
