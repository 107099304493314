/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { queryParamNameMapper } from 'api/common/mappers/coreMappers';
import { createApiAction } from 'api/core';

const resendVerifyAccountEmail = createApiAction({
  method: 'post',
  url: '/ajax/logon/resendValidationEmail',
  queryStringMapper: queryParamNameMapper({
    'encryptedUserId': 'encryptedId'
  })
});

export default resendVerifyAccountEmail;