/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import t from 'tcomb-validation';
import { defineSchema } from 'schemas';
import createApiActions from 'api/core';
import { queryParamNameMapper } from 'api/common/mappers';
import { createEntity } from 'schemas/state';
import {
  SearchTypes,
  StatusValues,
  SearchGroup,
  InvoiceSearchValues
} from 'schemas/common/filter';
import CompanyOrganizationNode from 'schemas/network/CompanyOrganizationNode';
import { InvoiceType } from 'schemas/invoicing/invoice';

export const InvoiceFilterCompanyResult = defineSchema({
  addressBookId: t.String,
  companyId: t.maybe(t.String),
  companyName: t.String
}, 'InvoiceFilterCompanyResult');

// search API returns Groups (hints) which have Results (results) which have Properties (properties)
// this is a way to define how we should map the result id and props for each result type to the client's SearchValue names
// Note: this only maps the field/property NAMES not the values
// TODO: Can we make this less complicated? :(
const SEARCH_API_MAPPING_CONFIG = [
  {
    mapWhenResultTypeIs: 'node',
    resultName: 'CompanyNode',
    resultFields: {
      'id': InvoiceSearchValues.meta.map.companyNodeId
    },
    properties: {
      'path': InvoiceSearchValues.meta.map.companyHierarchy,
      'company_id': InvoiceSearchValues.meta.map.companyId,
      'name': InvoiceSearchValues.meta.map.companyName
    }
  },
  {
    mapWhenResultTypeIs: 'entry',
    resultName: 'AddressBook',
    resultFields: {
      'id': InvoiceSearchValues.meta.map.addressBookId
    },
    properties: {
      'name': InvoiceSearchValues.meta.map.companyName,
      'target_id': InvoiceSearchValues.meta.map.companyId
    }
  },
  {
    mapWhenResultTypeIs: 'invoice_number',
    resultName: 'InvoiceNumber',
    resultFields: {
      'id': InvoiceSearchValues.meta.map.invoiceId,
      'documentType': InvoiceSearchValues.meta.map.invoiceType
    },
    properties: {
      'invoice_number': InvoiceSearchValues.meta.map.invoiceNumber
    }
  },
  {
    mapWhenResultTypeIs: 'purchase_order',
    resultName: 'PurchaseOrderNumber',
    resultFields: {
      'id': InvoiceSearchValues.meta.map.invoiceId,
      'documentType': InvoiceSearchValues.meta.map.invoiceType
    },
    properties: {
      'purchase_order': InvoiceSearchValues.meta.map.purchaseOrderNumber
    }
  }
];

function mapResultFieldValues(resultFieldName, resultFieldValue) {
  if (resultFieldName === 'documentType') {
    if (resultFieldValue === 'payable') return InvoiceType.meta.map.Payable;
    if (resultFieldValue === 'receivable') return InvoiceType.meta.map.Receivable;
    if (resultFieldValue === 'oob') return InvoiceType.meta.map.OutOfBandInvoice;
  }
  return resultFieldValue;
}

function mapPropertyValues(result, propertyName, apiPropertyValues) {
  if (!apiPropertyValues || apiPropertyValues.length < 1) return null;

  // manually handle known array values
  if (result.type === 'node' && propertyName === 'path') {
    //  org hierarchy
    let hierarchy = apiPropertyValues.map(v => v.value);
    // the path only contains nodes above the matching node
    // the matching node is the name
    let nameValue = result.properties.find(p => p.name === 'name');
    if (nameValue) {
      hierarchy.push(nameValue.values[0].value);
    }
    return hierarchy;
  }

  // every other known value is currently just a string
  return apiPropertyValues[0].value;
}

function mapPropertyMatchSegments(propertyValues) {
  if (!propertyValues) {
    return null;
  }
  let matchValue = propertyValues.find(v => v.matchSegments && v.matchSegments.length > 0);
  if (matchValue && matchValue.matchSegments) {
    return {
      matchSegments: matchValue.matchSegments.map((matchSegment) => {
        return {
          startIndex: matchSegment.start,
          count: matchSegment.length
        };
      })
    };
  }

  return null;
}

function invoiceSearchResponseMapper(context) {
  // we need the endpoint context to make assumptions about owernship since the API doesn't
  // actually give us the mine/theirs state for the invoices and we need it to be able to determine
  // the correct strawman url for invoice details when selecting an invoice result -_-
  // we should be able to get rid of this once we port strawman invoice details to brickman
  // and clean up the url so it only needs invoice id and type
  let isBillsContext = context === 'bills' ? true : false;

  return (response, state) => {
    let entities = [];
    let references = [];

    let groups = response && response.response
      && response.response.hints ? response.response.hints : [];
    groups.forEach((group) => {
      let groupName = group.name;
      if (groupName) {
        let results = [];

        let groupResults = group.results || [];
        groupResults.forEach((result) => {
          let values = [];

          let resultProperties = result.properties || [];

          let mappingConfig = SEARCH_API_MAPPING_CONFIG.find(c => c.mapWhenResultTypeIs === result.type);

          if (!mappingConfig) {
            console.warn('Unknown search result type. Result will be ignored.', result.type);
            return;
          }

          // map values on the result itself as if they were properties (so we only have a single way to get values associated with a result)
          let resultKeys = Object.keys(result);
          resultKeys.forEach((resultKey) => {
            // treat properties specially below
            if (resultKey === 'properties') return;

            if (mappingConfig.resultFields[resultKey]) {
              values.push({
                name: mappingConfig.resultFields[resultKey],
                value: mapResultFieldValues(resultKey, result[resultKey])
              });
            }
          });

          // map all of the properties we know how to map based on the config
          resultProperties.forEach((property) => {
            if (mappingConfig.properties[property.name]) {
              values.push({
                name: mappingConfig.properties[property.name],
                value: mapPropertyValues(result, property.name, property.values),
                meta: mapPropertyMatchSegments(property.values)
              });
            }
          });

          // fabricate isMine properties for invoice results since the API won't tell us :(
          if (result.type === 'invoice_number' || result.type === 'purchase_order') {
            let invoiceType = values.find(v => v.name === InvoiceSearchValues.meta.map.invoiceType).value;
            let isPayable = invoiceType === InvoiceType.meta.map.Payable;
            let isMine = (isBillsContext && isPayable) // my payable
              || (!isBillsContext && !isPayable); // my receivable
            values.push({
              name: InvoiceSearchValues.meta.map.isMine,
              value: isMine
            });
          }

          // this is currently our main source for Org Hierarchy nodes, so lets add those entitis in here too
          // so we can reference them by id (e.g. from Company Selection dropdown)
          if (result.type === 'node') {
            let companyNodeId = result.id;
            let nameProperty = result.properties.find(p => p.name === 'name');
            let nodeEntity = {
              id: companyNodeId,
              name: nameProperty ? nameProperty.values[0].value : null
            };
            entities.push(createEntity(
              companyNodeId,
              CompanyOrganizationNode.meta.name,
              nodeEntity
            ));
          }

          results.push({ name: mappingConfig.resultName, values: values });
        });

        let groupEntity = createEntity(
          groupName,
          SearchGroup.meta.name,
          { name: groupName, results: results }
        );

        entities.push(groupEntity);
        references.push(groupEntity.createReference());
      }
    });

    return {
      entities,
      references
    };
  };
}

const statusFilterResponseMapper = name => (response, state) => {
  let entities = [];
  let references = [];
  let results = [];

  let statuses = response && response.response
    && response.response.items ? response.response.items : [];
  statuses.forEach((status) => {
    let countValue = { name: StatusValues.meta.map.Count, value: status.count};
    let balanceValue = { name: StatusValues.meta.map.Balance, value: status.balance};
    let totalAmountValue = { name: StatusValues.meta.map.TotalAmount, value: status.totalAmount};
    let statusResult = { name: `${status.title}`, values: [ countValue, balanceValue, totalAmountValue ] };

    results.push(statusResult);
  });

  let groupEntity = createEntity(`${name}-${SearchTypes.meta.map.Statuses}`, SearchGroup.meta.name,
    { name: SearchTypes.meta.map.Statuses, results: results });
  entities.push(groupEntity);
  references.push(groupEntity.createReference());

  return {
    entities,
    references
  };
};

const billsStatusFilterResponseMapper = name => (response, state) => {
  let entities = [];
  let references = [];
  let results = [];

  let totalOverdueAmount = 0;
  let statuses = response ? Object.keys(response) : [];
  statuses.forEach((statusKey) => {

    if (statusKey === 'overdue') {
      totalOverdueAmount = response[statusKey].totalAmount;
      // Ux does not want to use overdue in the status filter (since its not really a status)
      return;
    }

    let status = response[statusKey];
    let countValue = { name: StatusValues.meta.map.Count, value: status.count};
    let balanceValue = { name: StatusValues.meta.map.Balance, value: status.totalBalance};
    let totalAmountValue = { name: StatusValues.meta.map.TotalAmount, value: status.totalAmount};
    let statusResult = { name: `${statusKey}`, values: [
      countValue,
      balanceValue,
      totalAmountValue,
      {
        name: StatusValues.meta.map.InboxItemCount,
        value: status.accountsPayableInboxActionRequiredCount
          + status.accountsPayableInboxProcessingCount || 0
      },
      {
        name: StatusValues.meta.map.InboxActionRequiredCount,
        value: status.accountsPayableInboxActionRequiredCount || 0
      }
    ]};

    results.push(statusResult);
  });

  let groupEntity = createEntity(`${name}-${SearchTypes.meta.map.Statuses}`, SearchGroup.meta.name,
    { name: SearchTypes.meta.map.Statuses, results: results });
  entities.push(groupEntity);
  references.push(groupEntity.createReference());

  return {
    entities,
    references,
    // 'overdue' was removed from filters, but Auto Pay still has a need for checking for overdueAmount for a given vendor
    totalOverdueAmount
  };
};

const companyGroupResultMapper = (response, state) => {
  const entities = [];

  const { hints } = response.response || {};

  (hints || []).forEach(({ name: groupName, results: groupResults }) => {
    if (groupName === 'Company') {
      (groupResults || []).forEach(({ id: addressBookId, properties }) => {
        const companyName = (((properties.find(x => x.name === 'name') || {}).values || [])[0] || {}).value;
        const companyId = (((properties.find(x => x.name === 'target_id') || {}).values || [])[0] || {}).value;

        entities.push(createEntity(
          addressBookId.toLowerCase(),
          InvoiceFilterCompanyResult.meta.name,
          {
            addressBookId: addressBookId.toLowerCase(),
            companyName,
            companyId: companyId ? companyId.toLowerCase() : null
          }
        ));
      });
    }
  });

  return { entities };
};

export default createApiActions({
  invoiceSearchForInvoiceList: {
    method: 'get',
    url: '/ajax/receivables/searchVendorPortal',
    queryStringMapper: queryParamNameMapper({
      'searchPhrase': 'searchPhrase'
    }),
    responseMapper: invoiceSearchResponseMapper('invoices')
  },
  // JL Note 04/19/2019: The SearchGroup model is awful and largely a relic of an endpoint that
  // is trying too hard to be generic, plus this functionality has been replaced by a text search
  // against the invoice list, serviced by Elastic Search. However, this is still the 'best'
  // endpoint to do company/address book searches in the company filter case, so this just dillutes
  // it down to that function.
  invoiceCompanySearchForInvoiceList: {
    method: 'get',
    url: '/ajax/receivables/searchVendorPortal',
    queryStringMapper: queryParamNameMapper({
      'searchPhrase': 'searchPhrase'
    }),
    responseMapper: companyGroupResultMapper
  },
  invoiceSearchForBillsInbox: {
    method: 'get',
    url: '/ajax/payables/invoiceReferenceSearchHints',
    queryStringMapper: queryParamNameMapper({
      'searchPhrase': 'searchPhrase'
    }),
    responseMapper: invoiceSearchResponseMapper('bills')
  },
  invoiceSearchForBillsList: {
    method: 'get',
    url: '/ajax/payables/invoiceNumberSearchHints',
    queryStringMapper: queryParamNameMapper({
      'searchPhrase': 'searchPhrase'
    }),
    responseMapper: invoiceSearchResponseMapper('bills')
  },
  // See note for invoiceCompanySearchForInvoiceList
  invoiceCompanySearchForBillsList: {
    method: 'get',
    url: '/ajax/payables/invoiceNumberSearchHints',
    queryStringMapper: queryParamNameMapper({
      'searchPhrase': 'searchPhrase'
    }),
    responseMapper: companyGroupResultMapper
  },
  statusLoadForInvoiceList: {
    method: 'get',
    url: '/ajax/receivables/overview',
    queryStringMapper: queryParamNameMapper({
      'addressBookId': 'addressBookId',
      'beginDate': 'beginDate',
      'endDate': 'endDate',
      dateField: 'dateFilterType'
    }),
    responseMapper: statusFilterResponseMapper('InvoiceList')
  },
  statusLoadForBillsInbox: {
    method: 'get',
    url: '/ajax/payables/inboxOverview',
    queryStringMapper: queryParamNameMapper({
      'addressBookId': 'addressBookId',
      'beginDate': 'beginDate',
      'endDate': 'endDate'
    }),
    responseMapper: statusFilterResponseMapper('BillsInbox')
  },
  statusLoadForBillsList: {
    method: 'get',
    url: '/api/payables/ap-overview',
    queryStringMapper: queryParamNameMapper({
      'addressBookId': 'customerAddressBookForVendorId',
      'beginDate': 'beginDate',
      'endDate': 'endDate',
      dateField: 'dateFilterType'
    }),
    responseMapper: billsStatusFilterResponseMapper('BillsList')
  },
  getPayableStatusFilters: {
    method: 'get',
    url: '/ajax/payables/overview',
    queryStringMapper: queryParamNameMapper({
      'addressBookId': 'addressBookId',
      'beginDate': 'beginDate',
      'endDate': 'endDate'
    }),
    responseMapper: statusFilterResponseMapper('Payables')
  }
});
