/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React from 'react';
import { Route, Switch } from 'react-router';
import { wrapImport } from 'decorators/ModuleLoader';
import useSessionInfo from 'hooks/identity/useSessionInfo';
import {
  getAuthRedirect,
  InAppNotFoundView
} from '../utils';

const DashboardPage = wrapImport(() => import(/* webpackChunkName: "views" */ 'views/Dashboard'));
const NotConnectedPortalDisclaimer = wrapImport(
  () => import(/* webpackChunkName: "views" */ 'views/Dashboard/NotConnectedPortal')
);

export const BusinessDashboardRoutes = ({ match }) => {
  const {
    isPersonal,
    isCustomerPortal,
    isVendorPortal,
    isPortalExperience
  } = useSessionInfo();

  return (
    <Switch>
      {getAuthRedirect({
        isPersonal,
        isCustomerPortal,
        isVendorPortal
      })}
      {isPortalExperience ? (
        <Route path={match.url} exact={true} component={NotConnectedPortalDisclaimer} />
      ) : null}
      <Route path={match.url} exact={true} component={DashboardPage} />
      {InAppNotFoundView}
    </Switch>
  );
};
