/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { createApiAction } from 'api/core';

export default createApiAction({
  method: 'post',
  url: '/api/register/contactEnterpriseSales',
  requestBodyMapper: ({
    companyName,
    firstName,
    lastName,
    workPhone,
    emailAddress,
    title,
    accountingPackage,
    apSpend,
    paperChecksSent,
    comments,
    captchaToken
  }) => ({
    companyName,
    firstName,
    lastName,
    workPhone,
    emailAddress,
    title,
    accountingPackage,
    apSpend,
    paperChecksSent,
    comments,
    captchaToken
  }),
  responseMapper: () => ({})
});