/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, { useRef } from 'react';

import DateFilter from 'components/DateFilter';
import { dateRangeToDates, dateRangeFromDates } from 'schemas/common/dateRange';

import './index.scss';

/**
 * Select implementation that uses a hyperlink (anchor) and dropdown
 * rather than a form Select component
 */
const DateRangePicker = ({
  onChange,
  value
}) => {

  let tooltipRef = useRef();

  const changeValue = (date1, date2) => {

    if (!date1 && !date2) {
      onChange?.(null);
      return;
    }

    // follow dateRange tcomb type
    onChange?.(dateRangeFromDates(date1, date2));

    // close tooltip on every change
    tooltipRef?.current?.close();
  };

  let dates = dateRangeToDates(value);

  let dropdown = (
    <DateFilter
      beginDate={dates?.date1}
      endDate={dates?.date2}
      // daysAgo={daysAgoMin != null || daysAgoMax != null ? [daysAgoMin, daysAgoMax] : null}
      includeAllDates={true}
      onChange={changeValue}
    />
  );

  return dropdown;
};

export default DateRangePicker;