/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { createApiAction } from 'api/core';

const twoFactorResendResetPasswordRequest = createApiAction({
  method: 'post',
  url: '/ajax/resetPassword/twoFactorAuth/resend',
  requestBodyMapper: ({ token }) => ({ token })
});

export default twoFactorResendResetPasswordRequest;