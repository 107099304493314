/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */
import { defineMessages } from 'react-intl';

export default defineMessages({
  Invalid: {
    id: 'ValidationError.Percent.Invalid',
    defaultMessage: 'Please enter a valid percent'
  },
  InvalidNotZero: {
    id: 'ValidationError.Percent.InvalidNotZero',
    defaultMessage: 'Please enter a percent greater than 0'
  },
  InvalidNotZeroMaxDigits: {
    id: 'ValidationError.Percent.InvalidNotZeroMaxDigits',
    defaultMessage: 'Please enter a percent greater than 0, fewer than {maxDigits} digits'
  },
  LessThan100: {
    id: 'ValidationError.Percent.LessThan100',
    defaultMessage: 'Please enter a value less than (or equal to) 100%'
  }
});
