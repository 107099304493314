/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, { isValidElement } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import './index.scss';

export const PanelVariants = {
  Light: 'light',
  Dropdown: 'dropdown',
  ThinHeader: 'thin-header',
  ThinFooter: 'thin-footer',
  Clean: 'clean'
};

export const PanelSubheader = ({
  children,
  className
}) => (
  <div
    className={classNames(
      'vp-subheader',
      className
    )}
  >
    {children}
  </div>
);

export const HeaderContent = ({
  children,
  className
}) => (
  <div
    className={classNames(
      'vp-header',
      className
    )}
  >
    {children}
  </div>
);

export const PanelHeader = ({
  children,
  className,
  rightContent,
  subheader
}) => {
  const content = (
    <>
      <div className="vp-header-centered">
        {children}
      </div>
      {subheader ? (
        <div className="subheader">
          {subheader}
        </div>
      ) : null}
    </>
  );

  if (rightContent) {
    return (
      <div
        className={classNames(
          'vp-header',
          className
        )}
        style={{ display: 'flex' }}
      >
        <div>
          {content}
        </div>
        <div style={{ marginLeft: 'auto' }}>
          {rightContent}
        </div>
      </div>
    );
  }

  return (
    <div
      className={classNames(
        'vp-header',
        className
      )}
    >
      {content}
    </div>
  );
};

export const PanelFooter = ({
  children,
  variant
}) => (
  <div className={classNames('vp-footer', variant)}>
    {children}
  </div>
);

PanelFooter.isPanelFooter = true;

const Panel = ({
  bodyClassName,
  children,
  className,
  footer,
  header,
  innerStyle,
  noPadding,
  panelStyle,
  style,
  variant
}) => {
  let headerElement;
  if (isValidElement(header) && (header.type === PanelHeader || header.type === HeaderContent)) {
    headerElement = header;
  } else if (header) {
    headerElement = PanelHeader({
      children: header,
      noMargin: noPadding
    });
  }

  // Allow for a way set parameters just on the footer without the panel component needing to ferry
  // them
  let footerElement;
  if (isValidElement(footer) && footer.type.isPanelFooter) {
    footerElement = footer;
  } else if (footer) {
    footerElement = PanelFooter({ children: footer });
  }

  return (
    <div
      className={classNames(
        'vp-panel',
        variant,
        className
      )}
      style={panelStyle}
    >
      {headerElement}
      <div
        className={classNames(
          'vp-panel-style',
          bodyClassName
        )}
        style={{
          padding: noPadding ? 0 : null,
          ...(innerStyle || {})
        }}
      >
        {children}
      </div>
      {footerElement}
    </div>
  );
};

Panel.propTypes = {
  /** optional content to render in a header section */
  header: PropTypes.node,
  /** optional content to render in a footer section */
  footer: PropTypes.node,
  className: PropTypes.string,
  noPadding: PropTypes.bool,
  variant: PropTypes.oneOf([
    'light',
    'dropdown',
    'thin-header',
    'thin-footer',
    'clean'
  ])
};

export default Panel;
