/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { defaultMapper } from 'api/common/mappers/default';
import { createApiAction } from 'api/core';
import patchIdentity from 'api/session/mappers/patchIdentity';

const patchCompanySettings = createApiAction({
  method: 'patch',
  url: '/api/web/company_settings',
  requestBodyMapper: defaultMapper,
  responseMapper: (params, state, request) => ({
    entities: [
      patchIdentity(
        {
          companySettings: {
            ...request
          }
        },
        state
      )
    ]
  })
});

export default patchCompanySettings;