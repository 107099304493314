/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { isFetchAction } from 'api/core/utils';
import { newState } from './utils';

export default (state = {}, action) => {
  if (isFetchAction(action)
    && action.meta.correlationId) {
    let trackedRequest = {};
    trackedRequest[`${action.type}/${action.meta.correlationId}`] = action.meta.requestId;

    return newState(state, trackedRequest);
  }

  return state;
};
