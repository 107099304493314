/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { useContext, useEffect } from 'react';

import UpdateContext from './provideHooks/UpdateContext';
import applyExtendHooks from './utils/applyExtendHooks';
import createBaseApiResultHooks from './utils/createBaseApiResultHooks';
import createUseApiResultHooks from './utils/createUseApiResultHooks';

const createProvideApiResultHooks = (
  {
    // This takes in the current set of query params and then the result of this function is overlaid
    // on the previous request params to see if there are any changes, if so, the API is called with
    // the new params. This can either be
    // 1) [ list of query params to map directly by key into the request ]
    // 2) { params: same as #1, transform: takes in the params and returns the actual params to map }
    // This is one of the 'killer features' of this service is that you don't need to listen to the
    // url state yourself to get the latest and greatest request params, and the idea is this concept
    // would be applied also to anything that can be globally gotten - route params, even redux state.
    mapQueryParamsToRequest,
    // This will fire off on every API call made by these hooks immediately after the request is made.
    // This is meant to be used in a static sense, such as to update the URL with the last params
    // used.
    // The first parameter to this callback are the request parameters that were used.
    onCall,
    // This will fire off on every API call made by these hooks. Don't confuse this with the one you
    // pass along when manually executing a request, this is meant to be used in a static sense, such
    // as to update the URL with the last params used
    onComplete: parentOnComplete,
    // This sets if there is any listener and there is an update to the underlying entities, recall
    // the API with the last request's params.
    refreshOnUpdate,
    // See createBaseApiResultHooks for the common params
    ...args
  },
  // An optional function to take in the output of this function and add
  // additional hooks or redux functions to it. Return an object in the same
  // format as the output of this create function.
  // (hooks, additionalInfo) => object
  extendHooks
) => {
  const defaultFlags = refreshOnUpdate ? { refreshOnUpdate } : null;

  const modifyHookConfig = hookConfig => ({
    onCall,
    onComplete: parentOnComplete,
    mapQueryParamsToRequest,
    ...hookConfig
  });

  const applyHookWrapper = (baseHookConfig, flagsFunc) => {
    const hookConfig = modifyHookConfig(baseHookConfig);

    const useListener = (flags) => {
      const { subscribeListener } = useContext(UpdateContext);

      useEffect(
        () => {
          const unsubscribeListener = subscribeListener(
            hookConfig.entryApiResultId,
            flags || defaultFlags,
            hookConfig
          );
          return unsubscribeListener;
        },
        []
      );
    };

    const applyUseListener = (innerFunc) => {
      const listenerWrappedFunc = (...innerFuncArgs) => {
        // Register all hooks with the update service to know which endpoints are still being
        // actively used
        const {
          refreshOnUpdate: listenerRefreshOnUpdate
        } = flagsFunc?.(...innerFuncArgs) || {};

        useListener({ refreshOnUpdate: listenerRefreshOnUpdate });
        return innerFunc(...innerFuncArgs);
      };

      // Create a meta with a flag that this hook is an UpdateService listener
      listenerWrappedFunc.meta = { isProvideListener: true };

      return listenerWrappedFunc;
    };

    return applyUseListener;
  };

  const {
    applyWrapper,
    entryApiResultId,
    hookConfig: baseHookConfig,
    Hooks: BaseHooks,
    Redux
  } = createBaseApiResultHooks({
    ...args,
    applyHookWrapper
  });

  const hookConfig = modifyHookConfig(baseHookConfig);

  // This also marks the associated listener with this that they want refreshOnUpdate
  const useProvideParams = applyWrapper(
    (
      params,
      updateRules
    ) => {
      const { useUpdateParams } = useContext(UpdateContext);

      return useUpdateParams({
        entryApiResultId,
        hookConfig,
        params,
        updateRules
      });
    },
    () => ({ refreshOnUpdate: true })
  );

  const useInvalidateOnMount = applyWrapper(
    (params) => {
      // Hooks
      const { useInvalidateEffect } = useContext(UpdateContext);

      return useInvalidateEffect({
        entryApiResultId,
        ...params
      });
    }
  );

  const output = {
    ...BaseHooks,
    Redux,
    useInvalidateOnMount,
    useProvideParams
  };

  if (extendHooks) {
    applyExtendHooks(
      extendHooks,
      output,
      {
        applyWrapper,
        entryApiResultId,
        hookConfig
      }
    );
  }

  return output;
};

export const useProvideApiResultHooks = createUseApiResultHooks(createProvideApiResultHooks);

export default createProvideApiResultHooks;