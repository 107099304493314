/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import t from 'tcomb-validation';
import { defineSchema } from 'schemas';

const SearchTypes = t.enums({
  Companies: 'Company',
  InvoiceNumber: 'Invoice #',
  PurchaseOrderNumber: 'P.O. #',
  Statuses: 'Statuses'
});

const StatusValues = t.enums({
  Count: 'Count',
  Balance: 'Balance',
  TotalAmount: 'TotalAmount',
  InboxItemCount: 'InboxItemCount',
  InboxActionRequiredCount: 'InboxActionRequiredCount'
});

const InvoiceSearchValues = t.enums({
  companyName: 'companyName',
  companyId: 'companyId',
  addressBookId: 'addressBookId',
  companyNodeId: 'companyNodeId',
  invoiceNumber: 'invoiceNumber',
  purchaseOrderNumber: 'purchaseOrderNumber',
  companyHierarchy: 'companyHierarchy',
  invoiceId: 'invoiceId',
  invoiceType: 'invoiceType',
  isMine: 'isMine'
});

const MatchInfo = t.struct({
  startIndex: t.Number,
  count: t.Number
}, 'MatchInfo');

const SearchMeta = t.struct({
  matchSegments: t.list(MatchInfo)
}, 'SearchMeta');

const SearchValue = t.struct({
  name: t.String,
  value: t.maybe(t.Any),
  meta: t.maybe(SearchMeta)
}, 'SearchValue');

const SearchResult = t.struct({
  name: t.String,
  values: t.list(SearchValue)
}, 'SearchResult');

const SearchGroup = defineSchema({
  name: t.String,
  results: t.list(SearchResult)
}, 'SearchGroup');

export {
  SearchTypes,
  StatusValues,
  MatchInfo,
  SearchMeta,
  SearchValue,
  SearchResult,
  SearchGroup,
  InvoiceSearchValues
};

export function mapSearchResults(entityList, groupName, handler) {
  let results = [];

  if (entityList && groupName && typeof handler === 'function') {
    let group = entityList.find(entityGroup => entityGroup.name === groupName);
    if (group && group.results) {
      results = group.results.map((result) => {
        if (result && result.values) {
          return handler(result, result.values);
        }

        return null;
      }).filter(result => result !== null);
    }
  }

  return results;
}

export function getValueByName(values, valueName) {
  if (values) {
    let foundValue = values.find(value => value.name === valueName);
    if (foundValue) {
      return foundValue.value;
    }
  }

  return null;
}
