/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import t from 'tcomb-validation';
import { defineSchema } from 'schemas';

export const ApprovalDetails = defineSchema({
  approvedCount: t.maybe(t.Number),
  remainingCount: t.maybe(t.Number),
  totalCount: t.maybe(t.Number),
  isAdvanced: t.maybe(t.Boolean),
  approved: t.maybe(t.list(t.struct({
    date: t.maybe(t.Date),
    firstName: t.maybe(t.String),
    lastName: t.maybe(t.String),
    userId: t.maybe(t.String),
    userName: t.maybe(t.String),
    groupId: t.maybe(t.String),
    groupName: t.maybe(t.String)
  }))),
  remaining: t.maybe(t.list(t.struct({
    remainingCount: t.maybe(t.Number),
    totalCount: t.maybe(t.Number),
    groupId: t.maybe(t.String),
    groupName: t.maybe(t.String)
  }))),
  levels: t.maybe(t.list(t.struct({
    remainingCount: t.maybe(t.Number),
    totalCount: t.maybe(t.Number),
    groupId: t.maybe(t.String),
    groupName: t.maybe(t.String)
  })))
}, 'ApprovalDetails');