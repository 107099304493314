/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React from 'react';
import Cookies from 'js-cookie';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router';

import useIsAuthorizedToUseApp from 'hooks/identity/useIsAuthorizedToUseApp';

import LogonLayout from 'views/Logon/LogonLayout';
import LogonWorkflowView from 'views/Logon/Workflow';
import CompleteResetPassword from 'views/Logon/CompleteResetPassword';

import { DefaultAuthRedirect, NotFoundRedirect } from './utils';

const LogonRoutes = () => {
  const { url } = useRouteMatch();
  const isAuthorizedToUseApp = useIsAuthorizedToUseApp();

  const invitationId = Cookies.get('InvitationId');

  return (
    <Switch>
      <Route path={`${url}/index`} exact={true}>
        <Redirect to={url} />
      </Route>
      {isAuthorizedToUseApp ? DefaultAuthRedirect : null}
      <Route path={`${url}/verifyEmailChange`} exact={true}>
        <LogonLayout>
          <LogonWorkflowView invitationId={invitationId} />
        </LogonLayout>
      </Route>
      <Route path={url} exact={true}>
        <LogonLayout>
          <LogonWorkflowView invitationId={invitationId} />
        </LogonLayout>
      </Route>
      <Route path={`${url}/resetPassword`} exact={true}>
        <LogonLayout>
          <LogonWorkflowView invitationId={invitationId} showResetPassword={true} />
        </LogonLayout>
      </Route>
      <Route path={`${url}/resetPassword/:token`} exact={true}>
        <LogonLayout>
          <CompleteResetPassword />
        </LogonLayout>
      </Route>
      {NotFoundRedirect}
    </Switch>
  );
};

export default LogonRoutes;