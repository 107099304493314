/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */
/* eslint max-len:0 */

import { toMessages } from 'config/messages';

export default toMessages('TokenLanding.AcceptVccTerms', {
  AssistedPayHeader: 'Review and Accept Terms for Card Payments',
  AssistedPaySubheader: 'Check the box below and click to accept card payments from {companyName}. Viewpost will assist in processing payments based on the preferences you discussed with our payment specialist. If you have questions, please contact our {supportLink} team.',
  DirectPayHeader: 'Review and Accept Terms for Card Payments',
  DirectPaySubheader: 'Check the box below and click to accept card payments from {companyName}. Payment instructions will be emailed to <b>{remitToEmail}</b>. If you have questions, please contact our {supportLink} team.',
  DirectPayResendHeader: 'Get Your Unique 6-Digit Prefix',
  DirectPayResendSubheader: 'Please review and accept the terms of service below to complete your enrollment and receive the unique 6-digit code that you will use to process payments from {companyName}. If you have questions, contact our {supportLink} team.',
  SupportLink: 'support',
  Terms: '<b>Terms of Service</b>: {paymentTerms}',
  AgreeCheckbox: 'I agree to the terms of service.',
  Submit: 'Accept Terms to Receive Card Payments',
  ResendSubmit: 'Accept Terms and Get 6-Digit Prefix',
  MustCheckValidationError: 'Check this box to proceed',
  MustClickValidationError: 'Click this button to proceed'
});

export const InvalidMessages = toMessages('TokenLanding.AcceptVccTerms.Invalid', {
  Header: 'This is link no longer valid',
  Subheader: 'Reach out to {supportLink} if you need assistance getting access to your 6 digits.'
});

export const ConfirmationMessages = toMessages('TokenLanding.AcceptVccTerms.Confirmation', {
  Header: 'You’re All Set',
  DirectPaySubheader: 'Below is the unique 6-digit {cardBrand} card prefix that you will use to process card transactions from {companyName}. Please save this number.',
  AssistedPaySubheader: 'Viewpost will ensure that payments from {companyName} are processed quickly and efficiently. If you have questions, please contact our {supportLink} team — we’re here to help.',
  SaveWarning: 'Save This Number',
  NowInstructions: '<b>Additional payment instructions have been sent to {email}.</b> If you do not receive the email or if you have questions about payments, please contact {supportLink}.',
  LaterInstructions: '<b>Additional payment instructions have been sent to the email address on file</b>. If you did not receive the email or if you have questions about payments, please contact {supportLink}.'
});

export const ResendMessages = toMessages('TokenLanding.AcceptVccTerms.Resend', {
  Header: 'Your 6-Digit Card Prefix was Sent',
  Subheader: 'An email containing the 6-digit prefix needed to process virtual card payments has been sent to the email address on file. Please save the 6-digit number. You will need it to process future payments. If you have question or need assistance, please contact {supportLink}.'
});