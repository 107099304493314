/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import t from 'tcomb-validation';

import { defineSchema } from 'schemas';
import { LooseAddress } from 'schemas/common/address';

export const SwitchCompanyProfile = defineSchema(
  {
    id: t.String,
    companyName: t.String,
    dbaCompanyName: t.maybe(t.String),
    address: LooseAddress,
    isActiveUser: t.Boolean,
    isOwner: t.Boolean,
    isDefault: t.Boolean
  },
  'SwitchCompanyProfile'
);