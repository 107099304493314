/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React from 'react';
import Footer from 'containers/Footer';
import './index.scss';

const StaticHeaderOnWhiteLayout = ({
  children,
  contentWidth,
  header,
  headerHeight,
  backgroundColor
}) => {
  const bodyStyle = {
    minHeight: `calc(100% - (${headerHeight}px + ${Footer.getHeight()}px))`,
    width: contentWidth,
    marginLeft: 'auto',
    marginRight: 'auto'
  };

  return (
    <div className="static-header-layout" style={{ height: '100%', backgroundColor}}>

      {/*
        HACK to get a non-white background on the entire layout
        in spite of CSS hacks used to make footer 'docked' at bottom
      */}
      {backgroundColor
        ? (
          <div style={{
            backgroundColor,
            position: 'fixed',
            height: '100%',
            width: '100%'
          }}>
            &nbsp;
          </div>
        )
        : null}

      {header}
      <div style={bodyStyle}>
        <div className="content-body" style={{height: '100%'}}>
          {children}
        </div>
      </div>
      <Footer/>
    </div>
  );
};


export default StaticHeaderOnWhiteLayout;
