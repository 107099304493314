/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, { useRef, useState } from 'react';

import WhiteHeaderOnWhiteLayout from 'layouts/WhiteHeaderOnWhiteLayout';
import Button from 'components/Button';
import Tooltip, { Placements } from 'components/Tooltip';
import Icon, { IconTypes } from 'components/Icon';
import LoadingIndicator from 'components/LoadingIndicator';
import Alert from 'components/Alert';
import useWindowWidth from 'hooks/browser/useWindowWidth';

import FormJSX from 'components/Form/FormJSX';
import FieldOption from 'components/Form/FieldOption';

import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';

import useApi from 'api/hooks/useApi';
import { getRemittanceToken } from 'api/onboarding/remittanceLanding';

import t from 'tcomb-validation';
import SingleCharactersTextBox from 'components/Form/Inputs/SingleCharactersTextBox';
import { createLengthRangeStringType, createExactLengthStringType } from 'schemas/common/string';

function getErrorMessage(errorCode) {
  switch (errorCode) {
    case 'InvalidCode': return <div>
      The entered code is invalid.
      <br/><br/>
      The remittance code is a 10-digit, alphanumeric number that is unique to a specific
      payment. You will find the code printed on the top of check statements that are received by mail.
      For additional information about payments, please contact your customer.
    </div>;
    case 'ExpiredCode': return <div>
      The entered code has expired and can no longer be used to view remittance.
      <br/><br/>
      For additional information about this payment, please contact your customer.
    </div>;
    default: return '';
  }
}


const RemittanceLanding = () => {
  const windowWidth = useWindowWidth();
  const dispatch = useDispatch();

  const api = useApi(getRemittanceToken, 'GetRemittanceToken', {
    popupError: false,
    onComplete: (success, action, response) => {
      if (success && response?.token) {
        dispatch(push(`/remittance/${response.token}`));
      }
    }
  });

  const formRef = useRef();
  const [formState, setFormState] = useState();

  const submit = (e) => {
    e?.preventDefault();

    let value = formRef?.current?.getValue();
    if (value) {
      api.call({ code: value.code });
    }
  };

  let isMobile = windowWidth < 800;

  let schema = {
    code: isMobile ? createLengthRangeStringType(10, 12) : createExactLengthStringType(10)
  };

  if (api.isLoading()) {
    return <LoadingIndicator />;
  }

  return (
    <WhiteHeaderOnWhiteLayout>
      <div className="viewstrap">
        <div style={{ textAlign: 'center' }}>
          <div style={{
            maxWidth: '680px',
            width: !isMobile ? '680px' : null,
            display: 'inline-block'
          }}>

            <div style={{ fontSize: '31px', fontWeight: 'bold', marginBottom: '10px' }}>
              Remittance Access
            </div>

            <div style={{textAlign: 'center', fontSize: '16px', lineHeight: '24px', marginBottom: '20px'}}>
              Enter the code found on the top of your printed check statement to view, print, or download
              <br/>
              complete remittance advice for a specific payment.
            </div>

            {api.isError()
              ? (
                <div className="vp-error">
                  {api.getErrorCodes().map((ec, i) => <div key={i}>
                    <Alert variant="failure" style={{textAlign: 'left'}}>
                      {getErrorMessage(ec)}
                    </Alert>
                  </div>)}
                </div>
              )
              : null}

            {/* HACK: position the dashes in (since base input component doesn't give us an easy way to do this) */}
            {!isMobile
              ? (
                <div style={{
                  textAlign: 'center',
                  fontSize: '20px',
                  fontWeight: 'bold',
                  zIndex: 1
                }}>
                  <div style={{
                    position: 'absolute',
                    top: '55px',
                    left: '198px'
                  }}>&ndash;</div>
                  <div style={{
                    position: 'absolute',
                    top: '55px',
                    left: '469px'
                  }}>&ndash;</div>
                </div>
              )
              : null}

            <FormJSX
              ref={formRef}
              onChange={v => setFormState(v)}
              value={formState}
              hasSubmitButton={true}
              modelType={t.struct(schema)}>

              {isMobile
                ? (
                  <FieldOption name="code" cols={12}
                    label="Enter Code"
                    inputProps={{
                      autoFocus: true
                    }} />
                )
                : (
                  <FieldOption name="code" cols={12}
                    label="Enter Code"
                    inputComponent={SingleCharactersTextBox}
                    placeholder="0"
                    inputProps={{
                      autoFocus: true,
                      characterCount: 10,
                      inputStyle: (index) => {
                        if (index === 2 || index === 6) {
                          return {
                            marginRight: '30px'
                          };
                        }
                      }
                    }} />
                )}


              <Button type="submit" style={{ width: '320px', marginTop: '40px' }} onClick={e => submit(e)}>
                Submit Remittance Code
              </Button>
            </FormJSX>

            <div style={{fontSize: '12px', marginTop: '14px'}}>
              Need Help? <Tooltip placement={Placements.Bottom}
                overlay={<div>
                  The remittance code is a 10-digit, alphanumeric number that is unique to a specific
                  payment. You will find the code printed on the top of check statements that are received by mail.
                  For additional information about payments, please contact your customer.
                </div>}>
                <Icon type={IconTypes.Info} size={12} />
              </Tooltip>
            </div>
          </div>
        </div>
      </div>
    </WhiteHeaderOnWhiteLayout>
  );
};

export default RemittanceLanding;