/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, { Component } from 'react';
import classnames from 'classnames';
import GatewayDest from 'components/ReactGatewayDest';
import ProvideIsMobile from 'decorators/ProvideIsMobile';
import Measure from 'react-measure';
import { Gateway } from 'react-gateway';
import AppFloatingActionButton from 'containers/AppFloatingActionButton';
import './index.scss';

/** Only display the FAB on mobile */
const DefaultAppFloatingActionButton = ProvideIsMobile(({
  isMobile
}) => isMobile
  ? <AppFloatingActionButton />
  : null
);

export class FloatingActionBar extends Component {

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentWillMount() {
    if (this.props.isAnimated) {
      let animationDelay = this.props.animationDelay || 1000;
      setTimeout(() => {
        this.setState({
          bottom: 0
        });
      }, animationDelay);
    }
  }

  render() {
    let { children, withPadding = true, addViewstrap, darkBackground, shadow } = this.props;

    let style = null;
    if (this.props.isAnimated) {
      style = {
        bottom: this.state.bottom
      };
    }
    return (
      <Gateway into="stickyBar">
        <div>
          {this.state.height ? <div style={{height: this.state.height}} /> : null}
          <div className={classnames('floating-action-bar', {
            'dark-background': darkBackground,
            'animated': this.props.isAnimated,
            'boxShadow': shadow
          })}
          style={style}>
            <Measure bounds={true} onResize={({ bounds: { height }}) => this.setState({height})}>
              {({ measureRef }) => (
                <div
                  ref={measureRef}
                  className={addViewstrap ? 'viewstrap' : null}
                >
                  <div className={withPadding ? 'with-padding' : null}>
                    {children}
                  </div>
                </div>
              )}
            </Measure>
          </div>
        </div>
      </Gateway>
    );
  }
}

export const FloatingActionBarDest = () => (
  <GatewayDest name="stickyBar" default={<DefaultAppFloatingActionButton />}/>
);
