/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { createApiAction } from 'api/core';

import { Identity } from 'schemas/network/session';
import {
  UseAppAuthorizationReason,
  createUseAppUnauthorizedEntity
} from 'schemas/session/UseAppAuthorizationStatus';

import { createEntity } from 'schemas/state';

const logout = createApiAction(
  {
    method: 'post',
    url: '/ajax/logoff',
    requestEntityMapper: () => ({
      entities: [
        createUseAppUnauthorizedEntity(UseAppAuthorizationReason.LogOut)
      ]
    }),
    responseMapper: () => ({
      entities: [
        // Clear this out since its no longer valid, but after the response
        // comes back to give some time for the app to rerender after the
        // requestEntityMapper had took away access from the app.
        createEntity(
          'current', // only 1 identity globally
          Identity.meta.name,
          null
        )
      ],
      updated: {
        anyOutsideScope: 'global',
        reason: 'Deleted'
      }
    }),
    acceptEmptyResponse: true
  },
  // Still used by reducers:
  //   session
  //   settings.AddCheckingAccount,
  //   isUserNowUnauthenticated helper function
  //   isNukeStateAction helper function
  'logout'
);

export default logout;