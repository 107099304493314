/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, { Children } from 'react';
import { FormattedMessage } from 'react-intl';
import { isNil } from 'lodash';
import createSecurityQuestionsForm from 'containers/Settings/SecurityQuestionsForm';
import createTwoStepSetupForm from 'containers/Settings/TwoStepVerification';
import { ModalBody, ModalFooter, ModalFooterButton } from 'components/Modal';
import HeaderText from 'components/HeaderText';
import BodyText from 'components/BodyText';
import Border from 'components/Border';
import Button from 'components/Button';
import createSecureAccountToggle from './Toggle';
import Messages from './index.messages';
import connect from './index.connect';
import './index.scss';

const SecureAccountModalWorkflowWrapper = ({
  isTwoFactor,
  toggleMethod,
  workflow,
  twoFactorInstructions,
  securityQuestionsInstructions,
  children
}) => {
  const toggleLink = (
    <Button anchor={true} onClick={toggleMethod}>
      {isTwoFactor
        ? <FormattedMessage {...Messages.twoFactor.SecurityQuestionsLink} />
        : <FormattedMessage {...Messages.securityQuestions.TwoFactorLink} />}
    </Button>
  );

  const instructions = isTwoFactor
    ? twoFactorInstructions || Messages.TwoFactorInstructions
    : securityQuestionsInstructions || Messages.SecurityQuestionsInstructions;

  return (
    <div>
      <ModalBody style={{paddingBottom: 0}}>
        <HeaderText>
          <FormattedMessage {...Messages.Header} />
        </HeaderText>
        <BodyText>
          <FormattedMessage {...instructions} values={{toggleLink}}/>
        </BodyText>
      </ModalBody>
      {Children.only(children)}
    </div>
  );
};

const TwoStepForm = ({
  isSendCodeDisabled,
  workflow,
  canCancel,
  onCancel,
  isVerifying,
  children,
  sendVerifyCode,
  getValue
}) => {
  let { parameters } = workflow || {};
  let cancelButtonMessage = parameters && parameters.cancelButtonMessage
    ? parameters.cancelButtonMessage : Messages.buttons.Cancel;

  return (
    <div className="two-step-workflow-step">
      <ModalBody>
        <Border addPadding={false} className="pad-12">
          {Children.only(children)}
        </Border>
      </ModalBody>
      <ModalFooter>
        {canCancel
          ? <ModalFooterButton onClick={onCancel} isRight={false}>
            <FormattedMessage {...cancelButtonMessage} />
          </ModalFooterButton>
          : null}
        <ModalFooterButton onClick={sendVerifyCode} isSubmit={true}
          isDisabled={isSendCodeDisabled || isVerifying}
          isProcessing={isVerifying}>
          <FormattedMessage {...Messages.buttons.Continue} />
        </ModalFooterButton>
      </ModalFooter>
    </div>
  );
};

const SecurityQuestionsForm = ({
  onSubmitQuestions,
  isLoading,
  isSubmitButtonDisabled,
  workflow,
  canCancel,
  onCancel,
  children
}) => {
  let { parameters } = workflow || {};
  let cancelButtonMessage = parameters && parameters.cancelButtonMessage
    ? parameters.cancelButtonMessage : Messages.buttons.Cancel;
  return (
    <div>
      <ModalBody>
        {Children.only(children)}
      </ModalBody>
      <ModalFooter>
        {canCancel
          ? <ModalFooterButton onClick={onCancel} isRight={false}>
            <FormattedMessage {...cancelButtonMessage} />
          </ModalFooterButton>
          : null}
        <ModalFooterButton
          onClick={onSubmitQuestions}
          isSubmit={true}
          isDisabled={isSubmitButtonDisabled || isLoading}
          isProcessing={isLoading}
        >
          <FormattedMessage {...Messages.buttons.Continue} />
        </ModalFooterButton>
      </ModalFooter>
    </div>
  );
};

const BaseSecureAccountModalWorkflowStep = createSecureAccountToggle({
  ParentWrappingComponent: SecureAccountModalWorkflowWrapper,
  TwoStepForm: createTwoStepSetupForm(TwoStepForm),
  SecurityQuestionsForm: createSecurityQuestionsForm(SecurityQuestionsForm)
});

const SecureAccountModalWorkflowStep = ({
  onComplete: originalOnComplete,
  onClose,
  loadIdentity,
  ...props
}) => {
  const onComplete = (didCancel) => {
    loadIdentity();
    onClose(didCancel);
    if (!didCancel && originalOnComplete) {
      originalOnComplete();
    }
  };

  const canCancel = props.workflow && !isNil(props.workflow.parameters.canCancel)
    ? props.workflow.parameters.canCancel
    : props.canCancel;

  return (
    <BaseSecureAccountModalWorkflowStep
      onComplete={() => onComplete()}
      onCancel={() => onComplete(true)}
      canCancel={canCancel}
      {...props}
    />
  );
};

export const connectSecureAccountSingleStep = connect;

export default connect(SecureAccountModalWorkflowStep);
