/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { createApiAction } from 'api/core';

import actionNumberToAction from './mappers/actionNumberToAction';
import mapSecurityQuestions from './mappers/mapSecurityQuestions';

const securityQuestionSubmitResetPasswordRequest = createApiAction({
  method: 'post',
  url: '/ajax/resetPassword/securityChallenge',
  requestBodyMapper: ({
    answers,
    token
  }) => ({
    token,
    securityAnswers: answers.map(({
      answer,
      questionId
    }) => ({
      answer,
      questionId
    }))
  }),
  responseMapper: ({
    success,
    response
  }) => {
    if (!success || !response) return {};

    const {
      action,
      isLocked,
      isValid,
      newPasswordToken
    } = response;

    if (isLocked) {
      return {
        result: { failure: 'Locked' }
      };
    }

    if (isValid != null && !isValid) {
      return {
        result: {
          action: 'SecurityQuestionAuthRequired',
          newPasswordToken: newPasswordToken,
          securityQuestion: {
            questions: mapSecurityQuestions(response),
            reason: 'InvalidAnswer'
          }
        }
      };
    }

    return {
      result: {
        action: actionNumberToAction(action, newPasswordToken),
        newPasswordToken: newPasswordToken
      }
    };
  }
});

export default securityQuestionSubmitResetPasswordRequest;