/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, { Component } from 'react';

export default (BaseComponent) => {
  return class extends Component {
    static displayName = 'WatchWindowSizeWrapper';

    constructor(props) {
      super(props);
      this.state = {
        wrappedProps: {}
      };
      this.handleResize = this.handleResize.bind(this);
    }

    // Add our resize sensor.
    handleResize() {
      if (this.wrapped && this.wrapped.onWindowSizeChange) {
        let props = this.wrapped.onWindowSizeChange(window.innerWidth);
        if (props) {
          this.setState({
            wrappedProps: props
          });
        }
      }
    }

    componentDidMount() {
      window.addEventListener('resize', this.handleResize);
      this.handleResize();
    }

    componentWillUnmount() {
      window.removeEventListener('resize', this.handleResize);
    }

    render() {
      return <BaseComponent ref={r => this.wrapped = r} {...this.props} {...this.state.wrappedProps} />;
    }
  };
};
