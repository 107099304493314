/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import { createCardTokenAsync } from 'api/stripe';

import Button from 'components/Button';
import FormJSX, { FieldOption } from 'components/Form/FormJSX';
import CreditCardNumberTextBox from 'components/Form/Inputs/CreditCardNumberTextBox';
import InfoTooltip from 'components/InfoTooltip';
import { SmallBannerNotification, NotificationStyleTypes } from 'components/Notification';

import MonthlyPaymentDescriptionPanel from 'containers/Subscription/MonthlyPaymentDescriptionPanel';

import { StripeSubscriptionPaymentForm } from 'schemas/payments/stripeAccount';

import Messages from './index.messages';

const SubscriptionPaymentForm = ({
  isLoading,
  onSubmit: onSubmitProp,
  subscription
}) => {
  // Hooks
  const dispatch = useDispatch();
  const formRef = useRef();

  const [ formState, setFormState ] = useState();
  const [ isCreatingToken, setIsCreatingToken ] = useState();
  const [ stripeError, setStripeError ] = useState();

  const onSubmit = async () => {
    const value = formRef.current.getValue();

    if (value) {
      setIsCreatingToken(true);
      setStripeError();
      const [ success, action ] = await dispatch(createCardTokenAsync({
        cardNumber: value.cardNumber,
        expirationDate: value.expirationDate,
        cvc: value.cvc,
        cardholderName: value.cardholderName,
        billingPostalCode: value.postalCode
      }));

      setIsCreatingToken(false);

      if (!success) {
        setStripeError(action.payload.error.message ?? true);
        return;
      }

      onSubmitProp?.(action.payload.id);
    }
  };

  // Render
  return (
    <div>
      <MonthlyPaymentDescriptionPanel
        billingDate={new Date()}
        amount={subscription.amount}
      />
      {stripeError ? (
        <SmallBannerNotification
          className="margin-12-y"
          type={NotificationStyleTypes.Warning}
          shakeOnEnter={true}
        >
          {stripeError === true
            ? <Messages.DefaultStripeError.Message />
            : stripeError}
        </SmallBannerNotification>
      ) : null}
      <FormJSX
        ref={formRef}
        modelType={StripeSubscriptionPaymentForm}
        value={formState}
        onChange={v => setFormState(v)}
      >
        <FieldOption
          name="cardNumber"
          label={Messages.CreditCardNumber}
          inputComponent={CreditCardNumberTextBox}
          cols={12}
          inputProps={{
            appendIcon: (
              <InfoTooltip tooltipWrapperStyle={{ position: 'absolute', right: 10, top: 7 }}>
                <Messages.CardNumberTooltip.VpMessage />
              </InfoTooltip>
            )
          }}
        />
        <FieldOption
          name="expirationDate"
          label={Messages.Expiration}
          cols={4}
        />
        <FieldOption
          name="cvc"
          label={Messages.Cvc}
          cols={4}
          appendIcon={(
            <InfoTooltip tooltipWrapperStyle={{ position: 'absolute', right: 10, top: 7 }}>
              <Messages.CvcTooltip.VpMessage />
            </InfoTooltip>
          )}
        />
        <FieldOption
          name="postalCode"
          label={Messages.PostalCode}
          cols={4}
        />
        <FieldOption
          name="cardholderName"
          label={Messages.CardholderName}
          cols={12}
        />
      </FormJSX>
      <Button
        type="submit"
        expand={true}
        onClick={onSubmit}
        isProcessing={isLoading || isCreatingToken}
        isDisabled={isLoading || isCreatingToken}
      >
        <Messages.SubmitButton.Message />
      </Button>
    </div>
  );
};

export default SubscriptionPaymentForm;
