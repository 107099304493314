/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, { useMemo, useRef, useState } from 'react';
import t from 'tcomb-validation';

import RegistrationApi from 'api/registration';

import Button from 'components/Button';
import PasswordStrengthTextBox from 'components/Form/Inputs/PasswordStrengthTextBox';
import PasswordToggleTextBox from 'components/Form/Inputs/PasswordToggleTextBox';
import FormV2, { FieldOption } from 'components/Form/V2';
import InvalidTokenInline from 'components/InvalidToken/Inline';
import { SmallBannerNotification, NotificationStyleTypes } from 'components/Notification';

import { TermsOfUse, PaymentTerms, PrivacyPolicy } from 'config/messageLinks';

import { ErrorMessages } from 'containers/Registration/BasicRegistrationWorkflow/index.messages';

import createExecuteApiResultHooks from 'hooks/apiResult/createExecuteApiResultHooks';
import useFormatMessage from 'hooks/intl/useFormatMessage';
import { useRegistrationMetadata } from 'hooks/registration';

import { createMatchingPassword, Password } from 'schemas/common/password';

import Messages from './index.messages';

const RegistrationErrorNotification = ({ error, style }) => {
  if (!error) return <></>;

  const ErrorMessage = ErrorMessages[error] || ErrorMessages.Default;

  return (
    <div style={style}>
      <SmallBannerNotification type={NotificationStyleTypes.Warning} shakeOnEnter={true}>
        <ErrorMessage.Message />
      </SmallBannerNotification>
    </div>
  );
};

const RegisterUserAccountHooks = createExecuteApiResultHooks({
  endpoint: RegistrationApi.registerNewAccount
});

const UserRegistrationWorkflow = () => {
  // Hooks
  const formRef = useRef();
  const { invitationId, isValid: isValidInvitation } = useRegistrationMetadata();
  const formatMessage = useFormatMessage();

  const [ formState, setFormState ] = useState({});

  const registerUserAccount = RegisterUserAccountHooks.useExecuteRequest();
  const isSubmitting = RegisterUserAccountHooks.useIsLoading();
  const registerError = RegisterUserAccountHooks.useRequestError();

  const onCreateAccount = useMemo(
    () => (e) => {
      e.preventDefault();

      const formValue = formRef.current.getValue();

      if (formValue) {
        registerUserAccount({
          invitationId,
          password: formValue.password
        });
      }
    },
    [ invitationId ]
  );

  const modelType = useMemo(
    () => t.struct({
      password: Password,
      verifyPassword: createMatchingPassword(formState.password)
    }),
    [ formState.password ]
  );

  // Render
  if (!isValidInvitation) {
    return <InvalidTokenInline />;
  }

  return (
    <>
      <RegistrationErrorNotification error={registerError} />
      <FormV2
        ref={formRef}
        className="registrationForm"
        onChange={setFormState}
        modelType={modelType}
        value={formState}
      >
        <FieldOption
          name="password"
          label={<Messages.Password.Message/>}
        >
          <PasswordStrengthTextBox
            isToggleEnabled={true}
            placeholder={formatMessage(Messages.PasswordPlaceholder)}
            variant="tooltip"
          />
        </FieldOption>
        <FieldOption
          name="verifyPassword"
          label={<Messages.ConfirmPassword.Message/>}
        >
          <PasswordToggleTextBox />
        </FieldOption>
      </FormV2>
      <Button
        analyticsId="RegistrationButton"
        expand={true}
        type="submit"
        isDisabled={isSubmitting}
        isProcessing={isSubmitting}
        onClick={onCreateAccount}
      >
        <Messages.Submit.Message />
      </Button>
      <div style={{ textAlign: 'center', padding: 16 }}>
        <Messages.Disclaimer.Message
          tou={TermsOfUse}
          paymentTerms={PaymentTerms}
          privacyPolicy={PrivacyPolicy}
        />
      </div>
    </>
  );
};

export default UserRegistrationWorkflow;
