/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { useIntl } from 'react-intl';

const useFormatMessage = () => {
  const intl = useIntl();

  const formatMessage = (
    descriptor,
    values
  ) => intl.formatMessage(descriptor, values);

  return formatMessage;
};

export default useFormatMessage;