/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, { useEffect } from 'react';
import moment from 'moment';
import SimpleModal from 'components/Modal/Simple';
import HeaderText from 'components/HeaderText';
import BodyText from 'components/BodyText';
import FormattedDate from 'components/FormattedDate';
import { addModal } from 'config/modals/registry';
import connect from './index.connect';
import Messages, {
  IntroMessages,
  NoAction1Messages,
  NoAction2Messages,
  ActionTaken1Messages,
  ActionTaken2Messages,
  ExpiredMessages
} from './index.messages';
import { Notifications } from './index.utils';

const Config = {
  [Notifications.Intro]: {
    header: IntroMessages.Header.Message,
    subheader: IntroMessages.Subheader.Message,
    message: IntroMessages.Instructions.Message,
    actions: [
      'CreateInvoice',
      'SendPayment'
    ]
  },
  [Notifications.NoAction1]: {
    header: NoAction1Messages.Header.Message,
    subheader: NoAction1Messages.Subheader.Message,
    message: NoAction1Messages.Instructions.Message,
    actions: [
      'CreateInvoice',
      'SendPayment'
    ]
  },
  [Notifications.NoAction2]: {
    header: NoAction2Messages.Header.Message,
    subheader: NoAction2Messages.Subheader.Message,
    message: NoAction2Messages.Instructions.Message,
    actions: [
      'CreateInvoice',
      'SendPayment'
    ]
  },
  [Notifications.ActionTaken1]: {
    header: ActionTaken1Messages.Header.Message,
    subheader: ActionTaken1Messages.Subheader.Message,
    message: ActionTaken1Messages.Instructions.Message,
    actions: [
      'Subscribe',
      'ContinueWithTrial'
    ]
  },
  [Notifications.ActionTaken2]: {
    header: ActionTaken2Messages.Header.Message,
    subheader: ActionTaken2Messages.Subheader.Message,
    message: ActionTaken2Messages.Instructions.Message,
    actions: [
      'Subscribe'
    ]
  },
  [Notifications.Expired]: {
    header: ExpiredMessages.Header.Message,
    subheader: ExpiredMessages.Subheader.Message,
    message: ExpiredMessages.Instructions.Message,
    actions: [
      'Subscribe'
    ]
  }
};

const createActions = (
  actions,
  {
    onClose,
    onCreateInvoice,
    onSendPayment,
    onSubscribe
  }
) => actions.reduce((val, action) => {
  if (action === 'Subscribe') {
    return {
      ...val,
      submit: {
        messageComponent: <Messages.Subscribe.Message />,
        onClick: () => {
          onClose();
          onSubscribe();
        }
      }
    };
  }

  if (action === 'CreateInvoice') {
    return {
      ...val,
      submit: {
        messageComponent: <Messages.CreateInvoice.Message />,
        onClick: () => {
          onClose();
          onCreateInvoice();
        }
      }
    };
  }

  if (action === 'SendPayment') {
    return {
      ...val,
      close: {
        messageComponent: <Messages.SendPayment.Message />,
        onClick: () => {
          onClose();
          onSendPayment();
        }
      }
    };
  }

  if (action === 'ContinueWithTrial') {
    return {
      ...val,
      close: {
        messageComponent: <Messages.ContinueWithTrial.Message />,
        onClick: onClose
      }
    };
  }

  return val;
}, {});

const FreeTrialNotificationsModal = ({
  onClose,
  onCreateInvoice,
  onSendPayment,
  onSubscribe,
  nextBillingDate,
  notification,
  portalType,
  updateNotification
}) => {
  useEffect(() => {
    updateNotification(notification);
  }, []);

  const {
    header: Header,
    subheader: Subheader,
    message: Message,
    actions
  } = Config[notification];

  const date = <FormattedDate value={nextBillingDate} />;
  const daysDiff = moment(nextBillingDate).diff(moment().startOf('day'), 'days');

  return (
    <SimpleModal
      header={<Header days={daysDiff} date={date} />}
      submit={false}
      close={false}
      {...createActions(
        actions,
        {
          onClose,
          onCreateInvoice,
          onSendPayment,
          onSubscribe
        }
      )}
      floatingClose={{ onClick: onClose }}
    >
      <HeaderText style={{ padding: '8px 0' }}>
        <Subheader days={daysDiff} />
      </HeaderText>
      <BodyText>
        <Message date={date} portalType={portalType} />
      </BodyText>
    </SimpleModal>
  );
};

export default addModal(
  'FreeTrialNotificationsModal',
  connect(FreeTrialNotificationsModal),
  { wide: true }
);
