/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React from 'react';
import GatewayDest from 'components/ReactGatewayDest';
import { Gateway } from 'react-gateway';

const FloatingAppContent = ({
  children
}) => (
  <Gateway into="floatingAppContent">
    <div className="viewstrap">
      {children}
    </div>
  </Gateway>
);

export default FloatingAppContent;

export const FloatingAppContentDest = () => (
  <GatewayDest name="floatingAppContent" />
);
