/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import moment from 'moment';
import { isDate } from 'lodash';

// JS Date -> serialized DateTime
function apiDateMapper(date) {
  if (!date) {
    return null;
  }
  if (!isDate(date)) {
    throw new Error(`API Date Mapper cannot map non-date value: ${date}`);
  }
  return moment(date).format('YYYY-MM-DDTHH:mm:ss');
}

export default apiDateMapper;