/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { shallowEqual, useSelector } from 'react-redux';
import { isAuthorizedToUseApp } from 'actions/common';
import { isPortalExperience } from 'actions/common/companySettings';

const useIsPortalUser = () => useSelector(
  (state) => {
    const isFullyAuthenticated = isAuthorizedToUseApp(state);

    return isFullyAuthenticated && isPortalExperience(state);
  },
  shallowEqual
);

export default useIsPortalUser;