/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */
/* eslint max-len:0 */

import { toMessages } from 'config/messages';

export default toMessages('AddSignatureAndTinForm', {
  AuthorizedSigner: 'Authorized Signer',
  TaxIdNumber: 'Tax ID Number (TIN, EIN)',
  TaxIdTooltip: 'Please enter the taxpayer identification number (TIN) for the company you are registering.  This may either be your employer identification number (EIN) or, in some instances, such as for a sole proprietor, may be your social security number (SSN).',
  Signature: 'Authorized Signature'
});
