/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import t from 'tcomb-validation';

import { defineSchema } from 'schemas';
import CompanyActions from 'schemas/network/CompanyActions';
import ConnectionStatusEnum from 'schemas/network/ConnectionStatusEnum';
import { EntityReference } from 'schemas/state';

const Company = defineSchema({
  id: t.String,
  profile: t.maybe(EntityReference),
  vendor: t.maybe(EntityReference),
  customer: t.maybe(EntityReference),
  sentConnectionRequest: t.maybe(EntityReference),
  receivedConnectionRequest: t.maybe(EntityReference),
  availableActions: t.list(CompanyActions),
  status: t.maybe(ConnectionStatusEnum),
  clientReferenceId: t.maybe(t.String),
  numberOfConnections: t.maybe(t.Number),
  requiresSubscription: t.maybe(t.Boolean)
}, 'Company');

export default Company;