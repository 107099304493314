/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import qs from 'qs';

import {
  ENTITY_ACTION_PREFIX,
  isApiAction,
  isEntityAction,
  isFailAction,
  isFetchAction,
  isSuccessAction
} from './constants';

// These long lived in this file but were moved for dependency graph reasons
// Maybe at some point move the users of these to their new location.
export {
  isApiAction,
  isEntityAction,
  isFailAction,
  isFetchAction,
  isSuccessAction
};

export const createUrl = (
  urlTemplate,
  apiParams,
  urlParamMapper,
  queryStringMapper
) => {
  let url = urlTemplate;
  if (!url) throw new Error('Url is not defined!');

  // ROUTE PARAM MAPPING (via url syntax like :paramName)
  let urlParams = {
    ...apiParams,
    ...(urlParamMapper ? urlParamMapper(apiParams) : {})
  };
  for (let paramName in urlParams) {
    if (urlParams.hasOwnProperty(paramName)) {
      url = url.replace(new RegExp(`(\\(:${paramName}\\)|:${paramName})`, 'g'), urlParams[paramName]);
    }
  }

  // QUERY STRING MAPPING
  if (queryStringMapper) {
    let queryStringParams = queryStringMapper(apiParams);
    url += `?${qs.stringify(queryStringParams, {
      // configures so arrays serialize as a duplicated query string param
      // e.g. a = [1,2,3] -> ?a=1&a=2&a=3
      arrayFormat: 'repeat'
    })}`;
  }

  return url;
};

export const createSimpleUrl = (configuration = {}, params = {}) => createUrl(
  configuration.url,
  params,
  configuration.urlParamMapper,
  configuration.queryStringMapper
);

export const createRequestBody = (apiParams, requestBodyMapper) => requestBodyMapper
  ? requestBodyMapper(apiParams)
  : null;

export const CreateEntityActionType = `${ENTITY_ACTION_PREFIX}CREATE`;

export const createEntityAction = (entities) => {
  return {
    type: CreateEntityActionType,
    payload: {
      entities,
      references: entities.map(entity => entity.createReference())
    },
    meta: {}
  };
};

export const UpdateEntityActionType = `${ENTITY_ACTION_PREFIX}UPDATE`;

export const updateEntityAction = (entities) => {
  return {
    type: UpdateEntityActionType,
    payload: {
      entities
    },
    meta: {}
  };
};

export const markEntitiesUpdatedAction = (references, reason) => ({
  type: UpdateEntityActionType,
  payload: {
    updated: [{
      reason,
      entityRefs: references
    }]
  }
});

export const markEntityTypeUpdated = (entityType, reason) => ({
  type: UpdateEntityActionType,
  payload: {
    updated: [{
      reason,
      entityType
    }]
  }
});

export const markUpdated = updated => ({
  type: UpdateEntityActionType,
  payload: {
    updated: [ updated ]
  }
});

export const deleteEntitiesAction = references => markEntitiesUpdatedAction(references, 'Deleted');
