/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import t from 'tcomb-validation';
import { addSchema, defineSchema } from 'schemas';
import { InvoiceActions, InvoiceRecurrenceActions, InvoiceCommentActions } from 'config/constants';
import NetworkActions from 'config/constants/network/NetworkActions';
import { SchemaInvoiceType } from 'config/constants/invoice';
import { EntityReference } from 'schemas/state';
import { Currency, currencyBounded } from 'schemas/common/currency';
import { RetrievableAddress } from 'schemas/common/address';
import { createPercentNotZeroMaxDigits, createPercentBetween } from 'schemas/common/percent';
import { createMaxLengthStringType } from 'schemas/common/string';
import { CurrentOrFutureDate } from 'schemas/common/date';
import { HighlightMatches } from 'schemas/common/highlight';
import PaymentMethodTypes from 'schemas/payments/PaymentMethodTypes';
import {
  createPositiveMaxDigitsAndDecimalsNumberType,
  createBoundedWholeNumber,
  createNumberWithUnits,
  PositiveWholeNumber
} from 'schemas/common/number';
import CompanyOrganizationNode from 'schemas/network/CompanyOrganizationNode';
import SelectCompany from 'schemas/network/SelectCompany';
import SelectedCompanyOrganizationNode from 'schemas/network/SelectedCompanyOrganizationNode';
import { PaymentTracker } from 'schemas/payments/tracker';

const InvoiceStatus = t.enums({
  None: 'None',
  Draft: 'Draft',
  New: 'New',
  Sent: 'Sent',
  InReview: 'InReview',
  Approved: 'Approved',
  Disputed: 'Disputed',
  PartiallyPaid: 'PartiallyPaid',
  Closed: 'Closed',
  Pending: 'Pending',
  Void: 'Void',
  Rejected: 'Rejected',
  Scheduled: 'Scheduled',
  ScheduledRecurring: 'ScheduledRecurring',
  Mapped: 'Mapped',
  Undelivered: 'Undelivered',
  ActionRequired: 'ActionRequired',
  Processing: 'Processing',

  // recurring status
  Expired: 'Expired',
  Paused: 'Paused'
}, 'InvoiceStatus');

const InvoiceRelationship = t.enums({
  Vendor: 'Vendor',
  Customer: 'Customer'
}, 'InvoiceRelationship');

const InvoiceActionRequiredReason = t.enums({
  PendingApprovals: 'PendingApprovals',
  VerificationRequired: 'VerificationRequired'
}, 'InvoiceRequiredActions');

const InsuranceClaimReason = t.enums({
  MyCustomerIsInsolvent: 'My Customer is Insolvent',
  PaymentIsLate: 'Payment is Late',
  ICantReachMyCustomer: `I Can't Reach My Customer`,
  Other: 'Other'
}, 'InsuranceClaimReasons');

const InsuranceStatus = t.enums({
  Activated: 'Activated',
  Claimed: 'Claimed',
  Pending: 'Pending',
  Rejected: 'Rejected',
  Settled: 'Settled'
});

const InvoiceAction = t.enums({
  [InvoiceActions.Approve]: InvoiceActions.ApproveInvoice,
  [InvoiceActions.ApproveWithChanges]: InvoiceActions.ApproveWithChanges,
  [InvoiceActions.BatchApproveAndPaymentQueue]: InvoiceActions.BatchApproveAndPaymentQueue,
  [InvoiceActions.ApproveAndQuickPay]: InvoiceActions.ApproveAndQuickPay,
  [InvoiceActions.ApproveAndPaymentQueue]: InvoiceActions.ApproveAndPaymentQueueInvoice,
  [InvoiceActions.Comment]: InvoiceActions.CommentInvoice,
  [InvoiceActions.Copy]: InvoiceActions.CopyInvoice,
  [InvoiceActions.UpdateRecurrence]: InvoiceActions.UpdateRecurrence,
  [InvoiceActions.StartRecurrence]: InvoiceActions.StartRecurrence,
  [InvoiceActions.Delete]: InvoiceActions.DeleteInvoice,
  [InvoiceActions.Dispute]: InvoiceActions.DisputeInvoice,
  [InvoiceActions.Edit]: InvoiceActions.EditInvoice,
  [InvoiceActions.EndDispute]: InvoiceActions.EndDisputeInvoice,
  [InvoiceActions.Link]: InvoiceActions.LinkInvoice,
  [InvoiceActions.AddToPaymentQueue]: InvoiceActions.AddToPaymentQueue, // Payment Queue
  [InvoiceActions.QuickPay]: InvoiceActions.QuickPay,
  [InvoiceActions.RecordPayment]: InvoiceActions.RecordPayment,
  [InvoiceActions.MarkAsPaid]: InvoiceActions.MarkAsPaid,
  [InvoiceActions.Reject]: InvoiceActions.RejectInvoice,
  [InvoiceActions.SaveAsPdf]: InvoiceActions.SaveAsPdfInvoice,
  [InvoiceActions.SendToPrinter]: InvoiceActions.SendToPrinter,
  [InvoiceActions.Send]: InvoiceActions.Send,
  [InvoiceActions.SendAndQuickPay]: InvoiceActions.SendAndQuickPay,
  [InvoiceActions.Share]: InvoiceActions.ShareInvoice,
  [InvoiceActions.Unlink]: InvoiceActions.UnlinkInvoice,
  [InvoiceActions.Unvoid]: InvoiceActions.UnvoidInvoice,
  [InvoiceActions.ViewApprovalRequests]: InvoiceActions.ViewApprovalRequests,
  [InvoiceActions.ViewAttachments]: InvoiceActions.ViewAttachments,
  [InvoiceActions.Void]: InvoiceActions.VoidInvoice,
  [InvoiceActions.VpxCancel]: InvoiceActions.VpxCancel,
  [InvoiceActions.VpxRequest]: InvoiceActions.VpxRequest,
  [InvoiceActions.CreateLateFee]: InvoiceActions.CreateLateFee,
  [InvoiceActions.RemoveLateFee]: InvoiceActions.RemoveLateFee,
  [InvoiceActions.RecordPayment]: InvoiceActions.RecordPayment,
  [InvoiceActions.RespondToApprovalRequest]: InvoiceActions.RespondToApprovalRequest,
  [InvoiceActions.RouteForApproval]: InvoiceActions.RouteForApproval,
  [InvoiceActions.RouteForAdditionalApproval]: InvoiceActions.RouteForAdditionalApproval,
  [InvoiceActions.Edit]: InvoiceActions.Edit,
  [InvoiceActions.SetUpAutoPay]: InvoiceActions.SetUpAutoPay,
  [InvoiceActions.EditAutoPay]: InvoiceActions.EditAutoPay,
  [InvoiceActions.GetInvoiceInsuranceQuote]: InvoiceActions.GetInvoiceInsuranceQuote,
  [InvoiceActions.PurchaseInvoiceInsurance]: InvoiceActions.PurchaseInvoiceInsurance,
  [InvoiceActions.SendReminder]: InvoiceActions.SendReminder,
  // Used for the early pay because there is a workflow that the invoice may need
  // an email address added
  [NetworkActions.UpdateAddressBook]: NetworkActions.UpdateAddressBook
}, 'InvoiceAction');

const InvoiceType = t.enums({
  [SchemaInvoiceType.Payable]: SchemaInvoiceType.Payable,
  [SchemaInvoiceType.Receivable]: SchemaInvoiceType.Receivable,
  [SchemaInvoiceType.OutOfBandInvoice]: SchemaInvoiceType.OutOfBandInvoice,
  [SchemaInvoiceType.Series]: SchemaInvoiceType.Series,
  [SchemaInvoiceType.APInboxItem]: SchemaInvoiceType.APInboxItem,
  [SchemaInvoiceType.APInboxItemBill]: SchemaInvoiceType.APInboxItemBill,

  // identifies a LogicalDocument that could be a Payable AND/OR Receivable
  [SchemaInvoiceType.LogicalDocument]: SchemaInvoiceType.LogicalDocument
}, 'InvoiceType');

// a VPX discount (generally at today's rate)
const VpxDiscount = t.struct({

  /* fields for SPECIFIC discount for specific day */

  // specific date that this discount applies to
  date: t.maybe(t.Date),
  // how much money we'd save with this discount
  discountAmount: t.maybe(t.Number),
  // the adjusted balance after this discount
  discountedBalance: t.maybe(t.Number),
  // percentage saved for this date
  discountPercent: t.maybe(t.Number),
  // number of days early the specific discounts are for
  // if not set, implies this discount is if paid today
  // this is usually the number of days before the due date
  discountDays: t.maybe(t.Number),

  // whether or not this is a preferred rate from a customer or just a VP suggestion
  isSuggested: t.maybe(t.Boolean),


  // optional APY
  annualPercentageYield: t.maybe(t.Number),

  /* fields applying to the overall rate for any date */

  // the rate of the actual discount function that determines the per-day discount
  // (e.g. {ratePercent}% / {rateDays} days = dailyPercent})
  ratePercent: t.maybe(t.Number),
  rateDays: t.maybe(t.Number),

  // id of a predefined rate (e.g. the preferred rate)
  rateFunctionId: t.maybe(t.String),

  // how many days before due the discount ends
  discountEndDaysEarly: t.maybe(t.Number),

  isLocked: t.maybe(t.Boolean),
  isAutoAccept: t.maybe(t.Boolean),

  // special case where cancel action can't happen immediately and must be queued (for File Upload users)
  isCancelBlocked: t.maybe(t.Boolean)
}, 'VpxDiscount');

const InvoiceLateFeeType = t.enums({
  Percent: 'Percent',
  FlatFee: 'FlatFee'
}, 'InvoiceLateFeeType');

const InvoiceLateFee = t.struct({

  // per term amount or rate
  amount: t.maybe(t.Number),
  percent: t.maybe(t.Number),

  // total accrued in late fees
  totalAmount: t.maybe(t.Number),
  // total unpaid in late fees
  balance: t.maybe(t.Number),

  type: t.maybe(InvoiceLateFeeType),
  gracePeriod: t.maybe(t.Number)
}, 'InvoiceLateFee');

const InsurancePolicy = t.struct({
  policyId: t.maybe(t.String),
  amount: t.maybe(Currency),
  insurancePolicyStatus: t.maybe(InsuranceStatus),
  isExpired: t.maybe(t.Boolean),
  isClaimable: t.maybe(t.Boolean),
  claimAfterTimestamp: t.maybe(t.Date),
  claimExpirationTimestamp: t.maybe(t.Date),
  claimedTimestamp: t.maybe(t.Date),
  purchasedTimestamp: t.maybe(t.Date)
}, 'InsurancePolicy');

const InvoiceCustomDisplayField = t.struct({
  label: t.maybe(t.String),
  value: t.maybe(t.Any)
}, 'InvoiceCustomDisplayField');

const InvoiceBase = t.struct({
  id: t.maybe(t.String),
  logicalDocumentId: t.maybe(t.String),
  recurrenceId: t.maybe(t.String),

  invoiceNumber: t.maybe(t.String),
  invoiceDate: t.maybe(t.Date),
  dueDate: t.maybe(t.Date),
  purchaseOrderNumber: t.maybe(t.String),
  balance: t.maybe(t.Number),
  totalAmount: t.maybe(t.Number),
  isCreditMemo: t.maybe(t.Boolean),
  isDraftRecurrence: t.maybe(t.Boolean),
  isSync: t.maybe(t.Boolean),
  availableActions: t.list(InvoiceAction),
  status: t.maybe(InvoiceStatus),

  isFinalized: t.maybe(t.Boolean),

  // why the invoice is in an 'Action Required' state/status
  actionRequiredReason: t.maybe(InvoiceActionRequiredReason),

  requiresSubscription: t.maybe(t.Boolean),

  closeDate: t.maybe(t.Date),
  scheduledPayDate: t.maybe(t.Date),
  undeliveredReason: t.maybe(t.String),

  terms: t.maybe(t.String),
  subtotal: t.maybe(t.Number),
  taxAmount: t.maybe(t.Number),

  remitToCompanyName: t.maybe(t.String),
  remitToAddress: t.maybe(RetrievableAddress),
  remitToPhone: t.maybe(t.String),
  billToCompanyName: t.maybe(t.String),
  billToAddress: t.maybe(RetrievableAddress),
  billToPhone: t.maybe(t.String),
  paymentMethodOptions: t.maybe(t.list(PaymentMethodTypes)),

  // a discount that has been requested on this invoice (at today's rate)
  vpxActiveDiscount: t.maybe(VpxDiscount),
  // a discount available for request on this invoice (at today's rate)
  vpxAvailableDiscount: t.maybe(VpxDiscount),

  lateFee: t.maybe(InvoiceLateFee),
  lateFeePaidAmount: t.maybe(t.Number),
  isOverdue: t.maybe(t.Boolean),

  // whether or not I own this invoice
  isMine: t.maybe(t.Boolean),
  documentSource: t.maybe(t.String),

  // whether I am the vendor or customer of this invoice
  myRelationship: t.maybe(InvoiceRelationship),

  // the 'other' company
  companyName: t.maybe(t.String),
  clientReferenceId: t.maybe(t.String),
  addressBookId: t.maybe(t.String),
  companyId: t.maybe(t.String),
  companyNodeId: t.maybe(t.String),
  customerCompanyNodeName: t.maybe(t.String),
  companyNodeHierarchy: t.maybe(t.list(CompanyOrganizationNode)),
  isEmailAddressRequired: t.maybe(t.Boolean),
  companyEmailAddress: t.maybe(t.String),
  companyContactFirstName: t.maybe(t.String),
  companyContactLastName: t.maybe(t.String),

  logoUrl: t.maybe(t.String),

  // attachment from AP Inbox
  primaryAttachmentId: t.maybe(t.String),
  primaryAttachmentPage: t.maybe(t.Number),

  // the other invoice this invoice is linked to
  linkedDocumentId: t.maybe(t.String),
  linkedDocumentType: t.maybe(InvoiceType),
  linkedDocumentStatus: t.maybe(InvoiceStatus),
  hasLinkedDocumentConflict: t.maybe(t.Boolean), // whether or not the linked document has conflicting fields (worthy of UI representation)

  // associated recurrence (Scheduled Drafts and Recurrence Previews)
  sentOccurrences: t.maybe(t.Number),
  remainingOccurrences: t.maybe(t.Number),
  totalOccurrences: t.maybe(t.Number),
  isInfinite: t.maybe(t.Boolean),
  nextOccurrenceDate: t.maybe(t.Date),

  insurancePolicy: t.maybe(InsurancePolicy),

  // platform provides this fuzzy bucket of fields to allow a company to define special fields not offically part of our standard invoice model
  // the hope is that at least calling out these as 'custom fields' will bring more visibility to these special cases when they are used
  // (and prevent us from adding a bunch of company-specific things to the invoice schema)
  customFields: t.maybe(t.Object),

  // custom fields per company intended to be displayed for each invoice
  customDisplayFields: t.maybe(t.list(InvoiceCustomDisplayField)),
  discountStrategyName: t.maybe(t.String),
  entityReference: t.maybe(EntityReference),

  // the search context of why this invoice was returned
  highlightMatches: t.maybe(HighlightMatches)
});

const AnyInvoice = InvoiceBase.extend({
  invoiceType: InvoiceType
});

const DetailedInvoiceLineItem = t.struct({
  lineNumber: t.maybe(t.String),
  vendorItemNumber: t.maybe(t.String),
  customerItemNumber: t.maybe(t.String),
  description: t.maybe(t.String),
  serialNumber: t.maybe(t.String),
  lotNumber: t.maybe(t.String),
  brand: t.maybe(t.String),
  color: t.maybe(t.String),
  size: t.maybe(t.String),
  unitOfMeasure: t.maybe(t.String),
  project: t.maybe(t.String),
  freeOnBoardPoint: t.maybe(t.String),
  countryOfOrigin: t.maybe(t.String),
  purchaseOrderNumber: t.maybe(t.String),
  purchaseOrderLineNumber: t.maybe(t.String),
  salesOrderNumber: t.maybe(t.String),
  salesOrderLineNumber: t.maybe(t.String),

  quantity: t.maybe(t.Number),
  unitPrice: t.maybe(t.Number),
  totalAmount: t.maybe(t.Number),
  subtotal: t.maybe(t.Number),
  shipAmount: t.maybe(t.Number),
  taxPercent: t.maybe(t.Number),
  taxAmount: t.maybe(t.Number),
  isTaxable: t.maybe(t.Boolean),
  charges: t.maybe(t.Number),
  tradeDiscountAmount: t.maybe(t.Number),

  taxDate: t.maybe(t.Date),

  shipToCompanyName: t.maybe(t.String),
  shipToAddress: t.maybe(RetrievableAddress),
  shipToPhone: t.maybe(t.String),
  shipMethod: t.maybe(t.String),
  shipDate: t.maybe(t.Date)
}, 'DetailedInvoiceLineItem');

// contains all the 'kitchen sink' fields that could possibly be rendered for an invoice detail view
const DetailedInvoice = addSchema(AnyInvoice.extend({
  receivableId: t.maybe(t.String),
  payableId: t.maybe(t.String),
  vendorCompanyId: t.maybe(t.String),
  customerCompanyId: t.maybe(t.String),

  lineItems: t.maybe(t.list(DetailedInvoiceLineItem)),

  sentTimestamp: t.maybe(t.Date),
  linkTimestamp: t.maybe(t.Date),

  remitToContactName: t.maybe(t.String),
  billToContactName: t.maybe(t.String),
  shipToCompanyName: t.maybe(t.String),
  shipToAddress: t.maybe(RetrievableAddress),
  shipToPhone: t.maybe(t.String),
  shipToContactName: t.maybe(t.String),

  shipDate: t.maybe(t.Date),
  shipMethod: t.maybe(t.String),
  shipAmount: t.maybe(t.Number),

  prepaidAmount: t.maybe(t.Number),
  tradeDiscountAmount: t.maybe(t.Number),
  charges: t.maybe(t.Number),
  paidAmount: t.maybe(t.Number),
  vpxAppliedDiscountAmount: t.maybe(t.Number),

  taxDate: t.maybe(t.Date),
  taxPercentage: t.maybe(t.String),

  salesperson: t.maybe(t.String),
  salesOrderNumber: t.maybe(t.String),
  project: t.maybe(t.String),
  accountNumber: t.maybe(t.String),
  freeOnBoardPoint: t.maybe(t.String),

  paymentTracker: t.maybe(PaymentTracker),
  creditMemoBalance: t.maybe(t.Number),
  enforceCreditMemoRestrictions: t.maybe(t.Boolean),

  // allow basic related payment info (for Sprocket)
  payments: t.maybe(t.list(t.struct({
    settlementId: t.maybe(t.String),
    referenceNumber: t.maybe(t.String),
    paymentMethodType: t.maybe(t.String)
  }))),

  description: t.maybe(t.String),
  notes: t.maybe(t.String)
}, 'DetailedInvoice'));

const Payable = addSchema(InvoiceBase.extend({
  invoiceType: t.enums.of([InvoiceType.meta.map.Payable])
}, 'Payable'));

const Receivable = addSchema(InvoiceBase.extend({
  invoiceType: t.enums.of([InvoiceType.meta.map.Receivable])
}, 'Receivable'));

const OutOfBandInvoice = addSchema(InvoiceBase.extend({
  invoiceType: t.enums.of([InvoiceType.meta.map.OutOfBandInvoice]),
  receivedDate: t.maybe(t.Date),
  receivedMessage: t.maybe(t.String), // some description from the source (e.g. subject of email, SMS message)
  receivedFrom: t.maybe(t.String), // identifier of who this came from (e.g. email address, SMS number)
  isKeyed: t.maybe(t.Boolean), // has details entered by a user of the company OR a 3rd party
  isCompletelyKeyed: t.maybe(t.Boolean), // has all required details entered by a user of the company or 3rd party
  isVerified: t.maybe(t.Boolean), // is considered verified by a user of the company
  isSenderMapped: t.maybe(t.Boolean), // whether or not the sender is already mapped to a company
  attachmentId: t.maybe(t.String) // id of an attachment that represents the image/document this invoice was keyed from
}, 'OutOfBandInvoice'));

const APInboxRejectReason = t.enums({
  NoBills: 'NoBills', // no bills found
  PartialBills: 'PartialBills', // some bills partially entered but not complete
  AddressConflict: 'AddressConflict', // address on bill doesn't match address book address
  PartialBillsAndAddressConflict: 'PartialBillsAndAddressConflict'
}, 'APInboxRejectReason');

const APInboxItemBill = addSchema(InvoiceBase.extend({
  invoiceType: t.enums.of([InvoiceType.meta.map.APInboxItemBill]),
  endPage: t.maybe(t.Number),
  inboxItemId: t.maybe(t.String)
}, 'APInboxItemBill'));

const APInboxItem = addSchema(InvoiceBase.extend({
  invoiceType: t.enums.of([InvoiceType.meta.map.APInboxItem]),
  receivedDate: t.maybe(t.Date),
  receivedMessage: t.maybe(t.String), // some description from the source (e.g. subject of email, SMS message)
  receivedFrom: t.maybe(t.String), // identifier of who this came from (e.g. email address, SMS number)

  bills: t.list(APInboxItemBill),

  isRejected: t.maybe(t.Boolean),
  rejectReason: t.maybe(APInboxRejectReason),
  canManuallyProcess: t.maybe(t.Boolean)
}, 'APInboxItem'));


const VpxInvoice = addSchema(InvoiceBase.extend({
  // Even if this is a payable, we still want all the other fields
  invoiceType: t.enums.of([InvoiceType.meta.map.Receivable, InvoiceType.meta.map.Payable]),
  payorId: t.maybe(t.String),
  creditMemoBalance: t.maybe(t.Number),
  enforceCreditMemoRestrictions: t.Boolean
}, 'VpxInvoice'));

const VpxBill = InvoiceBase.extend({
  // Even if this is a receivable, we still want all the other fields
  invoiceType: t.enums.of([InvoiceType.meta.map.Receivable, InvoiceType.meta.map.Payable]),
  annualPercentageYield: t.maybe(t.Number)
}, 'VpxBill');

const VpxListItemInvoice = t.struct({
  // payable or receivable id
  invoice: t.maybe(EntityReference),
  eligibleVpxOffer: t.maybe(VpxDiscount),
  pendingVpxOffer: t.maybe(VpxDiscount)
}, 'VpxListItemInvoice');

// action that the recurrence will take when it generates an invoice
const InvoiceRecurrenceInvoiceAction = t.enums({
  CreateAndSend: 'Send Invoice',
  CreateDraft: 'Create Draft'
}, 'InvoiceRecurrenceInvoiceAction');

// actions that can be taken on an invoice recurrence itself
const InvoiceRecurrenceAction = t.enums({
  [InvoiceRecurrenceActions.Preview]: InvoiceRecurrenceActions.Preview,
  [InvoiceRecurrenceActions.Edit]: InvoiceRecurrenceActions.Edit,
  [InvoiceRecurrenceActions.Delete]: InvoiceRecurrenceActions.Delete,
  [InvoiceRecurrenceActions.Pause]: InvoiceRecurrenceActions.Pause,
  [InvoiceRecurrenceActions.Resume]: InvoiceRecurrenceActions.Resume,
  [InvoiceRecurrenceActions.VpxRequest]: InvoiceRecurrenceActions.VpxRequest,
  [InvoiceRecurrenceActions.VpxCancel]: InvoiceRecurrenceActions.VpxCancel,
  [InvoiceRecurrenceActions.Start]: InvoiceRecurrenceActions.Start,
  [InvoiceRecurrenceActions.Copy]: InvoiceRecurrenceActions.Copy
}, 'InvoiceRecurrenceAction');

const InvoiceRecurrenceStatus = t.enums({
  Active: 'Active', // LEGACY
  Paused: 'Paused',
  Expired: 'Expired',
  Draft: 'Draft',
  ScheduledRecurring: 'ScheduledRecurring',
  Unspecified: 'Unknown'
});

const UpdateInvoiceRecurrenceAction = t.enums({
  UpdateTemplate: 'UpdateTemplate',
  UpdateSchedule: 'UpdateSchedule',
  UpdateVpx: 'UpdateVpx',
  CancelVpx: 'CancelVpx',
  UpdateLateFee: 'UpdateLateFee',
  CancelLateFee: 'CancelLateFee',
  LinkInvoice: 'LinkInvoice',
  UnlinkInvoice: 'UnlinkInvoice'
}, 'UpdateInvoiceRecurrenceAction');

const InvoiceRecurrence = defineSchema({
  id: t.String, // recurrence id
  companyName: t.maybe(t.String),
  addressBookId: t.maybe(t.String),
  clientReferenceId: t.maybe(t.String),
  totalAmount: t.maybe(t.Number),
  status: t.maybe(InvoiceRecurrenceStatus),

  frequency: t.maybe(t.Number),
  frequencyUnit: t.maybe(t.String),
  dueOn: t.maybe(t.String),
  useClosestBusinessDay: t.maybe(t.Boolean),
  sendOn: t.maybe(t.String),
  sendOnDayOffset: t.maybe(t.Number),
  sendOnAnchorDay: t.maybe(t.String),
  sendOnDaysOfWeek: t.maybe(t.Array),
  dueOnDayOffset: t.maybe(t.Number),
  dueOnSetTerms: t.maybe(t.Number),
  dueOnAnchorDay: t.maybe(t.String),
  ends: t.maybe(t.String),
  endingDate: t.maybe(t.Date),
  occurrences: t.maybe(t.Number),
  nextProcessDate: t.maybe(t.Date), // start date for recurrence OR next invoice date

  invoiceAction: t.maybe(InvoiceRecurrenceInvoiceAction), // legacy - not needed anymore
  availableActions: t.list(InvoiceRecurrenceAction),

  sentOccurrences: t.maybe(t.Number),
  totalOccurrences: t.maybe(t.Number),
  remainingOccurrences: t.maybe(t.Number),
  isInfinite: t.maybe(t.Boolean),

  // a discount available for request by the vendor
  vpxAvailableDiscount: t.maybe(VpxDiscount),
  // a discount that has been requested/offered to the customer
  vpxActiveDiscount: t.maybe(VpxDiscount),

  lateFee: t.maybe(InvoiceLateFee),

  // next/last invoice fields
  logicalDocumentId: t.maybe(t.String),
  invoiceId: t.maybe(t.String),
  invoiceType: t.maybe(InvoiceType),
  invoiceDate: t.maybe(t.Date),
  invoiceNumber: t.maybe(t.String),
  invoiceStatus: t.maybe(InvoiceStatus)
}, 'InvoiceRecurrence');

const DetailedInvoiceRecurrence = addSchema(InvoiceRecurrence.extend({
  dueDate: t.maybe(t.Date),
  notes: t.maybe(t.String),
  lineItems: t.maybe(t.list(DetailedInvoiceLineItem)),
  purchaseOrderNumber: t.maybe(t.String),
  taxPercentage: t.maybe(t.Number),
  shipAmount: t.maybe(t.Number),
  terms: t.maybe(t.String)
}, 'DetailedInvoiceRecurrence'));

const RecurringInvoiceScheduleInvoice = t.struct({
  masterInvoiceId: t.maybe(t.String),
  receivableId: t.maybe(t.String),
  invoiceNumber: t.maybe(t.String),
  invoiceDate: t.maybe(t.Date),
  dueDate: t.maybe(t.Date),
  terms: t.maybe(t.String),
  invoiceAmount: t.maybe(t.Number),
  status: t.maybe(t.String)
}, 'RecurringInvoiceScheduleInvoice');

export const RecurringInvoiceSchedule = defineSchema({
  id: t.maybe(t.String),
  parameters: t.maybe(t.Object),
  invoices: t.maybe(t.list(RecurringInvoiceScheduleInvoice)),
  summary: t.maybe(t.String),
  emailAddress: t.maybe(t.String),
  isDeleted: t.maybe(t.Boolean),
  totalSentCount: t.maybe(t.Number),
  hasMoreSent: t.maybe(t.Boolean),
  hasMoreFuture: t.maybe(t.Boolean)
}, 'RecurringInvoiceSchedule');

const PurchaseOrderNumber = createMaxLengthStringType(50);

const RequiredFormType = t.enums.of(['po', 'recipient']);

export const CreateInvoicePoRequiredFormSchema = t.struct({
  type: RequiredFormType,
  purchaseOrderNumber: PurchaseOrderNumber
});

export const CreateInvoiceRecipentRequireFormSchema = t.struct({
  type: RequiredFormType,
  recipient: SelectedCompanyOrganizationNode
});

const createCreateInvoiceOptionalFormSchema = (taxRequired) => {
  return t.struct({
    purchaseOrderNumber: t.maybe(PurchaseOrderNumber),
    tax: taxRequired ? createPercentNotZeroMaxDigits(5) : t.maybe(createPercentNotZeroMaxDigits(5)),
    shipAmount: t.maybe(currencyBounded(0, 1000000000)),
    additionalNotes: t.maybe(createMaxLengthStringType(255))
  });
};

const UpdateRecurrenceScheduleFrequencyUnits = t.enums({
  Days: 'Days',
  Weeks: 'Weeks',
  Months: 'Months'
});

const createUpdateRecurrenceScheduleForm = (invoice) => {
  let minBound = invoice.status === InvoiceStatus.meta.map.Draft
    ? 1 // the draft is now included in the count so 0 doesn't make sense
    : 0;
  return t.struct({
    frequency: createNumberWithUnits(PositiveWholeNumber, UpdateRecurrenceScheduleFrequencyUnits),
    occurrences: t.maybe(createBoundedWholeNumber(minBound, 9999)),
    nextProcessDate: CurrentOrFutureDate
  });
};

const CreateInvoiceLineItem = t.struct({
  item: t.maybe(createMaxLengthStringType(50)),
  description: createMaxLengthStringType(240),
  qty: createPositiveMaxDigitsAndDecimalsNumberType(12, 4),
  price: Currency,
  subtotal: t.maybe(Currency),
  tax: t.maybe(t.Boolean)
}, 'CreateInvoiceLineItem');

const EditOutOfBandInvoiceForm = t.struct({
  id: t.String,
  receivedDate: t.maybe(t.Date),
  receivedMessage: t.maybe(t.String),
  receivedFrom: t.maybe(t.String),
  invoiceNumber: t.maybe(createMaxLengthStringType(50)),
  purchaseOrderNumber: t.maybe(createMaxLengthStringType(50)),
  invoiceDate: t.Date,
  dueDate: t.Date,
  terms: t.maybe(createMaxLengthStringType(50)),
  subtotal: t.maybe(Currency),
  taxAmount: t.maybe(Currency),
  totalAmount: Currency,
  companySelection: SelectCompany,
  attachmentId: t.maybe(t.String),
  isSenderMapped: t.maybe(t.Boolean)
}, 'EditOutOfBandInvoiceForm');

const CompanySummary = defineSchema({
  id: t.String, // Address Book Id
  name: t.String,
  clientReferenceId: t.maybe(t.String),
  openAmount: Currency
}, 'CompanySummary');

const InvoiceStatement = t.struct({
  name: t.maybe(t.String),
  invoiceCount: t.maybe(t.Number),
  dueDate: t.maybe(t.Date),
  companyId: t.maybe(t.String),
  addressBookId: t.maybe(t.String),
  companyName: t.maybe(t.String),

  statementDate: t.maybe(t.Date), // displayed statement date (may be created date)
  beginDate: t.maybe(t.Date),
  endDate: t.maybe(t.Date),

  requiresSubscription: t.maybe(t.Boolean),

  balance: t.maybe(t.Number), // balance of current invoices
  previousBalance: t.maybe(t.Number), // balance carried over from previous statements (previousBalanceInvoices)

  invoices: t.list(InvoiceBase),
  // invoices not in this statement (based on date) but previously unpaid, and still maybe reflected on it due to outstanding balance
  previousBalanceInvoices: t.list(InvoiceBase),

  remitToCompanyName: t.maybe(t.String),
  remitToAddress: t.maybe(RetrievableAddress),
  remitToPhone: t.maybe(t.String),
  billToCompanyName: t.maybe(t.String),
  billToAddress: t.maybe(RetrievableAddress),
  billToPhone: t.maybe(t.String),

  logoUrl: t.maybe(t.String)
});

const InvoiceCommentAction = t.enums({
  [InvoiceCommentActions.Edit]: InvoiceCommentActions.Edit,
  [InvoiceCommentActions.Delete]: InvoiceCommentActions.Delete
}, 'InvoiceCommentAction');

export const CreateableInvoiceComment = t.struct({
  message: t.String,
  isPublic: t.maybe(t.Boolean) // whether or not this is visible only internally within the company or to other companies
});

const InvoiceComment = defineSchema({
  id: t.maybe(t.String),
  author: t.maybe(t.String),
  message: t.String,
  timestamp: t.maybe(t.Date),
  isPublic: t.maybe(t.Boolean), // whether or not this is visible only internally within the company or to other companies
  availableActions: t.list(InvoiceCommentAction)
}, 'InvoiceComment');

const InvoiceApprovalRequestStatus = t.enums({
  Unknown: 'Unknown',
  Pending: 'Pending',
  Approved: 'Approved',
  Rejected: 'Rejected',
  Returned: 'Returned',
  Forwarded: 'Forwarded'
}, 'InvoiceApprovalRequestStatus');

const InvoiceApprovalRequestAction = t.enums({
  [InvoiceActions.RespondToApprovalRequest]: InvoiceActions.RespondToApprovalRequest
}, 'InvoiceApprovalRequestAction');

const InvoiceApprovalRequest = defineSchema({
  id: t.maybe(t.String),
  invoiceId: t.maybe(t.String),
  logicalDocumentId: t.maybe(t.String),
  sourceId: t.maybe(t.String), // id of the InvoiceApprovalRequest this one was created from
  status: t.maybe(InvoiceApprovalRequestStatus),
  sender: t.maybe(t.String), // email or user name who send this request
  recipient: t.maybe(t.String), // email or user name to whom this request is being Sent
  recipientUserId: t.maybe(t.String),
  lastUpdated: t.maybe(t.Date),
  comments: t.list(InvoiceComment),
  availableActions: t.list(InvoiceApprovalRequestAction)
}, 'InvoiceApprovalRequest');

function createGetPaidEarlyForm(invoice, usePercent) {
  let isLocked = invoice.vpxAvailableDiscount && invoice.vpxAvailableDiscount.isLocked;
  if (usePercent) {
    let percentSchema = createPercentBetween(0.01, 99.99);
    if (!isLocked) {
      return t.struct({
        discountPercent: percentSchema
      }, 'GetPaidEarlyForm');
    }
    let lockedSchema = t.subtype(percentSchema, (value) => {
      return value >= invoice.vpxAvailableDiscount.discountPercent;
    }, 'PercentGetPaidEarly');
    lockedSchema.getValidationErrorMessage = (value, path, context) => {
      let baseError = percentSchema.getValidationErrorMessage(value, path, context);
      if (baseError) {
        return baseError;
      }
      if (value < invoice.vpxAvailableDiscount.discountPercent) {
        return `Your customer's target discount is ${invoice.vpxAvailableDiscount.discountPercent}%.`;
      }
    };
    return t.struct({
      discountPercent: lockedSchema
    }, 'GetPaidEarlyForm');
  }

  let amountSchema = currencyBounded(0.01, invoice.balance - 0.01);
  if (!isLocked) {
    return t.struct({
      discount: amountSchema
    }, 'GetPaidEarlyForm');
  }
  let lockedSchema = t.subtype(amountSchema, (value) => {
    return value >= invoice.vpxAvailableDiscount.discountAmount;
  }, 'CurrencyGetPaidEarly');
  lockedSchema.getValidationErrorMessage = (value, path, context) => {
    let baseError = amountSchema.getValidationErrorMessage(value, path, context);
    if (baseError) {
      return baseError;
    }
    if (value < invoice.vpxAvailableDiscount.discountAmount) {
      return `Your customer's target discount is $${invoice.vpxAvailableDiscount.discountAmount}.`;
    }
  };
  return t.struct({
    discount: lockedSchema
  }, 'GetPaidEarlyForm');
}

const invoiceRecipient = t.struct({
  email: t.String,
  sentDate: t.Date
});

export const InvoiceRecipents = defineSchema({
  recipients: t.list(invoiceRecipient)
}, 'InvoiceRecipents');

export const InvoiceListSettings = defineSchema({
  maximumDayRange: t.maybe(t.Number)
}, 'InvoiceListSettings');

export const APInboxSettings = defineSchema({
  email: t.maybe(t.String),
  enabled: t.maybe(t.Boolean),
  isAutomated: t.maybe(t.Boolean),
  isAutomationAvailable: t.maybe(t.Boolean),
  isTrialAvailable: t.maybe(t.Boolean),
  trialStartDate: t.maybe(t.Date),
  trialEndDate: t.maybe(t.Date),
  trialRemainingDays: t.maybe(t.Number),
  nextBillingDate: t.maybe(t.Date),
  billingRemainingDays: t.maybe(t.Number)
}, 'APInboxSettings');

export const InvoicesNavCount = defineSchema({
  inbox: t.Number,
  earlyPay: t.Number
}, 'InvoicesNavCount');

export const BillsNavCount = defineSchema({
  inbox: t.Number,
  earlyPay: t.Number,
  paymentQueue: t.Number,
  mailbox: t.Number
}, 'BillsNavCount');

export {
  AnyInvoice,
  InvoiceStatus,
  InvoiceType,
  InvoiceLateFee,
  InvoiceLateFeeType,
  InvoiceActionRequiredReason,
  InvoiceRelationship,
  Payable,
  Receivable,
  OutOfBandInvoice,
  APInboxItem,
  APInboxRejectReason,
  VpxInvoice,
  VpxBill,
  VpxListItemInvoice,
  CreateInvoiceLineItem,
  EditOutOfBandInvoiceForm,
  CompanySummary,
  InvoiceRecurrence,
  DetailedInvoiceRecurrence,
  InvoiceRecurrenceInvoiceAction,
  InvoiceRecurrenceAction,
  InvoiceRecurrenceStatus,
  InsuranceClaimReason,
  InsuranceStatus,
  UpdateInvoiceRecurrenceAction,
  createUpdateRecurrenceScheduleForm,
  InvoiceStatement,
  DetailedInvoice,
  InvoiceComment,
  InvoiceApprovalRequest,
  InvoiceApprovalRequestStatus,
  createCreateInvoiceOptionalFormSchema,
  VpxDiscount,
  createGetPaidEarlyForm
};
