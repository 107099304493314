/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './index.scss';

/**
 * Inline message box that can be used to show highlighted messages.
 */
export default class Alert extends Component {
  static propTypes = {
    /**
     * The style type of the alert.
     */
    variant: PropTypes.oneOf(['dark', 'light', 'failure', 'success', 'warning', 'note', 'disclaimer', 'info']),
    /**
     * Action to perform when alert is dismissed.
     */
    onDismissed: PropTypes.func
  };

  static defaultProps ={
    variant: 'dark'
  };

  renderDismissButton() {
    return (
      <div
        className="vp-exit"
        onClick={this.props.onDismissed}
        aria-hidden="true">
      </div>
    );
  }

  render() {
    let isDismissible = !!this.props.onDismissed;
    let classes = {
      'vp-alert': true
    };

    const { variant, onDismissed, ...divProps} = this.props;

    classes[variant] = true;

    return (
      <div {...divProps} className={classNames(classes, this.props.className)}>
        <div>
          {this.props.children}
        </div>
        {isDismissible ? this.renderDismissButton() : null}
      </div>
    );
  }
}
