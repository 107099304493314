/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

function actionNumberToAction(action, newPasswordToken) {
  if (!!newPasswordToken) return 'SetPassword';
  switch (action) {
    case 1: return 'SetPassword';
    case 2: return 'SecurityQuestionAuthRequired';
    case 3: return 'TwoFactorRequired';
    default: return null;
  }
}

export default actionNumberToAction;