/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

export const SHOW_ACTION = 'GUIDED_TOUR_SHOW';

export function showGuidedTour(steps, tourOptions) {
  return (dispatch) => {
    if (!steps || steps.length < 1) {
      return;
    }
    dispatch({
      type: SHOW_ACTION,
      steps,
      timestamp: new Date(),
      tourOptions
    });
  };
}
