/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { createEntity, getEntity } from 'schemas/state';
import { Identity } from 'schemas/network/session';

function createUpdateCompanySettingResponseMapper(companySetting) {
  return (apiResponse, state, params) => {
    let identity = state.session ? getEntity(state, state.session.identity) : null;

    if (!identity) {
      return {};
    }

    let companySettings = {
      ...identity.companySettings,
      [companySetting]: params.value
    };

    let newIdentity = createEntity('current', Identity.meta.name, {
      ...identity,
      companySettings
    });

    return {
      entities: [newIdentity]
    };
  };
}

export default createUpdateCompanySettingResponseMapper;