/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, { Component } from 'react';
import MaskedTextInput from 'react-text-mask';
import BrowserDetection from 'services/BrowserDetection';
import TextBox from 'components/Form/Inputs/TextBox';

export default class CreditCardDateTextBox extends Component {
  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);
  }

  validate(value, matcher, nextMatcher) {
    let newValue = null;
    if (!matcher) {
      return '';
    }

    /** just check normally if regex */
    if (matcher instanceof RegExp) {
      if (value.match(matcher)) {
        return value;
      }
      return '';
    }

    if (value === matcher) {
      return value;
    }
    /** check to see if next value matches our current,
    this allows us to insert the character for the user */
    if (nextMatcher) {
      newValue = this.validate(value, nextMatcher);
      if (newValue) {
        return matcher + value;
      }
    }
    return '';
  }

  onChange(event) {
    if (this.props.onChange) {
      /** TextBox and MaskedTextInput pass different things for event */
      if (BrowserDetection.isAndroid()) {
        this.props.onChange(event.target.value);
      } else {
        let newString = '';
        let value = event;
        if (value) {
          let stringArray = value.split('');
          let matchArray = [/\d/, /\d/, /\d/, /\d/];
          let newStringArray = [];

          /* Insert slash at correct location, this is so the user can backspace */
          let slashIndex = stringArray.lastIndexOf('/');
          if (slashIndex >= 0 && slashIndex < 3) {
            matchArray.splice(slashIndex, 0, '/');
          } else {
            matchArray.splice(2, 0, '/');
          }

          for (let i = 0; i < stringArray.length; i++) {
            newStringArray.push(this.validate(stringArray[i], matchArray[i], matchArray[i + 1]));
          }
          newString = newStringArray.join('');
        } else {
          newString = value;
        }
        this.props.onChange(newString);
      }
    }
  }

  render() {
    /** This prevents an Android bug that reorders numbers because of a delay on input*/
    if (BrowserDetection.isAndroid()) {
      let value = this.props.value === 0 || this.props.value ? this.props.value : '';
      return (
        <MaskedTextInput
          className="vp-input"
          value= {value}
          guide={true}
          placeholderChar=" "
          placeholder="MM/YY"
          mask={[/\d/, /\d/, '/', /\d/, /\d/]}
          onChange={this.onChange} />
      );

    }

    return (
      <TextBox {...this.props}
        placeholder="MM/YY"
        value={this.props.value}
        onChange={this.onChange} />
    );
  }
}
