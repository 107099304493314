/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */
/* eslint max-len:0 */

import { toMessages } from 'config/messages';

export default toMessages('PortalizedModal', {
  ModalHeader: 'Notification',
  Header: 'You’ve been given a Portal account.',
  Instructions: 'We noticed how you were using Viewpost and we\'ve opimized your experience to make doing those things simpler. If you want the full Viewpost experience back, you can always make that change in Company Settings.'
});
