/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import Messages from './index.messages';

export const getErrorMessage = (type, value, intl) => {
  let message = type.getValidationErrorMessage
    ? type.getValidationErrorMessage(value, name, { intl })
    : null;

  if (!message && type.meta.kind !== 'maybe' && !value) {
    message = intl.formatMessage(Messages.NotSetDefaultError);
  }

  // If we are dealing with a maybe type and still don't have a message - we
  // need to go deeper
  if (!message && type.meta.kind === 'maybe') {
    message = type.meta.type.getValidationErrorMessage
      ? type.meta.type.getValidationErrorMessage(value, name, { intl })
      : null;
  }

  return message;
};

export const getActualType = (type, keepSubtype) => {
  const { kind } = (type || {}).meta || {};
  if (kind === 'maybe' || (!keepSubtype && kind === 'subtype')) {
    return getActualType(type.meta.type, keepSubtype);
  }

  return type;
};

export const getKind = type => ((type || {}).meta || {}).kind;
