/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import {
  isApiAction,
  isFailAction,
  isFetchAction,
  isSuccessAction
} from './constants';

class ApiAction {
  constructor(
    action,
    normalize = true
  ) {
    if (!normalize) {
      this.meta = action.meta;
      this.payload = action.payload;
      this.type = action.type;
      return;
    }

    const {
      meta,
      payload: actionPayload,
      type
    } = action;

    const {
      entities,
      metaEntities,
      references,
      updated,
      ...payload
    } = actionPayload || {};

    this.meta = meta || {};
    this.payload = {
      entities: entities || [],
      metaEntities: metaEntities || [],
      references: references || [],
      updated: updated || [],
      ...payload
    };
    this.type = type;
  }

  get isSuccess() {
    return isSuccessAction(this);
  }

  get isFailure() {
    return isFailAction(this);
  }

  get isFetch() {
    return isFetchAction(this);
  }

  isEndpointOfName(name) {
    return this.type.includes(name);
  }

  getEntities(
    {
      // args are array.filter on the entities list
      filter,
      // (default) -> payload.entities
      // meta -> payload.metaEntities
      // both -> both payload.entities, payload.metaEntities
      type,
      valueOnly = true
    } = {}
  ) {
    const filterEntities = entities => filter
      ? entities.filter(filter)
      : entities;

    let entities;
    if (type != null) {
      switch (type) {
        case 'meta':
          entities = filterEntities(this.payload.metaEntities);
          break;
        case 'both':
          entities = filterEntities([
            ...this.payload.entities,
            ...this.payload.metaEntities
          ]);
          break;
        default:
          throw new Error(`Unknown type option ${type}`);
      }
    } else {
      entities = filterEntities(this.payload.entities);
    }

    return valueOnly ? entities.map(({ value }) => value) : entities;
  }

  getErrorReason() {
    return this.payload.errorReason;
  }

  getMessage(filter) {
    const messages = this.getMessages();
    return messages.find(filter);
  }

  getFirstMessage() {
    return this.getMessages()[0]?.message;
  }

  getMessages() {
    return this.payload.response?.body?.messages || [];
  }

  getResponseBody({ expected = true } = {}) {
    const responseBody = this.payload.response?.body;
    if (responseBody == null && expected) {
      throw new Error('Expected response body, but there was none');
    }

    return responseBody;
  }

  getStatusCode() {
    return this.payload.response?.statusCode;
  }

  getMeta() {
    return this.meta;
  }

  getRequestParams() {
    return this.meta.params;
  }

  // Redux cannot dispatch class instances, so this will create a plain object
  // that represents this action. You will need to use asApiAction to get it
  // back to the class instance.
  toReduxAction({
    // The additional redux action to dispatch upon dispatching this. e.g., the
    // onComplete of calling the API
    onDispatch
  } = {}) {
    return {
      meta: this.meta,
      onDispatch,
      payload: this.payload,
      type: this.type
    };
  }
}

// For use 'casting' an action to the ApiAction class, returning null if its
// not castable (i.e., its not an API action).
export const asApiAction = (action) => {
  if (!isApiAction(action)) return null;

  return new ApiAction(action, false);
};

export default ApiAction;