/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React from 'react';
import { connect } from 'react-redux';
import { get, isNil, omit, pick } from 'lodash';
import Link from 'components/Link';

const createPathLinkComponent = ({
  isOutsideApp,
  params,
  toPath
}) => ({
  children,
  isOutsideApp: isOutsideAppProp,
  target: originalTarget,
  transformPath,
  query,
  ...props
}) => {
  let path = toPath(pick(props, params), query);

  // This is the replacement for 'nonSpa'
  let target = originalTarget;
  if (path.startsWith('http') && isNil(target)) {
    target = '_blank';
  }

  return (
    <Link
      to={transformPath ? transformPath(path) : path}
      target={target}
      isOutsideApp={isOutsideAppProp != null
        ? isOutsideAppProp
        : isOutsideApp}
      {...omit(props, params)}
    >
      {children}
    </Link>
  );
};

const createPathLink = (Paths) => {
  const PathLink = ({ path, ...props }) => {
    const LinkComponent = createPathLinkComponent(path);

    return <LinkComponent {...props} />;
  };

  const convertToLinks = (config) => {
    return Object.keys(config).reduce((val, key) => {
      const childConfig = config[key];

      const LinkComponent = createPathLinkComponent(childConfig);
      const childComponents = convertToLinks(pick(childConfig, childConfig.children));
      Object.keys(childComponents).forEach((childKey) => {
        LinkComponent[childKey] = childComponents[childKey];
      });

      return {
        ...val,
        [key]: LinkComponent
      };
    }, {});
  };

  const PathLinks = convertToLinks(Paths);

  Object.keys(PathLinks).forEach((key) => {
    PathLink[key] = PathLinks[key];
  });

  const getPathLink = id => get(PathLinks, id ? id.id || id : null);

  return {
    PathLink,
    getPathLink
  };
};

const createLogicalPathComponent = ({
  params: paramKeys,
  path,
  mapStateToParams,
  mapParamsToProps
}, getPathLink) => {
  const LogicalPathComponent = ({ ...props }) => {
    const pathParams = pick(props, paramKeys);
    const actualPath = path(pathParams);

    const PathLink = getPathLink(actualPath.id);

    const additionalProps = mapParamsToProps ? mapParamsToProps(pathParams) : null;

    return (
      <PathLink
        {...props}
        {...(additionalProps || {})}
      />
    );
  };

  if (mapStateToParams) {
    return connect(mapStateToParams)(LogicalPathComponent);
  }

  return LogicalPathComponent;
};

export const createLogicalPathLink = (LogicalPaths, getPathLink) => {
  const LogicalPathLink = ({ path, ...props }) => {
    const LinkComponent = createLogicalPathComponent(path, getPathLink);

    return <LinkComponent {...props} />;
  };

  const convertToLogicalPaths = (config) => {
    return Object.keys(config).reduce((val, key) => {
      const childConfig = config[key];

      const LinkComponent = createLogicalPathComponent(childConfig, getPathLink);
      const childComponents = convertToLogicalPaths(pick(childConfig, childConfig.children));
      Object.keys(childComponents).forEach((childKey) => {
        LinkComponent[childKey] = childComponents[childKey];
      });

      return {
        ...val,
        [key]: LinkComponent
      };
    }, {});
  };

  const LogicalPathLinks = convertToLogicalPaths(LogicalPaths);

  Object.keys(LogicalPathLinks).forEach((key) => {
    LogicalPathLink[key] = LogicalPathLinks[key];
  });

  return {
    LogicalPathLink
  };
};

export default createPathLink;
