/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */
/* eslint max-len:0 */

import { defineMessages } from 'react-intl';

const links = defineMessages({
  AboutUs: {
    id: 'AppFooter.Links[AboutUs]',
    description: 'About Us link',
    defaultMessage: 'About'
  },
  Terms: {
    id: 'AppFooter.Links[Terms]',
    description: 'Terms link',
    defaultMessage: 'Terms'
  },
  Privacy: {
    id: 'AppFooter.Links[Privacy]',
    description: 'Privacy link',
    defaultMessage: 'Privacy'
  },
  Security: {
    id: 'AppFooter.Links[Security]',
    description: 'Security link',
    defaultMessage: 'Security'
  },
  Contact: {
    id: 'AppFooter.Links[Contact]',
    description: 'Contact link',
    defaultMessage: 'Contact'
  },
  Support: {
    id: 'AppFooter.Links[Support]',
    description: 'Support link',
    defaultMessage: 'Support'
  }
});

const disclaimers = defineMessages({
  TestEnvironment: {
    id: 'AppFooter.Disclaimers[TestEnvironment]',
    description: 'Test Environment disclaimer',
    defaultMessage: 'This is a cloud environment that is not managed or secured by Viewpost. '
      + 'Any data that you provide to the cloud environment is done at your own risk.'
  }
});

export default {
  links,
  disclaimers
};
