/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */
/* eslint max-len:0 */

import { toMessages } from 'config/messages';

export default toMessages('SmallBusinessSubscriptionAdvert', {
  Header: 'Invoicing and Payments for Small(er) Businesses ',
  Subheader: 'Smaller size can still mean big business. That’s why Viewpost created an invoicing and payments solution that meets the needs of virtually any small- or medium-size business. It’s simple to use and provides the features and security you need to keep your business moving. Try it today!',
  SignUp: 'Sign Up Now',
  LogOn: 'Log In to Subscribe',
  CarouselHeader: 'Viewpost works for you.',
  FooterHeader: 'It’s simple and secure.',
  FooterBody: 'Sign up now and start sending invoices or payments within minutes. Our simple registration flow walks you through creating your Viewpost account and securely registering your bank account. Once set up, you can send and receive payments without ever worrying about sharing sensitive payment information.'
});

export const ReasonMessages = toMessages('SmallBusinessSubscriptionAdvert.Reasons', {
  One: '{b}Simple{b} electronic invoicing and payments ',
  Two: '{b}Unlimited users{b} for each company account',
  Three: '{b}Secure{b} two-factor authentication and encrypted data',
  Four: '{b}Automatic reminders{b} and notifications',
  Five: '{b}24 x 7{b} convenient access'
});

export const InvoicingMessages = toMessages('SmallBusinessSubscriptionAdvert.Invoicing', {
  Header: 'Invoicing (AR)',
  Subheader: 'Our self-service platform empowers small businesses to create, send, track and manage invoices and payments with ease.',
  One: 'Create, Send and Track Invoices',
  OneExpanded: 'Use our simple and full-featured invoicing tool to send unlimited electronic invoices — then track their status and get paid faster.',
  Two: 'Automate Recurring Invoices',
  TwoExpanded: 'Save time and effort by setting up recurring invoices for rental payments, subscription fees, recurring orders, or any other fee.',
  Three: 'Include Attachments',
  ThreeExpanded: 'Give customers all the information they need by including images, videos, spreadsheets, or documents in 29 standard file types.',
  Four: 'Add Your Logo',
  FourExpanded: 'Customize your invoice to showcase your brand by adding your company logo.',
  Five: 'Apply Late Fees',
  FiveExpanded: 'Easily apply and manage late fees on any invoice. Simply set your terms, offer an optional grace period, and set the fee based on a percent of invoice or a flat fee.',
  Six: 'Receive Electronic Payments',
  SixExpanded: 'Connect your business bank account and invite your customers to pay your bills quickly and easily through Viewpost.',
  Seven: 'Get Paid Early',
  SevenExpanded: 'When you need cash fast, use Early Pay to offer your customer a discount in exchange for early payment.'
});

export const PaymentsMessages = toMessages('SmallBusinessSubscriptionAdvert.Payments', {
  Header: 'Payments (AP)',
  Subheader: 'Our self-service platform empowers small businesses to create, send, track and manage invoices and payments with ease.',
  One: 'Connect Your Bank Account',
  OneExpanded: 'Register your business bank account quickly and securely to start sending (or receiving) electronic payments with ease.',
  Two: 'Receive & Create Electronic Bills',
  TwoExpanded: 'Invite suppliers to send bills via Viewpost, or set up an AP Inbox to automatically capture and convert emails and attachments into bills.',
  Three: 'Pay Bills from Anywhere',
  ThreeExpanded: 'Secure, 24x7 access enables you to schedule payments from anywhere, anytime.',
  Four: 'Set Up Payment Approval',
  FourExpanded: 'Increase payments security by creating approval rules that are based on specific payment amounts for users with different check signing permissions.',
  Five: 'Stop Printing Checks',
  FiveExpanded: 'Let Viewpost do the work for you. You simply schedule payments and we deliver electronic payments or checks to your vendors.',
  Six: 'Automate Payments',
  SixExpanded: 'Use Auto Pay to automatically pay recurring bills for specific vendors based on payment thresholds that you define.',
  Seven: 'Save with Early Pay',
  SevenExpanded: 'Take advantage of early payment discounts by creating discount tables that notify vendors of your willingness to pay early.'
});