/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import t from 'tcomb-validation';

import { toMessages } from 'config/messages';

const Messages = toMessages('Schemas.SelectCompany', {
  SelectCompany: 'Please select a company'
});

const isValidCompanySelection = (value) => {
  return !!(value && value.companyBookId);
};

const SelectCompany = t.subtype(t.Object, (value) => {
  return isValidCompanySelection(value);
}, 'SelectCompany');

SelectCompany.getValidationErrorMessage = (value, path, context) => {
  if (!isValidCompanySelection(value) && context && context.intl) {
    return context.intl.formatMessage(Messages.SelectCompany);
  }

  return '';
};

export default SelectCompany;