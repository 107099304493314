/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */
/* eslint max-len:0 */

import { defineMessages } from 'react-intl';

const common = defineMessages({
  ShowMessage: {
    id: 'PasswordToggle.Show',
    defaultMessage: 'Show'
  },
  HideMessage: {
    id: 'PasswordToggle.Hide',
    defaultMessage: 'Hide'
  }
});

export default {
  ...common
};
