/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */
/* eslint max-len:0 */

import { toMessages } from 'config/messages';

export default toMessages('Logon.ClosedCompanyLogon', {
  Instructions: 'Please answer the following security questions.',
  IncorrectAnswer: 'Your answer is incorrect. Please correct the error. Warning! Your account will be locked if you answer any question incorrectly again. If you don\'t know the answer to a question, please call Customer Support at 1.888.248.9190.',
  FooterText: 'To avoid answering security questions when signing in, enroll in two-step verification (in your Personal Settings).'
});
