/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import t from 'tcomb-validation';

import PaymentMethodTypesEnum from 'schemas/payments/PaymentMethodTypes';

export const PaymentMethodTypes = PaymentMethodTypesEnum;

export const PaymentRole = t.enums.of([
  'None',
  'VccPayment',
  'VccPaymentPrefund',
  'VccPaymentReroute',
  'VccPaymentPartialReroute',
  'VccPaymentOverLimitSplit',
  'VccPaymentRefund',
  'VccPaymentPartialRefund'
], 'PaymentRole');

const PaymentBase = t.struct({
  id: t.maybe(t.String),
  companyName: t.maybe(t.String),
  status: t.maybe(t.String),

  date: t.maybe(t.Date), // TODO: delete this legacy date
  sentDate: t.maybe(t.Date),
  receivedDate: t.maybe(t.Date),
  scheduledDate: t.maybe(t.Date),

  // check number or other token or identifier for the payment
  referenceNumber: t.maybe(t.String),
  currencyCode: t.maybe(t.String),
  totalAmount: t.maybe(t.Number), // amount of the payment
  splitFromAmount: t.maybe(t.Number), // original amount this payment was split from

  availableAmount: t.maybe(t.Number), // represents an available amount for some cases (e.g. Virtual card)

  account: t.maybe(t.String),

  method: t.maybe(t.String), // TODO: do we need this?
  paymentMethodEnum: t.maybe(PaymentMethodTypes),

  // all methods associated with this payment when there is mulitple (e.g VCC + electronic)
  allPaymentMethodTypes: t.maybe(t.list(PaymentMethodTypes)),

  // platform ids
  settlementId: t.maybe(t.String),
  receiptSettlementId: t.maybe(t.String),

  // amount of ACH+ fee applicable to this payment
  achPlusFee: t.maybe(t.Number),
  // amount actually received by vendor (after taking out the ACH+ fees)
  vendorReceivedAmount: t.maybe(t.Number),

  clientReferenceId: t.maybe(t.String),

  logicalSettlementId: t.maybe(t.String),
  role: t.maybe(PaymentRole),
  description: t.maybe(t.String),

  numberOfApprovals: t.maybe(t.Number),
  numberOfRemainingApprovals: t.maybe(t.Number),
  numberOfRequiredApprovals: t.maybe(t.Number),

  customerName: t.maybe(t.String),
  vendorName: t.maybe(t.String),
  payToName: t.maybe(t.String),

  specialDeliveryType: t.maybe(t.String),
  trackingNumber: t.maybe(t.String)
});

export default PaymentBase;