/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */
/* eslint react/no-danger:0 */

import React, { useEffect } from 'react';

import BlueHeaderOnWhiteLayout from 'layouts/BlueHeaderOnWhite';
import TitleCase from 'components/TitleCase';
import FormattedDate from 'components/FormattedDate';

import LoadingIndicator from 'components/LoadingIndicator';

import useWindowWidth from 'hooks/browser/useWindowWidth';

import DOMPurify from 'dompurify';

import useApi from 'api/hooks/useApi';
import { getTerms } from 'api/terms';

import './index.scss';

const Terms = ({ type }) => {

  const api = useApi(getTerms, 'GetTerms', {
    popupError: false
  });
  const windowWidth = useWindowWidth();

  useEffect(() => {
    if (type) {
      window.scrollTo(0, 0);
      api.call({
        type
      });
    }
  }, [type]);

  if (api.isLoading()) {
    return <LoadingIndicator />;
  }

  const terms = api.getResponse()?.data?.[0];

  if (!terms) {
    return <BlueHeaderOnWhiteLayout>
    </BlueHeaderOnWhiteLayout>;
  }

  const heading = type === 'CassTerms'
    ? 'CASS Terms'
    : <TitleCase>{type}</TitleCase>;

  return (
    <BlueHeaderOnWhiteLayout>
      <div className="viewstrap">
        <div style={{
          textAlign: 'left',
          width: windowWidth >= 1000 ? '1000px' : null,
          margin: windowWidth >= 1000 ? '0 auto' : '20px'
        }} className="terms-content">

          <div className="headline">
            {heading}
          </div>

          <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(terms?.content)}}></div>

          <div style={{marginTop: '50px'}}>
            <h2>
              Last Updated: <FormattedDate value={terms?.updatedDate} />
            </h2>
          </div>

        </div>
      </div>
    </BlueHeaderOnWhiteLayout>
  );
};

export default Terms;