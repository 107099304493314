/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { uniqueId } from 'lodash';
import { useDispatch } from 'react-redux';
import { ModalActionTypes } from 'config/constants';
import { wrapImport } from 'decorators/ModuleLoader';

const ModalRegistry = {};

const createLaunchAction = ({
  key,
  ignoreIfAnotherModalOpen,
  parentModalParams
}) => (
  viewParams,
  modalParams
) => (
  dispatch,
  getState
) => {
  const { stack } = getState().modals || {};

  if (ignoreIfAnotherModalOpen && (stack || []).length) return;

  const allModalParams = {
    ...(parentModalParams || {}),
    ...(modalParams || {})
  };

  return new Promise((resolve, reject) => {
    dispatch({
      modalParams: allModalParams,
      modalType: key,
      onCloseCallbacks: { resolve, reject },
      type: ModalActionTypes.Show,
      viewParams: viewParams || {}
    });
  });
};

export const addModal = (
  key,
  component,
  parentModalParams,
  { ignoreIfAnotherModalOpen } = {}
) => {
  ModalRegistry[key] = component;

  return createLaunchAction({
    key,
    ignoreIfAnotherModalOpen,
    parentModalParams
  });
};

// This could be considered an interesting application of the hook concept, of
// course there is no problem with you using useDispatch and then passing in the
// result of addModal to launch a modal, but this function does something
// interesting in regards to code splitting - because the hook is executed in
// the context of rendering, we have the 'intent' to launch a specific modal, so
// we can now eagerly load the modal's code module before the modal is launched.
// Of course the modal may or may not be launched, but we can eliminate the user
// waiting for the code module to load and still keeping it out of a main
// bundle which keeps the bloat down.
export const createLaunchHook = (
  // This may either be a real component or a import module function that returns a component
  modalComponent,
  parentModalParams,
  {
    async: isAsyncLoad,
    ignoreIfAnotherModalOpen,
    key: overrideKey
  } = {}
) => {
  // Given how static createLaunchHook/addModal is used, defining a modal name
  // really doesn't seem necessary, so let's not make it necessary
  const key = overrideKey || `GeneratedKey-${uniqueId()}`;

  // If this is an async load, we are actually dealing with an importModule function
  const importModule = isAsyncLoad ? modalComponent : null;

  ModalRegistry[key] = importModule
    ? wrapImport(importModule)
    : modalComponent;

  const launchModal = createLaunchAction({
    key,
    ignoreIfAnotherModalOpen,
    parentModalParams
  });

  const useLaunchModal = () => {
    const dispatch = useDispatch();

    if (isAsyncLoad) {
      // Once the hook is invoked in a render method, we call the import module
      // to load the modal into memory
      importModule();
    }

    // Return the launch function - all good to go
    return (...params) => dispatch(launchModal(...params));
  };

  return useLaunchModal;
};

export const getModal = key => ModalRegistry[key];
