/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import getSecureTokenIdentity from './getSecureTokenIdentity';

const isSecureTokenUserOfType = (state, status, businessType) => {
  // Not expected because a lot of users of this call do it with no regard to the context
  const secureTokenIdentity = getSecureTokenIdentity(
    state,
    { notExpected: true, returnNull: true }
  );
  if (!secureTokenIdentity) return false;

  const { businessType: secureTokenBusinessType,  companyStatus } = secureTokenIdentity;
  if (status && status !== companyStatus) return false;
  if (businessType && businessType !== secureTokenBusinessType) return false;

  return true;
};

export default isSecureTokenUserOfType;