/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { connect } from 'react-redux';
import { dispatchApiCall, getCacheResponse, hasCacheFailed, isCacheLoading } from 'actions/references';
import PaymentAccountsApi from 'api/paymentAccounts';
import isCapabilityEnabled from 'actions/app/isCapabilityEnabled';

const ReferenceName = 'AddBankAccountManuallyForm';

const MapStateToProps = (state, props) => {
  const {
    name: routingBankName,
    formattedRoutingNumber
  } = getCacheResponse(state, ReferenceName) || {};

  return {
    canadianBankAccounts: isCapabilityEnabled(state, 'CanadianBankAccounts'),
    isValidationLoading: isCacheLoading(state, ReferenceName),
    isRoutingNumberValid: !hasCacheFailed(state, ReferenceName),
    routingBankName,
    formattedRoutingNumber
  };
};

const MapDispatchToProps = (dispatch, props) => {
  return {
    validateRoutingNumber: (id, country) => dispatch(dispatchApiCall(
      ReferenceName,
      PaymentAccountsApi.validateRoutingNumber,
      {
        id,
        country
      }
    ))
  };
};

export default Component => connect(MapStateToProps, MapDispatchToProps)(Component);
