/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { isUndefined } from 'lodash';
import AnalyticsContext from './context';

// This component can be used as an alternative to a wrapper 'div', it renders out as a div
// with any props you pass in and some extra analytics markers
const BaseAnalyticsCategory = ({
  category,
  children,
  name,
  style,
  urlToken,
  ...safeProps
}) => (
  <AnalyticsContext.Provider
    value={{
      category: name,
      urlToken
    }}
  >
    <div
      {...safeProps}
      id={`analytics.${name}`}
      style={isUndefined(style) ? { height: '100%'} : style}
    >
      {children}
    </div>
  </AnalyticsContext.Provider>
);

BaseAnalyticsCategory.propTypes = {
  name: PropTypes.string.isRequired,
  urlToken: PropTypes.string
};

// This one overlays the previous context on top of it, use the base if you want to completely reset
// the context or not take dependencies on the wrapping context.
const AnalyticsCategory = ({
  ignoreParent,
  name,
  urlToken: urlTokenProp,
  ...props
}) => {
  const {
    category: analyticsCategory,
    urlToken: contextUrlToken
  } = useContext(AnalyticsContext) || {};

  const getCategoryName = () => {
    if (!name) {
      return analyticsCategory;
    }

    return !ignoreParent && analyticsCategory
      ? `${analyticsCategory}.${name}`
      : name;
  };

  const category = getCategoryName();
  const urlToken = urlTokenProp || contextUrlToken;

  return (
    <BaseAnalyticsCategory
      {...props}
      name={category}
      urlToken={urlToken}
    />
  );
};

export default AnalyticsCategory;