/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, { useEffect } from 'react';

// import Button from 'components/Button';
// import Tooltip, { Placements } from 'components/Tooltip';
// import Icon, { IconTypes } from 'components/Icon';

import LoadingIndicator from 'components/LoadingIndicator';

import TableView from 'components/TableView';
import TableViewColumn from 'components/TableView/TableColumn';

import Currency from 'components/Currency';
import FormattedDate from 'components/FormattedDate';

import { Row, Column } from 'components/Viewstrap/Grid';
import useWindowWidth from 'hooks/browser/useWindowWidth';

import TinyListRow from 'components/TinyListRow';
import Button from 'components/Button';

import useApi from 'api/hooks/useApi';
import { getRemittanceList } from 'api/payments/remittance';

import ApiPagination from 'containers/ApiPagination';

function RemittanceTable({ remittance }) {
  if (!remittance?.length) return null;
  let hasMemo = remittance.some(r => !!r.memo);
  return (
    <TableView items={remittance} variant="listview">
      <TableViewColumn
        header="Invoice #"
        renderCell={r => r.invoiceNumber} />
      <TableViewColumn
        header="Date"
        renderCell={r => <FormattedDate value={r.invoiceDate} />} />
      {hasMemo
        ? (
          <TableViewColumn
            header="Description"
            renderCell={r => r.memo} />
        )
        : null}
      <TableViewColumn
        header="Gross"
        style={{ textAlign: 'right' }}
        renderCell={r => <Currency value={r.grossAmount} />} />
      <TableViewColumn
        header="Adjustments"
        style={{ textAlign: 'right' }}
        renderCell={r => <Currency value={r.adjustments} />} />
      <TableViewColumn
        header="Amount"
        style={{textAlign: 'right'}}
        renderCell={r => <strong><Currency value={r.amount} /></strong>} />
    </TableView>
  );
}

function MobileRemitance({ remittance, totalItems, isTotalCapped }) {
  const moreItemCount = totalItems - remittance.length;

  return (
    <div>
      {remittance.map((r, i) => {
        return (
          <TinyListRow
            key={i}
            isClickable={false}
            leftHeader={<div>
              {r.invoiceNumber}
              <div className="subtext">
                <FormattedDate value={r.invoiceDate} />
              </div>
            </div>}
            rightHeader={<div>
              {r.grossAmount !== r.amount
                ? (
                  <div>
                    <div>
                      <span style={{ fontSize: '12px', color: '#999' }}>(Gross) </span>
                      <Currency value={r.grossAmount} />
                    </div>
                    <div>
                      <span style={{ fontSize: '12px', color: '#999' }}>(Adjustments) </span>
                      <Currency value={r.adjustments} />
                    </div>
                  </div>
                )
                : null}
              <strong><Currency value={r.amount} /></strong>
            </div>}
            leftSubFooter={r.memo
              ? <>
                Memo: {r.memo}
              </>
              : null}
          />
        );
      })}
      {moreItemCount > 0
        ? (
          <div style={{textAlign: 'center', fontStyle: 'italic'}}>
            <strong>
              {moreItemCount}{isTotalCapped ? '+' : null} More
            </strong>
            <br/>
            (Download to see more)
          </div>
        )
        : null}
      <div style={{textAlign: 'center', marginTop: '20px'}}>
        <Button onClick={() => global.window.scrollTo(0, 0)}>
          Back to Top
        </Button>
      </div>
    </div>
  );
}

function RemittanceDetails({ settlementId, secureToken }) {
  const windowWidth = useWindowWidth();
  const api = useApi(getRemittanceList, 'GetRemittanceDetails', {
    paged: true
  });

  useEffect(() => {
    if (settlementId) {
      api.call({ settlementId, secureToken });
    }
  }, [settlementId]);

  if (api.isLoading()) {
    return <LoadingIndicator>
      Loading Remittance Preview...
    </LoadingIndicator>;
  }

  const remittance = api.getResponse()?.results;
  if (!remittance) return null;

  if (remittance.some(r => r.isUsingCustomRemittance)) return null;

  const totalItems = api.getPagination()?.totalItems ?? 0;
  const isTotalCapped = api.getPagination()?.isCapped ?? false;

  return (
    <div>
      <div style={{ marginTop: '50px', marginBottom: '50px' }}>
        <Row>
          <Column size={12} style={{ fontWeight: 'bold', fontSize: '16px', padding: '16px' }}>
            Remittance Preview
          </Column>
        </Row>

        {windowWidth < 800
          ? <MobileRemitance remittance={remittance} totalItems={totalItems} isTotalCapped={isTotalCapped} />
          : (
            <ApiPagination api={api} canChangePageSize={false}>
              <RemittanceTable remittance={remittance} />
            </ApiPagination>
          )}
      </div>
    </div>
  );
}

export default RemittanceDetails;
