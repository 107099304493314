/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { get } from 'lodash';

import { getEntity, getEntityList } from 'schemas/state';

import logError from 'services/ErrorService';

const getCompanyPaymentAccounts = (state, { notExpected = false, returnNull = false } = {}) => {
  const identity = getEntity(state, get(state, 'session.identity'));

  if (!notExpected && identity?.paymentAccounts == null) {
    logError('Expected paymentAccounts, but paymentAccounts is missing.', null, { includeStackTrace: true });
  }

  // Returning at least an empty array so that at least a JS error doesn't
  // take React down. We have the logged error, so you there is visiblity into
  // a problem
  if (identity?.paymentAccounts == null) return returnNull ? null : [];

  return getEntityList(state, identity.paymentAccounts);
};

export default getCompanyPaymentAccounts;