/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import t from 'tcomb-validation';
import { applyMetadata } from 'schemas';
import { StringOrNumberUnion } from './union';
import messages from './currency.messages';

// use same regex used by platform
/* eslint-disable max-len */

// TODO: support commas in amounts, enable this regex, and strip them out from the value
// let currencyRegex = new RegExp('^[+-]?[0-9]{1,3}(?:,?[0-9]{3})*(?:\\.[0-9]{2})?$');
let currencyRegex = new RegExp('^[+-]?[0-9]{0,11}(?:\\.[0-9]{0,2})?$');

// Maximum value allowed by currencyRegex
const currencyMax = 99999999999.99;

// We not legally/contractually allowed to make payments of $25M
export const MAX_PAYMENT_AMOUNT = 25000000.00;

function isValidCurrency(value) {
  let stringValue = `${value}`; // coerce numbers into strings if needed
  if (stringValue === '-' || stringValue === '.') {
    return false;
  }
  return currencyRegex.test(stringValue);
}

export function toNumber(value) {
  if (typeof value === 'number') return value;
  if (!value) return null;

  return Number(value.replace(/,/g, ''));
}

/* CURRENCY */
const Currency = t.subtype(StringOrNumberUnion, (value) => {
  return isValidCurrency(value);
}, 'Currency');

/* Other types based on currency */
const CurrencyPositive = t.subtype(Currency, (value) => {
  let num = toNumber(value);
  return num > 0;
}, 'CurrencyPositive');

CurrencyPositive.getValidationErrorMessage = (value) => {
  let num = toNumber(value);
  if (!(num > 0)) {
    return 'Please enter a positive amount.';
  }

  return '';
};

const CurrencyPositiveAllowZero = t.subtype(Currency, (value) => {
  let num = toNumber(value);
  return num >= 0;
}, 'CurrencyPositiveAllowZero');

CurrencyPositiveAllowZero.getValidationErrorMessage = (value, path, context) => {
  let num = toNumber(value);
  if (num < 0) {
    return 'Please enter a positive amount or zero.';
  }

  return Currency.getValidationErrorMessage(value, path, context);
};

const CurrencyNegative = t.subtype(Currency, (value) => {
  let num = toNumber(value);
  return num < 0;
}, 'CurrencyNegative');

CurrencyNegative.getValidationErrorMessage = (value) => {
  let num = toNumber(value);
  if (!(num < 0)) {
    return 'Please enter a negative amount.';
  }

  return '';
};

const CurrencyNegativeAllowZero = t.subtype(Currency, (value) => {
  let num = toNumber(value);
  return num <= 0;
}, 'CurrencyNegativeAllowZero');

CurrencyNegativeAllowZero.getValidationErrorMessage = (value) => {
  let num = toNumber(value);
  if (!(num <= 0)) {
    return 'Please enter a negative amount or zero.';
  }

  return '';
};

export const CurrencyGreaterThan = (
  amount,
  {
    equalTo,
    lessThanZero,
    customMessage
  } = {}
) => {
  const currencySubType = t.subtype(
    Currency,
    (value) => {
      const num = toNumber(value);
      return (equalTo ? num >= amount : num > amount)
        && (!lessThanZero || num < 0);
    },
    `CurrencyGreaterThan_${amount}`
  );

  currencySubType.getValidationErrorMessage = (value, path, context) => {
    if (context && context.intl) {
      return context.intl.formatMessage(
        customMessage || messages.InvalidGreaterThanEqualToAmount,
        { amount }
      );
    }

    return '';
  };

  return currencySubType;
};

export const CurrencyLessThan = (
  amount,
  {
    equalTo,
    greaterThanZero,
    maxAmount: originalMaxAmount,
    customMessage
  } = {}
) => {
  const maxAmount = originalMaxAmount != null ? originalMaxAmount : MAX_PAYMENT_AMOUNT;

  const boundAmount = (amount < maxAmount) ? amount : maxAmount;
  const currencySubType = t.subtype(
    Currency,
    (value) => {
      const num = toNumber(value);
      return (equalTo ? num <= boundAmount : num < boundAmount)
        && (!greaterThanZero || num > 0);
    },
    `CurrencyLessThan_${boundAmount}`
  );

  currencySubType.getValidationErrorMessage = (value, path, context) => {
    if (context && context.intl) {
      const message = greaterThanZero
        ? messages.GreaterThanZeroLessThanAmount
        : messages.InvalidLessThanEqualToAmount;
      return context.intl.formatMessage(
        customMessage || message,
        {
          amount: (+boundAmount).toLocaleString()
        }
      );
    }
    return '';
  };

  return currencySubType;
};

export const currencyLessThanCheck = amount => (value) => {
  let num = toNumber(value);
  return num < amount;
};

function currencyBounded(
  minAmount = -currencyMax,
  maxAmount = currencyMax,
  {
    inclusiveMin
  } = {}
) {
  const minCheck = val => inclusiveMin || inclusiveMin == null
    ? val >= minAmount
    : val > minAmount;

  const currencySubtype = t.subtype(Currency, (value) => {
    const num = toNumber(value);
    return minCheck(num) && (num <= maxAmount);
  }, `CurrencyBounded_${minAmount}_${maxAmount}`);

  currencySubtype.getValidationErrorMessage = (value, path, context) => {
    const num = toNumber(value);
    const {InvalidLessThanEqualToAmount, InvalidGreaterThanEqualToAmount} = messages;

    if (context && context.intl) {
      if (!minCheck(num)) {
        return context.intl.formatMessage(InvalidGreaterThanEqualToAmount, {
          amount: minAmount
        });
      }
      if (num > maxAmount) {
        return context.intl.formatMessage(InvalidLessThanEqualToAmount, {
          amount: maxAmount
        });
      }
    }
    return Currency.getValidationErrorMessage(value, path, context);
  };
  return currencySubtype;
}

function currencyWholeNumberBounded(minAmount = -currencyMax, maxAmount = currencyMax) {
  const currencyBoundedSubtype = currencyBounded(minAmount, maxAmount);
  const currencySubtype = t.subtype(currencyBoundedSubtype, (value) => {
    const num = toNumber(value);
    return (num % 1 === 0);
  }, `CurrencyWholeNumberBounded_${minAmount}_${maxAmount}`);

  currencySubtype.getValidationErrorMessage = (value, path, context) => {
    const num = toNumber(value);

    if (context && context.intl) {
      if (num % 1 !== 0) {
        return context.intl.formatMessage(messages.InvalidWholeNumber);
      }
    }
    return currencyBoundedSubtype.getValidationErrorMessage(value, path, context);
  };
  return currencySubtype;
}


Currency.getValidationErrorMessage = (value, path, context) => {
  if (!isValidCurrency(value) && context && context.intl) {
    if (value > currencyMax) {
      return context.intl.formatMessage(messages.InvalidLessThanAmount, {
        amount: value
      });
    }
    return context.intl.formatMessage(messages.Invalid);
  }

  return '';
};

// This is to instiutionalize the minimumFractionDigits as a property of the schema
// so that components or validation can all be on the same page to what the value
// should look like.
export const applyMinimumFractionDigits = (
  type,
  minimumFractionDigits
) => applyMetadata(
  `CurrencyMinFractionDigits-${minimumFractionDigits}`,
  type,
  { minimumFractionDigits }
);

export {
  Currency,
  CurrencyPositive,
  CurrencyPositiveAllowZero,
  CurrencyNegative,
  CurrencyNegativeAllowZero,
  currencyWholeNumberBounded,
  currencyBounded
};
