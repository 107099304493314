/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */
/* eslint max-len:0 */

import { toMessages } from 'config/messages';

export default toMessages('TokenLanding.ElectronicSelfEnrollment', {
  Header: 'Electronic Payment Registration',
  Subheader: 'Submit this form to begin receiving electronic payment from {companyName}.',
  RegisteredHeader: 'Ahh, the hard part is over.',
  RegisteredSubheader: 'Thanks for enrolling with Viewpost.'
});
