/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import t from 'tcomb-validation';

import { defineSchema } from 'schemas';

// a node in a company organization hierarchy
const CompanyOrganizationNode = defineSchema({
  id: t.String,
  name: t.maybe(t.String),
  path: t.maybe(t.list(t.String))
}, 'CompanyOrganizationNode');

export default CompanyOrganizationNode;