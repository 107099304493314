/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { createApiAction } from 'api/core';

export const getAttachmentList = createApiAction({
  method: 'post',
  url: '/api/attachments/list',
  requestBodyMapper: r => r,
  responseMapper: r => r
});


export const downloadAttachment = createApiAction({
  method: 'get',
  url: '/api/attachments/download',
  queryStringMapper: r => r,
  responseMapper: r => r,
  downloadResponse: true
});

export const downloadAllAttachments = createApiAction({
  method: 'get',
  url: '/api/attachments/downloadAll',
  queryStringMapper: r => r,
  responseMapper: r => r,
  downloadResponse: true
});
