/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, { Children, Fragment } from 'react';
import classNames from 'classnames';
import './index.scss';
import Icon, { IconTypes } from 'components/Icon';

const transformAutoBulletChild = bulletProps => (child) => {
  if (!child) return null;

  if (child.type === Fragment) {
    return React.cloneElement(
      child,
      {},
      Children.map(child.props.children, transformAutoBulletChild(bulletProps)));
  }

  return (
    <Bullet {...bulletProps}>
      {child}
    </Bullet>
  );
};

export const AutoBullet = ({ children, ...props }) => (
  <>
    {Children.map(children, transformAutoBulletChild(props))}
  </>
);

export function Bullet(props) {
  const bullet = (() => {
    switch (props.variant) {
      case 'checkmarks':
        return <Icon type={IconTypes.Checkmark} />;
      case 'bullets':
        return <span>&bull;</span>;
      default:
        return props.bulletNumber;
    }
  })();

  return (
    <div className="component-bullet">
      {props.renderBullet
        ? (
          <div style={{position: 'absolute'}}>
            {props.renderBullet(props.bulletNumber)}
          </div>
        )
        : (
          <div className="bullet-point" style={props.bulletStyle}>
            {bullet}
          </div>
        )}
      <div className="bullet-body">
        {props.children}
      </div>
    </div>
  );
}

const BulletedList = ({
  bulletStyle,
  children,
  className,
  style,
  variant = 'default',
  subVariant
}) => {
  let bulletNumber = 0;

  const transformChild = (child) => {
    if (!child) return null;

    if (child.type === Fragment) {
      return React.cloneElement(
        child,
        {},
        Children.map(child.props.children, transformChild));
    }

    bulletNumber++;
    return React.cloneElement(child, {
      bulletNumber,
      bulletStyle,
      variant
    });
  };

  let bullets = Children.map(children, transformChild);

  return (
    <div
      className={classNames(
        'component-bulleted-list',
        variant,
        subVariant,
        className
      )}
      style={style}
    >
      {bullets}
    </div>
  );
};

export default BulletedList;
