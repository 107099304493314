/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */
/* eslint max-len:0 */

import { toMessages } from 'config/messages';

export default toMessages('UserRegistrationWorkflow', {
  Disclaimer: 'By creating an account, you accept our {tou}, {paymentTerms}, and {privacyPolicy}.',
  Submit: 'Save Password',
  Password: 'Password',
  ConfirmPassword: 'Confirm Password',
  PasswordPlaceholder: 'Create Password'
});