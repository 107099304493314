/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, { useContext } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import LoadingIndicator from 'components/LoadingIndicator';
import ProblemErnie from 'components/ProblemErnie';

import LoadingContext from './loadingState/LoadingContext';
import useBaseStatusIndicator, {
  createGetAllHooksWith,
  getAllHooksWithIsLoading,
  getAllHooksWithRequestError
} from './utils/useBaseStatusIndicator';

export const useLoadingIndicator = (hooks) => {
  // Hooks
  const useWillLoadOrIsLoadings = useBaseStatusIndicator(
    getAllHooksWithIsLoading,
    hooks
  );

  const {
    stateRef: { current: loadingContextState }
  } = useContext(LoadingContext);

  const areLoadingIds = useSelector(
    state => useWillLoadOrIsLoadings.filter(
      ({ meta: { correlationId, selector }}) => selector(
        state,
        null,
        { loadingContext: loadingContextState }
      )
        ? correlationId
        : null
    ),
    shallowEqual
  );

  // Action
  if (!areLoadingIds.length) return null;

  return props => (
    <LoadingIndicator
      loadingIds={areLoadingIds}
      {...props}
    />
  );
};

export const useErrorErnie = (hooks) => {
  // Hooks
  const useRequestErrors = useBaseStatusIndicator(
    getAllHooksWithRequestError,
    hooks
  );

  const hasRequestError = useSelector(
    state => useRequestErrors.some(
      ({ meta: { selector }}) => selector(state)
    ),
    shallowEqual
  );

  // Action
  return hasRequestError ? ProblemErnie : null;
};

const getAllHooksWithEither = createGetAllHooksWith(
  hook => hook.useWillLoadOrIsLoading || hook.useRequestError,
  hook => [ hook.useWillLoadOrIsLoading, hook.useRequestError ]
);

const useStatusIndicator = (hooks, getConfig) => {
  // Hooks
  const hasStatusHooks = useBaseStatusIndicator(
    getAllHooksWithEither,
    hooks,
    // This feels like a lot of work to get an ID that's going to be the same 99.9% of the time
    hooksWith => hooksWith.reduce(
      (val, [ useWillLoadOrIsLoading, useRequestError ]) => {
        const metas = [];
        if (useWillLoadOrIsLoading) {
          metas.push(useWillLoadOrIsLoading.meta);
        }
        if (useRequestError) {
          metas.push(useRequestError.meta);
        }
        return [ ...val, ...metas];
      },
      []
    )
  );

  const {
    stateRef: { current: loadingContextState }
  } = useContext(LoadingContext);

  // This return structure is really strange, but its the most ideal to make use of the shallowEqual
  // to only render on state changes that are meaningful
  const [
    isLoading,
    isError,
    ...ids
  ] = useSelector(
    (state) => {
      const loadingIds = [];
      const errorIds = [];

      hasStatusHooks.forEach(
        ([
          useWillLoadOrIsLoading,
          useRequestError
        ]) => {
          if (useWillLoadOrIsLoading?.meta.selector(
            state,
            getConfig,
            { loadingContext: loadingContextState }
          )) {
            loadingIds.push(useWillLoadOrIsLoading.meta.correlationId);
          }

          if (useRequestError?.meta.selector(state, getConfig)) {
            errorIds.push(useRequestError.meta.correlationId);
          }
        }
      );

      // Give precedence to displaying errors above loading
      if (errorIds.length) return [ false, true, ...errorIds ];
      if (loadingIds.length) return [ true, false, ...loadingIds ];
      return [ false, false ];
    }
  );

  // Action

  // Passing error'd IDs to this component does nothing meaningful at this moment
  if (isError) return ProblemErnie;

  if (isLoading) {
    return props => (
      <LoadingIndicator
        loadingIds={ids}
        {...props}
      />
    );
  }

  return null;
};

export default useStatusIndicator;