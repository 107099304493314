/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { isString } from 'lodash';
import { createApiAction } from 'api/core';
import { filterAvailableActions } from 'api/permissions';
import {
  createEntity,
  createReferenceFromEntity
} from 'schemas/state';
import { Acknowledgement } from 'schemas/dashboard';
import {
  PersonalIdentity,
  GetIdentityAvailableActions,
  Identity,
  PartialIdentity,
  CompanyFeeSettings
} from 'schemas/network/session';
import {
  UseAppAuthorizationReason,
  createUseAppAuthorizedEntity,
  createUseAppUnauthorizedEntity
} from 'schemas/session/UseAppAuthorizationStatus';
import { dateMapper } from 'api/common/mappers/coreMappers';
import { needsAddressBookLinkOnConnect, needsErpLinkOnConnect } from 'api/permissions';
import bankAccountEntityMapper from 'api/paymentAccounts/mappers/bankAccountEntityMapper';
import {
  KnowledgeAuthenticationRequired,
  CompanyAccountType
} from 'config/constants/session';
import { isEnabled } from 'services/FeatureToggles';

export const determineIdentityActions = (identity, feeSettings) => filterAvailableActions(
  identity.permissions,
  identity.accountingPackage,
  identity.companySettings,
  identity.userSettings,
  GetIdentityAvailableActions(),
  identity.businessType === 'Personal',
  identity.associatedCompaniesCount,
  feeSettings
);

const attemptJsonParse = (json) => {
  let result = json;

  if (isString(json)) {
    try {
      result = JSON.parse(json);
    } catch (e) {
      // Do nothing, its *probably* nothing
    }
  }

  return result;
};

const identityResponseMapper = (
  apiUserModel,
  state,
  requestParams
) => {
  const {
    // Create an UseAppAuthorizationStatus entity if there is a full session. Used in
    // the initial app load to indicate we are authenticated but afterward we
    // defer to things that actually modify the authentication to create it.
    createUseAppAuthorizedEntity: shouldcreateUseAppAuthorizedEntity
  } = requestParams || {};

  const entities = [];

  let response = apiUserModel;
  let permissions = apiUserModel.permissions;

  let schema = Identity;

  if (permissions.consumerPayment) {
    schema = PersonalIdentity;
  } else if (!response.isFullSession
    && !response.company.hasCredentials
    && response.company.companyStatus === 'Active') {
    // Bit of a hairy set of conditions, but this will be without a doubt a temporary session
    // converting someone to vendor portal
    schema = PartialIdentity;
  }

  const accountingPackage = response.company.accountingPackage;
  const accountingType = response.company.accountingType;
  const kbaRequired = isEnabled('spoofKBA')
    ? KnowledgeAuthenticationRequired.Required
    : response.company.knowledgeAuthenticationRequired;
  const companyStatus = response.company.companyStatus;
  const businessType = response.company.businessType;

  const companyAdditionalSettings = response.company.additionalSettings || {};
  const companyUserAdditionalSettings = response.companyUser.additionalSettings || {};
  const userAdditionalSettings = response.user.additionalSettings || {};

  const identity = {
    isFullSession: response.isFullSession || false,
    isOumc: response.user?.associatedCompaniesCount > 1,
    isOumcCrossCompanyDataEnabled: response.user?.oumcCrossCompanyData,
    userId: response.user.id,
    firstName: response.user.firstName,
    lastName: response.user.lastName,
    email: response.user.email,
    phone: response.company.phone,
    createdDateTime: dateMapper(response.user.createdTimestamp, true),
    autoApprovesInvoices: !!response.company.autoApprovesInvoices,
    companyId: response.company.id,
    companyName: response.company.name,
    accountingPackage: response.company.accountingPackage,
    permissions,
    viewpostBillingCompanyId: response.company.viewpostBillingCompanyId,
    isAccountSecure: response.user.isAccountSecure,
    isTwoFactorSecured: response.user.isTwoFactorSecured || false,
    twoFactorSecuredRequirementDisabled: response.user.twoFactorSecuredRequirementDisabled || false,
    termsAccepted: response.user.termsAccepted,
    hasCompleteAddress: response.company.hasCompleteAddress,
    needsAccountSecure: !!response.paymentAccounts
      && !!response.paymentAccounts.length,
    nextSetupSteps: response.company?.nextSetupSteps,
    userSettings: {
      displayCashflowGraph: response.settings.displayCashFlowGraph,
      hideGettingStartedWizard: response.settings.hideGettingStartedWizard,
      oneClickPayEnabled: response.settings.oneClickPayEnabled,
      oneClickPaymentMethodId: response.settings.oneClickPaymentMethodId,
      oneClickPaymentTiming: response.settings.oneClickPaymentTiming,
      additionalSettings: Object.keys(userAdditionalSettings).reduce((val, key) => ({
        ...val,
        [key]: attemptJsonParse(userAdditionalSettings[key])
      }), {})
    },
    companySettings: {
      autoApprovesInvoices: !!response.company.autoApprovesInvoices,
      autoApplyPayments: response.company.autoApplyPayments,
      vccAllowPayViaFallback: response.company.vccAllowPayViaFallback,
      annualUpdateReminder: response.company.annualUpdateReminder,
      multistepApprovals: response.company.multistepApprovals,
      needsBeneficialOwnerInfo: response.company.needsBeneficialOwnerInfo,
      hasBeneficialOwnerInfo: response.company.hasBeneficialOwnerInfo,
      suggestCompany: response.company.suggestCompanyAsConnection,
      qboEnabled: response.company.accountingPackage === 'QuickBooksOnlineV1',
      canEnrollConsumerPayments: !!response.company.canEnrollConsumerPayments,
      canReceiveConsumerPayments: !!response.company.canReceiveConsumerPayments,
      hasOrgHierarchy: response.company.hasOrgHierarchy,
      hasTaxPayerIdentificationNumber: response.company.hasTaxPayerIdentificationNumber,
      needsAddressBookLinkOnConnect: needsAddressBookLinkOnConnect(accountingPackage),
      needsErpLinkOnConnect: needsErpLinkOnConnect(accountingPackage),
      vendorPaysFeesForNewAddressBooks:
        !!response.company.companyFeeSettings
        && !!response.company.companyFeeSettings.isPayingFeesForNewCustomers,
      canPurchaseInvoiceInsurance: response.company.canPurchaseInvoiceInsurance,
      accountingType,
      kbaRequired,
      registrationSource: response.company.registrationSource,
      twoStepPaymentLimit: response.company.twoStepPaymentLimit,
      accountType: response.company.accountType || CompanyAccountType.None,
      totalVendors: ((response.connectionsOverview || {}).connectedVendors || 0)
        + ((response.connectionsOverview || {}).unconnectedVendors || 0),
      totalCustomers: ((response.connectionsOverview || {}).connectedCustomers || 0)
        + ((response.connectionsOverview || {}).unconnectedCustomers || 0),
      vendorsAllowCreateInvoice: (response.connectionsOverview || {}).canSendInvoicesToVendors || false,
      hasMoreThanOneUser: response.company.hasMoreThanOneUser,
      hasSubscriptionPaymentMethod: (response.company.subscription || {}).hasCurrentCreditCard || false,
      isInSubscriptionTrial: (response.company.subscription || {}).isTrial || false,
      nextBillingDate: dateMapper((response.company.subscription || {}).nextBillingCycleDate),
      delinquencyDate: dateMapper((response.company.subscription || {}).delinquencyDate),
      hasCredentials: response.company.hasCredentials || false,
      paymentOptimization: {
        vccEnabled: (response.company.paymentOptimizationsEnabled || []).includes('Vcc'),
        achPlusEnabled: (response.company.paymentOptimizationsEnabled || []).includes('AchPlus')
      },
      campaigns: (response.company.campaigns || []).map(campaign => ({
        ...campaign,
        id: campaign.subscriptionCampaignId,
        startDate: dateMapper(campaign.startDate)
      })),
      additionalSettings: Object.keys(companyAdditionalSettings).reduce((val, key) => ({
        ...val,
        [key]: attemptJsonParse(companyAdditionalSettings[key])
      }), {})
    },
    companyUserSettings: {
      additionalSettings: Object.keys(companyUserAdditionalSettings).reduce((val, key) => ({
        ...val,
        [key]: attemptJsonParse(companyUserAdditionalSettings[key])
      }), {})
    },
    status: companyStatus,
    businessType,
    knowledgeAuthenticationRequired: kbaRequired,
    associatedCompaniesCount: response.user.associatedCompaniesCount || 0,
    timestamp: new Date() // Using the timestamp field to note when the user model has changed
  };

  let feeSettings = null;

  if (response.company.companyFeeSettings) {
    feeSettings = {
      paperCheck: response.company.companyFeeSettings.paperCheckSendingFee,
      digitalCheck: response.company.companyFeeSettings.digitalCheckSendingFee,
      monthlySubscriptionFee: response.company.companyFeeSettings.monthlySubscriptionFee,
      isAdvanced: response.company.companyFeeSettings.isAdvancedFeeRate
    };

    entities.push(createEntity(
      'current',
      CompanyFeeSettings.meta.name,
      feeSettings
    ));
  }

  identity.availableActions = determineIdentityActions(identity, feeSettings);

  let paymentAccountEntities = [];
  if (response.paymentAccounts) {
    paymentAccountEntities = response.paymentAccounts
      .map(paymentAccount => bankAccountEntityMapper(paymentAccount, identity));
  }

  identity.paymentAccounts = paymentAccountEntities.map(entity => createReferenceFromEntity(entity));

  entities.push(createEntity(
    'current', // only 1 identity globally
    schema.meta.name,
    identity
  ));

  (identity.companySettings.additionalSettings.acknowledgements || []).forEach((acknowledgement) => {
    entities.push(createEntity(acknowledgement, Acknowledgement.meta.name, {
      id: acknowledgement,
      completed: true,
      isCompanyWide: true
    }));
  });

  response.user.acknowledgements.forEach((acknowledgement) => {
    if (acknowledgement === 'accountSetup' && isEnabled('forceAccountSetup')) return;
    entities.push(createEntity(acknowledgement, Acknowledgement.meta.name, {
      id: acknowledgement,
      completed: true,
      isCompanyWide: false
    }));
  });

  paymentAccountEntities.forEach((entity) => {
    entities.push(entity);
  });

  if (response.isFullSession) {
    if (shouldcreateUseAppAuthorizedEntity) {
      entities.push(createUseAppAuthorizedEntity(UseAppAuthorizationReason.ExistingSession));
    }
  } else {
    entities.push(createUseAppUnauthorizedEntity());
  }

  return { entities };
};

export default createApiAction(
  {
    method: 'get',
    url: '/api/webApp/userModel',
    responseMapper: identityResponseMapper
  },
  'getIdentity' // Still used by the session reducer
);
