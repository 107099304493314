/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React from 'react';
import { connect } from 'react-redux';
import UserSettingsApi from 'api/settings/user';
import { SecurityQuestionOptions } from 'schemas/settings/user';
import { getEntity, createEntityReference } from 'schemas/state';
import { createCacheActions, makeApiCall } from 'actions/references';
import Messages from './index.messages';

const CreateTwoFactorActions = createCacheActions(
  'ExpeditedReg.ProvideCredentials.CreateTwoFactor',
  UserSettingsApi.createTwoFactorAuthentication
);

function MapStateToProps(state, props) {
  const optionsEntity = getEntity(state, createEntityReference('current', SecurityQuestionOptions.meta.name));
  const verificationStatus = CreateTwoFactorActions.getCacheResponse(state);

  return {
    securityQuestions: optionsEntity ? optionsEntity.options : null,
    isVerificationPending: verificationStatus ? !verificationStatus.isPhoneNumberLocked : false,
    isSendingPhoneNumber: CreateTwoFactorActions.isCacheLoading(state)
  };
}

const MapDispatchToProps = dispatch => ({
  getSecurityQuestions: () => dispatch(UserSettingsApi.getSecurityQuestions()),
  onCreateTwoFactor: phoneNumber => dispatch(CreateTwoFactorActions.makeApiCall({ phoneNumber })),
  onResetPhoneNumber: () => dispatch(CreateTwoFactorActions.clearCache()),
  onResendCode: () => dispatch(makeApiCall(
    'ExpeditedReg.ProvideCredentials.Resend',
    UserSettingsApi.resendTwoFactorAuthentication,
    {},
    {
      successMessage: <Messages.ResentCode.Message />
    }
  ))
});

export default connect(MapStateToProps, MapDispatchToProps);
