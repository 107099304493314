/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { addModal, createLaunchHook } from 'config/modals/registry';
import { wrapImport } from 'decorators/ModuleLoader';

const importModule = () => import(/* webpackChunkName: "modals" */ './index');

const CreateEditAddressBookModal = wrapImport(importModule);

export const useLaunchCreateEditAddressBookModal = createLaunchHook(importModule, null, { async: true });

export default addModal('CreateEditAddressBookModal', CreateEditAddressBookModal);
