
/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React from 'react';
import { ViewpostCustomerSupport } from 'config/messageLinks';
import Button from 'components/Button';
import HeaderText, { HeaderTextVariants } from 'components/HeaderText';
import GreyCheckmark from 'public/images/icons/grey-checkmark.svg';
import GreenCheckmark from 'public/images/icons/green-checkmark.svg';


function ConfirmAccount({
  method,
  onConfirm
}) {
  let header = null;
  let message = null;
  switch (method) {
    case 'manual':
      header = `Don't forget to verify micro deposits.`;
      message = <div>
        Two micro deposits will be deposited in your bank account. For security, you'll
        need to verify the amount of these deposits to complete the setup of your bank account.
        If you need help, please email {ViewpostCustomerSupport}.
      </div>;
      break;
    default:
      header = 'Bank Account Added!';
      message = <div>
        You are now able to receive electronic payments from your customers on Viewpost.
      </div>;
      break;
  }

  return (
    <>
      <div style={{
        textAlign: 'center',
        backgroundColor: 'white',
        border: '1px solid #ddd',
        borderRadius: '2px',
        padding: '30px 15px'
      }}>
        <img src={method === 'manual' ? GreyCheckmark : GreenCheckmark} />
        <HeaderText className="header" variant={HeaderTextVariants.Medium}>
          {header}
        </HeaderText>
        <div>
          {message}
        </div>
      </div>
      <div style={{ textAlign: 'right', marginTop: '30px' }}>
        <Button onClick={() => onConfirm?.()}>
          Continue
        </Button>
      </div>
    </>
  );
}

export default ConfirmAccount;