/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// { '2x': '/img1.png', '3x': '/img2.png'} -> '/img1.png 2x, /img2.png 3x'
export const makeSrcSet = (srcSet) => {
  return Object.keys(srcSet)
    .map(key => `${srcSet[key]} ${key}`)
    .join(',');
};

export default class Image extends Component {
  static propTypes = {
    willCenter: PropTypes.bool
  };

  static defaultProps = {
    willCenter: false
  };

  constructor(props) {
    super(props);
  }

  getImageClasses() {
    return classNames(
      'vp-image',
      {'center': this.props.willCenter},
      this.props.className
    );
  }

  render() {
    return (
      <img className={this.getImageClasses()}
        style={this.props.style}
        src={this.props.src || this.props.image.src} />
    );
  }
}
