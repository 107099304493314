/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { createApiAction } from 'api/core';

const createPlaidLinkToken = createApiAction({
  method: 'post',
  url: '/api/next/account/createPlaidLinkToken',
  saveResponseToResult: true
});

export default createPlaidLinkToken;