/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { getEntity } from 'schemas/state';

import logError from 'services/ErrorService';

const getIdentity = (state, { notExpected = false, returnNull = false } = {}) => {
  const identity = getEntity(state, state.session.identity);

  if (!identity && !notExpected) {
    logError('Expected identity, but identity is missing.', null, { includeStackTrace: true });
  }

  // Returning at least an empty object so that at least a JS error doesn't
  // take React down. We have the logged error, so you there is visiblity into
  // a problem.
  // Unless its not expected, then we want to return a falsy value to reflect
  // it isn't there.
  return identity || (returnNull ? null : {});
};

export default getIdentity;