/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { defineMessages } from 'react-intl';

export default defineMessages({
  NotSetDefaultError: {
    id: 'global_validationError_string_notset',
    description: 'TODO',
    defaultMessage: 'Please enter a value.'
  }
});
