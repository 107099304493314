/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

const s4 = () => {
  return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
};

export const generateFakeGuid = () => {
  return `${s4()}${s4()}-${s4()}-${s4()}-${s4()}-${s4()}${s4()}${s4()}`;
};

export const isGuid = (value) => {
  return value.match(/^.{9}-.{4}-.{4}-.{4}-.{12}$/);
};

export const toLowerCaseMap = (obj) => {
  const newObj = Object.keys(obj)
    .reduce((val, key) => ({ ...val, [key.toLowerCase()]: obj[key]}), {});

  return key => newObj[key.toLowerCase()];
};
