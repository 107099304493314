/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React from 'react';
import HeaderText from 'components/HeaderText';
import PathLink from 'components/Link/PathLink';
import ErnieWithLaptop from 'public/images/registration/ernieWithLaptop.png';
import Messages from './index.messages';
import './index.scss';

export const ClosedRegistration = () => {
  const centerStyle = { textAlign: 'center' };
  const messageStyle = { lineHeight: '20px' };

  return (
    <div className="closed-registration">
      <HeaderText style={{...centerStyle, paddingBottom: 16}}>
        <Messages.Header.Message/>
      </HeaderText>
      <div className="join-message" style={{...centerStyle, ...messageStyle}}>
        <Messages.Subheader.Message/>
      </div>
      <div className="emphasis-box">
        <HeaderText>
          <Messages.InvitationHeader.Message/>
        </HeaderText>
        <div style={{...messageStyle, paddingBottom: 24}}>
          <Messages.InvitationSubheader.Message/>
        </div>
        <HeaderText>
          <Messages.SalesHeader.Message/>
        </HeaderText>
        <div style={{...messageStyle, paddingBottom: 24}}>
          <Messages.SalesSubheader.Message
            contactUs={(
              <PathLink.Registration.CorporatePaymentsContact>
                <Messages.ContactUs.Message />
              </PathLink.Registration.CorporatePaymentsContact>
            )}
          />
        </div>
        <div className="ernie-image-container">
          <img src={ErnieWithLaptop} className="ernie-image" />
        </div>
      </div>
      <div style={centerStyle}>
        <Messages.AlreadyHaveAccount.Message
          logonLink={(
            <PathLink.Logon>
              <Messages.LogIn.Message/>
            </PathLink.Logon>
          )}
        />
      </div>
    </div>
  );
};

export default ClosedRegistration;
