/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React from 'react';
import './index.scss';
import classNames from 'classnames';
import DropdownArrowCaret from '../createDropdown/DropdownArrowCaret';

const LabelToggle = ({
  className,
  style,
  label,
  labelStyle,
  putCaretBeforeLabel,
  isMobile,
  hideLabelOnMobile,
  onClick,
  staticCaret,
  children
}) => {
  let hasLabel = label && !(isMobile && hideLabelOnMobile);

  let wrapperStyle = {
    cursor: 'pointer',
    display: 'inline-block',
    ...(style || {})
  };

  let wrappedLabelStyle = {
    paddingLeft: hasLabel && !putCaretBeforeLabel ? null : 8,
    paddingRight: hasLabel && putCaretBeforeLabel ? null : 8,
    ...(labelStyle || {})
  };

  const renderCaret = () => {
    return staticCaret ? <DropdownArrowCaret /> : children;
  };

  return (
    <div onClick={onClick} className={classNames('dropdown-label-toggle-component', className)} style={wrapperStyle}>
      <span style={{whiteSpace: 'nowrap'}}>
        {putCaretBeforeLabel ? renderCaret() : null}
        {staticCaret && (
          <div style={{opacity: 0, left: 50, width: 0, display: 'inline-block'}}>
            {children}
          </div>
        )}
        {hasLabel ? (
          <span className="viewstrap" style={wrappedLabelStyle}>
            {label}
          </span>
        ) : null}
        {putCaretBeforeLabel ? null : renderCaret()}
      </span>
    </div>
  );
};

export default LabelToggle;
