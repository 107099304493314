/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, { useEffect, useState } from 'react';

// import Button from 'components/Button';
// import Tooltip, { Placements } from 'components/Tooltip';
// import Icon, { IconTypes } from 'components/Icon';

import IconDropdownPanel, { IconTypes } from 'components/IconDropdownPanel';
import { Row, Column } from 'components/Viewstrap/Grid';
import LoadingSpinner from 'components/LoadingSpinner';

import useApi from 'api/hooks/useApi';
import { getAttachmentList, downloadAttachment, downloadAllAttachments } from 'api/common/attachment';

import { orderBy } from 'lodash';


function AttachmentRow({ attachment, isDownloading, download }) {
  return (
    <Row style={{
      padding: '10px',
      borderBottom: '1px solid #ddd'
    }}>
      <Column size={8}>
        {attachment.fileName}
      </Column>
      <Column size={4} style={{textAlign: 'right'}}>
        {isDownloading
          ? <><LoadingSpinner /> <em style={{fontSize: '12px'}}>Downloading...</em></>
          : <a style={{ fontSize: '12px' }} onClick={() => download(attachment.attachmentId)}>
            Download
          </a>}
      </Column>
    </Row>
  );
}

function Attachments({ settlementId, secureToken }) {

  const api = useApi(getAttachmentList, 'GetAttachmentList');
  const downloadApi = useApi(downloadAttachment, 'DownloadAttachment');
  const downloadAllApi = useApi(downloadAllAttachments, 'DownloadAllAttachments');

  const [downloadingId, setDownloadingId] = useState();

  useEffect(() => {
    if (settlementId && secureToken) {
      api.call({
        documentId: settlementId,
        documentType: 'Settlement',
        accessToken: secureToken,
        includeRelatedDocumentTypes: ['SettlementPayable']
      });
    }
  }, [settlementId, secureToken]);

  const download = (attachmentId) => {
    downloadApi.call({
      accessToken: secureToken,
      attachmentId
    });
    setDownloadingId(attachmentId);
  };

  let attachments = api.getResponse()?.items;

  if (!attachments?.length) return null;

  attachments = orderBy(attachments, x => x.fileName);

  const downloadAll = () => {
    let request = { accessToken: secureToken };
    attachments.forEach((a, i) => {
      request[`attachmentIds[${i}]`] = a.attachmentId;
    });
    downloadAllApi.call(request);
  };

  return (
    <IconDropdownPanel
      key="file-attachment"
      header={<div>
        Attachments
        <div style={{float: 'right'}}>
          {downloadAllApi.isLoading()
            ? <><LoadingSpinner /> <em style={{ fontSize: '12px' }}>Downloading All...</em></>
            : <a style={{ fontSize: '12px' }} onClick={() => downloadAll()}>
              Download All
            </a>}
        </div>
      </div>}
      labelMessage={{ id: 1, defaultMessage: 'Attachments'}}
      iconType={IconTypes.PaperClip}
      count={attachments.length}
      width={350}
      noPad={true}>
      {attachments.map(a => <AttachmentRow attachment={a} key={a.attachmentId}
        download={download}
        isDownloading={downloadApi.isLoading() && downloadingId === a.attachmentId} />)}
    </IconDropdownPanel>
  );
}

export default Attachments;