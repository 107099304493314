/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import Tooltip from 'components/Tooltip';
import Truncate from 'components/Truncate';

import FlagIcon, { isKnownCountry } from 'components/Icon/FlagIcon';

import { toEnumObject } from 'config/constants/utils';

import { lowerCaseArray } from 'utils/array';

import './index.scss';

export const LinesProp = toEnumObject([
  'LinesWithoutAddresseeProp',
  'LinesProp',
  'LineNumber'
]);

export const hasAddress = a => (
  a?.line1
  || a?.line2
  || a?.line3
  || a?.line4
  || a?.lines?.length
  || a?.linesWithoutAddressee?.length
  || a?.city
  || a?.stateProvince
  || a?.postalCode
);

const UsCountryStrings = lowerCaseArray([
  'US',
  'USA',
  'U.S.',
  'U.S.A.',
  'United States',
  'United States of America'
]);

const CanadaCountryStrings = lowerCaseArray([
  'CA',
  'CAN',
  'Canada'
]);

const isUsCountry = (country) => {
  if (!country || country.trim() === '') return true;

  return UsCountryStrings.includes(country.trim().toLowerCase());
};

const isCanadaCountry = country => country
  && CanadaCountryStrings.includes(country.trim().toLowerCase());

const formatPostalCode = (postalCode, country) => {
  if (isUsCountry(country)) {
    const stripped = (postalCode || '').replace(/\D/g, '');

    return stripped.length > 5
      ? `${stripped.substr(0, 5)}-${stripped.substr(5)}`
      : stripped;
  }

  if (isCanadaCountry(country)) {
    const stripped = (postalCode || '').replace(/^a-zA-Z0-9/g, '');

    return stripped.length === 6
      ? `${stripped.substr(0, 3)} ${stripped.substr(3)}`
      : stripped;
  }

  return postalCode;
};

export const OneLineAddress = ({
  afterLines,
  city,
  country,
  lines,
  maxLength,
  postalCode,
  stateProvince
}) => {
  let content = [ ...(lines || []) ];

  const cityState = city && stateProvince
    ? `${city}, ${stateProvince}`
    : city || stateProvince;

  const postalCodeElement = formatPostalCode(postalCode, country);

  const hideCountry = isUsCountry(country);

  const postalCodeAndCountry = postalCodeElement && country && !hideCountry
    ? `${postalCodeElement} ${country}`
    : postalCodeElement || country;

  // Check if a country was appended to the postal code, if not, move the postal code up to the city/state field
  if (cityState && postalCodeAndCountry && postalCodeAndCountry === postalCodeElement) {
    content.push(`${cityState} ${postalCodeAndCountry}`);
  } else {
    content.push(cityState);
    content.push(postalCodeAndCountry);
  }

  if (afterLines && afterLines.length) {
    content.push(...afterLines);
  }

  // Remove falsy/whitespace lines
  content = content.filter(line => line && line.trim() !== '');

  return (
    <>
      {content.reduce(
        (val, line, index) => {
          const key = `${line}${index}`;
          val.push(
            <Fragment key={key}>
              {line}
            </Fragment>
          );

          if (index !== content.length - 1) {
            val.push(
              <Fragment key={`${key}-after`}>
                &nbsp;&bull;&nbsp;
              </Fragment>
            );
          }

          return val;
        },
        []
      )}
    </>
  );
};

// This is only slim in the sense that it doesn't have as much markup around it as the primary one
// does, opting to use line breaks instead of divs to layout content. It also allows for more
// freeform text for lines, allowing for more content than just physical address lines. It also does
// a variation of truncation that doesn't piecemeal the tooltips, opting to just display the whole
// address in a tooltip.
export const SlimAddress = ({
  afterLines,
  city,
  country,
  lines,
  maxLength,
  postalCode,
  stateProvince
}) => {
  let content = [ ...(lines || []) ];

  const cityState = city && stateProvince
    ? `${city}, ${stateProvince}`
    : city || stateProvince;

  const postalCodeElement = formatPostalCode(postalCode, country);

  const hideCountry = isUsCountry(country);

  const postalCodeAndCountry = postalCodeElement && country && !hideCountry
    ? `${postalCodeElement} ${country}`
    : postalCodeElement || country;

  // Check if a country was appended to the postal code, if not, move the postal code up to the city/state field
  if (cityState && postalCodeAndCountry && postalCodeAndCountry === postalCodeElement) {
    content.push(`${cityState} ${postalCodeAndCountry}`);
  } else {
    content.push(cityState);
    content.push(postalCodeAndCountry);
  }

  if (afterLines && afterLines.length) {
    content.push(...afterLines);
  }

  // Remove falsy/whitespace lines
  content = content.filter(line => line && line.trim() !== '');

  const createRenderLine = shouldTruncate => (line, index) => (
    <Fragment key={`${index}${line}`}>
      {shouldTruncate && line.length >= maxLength ? (
        `${line.substr(0, maxLength)}&#8230;`
      ) : line}
      <br/>
    </Fragment>
  );

  const untruncatedElement = <>{content.map(createRenderLine(false))}</>;

  if (maxLength && content.some(line => line && line.length > maxLength)) {
    return (
      <Tooltip
        overlay={untruncatedElement}
      >
        {content.map(createRenderLine(true))}
      </Tooltip>
    );
  }

  return untruncatedElement;
};

const Address = ({
  city,
  clientReferenceId,
  companyName,
  country,
  line1,
  line2,
  line3,
  line4,
  lines,
  linesWithoutAddressee,
  linesProp,
  maxLength,
  pipedContent,
  postalCode: originalPostalCode,
  showEllipsis,
  stateProvince,
  style,
  toolTipPlacement,
  variant
}) => {
  const getLines = () => {
    switch (linesProp) {
      case LinesProp.LinesWithoutAddresseeProp:
        return linesWithoutAddressee || [];
      case LinesProp.LinesProp:
        return lines || [];
      case LinesProp.LineNumber:
        return [ line1, line2, line3, line4 ];
      default:
        return linesWithoutAddressee || [];
    }
  };

  const createRenderLine = addComma => (line, index) => line ? (
    <div
      key={`${line}-${index}`}
      className={showEllipsis ? 'ellipsis' : null}
    >
      {maxLength ? (
        <Truncate
          value={line}
          maxLength={maxLength}
          toolTipPlacement={toolTipPlacement}
        />
      ) : line}
      {addComma ? <>,</> : null}
    </div>
  ) : null;

  let cityState = city && stateProvince
    ? `${city}, ${stateProvince}`
    : city || stateProvince;

  const postalCodeElement = formatPostalCode(originalPostalCode, country);

  const hideCountry = isUsCountry(country);

  let postalCodeAndCountry = postalCodeElement && country && !hideCountry
    ? `${postalCodeElement} ${country}`
    : postalCodeElement || country;

  // Check if a country was appended to the postal code, if not, move the postal code up to the city/state field
  if (cityState && postalCodeAndCountry && postalCodeAndCountry === postalCodeElement) {
    cityState += ` ${postalCodeAndCountry}`;
    postalCodeAndCountry = null;
  }

  const name = companyName ? (
    <div key="company-name" className="ellipsis">
      <strong>
        { maxLength ? (
          <Truncate
            key="truncated-company-name"
            value={companyName}
            maxLength={maxLength}
            toolTipPlacement={toolTipPlacement}
          />
        ) : companyName }
      </strong>
    </div>
  ) : null;

  const clientReferenceIdLabel = clientReferenceId ? (
    <div className="ellipsis">
      {maxLength ? (
        <Truncate
          value={clientReferenceId}
          maxLength={maxLength}
        />
      ) : clientReferenceId}
    </div>
  ) : null;

  let cityStateDiv = cityState ? (
    <div key="city-state-default">
      {cityState}
    </div>
  ) : null;

  let zipCountryDiv = postalCodeAndCountry ? (
    <div key="zip-country-default">
      {postalCodeAndCountry}
    </div>
  ) : null;

  if (pipedContent) {
    const pipedContentSection = (
      <span key="piped-content">
        {pipedContent}
      </span>
    );

    if (zipCountryDiv) {
      zipCountryDiv = postalCodeAndCountry ? (
        <div key="zip-country" className="dividers">
          <span>
            {maxLength ? (
              <Truncate
                key="truncated-company-name"
                value={postalCodeAndCountry}
                maxLength={maxLength}
                toolTipPlacement={toolTipPlacement}
              />
            ) : postalCodeAndCountry}
          </span>
          {pipedContentSection}
        </div>
      ) : pipedContentSection;
    } else {
      cityStateDiv = cityState ? (
        <div key="city-state" className="dividers">
          <span>
            {maxLength ? (
              <Truncate
                key="truncated-company-name"
                value={cityState}
                maxLength={maxLength}
                toolTipPlacement={toolTipPlacement}
              />
            ) : cityState}
          </span>
          {pipedContentSection}
        </div>
      ) : pipedContentSection;
    }
  }

  if (variant === 'singleLine' || variant === 'twoLines') {
    return (
      <div
        className="address-parts-inline"
        style={style}
      >
        {getLines().map(createRenderLine(true))}
        {variant === 'twoLines' ? <br/> : null}
        {cityStateDiv}
        {zipCountryDiv}
      </div>
    );
  }

  let cityLine = `${city ?? ''}${city && stateProvince ? ',' : ''} ${stateProvince ?? ''} ${postalCodeElement ?? ''}`
    .trim();
  return (
    <div style={style}>
      {name}
      {clientReferenceIdLabel}
      {getLines().map(createRenderLine())}
      {maxLength ? (
        <Truncate
          key="truncated-company-name"
          value={cityLine}
          maxLength={maxLength}
          toolTipPlacement={toolTipPlacement}
        />
      ) : cityLine}
      {!hideCountry
        ? (
          <div>
            {isKnownCountry(country)
              ? <FlagIcon country={country} includeName={true} />
              : country}
          </div>
        )
        : null}
      {pipedContent
        ? (
          <div key="piped-content">
            {pipedContent}
          </div>
        )
        : null}
    </div>
  );
};

Address.propTypes = {
  companyName: PropTypes.string,
  lines: PropTypes.arrayOf(PropTypes.string),
  city: PropTypes.string,
  stateProvince: PropTypes.string,
  postalCode: PropTypes.string,
  country: PropTypes.string,
  linesWithoutAddressee: PropTypes.arrayOf(PropTypes.string),
  pipedContent: PropTypes.object,
  maxLength: PropTypes.number,
  showEllipsis: PropTypes.bool,
  variant: PropTypes.oneOf([
    'singleLine',
    'twoLines'
  ]),
  linesProp: PropTypes.oneOf([
    LinesProp.LinesWithoutAddresseeProp,
    LinesProp.LinesProp,
    LinesProp.LineNumber
  ])
};

export default Address;
