/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { get, pick } from 'lodash';
import { push, replace } from 'connected-react-router';

const convertToActions = (config, customAction) => {
  return Object.keys(config).reduce((val, key) => {
    const childConfig = config[key];

    const getBasePath = (params, query) => childConfig.toPath(params, query);

    const createDefaultAction = (params, query, state, shouldReplace, shouldOpen) => (dispatch) => {
      if (shouldOpen) return window.open(getBasePath(params, query));
      dispatch((shouldReplace ? replace : push)(getBasePath(params, query), state));
    };

    const action = (...params) => {
      const defaultAction = createDefaultAction(...params);

      if (!customAction) return defaultAction;
      return customAction({
        params: params[0],
        query: params[1],
        state: params[2],
        shouldReplace: params[3],
        shouldOpen: params[4],
        defaultAction,
        basePath: getBasePath(params[0], params[1])
      });
    };

    const childComponents = convertToActions(pick(childConfig, childConfig.children), customAction);
    Object.keys(childComponents).forEach((childKey) => {
      action[childKey] = childComponents[childKey];
    });

    return {
      ...val,
      [key]: action
    };
  }, {});
};

export const convertLogicalPathsToActions = (config, PathActions) => {
  return Object.keys(config).reduce((val, key) => {
    const childConfig = config[key];

    const action = (params, query, state, shouldReplace, shouldOpen) => (dispatch, getState) => {
      const {
        params: paramKeys,
        path,
        mapStateToParams,
        mapParamsToProps
      } = childConfig;

      const stateParams = mapStateToParams ? mapStateToParams(getState()) : null;
      const pathParams = pick({ ...(params || {}), ...(stateParams || {}) }, paramKeys);
      const actualPath = path(pathParams);
      const pathAction = get(PathActions, actualPath.id);

      const additionalParams = mapParamsToProps ? mapParamsToProps(pathParams) : null;

      dispatch(pathAction(
        { ...(params || {}), ...(additionalParams || {}) },
        query,
        state,
        shouldReplace,
        shouldOpen
      ));
    };

    const childComponents = convertLogicalPathsToActions(
      pick(childConfig, childConfig.children),
      PathActions
    );

    Object.keys(childComponents).forEach((childKey) => {
      action[childKey] = childComponents[childKey];
    });

    return {
      ...val,
      [key]: action
    };
  }, {});
};

export default convertToActions;
