/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */
/* eslint max-len:0 */
/* eslint no-trailing-spaces:0 */

import React from 'react';

import BlueHeaderOnWhiteLayout from 'layouts/BlueHeaderOnWhite';

import useWindowWidth from 'hooks/browser/useWindowWidth';


import './index.scss';

const ViewpostControls = () => {

  const windowWidth = useWindowWidth();

  return (
    <BlueHeaderOnWhiteLayout>
      <div className="viewstrap">
        <div style={{
          textAlign: 'left',
          width: windowWidth >= 1000 ? '1000px' : null,
          margin: windowWidth >= 1000 ? '0 auto' : '20px'
        }} className="terms-content">

          <div className="headline">
            Viewpost Controls
          </div>


          <p>
            Viewpost is committed to providing a secure, highly available service with safeguards that protect the processing integrity and confidentiality of its services.   See below to understand how Viewpost is working to provide these important services.
          </p>

          <h3>Security</h3>

          <p>
            Through implementation of industry recognized best practices, Viewpost has made security a critical component of its service offerings.  Viewpost develops, maintains, and implements  a defense-in-depth security program, designed to protect the security, integrity, and confidentiality of the system and its information.  Layers of protection are applied across the service ecosystem, beginning with a robust endpoint detection and response solution working side-by-side with privilege access management.  Encryption plays a vital role in protecting information throughout the platform.
          </p>

          <p>
            Benchmarking, hardening, strong authentication and media restrictions add additional protections.  Extensive logging and continuous monitoring provide awareness and when necessary, an Incident Response plan is in place to guide personnel.  Frequent scanning of assets and mitigation for findings helps ensure software remains patched against vulnerabilities. Firewall protections ensure applications are defended and unauthorized traffic is stopped.  Annual participation in third-party audits and penetration testing provide an outside view.  
          </p>

          <p>
            In addition to controls implemented internally, Viewpost takes advantage of safeguards provided by its cloud vendor. The security framework is reinforced through extensive documentation and training, with annual risk assessments providing additional insight.  While there can be no guarantees in terms of stopping all threats, Viewpost is committed to protecting its environment, and the data and resources contained within.
          </p>

          <h3>Availability</h3>

          <p>
            Viewpost maintains a highly available environment to adapt to failures, quickly restore customer data, and continue delivering a reliable platform to end users.  Cloud architecture allows for creativity when designing secure, highly available applications and systems.  Viewpost takes advantage of features provided by its cloud vendor to help ensure the availability of its services.  By procuring resources in datacenters that are physically separated, risks associated with singular data centers are eliminated and business continuity is protected.  
          </p>

          <p>
            To guard against regional failures, Viewpost has implemented a disaster recovery site and associated plan.  The disaster recovery plan is tested annually.  Backups are an important part of ensuring a successful recovery effort.  To help ensure the availability of its services, Viewpost retains talented individuals in support of its services and the underlying infrastructure it is built upon.
          </p>

          <h3>Processing Integrity</h3>

          <p>
            Viewpost provides a payment optimization service to our customers.  When Viewpost receives a file for processing, it is checked for correctness and authenticity.  Files that do not meet the criteria are rejected.  Personnel are notified and the proper protocols are engaged.  
          </p>

          <p>
            The system performs numerous checks, such as the above, to ensure transactions are processed completely, accurately, and timely.   As another example, checks are performed to prevent duplicate file runs.  Telemetry data is logged to track transactions throughout their lifecycle.  Events of importance are sent to personnel for examination and remediation.
          </p>

          <h3>Confidentiality</h3>

          <p>
            Protecting information is an important part of what we do.  Data can be on the move or at rest; it can be offline or online.  Regardless of its state, customer data is kept confidential and disclosed only to authorized parties. .  By encrypting data at rest and during transmission, it is protected.  The concept of ‘least privilege’ ensures individuals only see data that they have been authorized to access.  
          </p>

          <p>
            Multi-Factor authentication lessens the possibility of account take over and impersonation by a malicious actor.   Viewpost employees attend annual training that discusses this and other important confidentiality concepts.  Data Privacy policies guide personnel in the proper handling and disposal of confidential information.  Additionally, confidential information is retained as long as an account is active and/or in accordance with Viewpost’s data retention practices.  Confidential information is destroyed upon customer request, unless required to be retained for legal or regulatory purposes.  
          </p>

        </div>
      </div>
    </BlueHeaderOnWhiteLayout>
  );
};

export default ViewpostControls;