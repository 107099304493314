/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, { useState, useEffect } from 'react';

import Icon, { IconTypes } from 'components/Icon';
import Tooltip from 'components/Tooltip';

import moment from 'moment';

import TextBox from '../TextBox';

import './index.scss';

function OverlayButton({ children, onClick, isSelected }) {
  return (
    <div onClick={onClick}
      className={`component-time-picker-overlay-button ${isSelected ? 'selected' : null}`}>
      {children}
    </div>
  );
}

function TimeOverlay({ hour, minute, meridiem, setHour, setMinute, setMeridiem, includeMinutes }) {

  let hours = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  let minutes = [0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55];

  return (
    <div style={{
      display: 'flex',
      justifyContent: 'space-around'
    }}>
      <div>
        <div className="vp-label">Hour</div>
        <div>
          {hours.map((h) => {
            return (
              <OverlayButton key={h} onClick={() => setHour(h)}
                isSelected={h === parseInt(hour, 10)}>
                {h}
              </OverlayButton>
            );
          })}
        </div>
      </div>
      {includeMinutes
        ? (
          <div>
            <div className="vp-label">&nbsp;:&nbsp;</div>
          </div>
        )
        : null}
      {includeMinutes
        ? (
          <div>
            <div className="vp-label">Minute</div>
            <div>
              {minutes.map((m) => {
                return (
                  <OverlayButton key={m} onClick={() => setMinute(m)}
                    isSelected={m === parseInt(minute, 10)}>
                    {m < 10 ? '0' : null}{m}
                  </OverlayButton>
                );
              })}
            </div>
          </div>
        )
        : null}
      <div style={{ alignSelf: 'center'}}>
        <div className="vp-label">&nbsp;</div>
        <OverlayButton onClick={() => setMeridiem('AM')}
          isSelected={meridiem === 'AM'}>AM</OverlayButton>
        <OverlayButton onClick={() => setMeridiem('PM')}
          isSelected={meridiem === 'PM'}>PM</OverlayButton>
      </div>
    </div>
  );
}


const TIME_REGEX = /^([0]?[1-9]|[1][0-2]):[0-5][0-9][A|P][M]$/;
const HOUR_REGEX = /^([0]?[1-9]|[1][0-2])[A|P][M]$/;

function toTimeString(date, includeMinutes) {
  if (!date) return '';
  if (includeMinutes) {
    return moment(date).format('h:mmA');
  }
  return moment(date).format('hA');
}

function parse(timeString, includeMinutes) {
  if (includeMinutes && TIME_REGEX.test(timeString)) {
    let date = moment(timeString, 'h:mmA');
    return [date.format('h'), date.format('mm'), date.format('A'), date.toDate()];
  }

  if (!includeMinutes && HOUR_REGEX.test(timeString)) {
    let date = moment(timeString, 'hA');
    return [date.format('h'), date.format('mm'), date.format('A'), date.toDate()];
  }

  return [null, null, null, null];
}


/**
 * Input that returns time portion of a Date
 */
function TimePicker({
  value,
  onChange,
  includeMinutes = true,
  ...props
}) {

  const [timeString, setTimeString] = useState(toTimeString(value, includeMinutes));

  useEffect(() => {
    let newTimeString = toTimeString(value, includeMinutes);
    if (newTimeString !== timeString) {
      setTimeString(newTimeString);
    }
  }, [value]);

  const [hour, minute, meridiem] = parse(timeString, includeMinutes);

  const changeText = (text) => {
    let [, , , newDate] = parse(text, includeMinutes);
    if (newDate) {
      onChange(newDate);
    }
  };

  const setTime = (newHour, newMinute, newMeridiem) => {
    /* eslint-disable prefer-template */
    if (includeMinutes) {
      changeText(
        (newHour ?? hour ?? '12')
        + ':'
        + (newMinute ?? minute ?? '00')
        + (newMeridiem ?? meridiem ?? 'PM')
      );
    } else {
      changeText(
        (newHour ?? hour ?? '12')
        + (newMeridiem ?? meridiem ?? 'PM')
      );
    }
  };

  return (
    <div className="component-time-picker">
      <Tooltip trigger={['click']}
        overlay={
          <TimeOverlay hour={hour} minute={minute} meridiem={meridiem}
            setHour={v => setTime(v, null, null)}
            setMinute={v => setTime(null, v, null)}
            setMeridiem={v => setTime(null, null, v)}
            includeMinutes={includeMinutes} />
        }>
        <TextBox {...props}
          value={timeString}
          onChange={changeText}
          appendIcon={<Icon type={IconTypes.Scheduled} />}
          autocomplete="off"
          maxLength={8}
          placeholder={props.placeholder ?? 'Enter Time'} />
      </Tooltip>
    </div>
  );
}

export default TimePicker;