/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, { Component } from 'react';
import { Redirect } from 'react-router';
import { InvoiceType } from 'schemas/invoicing/invoice';

class OldInvoiceDetailsRedirect extends Component {
  render() {
    let isBills = this.props.isBills;
    let { params } = this.props.match;

    let nav = (isBills) ? 'bills' : 'invoices';
    let invoiceId = params.id;
    let context = params.context;
    let ownership = params.ownership;

    let invoiceType = 'o'; // out of band
    if (context === 'recurrence') {
      invoiceType = 's';
    } else if (
      (isBills && ownership === 'mine') // my payable
      ||      (!isBills && context === 'inbox') // theirs unlinked payable
      ||      (!isBills && ownership === 'theirs') // theirs linked payable
    ) {
      invoiceType = 'p';
    } else if (
      (!isBills && ownership === 'mine') // my receivable
      ||      (isBills && context === 'inbox') // theirs unlinked receivable
      ||      (isBills && ownership === 'theirs') // theirs linked receivable
    ) {
      invoiceType = 'r';
    }

    const redirectLocation = {
      pathname: `/${nav}/details/${invoiceType}/${invoiceId}`,
      query: location.query
    };

    return <Redirect to={redirectLocation} />;
  }
}

export const createOldInvoiceDetailsRedirect = isBills => props => (
  <OldInvoiceDetailsRedirect isBills={isBills} {...props} />
);

export const convertInvoiceDetailsUrlTypeToEnum = (type) => {
  if (!type) return null;
  // interpret invoice type form route/url
  // use shorthand notation to keep the url short and obscure actual type strings from user
  switch (type.toLowerCase()) {
    case 's': return InvoiceType.meta.map.Series;
    case 'rp': return InvoiceType.meta.map.Series; // Legacy
    case 'p': return InvoiceType.meta.map.Payable;
    case 'r': return InvoiceType.meta.map.Receivable;
    case 'o': return InvoiceType.meta.map.OutOfBandInvoice;
    case 'm': return InvoiceType.meta.map.LogicalDocument;
    case 'l': return InvoiceType.meta.map.LogicalDocument;
    default: return;
  }
};

export function convertInvoiceTypeToUrlTypeCode(invoiceType) {
  switch (invoiceType) {
    case InvoiceType.meta.map.Receivable: return 'r';
    case InvoiceType.meta.map.Payable: return 'p';
    case InvoiceType.meta.map.OutOfBandInvoice: return 'o';
    case InvoiceType.meta.map.Series: return 's';
    case InvoiceType.meta.map.LogicalDocument: return 'm';
    // TODO: Should we convert AP Items to this system too?
    // case InvoiceType.meta.map.APInboxItem: return 'ap';
    default: return null;
  }
}
