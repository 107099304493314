/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, { useEffect, useState } from 'react';
import { push } from 'connected-react-router';
import Cookies from 'js-cookie';
import { useDispatch, useSelector } from 'react-redux';

import PathActions from 'actions/paths';

import { GetPath, LinkRef } from 'config/links';

import { useHasNotBeenRedirected, useHasLoggedInOnThisPage } from '../utils';

const OnLogonRedirectWorkflowStep = ({
  active,
  updateState
}) => {
  // Hooks
  const dispatch = useDispatch();

  const redirect = useSelector(state => state.session.logOnRedirect);
  const loggedInThisPage = useHasLoggedInOnThisPage();
  const shouldShow = loggedInThisPage && !!redirect;

  const [ onboardingFlow ] = useState(
    () => Cookies.get('OnboardingFlow', { secure: true })
  );

  const hasNotBeenRedirected = useHasNotBeenRedirected();
  const willShow = hasNotBeenRedirected && (shouldShow || onboardingFlow);
  const willCleanupState = !!redirect;

  useEffect(
    () => {
      updateState(!willShow && !willCleanupState ? 'skipped' : 'loaded');
    },
    [ willShow ]
  );

  const execute = async () => {
    if (willCleanupState) {
      dispatch({ type: 'SaveLogonRedirect' });
    }

    if (willShow) {
      dispatch({ type: 'HasBeenRedirected', source: 'OnLogonRedirect' });

      if (onboardingFlow) {
        Cookies.remove('OnboardingFlow');
        if (onboardingFlow === 'SyncXero') {
          dispatch(push(GetPath(LinkRef.sync.syncOnboarding, { syncPackage: 'Xero' })));
        }

        if (onboardingFlow === 'SyncQuickBooksOnline') {
          dispatch(push(GetPath(LinkRef.sync.syncOnboarding, { syncPackage: 'QBOnline' })));
        }
      } else if (redirect) {
        dispatch(push(redirect));
      } else {
        dispatch(push(PathActions.Dashboard()));
      }
    }

    updateState('completed');
  };

  useEffect(
    () => {
      if (active) {
        // useEffect doesn't like being passed an async function - hence why this broken out
        execute();
      }
    },
    [ active ]
  );

  // Render
  return <></>;
};

export default OnLogonRedirectWorkflowStep;
