/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { useRef } from 'react';

// Similar to useEffect, however this runs the func when this hook is invoked,
// whereas useEffect waits until after the first render or even useLayoutEffect
// waits until you are in the first render. Ideally this is used for non-render
// impacting effects. This also doesn't support the cleanup return that those
// hooks provide.
const useImmediateEffect = (func, deps) => {
  // Hooks
  const prevDepsRef = useRef();

  // Action
  if (deps != null
    && deps.length === prevDepsRef.current?.length
    && deps.every((dep, index) => dep === prevDepsRef.current[index])) {
    return;
  }

  // Copy the array and its elements by reference
  prevDepsRef.current = deps != null
    ? deps.map(e => e)
    : deps;

  func();
};

export default useImmediateEffect;