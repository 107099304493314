/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, { useMemo } from 'react';

import twoFactorResendResetPasswordRequest from 'api/session/resetPassword/twoFactorResendResetPasswordRequest';
import twoFactorSubmitResetPasswordRequest from 'api/session/resetPassword/twoFactorSubmitResetPasswordRequest';

import TwoFactorPrompt from 'containers/Logon/TwoFactorPrompt';

import createExecuteApiResultHooks from 'hooks/apiResult/createExecuteApiResultHooks';

const SubmitCodeHooks = createExecuteApiResultHooks({
  endpoint: twoFactorSubmitResetPasswordRequest
});

const ResendCodeHooks = createExecuteApiResultHooks({
  endpoint: twoFactorResendResetPasswordRequest
});

const ResetPasswordTwoFactorPrompt = ({
  error,
  onUpdate,
  token
}) => {
  // Hooks
  const executeSubmit = SubmitCodeHooks.useExecuteRequest();
  const isSubmitting = SubmitCodeHooks.useIsLoading();

  const onSubmit = useMemo(
    () => async (code) => {
      const [ success, action ] = await executeSubmit({ code, token });

      if (success) {
        onUpdate(action.payload.result);
      }
    },
    [ executeSubmit, onUpdate, token ]
  );

  const executeResend = ResendCodeHooks.useExecuteRequest();
  const isResending = ResendCodeHooks.useIsLoading();

  const onResendCode = useMemo(
    () => options => executeResend({ token }, options),
    [ executeResend, token ]
  );

  // Render
  return (
    <TwoFactorPrompt
      error={error}
      isResending={isResending}
      isSubmitting={isSubmitting}
      onResendCode={onResendCode}
      onSubmit={onSubmit}
    />
  );
};

export default ResetPasswordTwoFactorPrompt;