/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import t from 'tcomb-validation';
import { set } from 'lodash';

export function onlyEntityType(entities, schema) {
  return entities.filter(entity => entity.type === schema.meta.name);
}

export function convertObjectToValueArray(obj) {
  return Object.keys(obj).map(key => obj[key]);
}

export function sanitize(obj, schema) {
  let result = t.validate(obj, schema);
  // If at first you don't succeed...
  if (result && !result.errors) {
    return result.value;
  }

  // Then step through the errors and set their paths to null
  result.errors.forEach((err) => {
    // This can probably be smarter - this makes the assumption of
    // the underlying data type to have t.maybe, we can validate that
    // assumption here
    set(obj, err.path, null);
  });

  return t.validate(obj, schema).value;
}

/**
 * Clears a struct of invalid types - currently null/undefined
 * Use this when using ternary operators to easily switch between something
 * being needed in one case, but not in another.
 * Must be applied at the object before its passed into t.struct
 */
export const removeInvalidTypes = (obj) => {
  Object.keys(obj).forEach((key) => {
    if (obj[key] == null) delete obj[key]; // delete
  });
  return obj;
};

// If predicate true, wrap type with t.maybe
export const optionallyApplyMaybe = (predicate, type) => predicate ? t.maybe(type) : type;

