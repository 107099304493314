/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */
/* eslint max-len:0 */

import { toMessages } from 'config/messages';

export default toMessages('Registration.ExpeditedRegistration', {
  IsPersonalUser: 'What best describes you?',
  CompanyName: 'Company Name',
  FirstName: 'First Name',
  LastName: 'Last Name',
  Email: 'Email Address',
  VerifyEmail: 'Verify Email Address',
  Address: 'Address',
  AddressLine1: 'Address Line 1',
  AddressLine2: 'Address Line 2',
  City: 'City',
  StateProvince: 'State',
  PostalCode: 'ZIP Code',
  Phone: 'Work Phone',
  PersonalPhone: 'Phone',
  PaymentMethod: 'Enter Bank Account Information',
  Success: 'You are now setup to receive electronic payments. Congratulations!',
  InvalidInvitation: 'This invitation is no longer valid. If you aren\'t already registered, ask {companyName} to send you another invitation.',
  Failure: 'We\'re sorry, but something unexpected went wrong. We\'ll have our top minds work on fixing it. Please contact {customerSupport} for further assistance.',
  EmailAddressInUse: 'This email address is already registered. If this email belongs to you, please {loginLink} to connect to your trading partner.',
  LoginLink: 'Log In',
  AccountInUse: 'This bank account is already registered on Viewpost. Please contact {customerSupport} if you would like to register this account for multiple companies or you can choose another bank account.',
  AccountWrongType: 'You must select a checking account. Please select another bank account.',
  Disclaimer: 'By providing your payment account information, you accept our {tou}, {paymentTerms}, {privacyPolicy}, and fees for receiving electronic payment.',
  BusinessVendorOption: 'I am a business or sole proprietorship geting paid by this company',
  PersonVendorOption: 'I am an individual getting paid by this company',
  BusinessCustomerOption: 'I am a business or sole proprietorship paying this company',
  PersonCustomerOption: 'I am an individual paying this company'
});

export const SuccessMessages = toMessages('Registration.ExpeditedRegistration.Success', {
  PlaidHeader: 'Electronic payments enrollment is complete.',
  PlaidSubheader: 'For questions about receiving electronic payments, please email {customerSupportLink}.',
  ManualHeader: 'Don’t forget to verify micro deposits.',
  ManualSubheader: 'Two micro deposits will be deposited in your bank account. For security, you’ll need to verify the amount of these deposits to complete the setup of your bank account. If you need help, please email {customerSupportLink}.'
});
