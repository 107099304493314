/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class TableColumn extends Component {
  static propTypes = {
    isHeader: PropTypes.bool,
    isFooter: PropTypes.bool,
    isSelected: PropTypes.bool,
    columnSpan: PropTypes.number
  };

  static defaultProps = {
    isHeader: false
  };

  constructor(props) {
    super(props);
  }

  render() {
    if (this.props.isHeader) {
      return (
        <th className={this.props.className}
          style={this.props.style}
          colSpan={this.props.columnSpan}
          onClick={this.props.onClick}>
          {this.props.children}
        </th>
      );
    }

    return (
      <td className={this.props.className}
        style={this.props.style}
        colSpan={this.props.columnSpan}
        onClick={this.props.onClick}>
        {this.props.children}
      </td>
    );
  }
}
