/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, {
  forwardRef,
  useImperativeHandle,
  useLayoutEffect,
  useRef,
  useState
} from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import RcTooltip from 'rc-tooltip';

import HeaderText, { HeaderTextVariants } from 'components/HeaderText';


import './index.scss';

export const Placements = {
  Top: 'top',
  Left: 'left',
  Right: 'right',
  Bottom: 'bottom',
  TopLeft: 'topLeft',
  TopRight: 'topRight',
  BottomLeft: 'bottomLeft',
  BottomRight: 'bottomRight',
  RightTop: 'rightTop',
  RightBottom: 'rightBottom',
  LeftTop: 'leftTop',
  LeftBottom: 'leftBottom'
};

const Tooltip = forwardRef(
  (
    {
      arrowContent,
      autoAlign,
      children,
      childWrapperStyle,
      className,
      defaultVisible,
      destroyTooltipOnHide,
      doublewide,
      forceOnlyClickOnTouch,
      header,
      headerColor,
      mouseLeaveDelay,
      offsetX,
      offsetY,
      onClick,
      onVisibleChange,
      overlay,
      overlayClassName,
      overlayStyle,
      padContent,
      placement,
      trigger,
      wide,
      variant,
      visible,
      willAnimate
    },
    ref
  ) => {
    // Hooks
    const tooltipRef = useRef();
    const childRef = useRef();

    const [ { childHeight, childWidth }, setChildSize ] = useState(
      { childHeight: 0, childWidth: 0 }
    );

    useImperativeHandle(
      ref,
      () => ({
        close: () => {
          tooltipRef.current?.close();
        },
        forceAlign: () => {
          tooltipRef.current?.forcePopupAlign();
        }
      })
    );

    useLayoutEffect(
      () => {
        if (childRef.current) {
          const { height, width } = childRef.current.getBoundingClientRect();
          setChildSize({ childHeight: height, childWidth: width });
        }
      },
      []
    );

    // Render
    let adjustedOffsetX = offsetX;
    let adjustedOffsetY = offsetY;
    if (placement === Placements.TopLeft || placement === Placements.BottomLeft
        || placement === Placements.TopRight || placement === Placements.BottomRight) {
      const arrowOffset = 25;
      // the arrow is on a 25px offset from the edge of the overlay, if this means that it will not point to the child, then adjust with an offset
      if (childWidth && (childWidth / 2 < arrowOffset)) {
        const arrowWidth = 15;
        adjustedOffsetX += arrowOffset - childWidth / 2 + arrowWidth / 2;
      }
      if (placement === Placements.TopLeft || placement === Placements.BottomLeft) {
        // flip offset if on the left side
        adjustedOffsetX *= -1;
      }
    } else if (placement === Placements.LeftTop || placement === Placements.LeftBottom
        || placement === Placements.RightTop || placement === Placements.RightBottom) {
      const arrowOffset = 20;
      // the arrow is on a 25px offset from the edge of the overlay, if this means that it will not point to the child, then adjust with an offset
      if (childHeight && (childHeight / 2 < arrowOffset)) {
        const arrowHeight = 15;
        adjustedOffsetY -= (arrowOffset - childHeight / 2 + arrowHeight / 2);
      }
      if (placement === Placements.RightBottom || placement === Placements.LeftBottom) {
        // flip offset if on the left side
        adjustedOffsetY *= -1;
      }
    }

    const createOverlay = () => (
      <div className="viewstrap">
        {header ? (
          <HeaderText
            color={headerColor}
            variant={HeaderTextVariants.Medium}
            style={{ marginBottom: 4 }}
          >
            {header}
          </HeaderText>
        ) : null}
        {overlay}
      </div>
    );

    return (
      <RcTooltip
        align={{
          offset: [ adjustedOffsetX, adjustedOffsetY ],
          overflow: {
            adjustX: autoAlign,
            adjustY: autoAlign
          }
        }}
        arrowContent={arrowContent}
        defaultVisible={defaultVisible}
        destroyTooltipOnHide={destroyTooltipOnHide}
        mouseLeaveDelay={mouseLeaveDelay}
        onClick={onClick}
        onVisibleChange={onVisibleChange}
        overlay={createOverlay}
        overlayClassName={classNames(
          overlayClassName,
          variant, {
            'wide': wide,
            'doublewide': doublewide,
            'padded': padContent == null || padContent
          }
        )}
        overlayStyle={overlayStyle}
        placement={placement}
        ref={tooltipRef}
        transitionName={classNames({
          'rc-tooltip-animation': willAnimate
        })}
        trigger={trigger == null ? [ 'click', 'hover' ] : trigger}
        {...(visible != null ? { visible } : null)}
      >
        <div
          className={className}
          ref={childRef}
          style={{
            display: 'inline-block',
            cursor: trigger?.includes('click') ? 'pointer' : null,
            ...(childWrapperStyle || {})
          }}
        >
          {children}
        </div>
      </RcTooltip>
    );
  }
);

Tooltip.propTypes = {
  /** content to go in the tooltip popup */
  overlay: PropTypes.node,
  placement: PropTypes.oneOf(Object.keys(Placements).map(key => Placements[key])),
  wide: PropTypes.bool,
  willAnimate: PropTypes.bool,
  /** delay before disappearing (in sec) */
  mouseLeaveDelay: PropTypes.number,
  trigger: PropTypes.arrayOf(PropTypes.oneOf(['hover', 'click', 'focus'])),
  overlayClassName: PropTypes.string,
  overlayStyle: PropTypes.object,
  onVisibleChange: PropTypes.func,
  defaultVisible: PropTypes.bool,
  /** whether the tooltip is visible */
  visible: PropTypes.bool,
  arrowContent: PropTypes.node,
  destroyTooltipOnHide: PropTypes.bool,
  className: PropTypes.string,
  /**
    style to use for the wrapper placed around the tooltip trigger component
    (e.g. if you want your child/content to have block or inline layout)
  */
  childWrapperStyle: PropTypes.object,
  /** adjust the X position of the overlay */
  offsetX: PropTypes.number,
  /** adjust the Y position of the overlay */
  offsetY: PropTypes.number,
  /** automatically align if tooltip goes offscreen */
  autoAlign: PropTypes.bool,
  /** Optional header section in the tooltip */
  header: PropTypes.node,
  /** Whether or not to use default padding on tooltip content */
  padContent: PropTypes.bool,
  /**
    Whether or not to respect 'trigger' prop on touch devices. Used to deal with
    tooltips that live on top of clickable areas (mainly to fix Android browsers)
  */
  forceOnlyClickOnTouch: PropTypes.bool
};

Tooltip.defaultProps = {
  willAnimate: true,
  mouseLeaveDelay: 0.3,
  defaultVisible: false,
  destroyTooltipOnHide: false,
  placement: Placements.Top,
  offsetY: 0,
  offsetX: 0,
  autoAlign: true
};

export default Tooltip;
