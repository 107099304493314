/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { createApiAction } from 'api/core';

const verifyTwoFactorAuthentication = createApiAction({
  method: 'post',
  url: '/ajax/profile/confirmTwoStepVerification',
  requestBodyMapper: ({ authenticationCode }) => ({ authenticationCode }),
  responseMapper: ({
    allowAdditionalAttempts,
    response,
    success
  }) => ({
    result: {
      success: response?.success ?? success,
      allowAdditionalAttempts: response?.allowAdditionalAttempts ?? allowAdditionalAttempts
    }
  })
});

export default verifyTwoFactorAuthentication;