/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React from 'react';

import Button from 'components/Button';

import CommonMessages from 'config/messages/common';

import { NamePrefixContext } from './context';
import FieldOption from './FieldOption';

const ListInput = ({
  children,
  name,
  onChange,
  value
}) => {
  const { length } = value || [];

  const listChildren = [];

  for (let i = 0; i < length; i += 1) {
    listChildren.push(
      <FieldOption key={i} name={`[${i}]`}>
        {children}
      </FieldOption>
    );
  }

  return (
    <NamePrefixContext.Provider value={name}>
      {listChildren}
      <Button onClick={() => onChange([...(value || []), null])}>
        <CommonMessages.Add.Message />
      </Button>
    </NamePrefixContext.Provider>
  );
};

export default ListInput;