/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { useEffect, useState } from 'react';

const useLoadScript = (
  promise
) => {
  // Hooks
  const [ isLoaded, setIsLoaded ] = useState(false);
  const [ hasError, setHasError ] = useState(false);

  useEffect(
    () => {
      if (!promise) return;

      const execute = async () => {
        try {
          await promise;
          setIsLoaded(true);
        } catch (e) {
          setHasError(true);
          throw e;
        }
      };

      execute();
    },
    [ promise ]
  );

  // Action
  return [ isLoaded, hasError ];
};

export default useLoadScript;