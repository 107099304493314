/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

export const HIGHLIGHT_ACTION_TYPE = 'HIGHLIGHT_ACTION_TYPE';

/**
  Highlight items
  @param {Array} itemKeys - array of keys that represent items in the view (e.g. array of invoice ids)
  @param {String} viewKey - key representing the view in which this applies (e.g. BillsList)
 */
export function highlightItems(itemKeys, viewKey) {
  return {
    type: HIGHLIGHT_ACTION_TYPE,
    payload: {
      itemKeys,
      viewKey,
      isOn: true
    }
  };
}

/**
  Remove the highlight on the items
  @param {Array} itemKeys - array of keys that represent items in the view (e.g. array of invoice ids)
  @param {String} viewKey - key representing the view in which this applies (e.g. BillsList)
 */
export function unhighlightItems(itemKeys, viewKey) {
  return {
    type: HIGHLIGHT_ACTION_TYPE,
    payload: {
      itemKeys,
      viewKey,
      isOn: false
    }
  };
}


export function getHighlightedItems(state, viewKey) {
  if (state.highlights && state.highlights[viewKey]) {
    return state.highlights[viewKey];
  }

  return [];
}
