/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import t from 'tcomb-validation';
import { cloneDeep, isArray, isFunction } from 'lodash';
import logError from 'services/ErrorService';
import {
  CreatableEntity,
  createEntity,
  createReferenceFromEntity,
  EntityReference,
  getEntity
} from 'schemas/state';
import { ApiResult, EntryApiResult } from 'schemas/api';
import ApiAction from './ApiAction';
import { getApi } from './init';
import callApi from './callApi';
import { callDownloadApiAsync } from './callDownloadApi';
import { getApiResult, getEntryApiResult } from './utils.state';
import {
  logValidateEntityError,
  validateEntity,
  validationErrorsToString
} from './utils.validation';

export const ActionPayloadSchema = t.struct({
  entities: t.list(CreatableEntity),
  updated: t.maybe(t.list(t.struct({
    context: t.maybe(t.String),
    contexts: t.maybe(t.list(t.String)),
    entityType: t.maybe(t.String),
    entityTypes: t.maybe(t.list(t.String)),
    entityRef: t.maybe(EntityReference),
    entityRefs: t.maybe(t.list(EntityReference)),
    reason: t.maybe(t.union([ t.String, t.Object ]))
  }))),
  references: t.list(EntityReference),
  pagination: t.maybe(t.struct({
    page: t.Number,
    pageSize: t.Number,
    totalItems: t.maybe(t.Number),
    totalPages: t.maybe(t.Number),
    isCapped: t.maybe(t.Boolean),
    sortFields: t.maybe(t.Array)
  })),
  additionalInfo: t.maybe(t.Object), // custom payload, only for cases where we can't fit response nicely into references
  config: t.maybe(t.Object)
});

// Wraps onComplete so a thunk can be returned by it and then subsequently executed
// 1) onComplete that is passed to the API Action must be a function that takes in (success, action)
// 2) But, onComplete can return a function that ideally is a thunk. This returned thunk will be
//    executed when onComplete is.
const wrapOnComplete = (
  onComplete,
  success,
  apiAction
) => (...thunkParams) => {
  const returned = onComplete(success, apiAction);
  if (isFunction(returned)) {
    returned(...thunkParams);
  }
};

// Accept either an array of updated structs or just a single one, but normalize it to an array so
// that the apiGenerator reducer knows only one type to expect.
const getUpdated = (
  markUpdated,
  {
    apiParams,
    getState,
    response
  }
) => {
  if (!markUpdated) return [];

  let value = markUpdated;
  if (isFunction(markUpdated)) {
    value = markUpdated({ response, apiParams, getState });
  }

  if (!value) return [];

  return isArray(value) ? value : [ value ];
};

const createAction = (config, actionTypes) => {
  const {
    acceptEmptyResponse,
    downloadResponse,
    errorMapper,
    // ignoreDispatch is a very spartan way to execute API calls, nothing goes through redux.
    // However, it will respond the result through onComplete
    ignoreDispatch,
    markUpdated,
    method: endpointMethod,
    requestEntityMapper,
    responseMapper,
    saveResult: configSaveResult,
    // This will set the successful call response in the ApiResult. Limit using this where the
    // overhead of creating an entity doesn't provide meaningful value (e.g., only used in one place).
    // This is a speedbump that was added to notate when an endpoint's response is going to be
    // persisted and likely mapped into a component, which adds tendrils if you are trying to figure
    // out how the API response is being used (vs just simply looking at the responseMapper). Also,
    // in 95% of cases, this is not needed because the API response is merely needed in the
    // responseMapper or the end of the createAction action, so saving this merely bloats redux.
    saveResponseToResult,
    // This will set the successful call responseMapper result in the ApiResult. Limit using this
    // where the overhead of creating an entity doesn't provide meaningful value (e.g. only used in
    // one place.). In 99% of cases this is not needed because it either just returns entities or
    // is used on the end of a createAction action, so saving this merely bloats redux.
    saveResponseMapperResultToResult,
    shouldErrorMaptoSuccess,
    shouldSuccessMapToError,
    url: endpointUrl
  } = config;

  const isResponseAnError = (response) => {
    const { isSuccessAnError } = getApi();
    const { body } = response || {};

    if (isSuccessAnError && isSuccessAnError(body || {})) {
      return true;
    } if (shouldSuccessMapToError && shouldSuccessMapToError(body || {})) {
      return true;
    }

    return false;
  };

  // map action parameters (client models) to API request params (API models)
  const create = (
    apiParams,
    {
      apiResultId: providedApiResultId,
      correlationId,
      entityScope,
      entryApiResultId: providedEntryApiResultId,
      markUpdated: requestMarkUpdated,
      onComplete,
      saveResult,
      ...additionalMeta
    } = {}
  ) => {
    const apiResultId = providedApiResultId || correlationId;
    const entryApiResultId = providedEntryApiResultId || correlationId;

    const actionThunk = async (dispatch, getState) => {
      const originalApiParams = cloneDeep(apiParams);

      const createApiResult = (
        entities,
        isFetch,
        {
          apiResultReferences,
          error,
          pagination,
          response,
          responseMapperResult
        } = {}
      ) => {
        if (saveResult || configSaveResult) {
          if (!apiResultId) {
            console.warn('Cannot cache without a name, ignoring cache param.');
            return [];
          }
          const previousEntry = getEntryApiResult(getState(), entryApiResultId);

          const newEntryPreviousList = (
            [
              (previousEntry || {}).current,
              ...((previousEntry || {}).previous || [])
            ]
          )
            // This will remove any previous entities with the same ID as the current API result.
            // In effect, this means we won't have any dupes in this list nor will it contain
            // the current active ID. This maintains a clean record of all the IDs that have been
            // used in the context of this ApiResult
            .filter(x => x && x.id !== apiResultId);

          const firstPreviousEntryResult = newEntryPreviousList.length
            ? getEntity(getState(), newEntryPreviousList[0])
            : null;

          const previousResult = getApiResult(getState(), apiResultId);

          const apiResultEntity = createEntity(
            apiResultId,
            ApiResult.meta.name,
            {
              id: apiResultId,
              loading: isFetch,
              error,
              // Defer to whatever references are created (largely to support listMapperConfig right
              // now), otherwise all entities in the response are going to be available.
              refs: apiResultReferences || (entities || []).map(createReferenceFromEntity),
              request: originalApiParams,
              response: saveResponseToResult ? response : null,
              responseMapperResult: saveResponseMapperResultToResult ? responseMapperResult : null,
              created: isFetch || error ? null : new Date().getTime(),
              completed: isFetch ? null : new Date().getTime(),
              previous: (
                !isFetch && previousResult
                // If we are not fetching, then the previous result is actually this entity in the
                // state of being fetched, whose previous entity will be the actual previous entity
                // last gotten.
                  ? previousResult.previous
                  : previousResult
              // If we don't have a previous result for these params, then we will coalesce the
              // previous to the last call made (read: different params)
              ) || firstPreviousEntryResult,
              pagination
            },
            null,
            { scope: entityScope }
          );

          const entryApiResultEntity = createEntity(
            entryApiResultId,
            EntryApiResult.meta.name,
            {
              current: createReferenceFromEntity(apiResultEntity),
              previous: newEntryPreviousList
            },
            null,
            { scope: entityScope }
          );

          return [ apiResultEntity, entryApiResultEntity ];
        }

        return [];
      };

      let fetchEntities = [];
      let fetchUpdatedEntities = [];

      if (requestEntityMapper) {
        const requestMapResult = requestEntityMapper(
          apiParams,
          getState(),
          {
            endpointConfig: config,
            correlationId,
            apiResultId,
            saveResult,
            ...additionalMeta
          }
        );

        fetchEntities = fetchEntities.concat(requestMapResult.entities || []);
        fetchUpdatedEntities = requestMapResult.updated
          ? getUpdated(requestMapResult.updated, { apiParams, getState })
          : null;
      }

      const metaRequestId = (new Date()).getTime();

      // initiate request
      if (!ignoreDispatch) {
        dispatch(new ApiAction({
          type: actionTypes.fetch,
          payload: {
            params: apiParams,
            entities: fetchEntities.map(
              (entity) => {
                if (entityScope != null && entity && entity.scope == null) {
                  entity.scope = entityScope;
                }

                return entity;
              }
            ),
            metaEntities: [
              ...createApiResult(
                fetchEntities,
                true,
                { apiResultReferences: [] }
              )
            ],
            updated: fetchUpdatedEntities
          },
          meta: {
            config,
            correlationId,
            apiResultId,
            requestId: metaRequestId,
            ...additionalMeta
          }
        }).toReduxAction());
      }

      const {
        createRequest,
        headersToEntitiesMapper,
        mapErrorToPayload,
        transformUrl
      } = getApi();

      // expose inputs to reducers
      const meta = {
        config,
        params: apiParams,
        correlationId,
        apiResultId,
        requestId: metaRequestId,
        ...additionalMeta
      };

      const emitFailure = (response) => {
        const action = {
          type: actionTypes.fail,
          payload: {
            response
          },
          meta: {
            ...meta,
            response // TODO: Remove this from meta. Adopt the convention: meta -> preresponse, payload -> postresponse
          },
          error: true
        };

        if (ignoreDispatch) {
          if (onComplete) {
            onComplete(false, action);
          }

          return [ false, action ];
        }

        const {
          entities: payloadEntities,
          reason: payloadErrorReason
        } = mapErrorToPayload?.(response, config) || {};
        // Currently only taking reason from the endpoint config error mapper
        const errorResponse = errorMapper ? errorMapper(response, apiParams) : null;
        const errorReason = errorResponse?.reason || payloadErrorReason;
        const apiEntitiesResult = createApiResult(
          [],
          false,
          {
            apiResultReferences: [],
            error: errorReason || 'Unknown'
          }
        );

        action.payload.metaEntities = apiEntitiesResult || [];
        action.payload.entities = [
          ...(errorResponse?.entities || []),
          ...(payloadEntities || [])
        ];
        action.payload.errorReason = errorReason;

        const entityValidations = (action.payload.entities || [])
          .map(entity => validateEntity(entity, config))
          .filter(validateResult => validateResult !== true);

        if (entityValidations.length) {
          logError(
            'Entities failed to validate on failure.',
            {
              apiUrl: endpointUrl,
              method: endpointMethod
            }
          );
          entityValidations.forEach(logValidateEntityError);
          return [ false, action ];
        }

        meta.response = response;

        const apiAction = new ApiAction(action);

        try {
          dispatch(apiAction.toReduxAction({
            onDispatch: onComplete ? wrapOnComplete(onComplete, false, apiAction) : null
          }));
        } catch (e) {
          logError(
            'Caught an error when dispatching the Failure API Action.',
            {
              apiUrl: endpointUrl,
              method: endpointMethod,
              error: e
            }
          );
        }

        if (response.error) {
          logError(
            'Caught an error when processing the request',
            {
              apiUrl: endpointUrl,
              method: endpointMethod,
              error: response.error,
              errorContext: response.errorContext
            }
          );
        }

        return [ false, apiAction ];
      };

      const onSuccess = (response) => {
        const action = {
          type: actionTypes.success,
          payload: {
            response
          },
          meta: {
            ...meta,
            response // TODO: Remove this from meta. Adopt the convention: meta -> preresponse, payload -> postresponse
          }
        };

        // ignoreDispatch is a very spartan way to execute API calls, nothing goes through redux
        if (ignoreDispatch) {
          if (isResponseAnError(response)) {
            return emitFailure(response);
          }

          const apiAction = new ApiAction(action);

          if (onComplete) {
            onComplete(true, apiAction);
          }

          return [ true, apiAction ];
        }

        // RESPONSE MAPPING
        let mapperResult;
        let responseMapperUpdated;
        const payload = {
          entities: [],
          references: [],
          response
        };

        if ((response.body || acceptEmptyResponse) && responseMapper) {
          if (isResponseAnError(response)) {
            return emitFailure(response);
          }

          try {
            mapperResult = responseMapper(
              response.body,
              getState(),
              originalApiParams,
              getApi(),
              {
                endpointConfig: config,
                correlationId,
                apiResultId,
                saveResult,
                ...additionalMeta
              }
            );
          } catch (ex) {
            return emitFailure({
              ...response,
              error: ex,
              errorContext: 'When mapping the response.'
            });
          }

          if (!mapperResult) {
            return emitFailure({
              ...response,
              error: new Error('The responseMapper did not return anything.')
            });
          }

          Object.keys(mapperResult).forEach((key) => {
            payload[key] = mapperResult[key];
          });
          payload.entities = mapperResult.entities || [];
          payload.references = mapperResult.references || [];
          responseMapperUpdated = mapperResult.updated;
        } else if (!response.body && responseMapper && !acceptEmptyResponse) {
          logError('Got an empty response, wasn\'t expecting one.', {
            apiUrl: endpointUrl,
            method: endpointMethod
          });
        }

        if (headersToEntitiesMapper) {
          payload.entities = payload.entities
            .concat(headersToEntitiesMapper(response.headers) || []);
        }

        payload.entities = payload.entities.map(
          (entity) => {
            if (entityScope != null && entity && entity.scope == null) {
              entity.scope = entityScope;
            }

            return entity;
          }
        );

        payload.metaEntities = [
          ...createApiResult(
            payload.entities,
            false,
            {
              pagination: payload.pagination,
              response: response.body,
              responseMapperResult: mapperResult,
              apiResultReferences: payload.apiResultReferences
            }
          )
        ];

        const markUpdatedContext = { apiParams, getState, response };

        payload.updated = [
          ...getUpdated(markUpdated, markUpdatedContext),
          // Only apply the V2 Update Format to the updated from the request function, a simple
          // array object is serializable as a redux action and is easy to define.
          ...getUpdated(requestMarkUpdated, markUpdatedContext),
          ...getUpdated(responseMapperUpdated, markUpdatedContext)
        ];

        const validationResult = t.validate(payload, ActionPayloadSchema);
        if (validationResult.errors.length > 0) {
          console.error(
            // eslint-disable-next-line max-len
            `Action Payload Schema Error: Make sure the responseMapper for {apiUrl} {endpointMethod} is a function that returns valid entities and references.
            Each entity and reference must have an id and type.
            If you're using a tcomb schema name for the type (*.meta.name), make sure it has
            a name set (last optional param when making structs, etc)`,
            validationResult
          );

          // Return a failure action if we can't parse the data
          return emitFailure({
            ...response,
            error: new Error(`Action Payload Schema Error: ${validationErrorsToString(validationResult)}`)
          });
        }

        action.payload = payload;

        const entityValidations = (action.payload.entities || [])
          .map(entity => validateEntity(entity, config))
          .filter(validateResult => validateResult !== true);

        if (entityValidations.length) {
          entityValidations.forEach(logValidateEntityError);
          return emitFailure({
            ...response,
            error: new Error('Entities failed to validate on success.')
          });
        }

        const apiAction = new ApiAction(action);

        // It's pretty common for things downstream of this dispatch to fail, so it makes sense to
        // capture and log it so it doesn't fall back to the callApi's promise catching any random
        // error.
        try {
          const reduxAction = apiAction.toReduxAction({
            onDispatch: onComplete ? wrapOnComplete(onComplete, true, apiAction) : null
          });

          dispatch(reduxAction);
        } catch (e) {
          return emitFailure({
            ...response,
            error: e,
            errorContext: 'When dispatching the Success API Action.'
          });
        }

        return [ true, apiAction ];
      };

      const onFailure = (response) => {
        if (shouldErrorMaptoSuccess && shouldErrorMaptoSuccess(response)) {
          return onSuccess(response);
        }

        return emitFailure(response);
      };

      const requestParts = createRequest(config, apiParams);
      const { url: originalUrl } = requestParts;

      const url = transformUrl ? transformUrl(originalUrl) : originalUrl;

      if (downloadResponse) {
        // response is a call to download a stream/file
        let result = await callDownloadApiAsync(url, requestParts, config);

        const apiAction = result.success
          ? new ApiAction({
            type: actionTypes.success,
            payload: { response: { body: {} }},
            meta: { ...meta }
          })
          : new ApiAction({
            type: actionTypes.fail,
            payload: { response: { body: result?.responseBody } },
            meta: { ...meta }
          });
        const reduxAction = apiAction.toReduxAction({
          onDispatch: onComplete ? wrapOnComplete(onComplete, true, apiAction) : null
        });
        dispatch(reduxAction);
        return [!!result?.success, apiAction];
      }

      // otherwise expect an api call with a JSON response
      const [ success, response ] = await callApi(
        url,
        requestParts.method,
        {
          body: requestParts.body,
          headers: requestParts.headers
        }
      );

      const trackedRequests = getState().requests || {};
      const trackedId = correlationId
        ? trackedRequests[`${actionTypes.fetch}/${correlationId}`]
        : null;

      // Ignore responses when a newer request has been made on the correlationID
      if (!!trackedId && trackedId !== meta.requestId) {
        return [ false, { cancelled: true } ];
      }

      if (success) {
        const responseContentType = response.headers.get('Content-Type');
        if ((responseContentType || []).includes('text/html')) {
          return onFailure({ error: new Error('Unexpected HTML response') });
        }

        return onSuccess(response);
      }

      return onFailure(response);
    };

    return actionThunk;
  };

  const actionCreator = (
    apiParams,
    onComplete,
    correlationId,
    additionalMeta = {}
  ) => create(
    apiParams, {
      onComplete,
      correlationId,
      ...additionalMeta
    }
  );

  // expose types
  actionCreator.actionTypes = actionTypes;
  actionCreator.create = create;
  actionCreator.config = config;

  return actionCreator;
};

export default createAction;
