/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { SettingsViewActions } from 'config/constants';

export default {
  path: '/settings',
  children: {
    Profile: {
      path: '/companyProfile',
      requiredAction: SettingsViewActions.ViewCompanySettings,
      children: {
        Edit: {
          path: '/edit'
        }
      }
    },
    Preferences: {
      path: '/companyPreferences',
      requiredAction: SettingsViewActions.ViewCompanySettings
    },
    CloseAccount: {
      path: '/closeAccount',
      requiredAction: SettingsViewActions.ViewCompanySettings
    },
    Logos: {
      path: '/logos',
      requiredAction: SettingsViewActions.ViewCompanySettings
    },
    Groups: {
      path: '/groups',
      requiredAction: SettingsViewActions.ViewGroups,
      children: {
        Add: {
          path: '/add'
        },
        Edit: {
          path: '/edit/:id'
        }
      }
    },
    Users: {
      path: '/users',
      requiredAction: SettingsViewActions.ViewUsers,
      children: {
        Add: {
          path: '/add'
        },
        Edit: {
          path: '/edit/:id'
        }
      }
    },
    PaymentApproval: {
      path: '/paymentApproval',
      requiredAction: SettingsViewActions.ViewCompanySettings
    },
    Discounts: {
      path: '/discount',
      requiredAction: SettingsViewActions.ViewEarlyPay,
      children: {
        CreateStrategy: {
          path: '/strategy/create'
        },
        EditStrategy: {
          path: '/strategy/edit/:id'
        },
        AddVendors: {
          path: '/strategy/addVendors/:id'
        },
        ManageVpxVendors: {
          path: '/manageVendors'
        }
      }
    },
    PaymentMethods: {
      path: '/paymentMethods',
      requiredAction: SettingsViewActions.ViewPaymentMethods
    },
    Fees: {
      path: '/fees',
      requiredAction: SettingsViewActions.ViewFees,
      children: {
        Details: {
          path: '/details'
        }
      }
    },
    Reports: {
      path: '/reports'
    },
    SwitchCompanies: {
      path: '/switchCompanies',
      requiredAction: SettingsViewActions.CanSwitchCompanies
    },
    UserAccount: {
      path: '/userAccount'
    },
    Subscription: {
      path: '/subscription',
      requiredAction: SettingsViewActions.CanUpgradeToCorporate
    }
  }
};
