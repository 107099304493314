/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { useEffect, useState } from 'react';
import { shallowEqual } from 'react-redux';
import { useHistory } from 'react-router-dom';

import useFuncRef from 'hooks/react/useFuncRef';
import usePropRef from 'hooks/react/usePropRef';

import getQueryValues from './utils/getQueryValues';

const useQueryParams = (
  queryParams,
  {
    mapQueryParams // Output from useMapQueryParams
  } = {}
) => {
  // Hooks
  const history = useHistory();

  const queryParamsRef = usePropRef(queryParams);

  const { current: getActualQueryValues } = useFuncRef(
    () => () => getQueryValues(
      {
        queryParams: queryParamsRef.current,
        deserializeQuery: mapQueryParams?.deserializeQuery
      },
      history.location
    )
  );

  const [ queryValues, setQueryValues ] = useState(
    () => getActualQueryValues()
  );

  const { current: onUpdate } = useFuncRef(
    () => () => {
      const newQueryValues = getActualQueryValues();

      setQueryValues(prevValues => !shallowEqual(prevValues, newQueryValues)
        ? newQueryValues
        : prevValues);
    }
  );

  useEffect(
    () => {
      // Going down the path of going directly to the history API as a listener
      // to tightly control when this hook updates vs using the useLocation hook
      // and causing rerenders anytime the location changes, even if the query
      // params we are interested in don't change
      const unlisten = history.listen(onUpdate);

      // When the component unmounts, unregister the listener
      return unlisten;
    },
    []
  );

  useEffect(
    () => {
      onUpdate();
    },
    [ ...(queryParams ?? []) ]
  );

  // Action
  return queryValues;
};

export default useQueryParams;