/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, { isValidElement } from 'react';
import { FormattedMessage } from 'react-intl';
import Paths from 'config/paths';
import useIsPortalUser from 'hooks/identity/useIsPortalUser';
import CountdownSubscribeLink from './CountdownSubscribeLink';
import NavLabels, {
  PrimaryNavLabels,
  PortalNavLabels,
  PortalSecondaryNavLabels
} from './index.messages';

const renderCustomLabel = label => isValidElement(label)
  ? label
  : <FormattedMessage {...label} />;

const createNavLabel = CustomLabels => ({ id }) => {
  if (CustomLabels) {
    if (Array.isArray(CustomLabels)) {
      const labels = CustomLabels.find(x => x[id]);

      if (labels) {
        return renderCustomLabel(labels[id]);
      }

    } else if (CustomLabels[id]) {
      return renderCustomLabel(CustomLabels[id]);
    }
  }

  if (NavLabels[id]) {
    return <FormattedMessage {...NavLabels[id]} />;
  }

  console.warn(`No message found by id: ${id}`);

  return <></>;
};

const NavLabel = createNavLabel();

const PrimaryNavCustomLabels = {
  [Paths.Settings.Subscription.id]: (
    <CountdownSubscribeLink
      defaultLabel={<NavLabel id={Paths.Settings.Subscription.id} />}
    />
  )
};

const DefaultPrimaryNavLabel = createNavLabel([
  PrimaryNavCustomLabels,
  PrimaryNavLabels
]);

const PortalPrimaryNavLabel = createNavLabel([
  PortalNavLabels,
  PrimaryNavCustomLabels,
  PrimaryNavLabels
]);

export const PrimaryNavLabel = ({ id }) => {
  const isPortalUser = useIsPortalUser();

  return isPortalUser
    ? <PortalPrimaryNavLabel id={id} />
    : <DefaultPrimaryNavLabel id={id} />;
};

const PortalSecondaryNavLabel = createNavLabel(PortalSecondaryNavLabels);

export const SecondaryNavLabel = ({ id }) => {
  const isPortalUser = useIsPortalUser();

  return isPortalUser
    ? <PortalSecondaryNavLabel id={id} />
    : <NavLabel id={id} />;
};

const PortalMobileNavLabel = createNavLabel([
  PortalNavLabels,
  PrimaryNavCustomLabels,
  PortalSecondaryNavLabels
]);

export const MobileNavLabel = ({ id }) => {
  const isPortalUser = useIsPortalUser();

  return isPortalUser
    ? <PortalMobileNavLabel id={id} />
    : <DefaultPrimaryNavLabel id={id} />;
};

export const LogOutNavLabel = () => <FormattedMessage {...NavLabels.LogOut} />;

export default NavLabel;