/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { connect } from 'react-redux';

import { createCacheActions } from 'actions/references';
import getBankAccounts from 'api/paymentAccounts/getBankAccounts';

import getIdentityEndpoint from 'api/session/getIdentity';
import { getIdentity } from 'actions/common';

import BankAccountApi from 'api/paymentAccounts';

const GetDelegatedAccountAction = createCacheActions(
  'AddBankAccountInput_GetDelegatedAccount',
  getBankAccounts
);

const UpsertBankAccountAction = createCacheActions(
  'AddPayeeAccountWorkflow_UpsertBankAccount',
  BankAccountApi.upsertBankAccount
);

const MapStateToProps = (state, props) => {
  const identity = getIdentity(state);

  // only attempt to add "receive only" account for portal users that appear
  // to only have receive relationships
  const isVendorPortal = identity?.companySettings?.accountType === 'Portal'
    && identity?.companySettings?.totalVendors === 0;

  const isPersonalAccount = identity?.businessType === 'Personal';

  return {
    hasTaxId: identity?.companySettings?.hasTaxPayerIdentificationNumber ?? false,
    hasBankAccount: identity?.paymentAccounts?.length > 0,
    isReceiptAccount: props.isReceiptAccount || isVendorPortal,
    isPersonalAccount,

    isLoading: GetDelegatedAccountAction.isCacheLoading(state),
    delegatedAccount: GetDelegatedAccountAction.getCacheEntity(state),
    isAdding: UpsertBankAccountAction.isCacheLoading(state)
  };
};

const MapDispatchToProps = (dispatch, props) => ({
  loadDelegatedAccount: () => {
    dispatch(GetDelegatedAccountAction.makeApiCall({
      filter: 'AvailableDelegated'
    }));
  },
  addBankAccount: (method, value, onAddComplete) => {
    let request = {
      skipApproverCountWarning: true,
      name: value?.accountName ?? 'Payment Account'
    };

    if (method === 'delegated') {
      request.delegatedAccount = {
        paymentAccountId: value?.id
      };
    } else if (method === 'plaid') {
      request.plaidAccount = {
        publicToken: value?.publicToken,
        accountId: value?.accountId
      };

    } else if (method === 'manual') {
      request.manualAccount = {
        routingNumber: value?.routingNumber,
        accountNumber: value?.accountNumber,
        currency: value?.currency
      };
    }

    if (method === 'plaid' || method === 'manual') {
      request.taxpayerIdNumber = value?.taxIdNumber;
      request.signatureText1 = value?.signer1;
      request.signatureImage = value?.signatureImage;
      request.paymentApprovalSignerCount = 1;
      request.accountName = value?.accountName;
    }

    dispatch(UpsertBankAccountAction.makeApiCall(request,
      {
        extractFirstErrorMessage: true,
        apiMetadata: {
          onComplete: (success) => {
            if (success) {
              dispatch(getIdentityEndpoint());
              onAddComplete?.();
            }
          }
        }
      }));
  }
});

export default Component => connect(MapStateToProps, MapDispatchToProps)(Component);
