/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isNil } from 'lodash';
import Icon, { IconTypes } from 'components/Icon';
import TextBox from 'components/Form/Inputs/TextBox';
import { getMetadata } from 'schemas';
import { toNumber } from 'schemas/common/currency';

const normalizeInput = (val, originalMinFractionDigits, forStaticDisplay) => {
  // Also allow user to lead with a '.' in case its a decimal only value
  // Also allow negatives (e.g. Credit memos)
  if (val === '.' || val === '-') {
    return val;
  }

  const minimumFractionDigits = isNil(originalMinFractionDigits) ? 2 : originalMinFractionDigits;
  const number = toNumber(val);

  if (Number.isNaN(number)) {
    return val;
  }

  let formattedValue = val;

  if (!isNil(number)) {
    if (forStaticDisplay && !isNil(number)) {
      formattedValue = number.toFixed(minimumFractionDigits);
    }

    // UX team wants currency input fields to stop allowing user input after 2 decimals
    // Only apply this behavior when there are decimal points because this creates a weird
    // UX that we take the decimal point out as you are typing it. So it ends up like:
    // 100.99 -> 1009, which is not ideal
    if (minimumFractionDigits > 0 && number !== +(number.toFixed(minimumFractionDigits))) {
      formattedValue = number.toFixed(minimumFractionDigits);
    }
  }

  return formattedValue;
};

/**
 * Default form input for Currency type fields. Formats the value into currency (2 decimal places).
 * Use applyMinimumFractionDigits on the schema to apply a different fraction digit formatting.
 */
export default class CurrencyTextBox extends Component {
  static propTypes = {
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onChange: PropTypes.func
  };

  static defaultProps = {
    onChange: () => null
  };

  static createTransformer = type => ({
    // We don't need to do something so drastic on every value change to add the decimal places,
    // that's purely a UI niceness - the value is still the same
    format: val => normalizeInput(val, getMetadata(type).minimumFractionDigits),
    parse: val => val
  })

  constructor(props) {
    super(props);
    this.state = {
      hasFocus: false
    };
  }

  focus = (event) => {
    const { onFocus } = this.props;
    this.setState({
      hasFocus: true
    }, () => {
      if (onFocus) {
        onFocus(event);
      }
    });
  }

  onBlur = (event, previousVal) => {
    const { onBlur, onChange, type } = this.props;
    const { minimumFractionDigits } = getMetadata(type);
    const formattedValue = normalizeInput(
      previousVal,
      isNil(minimumFractionDigits) ? 2 : minimumFractionDigits,
      true
    );

    // Format the value on blur to show the proper value, with cents and all
    if (formattedValue !== previousVal) {
      if (onChange) {
        onChange(formattedValue);
      }
    }

    if (onBlur) {
      onBlur(event);
    }
  }

  blur = (event) => {
    const inputValue = event && event.target ? event.target.value : null;
    this.setState({
      hasFocus: false
    }, () => this.onBlur(event, inputValue));
  }

  change(value) {
    // Render logic may add in commas into the value (e.g. 15,000.00)
    // make sure we never include those in the actual value we are changing
    // since commas don't mean the same thing internationally and for JS Number (e.g. parseFloat('15,000.00') === 15)
    let actualValue = value?.replace(',', '');
    this.props.onChange(actualValue);
  }

  render() {
    const { value } = this.props;
    const { hasFocus } = this.state;

    return (
      <TextBox
        {...this.props}
        value={hasFocus || !value
          ? value
          // Add commas when not focused
          : value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
        onBlur={this.blur}
        onFocus={this.focus}
        prependIcon={<Icon type={IconTypes.Dollar} />}
        onChange={v => this.change(v)}
        alignRight={true}
      />
    );
  }
}
