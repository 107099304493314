/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, { forwardRef, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import upsertSubscriptionCreditCard from 'actions/payments/upsertSubscriptionCreditCard';

import { SmallBannerNotification, NotificationStyleTypes } from 'components/Notification';
import MonthlyPaymentDescriptionPanel from 'containers/Subscription/MonthlyPaymentDescriptionPanel';
import CreditCardForm from 'containers/Subscription/CreditCardForm';

import useCompanySettings from 'hooks/identity/useCompanySettings';
import useIdentity from 'hooks/identity/useIdentity';
import useIsPortalUser from 'hooks/identity/useIsPortalUser';

import Messages from './index.messages';

export const useSetupSubscriptionData = ({
  campaignId,
  formRef
}) => {
  // Hooks
  const dispatch = useDispatch();

  const [ isLoading, setIsLoading ] = useState(false);
  const [ error, setError ] = useState();

  const { companyId } = useIdentity();
  const isPortalUser = useIsPortalUser();
  const {
    delinquencyDate,
    hasSubscriptionPaymentMethod,
    nextBillingDate: originalNextBillingDate
  } = useCompanySettings();

  const nextBillingDate = delinquencyDate ? null : originalNextBillingDate;

  const isAllSet = !isPortalUser
    && hasSubscriptionPaymentMethod
    && !delinquencyDate;

  const submitSetup = useMemo(
    () => async () => {
      const value = formRef.current.getValue();

      if (!value) return [ false ];

      setIsLoading(true);

      const [ success, action ] = await dispatch(upsertSubscriptionCreditCard(
        {
          ...value,
          campaignId
        },
        {
          companyId,
          isPortalCompany: isPortalUser
        }
      ));

      setIsLoading(false);

      if (!success) {
        setError(action.errorMessage);
        return [ false ];
      }

      return [ true ];
    },
    []
  );

  // Action
  return {
    delinquencyDate,
    isAllSet,
    isLoading,
    nextBillingDate,
    submitError: error,
    submitSetup
  };
};

const SetupSubscriptionForm = (
  {
    billingDate,
    children,
    delinquencyDate,
    onChange,
    showCancellationPolicy,
    subcriptionAmount,
    submitError,
    value,
    wasDelinquent
  },
  ref
) => {
  // Hooks
  const isPortalUser = useIsPortalUser();

  // Render
  let alert = null;

  if (submitError) {
    alert = (
      <SmallBannerNotification type={NotificationStyleTypes.Warning} shakeOnEnter={true}>
        {submitError}
      </SmallBannerNotification>
    );
  }

  return (
    <>
      <MonthlyPaymentDescriptionPanel
        overrideHeader={<Messages.Header.Message />}
        inFuture={!isPortalUser && !wasDelinquent}
        amount={subcriptionAmount}
        billingDate={billingDate}
        delinquencyDate={delinquencyDate}
        showCancellationPolicy={showCancellationPolicy}
      />
      {children}
      {alert}
      <CreditCardForm
        onChange={onChange}
        ref={ref}
        value={value}
      />
    </>
  );
};

export default forwardRef(SetupSubscriptionForm);
