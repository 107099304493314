/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */
/* eslint max-len:0 */

import { toMessages } from 'config/messages';

export default toMessages('InitiateResetPassword', {
  Header: 'Forgot your password?',
  AccessDeniedHeader: 'Access Denied',
  AccessDeniedButton: 'Ok, Got it',
  AccessDeniedInstructions: 'Your Viewpost account has been locked. Please contact Customer Support at 1.888.248.9190.',
  ResetPasswordSentHeader: 'Almost there.',
  SentInstructions: 'Please check your email. If you entered a registered email address, you will receive an email shortly with instructions on resetting your password. The email will contain a reset password link that expires in one hour.',
  EmailAddress: 'Enter your email address',
  EmailAddressPlaceholder: 'username@email.com'
});
