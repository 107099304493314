/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import TextBox from 'components/Form/Inputs/TextBox';
import classNames from 'classnames';
import Button from 'components/Button';
import Messages from './index.messages';
import './index.scss';

/**
 * A textbox that masks/hides the value entered when clicking the toggle
 */
export default class PasswordToggleTextBox extends Component {
  static propTypes = {
    /** The value of the input */
    value: PropTypes.string,
    /** Options object used by TextBox to configure the input type */
    options: PropTypes.object,
    /** Determines if the Show/Hide toggle is enabled for the input */
    isToggleEnabled: PropTypes.bool
  };

  static defaultProps = {
    isToggleEnabled: true
  };

  constructor(props) {
    super(props);

    this.togglePasswordVisiblity = this.togglePasswordVisiblity.bind(this);

    this.state = {
      value: props.value,
      inputType: 'password'
    };
  }

  togglePasswordVisiblity() {
    const newInputType = this.state.inputType === 'password' ? 'text' : 'password';

    this.setState({inputType: newInputType});
  }

  renderToggle() {
    if (!this.props.isToggleEnabled) return null;

    const {ShowMessage, HideMessage} = Messages;
    const passwordShowMessage = this.state.inputType === 'password' ? ShowMessage : HideMessage;

    return (
      <div className="show-hide-toggle">
        <Button
          anchor={true}
          onClick={() => this.togglePasswordVisiblity()}
          tabIndex={100}
        >
          <FormattedMessage {...passwordShowMessage} />
        </Button>
      </div>
    );
  }

  render() {
    const options = {...this.props.options, type: this.state.inputType};
    const value = this.props.value;
    const passwordToggleClassNames = classNames('vp-password-toggle-textbox', {
      'hide-toggle': !value || value.length < 1
    });

    return (
      <div className={passwordToggleClassNames}>
        <TextBox
          {...this.props}
          options={options}
          value={this.props.value}
          appendIcon={this.renderToggle()}
        />
      </div>
    );
  }
}
