/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { isFailAction, isFetchAction, isSuccessAction } from 'api/core/utils';
import { newState } from '../utils';

export function AutoPay(state = {
  isLoadingList: true,
  isLoadingSummary: true,
  isWhitelisted: false,
  isPaperCheck: false,
  settings: null,
  settingsPagination: null,
  nextScheduledAmountTotal: 0
}, action) {
  if (isFetchAction(action, 'getAutoPayList')) {
    return newState(state, {
      isLoadingList: true
    });
  } if (isSuccessAction(action, 'getAutoPayList')) {
    return newState(state, {
      isLoadingList: false,
      settings: action.payload.references,
      settingsPagination: action.payload.pagination
    });
  }

  if (isFetchAction(action, 'getAutoPaySummary')) {
    return newState(state, {
      isLoadingSummary: true
    });
  } if (isSuccessAction(action, 'getAutoPaySummary')) {
    return newState(state, {
      isLoadingSummary: false,
      nextScheduledAmountTotal: action.payload.nextScheduledAmountTotal
    });
  }

  if (isSuccessAction(action, 'getCompanySettingsByAddressBook')) {
    const companySettings = action.payload.entities && action.payload.entities.length
      ? action.payload.entities[0] : null;
    if (companySettings && companySettings.value) {
      return newState(state, {
        isWhitelisted: !!companySettings.value.canReceiveUnverifiedPaymentAccountPayments
      });
    }

    return newState(state, {
      isWhitelisted: false
    });
  } if (isFailAction(action, 'getCompanySettingsByAddressBook')
    || action.type === 'autoPay/selectCompany') {
    return newState(state, {
      isWhitelisted: false,
      totalOverdueAmount: 0
    });
  }

  if (action.type === '@@router/LOCATION_CHANGE') {
    return newState(state, {
      isLoadingList: true,
      isLoadingSummary: true,
      isWhitelisted: false,
      settings: null,
      settingsPagination: null,
      nextScheduledAmountTotal: 0
    });
  }

  if (isFetchAction(action, 'statusLoadForBillsList')
    && action.meta.correlationId === 'SetupAutoPay') {
    return newState(state, {
      totalOverdueAmount: 0
    });
  }

  if (isSuccessAction(action, 'statusLoadForBillsList')
    && action.meta.correlationId === 'SetupAutoPay') {
    return newState(state, {
      totalOverdueAmount: action.payload.totalOverdueAmount
    });
  }

  return state;
}

export function ConsumerAutoPay(state = {}, action) {
  if (isFetchAction(action, 'searchConsumerAutoPay')) {
    return newState(state, {
      isLoading: true
    });
  } if (isFailAction(action, 'searchConsumerAutoPay')) {
    return newState(state, {
      isLoading: false
    });
  } if (isSuccessAction(action, 'searchConsumerAutoPay')) {
    return newState(state, {
      isLoading: false,
      autoPayResults: action.payload.references
    });
  }

  return state;
}
