/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { addModal } from 'config/modals/registry';
import { wrapImport } from 'decorators/ModuleLoader';

const AccountSetupModal = wrapImport(() => import(/* webpackChunkName: "modals" */ './index'));

export default addModal(
  'AccountSetupModal',
  AccountSetupModal,
  { wide: true },
  { ignoreIfAnotherModalOpen: true }
);
