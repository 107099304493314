/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import t from 'tcomb-validation';
import { defineSchema } from 'schemas';
import { LooseAddress } from 'schemas/common/address';
import { createEntity } from 'schemas/state';
import createApiActions, { createApiAction } from 'api/core';
import AddressBookRelationships from 'config/constants/network/AddressBookRelationships';
import { RetrievableCompanyProfile } from 'schemas/network/companyProfile';
import {
  queryParamNameMapper
} from 'api/common/mappers';
import { getIdentity } from 'actions/common';
import {
  loadDocumentsFromTokenResponseMapper
} from './mappers/onboardingMappers';
import {
  shareOnboardingInvoiceRequestMapper,
  getBillsInvitationDocumentsResponseMapper
} from './mappers/invoiceOnboardingMappers';

const SourceCompanyModel = t.struct({
  id: t.String,
  name: t.String,
  connectedToAs: t.enums(AddressBookRelationships)
});

export const AccountSetupData = defineSchema({
  invitationDocuments: t.list(t.struct({
    documentId: t.String,
    type: t.String,
    sourceCompany: SourceCompanyModel
  })),
  onboardingProfiles: t.list(t.struct({
    address: LooseAddress,
    companyName: t.maybe(t.String),
    phone: t.maybe(t.String),
    email: t.maybe(t.String),
    firstName: t.maybe(t.String),
    lastName: t.maybe(t.String),
    source: t.String,
    sourceCompany: t.maybe(SourceCompanyModel)
  })),
  onboardingFlow: t.maybe(t.String)
}, 'AccountSetupData');

const CorporateActions = defineSchema({
  createdInvoicesCount: t.Number,
  createdPaymentsCount: t.Number
}, 'CorporateActions');

export const getCorporateActions = createApiAction({
  method: 'get',
  url: '/api/onboarding/corporateActions',
  responseMapper: ({ createdInvoicesCount, createdPaymentsCount }) => ({
    entities: [
      createEntity(
        'current',
        CorporateActions.meta.name,
        { createdInvoicesCount, createdPaymentsCount }
      )
    ]
  })
});

export const getAccountSetup = createApiAction({
  method: 'get',
  url: '/api/onboarding/accountSetup',
  requestBodyMapper: () => ({}),
  responseMapper: (apiResponse, state) => {
    const { companyId } = getIdentity(state);
    const { companyProfile, ...response } = apiResponse;
    const {
      address,
      businessStartDate,
      doingBusinessAs,
      name: companyName,
      phoneNumber,
      selectedAccountingPackage,
      taxpayerIdNumberDisplayDigits
    } = companyProfile;
    const companyProfileEntity = createEntity(
      companyId,
      RetrievableCompanyProfile.meta.name,
      {
        ...companyProfile,
        id: companyId,
        companyName,
        dbaName: doingBusinessAs,
        phone: phoneNumber,
        taxpayerIdDisplay: taxpayerIdNumberDisplayDigits,
        yearOfIncorporation: businessStartDate ? businessStartDate.substring(0, 4) : null,
        accountingPackage: selectedAccountingPackage,
        address: {
          ...address,
          lines: [address.line1, address.line2, address.line3, address.line4].filter(a => a),
          linesWithoutAddressee: [address.line1, address.line2, address.line3, address.line4].filter(a => a)
        },
        logos: []
      }
    );

    const onboardingProfiles = (response.onboardingProfiles || []).map(profile => ({
      ...profile,
      address: {
        ...(profile.addresses || [])[0]
      },
      phone: profile.phoneNumbers?.length
        ? profile.phoneNumbers[0].replace(/\D/g, '').slice(-10) : null,
      sourceCompany: profile.sourceCompany ? {
        ...profile.sourceCompany,
        // Doing some slight massaging here to fix a problem with the type of address books
        // the Viewpost Billing company makes
        connectedToAs: profile.sourceCompany.connectedToAs === 'Contact'
          ? 'Customer'
          : profile.sourceCompany.connectedToAs
      } : null
    }));

    const invitationDocuments = (response.invitationDocuments || []).map(doc => ({
      ...doc,
      sourceCompany: doc.sourceCompany ? {
        ...doc.sourceCompany,
        // Doing some slight massaging here to fix a problem with the type of address books
        // the Viewpost Billing company makes
        connectedToAs: doc.sourceCompany.connectedToAs === 'Contact'
          ? 'Customer'
          : doc.sourceCompany.connectedToAs
      } : null
    }));

    const accountSetupEntity = createEntity(
      'current',
      AccountSetupData.meta.name,
      {
        ...response,
        email: response.emailAddress,
        phone: response.phoneNumbers && response.phoneNumbers.length
          ? response.phoneNumbers[0].replace(/\D/g, '').slice(-10) : null,
        onboardingProfiles,
        invitationDocuments
      }
    );

    return {
      entities: [
        companyProfileEntity,
        accountSetupEntity
      ]
    };
  }
});

export default createApiActions({
  getBillsInvitationDocuments: {
    method: 'get',
    url: '/ajax/onboarding/payables/:invitationId',
    responseMapper: getBillsInvitationDocumentsResponseMapper
  },
  shareOnboardingInvoice: {
    method: 'post',
    url: '/api/documents/share',
    headers: (apiParams) => { // support anonymous access via secure token
      return {
        'Authorization': 'document-token',
        'documentToken': apiParams.secureToken
      };
    },
    requestBodyMapper: shareOnboardingInvoiceRequestMapper
  },
  loadDocumentsFromToken: {
    method: 'get',
    url: '/api/identity/documents',
    queryStringMapper: queryParamNameMapper({
      'secureToken': 'secureToken'
    }),
    responseMapper: loadDocumentsFromTokenResponseMapper
  },
  getPaymentAccountStatus: {
    method: 'get',
    url: '/api/onboarding/:token/get_payment_account_status'
  }
});
