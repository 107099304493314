/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { useRef } from 'react';

// Similar to if you are using useMemo to create a function, this takes in a function that creates
// a function, so a function isn't being made every render. It then stores the result of that
// creation in a ref.
// Just like useEffect/useMemo, take special care the variables you use in the function's scope are
// referenced in such a way you get the latest and greatest version you want, since this will
// capture the value at the time this is created. I suggest for props you use in this function that
// you use usePropRef.
const useFuncRef = (funcCreator) => {
  // Hooks
  const funcRef = useRef();

  if (funcRef.current == null) {
    funcRef.current = funcCreator();
  }

  // Action
  return funcRef;
};

export default useFuncRef;