/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { createApiAction } from 'api/core';

import { SecureTokenContextModel } from 'api/onboarding/getSecureTokenData.schemas';

const acceptVccTerms = createApiAction({
  method: 'post',
  url: '/api/landing/acceptVccTerms',
  requestBodyMapper: ({
    secureToken
  }) => ({
    secureToken
  }),
  markUpdated: {
    entityType: SecureTokenContextModel.meta.name
  }
});

export default acceptVccTerms;