/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { isSuccessAction } from 'api/core/utils';
import { newState } from '../utils';

export const SentDetails = (state = {}, action) => {

  // Handle the load of the attachments
  if (isSuccessAction(action, 'getPaymentAttachments')) {
    return newState(state, {
      attachments: action.payload.references
    });
  }

  // Handle errors on payment actions (TODO: make this better)
  if (isSuccessAction(action, 'approvePayments')) {
    const messages = action.payload.messages || [];
    const { message } = messages.length ? messages[0] : {};
    return newState(state, {
      error: message
    });
  }

  if (action.type === '@@router/LOCATION_CHANGE') {
    return newState(state, {
      attachments: null,
      error: null
    });
  }

  return state;
};
