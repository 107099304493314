/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React from 'react';
import BrowserDetection from 'services/BrowserDetection';
import Measure from 'react-measure';
import './index.scss';

const AppContainer = ({ children }) => {
  if (BrowserDetection.isIE()) {
    return (
      <Measure bounds={true}>
        {({ measureRef, contentRect: { bounds } }) => (
          <div
            ref={measureRef}
            className="app-container"
            style={{
              // needed to do this calculation in JS instead of CSS because IE does not cap negative paddings to 0
              padding: bounds && bounds.width
                ? `0 ${Math.max((bounds.width - 1040) / 2, 0)}px`
                : null
            }}
          >
            <div className="viewstrap">
              {children}
            </div>
          </div>
        )}
      </Measure>
    );
  }

  return (
    <div className="app-container">
      <div className="viewstrap">
        {children}
      </div>
    </div>
  );
};

export default AppContainer;
