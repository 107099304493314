/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import t from 'tcomb-validation';
import { isValidCardNumber, isValidExpirationDate, isValidCVC } from 'api/stripe';
import Messages from './creditCard.messages';

const CardNumber = t.subtype(t.String, (value) => {
  return isValidCardNumber(value);
}, 'CardNumber');
CardNumber.getValidationErrorMessage = (value, path, context) => {
  if (!isValidCardNumber(value) && context && context.intl) {
    return context.intl.formatMessage(Messages.InvalidCardNumber);
  }

  return '';
};

const CardExpirationDate = t.subtype(t.String, (value) => {
  return isValidExpirationDate(value);
}, 'CardExpirationDate');
CardExpirationDate.getValidationErrorMessage = (value, path, context) => {
  if (!isValidExpirationDate(value) && context && context.intl) {
    return context.intl.formatMessage(Messages.InvalidExpiration);
  }

  return '';
};

const CardVerificationCode = t.subtype(t.String, (value) => {
  return isValidCVC(value);
}, 'CardVerificationCode');
CardVerificationCode.getValidationErrorMessage = (value, path, context) => {
  if (!isValidCVC(value) && context && context.intl) {
    return context.intl.formatMessage(Messages.InvalidCVC);
  }

  return '';
};

export const CreditCardType = t.enums({
  Visa: 'Visa',
  MasterCard: 'MasterCard',
  Amex: 'Amex',
  Discover: 'Discover',
  DinersClub: 'DinersClub',
  JCB: 'JCB',
  Unknown: 'Unknown'
}, 'CreditCardType');

export {
  CardNumber,
  CardExpirationDate,
  CardVerificationCode
};
