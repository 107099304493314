/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React from 'react';
import classnames from 'classnames';
import './index.scss';

export default ({
  children,
  className,
  onClick,
  style,
  noPadding
}) => (
  <div
    className={classnames(
      'border-component',
      className,
      {'no-padding': noPadding}
    )}
    style={style}
    onClick={onClick}
  >
    {children}
  </div>
);
