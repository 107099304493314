/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import logError from 'services/ErrorService';
import useIdentity from './useIdentity';

const useUserAvailableActions = (
  {
    notExpected,
    returnNull
  } = {}
) => {
  const identity = useIdentity({ notExpected: true, returnNull: true });

  if (!identity && !notExpected) {
    logError('Expected availableActions, but availableActions is missing.', null, { includeStackTrace: true });
  }

  if (identity != null) return identity.availableActions;

  return returnNull ? null : [];
};

export default useUserAvailableActions;
