/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, { Component } from 'react';

import FlagIcon from 'components/Icon/FlagIcon';
import SelectPagingTooltip from 'components/Form/Inputs/SelectPagingTooltip';
import Tooltip, { Placements } from 'components/Tooltip';
import Icon, { IconTypes } from 'components/Icon';

class CountrySelect extends Component {

  render() {
    let {
      value,
      onChange,
      countries,
      placeholder,
      hasError,
      disabled,
      variant = 'default'
    } = this.props;

    let currentCountry = null;
    let options = countries?.map((c) => {
      if (c.code === value) currentCountry = c;
      return {
        value: c.code,
        text: c.name,
        richText: <div>
          <FlagIcon country={c.code} /> {c.name}
        </div>
      };
    }) ?? [];

    if (disabled) {
      return (
        <><FlagIcon country={value} /> {currentCountry?.name}</>
      );
    }

    let overlay = (
      <SelectPagingTooltip
        value={value}
        // clearOption={{
        //   value: null,
        //   richText: 'All Vendors'
        // }}
        searchable={options?.length > 5}
        pageable={options?.length > 5}
        clearable={false}
        onChange={(v) => {
          this.tooltipRef?.close();
          onChange?.(v);
        }}
        placeholder={placeholder}
        selectOptions={options}
        pageSize={5}
        pagingCappedAt={300} />
    );

    let showError = hasError && !currentCountry;

    let content = (
      <div style={{
        padding: '8px',
        border: '1px solid #ccc',
        cursor: 'pointer',
        textAlign: 'center'
      }}>
        {currentCountry
          ? <FlagIcon country={value} />
          : <Icon type={showError ? IconTypes.Alert : IconTypes.World} />}
      </div>
    );

    if (variant === 'link') {
      content = (
        <a>
          {currentCountry
            ? <><FlagIcon country={value} /> {currentCountry?.name}</>
            : <><Icon type={showError ? IconTypes.Alert : IconTypes.World} />Select Country</>}
        </a>
      );
    }

    return (
      <Tooltip overlay={overlay} trigger={['click']} padContent={false}
        ref={r => this.tooltipRef = r}
        placement={Placements.Bottom}
        childWrapperStyle={{
          display: 'block'
        }}>
        {content}
      </Tooltip>
    );
  }
}

export default CountrySelect;