/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */
/* eslint max-len:0 */

import { defineMessages } from 'react-intl';

const common = defineMessages({
  Copyright: {
    id: 'AppFooter[Copyright]',
    description: 'Copyright text with year and viewpost',
    defaultMessage: 'Copyright © {year}. Viewpost IP Holdings, LLC. All Rights Reserved.'
  }
});

export default {
  ...common
};
