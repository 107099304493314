/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, { useMemo, useRef, useState } from 'react';
import t from 'tcomb-validation';

import completeResetPassword from 'api/session/resetPassword/completeResetPassword';

import Button from 'components/Button';
import PasswordStrengthField from 'components/Form/Inputs/PasswordStrengthTextBox';
import PasswordToggleTextBox from 'components/Form/Inputs/PasswordToggleTextBox';
import FormV2, { FieldOption } from 'components/Form/V2';

import CommonMessages from 'config/messages/common';
import { preventDefault } from 'config/utils/click';

import createExecuteApiResultHooks from 'hooks/apiResult/createExecuteApiResultHooks';
import useFormatMessage from 'hooks/intl/useFormatMessage';

import { Password, createMatchingPassword } from 'schemas/common/password';

import Messages from './index.messages';

const CompleteRequestHooks = createExecuteApiResultHooks({
  endpoint: completeResetPassword
});

const SetPassword = ({
  newPasswordToken,
  token
}) => {
  // Hooks
  const formatMessage = useFormatMessage();

  const formRef = useRef();
  const [ formState, setFormState ] = useState({});

  const formSchema = useMemo(
    () => t.struct({
      password: Password,
      confirmPassword: createMatchingPassword(formState.password)
    }),
    [ formState.password ]
  );

  const executeComplete = CompleteRequestHooks.useExecuteRequest();
  const isLoading = CompleteRequestHooks.useIsLoading();

  const onSubmit = useMemo(
    () => preventDefault(
      () => {
        const value = formRef.current.getValue();

        if (value) {
          executeComplete({
            token,
            newPasswordToken,
            password: value.password
          });
        }
      }
    ),
    [ token, newPasswordToken ]
  );

  // Render
  return (
    <div>
      <h2 style={{ textAlign: 'center' }}>
        <Messages.InProgressHeader.Message />
      </h2>
      <form>
        <p>
          <Messages.Instructions.Message />
        </p>
        <FormV2
          ref={formRef}
          formTag={false}
          onChange={setFormState}
          modelType={formSchema}
          value={formState}
        >
          <FieldOption
            name="password"
            label={<Messages.NewPassword.Message />}
          >
            <PasswordStrengthField />
          </FieldOption>
          <FieldOption name="confirmPassword">
            <PasswordToggleTextBox
              placeholder={formatMessage(Messages.ConfirmPasswordPlaceholder)}
            />
          </FieldOption>
        </FormV2>
        <Button
          type="submit"
          isDisabled={isLoading}
          isProcessing={isLoading}
          onClick={onSubmit}
          expand={true}
        >
          <CommonMessages.Continue.Message />
        </Button>
      </form>
    </div>
  );
};

export default SetPassword;
