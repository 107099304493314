/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, { memo } from 'react';
import useIsMobile from 'hooks/browser/useIsMobile';
import DesktopNav from './DesktopNav';
import MobileNav from './MobileNav';
import connect from './index.connect';
import './index.scss';

const Navigation = memo(
  () => {
    const isMobile = useIsMobile();

    return isMobile
      ? <MobileNav />
      : <DesktopNav />;
  }
);

export default connect(Navigation);
