/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import createAvailableActions from 'config/constants/actionsRegistry';

export const ViewActions = createAvailableActions('ViewActions', [
  'CanFilterInvoiceListByCompany',
  'CanFilterPaymentsReceivedListByCompany',
  'CanFilterPaymentsReceivedListByStatus'
]);

export const SyncViewActions = createAvailableActions('SyncViewActions', [
  'ViewPayments',
  'ViewHistory',
  'ViewSettings'
]);
