/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import chargeInitial from 'api/settings/subscription/chargeInitial';
import selectSubscriptionOption from 'api/settings/subscription/selectSubscriptionOption';
import updateSubscription from 'api/settings/subscription/updateSubscription';
import { createCardTokenAsync } from 'api/stripe';

import createExecuteApiResultHooks from 'hooks/apiResult/createExecuteApiResultHooks';

const UpdateSubscriptionHooks = createExecuteApiResultHooks({
  endpoint: updateSubscription
});

const SelectCorporateSubscriptionHooks = createExecuteApiResultHooks({
  endpoint: selectSubscriptionOption
});

const ChargeInitialSubscriptionHooks = createExecuteApiResultHooks({
  endpoint: chargeInitial
});

const upsertSubscriptionCreditCard = (
  {
    campaignId,
    cardholderName,
    cardNumber,
    cvc,
    expirationDate,
    postalCode
  },
  {
    companyId,
    isPortalCompany,
    successMessage
  }
) => async (
  dispatch
) => {
  const [ tokenSuccess, { payload: tokenPayload } ] = await dispatch(createCardTokenAsync({
    cardNumber,
    expirationDate,
    cvc,
    cardholderName,
    billingPostalCode: postalCode
  }));

  if (!tokenSuccess) {
    return [ false, { errorMessage: tokenPayload.error.message } ];
  }

  const cardToken = tokenPayload.id;

  if (campaignId) {
    const [ success, action ] = await dispatch(
      SelectCorporateSubscriptionHooks.Redux.executeRequestAction(
        {
          campaignId,
          selection: 'Corporate',
          creditCardToken: cardToken
        },
        {
          failureMessage: false,
          successMessage
        }
      )
    );

    if (success) return [ true, {} ];

    return [ false, { errorMessage: action.getFirstMessage() } ];
  }

  if (isPortalCompany) {
    const [ success, action ] = await dispatch(
      ChargeInitialSubscriptionHooks.Redux.executeRequestAction(
        {
          companyId,
          creditCardToken: cardToken
        },
        {
          failureMessage: false,
          successMessage
        }
      )
    );

    if (success) return [ true, {} ];

    return [ false, { errorMessage: action.getFirstMessage() } ];
  }

  const [ success, action ] = await dispatch(
    UpdateSubscriptionHooks.Redux.executeRequestAction(
      {
        cardToken
      },
      {
        failureMessage: false,
        successMessage
      }
    )
  );

  if (success) return [ true, {} ];

  return [ false, { errorMessage: action.getFirstMessage() } ];
};

export default upsertSubscriptionCreditCard;