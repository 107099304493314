/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { BillViewActions, InvoiceActions } from 'config/constants';
import { invoiceListQueryStringMapper } from './invoiceUtils';

export default {
  path: '/bills',
  children: {
    List: {
      path: '/list',
      queryStringMapper: invoiceListQueryStringMapper,
      requiredAction: BillViewActions.ViewList
    },
    Details: {
      path: '/details/:type/:id'
    },
    Statements: {
      path: '/statements',
      requiredAction: BillViewActions.ViewStatements
    },
    StatementDetails: {
      path: '/statements/:id'
    },
    Vpx: {
      path: '/vpx',
      requiredAction: BillViewActions.ViewBillEarlyPay
    },
    PaymentQueue: {
      path: '/paymentQueue',
      requiredAction: InvoiceActions.AddToPaymentQueue
    },
    PaymentQueueReview: {
      path: '/paymentQueue/review'
    },
    RecordPayment: {
      path: '/recordPayment'
    },
    OutOfBandAttachments: {
      path: '/oob/:id/attachments'
    },
    OutOfBandEdit: {
      path: '/oob/:id/edit'
    },
    VpInboxCreate: {
      path: '/vpinbox/:id/create'
    },
    Create: {
      path: '/create'
    },
    Edit: {
      path: '/edit/:type/:id'
    },
    ConsumerOpenBills: {
      path: '/open',
      requiredAction: BillViewActions.ViewPersonalOpen
    },
    ConsumerClosedBills: {
      path: '/closed',
      requiredAction: BillViewActions.ViewPersonalClosed
    }
  }
};
