/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import { createFeatureToggleReducer } from 'services/FeatureToggles';
import apiEntityReducer from 'api/core/apiEntityReducer';

// Connected-React-Router
import history from 'config/history';
import { connectRouter, routerMiddleware } from 'connected-react-router';

// Middleware
import thunkMiddleware from 'redux-thunk';
import schemaMiddleware from 'middleware/schemaValidation';
import callbackMiddleware from 'middleware/callback';

// App-specific reducers
import session from 'reducers/session';
import modalReducer from 'reducers/modals';
import componentReducer from 'reducers/component';
import guidedTourReducer from 'reducers/guidedTour';
import notificationReducer from 'reducers/notification';
import highlightsReducer from 'reducers/highlights';
import workflowReducer from 'reducers/workflow';
import selectCompanyReducer from 'reducers/selectCompany';
import popupDrawerReducer from 'reducers/popupDrawer';
import deviceInfoReducer from 'reducers/deviceInfo';
import requestsReducer from 'reducers/requests';
import * as networkReducers from '../reducers/network';
import * as settingsReducers from '../reducers/settings';
import * as paymentReducers from '../reducers/payments';
import * as invoiceReducers from '../reducers/invoice';
import * as onboardingReducers from '../reducers/onboarding';
import registrationReducer from '../reducers/registration';
import * as dashboardReducers from '../reducers/dashboard';
import * as syncReducers from '../reducers/sync';
import * as accountSetupReducers from '../reducers/accountSetup';
import coordinatorReducer from '../reducers/coordinator';
import referencesReducer from '../reducers/references';
import fileImportReducer from '../reducers/fileImport';
import { newState } from '../reducers/utils';

const createAppReducer = reducers => combineReducers({
  entities: apiEntityReducer,
  session,
  onboarding: combineReducers(onboardingReducers),
  invoice: combineReducers(invoiceReducers),
  dashboard: combineReducers(dashboardReducers),
  sync: combineReducers(syncReducers),
  network: combineReducers(networkReducers),
  payments: combineReducers(paymentReducers),
  settings: combineReducers(settingsReducers),
  accountSetup: combineReducers(accountSetupReducers),
  component: componentReducer,
  registration: registrationReducer,
  modals: modalReducer,
  guidedTour: guidedTourReducer,
  notifications: notificationReducer,
  highlights: highlightsReducer,
  workflow: workflowReducer,
  selectCompany: selectCompanyReducer,
  popupDrawer: popupDrawerReducer,
  deviceInfo: deviceInfoReducer,
  requests: requestsReducer,
  coordinator: coordinatorReducer,
  references: referencesReducer,
  fileImport: fileImportReducer,
  featureToggles: createFeatureToggleReducer({ newState }),
  router: connectRouter(history),
  ...(reducers ?? [])
});

const createDefaultStore = config => createStore(
  config?.overrideReducers ?? createAppReducer(config?.reducers),
  config?.initialState ?? {},
  compose(
    applyMiddleware(
      callbackMiddleware,
      thunkMiddleware,
      schemaMiddleware,
      routerMiddleware(history)
    ),
    ...(config?.enhancers ?? [])
  )
);

export default createDefaultStore;
