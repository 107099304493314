/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { connect } from 'react-redux';
import OnboardingApi from 'api/onboarding';
import PaymentAccountsApi from 'api/paymentAccounts';
import { dispatchApiCall, makeApiCall, getCacheResponse, hasCacheFailed, isCacheLoading } from 'actions/references';

const CACHE_NAME = 'PaymentAccountOnboarding';
const VERIFY_CACHE_NAME = 'VerifyPaymentAccountOnboarding';

const MapStateToProps = state => ({
  isLoading: isCacheLoading(state, CACHE_NAME),
  account: getCacheResponse(state, CACHE_NAME),
  isSubmitting: isCacheLoading(state, VERIFY_CACHE_NAME),
  verification: hasCacheFailed(state, VERIFY_CACHE_NAME)
    ? null
    : getCacheResponse(state, VERIFY_CACHE_NAME)
});

const MapDispatchToProps = dispatch => ({
  getPaymentAccountStatus: token => dispatch(dispatchApiCall(
    CACHE_NAME,
    OnboardingApi.getPaymentAccountStatus,
    { token }
  )),
  verifyAccount: verification => dispatch(makeApiCall(
    VERIFY_CACHE_NAME,
    PaymentAccountsApi.newVerifyPaymentAccount,
    verification
  ))
});

export default Component => connect(MapStateToProps, MapDispatchToProps)(Component);
