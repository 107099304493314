/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */
/* eslint max-len:0 */

import { toMessages } from 'config/messages';

export default toMessages('Registration.AchPlusEnrollment.Success', {
  PlaidSubheader: '{b}Enrollment complete!{b} You will now receive electronic payments from {companyName}.',
  ManualHeader: 'Don’t forget to verify micro deposits.',
  ManualSubheader: 'Two micro deposits will be deposited in your bank account. For security, you’ll need to verify the amount of these deposits to complete the setup of your bank account. If you need help, please email {customerSupportLink}.',
  UnauthPartialRegistration: 'You have filled out the form to receive electronic payments. If you want more visibility into your payments and invoices, contact {customerSupportLink}.'
});
