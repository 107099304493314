/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import FeatureToggleList from './list';
import createFeatureToggleService from './utils';

const {
  isEnabled,
  isForceEnabled,
  saveFeatureToggles,
  getFeatureToggles,
  createFeatureToggleReducer,
  createFeatureToggleDefaultState,
  updateFeatureToggleAction: updateFeatureToggle,
  getFeatureToggleState
} = createFeatureToggleService(FeatureToggleList);

export {
  FeatureToggleList,
  isEnabled,
  isForceEnabled,
  saveFeatureToggles,
  getFeatureToggles,
  createFeatureToggleReducer,
  createFeatureToggleDefaultState,
  updateFeatureToggle,
  getFeatureToggleState
};
