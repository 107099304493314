/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { toMessages } from '../messages';

export default toMessages('CommonMessages', {
  Search: 'Search',
  Submit: 'Submit',
  Reset: 'Reset',
  Close: 'Close',
  Cancel: 'Cancel',
  Continue: 'Continue',
  Enable: 'Enable',
  Enabled: 'Enabled',
  Disable: 'Disable',
  Disabled: 'Disabled',
  Revert: 'Revert',
  Verify: 'Verify',
  GetStarted: 'Get Started',
  GotIt: 'Got It',
  NA: 'N/A',
  None: 'None',
  Yes: 'Yes',
  No: 'No',
  Save: 'Save',
  Edit: 'Edit',
  Unknown: 'Unknown',
  Go: 'Go',
  Back: 'Back',
  Change: 'Change',
  Show: 'Show',
  Hide: 'Hide',
  Dismiss: 'Dismiss',
  Update: 'Update',
  Refresh: 'Refresh',
  Add: 'Add',
  Vendor: 'Vendor',
  Customer: 'Customer',
  Optional: 'Optional',
  View: 'View',
  Delete: 'Delete',
  Accept: 'Accept',
  Send: 'Send',
  Return: 'Return',
  Viewpost: 'Viewpost',
  CustomerSupportEmail: 'support@viewpost.com',
  UnexpectedError: 'An unexpected error occurred.',
  More: 'More',
  Actions: 'Actions'
});
