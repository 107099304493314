/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { Children, isValidElement } from 'react';

export default function Step(props) {
  let actions = [];
  let parameters = [];
  let view = null;

  if (!props.name) {
    throw new Error('Each Workflow Step must have a name.');
  }

  const { children, ...stepProps } = props;

  Children.forEach(children, (child) => {
    if (!child) {
      return;
    }

    let childConfig = child.type(child.props);
    if (childConfig.workflowType === 'Parameter') {
      parameters.push(childConfig);
    }

    if (childConfig.workflowType === 'Action') {
      actions.push(childConfig);
    }

    if (childConfig.workflowType === 'View') {
      if (view) {
        throw new Error('Cannot have multiple views for step');
      }

      if (!isValidElement(childConfig.child)) {
        throw new Error('View is not a valid element');
      }

      view = childConfig.child;
    }
  });

  if (!props.component && !view) {
    throw new Error(`Each Workflow Step must have a component or a view child. Step ${props.name} does not have a component.`); // eslint-disable-line max-len
  }

  if (parameters.length) {
    console.warn(`<Parameter> is now deprecated. `
      + `Please pass parameters directly as props to the <Step name="${props.name}"> instead.`);
  }

  return {
    ...stepProps,
    workflowType: 'Step',
    actions,
    parameters,
    view
  };
}
