/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';

import FormattedDate from 'components/FormattedDate';

import Messages from './index.messages';

const DateRange = ({
  beginDate,
  endDate,
  showToday
}) => {
  if (beginDate == null && endDate == null) {
    return <></>;
  }

  const beginDay = beginDate ? moment(beginDate, 'MM/DD/YYYY').format('L') : null;
  const endDay = endDate ? moment(endDate, 'MM/DD/YYYY').format('L') : null;

  if (showToday == null || showToday) {
    const today = moment().format('L');

    if (beginDay === today && endDay === today) {
      return (
        <Messages.Today.Message/>
      );
    }
  }

  if (beginDay === endDay) {
    return <FormattedDate value={beginDate} />;
  }

  if (beginDate != null && endDate == null) {
    return (
      <Messages.AfterRange.Message
        date={<FormattedDate value={beginDate} />}
      />
    );
  }

  if (endDate != null && beginDate == null) {
    return (
      <Messages.BeforeRange.Message
        date={<FormattedDate value={endDate} />}
      />
    );
  }

  return (
    <Messages.DateRange.Message
      begin={<FormattedDate value={beginDate} />}
      end={ <FormattedDate value={endDate} />}
    />
  );
};

DateRange.propTypes = {
  beginDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date)
  ]).isRequired,
  endDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date)
  ]).isRequired,
  todayMessage: PropTypes.object,
  rangeMessage: PropTypes.object
};

export default DateRange;