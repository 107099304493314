/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import qs from 'qs';

const getQueryValues = (
  {
    queryParams,
    deserializeQuery
  },
  {
    search
  }
) => {
  const query = qs.parse(search.startsWith('?')
    ? search.substr(1)
    : search);

  const queryKeys = Object.keys(query);
  // Make the keys case insensitive
  const getQueryKey = key => queryKeys.find(
    queryKey => queryKey.toLowerCase() === key.toLowerCase()
  );

  if (!queryParams?.length) return query;

  let keysToNewKeyMap;

  if (!!queryParams?.length) {
    // Normalize query params to [ inputQueryParamKey, outputObjectKey ]
    keysToNewKeyMap = queryParams.map(k => Array.isArray(k) ? k : [k, k]);
  }

  if (!keysToNewKeyMap) {
    keysToNewKeyMap = queryKeys.map(k => [k, k]);
  }

  let queryValues = keysToNewKeyMap.reduce(
    (
      val,
      [ existingKey, newKey ]
    ) => ({
      ...val,
      [newKey]: query[getQueryKey(existingKey)]
    }),
    {}
  );

  if (deserializeQuery) {
    queryValues = deserializeQuery(queryValues);
  }

  return queryValues;
};

export default getQueryValues;