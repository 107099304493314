/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './index.scss';

export const BodyTextVariants = {
  Default: 'default',
  Smaller: 'smaller',
  Smallest: 'smallest'
};

export default class BodyText extends Component {
  static propTypes = {
    variant: PropTypes.oneOf(Object.keys(BodyTextVariants).map(variant => BodyTextVariants[variant])),
    bold: PropTypes.bool,
    inline: PropTypes.bool,
    style: PropTypes.object,
    className: PropTypes.string,
    color: PropTypes.oneOf(['black', 'darkGrey', 'grey', 'red'])
  };

  static defaultProps = {
    variant: 'default',
    bold: false,
    color: 'black'
  };

  render() {
    const { variant, bold, children, className, style, color, inline } = this.props;

    return (
      <div style={style}
        className={classNames('body-text-component', className, `color-${color}`, {
          bold,
          'default': variant === 'default',
          'smaller': variant === 'smaller',
          'smallest': variant === 'smallest',
          'inline-text': inline
        })}>
        {children}
      </div>
    );
  }
}
