/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */
/* eslint max-len:0 */

import { toMessages } from 'config/messages';

export default toMessages('CreateSwitchCompanyModal', {
  AddHeader: 'Create New Company',
  SubmitButton: 'Create Company',
  CloseButton: 'Cancel',
  CorporateHeader: 'Create a full-featured Corporate Account',
  CorporateSubheader: 'Please enter your credit card information below.',
  CreatePortalAccount: 'Create a free account with limited features, instead.',
  WillAcceptTerms: 'I have read and agree to Viewpost\'s Terms of Use, Privacy Policy, and Payment Terms',
  CompanyName: 'Company\'s Legal Name',
  PortalHeader: 'Create a free Portal account with limited features',
  PortalInstructions: 'A Portal account allows you to transact with specific companies that are already on Viewpost and are using a full-featured Viewpost account. In order to connect and transact with other companies you must subscribe to a full-featured Viewpost Corporate account.',
  PortalInstructionsTwo: 'Once you create a Portal account you will need to connect with another company in order to enable the available features of this account.',
  CreateCorporateAccount: 'Create a full-featured Corporate account, instead.'
});

