/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { toEnumObject } from './utils';

const CorrelationId = {
  AddCheckingAccount: 'AddCheckingAccount',
  AddCheckingAccountRefresh: 'AddCheckingAccountRefresh'
};

const CompanyUserRole = {
  None: 0,
  Admin: 1
};

const CompanyUserStatus = {
  None: 0,
  PendingVerification: 1,
  Registered: 2,
  NotInvited: 3,
  PendingRegistration: 4
};

export const AccountingType = toEnumObject([
  'Unknown',
  'AccountsPayable',
  'AccountsReceivable',
  'Both'
]);

export const AccountingTypePromptAcknowledgement = 'HasSeenAccountingTypePrompt';

export {
  CorrelationId,
  CompanyUserStatus,
  CompanyUserRole
};
