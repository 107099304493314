/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { isArray, isObject, isFunction } from 'lodash';
import t from 'tcomb-validation';

const isAvailableActionString = (availableActions) => {
  if (isFunction(availableActions)) {
    return t.refinement(
      t.String,
      action => availableActions().includes(action)
    );
  }

  if (isObject(availableActions)) {
    return t.enums.of(
      Object.keys(availableActions)
        .map(key => availableActions[key])
    );
  }

  if (isArray) {
    return t.enums.of(availableActions);
  }

  throw new Error('Unknown availableAction type');
};

/**
 * Creates a type of a list of strings that are available actions
 *
 * Can take several things to define the possible available actions
 *   1) function returning array of strings
 *   2) enum object (string -> string)
 *   3) array of strings
 *
 * Convention is to have this defined as a field called 'availableActions'
 */
export const createAvailableActionsType = availableActions => t.list(
  isAvailableActionString(availableActions)
);

/**
 * Maps denied available actions to a list of reasons why they were denied
 *
 * Convention is to have this defined as a field called 'deniedAvailableActions'
 */
export const createDeniedAvailableActionsType = availableActions => t.dict(
  isAvailableActionString(availableActions),
  t.list(t.String)
);

/**
 * Aggregates both the list of available actions with the denied available
 * actions into a single object
 *
 * Aligns with the output of 'determineAvailableActions', so that breaks the convention
 * of deniedAvailableActions being defined in the field 'deniedReasons'
 *
 * Convention is to have this defined as a field called 'availableActionsState'
 */
export const createAvailableActionsStateType = availableActions => t.struct({
  availableActions: createAvailableActionsType(availableActions),
  deniedReasons: createDeniedAvailableActionsType(availableActions)
});

/**
 * This available actions state without any validation on the keys being used.
 * Theoretically, we could totally stop doing strict key validation in favor of doing soft
 * checks/warnings at the users of the fields as we probably don't get any real benefit of locking
 * down the type at all.
 */
export const AvailableActionsState = t.struct({
  availableActions: t.list(t.String),
  deniedReasons: t.dict(t.String, t.list(t.String))
});
