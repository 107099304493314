/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React from 'react';
import { FormattedMessage } from 'react-intl';
import messages from './index.messages.js';

export default function Slogan(props) {
  return (
    <p className="business-slogan">
      <FormattedMessage {...messages.Slogan} />
    </p>
  );
}
