/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { createApiAction } from 'api/core';

const acceptTerms = createApiAction({
  method: 'post',
  url: '/ajax/logon/acceptNewTermsAndConditions',
  requestBodyMapper: () => ({ accepted: true })
});

export default acceptTerms;