/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */
/* eslint max-len:0 */

import { toMessages } from 'config/messages';

export default toMessages('Registration.SubscriptionPaymentForm', {
  CreditCardNumber: 'Credit/Debit Card Number',
  CardNumberTooltip: '{b}Viewpost does not store your credit card information.{b} For security, credit card transactions are processed through a third-party provider.',
  Expiration: 'Expiration',
  Cvc: 'Secure Code',
  CvcTooltip: '{b}The secure code (CVV or Card Verification Value){b} on your credit card or debit card is a 3-digit number located on the back of VISA®, MasterCard® and Discover® branded credit and debit cards. On your American Express® branded cards, it is a 4-digit numeric code located on the front.',
  PostalCode: 'Zip Code',
  CardholderName: 'Name on Card',
  SubmitButton: 'Pay Now and Create Account',
  DefaultStripeError: 'There is a problem with the card information you provided. Verify the information and submit again.'
});
