/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

const PaymentViewActions = {
  ViewPayments: 'ViewPayments',
  ViewQbo: 'ViewQboPayments',
  ViewReceived: 'ViewReceivedPayments',
  ViewSent: 'ViewSentPayments',
  ViewAutoPay: 'ViewAutoPay',
  ViewApprovals: 'ViewPaymentApprovals'
};

export default PaymentViewActions;