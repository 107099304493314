/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import createActionRegistry from './createActionRegistry';

const {
  getAllAvailableActionsList,
  createAvailableActions,
  createLegacyAvailableActions
} = createActionRegistry();

export { getAllAvailableActionsList, createLegacyAvailableActions };

export default createAvailableActions;
