/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { isSuccessAction } from 'api/core/utils';
import { newState } from './utils';

export function BillsOnboarding(state = {}, action) {
  if (isSuccessAction(action, 'getBillsInvitationDocuments')) {
    return newState(state, {
      invoices: action.payload.entities.map(e => e.value)
    });
  }

  return state;
}
