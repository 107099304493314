/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { connect } from 'react-redux';

import {
  getTwoStepSettings,
  enableTwoStepVerification,
  resendTwoFactorCode,
  disableTwoFactorAuthentication
} from 'actions/settings';

function MapStateToProps(state, props) {
  let viewState = state.settings && state.settings.AccountSettingsPage
    ? state.settings.AccountSettingsPage
    : null;

  return {
    isTwoFactorEnabled: viewState.isTwoFactorEnabled,
    isTwoFactorVerificationPending: viewState.isVerificationPending,
    phoneNumber: viewState.phoneNumber,
    saveTwoStepSuccess: viewState.saveTwoStepSuccess,
    isPhoneNumberLocked: viewState.isPhoneNumberLocked,
    skipValidation: viewState.skipValidation,
    canDisableTwoFactor: viewState.canDisableTwoFactor
  };
}

function MapDispatchToProps(dispatch) {
  return {
    getTwoStepSettings: () => dispatch(getTwoStepSettings()),
    enableTwoStepVerification: phoneNumber => dispatch(enableTwoStepVerification(phoneNumber)),
    resendTwoFactorCode: () => dispatch(resendTwoFactorCode()),
    disableTwoFactorAuthentication: () => dispatch(disableTwoFactorAuthentication()),
    resetTwoFactorVerification: () => dispatch({ type: 'ResetTwoFactor' })
  };
}

export default connect(MapStateToProps, MapDispatchToProps, null, { forwardRef: true });
