/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React from 'react';
import { Route, Redirect, Switch } from 'react-router';
import { wrapImport } from 'decorators/ModuleLoader';
import { flattenHashLocation, InAppNotFoundView } from '../utils';

const SwitchCompanies = wrapImport(() => import(/* webpackChunkName: "views" */ 'views/Settings/SwitchCompanies'));
const PaymentMethodsPage = wrapImport(() => import(/* webpackChunkName: "views" */ 'views/Settings/PaymentMethods'));
const AddVendorsList = wrapImport(() => import(/* webpackChunkName: "views" */ 'views/Settings/Vpx/addVendorsList'));
const MainVendorsList = wrapImport(() => import(/* webpackChunkName: "views" */ 'views/Settings/Vpx/mainVendorsList'));
const ManageVendorsList = wrapImport(
  () => import(/* webpackChunkName: "views" */ 'views/Settings/Vpx/manageVendorsList')
);
const VpxSettingsNewStrategy = wrapImport(
  () => import(/* webpackChunkName: "views" */ 'views/Settings/Vpx/NewStrategy')
);
const UserSettingsPage = wrapImport(() => import(/* webpackChunkName: "views" */ 'views/Settings/User'));
const CompanyGroupsPage = wrapImport(() => import(/* webpackChunkName: "views" */ 'views/Settings/Company/Groups'));
const CompanyUsersPage = wrapImport(() => import(/* webpackChunkName: "views" */ 'views/Settings/Company/Users'));
const CompanyPaymentApprovalsPage = wrapImport(
  () => import(/* webpackChunkName: "views" */ 'views/Settings/Company/PaymentApproval'));
const CompanyLogosPage = wrapImport(() => import(/* webpackChunkName: "views" */ 'views/Settings/Company/Logos'));
const CompanyProfilePage = wrapImport(() => import(/* webpackChunkName: "views" */ 'views/Settings/Company/Profile'));
const CompanyReportsPage = wrapImport(() => import(/* webpackChunkName: "views" */ 'views/Settings/Company/Reports'));
const CompanyPreferencesPage = wrapImport(
  () => import(/* webpackChunkName: "views" */ 'views/Settings/Company/Preferences')
);
const CloseAccountPage = wrapImport(
  () => import(/* webpackChunkName: "views" */ 'views/Settings/Company/CloseAccount')
);
const FeeSummaryPage = wrapImport(() => import(/* webpackChunkName: "views" */ 'views/Settings/Fees/Summary'));
const FeeDetailsPage = wrapImport(() => import(/* webpackChunkName: "views" */ 'views/Settings/Fees/Details'));
const EditCompanyProfilePage = wrapImport(
  () => import(/* webpackChunkName: "views" */ 'views/Settings/Company/Profile/EditProfile')
);
const AddEditGroupPage = wrapImport(
  () => import(/* webpackChunkName: "views" */ 'views/Settings/Company/Groups/AddEditGroup')
);
const AddEditUserPage = wrapImport(
  () => import(/* webpackChunkName: "views" */ 'views/Settings/Company/Users/AddEditUser')
);
const SubscriptionPage = wrapImport(
  () => import(/* webpackChunkName: "views" */ 'views/Settings/Company/Subscription')
);

export const LegacyAngularSettingsRedirects = ({ location, match }) => (
  <Switch location={flattenHashLocation(location)}>
    <Redirect path={match.url} exact={true} to="/settings" />
    <Redirect path={`${match.url}/companyProfile`} exact={true} to="/settings/companyProfile" />
    <Redirect path={`${match.url}/companyProfile/edit`} exact={true} to="/settings/companyProfile/edit" />
    <Redirect path={`${match.url}/companySettings`} exact={true} to="/settings/companyPreferences" />
    <Redirect path={`${match.url}/companySettings/close`} exact={true} to="/settings/closeAccount" />
    <Redirect path={`${match.url}/feebies`} exact={true} to="/settings" />
    <Redirect path={`${match.url}/accounts`} exact={true} to="/settings/paymentMethods" />
    <Redirect path={`${match.url}/reports`} exact={true} to="/settings/reports" />
    <Redirect path={`${match.url}/logos`} exact={true} to="/settings/logos" />
    <Redirect path="*" to="/settings" />
  </Switch>
);

export const LegacyProfileRedirects = ({ match }) => (
  <Switch location={flattenHashLocation(location)}>
    <Redirect path={match.url} exact={true} to="/settings/userAccount" />
    <Redirect path={`${match.url}/emailPreferences`} exact={true} to="/settings/userAccount" />
    <Redirect path={`${match.url}/security`} exact={true} to="/settings/userAccount" />
    <Redirect path="*" to="/settings/userAccount" />
  </Switch>
);

const LegacySettingsRedirects = ({ match }) => (
  <Switch>
    <Redirect path={match.url} exact={true} to="/settings" />
    <Redirect path={`${match.url}/manageTemplates`} exact={true} to="/settings" />
    <Redirect path={`${match.url}/companyProfile`} exact={true} to="/settings/companyProfile" />
    <Redirect path={`${match.url}/employees/view`} exact={true} to="/settings/users" />
    <Redirect path={`${match.url}/employees/add`} exact={true} to="/settings/users/add" />
    <Redirect path={`${match.url}/employees/edit/:id`} exact={true} to="/settings/users/edit/:id" />
    <Redirect path={`${match.url}/roles/view`} exact={true} to="/settings/groups" />
    <Redirect path={`${match.url}/roles/add`} exact={true} to="/settings/groups/add" />
    <Redirect path={`${match.url}/roles/edit-admin/:id`} exact={true} to="/settings/groups/edit/:id" />
    <Redirect path={`${match.url}/roles/edit/:id`} exact={true} to="/settings/groups/edit/:id" />
    <Redirect path="*" to="/settings" />
  </Switch>
);

const DiscountSettingsRoutes = ({ match }) => (
  <Switch>
    <Route path={match.url} exact={true} component={MainVendorsList} />
    <Route path={`${match.url}/manageVendors`} exact={true} component={ManageVendorsList} />
    <Route path={`${match.url}/strategy/create`} exact={true} component={VpxSettingsNewStrategy} />
    <Route path={`${match.url}/strategy/edit/:id`} exact={true} component={VpxSettingsNewStrategy} />
    <Route path={`${match.url}/strategy/addVendors/:id`} exact={true} component={AddVendorsList} />
    {InAppNotFoundView}
  </Switch>
);

export const BusinessSettingsRoutes = ({ location, match }) => (
  <Switch location={flattenHashLocation(location, '/old')}>
    {/* Redirects */}
    <Redirect path={match.url} exact={true} to={`${match.url}/companyProfile`} />
    <Route path={`${match.url}/old`} component={LegacySettingsRedirects} />
    <Route path={`${match.url}/ng`} component={LegacyAngularSettingsRedirects} />
    <Redirect path={`${match.url}/vpx`} exact={true} to={`${match.url}/discount`} />
    <Redirect path={`${match.url}/verifyAccount/:id`} exact={true}
      to={`${match.url}/paymentMethods?verifyAccountId=:id`} />
    <Redirect path={`${match.url}/emailPreferences`} exact={true}
      to={`${match.url}/userAccount`} />
    {/* Routes */}
    <Route path={`${match.url}/companyProfile`} exact={true} component={CompanyProfilePage} />
    <Route path={`${match.url}/companyProfile/edit`} exact={true} component={EditCompanyProfilePage} />
    <Route path={`${match.url}/companyPreferences`} exact={true} component={CompanyPreferencesPage} />
    <Route path={`${match.url}/closeAccount`} exact={true} component={CloseAccountPage} />
    <Route path={`${match.url}/logos`} exact={true} component={CompanyLogosPage} />
    <Route path={`${match.url}/users`} exact={true} component={CompanyUsersPage} />
    <Route path={`${match.url}/users/add`} exact={true} component={AddEditUserPage} />
    <Route path={`${match.url}/users/edit/:id`} exact={true} component={AddEditUserPage} />
    <Route path={`${match.url}/paymentApproval`} exact={true} component={CompanyPaymentApprovalsPage} />
    <Route path={`${match.url}/groups`} exact={true} component={CompanyGroupsPage} />
    <Route path={`${match.url}/groups/add`} exact={true} component={AddEditGroupPage} />
    <Route path={`${match.url}/groups/edit/:id`} exact={true} component={AddEditGroupPage} />
    <Route path={`${match.url}/userAccount`} exact={true} component={UserSettingsPage} />
    <Route path={`${match.url}/reports`} exact={true} component={CompanyReportsPage} />
    <Route path={`${match.url}/fees`} exact={true}>
      <FeeSummaryPage />
    </Route>
    <Route path={`${match.url}/fees/details`} exact={true}>
      <FeeDetailsPage />
    </Route>
    <Route path={`${match.url}/paymentMethods`} exact={true} component={PaymentMethodsPage} />
    <Route path={`${match.url}/switchCompanies`} exact={true} component={SwitchCompanies} />
    <Route path={`${match.url}/discount`} component={DiscountSettingsRoutes} />
    <Route path={`${match.url}/subscription`} component={SubscriptionPage} />}
    {InAppNotFoundView}
  </Switch>
);

export const PersonalPortalSettingsRoutes = ({ location, match }) => (
  <Switch location={flattenHashLocation(location, '/old')}>
    {/* Redirects */}
    <Redirect path={`${match.url}/paymentMethods`} exact={true} to="/userSettings" />
    {/* Routes */}
    <Route path={`${match.url}/closeAccount`} exact={true} component={CloseAccountPage} />
    <Route path={`${match.url}/switchCompanies`} exact={true} component={SwitchCompanies} />
    {InAppNotFoundView}
  </Switch>
);
