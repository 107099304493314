/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import verifyUserAccount from 'api/session/verifyUserAccount';

import useQueryParams from 'hooks/location/useQueryParams';

const VerifyUserAccountWorkflowStep = ({
  active,
  updateState
}) => {
  // Hooks
  const dispatch = useDispatch();
  const {
    signature: verifyAccountSignature,
    userId: verifyAccountUserId
  } = useQueryParams([
    'signature',
    'userId'
  ]);

  const willShow = !!verifyAccountSignature && !!verifyAccountUserId;

  useEffect(
    () => {
      updateState(willShow ? 'loaded' : 'skipped');
    },
    []
  );

  const execute = async () => {
    await dispatch(verifyUserAccount({
      signature: verifyAccountSignature,
      userId: verifyAccountUserId
    }));
    updateState('completed');
  };

  useEffect(
    () => {
      if (willShow && active) {
        // useEffect doesn't like being passed an async function - hence why this broken out
        execute();
      }
    },
    [ active ]
  );

  // Render
  return <></>;
};

export default VerifyUserAccountWorkflowStep;
