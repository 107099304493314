/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { isPersonalPortal } from 'actions/common';
import getNavigationData from 'api/navigation/getNavigationData';
import createDataSource from 'decorators/ApiResultDataSource';

const connectNavigationDataSource = createDataSource({
  correlationId: 'HeaderNavigation',
  apiConfig: [{
    endpoint: getNavigationData,
    getParams: ({ isPersonalPortal: isPersonal }) => !isPersonal
      ? {}
      : false,
    mapToProps: ({ getResult, executeRequest }) => ({
      navData: getResult({ coalesce: true }),
      refreshNavData: executeRequest
    }),
    mapStateToParams: state => ({
      isPersonalPortal: isPersonalPortal(state)
    })
  }]
});

export const connectNavigationData = connectNavigationDataSource.createChildWrapper;

export default Component => connectNavigationDataSource(Component);
