/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */

import { createContext } from 'react';

const FormContext = createContext({
  disabled: null,
  highlightRequired: null,
  invalidFields: null,
  onChange: null,
  paths: null,
  register: null
});

export const FormStateContext = createContext({});

export const NamePrefixContext = createContext('');

export default FormContext;