/** @copyright (c) Viewpost. All Rights Reserved. See LICENSE for more details. */
/* eslint max-len:0 */

import { defineMessages } from 'react-intl';

const common = defineMessages({
  ConfirmSubmitButton: {
    id: 'Modal.Confirm.Submit',
    description: 'TODO',
    defaultMessage: 'OK'
  },
  ConfirmCloseButton: {
    id: 'Modal.Confirm.Close',
    description: 'TODO',
    defaultMessage: 'Close'
  }
});

export default {
  ...common
};
